import {Project, ProjectUrl, urlToId} from "@co-common-libs/resources";
import {TaskArchiveData} from "@co-common-libs/resources-utils";
import {DateField, TrimTextField} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {QueryParameters} from "@co-frontend-libs/routing-sync-history";
import {NetworkError, StatusError} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  buildArchiveDataUrl,
  PaginationPageSize,
  SortingState,
  TableDefaults,
  TaskTableColumnID,
  useArchiveSearchState,
  useTableWithPagination,
} from "app-components";
import {useFetchGet} from "app-utils";
import bowser from "bowser";
import clsx from "clsx";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {ArchiveTaskTable} from "../task-list/task-archive/archive-task-table";

const mobile = bowser.tablet || bowser.mobile;

export const useStyles = makeStyles((_theme: Theme) => {
  return createStyles({
    mobilePadding: {padding: "1em 11px 1em 11px"},
    pcPadding: {padding: "1em"},
  });
});

const projectTaskArchiveTableDefaults: TableDefaults<TaskTableColumnID> = {
  defaultRowsPerPage: PaginationPageSize.SMALL,
  defaultSortDirection: "ASC",
  defaultSortKey: "status",
};

export interface ProjectTaskTableProps {
  project: Pick<Project, "url">;
}

const projectTaskArchiveIdentifier = "ProjectTaskArchiveTable";

export const ProjectTaskTable = React.memo(function ProjectTaskTable(
  props: ProjectTaskTableProps,
): React.JSX.Element {
  const {project} = props;
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    fromDate,
    handleFromDateBlur,
    handlePeriodStartSelectedInDialog,
    setFromDate,
    setTextSearch,
    setToDate,
    textSearch,
    toDate,
  } = useArchiveSearchState();

  const [fetchData, fetchDataCancel, response, fetching, fetchError] = useFetchGet<{
    data: TaskArchiveData[];
    total: number;
  }>();

  const [page, rowsPerPage, sortBy, sortDirection, setPage, setRowsPerPage, toggleSortingKey] =
    useTableWithPagination(
      projectTaskArchiveIdentifier,
      projectTaskArchiveIdentifier,
      projectTaskArchiveTableDefaults.defaultSortKey,
      projectTaskArchiveTableDefaults.defaultSortDirection,
      projectTaskArchiveTableDefaults.defaultRowsPerPage,
      null,
    );

  const sortingState: SortingState<TaskTableColumnID> = useMemo(() => {
    return {sortDirection, sortKey: sortBy};
  }, [sortDirection, sortBy]);

  const [searchPerformedWithCurrentCriteria, setSearchPerformedWithCurrentCriteria] =
    useState(false);

  useEffect(() => {
    if (response) {
      setSearchPerformedWithCurrentCriteria(true);
    }
  }, [response]);

  useEffect(() => {
    fetchDataCancel();
    setSearchPerformedWithCurrentCriteria(false);
  }, [fromDate, toDate, textSearch, fetchDataCancel]);

  const doFetch = useCallback(
    (
      fetchFromDate: string | null,
      fetchToDate: string | null,
      fetchSortingState: SortingState<TaskTableColumnID>,
      fetchPage: number,
      fetchRowsPerPage: number,
      fetchTextSearch: string,
      projectUrl: ProjectUrl,
    ) => {
      if (!fetchFromDate || !fetchToDate || fetchFromDate <= fetchToDate) {
        const archiveUrl = buildArchiveDataUrl({
          archiveResource: "task",
          fetchFromDate,
          fetchPage,
          fetchRowsPerPage,
          fetchSortingState,
          fetchTextSearch,
          fetchToDate,
          project: projectUrl,
        });
        fetchData(archiveUrl);
      }
    },
    [fetchData],
  );

  const handleFetchClick = useCallback(() => {
    doFetch(fromDate, toDate, sortingState, page, rowsPerPage, textSearch, project.url);
  }, [doFetch, fromDate, toDate, sortingState, page, rowsPerPage, textSearch, project.url]);

  const handleSubmitOnEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleFetchClick();
      }
    },
    [handleFetchClick],
  );

  useEffect(() => {
    if (searchPerformedWithCurrentCriteria) {
      doFetch(fromDate, toDate, sortingState, page, rowsPerPage, textSearch, project.url);
    }
    // trigger on deep linking and table header click
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingState]);

  // Fetch data on initial mount
  useEffect(() => {
    doFetch(fromDate, toDate, sortingState, page, rowsPerPage, textSearch, project.url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      setPage(newPage);
      doFetch(fromDate, toDate, sortingState, newPage, rowsPerPage, textSearch, project.url);
    },
    [doFetch, fromDate, rowsPerPage, setPage, sortingState, textSearch, toDate, project.url],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const rows = parseInt(event.target.value);
      setPage(0);
      setRowsPerPage(rows);
      doFetch(fromDate, toDate, sortingState, 0, rows, textSearch, project.url);
    },
    [doFetch, fromDate, setPage, setRowsPerPage, sortingState, textSearch, toDate, project.url],
  );

  const navigateToTask = useCallback(
    (taskURL: string, queryParameters?: QueryParameters) => {
      const id = urlToId(taskURL);
      dispatch(actions.go("/task/:id", {id}, queryParameters));
    },
    [dispatch],
  );

  const navigateToTaskPhotoTab = useCallback(
    (taskURL: string) => {
      navigateToTask(taskURL, {tab: "photos"});
    },
    [navigateToTask],
  );

  let errorText: string | undefined;

  if (fetchError) {
    if (fetchError instanceof NetworkError) {
      errorText = intl.formatMessage({
        defaultMessage: "Kunne ikke kontakte server",
      });
    } else if (fetchError instanceof StatusError) {
      errorText = intl.formatMessage({defaultMessage: "Serverfejl"});
    } else {
      errorText = intl.formatMessage({defaultMessage: "Ukendt fejl"});
    }
  }

  return (
    <div
      className={clsx({
        [classes.mobilePadding]: mobile,
        [classes.pcPadding]: !mobile,
      })}
    >
      <Card>
        <CardContent>
          <FormattedMessage defaultMessage="Periode" />
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                label={intl.formatMessage({defaultMessage: "Fra"})}
                margin="dense"
                onBlur={handleFromDateBlur}
                onChange={setFromDate}
                onKeyDown={handleSubmitOnEnter}
                onSelectedInDialog={handlePeriodStartSelectedInDialog}
                referenceDate={toDate || undefined}
                value={fromDate || null}
                yearSuggestions="DATE_BEFORE"
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                label={intl.formatMessage({defaultMessage: "Til"})}
                margin="dense"
                minDate={fromDate || undefined}
                onChange={setToDate}
                onKeyDown={handleSubmitOnEnter}
                referenceDate={fromDate || undefined}
                value={toDate || null}
                yearSuggestions={fromDate ? "DATE_AFTER" : "DATE_BEFORE"}
              />
            </Grid>
            <Grid item xs={12}>
              <TrimTextField
                fullWidth
                label={intl.formatMessage({defaultMessage: "Fritekst"})}
                margin="dense"
                onChange={setTextSearch}
                onKeyDown={handleSubmitOnEnter}
                value={textSearch}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disabled={(fromDate && toDate && fromDate > toDate) || fetching}
            onClick={handleFetchClick}
            variant="contained"
          >
            <FormattedMessage defaultMessage="Søg" />
          </Button>
        </CardActions>
      </Card>
      <div style={{marginTop: "1em"}}>
        {fetching ? (
          <div style={{textAlign: "center"}}>
            <CircularProgress />
          </div>
        ) : null}
        {response ? (
          <div data-testid="tasks">
            <ArchiveTaskTable
              count={response.total}
              data={response.data}
              onClick={navigateToTask}
              onHeaderClick={toggleSortingKey}
              onPageChange={handleChangePage}
              onPhotoClick={navigateToTaskPhotoTab}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          </div>
        ) : null}
        {errorText ? (
          <Card>
            <CardContent>
              <Typography color="error">{errorText}</Typography>
            </CardContent>
          </Card>
        ) : null}
      </div>
    </div>
  );
});
