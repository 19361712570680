import {Config} from "@co-common-libs/config";
import {
  CustomerUrl,
  Machine,
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
} from "@co-common-libs/resources";
import {customerFilteredPriceGroups} from "@co-common-libs/resources-utils";
import {identifierComparator, notUndefined} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerSettings,
  getMachineLookup,
  getPriceGroupLookup,
} from "@co-frontend-libs/redux";
import {ButtonBaseActions, List, ListItem, ListItemText} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React, {useCallback, useRef} from "react";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  title: {
    defaultMessage: "Vælg variant",
    id: "price-group-dialog.dialog-title.select",
  },
});

interface PriceGroupDialogItemProps {
  autoFocus: boolean;
  name: string;
  onItemSelect: (url: PriceGroupUrl) => void;
  url: PriceGroupUrl;
}

function PriceGroupDialogItem(props: PriceGroupDialogItemProps): React.JSX.Element {
  const {autoFocus, name, onItemSelect, url} = props;

  const handleClick = useCallback(
    (event: React.MouseEvent<unknown, MouseEvent>): void => {
      if (event) {
        event.preventDefault();
      }
      onItemSelect(url);
    },
    [onItemSelect, url],
  );

  const focusButtonRef = useRef<ButtonBaseActions | null>(null);

  const focusActionCallback = useCallback((instance: ButtonBaseActions | null) => {
    if (instance !== focusButtonRef.current) {
      focusButtonRef.current = instance;
      if (instance) {
        // autoFocus "works"; it does set focus;
        // but this is necessary to also show the visual indication...
        instance.focusVisible();
      }
    }
  }, []);

  if (autoFocus) {
    return (
      <ListItem action={focusActionCallback} autoFocus button onClick={handleClick}>
        <ListItemText primary={name} />
      </ListItem>
    );
  } else {
    return (
      <ListItem button onClick={handleClick}>
        <ListItemText primary={name} />
      </ListItem>
    );
  }
}

interface PriceGroupDialogContentProps {
  customerSettings: Config;
  customerURL?: CustomerUrl | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  machineURL?: MachineUrl | undefined;
  onCancel: () => void;
  onItemSelect: (url: PriceGroupUrl) => void;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
}

class PriceGroupDialogContent extends PureComponent<PriceGroupDialogContentProps> {
  render(): React.JSX.Element {
    const {c5Sync} = this.props.customerSettings;
    const {customerURL, machineLookup, machineURL, onItemSelect, priceGroupLookup} = this.props;
    let priceGroupURLs: readonly PriceGroupUrl[] = [];

    if (machineURL) {
      const machine = machineLookup(machineURL);
      if (machine && machine.pricegroups) {
        priceGroupURLs = machine.pricegroups;
      }
    }

    const priceGroups = priceGroupURLs.map((url) => priceGroupLookup(url)).filter(notUndefined);

    // checks active, onlyForExtraTimers and customer/standardListing
    let filteredSortedPriceGroups = customerFilteredPriceGroups(priceGroups, customerURL || null);
    filteredSortedPriceGroups.sort((a, b) => identifierComparator(a.identifier, b.identifier));
    if (c5Sync) {
      filteredSortedPriceGroups = _.sortBy(
        filteredSortedPriceGroups,
        (instance) => instance.c5_recid,
      );
    }
    const listItems = filteredSortedPriceGroups.map((instance, index) => {
      const {url} = instance;
      return (
        <PriceGroupDialogItem
          autoFocus={index === 0}
          key={url}
          name={instance.name}
          onItemSelect={onItemSelect}
          url={url}
        />
      );
    });
    return <List>{listItems}</List>;
  }
}

interface PriceGroupDialogStateProps {
  customerSettings: Config;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
}

interface PriceGroupDialogOwnProps {
  customerURL?: CustomerUrl | undefined;
  machineURL?: MachineUrl | undefined;
  onCancel: () => void;
  onOk: (url: PriceGroupUrl) => void;
  open: boolean;
}

type PriceGroupDialogProps = PriceGroupDialogOwnProps & PriceGroupDialogStateProps;

class PriceGroupDialogImpl extends PureComponent<PriceGroupDialogProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleItemSelect(url: PriceGroupUrl): void {
    if (this.props.onOk) {
      this.props.onOk(url);
    }
  }
  @bind
  handleRequestClose(): void {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {open} = this.props;
    const dialogContent = (
      <PriceGroupDialogContent
        customerSettings={this.props.customerSettings}
        customerURL={this.props.customerURL}
        machineLookup={this.props.machineLookup}
        machineURL={this.props.machineURL}
        onCancel={this.props.onCancel}
        onItemSelect={this.handleItemSelect}
        priceGroupLookup={this.props.priceGroupLookup}
      />
    );
    return (
      <ResponsiveDialog
        onCancel={this.handleRequestClose}
        open={open}
        title={formatMessage(messages.title)}
      >
        {dialogContent}
      </ResponsiveDialog>
    );
  }
}

export const PriceGroupDialog: React.ComponentType<PriceGroupDialogOwnProps> = connect<
  PriceGroupDialogStateProps,
  object,
  PriceGroupDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, PriceGroupDialogStateProps>({
    customerSettings: getCustomerSettings,
    machineLookup: getMachineLookup,
    priceGroupLookup: getPriceGroupLookup,
  }),
  {},
)(PriceGroupDialogImpl);
