import {
  applyPatch,
  CustomerUrl,
  FieldUse,
  Patch,
  PatchOperation,
  ReportingLocation,
  Task,
} from "@co-common-libs/resources";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {ProvisionaryCommand} from "../../resources/actions";
import {getLocationLookup, getReportingSpecificationLookup} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function addRemoveLogLocationsOnFieldChanges(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }
  if (
    !newTask.reportingSpecification ||
    !newTask.fielduseSet ||
    _.isEqual(newTask.fielduseSet, oldTask?.fielduseSet)
  ) {
    // no generic log, or
    // no changes to fields
    return null;
  }

  const state = middlewareApi.getState();
  const oldFieldUses = oldTask?.fielduseSet || [];
  const newFieldUses = newTask.fielduseSet;

  const addedFieldUses = newFieldUses.filter(
    (newFieldUse) =>
      !oldFieldUses.some((oldFieldUse) => oldFieldUse.relatedField === newFieldUse.relatedField),
  );
  const removedFieldUses = oldFieldUses.filter(
    (oldFieldUse) =>
      !newFieldUses.some((newFieldUse) => newFieldUse.relatedField === oldFieldUse.relatedField),
  );

  if (!addedFieldUses.length && !removedFieldUses.length) {
    // fields not *actually* added/removed...
    return null;
  }

  const reportingSpecificationLookup = getReportingSpecificationLookup(state);
  const reportingSpecification = reportingSpecificationLookup(newTask.reportingSpecification);

  if (!reportingSpecification) {
    return null;
  }

  const {fieldsUsedFor} = reportingSpecification;

  if (
    !(addedFieldUses.length && fieldsUsedFor && fieldsUsedFor !== "unused") &&
    !removedFieldUses.length
  ) {
    // added fields should not be auto-added on log, and no fields removed
    return null;
  }

  const locationLookup = getLocationLookup(state);

  const patch: PatchOperation<Task>[] = [];

  if (addedFieldUses.length && fieldsUsedFor && fieldsUsedFor !== "unused") {
    // some fields added, and reporting specification indicates that
    // they should be added on log

    // find all locations currently in use on log, independently of `fieldsUsedFor`;
    // we don't want to auto-add log locations for fields already present on log,
    // even if as/for a different use
    const currentLogLocations = newTask.reportingLocations
      ? new Set(Object.values(newTask.reportingLocations).map((entry) => entry.location))
      : null;

    const oldMaxOrder =
      newTask.reportingLocations &&
      _.max(Object.values(newTask.reportingLocations).map((entry) => entry.order));
    let nextOrder = oldMaxOrder != null ? oldMaxOrder + 1 : 0;

    const workplaceDataForType = reportingSpecification.workplaceData[fieldsUsedFor];
    const worktypeInputSpecifications = workplaceDataForType?.inputs;

    for (const fieldUse of addedFieldUses) {
      const fieldURL = fieldUse.relatedField;
      if (currentLogLocations?.has(fieldURL)) {
        continue;
      }
      const field = locationLookup(fieldURL);
      if (!field) {
        continue;
      }

      const {customer, fieldAreaHa, fieldCrop} = field;
      const values: {[identifier: string]: number | string | undefined} = {};
      if (worktypeInputSpecifications) {
        for (const inputSpecification of worktypeInputSpecifications) {
          if (inputSpecification.fieldAreaTarget) {
            const decimals = 2;
            values[inputSpecification.identifier] = _.floor(fieldAreaHa || 0, decimals);
          }
          if (inputSpecification.fieldCropTarget) {
            values[inputSpecification.identifier] = fieldCrop;
          }
        }
      }
      const identifier = uuid();
      const entry: ReportingLocation = {
        customer: customer as CustomerUrl,
        location: fieldURL,
        order: nextOrder,
        productUses: {},
        type: fieldsUsedFor,
        values,
      };
      nextOrder += 1;
      patch.push({path: ["reportingLocations", identifier], value: entry});
    }
  }

  const updatedTask = patch.length ? applyPatch(newTask, patch) : newTask;

  if (removedFieldUses.length && updatedTask.reportingLocations) {
    // some fields removed

    const reAddFieldUses: FieldUse[] = [];

    const removedFields = new Set(removedFieldUses.map((fieldUse) => fieldUse.relatedField));

    const usedLocationEntries = updatedTask.reportingLog
      ? new Set(Object.values(updatedTask.reportingLog).map((logEntry) => logEntry.location))
      : null;

    for (const [identifier, entry] of Object.entries(updatedTask.reportingLocations)) {
      if (entry.location && removedFields.has(entry.location)) {
        // field removed
        if (usedLocationEntries?.has(identifier)) {
          // field removed, but has log entries...
          // ... so re-add fielduse rather than remove from log
          const fieldUse = removedFieldUses.find(
            ({relatedField}) => relatedField === entry.location,
          ) as FieldUse;
          console.assert(fieldUse);
          reAddFieldUses.push(fieldUse);
          removedFields.delete(entry.location);
        } else {
          patch.push({
            path: ["reportingLocations", identifier],
            value: undefined,
          });
        }
      }
    }
    if (reAddFieldUses.length) {
      patch.push({
        member: "fielduseSet",
        value: updatedTask.fielduseSet.concat(reAddFieldUses),
      });
    }
  }

  if (patch.length) {
    return patch;
  } else {
    return null;
  }
}
