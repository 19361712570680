/* eslint-disable react/jsx-no-bind */
import {ContentSelection} from "@co-common-libs/report-rendering";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";

interface StorageJournalContentSelectionProps {
  onChange: (contentSelection: ContentSelection) => void;
  selected: ContentSelection;
}
export function StorageJournalContentSelection(
  props: StorageJournalContentSelectionProps,
): React.JSX.Element {
  const {onChange, selected} = props;
  const intl = useIntl();

  const selectContent = useCallback(
    (mode: ContentSelection) => {
      onChange(mode);
    },
    [onChange],
  );

  return (
    <ButtonGroup variant="contained">
      <Button
        color={selected === ContentSelection.EVERYTHING ? "secondary" : "default"}
        onClick={() => selectContent(ContentSelection.EVERYTHING)}
      >
        {intl.formatMessage({defaultMessage: "Alle"})}
      </Button>
      <Button
        color={selected === ContentSelection.EVENTS ? "secondary" : "default"}
        onClick={() => selectContent(ContentSelection.EVENTS)}
      >
        {intl.formatMessage({defaultMessage: "Lager bevægelser"})}
      </Button>
      <Button
        color={selected === ContentSelection.SUMMARY_PRODUCT_PER_LOCATION ? "secondary" : "default"}
        onClick={() => selectContent(ContentSelection.SUMMARY_PRODUCT_PER_LOCATION)}
      >
        {intl.formatMessage({defaultMessage: "Sum. vare/sted"})}
      </Button>
      <Button
        color={selected === ContentSelection.SUMMARY_BY_PRODUCT ? "secondary" : "default"}
        onClick={() => selectContent(ContentSelection.SUMMARY_BY_PRODUCT)}
      >
        {intl.formatMessage({defaultMessage: "Sum. pr. vare"})}
      </Button>
    </ButtonGroup>
  );
}
