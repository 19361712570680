import {EffectCallback, useEffect, useState} from "react";
import {useIsOnline} from "react-use-is-online";

export function useReconnectEffect(effect: EffectCallback): void {
  const {isOnline} = useIsOnline();
  const [wasOffline, setWasOffline] = useState(!isOnline);

  useEffect(() => {
    let destructor: ReturnType<EffectCallback>;
    if (!isOnline) {
      setWasOffline(true);
    } else if (wasOffline) {
      destructor = effect();
      setWasOffline(false);
    }
    return () => {
      if (destructor) {
        destructor();
      }
    };
  }, [isOnline, wasOffline, effect]);

  return;
}
