import {MenuToolbar, PageLayout} from "app-components";
import React from "react";
import {useIntl} from "react-intl";
import {Crops} from "./crops";

interface CropsSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const CropsSettingsPage = React.memo(function CropsSettingsPage(
  props: CropsSettingsPageProps,
): React.JSX.Element {
  const intl = useIntl();

  return (
    <PageLayout
      toolbar={
        <MenuToolbar
          onMenuButton={props.onMenuButton}
          title={intl.formatMessage({defaultMessage: "Afgrøder"})}
        />
      }
      withBottomScrollPadding
    >
      <Crops />
    </PageLayout>
  );
});
