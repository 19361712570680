import {Task, TimerStart} from "@co-common-libs/resources";
import {WorkStatusIcon} from "@co-frontend-libs/components";
import {getCustomerLookup, getCustomerSettings, getOrderLookup} from "@co-frontend-libs/redux";
import {computeTaskStatus} from "app-utils";
import React from "react";
import {useSelector} from "react-redux";

interface TaskStatusIconProps {
  task: Task;
  timerStartArray: readonly TimerStart[];
}

export const TaskStatusIcon = React.memo(function TaskStatusIcon(
  props: TaskStatusIconProps,
): React.JSX.Element | null {
  const {task, timerStartArray} = props;
  const orderLookup = useSelector(getOrderLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const status = computeTaskStatus(
    task,
    timerStartArray,
    orderLookup,
    customerLookup,
    customerSettings.economicSync,
  );
  if (status) {
    return <WorkStatusIcon status={status} />;
  } else {
    return null;
  }
});
