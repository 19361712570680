import {
  Patch,
  PatchOperation,
  PathPatchOperation,
  ProductUrl,
  ReportingSpecification,
  Task,
  TimerUrl,
} from "@co-common-libs/resources";
import {
  getAutoProductsForProductGroup,
  getAutoProductsMapping,
  getProductGroupsWithAutoSupplementingProducts,
  getProductsWithLogData,
  patchFromProductUsesChange,
  sortProductUseListByCatalogNumber,
  updateProductUseEntriesOrder,
} from "@co-common-libs/resources-utils";
import {notNull, notUndefined} from "@co-common-libs/utils";
import {
  actions,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getPriceGroupLookup,
  getProductArray,
  getProductGroupArray,
  getProductGroupLookup,
  getProductLookup,
  getProductUseLogArray,
  getReportingSpecificationLookup,
  getTimerLookup,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {getReadonlyProductsFromTask, getRelevantPriceGroupSet, readProductUseLog} from "app-utils";
import _ from "lodash";
import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {ProductTable} from "./product-table";

interface TaskProductTableProps {
  hasActivity: boolean;
  productDialogTimerMinutesMap?: ReadonlyMap<TimerUrl, number> | undefined;
  reportingSpecification?: ReportingSpecification | undefined;
  task: Task;
}

export const TaskProductTable = React.memo(function TaskProductTable(
  props: TaskProductTableProps,
): React.JSX.Element {
  const {hasActivity, productDialogTimerMinutesMap, task} = props;
  const dispatch = useDispatch();
  const customerSettings = useSelector(getCustomerSettings);
  const productLookup = useSelector(getProductLookup);
  const unitLookup = useSelector(getUnitLookup);
  const role = useSelector(getCurrentRole);
  const userUrl = useSelector(getCurrentUserURL);
  const productGroupArray = useSelector(getProductGroupArray);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const productArray = useSelector(getProductArray);
  const currentUserUrl = useSelector(getCurrentUserURL);
  const productUseLogArray = useSelector(getProductUseLogArray);
  const timerLookup = useSelector(getTimerLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);

  const isTaskResponsible = task.machineOperator === userUrl;
  const isSeniorMachineOperator = !!role && role.seniorMachineOperator;
  const isManager = !!role && role.manager;
  const allowEditAsTaskResponsible = isTaskResponsible && !task.completed;
  const allowEditAsSenior = isSeniorMachineOperator && !hasActivity;
  const allowEditAsManager = isManager && !task.validatedAndRecorded;
  const readonly = !allowEditAsTaskResponsible && !allowEditAsSenior && !allowEditAsManager;

  const {priceItemUses, productUses, reportingLog, url} = task;
  const readonlyProducts = useMemo(
    (): Set<string> =>
      getReadonlyProductsFromTask(task, productLookup, unitLookup, reportingSpecificationLookup),
    [productLookup, reportingSpecificationLookup, task, unitLookup],
  );
  const productsWithLogData = useMemo(
    () => (!task.logSkipped ? getProductsWithLogData(task) : undefined),
    [task],
  );

  const handleCountChange = useCallback(
    (identifier: string, value: number | null): void => {
      const patch: Patch<Task> = [{path: ["productUses", identifier, "count"], value}];
      dispatch(actions.update(url, patch));
    },
    [dispatch, url],
  );

  const handleNotesChange = useCallback(
    (identifier: string, value: string): void => {
      const patch: Patch<Task> = [{path: ["productUses", identifier, "notes"], value}];
      dispatch(actions.update(url, patch));
    },
    [dispatch, url],
  );

  const handleOursChange = useCallback(
    (identifier: string, value: boolean): void => {
      const patch: Patch<Task> = [{path: ["productUses", identifier, "ours"], value}];
      dispatch(actions.update(url, patch));
    },
    [dispatch, url],
  );

  const handleDeleteClick = useCallback(
    (identifier: string): void => {
      const productUse = (productUses || {})[identifier];
      console.assert(productUse.count === null);
      const patch: PathPatchOperation[] = [];
      // remove from all log entry productuses for this product
      for (const [logEntryIndentifier, logEntry] of Object.entries(reportingLog || {})) {
        for (const [logProductUseIdentifier, logProductUse] of Object.entries(
          logEntry.productUses || {},
        )) {
          if (logProductUse.product === productUse.product) {
            console.assert(logProductUse.count === null);
            patch.push({
              path: ["reportingLog", logEntryIndentifier, "productUses", logProductUseIdentifier],
              value: undefined,
            });
          }
        }
      }
      patch.push({path: ["productUses", identifier], value: undefined});
      dispatch(actions.update(url, patch));
    },
    [dispatch, productUses, reportingLog, url],
  );

  const handleSwitchProduct = useCallback(
    (identifier: string, productUrl: ProductUrl): void => {
      if (!productUses) {
        return;
      }
      const currentEntry = productUses[identifier];
      if (!currentEntry) {
        return;
      }
      if (productUrl === currentEntry.product) {
        return;
      }
      if (task.reportingSpecification && !task.logSkipped) {
        // task with log
        const {ours, product: oldProduct} = currentEntry;
        // product combined with ours/theirs *must* be unique when using log
        if (
          Object.entries(productUses).some(
            ([otherIdentifier, otherEntry]) =>
              otherEntry.product === productUrl &&
              otherEntry.ours === ours &&
              otherIdentifier !== identifier,
          )
        ) {
          // other product use entry with that product and same ours/theirs already present
          return;
        }
        if (customerSettings.autoSupplementingProducts) {
          // product with autoSupplementingProducts not supported when using log
          const blockProductGroupUrls = getProductGroupsWithAutoSupplementingProducts(
            customerSettings.autoSupplementingProducts,
            productGroupArray,
          );
          const product = productLookup(productUrl);
          if (product && product.group && blockProductGroupUrls.has(product.group)) {
            return;
          }
        }
        const patch: PatchOperation<Task>[] = [
          {path: ["productUses", identifier, "product"], value: productUrl},
        ];
        const resortedProductUses = sortProductUseListByCatalogNumber(
          {
            ...productUses,
            [identifier]: {...currentEntry, product: productUrl},
          },
          productLookup,
        );
        for (const [entryIdentifier, entry] of Object.entries(resortedProductUses)) {
          if (entry.order !== productUses[entryIdentifier].order) {
            patch.push({
              path: ["productUses", entryIdentifier, "order"],
              value: entry.order,
            });
          }
        }
        for (const [logEntryIndentifier, logEntry] of Object.entries(reportingLog || {})) {
          if (!logEntry.productUses) {
            continue;
          }
          const logProductUseEntryToChange = Object.entries(logEntry.productUses).find(
            ([_logProductUseIdentifier, logProductUse]) =>
              logProductUse.product === oldProduct && logProductUse.ours === ours,
          );
          if (!logProductUseEntryToChange) {
            continue;
          }
          const [logProductUseIdentifierToChange, logProductUseToChange] =
            logProductUseEntryToChange;
          patch.push({
            path: [
              "reportingLog",
              logEntryIndentifier,
              "productUses",
              logProductUseIdentifierToChange,
              "product",
            ],
            value: productUrl,
          });
          const resortedLogEntryProductUses = sortProductUseListByCatalogNumber(
            {
              ...logEntry.productUses,
              [logProductUseIdentifierToChange]: {
                ...logProductUseToChange,
                product: productUrl,
              },
            },
            productLookup,
          );
          for (const [logProductUseIdentifier, logProductUse] of Object.entries(
            resortedLogEntryProductUses,
          )) {
            if (logProductUse.order !== logEntry.productUses[logProductUseIdentifier].order) {
              patch.push({
                path: [
                  "reportingLog",
                  logEntryIndentifier,
                  "productUses",
                  logProductUseIdentifier,
                  "order",
                ],
                value: logProductUse.order,
              });
            }
          }

          for (const [logProductUseIdentifier, logProductUse] of Object.entries(
            logEntry.productUses || {},
          )) {
            if (logProductUse.product === oldProduct && logProductUse.ours === ours) {
              patch.push({
                path: [
                  "reportingLog",
                  logEntryIndentifier,
                  "productUses",
                  logProductUseIdentifier,
                  "product",
                ],
                value: productUrl,
              });
            }
          }
        }
        dispatch(actions.update(url, patch));
      } else {
        // task without log
        if (!customerSettings.allowDuplicateProductUses) {
          // when allowDuplicateProductUses is false, and we don't have
          // ours/theirs special case from log), product must be unique
          if (
            Object.entries(productUses).some(
              ([otherIdentifier, otherEntry]) =>
                otherEntry.product === productUrl && otherIdentifier !== identifier,
            )
          ) {
            // other product use entry with that product already present
            return;
          }
        }
        if (customerSettings.autoSupplementingProducts) {
          const product = productLookup(productUrl);
          const oldProduct = productLookup(currentEntry.product);
          if (!product || !oldProduct) {
            // missing information to handle autoSupplementingProducts...
            return;
          }
          const productGroup = product.group ? productGroupLookup(product.group) : null;
          const oldProductGroup = oldProduct.group ? productGroupLookup(oldProduct.group) : null;
          if (!productGroup || !oldProductGroup) {
            // missing information to handle autoSupplementingProducts...
            return;
          }
          const {autoLinesToLines: oldAutoLinesToLines, linesToAutoLines: oldLinesToAutoLines} =
            getAutoProductsMapping(
              productUses,
              productLookup,
              productGroupLookup,
              customerSettings,
            );
          const autoProducts = getAutoProductsForProductGroup(
            customerSettings.autoSupplementingProducts,
            productGroup,
            productArray,
          );
          const oldAutoProducts = getAutoProductsForProductGroup(
            customerSettings.autoSupplementingProducts,
            oldProductGroup,
            productArray,
          );
          const newProductUses = {
            ...productUses,
            [identifier]: {...currentEntry, product: productUrl},
          };
          const oldAutoLines = oldLinesToAutoLines?.get(identifier);
          if (oldAutoLines) {
            for (const autoIdentifier of oldAutoLines) {
              const sourcesForAutoIdentifiers = oldAutoLinesToLines?.get(
                autoIdentifier,
              ) as string[];
              console.assert(sourcesForAutoIdentifiers);
              console.assert(sourcesForAutoIdentifiers.includes(identifier));
              if (
                sourcesForAutoIdentifiers.length === 1 &&
                !autoProducts.has(productUses[autoIdentifier].product)
              ) {
                // autoProduct owned by this line and not present for new product
                delete newProductUses[autoIdentifier];
              }
            }
          }
          autoProducts.forEach((autoProductUrl) => {
            if (oldAutoProducts.has(autoProductUrl)) {
              // no change for this
              return;
            }
            if (
              customerSettings.allowDuplicateProductUses ||
              !Object.values(newProductUses).some(
                (productUse) => productUse.product === autoProductUrl,
              )
            ) {
              // absent or needs to be present per using product
              newProductUses[uuid()] = {
                addedBy: currentUserUrl,
                correctedCount: null,
                count: currentEntry.count,
                notes: "",
                order: (_.max(Object.values(newProductUses).map(({order}) => order)) || 0) + 1,
                ours: true,
                product: autoProductUrl,
              };
            }
          });
          const sortedNewProductUses = sortProductUseListByCatalogNumber(
            newProductUses,
            productLookup,
          );
          const {autoLinesToLines, linesToAutoLines} = getAutoProductsMapping(
            sortedNewProductUses,
            productLookup,
            productGroupLookup,
            customerSettings,
          );
          if (autoLinesToLines && linesToAutoLines) {
            // recompute counts and notes for autolines...
            autoLinesToLines.forEach((sourceLineIdentifiers, autoLineIdentifier) => {
              const autoLine = sortedNewProductUses[autoLineIdentifier];
              const sourceEntries = sourceLineIdentifiers.map(
                (sourceLineIdentifier) => sortedNewProductUses[sourceLineIdentifier],
              );
              const counts = sourceEntries.map(({count}) => count);
              const notNullCounts = counts.filter(notNull);
              const count = notNullCounts.length === 0 ? null : _.sum(notNullCounts);
              const notes = customerSettings.autoCopyMaterialNoteToSupplementingProductNote
                ? sourceEntries
                    .map(({notes: entryNotes}) => entryNotes)
                    .filter((str) => str !== "")
                    .join(" ")
                : "";
              sortedNewProductUses[autoLineIdentifier] = {
                ...autoLine,
                count,
                notes,
              };
            });
            if (customerSettings.allowDuplicateProductUses) {
              // each source line will have a separate autoline then...
              const entriesWithAutoLinesInlined = Object.entries(sortedNewProductUses).flatMap(
                (entry) => {
                  const [entryIdentifier /* productUse */] = entry;
                  if (autoLinesToLines.has(entryIdentifier)) {
                    console.assert(autoLinesToLines.get(entryIdentifier)?.length === 1);
                    return [];
                  }
                  const autoLineIdentifiers = linesToAutoLines.get(entryIdentifier);
                  if (!autoLineIdentifiers) {
                    return [entry];
                  }
                  const associatedAutoLines = autoLineIdentifiers.map(
                    (autoIdentifier) =>
                      [autoIdentifier, sortedNewProductUses[autoIdentifier]] as const,
                  );
                  const sortedAssociatedAutoLines = _.sortBy(
                    associatedAutoLines,
                    ([_identifier, autoProductUse]) => autoProductUse.order,
                  );
                  return [entry, ...sortedAssociatedAutoLines];
                },
              );
              updateProductUseEntriesOrder(entriesWithAutoLinesInlined);
              const newValueWithAutoLines = Object.fromEntries(entriesWithAutoLinesInlined);
              const patch = patchFromProductUsesChange(productUses, newValueWithAutoLines);
              dispatch(actions.update(url, patch));
            } else {
              const patch = patchFromProductUsesChange(productUses, sortedNewProductUses);
              dispatch(actions.update(url, patch));
            }
          } else {
            const patch = patchFromProductUsesChange(productUses, sortedNewProductUses);
            dispatch(actions.update(url, patch));
          }
        } else {
          const patch: PatchOperation<Task>[] = [
            {path: ["productUses", identifier, "product"], value: productUrl},
          ];
          const resorted = sortProductUseListByCatalogNumber(
            {
              ...productUses,
              [identifier]: {...currentEntry, product: productUrl},
            },
            productLookup,
          );
          for (const [entryIdentifier, entry] of Object.entries(resorted)) {
            if (entry.order !== productUses[entryIdentifier].order) {
              patch.push({
                path: ["productUses", entryIdentifier, "order"],
                value: entry.order,
              });
            }
          }
          dispatch(actions.update(url, patch));
        }
      }
    },
    [
      currentUserUrl,
      customerSettings,
      dispatch,
      productArray,
      productGroupArray,
      productGroupLookup,
      productLookup,
      productUses,
      reportingLog,
      task.logSkipped,
      task.reportingSpecification,
      url,
    ],
  );

  const productDialogPreferredProductURLs = useMemo(
    () =>
      customerSettings.enableRecentlyUsedProducts
        ? readProductUseLog(productUseLogArray, productLookup, task)
        : undefined,
    [customerSettings.enableRecentlyUsedProducts, productLookup, productUseLogArray, task],
  );
  const productDialogPriceGroups = useMemo(
    () =>
      Array.from(
        getRelevantPriceGroupSet(task, {
          timerLookup,
          timerMinutesMap: productDialogTimerMinutesMap ?? new Map(),
        }),
      )
        .map(priceGroupLookup)
        .filter(notUndefined),
    [priceGroupLookup, productDialogTimerMinutesMap, task, timerLookup],
  );
  const productDialogWorkType = useMemo(
    () =>
      !customerSettings.noExternalTaskWorkType && task?.workType
        ? workTypeLookup(task.workType)
        : undefined,
    [customerSettings.noExternalTaskWorkType, task.workType, workTypeLookup],
  );

  return (
    <ProductTable
      onCountChange={handleCountChange}
      onDeleteClick={handleDeleteClick}
      onNotesChange={handleNotesChange}
      onOursChange={handleOursChange}
      onSwitchProduct={handleSwitchProduct}
      priceItemUses={priceItemUses || {}}
      productDialogPreferredProductURLs={productDialogPreferredProductURLs}
      productDialogPriceGroups={productDialogPriceGroups}
      productDialogWorkType={productDialogWorkType}
      productsWithLogData={productsWithLogData}
      productUses={productUses || {}}
      readonly={readonly}
      readonlyProducts={readonlyProducts}
      showNotes={customerSettings.enableMaterialNoteFields}
      withSwitchProduct
    />
  );
});
