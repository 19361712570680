import {
  Crop,
  Customer,
  emptyLocation,
  Location,
  Patch,
  PatchOperation,
  urlToId,
} from "@co-common-libs/resources";
import {
  COLORS,
  SpinnerDialog,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  getCropColorMap,
  getCustomerSettings,
  getLocationArray,
  getLocationTypeArray,
} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  ResponseWithData,
  useCallWithFalse,
  useCallWithTrue,
} from "@co-frontend-libs/utils";
import bowser from "bowser";
import {globalConfig, instanceURL} from "frontend-global-config";
import JSZip from "jszip";
import _ from "lodash";
import EarthIcon from "mdi-react/EarthIcon";
import FileUploadIcon from "mdi-react/FileUploadIcon";
import SyncIcon from "mdi-react/SyncIcon";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import shp, {ShpJSBuffer} from "shpjs";
import {v4 as uuid} from "uuid";
import {FetchFieldsDialog} from "./fetch-fields-dialog";
import {FieldTable} from "./fields-table";
import {
  convertZIPFieldFeatures,
  LocationPart,
  OnlineFieldLocationPart,
  ZipFieldLocationPart,
} from "./utils";
import {ZipFieldsDialog} from "./zip-fields-dialog";

const OFFICIAL_ZIP_MIME_TYPE = "application/zip";
const WINDOWS_ZIP_MIME_TYPE = "application/x-zip-compressed";

const messages = defineMessages({
  invalidFile: {
    defaultMessage: "Ikke en tilladt filtype: {name}",
    id: "field-list.toast.invalid-file",
  },
});

const fileArrayBufferPromise = (file: File): Promise<ArrayBuffer> => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsArrayBuffer(file);
  });
};

interface FieldsListProps {
  customer: Customer | null;
}

function refetchFields(
  customerUrl: string | null,
  abortController: AbortController | undefined,
): Promise<ResponseWithData> {
  const url = `${globalConfig.baseURL}/api/remote_fields_update/`;
  const data = customerUrl ? {customerId: urlToId(customerUrl)} : {customerId: null};
  return jsonFetch(url, "POST", data, abortController?.signal);
}

export function FieldList(props: FieldsListProps): React.JSX.Element {
  const {customer} = props;
  const customerURL = customer ? customer.url : null;

  const customerSettings = useSelector(getCustomerSettings);
  const locationTypeArray = useSelector(getLocationTypeArray);
  const [refetching, setRefetching] = useState(false);

  const [fetchFieldsDialogOpen, setFetchFieldsDialogOpen] = useState(false);
  const setFetchFieldsDialogOpenTrue = useCallWithTrue(setFetchFieldsDialogOpen);
  const setFetchFieldsDialogOpenFalse = useCallWithFalse(setFetchFieldsDialogOpen);

  const locationArray = useSelector(getLocationArray);

  const [zipFileLocations, setZipFileLocations] = useState<ZipFieldLocationPart[]>([]);

  const abortController = useRef<AbortController>();

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  const dispatch = useDispatch();

  const handleDelete = useCallback(
    (locationURL: string) => {
      dispatch(actions.update(locationURL, [{member: "active", value: false}]));
    },
    [dispatch],
  );

  const addCropIfNeeded = useCallback(
    (name: string, colorMap: ReadonlyMap<string, string>): ReadonlyMap<string, string> => {
      if (colorMap.has(name)) {
        return colorMap;
      }
      const id = uuid();
      const url = instanceURL("crop", id);
      const colorMapCopy = new Map(colorMap);
      colorMapCopy.set(name, "#0084ff");
      const newCrop: Crop = {
        color: COLORS[colorMapCopy.size % COLORS.length],
        id,
        name,
        url,
      };
      dispatch(actions.create(newCrop));
      return colorMapCopy;
    },
    [dispatch],
  );

  const cropColorMap = useSelector(getCropColorMap);

  const handleImport = useCallback(
    (locations: LocationPart[]) => {
      let localColorMap = cropColorMap;
      const matchMapping = new Map<string, Location>();
      locationArray.forEach((location) => {
        if (location.customer !== customerURL || !location.fieldNumber) {
          return;
        }
        if (location.fieldBlock) {
          matchMapping.set(`${location.fieldBlock} \n---\n ${location.fieldNumber}`, location);
        } else {
          matchMapping.set(location.fieldNumber, location);
        }
      });
      const now = new Date().toISOString();
      locations.forEach((location) => {
        const existing =
          matchMapping.get(`${location.fieldBlock} \n---\n ${location.fieldNumber}`) ||
          matchMapping.get(location.fieldNumber);
        if (existing) {
          let newCoordinates = location.geojson?.geometry.coordinates;
          let existingCoordiates = existing.geojson?.geometry.coordinates;
          if (newCoordinates && existingCoordiates) {
            newCoordinates = newCoordinates.slice().sort();
            existingCoordiates = existingCoordiates.slice().sort();
          }
          if (
            location.fieldNumber !== existing.fieldNumber ||
            location.fieldJournalNumber !== existing.fieldJournalNumber ||
            location.fieldRecordYear !== existing.fieldRecordYear ||
            location.fieldBlock !== existing.fieldBlock ||
            location.fieldFromUpload !== existing.fieldFromUpload ||
            location.fieldAreaHa !== existing.fieldAreaHa ||
            location.fieldCrop !== existing.fieldCrop ||
            (location.fieldVatNumber || "") !== existing.fieldVatNumber ||
            !_.isEqual(newCoordinates, existingCoordiates)
          ) {
            const patch: PatchOperation<Location>[] = [];
            (Object.keys(location) as (keyof LocationPart)[]).forEach((key) => {
              patch.push({member: key, value: location[key] as any});
            });
            patch.push(
              {member: "active", value: true},
              {member: "customer", value: customerURL},
              {member: "fieldDataChanged", value: now},
            );
            dispatch(actions.update(existing.url, patch));
          } else if (!existing.active) {
            const patch: Patch<Location> = [{member: "active", value: true}];
            dispatch(actions.update(existing.url, patch));
          }
        } else {
          const id = uuid();
          const url = instanceURL("location", id);
          dispatch(
            actions.create({
              id,
              url,
              ...emptyLocation,
              ...location,
              customer: customerURL,
              fieldDataChanged: now,
            }),
          );
        }
        localColorMap = addCropIfNeeded(location.fieldCrop, localColorMap);
      });
    },
    [addCropIfNeeded, cropColorMap, customerURL, dispatch, locationArray],
  );

  const handleOnlineImport = useCallback(
    (locations: OnlineFieldLocationPart[]): void => {
      handleImport(locations);
      setFetchFieldsDialogOpen(false);
    },
    [handleImport],
  );

  const handleRefetchResponse = useCallback(
    (response: ResponseWithData) => {
      setRefetching(false);
      const locations: Location[] = response.data;
      dispatch(actions.addToOffline(locations));
      abortController.current = undefined;
    },
    [dispatch],
  );

  const handleRefetchError = useCallback((error: Error) => {
    setRefetching(false);
    // eslint-disable-next-line no-console
    console.error(error);
    abortController.current = undefined;
  }, []);

  const handleRefetchFieldsButtonClick = useCallback(() => {
    if (window.AbortController) {
      if (abortController.current) {
        abortController.current.abort();
      }
      abortController.current = new AbortController();
    }
    const refetchFieldsPromise = refetchFields(customerURL, abortController.current);
    setRefetching(true);
    return refetchFieldsPromise.then(handleRefetchResponse, handleRefetchError);
  }, [customerURL, handleRefetchError, handleRefetchResponse]);

  const {formatMessage} = useIntl();

  const handleFileDropAccepted = useCallback(
    (files: readonly File[]): void => {
      const fieldLocationType = customerSettings.fieldDefaultLocationType
        ? locationTypeArray.find(
            (locationType) => locationType.identifier === customerSettings.fieldDefaultLocationType,
          )
        : null;

      files.forEach((file) => {
        return fileArrayBufferPromise(file)
          .then(async (arrayBuffer) => {
            const zip = await JSZip.loadAsync(arrayBuffer);

            const shpFile = Object.keys(zip.files).find((name) => name.endsWith(".shp"));
            const dbfFile = Object.keys(zip.files).find((name) => name.endsWith(".dbf"));
            const prjFile = Object.keys(zip.files).find((name) => name.endsWith(".prj"));
            const cpgFile = Object.keys(zip.files).find((name) => name.endsWith(".cpg"));

            if (!shpFile || !dbfFile) {
              throw new Error("Zip file must contain both .shp and .dbf files");
            }

            const shpFileEntry = zip.file(shpFile);
            if (!shpFileEntry) {
              throw new Error("Shapefile entry not found in zip");
            }
            const shpBuffer = await shpFileEntry.async("arraybuffer");
            const dbfFileEntry = zip.file(dbfFile);
            if (!dbfFileEntry) {
              throw new Error("DBF file entry not found in zip");
            }
            const dbfBuffer = await dbfFileEntry.async("arraybuffer");
            const prjBuffer = prjFile ? await zip.file(prjFile)?.async("string") : undefined;

            let encoding = "windows-1252";
            if (cpgFile) {
              const cpgFileEntry = zip.file(cpgFile);
              if (cpgFileEntry) {
                const cpgContent = await cpgFileEntry.async("string");
                encoding = cpgContent.trim();
              }
            }

            const geometries = shp.parseShp(shpBuffer, prjBuffer);
            const properties = shp.parseDbf(dbfBuffer, encoding as unknown as ShpJSBuffer);
            const geoJSON = shp.combine([geometries, properties]);

            return geoJSON;
          })
          .then((geoJSON) => {
            const locations = convertZIPFieldFeatures(geoJSON, fieldLocationType?.url ?? null);
            setZipFileLocations(locations);
          })
          .catch((error) => {
            console.assert("Error processing zip file:", error);
          });
      });
    },
    [customerSettings.fieldDefaultLocationType, locationTypeArray],
  );

  const handleFileDropRejected = useCallback(
    (files: readonly File[]): void => {
      const message = formatMessage(messages.invalidFile, {
        name: files[0].name,
      });
      window.setTimeout(() => {
        dispatch(actions.setMessage(message, new Date()));
      }, 0);
    },
    [dispatch, formatMessage],
  );

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      const droppedFiles = (e as any).dataTransfer
        ? ((e as any).dataTransfer as DataTransfer).files
        : e.target.files;
      if (!droppedFiles) {
        return;
      }
      const max = droppedFiles.length;
      const files: File[] = [];
      for (let i = 0; i < max; i += 1) {
        const file = droppedFiles[i];
        files.push(file);
      }
      const allFilesAccepted = files.every((file) =>
        [OFFICIAL_ZIP_MIME_TYPE, WINDOWS_ZIP_MIME_TYPE].includes(file.type),
      );
      if (allFilesAccepted) {
        handleFileDropAccepted(files);
      } else {
        handleFileDropRejected(files);
      }
    },
    [handleFileDropAccepted, handleFileDropRejected],
  );

  const handleZipImport = useCallback(
    (locations: ZipFieldLocationPart[]): void => {
      handleImport(locations);
      setZipFileLocations([]);
    },
    [handleImport],
  );

  const handleZipFieldsDialogCancel = useCallback(() => {
    setZipFileLocations([]);
  }, []);
  const intl = useIntl();

  const zipMIMEType = bowser.windows ? WINDOWS_ZIP_MIME_TYPE : OFFICIAL_ZIP_MIME_TYPE;

  return (
    <>
      <FieldTable customerURL={customerURL} onDelete={handleDelete} />
      <VerticalStackingFloatingActionButton
        disabled={refetching}
        onClick={handleRefetchFieldsButtonClick}
        stackIndex={2}
      >
        <SyncIcon className={refetching ? "rotate" : ""} />
      </VerticalStackingFloatingActionButton>
      <>
        <input
          accept={zipMIMEType}
          id="field-zip-input"
          onChange={handleFileInputChange}
          style={{display: "none"}}
          type="file"
        />
        <label htmlFor="field-zip-input">
          <VerticalStackingFloatingActionButton component="span" stackIndex={1}>
            <FileUploadIcon />
          </VerticalStackingFloatingActionButton>
        </label>
      </>
      <VerticalStackingFloatingActionButton onClick={setFetchFieldsDialogOpenTrue} stackIndex={0}>
        <EarthIcon />
      </VerticalStackingFloatingActionButton>
      <FetchFieldsDialog
        initialSearch={customer?.vatNumber || ""}
        onCancel={setFetchFieldsDialogOpenFalse}
        onOk={handleOnlineImport}
        open={fetchFieldsDialogOpen}
      />
      <ZipFieldsDialog
        locations={zipFileLocations}
        onCancel={handleZipFieldsDialogCancel}
        onOk={handleZipImport}
        open={!!zipFileLocations.length}
      />
      <SpinnerDialog
        open={refetching}
        title={intl.formatMessage({
          defaultMessage: "Henter markdata for marker",
        })}
      />
    </>
  );
}
