import {schema} from "@co-common-libs/config";
import {SettingEntry} from "@co-common-libs/resources";
import {TrimTextField} from "@co-frontend-libs/components";
import {actions, getCurrentUserURL, getSettingEntryArray} from "@co-frontend-libs/redux";
import {Button, Card, CardActions, CardContent, useTheme} from "@material-ui/core";
import bowser from "bowser";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {getValidator} from "./utils";

const JSON_INDENTATION_LEVEL = 2;

export function SettingJSON(): React.JSX.Element {
  const settingEntryArray = useSelector(getSettingEntryArray);
  const currentUserURL = useSelector(getCurrentUserURL);

  const currentSettings = useMemo<ReadonlyMap<string, Readonly<SettingEntry>>>(
    () => new Map(settingEntryArray.map((settingEntry) => [settingEntry.key, settingEntry])),
    [settingEntryArray],
  );

  const currentSettingsCombined = useMemo(
    () =>
      _.fromPairs(settingEntryArray.map((settingEntry) => [settingEntry.key, settingEntry.data])),
    [settingEntryArray],
  );

  const savedText = useMemo(
    () => JSON.stringify(currentSettingsCombined, null, JSON_INDENTATION_LEVEL),
    [currentSettingsCombined],
  );

  const [textValue, setTextValue] = useState(() => savedText);

  useEffect(() => {
    setTextValue(savedText);
  }, [savedText]);

  let jsonParseError: string | undefined;
  let editedValue: unknown;
  try {
    editedValue = JSON.parse(textValue);
  } catch (error) {
    if (error && typeof error === "object") {
      const errorObject: Partial<Record<string, unknown>> = error;
      if (
        "message" in errorObject &&
        typeof errorObject.message === "string" &&
        errorObject.message
      ) {
        jsonParseError = errorObject.message;
      } else {
        jsonParseError = "";
      }
    } else {
      jsonParseError = " ";
    }
  }

  const validator = useMemo(() => getValidator(schema), []);
  const schemaErrors = useMemo(
    () => (editedValue !== undefined ? validator(editedValue) : null),
    [editedValue, validator],
  );

  const dispatch = useDispatch();

  const handleSave = useCallback(() => {
    if (typeof editedValue === "object" && editedValue) {
      Object.entries(editedValue).forEach(([key, data]) => {
        const existingSettingEntry = currentSettings.get(key);
        if (existingSettingEntry) {
          if (!_.isEqual(data, existingSettingEntry.data)) {
            dispatch(
              actions.update(existingSettingEntry.url, [
                {member: "changedBy", value: currentUserURL},
                {member: "data", value: data},
              ]),
            );
          }
        }
      });
    }
  }, [currentSettings, currentUserURL, dispatch, editedValue]);

  const theme = useTheme();

  return (
    <div
      style={{
        padding: bowser.mobile || bowser.tablet ? "1em 11px 1em 11px" : "1em",
      }}
    >
      <Card>
        <CardContent>
          <TrimTextField
            autoFocus
            fullWidth
            label="JSON"
            margin="dense"
            multiline
            onChange={setTextValue}
            value={textValue}
            variant="outlined"
          />
          {jsonParseError ? (
            <div style={{color: theme.palette.error.main}}>
              <FormattedMessage
                defaultMessage="Ugyldig JSON: {error}"
                id="system-setup.text.invalid-json"
                values={{error: jsonParseError}}
              />
            </div>
          ) : null}
          {schemaErrors ? (
            <div style={{color: theme.palette.error.main}}>
              <FormattedMessage
                defaultMessage="Schema-issues"
                id="system-setup.header.schema-issues"
                tagName="h3"
              />
              <pre>
                <code>{JSON.stringify(schemaErrors, null, JSON_INDENTATION_LEVEL)}</code>
              </pre>
            </div>
          ) : null}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disabled={!!jsonParseError}
            onClick={handleSave}
            variant="contained"
          >
            <FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
