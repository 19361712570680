import {HOUR_MINUTES, SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {padZero} from "app-utils";
import {bind} from "bind-decorator";
import React, {ReactNode} from "react";
import {defineMessages, IntlContext} from "react-intl";

const messages = defineMessages({
  ok: {
    defaultMessage: "OK ({timeout})",
    id: "dialog.label.ok-timeout",
  },
});

interface TimeoutDialogProps {
  cancelLabel?: string;
  children: ReactNode;
  okLabel?: string;
  onCancel?: () => void;
  onOk: () => void;
  open: boolean;
  timeoutSeconds: number;
  title: string;
}

interface TimeoutDialogState {
  remaining: number;
}

export class TimeoutDialog extends React.Component<TimeoutDialogProps, TimeoutDialogState> {
  static contextType = IntlContext;
  _interval: number | null = null;
  context!: React.ContextType<typeof IntlContext>;
  state = {
    remaining: this.props.timeoutSeconds,
  };
  componentWillUnmount(): void {
    this.stopTimer();
  }
  @bind
  countDown(): void {
    const {remaining} = this.state;
    if (remaining === 1) {
      this.stopTimer();
      this.props.onOk();
    } else {
      this.setState({remaining: remaining - 1});
    }
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {okLabel, timeoutSeconds, ...others} = this.props;
    const {remaining} = this.state;
    const remainingString = `${Math.floor(remaining / HOUR_MINUTES)}:${padZero(
      remaining % HOUR_MINUTES,

      2,
    )}`;
    const renderedOkLabel = formatMessage(messages.ok, {
      timeout: remainingString,
    });
    return <ResponsiveDialog okLabel={renderedOkLabel} {...others} />;
  }
  startTimer(): void {
    this.stopTimer();
    this._interval = window.setInterval(this.countDown, SECOND_MILLISECONDS);
  }
  stopTimer(): void {
    if (this._interval) {
      window.clearInterval(this._interval);
      this._interval = null;
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: TimeoutDialogProps): void {
    if (nextProps.timeoutSeconds !== this.props.timeoutSeconds) {
      this.setState({remaining: nextProps.timeoutSeconds});
    }
    if (nextProps.open && !this.props.open) {
      this.startTimer();
    } else if (!nextProps.open && this.props.open) {
      this.stopTimer();
    }
  }
}
