import {
  buildCommonOptions,
  buildGroupOptions,
  computeDurationMilliseconds,
  computeIntervalDurationMilliseconds,
  computeWorkdaysFull,
  IntervalTaskData,
  Rate,
  RemunerationGroup,
} from "@co-common-libs/payroll";
import {
  MachineUrl,
  PriceGroupUrl,
  Task,
  TimerUrl,
  UserProfile,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {timerMayBeBilled} from "@co-common-libs/resources-utils";
import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {
  getCustomerSettings,
  getMachineArray,
  getOrderArray,
  getPriceGroupArray,
  getPriceGroupLookup,
  getPriceItemArray,
  getPriceItemLookup,
  getProjectArray,
  getTimerArray,
  getTimerLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {getEmployeeRemunerationGroup} from "./remuneration-computations";
import {getGenericPrimaryTimer} from "./timers";

export const useTaskSums = (
  taskList: readonly Task[],
  userProfile?: UserProfile | undefined,
): {
  breakMinutes: number;
  effectiveMinutes: number;
  efficiencyPercent: number;
  firstStart: string | undefined;
  internalMinutes: number;
  lastEnd: string | undefined;
  paidMinutes: number;
  startEndMinutes: number;
  totalWorkMinutes: number;
  totalWorkPeriodMinutes: number;
  unpaidMinutes: number;
} => {
  const customerSettings = useSelector(getCustomerSettings);
  const workTypeArray = useSelector(getWorkTypeArray);
  const machineArray = useSelector(getMachineArray);
  const priceGroupArray = useSelector(getPriceGroupArray);
  const timerArray = useSelector(getTimerArray);
  const orderArray = useSelector(getOrderArray);
  const projectArray = useSelector(getProjectArray);
  const priceItemArray = useSelector(getPriceItemArray);
  const timerLookup = useSelector(getTimerLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);

  const timerMap = useMemo(() => new Map(timerArray.map((t) => [t.url, t])), [timerArray]);
  const orderMap = useMemo(() => new Map(orderArray.map((o) => [o.url, o])), [orderArray]);
  const projectMap = useMemo(() => new Map(projectArray.map((o) => [o.url, o])), [projectArray]);
  const priceItemMap = useMemo(
    () => new Map(priceItemArray.map((o) => [o.url, o])),
    [priceItemArray],
  );

  const primaryTimer = getGenericPrimaryTimer(timerArray);
  const primaryTimerURL = primaryTimer ? primaryTimer.url : "";

  const intervalMayBeBilled = useCallback(
    (t: IntervalTaskData): boolean =>
      !!t.customerTask &&
      !!t.timerURL &&
      timerMayBeBilled(
        t.timerURL,
        primaryTimerURL as TimerUrl,
        timerLookup,
        workTypeLookup,
        priceGroupLookup,
        priceItemLookup,
      ),
    [priceGroupLookup, priceItemLookup, primaryTimerURL, timerLookup, workTypeLookup],
  );

  const employeeDefaultRemunerationGroupID = getEmployeeRemunerationGroup(
    customerSettings,
    userProfile,
  );
  const workTypeIDURLMapping: {
    [workTypeID: string]: WorkTypeUrl;
  } = {};
  workTypeArray.forEach((workType) => {
    workTypeIDURLMapping[workType.identifier] = workType.url;
  });
  const machineIDURLMapping: {[machineID: string]: MachineUrl} = {};
  machineArray.forEach((machine) => {
    machineIDURLMapping[machine.c5_machine] = machine.url;
  });
  const priceGroupIDURLMapping: {[priceGroupID: string]: PriceGroupUrl} = {};
  priceGroupArray.forEach((priceGroup) => {
    priceGroupIDURLMapping[priceGroup.identifier] = priceGroup.url;
  });

  const remunerationGroups = new Map<string, RemunerationGroup>();
  Object.entries(customerSettings.remunerationGroups).forEach(([identifier, options]) => {
    const x = buildGroupOptions(
      options as any,
      customerSettings,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
      [], //daysAbsenceList
    );
    remunerationGroups.set(identifier, x);
  });

  const commonRemunerationSettings = buildCommonOptions(customerSettings, workTypeIDURLMapping);

  const {remunerationNormalTransportKilometersCountBonusLabel} = customerSettings;

  const workDaysMap = computeWorkdaysFull(
    taskList,
    null, // punchedInOutArray
    timerMap,
    orderMap,
    projectMap,
    priceItemMap,
    [], //daysAbsenceList
    userProfile && userProfile.normalTransportMinutes != null
      ? userProfile.normalTransportMinutes
      : undefined,
    employeeDefaultRemunerationGroupID,
    remunerationGroups,
    commonRemunerationSettings,
    remunerationNormalTransportKilometersCountBonusLabel,
    userProfile && userProfile.normalTransportKilometers != null
      ? userProfile.normalTransportKilometers
      : undefined,
  );

  let firstStart: string | undefined;
  let lastEnd: string | undefined;
  taskList.forEach((task) => {
    const taskWorkFromTimestamp = task.workFromTimestamp;
    const taskWorkToTimestamp = task.workToTimestamp;
    if (taskWorkFromTimestamp && (!firstStart || taskWorkFromTimestamp < firstStart)) {
      firstStart = taskWorkFromTimestamp;
    }
    if (taskWorkToTimestamp && (!lastEnd || taskWorkToTimestamp > lastEnd)) {
      lastEnd = taskWorkToTimestamp;
    }
  });

  let paidMilliseconds = 0;
  let unpaidMilliseconds = 0;
  let effectiveMilliseconds = 0;
  let totalWorkPeriodMilliseconds = 0;

  workDaysMap.forEach((workDays) => {
    for (let i = 0; i < workDays.length; i += 1) {
      const workDay = workDays[i];
      const {workPeriods} = workDay;
      for (let j = 0; j < workPeriods.length; j += 1) {
        const workPeriod = workPeriods[j];
        totalWorkPeriodMilliseconds += computeDurationMilliseconds(
          new Date(workPeriod.firstFromTimestamp),
          new Date(workPeriod.lastToTimestamp),
        );
        const {work} = workPeriod;
        for (let k = 0; k < work.length; k += 1) {
          const workInterval = work[k];
          const intervalMilliseconds = computeIntervalDurationMilliseconds(workInterval);
          if (workInterval.rate === Rate.UNPAID) {
            unpaidMilliseconds += intervalMilliseconds;
          } else {
            paidMilliseconds += intervalMilliseconds;
          }
          if (!workInterval.unregistered && workInterval.taskData.some(intervalMayBeBilled)) {
            effectiveMilliseconds += intervalMilliseconds;
          }
        }
      }
    }
  });

  let startEndMinutes = 0;
  if (firstStart && lastEnd) {
    const startToEndPeriodMilliseconds =
      new Date(lastEnd).valueOf() - new Date(firstStart).valueOf();

    startEndMinutes = Math.round(startToEndPeriodMilliseconds / MINUTE_MILLISECONDS);
    if (startToEndPeriodMilliseconds > totalWorkPeriodMilliseconds) {
      totalWorkPeriodMilliseconds = startToEndPeriodMilliseconds;
    }
  }

  const paidMinutes = Math.round(paidMilliseconds / MINUTE_MILLISECONDS);
  const unpaidMinutes = Math.round(unpaidMilliseconds / MINUTE_MILLISECONDS);
  const totalWorkMinutes = paidMinutes + unpaidMinutes;
  const effectiveMinutes = Math.round(effectiveMilliseconds / MINUTE_MILLISECONDS);

  const internalMinutes = totalWorkMinutes - effectiveMinutes;

  const totalWorkPeriodMinutes = Math.round(totalWorkPeriodMilliseconds / MINUTE_MILLISECONDS);

  const breakMinutes = totalWorkPeriodMinutes - totalWorkMinutes;

  const efficiencyPercent = Math.round((effectiveMinutes / totalWorkMinutes) * 100) || 0;

  return {
    breakMinutes,
    effectiveMinutes,
    efficiencyPercent,
    firstStart,
    internalMinutes,
    lastEnd,
    paidMinutes,
    startEndMinutes,
    totalWorkMinutes,
    totalWorkPeriodMinutes,
    unpaidMinutes,
  };
};
