import {PunchInOut, UserUrl} from "@co-common-libs/resources";
import {buildEmployeeTimeline, PunchWorkPeriod} from "@co-common-libs/resources-utils";

export function getWorkPeriodsPerEmployee(
  punchInOutArray: readonly PunchInOut[],
): Map<string, PunchWorkPeriod[]> {
  const punchInOutPerEmployee = new Map<string, PunchInOut[]>();
  for (let i = 0; i < punchInOutArray.length; i += 1) {
    const entry = punchInOutArray[i];
    const key = entry.employee;
    const existing = punchInOutPerEmployee.get(key);
    if (existing) {
      existing.push(entry);
    } else {
      punchInOutPerEmployee.set(key, [entry]);
    }
  }
  const result = new Map<string, PunchWorkPeriod[]>();
  punchInOutPerEmployee.forEach((employeePunchInOutArray, employeeURL) => {
    const employeeTimeline = buildEmployeeTimeline(employeePunchInOutArray);
    if (employeeTimeline.length) {
      result.set(employeeURL, employeeTimeline);
    }
  });
  return result;
}

export function filterOnEmployee<T extends {readonly employee: UserUrl}>(
  employeeURL: UserUrl | null,
  entries: readonly T[],
): readonly T[] {
  if (!employeeURL) {
    return [];
  } else {
    return entries.filter((e) => e.employee === employeeURL);
  }
}
