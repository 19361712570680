import {UserUrl} from "@co-common-libs/resources";
import {
  getAccumulatedCompensatoryArray,
  getCustomerSettings,
  getUserLookup,
  getUserRoleLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {Grid} from "@material-ui/core";
import bowser from "bowser";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {AccumulatedCompensatoryHistoryCard} from "./accumulated-compensatory-history-card";
import {CostCard} from "./cost-card";
import {SalaryCard} from "./salary-card";

export const SalaryTab = React.memo(function SalaryTab({
  userUrl,
}: {
  userUrl: UserUrl;
}): React.JSX.Element | null {
  const padding = bowser.mobile || bowser.tablet ? "1em 11px 1em 11px" : "1em";
  const userLookup = useSelector(getUserLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const userRoleLookup = useSelector(getUserRoleLookup);
  const user = userLookup(userUrl);
  const userProfile = userUserProfileLookup(userUrl);
  const userRoles = userRoleLookup(userUrl);
  const accumulatedCompensatoryArray = useSelector(getAccumulatedCompensatoryArray);
  const customerSettings = useSelector(getCustomerSettings);

  const userAccumulatedCompensatoryArray = useMemo(
    () => accumulatedCompensatoryArray.filter((entry) => entry.employee === userUrl),
    [accumulatedCompensatoryArray, userUrl],
  );

  if (!user || !userProfile || !userRoles) {
    return null;
  }
  const remunerationGroup =
    customerSettings.remunerationGroups[
      userProfile.remunerationGroup || customerSettings.remunerationDefaultGroup
    ];
  const showAccumulatedCompensatoryHistory =
    userAccumulatedCompensatoryArray.length || remunerationGroup?.compensatoryMultiplier;

  return (
    <div style={{padding}}>
      <Grid container spacing={2}>
        <Grid container item sm={6} spacing={2} xs={12}>
          <Grid item xs={12}>
            <SalaryCard userProfile={userProfile} />
          </Grid>
          {customerSettings.enableMachineAnalysis ? (
            <Grid item xs={12}>
              <CostCard userProfile={userProfile} />
            </Grid>
          ) : null}
        </Grid>
        {showAccumulatedCompensatoryHistory ? (
          <Grid item sm={6} xs={12}>
            <AccumulatedCompensatoryHistoryCard
              user={user}
              userAccumulatedCompensatoryArray={userAccumulatedCompensatoryArray}
              userProfile={userProfile}
            />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
});
