import {
  JournalDataEntry,
  JournalSummarizedDataByProductEntry,
  JournalSummarizedDataEntry,
} from "@co-common-libs/report-rendering";
import {LocationUrl, urlToId} from "@co-common-libs/resources";
import {getCurrentUserProfile, getLocationLookup, getShareToken} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {Grid} from "@material-ui/core";
import {globalConfig} from "frontend-global-config";
import React, {useEffect, useReducer} from "react";
import {IntlShape, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {DisplayStorageJournal} from "./display-storage-journal";

function buildURLs(options: {
  fromDate: string;
  intl: IntlShape;
  invoiceCustomerSet: ReadonlySet<string>;
  locationCustomerSet: ReadonlySet<string>;
  locationUrl: LocationUrl;
  machinePriceGroupsMap: ReadonlyMap<string, ReadonlySet<string>>;
  printedBy: string;
  products: ReadonlySet<string>;
  reportingLocationName: string;
  restrictToAndFromToLocationCustomers: boolean;
  shareToken: string | null;
  toDate: string;
  workTypePriceGroupsMap: ReadonlyMap<string, ReadonlySet<string>>;
}): {csvURL: string; pdfURL: string; resultsURL: string} {
  const {
    fromDate,
    intl,
    invoiceCustomerSet,
    locationCustomerSet,
    locationUrl,
    machinePriceGroupsMap,
    printedBy,
    products,
    reportingLocationName,
    restrictToAndFromToLocationCustomers,
    shareToken,
    toDate,
    workTypePriceGroupsMap,
  } = options;
  const filename = intl.formatMessage(
    {
      defaultMessage: "Lagerjournal_{fromDate}_{toDate}.pdf",
    },
    {fromDate, toDate},
  );
  const pdfBaseURL = `${globalConfig.baseURL}/download/report/storage_journal/pdf/${filename}`;
  const csvBaseURL = `${globalConfig.baseURL}/download/storage_journal/csv/${filename}`;
  const resultsBaseURL = `${globalConfig.baseURL}/api/report/storage_journal/results/`;

  const invoiceCustomerFilterString = Array.from(invoiceCustomerSet)
    .map((customerURL) => `&invoiceCustomer=${urlToId(customerURL)}`)
    .join("");

  const locationCustomerFilterString = Array.from(locationCustomerSet)
    .map((customerURL) => `&locationCustomer=${urlToId(customerURL)}`)
    .join("");
  const productsFilterString = Array.from(products)
    .map((productUrl) => `&product=${urlToId(productUrl)}`)
    .join("");

  const workTypeFilterString = Array.from(workTypePriceGroupsMap.entries())
    .map(([workTypeURL, priceGroupURLs]) => {
      if (!priceGroupURLs.size) {
        return `&workType=${urlToId(workTypeURL)},`;
      } else {
        return Array.from(priceGroupURLs)
          .map((priceGroupURL) => {
            return `&workType=${urlToId(workTypeURL)},${urlToId(priceGroupURL)}`;
          })
          .join("");
      }
    })
    .join("");

  const machineFilterString = Array.from(machinePriceGroupsMap.entries())
    .map(([machineURL, priceGroupURLs]) => {
      if (!priceGroupURLs.size) {
        return `&machine=${urlToId(machineURL)},`;
      } else {
        return Array.from(priceGroupURLs)
          .map((priceGroupURL) => {
            return `&machine=${urlToId(machineURL)},${urlToId(priceGroupURL)}`;
          })
          .join("");
      }
    })
    .join("");

  const locationString = `&location=${urlToId(locationUrl)}`;

  const fileNameWithoutExtension = `Lagerjournal_${fromDate}_${toDate}_${reportingLocationName}`;

  const restrictToAndFromToLocationCustomersString = restrictToAndFromToLocationCustomers
    ? "&restrictToAndFromToLocationCustomers"
    : "";

  const pdfURL = [
    `${pdfBaseURL}${encodeURIComponent(
      fileNameWithoutExtension,
    )}.pdf?token=${shareToken}&fromDate=${fromDate}&toDate=${toDate}&printedBy=${printedBy}`,
    invoiceCustomerFilterString,
    locationCustomerFilterString,
    workTypeFilterString,
    machineFilterString,
    locationString,
    productsFilterString,
    restrictToAndFromToLocationCustomersString,
  ].join("");

  const csvURL = [
    `${csvBaseURL}${encodeURIComponent(
      fileNameWithoutExtension,
    )}.csv?token=${shareToken}&fromDate=${fromDate}&toDate=${toDate}`,
    invoiceCustomerFilterString,
    locationCustomerFilterString,
    workTypeFilterString,
    machineFilterString,
    locationString,
    productsFilterString,
    restrictToAndFromToLocationCustomersString,
  ].join("");

  const resultsURL = [
    `${resultsBaseURL}?fromDate=${fromDate}&toDate=${toDate}`,
    invoiceCustomerFilterString,
    locationCustomerFilterString,
    workTypeFilterString,
    machineFilterString,
    locationString,
    productsFilterString,
    restrictToAndFromToLocationCustomersString,
  ].join("");

  return {csvURL, pdfURL, resultsURL};
}

interface ResultData {
  data: readonly [
    readonly JournalDataEntry[],
    readonly JournalSummarizedDataEntry[],
    readonly JournalSummarizedDataByProductEntry[],
  ];
  fromDate: string;
  invoiceCustomers: string[];
  location: LocationUrl;
  locationCustomers: string[];
  machines: {machine: string; priceGroups: string[]}[];
  products: string[];
  restrictToAndFromToLocationCustomers: boolean;
  toDate: string;
  workTypes: {priceGroups: string[]; workType: string}[];
}

type FetchedDataAction = {data: ResultData; type: "add"} | {type: "clear"};

function fetchedDataReducer(
  state: readonly ResultData[],
  action: FetchedDataAction,
): readonly ResultData[] {
  if (action.type === "add") {
    return [...state, action.data];
  } else {
    return [];
  }
}

type DownloadURLAction = {type: "add"; url: string} | {type: "clear"};

function downloadURLReducer(
  state: readonly string[],
  action: DownloadURLAction,
): readonly string[] {
  if (action.type === "add") {
    return [...state, action.url];
  } else {
    return [];
  }
}

interface FetchAndDisplayStorageJournalsProps {
  fromDate: string;
  invoiceCustomerSet?: ReadonlySet<string>;
  locationCustomerSet?: ReadonlySet<string>;
  locationUrls: readonly LocationUrl[];
  machinePriceGroupsMap?: ReadonlyMap<string, ReadonlySet<string>>;
  products?: ReadonlySet<string>;
  restrictToAndFromToLocationCustomers?: boolean;
  toDate: string;
  workTypePriceGroupsMap?: ReadonlyMap<string, ReadonlySet<string>>;
}

export function FetchAndDisplayStorageJournals(
  props: FetchAndDisplayStorageJournalsProps,
): React.JSX.Element[] {
  const {
    fromDate,
    invoiceCustomerSet,
    locationCustomerSet,
    locationUrls,
    machinePriceGroupsMap,
    products,
    restrictToAndFromToLocationCustomers = false,
    toDate,
    workTypePriceGroupsMap,
  } = props;

  const intl = useIntl();

  const [fetchedData, dispatchFetchedData] = useReducer(fetchedDataReducer, []);
  const [pdfURLs, dispatchPdfURL] = useReducer(downloadURLReducer, []);
  const [csvURLs, dispatchCsvURL] = useReducer(downloadURLReducer, []);

  const currentUserProfile = useSelector(getCurrentUserProfile);
  const shareToken = useSelector(getShareToken);

  const locationLookup = useSelector(getLocationLookup);

  useEffect(() => {
    let index = 0;
    let cancelled = false;
    let abortController: AbortController | undefined;

    const fetchNext = (): Promise<void> => {
      const locationUrl = locationUrls[index];
      const locationInstance = locationLookup(locationUrl);
      const reportingLocationName =
        locationInstance?.name && locationInstance?.address
          ? `${locationInstance.name}, ${locationInstance.address}`
          : locationInstance?.name || locationInstance?.address || "";

      const {csvURL, pdfURL, resultsURL} = buildURLs({
        fromDate,
        intl,
        invoiceCustomerSet: invoiceCustomerSet || new Set(),
        locationCustomerSet: locationCustomerSet || new Set(),
        locationUrl,
        machinePriceGroupsMap: machinePriceGroupsMap || new Map(),
        printedBy: currentUserProfile?.name || currentUserProfile?.alias || "",
        products: products || new Set(),
        reportingLocationName,
        restrictToAndFromToLocationCustomers,
        shareToken,
        toDate,
        workTypePriceGroupsMap: workTypePriceGroupsMap || new Map(),
      });
      dispatchCsvURL({type: "add", url: csvURL});
      dispatchPdfURL({type: "add", url: pdfURL});
      abortController = window.AbortController ? new AbortController() : undefined;
      return jsonFetch(resultsURL, "GET", null, abortController?.signal)
        .then((response) => {
          if (!cancelled) {
            // Insert data at fetchedData[index]
            dispatchFetchedData({data: response.data, type: "add"});
            index += 1;
            if (index < locationUrls.length) {
              return fetchNext();
            }
          }
          return undefined;
        })
        .catch(() => {
          return;
        });
    };
    fetchNext();
    return () => {
      cancelled = true;
      if (abortController) {
        abortController.abort();
      }
      dispatchFetchedData({type: "clear"});
      dispatchPdfURL({type: "clear"});
      dispatchCsvURL({type: "clear"});
    };
  }, [
    locationLookup,
    currentUserProfile?.alias,
    currentUserProfile?.name,
    fromDate,
    invoiceCustomerSet,
    locationCustomerSet,
    machinePriceGroupsMap,
    products,
    shareToken,
    toDate,
    workTypePriceGroupsMap,
    locationUrls,
    restrictToAndFromToLocationCustomers,
    intl,
  ]);

  const storageJournals = locationUrls.map((url, index) => {
    return (
      <Grid item key={url} xs={12}>
        <DisplayStorageJournal
          csvURL={csvURLs[index]}
          key={url}
          pdfURL={pdfURLs[index]}
          results={fetchedData[index]}
        />
      </Grid>
    );
  });

  return storageJournals;
}
