import type {ErrorObject} from "ajv";
import type {DeepReadonly} from "ts-essentials";
import {Config, SettingDependency, SettingMetaData} from "@co-common-libs/config";
import Ajv from "ajv";
import {JSONSchema7} from "json-schema";
import {differenceBy} from "lodash";

const ajv = new Ajv({
  allErrors: true,
  validateSchema: false,
});

export function settingValueIsBlank(value: unknown): boolean {
  return (
    value === undefined ||
    value === null ||
    value === false ||
    value === "" ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" && value !== null && Object.keys(value).length === 0)
  );
}

export function getValidator(
  schema: DeepReadonly<JSONSchema7>,
): (data: unknown) => ErrorObject[] | null {
  const validate = ajv.compile(schema);
  return function validator(data: unknown): ErrorObject[] | null {
    validate(data);
    return validate.errors ?? null;
  };
}

function filterSatisfiedDependencies(
  dependencies: readonly SettingDependency[],
  nonBlankSettings: Partial<Config>,
  value: unknown,
): readonly SettingDependency[] | null {
  if (settingValueIsBlank(value) || !dependencies.length) {
    return null;
  }
  const matches = dependencies.filter((other) => {
    if (typeof other === "object") {
      const {key: otherKey, value: otherValue} = other;

      const actualValue = nonBlankSettings[otherKey];

      const actualValueSet = new Set(
        typeof actualValue === "object"
          ? Object.values(Array.isArray(actualValue) ? actualValue : [actualValue])
          : [actualValue],
      );

      return actualValueSet.has(otherValue);
    } else {
      return other in nonBlankSettings;
    }
  });
  return matches;
}

export function checkConflictsWith(
  {conflictsWith}: Pick<SettingMetaData, "conflictsWith">,
  nonBlankSettings: Partial<Config>,
  value: unknown,
): readonly SettingDependency[] | null {
  const conflicts = filterSatisfiedDependencies(conflictsWith, nonBlankSettings, value);
  return conflicts && conflicts.length ? conflicts : null;
}

export function checkRequiresAllOf(
  {requiresAllOf}: Pick<SettingMetaData, "requiresAllOf">,
  nonBlankSettings: Partial<Config>,
  value: unknown,
): readonly SettingDependency[] | null {
  const satisfied = filterSatisfiedDependencies(requiresAllOf, nonBlankSettings, value);
  return satisfied && satisfied.length !== requiresAllOf.length ? requiresAllOf : null;
}

export function checkRequiresOneOf(
  {requiresOneOf}: Pick<SettingMetaData, "requiresOneOf">,
  nonBlankSettings: Partial<Config>,
  value: unknown,
): readonly SettingDependency[] | null {
  const satisfied = filterSatisfiedDependencies(requiresOneOf, nonBlankSettings, value);
  return satisfied && !satisfied.length ? requiresOneOf : null;
}

export function checkNoopWithoutAllOf(
  {noopWithoutAllOf}: Pick<SettingMetaData, "noopWithoutAllOf">,
  nonBlankSettings: Partial<Config>,
  value: unknown,
): readonly SettingDependency[] | null {
  const satisfied = filterSatisfiedDependencies(noopWithoutAllOf, nonBlankSettings, value);
  return satisfied && satisfied.length !== noopWithoutAllOf.length
    ? differenceBy(noopWithoutAllOf, satisfied, (d) => (typeof d === "object" ? d.key : d))
    : null;
}

export function checkNoopWithoutOneOf(
  {noopWithoutOneOf}: Pick<SettingMetaData, "noopWithoutOneOf">,
  nonBlankSettings: Partial<Config>,
  value: unknown,
): readonly SettingDependency[] | null {
  const satisfied = filterSatisfiedDependencies(noopWithoutOneOf, nonBlankSettings, value);
  return satisfied && !satisfied.length ? satisfied : null;
}

export function settingDependenciesToString(dependencies: readonly SettingDependency[]): string {
  return dependencies
    .map((dependency) =>
      typeof dependency === "object" ? `${dependency.key}(${dependency.value})` : dependency,
    )
    .join(", ");
}
