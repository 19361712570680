import {LocationUrl} from "@co-common-libs/resources";
import {SetAction, setReducer} from "@co-frontend-libs/utils";
import React, {useCallback, useEffect, useReducer} from "react";
import {
  BaseFieldMultiSelectionDialog,
  BaseFieldMultiSelectionDialogProps,
} from "./base-field-multi-selection-dialog";

const locationUrlSetReducer: (
  state: ReadonlySet<LocationUrl>,
  action: SetAction<LocationUrl>,
) => ReadonlySet<LocationUrl> = setReducer;

function buildSelectedSet(
  selected?:
    | ReadonlySet<LocationUrl>
    | readonly {readonly relatedField: LocationUrl}[]
    | readonly LocationUrl[],
): ReadonlySet<LocationUrl> {
  if (!selected) {
    return new Set<LocationUrl>();
  } else if (Array.isArray(selected)) {
    if (selected.length === 0) {
      return new Set<LocationUrl>();
    } else if (typeof selected[0] === "string") {
      return new Set(selected as readonly LocationUrl[]);
    } else {
      return new Set(
        (selected as readonly {readonly relatedField: LocationUrl}[]).map(
          (entry) => entry.relatedField,
        ),
      );
    }
  } else {
    return new Set(selected);
  }
}

interface FieldMultiSelectionDialogProps
  extends Omit<
    BaseFieldMultiSelectionDialogProps,
    "onOk" | "onSelect" | "onSelectMultiple" | "onToggleSelected" | "selected"
  > {
  onOk: (identifiers: ReadonlySet<LocationUrl>) => void;
  /** @deprecated
   * now Ignored
   */
  onSelect?: (
    identifier: LocationUrl,
    isSelected: boolean,
    acceptChange: () => void,
  ) => undefined | void;
  open: boolean;
  readonlySet?: ReadonlySet<LocationUrl> | undefined;
  selected?:
    | ReadonlySet<LocationUrl>
    | readonly {readonly relatedField: LocationUrl}[]
    | readonly LocationUrl[];
}

export const FieldMultiSelectionDialog = React.memo(function FieldMultiSelectionDialog(
  props: FieldMultiSelectionDialogProps,
): React.JSX.Element {
  const {onOk, onSelect, open, selected: selectedFromProps, ...other} = props;

  const [selected, dispatchSelect] = useReducer(
    locationUrlSetReducer,
    selectedFromProps,
    buildSelectedSet,
  );

  useEffect((): void => {
    if (open) {
      dispatchSelect({
        type: "replace",
        values: buildSelectedSet(selectedFromProps),
      });
    }
  }, [open, selectedFromProps]);

  const handleSelect = useCallback((identifier: LocationUrl, isSelected: boolean): void => {
    if (isSelected) {
      dispatchSelect({type: "addOne", value: identifier});
    } else {
      dispatchSelect({type: "removeOne", value: identifier});
    }
  }, []);

  const handleSelectMultiple = useCallback(
    (identifiers: ReadonlySet<LocationUrl>, isSelected: boolean): void => {
      if (isSelected) {
        dispatchSelect({type: "addMultiple", values: identifiers});
      } else {
        dispatchSelect({type: "removeMultiple", values: identifiers});
      }
    },
    [],
  );

  const handleToggleSelected = useCallback((identifier: LocationUrl): void => {
    dispatchSelect({type: "toggle", value: identifier});
  }, []);

  const handleOk = useCallback((): void => {
    onOk(selected);
  }, [onOk, selected]);

  return (
    <BaseFieldMultiSelectionDialog
      onOk={handleOk}
      onSelect={handleSelect}
      onSelectMultiple={handleSelectMultiple}
      onToggleSelected={handleToggleSelected}
      open={open}
      selected={selected}
      {...other}
    />
  );
});
