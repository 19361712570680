import {
  Command,
  emptyTransportLog,
  instanceURL,
  ResourceTypeUnion,
  SprayLog,
  Task,
  TaskUrl,
  TransportLog,
  urlToId,
  YieldLog,
} from "@co-common-libs/resources";
import _ from "lodash";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getCustomerSettings,
  getMachineLookup,
  getSprayLogArray,
  getTransportLogArray,
  getWorkTypeLookup,
  getYieldLogArray,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";
import {getBaseURL} from "./get-base-url";

export function createLegacyLogs(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  if (!newTask) {
    return null;
  }

  const orderURL = newTask.order;
  if (
    newTask.recordedInC5 ||
    newTask.archivable ||
    !orderURL ||
    ((!newTask.workType || newTask.workType === oldTask?.workType) &&
      (!newTask.machineuseSet ||
        !newTask.machineuseSet.length ||
        _.isEqual(newTask.machineuseSet, oldTask?.machineuseSet)))
  ) {
    return null;
  }

  const state = middlewareApi.getState();

  const customerSettings = getCustomerSettings(state);

  if (
    !customerSettings.autoAddTransportLogFor.length &&
    !customerSettings.autoAddYieldLogFor.length &&
    !customerSettings.autoAddSprayLogFor.length
  ) {
    return null;
  }

  const identifiers: string[] = [];

  if (newTask.workType && newTask.workType !== oldTask?.workType) {
    const workTypeLookup = getWorkTypeLookup(state);
    const workType = workTypeLookup(newTask.workType);
    if (workType?.identifier) {
      identifiers.push(workType.identifier);
    }
  }

  // logs may be auto-added based on machines, but only when work types disabled...
  if (
    customerSettings.noExternalTaskWorkType &&
    newTask.machineuseSet &&
    newTask.machineuseSet.length &&
    !_.isEqual(newTask.machineuseSet, oldTask?.machineuseSet)
  ) {
    const currentMachineURLs = newTask.machineuseSet.map((machineUse) => machineUse.machine);
    const oldMachineURLs = oldTask?.machineuseSet?.map((machineUse) => machineUse.machine);
    const addedMachineURLs = oldMachineURLs
      ? _.difference(currentMachineURLs, oldMachineURLs)
      : currentMachineURLs;
    if (addedMachineURLs.length) {
      const machineLookup = getMachineLookup(state);
      addedMachineURLs.forEach((machineURL) => {
        const machine = machineLookup(machineURL);
        if (machine?.c5_machine) {
          identifiers.push(machine.c5_machine);
        }
      });
    }
  }

  if (!identifiers.length) {
    return null;
  }

  const taskURL = command.url as TaskUrl;
  const taskID = urlToId(taskURL);
  const baseURL = getBaseURL(taskURL);

  // const orderLookup = getOrderLookup(state);

  const newResourceInstances: ResourceTypeUnion[] = [];

  if (
    customerSettings.autoAddTransportLogFor.length &&
    _.intersection(customerSettings.autoAddTransportLogFor, identifiers).length
  ) {
    const transportLogArray = getTransportLogArray(state);
    if (!transportLogArray.some((transportLog) => transportLog.task === taskURL)) {
      const transportLog: TransportLog = {
        ...emptyTransportLog,
        id: taskID,
        task: taskURL,
        url: instanceURL(baseURL, "transportLog", taskID),
      };
      newResourceInstances.push(transportLog);
    }
  }

  if (
    customerSettings.autoAddYieldLogFor.length &&
    _.intersection(customerSettings.autoAddYieldLogFor, identifiers).length
  ) {
    const yieldLogArray = getYieldLogArray(state);
    if (!yieldLogArray.some((yieldLog) => yieldLog.task === taskURL)) {
      const yieldLogURL = instanceURL(baseURL, "yieldLog", taskID);
      const yieldLog: YieldLog = {
        crop: customerSettings.yieldLogDefaultCrop,
        cuts: null,
        density: null,
        id: taskID,
        task: taskURL,
        unit: customerSettings.yieldLogDefaultUnit,
        url: yieldLogURL,
        wagonWeight: null,
      };
      newResourceInstances.push(yieldLog);
      /*
      // Makes a mess when locations are also added on task copy;
      // and auto-adding from fields have only ever worked when fields
      // were added afterwards -- not worth spending time on supporting
      // for "legacy" logs...
      if (task.fielduseSet) {
        task.fielduseSet.forEach((fieldUse, index) => {
          const fieldURL = fieldUse.relatedField;
          const id = uuid();
          const url = instanceURL(baseURL, "yieldPickupLocation", id);
          const newYieldPickupLocation: YieldPickupLocation = {
            customer: orderLookup(orderURL)?.customer || null,
            id,
            note: "",
            order: index,
            relatedLocation: fieldURL,
            task: taskURL,
            url,
            yieldlog: yieldLogURL,
          };
          newResourceInstances.push(newYieldPickupLocation);
        });
      }
      */
    }
  }

  if (
    customerSettings.autoAddSprayLogFor.length &&
    _.intersection(customerSettings.autoAddSprayLogFor, identifiers).length
  ) {
    const sprayLogArray = getSprayLogArray(state);
    if (!sprayLogArray.some((sprayLog) => sprayLog.task === taskURL)) {
      const sprayLogURL = instanceURL(baseURL, "sprayLog", taskID);
      const sprayLog: SprayLog = {
        id: taskID,
        nozzleType: "",
        sun: "",
        task: taskURL,
        temperature: null,
        url: sprayLogURL,
        windDirection: "",
        windSpeed: null,
      };
      newResourceInstances.push(sprayLog);
      /*
      // Makes a mess when locations are also added on task copy;
      // and auto-adding from fields have only ever worked when fields
      // were added afterwards -- not worth spending time on supporting
      // for "legacy" logs...
      if (task.fielduseSet) {
        task.fielduseSet.forEach((fieldUse, index) => {
          const fieldURL = fieldUse.relatedField;
          const id = uuid();
          const url = instanceURL(baseURL, "sprayLocation", id);
          const digits = 2;
          const newSprayLocation: SprayLocation = {
            area:
              fieldUse.fieldAreaHa != null
                ? _.round(fieldUse.fieldAreaHa, digits)
                : null,
            crop: fieldUse.fieldCrop,
            customer: orderLookup(orderURL)?.customer || null,
            id,
            note: "",
            order: index,
            relatedLocation: fieldURL,
            spraylog: sprayLogURL,
            task: null,
            url,
          };
          newResourceInstances.push(newSprayLocation);
        });
      }
      */
    }
  }
  if (!newResourceInstances.length) {
    return null;
  }

  return {
    after: newResourceInstances.map((logInstance) => ({
      action: "CREATE",
      instance: logInstance,
      url: logInstance.url,
    })),
  };
}
