import {ExtendedConfig} from "extended-config";
import {CustomerFormMember} from "./types";

export function computeRequiredInputFields(
  inputFields: ReadonlySet<CustomerFormMember>,
  offlineOnlyInputs: boolean,
  customerSettings: Pick<ExtendedConfig, "customers">,
): ReadonlySet<CustomerFormMember> {
  const {customers} = customerSettings;

  // name is always required
  const required: Set<CustomerFormMember> = new Set([
    CustomerFormMember.NAME,
    ...(customers.customerRequiredFields as CustomerFormMember[]),
  ]);

  if (!offlineOnlyInputs) {
    if (customers.liveSyncWithThirdParty && inputFields.has(CustomerFormMember.CUSTOMER_NUMBER)) {
      required.add(CustomerFormMember.CUSTOMER_NUMBER);
    }
  }

  return required;
}
