import {
  getCustomerArray,
  getReportingSpecificationArray,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import _ from "lodash";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";

export function SendValidatedGenericLogToCustomers(
  props: SettingValueDisplayProps,
): React.JSX.Element {
  const {settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const reportingSpecificationArray = useSelector(getReportingSpecificationArray);

  const customerArray = useSelector(getCustomerArray);

  const sendValidatedGenericLogToCustomers: {
    [label: string]: string[];
  } = useMemo(() => settingEntry?.data || {}, [settingEntry?.data]);

  const sendValidatedGenericLogToCustomersWithAllSpecs: {
    [key: string]: string[];
  } = {
    ...reportingSpecificationArray
      .filter((spec) => spec.active)
      .reduce((dict: {[identifier: string]: string[]}, spec) => {
        dict[spec.identifier] = [];
        return dict;
      }, {}),
    ...sendValidatedGenericLogToCustomers,
  };

  const sortedReportingSpecificationArray = _.sortBy(
    reportingSpecificationArray,
    (s) => s.lastChanged,
  );
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{width: 200}}>
            <FormattedMessage
              defaultMessage="Generisk log"
              id="system-setup.dialog-title.generic-log"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Kunder" id="system-setup.dialog-title.customers" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(sendValidatedGenericLogToCustomersWithAllSpecs).map(
          ([identifier, customerIdentifiers]) => {
            const spec = sortedReportingSpecificationArray.find(
              (instance) => instance.active && instance.identifier === identifier,
            );
            return (
              <TableRow key={identifier}>
                <TableCell>{spec?.name}</TableCell>
                <TableCell>
                  {customerIdentifiers.map((c5Account) => {
                    const customer = customerArray.find((c) => c.c5_account === c5Account);
                    const key = `${identifier}-${c5Account}`;
                    return customer ? (
                      <div key={key}>
                        {customer.c5_account}: {customer.name}
                      </div>
                    ) : (
                      <FormattedMessage
                        defaultMessage="Ukendt kunde med kontonr. {account}"
                        key={key}
                        values={{account: c5Account}}
                      />
                    );
                  })}
                </TableCell>
              </TableRow>
            );
          },
        )}
      </TableBody>
    </Table>
  );
}
