import {
  CsvExportSpecification,
  getAvailableColumns,
} from "@co-common-libs/csv-export-specifications";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ExportColumnSelectorCheckboxes} from "./column-selector-checkboxes";

export function ExportColumnSelectorCheckboxesCard(props: {
  checkedStates: ReadonlySet<string>;
  selectedExportSpecification: CsvExportSpecification;
  setSelectedColumnNames: (checked: ReadonlySet<string>) => void;
}): React.JSX.Element {
  const {checkedStates, selectedExportSpecification, setSelectedColumnNames} = props;

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);

  const columns = getAvailableColumns(customerSettings, selectedExportSpecification);

  return (
    <Card style={{height: "100%"}}>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage: "Vælg kolonner",
        })}
      />
      <CardContent>
        <ExportColumnSelectorCheckboxes
          checkedStates={checkedStates}
          columns={columns}
          onChange={setSelectedColumnNames}
        />
      </CardContent>
    </Card>
  );
}
