export const syncedResourceHandleKeys = [
  "contact",
  "customer",
  "externalWorkType",
  "internalWorkType",
  "location",
  "machine",
  "priceItem",
  "product",
  "productGroup",
  "project",
  "puller",
  "unit",
  "userProfile",
] as const;

export type SyncedResourceHandleKey = (typeof syncedResourceHandleKeys)[number];

export const previewAbleHandleKeys = [
  "externalWorkType",
  "internalWorkType",
  "machine",
  "priceItem",
  "product",
  "productGroup",
  "puller",
] satisfies SyncedResourceHandleKey[];

export type PreviewAbleHandleKeys = (typeof previewAbleHandleKeys)[number];
