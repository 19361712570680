import {Order, RoutePlanTaskUrl, RouteTaskUrl, Task} from "@co-common-libs/resources";
import React from "react";
import {RouteTabRoute} from "./route-tab-route";
import {RouteTabRoutePlan} from "./route-tab-route-plan";

interface RouteTabProps {
  onRouteTaskCompleteClick: (
    routePlanTaskUrl: RoutePlanTaskUrl,
    routeTaskUrl: RouteTaskUrl | undefined,
  ) => void;
  onStartClick: () => void;
  order?: Order | undefined;
  task: Task;
}

export const RouteTab = React.memo(function RouteTab(props: RouteTabProps): React.JSX.Element {
  const {onRouteTaskCompleteClick, onStartClick, order, task} = props;

  if (task.completed) {
    return <RouteTabRoute task={task} />;
  } else if (order) {
    if (!order.routePlan) {
      throw new Error("no routePlan on order");
    }
    return (
      <RouteTabRoutePlan
        onRouteTaskCompleteClick={onRouteTaskCompleteClick}
        onStartClick={onStartClick}
        routePlanUrl={order.routePlan}
        task={task}
      />
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
});
