import {
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationUrl,
} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {EntryData, GenericSingleSelectionSearchDialog} from "../search-dialog";

function computeBaseChoices(
  pricePercentFuelSurchargeSpecificationArray: readonly PricePercentFuelSurchargeSpecification[],
): readonly EntryData<PricePercentFuelSurchargeSpecificationUrl>[] {
  const data: EntryData<PricePercentFuelSurchargeSpecificationUrl>[] = [];
  pricePercentFuelSurchargeSpecificationArray.forEach((instance) => {
    if (instance.active) {
      const {url} = instance;
      const name = instance.name || "";
      const entry: EntryData<PricePercentFuelSurchargeSpecificationUrl> = {
        category: "standard",
        exactMatchValue: name,
        identifier: url,
        primaryText: name,
        searchFields: [{label: "Navn", priority: 5, text: name}],
      };
      data.push(entry);
    }
  });
  return data;
}

interface PricePercentFuelSurchargeSpecificationDialogProps {
  onCancel(): void;
  onNone?(): void;
  onOk(url: PricePercentFuelSurchargeSpecificationUrl): void;
  open: boolean;
  pricePercentFuelSurchargeSpecificationArray: readonly PricePercentFuelSurchargeSpecification[];
}

export const PricePercentFuelSurchargeSpecificationDialog = React.memo(
  function PricePercentFuelSurchargeSpecificationDialog(
    props: PricePercentFuelSurchargeSpecificationDialogProps,
  ) {
    const {onCancel, onNone, onOk, open, pricePercentFuelSurchargeSpecificationArray} = props;
    const intl = useIntl();

    const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
      () => memoizeForceReuse(computeBaseChoices, []),
      [],
    );
    const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
    const data = getBaseChoices(pricePercentFuelSurchargeSpecificationArray);

    return (
      <GenericSingleSelectionSearchDialog<PricePercentFuelSurchargeSpecificationUrl>
        data={data}
        mobilePrimaryLines={1}
        mobileSearchPrimaryLines={1}
        mobileSearchSecondaryLines={1}
        mobileSecondaryLines={0}
        noneLabel={
          onNone ? intl.formatMessage({defaultMessage: "Uden brændstoftillæg"}) : undefined
        }
        onCancel={onCancel}
        onNone={onNone}
        onOk={onOk}
        open={open}
        searchTitle={intl.formatMessage({
          defaultMessage: "Søg brændstoftillæg",
        })}
        title={intl.formatMessage({
          defaultMessage: "Vælg brændstoftillæg",
        })}
      />
    );
  },
);
