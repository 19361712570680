import {Patch, Role, StartPage, UserProfile} from "@co-common-libs/resources";
import {actions, getCurrentUserURL, getCustomerSettings} from "@co-frontend-libs/redux";
import {Card, CardContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {useEventTargetValueCallback, useFieldUpdater} from "app-utils";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

type RoleName =
  | "breakRoom"
  | "jobber"
  | "machineOperator"
  | "manager"
  | "mechanic"
  | "projectManager"
  | "seniorMachineOperator";

export const RoleCard = React.memo(function RoleCard({
  userProfile,
  userRoles,
}: {
  userProfile: UserProfile;
  userRoles: Role;
}): React.JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);

  const intl = useIntl();

  const userRolesUrl = userRoles.url;
  const currentUserUrl = useSelector(getCurrentUserURL);
  const userIsOther = currentUserUrl !== userRoles.user;

  const {
    breakRoom,
    jobber,
    machineOperator,
    manager,
    mechanic,
    projectManager,
    seniorMachineOperator,
  } = userRoles;
  let currentRole: RoleName | null = null;
  if (jobber) {
    currentRole = "jobber";
  } else if (mechanic) {
    currentRole = "mechanic";
  } else if (projectManager) {
    currentRole = "projectManager";
  } else if (seniorMachineOperator) {
    currentRole = "seniorMachineOperator";
  } else if (manager) {
    currentRole = "manager";
  } else if (machineOperator) {
    currentRole = "machineOperator";
  } else if (breakRoom) {
    currentRole = "breakRoom";
  }

  const dispatch = useDispatch();

  const handleRoleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const selectedRole = event.target.value as RoleName;

      // project manager permissions are hacked on top og manager permissions.
      // That is, project managers must also be marked as manager but the project
      // manager role restricts the manager permissions in some places. E.g., the
      // project manager cannot see salary reports.
      const rolePatch: Patch<Role> = [
        {member: "breakRoom", value: selectedRole === "breakRoom"},
        {member: "jobber", value: selectedRole === "jobber"},
        {member: "machineOperator", value: selectedRole !== "breakRoom"},
        {
          member: "manager",
          value: selectedRole === "manager" || selectedRole === "projectManager",
        },
        {member: "mechanic", value: selectedRole === "mechanic"},
        {member: "projectManager", value: selectedRole === "projectManager"},
        {
          member: "seniorMachineOperator",
          value: selectedRole === "seniorMachineOperator",
        },
      ];

      dispatch(actions.update(userRolesUrl, rolePatch));

      if (
        (selectedRole === "breakRoom" && userProfile.startPage !== "CHECKOUT") ||
        (selectedRole !== "breakRoom" && userProfile.startPage !== "")
      ) {
        const profilePatch: Patch<UserProfile> = [
          {
            member: "startPage",
            value: selectedRole === "breakRoom" ? "CHECKOUT" : "",
          },
        ];

        dispatch(actions.update(userProfile.url, profilePatch));
      }
    },
    [dispatch, userProfile.url, userProfile.startPage, userRolesUrl],
  );

  const startPageUpdater = useFieldUpdater<UserProfile, StartPage>(userProfile.url, "startPage");

  const handleStartPageChange = useEventTargetValueCallback(
    (value: string) => {
      startPageUpdater(value as StartPage);
    },
    [startPageUpdater],
  );

  return (
    <Card>
      <CardContent>
        <FormattedMessage defaultMessage="Rolle:" id="user-profile.label.role" tagName="b" />
        <RadioGroup name="role" onChange={handleRoleChange} value={currentRole}>
          {customerSettings.enableJobberRole ? (
            <FormControlLabel
              control={<Radio />}
              disabled={!userIsOther}
              label={intl.formatMessage({
                defaultMessage: "Løsarbejder",
              })}
              value="jobber"
            />
          ) : (
            []
          )}
          {customerSettings.enableMechanicRole ? (
            <FormControlLabel
              control={<Radio />}
              disabled={!userIsOther}
              label={intl.formatMessage({
                defaultMessage: "Mekaniker",
              })}
              value="mechanic"
            />
          ) : (
            []
          )}
          {customerSettings.enableProjectManagerRole ? (
            <FormControlLabel
              control={<Radio />}
              disabled={!userIsOther}
              label={intl.formatMessage({
                defaultMessage: "Projektleder",
              })}
              value="projectManager"
            />
          ) : (
            []
          )}
          <FormControlLabel
            control={<Radio />}
            disabled={!userIsOther}
            label={
              customerSettings.employeeLabelVariant === "MACHINEOPERATOR"
                ? intl.formatMessage({
                    defaultMessage: "Maskinfører",
                  })
                : customerSettings.employeeLabelVariant === "EMPLOYEE"
                  ? intl.formatMessage({
                      defaultMessage: "Medarbejder",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Chauffør",
                    })
            }
            value="machineOperator"
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!userIsOther}
            label={
              customerSettings.employeeLabelVariant === "MACHINEOPERATOR"
                ? intl.formatMessage({
                    defaultMessage: "Senior Maskinfører",
                  })
                : customerSettings.employeeLabelVariant === "EMPLOYEE"
                  ? intl.formatMessage({
                      defaultMessage: "Senior Medarbejder",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Senior Chauffør",
                    })
            }
            value="seniorMachineOperator"
          />
          <FormControlLabel
            control={<Radio />}
            label={intl.formatMessage({
              defaultMessage: "Administrator",
            })}
            value="manager"
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!userIsOther}
            label={intl.formatMessage({
              defaultMessage: "Info Rolle",
            })}
            value="breakRoom"
          />
        </RadioGroup>
        <FormattedMessage defaultMessage="Info rolle startside:" tagName="b" />
        <RadioGroup name="startPage" onChange={handleStartPageChange} value={userProfile.startPage}>
          <FormControlLabel
            control={<Radio />}
            disabled
            label={intl.formatMessage({defaultMessage: "Ikke angivet"})}
            value=""
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!breakRoom}
            label={intl.formatMessage({defaultMessage: "Pauseskærm"})}
            value="CHECKOUT"
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!breakRoom}
            label={intl.formatMessage({defaultMessage: "Fuldskærmskort"})}
            value="MAP"
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!breakRoom}
            label={intl.formatMessage({defaultMessage: "Fuldskærmskalender"})}
            value="CALENDAR"
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!breakRoom}
            label={intl.formatMessage({
              defaultMessage: "Fuldskærmskalender, side 1",
            })}
            value="CALENDAR_PAGE_ONE"
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!breakRoom}
            label={intl.formatMessage({
              defaultMessage: "Fuldskærmskalender, side 2",
            })}
            value="CALENDAR_PAGE_TWO"
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!breakRoom}
            label={intl.formatMessage({
              defaultMessage: "Fuldskærmsordrekalender",
            })}
            value="ORDER_CALENDAR"
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!breakRoom}
            label={intl.formatMessage({
              defaultMessage: "Fuldskærmsordrekalender (minimal)",
            })}
            value="MINIMAL_ORDER_CALENDAR"
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!breakRoom}
            label={intl.formatMessage({
              defaultMessage: "Fuldskærmsfraværskalender",
            })}
            value="ABSENCE_CALENDAR"
          />
        </RadioGroup>
      </CardContent>
    </Card>
  );
});
