import {PathTemplate} from "@co-frontend-libs/redux";
import {SPACING} from "frontend-global-config";
import React from "react";
import {NavListItem} from "../nav-list-item";
import {MenuItem} from "../types";

export interface SettingsMenuItemProps {
  menuItem: MenuItem;
  onRequestChange: (route: PathTemplate) => void;
  selectedRoute: PathTemplate | null;
}

export const SettingsMenuItem = React.memo(function SettingsMenuItem(
  props: SettingsMenuItemProps,
): React.JSX.Element {
  const {menuItem, onRequestChange, selectedRoute} = props;
  const {Icon, route, text} = menuItem;

  return (
    <NavListItem
      Icon={Icon}
      key={route}
      onRequestChange={onRequestChange}
      route={route}
      selected={selectedRoute === route}
      spacingLeft={SPACING.MEDIUM}
      text={text}
    />
  );
});
