import {matchingTextColor} from "@co-frontend-libs/utils";
import {useTheme} from "@material-ui/core";
import CheckIcon from "mdi-react/CheckIcon";
import SlashForwardIcon from "mdi-react/SlashForwardIcon";
import React, {useCallback} from "react";

export const COLORS: readonly string[] = [
  "#f48fb1",
  "#ce93d8",
  "#90caf9",
  "#80deea",
  "#80cbc4",
  "#a5d6a7",
  "#fff59d",
  "#ffab91",
  "#bcaaa4",
  "#e0e0e0",
  "#ec407a",
  "#ab47bc",
  "#42a5f5",
  "#26c6da",
  "#26a69a",
  "#66bb6a",
  "#ffee58",
  "#ff7043",
  "#8d6e63",
  "#9e9e9e",
  "#4caf50",
  "#93c47d",
  "#b6d7a8",
  "#8bc34a",
  "#f44336",
  "#ffa000",
  "#2196f3",
  "#673ab7",
  "#fdd835",
  "#c26dd0",
];

interface ColorTileProps {
  color: string;
  onColorClick: (color: string) => void;
  selected?: boolean;
}

const ColorTile = React.memo(function ColorTile({
  color,
  onColorClick,
  selected,
}: ColorTileProps): React.JSX.Element {
  const handleClick = useCallback(() => {
    onColorClick(color);
  }, [onColorClick, color]);
  return (
    <div
      onClick={handleClick}
      style={{
        alignItems: "center",
        backgroundColor: color,
        color: matchingTextColor(color),
        cursor: "pointer",
        display: "flex",
        height: 50,
        justifyContent: "center",
        width: 50,
      }}
    >
      {selected ? <CheckIcon /> : null}
    </div>
  );
});

export interface ColorPickerProps {
  onColorClick: (color: string) => void;
  required?: boolean | undefined;
  selectedColor?: string | undefined;
}

export const ColorPicker = React.memo(function ColorPicker({
  onColorClick,
  required,
  selectedColor,
}: ColorPickerProps): React.JSX.Element {
  const theme = useTheme();
  const handleNoColorClick = useCallback(() => {
    onColorClick("");
  }, [onColorClick]);
  return (
    <div style={{display: "flex", flexWrap: "wrap"}}>
      {COLORS.map((color) => (
        <ColorTile
          color={color}
          key={color}
          onColorClick={onColorClick}
          selected={color === selectedColor}
        />
      ))}
      {!required ? (
        <div
          onClick={handleNoColorClick}
          style={{
            border: "1px solid",
            borderColor: theme.palette.error.main,
            cursor: "pointer",
            height: 50,
            width: 50,
          }}
        >
          <SlashForwardIcon
            style={{
              color: theme.palette.error.main,
              height: 49,
              width: 49,
            }}
          />
        </div>
      ) : null}
    </div>
  );
});
