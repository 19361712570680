import {Config} from "@co-common-libs/config";
import {Machine, Task, TaskUrl, TimerStart, UserProfile, WorkType} from "@co-common-libs/resources";
import {formatDuration} from "@co-common-libs/resources-utils";
import {formatDateShort} from "@co-common-libs/utils";
import {SvgIcon, TableCell, TableRow} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import useMergedRef from "@react-hook/merged-ref";
import {Linkify, TaskStatusIcon} from "app-components";
import bowser from "bowser";
import _ from "lodash";
import DragVerticalIcon from "mdi-react/DragVerticalIcon";
import ImageIcon from "mdi-react/ImageIcon";
import React, {useCallback} from "react";
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from "react-dnd";
import {
  DATE_COLUMN_WIDTH,
  DRAG_HANDLE_CELL_WIDTH,
  DURATION_COLUMN_WIDTH,
  EMPLOYEE_COLUMN_WIDTH,
  MACHINES_COLUMN_WIDTH,
  MACHINES_NAME_COLUMN_WIDTH,
  NOTES_COLUMN_WIDTH,
  PHOTO_ICON_COLUMN_WIDTH,
  STATUS_ICON_COLUMN_WIDTH,
  VEHICLE_IDENTIFICATION_NUMBER_COLUMN_WIDTH,
  WORK_TYPE_COLUMN_WIDTH,
} from "./column-widths";

interface TodoTaskRowProps {
  createdByUserProfile: UserProfile | undefined;
  customerSettings: Config;
  hasPhoto: boolean;
  machineList: readonly Machine[];
  machineOperatorProfile: UserProfile | undefined;
  onClick: (taskURL: string) => void;
  onDragDrop: (movedURL: TaskUrl, targetURL: TaskUrl) => void;
  task: Task;
  taskTimerStartArray: readonly TimerStart[];
  workType: WorkType | undefined;
}

export function TodoTaskRow(props: TodoTaskRowProps): React.JSX.Element {
  const {
    createdByUserProfile,
    customerSettings,
    machineList,
    machineOperatorProfile,
    onClick,
    onDragDrop,
    task,
    workType,
  } = props;

  const handleClick = useCallback(() => {
    onClick(task.url);
  }, [onClick, task.url]);

  const [dropCollectedProps, drop] = useDrop({
    accept: "task",
    canDrop: (item: any, _monitor: DropTargetMonitor) => {
      return task.priority != null && item.url !== task.url;
    },
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item: any, _monitor: DropTargetMonitor) => {
      onDragDrop(item.url, task.url);
    },
  });
  const {canDrop, isOver} = dropCollectedProps;

  const [dragCollectedProps, drag, preview] = useDrag({
    canDrag: (_monitor: DragSourceMonitor) => {
      return true;
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      createdByUserProfile,
      machineList,
      machineOperatorProfile,
      task,
      url: task.url,
      workType,
    },
    type: "task",
  });
  const {isDragging} = dragCollectedProps;

  let machines;
  let machineColumnWidth;
  if (customerSettings.showWorkshopMachineName) {
    machines = machineList.map((m) => m.name).join(", ");
    machineColumnWidth = MACHINES_NAME_COLUMN_WIDTH;
  } else {
    machines = machineList.map((m) => m.c5_machine).join(", ");
    machineColumnWidth = MACHINES_COLUMN_WIDTH;
  }
  const notes = task.notesFromManager || task.notesFromMachineOperator;
  const machineOperatorInitials = machineOperatorProfile ? machineOperatorProfile.alias : null;
  const duration = formatDuration(
    customerSettings.durationFormat,
    task.minutesExpectedTotalTaskDuration,
  );
  let dragRow;
  let dragHandleCell;
  if (bowser.mobile || bowser.tablet) {
    dragRow = _.identity;
    const style = {
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: "center",
      width: DRAG_HANDLE_CELL_WIDTH,
    } as const;
    dragHandleCell = (
      <TableCell ref={drag} style={style}>
        <SvgIcon>
          <DragVerticalIcon />
        </SvgIcon>
      </TableCell>
    );
  } else {
    dragRow = drag;
  }

  const style: React.CSSProperties = {cursor: "pointer"};

  if (bowser.mobile || bowser.tablet) {
    style.userSelect = "none";
  }

  let createdByColumn;
  if (customerSettings.workshopCreatedByColumn && !bowser.mobile) {
    const createdByInitials = createdByUserProfile ? createdByUserProfile.alias : null;
    createdByColumn = (
      <TableCell onClick={handleClick} style={{width: EMPLOYEE_COLUMN_WIDTH}}>
        {createdByInitials}
      </TableCell>
    );
  }
  let createdDateColumn;
  if (customerSettings.workshopCreatedDateColumn && !bowser.mobile) {
    createdDateColumn = (
      <TableCell onClick={handleClick} style={{width: DATE_COLUMN_WIDTH}}>
        {formatDateShort(task.created)}
      </TableCell>
    );
  }
  if (isDragging) {
    style.opacity = 0.2;
  }
  if (isOver && canDrop) {
    style.backgroundColor = green[100];
  }
  let vinColumn;
  if (customerSettings.workshopVehicleIdentificationNumber) {
    const vinValues = machineList
      .map((m) => m.vehicleIdentificationNumber)
      .filter((n) => n)
      .join(", ");
    vinColumn = (
      <TableCell onClick={handleClick} style={{width: VEHICLE_IDENTIFICATION_NUMBER_COLUMN_WIDTH}}>
        {vinValues}
      </TableCell>
    );
  }

  let worktypeColumn;
  if (!bowser.mobile) {
    worktypeColumn = (
      <TableCell onClick={handleClick} style={{paddingRight: 0, width: WORK_TYPE_COLUMN_WIDTH}}>
        {workType ? workType.identifier : null}
      </TableCell>
    );
  }

  let photoIconColumn;
  if (!bowser.mobile) {
    photoIconColumn = (
      <TableCell onClick={handleClick} style={{width: PHOTO_ICON_COLUMN_WIDTH}}>
        {props.hasPhoto ? (
          <div style={{height: 24, width: 24}}>
            <SvgIcon>
              <ImageIcon />
            </SvgIcon>
          </div>
        ) : null}
      </TableCell>
    );
  }

  const previewRow = bowser.mobile || bowser.tablet ? preview : _.identity;

  const multiRef = useMergedRef<HTMLTableRowElement>(drop, dragRow, previewRow);

  return (
    <TableRow ref={multiRef} style={style}>
      {worktypeColumn}
      <TableCell
        onClick={handleClick}
        style={{
          whiteSpace: "normal",
          width: machineColumnWidth,
        }}
      >
        {machines}
      </TableCell>
      {vinColumn}
      <TableCell onClick={handleClick} style={{minWidth: NOTES_COLUMN_WIDTH}}>
        <Linkify>{notes}</Linkify>
      </TableCell>
      <TableCell onClick={handleClick} style={{width: EMPLOYEE_COLUMN_WIDTH}}>
        {machineOperatorInitials}
      </TableCell>
      <TableCell onClick={handleClick} style={{width: DURATION_COLUMN_WIDTH}}>
        {duration}
      </TableCell>
      {createdByColumn}
      {createdDateColumn}
      {photoIconColumn}
      <TableCell onClick={handleClick} style={{width: STATUS_ICON_COLUMN_WIDTH}}>
        <TaskStatusIcon task={task} timerStartArray={props.taskTimerStartArray} />
      </TableCell>
      {dragHandleCell}
    </TableRow>
  );
}
