import {Button, Dialog, DialogActions, Theme, useTheme} from "@material-ui/core";
import {DatePicker, Day, MuiPickersContext} from "@material-ui/pickers";
import {getWeek, isSameDay, Locale, parseISO} from "date-fns";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

export interface DateDialogProps {
  autoOk?: boolean;
  initialFocusedDate?: string | null;
  maxDate?: string | null;
  minDate?: string | null;
  onCancel: () => void;
  onOk: (date: Date | null) => void;
  open: boolean;
  value?: Date;
}

function makeRenderDay(
  theme: Theme,
  locale?: Locale,
): (date: Date | null, selectedDate: Date | null, dayInCurrentMonth: boolean) => React.JSX.Element {
  return function renderDay(
    date: Date | null,
    selectedDate: Date | null,
    dayInCurrentMonth: boolean,
  ): React.JSX.Element {
    if (!date) {
      return <div />;
    }
    const dateDay = date.getDate();

    const localeOption = locale ? {locale} : undefined;

    const weekStartsOn = locale?.options?.weekStartsOn ?? 0;
    return (
      <div>
        {date.getDay() === weekStartsOn ? (
          <div
            style={{
              ...theme.typography.caption,
              color: theme.palette.text.disabled,
              left: 3,
              paddingTop: 9,
              position: "absolute",
            }}
          >
            {getWeek(date, localeOption)}
          </div>
        ) : null}
        <Day
          current={isSameDay(date, new Date())}
          hidden={!dayInCurrentMonth}
          selected={!!selectedDate && isSameDay(date, selectedDate)}
        >
          {dateDay}
        </Day>
      </div>
    );
  };
}

export function DateDialog(props: DateDialogProps): React.JSX.Element {
  const {autoOk, initialFocusedDate, maxDate, minDate, onCancel, onOk, open, value} = props;
  const [date, setDate] = useState<Date | null>(value || new Date());
  const handleDateChange = useCallback(
    (newDate: Date | null): void => {
      if (autoOk) {
        onOk(newDate);
      } else {
        setDate(newDate);
      }
    },
    [autoOk, onOk],
  );
  useEffect(() => {
    setDate(value || new Date());
  }, [value, open]);
  const handleOk = useCallback(() => {
    onOk(date);
  }, [date, onOk]);

  const theme = useTheme();
  const locale = useContext(MuiPickersContext)?.locale as Locale | undefined;

  const renderDay = makeRenderDay(theme, locale);

  return (
    <Dialog onClose={onCancel} open={props.open}>
      <DatePicker
        initialFocusedDate={initialFocusedDate ? parseISO(initialFocusedDate) : undefined}
        maxDate={maxDate ? parseISO(maxDate) : undefined}
        minDate={minDate ? parseISO(minDate) : undefined}
        onChange={handleDateChange}
        renderDay={renderDay}
        value={date}
        variant="static"
      />
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          <FormattedMessage defaultMessage="Fortryd" id="dialog.label.cancel" />
        </Button>
        {autoOk ? undefined : (
          <Button color="primary" onClick={handleOk}>
            <FormattedMessage defaultMessage="OK" id="dialog.label.ok" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
