import {BarcodeScanner, getFrontendSentry} from "@co-frontend-libs/utils";
import {IconButton} from "@material-ui/core";
import QrcodeScanIcon from "mdi-react/QrcodeScanIcon";
import React, {useCallback} from "react";

interface BarcodeScannerIconButtonProps {
  barcodeScanner: BarcodeScanner;
  onScanResult: (text: string) => void;
}

export const BarcodeScannerIconButton = React.memo(function BarcodeScannerIconButton(
  props: BarcodeScannerIconButtonProps,
): React.JSX.Element {
  const {barcodeScanner, onScanResult} = props;

  const sentry = getFrontendSentry();

  const handleStartScan = useCallback(async () => {
    if (!barcodeScanner) {
      return;
    }
    let result: string | undefined;
    try {
      result = await barcodeScanner();
    } catch (error) {
      sentry.captureMessage(`BarcodeScanner: Scan threw exception: ${error}`, "error");
    }
    if (result) {
      onScanResult(result);
    }
  }, [barcodeScanner, onScanResult, sentry]);

  return (
    <IconButton color="inherit" onClick={handleStartScan}>
      <QrcodeScanIcon />
    </IconButton>
  );
});
