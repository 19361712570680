import {Location, LocationUrl} from "@co-common-libs/resources";
import React, {useMemo} from "react";
import {FieldsClusteredMarkers} from "./fields-clustered-markers";
import {FieldsGeoJson} from "./fields-geojson";
import {isLocationWithGeoJson} from "./types";

export const DEFAULT_DISPLAY_POLYGONS_ZOOM_THRESHOLD = 12;
interface FieldPolygonsProps {
  clusterColor?: string;
  clusterZIndex?: number | null;
  cropColorMap: ReadonlyMap<string, string>;
  displayPolygonsZoomThreshold?: number | undefined;
  locations: readonly Location[];
  map: google.maps.Map;
  onSelect: ((identifier: LocationUrl, selected: boolean) => void) | undefined;
  readonlySet: ReadonlySet<LocationUrl> | undefined;
  selected: ReadonlySet<LocationUrl> | undefined;
}

export function FieldPolygons(props: FieldPolygonsProps): React.JSX.Element {
  const {
    clusterColor,
    clusterZIndex,
    cropColorMap,
    displayPolygonsZoomThreshold = DEFAULT_DISPLAY_POLYGONS_ZOOM_THRESHOLD,
    locations,
    map,
    onSelect,
    readonlySet,
    selected,
  } = props;

  const instances = useMemo(() => locations.filter(isLocationWithGeoJson), [locations]);

  return (
    <>
      <FieldsClusteredMarkers
        color={clusterColor}
        displayPolygonsZoomThreshold={displayPolygonsZoomThreshold}
        instances={instances}
        map={map}
        zIndex={clusterZIndex}
      />
      <FieldsGeoJson
        cropColorMap={cropColorMap}
        displayPolygonsZoomThreshold={displayPolygonsZoomThreshold}
        instances={instances}
        map={map}
        onSelect={onSelect}
        readonlySet={readonlySet}
        selected={selected}
      />
    </>
  );
}
