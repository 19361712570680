import React, {useCallback} from "react";
import {IdentifierChip} from "./identifier-chip";

interface DragDropReorderIdentifierChipsProps<IdentifierChoice extends string> {
  identifiers: readonly IdentifierChoice[];
  itemType: string | symbol;
  onChange: (reorderedIdentifiers: readonly IdentifierChoice[]) => void;
}

export function DragDropReorderIdentifierChips<IdentifierChoice extends string>(
  props: DragDropReorderIdentifierChipsProps<IdentifierChoice>,
): React.JSX.Element {
  const {identifiers, itemType, onChange} = props;

  const handleDrop = useCallback(
    (source: IdentifierChoice, target: IdentifierChoice): void => {
      const reorderedIdentifiers = identifiers.slice();
      const sourceIndex = identifiers.indexOf(source);
      const targetIndex = identifiers.indexOf(target);
      reorderedIdentifiers.splice(sourceIndex, 1);
      reorderedIdentifiers.splice(targetIndex, 0, source);
      onChange(reorderedIdentifiers);
    },
    [identifiers, onChange],
  );

  return (
    <span>
      {identifiers.map((identifier) => (
        <IdentifierChip<IdentifierChoice>
          identifier={identifier}
          itemType={itemType}
          key={identifier}
          onDrop={handleDrop}
        />
      ))}
    </span>
  );
}
