import {formatDateNumeric, formatTime} from "@co-common-libs/utils";
import React from "react";
import {FormattedMessage} from "react-intl";

const GOOGLE_MAPS_DECIMALS = 7;

interface RouteJournalProps {
  data: {
    date: string;
    employeeAlias: string;
    geolocation: {latitude: number; longitude: number} | null;
    invoiceCustomerName: string;
    locationAddress: string;
    locationName: string;
    minutes: number;
    routePlanName: string;
    startTimestamp: string;
    stopTimestamp: string;
    variantIdentifier: string;
    variantName: string;
  }[];
  filteringInvoiceCustomerNames: string[];
  filteringLocationNames: string[];
  filteringRoutePlanNames: string[];
  fromDate: string;
  showFilteringCriteria: boolean;
  showInvoiceCustomerColumn: boolean;
  toDate: string;
}

export function RouteJournal(props: RouteJournalProps): React.JSX.Element {
  const {
    data,
    filteringInvoiceCustomerNames,
    filteringLocationNames,
    filteringRoutePlanNames,
    fromDate,
    showFilteringCriteria,
    showInvoiceCustomerColumn,
    toDate,
  } = props;
  const hasStartStop = data.some((entry) => entry.startTimestamp && entry.stopTimestamp);
  const rows = data.map(
    (
      {
        date,
        employeeAlias,
        geolocation,
        invoiceCustomerName,
        locationAddress,
        locationName,
        minutes,
        routePlanName,
        startTimestamp,
        stopTimestamp,
        variantIdentifier,
        variantName,
      },
      index,
    ) => {
      let googleMapsURL;
      let latitude, longitude;
      if (geolocation) {
        ({latitude, longitude} = geolocation);
        googleMapsURL =
          latitude && longitude ? `https://www.google.com/maps?q=${latitude},${longitude}` : "";
      } else {
        googleMapsURL = "";
      }
      return (
        <tr key={index} style={{borderTop: "1px solid black"}}>
          <td style={{textAlign: "left"}}>{formatDateNumeric(date)}</td>
          {showInvoiceCustomerColumn ? (
            <td style={{textAlign: "left"}}>{invoiceCustomerName}</td>
          ) : null}
          <td style={{textAlign: "left"}}>{locationName}</td>
          <td style={{textAlign: "left"}}>{locationAddress}</td>
          <td style={{textAlign: "left"}}>{routePlanName}</td>
          <td style={{textAlign: "left"}}>{variantIdentifier}</td>
          <td style={{textAlign: "left"}}>{variantName}</td>
          <td style={{textAlign: "left"}}>{employeeAlias}</td>
          {hasStartStop ? <td style={{textAlign: "left"}}>{formatTime(startTimestamp)}</td> : null}
          <td style={{textAlign: "left"}}>{formatTime(stopTimestamp)}</td>
          {hasStartStop ? <td style={{textAlign: "right"}}>{minutes}</td> : null}
          <td style={{textAlign: "left"}}>
            {latitude && longitude ? (
              <a href={googleMapsURL} rel="noreferrer" target="_blank">
                {latitude.toFixed(GOOGLE_MAPS_DECIMALS)}
                <br />
                {longitude.toFixed(GOOGLE_MAPS_DECIMALS)}
              </a>
            ) : null}
          </td>
        </tr>
      );
    },
  );
  return (
    <div
      style={{
        fontFamily: "Linux Biolinum O, sans-serif",
        fontSize: "11pt",
        width: "100%",
      }}
    >
      <FormattedMessage
        defaultMessage="Rutejournal"
        id="route-journal.title.route-journal"
        tagName="h3"
      />
      <FormattedMessage
        defaultMessage="Periode: {fromDate} - {toDate}"
        id="route-journal.title.period"
        tagName="div"
        values={{
          fromDate: formatDateNumeric(fromDate),
          toDate: formatDateNumeric(toDate),
        }}
      />
      {showFilteringCriteria ? (
        <>
          {filteringRoutePlanNames.length ? (
            <FormattedMessage
              defaultMessage="Ruter: {routes}"
              id="route-journal.text.route-plan-list"
              tagName="div"
              values={{routes: filteringRoutePlanNames.join(", ")}}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Ruter: Alle"
              id="route-journal.text.all-route-plans"
              tagName="div"
            />
          )}
          {filteringLocationNames.length ? (
            <FormattedMessage
              defaultMessage="Steder: {locations}"
              id="route-journal.text.location-list"
              tagName="div"
              values={{customers: filteringLocationNames.join(", ")}}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Steder: Alle"
              id="route-journal.text.all-locations"
              tagName="div"
            />
          )}
          {filteringInvoiceCustomerNames.length ? (
            <FormattedMessage
              defaultMessage="Fakturakunder: {customers}"
              id="route-journal.text.invoice-customer-list"
              tagName="div"
              values={{customers: filteringInvoiceCustomerNames.join(", ")}}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Fakturekunder: Alle"
              id="route-journal.text.all-invoice-customers"
              tagName="div"
            />
          )}
        </>
      ) : null}
      <table style={{borderCollapse: "collapse", width: "100%"}}>
        <thead>
          <tr>
            <th style={{textAlign: "left"}}>
              <FormattedMessage defaultMessage="Dato" id="route-journal.table-header.date" />
            </th>
            {showInvoiceCustomerColumn ? (
              <th style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="Fakturakunder"
                  id="route-journal.table-header.invoice-customer"
                />
              </th>
            ) : null}
            <th style={{textAlign: "left"}}>
              <FormattedMessage defaultMessage="Sted" id="route-journal.table-header.location" />
            </th>
            <th style={{textAlign: "left"}}>
              <FormattedMessage defaultMessage="Adresse" id="route-journal.table-header.adress" />
            </th>
            <th style={{textAlign: "left"}}>
              <FormattedMessage defaultMessage="Route" id="route-journal.table-header.route" />
            </th>
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Variant-ID"
                id="route-journal.table-header.variant-identifier"
              />
            </th>
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Variant-navn"
                id="route-journal.table-header.variant-name"
              />
            </th>
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Medarb."
                id="route-journal.table-header.employee-alias"
              />
            </th>
            {hasStartStop ? (
              <th style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="Start"
                  id="route-journal.table-header.start-time"
                />
              </th>
            ) : null}
            {hasStartStop ? (
              <th style={{textAlign: "left"}}>
                <FormattedMessage defaultMessage="Stop" id="route-journal.table-header.stop-time" />
              </th>
            ) : (
              <th style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="Udført"
                  id="route-journal.table-header.completed-time"
                />
              </th>
            )}
            {hasStartStop ? (
              <th style={{textAlign: "right"}}>
                <FormattedMessage
                  defaultMessage="Minutter"
                  id="route-journal.table-header.minutes"
                />
              </th>
            ) : null}
            {hasStartStop ? (
              <th style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="GPS start"
                  id="route-journal.table-header.gps-start"
                />
              </th>
            ) : (
              <th style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="GPS udført"
                  id="route-journal.table-header.gps-stop"
                />
              </th>
            )}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}
