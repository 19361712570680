import {Location, LocationTypeUrl} from "@co-common-libs/resources";

const numberCompare = (a: number, b: number): number => a - b;

function getBbox(geometry: any): [number, number, number, number] {
  const observedLat: number[] = [];
  const observedLng: number[] = [];

  const processEntry = ([lng, lat]: [number, number]): void => {
    observedLat.push(lat);
    observedLng.push(lng);
  };

  const processArray = (arr: [number, number][]): void => {
    arr.forEach(processEntry);
  };

  const processPolygon = (polygon: [number, number][][]): void => {
    polygon.forEach(processArray);
  };

  const processMultiPolygon = (multiPolygon: [number, number][][][]): void => {
    multiPolygon.forEach(processPolygon);
  };

  if (geometry.type === "MultiPolygon") {
    processMultiPolygon(geometry.coordinates);
  } else if (geometry.type === "Polygon") {
    processPolygon(geometry.coordinates);
  } else {
    throw new Error(`Unsupported geometry type: ${geometry.type}`);
  }
  observedLng.sort(numberCompare);
  observedLat.sort(numberCompare);
  // southwest; northeast
  return [
    observedLng[0],
    observedLat[0],
    observedLng[observedLng.length - 1],
    observedLat[observedLat.length - 1],
  ];
}

type OnlineFieldLocationMembers =
  | "fieldAreaHa"
  | "fieldBlock"
  | "fieldCrop"
  | "fieldFromUpload"
  | "fieldJournalNumber"
  | "fieldNumber"
  | "fieldRecordYear"
  | "fieldVatNumber"
  | "geojson"
  | "latitude"
  | "locationType"
  | "longitude"
  | "name";

export type OnlineFieldLocationPart = Pick<Location, OnlineFieldLocationMembers>;

interface RemoteField {
  basicPaymentApplied: boolean | null;
  basicPaymentAreaHa: number | null;
  cropCode: number | null;
  cropName: string;
  fieldBlock: string;
  fieldNumber: string;
  geometry: {
    bbox: [number, number, number, number];
    coordinates: [number, number][][] | [number, number][][][];
    type: "MultiPolygon" | "Polygon";
  };
  recordNumber: string;
  recordYear: number;
  registrationNumber: string;
  totalGeographicAreaHa: number;
}

interface RemoteFieldDicts {
  [registrationNumber: string]: {
    [fieldBlock: string]: {[fieldNumber: string]: RemoteField};
  };
}

export function convertRemoteFieldFeatures(
  data: RemoteFieldDicts,
  locationTypeURL: LocationTypeUrl | null,
): OnlineFieldLocationPart[] {
  const result: OnlineFieldLocationPart[] = [];

  for (const [fieldVatNumber, entriesForRegistrationNumber] of Object.entries(data)) {
    for (const [fieldBlock, entriesForFieldBlock] of Object.entries(entriesForRegistrationNumber)) {
      for (const [fieldNumber, field] of Object.entries(entriesForFieldBlock)) {
        const {basicPaymentAreaHa, cropName, geometry, recordNumber, recordYear} = field;

        const latitude = (geometry.bbox[1] + geometry.bbox[3]) / 2;

        const longitude = (geometry.bbox[0] + geometry.bbox[2]) / 2;
        result.push({
          fieldAreaHa: basicPaymentAreaHa,
          fieldBlock,
          fieldCrop: cropName,
          fieldFromUpload: false,
          fieldJournalNumber: recordNumber,
          fieldNumber,
          fieldRecordYear: recordYear,
          fieldVatNumber,
          geojson: {geometry, type: "Feature"},
          latitude,
          locationType: locationTypeURL,
          longitude,
          name: fieldNumber,
        });
      }
    }
  }

  return result;
}

function getFieldAreaHa(geojson: any): number | null {
  const {properties} = geojson;
  if (!properties) {
    return null;
  }
  for (const [key, value] of Object.entries(properties)) {
    const loweredKey = key.toLowerCase();
    if (loweredKey === "areal") {
      const result = parseFloat(value as any);
      if (!isNaN(result)) {
        return result;
      }
    }
    if (loweredKey === "dbarea" || loweredKey === "db_area") {
      const parts = (value as any).trim().split(" ");

      if (parts.length !== 2) {
        continue;
      }
      const [numberString, unit] = parts;
      if (unit.toLowerCase() === "ha") {
        const result = parseFloat(numberString.replace(",", "."));
        if (!isNaN(result)) {
          return result;
        }
      }
    }
  }
  for (const [key, value] of Object.entries(properties)) {
    const loweredKey = key.toLowerCase();
    if (loweredKey === "geoarea" || loweredKey === "geo_area") {
      const parts = (value as any).trim().split(" ");

      if (parts.length !== 2) {
        continue;
      }
      const [numberString, unit] = parts;
      if (unit.toLowerCase() === "ha") {
        const result = parseFloat(numberString.replace(",", "."));
        if (!isNaN(result)) {
          return result;
        }
      }
    }
  }
  return null;
}

function getFieldCrop(geojson: any): string {
  const {properties} = geojson;
  if (!properties) {
    return "";
  }
  const cropKeys = ["afgrode", "afgroede", "cropname", "crop_name"];
  for (const [key, value] of Object.entries(properties)) {
    const loweredKey = key.toLowerCase();
    if (cropKeys.includes(loweredKey)) {
      return value as string;
    }
  }
  return "";
}

function getFieldNumber(geojson: any): string {
  const {properties} = geojson;
  if (!properties) {
    return "";
  }
  const fieldNumberKeys = ["fieldnumbe", "markkode", "marknr"];
  for (const [key, value] of Object.entries(properties)) {
    const loweredKey = key.toLowerCase();
    if (fieldNumberKeys.includes(loweredKey)) {
      return value as string;
    }
  }
  return "";
}

type ZipFieldLocationMembers =
  | "fieldAreaHa"
  | "fieldBlock"
  | "fieldCrop"
  | "fieldFromUpload"
  | "fieldNumber"
  | "geojson"
  | "latitude"
  | "locationType"
  | "longitude"
  | "name";

export type ZipFieldLocationPart = Pick<Location, ZipFieldLocationMembers>;

export function convertZIPFieldFeatures(
  data: unknown,
  locationTypeURL: LocationTypeUrl | null,
): ZipFieldLocationPart[] {
  const features = ((data as any).features as any[])
    .map((feature: any): ZipFieldLocationPart => {
      console.assert(feature.type === "Feature");
      const {geometry, properties, type} = feature;
      const geometryWithBbox = {bbox: getBbox(geometry), ...geometry};
      const fieldNumber = getFieldNumber(feature);
      const result: ZipFieldLocationPart = {
        fieldAreaHa: getFieldAreaHa(feature),
        fieldBlock: "",
        fieldCrop: getFieldCrop(feature),
        fieldFromUpload: true,
        fieldNumber,
        geojson: {geometry: geometryWithBbox, properties, type},

        latitude: (geometryWithBbox.bbox[1] + geometryWithBbox.bbox[3]) / 2,
        locationType: locationTypeURL,

        longitude: (geometryWithBbox.bbox[0] + geometryWithBbox.bbox[2]) / 2,
        name: fieldNumber,
      };
      return result;
    })
    .filter((entry) => entry.fieldNumber);
  return features;
}

type CommonLocationPartMembers = OnlineFieldLocationMembers & ZipFieldLocationMembers;

type AllLocationPartMembers = OnlineFieldLocationMembers | ZipFieldLocationMembers;

type ExclusiveLocationPartMembers = Exclude<AllLocationPartMembers, CommonLocationPartMembers>;

export type LocationPart = Partial<Pick<Location, ExclusiveLocationPartMembers>> &
  Pick<Location, CommonLocationPartMembers>;

export function getKey(location: LocationPart): string {
  return `${location.fieldBlock} \n---\n ${location.fieldNumber}`;
}
