import {Config} from "@co-common-libs/config";
import {User, UserUrl} from "@co-common-libs/resources";
import {IntegerField, ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent, FormControlLabel, IconButton, Radio, RadioGroup} from "@material-ui/core";
import {green, red} from "@material-ui/core/colors";
import ThumbDownIcon from "mdi-react/ThumbDownIcon";
import ThumbDownOutlineIcon from "mdi-react/ThumbDownOutlineIcon";
import ThumbUpIcon from "mdi-react/ThumbUpIcon";
import ThumbUpOutlineIcon from "mdi-react/ThumbUpOutlineIcon";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";

interface ThumbsBlockProps {
  onChange: (value: boolean) => void;
  value?: boolean | undefined;
}

const ThumbsBlock = React.memo(function ThumbsBlock({
  onChange,
  value,
}: ThumbsBlockProps): React.JSX.Element {
  const handleYesClick = useCallback(() => onChange(true), [onChange]);

  const handleNoClick = useCallback(() => onChange(false), [onChange]);

  const baseSize = 48;
  const buttonStyle = {
    height: 2 * baseSize,

    padding: baseSize / 2,

    width: 2 * baseSize,
  };
  let thumbDownIcon, thumbUpIcon;
  if (value === true) {
    thumbUpIcon = <ThumbUpIcon color={green[300]} size={baseSize} />;
    thumbDownIcon = <ThumbDownOutlineIcon size={baseSize} />;
  } else if (value === false) {
    thumbUpIcon = <ThumbUpOutlineIcon size={baseSize} />;
    thumbDownIcon = <ThumbDownIcon color={red[300]} size={baseSize} />;
  } else {
    thumbUpIcon = <ThumbUpOutlineIcon size={baseSize} />;
    thumbDownIcon = <ThumbDownOutlineIcon size={baseSize} />;
  }
  const yesButtonStyle: React.CSSProperties =
    value === true ? {fill: green[300], ...buttonStyle} : buttonStyle;
  const noButtonStyle: React.CSSProperties =
    value === false ? {fill: red[300], ...buttonStyle} : buttonStyle;
  return (
    <div>
      <IconButton onClick={handleYesClick} style={yesButtonStyle}>
        {thumbUpIcon}
      </IconButton>
      <IconButton onClick={handleNoClick} style={noButtonStyle}>
        {thumbDownIcon}
      </IconButton>
    </div>
  );
});

interface DinnerBookingDialogProps {
  count?: number | undefined;
  customerSettings: Config;
  date: string;
  dinnerLocation?: string | undefined;
  employee?: User | undefined;
  lunchCount?: number | undefined;
  lunchLocation?: string | undefined;
  onCancel?: () => void;
  onOk: (
    date: string,
    employeeURL: UserUrl,
    dinnerCount: number,
    lunchCount: number | null,
    dinnerLocation: string | null,
    lunchLocation: string | null,
  ) => void;
  open: boolean;
  showDinnerBooking?: boolean | undefined;
  showLunchBooking?: boolean | undefined;
}

export const DinnerBookingDialog = React.memo(function DinnerBookingDialog(
  props: DinnerBookingDialogProps,
): React.JSX.Element {
  const intl = useIntl();
  const {
    count: countFromProps,
    customerSettings,
    date,
    dinnerLocation: dinnerLocationFromProps,
    employee,
    lunchCount: lunchCountFromProps,
    lunchLocation: lunchLocationFromProps,
    onCancel,
    onOk,
    open,
    showDinnerBooking = false,
    showLunchBooking = false,
  } = props;

  const [count, setCount] = useState(countFromProps);
  const [dinnerLocation, setDinnerLocation] = useState(dinnerLocationFromProps);
  const [lunchCount, setLunchCount] = useState(lunchCountFromProps);
  const [lunchLocation, setLunchLocation] = useState(lunchLocationFromProps);

  useEffect(() => {
    setCount(countFromProps);
    setDinnerLocation(dinnerLocationFromProps);
    setLunchCount(lunchCountFromProps);
    setLunchLocation(lunchLocationFromProps);
  }, [countFromProps, dinnerLocationFromProps, lunchCountFromProps, lunchLocationFromProps]);

  const handleCountChange = useCallback((value: number | null) => setCount(value || 0), []);

  const handleThumbChange = useCallback((value: boolean) => {
    if (value) {
      setCount(1);
    } else {
      setCount(0);
    }
  }, []);

  const handleDinneLocationChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setDinnerLocation(event.target.value),
    [],
  );

  const handleLunchCountChange = useCallback(
    (value: number | null) => setLunchCount(value || 0),
    [],
  );

  const handleLunchThumbChange = useCallback((value: boolean) => {
    if (value) {
      setLunchCount(1);
    } else {
      setLunchCount(0);
    }
  }, []);

  const handleLunchLocationChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setLunchLocation(event.target.value),
    [],
  );

  const handleOk = useCallback(() => {
    onOk(
      date,
      (employee as User).url,
      count || 0,
      lunchCount != null ? lunchCount : null,
      dinnerLocation || null,
      lunchLocation || null,
    );
  }, [count, date, dinnerLocation, employee, lunchCount, lunchLocation, onOk]);

  const {
    dinnerBookingLocations,
    dinnerBookings,
    dinnerBookingsShowThumbDialog,
    lunchBookingLocations,
    lunchBookings,
  } = customerSettings;

  let okDisabled = false;
  let dinnerBookingField;
  let dinnerBookingLocationField;
  let lunchBookingField;
  let lunchBookingLocationField;
  if (dinnerBookings && showDinnerBooking) {
    if (count == null) {
      okDisabled = true;
    }
    if (dinnerBookingsShowThumbDialog) {
      let thumbValue;
      if (count && count > 0) {
        thumbValue = true;
      } else if (count === 0) {
        thumbValue = false;
      }
      dinnerBookingField = (
        <div key="dinner-booking-thumb">
          <FormattedMessage defaultMessage="Aftensmad" tagName="h3" />
          <ThumbsBlock onChange={handleThumbChange} value={thumbValue} />
        </div>
      );
    } else {
      dinnerBookingField = (
        <div key="dinner-booking-field">
          <FormattedMessage defaultMessage="Aftensmad" tagName="h3" />
          <IntegerField
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Antal",
            })}
            margin="dense"
            onChange={handleCountChange}
            value={count != null ? count : null}
          />
        </div>
      );
    }
  }
  if (dinnerBookingLocations.length) {
    if (count && !dinnerLocation) {
      okDisabled = true;
    }
    const dinnerBookingLocationRadioButtons = dinnerBookingLocations.map((label) => (
      <FormControlLabel
        control={<Radio />}
        disabled={!count}
        key={label}
        label={label}
        value={label}
      />
    ));
    dinnerBookingLocationField = (
      <RadioGroup name="dinnerLocation" onChange={handleDinneLocationChange} value={dinnerLocation}>
        {dinnerBookingLocationRadioButtons}
      </RadioGroup>
    );
  }
  if (lunchBookings && showLunchBooking) {
    if (lunchCount == null) {
      okDisabled = true;
    }
    if (dinnerBookingsShowThumbDialog) {
      let lunchThumbValue;
      if (lunchCount && lunchCount > 0) {
        lunchThumbValue = true;
      } else if (lunchCount === 0) {
        lunchThumbValue = false;
      }
      lunchBookingField = (
        <div key="lunch-booking-thumb">
          <FormattedMessage defaultMessage="Frokost" tagName="h3" />
          <ThumbsBlock onChange={handleLunchThumbChange} value={lunchThumbValue} />
        </div>
      );
    } else {
      lunchBookingField = (
        <div key="lunch-booking-field">
          <FormattedMessage defaultMessage="Frokost" tagName="h3" />
          <IntegerField
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Antal",
            })}
            margin="dense"
            onChange={handleLunchCountChange}
            value={lunchCount != null ? lunchCount : null}
          />
        </div>
      );
    }
    if (lunchBookingLocations.length) {
      if (lunchCount && !lunchLocation) {
        okDisabled = true;
      }
      const lunchBookingLocationRadioButtons = lunchBookingLocations.map((label) => (
        <FormControlLabel
          control={<Radio />}
          disabled={!lunchCount}
          key={label}
          label={label}
          value={label}
        />
      ));
      lunchBookingLocationField = (
        <RadioGroup name="lunchLocation" onChange={handleLunchLocationChange} value={lunchLocation}>
          {lunchBookingLocationRadioButtons}
        </RadioGroup>
      );
    }
  }
  return (
    <ResponsiveDialog
      okDisabled={okDisabled}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={intl.formatMessage({
        defaultMessage: "Madbestilling",
      })}
    >
      <DialogContent>
        {dinnerBookingField}
        {dinnerBookingLocationField}
        {lunchBookingField}
        {lunchBookingLocationField}
      </DialogContent>
    </ResponsiveDialog>
  );
});
