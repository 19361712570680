import {Customer, Order, Task, urlToId, UserProfile, WorkType} from "@co-common-libs/resources";
import {formatDuration} from "@co-common-libs/resources-utils";
import {dateToString, formatTime, MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {getCustomerSettings, PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {colorMap, matchingTextColor} from "@co-frontend-libs/utils";
import {Linkify} from "app-components";
import {dateFromDateAndTime} from "app-utils";
import _ from "lodash";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {DAY_HEIGHT, DAY_WIDTH, INITIALS_WIDTH, TIME_DURATION_WIDTH} from "./constants";

interface TaskBlockProps {
  customer?: Customer | undefined;
  date: string;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  offset: number;
  order: Order | undefined;
  task: Task;
  userProfile: UserProfile | undefined;
  workType: WorkType | undefined;
}

export const TaskBlock = React.memo(function TaskBlock(props: TaskBlockProps): React.JSX.Element {
  const {customer, date, go, offset, order, task, userProfile, workType} = props;
  const customerSettings = useSelector(getCustomerSettings);

  const handleGoToTask = useCallback(() => {
    const taskId = urlToId(task.url);
    if (task.order) {
      const orderId = urlToId(task.order);
      go("/order/:id/:taskID", {id: orderId, taskID: taskId});
    } else {
      go("/internalTask/:id", {id: taskId});
    }
  }, [go, task.order, task.url]);
  const backgroundColor = (workType && workType.color) || colorMap.ORDER;
  const color = matchingTextColor(backgroundColor);

  const innerStyle: React.CSSProperties = {
    backgroundColor: "#fff",
    color: "#000",

    height: (DAY_HEIGHT - 4) / 2,

    width: DAY_WIDTH - 4,
  };
  const innerColoredStyle: React.CSSProperties = Object.assign({}, innerStyle, {
    backgroundColor,
    color,
  });

  const boxStyle: React.CSSProperties = {
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 1,
    cursor: "pointer",

    height: DAY_HEIGHT - 2,
    left: 1,
    overflow: "hidden",
    position: "absolute",
    top: offset * DAY_HEIGHT + 1,

    width: DAY_WIDTH - 2,
  };
  const customerName = (customer && customer.name) || "??";
  const taskDate = task.date;
  const {time} = task;
  const duration = task.minutesExpectedTotalTaskDuration;
  let timeFromString = "??:??";
  let timeToString = "??:??";
  if (time) {
    let fromDateTime;
    console.assert(taskDate);
    if ((taskDate as string) < date) {
      fromDateTime = dateFromDateAndTime(date, "00:00");
    } else {
      fromDateTime = dateFromDateAndTime(taskDate as string, time);
    }
    timeFromString = formatTime(fromDateTime);
    if (duration != null) {
      let toDateTime = new Date(fromDateTime.valueOf() + duration * MINUTE_MILLISECONDS);
      if (dateToString(toDateTime) > date) {
        toDateTime = dateFromDateAndTime(date, "24:00");
      }
      timeToString = formatTime(toDateTime);
    }
  }
  const timeString = `${timeFromString}–${timeToString}`;
  const durationString =
    duration != null ? formatDuration(customerSettings.durationFormat, duration) : "??";

  const timeDuration = `${timeString}, ${durationString}`;
  const noteParts = [task.notesFromManager, order && order.notes];
  const taskFieldUseList = task.fielduseSet;
  if (taskFieldUseList && !!taskFieldUseList.length) {
    taskFieldUseList.forEach((fieldUse) => {
      noteParts.push(fieldUse.notes);
    });
    const orderFieldUseList = order && order.orderfielduseSet;
    if (orderFieldUseList && !!orderFieldUseList.length) {
      orderFieldUseList.forEach((orderFieldUse) => {
        const fieldURL = orderFieldUse.relatedField;
        if (taskFieldUseList.some((taskFieldUse) => taskFieldUse.relatedField === fieldURL)) {
          noteParts.push(orderFieldUse.notes);
        }
      });
    }
  }
  const note = noteParts.filter(_.identity).join("\n");
  const initials = (userProfile && userProfile.alias) || "??";

  const nameStyle: React.CSSProperties = {
    display: "inline-block",
    overflow: "hidden",
    paddingLeft: 1,
    paddingRight: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

    width: DAY_WIDTH - 4 - TIME_DURATION_WIDTH,
  };
  const timeDurationStyle: React.CSSProperties = {
    display: "inline-block",
    overflow: "hidden",
    paddingLeft: 1,
    paddingRight: 1,
    textAlign: "right",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: TIME_DURATION_WIDTH,
  };
  const noteStyle: React.CSSProperties = {
    display: "inline-block",
    overflow: "hidden",
    paddingLeft: 1,
    paddingRight: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

    width: DAY_WIDTH - 4 - INITIALS_WIDTH,
  };
  const initialsStyle: React.CSSProperties = {
    display: "inline-block",
    overflow: "hidden",
    paddingLeft: 1,
    paddingRight: 1,
    width: INITIALS_WIDTH,
  };
  return (
    <div onClick={handleGoToTask} style={boxStyle}>
      <div style={innerColoredStyle}>
        <div style={nameStyle}>{customerName}</div>
        <div style={timeDurationStyle}>{timeDuration}</div>
      </div>
      <div style={innerStyle}>
        <div style={noteStyle}>
          <Linkify>{note}</Linkify>
        </div>
        <div style={initialsStyle}>{initials}</div>
      </div>
    </div>
  );
});
