import {actions, AppState, getDeviceConfigKey} from "@co-frontend-libs/redux";
import {bind} from "bind-decorator";
import React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {SORT_DIRECTIONS, SortDirection} from "../table-sort";

interface WithPersistedSortingStateBaseState {
  sortDirection: SortDirection;
  sortKey: string | null;
  tableSortSetting: {[identifier: string]: {[key: string]: SortDirection}};
}

class WithPersistedSortingStateBase<
  T extends {saveTableSort: (value: any) => void; tableSortSetting: any},
> extends React.Component<T, WithPersistedSortingStateBaseState> {
  _defaultDirection: SortDirection;
  _defaultKey: string | null;
  _identifier: string;
  state: WithPersistedSortingStateBaseState = {
    sortDirection: SORT_DIRECTIONS.ASC,
    sortKey: null,
    tableSortSetting: {},
  };
  constructor(
    props: T,
    identifier: string,
    defaultKey: string | null,
    defaultDirection: SortDirection,
  ) {
    super(props);
    this._identifier = identifier;
    this._defaultKey = defaultKey;
    this._defaultDirection = defaultDirection;
    const tableSortSetting = props.tableSortSetting || {};
    this.state.tableSortSetting = tableSortSetting;
    const currrentTableSettings = tableSortSetting ? tableSortSetting[identifier] : null;
    if (currrentTableSettings && Object.keys(currrentTableSettings).length) {
      const sortKey = Object.keys(currrentTableSettings)[0];
      const sortDirection = currrentTableSettings[sortKey];
      this.state.sortKey = sortKey;
      this.state.sortDirection = sortDirection;
    } else {
      this.state.sortKey = defaultKey;
      this.state.sortDirection = defaultDirection;
    }
  }
  @bind
  handleSortChange(columnKey: string, newDirection: SortDirection): void {
    this.setState({
      sortDirection: newDirection,
      sortKey: columnKey,
    });
    const oldValue = this.state.tableSortSetting;
    const newSort = {[columnKey]: newDirection};
    const newValue = {...oldValue};
    newValue[this._identifier] = newSort;
    this.props.saveTableSort(newValue);
  }
}

function wrapWithPersistedSortingState<T>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: React.ComponentType<
    T & {
      onSortChange: (columnKey: string, newDirection: SortDirection) => void;
      sortDirection: SortDirection;
      sortKey: string | null;
    }
  >,
  identifier: string,
  defaultKey: string | null,
  defaultDirection: SortDirection,
): React.ComponentType<T> {
  const componentName = Component.displayName || Component.name || "";
  const wrapperName = `WithPersistedSortingState(${componentName})`;
  class WithPersistedSortingState extends WithPersistedSortingStateBase<
    T & {saveTableSort: (value: any) => void; tableSortSetting: any}
  > {
    static displayName = wrapperName;
    constructor(props: T & {saveTableSort: (value: any) => void; tableSortSetting: any}) {
      super(props, identifier, defaultKey, defaultDirection);
    }
    render(): React.JSX.Element {
      return (
        <Component
          onSortChange={this.handleSortChange}
          sortDirection={this.state.sortDirection}
          sortKey={this.state.sortKey}
          {...this.props}
        />
      );
    }
  }

  const WrappedWithPersistedSortingState: React.ComponentType<T> = connect<
    {tableSortSetting: any},
    {saveTableSort: (value: any) => void},
    T,
    AppState
  >(
    createStructuredSelector<AppState, {tableSortSetting: any}>({
      tableSortSetting: getDeviceConfigKey("tableSort"),
    }),
    {
      saveTableSort: (value: any): ReturnType<typeof actions.configPut> =>
        actions.configPut({key: "tableSort", value}),
    },
  )(WithPersistedSortingState as any) as any;
  return WrappedWithPersistedSortingState;
}

export interface PersistSortingStateProps {
  onSortChange: (columnKey: string, newDirection: SortDirection) => void;
  sortDirection: SortDirection;
  sortKey: string | null;
}

export function persistSortingState(
  identifier: string,
  defaultKey: string | null = null,
  defaultDirection: SortDirection = SORT_DIRECTIONS.ASC,
): <T>(Component: React.ComponentType<PersistSortingStateProps & T>) => React.ComponentType<T> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return (Component) =>
    wrapWithPersistedSortingState(Component, identifier, defaultKey, defaultDirection);
}
