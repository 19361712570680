import {getShareToken} from "@co-frontend-libs/redux";
import {Button, Card, CardHeader} from "@material-ui/core";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {AppendicesLogTable} from "./appendices-log-table";
import {LogTaskData} from "./types";

const messages = defineMessages({
  logCardTitle: {
    defaultMessage: "Logs",
    id: "appendices-display-cards.log-card.title",
  },
  logs: {
    defaultMessage: "logs",
    id: "appendices-display-cards.log-card.logs",
  },
});

interface LogsCardProps {
  data: readonly LogTaskData[] | null;
}

export const LogsCard = React.memo(function LogsCard(
  props: LogsCardProps,
): React.JSX.Element | null {
  const [checkedLogs, setCheckedLogs] = useState(new Set<string>());
  const {data} = props;
  const shareToken = useSelector(getShareToken);

  const intl = useIntl();
  useEffect(() => {
    setCheckedLogs(new Set());
  }, [data]);

  const handleLogCheckAllCheck = useCallback((): void => {
    if (!data) {
      return;
    }
    if (checkedLogs.size < data.length) {
      setCheckedLogs(new Set(data.map((taskData) => taskData.id)));
    } else {
      setCheckedLogs(new Set());
    }
  }, [checkedLogs.size, data]);

  const handleLogCheckboxCheck = useCallback(
    (id: string, checked: boolean): void => {
      const newCheckedLogs = new Set(checkedLogs);
      if (checked) {
        newCheckedLogs.add(id);
      } else {
        newCheckedLogs.delete(id);
      }
      setCheckedLogs(newCheckedLogs);
    },
    [checkedLogs],
  );

  const {baseURL} = globalConfig.resources;

  if (!data) {
    return null;
  }

  const taskIds = Array.from(checkedLogs)
    .map((id) => `&taskIds=${id}`)
    .join("");

  return (
    <Card style={{marginTop: 24}}>
      <div style={{position: "relative"}}>
        <div
          style={{
            position: "absolute",
            right: "1em",
            top: 14,
          }}
        >
          <Button
            disabled={!checkedLogs.size}
            href={`${baseURL}appendicesLogPdfDownload/${intl.formatMessage(
              messages.logs,
            )}.pdf?token=${shareToken}${taskIds}`}
            target={window.cordova && bowser.ios ? "_system" : "_blank"}
          >
            <FormattedMessage defaultMessage="Download PDF" />
          </Button>
        </div>
        <div
          style={{
            position: "absolute",
            right: 160,
            top: 14,
          }}
        >
          <Button
            disabled={!checkedLogs.size}
            href={`${baseURL}appendicesLogZipDownload/${intl.formatMessage(
              messages.logs,
            )}.pdf?token=${shareToken}${taskIds}`}
          >
            <FormattedMessage defaultMessage="Download ZIP" />
          </Button>
        </div>
      </div>
      <CardHeader title={intl.formatMessage(messages.logCardTitle)} />

      <AppendicesLogTable
        checkedLogs={checkedLogs}
        logTasks={data}
        onCheckAllCheck={handleLogCheckAllCheck}
        onCheckboxCheck={handleLogCheckboxCheck}
      />
    </Card>
  );
});
