import {Config} from "@co-common-libs/config";
import {
  PriceItem,
  PriceItemUrl,
  PriceItemUsesDict,
  ReportingInputSpecification,
  ReportingSpecification,
  Task,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import _ from "lodash";
import {includePriceItemInLogs, ITEM_TYPE_STARTPRIS} from "../price-item";
import {getUnitCode} from "../unit";
import {computeLogSums} from "./compute-log-sums";
import {getLogTotalsSpec} from "./get-log-totals-spec";

export function getPriceItemUsesCountsFromLogEntries(
  logSpecification: ReportingSpecification,
  customerSettings: Config,
  task: Pick<
    Task,
    "logSkipped" | "priceItemUses" | "productUses" | "reportingLocations" | "reportingLog"
  >,
  priceItemUsesFromPatch: PriceItemUsesDict | undefined,
  inputSpecificationsMap: Map<string, ReportingInputSpecification>,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): Map<string, number | null> | null {
  if (task.logSkipped) {
    return null;
  }
  // identifier -> contributes-to-sum-label
  // identifier -> contributes-to-transfer-unit
  const [logSummedIdentifierLabels, logTransferUnits] = getLogTotalsSpec(logSpecification);

  // sum of the log "values" (not price item uses or product uses)
  const sums = computeLogSums(
    customerSettings,
    task.reportingLog,
    task.reportingLocations || {},
    logSummedIdentifierLabels,
    inputSpecificationsMap,
  );

  const newPriceItemUsesCounts = new Map<string, number | null>();

  if (task.priceItemUses && logTransferUnits.size) {
    const transferUnitCounts = new Map<string, number>();
    logTransferUnits.forEach((unit, identifier) => {
      const sum = sums.get(identifier);
      if (sum != null) {
        transferUnitCounts.set(unit, sum);
      }
    });
    for (const [identifier, priceItemUse] of Object.entries(task.priceItemUses)) {
      const priceItem = priceItemLookup(priceItemUse.priceItem);
      if (
        !priceItem ||
        priceItem.includeInLogs === false ||
        priceItem.itemtype === ITEM_TYPE_STARTPRIS
      ) {
        continue;
      }
      const priceItemUnitLowerCase = getUnitCode(priceItem, unitLookup).toLowerCase();
      const sum = transferUnitCounts.get(priceItemUnitLowerCase);
      if (sum != null) {
        newPriceItemUsesCounts.set(identifier, sum);
      }
    }
  }

  const priceItemUsesFromPatchOrTask = priceItemUsesFromPatch || task.priceItemUses;
  if (
    priceItemUsesFromPatchOrTask &&
    (logSpecification.workplaceData.workplace?.logPriceItems ||
      logSpecification.workplaceData.delivery?.logPriceItems)
  ) {
    const counts = new Map<string, number | null>();
    for (const logEntry of Object.values(task.reportingLog)) {
      if (!logEntry.priceItemUses) {
        continue;
      }
      for (const priceItemUse of Object.values(logEntry.priceItemUses)) {
        const {count, priceItem: url} = priceItemUse;
        if (typeof count === "number" && logEntry.type !== "pickup") {
          counts.set(url, (counts.get(url) || 0) + count);
        } else if (!counts.has(url)) {
          counts.set(url, null);
        }
      }
    }
    const taskOrPatchPriceItemUseArray = Object.values(priceItemUsesFromPatchOrTask);
    for (const [identifier, priceItemUse] of Object.entries(priceItemUsesFromPatchOrTask)) {
      const priceItem = priceItemLookup(priceItemUse.priceItem);
      if (
        !priceItem ||
        !includePriceItemInLogs(
          unitLookup,
          taskOrPatchPriceItemUseArray,
          priceItem,
          priceItemLookup,
        )
      ) {
        continue;
      }
      // only use count entry once, then remove
      const logCount = counts.get(priceItemUse.priceItem) ?? null;
      counts.delete(priceItemUse.priceItem);
      newPriceItemUsesCounts.set(identifier, logCount);
    }
    if (task.priceItemUses && !_.isEqual(task.priceItemUses, priceItemUsesFromPatchOrTask)) {
      const taskPriceItemUseArray = Object.values(task.priceItemUses);
      for (const [identifier, priceItemUse] of Object.entries(task.priceItemUses)) {
        const priceItem = priceItemLookup(priceItemUse.priceItem);
        if (
          !priceItem ||
          !includePriceItemInLogs(unitLookup, taskPriceItemUseArray, priceItem, priceItemLookup)
        ) {
          continue;
        }
        // readd if removed by patch but still present in counts
        const logCount = counts.get(priceItemUse.priceItem);
        if (logCount !== undefined) {
          newPriceItemUsesCounts.set(identifier, logCount);
        }
      }
    }
  }

  return newPriceItemUsesCounts;
}
