import {resourceNameFor, TaskPhoto, urlToId} from "@co-common-libs/resources";
import {actions, getShareToken} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
} from "@material-ui/core";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import memoizeOne from "memoize-one";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {PhotoDisplayDialog} from "../photo-display-dialog";
import {PhotosImageList} from "./photos-image-list";
import {PhotoTaskData} from "./types";

const messages = defineMessages({
  markAll: {
    defaultMessage: "Vælg alle",
  },
  photos: {
    defaultMessage: "fotos",
  },
  photosCardTitle: {
    defaultMessage: "Fotos",
  },
});

const getAllPhotoOrFileURLs = (photoTasks: readonly PhotoTaskData[]): Set<string> => {
  const result = new Set<string>();
  photoTasks.forEach((photoTask) => {
    photoTask.photos.forEach((photo) => {
      result.add(photo.url);
    });
    photoTask.files.forEach((file) => {
      result.add(file.url);
    });
  });
  return result;
};

interface PhotosCardProps {
  data: readonly PhotoTaskData[] | null;
}

export const PhotosCard = React.memo(function PhotosCard(
  props: PhotosCardProps,
): React.JSX.Element | null {
  const [checkedPhotos, setCheckedPhotos] = useState(new Set<string>());
  const [displayedImage, setDisplayedImage] = useState<TaskPhoto | undefined>();
  const dispatch = useDispatch();
  const {data} = props;
  const shareToken = useSelector(getShareToken);
  const intl = useIntl();
  useEffect(() => {
    setCheckedPhotos(new Set());
  }, [data]);

  const pdfButtonRef = useRef<HTMLButtonElement>(null);

  const [pdfMenuOpen, setPdfMenuOpen] = useState(false);
  const setPdfMenuOpenTrue = useCallWithTrue(setPdfMenuOpen);
  const setPdfMenuOpenFalse = useCallWithFalse(setPdfMenuOpen);

  const zipButtonRef = useRef<HTMLButtonElement>(null);
  const [zipMenuOpen, setZipMenuOpen] = useState(false);
  const setZipMenuOpenTrue = useCallWithTrue(setZipMenuOpen);
  const setZipMenuOpenFalse = useCallWithFalse(setZipMenuOpen);

  const memoizedGetAllFileOrPhotoURLs = memoizeOne(getAllPhotoOrFileURLs);

  const handlePhotosCheckAllCheck = useCallback((): void => {
    if (!data) {
      return;
    }
    const allPhotos = memoizedGetAllFileOrPhotoURLs(data);
    if (checkedPhotos.size < allPhotos.size) {
      setCheckedPhotos(allPhotos);
    } else {
      setCheckedPhotos(new Set());
    }
  }, [checkedPhotos.size, data, memoizedGetAllFileOrPhotoURLs]);

  const handlePhotosCheckboxCheck = useCallback(
    (url: string, checked: boolean): void => {
      const newCheckedPhotos = new Set(checkedPhotos);
      if (checked) {
        newCheckedPhotos.add(url);
      } else {
        newCheckedPhotos.delete(url);
      }
      setCheckedPhotos(newCheckedPhotos);
    },
    [checkedPhotos],
  );

  const handleGoToTask = useCallback(
    (url: string): void => {
      const id = urlToId(url);
      dispatch(actions.go("/task/:id", {id}));
    },
    [dispatch],
  );

  const handleDisplayImageRequestClose = useCallback((): void => {
    setDisplayedImage(undefined);
  }, []);

  const {baseURL} = globalConfig;

  const allPhotosChecked = !!(data
    ? checkedPhotos.size && memoizedGetAllFileOrPhotoURLs(data).size === checkedPhotos.size
    : false);

  const fileIds = Array.from(checkedPhotos)
    .filter((url) => resourceNameFor(url) === "taskFile")
    .map((url) => `&fileIds=${urlToId(url)}`)
    .join("");
  const photoIds = Array.from(checkedPhotos)
    .filter((url) => resourceNameFor(url) === "taskPhoto")
    .map((url) => `&photoIds=${urlToId(url)}`)
    .join("");

  const photosCard = data ? (
    <Card style={{marginTop: 24}}>
      <div style={{position: "relative"}}>
        <div
          style={{
            position: "absolute",
            right: "1em",
            top: 14,
          }}
        >
          <Button disabled={!checkedPhotos.size} onClick={setPdfMenuOpenTrue} ref={pdfButtonRef}>
            <FormattedMessage defaultMessage="Download PDF" />
          </Button>
        </div>
        <div
          style={{
            position: "absolute",
            right: 160,
            top: 14,
          }}
        >
          <Button disabled={!checkedPhotos.size} onClick={setZipMenuOpenTrue} ref={zipButtonRef}>
            <FormattedMessage defaultMessage="Download ZIP" />
          </Button>
        </div>
      </div>
      <CardHeader title={intl.formatMessage(messages.photosCardTitle)} />
      <CardContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={allPhotosChecked}
              id="check-all-checkbox"
              name="check-all"
              onChange={handlePhotosCheckAllCheck}
            />
          }
          label={intl.formatMessage(messages.markAll)}
        />
      </CardContent>
      <CardMedia>
        <PhotosImageList
          checkedPhotos={checkedPhotos}
          data={data}
          onCheckboxCheck={handlePhotosCheckboxCheck}
          onImageClick={setDisplayedImage}
          onTitleClick={handleGoToTask}
          shareToken={shareToken || ""}
        />
      </CardMedia>
    </Card>
  ) : null;

  const baseZipUrl = `${baseURL}/download/appendices_photo_zip_download/${intl.formatMessage(
    messages.photos,
  )}.zip?token=${shareToken}${fileIds}${photoIds}`;

  const basePdfUrl = `${baseURL}/download/appendices_photo_pdf_download/${intl.formatMessage(
    messages.photos,
  )}.pdf?token=${shareToken}${fileIds}${photoIds}`;

  return (
    <>
      {photosCard}
      <PhotoDisplayDialog
        instance={displayedImage}
        key="photo-display-dialog"
        onRequestClose={handleDisplayImageRequestClose}
        onRequestGoToTask={handleGoToTask}
      />
      <Menu anchorEl={pdfButtonRef.current} onClose={setPdfMenuOpenFalse} open={pdfMenuOpen}>
        <MenuItem
          component="a"
          href={`${basePdfUrl}&quality=HIGH`}
          target={window.cordova && bowser.ios ? "_system" : "_blank"}
        >
          <FormattedMessage defaultMessage="Høj billedkvalitet" />
        </MenuItem>
        <MenuItem
          component="a"
          href={`${basePdfUrl}&quality=MEDIUM`}
          target={window.cordova && bowser.ios ? "_system" : "_blank"}
        >
          <FormattedMessage defaultMessage="Medium billedkvalitet" />
        </MenuItem>
        <MenuItem
          component="a"
          href={`${basePdfUrl}&quality=LOW`}
          target={window.cordova && bowser.ios ? "_system" : "_blank"}
        >
          <FormattedMessage defaultMessage="Lav billedkvalitet" />
        </MenuItem>
      </Menu>
      <Menu anchorEl={zipButtonRef.current} onClose={setZipMenuOpenFalse} open={zipMenuOpen}>
        <MenuItem
          component="a"
          href={`${baseZipUrl}&quality=HIGH`}
          target={window.cordova && bowser.ios ? "_system" : "_blank"}
        >
          <FormattedMessage defaultMessage="Høj billedkvalitet" />
        </MenuItem>
        <MenuItem
          component="a"
          href={`${baseZipUrl}&quality=MEDIUM`}
          target={window.cordova && bowser.ios ? "_system" : "_blank"}
        >
          <FormattedMessage defaultMessage="Medium billedkvalitet" />
        </MenuItem>
        <MenuItem
          component="a"
          href={`${baseZipUrl}&quality=LOW`}
          target={window.cordova && bowser.ios ? "_system" : "_blank"}
        >
          <FormattedMessage defaultMessage="Lav billedkvalitet" />
        </MenuItem>
      </Menu>
    </>
  );
});
