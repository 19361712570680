import {ReportingData, ReportingSpecification, Task} from "@co-common-libs/resources";
import {getInputSpecificationsMap} from "@co-common-libs/resources-utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {getCustomerSettings, getUnitLookup} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {valuesReducer} from "app-utils";
import React, {useCallback, useEffect, useMemo, useReducer} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {buildDialogFields} from "./build-dialog-fields";

interface ReportingDialogProps {
  logSpecification: ReportingSpecification;
  onCancel: () => void;
  onOk: (values: ReportingData) => void;
  open: boolean;
  task: Task;
  title: string;
}

export const ReportingDialog = React.memo(function ReportingDialog(
  props: ReportingDialogProps,
): React.JSX.Element {
  const {logSpecification, onCancel, onOk, open, task, title} = props;

  const unitLookup = useSelector(getUnitLookup);
  const customerSettings = useSelector(getCustomerSettings);

  const inputSpecifications = logSpecification.taskData.inputs;

  const [values, dispatchValues] = useReducer(valuesReducer, task.reportingData || {});

  useEffect(() => {
    if (open) {
      dispatchValues({
        type: "replace",
        value: task.reportingData || {},
      });
    }
  }, [open, task.reportingData]);

  const handleFieldChange = useCallback((identifier: string, value: unknown): void => {
    dispatchValues({identifier, type: "put", value});
  }, []);

  const handleOk = useCallback(() => {
    onOk(values as ReportingData);
  }, [onOk, values]);

  const inputSpecificationsMap = useMemo(
    () => getInputSpecificationsMap(logSpecification),
    [logSpecification],
  );

  const valueMaps = [values];
  const {anyRequired, inputFields, okDisabled} = buildDialogFields(
    unitLookup,
    inputSpecifications || [],
    customerSettings,
    valueMaps,
    inputSpecificationsMap,
    handleFieldChange,
    values,
  );
  let requiredNote;
  if (anyRequired) {
    requiredNote = <FormattedMessage defaultMessage="* krævet" />;
  }
  return (
    <ResponsiveDialog
      okDisabled={okDisabled}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={title}
    >
      <DialogContent>
        {inputFields}
        {requiredNote}
      </DialogContent>
    </ResponsiveDialog>
  );
});
