import {ColumnSpecifications, GenericTable, RowData} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
  getTableSortingState,
} from "@co-frontend-libs/redux";
import {Fab} from "@material-ui/core";
import _ from "lodash";
import CheckIcon from "mdi-react/CheckIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {defineMessages, IntlShape, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {MachineAnalysisAccountCreateEditDialog} from "./machine-analysis-account-create-edit-dialog";

const TABLE_SORTING_IDENTIFIER = "MachineAnalysisAccountsTable";
const FAB_MARGIN = 23;

const messages = defineMessages({
  accountNumber: {
    defaultMessage: "Konto",
    id: "machine-analysis-config.table-header.account-number",
  },
  isDirectCostsAccount: {
    defaultMessage: "Direkte omkostninger",
    id: "machine-analysis-config.table-header.direct-costs",
  },
  isIndirectCostsAccount: {
    defaultMessage: "Indirekte omkostninger",
    id: "machine-analysis-config.table-header.indirect-costs",
  },
  isTurnoverAccount: {
    defaultMessage: "Omsætning",
    id: "machine-analysis-config.table-header.turnover",
  },
});

type AccountsTableFieldID =
  | "accountNumber"
  | "isDirectCostsAccount"
  | "isIndirectCostsAccount"
  | "isTurnoverAccount";
type AccountsTableColumnID =
  | "accountNumber"
  | "isDirectCostsAccount"
  | "isIndirectCostsAccount"
  | "isTurnoverAccount";

interface AccountsTableDataType extends RowData<AccountsTableColumnID, string> {
  accountNumber: string;
  isDirectCostsAccount: boolean;
  isIndirectCostsAccount: boolean;
  isTurnoverAccount: boolean;
}

function renderIsTurnoverAccount(data: AccountsTableDataType): React.JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.isTurnoverAccount ? <CheckIcon /> : <></>;
}

function renderIsDirectCostsAccount(data: AccountsTableDataType): React.JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.isDirectCostsAccount ? <CheckIcon /> : <></>;
}

function renderIsIndirectCostsAccount(data: AccountsTableDataType): React.JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.isIndirectCostsAccount ? <CheckIcon /> : <></>;
}

function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onClick: (accountNumber: string) => void,
): ColumnSpecifications<
  AccountsTableFieldID,
  AccountsTableColumnID,
  string,
  AccountsTableDataType
> {
  return {
    accountNumber: {
      field: "accountNumber",
      label: formatMessage(messages.accountNumber),
      onClick,
    },
    isDirectCostsAccount: {
      field: "isDirectCostsAccount",
      label: formatMessage(messages.isDirectCostsAccount),
      onClick,
      render: renderIsDirectCostsAccount,
    },
    isIndirectCostsAccount: {
      field: "isIndirectCostsAccount",
      label: formatMessage(messages.isIndirectCostsAccount),
      onClick,
      render: renderIsIndirectCostsAccount,
    },
    isTurnoverAccount: {
      field: "isTurnoverAccount",
      label: formatMessage(messages.isTurnoverAccount),
      onClick,
      render: renderIsTurnoverAccount,
    },
  };
}

function buildRowData(accounts: {
  [accountNumber: string]: "DIRECT_COSTS" | "INDIRECT_COSTS" | "TURNOVER";
}): AccountsTableDataType[] {
  return Object.entries(accounts).map(([accountNumber, accountType]) => ({
    accountNumber,
    isDirectCostsAccount: accountType === "DIRECT_COSTS",
    isIndirectCostsAccount: accountType === "INDIRECT_COSTS",
    isTurnoverAccount: accountType === "TURNOVER",
    key: accountNumber,
  }));
}

interface MachineAnalysisAccountsTableProps {
  readonly fabPositioning: "absolute" | "fixed";
}

export function MachineAnalysisAccountsTable(
  props: MachineAnalysisAccountsTableProps,
): React.JSX.Element {
  const {formatMessage} = useIntl();
  const {fabPositioning} = props;
  const customerSettings = useSelector(getCustomerSettings);
  const dispatch = useDispatch();

  const currentUserURL = useSelector(getCurrentUserURL);

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingsEntry = settingsEntryLookupByIdentifier("machineAnalysisAccounts");

  const {machineAnalysisAccounts} = customerSettings;

  const [dialogOpenForAccount, setDialogOpenForAccount] = useState<string | null>(null);

  const handleFabClick = useCallback(() => {
    setDialogOpenForAccount("");
  }, []);

  const handleRowClick = useCallback((accountNumber: string): void => {
    setDialogOpenForAccount(accountNumber);
  }, []);

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(formatMessage, handleRowClick),
    [formatMessage, handleRowClick],
  );

  const rowData = useMemo(() => buildRowData(machineAnalysisAccounts), [machineAnalysisAccounts]);

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: AccountsTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  const handleDialogCancel = useCallback(() => {
    setDialogOpenForAccount(null);
  }, []);

  const handleDialogOk = useCallback(
    (accountNumber: string, accountType: "DIRECT_COSTS" | "INDIRECT_COSTS" | "TURNOVER") => {
      if (settingsEntry) {
        const newValue = _.cloneDeep(settingsEntry.data);
        newValue[accountNumber] = accountType;
        dispatch(
          actions.update(settingsEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
      setDialogOpenForAccount(null);
    },
    [currentUserURL, dispatch, settingsEntry],
  );

  const handleDialogDelete = useCallback(
    (accountNumber: string) => {
      if (settingsEntry) {
        const newValue = _.cloneDeep(settingsEntry.data);
        delete newValue[accountNumber];
        dispatch(
          actions.update(settingsEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
      setDialogOpenForAccount(null);
    },
    [currentUserURL, dispatch, settingsEntry],
  );

  const visibleColumns = [
    "accountNumber",
    "isTurnoverAccount",
    "isDirectCostsAccount",
    "isIndirectCostsAccount",
  ] as const;

  return (
    <>
      <div
        style={{
          height: "100%",
          minHeight: 200,
          position: "relative",
        }}
      >
        <GenericTable
          columns={columnSpecifications}
          entries={rowData}
          onHeaderClick={handleHeaderClick}
          sortBy={sortKey as any}
          sortDirection={sortDirection}
          visibleColumns={visibleColumns}
        />
        <Fab
          onClick={handleFabClick}
          style={{
            bottom: FAB_MARGIN,
            position: fabPositioning,
            right: FAB_MARGIN,
            zIndex: 1000,
          }}
        >
          <PlusIcon />
        </Fab>
      </div>
      <MachineAnalysisAccountCreateEditDialog
        accountNumber={dialogOpenForAccount || undefined}
        accountType={
          dialogOpenForAccount ? machineAnalysisAccounts[dialogOpenForAccount] : undefined
        }
        onCancel={handleDialogCancel}
        onDelete={handleDialogDelete}
        onOk={handleDialogOk}
        open={dialogOpenForAccount !== null}
      />
    </>
  );
}
