import {ResponsiveDialog} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Checkbox, DialogContent, FormControlLabel} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

interface MissingProductDialogProps {
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

export const MissingProductDialog = React.memo(function MissingProductDialog(
  props: MissingProductDialogProps,
): React.JSX.Element {
  const [noProduct, setNoProduct] = useState(false);
  const {onCancel, onOk, open} = props;
  const customerSettings = useSelector(getCustomerSettings);
  useEffect(() => {
    if (open) {
      setNoProduct(false);
    }
  }, [open]);
  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <ResponsiveDialog
      okDisabled={!noProduct}
      onCancel={handleCancel}
      onOk={onOk}
      open={open}
      title={<FormattedMessage defaultMessage="Der er ikke registreret materiel på opgaven" />}
    >
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={noProduct}
              name="noProduct"
              onChange={useEventTargetCheckedCallback(setNoProduct, [setNoProduct])}
            />
          }
          label={
            customerSettings.materialUseAlternativeText ? (
              <FormattedMessage defaultMessage="Jeg har ikke anvendt materialer under udførslen af denne opgave" />
            ) : (
              <FormattedMessage defaultMessage="Jeg har ikke anvendt materiel under udførslen af denne opgave" />
            )
          }
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
