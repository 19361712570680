import {
  Machine,
  MachineUrl,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
} from "@co-common-libs/resources";
import {identifierComparator} from "@co-common-libs/utils";
import {DecimalField, MultiplePriceGroupsDialog} from "@co-frontend-libs/components";
import {
  actions,
  AppState,
  getExtendedCustomerSettings,
  getMachineLookup,
  getPriceGroupArray,
  getPriceGroupLookup,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader, Fab, IconButton} from "@material-ui/core";
import {MachineCreateEditDialog, PageLayout} from "app-components";
import {checkedModelUpdaterFactoryFactory, PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {ExtendedConfig} from "extended-config";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {RelationsTable} from "../relations-table";
import {MachineKrPerLiterFuelSurchargeBlock} from "./machine-kr-per-liter-fuel-surcharge-block";
import {MachinePricePercentFuelSurchargeBlock} from "./machine-price-percent-fuel-surcharge-block";

const messages = defineMessages({
  active: {
    defaultMessage: "Aktiv",
    id: "machines-setting.label.active",
  },
  canPull: {defaultMessage: "Trækker", id: "machines-settings.label.can-pull"},
  editMachine: {
    defaultMessage: "Redigér maskine",
    id: "machines-settings.header.edit-machine",
  },
  editVehicle: {
    defaultMessage: "Redigér køretøj",
    id: "machines-settings.header.edit-vehicle",
  },
  fuelConsumptionLiterPerHour: {
    defaultMessage: "Brændstofforbrug, liter per time",
  },
  fuelSurcharge: {
    defaultMessage: "Brændstoftillæg",
  },
  id: {
    defaultMessage: "ID",
    id: "machines-setting.label.id",
  },
  name: {
    defaultMessage: "Navn",
    id: "machines-setting.label.name",
  },
  priceGroups: {
    defaultMessage: "Varianter",
    id: "machines-settings.header.price-groups",
  },
  selfPropelled: {
    defaultMessage: "Selvkørende",
    id: "machines-setting.label.self-propelled",
  },
  vehicleNumber: {
    defaultMessage: "Køretøjsnummer",
    id: "machines-setting.label.vehicle-number",
  },
});

interface MachineEditBlockProps {
  customerSettings: ExtendedConfig;
  machine: Machine;
  update: (url: string, patch: PatchUnion) => void;
}

interface MachineEditBlockState {
  machineDialogOpen: boolean;
}

class MachineEditBlock extends PureComponent<MachineEditBlockProps, MachineEditBlockState> {
  static contextType = IntlContext;

  context!: React.ContextType<typeof IntlContext>;
  makeCheckedModelUpdater = checkedModelUpdaterFactoryFactory<Machine>(this.props.update);

  state: MachineEditBlockState = {
    machineDialogOpen: false,
  };

  @bind
  handleEditNumber(): void {
    this.setState({machineDialogOpen: true});
  }

  @bind
  handleMachineDialogCancel(): void {
    this.setState({machineDialogOpen: false});
  }

  @bind
  handleMachineDialogOk(number: string): void {
    this.setState({machineDialogOpen: false});

    this.props.update(this.props.machine.url, [{member: "c5_machine", value: number}]);
  }

  @bind
  handleNameChange(value: string): void {
    const {machine, update} = this.props;
    update(machine.url, [{member: "name", value}]);
  }

  render(): React.JSX.Element {
    const {customerSettings, machine} = this.props;

    const {
      machines: {canOpenEditDialog},
    } = customerSettings;

    const number = machine.c5_machine;
    const {name} = machine;

    return (
      <>
        <Card>
          <CardContent>
            <div style={{float: "left"}}>
              <h4>
                <FormattedMessage defaultMessage="Navn" />
              </h4>
              {name}
              <h4>
                <FormattedMessage defaultMessage="Nummer" />
              </h4>
              {number}
            </div>
            {canOpenEditDialog ? (
              <IconButton onClick={this.handleEditNumber} style={{float: "right"}}>
                <PencilIcon />
              </IconButton>
            ) : null}
          </CardContent>
        </Card>
        <MachineCreateEditDialog
          machine={machine}
          onCancel={this.handleMachineDialogCancel}
          onOk={this.handleMachineDialogCancel}
          open={this.state.machineDialogOpen}
        />
      </>
    );
  }
}

interface EditMachineStateProps {
  customerSettings: ExtendedConfig;
  id: string;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  priceGroupArray: readonly PriceGroup[];
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
}

interface EditMachineDispatchProps {
  update: (url: string, patch: PatchUnion) => void;
}

type EditMachineProps = EditMachineDispatchProps & EditMachineStateProps;
interface EditMachineState {
  priceGroupDialogOpen: boolean;
}

class EditMachine extends PureComponent<EditMachineProps, EditMachineState> {
  static contextType = IntlContext;

  context!: React.ContextType<typeof IntlContext>;
  state = {
    priceGroupDialogOpen: false,
  };

  getSelectedPriceGroups(machineURL: MachineUrl): PriceGroupUrl[] {
    const machine = this.props.machineLookup(machineURL);
    return (machine && machine.pricegroups && machine.pricegroups.slice().sort()) || [];
  }

  @bind
  handleConnectPriceGroups(): void {
    this.setState({priceGroupDialogOpen: true});
  }

  @bind
  handleFuelConsumptionLiterPerHourChange(value: number | null): void {
    const {id, update} = this.props;
    const machineUrl = instanceURL("machine", id);
    update(machineUrl, [{member: "fuelConsumptionLiterPerHour", value}]);
  }

  @bind
  handlePriceGroupDialogCancel(): void {
    this.setState({priceGroupDialogOpen: false});
  }

  @bind
  handlePriceGroupDialogOk(priceGroupURLS: ReadonlySet<PriceGroupUrl>): void {
    this.setState({priceGroupDialogOpen: false});
    const machineURL = instanceURL("machine", this.props.id);
    const oldValue = this.getSelectedPriceGroups(machineURL);

    const newValue = oldValue.concat(Array.from(priceGroupURLS)).sort();
    if (!_.isEqual(newValue, oldValue)) {
      this.props.update(machineURL, [{member: "pricegroups", value: newValue}]);
    }
  }

  @bind
  handlePriceGroupRemoved(priceGroupUrl: PriceGroupUrl): void {
    const machineURL = instanceURL("machine", this.props.id);
    const oldValue = this.getSelectedPriceGroups(machineURL);
    const index = oldValue.indexOf(priceGroupUrl);
    const newValue = oldValue.slice();
    if (index > -1) {
      newValue.splice(index, 1);
    }
    if (!_.isEqual(newValue, oldValue)) {
      this.props.update(machineURL, [{member: "pricegroups", value: newValue}]);
    }
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;

    const machineURL = instanceURL("machine", this.props.id);
    const machine = this.props.machineLookup(machineURL);

    const selectedPriceGroups = this.getSelectedPriceGroups(machineURL);
    const selectedPriceGroupList = selectedPriceGroups
      .map((priceGroupUrl) => this.props.priceGroupLookup(priceGroupUrl))
      .filter(Boolean) as PriceGroup[];

    let sortedPriceGroups = selectedPriceGroupList
      .filter((instance) => instance.active)
      .sort((a, b) => identifierComparator(a.identifier, b.identifier));

    if (customerSettings.c5Sync) {
      sortedPriceGroups = _.sortBy(sortedPriceGroups, (pg) => pg.c5_recid);
    }
    const priceGroupsData = sortedPriceGroups.map((priceGroup) => {
      return {
        columns: [priceGroup.identifier, priceGroup.name],
        identifier: priceGroup.url,
      };
    });

    const header = [formatMessage(messages.id), formatMessage(messages.name)];

    const connectButton = (
      <Fab
        onClick={this.handleConnectPriceGroups}
        size="small"
        style={{
          position: "absolute",
          right: 16,
          top: 16,
        }}
      >
        <PlusIcon />
      </Fab>
    );

    // Grid/Cell types do not accept style prop ...?
    const GridNoType = Grid as any;
    const CellNoType = Cell as any;

    return (
      <PageLayout
        toolbar={
          customerSettings.machineLabelVariant === "MACHINE"
            ? formatMessage(messages.editMachine)
            : formatMessage(messages.editVehicle)
        }
      >
        <GridNoType
          style={{
            height: bowser.mobile ? "auto" : "calc(100% - 10px)",
            margin: 5,
          }}
        >
          <CellNoType
            palm="12/12"
            style={{
              paddingBottom: 11,
              paddingTop: 11,
            }}
          >
            {machine ? (
              <MachineEditBlock
                customerSettings={this.props.customerSettings}
                machine={machine}
                update={this.props.update}
              />
            ) : null}
          </CellNoType>
          <CellNoType palm="12/12" style={{paddingBottom: 11, paddingTop: 11}}>
            <Card>
              <div style={{position: "relative"}}>{connectButton}</div>
              <CardHeader title={formatMessage(messages.priceGroups)} />
              <RelationsTable
                data={priceGroupsData}
                header={header}
                onRemove={this.handlePriceGroupRemoved}
              />
            </Card>
            {customerSettings.fuelSurcharge !== null && machine ? (
              <Card style={{marginTop: 16}}>
                <CardHeader title={formatMessage(messages.fuelSurcharge)} />
                <CardContent>
                  {customerSettings.fuelSurcharge === "PRICE_PERCENT" ? (
                    <MachinePricePercentFuelSurchargeBlock machine={machine} />
                  ) : null}
                  {customerSettings.fuelSurcharge === "KR_PER_LITER" ? (
                    <MachineKrPerLiterFuelSurchargeBlock machine={machine} />
                  ) : null}
                  {customerSettings.fuelSurcharge === "KR_PER_LITER" ? (
                    <DecimalField
                      decimalPlaces={3}
                      fullWidth
                      label={formatMessage(messages.fuelConsumptionLiterPerHour)}
                      margin="dense"
                      maxDigits={10}
                      onChange={this.handleFuelConsumptionLiterPerHourChange}
                      value={machine.fuelConsumptionLiterPerHour ?? null}
                    />
                  ) : null}
                </CardContent>
              </Card>
            ) : null}
          </CellNoType>
        </GridNoType>
        <MultiplePriceGroupsDialog
          includeSelectAll={false}
          onCancel={this.handlePriceGroupDialogCancel}
          onOk={this.handlePriceGroupDialogOk}
          open={this.state.priceGroupDialogOpen}
          priceGroupArray={this.props.priceGroupArray.filter(
            (w) => !selectedPriceGroups.includes(w.url),
          )}
        />
      </PageLayout>
    );
  }
}

const ConnectedEditMachine = connect<
  EditMachineStateProps,
  EditMachineDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, EditMachineStateProps>({
    customerSettings: getExtendedCustomerSettings,
    id: makePathParameterGetter("id"),
    machineLookup: getMachineLookup,
    priceGroupArray: getPriceGroupArray,
    priceGroupLookup: getPriceGroupLookup,
  }),
  {
    update: actions.update,
  },
)(EditMachine);

export {ConnectedEditMachine as EditMachinePage};
