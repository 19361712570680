import {LocationType} from "@co-common-libs/resources";
import {createSelector} from "reselect";
import {getLocationTypeArray} from "../resources/selectors";
import {AppState} from "../root-reducer";

const DEFAULT_LOCATION_TYPE_IDENTIFIER = "standard";

function findDefaultLocationType(locationTypeArray: readonly LocationType[]): LocationType | null {
  return (
    locationTypeArray.find(
      (locationType) => locationType.identifier === DEFAULT_LOCATION_TYPE_IDENTIFIER,
    ) ?? null
  );
}

export const getDefaultLocationType: (state: AppState) => LocationType | null = createSelector(
  getLocationTypeArray,
  findDefaultLocationType,
);
