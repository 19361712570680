import {makeMapFromArray} from "@co-common-libs/utils";
import {ExternalId} from "shared-types";

/*
  Makes working with external id's (for now remoteUrls) a bit easier
  but more importantly combines all places that we use remoteUrl as preparation for moving
  to external reference tables.
 */

type ModelWithRemoteUrl = {remoteUrl: string};

export function asExternalId(remoteUrl: string): ExternalId {
  return remoteUrl as ExternalId;
}

export function getExternalId<T extends ModelWithRemoteUrl>(model: T): ExternalId {
  if (!model.remoteUrl) {
    throw new Error(`Missing remoteUrl: ${JSON.stringify(model)}`);
  }

  return asExternalId(model.remoteUrl);
}

export function filterWithExternalId<T extends ModelWithRemoteUrl>(arr: readonly T[]): T[] {
  return arr.filter(({remoteUrl}) => remoteUrl);
}

export function hasExternalId<T extends ModelWithRemoteUrl>(model: T): boolean {
  return !!model.remoteUrl;
}

export function externalIdMapFromArray<T extends ModelWithRemoteUrl>(
  arr: readonly T[],
): Map<ExternalId, T> {
  return makeMapFromArray(filterWithExternalId(arr), getExternalId);
}

export function extractExternalIds<T extends ModelWithRemoteUrl>(arr: readonly T[]): ExternalId[] {
  return filterWithExternalId(arr).map(getExternalId);
}

export function getExternalIdOrNull<T extends ModelWithRemoteUrl>(
  model: T | null,
): ExternalId | null {
  return model ? getExternalId(model) : null;
}
