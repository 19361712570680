import {alpha, createStyles, InputBase, makeStyles, Theme} from "@material-ui/core";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import React, {useCallback, useEffect, useState} from "react";
import {batch} from "react-redux";
import {useDebouncedCallback} from "use-debounce";

// based on
// https://material-ui.com/components/app-bar/#app-bar-with-search-field
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      [theme.breakpoints.up("sm")]: {
        "&:focus": {
          width: 200,
        },
        width: 120,
      },
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    inputRoot: {
      color: "inherit",
    },
    search: {
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },

      backgroundColor: alpha(theme.palette.common.white, 0.15),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
      width: "100%",
    },
    searchIcon: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "none",
      position: "absolute",

      width: theme.spacing(7),
    },
  }),
);

interface AppbarSearchFieldProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
}

export function AppbarSearchField(props: AppbarSearchFieldProps): React.JSX.Element {
  const {onChange, placeholder, value} = props;

  const classes = useStyles();

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <MagnifyIcon />
      </div>
      <InputBase
        classes={{
          input: classes.inputInput,
          root: classes.inputRoot,
        }}
        onChange={onChange}
        placeholder={placeholder || ""}
        value={value}
      />
    </div>
  );
}

interface DebouncedAppbarSearchFieldProps {
  debounceTimeout?: number;
  onChange: (newValue: string) => void;
  placeholder?: string;
  value: string;
}

const defaultDebounceTimeout = 100;

export function DebouncedAppbarSearchField(
  props: DebouncedAppbarSearchFieldProps,
): React.JSX.Element {
  const {
    debounceTimeout: debounceTimeoutFromProps,
    onChange,
    placeholder,
    value: valueFromProps,
  } = props;

  const debounceTimeout = debounceTimeoutFromProps || defaultDebounceTimeout;

  // NOTE: intentionally skipping "cancel" element
  const debouncedOnChange = useDebouncedCallback(
    (value: string) =>
      batch(() => {
        onChange(value);
      }),
    debounceTimeout,
  );

  const [value, setValue] = useState(valueFromProps);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue = event.target.value;
      setValue(newValue);
      debouncedOnChange(newValue);
    },
    [debouncedOnChange],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        debouncedOnChange.flush();
      }
    },
    [debouncedOnChange],
  );

  const handleBlur = useCallback(
    (_event: React.FocusEvent<HTMLInputElement>): void => {
      debouncedOnChange.flush();
    },
    [debouncedOnChange],
  );

  useEffect(() => {
    setValue(valueFromProps);
  }, [valueFromProps]);

  const classes = useStyles();

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <MagnifyIcon />
      </div>
      <InputBase
        classes={{
          input: classes.inputInput,
          root: classes.inputRoot,
        }}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder || ""}
        value={value}
      />
    </div>
  );
}
