import {Config} from "@co-common-libs/config";
import {
  Contact,
  Culture,
  Customer,
  Location,
  Machine,
  PriceItem,
  Product,
  Project,
  RoutePlan,
  Task,
  Unit,
  UnitUrl,
  urlToId,
  WorkType,
} from "@co-common-libs/resources";
import {
  getMachinesWorkTypeString,
  getUnitCode,
  getWorkTypeString,
  priceItemIsVisible,
} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {getCurrentRole, PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {colorMap, hexToRGBA} from "@co-frontend-libs/utils";
import {useTheme} from "@material-ui/core";
import {common as commonColors} from "@material-ui/core/colors";
import useMergedRef from "@react-hook/merged-ref";
import {dateFromDateAndTime} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import _ from "lodash";
import ImageIcon from "mdi-react/ImageIcon";
import React, {useCallback, useRef} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {
  ARRIVAL_MARKER_Z_INDEX,
  COMPLETED_OPACITY,
  FADED_OPACITY,
  NO_ESTIMATE_TASK_MINUTES,
  PLANNED_TASK_LEFT,
  TASK_BACK_Z_INDEX,
  TASK_FRONT_Z_INDEX,
  TASK_WIDTH,
  TRACKED_TASK_LEFT,
} from "./constants";
import {TaskDragHandle} from "./task-drag-handle";
import {calculateYPosition} from "./utils";

const messages = defineMessages({
  contractorWork: {
    defaultMessage: "Entreprenørarbejde",
    id: "task-list.contractor-work",
  },
  phoneNumber: {
    defaultMessage: "{number}",
    id: "time-overview.contactPhone",
  },
});

export interface TaskBlockProps {
  backgroundColor?: string | undefined;
  blockType: "partiallyPlanned" | "planned" | "tracked";
  calendarFromTimestamp?: ImmutableDate;
  calendarToTimestamp?: ImmutableDate;
  contact?: Contact | undefined;
  containerStyle?: React.CSSProperties;
  culture?: Culture | undefined;
  customer?: Customer | undefined;
  customerSettings: Config;
  fade?: boolean | undefined;
  fielduseSet?:
    | readonly {
        readonly notes: string;
        readonly relatedField: Location | undefined;
      }[]
    | undefined;
  fromTimestamp?: ImmutableDate;
  go?: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  hasConflict?: boolean;
  hasPhoto?: boolean;
  machineuseSet: readonly {
    readonly machine: Machine | undefined;
    readonly priceGroup: string | null;
    readonly transporter: boolean;
  }[];
  onDragHandleLongPress?:
    | ((event: React.TouchEvent<HTMLDivElement>, task: Task, taskBlock: HTMLDivElement) => void)
    | undefined;
  onDragHandleMouseDown?:
    | ((event: React.MouseEvent<HTMLDivElement>, task: Task, taskBlock: HTMLDivElement) => void)
    | undefined;
  onRequestTaskInfo?: ((position: {x: number; y: number}, task: Task) => void) | undefined;
  onResizeStop?: () => void;
  priceitemuseSet?:
    | readonly {
        readonly correctedCount: number | null;
        readonly count: number | null;
        readonly dangling: boolean;
        readonly notes: string;
        readonly priceItem: PriceItem | undefined;
      }[]
    | undefined;
  productuseSet?:
    | readonly {
        readonly correctedCount: number | null;
        readonly count: number | null;
        readonly notes: string;
        readonly ours: boolean;
        readonly product: Product | undefined;
      }[]
    | undefined;
  project?: Project | undefined;
  routePlan?: RoutePlan | undefined;
  task: Task;
  toTimestamp?: ImmutableDate;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workType?: WorkType | undefined;
}

function handleStopPropagation(event: React.MouseEvent<HTMLDivElement>): void {
  event.stopPropagation();
}

export const TaskBlock = React.memo(
  React.forwardRef(function TaskBlock(
    props: TaskBlockProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): React.JSX.Element {
    const {
      backgroundColor: backgroundColorFromProps,
      blockType,
      calendarFromTimestamp,
      calendarToTimestamp,
      contact,
      culture,
      customer,
      customerSettings,
      fade,
      fielduseSet,
      fromTimestamp,
      go,
      hasConflict,
      hasPhoto,
      machineuseSet,
      onDragHandleLongPress,
      onDragHandleMouseDown,
      onRequestTaskInfo,
      onResizeStop,
      priceitemuseSet,
      productuseSet,
      project,
      routePlan,
      task,
      unitLookup,
      workType,
    } = props;

    const theme = useTheme();
    const currentRole = useSelector(getCurrentRole);

    const allowTaskInfo =
      !!currentRole &&
      (currentRole.manager || currentRole.seniorMachineOperator) &&
      customerSettings.enableCalendarTaskInfoPopup;

    const handleTaskClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        if (allowTaskInfo && onRequestTaskInfo) {
          onRequestTaskInfo({x: event.clientX, y: event.clientY}, task);
        } else if (go) {
          go("/task/:id", {id: urlToId(task.url)});
        }
      },
      [allowTaskInfo, go, onRequestTaskInfo, task],
    );

    const {calendarShowMaterialsWithUnits} = customerSettings;
    const materials: {count: number; unit: string}[] = [];
    if (calendarShowMaterialsWithUnits.length && priceitemuseSet) {
      priceitemuseSet.forEach((entry) => {
        if (!entry.priceItem) {
          return;
        }
        const unit = getUnitCode(entry.priceItem, unitLookup);
        const {count} = entry;
        if (
          count &&
          unit &&
          calendarShowMaterialsWithUnits.includes(unit.toLowerCase()) &&
          // we only get here if priceitems are inlined in priceitemuseSet, so
          // the priceItemMap parameter to priceItemIsVisible will not be used;
          // and we can get away with not providing it
          priceItemIsVisible(entry.priceItem, false, priceitemuseSet, unitLookup)
        ) {
          materials.push({count, unit});
        }
      });
    }
    if (calendarShowMaterialsWithUnits.length && productuseSet) {
      productuseSet.forEach((entry) => {
        if (!entry.product) {
          return;
        }
        const unit = getUnitCode(entry.product, unitLookup);
        const {count} = entry;
        if (count && unit && calendarShowMaterialsWithUnits.includes(unit.toLowerCase())) {
          materials.push({count, unit});
        }
      });
    }
    let {toTimestamp} = props;
    const intl = useIntl();
    const userURL = task.machineOperator;
    let startY;
    if (fromTimestamp && calendarFromTimestamp && calendarToTimestamp) {
      startY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, fromTimestamp);
    }
    const {arrivalAtLocation} = task;
    if (blockType === "planned" && !toTimestamp) {
      if (arrivalAtLocation && task.date) {
        const arrivalAtLocationTimestamp = new ImmutableDate(
          dateFromDateAndTime(task.date, arrivalAtLocation),
        );
        toTimestamp = arrivalAtLocationTimestamp.setUTCMinutes(
          arrivalAtLocationTimestamp.getUTCMinutes() + NO_ESTIMATE_TASK_MINUTES,
        );
      }
    }
    const onlyPartiallyPlanned = !fromTimestamp || !userURL;
    let endY;
    if (toTimestamp && calendarFromTimestamp && calendarToTimestamp) {
      endY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, toTimestamp);
    }

    const minutesExpectedDuration = task.minutesExpectedTotalTaskDuration;
    const noEstimate = !minutesExpectedDuration;

    const isTODO = !task.order;
    const backgroundColor =
      backgroundColorFromProps || (isTODO ? colorMap.CALENDAR_TODO_OK : colorMap.CALENDAR_TASK_OK);
    const {completed} = task;
    const opacity = completed ? COMPLETED_OPACITY : 1;
    let workTypeString;
    if (workType) {
      workTypeString = getWorkTypeString(workType, customerSettings.calendarShowWorkTypeNumber);
    } else if (customerSettings.enableExternalTaskDepartmentField && task.department === "E") {
      workTypeString = intl.formatMessage(messages.contractorWork);
    } else {
      const machineList = machineuseSet
        .map((machineUse) => machineUse.machine)
        .filter(notUndefined);
      workTypeString = getMachinesWorkTypeString(
        machineList,
        customerSettings.calendarShowWorkTypeNumber,
      );
    }
    const zIndexBlockTypeMap = customerSettings.calendarPlannedTasksBehindMeasured
      ? {
          partiallyPlanned: undefined,
          planned: TASK_BACK_Z_INDEX,
          tracked: TASK_FRONT_Z_INDEX,
        }
      : {
          partiallyPlanned: undefined,
          planned: TASK_FRONT_Z_INDEX,
          tracked: TASK_BACK_Z_INDEX,
        };
    let relevantLine1;
    let relevantLine2;
    if (isTODO) {
      const machinesUsed = machineuseSet;
      if (machinesUsed) {
        const machineIDs = machinesUsed
          .map((entry) => {
            const {machine} = entry;
            const machineID = machine && machine.c5_machine;
            return machineID;
          })
          .filter((x) => x);
        relevantLine1 = machineIDs.join(", ");
      }
    } else {
      if (customerSettings.showFieldNumbersOnCalendar) {
        if (fielduseSet) {
          relevantLine2 = fielduseSet
            .map((fieldUse) => {
              return fieldUse.relatedField ? fieldUse.relatedField.fieldNumber : "";
            })
            .join(", ");
        }
      }

      if (customerSettings.routesEnabled && routePlan && typeof routePlan !== "string") {
        relevantLine1 = routePlan.name;
      } else if (
        customerSettings.showProjectInsteadOfCustomer &&
        project &&
        typeof project !== "string"
      ) {
        const {alias, name, projectNumber} = project;
        const nameAndOrAlias =
          customerSettings.showProjectAlias && alias && name ? `${name}, ${alias}` : name || alias;
        relevantLine1 = `${projectNumber}: ${nameAndOrAlias}`;
      } else if (culture && typeof culture !== "string") {
        relevantLine1 = culture.name;
      } else {
        if (customer) {
          const customerName = customer.name;
          relevantLine1 = customerName;
        }
        const phone = contact && contact.phone;
        if (phone) {
          relevantLine2 = intl.formatMessage(messages.phoneNumber, {
            number: phone,
          });
        }
      }
    }
    const validated = task.validatedAndRecorded || task.reportApproved;
    let arrivalMarker;
    if (blockType === "planned" && arrivalAtLocation && startY != null && task.date) {
      const arrivalAtLocationTimestamp = dateFromDateAndTime(task.date, arrivalAtLocation);
      const arrivalMarkerOffset =
        calendarFromTimestamp && calendarToTimestamp
          ? calculateYPosition(
              calendarFromTimestamp,
              calendarToTimestamp,
              arrivalAtLocationTimestamp,
            ) - startY
          : undefined;
      const arrivalMarkerStyle: React.CSSProperties = {
        borderBottomStyle: "dotted",
        borderBottomWidth: 1,
        borderColor: commonColors.black,
        height: 1,
        position: "absolute",
        width: TASK_WIDTH,
        zIndex: ARRIVAL_MARKER_Z_INDEX,
      };
      if (arrivalMarkerOffset !== undefined) {
        arrivalMarkerStyle.top = arrivalMarkerOffset;
      }
      arrivalMarker = <div style={arrivalMarkerStyle} />;
    }
    const containerStyle: React.CSSProperties = {
      backgroundColor: commonColors.white,
      cursor: "pointer",
      overflow: "hidden",
      position: "absolute",
      userSelect: "none",
      WebkitUserSelect: "none",
      width: TASK_WIDTH,
    };
    if (!onlyPartiallyPlanned) {
      const left = blockType === "tracked" ? TRACKED_TASK_LEFT : PLANNED_TASK_LEFT;
      Object.assign(containerStyle, {
        height: endY != null && startY != null ? endY - startY : undefined,
        left,
        top: startY,
        zIndex: zIndexBlockTypeMap[blockType],
      });
    }

    if (props.containerStyle) {
      Object.assign(containerStyle, props.containerStyle);
    }
    let borderStyle;
    if (blockType === "tracked") {
      borderStyle = validated ? "none double solid solid" : "none none solid solid";
    } else if (blockType === "planned" || blockType === "partiallyPlanned") {
      const rightBorder = validated ? "double" : "solid";
      const bottomBorder = noEstimate ? "dashed" : "solid";
      borderStyle = `solid ${rightBorder} ${bottomBorder} solid`;
    }

    const rgbaBackgroundColor = hexToRGBA(backgroundColor, opacity);
    const style: React.CSSProperties = {
      backgroundColor: rgbaBackgroundColor,
      borderColor: hasConflict ? colorMap.ERROR : commonColors.black,
      borderWidth: validated ? "1px 4px 1px 1px" : "1px 1px 1px 1px",
      color: theme.palette.getContrastText(rgbaBackgroundColor),
      height: "100%",
      opacity: fade ? FADED_OPACITY : 1,
      width: "100%",
    };
    if (borderStyle !== undefined) {
      style.borderStyle = borderStyle;
    }
    const contentText: React.CSSProperties = {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "14px",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    };
    const workTypeStyle: React.CSSProperties = {
      ...contentText,
      marginRight: 14,
      paddingLeft: 2,
    };
    if (
      (customerSettings.colorWorkTypesOnEmployeeCalendar ||
        (customerSettings.colorUnscheduledCalendar && blockType === "partiallyPlanned")) &&
      (!isTODO || customerSettings.colorInternalTaskWorkTypesOnEmployeeCalendar) &&
      workType
    ) {
      const workTypeColor = workType.color;
      if (workTypeColor) {
        workTypeStyle.backgroundColor = workTypeColor;
        workTypeStyle.color = theme.palette.getContrastText(workTypeColor);
        workTypeStyle.width = "100%";
      }
    }
    let materialsBlock;
    if (materials.length) {
      const materialsEntries = materials.map(({count, unit}, index) => (
        <div key={index}>
          {count} {unit}
        </div>
      ));
      materialsBlock = <div style={contentText}>{materialsEntries}</div>;
    }

    const ImageIconNoCheck = ImageIcon as any;
    const taskBlockRef = useRef<HTMLDivElement | null>(null);

    const handleMouseDown = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        if (onDragHandleMouseDown && taskBlockRef.current && event.button === 0) {
          event.stopPropagation();
          onDragHandleMouseDown(event, task, taskBlockRef.current);
        }
      },
      [onDragHandleMouseDown, task, taskBlockRef],
    );

    const handleLongPress = useCallback(
      (event: React.TouchEvent<HTMLDivElement>) => {
        if (onDragHandleLongPress && taskBlockRef.current) {
          onDragHandleLongPress(event, task, taskBlockRef.current);
        }
      },
      [onDragHandleLongPress, task, taskBlockRef],
    );

    const allowCalendarPlanning =
      customerSettings.calendarPlanningFunctions &&
      !!currentRole &&
      (currentRole.manager || currentRole.seniorMachineOperator);
    const multiRef = useMergedRef(ref, taskBlockRef);
    return (
      <div
        onClick={handleTaskClick}
        onMouseDown={handleStopPropagation}
        ref={multiRef}
        style={containerStyle}
      >
        {arrivalMarker}
        <div style={style}>
          <div style={workTypeStyle}>{workTypeString}</div>
          <div style={{paddingLeft: 2}}>
            {materialsBlock}
            <div style={contentText}>{relevantLine1}</div>
            <div style={contentText}>{relevantLine2}</div>
          </div>
        </div>
        {allowCalendarPlanning && onDragHandleMouseDown ? (
          <TaskDragHandle
            onLongPress={handleLongPress}
            onMouseDown={handleMouseDown}
            onResizeStop={onResizeStop}
          />
        ) : null}
        {hasPhoto ? (
          <ImageIconNoCheck size={12} style={{bottom: 1, position: "absolute", right: 1}} />
        ) : null}
      </div>
    );
  }),
  _.isEqual,
);
