import {TimerUrl} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {ConnectedMultipleTimerDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
  getTimerArray,
  getTimerLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

export function SelectTimers(props: SettingViewProps): React.JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const selectedTimers: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);
  const timerArray = useSelector(getTimerArray);
  const timerLookup = useSelector(getTimerLookup);
  const timerInstances = useMemo(() => {
    return selectedTimers
      .map((identifier) => timerArray.find((w) => w.identifier === identifier))
      .filter(notUndefined);
  }, [selectedTimers, timerArray]);

  const timerLabels = useMemo(() => {
    const labels = timerInstances.map((timer) => `${timer.identifier}: ${timer.label}`);
    labels.sort();
    return labels;
  }, [timerInstances]);

  const [timerDialogOpen, setTimerDialogOpen] = useState(false);
  const setTimerDialogOpenTrue = useCallWithTrue(setTimerDialogOpen);
  const setTimerDialogOpenFalse = useCallWithFalse(setTimerDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleTimerDialogOk = useCallback(
    (urls: ReadonlySet<TimerUrl>) => {
      setTimerDialogOpen(false);

      const newValue = [...urls].map((url) => timerLookup(url)?.identifier);

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry, timerLookup],
  );

  return (
    <>
      <DisplaySelected
        currentValue={timerLabels}
        onClick={setTimerDialogOpenTrue}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
      />
      <ConnectedMultipleTimerDialog
        includeOnlyTimersWithIdentifiers
        onCancel={setTimerDialogOpenFalse}
        onOk={handleTimerDialogOk}
        open={timerDialogOpen}
        selected={new Set(timerInstances.map((timer) => timer?.url))}
      />
    </>
  );
}
