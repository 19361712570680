import {WorkStatus} from "@co-common-libs/utils";
import {SvgIcon, useTheme} from "@material-ui/core";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import CheckCircleOutlineIcon from "mdi-react/CheckCircleOutlineIcon";
import PauseCircleOutlineIcon from "mdi-react/PauseCircleOutlineIcon";
import PlayCircleOutlineIcon from "mdi-react/PlayCircleOutlineIcon";
import StopCircleOutlineIcon from "mdi-react/StopCircleOutlineIcon";
import React from "react";
import {CoinOutlineIcon} from "./icons/CoinOutlineIcon";

const wrapperStyle = {
  height: 24,
  width: 24,
};

export interface WorkStatusIconProps {
  status: WorkStatus;
}

export const WorkStatusIcon = React.memo(function WorkStatusIcon(
  props: WorkStatusIconProps,
): React.JSX.Element {
  const {status} = props;
  const theme = useTheme();
  let icon;
  let color: string | undefined;
  if (status === "recorded") {
    icon = <CoinOutlineIcon />;
  } else if (status === "error") {
    icon = <AlertCircleIcon />;
    color = theme.palette.error.main;
  } else if (status === "validated") {
    icon = <CheckCircleOutlineIcon />;
  } else if (status === "completed") {
    icon = <StopCircleOutlineIcon />;
  } else if (status === "active") {
    icon = <PlayCircleOutlineIcon />;
  } else if (status === "paused") {
    icon = <PauseCircleOutlineIcon />;
  }
  return (
    <div style={wrapperStyle}>
      <SvgIcon style={color ? {color} : {}}>{icon}</SvgIcon>
    </div>
  );
});
