import {PatchOperation, ProductUrl, Task} from "@co-common-libs/resources";
import {dateFromString, dateToString, formatDate} from "@co-common-libs/utils";
import {FilePdfIcon} from "@co-frontend-libs/components";
import {Check, makeQuery} from "@co-frontend-libs/db-resources";
import {
  actions,
  getContactLookup,
  getCultureLookup,
  getCurrentRole,
  getCustomerLookup,
  getDaysAbsenceArray,
  getDeliveryArray,
  getDeliveryLocationArray,
  getExtendedCustomerSettings,
  getHoursAbsenceArray,
  getLocationArray,
  getLocationLookup,
  getLocationStorageAdjustmentArray,
  getLocationStorageChangeArray,
  getLocationStorageStatusArray,
  getLocationTypeLookup,
  getMachineArray,
  getMachineLookup,
  getOrderLookup,
  getPathName,
  getPickupArray,
  getPickupLocationArray,
  getPriceGroupLookup,
  getPriceItemArray,
  getPriceItemLookup,
  getProductGroupLookup,
  getProductLookup,
  getProjectArray,
  getProjectLookup,
  getReportingSpecificationLookup,
  getRouteTaskActivityOptionLookup,
  getRouteTaskArray,
  getRouteTaskLookup,
  getRouteTaskResultArray,
  getShareToken,
  getTaskFileArray,
  getTaskLookup,
  getTaskPhotoArray,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getTransportLogArray,
  getUnitLookup,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkshopChecklistArray,
  getWorkshopChecklistItemArray,
  getWorkTypeLookup,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {Button, ButtonProps, Card, CardContent, CardHeader} from "@material-ui/core";
import {
  Calendar,
  generateTimerStartMapping,
  PageLayout,
  resolveTaskRelations,
  sortTasksInColumns,
  TaskWithRelations,
  USER_COLUMN_WIDTH,
  ValidationDialog,
} from "app-components";
import {
  computeIntervalsTruncated,
  ErrorEntry,
  getBreakTimer,
  getErrors,
  getGenericPrimaryTimer,
  getInternalTaskErrors,
  getReadonlyProductsFromTask,
  getTaskSecondaryTimerList,
  getWarnings,
  InlinedTask,
  inlineTaskData,
  inlineTransportLogData,
  useBookkeepingTasks,
  willTaskBeRecorded,
} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import bowser from "bowser";
import {endOfDay, startOfDay} from "date-fns";
import {globalConfig, instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
// Allowed for existing code.
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {getAbsenceLists} from "../task-calendar/employee-tab";
import {BookkeepingCompletedTasksSums} from "./bookkeeping-completed-tasks-sums";
import {BookkeepingTaskTable} from "./bookkeeping-task-table";

const messages = defineMessages({
  approveInvoicing: {
    defaultMessage: "Godkend til bogføring",
    id: "bookkeeping-entry.label.approve-invoicing",
  },
  approveReport: {
    defaultMessage: "Godkend indberetning",
    id: "bookkeeping-entry.label.approve-report",
  },
  dailyTasksHeader: {
    defaultMessage: "Dagseddel",
    id: "bookkeeping-entry-header",
  },
  dailyTasksHeaderSubtitle: {
    defaultMessage: "{date}, {name} {initials}",
    id: "bookkeeping-entry-header-subtitle",
  },
  downloadPDF: {
    defaultMessage: "Download PDF",
    id: "bookkeeping-entry.label.download-pdf",
  },
  gps: {
    defaultMessage: "GPS",
    id: "bookkeeping-entry.label.gps",
  },
  incompleteTasksHeader: {
    defaultMessage: "Der findes uafsluttede opgaver på dagssedlen.",
    id: "bookkeeping-day.title.some-unrecorded",
  },
  title: {
    defaultMessage: "Dagseddel",
    id: "bookkeeping-entry.title.day",
  },
});

const ARCHIVE_DAYS = 7;

const TEMPORARY_QUERIES_KEY = "BookkeepingEntry";

export const BookkeepingDay = React.memo(function BookkeepingDay(): React.JSX.Element | null {
  const contactLookup = useSelector(getContactLookup);
  const cultureLookup = useSelector(getCultureLookup);
  const currentRole = useSelector(getCurrentRole);
  const customerLookup = useSelector(getCustomerLookup);
  const customerSettings = useSelector(getExtendedCustomerSettings);
  const daysAbsenceArray = useSelector(getDaysAbsenceArray);
  const deliveryArray = useSelector(getDeliveryArray);
  const deliveryLocationArray = useSelector(getDeliveryLocationArray);
  const hoursAbsenceArray = useSelector(getHoursAbsenceArray);
  const locationArray = useSelector(getLocationArray);
  const locationLookup = useSelector(getLocationLookup);
  const locationStorageAdjustmentArray = useSelector(getLocationStorageAdjustmentArray);
  const locationStorageChangeArray = useSelector(getLocationStorageChangeArray);
  const locationStorageStatusArray = useSelector(getLocationStorageStatusArray);
  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const machineArray = useSelector(getMachineArray);
  const machineLookup = useSelector(getMachineLookup);
  const orderLookup = useSelector(getOrderLookup);
  const pathName = useSelector(getPathName);
  const pickupArray = useSelector(getPickupArray);
  const pickupLocationArray = useSelector(getPickupLocationArray);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemArray = useSelector(getPriceItemArray);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const projectArray = useSelector(getProjectArray);
  const projectLookup = useSelector(getProjectLookup);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
  const routeTaskActivityOptionLookup = useSelector(getRouteTaskActivityOptionLookup);
  const routeTaskLookup = useSelector(getRouteTaskLookup);
  const routeTaskResultArray = useSelector(getRouteTaskResultArray);
  const routeTaskArray = useSelector(getRouteTaskArray);
  const shareToken = useSelector(getShareToken);
  const taskFileArray = useSelector(getTaskFileArray);
  const taskLookup = useSelector(getTaskLookup);
  const taskPhotoArray = useSelector(getTaskPhotoArray);
  const timerArray = useSelector(getTimerArray);
  const timerLookup = useSelector(getTimerLookup);
  const timerStartArray = useSelector(getTimerStartArray);
  const transportLogArray = useSelector(getTransportLogArray);
  const unitLookup = useSelector(getUnitLookup);
  const userLookup = useSelector(getUserLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const workshopChecklistArray = useSelector(getWorkshopChecklistArray);
  const workshopChecklistItemArray = useSelector(getWorkshopChecklistItemArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const [reportApprovedDialogOpen, setReportApprovedDialogOpen] = useState(false);
  const setReportApprovedDialogOpenFalse = useCallWithFalse(setReportApprovedDialogOpen);

  const [validateDialogOpen, setValidateDialogOpen] = useState(false);
  const setValidateDialogOpenFalse = useCallWithFalse(setValidateDialogOpen);

  const date = useSelector(makePathParameterGetter("date"));
  const machineOperatorId = useSelector(makePathParameterGetter("machineOperatorId"));
  const dispatch = useDispatch();
  const intl = useIntl();

  const machineOperatorURL = instanceURL("user", machineOperatorId);
  const machineOperator = userLookup(machineOperatorURL);
  const machineOperatorProfile = userUserProfileLookup(machineOperatorURL);
  const machineOperatorInitials = machineOperatorProfile?.alias || "";
  const machineOperatorName = machineOperatorProfile?.name || "";

  useEffect(() => {
    const timeBackDateDate = new Date();
    timeBackDateDate.setDate(timeBackDateDate.getDate() - ARCHIVE_DAYS);
    const timeBackDate = dateToString(timeBackDateDate);
    if (date <= timeBackDate) {
      const rangeStartString = startOfDay(dateFromString(date) as Date).toISOString();
      const rangeEndString = endOfDay(dateFromString(date) as Date).toISOString();
      const taskCheckParts: Check[] = [
        {
          memberName: "workFromTimestamp",
          type: "memberGte",
          value: rangeStartString,
        },
        {
          memberName: "workFromTimestamp",
          type: "memberLte",
          value: rangeEndString,
        },
      ];
      if (machineOperator) {
        taskCheckParts.push({
          memberName: "machineOperator",
          type: "memberEq",
          value: machineOperator.url,
        });
      }
      const taskCheck: Check = {
        checks: taskCheckParts,
        type: "and",
      };
      const orderCheck: Check = {
        check: taskCheck,
        fromResource: "task",
        memberName: "order",
        type: "targetOfForeignKey",
      };
      const taskPhotoCheck: Check = {
        check: taskCheck,
        memberName: "task",
        targetType: "task",
        type: "hasForeignKey",
      };
      const queries = [
        makeQuery({
          check: taskCheck,
          filter: {
            bookkeepingDate: date,
            initials: machineOperatorInitials,
          },
          independentFetch: true,
          resourceName: "task",
        }),
        makeQuery({
          check: orderCheck,
          independentFetch: false,
          resourceName: "order",
        }),
        makeQuery({
          check: taskPhotoCheck,
          independentFetch: false,
          resourceName: "taskPhoto",
        }),
      ];
      if (customerSettings.routesEnabled) {
        const relatedTaskRouteMemberCheck: Check = {
          check: taskCheck,
          memberName: "route",
          targetType: "task",
          type: "hasForeignKey",
        };
        const relatedRouteTaskTaskRouteMemberCheck: Check = {
          check: relatedTaskRouteMemberCheck,
          memberName: "routeTask",
          targetType: "routeTask",
          type: "hasForeignKey",
        };
        queries.push(
          makeQuery({
            check: relatedTaskRouteMemberCheck,
            independentFetch: false,
            resourceName: "routeTask",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedRouteTaskTaskRouteMemberCheck,
            independentFetch: false,
            resourceName: "routeTaskResult",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedRouteTaskTaskRouteMemberCheck,
            independentFetch: false,
            resourceName: "routeTaskActivityOption",
          }),
        );
      }
      dispatch(actions.temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY));
    }
  }, [
    customerSettings.routesEnabled,
    date,
    dispatch,
    machineOperatorInitials,
    machineOperator,
    pathName,
  ]);

  const handleValidatedButton = useCallback(() => {
    if (customerSettings.useApproveReport) {
      setReportApprovedDialogOpen(true);
    } else {
      setValidateDialogOpen(true);
    }
  }, [customerSettings.useApproveReport]);

  const breakTimer = getBreakTimer(timerArray);

  const approveOrReportApproveTask = useCallback(
    (field: "reportApproved" | "validatedAndRecorded", taskList?: readonly InlinedTask[]) => {
      if (!taskList) {
        return;
      }
      taskList.forEach((task) => {
        const patch: PatchOperation<Task>[] = [{member: field, value: true}];
        const {order} = task;
        if (
          !willTaskBeRecorded(
            task,
            order || undefined,
            customerSettings,
            timerStartArray,
            breakTimer?.url,
          )
        ) {
          patch.push({member: "archivable", value: true});
        }
        dispatch(actions.update(task.url, patch));
      });
      dispatch(actions.back());
    },
    [breakTimer?.url, customerSettings, dispatch, timerStartArray],
  );

  const handleReportApprovedOK = useCallback(
    (taskList: readonly InlinedTask[]) => {
      if (customerSettings.economicSync) {
        approveOrReportApproveTask("reportApproved", taskList);
      } else {
        taskList.forEach((task) => {
          dispatch(actions.update(task.url, [{member: "reportApproved", value: true}]));
        });
        dispatch(actions.back());
      }
      setReportApprovedDialogOpen(false);
    },
    [approveOrReportApproveTask, customerSettings.economicSync, dispatch],
  );
  const handleValidatedOk = useCallback(
    (taskList?: readonly InlinedTask[]) => {
      approveOrReportApproveTask("validatedAndRecorded", taskList);
      setValidateDialogOpen(false);
    },
    [approveOrReportApproveTask],
  );

  const userURLs = [machineOperatorURL];

  const {
    completedTasksStartedToday,
    extraTasks,
    finalIncompleteTaskList,
    fromTimestampArray,
    incompleteTasks,
    intersectingCompletedTasks,
    someIncomplete,
    sortedTimerStarts,
    toTimestampArray,
  } = useBookkeepingTasks({
    employeeUrl: machineOperatorURL,
    selectedDate: date,
  });

  const combinedTasks = (completedTasksStartedToday as Task[]).concat(extraTasks);

  const getInternalErrors = useCallback(
    (inlinedTask: InlinedTask, now: Date): readonly ErrorEntry[] => {
      const task = taskLookup(inlinedTask.url);
      if (!task) {
        return [];
      }
      return getInternalTaskErrors(
        {
          customerSettings,
          department: inlinedTask.department,
          finalIntervals: inlinedTask._intervals,
          machineOperator: task.machineOperator ? userLookup(task.machineOperator) : undefined,
          machineUseList: inlinedTask.machineuseSet,
          onlyCompletedChecks: false,
          primaryWorkType: inlinedTask.workType || undefined,
          task,
          taskPhotoArray,
          workshopChecklistAnswers: task.workshopchecklistanswerSet,
          workshopChecklistItemArray,
          workshopChecklists: workshopChecklistArray.filter((checklist) => {
            if (!checklist.draft && checklist.active) {
              const checklistMachines = checklist.machines;
              return task.machineuseSet.some((machineUse) =>
                machineUse.machine ? checklistMachines.includes(machineUse.machine) : false,
              );
            } else {
              return false;
            }
          }),
          workTypeLookup,
        },
        now,
        intl,
      );
    },
    [
      customerSettings,
      intl,
      taskLookup,
      taskPhotoArray,
      userLookup,
      workTypeLookup,
      workshopChecklistArray,
      workshopChecklistItemArray,
    ],
  );

  const now = new Date();

  let taskIssueMap:
    | Map<
        string,
        {
          errors: readonly ErrorEntry[];
          warnings: readonly ErrorEntry[];
        }
      >
    | undefined;

  const someUnrecorded = combinedTasks.some(
    (task) =>
      ((customerSettings.useApproveReport && !task.reportApproved) ||
        (!customerSettings.useApproveReport && !task.validatedAndRecorded)) &&
      !task.recordedInC5,
  );

  let taskWithDataList: InlinedTask[] | undefined;
  if (validateDialogOpen || reportApprovedDialogOpen) {
    const taskDataLookups = {
      contactLookup,
      customerLookup,
      machineLookup,
      orderLookup,
      priceGroupLookup,
      priceItemLookup,
      productLookup,
      projectLookup,
      reportingSpecificationLookup,
      timerLookup,
      workTypeLookup,
    };
    taskWithDataList = _.sortBy(
      combinedTasks.filter(
        (task) =>
          !task.validatedAndRecorded || (customerSettings.useApproveReport && !task.reportApproved),
      ),
      (task) => task.workFromTimestamp,
    ).map((task) => inlineTaskData(task, taskDataLookups));
    const taskURLSet = new Set(combinedTasks.map((task) => task.url));
    const transportLogWithDataList = transportLogArray
      .filter((transportLog) => taskURLSet.has(transportLog.task))
      .map((transportLog) =>
        inlineTransportLogData(transportLog, {
          deliveryArray,
          deliveryLocationArray,
          pickupArray,
          pickupLocationArray,
        }),
      );
    const genericPrimaryTimer = getGenericPrimaryTimer(timerArray);
    taskIssueMap = new Map();
    const taskIssueMapNotUndefined = taskIssueMap;
    taskWithDataList.forEach((task) => {
      const productsWithLogData: Set<ProductUrl> = new Set();
      const readonlyProducts: Set<ProductUrl> = getReadonlyProductsFromTask(
        task,
        productLookup,
        unitLookup,
        reportingSpecificationLookup,
      );

      const taskURL = task.url;
      const transportLog = transportLogWithDataList.find((t) => t.task === taskURL);
      const secondaryTimers = new Set(
        getTaskSecondaryTimerList(task, customerSettings, {
          machineLookup,
          priceGroupLookup,
          timerArray,
          workTypeLookup,
        }),
      );
      const userIsManager = !!(currentRole && currentRole.manager);

      const warnings: ErrorEntry[] = getWarnings(
        {
          genericPrimaryTimer,
          locationArray,
          locationLookup,
          locationStorageAdjustmentArray,
          locationStorageChangeArray,
          locationStorageStatusArray,
          locationTypeLookup,
          machineArray,
          machineLookup,
          priceGroupLookup,
          priceItemArray,
          priceItemLookup,
          productGroupLookup,
          productLookup,
          productsWithLogData,
          projectArray,
          readonlyProducts,
          routeTaskArray,
          secondaryTimers,
          task,
          timerArray,
          transportLog,
          unitLookup,
          userIsManager,
          workTypeLookup,
        },
        customerSettings,
        intl,
      );
      const errors: readonly ErrorEntry[] = task.order
        ? getErrors(
            {
              genericPrimaryTimer,
              locationArray,
              machineArray,
              priceGroupLookup,
              priceItemArray,
              priceItemLookup,
              productGroupLookup,
              productLookup,
              productsWithLogData,
              projectArray,
              readonlyProducts,
              routeTaskActivityOptionLookup,
              routeTaskArray,
              routeTaskLookup,
              routeTaskResultArray,
              secondaryTimers,
              task,
              taskFileArray,
              taskPhotoArray,
              transportLog,
              unitLookup,
              userIsManager,
              workTypeLookup,
            },
            customerSettings,
            intl,
            false,
            task.completedAsInternal,
          )
        : getInternalErrors(task, now);
      taskIssueMapNotUndefined.set(taskURL, {errors, warnings});
    });
  }
  const validateDialogs = [
    <ValidationDialog
      key="validate"
      onCancel={setValidateDialogOpenFalse}
      onOk={handleValidatedOk}
      open={validateDialogOpen}
      someIncomplete={someIncomplete}
      taskIssueMap={taskIssueMap}
      taskList={taskWithDataList}
    />,
  ];
  if (customerSettings.useApproveReport) {
    validateDialogs.push(
      <ValidationDialog
        key="report-approved"
        onCancel={setReportApprovedDialogOpenFalse}
        onOk={handleReportApprovedOK}
        open={reportApprovedDialogOpen}
        someIncomplete={someIncomplete}
        taskIssueMap={taskIssueMap}
        taskList={taskWithDataList}
      />,
    );
  }
  let calendarDate;
  let calendarFromTimestamp: ImmutableDate | undefined;
  let calendarToTimestamp: ImmutableDate | undefined;
  if (fromTimestampArray.length && toTimestampArray.length) {
    const minFromTimestampString = fromTimestampArray.reduce((previous, current) => {
      if (previous < current) {
        return previous;
      } else {
        return current;
      }
    });
    calendarFromTimestamp = new ImmutableDate(minFromTimestampString);
    calendarFromTimestamp = calendarFromTimestamp.setUTCMinutes(0, 0, 0);
    calendarFromTimestamp = calendarFromTimestamp.setUTCHours(
      calendarFromTimestamp.getUTCHours() - 1,
    );
    const maxToTimestampString = toTimestampArray.reduce((previous, current) => {
      if (previous > current) {
        return previous;
      } else {
        return current;
      }
    });
    calendarToTimestamp = new ImmutableDate(maxToTimestampString);
    calendarToTimestamp = calendarToTimestamp.setUTCMinutes(0, 0, 0);
    calendarToTimestamp = calendarToTimestamp.setUTCHours(calendarToTimestamp.getUTCHours() + 2);
  } else {
    calendarDate = date;
  }

  let pdfButton: React.JSX.Element | null = null;
  let pdfWithInternalNotesButton: React.JSX.Element | null = null;
  if (customerSettings.bookkeepingDayPDF) {
    const pdfFilename = `Dagseddel-${machineOperatorInitials}-${date}.pdf`;
    const pdfUrl = `${globalConfig.baseURL}/download/bookkeeping/pdf/${machineOperatorId}/${date}/${pdfFilename}?token=${shareToken}`;
    pdfButton = (
      <Button
        href={pdfUrl}
        startIcon={<FilePdfIcon />}
        target={window.cordova && bowser.ios ? "_system" : "_blank"}
        variant="text"
      >
        <FormattedMessage defaultMessage="Download PDF" />
      </Button>
    );

    const pdfWithInternalNotesFilename = `Dagseddel-${machineOperatorInitials}-${date}-intern.pdf`;
    const pdfWithInternalNotesUrl = `${globalConfig.baseURL}/download/bookkeeping/internal-pdf/${machineOperatorId}/${date}/${pdfWithInternalNotesFilename}?token=${shareToken}`;

    pdfWithInternalNotesButton = (
      <Button
        href={pdfWithInternalNotesUrl}
        startIcon={<FilePdfIcon />}
        target={window.cordova && bowser.ios ? "_system" : "_blank"}
        variant="text"
      >
        <FormattedMessage defaultMessage="Download PDF (inkl. interne noter)" />
      </Button>
    );
  }

  let daysAbsenceIntersectingDayList;
  let hoursAbsenceIntersectingDayList;

  if (calendarFromTimestamp && calendarToTimestamp) {
    ({daysAbsenceIntersectingDayList, hoursAbsenceIntersectingDayList} = getAbsenceLists(
      customerSettings,
      date,
      calendarFromTimestamp.toISOString(),
      calendarToTimestamp.toISOString(),
      daysAbsenceArray,
      hoursAbsenceArray,
    ));
  }

  const fromISOString = calendarFromTimestamp?.toISOString();
  const toISOString = calendarToTimestamp?.toISOString();
  const handleGPSClick = useCallback((): void => {
    if (!customerSettings.enableGPSList || !fromISOString || !toISOString) {
      return;
    }
    dispatch(
      actions.go(
        "/positions/:machineOperatorId",
        {machineOperatorId},
        {fromDateTime: fromISOString, toDateTime: toISOString},
      ),
    );
  }, [customerSettings.enableGPSList, dispatch, fromISOString, machineOperatorId, toISOString]);

  const tasksWithPhotos = new Set(taskPhotoArray.map((instance) => instance.task));

  const normalMargin = 10;
  const baseCalendarWidth = 78;

  const taskTimerStartMapping = generateTimerStartMapping(sortedTimerStarts);

  const boundResolveTaskRelations = (task: Task): TaskWithRelations => {
    let taskWithComputedTimeSet = task;
    if (!task.archivable) {
      const taskURL = task.url;
      const arrayForTask = taskTimerStartMapping[taskURL];
      const computedIntervals = computeIntervalsTruncated(arrayForTask || [], now.toISOString());
      taskWithComputedTimeSet = {...task, computedTimeSet: computedIntervals};
    }
    return resolveTaskRelations(
      taskWithComputedTimeSet,
      calendarFromTimestamp || null,
      calendarToTimestamp || null,
      now.toISOString(),
      false,
      customerSettings,
      {
        contactLookup,
        cultureLookup,
        customerLookup,
        locationLookup,
        machineLookup,
        orderLookup,
        priceItemLookup,
        productLookup,
        projectLookup,
        timerLookup,
        workTypeLookup,
      },
    );
  };
  if (!machineOperator) {
    return null;
  }

  const columns = sortTasksInColumns(
    machineOperator.url,
    [],
    intersectingCompletedTasks,
    finalIncompleteTaskList,
    boundResolveTaskRelations,
    customerSettings.taskOverlapWarningAfterMinutes,
  );
  const columnCount = columns.length;

  const calendarWidth = baseCalendarWidth + columnCount * USER_COLUMN_WIDTH;

  const gpsButtonOutionalClickHandler: ButtonProps = {};
  if (handleGPSClick) {
    gpsButtonOutionalClickHandler.onClick = handleGPSClick;
  }

  return (
    <PageLayout dialogs={validateDialogs} toolbar={intl.formatMessage(messages.title)} withPadding>
      <Grid style={bowser.tablet ? {minWidth: 1000} : {}}>
        <Cell>
          <Card>
            <CardHeader
              subheader={intl.formatMessage(messages.dailyTasksHeader)}
              title={intl.formatMessage(messages.dailyTasksHeaderSubtitle, {
                date: formatDate(date),
                initials: machineOperatorInitials,
                name: machineOperatorName,
              })}
            />
            <CardContent>
              <BookkeepingCompletedTasksSums
                date={date}
                taskList={completedTasksStartedToday}
                user={machineOperator}
                userProfile={machineOperatorProfile}
              />
            </CardContent>
            <BookkeepingTaskTable
              isManager
              tasks={combinedTasks}
              tasksWithPhotos={tasksWithPhotos}
            />
            <CardContent>
              <div style={{textAlign: "right"}}>
                {customerSettings.enableGPSList ? (
                  <Button
                    disabled={!handleGPSClick}
                    style={{marginRight: 12}}
                    variant="contained"
                    {...gpsButtonOutionalClickHandler}
                  >
                    {intl.formatMessage(messages.gps)}
                  </Button>
                ) : null}

                <Button
                  color="secondary"
                  disabled={!someUnrecorded}
                  onClick={handleValidatedButton}
                  variant="contained"
                >
                  {intl.formatMessage({defaultMessage: "Godkend"})}
                </Button>
              </div>
              {pdfButton}
              {pdfWithInternalNotesButton}
            </CardContent>
          </Card>
          {someIncomplete ? (
            <Card style={{marginTop: 10}}>
              <CardHeader title={intl.formatMessage(messages.incompleteTasksHeader)} />
              <BookkeepingTaskTable
                isManager
                tasks={finalIncompleteTaskList}
                tasksWithPhotos={tasksWithPhotos}
              />
            </Card>
          ) : null}
        </Cell>
        <Cell
          style={{
            maxWidth: calendarWidth,
          }}
        >
          <Card>
            <CardContent style={{margin: bowser.tablet ? 0 : normalMargin, padding: 0}}>
              <Calendar
                date={calendarDate}
                dateTransitionMark
                fromTimestamp={calendarFromTimestamp}
                incompleteTasks={incompleteTasks}
                intersectingCompletedTasks={intersectingCompletedTasks}
                intersectingDaysAbsenceList={daysAbsenceIntersectingDayList}
                intersectingHoursAbsenceList={hoursAbsenceIntersectingDayList}
                intersectingPlannedTasks={[]}
                singleDateFocus
                sortedTimerStarts={sortedTimerStarts}
                toTimestamp={calendarToTimestamp}
                userURLs={userURLs}
              />
            </CardContent>
          </Card>
        </Cell>
      </Grid>
    </PageLayout>
  );
});
