import {Config} from "@co-common-libs/config";
import {TaskUrl} from "@co-common-libs/resources";
import {TableCell, TableRow} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import bowser from "bowser";
import React from "react";
import {DropTargetMonitor, useDrop} from "react-dnd";

type Priority = "--not-prioritized--" | "--prioritized--";

interface TodoSplitRowOwnProps {
  children: React.ReactNode;
  customerSettings: Config;
  id: Priority;
  onDragDrop: (movedURL: TaskUrl, target: Priority) => void;
}

type TodoSplitRowProps = TodoSplitRowOwnProps;

const greenBackgroundStyle: React.CSSProperties = {
  backgroundColor: green[100],
};
const emptyStyle: React.CSSProperties = {};

export function TodoSplitRow(props: TodoSplitRowProps): React.JSX.Element {
  const {children, customerSettings, id, onDragDrop} = props;
  const [collectedProps, drop] = useDrop({
    accept: "task",
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item: any, _monitor: DropTargetMonitor) => {
      onDragDrop(item.url, id);
    },
  });
  const {canDrop, isOver} = collectedProps;

  const baseColumns = 5;
  let colSpan = baseColumns;
  if (!bowser.mobile) {
    // worktype column
    // photo icon column

    colSpan += 2;
  }
  if (customerSettings.workshopVehicleIdentificationNumber) {
    colSpan += 1;
  }
  if (customerSettings.workshopCreatedByColumn && !bowser.mobile) {
    colSpan += 1;
  }
  if (customerSettings.workshopCreatedDateColumn && !bowser.mobile) {
    colSpan += 1;
  }
  if (bowser.mobile || bowser.tablet) {
    // drag handle column
    colSpan += 1;
  }
  const style = isOver && canDrop ? greenBackgroundStyle : emptyStyle;
  return (
    <TableRow ref={drop} style={style}>
      <TableCell colSpan={colSpan}>
        <strong>{children}</strong>
      </TableCell>
    </TableRow>
  );
}
