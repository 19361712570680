import {Config} from "@co-common-libs/config";
import {AnniversaryType, PatchOperation, Task, User, UserProfile} from "@co-common-libs/resources";
import {dateToString} from "@co-common-libs/utils";
import {actions} from "@co-frontend-libs/redux";
import {useTheme} from "@material-ui/core";
import {blue, green, red} from "@material-ui/core/colors";
import useMergedRef from "@react-hook/merged-ref";
import {USER_COLUMN_WIDTH} from "app-components";
import ImmutableDate from "bloody-immutable-date";
import bowser from "bowser";
import _ from "lodash";
import HotelIcon from "mdi-react/HotelIcon";
import ThumbDownIcon from "mdi-react/ThumbDownIcon";
import ThumbUpIcon from "mdi-react/ThumbUpIcon";
import React, {useCallback} from "react";
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from "react-dnd";
import {useDispatch} from "react-redux";
import {getAnniversaryIcon} from "../../user-profile/anniversary-card";

const IS_MOBILE = bowser.mobile || bowser.tablet;

interface UserHeaderProps {
  anniversaries?: readonly AnniversaryType[] | undefined;
  availability?: boolean | undefined;
  columnCount: number;
  customerSettings: Config;
  date: ImmutableDate;
  dndMode?: boolean;
  hasAccommodationAllowance: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLElement>, user: User, userProfile: UserProfile) => void)
    | undefined;
  onDragDrop: (movedInitials: string, targetInitials: string) => void;
  user: User;
  userProfile: UserProfile | undefined;
}

export const UserHeader = React.memo(function UserHeader(
  props: UserHeaderProps,
): React.JSX.Element {
  const {availability, columnCount, customerSettings, onClick, user, userProfile} = props;

  const theme = useTheme();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (onClick && userProfile) {
        onClick(event, user, userProfile);
      }
    },
    [onClick, user, userProfile],
  );

  const alias = customerSettings.calendarShowEmployeeAlias ? (
    <span>
      {(userProfile && userProfile.alias.toUpperCase()) || user.loginIdentifier.toUpperCase()}
    </span>
  ) : null;
  const employeeNumber = customerSettings.calendarShowEmployeeNumber ? (
    <span>{userProfile ? userProfile.employeeNumber : null}</span>
  ) : null;
  const name = customerSettings.calendarShowEmployeeName ? (
    <span style={{whiteSpace: "normal"}}>{userProfile ? userProfile.name : null}</span>
  ) : null;

  const headerLineHeight = 24;
  const headerLines =
    (customerSettings.calendarShowEmployeeAlias ? 1 : 0) +
    (customerSettings.calendarShowEmployeeNumber ? 1 : 0) +
    (customerSettings.calendarShowEmployeeName ? 2 : 0);

  const usersHeaderHeight = Math.max(headerLines, 1) * headerLineHeight;
  const dispatch = useDispatch();

  const initials = userProfile?.alias || user.loginIdentifier;

  const [dropCollectedProps, drop] = useDrop({
    accept: "user",
    canDrop(item: any, _monitor: DropTargetMonitor) {
      return item.initials !== initials;
    },
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop(item: any, _monitor: DropTargetMonitor) {
      props.onDragDrop(item.initials, initials);
      return;
    },
  });
  const {canDrop, isOver} = dropCollectedProps;

  const [taskDropCollectedProps, taskDrop] = useDrop({
    accept: "task",
    canDrop(_item: Task, _monitor: DropTargetMonitor) {
      return true;
    },
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop(task: Task, _monitor: DropTargetMonitor) {
      const plainDate = new Date(props.date.getTime());
      const date = dateToString(plainDate);
      const patch: PatchOperation<Task>[] = [];
      if (task.date !== date) {
        patch.push({
          member: "date",
          value: date,
        });
      }
      if (task.time) {
        patch.push({
          member: "time",
          value: null,
        });
      }
      if (task.machineOperator !== props.user.url) {
        patch.push({
          member: "machineOperator",
          value: props.user.url,
        });
      }
      dispatch(actions.update(task.url, patch));
    },
  });
  const {canDrop: taskCanDrop, isOver: taskIsOver} = taskDropCollectedProps;

  const [dragCollectedProps, drag, preview] = useDrag({
    canDrag(_monitor: DragSourceMonitor) {
      return !!props.dndMode;
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      initials,
    },
    type: "user",
  });
  const {isDragging} = dragCollectedProps;

  const draggingOpacity = 0.2;
  const backgroundColor = (isOver && canDrop) || (taskCanDrop && taskIsOver) ? green[100] : "#fff";
  const style: React.CSSProperties = {
    backgroundColor,
    color: theme.palette.getContrastText(backgroundColor),
    display: "inline-block",
    height: usersHeaderHeight,
    opacity: isDragging ? draggingOpacity : 1,
    textAlign: "center",
    verticalAlign: "top",
    width: USER_COLUMN_WIDTH * columnCount || 1,
  };
  if (
    props.customerSettings.concurrentTasksAllowed ||
    props.customerSettings.concurrentTasksAllowedForDepartments.length ||
    props.customerSettings.concurrentTasksAllowedForWorkTypes.length
  ) {
    style.borderRight = "1px solid #000";
  }
  if (onClick) {
    style.cursor = "pointer";
  }
  let availabilityIcon;
  const iconSize = 12;
  if (availability === true) {
    availabilityIcon = <ThumbUpIcon color={green[300]} size={iconSize} />;
  } else if (availability === false) {
    availabilityIcon = <ThumbDownIcon color={red[300]} size={iconSize} />;
  }
  let anniversaries;
  if (props.anniversaries) {
    anniversaries = props.anniversaries.map((a, index) => (
      <span key={index}>{getAnniversaryIcon(a.icon, blue[300], iconSize)}</span>
    ));
  }
  let accommodation;
  if (props.hasAccommodationAllowance) {
    const HotelIconNoType = HotelIcon as any;
    accommodation = <HotelIconNoType size={iconSize} />;
  }
  const aliasLine = alias ? (
    <>
      {alias} {availabilityIcon}
      {employeeNumber || name ? <br /> : null}
    </>
  ) : null;
  const employeeNumberLine = employeeNumber ? (
    <>
      {employeeNumber} {!alias ? availabilityIcon : null}
      {name ? <br /> : null}
    </>
  ) : null;
  const nameLine = name ? (
    <>
      {name} {!alias && !employeeNumber ? availabilityIcon : null}
    </>
  ) : null;
  const multiRef = useMergedRef<HTMLDivElement>(drag, drop, preview, taskDrop);
  return (
    <div onClick={handleClick} ref={IS_MOBILE ? taskDrop : multiRef} style={style}>
      {accommodation}
      {anniversaries}
      {aliasLine}
      {employeeNumberLine}
      {nameLine}
    </div>
  );
}, _.isEqual);
