import {MenuToolbar, PageLayout} from "app-components";
import React from "react";
import {useIntl} from "react-intl";
import StandardProducts from "./standard-products";

interface FavoriteProductsSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const FavoriteProductsSettingsPage = React.memo(function FavoriteProductsSettingsPage(
  props: FavoriteProductsSettingsPageProps,
): React.JSX.Element {
  const intl = useIntl();

  return (
    <PageLayout
      toolbar={
        <MenuToolbar
          onMenuButton={props.onMenuButton}
          title={intl.formatMessage({defaultMessage: "Favoritvarer"})}
        />
      }
      withBottomScrollPadding
    >
      <StandardProducts />
    </PageLayout>
  );
});
