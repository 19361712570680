import {Config} from "@co-common-libs/config";
import {colorMap} from "@co-frontend-libs/utils";
import {Button, ButtonProps, Grid} from "@material-ui/core";
import {PureComponent} from "app-utils";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";

const messages = defineMessages({
  approveInvoicing: {
    defaultMessage: "Godkend til bogføring",
    id: "task-instance.label.approve-invoicing",
  },
  approveReport: {
    defaultMessage: "Godkend indberetning",
    id: "task-instance.label.approve-report",
  },
  cancelled: {
    defaultMessage: "Afslut som aflyst",
    id: "task-instance.label.cancelled-completed",
  },
  completeAsInternal: {
    defaultMessage: "Aflever som intern",
    id: "task-instance.label.complete-as-internal",
  },
  continueToNextCustomer: {
    defaultMessage: "Videre til næste kunde",
    id: "task-instance.label.continue-to-next-customer",
  },
  convertToCustomerTask: {
    defaultMessage: "Til kundeopg.",
    id: "task-instance.label.to-customer",
  },
  copyTask: {defaultMessage: "Opret kopi", id: "task-instance.label.copy"},
  delete: {defaultMessage: "Slet", id: "task-instance.label.delete"},
  deliverToAdministration: {
    defaultMessage: "Udført",
  },
  endOfDay: {
    defaultMessage: "Afslut og viderefør",
    id: "task-instance.label.new-task-deliver-to-administration",
  },
  extraWork: {
    defaultMessage: "Ekstraarbejde",
    id: "task-instance.label.extra-work",
  },
  takeTask: {defaultMessage: "Tag opgave", id: "task-instance.label.take-task"},
  validate: {defaultMessage: "Godkend", id: "task-instance.label.validate"},
});

interface ActionButtonsProps {
  canTakeTask: boolean;
  completed: boolean;
  customerSettings: Config;
  disableDelete: boolean;
  hasEffectiveTime?: boolean;
  hasInvoicedTime?: boolean;
  hasProject?: boolean;
  includeCancelled?: boolean;
  includeCopy?: boolean;
  onCancelledButton?: () => void;
  onCompletedAsInternalButton?: () => void;
  onCompletedButton: () => void;
  onContinueToNextCustomerButton?: () => void;
  onConvertToCustomerTask?: () => void;
  onCopyButton: () => void;
  onDeleteButton: () => void;
  onExtraWorkButton?: () => void;
  onIncompleteButton: () => void;
  onSaveButton: () => void;
  onTakeButton: () => void;
  onValidatedButton: () => void;
  reportApproved: boolean;
  userIsOnlyMachineOperator: boolean;
  userIsOtherMachineOperator: boolean;
  validated: boolean;
}

export class ActionButtons extends PureComponent<ActionButtonsProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): React.JSX.Element {
    const {
      canTakeTask,
      completed,
      disableDelete,
      hasEffectiveTime = false,
      hasInvoicedTime = false,
      hasProject,
      includeCancelled,
      includeCopy,
      onCompletedAsInternalButton,
      onConvertToCustomerTask,
      onExtraWorkButton,
      userIsOnlyMachineOperator,
      userIsOtherMachineOperator,
      validated,
    } = this.props;
    const {enableConvertInternalToCustomerTask} = this.props.customerSettings;
    const {formatMessage} = this.context;
    const truncateStyle: React.CSSProperties = {
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    };
    let validateBlock: React.JSX.Element | null = null;
    if (!userIsOnlyMachineOperator) {
      validateBlock = (
        <Grid item key="validated-button" sm xs={12}>
          <Button
            color="secondary"
            disabled={!completed || validated}
            fullWidth
            onClick={this.props.onValidatedButton}
            variant="contained"
          >
            {formatMessage(messages.validate)}
          </Button>
        </Grid>
      );
    }

    const deleteButtonStyle: React.CSSProperties = disableDelete
      ? {
          width: "100%",
        }
      : {
          backgroundColor: colorMap.ERROR,
          color: "#fff",
          width: "100%",
        };

    const cancelledButtonStyle: React.CSSProperties = {...truncateStyle};
    if (
      !completed &&
      !validated &&
      !userIsOtherMachineOperator &&
      !hasEffectiveTime &&
      !hasInvoicedTime
    ) {
      cancelledButtonStyle.backgroundColor = colorMap.ERROR;
    }
    if (!completed && !validated && !userIsOtherMachineOperator && !disableDelete) {
      cancelledButtonStyle.color = "#fff";
    }

    const cancelledButtonOptionalClickHandlerProps: ButtonProps = {};
    if (this.props.onCancelledButton) {
      cancelledButtonOptionalClickHandlerProps.onClick = this.props.onCancelledButton;
    }

    const completedAsInternalButtonOptionalClickHandlerProps: ButtonProps = {};
    if (this.props.onCompletedAsInternalButton) {
      completedAsInternalButtonOptionalClickHandlerProps.onClick =
        this.props.onCompletedAsInternalButton;
    }

    return (
      <Grid container spacing={1}>
        <Grid item sm xs={12}>
          <Button
            disabled={disableDelete}
            onClick={this.props.onDeleteButton}
            style={deleteButtonStyle}
            variant="contained"
          >
            {formatMessage(messages.delete)}
          </Button>
        </Grid>
        <Grid item sm xs={12}>
          <Button
            color="secondary"
            disabled={completed || validated || userIsOtherMachineOperator}
            fullWidth
            onClick={this.props.onCompletedButton}
            variant="contained"
          >
            {formatMessage(messages.deliverToAdministration)}
          </Button>
        </Grid>
        {!this.props.customerSettings.hideCompleteAndMakeCopyButton ? (
          <Grid item sm xs={12}>
            <Button
              color="primary"
              disabled={completed || validated || userIsOtherMachineOperator}
              fullWidth
              onClick={this.props.onIncompleteButton}
              style={truncateStyle}
              variant="contained"
            >
              {formatMessage(messages.endOfDay)}
            </Button>
          </Grid>
        ) : null}
        {this.props.onContinueToNextCustomerButton &&
        this.props.customerSettings.showContinueToNextCustomerButton ? (
          <Grid item sm xs={12}>
            <Button
              color="primary"
              disabled={userIsOtherMachineOperator}
              fullWidth
              onClick={this.props.onContinueToNextCustomerButton}
              style={truncateStyle}
              variant="contained"
            >
              {formatMessage(messages.continueToNextCustomer)}
            </Button>
          </Grid>
        ) : null}
        {includeCancelled ? (
          <Grid item sm xs={12}>
            <Button
              disabled={
                completed ||
                validated ||
                userIsOtherMachineOperator ||
                hasEffectiveTime ||
                hasInvoicedTime
              }
              fullWidth
              style={{
                ...truncateStyle,
                ...cancelledButtonStyle,
              }}
              variant="contained"
              {...cancelledButtonOptionalClickHandlerProps}
            >
              {formatMessage(messages.cancelled)}
            </Button>
          </Grid>
        ) : null}
        {includeCopy ? (
          <Grid item sm xs={12}>
            <Button
              color="primary"
              fullWidth
              onClick={this.props.onCopyButton}
              style={truncateStyle}
              variant="contained"
            >
              {formatMessage(messages.copyTask)}
            </Button>
          </Grid>
        ) : null}
        {this.props.customerSettings.enableExtraWorkButton && !!onExtraWorkButton ? (
          <Grid item sm xs={12}>
            <Button
              color="primary"
              disabled={!hasProject}
              fullWidth
              onClick={onExtraWorkButton}
              style={truncateStyle}
              variant="contained"
            >
              {formatMessage(messages.extraWork)}
            </Button>
          </Grid>
        ) : null}
        {this.props.customerSettings.allowTakingPendingTasksFromOthers ? (
          <Grid item sm xs={12}>
            <Button
              color="primary"
              disabled={completed || validated || !canTakeTask}
              fullWidth
              onClick={this.props.onTakeButton}
              style={truncateStyle}
              variant="contained"
            >
              {formatMessage(messages.takeTask)}
            </Button>
          </Grid>
        ) : null}
        {onConvertToCustomerTask &&
        !userIsOnlyMachineOperator &&
        enableConvertInternalToCustomerTask ? (
          <Grid item sm xs={12}>
            <Button
              color="secondary"
              disabled={validated}
              onClick={onConvertToCustomerTask}
              style={{width: "100%"}}
              variant="contained"
            >
              {formatMessage(messages.convertToCustomerTask)}
            </Button>
          </Grid>
        ) : null}
        {validateBlock}
        {!!onCompletedAsInternalButton && this.props.customerSettings.completeTaskAsInternal ? (
          <Grid item sm xs={12}>
            <Button
              color="primary"
              disabled={completed || validated || hasEffectiveTime || hasInvoicedTime}
              fullWidth
              style={truncateStyle}
              variant="contained"
              {...completedAsInternalButtonOptionalClickHandlerProps}
            >
              {formatMessage(messages.completeAsInternal)}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    );
  }
}
