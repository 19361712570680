import {DebouncedAppbarSearchField} from "@co-frontend-libs/components";
import {MenuToolbar, PageLayout} from "app-components";
import {useQueryParameterState} from "app-utils";
import React from "react";
import {useIntl} from "react-intl";
import {LocationTypes} from "./location-types";

interface LocationTypesSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const LocationTypesSettingsPage = React.memo(function LocationTypesSettingsPage(
  props: LocationTypesSettingsPageProps,
): React.JSX.Element {
  const intl = useIntl();

  const [filter, setFilter] = useQueryParameterState<string>("q", "");

  return (
    <PageLayout
      toolbar={
        <MenuToolbar
          onMenuButton={props.onMenuButton}
          rightElement={
            <DebouncedAppbarSearchField debounceTimeout={200} onChange={setFilter} value={filter} />
          }
          title={intl.formatMessage({defaultMessage: "Stedtyper"})}
        />
      }
      withBottomScrollPadding
    >
      <LocationTypes />
    </PageLayout>
  );
});
