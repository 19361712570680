import {VolumeDiscount} from "@co-common-libs/resources";
import {DecimalField, ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";

const messages = defineMessages({
  discountPercentage: {
    defaultMessage: "Procentsats",
    id: "priceitems-settings.label.discountPercentage",
  },
  threshold: {
    defaultMessage: "Grænse",
    id: "priceitems-settings.label.threshold",
  },
  title: {
    defaultMessage: "Angiv mængderabat",
    id: "priceitems-settings.dialog-title.volume-discount",
  },
});

interface VolumeDiscountDialogProps {
  instance?: VolumeDiscount | undefined;
  onCancel: () => void;
  onOk: (data: {discountPercentage: number; threshold: number}) => void;
  open: boolean;
}

interface VolumeDiscountDialogState {
  discountPercentage: number | null;
  threshold: number | null;
}

class VolumeDiscountDialog extends PureComponent<VolumeDiscountDialogProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: VolumeDiscountDialogState = {
    discountPercentage: null,
    threshold: null,
  };
  @bind
  handleCancel(): void {
    this.props.onCancel();
    this.setState({discountPercentage: null, threshold: null});
  }
  @bind
  handleDiscountPercentageChange(value: number | null): void {
    this.setState({discountPercentage: value || null});
  }
  @bind
  handleOk(): void {
    const {discountPercentage, threshold} = this.state;
    if (threshold != null && discountPercentage != null) {
      this.props.onOk({discountPercentage, threshold});
    }

    this.setState({discountPercentage: null, threshold: null});
  }
  @bind
  handleThresholdChange(value: number | null): void {
    this.setState({threshold: value || null});
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    return (
      <ResponsiveDialog
        okDisabled={!this.state.threshold || !this.state.discountPercentage}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        open={this.props.open}
        title={formatMessage(messages.title)}
      >
        <DialogContent>
          <DecimalField
            decimalPlaces={3}
            fullWidth
            label={formatMessage(messages.threshold)}
            margin="dense"
            maxDigits={10}
            onChange={this.handleThresholdChange}
            value={this.state.threshold}
          />
          <DecimalField
            decimalPlaces={3}
            fullWidth
            label={formatMessage(messages.discountPercentage)}
            margin="dense"
            maxDigits={6}
            onChange={this.handleDiscountPercentageChange}
            value={this.state.discountPercentage}
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps: VolumeDiscountDialogProps): void {
    if (!this.props.open && nextProps.open && nextProps.instance) {
      this.setState({
        discountPercentage: nextProps.instance.discountPercentage,
        threshold: nextProps.instance.threshold,
      });
    }
  }
}

export default VolumeDiscountDialog;
