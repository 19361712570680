import {EmployeeGroupUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface EmployeeGroup extends ResourceInstance<EmployeeGroupUrl> {
  readonly active: boolean;

  readonly c5_id: string;

  readonly c5_recid: number | null;
  readonly name: string;
  readonly remoteUrl: string;
}

export const emptyEmployeeGroup: Omit<EmployeeGroup, "id" | "url"> = {
  active: true,
  c5_id: "",
  c5_recid: null,
  name: "",
  remoteUrl: "",
};
