import {KrPerLiterFuelSurchargeSpecification} from "@co-common-libs/resources";
import {DeleteDialog} from "@co-frontend-libs/components";
import {actions, getKrPerLiterFuelSurchargeBasisArray} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Card,
  CardHeader,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {KrPerLiterFuelSurchargeBasisDialog} from "./kr-per-liter-fuel-surcharge-basis-dialog";
import {KrPerLiterFuelSurchargeBasisHistoricRow} from "./kr-per-liter-fuel-surcharge-basis-historic-row";

const buttonColumnCellStyle: React.CSSProperties = {
  paddingLeft: 0,
  paddingRight: 0,
  width: 48,
};

interface KrPerLiterFuelSurchargeBasisHistoricProps {
  specification: KrPerLiterFuelSurchargeSpecification;
}

export const KrPerLiterFuelSurchargeBasisHistoric = React.memo(
  function KrPerLiterFuelSurchargeBasisHistoric(
    props: KrPerLiterFuelSurchargeBasisHistoricProps,
  ): React.JSX.Element {
    const {specification} = props;
    const {conversionFactor} = specification;

    const intl = useIntl();

    const krPerLiterFuelSurchargeBasisArray = useSelector(getKrPerLiterFuelSurchargeBasisArray);

    const dispatch = useDispatch();

    const [deletingUrl, setDeletingUrl] = useState<string | null>(null);

    const handleDeleteCancel = useCallback((): void => {
      setDeletingUrl(null);
    }, []);

    const handleDeleteOk = useCallback((): void => {
      if (deletingUrl) {
        dispatch(actions.remove(deletingUrl));
        setDeletingUrl(null);
      }
    }, [deletingUrl, dispatch]);

    const specificationFilteredBasisArray = krPerLiterFuelSurchargeBasisArray.filter(
      (basis) => basis.specification === specification.url,
    );

    const sortedBasisArray = _.reverse(
      _.sortBy(specificationFilteredBasisArray, [
        (basis) => basis.fromDate || "0000-00-00",
        (basis) => basis.url,
      ]),
    );

    const rows = sortedBasisArray.map((basis) => (
      <KrPerLiterFuelSurchargeBasisHistoricRow
        basis={basis}
        key={basis.url}
        onDeleteClick={setDeletingUrl}
        specification={specification}
      />
    ));

    const [creating, setCreating] = useState(false);
    const setCreatingTrue = useCallWithTrue(setCreating);
    const setCreatingFalse = useCallWithFalse(setCreating);

    return (
      <>
        <KrPerLiterFuelSurchargeBasisDialog
          onClose={setCreatingFalse}
          open={creating}
          specification={specification}
        />
        <DeleteDialog onCancel={handleDeleteCancel} onOk={handleDeleteOk} open={!!deletingUrl}>
          <FormattedMessage defaultMessage="Slet grundlag?" />
        </DeleteDialog>
        <Card>
          <CardHeader
            action={
              <Fab onClick={setCreatingTrue} size="small">
                <PlusIcon />
              </Fab>
            }
            title={intl.formatMessage({
              defaultMessage: "Grundlag, historisk",
            })}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Gældende fra" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Oprettet" />
                </TableCell>
                <TableCell>
                  {conversionFactor === 1 ? (
                    <FormattedMessage defaultMessage="Basispris (kr/L)" />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Basispris (kr{conversionFactor, number} L)"
                      values={{conversionFactor}}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {conversionFactor === 1 ? (
                    <FormattedMessage defaultMessage="Margin (kr/L)" />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Margin (kr{conversionFactor, number} L)"
                      values={{conversionFactor}}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Negativ tillæg" />
                </TableCell>
                <TableCell style={buttonColumnCellStyle} />
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </Card>
      </>
    );
  },
);
