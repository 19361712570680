import {resourceNameFor} from "@co-common-libs/resources";
import {current, Draft} from "@reduxjs/toolkit";
import _ from "lodash";
import {saveOnline} from "../actions";
import {ResourcesState} from "../types";

export function handleSaveOnlinePending(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof saveOnline.pending>,
): void {
  const {command, id} = action.meta.arg;
  const {requestId} = action.meta;
  const index = state.commitQueue.findIndex((entry) => entry.id === id);
  console.assert(index !== -1);
  if (index !== -1) {
    const entry = state.commitQueue[index];
    console.assert(_.isEqual(command, current(entry.command)));
    console.assert(entry.status === "SAVED_LOCALLY");
    console.assert(entry.requestId === null);
    entry.status = "SAVING_ONLINE";
    entry.requestId = requestId;
  }
}

export function handleSaveOnlineFulfilled(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof saveOnline.fulfilled>,
): void {
  const {onlineInstance, superseded} = action.payload;
  if (superseded && process.env.NODE_ENV === "production") {
    return;
  }
  const {command, id} = action.meta.arg;
  const {requestId} = action.meta;
  const index = state.commitQueue.findIndex((entry) => entry.id === id);
  console.assert(index !== -1);
  if (index !== -1) {
    const entry = state.commitQueue[index];
    if (process.env.NODE_ENV !== "production" && superseded) {
      console.assert(entry.requestId !== requestId);
    }
    if (entry.requestId === requestId) {
      console.assert(_.isEqual(command, current(entry.command)));
      console.assert(entry.status === "SAVING_ONLINE");
      state.commitQueue.splice(index, 1);
      const {url} = command;
      const resourceName = resourceNameFor(url);
      if (onlineInstance) {
        state.persistedData[resourceName][url] = onlineInstance as Draft<any>;
        if (state.temporaryData[resourceName][url]) {
          state.temporaryData[resourceName][url] = onlineInstance as Draft<any>;
        }
      } else {
        delete state.persistedData[resourceName][url];
        if (state.temporaryData[resourceName][url]) {
          delete state.temporaryData[resourceName][url];
        }
      }
    }
  }
}

const HTTP_403_FORBIDDEN = 403;

export function handleSaveOnlineRejected(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof saveOnline.rejected>,
): void {
  const {command, id} = action.meta.arg;
  const {requestId} = action.meta;
  const index = state.commitQueue.findIndex((entry) => entry.id === id);
  console.assert(index !== -1);
  if (index !== -1) {
    const entry = state.commitQueue[index];
    if (entry.requestId === requestId) {
      console.assert(_.isEqual(command, current(entry.command)));
      entry.status = "SAVED_LOCALLY";
      entry.requestId = null;
      if (action.payload) {
        const {error, errorTimestamp} = action.payload;
        if (
          error.type !== "NetworkError" &&
          (error.type !== "StatusError" || error.statusCode !== HTTP_403_FORBIDDEN)
        ) {
          entry.error = error;
          entry.errorTimestamp = errorTimestamp;
        }
      } else {
        entry.error = action.error as any;
      }
    }
  }
}
