import {formatMinutes} from "@co-common-libs/resources-utils";
import {TableCell, TableRow, useTheme} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import React from "react";
import {FormattedMessage} from "react-intl";

interface TotalRowProps {
  distributedMinutes: number;
  expectedMinutes: number;
}

export function TotalRow(props: TotalRowProps): React.JSX.Element {
  const {distributedMinutes, expectedMinutes} = props;

  const theme = useTheme();

  const totalStyle: React.CSSProperties =
    distributedMinutes !== expectedMinutes ? {color: theme.palette.error.main} : {};

  return (
    <TableRow key="total" style={{backgroundColor: grey[300]}}>
      <TableCell style={totalStyle}>
        <FormattedMessage defaultMessage="Forventet" id="time-distribution-table.label.expected" />
        /
        <FormattedMessage
          defaultMessage="Rest"
          id="time-distribution-table.label.missing"
          tagName="strong"
        />
      </TableCell>
      <TableCell>
        {formatMinutes(expectedMinutes)}/
        <strong style={totalStyle}>{formatMinutes(expectedMinutes - distributedMinutes)}</strong>
      </TableCell>
    </TableRow>
  );
}
