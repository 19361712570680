import {ImportPreviewProductWithGroupMeta} from "@co-common-libs/resources-utils";
import {
  ErrorDialog,
  SpinnerDialog,
  VerticalStackingFloatingActionButton,
  WarningDialog,
} from "@co-frontend-libs/components";
import {actions, getProductArray} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  translateNetworkError,
  useCallWithFalse,
  useCallWithTrue,
} from "@co-frontend-libs/utils";
import {getApiEndpoint} from "api-endpoint-urls";
import {globalConfig} from "frontend-global-config";
import CloudDownloadIcon from "mdi-react/CloudDownloadIcon";
import React, {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ImportFabProps2} from "../import-fab-types";
import {ImportProductsDialog} from "./import-products-dialog";

export const ImportProductsFab = React.memo(function ImportProductsFab(
  props: ImportFabProps2,
): React.JSX.Element {
  const {stackIndex} = props;

  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const setImportDialogOpenTrue = useCallWithTrue(setImportDialogOpen);
  const setImportDialogOpenFalse = useCallWithFalse(setImportDialogOpen);

  const intl = useIntl();

  const [spinnerDialogOpen, setSpinnerDialogOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  const [selectedProducts, setSelectedProducts] = useState<
    ImportPreviewProductWithGroupMeta[] | null
  >(null);

  const dispatch = useDispatch();

  const importProducts = useCallback(
    async (products: ImportPreviewProductWithGroupMeta[]) => {
      setImportDialogOpen(false);
      setSpinnerDialogOpen(true);

      try {
        // TODO(mr): refactor to `useAsync` (removes need for spinnerDialogOpen and errorMessage states)
        const response = await jsonFetch(
          getApiEndpoint(globalConfig.baseURL).importResourceUrl("product"),
          "POST",
          {
            products: products.map((p) => ({
              catalogNumber: p.identifier,
              groupIdentifier: p.group,
              name: p.name,
              price: p.price,
              remoteUrl: p.remoteUrl,
              unit: p.unit?.remoteUrl || null,
            })),
          },
        );

        if (response.data) {
          dispatch(actions.addToOffline(response.data));
        }
      } catch (error) {
        setErrorMessage(translateNetworkError(error, intl));
      }

      setSpinnerDialogOpen(false);
    },
    [dispatch, intl, setSpinnerDialogOpen],
  );

  const handleOk = useCallback(
    async (products: ImportPreviewProductWithGroupMeta[]) => {
      setImportDialogOpen(false);
      if (!products.every((p) => p.groupExists)) {
        setSelectedProducts(products);
        // Using Map to remove duplicates coming from products with the same group
        const missingGroupsWithCounts = new Map(
          products.filter((p) => !p.groupExists).map((p) => [p.group, p.groupProductCount]),
        );
        const totalProductCount = [...missingGroupsWithCounts.values()].reduce((prev, current) => {
          return prev + current;
        }, 0);
        setWarningMessage(
          intl.formatMessage(
            {
              defaultMessage:
                "Ved at importere disse varer importeres der også {groups} varegruppe(r) med i alt {products} varer",
            },
            {groups: missingGroupsWithCounts.size, products: totalProductCount},
          ),
        );
      } else {
        await importProducts(products);
      }
    },
    [importProducts, intl],
  );

  const handleWarningMessageCancel = useCallback(() => {
    setSelectedProducts(null);
    setWarningMessage("");
  }, []);

  const handleWarningMessageOk = useCallback(async () => {
    setWarningMessage("");
    if (selectedProducts) {
      setSelectedProducts(null);
      await importProducts(selectedProducts);
    }
  }, [importProducts, selectedProducts]);

  const handleErrorMessageOk = useCallback(() => {
    setErrorMessage("");
    setSpinnerDialogOpen(false);
  }, [setSpinnerDialogOpen]);

  const productArray = useSelector(getProductArray);

  const existingProductRemoteUrls = useMemo(
    () =>
      new Set(
        productArray.filter(({active, group}) => active && group).map(({remoteUrl}) => remoteUrl),
      ),
    [productArray],
  );

  return (
    <>
      <VerticalStackingFloatingActionButton
        onClick={setImportDialogOpenTrue}
        stackIndex={stackIndex}
      >
        <CloudDownloadIcon />
      </VerticalStackingFloatingActionButton>
      <ImportProductsDialog
        filter={existingProductRemoteUrls}
        onCancel={setImportDialogOpenFalse}
        onOk={handleOk}
        open={importDialogOpen}
      />
      <SpinnerDialog
        open={spinnerDialogOpen}
        title={intl.formatMessage({defaultMessage: "Importerer varer"})}
      />
      <WarningDialog
        message={warningMessage}
        onCancel={handleWarningMessageCancel}
        onOk={handleWarningMessageOk}
        open={!!warningMessage}
        title={intl.formatMessage({defaultMessage: "Advarsel"})}
      />
      <ErrorDialog
        message={errorMessage}
        onOk={handleErrorMessageOk}
        open={!!errorMessage}
        title={intl.formatMessage({defaultMessage: "Import fejlede"})}
      />
    </>
  );
});
