import {useTheme} from "@material-ui/core";
import React from "react";

interface AlertMessageProps {
  text: string;
  type: "error" | "warning";
}

export function AlertMessage(props: AlertMessageProps): React.JSX.Element {
  const {text, type} = props;
  const theme = useTheme();
  const color = type === "error" ? theme.palette.error.main : theme.palette.warning.main;
  return (
    <div
      style={{
        color,
      }}
    >
      <span>{text}</span>
    </div>
  );
}
