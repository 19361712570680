import {Config} from "@co-common-libs/config";
import {AppState, getCurrentRoleIsManager, getCustomerSettings} from "@co-frontend-libs/redux";
import {statusColorMap} from "@co-frontend-libs/utils";
import {createSelector} from "reselect";
import {TaskTableDataType} from "./types";

const FADED_STYLE: Readonly<React.CSSProperties> = {color: "#9e9e9e"};
const NOOP_STYLE: Readonly<React.CSSProperties> = {};

function fadeCompleted(data: TaskTableDataType): React.CSSProperties {
  if (data.completed) {
    return FADED_STYLE;
  } else {
    return NOOP_STYLE;
  }
}

function workStatusBackgroundColor({status}: TaskTableDataType): React.CSSProperties | undefined {
  const color = statusColorMap[status];
  return color ? {backgroundColor: color} : undefined;
}

const buildTaskTableRowStyleFunction =
  (currentRoleIsManager: boolean, {enableBackgroundColorTasks}: Config) =>
  (data: TaskTableDataType) => ({
    ...(currentRoleIsManager ? {} : fadeCompleted(data)),
    ...(enableBackgroundColorTasks ? workStatusBackgroundColor(data) : {}),
  });

export const getTaskTableRowStyleFunction: (
  state: AppState,
) => (data: TaskTableDataType) => React.CSSProperties = createSelector(
  getCurrentRoleIsManager,
  getCustomerSettings,
  buildTaskTableRowStyleFunction,
);
