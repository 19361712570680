export const pathTemplates = [
  "/",
  "/fullscreenTaskCalendar",
  "/fullscreenGeolocationMap",
  "/checkOut",
  // "/default",
  "/deviceSetup",
  "/order",
  "/order/:id",
  "/order/:id/:taskID",
  "/orderEntry/:id",
  "/culture",
  "/culture/:id",
  "/customer",
  "/customer/entry",
  "/customer/entry/:id",
  "/customer/:id",
  "/task/:id",
  "/taskDetails/:id",
  "/taskEdit/:id",
  "/taskCalendar",
  "/remunerationReport",
  "/remunerationReport/:id",
  "/timeOverview",
  "/task",
  "/bookkeeping",
  "/bookkeepingDay/:date/:machineOperatorId",
  "/internalTask/:id",
  "/userProfile/:id",
  "/user",
  "/salaryVoucher",
  "/salaryVoucher/:id",
  "/computeSalaryVoucher",
  "/navSync",
  "/sync",
  "/economicSync",
  "/brugerdataSync",
  "/geolocationMap",
  "/workshop",
  "/machine/:id",
  "/journal/:id",
  "/information",
  "/information/:id",
  "/dinnerBookings",
  "/tasksPrintout",
  "/settings/anniversary",
  "/settings/employeeGroup",
  "/settings/crops",
  "/settings/favoriteMachines",
  "/settings/favoriteProducts",
  "/settings/fields",
  "/settings/foodBooking",
  "/settings/fuelSurcharge",
  "/settings/importAndExport",
  "/settings/locations",
  "/settings/locationTypes",
  "/settings/machineAnalysis",
  "/settings/machines",
  "/settings/overview",
  "/settings/productGroups",
  "/settings/products",
  "/settings/projects",
  "/settings/routes",
  "/settings/units",
  "/settings/workshopChecklists",
  "/settings/workTypes",
  "/settings/externalWorktype/:id",
  "/settings/worktype/:id",
  "/settings/machine/:id",
  "/settings/pricegroup/:id",
  "/settings/priceitem/:id",
  "/settings/krPerLiterFuelSurcharge/:id",
  "/settings/pricePercentFuelSurcharge/:id",
  "/settings/locationType/:id",
  "/settings/route/:planID/:id",
  "/settings/route/:id",
  "/positions/:machineOperatorId",
  "/worktypeStatus",
  "/worktypeStatus/:id/:unit",
  "/report",
  "/account",
  "/systemSetup",
  "/systemSetup/timer/:id",
  "/systemSetup/reportingSpecification/:id",
  "/consultant-tools",
  "/appendices",
  "/files",
  "/files-task",
  "/locationStorage",
  "/storageJournal/:locationId",
  "/fullscreenOrderCalendar",
  "/minimalFullscreenOrderCalendar",
  "/fullscreenAbsenceCalendar",
  "/projects",
  "/projects/:id",
  "/settings/projects/:id",
] as const;

export type PathTemplate = (typeof pathTemplates)[number];
