import {TaskUrl, urlToId} from "@co-common-libs/resources";
import {CustomOfficeIconButton} from "@co-frontend-libs/components";
import {actions, getOrderLookup, getTaskLookup} from "@co-frontend-libs/redux";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

interface TaskEditButtonProps {
  compact?: boolean;
  taskUrl: TaskUrl;
}

export const TaskEditButton = React.memo(function TaskEditButton(
  props: TaskEditButtonProps,
): React.JSX.Element {
  const {compact, taskUrl} = props;

  const dispatch = useDispatch();
  const taskLookup = useSelector(getTaskLookup);
  const orderLookup = useSelector(getOrderLookup);

  const task = taskLookup(taskUrl);
  const orderUrl = task?.order;
  const order = orderUrl ? orderLookup(orderUrl) : null;
  const routePlanURL = order ? order.routePlan : null;

  const handleClick = useCallback(() => {
    if (routePlanURL) {
      dispatch(actions.go("/taskEdit/:id", {id: urlToId(taskUrl)}));
    } else if (orderUrl) {
      dispatch(
        actions.go("/order/:id/:taskID", {
          id: urlToId(orderUrl),
          taskID: urlToId(taskUrl),
        }),
      );
    } else {
      dispatch(actions.go("/internalTask/:id", {id: urlToId(taskUrl)}));
    }
  }, [dispatch, orderUrl, routePlanURL, taskUrl]);

  return (
    <CustomOfficeIconButton color="primary" compact={compact} onClick={handleClick}>
      <PencilIcon />
    </CustomOfficeIconButton>
  );
});
