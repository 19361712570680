import {
  CustomerUrl,
  Machine,
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {
  ConnectedMultipleMachinesDialog,
  ConnectedPriceGroupDialog,
} from "@co-frontend-libs/connected-components";
import {getCustomerSettings, getMachineLookup, getPriceGroupLookup} from "@co-frontend-libs/redux";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {
  MultiMachineSelectionEvent,
  MultiMachineSelectionState,
} from "./multi-machine-selection-state-machine";

function getPotentialPriceGroups(
  machineURL: MachineUrl,
  customerURL: CustomerUrl | null,
  machineLookup: (url: MachineUrl) => Machine | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
): PriceGroupUrl[] {
  const machine = machineLookup(machineURL);
  const priceGroupURLs = machine?.pricegroups;
  if (!priceGroupURLs || !priceGroupURLs.length) {
    return [];
  }
  const potentialPriceGroups = priceGroupURLs
    .map(priceGroupLookup)
    .filter(notUndefined)
    .filter((priceGroup) => priceGroup.active)
    .filter((priceGroup) => !priceGroup.onlyForExtraTimers);
  if (!potentialPriceGroups) {
    return [];
  }
  if (customerURL) {
    const customerSpecificPriceGroups = potentialPriceGroups.filter(
      (priceGroup) => priceGroup.customers && priceGroup.customers.includes(customerURL),
    );
    if (customerSpecificPriceGroups.length) {
      return customerSpecificPriceGroups.map((priceGroup) => priceGroup.url);
    }
  }
  const standardPriceGroups = potentialPriceGroups.filter(
    (priceGroup) => priceGroup.standardListing,
  );
  return standardPriceGroups.map((priceGroup) => priceGroup.url);
}

interface MultiMachineSelectionDisplayProps {
  send: (event: MultiMachineSelectionEvent) => void;
  state: MultiMachineSelectionState;
}

export function MultiMachineSelectionDisplay(
  props: MultiMachineSelectionDisplayProps,
): React.JSX.Element {
  const {send, state} = props;

  const handleCancel = useCallback(() => {
    send({type: "CANCEL"});
  }, [send]);

  const customerSettings = useSelector(getCustomerSettings);
  const machineLookup = useSelector(getMachineLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);

  const handleMultiMachineDialogOk = useCallback(
    (urls: ReadonlySet<MachineUrl>) => {
      const machines = Array.from(urls).map((url) => {
        const potentialPriceGroups = getPotentialPriceGroups(
          url,
          state.context.customer,
          machineLookup,
          priceGroupLookup,
        );
        const priceGroup = potentialPriceGroups.length === 1 ? potentialPriceGroups[0] : null;
        return {
          machine: url,
          priceGroup,
          priceGroupOptions: potentialPriceGroups,
        };
      });
      send({machines, type: "MACHINES_SELECTED"});
    },
    [machineLookup, priceGroupLookup, send, state.context.customer],
  );

  const handlePriceGroupDialogOk = useCallback(
    (url: PriceGroupUrl) => {
      send({type: "PRICE_GROUP_SELECTED", url});
    },
    [send],
  );
  return (
    <>
      <ConnectedMultipleMachinesDialog
        barcodeScannerFormats={customerSettings.barcodeScannerMachineDialog}
        includeSelectAll={false}
        onCancel={handleCancel}
        onOk={handleMultiMachineDialogOk}
        open={state.matches("selectMachines")}
      />
      <ConnectedPriceGroupDialog
        machineURL={state.context.currentMachine?.machine ?? undefined}
        onCancel={handleCancel}
        onOk={handlePriceGroupDialogOk}
        open={state.matches("selectPriceGroups")}
      />
    </>
  );
}
