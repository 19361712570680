import {CsvExportSpecification} from "@co-common-libs/csv-export-specifications";
import {Button, Card, CardActions} from "@material-ui/core";
import {SimpleDataTable} from "app-components";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {FormattedMessage} from "react-intl";

function getDownloadCsvUrl(
  exportSpecification: CsvExportSpecification,
  selectedColumns: ReadonlySet<string>,
  shareToken: string,
): string {
  const filename = `${exportSpecification.label}.csv`;
  const url = new URL(globalConfig.baseURL);
  url.pathname = `/download/csv_export/${filename}`;
  url.searchParams.append("specificationName", exportSpecification.name);
  selectedColumns.forEach((column) => url.searchParams.append("column", column));
  url.searchParams.append("token", shareToken || "");
  return `${url}`;
}

export function ExportPreviewCard(props: {
  rows: readonly (readonly string[])[];
  selectedColumnNames: ReadonlySet<string>;
  selectedExportSpecification: CsvExportSpecification;
  shareToken: string;
}): React.JSX.Element {
  const {rows, selectedColumnNames, selectedExportSpecification, shareToken} = props;

  const headerLabelsForSelectedcolumns = selectedExportSpecification.columns
    .filter(({name}) => selectedColumnNames.has(name))
    .map(({label}) => label);
  const rowsFilteredBySelectedColumns =
    selectedColumnNames.size && selectedExportSpecification.columns.length && rows.length
      ? rows.map((row) =>
          row.filter((_value, index) =>
            selectedColumnNames.has(selectedExportSpecification.columns[index]?.name),
          ),
        )
      : [];

  return (
    <Card style={{minWidth: "auto", overflow: "auto", width: "100%"}}>
      <SimpleDataTable
        headers={headerLabelsForSelectedcolumns}
        rows={rowsFilteredBySelectedColumns}
      />
      <CardActions>
        <Button
          color="primary"
          href={getDownloadCsvUrl(selectedExportSpecification, selectedColumnNames, shareToken)}
          target={window.cordova && bowser.ios ? "_system" : "_blank"}
          variant="contained"
        >
          <FormattedMessage defaultMessage="Eksporter" />
        </Button>
      </CardActions>
    </Card>
  );
}
