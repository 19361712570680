import {Timer, TimerUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {EntryData, GenericMultiSelectionSearchDialog} from "../search-dialog";

const messages = defineMessages({
  searchTimer: {
    defaultMessage: "Søg enhed",
    id: "uinit-dialog.dialog-title.search-uinit",
  },
  selectTimers: {
    defaultMessage: "Vælg enheder",
    id: "uinit-dialog.dialog-title.select-uinit",
  },
});

function computeBaseChoices(
  timerArray: readonly Timer[],
  includeOnlyTimersWithIdentifiers?: boolean,
): readonly EntryData<TimerUrl>[] {
  const result: EntryData<TimerUrl>[] = timerArray
    .filter(
      (instance) => instance.active && !(includeOnlyTimersWithIdentifiers && !instance.identifier),
    )
    .map((instance) => {
      const {identifier, label, url} = instance;
      const entry: EntryData<TimerUrl> = {
        category: "standard",
        exactMatchValue: label,
        identifier: url,
        primaryText: label,
        searchFields: [
          {label: "Navn", priority: 5, text: label},
          {label: "Identifier", priority: 10, text: identifier},
        ],
        secondaryText: identifier,
      };
      return entry;
    });
  return result;
}

interface MultipleTimerDialogProps {
  includeOnlyTimersWithIdentifiers?: boolean;
  onCancel(): void;
  onOk(urls: ReadonlySet<TimerUrl>): void;
  open: boolean;
  selected?: ReadonlySet<TimerUrl>;
  timerArray: readonly Timer[];
}

export const MultipleTimerDialog = React.memo(function MultipleTimerDialog(
  props: MultipleTimerDialogProps,
): React.JSX.Element {
  const {formatMessage} = useIntl();
  const {includeOnlyTimersWithIdentifiers, onCancel, onOk, open, selected, timerArray} = props;

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(timerArray, includeOnlyTimersWithIdentifiers);

  const title = formatMessage(messages.selectTimers);
  const searchTitle = formatMessage(messages.searchTimer);
  const selectedSet = selected;
  return (
    <GenericMultiSelectionSearchDialog<TimerUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      selected={selectedSet}
      title={title}
    />
  );
});
