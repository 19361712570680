import _ from "lodash";
import React, {ReactNode, useEffect, useRef, useState} from "react";

interface GoogleMapProps {
  children?: ReactNode | undefined;
  id: string;
  mapContainerStyle: React.CSSProperties;
  onLoad: (map: google.maps.Map) => void;
  options: google.maps.MapOptions;
}

/**
 * NOTE: changing `id` or `onLoad` not supported/ignored.
 */
export const GoogleMap = React.memo(function GoogleMap(props: GoogleMapProps): React.JSX.Element {
  const {children, id, mapContainerStyle, onLoad, options} = props;

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (!ref.current) {
      throw new Error("Missing ref after mount");
    }
    const newMap = new google.maps.Map(ref.current, options);
    setMap(newMap);
    onLoad(newMap);
    // options update handled elsewhere, onLoad only called for initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id={id} ref={ref} style={mapContainerStyle}>
      {map ? children : null}
    </div>
  );
}, _.isEqual);
