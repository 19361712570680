import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";

interface InformationReceivedDialogProps {
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

export const InformationReceivedDialog = React.memo(function InformationReceivedDialog({
  onCancel,
  onOk,
  open,
}: InformationReceivedDialogProps): React.JSX.Element {
  const intl = useIntl();

  return (
    <ResponsiveDialog
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      title={intl.formatMessage({
        defaultMessage: "Ny information modtaget",
      })}
    >
      <DialogContent>
        <FormattedMessage defaultMessage="Du har modtaget en ny information. Vil du gå til informationssiden?" />
      </DialogContent>
    </ResponsiveDialog>
  );
});
