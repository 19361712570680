import {EmployeeGroup} from "@co-common-libs/resources";
import {makeMapFromArray} from "@co-common-libs/utils";
import {createSelector} from "reselect";
import {
  getCustomerSettings,
  getEmployeeGroupArray,
  getUserProfileArray,
} from "../resources/selectors";
import {StateWithResources} from "../resources/types";
import {filterOnActive} from "./utils";

export const getActiveEmployeeGroupArray: (state: StateWithResources) => readonly EmployeeGroup[] =
  createSelector(getEmployeeGroupArray, filterOnActive);

export const isEmployeeGroupsActivated = createSelector(
  [getUserProfileArray, getActiveEmployeeGroupArray, getCustomerSettings],
  (userProfileArray, activeEmployeeGroupArray, customerSettings) => {
    const activeEmployeeGroupUrls = makeMapFromArray(activeEmployeeGroupArray, ({url}) => url);
    return (
      customerSettings.enableEmployeeGroups &&
      userProfileArray.some(
        ({employeeGroup: userEmployeeGroupUrl}) =>
          userEmployeeGroupUrl && activeEmployeeGroupUrls.has(userEmployeeGroupUrl),
      )
    );
  },
);
