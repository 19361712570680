import {createStyles, makeStyles} from "@material-ui/core";
import React from "react";
import {useIntl} from "react-intl";
import {useIsOnline} from "react-use-is-online";

const useOfflineStyles = makeStyles((theme) =>
  createStyles({
    colorOffline: {
      backgroundColor: theme.palette.warning.main,
      borderRadius: 4,
      textAlign: "center",
    },
  }),
);

export const OfflineWarning = (): React.JSX.Element | null => {
  const intl = useIntl();
  const {isOnline} = useIsOnline();
  const offlineClasses = useOfflineStyles();

  return !isOnline ? (
    <p className={offlineClasses.colorOffline}>
      {intl.formatMessage({
        defaultMessage: "Denne funktionalitet kræver at du er online",
      })}
    </p>
  ) : null;
};
