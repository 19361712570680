import {
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  Product,
  ProductUrl,
  ProductUse,
  Task,
  TimerUrl,
  Unit,
  UnitUrl,
  WorkType,
} from "@co-common-libs/resources";
import {
  getUnitString,
  getWorkTypeString,
  priceItemIsVisible,
} from "@co-common-libs/resources-utils";
import {
  caseAccentInsensitiveCollator,
  formatDateNumeric,
  HOUR_MINUTES,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {FilePdfIcon} from "@co-frontend-libs/components";
import {
  getCustomerSettings,
  getPriceItemLookup,
  getProductLookup,
  getTimerArray,
  getToken,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {computeIntervalSums, getGenericPrimaryTimer, mergeIntervals} from "app-utils";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import React from "react";
import {defineMessages, FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {AjaxDownloadButton} from "./ajax-download-button";

const messages = defineMessages({
  downloadPDF: {
    defaultMessage: "Download PDF",
    id: "worktype-sum-list.download-pdf",
  },
  hours: {
    defaultMessage: "Timer",
    id: "worktype-sum-list.hours",
  },
  time: {
    defaultMessage: "Effektiv tid",
    id: "worktype-sum-list.time",
  },
});

const unitColumnStyle = {
  width: 86,
} as const;
const countColumnStyle = {
  textAlign: "right",
  width: 100,
} as const;

interface WorkTypeRowProps {
  workType?: WorkType | undefined;
}

const WorkTypeRow = React.memo(function WorkTypeRow(props: WorkTypeRowProps): React.JSX.Element {
  const {workType} = props;
  const workTypeURL = workType ? workType.url : "";
  return (
    <TableRow key={workTypeURL}>
      <TableCell style={{fontWeight: "bold"}}>{getWorkTypeString(workType)}</TableCell>
      <TableCell style={countColumnStyle} />
      <TableCell style={unitColumnStyle} />
    </TableRow>
  );
});

interface SumItemRowProps {
  amount: number;
  name: string;
  unit: string;
}

const SumItemRow = React.memo(function SumItemRow(props: SumItemRowProps): React.JSX.Element {
  const {amount, name, unit} = props;
  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell style={countColumnStyle}>
        <FormattedNumber value={amount} />
      </TableCell>
      <TableCell style={unitColumnStyle}>{unit}</TableCell>
    </TableRow>
  );
});

function updateSumMap(
  sumItemMap: Map<
    string,
    {
      amount: number;
      name: string;
      unit: string;
    }
  >,
  amount: number,
  name: string,
  unit: string,
): void {
  const key = `${name}-${unit}`;
  const itemSumItem = sumItemMap.get(key);
  if (!itemSumItem) {
    sumItemMap.set(key, {
      amount,
      name,
      unit,
    });
  } else {
    itemSumItem.amount += amount;
  }
}

function updatePriceItemSum(
  priceItemUseSet: readonly PriceItemUse[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  sumItemMap: Map<
    string,
    {
      amount: number;
      name: string;
      unit: string;
    }
  >,
): void {
  priceItemUseSet.forEach((priceItemUse: PriceItemUse) => {
    if (!priceItemUse.count && !priceItemUse.correctedCount) {
      return;
    }
    const priceItem = priceItemLookup(priceItemUse.priceItem);
    if (
      !priceItem ||
      !priceItemIsVisible(priceItem, false, priceItemUseSet, unitLookup, priceItemLookup)
    ) {
      return;
    }
    updateSumMap(
      sumItemMap,
      priceItemUse.correctedCount || priceItemUse.count || 0,
      priceItem.name,
      getUnitString(priceItem, unitLookup),
    );
  });
}

function updateProductSum(
  productUseSet: readonly ProductUse[],
  productLookup: (url: ProductUrl) => Product | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  sumItemMap: Map<
    string,
    {
      amount: number;
      name: string;
      unit: string;
    }
  >,
): void {
  productUseSet.forEach((productUse: ProductUse) => {
    if (!productUse.count && !productUse.correctedCount) {
      return;
    }
    const product = productLookup(productUse.product);
    if (!product) {
      return;
    }
    updateSumMap(
      sumItemMap,
      productUse.correctedCount || productUse.count || 0,
      product.name,
      getUnitString(product, unitLookup),
    );
  });
}

const getTaskEffectiveHours = (task: Task, genericPrimaryTimerURL: TimerUrl, now: Date): number => {
  const computedIntervals = task.computedTimeSet || [];
  const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
  const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
  const intervals = mergeIntervals(
    computedIntervals,
    correctionIntervals,
    managerCorrectionIntervals,
  );
  const finalSums = computeIntervalSums(intervals, now);
  const minutesEffective = finalSums.get(genericPrimaryTimerURL) || 0;
  return minutesEffective / HOUR_MINUTES;
};

interface WorkTypeSumListProps {
  customerName: string;
  fromDate: string;
  taskList: readonly Task[];
  toDate: string;
}

export const WorkTypeSumList = React.memo(function WorkTypeSumList(
  props: WorkTypeSumListProps,
): React.JSX.Element {
  const {customerName, fromDate, taskList, toDate} = props;

  const {formatMessage} = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const timerArray = useSelector(getTimerArray);
  const token = useSelector(getToken);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const unitLookup = useSelector(getUnitLookup);

  const rowArray: React.JSX.Element[] = [];
  const workTypesPDFSum = new Map<
    string,
    Map<string, {amount: number; name: string; unit: string}>
  >();
  let noWorkTypesPDFSum: Map<string, {amount: number; name: string; unit: string}> | undefined;
  const genericPrimaryTimer = getGenericPrimaryTimer(timerArray);
  const genericPrimaryTimerURL = genericPrimaryTimer ? genericPrimaryTimer.url : null;
  const now = new Date();

  if (customerSettings.noExternalTaskWorkType) {
    const sum = new Map<string, {amount: number; name: string; unit: string}>();

    taskList.forEach((task) => {
      updatePriceItemSum(
        sortByOrderMember(Object.values(task.priceItemUses || {})),
        priceItemLookup,
        unitLookup,
        sum,
      );
      updateProductSum(
        sortByOrderMember(Object.values(task.productUses || {})),
        productLookup,
        unitLookup,
        sum,
      );
      const hoursEffective = genericPrimaryTimerURL
        ? getTaskEffectiveHours(task, genericPrimaryTimerURL, now)
        : 0;
      if (hoursEffective) {
        const key = "effective-time";
        const itemSumItem = sum.get(key);
        if (!itemSumItem) {
          sum.set(key, {
            amount: hoursEffective,
            name: formatMessage(messages.time),
            unit: formatMessage(messages.hours),
          });
        } else {
          itemSumItem.amount += hoursEffective;
        }
      }
    });

    if (sum.size) {
      _.sortBy(Array.from(sum.entries()), ([ident, _sumItem]) => ident).forEach(
        ([ident, sumItem]) => {
          rowArray.push(
            <SumItemRow
              amount={sumItem.amount}
              key={ident}
              name={sumItem.name}
              unit={sumItem.unit}
            />,
          );
        },
      );
    }
    noWorkTypesPDFSum = sum;
  } else {
    const workTypeTaskMap = new Map<string, {tasks: Task[]; workType: WorkType | undefined}>();
    taskList.forEach((task) => {
      const workTypeURL = task.workType;
      const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
      const workTypeKey = getWorkTypeString(workType);
      const workTypeTaskItem = workTypeTaskMap.get(workTypeKey);
      if (workTypeTaskItem) {
        workTypeTaskItem.tasks.push(task);
      } else {
        workTypeTaskMap.set(workTypeKey, {
          tasks: [task],
          workType,
        });
      }
    });

    Array.from(workTypeTaskMap.entries())
      .sort(([aWorkTypeKey, _aData], [bWorkTypeKey, _bData]) =>
        caseAccentInsensitiveCollator.compare(aWorkTypeKey, bWorkTypeKey),
      )
      .forEach(([workTypeKey, data]) => {
        const sum = new Map<string, {amount: number; name: string; unit: string}>();
        const {tasks, workType} = data;

        tasks.forEach((task) => {
          updatePriceItemSum(
            sortByOrderMember(Object.values(task.priceItemUses || {})),
            priceItemLookup,
            unitLookup,
            sum,
          );
          updateProductSum(
            sortByOrderMember(Object.values(task.productUses || {})),
            productLookup,
            unitLookup,
            sum,
          );
          const hoursEffective = genericPrimaryTimerURL
            ? getTaskEffectiveHours(task, genericPrimaryTimerURL, now)
            : null;
          if (hoursEffective) {
            const key = "effective-time";
            const itemSumItem = sum.get(key);
            if (!itemSumItem) {
              sum.set(key, {
                amount: hoursEffective,
                name: formatMessage(messages.time),
                unit: formatMessage(messages.hours),
              });
            } else {
              itemSumItem.amount += hoursEffective;
            }
          }
        });

        if (sum.size) {
          workTypesPDFSum.set(getWorkTypeString(workType), sum);
          rowArray.push(<WorkTypeRow key={workTypeKey} workType={workType} />);

          _.sortBy(Array.from(sum.entries()), ([ident, _sumItem]) => ident).forEach(
            ([ident, sumItem]) => {
              rowArray.push(
                <SumItemRow
                  amount={sumItem.amount}
                  key={`${ident}-${workTypeKey}`}
                  name={sumItem.name}
                  unit={sumItem.unit}
                />,
              );
            },
          );
        }
      });
  }

  const pdfUrl = `${globalConfig.baseURL}/download/sum_report/pdf`;
  const pdfData = noWorkTypesPDFSum
    ? {
        customerName,
        data: Object.fromEntries(noWorkTypesPDFSum.entries()) as {
          [itemNameUnit: string]: {
            amount: number;
            name: string;
            unit: string;
          };
        },
        deviceTime: Date.now(),
        fromDateString: formatDateNumeric(fromDate),
        toDateString: formatDateNumeric(toDate),
        withWorkType: false,
      }
    : {
        customerName,
        data: Object.fromEntries(
          Array.from(workTypesPDFSum.entries()).map(([key, data]) => {
            return [
              key,
              Object.fromEntries(data.entries()) as {
                [itemNameUnit: string]: {
                  amount: number;
                  name: string;
                  unit: string;
                };
              },
            ];
          }),
        ) as {
          [workTypeString: string]: {
            [itemNameUnit: string]: {
              amount: number;
              name: string;
              unit: string;
            };
          };
        },
        deviceTime: Date.now(),
        fromDateString: formatDateNumeric(fromDate),
        toDateString: formatDateNumeric(toDate),
        withWorkType: true,
      };
  return (
    <div>
      <AjaxDownloadButton
        data={pdfData}
        downloadURL={pdfUrl}
        filename="sum.pdf"
        Icon={FilePdfIcon}
        label={formatMessage(messages.downloadPDF)}
        token={token}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                defaultMessage="Produkt"
                id="customer-instance.table-header.product"
              />
            </TableCell>
            <TableCell style={countColumnStyle}>
              <FormattedMessage defaultMessage="Antal" id="customer-instance.table-header.count" />
            </TableCell>
            <TableCell style={unitColumnStyle}>
              <FormattedMessage defaultMessage="Enhed" id="customer-instance.table-header.unit" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rowArray}</TableBody>
      </Table>
    </div>
  );
});
