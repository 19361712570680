import {
  ComputedTime,
  Customer,
  Project,
  Task,
  Timer,
  UserProfile,
  WorkType,
} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import {TaskInformation} from "app-components";
import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";

export const TaskDeleteDialog = React.memo(function TaskDeleteDialog({
  breakTimer,
  computedIntervals,
  customer,
  genericPrimaryTimer,
  intervalsWithTimers,
  machineOperatorProfile,
  onCancel,
  onOk,
  open,
  project,
  secondaryTimers,
  task,
  workType,
}: {
  breakTimer?: Timer | undefined;
  computedIntervals: readonly ComputedTime[];
  customer?: Customer | undefined;
  finalStartTime?: string;
  genericPrimaryTimer?: Timer;
  intervalsWithTimers: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  machineOperatorProfile?: UserProfile | undefined;
  onCancel: () => void;
  onlyCompletedChecks?: boolean;
  onOk: () => void;
  open: boolean;
  project?: Project | undefined;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  workType?: WorkType | undefined;
}): React.JSX.Element {
  const intl = useIntl();
  return (
    <ResponsiveDialog
      autoFocusCancel
      errorColor
      okLabel={intl.formatMessage({defaultMessage: "Slet"})}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      title={intl.formatMessage({
        defaultMessage: "SLET? (Påvirker fakturering og løn!)",
      })}
    >
      <DialogContent>
        <TaskInformation
          breakTimer={breakTimer}
          computedIntervals={computedIntervals}
          customer={customer || undefined}
          finalIntervals={intervalsWithTimers}
          genericPrimaryTimer={genericPrimaryTimer}
          machineOperatorProfile={machineOperatorProfile || undefined}
          project={project || undefined}
          secondaryTimers={secondaryTimers}
          task={task}
          workType={workType}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}, _.isEqual);
