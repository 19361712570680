import {ReportingSpecification, urlToId} from "@co-common-libs/resources";
import {VerticalStackingFloatingActionButton} from "@co-frontend-libs/components";
import {actions, getReportingSpecificationArray} from "@co-frontend-libs/redux";
import {useFalseCallback, useTrueCallback} from "app-utils";
import {instanceURL} from "frontend-global-config";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {ReportingIdentifierNameDialog} from "./reporting-identifier-name-dialog";
import {ReportingSpecificationTable} from "./reporting-table";
import {SelectReportingSpecificationTemplateDialog} from "./select-template-dialog";
import {emptyLog} from "./templates";
import {ReportingSpecificationTemplate} from "./templates/types";

export const ReportingSpecificationList = React.memo(function ReportingSpecificationList() {
  const dispatch = useDispatch();
  const handleClick = useCallback(
    (reportingSpecificationURL: string): void => {
      dispatch(
        actions.go("/systemSetup/reportingSpecification/:id", {
          id: urlToId(reportingSpecificationURL),
        }),
      );
    },
    [dispatch],
  );
  const [reportingSpecificationTemplateDialogOpen, setReportingSpecificationTemplateDialogOpen] =
    useState(false);
  const [identifierNameDialogOpen, setIdentifierNameDialogOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ReportingSpecificationTemplate | null>();
  const handleTemplateSelected = useCallback((template: ReportingSpecificationTemplate | null) => {
    setSelectedTemplate(template);
    setReportingSpecificationTemplateDialogOpen(false);
    setIdentifierNameDialogOpen(true);
  }, []);
  const reportingSpecificationArray = useSelector(getReportingSpecificationArray);
  const otherReportingIdentifiers = useMemo(
    () =>
      new Set(
        reportingSpecificationArray
          .filter((spec) => spec.active !== false)
          .map((spec) => spec.identifier),
      ),
    [reportingSpecificationArray],
  );
  const reportingIdentifierNameDialogOk = useCallback(
    (identifier: string, name: string): void => {
      setIdentifierNameDialogOpen(false);
      const id = uuid();
      const url = instanceURL("reportingSpecification", id);
      const baseSpec = selectedTemplate || emptyLog;
      const instance: ReportingSpecification = {
        ...baseSpec,
        id,
        identifier,
        name,
        url,
      };
      dispatch(actions.create(instance));
      dispatch(
        actions.go("/systemSetup/reportingSpecification/:id", {
          id,
        }),
      );
    },
    [dispatch, selectedTemplate],
  );
  return (
    <div style={{minHeight: "100%", position: "relative"}}>
      <ReportingSpecificationTable onClick={handleClick} />
      <SelectReportingSpecificationTemplateDialog
        onCancel={useFalseCallback(setReportingSpecificationTemplateDialogOpen, [
          setReportingSpecificationTemplateDialogOpen,
        ])}
        onOk={handleTemplateSelected}
        open={reportingSpecificationTemplateDialogOpen}
      />
      <ReportingIdentifierNameDialog
        identifier={selectedTemplate?.identifier}
        name={selectedTemplate?.name}
        onCancel={useFalseCallback(setIdentifierNameDialogOpen, [setIdentifierNameDialogOpen])}
        onOk={reportingIdentifierNameDialogOk}
        open={identifierNameDialogOpen}
        otherReportingIdentifiers={otherReportingIdentifiers}
      />
      <VerticalStackingFloatingActionButton
        fabPosition="absolute"
        onClick={useTrueCallback(setReportingSpecificationTemplateDialogOpen, [
          setReportingSpecificationTemplateDialogOpen,
        ])}
        stackIndex={0}
      >
        <PlusIcon />
      </VerticalStackingFloatingActionButton>
    </div>
  );
});
