import {instanceURL, ReportingLocation, ReportingLogEntry, Task} from "@co-common-libs/resources";
import {notNull} from "@co-common-libs/utils";

function logEntryContainsNonZeroValue(
  entry: ReportingLogEntry,
  location: ReportingLocation,
): boolean {
  const entryValues = Object.values(entry.values || {});
  if (entryValues.some(Boolean)) {
    return true;
  }
  const locationValues = Object.values(location.values || {});
  if (locationValues.some(Boolean)) {
    return true;
  }
  const priceItemUses = Object.values(entry.priceItemUses || {});
  if (priceItemUses.some((priceItemUse) => priceItemUse.count)) {
    return true;
  }
  const productUses = Object.values(entry.productUses || {});
  if (productUses.some((productUse) => productUse.count)) {
    return true;
  }
  return false;
}

function getLogCustomerReportingLocationIdentifiers(
  apiBaseUrl: string,
  logCustomerId: string,
  task: Pick<Task, "reportingLocations" | "reportingLog">,
): ReadonlySet<string> {
  const customerUrl = instanceURL(apiBaseUrl, "customer", logCustomerId);
  return new Set(
    Object.entries(task.reportingLocations)
      .map(([identifier, location]) => (location.customer === customerUrl ? identifier : null))
      .filter(notNull),
  );
}

export function logEntryOnLogCustomerLocation(
  apiBaseUrl: string,
  logCustomerId: string,
  task: Pick<Task, "reportingLocations" | "reportingLog">,
  logEntry: ReportingLogEntry,
): boolean {
  const customerReportingLocationIdentifiers = getLogCustomerReportingLocationIdentifiers(
    apiBaseUrl,
    logCustomerId,
    task,
  );
  return (
    customerReportingLocationIdentifiers.has(logEntry.location) &&
    logEntryContainsNonZeroValue(logEntry, task.reportingLocations[logEntry.location])
  );
}

export function taskHasLogEntryOnLogCustomerLocation(
  apiBaseUrl: string,
  logCustomerId: string,
  task: Pick<Task, "reportingLocations" | "reportingLog">,
): boolean {
  return Object.values(task.reportingLog).some(
    logEntryOnLogCustomerLocation.bind(null, apiBaseUrl, logCustomerId, task),
  );
}
