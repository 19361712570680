import {ProductVariant} from "@co-common-libs/config";
import _ from "lodash";
import React from "react";

export const ProductVariantDisplay = ({
  variants,
}: {
  variants: readonly ProductVariant[];
}): React.JSX.Element => {
  return <span>{variants.map(_.capitalize).join(", ")}</span>;
};
