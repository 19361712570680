import {PriceItem, PriceItemUrl, Unit, UnitUrl} from "@co-common-libs/resources";
import {getPriceItemIdentifierString, getUnitString} from "@co-common-libs/resources-utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {EntryData, GenericMultiSelectionSearchDialog} from "../search-dialog";

const messages = defineMessages({
  searchPriceItems: {
    defaultMessage: "Søg prislinjer",
    id: "price-item-dialog.dialog-title.search-price-items",
  },
  selectPriceItems: {
    defaultMessage: "Vælg prislinjer",
    id: "price-item-dialog.dialog-title.select-price-items",
  },
});

function computeBaseChoices(
  priceItemArray: readonly PriceItem[],
  unitLookup: (url: UnitUrl) => Unit | undefined,
): readonly EntryData<PriceItemUrl>[] {
  return priceItemArray.map((priceItem) => {
    const identifier = getPriceItemIdentifierString(priceItem);
    const unit = getUnitString(priceItem, unitLookup);
    const {name, url} = priceItem;
    const entry: EntryData<PriceItemUrl> = {
      category: "standard",
      exactMatchValue: identifier,
      identifier: url,
      primaryText: name,
      searchFields: [
        {label: "ID", priority: 10, text: identifier},
        {label: "Navn", priority: 7, text: name},
        {label: "Enhed", priority: 5, text: unit},
      ],
      secondaryText: identifier && unit ? `${identifier} - ${unit}` : identifier || unit || "",
    };
    return entry;
  });
}

interface MultiplePriceItemsDialogProps {
  onCancel(): void;
  onOk(urls: ReadonlySet<PriceItemUrl>): void;
  open: boolean;
  priceItemArray: readonly PriceItem[];
  selected?: ReadonlySet<PriceItemUrl>;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export const MultiplePriceItemsDialog = React.memo(function MultiplePriceItemsDialog(
  props: MultiplePriceItemsDialogProps,
) {
  const {onCancel, onOk, open, priceItemArray, selected, unitLookup} = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.selectPriceItems);
  const searchTitle = intl.formatMessage(messages.searchPriceItems);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(priceItemArray, unitLookup);

  return (
    <GenericMultiSelectionSearchDialog<PriceItemUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
    />
  );
});
