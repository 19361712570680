import {Project, ProjectAccess} from "@co-common-libs/resources";
import {
  actions,
  diffResourceInstanceProperties,
  getExtendedCustomerSettings,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {IconButton} from "@material-ui/core";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ProjectCreateEditDialog, ProjectData} from "../create-edit-dialogs";

interface ProjectEditButtonProps {
  project: Project;
}

export const ProjectEditButton = React.memo(function ProjectEditButton(
  props: ProjectEditButtonProps,
): React.JSX.Element | null {
  const {project} = props;

  const [projectCreateEditDialogOpen, setProjectCreateEditDialogOpen] = useState(false);
  const setProjectEditDialogOpenTrue = useCallWithTrue(setProjectCreateEditDialogOpen);
  const setProjectEditDialogOpenFalse = useCallWithFalse(setProjectCreateEditDialogOpen);

  const dispatch = useDispatch();

  const {
    projects: {canOpenEditDialog},
  } = useSelector(getExtendedCustomerSettings);

  const handleProjectEditOk = useCallback(
    (data: ProjectData): void => {
      const updatedProperties = diffResourceInstanceProperties(data, project);

      if (updatedProperties) {
        dispatch(actions.update(project.url, updatedProperties));
      }

      setProjectCreateEditDialogOpen(false);
    },
    [dispatch, project],
  );

  return canOpenEditDialog ? (
    <div>
      <IconButton onClick={setProjectEditDialogOpenTrue}>
        <PencilIcon />
      </IconButton>
      <ProjectCreateEditDialog
        data={project}
        defaultAccessOnCreate={ProjectAccess.Hidden}
        onCancel={setProjectEditDialogOpenFalse}
        onOk={handleProjectEditOk}
        open={projectCreateEditDialogOpen}
      />
    </div>
  ) : null;
});
