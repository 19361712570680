import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Checkbox,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {MultiSelectableListItem} from "app-components";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

const messages = defineMessages({
  friday: {
    defaultMessage: "Fredag",
    id: "availability-dialog.label.friday",
  },
  monday: {
    defaultMessage: "Mandag",
    id: "availability-dialog.label.monday",
  },
  saturday: {
    defaultMessage: "Lørdag",
    id: "availability-dialog.label.saturday",
  },
  sunday: {
    defaultMessage: "Søndag",
    id: "availability-dialog.label.sunday",
  },
  thursday: {
    defaultMessage: "Torsdag",
    id: "availability-dialog.label.thursday",
  },
  tuesday: {
    defaultMessage: "Tirsdag",
    id: "availability-dialog.label.tuesday",
  },
  wednesday: {
    defaultMessage: "Onsdag",
    id: "availability-dialog.label.wednesday",
  },
});

export const SelectWeekdays = React.memo(function SelectWeekdays(
  props: SettingViewProps,
): React.JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const currentWeekdays: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);

  const [selected, setSelected] = useState(new Set(currentWeekdays));

  useEffect(() => {
    setSelected(new Set(currentWeekdays));
  }, [currentWeekdays]);

  const [weekdaysDialogOpen, setWeekdaysDialogOpen] = useState(false);
  const setWeekdaysDialogOpenTrue = useCallWithTrue(setWeekdaysDialogOpen);
  const setWeekdaysDialogOpenFalse = useCallWithFalse(setWeekdaysDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleDialogOk = useCallback(() => {
    setWeekdaysDialogOpen(false);

    const newValue = selected.size ? [...selected] : null;
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValue},
        ]),
      );
    }
  }, [currentUserURL, dispatch, selected, settingEntry]);

  const handleCheck = useCallback(
    (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
      const newSelected = new Set(selected);
      if (selected.has(value)) {
        newSelected.delete(value);
      } else {
        newSelected.add(value);
      }
      setSelected(newSelected);
    },
    [selected],
  );

  const {formatMessage} = useIntl();

  const weekdays = useMemo(
    () =>
      new Map<string, string>([
        ["friday", formatMessage(messages.friday)],
        ["monday", formatMessage(messages.monday)],
        ["saturday", formatMessage(messages.saturday)],
        ["sunday", formatMessage(messages.sunday)],
        ["thursday", formatMessage(messages.thursday)],
        ["tuesday", formatMessage(messages.tuesday)],
        ["wednesday", formatMessage(messages.wednesday)],
      ]),
    [formatMessage],
  );

  const listItems = useMemo(
    () =>
      [...weekdays.entries()].map(([value, name]) => {
        return (
          <MultiSelectableListItem
            isSelected={selected.has(value)}
            key={value}
            onCheck={handleCheck}
            primaryText={name}
            value={value}
          />
        );
      }),
    [handleCheck, selected, weekdays],
  );

  const handleToggleAll = useCallback(() => {
    if (selected.size === weekdays.size) {
      setSelected(new Set<string>());
    } else {
      setSelected(new Set([...weekdays.keys()]));
    }
  }, [selected.size, weekdays]);

  const selectedNames = currentWeekdays.map((day) => weekdays.get(day)).filter(Boolean) as string[];

  return (
    <>
      <DisplaySelected
        currentValue={selectedNames}
        onClick={setWeekdaysDialogOpenTrue}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
      />
      <ResponsiveDialog
        onCancel={setWeekdaysDialogOpenFalse}
        onOk={handleDialogOk}
        open={weekdaysDialogOpen}
        title={<FormattedMessage defaultMessage="Vælg dage" id="select-units-or-all.title" />}
      >
        <DialogContent dividers style={{padding: 0}}>
          <List>
            <ListItem button onClick={handleToggleAll}>
              <ListItemIcon>
                <Checkbox
                  checked={selected.size === weekdays.size}
                  disableRipple
                  edge="start"
                  indeterminate={selected.size > 0 && selected.size < weekdays.size}
                  onChange={handleToggleAll}
                  tabIndex={-1}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage defaultMessage="Vælg alle" id="field-list.label.select-all" />
                }
              />
            </ListItem>
            <Divider />
            {listItems}
          </List>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
});
