import {ProductGroup, ProductGroupUrl} from "@co-common-libs/resources";
import {identifierComparator, notNull} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  GenericTable,
  iconColumnSpecification,
  RowData,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  getCustomerSettings,
  getExtendedCustomerSettings,
  getProductGroupArray,
  getProductGroupLookup,
  getShareToken,
  getTableSortingState,
} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {useQueryParameter} from "app-utils";
import {ImportProductGroupsFab} from "feat-import-resources";
import CheckIcon from "mdi-react/CheckIcon";
import GasStationIcon from "mdi-react/GasStationIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {defineMessages, IntlShape, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ProductGroupCreateEditDialog} from "./product-groups-create-edit-dialog";

const TABLE_SORTING_IDENTIFIER = "ProductGroupTable";

const messages = defineMessages({
  active: {
    defaultMessage: "Aktiv",
  },
  fuelSurcharge: {
    defaultMessage: "Brændstoftillæg",
  },
  identifier: {
    defaultMessage: "ID",
  },
  name: {
    defaultMessage: "Navn",
  },
  photoUrl: {
    defaultMessage: "Billede",
  },
});

type ProductGroupTableFieldID = "active" | "fuelSurcharge" | "identifier" | "name" | "photoUrl";

type ProductGroupTableColumnID = "active" | "fuelSurcharge" | "identifier" | "name" | "photoUrl";

interface ProductGroupTableDataType extends RowData<ProductGroupTableFieldID, ProductGroupUrl> {
  active: boolean;
  fuelSurcharge: boolean;
  identifier: string;
  name: string;
  photoUrl: string;
}

function renderPhoto(data: ProductGroupTableDataType): React.JSX.Element | null {
  return data.photoUrl ? (
    <img alt="" src={data.photoUrl} style={{aspectRatio: "auto", maxHeight: 25, maxWidth: 25}} />
  ) : null;
}

function renderActive(data: ProductGroupTableDataType): React.JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.active ? <CheckIcon /> : <></>;
}

function renderFuelSurcharge(data: ProductGroupTableDataType): React.JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.fuelSurcharge ? <GasStationIcon /> : <></>;
}

function compareID(a: ProductGroupTableDataType, b: ProductGroupTableDataType): number {
  return identifierComparator(a.identifier, b.identifier);
}

function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onClick?: (productGroupURL: ProductGroupUrl) => void,
): ColumnSpecifications<
  ProductGroupTableFieldID,
  ProductGroupTableColumnID,
  ProductGroupUrl,
  ProductGroupTableDataType
> {
  return {
    active: iconColumnSpecification({
      field: "active",
      label: formatMessage(messages.active),
      onClick,
      render: renderActive,
    }),
    fuelSurcharge: iconColumnSpecification({
      field: "fuelSurcharge",
      label: formatMessage(messages.fuelSurcharge),
      onClick,
      render: renderFuelSurcharge,
    }),
    identifier: {
      comparator: compareID,
      field: "identifier",
      label: formatMessage(messages.identifier),
      onClick,
      width: 180,
    },
    name: {
      field: "name",
      label: formatMessage(messages.name),
      onClick,
      width: 400,
    },
    photoUrl: iconColumnSpecification({
      field: "photoUrl",
      label: formatMessage(messages.photoUrl),
      onClick,
      render: renderPhoto,
      width: 180,
    }),
  };
}

function buildRowData(
  productGroupArray: readonly ProductGroup[],
  token: string | null,
): ProductGroupTableDataType[] {
  return productGroupArray.map((productGroup) => ({
    active: productGroup.active,
    fuelSurcharge: productGroup.contributesToFuelSurchargeSalesPrice,
    identifier: productGroup.identifier,
    key: productGroup.url,
    name: productGroup.name,
    photoUrl: `${productGroup.photoUrl}?token=${token}`,
  }));
}

const defaultVisibleColumns = ["identifier", "name"] as const;
const fuelSurchargeVisibleColumns = [...defaultVisibleColumns, "fuelSurcharge"] as const;

export function ProductGroupsList({showInactive}: {showInactive: boolean}): React.JSX.Element {
  const {formatMessage} = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(getShareToken);
  const productGroupArray = useSelector(getProductGroupArray);
  const {productImageSelection} = useSelector(getCustomerSettings);

  const [productGroupDialogOpen, setProductGroupDialogOpen] = useState(false);
  const setProductGroupDialogOpenFalse = useCallWithFalse(setProductGroupDialogOpen);

  const productGroupLookup = useSelector(getProductGroupLookup);
  const [productGroup, setProductGroup] = useState<ProductGroup | undefined>();

  const handleEditProductGroup = useCallback(
    (productGroupURL: ProductGroupUrl) => {
      setProductGroup(productGroupLookup(productGroupURL));
      setProductGroupDialogOpen(true);
    },
    [productGroupLookup],
  );
  const handleCreateProductGroup = useCallback(() => {
    setProductGroup(undefined);
    setProductGroupDialogOpen(true);
  }, []);

  const {
    fuelSurcharge,
    productGroups: {canImport, canManage},
  } = useSelector(getExtendedCustomerSettings);

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(formatMessage, canManage ? handleEditProductGroup : undefined),
    [canManage, formatMessage, handleEditProductGroup],
  );

  const filteredProductGroupArray = !showInactive
    ? productGroupArray.filter((p) => p.active)
    : productGroupArray;

  const rowData = useMemo(
    () => buildRowData(filteredProductGroupArray, token),
    [filteredProductGroupArray, token],
  );

  const filterString = useQueryParameter("q", "");

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: ProductGroupTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  const visibleColumns = [
    ...(fuelSurcharge === "PRICE_PERCENT" ? fuelSurchargeVisibleColumns : defaultVisibleColumns),
    productImageSelection ? "photoUrl" : null,
    showInactive ? "active" : null,
  ].filter(notNull) as ProductGroupTableColumnID[];

  return (
    <>
      <GenericTable
        columns={columnSpecifications}
        entries={rowData}
        filterString={filterString}
        onHeaderClick={handleHeaderClick}
        sortBy={sortKey as any}
        sortDirection={sortDirection}
        visibleColumns={visibleColumns}
      />
      {canImport ? <ImportProductGroupsFab stackIndex={canManage ? 1 : 0} /> : null}
      {canManage ? (
        <VerticalStackingFloatingActionButton onClick={handleCreateProductGroup} stackIndex={0}>
          <PlusIcon />
        </VerticalStackingFloatingActionButton>
      ) : null}
      <ProductGroupCreateEditDialog
        onCancel={setProductGroupDialogOpenFalse}
        onOk={setProductGroupDialogOpenFalse}
        open={productGroupDialogOpen}
        productGroup={productGroup}
      />
    </>
  );
}
