import {dateToString, formatDate} from "@co-common-libs/utils";
import {useTheme} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import _ from "lodash";
import React from "react";
import {ORDER_CALENDAR_COLUMN_WIDTH} from "./constants";

interface DayColumnHeaderProps {
  date: Date;
}

export const DayColumnHeader = React.memo(function DayColumnHeader(
  props: DayColumnHeaderProps,
): React.JSX.Element {
  const {date} = props;

  const theme = useTheme();

  let backgroundColor: string = date.getDate() % 2 ? grey[100] : grey[200];
  const style: React.CSSProperties = {
    backgroundColor,
    display: "inline-block",
    textAlign: "center",
    width: ORDER_CALENDAR_COLUMN_WIDTH,
  };
  if (dateToString(new Date()) === dateToString(date)) {
    style.backgroundColor = backgroundColor = theme.palette.primary.main;
    style.color = theme.palette.getContrastText(backgroundColor);
  }

  return <div style={style}>{_.capitalize(formatDate(date))}</div>;
});
