import {ReadonlyConfig} from "@co-common-libs/config";
import {ExtendedCustomerSettingsPart} from "./extended-customer-settings";

type ExtendedTaskSettingsPart = ExtendedCustomerSettingsPart &
  Pick<ReadonlyConfig, "createCostLines" | "createSalesLines">;

export interface ExtendedTaskSettings {
  sendOnApprove: boolean;
}

export function extendedTaskSettings(config: ExtendedTaskSettingsPart): ExtendedTaskSettings {
  const {createCostLines, createSalesLines, unicontaSync} = config;

  return {
    sendOnApprove: unicontaSync && (createSalesLines || createCostLines),
  };
}
