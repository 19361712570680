import {Config, SettingID, settings} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions, getCurrentUserURL, getSettingEntryArray} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ErrorsBlock} from "../errors-block";
import {checkConflictsWith, checkRequiresAllOf, checkRequiresOneOf, getValidator} from "../utils";
import {SettingEntryInputField} from "./setting-entry-input-field";

const JSON_INDENTATION_LEVEL = 2;

export interface SettingDialogProps {
  nonBlankSettings: Partial<Config>;
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function SettingDialog(props: SettingDialogProps): React.JSX.Element | null {
  const {nonBlankSettings, onClose, open, settingID} = props;

  const settingEntryArray = useSelector(getSettingEntryArray);
  const settingEntry = useMemo(
    () => settingEntryArray.find((entry) => entry.key === settingID),
    [settingEntryArray, settingID],
  );

  const currentUserURL = useSelector(getCurrentUserURL);

  const savedValue = settingEntry?.data;

  const savedText = useMemo(
    () => JSON.stringify(savedValue, null, JSON_INDENTATION_LEVEL),
    [savedValue],
  );

  const [textValue, setTextValue] = useState(() => savedText);

  useEffect(() => {
    if (open) {
      setTextValue(savedText);
    }
  }, [open, savedText]);

  let jsonParseError: string | undefined;
  let editedValue: unknown;
  try {
    editedValue = JSON.parse(textValue);
  } catch (error) {
    if (error && typeof error === "object") {
      const errorObject: Partial<Record<string, unknown>> = error;
      if (
        "message" in errorObject &&
        typeof errorObject.message === "string" &&
        errorObject.message
      ) {
        jsonParseError = errorObject.message;
      } else {
        jsonParseError = "";
      }
    } else {
      jsonParseError = " ";
    }
  }

  const dispatch = useDispatch();

  const handleSave = useCallback(() => {
    if (settingID && editedValue !== undefined) {
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: editedValue},
          ]),
        );
      }
    }
    onClose();
  }, [currentUserURL, dispatch, editedValue, onClose, settingEntry, settingID]);

  const schema = settingID ? settings[settingID].schema : null;

  const validator = useMemo(() => (schema ? getValidator(schema) : null), [schema]);
  const schemaErrors = useMemo(
    () => (validator ? validator(editedValue) : null),
    [validator, editedValue],
  );

  if (!settingID) {
    return null;
  }

  const settingMetaData = settings[settingID];
  const {description} = settingMetaData;

  const conflictsWithIssue = checkConflictsWith(settingMetaData, nonBlankSettings, editedValue);
  const requiresAllOfIssue = checkRequiresAllOf(settingMetaData, nonBlankSettings, editedValue);
  const requiresOneOfIssue = checkRequiresOneOf(settingMetaData, nonBlankSettings, editedValue);

  return (
    <ResponsiveDialog
      fullWidth
      okDisabled={
        !!jsonParseError ||
        !!schemaErrors ||
        !!conflictsWithIssue ||
        !!requiresAllOfIssue ||
        !!requiresOneOfIssue
      }
      okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
      onCancel={onClose}
      onOk={handleSave}
      open={open}
      title={description}
    >
      <DialogContent>
        <SettingEntryInputField
          onValueTextChange={setTextValue}
          schema={settingMetaData.schema}
          valueText={textValue}
        />
        <ErrorsBlock
          nonBlankSettings={nonBlankSettings}
          settingMetaData={settingMetaData}
          value={editedValue}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
