import {Config} from "@co-common-libs/config";
import {frontendVersion} from "@co-common-libs/frontend-version";
import {Button, Menu, MenuItem} from "@material-ui/core";
import bowser from "bowser";
import MenuDownIcon from "mdi-react/MenuDownIcon";
import React, {useCallback, useMemo, useState} from "react";
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
  account: {
    defaultMessage: "Konto",
    id: "menu.account",
  },
  checkForUpdates: {
    defaultMessage: "Tjek for opdateringer",
    id: "menu.label.check-for-updates",
  },
  clearApp: {
    defaultMessage: "Nulstil app",
    id: "menu.label.clear-app",
  },
  clearSite: {
    defaultMessage: "Nulstil CustomOffice",
    id: "menu.label.clear-site",
  },
  consultantTools: {
    defaultMessage: "Konsulent værktøjer",
    id: "menu.label.consultant-mirror",
  },
  deviceSetup: {
    defaultMessage: "Opsæt enhed",
    id: "menu.label.device-setup",
  },
  fetchPunchInOut: {
    defaultMessage: "Hent ind/udstempling",
    id: "menu.label.fetch-punch-in-out",
  },
  forceReload: {
    defaultMessage: "Genhent offline data",
  },
  fullscreenLinks: {
    defaultMessage: "Fullscreen links",
    id: "menu.label.fullscreen-links",
  },
  logOut: {
    defaultMessage: "Log ud",
  },
  systemSetup: {
    defaultMessage: "Systemopsætning",
    id: "menu.label.setup",
  },
  userProfile: {
    defaultMessage: "Brugerprofil",
    id: "menu.label.user-profile",
  },
});

interface UserDropdownMenuItemProps {
  identifier: string;
  onClick: (event: React.MouseEvent<HTMLLIElement>, identifier: string) => void;
  text: string;
}

const UserDropdownMenuItem = React.forwardRef(function UserDropdownMenuItem(
  props: UserDropdownMenuItemProps,
  ref: React.Ref<HTMLLIElement>,
): React.JSX.Element {
  const {identifier, onClick, text} = props;
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      onClick(event, identifier);
    },
    [identifier, onClick],
  );
  return (
    <MenuItem onClick={handleClick} ref={ref}>
      {text}
    </MenuItem>
  );
});

interface UserDropdownMenuProps {
  consultant: boolean;
  customerSettings: Config;
  initials: string;
  manager: boolean;
  onItemClick: (event: React.MouseEvent<HTMLLIElement>, identifier: string) => void;
  owner: boolean;
}

export function UserDropdownMenu(props: UserDropdownMenuProps): React.JSX.Element {
  const {consultant, customerSettings, initials, manager, onItemClick, owner} = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleItemClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement>, identifier: string): void => {
      handleClose();
      onItemClick(event, identifier);
    },
    [handleClose, onItemClick],
  );
  const intl = useIntl();

  const {formatMessage} = intl;

  const menuItems = useMemo(() => {
    const entryData: {key: string; text: string}[] = [];
    entryData.push({
      key: "userProfile",
      text: formatMessage(messages.userProfile),
    });
    if (owner || consultant) {
      entryData.push({
        key: "account",
        text: formatMessage(messages.account),
      });
    }
    entryData.push({
      key: "checkForUpdates",
      text: formatMessage(messages.checkForUpdates),
    });
    if (manager && !window.cordova) {
      entryData.push({
        key: "fullscreenLinks",
        text: formatMessage(messages.fullscreenLinks),
      });
    }
    if (!bowser.mobile && !bowser.tablet) {
      entryData.push({
        key: "teamviewer",
        text: intl.formatMessage({defaultMessage: "TeamViewer"}),
      });
    }
    entryData.push({
      key: "sync",
      text: intl.formatMessage({defaultMessage: "Synkronisering"}),
    });
    entryData.push({
      key: "privacyPolicy",
      text: intl.formatMessage({defaultMessage: "Privatlivspolitik"}),
    });
    if (manager) {
      entryData.push({
        key: "deviceSetup",
        text: formatMessage(messages.deviceSetup),
      });
    }
    if (consultant) {
      entryData.push({
        key: "systemSetup",
        text: formatMessage(messages.systemSetup),
      });
      entryData.push({
        key: "consultantTools",
        text: formatMessage(messages.consultantTools),
      });
      entryData.push({
        key: "forceReload",
        text: formatMessage(messages.forceReload),
      });
    }

    if (window.cordova) {
      // Some older version used a DB library without a "delete" method;
      // that's not worth the effort to support...
      const APP_VERSION_ON_CLEAR_LOGIC_INTRODUCTION = "1.3.4";
      if ((window as any).APP_VERSION >= APP_VERSION_ON_CLEAR_LOGIC_INTRODUCTION) {
        entryData.push({
          key: "clearApp",
          text: formatMessage(messages.clearApp),
        });
      }
    } else {
      entryData.push({
        key: "clearApp",
        text: formatMessage(messages.clearSite),
      });
    }

    if (customerSettings.usePunchInOut) {
      entryData.push({
        key: "fetchPunchInOut",
        text: formatMessage(messages.fetchPunchInOut),
      });
    }

    entryData.push({
      key: "logout",
      text: formatMessage(messages.logOut),
    });
    return entryData.map(({key, text}) => (
      <UserDropdownMenuItem identifier={key} key={key} onClick={handleItemClick} text={text} />
    ));
  }, [
    consultant,
    customerSettings.usePunchInOut,
    formatMessage,
    handleItemClick,
    intl,
    manager,
    owner,
  ]);

  return (
    <>
      <Button
        endIcon={<MenuDownIcon />}
        onClick={handleClick}
        style={{height: "100%", justifyContent: "start", width: "100%"}}
      >
        <span style={{paddingLeft: 64}}>{initials}</span>
      </Button>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={!!anchorEl}>
        {menuItems}
        <span style={{fontSize: 12, opacity: 0.5, paddingLeft: 16}}>v. {frontendVersion}</span>
      </Menu>
    </>
  );
}
