import {ProductGroup, ProductGroupUrl} from "@co-common-libs/resources";
import {Button} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";

const messages = defineMessages({
  allGroups: {
    defaultMessage: "Alle",
    id: "product-group-tree-dialog.label.all-groups",
  },
});

interface BreadCrumbEntryProps {
  label: string;
  onClick: (event: React.MouseEvent, value: ProductGroupUrl | null) => void;
  value?: ProductGroupUrl;
}

class BreadCrumbEntry extends PureComponent<BreadCrumbEntryProps> {
  @bind
  handleClick(event: React.MouseEvent): void {
    const {onClick, value} = this.props;
    onClick(event, value || null);
  }
  render(): React.JSX.Element {
    const {label} = this.props;
    return (
      <Button onClick={this.handleClick} style={{marginRight: 8}} variant="text">
        {label}
      </Button>
    );
  }
}

interface BreadCrumbsProps {
  onClick: (event: React.MouseEvent, url: ProductGroupUrl | null) => void;
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
  selectedGroup?: ProductGroupUrl | undefined;
}

export class BreadCrumbs extends PureComponent<BreadCrumbsProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleBreadCrumbClick(event: React.MouseEvent, url: ProductGroupUrl | null): void {
    this.props.onClick(event, url);
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {productGroupLookup, selectedGroup} = this.props;
    const groups: ProductGroup[] = [];
    if (selectedGroup) {
      let groupURL: ProductGroupUrl | null = selectedGroup;
      while (groupURL) {
        const group = productGroupLookup(groupURL);
        if (group) {
          groups.push(group);
          groupURL = group.parent;
        } else {
          groupURL = null;
        }
      }
      groups.reverse();
    }
    const groupElements = groups.map((productGroup) => {
      const {name, url} = productGroup;
      return (
        <BreadCrumbEntry key={url} label={name} onClick={this.handleBreadCrumbClick} value={url} />
      );
    });
    return (
      <div style={{marginBottom: 8}}>
        <BreadCrumbEntry
          label={formatMessage(messages.allGroups)}
          onClick={this.handleBreadCrumbClick}
        />
        {groupElements}
      </div>
    );
  }
}
