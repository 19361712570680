import {setReducer} from "@co-frontend-libs/utils";
import React, {useCallback, useEffect, useReducer} from "react";
import {typedMemo} from "../../types";
import {
  BaseMultiSelectionDialog,
  BaseMultiSelectionDialogProps,
} from "./base-multi-selection-dialog";

function buildSelectedSet<Identifier>(
  selectedFromProps?: ReadonlySet<Identifier>,
): ReadonlySet<Identifier> {
  return new Set(selectedFromProps);
}

export interface MultiSelectionDialogProps<Identifier extends string>
  extends Omit<
    BaseMultiSelectionDialogProps<Identifier>,
    "onOk" | "onSelect" | "onSelectMultiple" | "onToggleSelected" | "selected"
  > {
  onOk: (identifiers: ReadonlySet<Identifier>) => void;
  selected?: ReadonlySet<Identifier> | undefined;
}

export const MultiSelectionDialog = typedMemo(function MultiSelectionDialog<
  Identifier extends string,
>(props: MultiSelectionDialogProps<Identifier>): React.JSX.Element {
  const {onOk, open, selected: selectedFromProps, ...other} = props;

  const [selected, dispatchSelect] = useReducer(
    setReducer<Identifier>,
    selectedFromProps,
    buildSelectedSet,
  );

  useEffect((): void => {
    if (open) {
      dispatchSelect({type: "replace", values: selectedFromProps});
    }
  }, [selectedFromProps, open]);

  const handleSelect = useCallback((identifier: Identifier, isSelected: boolean): void => {
    if (isSelected) {
      dispatchSelect({type: "addOne", value: identifier});
    } else {
      dispatchSelect({type: "removeOne", value: identifier});
    }
  }, []);

  const handleSelectMultiple = useCallback(
    (identifiers: ReadonlySet<Identifier>, isSelected: boolean): void => {
      if (isSelected) {
        dispatchSelect({type: "addMultiple", values: identifiers});
      } else {
        dispatchSelect({type: "removeMultiple", values: identifiers});
      }
    },
    [],
  );

  const handleToggleSelected = useCallback((identifier: Identifier): void => {
    dispatchSelect({type: "toggle", value: identifier});
  }, []);

  const handleOk = useCallback((): void => {
    onOk(selected);
  }, [onOk, selected]);

  return (
    <BaseMultiSelectionDialog
      onOk={handleOk}
      onSelect={handleSelect}
      onSelectMultiple={handleSelectMultiple}
      onToggleSelected={handleToggleSelected}
      open={open}
      selected={selected}
      {...other}
    />
  );
});
