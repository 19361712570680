import {schema, SettingID} from "@co-common-libs/config";
import React from "react";
import {UserTypeDeviceListColumnsDialog} from "./usertype-device-columns-dialog";

interface UserListColumnsDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function UserListColumnsDialog(props: UserListColumnsDialogProps): React.JSX.Element {
  const columnChoices = schema.definitions.userListColumnsColumnChoices.items.enum;
  const managerColumnChoices = schema.definitions.userListColumnsManagerColumnChoices.items.enum;
  type ColumnChoice = (typeof columnChoices)[number];
  type AdminColumnChoice = (typeof managerColumnChoices)[number];

  return (
    <UserTypeDeviceListColumnsDialog<AdminColumnChoice | ColumnChoice>
      {...props}
      columnChoices={columnChoices}
      managerColumnChoices={managerColumnChoices}
    />
  );
}
