import {ReportSetup, ReportSetupUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {EntryData, GenericSingleSelectionSearchDialog} from "../search-dialog";

const messages = defineMessages({
  searchReportSetup: {
    defaultMessage: "Søg rapportopsætning",
    id: "report-setup-dialog.dialog-title.search-report-setup",
  },
  selectReportSetup: {
    defaultMessage: "Åbn rapportopsætning",
    id: "report-setup-dialog.dialog-title.select-report-setup",
  },
});

function computeBaseChoices(
  reportSetupArray: readonly ReportSetup[],
): readonly EntryData<ReportSetupUrl>[] {
  const result: EntryData<ReportSetupUrl>[] = reportSetupArray.map((instance) => {
    const {name, url} = instance;
    const entry: EntryData<ReportSetupUrl> = {
      category: "standard",
      exactMatchValue: name,
      identifier: url,
      primaryText: name,
      searchFields: [{label: "Navn", priority: 10, text: name}],
    };
    return entry;
  });
  return result;
}

interface ReportSetupDialogProps {
  onCancel(): void;
  onOk(url: ReportSetupUrl): void;
  open: boolean;
  reportSetupArray: readonly ReportSetup[];
}

export const ReportSetupDialog = React.memo(function ReportSetupDialog(
  props: ReportSetupDialogProps,
) {
  const {onCancel, onOk, open, reportSetupArray} = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.selectReportSetup);
  const searchTitle = intl.formatMessage(messages.searchReportSetup);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(reportSetupArray);

  return (
    <GenericSingleSelectionSearchDialog<ReportSetupUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      title={title}
    />
  );
});
