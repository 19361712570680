import {Config} from "@co-common-libs/config";
import {
  CachedDailyAccumulatedCompensatory,
  ResourceTypeUnion,
  Role,
  urlToId,
  User,
} from "@co-common-libs/resources";
import {TranslatedError} from "@co-common-libs/utils";
import {
  AppbarSearchField,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  AppState,
  getCachedDailyAccumulatedCompensatoryArray,
  getCurrentRole,
  getCustomerSettings,
  getUserArray,
  makeQueryParameterGetter,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {jsonFetch, translateNetworkError} from "@co-frontend-libs/utils";
import {Tab, Tabs} from "@material-ui/core";
import {
  MenuToolbar,
  PageLayout,
  UserCreateData,
  UserCreateDialog,
  UserProfileData,
} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {UserTable} from "./user-table";

const messages = defineMessages({
  activeTab: {
    defaultMessage: "Aktive",
    id: "user-list.label.active",
  },
  inactiveTab: {
    defaultMessage: "Inaktive",
    id: "user-list.label.inactiveTab",
  },
  title: {
    defaultMessage: "Medarbejdere",
    id: "user-list.title.user-list",
  },
});

interface UserListStateProps {
  cachedDailyAccumulatedCompensatoryArray: readonly CachedDailyAccumulatedCompensatory[];
  currentRole: Role | null;
  customerSettings: Config;
  q: string;
  tab: string;
  userArray: readonly User[];
}

interface UserListDispatchProps {
  addToOffline: (instances: ResourceTypeUnion[]) => void;
  create: (instance: ResourceTypeUnion) => void;
  deleteQueryKey: (key: string, navigationKind?: PartialNavigationKind) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
}

interface UserListOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type UserListProps = UserListDispatchProps & UserListOwnProps & UserListStateProps;

interface UserListState {
  userCreateDialogOpen: boolean;
}

class UserList extends PureComponent<UserListProps, UserListState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: UserListState = {
    userCreateDialogOpen: false,
  };
  @bind
  handleCreateUserDialogCancel(): void {
    this.setState({
      userCreateDialogOpen: false,
    });
  }
  @bind
  async handleCreateUserDialogOk(
    userData: UserCreateData,
    userProfileData: UserProfileData,
  ): Promise<void> {
    const {currentRole} = this.props;
    const userIsManager = !!(currentRole && currentRole.manager);
    if (!userIsManager) {
      this.setState({
        userCreateDialogOpen: false,
      });
      return;
    }

    const {baseURL} = globalConfig.resources;

    try {
      // @co-apps/api-server/src/api/custom-views/user.ts
      const response = await jsonFetch(`${baseURL}create-user/`, "POST", {
        profile: userProfileData,
        user: userData,
      });
      const {profile, role, user} = response.data;
      this.props.addToOffline([user, profile, role]);
      this.setState({
        userCreateDialogOpen: false,
      });
      this.props.go("/userProfile/:id", {id: user.id});
    } catch (error) {
      const intl = this.context;
      throw new TranslatedError(translateNetworkError(error, intl));
    }
  }
  @bind
  handleFabButton(): void {
    this.setState({userCreateDialogOpen: true});
  }
  @bind
  handleFilterStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    this.props.putQueryKey("q", value || "");
  }

  @bind
  handleRowClick(userURL: string): void {
    const id = urlToId(userURL);
    this.props.go("/userProfile/:id", {id});
  }

  @bind
  handleTabChange(_event: React.ChangeEvent<unknown>, value: string): void {
    this.props.putQueryKey("tab", value || "");
  }

  render(): React.JSX.Element | null {
    const {formatMessage} = this.context;
    const {currentRole, customerSettings} = this.props;
    if (!currentRole) {
      return null;
    }
    const userIsManager = !!currentRole && currentRole.manager;

    const activeTab = this.props.tab;

    const accumulatedCompensatoryInUse = Object.values(customerSettings.remunerationGroups).some(
      (override) => override.accumulateCompensatoryLimit,
    );

    const userCachedDailyAccumulatedCompensatory = new Map<
      string,
      CachedDailyAccumulatedCompensatory
    >();
    if (accumulatedCompensatoryInUse) {
      this.props.cachedDailyAccumulatedCompensatoryArray.forEach(
        (cachedDailyAccumulatedCompensatory) => {
          userCachedDailyAccumulatedCompensatory.set(
            cachedDailyAccumulatedCompensatory.employee,
            cachedDailyAccumulatedCompensatory,
          );
        },
      );
    }

    const right = (
      <AppbarSearchField onChange={this.handleFilterStringChange} value={this.props.q} />
    );
    let floatingActionButton;
    if (userIsManager) {
      floatingActionButton = (
        <VerticalStackingFloatingActionButton onClick={this.handleFabButton} stackIndex={0}>
          <PlusIcon />
        </VerticalStackingFloatingActionButton>
      );
    }
    const dialogs = [
      <UserCreateDialog
        key="user-create-edit-dialog"
        onCancel={this.handleCreateUserDialogCancel}
        onOk={this.handleCreateUserDialogOk}
        open={this.state.userCreateDialogOpen}
      />,
    ];
    return (
      <PageLayout
        dialogs={dialogs}
        floatingActionButton={floatingActionButton}
        tabs={
          userIsManager ? (
            <Tabs
              onChange={this.handleTabChange}
              value={activeTab}
              variant={bowser.mobile ? "fullWidth" : "standard"}
            >
              <Tab label={formatMessage(messages.activeTab)} value="active" />
              <Tab label={formatMessage(messages.inactiveTab)} value="inactive" />
            </Tabs>
          ) : undefined
        }
        toolbar={
          <MenuToolbar
            onMenuButton={this.props.onMenuButton}
            rightElement={right}
            title={formatMessage(messages.title)}
          />
        }
        withBottomScrollPadding
      >
        <UserTable
          active={activeTab === "active"}
          filterString={this.props.q}
          onClick={this.handleRowClick}
        />
      </PageLayout>
    );
  }
}

const ConnectedUserList: React.ComponentType<UserListOwnProps> = connect<
  UserListStateProps,
  UserListDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, UserListStateProps>({
    cachedDailyAccumulatedCompensatoryArray: getCachedDailyAccumulatedCompensatoryArray,
    currentRole: getCurrentRole,
    customerSettings: getCustomerSettings,
    q: makeQueryParameterGetter("q", ""),
    tab: makeQueryParameterGetter("tab", "active"),
    userArray: getUserArray,
  }),
  {
    addToOffline: actions.addToOffline,
    create: actions.create,
    deleteQueryKey: actions.deleteQueryKey,
    go: actions.go,
    putQueryKey: actions.putQueryKey,
  },
)(UserList);

export default ConnectedUserList;
