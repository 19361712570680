import {Typography} from "@material-ui/core";
import React from "react";

interface DialogContentHeaderProps {
  children: React.ReactNode;
}

export const DialogContentHeader = React.memo(function DialogContentHeader(
  props: DialogContentHeaderProps,
) {
  return (
    <Typography color="textPrimary" gutterBottom style={{marginTop: 8}} variant="h6">
      {props.children}
    </Typography>
  );
});
