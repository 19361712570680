import _ from "lodash";
import {HolidayCalendarLabel, holidaysForYear} from "./holidays";

export enum DayTypeHoliday {
  HALF_HOLIDAY = 2,
  HOLIDAY = 1,

  NORMAL = 0,

  VALID_ABSENCE = 3,
}

export const DAY_NORMAL = DayTypeHoliday.NORMAL;
export const DAY_HOLIDAY = DayTypeHoliday.HOLIDAY;
export const DAY_HALF_HOLIDAY = DayTypeHoliday.HALF_HOLIDAY;

export const HALF_SECOND_MILLISECONDS = 500;
export const SECOND_MILLISECONDS = 1000;
export const MINUTE_SECONDS = 60;
export const HALF_MINUTE_SECONDS = 30;
export const HALF_HOUR_MINUTES = 30;
export const QUARTER_MINUTE_SECONDS = 15;
export const MINUTE_MILLISECONDS = MINUTE_SECONDS * SECOND_MILLISECONDS;
export const HALF_MINUTE_MILLISECONDS = HALF_MINUTE_SECONDS * SECOND_MILLISECONDS;
export const HOUR_MINUTES = 60;
export const HOUR_SECONDS = HOUR_MINUTES * MINUTE_SECONDS;
export const HOUR_MILLISECONDS = HOUR_MINUTES * MINUTE_MILLISECONDS;
export const QUARTERS_PER_HOUR = 4;
export const QUARTER_HOUR_MINUTES = HOUR_MINUTES / QUARTERS_PER_HOUR;
export const DAY_HOURS = 24;
export const DAY_MINUTES = DAY_HOURS * HOUR_MINUTES;
export const DAY_SECONDS = DAY_HOURS * HOUR_SECONDS;
export const DAY_MILLISECONDS = DAY_HOURS * HOUR_MILLISECONDS;
export const WEEK_DAYS = 7;
export const WEEK_MILLISECONDS = WEEK_DAYS * DAY_MILLISECONDS;
export const YEAR_MONTHS = 12;

export function dateFromString(): null;
export function dateFromString(str: undefined): null;
export function dateFromString(str: null): null;
export function dateFromString(str: string): Date | null;
export function dateFromString(str?: string | null | undefined): Date | null {
  if (!str || typeof str !== "string") {
    return null;
  }
  const [year, month, day] = str.split("-");
  const NOON = 12;
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), NOON, 0);
}

export function utcDateFromString(str: string): Date {
  const [year, month, day] = str.split("-");
  const NOON = 12;
  const date = new Date(0);
  date.setUTCFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
  date.setUTCHours(NOON);
  return date;
}

interface DateLike {
  getDate(): number;
  getFullYear(): number;
  getMonth(): number;
}

function formatDateIsoString(year: number, month: number, day: number): string {
  const YEAR_DIGITS = 4;
  const MONTH_DIGITS = 2;
  const DAY_DIGITS = 2;
  const yearString = `${year}`.padStart(YEAR_DIGITS, "0");
  const monthString = `${month}`.padStart(MONTH_DIGITS, "0");
  const dayString = `${day}`.padStart(DAY_DIGITS, "0");
  return `${yearString}-${monthString}-${dayString}`;
}

export const dateToString = (date?: Date | DateLike | null): string => {
  if (!date) {
    return "";
  }
  return formatDateIsoString(date.getFullYear(), date.getMonth() + 1, date.getDate());
};

export const utcDateToString = (date: Date): string => {
  return formatDateIsoString(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
};

export const dateAndTimeToTimestamp = (dateString: string, timeString: string): string => {
  const date = dateFromString(dateString) as Date;
  const [hours, minutes] = timeString.split(":");
  date.setHours(parseInt(hours), parseInt(minutes));
  return date.toISOString();
};

export const dateStringDifference = (a: string, b: string): number => {
  const aa = dateFromString(a);
  const bb = dateFromString(b);
  if (!aa || !bb) {
    return NaN;
  }
  const msDifference = aa.valueOf() - bb.valueOf();
  // Round in case DST-change means there's a 23- or 25-hour day included
  // and the JS interpreter is DST-aware.
  return Math.round(msDifference / DAY_MILLISECONDS);
};

export const getDateStringSequence = (first: string, last: string): string[] => {
  const result: string[] = [];
  if (last < first) {
    return result;
  }
  let current = first;
  const currentDate = dateFromString(current);
  if (!currentDate) {
    return result;
  }
  while (current < last) {
    result.push(current);
    currentDate.setDate(currentDate.getDate() + 1);
    current = dateToString(currentDate);
  }
  result.push(last);
  return result;
};

const memoizedHolidaysForYearString = _.memoize(
  (selectedCalendars: readonly HolidayCalendarLabel[], year: string) =>
    holidaysForYear(selectedCalendars, parseInt(year)),
  (selectedCalendars, year) => [...selectedCalendars, year].join("\0"),
);

export function getHoliday(
  selectedCalendars: readonly HolidayCalendarLabel[],
  dateString: string,
): readonly string[] | undefined {
  const yearString = dateString.split("-")[0];
  const holidays = memoizedHolidaysForYearString(selectedCalendars, yearString);
  return holidays.get(dateString);
}

function simpleCheckHoliday(
  selectedCalendars: readonly HolidayCalendarLabel[],
  dateString: string,
): DayTypeHoliday {
  if (getHoliday(selectedCalendars, dateString)) {
    return DayTypeHoliday.HOLIDAY;
  } else {
    return DayTypeHoliday.NORMAL;
  }
}

const DATE_STRING_LENGTH = "YYYY-MM-DD".length;
const YEAR_AND_SEPARATOR_LENGTH = "YYYY-".length;

function extendedCheckHoliday(
  selectedCalendars: readonly HolidayCalendarLabel[],
  dateString: string,
  extraHolidays: ReadonlyMap<string, string>,
  extraHalfHolidays: ReadonlyMap<string, string>,
): DayTypeHoliday {
  if (getHoliday(selectedCalendars, dateString)) {
    return DayTypeHoliday.HOLIDAY;
  }
  console.assert(dateString.length === DATE_STRING_LENGTH);
  const monthDayString = dateString.substring(YEAR_AND_SEPARATOR_LENGTH);
  if (extraHolidays.get(dateString) || extraHolidays.get(monthDayString)) {
    return DayTypeHoliday.HOLIDAY;
  } else if (extraHalfHolidays.get(dateString) || extraHalfHolidays.get(monthDayString)) {
    return DayTypeHoliday.HALF_HOLIDAY;
  } else {
    return DayTypeHoliday.NORMAL;
  }
}

export function getCheckHolidayFunction(
  extraHolidays: ReadonlyMap<string, string>,
  extraHalfHolidays: ReadonlyMap<string, string>,
): (selectedCalendars: readonly HolidayCalendarLabel[], dateString: string) => DayTypeHoliday {
  if (extraHolidays.size === 0 && extraHalfHolidays.size === 0) {
    return simpleCheckHoliday;
  } else {
    const boundCheckHoliday = (
      selectedCalendars: readonly HolidayCalendarLabel[],
      dateString: string,
    ): DayTypeHoliday =>
      extendedCheckHoliday(selectedCalendars, dateString, extraHolidays, extraHalfHolidays);
    return boundCheckHoliday;
  }
}

export const WEEKDAY_SUNDAY = 0;
export const WEEKDAY_MONDAY = 1;
export const WEEKDAY_TUESDAY = 2;
export const WEEKDAY_WEDNESDAY = 3;
export const WEEKDAY_THURSDAY = 4;
export const WEEKDAY_FRIDAY = 5;
export const WEEKDAY_SATURDAY = 6;

export type WeekdayNumberType =
  | typeof WEEKDAY_FRIDAY
  | typeof WEEKDAY_MONDAY
  | typeof WEEKDAY_SATURDAY
  | typeof WEEKDAY_SUNDAY
  | typeof WEEKDAY_THURSDAY
  | typeof WEEKDAY_TUESDAY
  | typeof WEEKDAY_WEDNESDAY;

export const ISO_WEEKDAY_SUNDAY = 7;

export type ISOWeekdayNumberType =
  | typeof ISO_WEEKDAY_SUNDAY
  | typeof WEEKDAY_FRIDAY
  | typeof WEEKDAY_MONDAY
  | typeof WEEKDAY_SATURDAY
  | typeof WEEKDAY_THURSDAY
  | typeof WEEKDAY_TUESDAY
  | typeof WEEKDAY_WEDNESDAY;

export const getHolidaySundayWeekday = (
  selectedCalendars: readonly HolidayCalendarLabel[],
  dateString: string,
  getUserHoliday?: (date: string) => string | undefined,
): number => {
  if (getHoliday(selectedCalendars, dateString) || (getUserHoliday && getUserHoliday(dateString))) {
    // treat like sunday
    return WEEKDAY_SUNDAY;
  } else {
    const date = dateFromString(dateString) as Date;
    return date.getDay();
  }
};

// Does *not* take DST-switch into account;
// works as if hour N is always N hours past midnight
export const hourMinutesStringDaySeconds = (timeString: string): number => {
  const [hoursString, minutesString] = timeString.split(":");
  const hours = parseInt(hoursString);
  const minutes = parseInt(minutesString);
  return hours * HOUR_SECONDS + minutes * MINUTE_SECONDS;
};

// Does *not* take DST-switch into account;
// works as if hour N is always N hours past midnight
export const daySeconds = (date: Date): number => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();
  return (
    hours * HOUR_SECONDS + minutes * MINUTE_SECONDS + seconds + milliseconds / SECOND_MILLISECONDS
  );
};

export const getStartOfDate = (date: string): string => {
  const dateObj = dateFromString(date) as Date;
  dateObj.setHours(0, 0, 0, 0);
  return dateObj.toISOString();
};

export function midnightFromDateString(dateString: string): Date {
  const [year, month, day] = dateString.split("-");
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

export const getEndOfDate = (date: string): string => {
  const dateObj = dateFromString(date) as Date;
  dateObj.setDate(dateObj.getDate() + 1);
  dateObj.setHours(0, 0, 0, 0);
  return dateObj.toISOString();
};

const YYYY_MM_LENGTH = "YYYY-MM".length;

export const monthDates = (monthString: string): {fromDate: string; toDate: string} => {
  console.assert(
    !!monthString && monthString.length === YYYY_MM_LENGTH,
    `Wrong length for 'YYYY-MM'-string: ${monthString}`,
  );
  const fromDate = `${monthString}-01`;
  const date = dateFromString(fromDate) as Date;
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  const toDate = dateToString(date);
  return {fromDate, toDate};
};

// http://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php/6117889#6117889
export const weekNumber = function (date: Date): {week: number; year: number} {
  // Copy date so don't modify original
  const weekDate = new Date(date);
  weekDate.setHours(0, 0, 0, 0);
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  const isoDayNumber = weekDate.getDay() || ISO_WEEKDAY_SUNDAY;
  weekDate.setDate(weekDate.getDate() + WEEKDAY_THURSDAY - isoDayNumber);
  // Get first day of year
  const yearStart = new Date(weekDate.getFullYear(), 0, 1);
  // Calculate full weeks to nearest Thursday
  const week = Math.ceil(
    ((weekDate.valueOf() - yearStart.valueOf()) / DAY_MILLISECONDS + 1) / WEEK_DAYS,
  );

  // Return year and week number
  return {week, year: weekDate.getFullYear()};
};

export const YEAR_MAX_WEEKS = 53;

export const weekStart = (forWeekNumber: number, forYear: number): Date => {
  console.assert(forWeekNumber >= 1);
  console.assert(forWeekNumber <= YEAR_MAX_WEEKS);
  const simple = new Date(forYear, 0, 1 + (forWeekNumber - 1) * WEEK_DAYS);
  const dow = simple.getDay();
  const weekStartDate = simple;
  if (dow <= WEEKDAY_THURSDAY) {
    weekStartDate.setDate(simple.getDate() - dow + 1);
  } else {
    weekStartDate.setDate(simple.getDate() - dow + 1 + WEEK_DAYS);
  }
  return weekStartDate;
};

export const weekDates = (
  forWeekNumber: number,
  forYear: number,
): {fromDate: string; toDate: string} => {
  const date = weekStart(forWeekNumber, forYear);
  const fromDate = dateToString(date);
  date.setDate(date.getDate() + WEEK_DAYS - 1);
  const toDate = dateToString(date);
  return {fromDate, toDate};
};

export function firstDate(a: Date, b: Date): Date {
  return a < b ? a : b;
}

export function lastDate(a: Date, b: Date): Date {
  return a > b ? a : b;
}

export const FOUR_WEEK_WEEKS = 4;
export const FOUR_WEEK_DAYS = WEEK_DAYS * FOUR_WEEK_WEEKS;
export const TWO_WEEK_WEEKS = 2;
export const TWO_WEEK_DAYS = WEEK_DAYS * TWO_WEEK_WEEKS;

export const dateToTimeString = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const hoursString = `${hours < 10 ? "0" : ""}${hours}`;

  const minutesString = `${minutes < 10 ? "0" : ""}${minutes}`;
  return `${hoursString}:${minutesString}`;
};

export const utcDateToTimeString = (date: Date): string => {
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  const hoursString = `${hours < 10 ? "0" : ""}${hours}`;

  const minutesString = `${minutes < 10 ? "0" : ""}${minutes}`;

  const secondsString = `${seconds < 10 ? "0" : ""}${seconds}`;
  return `${hoursString}:${minutesString}:${secondsString}`;
};

export const dateFromTimeString = (str: string): Date => {
  const [hours, minutes] = str.split(":");
  return new Date(0, 0, 0, parseInt(hours), parseInt(minutes));
};

export const utcDateFromTimeString = (str: string): Date => {
  const [hours, minutes] = str.split(":");
  const date = new Date(0);
  date.setUTCHours(parseInt(hours), parseInt(minutes));
  return date;
};

export function startOfDate(dateString: string): Date {
  const [yearString, monthString, dayString] = dateString.split("-");
  return new Date(parseInt(yearString), parseInt(monthString) - 1, parseInt(dayString));
}

export function endOfDate(dateString: string): Date {
  const date = startOfDate(dateString);
  date.setDate(date.getDate() + 1);
  return date;
}

export function getMinuteString(date: Date): string {
  const minutesDate = new Date(date);
  minutesDate.setUTCSeconds(0, 0);
  return minutesDate.toISOString();
}

export function isWeekend(dateString: string): boolean {
  const weekday = (dateFromString(dateString) as Date).getDay();
  return weekday === WEEKDAY_SATURDAY || weekday === WEEKDAY_SUNDAY;
}
