import {Config} from "@co-common-libs/config";
import {Task} from "@co-common-libs/resources";
import React from "react";
import {FormattedMessage} from "react-intl";

export const getOtherTaskCount = (task: Task, taskArray: readonly Task[]): number => {
  if (!task.date || !task.machineOperator) {
    return 0;
  }
  return taskArray.filter(
    (t) => t.url !== task.url && t.machineOperator === task.machineOperator && t.date === task.date,
  ).length;
};

export const TaskCountMessage = ({
  customerSettings,
  machineOperatorOtherTaskCount,
}: {
  customerSettings: Config;
  machineOperatorOtherTaskCount: number;
}): React.JSX.Element => {
  return (
    <div>
      {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
        <FormattedMessage
          defaultMessage="Den valgte maskinfører har {taskCount} andre opgave(r) på den valgte dato"
          id="other-tasks.machine-operator-absent"
          tagName="h4"
          values={{taskCount: machineOperatorOtherTaskCount}}
        />
      ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
        <FormattedMessage
          defaultMessage="Den valgte medarbejder har {taskCount} andre opgave(r) på den valgte dato"
          id="other-tasks.employee-absent"
          tagName="h4"
          values={{taskCount: machineOperatorOtherTaskCount}}
        />
      ) : (
        <FormattedMessage
          defaultMessage="Den valgte chauffør har {taskCount} andre opgave(r) på den valgte dato"
          id="other-tasks.chauffeur-absent"
          tagName="h4"
          values={{taskCount: machineOperatorOtherTaskCount}}
        />
      )}
    </div>
  );
};
