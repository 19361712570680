import {SettingsGroupID, SettingsModuleID} from "@co-common-libs/config";
import {actions} from "@co-frontend-libs/redux";
import {ListItem, ListItemText, useTheme} from "@material-ui/core";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";

const NESTED_LIST_SPACING = 4;

interface SettingsMenuGroupEntryProps {
  groupID: SettingsGroupID;
  groupTitle: string;
  moduleID: SettingsModuleID;
}

export const SettingsMenuGroupEntry = React.memo(function SettingsMenuGroupEntry(
  props: SettingsMenuGroupEntryProps,
): React.JSX.Element {
  const {groupID, groupTitle, moduleID} = props;
  const dispatch = useDispatch();
  const handleGroupChange = useCallback((): void => {
    dispatch(actions.putQueryKeys({group: groupID, module: moduleID}));
  }, [dispatch, groupID, moduleID]);
  const theme = useTheme();

  return (
    <ListItem
      button
      onClick={handleGroupChange}
      style={{paddingLeft: theme.spacing(NESTED_LIST_SPACING)}}
    >
      <ListItemText primary={groupTitle} />
    </ListItem>
  );
});
