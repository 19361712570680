import {schema, SettingID} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {DialogContent, List} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {IndustryListItem} from "./industry-list-item";
import {IndustryChoice} from "./types";

interface IndustriesDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function IndustriesDialog(props: IndustriesDialogProps): React.JSX.Element {
  const {onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingsEntry = settingsEntryLookupByIdentifier(settingID);
  const currentUserURL = useSelector(getCurrentUserURL);
  const dispatch = useDispatch();
  const customerSettings = useSelector(getCustomerSettings);

  const industries = schema.properties.industries.items.enum;

  const [selected, setSelected] = useState<readonly IndustryChoice[]>(customerSettings.industries);

  useEffect(() => {
    setSelected(customerSettings.industries);
  }, [customerSettings.industries]);

  const handleSave = useCallback(() => {
    if (settingsEntry) {
      dispatch(
        actions.update(settingsEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: selected},
        ]),
      );
    } else {
      const id = uuid();
      const url = instanceURL("settingEntry", id);
      dispatch(
        actions.create({
          changedBy: currentUserURL,
          data: selected,
          key: settingID,
          url,
        }),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, selected, settingID, settingsEntry]);

  const handleToggle = useCallback(
    (industry: IndustryChoice) => {
      if (selected.includes(industry)) {
        setSelected(selected.filter((x) => x !== industry));
      } else {
        setSelected([...selected, industry]);
      }
    },
    [selected],
  );
  return (
    <ResponsiveDialog
      okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
      onCancel={onClose}
      onOk={handleSave}
      open={open}
      title={
        <FormattedMessage defaultMessage="Brancher" id="system-setup.dialog-title.industries" />
      }
    >
      <DialogContent>
        <List>
          {industries.map((industry) => (
            <IndustryListItem
              checked={selected.includes(industry)}
              industry={industry}
              key={industry}
              onClick={handleToggle}
            />
          ))}
        </List>
      </DialogContent>
    </ResponsiveDialog>
  );
}
