import type {Writable} from "ts-essentials";
import {
  caseAccentInsensitiveCollator,
  identifierComparator,
  normalizeSearchTextArray,
} from "@co-common-libs/utils";
import {findMatches} from "app-utils";
import _ from "lodash";
import {categoryOrder, EntryData, FilterVariant, SearchResultEntryData} from "./types";

export function recentlyUsedComparator<Identifier extends string>(
  a: EntryData<Identifier>,
  b: EntryData<Identifier>,
): number {
  // Should never be 0 when category is "recentlyUsed", but expressing this in types is hard
  return (b.recentlyUsedSortKey || 0) - (a.recentlyUsedSortKey || 0);
}

export function categoryComparator<Identifier extends string>(
  a: EntryData<Identifier>,
  b: EntryData<Identifier>,
): number {
  if (a.category === "recentlyUsed" && b.category === "recentlyUsed") {
    return recentlyUsedComparator(a, b);
  } else {
    return categoryOrder[a.category] - categoryOrder[b.category];
  }
}

export function primaryTextComparator<Identifier extends string>(
  a: EntryData<Identifier>,
  b: EntryData<Identifier>,
): number {
  if (a.priority && !b.priority) return -1;
  if (b.priority && !a.priority) return 1;
  return (
    caseAccentInsensitiveCollator.compare(a.primaryText, b.primaryText) ||
    caseAccentInsensitiveCollator.compare(a.secondaryText || "", b.secondaryText || "") ||
    caseAccentInsensitiveCollator.compare(a.identifier, b.identifier)
  );
}

export function secondaryTextComparator<Identifier extends string>(
  a: EntryData<Identifier>,
  b: EntryData<Identifier>,
): number {
  if (a.priority && !b.priority) return -1;
  if (b.priority && !a.priority) return 1;
  return (
    caseAccentInsensitiveCollator.compare(a.secondaryText || "", b.secondaryText || "") ||
    caseAccentInsensitiveCollator.compare(a.primaryText, b.primaryText) ||
    caseAccentInsensitiveCollator.compare(a.identifier, b.identifier)
  );
}

export function primaryIdentifierComparator<Identifier extends string>(
  a: EntryData<Identifier>,
  b: EntryData<Identifier>,
): number {
  if (a.priority && !b.priority) return -1;
  if (b.priority && !a.priority) return 1;
  return (
    identifierComparator(a.primaryText, b.primaryText) ||
    caseAccentInsensitiveCollator.compare(a.secondaryText || "", b.secondaryText || "") ||
    caseAccentInsensitiveCollator.compare(a.identifier, b.identifier)
  );
}

export function secondaryIdentifierComparator<Identifier extends string>(
  a: EntryData<Identifier>,
  b: EntryData<Identifier>,
): number {
  if (a.priority && !b.priority) return -1;
  if (b.priority && !a.priority) return 1;
  return (
    identifierComparator(a.secondaryText || "", b.secondaryText || "") ||
    caseAccentInsensitiveCollator.compare(a.primaryText, b.primaryText) ||
    caseAccentInsensitiveCollator.compare(a.identifier, b.identifier)
  );
}

// for sorting where we want highest score first...
function getEntryNegatedScore<Identifier extends string>(
  entry: SearchResultEntryData<Identifier>,
): number {
  let result = 0;
  for (const {score} of entry.matches) {
    result -= score;
  }
  return result;
}

export function filterSort<Identifier extends string>(
  data: readonly EntryData<Identifier>[],
  filterString: string,
  matchType: "AND" | "OR",
): SearchResultEntryData<Identifier>[] {
  console.assert(filterString.trim());
  const lowerFilterString = filterString.trim().toLocaleLowerCase();
  const needleArray = normalizeSearchTextArray(filterString);

  const result: Writable<SearchResultEntryData<Identifier>>[] = [];
  for (const entry of data) {
    const matches = findMatches(matchType, needleArray, entry.searchFields);
    if (matches.length) {
      result.push({
        ...entry,
        matches,
      });
    }
  }
  // higher score at the start; but keep original order on equal score
  const sortedResult = _.sortBy(result, getEntryNegatedScore);
  for (const entry of sortedResult) {
    if (entry.exactMatchValue && entry.exactMatchValue.toLocaleLowerCase() === lowerFilterString) {
      entry.highlight = true;
      break;
    }
  }
  return sortedResult;
}

export function getExactMatchEntry<Identifier extends string>(
  data: readonly EntryData<Identifier>[],
  lowerFilterString: string,
): EntryData<Identifier> | undefined {
  return data.find((entry) => entry.exactMatchValue?.toLocaleLowerCase() === lowerFilterString);
}

export function getSingleEntry<T>(data: readonly T[]): T | undefined {
  if (data.length === 1) {
    return data[0];
  } else {
    return undefined;
  }
}

export function filterData<Identifier extends string>(
  data: readonly EntryData<Identifier>[],
  filterVariant: FilterVariant,
  trimmedFilterString: string,
): readonly EntryData<Identifier>[] {
  if (trimmedFilterString) {
    return _.sortBy(
      filterSort(data, trimmedFilterString, filterVariant),
      (entry) => categoryOrder[entry.category],
    );
  } else {
    return data;
  }
}

export function getUniqueFilterResult<Identifier extends string>(
  filteredData: readonly EntryData<Identifier>[],
  trimmedFilterString: string,
): EntryData<Identifier> | undefined {
  const singleEntry = getSingleEntry(filteredData);
  if (singleEntry) {
    return singleEntry;
  } else if (trimmedFilterString) {
    const lowerFilterString = trimmedFilterString.toLocaleLowerCase();
    return getExactMatchEntry(filteredData, lowerFilterString);
  } else {
    return undefined;
  }
}
