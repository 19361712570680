import type {DeepReadonly} from "ts-essentials";
import {DecimalField, IntegerField, TrimTextField} from "@co-frontend-libs/components";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {useEventTargetCheckedCallback, useEventTargetValueCallback} from "app-utils";
import {JSONSchema7} from "json-schema";
import _ from "lodash";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";

interface SettingEntryInputFieldProps {
  onValueTextChange: (newValueText: string) => void;
  schema: DeepReadonly<JSONSchema7>;
  valueText: string;
}

export function SettingEntryInputField(props: SettingEntryInputFieldProps): React.JSX.Element {
  const {onValueTextChange: setValueText, schema, valueText} = props;
  const intl = useIntl();

  const handleTextChange = useCallback(
    (text: string) => {
      setValueText(JSON.stringify(text));
    },
    [setValueText],
  );

  const handleNullableTextChange = useCallback(
    (text: string) => {
      setValueText(JSON.stringify(text || null));
    },
    [setValueText],
  );

  const handleCheckedChange = useEventTargetCheckedCallback(
    (checked: boolean) => {
      setValueText(JSON.stringify(checked));
    },
    [setValueText],
  );
  const handleCheckedGroupChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const selected = new Set(JSON.parse(valueText));
      const choice = JSON.parse(event.target.name);
      if (event.target.checked) {
        selected.add(choice);
      } else {
        selected.delete(choice);
      }
      setValueText(JSON.stringify(Array.from(selected).sort()));
    },
    [setValueText, valueText],
  );

  const handleJSONTextChange = useEventTargetValueCallback(setValueText, [setValueText]);

  const handleNumberChange = useCallback(
    (value: number | null): void => {
      setValueText(JSON.stringify(value));
    },
    [setValueText],
  );

  let value: any | undefined;
  try {
    value = JSON.parse(valueText);
  } catch (_error) {
    // ignore
  }

  if (schema.type === "boolean") {
    return (
      <FormControl>
        <Checkbox checked={!!value} onChange={handleCheckedChange} />
      </FormControl>
    );
  } else if (schema.enum) {
    return (
      <RadioGroup name="setting-field" onChange={handleJSONTextChange} value={valueText}>
        {schema.enum.map((alternativeValue) => {
          return (
            <FormControlLabel
              control={<Radio />}
              key={JSON.stringify(alternativeValue)}
              label={`${alternativeValue ?? intl.formatMessage({defaultMessage: "Ingen"})}`}
              value={JSON.stringify(alternativeValue)}
            />
          );
        })}
      </RadioGroup>
    );
  } else if (
    schema.type === "array" &&
    schema.uniqueItems &&
    Array.isArray((schema as unknown as any)?.items?.enum)
  ) {
    const choices: any[] = (schema as unknown as any)?.items?.enum;
    return (
      <FormGroup>
        {choices.map((choice) => {
          const choiceText = `${choice}`;
          return (
            <FormControlLabel
              control={
                <Checkbox checked={value.includes(choice)} onChange={handleCheckedGroupChange} />
              }
              key={choiceText}
              label={choiceText}
              name={JSON.stringify(choice)}
            />
          );
        })}
      </FormGroup>
    );
  } else if (schema.type === "string") {
    return (
      <TrimTextField
        autoFocus
        fullWidth
        label={<FormattedMessage defaultMessage="Tekst" id="system-setup.label.text" />}
        margin="dense"
        multiline
        onChange={handleTextChange}
        value={value ?? ""}
        variant="outlined"
      />
    );
  } else if (_.isEqual(schema.type, ["string", "null"])) {
    return (
      <TrimTextField
        autoFocus
        fullWidth
        label={<FormattedMessage defaultMessage="Tekst" id="system-setup.label.text" />}
        margin="dense"
        multiline
        onChange={handleNullableTextChange}
        value={value ?? ""}
        variant="outlined"
      />
    );
  } else if (schema.type === "number" || _.isEqual(schema.type, ["number", "null"])) {
    return (
      <DecimalField
        autoFocus
        decimalPlaces={3}
        fullWidth
        label={<FormattedMessage defaultMessage="Tal" id="system-setup.label.number" />}
        margin="dense"
        maxDigits={15}
        onChange={handleNumberChange}
        value={value ?? null}
      />
    );
  } else if (schema.type === "integer" || _.isEqual(schema.type, ["integer", "null"])) {
    return (
      <IntegerField
        autoFocus
        fullWidth
        label={<FormattedMessage defaultMessage="Tal" id="system-setup.label.number" />}
        margin="dense"
        onChange={handleNumberChange}
        value={value ?? null}
      />
    );
  } else {
    return (
      <TrimTextField
        autoFocus
        fullWidth
        label="JSON"
        margin="dense"
        maxRows={20}
        minRows={2}
        multiline
        onChange={setValueText}
        value={valueText}
        variant="outlined"
      />
    );
  }
}
