import {Checkbox, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";
import {IndustryLabel} from "../industry-label";
import {IndustryChoice} from "./types";

interface IndustryListItemProps {
  checked: boolean;
  industry: IndustryChoice;
  onClick: (industry: IndustryChoice) => void;
}

export function IndustryListItem(props: IndustryListItemProps): React.JSX.Element {
  const {checked, industry, onClick} = props;

  const handleClick = useCallback(() => {
    onClick(industry);
  }, [industry, onClick]);
  return (
    <ListItem button dense key={industry} onClick={handleClick}>
      <ListItemIcon>
        <Checkbox checked={checked} edge="start" />
      </ListItemIcon>
      <ListItemText primary={<IndustryLabel industry={industry} />} />
    </ListItem>
  );
}
