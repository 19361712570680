import type {rdiffResult} from "recursive-diff";
import {ResourceName} from "@co-common-libs/resources";
import {actions, getUserLookup} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {
  alpha,
  Box,
  Collapse,
  createStyles,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import {useAsync, useMountEffect, usePrevious} from "@react-hookz/web";
import {GoToPathButton} from "app-components";
import {useQueryParameter} from "app-utils";
import {instanceURL, SPACING} from "frontend-global-config";
import KeyboardArrowDownIcon from "mdi-react/KeyboardArrowDownIcon";
import KeyboardArrowUpIcon from "mdi-react/KeyboardArrowUpIcon";
import LaunchIcon from "mdi-react/LaunchIcon";
import SearchIcon from "mdi-react/SearchIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {parse as uuidParse} from "uuid";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

type HistoryEntry = {
  action: number;
  byUserId: string | null;
  diff: rdiffResult[];
  timestamp: string;
};

interface ResourceHistoryResult {
  error: string | null;
  resourceName: ResourceName;
  results: HistoryEntry[];
}

function HistoryEntryRow(props: {row: HistoryEntry}): React.JSX.Element {
  const {row} = props;
  const [open, setOpen] = React.useState(true);
  const classes = useRowStyles();

  const userLookup = useSelector(getUserLookup);

  const intl = useIntl();

  const toggleCollapse = useCallback(() => setOpen(!open), [open]);

  const translateAction = useCallback(
    (action: number) => {
      if (action === 0) {
        return intl.formatMessage({defaultMessage: "Opret"});
      } else if (action === 1) {
        return intl.formatMessage({defaultMessage: "Opdater"});
      } else if (action === 2) {
        return intl.formatMessage({defaultMessage: "Slet"});
      } else {
        return "";
      }
    },
    [intl],
  );

  const translateUser = useCallback(
    (id: string | null) => {
      if (!id) {
        return "CustomOffice";
      }

      const profile = userLookup(instanceURL("user", id));

      if (profile) {
        return (
          <GoToPathButton parameters={{id}} path="/userProfile/:id">
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <Typography variant="body2">{profile.loginIdentifier}</Typography>
              </Grid>
              <Grid item>
                <LaunchIcon />
              </Grid>
            </Grid>
          </GoToPathButton>
        );
      } else {
        return "???";
      }
    },
    [userLookup],
  );

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" onClick={toggleCollapse} size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{translateAction(row.action)}</TableCell>
        <TableCell component="th" scope="row">
          {row.timestamp}
        </TableCell>
        <TableCell align="right">{translateUser(row.byUserId)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{paddingBottom: 0, paddingTop: 0}}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <TextareaAutosize
                defaultValue={JSON.stringify(row.diff, undefined, 2)}
                style={{width: "100%"}}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "fixed",
    },

    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    inputRoot: {
      color: "inherit",
      minWidth: 650,
    },
    noPadding: {
      padding: 0,
    },
    search: {
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.25),
      },
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      marginRight: 0,
      position: "relative",
      width: "100%",
    },
    searchIcon: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "none",
      position: "absolute",
      width: theme.spacing(7),
    },
    table: {
      minWidth: 650,
      width: "100%",
    },
  }),
);

export function ResourceHistoryPage(): React.JSX.Element {
  const filterString = useQueryParameter("q", "");

  const dispatch = useDispatch();

  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  const [searchUUID, setSearchUUID] = useState("");

  const [state, fetchActions] = useAsync(() =>
    jsonFetch(`/api/resource_history/${filterString}`, "GET"),
  );

  const handleSearchFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      dispatch(actions.putQueryKey("q", event.target.value));
    },
    [dispatch],
  );

  const doSearch = useCallback(async () => {
    try {
      uuidParse(filterString);
      setSearchUUID(filterString);
      await fetchActions.execute();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      // ignore invalid uuid
    }
  }, [fetchActions, filterString]);

  const handleSearchFieldKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
      if (event.key !== "Enter") {
        return;
      }

      await doSearch();
    },
    [doSearch],
  );

  useMountEffect(async () => {
    if (filterString) {
      await doSearch();
    }
  });

  const prevData = usePrevious(state.result);
  const data: ResourceHistoryResult | undefined =
    state.status === "success" ? state.result?.data : prevData?.data;

  return (
    <Grid alignContent={"center"} container direction="column" spacing={SPACING.MEDIUM}>
      <Grid item xs={8}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            classes={{
              input: classes.inputInput,
              root: classes.inputRoot,
            }}
            disabled={state.status === "loading"}
            onChange={handleSearchFieldChange}
            onKeyDown={handleSearchFieldKeyDown}
            placeholder={intl.formatMessage({defaultMessage: "Indsæt UUID"})}
            value={filterString}
          />
        </div>
        {(state.status === "error" || data?.error) && (
          <Typography style={{color: theme.palette.error.main}} variant="body1">
            <FormattedMessage
              defaultMessage="Der skete en fejl ved opslag efter {uuid}"
              values={{uuid: searchUUID}}
            />
            <br />
            <br />
            {data?.error}
          </Typography>
        )}
      </Grid>

      {data && data.results.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6">
            <FormattedMessage
              defaultMessage="Fandt {count} historik elementer for {resourceName}"
              values={{count: data.results.length, resourceName: data.resourceName}}
            />
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell size="small">
                    <FormattedMessage defaultMessage="Handling" />
                  </TableCell>
                  <TableCell size="small">
                    <FormattedMessage defaultMessage="Tid" />
                  </TableCell>
                  <TableCell align="right" size="small">
                    <FormattedMessage defaultMessage="Ansvarlig" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.results.map((result, index) => (
                  <HistoryEntryRow key={index} row={result} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
}
