import {SettingID, SettingMetaData} from "@co-common-libs/config";
import {IconButton, useTheme} from "@material-ui/core";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import React from "react";
import {FormattedMessage} from "react-intl";

interface SettingDataMissingViewProps {
  settingId: SettingID;
  settingMetaData: SettingMetaData;
}
export function SettingDataMissingView(props: SettingDataMissingViewProps): React.JSX.Element {
  const theme = useTheme();
  const {settingId, settingMetaData} = props;
  return (
    <div>
      <div style={{display: "flex"}}>
        <div style={{flex: "0 0 50px"}}>
          <AlertCircleIcon color={theme.palette.error.main} />
        </div>
        <div style={{flex: 1}}>
          <span>{settingMetaData.description}</span>
          <div style={{color: theme.palette.error.main, padding: 2}}>
            <FormattedMessage defaultMessage="Data for setting mangler i databasen. Prøv at genhente offline data. Hvis dette ikke virker, så kontakt udviklerne." />
          </div>
        </div>
        <div
          style={{
            color: theme.palette.grey[500],
            flex: "0 0 100px",
            textAlign: "right",
          }}
        >
          <small>
            <div>{settingId}</div>
          </small>
        </div>
        <div style={{flex: "0 0 50px", position: "relative", top: -12}}>
          {settingMetaData.documentationURL ? (
            <IconButton color="primary" href={settingMetaData.documentationURL} target="blank">
              <InformationOutlineIcon />
            </IconButton>
          ) : null}
        </div>
      </div>
    </div>
  );
}
