import type {DeepReadonly, Writable} from "ts-essentials";
import {JSONSchema7} from "json-schema";
import _ from "lodash";
import {
  conflictsWith,
  noopWithoutAllOf,
  noopWithoutOneOf,
  requiresAllOf,
  requiresOneOf,
  SettingDependency,
} from "./dependencies";
import {schema} from "./schema";
import {settingGroupRelations, SettingID} from "./setting-group-relations";
import {SettingsGroupID} from "./settings-groups";
import {settingsIdentifiers} from "./settings-order";
import {settingsDocumentationURLs} from "./settings-urls";
import {Config} from "./types";

export interface SettingMetaData {
  readonly conflictsWith: readonly SettingDependency[];
  readonly defaultValue: Config[SettingID];
  readonly description: string;
  readonly documentationURL?: string;
  readonly group: SettingsGroupID;
  readonly noopWithoutAllOf: readonly SettingDependency[];
  readonly noopWithoutOneOf: readonly SettingDependency[];
  readonly requiresAllOf: readonly SettingDependency[];
  readonly requiresOneOf: readonly SettingDependency[];
  readonly schema: DeepReadonly<JSONSchema7>;
}

export type Settings = {
  readonly [S in SettingID]: SettingMetaData;
};

function resolveSchema<T extends DeepReadonly<JSONSchema7>>(inputSchema: T): T {
  const result = _.cloneDeep(inputSchema);
  const {definitions} = result;
  if (!definitions) {
    return result;
  }
  function replaceRefs(value: any, key: number | string, collection: any): void {
    // also matches arrays
    if (value && typeof value === "object") {
      if (_.isEqual(Object.keys(value), ["$ref"])) {
        const ref = value.$ref as string;
        if (!ref.startsWith("#/definitions/")) {
          throw new Error(`only #/definitions/ refs supported; got ${ref}`);
        }
        const [, , refName] = ref.split("/");
        collection[key] = (definitions as any)[refName];
      } else {
        // iterates sensibly over objects and arrays
        _.forEach(value, replaceRefs);
      }
    }
  }
  _.forEach(result, replaceRefs);
  return result;
}

export const settings = Object.fromEntries(
  Object.entries(resolveSchema(schema).properties).map(
    ([schemaKey, entrySchema]): [string, SettingMetaData] => {
      const key = schemaKey as SettingID;
      const result: Writable<SettingMetaData> = {
        conflictsWith: conflictsWith[key] || [],
        defaultValue: entrySchema.default as Config[SettingID],
        description: entrySchema.description || "",
        group: settingGroupRelations[key],
        noopWithoutAllOf: noopWithoutAllOf[key] || [],
        noopWithoutOneOf: noopWithoutOneOf[key] || [],
        requiresAllOf: requiresAllOf[key] || [],
        requiresOneOf: requiresOneOf[key] || [],
        schema: entrySchema,
      };
      const documentationURL = settingsDocumentationURLs[key];
      if (documentationURL) {
        result.documentationURL = documentationURL;
      }
      return [key, result];
    },
  ),
) as Settings;

if (process.env.NODE_ENV !== "production") {
  settingsIdentifiers.forEach((identifier) => {
    if (!settings[identifier]) {
      throw new Error(`Unknown settings identifier ${identifier}`);
    }
  });
  (Object.keys(settings) as SettingID[]).forEach((identifier) => {
    if (!settingsIdentifiers.includes(identifier)) {
      throw new Error(`Missing order for settings identifier ${identifier}`);
    }
  });
}
