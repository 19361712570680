import {Order} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {DialogContent, IconButton} from "@material-ui/core";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch} from "react-redux";

interface RemoveOrderBDReferenceProps {
  order: Order | undefined;
}

export function RemoveOrderBDReference(props: RemoveOrderBDReferenceProps): React.JSX.Element {
  const {order} = props;

  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleOk = useCallback(() => {
    if (order && order.remoteUrl) {
      dispatch(
        actions.update(order.url, [
          {member: "remoteUrl", value: ""},
          {member: "referenceNumber", value: ""},
        ]),
      );
    }
    setDialogOpen(false);
  }, [dispatch, order]);
  return (
    <>
      <IconButton disabled={!order?.remoteUrl} onClick={handleClick}>
        <CloseIcon />
      </IconButton>
      <ResponsiveDialog
        cancelLabel={
          <FormattedMessage defaultMessage="Nej" id="remove-order-remote-url-cross.label.no" />
        }
        okLabel={
          <FormattedMessage defaultMessage="Ja" id="remove-order-remote-url-cross.label.yes" />
        }
        onCancel={handleCancel}
        onOk={handleOk}
        open={dialogOpen}
        title={
          <FormattedMessage
            defaultMessage="Nulstil forbindelse til BrugerData-ordre?"
            id="remove-order-remote-url-cross.dialog-title.clear-bd-order-association"
          />
        }
      >
        <DialogContent>
          <FormattedMessage
            defaultMessage="En ny BrugerData-ordre vil oprettes ved evt. senere overførsel."
            id="remove-order-remote-url.text.new-db-order-may-be-created"
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
