import {SettingID} from "@co-common-libs/config";
import {UnitUrl} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {ConnectedSingleUnitDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {
  Button,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "mdi-react/DeleteIcon";
import React, {useCallback, useEffect, useMemo, useReducer, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DeletableChip} from "../../reports/deletable-chip";

type Setting = {[targetFieldUnit: string]: readonly string[] | undefined};

type StateActions =
  | {
      helperFieldUnit: string;
      targetFieldUnit: string;
      type: "addHelperFieldUnit";
    }
  | {
      helperFieldUnit: string;
      targetFieldUnit: string;
      type: "deleteHelperFieldUnit";
    }
  | {newState: Setting; type: "replace"}
  | {
      targetFieldUnit: string;
      type: "addTargetFieldUnit";
    }
  | {
      targetFieldUnit: string;
      type: "deleteTargetFieldUnit";
    };

// eslint-disable-next-line consistent-return
const reducer = (state: Setting, action: StateActions): Setting => {
  const newState = {...state};
  switch (action.type) {
    case "addHelperFieldUnit": {
      const unitList = newState[action.targetFieldUnit];

      if (unitList) {
        newState[action.targetFieldUnit] = [...unitList, action.helperFieldUnit];
      } else {
        newState[action.targetFieldUnit] = [action.helperFieldUnit];
      }
      return newState;
    }
    case "addTargetFieldUnit": {
      newState[action.targetFieldUnit] = [];
      return newState;
    }
    case "deleteHelperFieldUnit": {
      const unitList = newState[action.targetFieldUnit];
      if (unitList) {
        newState[action.targetFieldUnit] = unitList.filter((u) => u !== action.helperFieldUnit);
      }
      return newState;
    }
    case "deleteTargetFieldUnit": {
      delete newState[action.targetFieldUnit];
      return newState;
    }
    case "replace": {
      return action.newState;
    }
  }
};

function EnterUnitConversionHelpersRow({
  dispatch,
  helperFieldUnits,
  targetFieldUnit,
}: {
  dispatch: React.Dispatch<StateActions>;
  helperFieldUnits: readonly string[];
  targetFieldUnit: string;
}): React.JSX.Element {
  const handleDeleteClick = useCallback(() => {
    dispatch({targetFieldUnit, type: "deleteTargetFieldUnit"});
  }, [dispatch, targetFieldUnit]);

  const handleUnitDelete = useCallback(
    (unitToRemove: string) =>
      dispatch({
        helperFieldUnit: unitToRemove,
        targetFieldUnit,
        type: "deleteHelperFieldUnit",
      }),
    [dispatch, targetFieldUnit],
  );

  const [unitDialogOpen, setUnitDialogOpen] = useState(false);
  const setUnitDialogOpenFalse = useCallWithFalse(setUnitDialogOpen);

  const unitLookup = useSelector(getUnitLookup);
  const handleUnitDialogOk = useCallback(
    (url: UnitUrl) => {
      const unitInstance = unitLookup(url);
      setUnitDialogOpen(false);
      if (unitInstance && !helperFieldUnits.includes(unitInstance.name)) {
        dispatch({
          helperFieldUnit: unitInstance.name,
          targetFieldUnit,
          type: "addHelperFieldUnit",
        });
      }
    },
    [dispatch, targetFieldUnit, unitLookup, helperFieldUnits],
  );

  const handleAddUnitDialog = useCallback(() => {
    setUnitDialogOpen(true);
  }, []);

  return (
    <>
      <TableRow>
        <TableCell>{targetFieldUnit}</TableCell>
        <TableCell>
          <Button onClick={handleAddUnitDialog}>Tilføj</Button>
          <div>
            {helperFieldUnits.map((u) => (
              <DeletableChip deletionId={u} key={u} label={u} onDelete={handleUnitDelete} />
            ))}
          </div>
        </TableCell>
        <TableCell>
          {helperFieldUnits?.length
            ? helperFieldUnits.map((u) => `${u}/${targetFieldUnit}`).join(", ")
            : null}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <ConnectedSingleUnitDialog
        onCancel={setUnitDialogOpenFalse}
        onOk={handleUnitDialogOk}
        open={unitDialogOpen}
      />
    </>
  );
}

interface UnitConversionHelpersDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function UnitConversionHelpersDialog(
  props: UnitConversionHelpersDialogProps,
): React.JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const unitConversionHelpers = useMemo(() => {
    if (settingEntry?.data) {
      const ordered: Setting = {};
      Object.keys(settingEntry?.data)
        .sort()
        .forEach((key) => {
          ordered[key] = settingEntry?.data[key];
        });
      return ordered;
    }
    return {};
  }, [settingEntry?.data]);

  const [values, localDispatch] = useReducer(reducer, unitConversionHelpers);

  useEffect(() => {
    if (open) {
      localDispatch({newState: unitConversionHelpers, type: "replace"});
    }
  }, [open, unitConversionHelpers]);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleOk = useCallback(() => {
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: values},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, settingEntry, values]);

  const diableOk = Object.values(values).some((units) => !units || !units.length);

  const [unitDialogOpen, setUnitDialogOpen] = useState(false);
  const setUnitDialogOpenFalse = useCallWithFalse(setUnitDialogOpen);

  const unitLookup = useSelector(getUnitLookup);
  const handleUnitDialogOk = useCallback(
    (url: UnitUrl) => {
      const unit = unitLookup(url);
      setUnitDialogOpen(false);
      if (unit) {
        localDispatch({targetFieldUnit: unit.name, type: "addTargetFieldUnit"});
      }
    },
    [unitLookup],
  );

  const handleAddUnitDialog = useCallback(() => {
    setUnitDialogOpen(true);
  }, []);

  return (
    <>
      <ResponsiveDialog
        okDisabled={diableOk}
        onCancel={onClose}
        onOk={handleOk}
        open={open}
        title={
          <FormattedMessage
            defaultMessage="Rediger konvertering"
            id="enter-unitConversionHelpers.edit-unitConversionHelpers"
          />
        }
      >
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Enhed"
                    id="unit-conversion-helpers-dialog.unit"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Enheder"
                    id="unit-conversion-helpers-dialog.units"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Konvertering"
                    id="unit-conversion-helpers-dialog.conversion"
                  />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(values).map((targetFieldUnit, index) => {
                const helperFieldUnits = values[targetFieldUnit] || [];
                return (
                  <EnterUnitConversionHelpersRow
                    dispatch={localDispatch}
                    helperFieldUnits={helperFieldUnits}
                    key={index}
                    targetFieldUnit={targetFieldUnit}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Button onClick={handleAddUnitDialog}>Tilføj</Button>
        </DialogContent>
      </ResponsiveDialog>
      <ConnectedSingleUnitDialog
        onCancel={setUnitDialogOpenFalse}
        onOk={handleUnitDialogOk}
        open={unitDialogOpen}
      />
    </>
  );
}
