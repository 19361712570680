import {
  Customer,
  CustomerUrl,
  Location,
  LocationStorageAdjustment,
  LocationUrl,
  Product,
} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  GenericTable,
  iconButtonColumnSpecification,
  RowData,
} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRole,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Card, CardHeader, Collapse, IconButton, makeStyles} from "@material-ui/core";
import {getLocationString} from "app-utils";
import clsx from "clsx";
import {instanceURL} from "frontend-global-config";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useMemo, useState} from "react";
import {defineMessages, FormattedMessage, FormattedNumber, IntlShape, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {LocationStorageStatusDialog} from "./location-storage-status-dialog";

const messages = defineMessages({
  accountNumber: {defaultMessage: "Kontonr"},
  amount: {
    defaultMessage: "Antal",
    id: "location-storage.table-header.amount",
  },
  customer: {
    defaultMessage: "Kunde",
    id: "location-storage.table-header.customer",
  },
  location: {
    defaultMessage: "Sted",
    id: "location-storage.table-header.lokation",
  },
});

type ProductStorageTableFieldID = "amount" | "customerAccount" | "customerName" | "locationString";

type ProductStorageTableColumnID =
  | "amount"
  | "customerAccount"
  | "customerName"
  | "edit"
  | "locationString";

interface ProductStorageTableDataType extends RowData<ProductStorageTableFieldID, LocationUrl> {
  amount: number;
  customerAccount: string;
  customerName: string;
  locationString: string;
}

function getEditButtonRenderFunction(
  onEditClick: (locationURL: LocationUrl) => void,
): (data: ProductStorageTableDataType) => React.JSX.Element {
  // eslint-disable-next-line react/display-name
  return (data: ProductStorageTableDataType): React.JSX.Element => {
    const handleEditClick = (): void => {
      onEditClick(data.key);
    };
    return (
      // buildColumnSpecifications should probably take a
      // ComponentType<{data: ...}> parameter; but whitelist this until then
      // eslint-disable-next-line react/jsx-no-bind
      <IconButton onClick={handleEditClick}>
        <PencilIcon />
      </IconButton>
    );
  };
}

function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onEditClick: (locationURL: LocationUrl) => void,
): ColumnSpecifications<
  ProductStorageTableFieldID,
  ProductStorageTableColumnID,
  LocationUrl,
  ProductStorageTableDataType
> {
  return {
    amount: {
      field: "amount",
      label: formatMessage(messages.amount),
      width: 120,
    },
    customerAccount: {
      field: "customerAccount",
      label: formatMessage(messages.accountNumber),
    },
    customerName: {
      field: "customerName",
      label: formatMessage(messages.customer),
    },
    edit: iconButtonColumnSpecification({
      field: "amount",
      render: getEditButtonRenderFunction(onEditClick),
    }),
    locationString: {
      field: "locationString",
      label: formatMessage(messages.location),
    },
  };
}

function buildRowData(
  locationCounts: readonly {
    count: number;
    location: Readonly<Location>;
  }[],
  customerLookup: (url: CustomerUrl) => Customer | undefined,
): {
  amount: number;
  customerAccount: string;
  customerName: string;
  key: LocationUrl;
  locationString: string;
}[] {
  return locationCounts
    .map(({count, location}) => {
      const customer = location.customer ? customerLookup(location.customer) : null;
      return {
        amount: count,
        customerAccount: customer?.c5_account || "",
        customerName: customer?.name || "",
        key: location.url,
        locationString: getLocationString(location),
      };
    })
    .sort((a, b) => {
      return (
        a.customerName.localeCompare(b.customerName) ||
        a.locationString.localeCompare(b.locationString)
      );
    });
}

const useStyles = makeStyles((theme) => ({
  expand: {
    marginLeft: "auto",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

interface ProductCardProps {
  includeCustomerColumn: boolean;
  locationCounts: ReadonlyMap<LocationUrl, number>;
  product: Product;
}

export function ProductCard(props: ProductCardProps): React.JSX.Element {
  const {formatMessage} = useIntl();
  const {includeCustomerColumn, locationCounts, product} = props;

  const locationLookup = useSelector(getLocationLookup);
  const customerSettings = useSelector(getCustomerSettings);

  const locationCountArray = useMemo(
    (): readonly {
      count: number;
      location: Readonly<Location>;
    }[] =>
      Array.from(locationCounts.entries())
        .map(([locationURL, count]) => {
          const location = locationLookup(locationURL);
          if (!location) {
            return undefined;
          }
          return {count, location};
        })
        .filter(notUndefined),
    [locationCounts, locationLookup],
  );

  const totalAmount = useMemo(
    () => locationCountArray.reduce((acc, {count}) => acc + count, 0),
    [locationCountArray],
  );

  const unitLookup = useSelector(getUnitLookup);
  const unitString = getUnitString(product, unitLookup);
  const titleBlock = (
    <div>
      <FormattedMessage
        defaultMessage="{catalogNumber}: {name}, {unit}"
        id="location-storage.card-title.product-card"
        values={{
          catalogNumber: product.catalogNumber,
          name: product.name,
          unit: unitString,
        }}
      />
      <div style={{float: "right"}}>
        <FormattedNumber
          maximumFractionDigits={customerSettings.materialDecimals}
          minimumFractionDigits={customerSettings.materialDecimals}
          value={totalAmount}
        />
      </div>
    </div>
  );

  const [sortKey, setSortKey] = useState<ProductStorageTableColumnID>("customerName");
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("ASC");

  const [editingLocationURL, setEditingLocationURL] = useState<LocationUrl | null>(null);
  const editingLocationAmount =
    (editingLocationURL && locationCounts?.get(editingLocationURL)) || 0;
  const handleEditDialogCancel = useCallback(() => {
    setEditingLocationURL(null);
  }, []);
  const dispatch = useDispatch();
  const handleEditDialogOk = useCallback(
    (amount: number) => {
      const change = amount - editingLocationAmount;
      if (editingLocationURL && change) {
        const id = uuid();
        const url = instanceURL("locationStorageAdjustment", id);
        const instance: LocationStorageAdjustment = {
          deviceTimestamp: new Date().toISOString(),
          id,
          location: editingLocationURL,
          product: product.url,
          url,
          value: amount,
        };
        dispatch(actions.create(instance));
      }
      setEditingLocationURL(null);
    },
    [dispatch, editingLocationAmount, editingLocationURL, product.url],
  );

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(formatMessage, setEditingLocationURL),
    [formatMessage],
  );

  const customerLookup = useSelector(getCustomerLookup);

  const rowData = useMemo(
    () => buildRowData(locationCountArray, customerLookup),
    [customerLookup, locationCountArray],
  );

  const handleHeaderClick = useCallback(
    (key: ProductStorageTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      setSortKey(key);
      setSortDirection(direction);
    },
    [sortDirection, sortKey],
  );

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  const expandButton = (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      onClick={handleExpandClick}
    >
      <ChevronDownIcon />
    </IconButton>
  );

  const currentRole = useSelector(getCurrentRole);

  const visibleColumns = useMemo(() => {
    const colums: (ProductStorageTableColumnID | undefined)[] = [
      includeCustomerColumn ? "customerName" : undefined,
      includeCustomerColumn ? "customerAccount" : undefined,
      "locationString",
      "amount",
      currentRole && currentRole.manager ? "edit" : undefined,
    ];
    return colums.filter(notUndefined);
  }, [currentRole, includeCustomerColumn]);

  return (
    <>
      <Card style={{margin: 8}}>
        <CardHeader action={expandButton} title={titleBlock} />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <GenericTable
            columns={columnSpecifications}
            entries={rowData}
            onHeaderClick={handleHeaderClick}
            sortBy={sortKey}
            sortDirection={sortDirection}
            visibleColumns={visibleColumns}
          />
        </Collapse>
      </Card>
      <LocationStorageStatusDialog
        amount={editingLocationAmount}
        onCancel={handleEditDialogCancel}
        onOk={handleEditDialogOk}
        open={!!editingLocationURL}
      />
    </>
  );
}
