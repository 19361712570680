import {Config} from "@co-common-libs/config";
import {
  emptyPriceGroup,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  ResourceTypeUnion,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {identifierComparator} from "@co-common-libs/utils";
import {MultiplePriceGroupsDialog} from "@co-frontend-libs/components";
import {
  actions,
  AppState,
  getCustomerSettings,
  getPriceGroupArray,
  getPriceGroupLookup,
  getWorkTypeLookup,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader, Fab} from "@material-ui/core";
import {PageLayout, WorkTypeEditCard} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {PriceGroupDialog} from "./pricegroup-dialog";
import {RelationsTable} from "./relations-table";
import {WorkTypeKrPerLiterFuelSurchargeBlock} from "./work-type-kr-per-liter-fuel-surcharge-block";
import {WorkTypePricePercentFuelSurchargeBlock} from "./work-type-price-percent-fuel-surcharge-block";

const messages = defineMessages({
  active: {defaultMessage: "Aktiv"},
  editWorkType: {
    defaultMessage: "Redigér arbejdsområde",
  },
  fueldSurcharge: {
    defaultMessage: "Brændstoftillæg",
  },
  id: {
    defaultMessage: "ID",
  },
  identifier: {
    defaultMessage: "Område ID",
  },
  name: {defaultMessage: "Navn"},
  newWorktype: {
    defaultMessage: "Nyt arbejdsområde",
  },
  priceGroups: {
    defaultMessage: "Varianter",
  },
  priceGroupsHelpText: {
    defaultMessage: "Angiv tilgængelige varianter for området.",
  },
});

interface EditExternalWorkTypeStateProps {
  customerSettings: Config;
  id: string;
  priceGroupArray: readonly PriceGroup[];
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface EditExternalWorkTypeDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  update: (url: string, patch: PatchUnion) => void;
}

type EditExternalWorkTypeProps = EditExternalWorkTypeDispatchProps & EditExternalWorkTypeStateProps;

interface EditExternalWorkTypeState {
  initialName: string;
  priceGroupCreateDialogOpen: boolean;
  priceGroupDialogOpen: boolean;
  selectedFromCreate: ReadonlySet<PriceGroupUrl>;
}

class EditExternalWorkType extends PureComponent<
  EditExternalWorkTypeProps,
  EditExternalWorkTypeState
> {
  static contextType = IntlContext;

  context!: React.ContextType<typeof IntlContext>;
  state = {
    initialName: "",
    priceGroupCreateDialogOpen: false,
    priceGroupDialogOpen: false,
    selectedFromCreate: new Set<PriceGroupUrl>([]),
  };

  getSelectedPriceGroups(): PriceGroupUrl[] {
    const workType = this.props.workTypeLookup(instanceURL("workType", this.props.id));
    return (workType && workType.pricegroups && workType.pricegroups.slice().sort()) || [];
  }

  @bind
  handleConnectPriceGroups(): void {
    this.setState({priceGroupDialogOpen: true});
  }

  @bind
  handlePriceGroupDialogCancel(): void {
    this.setState({priceGroupDialogOpen: false});
  }

  @bind
  handlePriceGroupCreateDialogCancel(): void {
    this.setState({priceGroupCreateDialogOpen: false});
    this.setState({priceGroupDialogOpen: true});
  }

  @bind
  handlePriceGroupDialogOk(priceGroupURLS: ReadonlySet<PriceGroupUrl>): void {
    this.setState({priceGroupDialogOpen: false});
    const workTypeURL = instanceURL("workType", this.props.id);
    const oldValue = this.getSelectedPriceGroups();
    const newValue = [...oldValue, ...priceGroupURLS];
    if (!_.isEqual(newValue, oldValue)) {
      this.props.update(workTypeURL, [{member: "pricegroups", value: newValue}]);
    }
  }

  @bind
  handlePriceGroupRemoved(priceGroupUrl: PriceGroupUrl): void {
    const workTypeURL = instanceURL("workType", this.props.id);
    const oldValue = this.getSelectedPriceGroups();
    const newValue = oldValue.slice();
    newValue.splice(newValue.indexOf(priceGroupUrl), 1);
    if (!_.isEqual(newValue, oldValue)) {
      this.props.update(workTypeURL, [{member: "pricegroups", value: newValue}]);
    }
  }

  @bind
  handleOpenCreateDialog(searchString: string): void {
    this.setState({priceGroupDialogOpen: false});
    this.setState({priceGroupCreateDialogOpen: true});
    this.setState({initialName: searchString});
  }

  @bind
  handleCreateDialogOk(name: string, identifier: string): void {
    const id = uuid();
    const url = instanceURL("priceGroup", id);
    const data: PriceGroup = {
      ...emptyPriceGroup,
      active: true,
      id,
      identifier,
      name,
      url,
    };
    this.props.create(data);

    this.setState({priceGroupCreateDialogOpen: false});
    this.setState({selectedFromCreate: new Set([url])});
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const workType = this.props.workTypeLookup(instanceURL("workType", this.props.id));

    const selectedPriceGroups = this.getSelectedPriceGroups();
    const selectedPriceGroupList = selectedPriceGroups
      .map((priceGroupUrl) => this.props.priceGroupLookup(priceGroupUrl))
      .filter(Boolean) as PriceGroup[];

    let sortedPriceGroups = selectedPriceGroupList
      .filter((instance) => instance && instance.active)
      .sort((a, b) => identifierComparator(a.identifier, b.identifier));

    if (customerSettings.c5Sync) {
      sortedPriceGroups = _.sortBy(sortedPriceGroups, (pg) => pg.c5_recid);
    }
    const priceGroupsData = sortedPriceGroups.map((priceGroup) => {
      return {
        columns: [priceGroup.identifier, priceGroup.name],
        identifier: priceGroup.url,
      };
    });

    const header = [formatMessage(messages.id), formatMessage(messages.name)];

    const connectButton = (
      <Fab
        onClick={this.handleConnectPriceGroups}
        size="small"
        style={{
          position: "absolute",
          right: 16,
          top: 16,
        }}
      >
        <PlusIcon />
      </Fab>
    );

    const mobileRelationsCellHeight = 360;

    // Grid/Cell types do not accept style prop ...?
    const GridNoType = Grid as any;
    const CellNoType = Cell as any;

    return (
      <PageLayout toolbar={formatMessage(messages.editWorkType)}>
        <GridNoType
          style={{
            margin: 5,
          }}
        >
          <CellNoType
            palm="12/12"
            style={{
              paddingBottom: 11,
              paddingTop: 11,
            }}
          >
            {workType ? <WorkTypeEditCard workType={workType} /> : null}
          </CellNoType>
          <CellNoType
            palm="12/12"
            style={{
              height: bowser.mobile ? mobileRelationsCellHeight : "100%",
              paddingBottom: 11,
              paddingTop: 11,
            }}
          >
            <Card>
              <div style={{position: "relative"}}>{connectButton}</div>
              <CardHeader title={formatMessage(messages.priceGroups)} />
              <RelationsTable
                data={priceGroupsData}
                header={header}
                onRemove={this.handlePriceGroupRemoved}
              />
            </Card>
            {customerSettings.fuelSurcharge !== null && workType ? (
              <Card style={{marginTop: 16}}>
                <CardHeader title={formatMessage(messages.fueldSurcharge)} />
                <CardContent>
                  {customerSettings.fuelSurcharge === "PRICE_PERCENT" ? (
                    <WorkTypePricePercentFuelSurchargeBlock workType={workType} />
                  ) : null}
                  {customerSettings.fuelSurcharge === "KR_PER_LITER" ? (
                    <WorkTypeKrPerLiterFuelSurchargeBlock workType={workType} />
                  ) : null}
                </CardContent>
              </Card>
            ) : null}
          </CellNoType>
        </GridNoType>
        <PriceGroupDialog
          inputName={this.state.initialName}
          onCancel={this.handlePriceGroupCreateDialogCancel}
          onOk={this.handleCreateDialogOk}
          open={this.state.priceGroupCreateDialogOpen && !this.state.priceGroupDialogOpen}
        />
        <MultiplePriceGroupsDialog
          includeSelectAll={false}
          onAdd={this.handleOpenCreateDialog}
          onCancel={this.handlePriceGroupDialogCancel}
          onOk={this.handlePriceGroupDialogOk}
          open={this.state.priceGroupDialogOpen && !this.state.priceGroupCreateDialogOpen}
          priceGroupArray={this.props.priceGroupArray.filter(
            (w) => !selectedPriceGroups.includes(w.url),
          )}
          selected={this.state.selectedFromCreate}
        />
      </PageLayout>
    );
  }
}

const ConnectedEditExternalWorkType = connect<
  EditExternalWorkTypeStateProps,
  EditExternalWorkTypeDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, EditExternalWorkTypeStateProps>({
    customerSettings: getCustomerSettings,
    id: makePathParameterGetter("id"),
    priceGroupArray: getPriceGroupArray,
    priceGroupLookup: getPriceGroupLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    create: actions.create,
    update: actions.update,
  },
)(EditExternalWorkType);

export {ConnectedEditExternalWorkType as EditExternalWorkTypePage};
