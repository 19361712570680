import {ReportingSpecificationUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

type InputType = "boolean" | "choice" | "decimal" | "integer" | "multiple_choice" | "text";

export interface ReportingChoiceAlternative {
  readonly identifier: string;
  readonly label: string;
}

export interface ReportingInputFormatSpecification {
  readonly alternatives?: readonly ReportingChoiceAlternative[];
  readonly booleanDefault?: boolean;
  readonly choices?: readonly ReportingChoiceAlternative[];
  readonly decimalPlaces?: number;
  readonly maxDigits?: number;
  readonly multiline?: boolean;
  readonly type: InputType;
}

interface ConversionSpecification {
  readonly denominator: string;
  readonly numerator: string;
}

interface SubtractionSpecification {
  readonly minuend: string;
  readonly subtrahend: string;
}

interface MultiplicationSpecification {
  readonly multiplicand: string;
  readonly multiplier: string;
}

interface ReportingInputSpecificationBase {
  readonly average?: boolean;
  readonly conversion?: ConversionSpecification;
  readonly format: ReportingInputFormatSpecification;
  readonly identifier: string;
  readonly label: string;
  readonly multiplication?: MultiplicationSpecification;
  readonly readonly?: boolean;
  readonly required: boolean;
  readonly subtraction?: SubtractionSpecification;
  readonly unit?: string;
}

export type ReportingTaskDataInputSpecification = ReportingInputSpecificationBase;

export interface ReportingWorkplaceInputSpecification extends ReportingInputSpecificationBase {
  readonly fieldAreaTarget?: boolean;
  readonly fieldCropTarget?: boolean;
  readonly sum?: boolean;
  readonly transfer?: boolean;
}

export interface ReportingLogInputSpecification extends ReportingInputSpecificationBase {
  readonly copyValueFromFirstValidPickup?: string;
  readonly copyValueFromLastDelivery?: string;
  readonly fieldAreaTarget?: boolean;
  readonly fieldCropTarget?: boolean;
  readonly sum?: boolean;
  readonly transfer?: boolean;
  readonly transferToEntry?: boolean;
  readonly unit?: string;
}

/** When possible, use the specific types... */
export type ReportingInputSpecification = Partial<ReportingLogInputSpecification> &
  Partial<ReportingTaskDataInputSpecification> &
  Partial<ReportingWorkplaceInputSpecification> &
  ReportingInputSpecificationBase;

type LogCountCoice = "*" | 0 | 1;

export interface TaskDataSpecification {
  readonly enterBaseDataLabel?: string;
  readonly inputs?: readonly ReportingTaskDataInputSpecification[];
}

interface ConversionDenominatorSpecification {
  readonly field: string;
  readonly label?: string;
  readonly unit: string;
}

export interface CalculatorSpecification {
  readonly inputLabelA: string;
  readonly inputLabelB: string;
  readonly operation: "-";
  readonly resultLabel: string;
}

export interface CalculatorSpecifications {
  readonly [unit: string]: CalculatorSpecification;
}

export interface ReportingWorkplaceTypeDataSpecification {
  readonly calculators?: CalculatorSpecifications;
  readonly inputs?: readonly ReportingWorkplaceInputSpecification[];
  readonly logEntries: LogCountCoice;
  readonly logInputs?: readonly ReportingLogInputSpecification[];
  readonly logPriceItems?: boolean;
  readonly logProducts?: boolean;
  readonly priceItemConversion?: ConversionDenominatorSpecification;
  readonly productConversion?: ConversionDenominatorSpecification;
  readonly requireAtLeastOneProduct?: boolean;
}

export interface WorkplaceDataSpecification {
  readonly delivery?: ReportingWorkplaceTypeDataSpecification;
  readonly pickup?: ReportingWorkplaceTypeDataSpecification;
  readonly workplace?: ReportingWorkplaceTypeDataSpecification;
}

export enum WorkplaceRegistration {
  none = 0,
  transportation = 2,

  workplace = 1,
}

export interface ReportingSpecification extends ResourceInstance<ReportingSpecificationUrl> {
  readonly active: boolean;
  readonly allowSkip: boolean;
  readonly allowTotalsTable: boolean;
  readonly allowTransportedMaterialCountMismatch: boolean;
  readonly autoInsertAmountInDelivery: boolean | undefined;
  readonly autoInsertLastPickupValues: boolean;
  readonly autoInsertLastWorkplaceConversionValue: boolean | undefined;
  readonly autoInsertLastWorkplaceValues: boolean | undefined;
  readonly customerLogSums: boolean | null;
  readonly displayProductsPerWorkplace: boolean;
  readonly displayWorkplaceOverviewOnPDF: boolean;
  readonly extraFooterText: string;
  readonly fieldsUsedFor: "delivery" | "pickup" | "unused" | "workplace";
  readonly hideMaterialsOnPrintout: boolean;
  readonly horizontal: boolean;
  readonly identifier: string;
  readonly invoiceCustomerAddress: boolean;
  readonly invoiceCustomerName: boolean;
  readonly invoiceCustomerVatNumber: boolean;
  readonly invoiceWorkplaceAddress: boolean;
  readonly invoiceWorkplaceName: boolean;
  readonly name: string;
  readonly reportTransport: boolean;
  readonly requireAtLeastOneLogEntry: boolean;
  readonly sendMailToPickupDeliveryCustomers: boolean;
  readonly shared: boolean;
  readonly showCustomerOnEntryTable: boolean;
  readonly showLoadCounts: boolean;
  readonly showLocationMaterialSums: boolean;
  readonly showLocationProducts: boolean;
  readonly showMachines: boolean;
  readonly showSharedTotalsTableOnAllOrderTasks: boolean | undefined;
  readonly showTaskTimeOnReport: boolean;
  readonly simpleLocationSumLog: boolean;
  readonly taskData: TaskDataSpecification;
  readonly versionNote: string;
  readonly workplaceData: WorkplaceDataSpecification;
  readonly workplaceRegistration: WorkplaceRegistration;
}

export const emptyReportingSpecification: Omit<ReportingSpecification, "createdBy" | "id" | "url"> =
  {
    active: true,
    allowSkip: false,
    allowTotalsTable: true,
    allowTransportedMaterialCountMismatch: true,
    autoInsertAmountInDelivery: true,
    autoInsertLastPickupValues: true,
    autoInsertLastWorkplaceConversionValue: false,
    autoInsertLastWorkplaceValues: false,
    customerLogSums: null,
    displayProductsPerWorkplace: false,
    displayWorkplaceOverviewOnPDF: true,
    extraFooterText: "",
    fieldsUsedFor: "unused",
    hideMaterialsOnPrintout: false,
    horizontal: false,
    identifier: "",
    invoiceCustomerAddress: false,
    invoiceCustomerName: false,
    invoiceCustomerVatNumber: false,
    invoiceWorkplaceAddress: false,
    invoiceWorkplaceName: false,
    name: "",
    reportTransport: false,
    requireAtLeastOneLogEntry: false,
    sendMailToPickupDeliveryCustomers: false,
    shared: false,
    showCustomerOnEntryTable: false,
    showLoadCounts: false,
    showLocationMaterialSums: false,
    showLocationProducts: false,
    showMachines: false,
    showSharedTotalsTableOnAllOrderTasks: false,
    showTaskTimeOnReport: true,
    simpleLocationSumLog: false,
    taskData: {},
    versionNote: "",
    workplaceData: {},
    workplaceRegistration: WorkplaceRegistration.none,
  };
