import {Project} from "@co-common-libs/resources";
import {ThrottledTextField} from "@co-frontend-libs/components";
import {actions, getCurrentRole} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {removeUnicodeCf} from "app-utils";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface ProjectNotesProps {
  editable: boolean;
  project: Project;
}

export const ProjectNotes = (props: ProjectNotesProps): React.JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {project} = props;
  const currentRole = useSelector(getCurrentRole);
  const canManageNotes =
    currentRole?.manager || currentRole?.projectManager || currentRole?.consultant;

  const handleManagerInternalNotesChange = useCallback(
    (value: string) => {
      const filteredValue = removeUnicodeCf(value);
      dispatch(actions.updateDiff({managerInternalNotes: filteredValue}, project));
    },
    [dispatch, project],
  );

  const handleProjectNotesChange = useCallback(
    (value: string) => {
      const filteredValue = removeUnicodeCf(value);
      dispatch(actions.updateDiff({notes: filteredValue}, project));
    },
    [dispatch, project],
  );

  const title = intl.formatMessage({defaultMessage: "Noter"});

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        {canManageNotes && (
          <ThrottledTextField
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Interne, kan kun ses af administrator",
            })}
            maxRows={30}
            minRows={2}
            multiline
            onChange={handleManagerInternalNotesChange}
            readonly={!canManageNotes}
            style={{marginTop: 16}}
            value={project.managerInternalNotes || ""}
            variant="outlined"
          />
        )}
        <ThrottledTextField
          fullWidth
          label={intl.formatMessage({
            defaultMessage: "Noter til medarbejdere",
          })}
          margin="dense"
          maxRows={30}
          minRows={2}
          multiline
          onChange={handleProjectNotesChange}
          readonly={!canManageNotes}
          style={{marginTop: 16}}
          value={project.notes || ""}
          variant="outlined"
        />
      </CardContent>
    </Card>
  );
};
