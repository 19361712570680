import {AccomodationAllowance as AccommodationAllowance} from "@co-common-libs/resources";
import {DeleteDialog} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import React, {useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch} from "react-redux";

interface UserAccommodationAllowanceDeleteDialogProps {
  instance: AccommodationAllowance;
  label: string;
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

export function UserAccommodationAllowanceDeleteDialog(
  props: UserAccommodationAllowanceDeleteDialogProps,
): React.JSX.Element {
  const dispatch = useDispatch();
  const {instance, label, onCancel, onOk, open} = props;
  const confirmationText = label ? (
    <FormattedMessage
      defaultMessage="Slet {accommodationAllowanceLabel}"
      values={{accommodationAllowanceLabel: label}}
    />
  ) : (
    <FormattedMessage defaultMessage="Slet diæt/udetillæg?" />
  );

  const handleOk = useCallback(() => {
    dispatch(actions.remove(instance.url));
    onOk();
  }, [dispatch, instance.url, onOk]);

  return (
    <DeleteDialog onCancel={onCancel} onOk={handleOk} open={open}>
      {confirmationText}
    </DeleteDialog>
  );
}
