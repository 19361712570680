import {
  AccomodationAllowance as AccommodationAllowance,
  Task,
  User,
  UserProfile,
} from "@co-common-libs/resources";
import {dateToString, WEEKDAY_SATURDAY, WEEKDAY_SUNDAY} from "@co-common-libs/utils";
import {
  getAccomodationAllowanceArray as getAccommodationAllowanceArray,
  getCurrentRoleIsManager,
  getCurrentRoleIsProjectManager,
  getCustomerSettings,
  getDateEmployeeAvailabilityMap,
} from "@co-frontend-libs/redux";
import {Menu, MenuList, MenuProps} from "@material-ui/core";
import {useBookkeepingTasks, useFalseCallback} from "app-utils";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {AccommodationMenuItem} from "./accommodation-menu-item";
import {AvailabilityMenuItem} from "./availability-menu-item";
import {UserAccommodationAllowanceDeleteDialog} from "./user-accommodation-allowance-delete-dialog";
import {UserAvailabilityChangeDialog} from "./user-availability-change-dialog";
import {UserDailyReportValidationDialog} from "./user-daily-report-validation-dialog";
import {UserDailyValidationMenuItem} from "./user-daily-validation-menu-item";

interface UserHeaderMenuProps {
  anchorElement: MenuProps["anchorEl"];
  date: Date;
  onClose: () => void;
  open: boolean;
  user: User;
  userProfile: UserProfile;
}

export function UserHeaderMenu(props: UserHeaderMenuProps): React.JSX.Element | null {
  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);
  const accommodationAllowanceArray = useSelector(getAccommodationAllowanceArray);

  const {anchorElement, date, onClose, open, user, userProfile} = props;
  const dateString = dateToString(date);
  const day = date.getDay();
  const weekdayIsWeekend = day === WEEKDAY_SUNDAY || day === WEEKDAY_SATURDAY;
  const showAvailability =
    (weekdayIsWeekend && customerSettings.availabilityWeekends) ||
    (!weekdayIsWeekend && customerSettings.availabilityWeekdays !== null);
  const dateEmployeeAvailabilityMap = useSelector(getDateEmployeeAvailabilityMap(dateString));
  const availability = dateEmployeeAvailabilityMap.get(userProfile.user);
  const isManager = useSelector(getCurrentRoleIsManager);
  const isProjectManager = useSelector(getCurrentRoleIsProjectManager);

  const canApproveDailyReports = isManager || isProjectManager;

  const [accommodationAllowanceData, setAccommodationAllowanceData] = useState<{
    instance: AccommodationAllowance;
    label: string;
  } | null>(null);
  const [userAvailabilityChangeDialogOpen, setUserAvailabilityChangeDialogOpen] = useState(false);

  const [handleUserDailyValidationDialogOpen, setHandleUserDailyValidationDialogOpen] =
    useState(false);

  const accomodationAllowanceDeleteDialogOpen = !!accommodationAllowanceData;

  const {remunerationGroups} = customerSettings;

  const handleAccommodationMenuItemClick = useCallback(
    ({instance, label}: {instance: AccommodationAllowance; label: string}) => {
      setAccommodationAllowanceData({instance, label});
      onClose();
    },
    [onClose],
  );

  const handleAvailabilityMenuItemClick = useCallback(() => {
    setUserAvailabilityChangeDialogOpen(true);
    onClose();
  }, [onClose]);

  const handleUserDailyValidationMenuItemClick = useCallback(() => {
    setHandleUserDailyValidationDialogOpen(true);
    onClose();
  }, [onClose]);

  const accommodationAllowanceMenuItems = accommodationAllowanceArray
    .filter(
      (accommodationAllowance) =>
        accommodationAllowance.date === dateString && accommodationAllowance.employee === user.url,
    )
    .map((accommodationAllowance) => {
      const remunerationGroupLabel =
        remunerationGroups[accommodationAllowance.remunerationGroup].reportTitle;
      const allowanceLabel =
        remunerationGroups[accommodationAllowance.remunerationGroup].accomodationAllowanceLabel ||
        intl.formatMessage({defaultMessage: "Diæt"});
      const accommodationAllowanceLabel = remunerationGroupLabel
        ? `${allowanceLabel} (${remunerationGroupLabel})`
        : allowanceLabel;
      return (
        <AccommodationMenuItem
          accommodationAllowanceLabel={accommodationAllowanceLabel}
          instance={accommodationAllowance}
          key={accommodationAllowance.url}
          onClick={handleAccommodationMenuItemClick}
        />
      );
    });

  const menuItems = [...accommodationAllowanceMenuItems];
  if (showAvailability) {
    menuItems.push(
      <AvailabilityMenuItem
        key="availability-menu-item"
        onClick={handleAvailabilityMenuItemClick}
      />,
    );
  }

  const {completedTasksStartedToday, extraTasks} = useBookkeepingTasks({
    employeeUrl: user.url,
    selectedDate: dateToString(date),
  });

  const combinedTasks = (completedTasksStartedToday as Task[]).concat(extraTasks);

  const someUnrecorded = combinedTasks.some(
    (task) =>
      ((customerSettings.useApproveReport && !task.reportApproved) ||
        (!customerSettings.useApproveReport && !task.validatedAndRecorded)) &&
      !task.recordedInC5,
  );

  if (canApproveDailyReports) {
    menuItems.push(
      <UserDailyValidationMenuItem
        disabled={!someUnrecorded}
        key="user-daily-validation-menu-item"
        onClick={handleUserDailyValidationMenuItemClick}
      />,
    );
  }

  const handleAccommodationDeleteDialogClose = useCallback(() => {
    setAccommodationAllowanceData(null);
  }, []);
  const accommodationAllowanceDeleteDialog = accommodationAllowanceData ? (
    <UserAccommodationAllowanceDeleteDialog
      instance={accommodationAllowanceData?.instance}
      label={accommodationAllowanceData?.label}
      onCancel={handleAccommodationDeleteDialogClose}
      onOk={handleAccommodationDeleteDialogClose}
      open={accomodationAllowanceDeleteDialogOpen}
    />
  ) : null;

  const handleAvailabilityChangeClose = useCallback(() => {
    setUserAvailabilityChangeDialogOpen(false);
  }, []);
  const userAvailabilityChangeDialog = (
    <UserAvailabilityChangeDialog
      date={date}
      instance={availability}
      onCancel={handleAvailabilityChangeClose}
      onOk={handleAvailabilityChangeClose}
      open={userAvailabilityChangeDialogOpen}
      userProfile={userProfile}
    />
  );

  const userDailyValidationDialog = (
    <UserDailyReportValidationDialog
      date={date}
      onClose={useFalseCallback(setHandleUserDailyValidationDialogOpen, [
        setHandleUserDailyValidationDialogOpen,
      ])}
      open={handleUserDailyValidationDialogOpen}
      user={user}
    />
  );

  return (
    <>
      <Menu anchorEl={anchorElement} onClose={onClose} open={open}>
        <MenuList>{menuItems}</MenuList>
      </Menu>
      {accommodationAllowanceDeleteDialog}
      {userAvailabilityChangeDialog}
      {userDailyValidationDialog}
    </>
  );
}
