import {Config} from "@co-common-libs/config";
import {Customer, DeliveryLocation, Location, LocationUrl, Unit} from "@co-common-libs/resources";
import {
  dateAndTimeToTimestamp,
  dateToString,
  formatAddress,
  formatTime,
} from "@co-common-libs/utils";
import {
  DecimalField,
  IntegerField,
  ResponsiveDialog,
  TimeField,
  TrimTextField,
} from "@co-frontend-libs/components";
import {AppState, getCustomerSettings, getLocationLookup} from "@co-frontend-libs/redux";
import {Button, DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  amount: {
    defaultMessage: "Mængde ({unit})",
    id: "delivery-dialog.label.amount",
  },
  areaHa: {
    defaultMessage: "Areal (ha)",
    id: "delivery-dialog.title.area",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  delivery: {
    defaultMessage: "Levering",
    id: "delivery-dialog.title.delivery",
  },
  deliveryTime: {
    defaultMessage: "Tidspunkt",
    id: "delivery-dialog.label.time",
  },
  distanceKm: {
    defaultMessage: "Afstand (km)",
    id: "delivery-dialog.label.distance-km",
  },
  editDelivery: {
    defaultMessage: "Ret levering",
    id: "delivery-dialog.title.edit-delivery",
  },
  makeWorkplaceCorrection: {
    defaultMessage: "Ret i arbejdssted",
    id: "delivery-dialog.label.make-workplace-correction",
  },
  note: {
    defaultMessage: "Note",
    id: "delivery-dialog.label.note",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
});

interface DeliveryDialogStateProps {
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
}

interface DeliveryDialogOwnProps {
  amount?: number | undefined;
  areaHa?: number | undefined;
  customer?: Customer | undefined;
  deliveryLocation?: DeliveryLocation | undefined;
  distanceKm?: number | undefined;
  haRequired?: boolean | undefined;
  isNew: boolean;
  kmRequired?: boolean | undefined;
  note?: string | undefined;
  onCancel: () => void;
  onOk: (data: {
    amount: number | null;
    areaHa: number | null;
    distanceKm: number | null;
    note: string;
    timestamp: string | null;
  }) => void;
  onRequestDeliveryLocationDialog: (deliveryLocation?: DeliveryLocation) => void;
  open: boolean;
  relatedUnit?: Unit | undefined;
  timestamp?: string | undefined;
  unit?: string | undefined;
}

type DeliveryDialogProps = DeliveryDialogOwnProps & DeliveryDialogStateProps;

interface DeliveryDialogState {
  amount: number | null;
  areaHa: number | null;
  disabled: boolean;
  distanceKm: number | null;
  note: string;
  time: string | null;
}

class DeliveryDialogImpl extends PureComponent<DeliveryDialogProps, DeliveryDialogState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: DeliveryDialogState = {
    amount: this.props.amount != null ? this.props.amount : null,
    areaHa: this.props.areaHa != null ? this.props.areaHa : null,
    disabled: false,
    distanceKm: this.props.distanceKm != null ? this.props.distanceKm : null,
    note: this.props.note || "",
    time: null,
  };
  componentDidUpdate(prevProps: DeliveryDialogProps): void {
    if (this.props.open && !prevProps.open) {
      window.setTimeout(() => {
        this.setState({disabled: false});
      }, 350);
    }
  }
  @bind
  handleAmountChange(newValue: number | null): void {
    this.setState({amount: newValue});
  }
  @bind
  handleAreaHaChange(newValue: number | null): void {
    this.setState({areaHa: newValue});
  }
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleDistanceKmChange(newValue: number | null): void {
    this.setState({distanceKm: newValue});
  }
  @bind
  handleEditLocation(): void {
    this.props.onRequestDeliveryLocationDialog(this.props.deliveryLocation);
  }
  @bind
  handleNoteChange(value: string): void {
    this.setState({note: value});
  }
  @bind
  handleOk(): void {
    const {amount, areaHa, distanceKm, note, time} = this.state;
    let timestamp: string | null = null;
    if (this.props.timestamp) {
      ({timestamp} = this.props);
      if (time) {
        const dateString = dateToString(new Date(this.props.timestamp));
        timestamp = dateAndTimeToTimestamp(dateString, time);
      }
    }

    this.props.onOk({
      amount,
      areaHa,
      distanceKm,
      note,
      timestamp,
    });
  }
  @bind
  handleTimeChange(newValue: string | null): void {
    this.setState({time: newValue});
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      customer,
      deliveryLocation,
      haRequired,
      isNew,
      kmRequired,
      locationLookup,
      open,
      relatedUnit,
      unit,
    } = this.props;
    const customerName = customer ? customer.name : null;
    const location = deliveryLocation?.relatedLocation
      ? locationLookup(deliveryLocation.relatedLocation)
      : null;
    const addressString = location ? location.name || location.address : deliveryLocation?.address;
    const deliveryLocationNote = deliveryLocation ? deliveryLocation.note : null;
    let distanceKmField;
    if (kmRequired) {
      if (this.props.customerSettings.transportLogDistanceKmDecimalPlaces) {
        distanceKmField = (
          <DecimalField
            decimalPlaces={this.props.customerSettings.transportLogDistanceKmDecimalPlaces}
            disabled={this.state.disabled}
            fullWidth
            label={formatMessage(messages.distanceKm)}
            margin="dense"
            maxDigits={4 + this.props.customerSettings.transportLogDistanceKmDecimalPlaces}
            onChange={this.handleDistanceKmChange}
            value={this.state.distanceKm}
          />
        );
      } else {
        distanceKmField = (
          <IntegerField
            disabled={this.state.disabled}
            fullWidth
            label={formatMessage(messages.distanceKm)}
            margin="dense"
            onChange={this.handleDistanceKmChange}
            value={this.state.distanceKm}
          />
        );
      }
    }
    const unitString = relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit;
    const dialogContent = (
      <div>
        <Grid>
          <Cell palm="12/12">
            <div>Kunde: {customerName}</div>
            <div>Kundes adresse: {customer ? formatAddress(customer) : null}</div>
            <div>Arbejdssted: {addressString}</div>
            <div>Note: {deliveryLocationNote}</div>
            <Button color="secondary" onClick={this.handleEditLocation} variant="contained">
              {formatMessage(messages.makeWorkplaceCorrection)}
            </Button>
          </Cell>
          <Cell palm="12/12">
            {this.props.customerSettings.canChangeTransportLogTime ? (
              <TimeField
                fullWidth
                label={formatMessage(messages.deliveryTime)}
                margin="dense"
                onChange={this.handleTimeChange}
                value={this.state.time || undefined}
              />
            ) : null}
            <DecimalField
              decimalPlaces={this.props.customerSettings.transportLogDecimals}
              fullWidth
              label={formatMessage(messages.amount, {
                unit: unitString,
              })}
              margin="dense"
              maxDigits={9}
              onChange={this.handleAmountChange}
              value={this.state.amount}
            />
            {distanceKmField}
            {haRequired ? (
              <DecimalField
                decimalPlaces={this.props.customerSettings.transportLogDecimals}
                disabled={this.state.disabled}
                fullWidth
                label={formatMessage(messages.areaHa)}
                margin="dense"
                maxDigits={9}
                onChange={this.handleAreaHaChange}
                value={this.state.areaHa}
              />
            ) : null}
            <TrimTextField
              disabled={this.state.disabled}
              fullWidth
              label={formatMessage(messages.note)}
              margin="dense"
              onChange={this.handleNoteChange}
              value={this.state.note}
              variant="outlined"
            />
          </Cell>
        </Grid>
      </div>
    );
    return (
      <ResponsiveDialog
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        open={open}
        title={isNew ? formatMessage(messages.delivery) : formatMessage(messages.editDelivery)}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps: DeliveryDialogProps): void {
    if (nextProps.open && !this.props.open) {
      let time;
      if (nextProps.timestamp) {
        time = formatTime(nextProps.timestamp).replace(".", ":");
      }
      this.setState({
        amount: nextProps.amount != null ? nextProps.amount : null,
        areaHa: nextProps.areaHa != null ? nextProps.areaHa : null,
        disabled: true,
        distanceKm: nextProps.distanceKm != null ? nextProps.distanceKm : null,
        note: nextProps.note || "",
        time: time != null ? time : null,
      });
    }
  }
}

export const DeliveryDialog: React.ComponentType<DeliveryDialogOwnProps> = connect<
  DeliveryDialogStateProps,
  object,
  DeliveryDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, DeliveryDialogStateProps>({
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
  }),
  {},
)(DeliveryDialogImpl);
