import {Config} from "@co-common-libs/config";
import {Machine, Task, UserProfile, WorkType} from "@co-common-libs/resources";
import {formatDuration} from "@co-common-libs/resources-utils";
import {formatDateShort} from "@co-common-libs/utils";
import {SvgIcon, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {Linkify} from "app-components";
import {PureComponent} from "app-utils";
import DragVerticalIcon from "mdi-react/DragVerticalIcon";
import React from "react";
import {DragLayer} from "react-dnd";
import {
  DATE_COLUMN_WIDTH,
  DRAG_HANDLE_CELL_WIDTH,
  DURATION_COLUMN_WIDTH,
  EMPLOYEE_COLUMN_WIDTH,
  MACHINES_COLUMN_WIDTH,
  STATUS_ICON_COLUMN_WIDTH,
  TABLE_MIN_WIDTH,
  VEHICLE_IDENTIFICATION_NUMBER_COLUMN_WIDTH,
  WORK_TYPE_COLUMN_WIDTH,
} from "./column-widths";

const layerStyles: React.CSSProperties = {
  height: "100%",
  left: 0,
  pointerEvents: "none",
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 100,
};

interface TaskDragPreviewOwnProps {
  customerSettings: Config;
}

interface TaskDragPreviewDragLayerProps {
  currentOffset: {x: number; y: number};
  isDragging: boolean;
  item: {
    createdByUserProfile: UserProfile | undefined;
    machineList: readonly Machine[];
    machineOperatorProfile: UserProfile | undefined;
    task: Task;
    workType: WorkType | undefined;
  };
  itemType: string;
}

type TaskDragPreviewProps = TaskDragPreviewDragLayerProps & TaskDragPreviewOwnProps;

class TaskDragPreview extends PureComponent<TaskDragPreviewProps> {
  render(): React.JSX.Element | null {
    const {
      currentOffset,
      // itemType,
      isDragging,
      item,
    } = this.props;
    if (!isDragging) {
      return null;
    }
    let style: React.CSSProperties | undefined;
    let content;
    if (!currentOffset) {
      style = {
        display: "none",
      };
    } else {
      const {y} = currentOffset;
      const x = 0;
      const transform = `translate(${x}px, ${y}px)`;
      style = {
        opacity: 0.8,
        pointerEvents: "none",
        transform,
        WebkitTransform: transform,
      };
      const {createdByUserProfile, machineList, machineOperatorProfile, task, workType} = item;
      const workTypeIdentifier = workType ? workType.identifier : null;
      const machines = machineList.map((m) => m.c5_machine).join(", ");
      const machineOperatorInitials = machineOperatorProfile ? machineOperatorProfile.alias : null;
      const notes = task.notesFromManager || task.notesFromMachineOperator;
      const duration = formatDuration(
        this.props.customerSettings.durationFormat,
        task.minutesExpectedTotalTaskDuration,
      );
      let vinColumn;
      if (this.props.customerSettings.workshopVehicleIdentificationNumber) {
        const vinValues = machineList
          .map((m) => m.vehicleIdentificationNumber)
          .filter((n) => n)
          .join(", ");
        vinColumn = (
          <TableCell
            style={{
              width: VEHICLE_IDENTIFICATION_NUMBER_COLUMN_WIDTH,
            }}
          >
            {vinValues}
          </TableCell>
        );
      }
      let createdByColumn;
      if (this.props.customerSettings.workshopCreatedByColumn) {
        const createdByInitials = createdByUserProfile ? createdByUserProfile.alias : null;
        createdByColumn = (
          <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}}>{createdByInitials}</TableCell>
        );
      }
      let createdDateColumn;
      if (this.props.customerSettings.workshopCreatedDateColumn) {
        createdDateColumn = (
          <TableCell style={{width: DATE_COLUMN_WIDTH}}>{formatDateShort(task.created)}</TableCell>
        );
      }
      content = (
        <Table style={{minWidth: TABLE_MIN_WIDTH}}>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  paddingRight: 0,
                  width: WORK_TYPE_COLUMN_WIDTH,
                }}
              >
                {workTypeIdentifier}
              </TableCell>
              <TableCell style={{width: MACHINES_COLUMN_WIDTH}}>{machines}</TableCell>
              {vinColumn}
              <TableCell>
                <Linkify>{notes}</Linkify>
              </TableCell>
              <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}}>
                {machineOperatorInitials}
              </TableCell>
              <TableCell style={{width: DURATION_COLUMN_WIDTH}}>{duration}</TableCell>
              {createdByColumn}
              {createdDateColumn}
              <TableCell
                style={{
                  width: STATUS_ICON_COLUMN_WIDTH,
                }}
              />
              <TableCell
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  textAlign: "center",
                  width: DRAG_HANDLE_CELL_WIDTH,
                }}
              >
                <SvgIcon>
                  <DragVerticalIcon />
                </SvgIcon>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
    return (
      <div style={layerStyles}>
        <div style={style}>{content}</div>
      </div>
    );
  }
}

const DragLayerTaskDragPreview = DragLayer<TaskDragPreviewOwnProps>((monitor) => ({
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
}))(TaskDragPreview) as any as React.ComponentType<TaskDragPreviewOwnProps>;

export {DragLayerTaskDragPreview as TaskDragPreview};
