import {PureComponent} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import _ from "lodash";
import React from "react";
import {TimeTrackerBlock} from "./time-tracker-block";
import {TaskWithRelations} from "./utils";

interface TimeTrackerProps {
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  completedTasks: readonly TaskWithRelations[];
  incompleteTasks: readonly TaskWithRelations[];
  left: number;
  now: string;
  singleDateFocus?: boolean | undefined;
  width: number;
}

export class TimeTracker extends PureComponent<TimeTrackerProps> {
  render(): React.JSX.Element {
    const {
      calendarFromTimestamp,
      calendarToTimestamp,
      completedTasks,
      incompleteTasks,
      left,
      now,
      singleDateFocus,
      width,
    } = this.props;
    const completedBlocks = _.flatMap(completedTasks, (taskWithRelations) => {
      const taskURL = taskWithRelations.task.url;
      return taskWithRelations.intervalsInPeriod.map((interval, index) => {
        const fromTimestamp = new ImmutableDate(interval.fromTimestamp);
        const toTimestamp = new ImmutableDate(interval.toTimestamp || now);
        const {timer} = interval;
        return (
          <TimeTrackerBlock
            calendarFromTimestamp={calendarFromTimestamp}
            calendarToTimestamp={calendarToTimestamp}
            completed
            fade={singleDateFocus && taskWithRelations.workStartedEarlier}
            fromTimestamp={fromTimestamp}
            key={taskURL + index}
            left={left}
            timer={timer}
            toTimestamp={toTimestamp}
            width={width}
          />
        );
      });
    });
    const incompleteBlocks = _.flatMap(incompleteTasks, (taskWithRelations) => {
      const taskURL = taskWithRelations.task.url;
      return taskWithRelations.intervalsInPeriod.map((interval, index) => {
        const fromTimestamp = new ImmutableDate(interval.fromTimestamp);
        const toTimestamp = new ImmutableDate(interval.toTimestamp || now);
        const {timer} = interval;
        return (
          <TimeTrackerBlock
            calendarFromTimestamp={calendarFromTimestamp}
            calendarToTimestamp={calendarToTimestamp}
            fade={singleDateFocus && taskWithRelations.workStartedEarlier}
            fromTimestamp={fromTimestamp}
            key={taskURL + index}
            left={left}
            timer={timer}
            toTimestamp={toTimestamp}
            width={width}
          />
        );
      });
    });
    return (
      <div>
        {completedBlocks}
        {incompleteBlocks}
      </div>
    );
  }
}
