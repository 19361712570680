import {WorkType} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {Chip} from "@material-ui/core";
import React, {useCallback} from "react";

export const WorkTypeChip = ({
  onDeleteClick,
  workType,
}: {
  onDeleteClick?: (workType: WorkType) => void;
  workType: WorkType;
}): React.JSX.Element => {
  const handleDelete = useCallback(() => {
    if (onDeleteClick) {
      onDeleteClick(workType);
    }
  }, [workType, onDeleteClick]);

  if (onDeleteClick) {
    return <Chip label={getWorkTypeString(workType)} onDelete={handleDelete} />;
  } else {
    return <Chip label={getWorkTypeString(workType)} />;
  }
};
