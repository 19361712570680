import {Product, ProductGroup, ProductGroupUrl} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator, makeMapFromArray} from "@co-common-libs/utils";
import {Paper} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import ImageIcon from "mdi-react/ImageIcon";
import React from "react";

const productGrouptileStyle: React.CSSProperties = {
  display: "inline-block",
  height: 218,
  marginRight: 8,
  textAlign: "center",
  verticalAlign: "bottom",
  width: 160,
};

const productImagePaperStyle: React.CSSProperties = {
  alignContent: "center",
  height: 160,
  width: 160,
};

const imageStyle: React.CSSProperties = {
  aspectRatio: "auto",
  maxHeight: 160,
  maxWidth: 160,
};

const iconWrapperStyle: React.CSSProperties = {
  padding: 68,
};

interface ProductGroupTileProps {
  onClick: (event: React.MouseEvent, url: ProductGroupUrl) => void;
  productGroup: ProductGroup;
  token: string | null;
}

class ProductGroupTile extends PureComponent<ProductGroupTileProps> {
  @bind
  handleClick(event: React.MouseEvent): void {
    const {onClick, productGroup} = this.props;
    const {url} = productGroup;
    onClick(event, url);
  }

  render(): React.JSX.Element {
    const {productGroup, token} = this.props;
    const {name} = productGroup;
    const photoURL = productGroup.photoUrl;
    const image =
      token && photoURL ? (
        <img alt="" src={`${photoURL}?token=${token}`} style={imageStyle} />
      ) : (
        <div style={iconWrapperStyle}>
          <ImageIcon />
        </div>
      );
    return (
      <div style={productGrouptileStyle}>
        <Paper onClick={this.handleClick} style={productImagePaperStyle}>
          {image}
        </Paper>
        {name}
      </div>
    );
  }
}

function isActive(productGroup: ProductGroup): boolean {
  return productGroup.active;
}

interface ProductGroupGridProps {
  onClick: (event: React.MouseEvent, url: ProductGroupUrl) => void;
  productGroupArray: readonly ProductGroup[];
  products: readonly Product[];
  selectedGroup?: string | undefined;
  token: string | null;
}

export class ProductGroupGrid extends PureComponent<ProductGroupGridProps> {
  @bind
  handleProductGroupTileClick(event: React.MouseEvent, url: ProductGroupUrl): void {
    this.props.onClick(event, url);
  }

  render(): React.JSX.Element | null {
    const {productGroupArray, products, selectedGroup, token} = this.props;

    const productByGroupUrl = makeMapFromArray(products, ({group}) => group);
    const productGroupList = productGroupArray
      .filter(
        (productGroup) =>
          isActive(productGroup) &&
          productByGroupUrl.has(productGroup.url) &&
          (selectedGroup ? productGroup.parent === selectedGroup : !productGroup.parent),
      )
      .sort((a, b) => caseAccentInsensitiveCollator.compare(a.name, b.name));

    if (!productGroupList.length) {
      return null;
    }
    const productGroupTileList = productGroupList.map((productGroup) => (
      <ProductGroupTile
        key={productGroup.url}
        onClick={this.handleProductGroupTileClick}
        productGroup={productGroup}
        token={token}
      />
    ));
    return <div>{productGroupTileList}</div>;
  }
}
