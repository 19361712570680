import {TableCell} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import ArrowDownIcon from "mdi-react/ArrowDownIcon";
import ArrowUpIcon from "mdi-react/ArrowUpIcon";
import React, {ReactNode} from "react";

export const SORT_DIRECTIONS = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export type SortDirection = "ASC" | "DESC";

const reverseSortDirection = (direction: SortDirection): SortDirection => {
  return direction === SORT_DIRECTIONS.ASC ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC;
};

interface SortTableHeaderColumnProps {
  children: ReactNode;
  columnKey?: string;
  onSortChange?: (columnKey: string, sortDirection: SortDirection) => void;
  sortDirection?: SortDirection | undefined;
  style?: React.CSSProperties;
}

export class SortTableHeaderColumn extends PureComponent<SortTableHeaderColumnProps, object> {
  @bind
  handleClick(): void {
    const {columnKey, onSortChange, sortDirection} = this.props;
    if (onSortChange) {
      const newDirection = sortDirection
        ? reverseSortDirection(sortDirection)
        : SORT_DIRECTIONS.ASC;
      onSortChange(columnKey as string, newDirection);
    }
  }
  render(): React.JSX.Element {
    const {children, sortDirection, style} = this.props;
    let combinedStyle;
    if (this.props.onSortChange) {
      combinedStyle = Object.assign({cursor: "pointer"}, style);
    } else {
      combinedStyle = style;
    }
    let icon;
    if (sortDirection === SORT_DIRECTIONS.ASC) {
      icon = <ArrowDownIcon size={12} />;
    } else if (sortDirection === SORT_DIRECTIONS.DESC) {
      icon = <ArrowUpIcon size={12} />;
    }
    return (
      <TableCell onClick={this.handleClick} style={combinedStyle || {}}>
        {children} {icon}
      </TableCell>
    );
  }
}
