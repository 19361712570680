import {emptyCustomer} from "@co-common-libs/resources";
import _ from "lodash";
import {customerFormMembers, CustomerFormPart} from "./types";

export function transformToCustomerFormPart(
  customer?: Partial<CustomerFormPart> | null,
): CustomerFormPart {
  return _.pick({...emptyCustomer, ...customer}, customerFormMembers) satisfies Omit<
    CustomerFormPart,
    "_exact"
  > as CustomerFormPart;
}
