import {Task, UserUrl, WorkType} from "@co-common-libs/resources";
import {
  MultipleWorkTypesDialog,
  MultipleWorkTypesDialogProps,
  WorkTypeDialog,
  WorkTypeDialogProps,
} from "@co-frontend-libs/components";
import {AppState, getCurrentUserURL, getTaskArray} from "@co-frontend-libs/redux";
import React from "react";
import {useSelector} from "react-redux";
import {getDisabledWorkTypes, getSuggestRecentlyUsedWorkTypes} from "./selectors";

interface ConnectedWorkTypeDialogProps
  extends Pick<WorkTypeDialogProps, "onCancel" | "onNone" | "onOk" | "open"> {
  currentUserUrlSelector?: (state: AppState) => UserUrl | null;
  disabledWorkTypesSelector?: (state: AppState) => readonly string[];
  suggestRecentlyUsedWorkTypesSelector?: (state: AppState) => boolean;
  taskArraySelector?: (state: AppState) => readonly Task[];
  workTypeArraySelector: (state: AppState) => readonly WorkType[];
}

export const ConnectedWorkTypeDialog = React.memo(function ConnectedWorkTypeDialog(
  props: ConnectedWorkTypeDialogProps,
): React.JSX.Element {
  const {
    currentUserUrlSelector = getCurrentUserURL,
    disabledWorkTypesSelector = getDisabledWorkTypes,
    suggestRecentlyUsedWorkTypesSelector = getSuggestRecentlyUsedWorkTypes,
    taskArraySelector = getTaskArray,
    workTypeArraySelector,
    ...other
  } = props;

  const currentUserURL = useSelector(currentUserUrlSelector);
  const disabledWorkTypes = useSelector(disabledWorkTypesSelector);
  const suggestRecentlyUsedWorkTypes = useSelector(suggestRecentlyUsedWorkTypesSelector);
  const taskArray = useSelector(taskArraySelector);
  const workTypeArray = useSelector(workTypeArraySelector);

  return (
    <WorkTypeDialog
      currentUserURL={currentUserURL}
      disabledWorkTypes={disabledWorkTypes}
      suggestRecentlyUsedWorkTypes={suggestRecentlyUsedWorkTypes}
      taskArray={taskArray}
      workTypeArray={workTypeArray}
      {...other}
    />
  );
});

interface ConnectedMultipleWorkTypesDialogProps
  extends Pick<MultipleWorkTypesDialogProps, "onCancel" | "onOk" | "open" | "selected"> {
  currentUserUrlSelector?: (state: AppState) => UserUrl | null;
  disabledWorkTypesSelector?: (state: AppState) => readonly string[];
  suggestRecentlyUsedWorkTypesSelector?: (state: AppState) => boolean;
  taskArraySelector?: (state: AppState) => readonly Task[];
  workTypeArraySelector: (state: AppState) => readonly WorkType[];
}

export const ConnectedMultipleWorkTypesDialog = React.memo(
  function ConnectedMultipleWorkTypesDialog(
    props: ConnectedMultipleWorkTypesDialogProps,
  ): React.JSX.Element {
    const {
      currentUserUrlSelector = getCurrentUserURL,
      disabledWorkTypesSelector = getDisabledWorkTypes,
      suggestRecentlyUsedWorkTypesSelector = getSuggestRecentlyUsedWorkTypes,
      taskArraySelector = getTaskArray,
      workTypeArraySelector,
      ...other
    } = props;

    const currentUserURL = useSelector(currentUserUrlSelector);
    const disabledWorkTypes = useSelector(disabledWorkTypesSelector);
    const suggestRecentlyUsedWorkTypes = useSelector(suggestRecentlyUsedWorkTypesSelector);
    const taskArray = useSelector(taskArraySelector);
    const workTypeArray = useSelector(workTypeArraySelector);

    return (
      <MultipleWorkTypesDialog
        currentUserURL={currentUserURL}
        disabledWorkTypes={disabledWorkTypes}
        suggestRecentlyUsedWorkTypes={suggestRecentlyUsedWorkTypes}
        taskArray={taskArray}
        workTypeArray={workTypeArray}
        {...other}
      />
    );
  },
);
