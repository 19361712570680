import {
  ContactUrl,
  CustomerUrl,
  LocationUrl,
  Order,
  Project,
  ProjectAccess,
  ProjectUrl,
} from "@co-common-libs/resources";
import {useModal} from "@co-frontend-libs/components";
import {actions, getProjectLookup, getTaskArray} from "@co-frontend-libs/redux";
import React, {RefObject, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ConfirmProjectChangeDialog} from "../../confirm-dialogs";
import {InformationBlockPropsBase} from "../information-block-props-base";
import {ConfirmDialogVisibility, ProjectBlock, UserConfirmations} from "../project-block";

interface OrderProjectBlockProps extends InformationBlockPropsBase {
  contactUrl: ContactUrl | null;
  customerUrl: CustomerUrl | null;
  defaultAccessOnCreate: ProjectAccess;
  onContactChanged: (contactUrl: ContactUrl | null) => void;
  onCustomerChanged: (customerUrl: CustomerUrl | null) => void;
  onLocationChanged: (locationUrl: LocationUrl | null) => void;
  onProjectChanged: (projectUrl: ProjectUrl | null) => void;
  order: Order;
  projectUrl: ProjectUrl | null;
  ref: RefObject<HTMLButtonElement>;
}

export const OrderProjectBlock = function (props: OrderProjectBlockProps): React.JSX.Element {
  const {
    customerUrl,
    defaultAccessOnCreate,
    onContactChanged,
    onCustomerChanged,
    onLocationChanged,
    onProjectChanged,
    order,
    projectUrl,
  } = props;

  const projectLookup = useSelector(getProjectLookup);
  const orderTaskArray = useSelector(getTaskArray).filter((task) => task.order === order.url);
  const dispatch = useDispatch();

  const [confirmProjectChangeDialog, promptConfirmProjectChangeDialog] = useModal(
    ConfirmProjectChangeDialog,
  );

  const handleDialogsConfirmed = useCallback(
    (userConfirmations: UserConfirmations, project: Project) => {
      if (userConfirmations.contactConfirmDialogResponse) {
        onContactChanged(project.contact);
      }
      if (userConfirmations.workplaceConfirmDialogResponse) {
        onLocationChanged(project.relatedWorkplace);
      }
    },
    [onContactChanged, onLocationChanged],
  );

  const computeConfirmDialogVisibility = useCallback(
    (selectedProjectUrl: ProjectUrl): ConfirmDialogVisibility => {
      const newProject = projectLookup(selectedProjectUrl);
      const showRelatedWorkplaceConfirmDialog =
        newProject?.relatedWorkplace !== order.relatedWorkplace && !!order.relatedWorkplace;

      const showContactConfirmDialog = newProject?.contact !== order.contact && !!order.contact;

      return {
        showContactConfirmDialog,
        showRelatedWorkplaceConfirmDialog,
      };
    },

    [order.contact, order.relatedWorkplace, projectLookup],
  );

  const handlePropagateProject = useCallback(
    async (newProjectUrl: ProjectUrl) => {
      const tasksProjectMayPropagateTo = orderTaskArray.filter(
        (task) => !task.validatedAndRecorded && task.project !== newProjectUrl,
      );
      if (tasksProjectMayPropagateTo.length > 0) {
        const ok = await promptConfirmProjectChangeDialog();
        if (ok) {
          tasksProjectMayPropagateTo.forEach((task) => {
            dispatch(actions.updateDiff({project: newProjectUrl}, task));
          });
        }
      }
    },
    [dispatch, orderTaskArray, promptConfirmProjectChangeDialog],
  );

  const handleProjectChanged = useCallback(
    (newProjectUrl: ProjectUrl | null) => {
      if (newProjectUrl) {
        onProjectChanged(newProjectUrl);
        const project = newProjectUrl && projectLookup(newProjectUrl);

        handlePropagateProject(newProjectUrl);

        if (!order.relatedWorkplace && project) {
          onLocationChanged(project.relatedWorkplace);
        }
        if (!order.contact && project) {
          onContactChanged(project.contact);
        }
      } else {
        onProjectChanged(newProjectUrl);
      }
    },
    [
      handlePropagateProject,
      onContactChanged,
      onLocationChanged,
      onProjectChanged,
      order.contact,
      order.relatedWorkplace,
      projectLookup,
    ],
  );

  return (
    <>
      {confirmProjectChangeDialog}

      <ProjectBlock
        computeConfirmDialogVisibility={computeConfirmDialogVisibility}
        customerUrl={customerUrl}
        defaultAccessOnCreate={defaultAccessOnCreate}
        onCustomerChanged={onCustomerChanged}
        onDialogsConfirmed={handleDialogsConfirmed}
        onProjectChanged={handleProjectChanged}
        projectUrl={projectUrl}
      />
    </>
  );
};
