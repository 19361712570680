import type {Writable} from "ts-essentials";
import {Task, WorkType, WorkTypeUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import _ from "lodash";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericMultiSelectionSearchDialogProps,
  GenericSingleSelectionSearchDialog,
  GenericSingleSelectionSearchDialogProps,
} from "../search-dialog";

export function computeBaseChoices(
  workTypeArray: readonly WorkType[],
  taskArray: readonly Task[] | undefined,
  currentUserURL: string | null,
  disabledWorkTypes: ReadonlySet<string>,
  suggestRecentlyUsedWorkTypes: boolean,
): readonly EntryData<WorkTypeUrl>[] {
  const dataEntries = new Map<string, Writable<EntryData<WorkTypeUrl>>>();
  workTypeArray.forEach((instance) => {
    if (!disabledWorkTypes.has(instance.identifier)) {
      const {color, url} = instance;
      const name = instance.name || "";
      const identifier = instance.identifier || "";
      const entry: Writable<EntryData<WorkTypeUrl>> = {
        category: "standard",
        color,
        exactMatchValue: identifier,
        identifier: url,
        primaryText: name,
        searchFields: [
          {label: "Nr", priority: 10, text: identifier},
          {label: "Navn", priority: 5, text: name},
        ],
        secondaryText: identifier,
      };
      dataEntries.set(url, entry);
    }
  });
  if (suggestRecentlyUsedWorkTypes && taskArray && currentUserURL) {
    const currentUserTasks = _.sortBy(
      taskArray.filter(
        (t) =>
          (t.createdBy === currentUserURL || t.machineOperator === currentUserURL) && t.workType,
      ),
      (t) => t.created || "X",
    ).reverse();
    const RECENTLY_USED_LIMIT = 6;
    let recentlyUsed = 0;
    for (const task of currentUserTasks) {
      // workType=null filtered out above
      const workType = task.workType as WorkTypeUrl;
      const entry = dataEntries.get(workType);
      if (entry) {
        entry.category = "recentlyUsed";
        if (task.created) {
          entry.recentlyUsedSortKey = new Date(task.created).valueOf();
        }
        recentlyUsed += 1;
        if (recentlyUsed >= RECENTLY_USED_LIMIT) {
          break;
        }
      }
    }
  }
  return Array.from(dataEntries.values());
}

export interface WorkTypeDialogProps
  extends Pick<
    GenericSingleSelectionSearchDialogProps<WorkTypeUrl>,
    "onCancel" | "onNone" | "onOk" | "open"
  > {
  currentUserURL: string | null;
  disabledWorkTypes: readonly string[];
  suggestRecentlyUsedWorkTypes: boolean;
  taskArray?: readonly Task[];
  workTypeArray: readonly WorkType[];
}

export const WorkTypeDialog = React.memo(function WorkTypeDialog(props: WorkTypeDialogProps) {
  const {
    currentUserURL,
    disabledWorkTypes,
    onCancel,
    onNone,
    onOk,
    open,
    suggestRecentlyUsedWorkTypes,
    taskArray,
    workTypeArray,
  } = props;
  const disabledWorkTypesSet = new Set(disabledWorkTypes);
  const intl = useIntl();
  const title = intl.formatMessage({defaultMessage: "Vælg arbejdsområde"});
  const searchTitle = intl.formatMessage({defaultMessage: "Søg arbejdsområde"});

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(
    workTypeArray,
    taskArray,
    currentUserURL,
    disabledWorkTypesSet,
    suggestRecentlyUsedWorkTypes,
  );
  return (
    <GenericSingleSelectionSearchDialog<WorkTypeUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onNone={onNone}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
    />
  );
});

export interface MultipleWorkTypesDialogProps
  extends Pick<
    GenericMultiSelectionSearchDialogProps<WorkTypeUrl>,
    "includeSelectAll" | "onCancel" | "onOk" | "open" | "selected"
  > {
  currentUserURL: string | null;
  disabledWorkTypes: readonly string[];
  suggestRecentlyUsedWorkTypes: boolean;
  taskArray?: readonly Task[];
  workTypeArray: readonly WorkType[];
}

export const MultipleWorkTypesDialog = React.memo(function MultipleWorkTypesDialog(
  props: MultipleWorkTypesDialogProps,
) {
  const {
    currentUserURL,
    disabledWorkTypes,
    includeSelectAll,
    onCancel,
    onOk,
    open,
    selected,
    suggestRecentlyUsedWorkTypes,
    taskArray,
    workTypeArray,
  } = props;
  const disabledWorkTypesSet = new Set(disabledWorkTypes);
  const intl = useIntl();
  const title = intl.formatMessage({defaultMessage: "Vælg arbejdsområder"});
  const searchTitle = intl.formatMessage({
    defaultMessage: "Søg arbejdsområder",
  });

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(
    workTypeArray,
    taskArray,
    currentUserURL,
    disabledWorkTypesSet,
    suggestRecentlyUsedWorkTypes,
  );

  return (
    <GenericMultiSelectionSearchDialog<WorkTypeUrl>
      data={data}
      includeSelectAll={includeSelectAll}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
    />
  );
});
