import {ExtendedConfig} from "extended-config";
import {CustomerFormMember} from "./types";

export function computeVisibleInputFields(
  offlineOnlyInputs: boolean,
  customerSettings: Pick<ExtendedConfig, "customers">,
): ReadonlySet<CustomerFormMember> {
  const {customers} = customerSettings;

  const inputs: Set<CustomerFormMember> = new Set([
    CustomerFormMember.ACTIVE,
    CustomerFormMember.ADDRESS,
    CustomerFormMember.BILLING_EMAIL,
    CustomerFormMember.CITY,
    CustomerFormMember.NAME,
    CustomerFormMember.PHONE,
    CustomerFormMember.POSTAL_CODE,
  ]);

  if (offlineOnlyInputs) {
    inputs.delete(CustomerFormMember.ACTIVE);
  }

  if (customers.canEditCustomerVATNumber) {
    inputs.add(CustomerFormMember.VAT_NUMBER);
  }

  if (
    !customers.liveSyncWithThirdParty ||
    (customers.liveSyncWithThirdParty && !customers.economicAutogeneratedCustomerNumbers)
  ) {
    inputs.add(CustomerFormMember.CUSTOMER_NUMBER);
  }
  return inputs;
}
