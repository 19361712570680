import {Task, Timer} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {useIntl} from "react-intl";
import {TimelineTable} from "./timeline-table";
import {IntervalWithTimer} from "./types";

interface TimelineModalProps {
  intervals: readonly {
    fromTimestamp: string;
    timer: Timer;
    toTimestamp: string;
  }[];
  onCancel: () => void;
  open: boolean;
  task: Task;
}

export const TimelineModal = React.memo(function TimelineModal(props: TimelineModalProps) {
  const {intervals, onCancel, open, task} = props;
  const intl = useIntl();

  const intervalWithTimers: IntervalWithTimer[] = intervals.filter((interval) => interval.timer);

  return (
    <ResponsiveDialog
      cancelLabel={intl.formatMessage({defaultMessage: "Luk vindue"})}
      onCancel={onCancel}
      open={open}
      title={intl.formatMessage({defaultMessage: "Tidslinje"})}
    >
      <DialogContent>
        <TimelineTable intervals={intervalWithTimers} showAllInTimelineTable task={task} />
      </DialogContent>
    </ResponsiveDialog>
  );
});
