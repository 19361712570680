import {Location} from "@co-common-libs/resources";
import {DebouncedAppbarSearchField, SpinnerDialog} from "@co-frontend-libs/components";
import {actions, getCustomerSettings} from "@co-frontend-libs/redux";
import {jsonFetch, ResponseWithData} from "@co-frontend-libs/utils";
import {Button} from "@material-ui/core";
import {LocationList, MenuToolbar, PageLayout} from "app-components";
import {useQueryParameterState} from "app-utils";
import bowser from "bowser";
import {globalConfig, theme} from "frontend-global-config";
import React, {useCallback, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ShowInactiveToggle} from "../shared/show-inactive-toggle";

interface LocationsSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

function refetchFields(abortController: AbortController | undefined): Promise<ResponseWithData> {
  const url = `${globalConfig.baseURL}/api/remote_fields_update/`;
  const data = {};
  return jsonFetch(url, "POST", data, abortController?.signal);
}

export const LocationsSettingsPage = React.memo(function LocationsSettingsPage(
  props: LocationsSettingsPageProps,
): React.JSX.Element {
  const dispatch = useDispatch();
  const customerSettings = useSelector(getCustomerSettings);
  const intl = useIntl();

  const [filter, setFilter] = useQueryParameterState<string>("q", "");
  const [showInactive, setShowInactive] = useState(false);
  const [refetching, setRefetching] = useState(false);

  const abortController = useRef<AbortController>();

  const handleRefetchResponse = useCallback(
    (response: ResponseWithData) => {
      setRefetching(false);
      const locations: Location[] = response.data;
      dispatch(actions.addToOffline(locations));
      abortController.current = undefined;
    },
    [dispatch],
  );

  const handleRefetchError = useCallback((error: Error) => {
    setRefetching(false);
    // eslint-disable-next-line no-console
    console.error(error);
    abortController.current = undefined;
  }, []);

  const handleRefetchFieldsButtonClick = useCallback(() => {
    if (window.AbortController) {
      if (abortController.current) {
        abortController.current.abort();
      }
      abortController.current = new AbortController();
    }
    const refetchFieldsPromise = refetchFields(abortController.current);
    setRefetching(true);
    return refetchFieldsPromise.then(handleRefetchResponse, handleRefetchError);
  }, [handleRefetchError, handleRefetchResponse]);

  return (
    <PageLayout
      dialogs={
        <SpinnerDialog
          key="refetching-fields-spinner"
          open={refetching}
          title={intl.formatMessage({
            defaultMessage: "Henter markdata for marker",
          })}
        />
      }
      toolbar={
        <MenuToolbar
          onMenuButton={props.onMenuButton}
          rightElement={
            <>
              {customerSettings.customerFields && !bowser.mobile ? (
                <Button
                  color="inherit"
                  disabled={refetching}
                  onClick={handleRefetchFieldsButtonClick}
                  style={{
                    marginLeft: theme.spacing(1),
                    marginRight: theme.spacing(1),
                  }}
                  variant="outlined"
                >
                  <FormattedMessage defaultMessage="Opdater alle marker" />
                </Button>
              ) : null}
              <ShowInactiveToggle setShowInactive={setShowInactive} showInactive={showInactive} />
              <DebouncedAppbarSearchField
                debounceTimeout={200}
                onChange={setFilter}
                value={filter}
              />
            </>
          }
          title={intl.formatMessage({defaultMessage: "Steder"})}
        />
      }
      withBottomScrollPadding
    >
      <LocationList showInactive={showInactive} />
    </PageLayout>
  );
});
