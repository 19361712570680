import {Location, LocationType, LocationUrl, ProductUrl} from "@co-common-libs/resources";
import {useMapContainer} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import ReactDOM from "react-dom";
import {VisibleLocationMarkers} from "../location-markers/visible-location-markers";

const LABEL_VISIBILITY_THRESHOLD = 12;
const AREA_AND_CUSTOMER_NAME_VISIBILITY_THRESHOLD = 14;
const CROP_VISIBILITY_THRESHOLD = 15;

interface LocationTypeMarkersWithoutMenuProps {
  filteredLocationArray: readonly Location[];
  googleMapsLinks: boolean;
  locationProductCounts?: ReadonlyMap<LocationUrl, ReadonlyMap<ProductUrl, number>> | undefined;
  locationType: LocationType;
  map: google.maps.Map;
  showAsActive?: boolean | undefined;
}

export function LocationTypeMarkersWithoutMenu(
  props: LocationTypeMarkersWithoutMenuProps,
): React.JSX.Element {
  const {
    filteredLocationArray,
    googleMapsLinks,
    locationProductCounts,
    locationType,
    map,
    showAsActive,
  } = props;
  const locationTypeURL = locationType.url;
  const typeFilteredLocationArray = useMemo(() => {
    return filteredLocationArray.filter((location) => location.locationType === locationTypeURL);
  }, [filteredLocationArray, locationTypeURL]);

  const [containerElement, overlayView] = useMapContainer(map);

  const zoomLevel = map.getZoom();
  if (containerElement && overlayView && zoomLevel) {
    const mapCanvasProjection = overlayView.getProjection();
    const bounds = map.getBounds() as google.maps.LatLngBounds;

    const labelsVisible = zoomLevel > LABEL_VISIBILITY_THRESHOLD;
    const fieldHaAndCustomerNameVisible = zoomLevel > AREA_AND_CUSTOMER_NAME_VISIBILITY_THRESHOLD;
    const cropVisible = zoomLevel > CROP_VISIBILITY_THRESHOLD;

    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        <VisibleLocationMarkers
          bounds={bounds}
          cropVisible={cropVisible}
          fieldHaAndCustomerNameVisible={fieldHaAndCustomerNameVisible}
          labelsVisible={labelsVisible}
          locationProductCounts={locationProductCounts}
          locations={typeFilteredLocationArray}
          mapCanvasProjection={mapCanvasProjection}
          onClickOrGoogleMapsUrl={googleMapsLinks ? "GOOGLE_MAPS" : undefined}
          showAsActive={!!showAsActive}
        />
      </>,
      containerElement,
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
}
