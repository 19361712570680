import {ImportPreviewWorkType} from "@co-common-libs/resources-utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {GenericMultiSelectionSearchDialog} from "../../../search-dialog";
import {computePreviewWorkTypeBaseChoices} from "./compute-preview-work-type-base-choices";

interface WorkTypesPreviewSelectionDialogProps {
  error: string | undefined;
  includeSelectAll?: boolean;
  onCancel(): void;
  onOk(identifiers: ReadonlySet<string>): void;
  open: boolean;
  previewWorkTypes: readonly ImportPreviewWorkType[] | null;
}

export const WorkTypesPreviewSelectionDialog = React.memo(function WorkTypesPreviewSelectionDialog(
  props: WorkTypesPreviewSelectionDialogProps,
) {
  const {error, includeSelectAll, onCancel, onOk, open, previewWorkTypes} = props;
  const intl = useIntl();

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computePreviewWorkTypeBaseChoices, []),
    [],
  );

  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = previewWorkTypes ? getBaseChoices(previewWorkTypes) : null;

  return (
    <GenericMultiSelectionSearchDialog<string>
      data={data}
      error={error}
      includeSelectAll={includeSelectAll}
      listEmptyMessage={intl.formatMessage({
        defaultMessage: "Alle områder er allerede importeret til CustomOffice",
      })}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={intl.formatMessage({defaultMessage: "Søg arbejdsområder"})}
      sorting="SECONDARY_TEXT"
      title={intl.formatMessage({
        defaultMessage: "Importer arbejdsområder",
      })}
    />
  );
});
