import {Contact, ContactUrl, CustomerUrl, emptyContact} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions, getExtendedCustomerSettings} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {simpleObjectReducer} from "app-utils";
import {instanceURL} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useReducer} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {createFormContactValidation, CustomerContactForm} from "./customer-contact-form";

interface CreateCustomerContactDialogProps {
  contactTemplate: typeof emptyContact;
  customerUrl: CustomerUrl;
  fullscreen?: boolean;
  onCancel: () => void;
  onOk: (url: ContactUrl) => void;
  open: boolean;
}

export const CreateCustomerContactDialog = React.memo(function CreateCustomerContactDialog(
  props: CreateCustomerContactDialogProps,
): React.JSX.Element {
  const {contactTemplate, customerUrl, onOk, open, ...other} = props;
  const [contact, reducerDispatch] = useReducer(
    simpleObjectReducer<typeof emptyContact>,
    contactTemplate,
  );

  const reduxDispatch = useDispatch();
  const handleSaveClick = useCallback(() => {
    const id = uuid();
    const url = instanceURL("contact", id);
    const newContact: Contact = {
      ...contact,
      customer: customerUrl,
      id,
      url,
    };
    reduxDispatch(actions.create(newContact));
    onOk(url);
  }, [reduxDispatch, onOk, contact, customerUrl]);

  useEffect(() => {
    if (open) {
      reducerDispatch({type: "reset", value: contactTemplate});
    }
  }, [open, reducerDispatch, contactTemplate]);

  const intl = useIntl();
  const formContactSchema = useMemo(() => createFormContactValidation(intl), [intl]);

  const validationResult = formContactSchema.safeParse(contact);
  const errors = validationResult.success ? null : validationResult.error;

  const {
    contacts: {contactsMayHaveCellphone, contactsMayHaveFax},
  } = useSelector(getExtendedCustomerSettings);

  return (
    <ResponsiveDialog
      {...other}
      cancelLabel={intl.formatMessage({defaultMessage: "Annuller"})}
      okDisabled={!validationResult.success}
      okLabel={intl.formatMessage({defaultMessage: "Opret"})}
      onOk={handleSaveClick}
      open={open}
      title={intl.formatMessage({defaultMessage: "Opret kontakt"})}
    >
      <DialogContent>
        <CustomerContactForm
          contact={contact}
          contactsMayHaveCellphone={contactsMayHaveCellphone}
          contactsMayHaveFax={contactsMayHaveFax}
          dispatch={reducerDispatch}
          validationError={errors}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
