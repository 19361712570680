import {Config} from "@co-common-libs/config";
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import {defineMessages, FormattedMessage, FormattedNumber, useIntl} from "react-intl";

const messages = defineMessages({
  total: {
    defaultMessage: "Total",
    id: "transportlog-totals-card.card-title.total",
  },
});

const LogRow = ({
  customerSettings,
  delivery,
  difference,
  pickup,
  unit,
}: {
  customerSettings: Config;
  delivery: number;
  difference: number;
  pickup: number;
  unit: string;
}): React.JSX.Element => {
  const decimalPlaces = customerSettings.transportLogDecimals;
  return (
    <TableRow>
      <TableCell>{unit}</TableCell>
      <TableCell>
        <FormattedNumber maximumFractionDigits={decimalPlaces} value={pickup} />
      </TableCell>
      <TableCell>
        <FormattedNumber maximumFractionDigits={decimalPlaces} value={delivery} />
      </TableCell>
      <TableCell>
        <FormattedNumber maximumFractionDigits={decimalPlaces} value={difference} />
      </TableCell>
    </TableRow>
  );
};

export const TransportLogTotalsCard = ({
  customerSettings,
  orderTotals,
  taskTotals,
}: {
  customerSettings: Config;
  orderTotals: ReadonlyMap<
    string,
    {
      readonly delivery: number;
      readonly difference: number;
      readonly pickup: number;
      readonly unit: string;
    }
  >;
  taskTotals: ReadonlyMap<
    string,
    {
      readonly delivery: number;
      readonly difference: number;
      readonly pickup: number;
      readonly unit: string;
    }
  >;
}): React.JSX.Element => {
  const {formatMessage} = useIntl();

  const taskEntries = _.sortBy(Array.from(taskTotals.entries()), ([key, _data]) => key);
  const taskRows = taskEntries.map(([_key, total]) => (
    <LogRow
      customerSettings={customerSettings}
      delivery={total.delivery}
      difference={total.difference}
      key={`task-${total.unit}`}
      pickup={total.pickup}
      unit={total.unit}
    />
  ));

  const orderEntries = _.sortBy(Array.from(orderTotals.entries()), ([key, _data]) => key);
  const orderRows = orderEntries.map(([_key, total]) => (
    <LogRow
      customerSettings={customerSettings}
      delivery={total.delivery}
      difference={total.difference}
      key={`order-${total.unit}`}
      pickup={total.pickup}
      unit={total.unit}
    />
  ));

  return (
    <Card style={{margin: "1em"}}>
      <CardHeader title={formatMessage(messages.total)} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                defaultMessage="Enhed"
                id="transportlog-totals-card.table-header.Unit"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Afhentet"
                id="transportlog-totals-card.table-header.picked-up"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Leveret"
                id="transportlog-totals-card.table-header.delivered"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="På vej"
                id="transportlog-totals-card.table-header.onroad"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={4}>
              <strong>
                <FormattedMessage
                  defaultMessage="Min opgave"
                  id="transportlog-totals-card.table-row.task"
                />
              </strong>
            </TableCell>
          </TableRow>
          {taskRows}
          <TableRow>
            <TableCell colSpan={4}>
              <strong>
                <FormattedMessage
                  defaultMessage="Samlet ordrer"
                  id="transportlog-totals-card.table-row.order"
                />
              </strong>
            </TableCell>
          </TableRow>
          {orderRows}
        </TableBody>
      </Table>
    </Card>
  );
};
