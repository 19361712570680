import {CustomerUrl} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {ConnectedMultipleCustomersDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getCustomerArray,
  getCustomerLookup,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

export function SelectCustomers(props: SettingViewProps): React.JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const selectedCustomers: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);
  const customerArray = useSelector(getCustomerArray);
  const customerLookup = useSelector(getCustomerLookup);
  const customerInstances = useMemo(() => {
    return selectedCustomers
      .map((c5Account) => customerArray.find((w) => w.c5_account === c5Account))
      .filter(notUndefined);
  }, [selectedCustomers, customerArray]);

  const customerLabels = useMemo(() => {
    const labels = customerInstances.map((customer) => `${customer.c5_account}: ${customer.name}`);
    labels.sort();
    return labels;
  }, [customerInstances]);

  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const setCustomerDialogOpenTrue = useCallWithTrue(setCustomerDialogOpen);
  const setCustomerDialogOpenFalse = useCallWithFalse(setCustomerDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleCustomerDialogOk = useCallback(
    (urls: ReadonlySet<CustomerUrl>) => {
      setCustomerDialogOpen(false);

      const newValue = [...urls].map((url) => customerLookup(url)?.c5_account);

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry, customerLookup],
  );

  return (
    <>
      <DisplaySelected
        currentValue={customerLabels}
        onClick={setCustomerDialogOpenTrue}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
      />
      <ConnectedMultipleCustomersDialog
        onCancel={setCustomerDialogOpenFalse}
        onOk={handleCustomerDialogOk}
        open={customerDialogOpen}
        selected={new Set(customerInstances.map((customer) => customer?.url))}
      />
    </>
  );
}
