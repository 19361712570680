const BASE_DIR_NAME = "www";

function getDataDirectoryEntry(): Promise<DirectoryEntry> {
  return new Promise<Entry>((resolve, reject) => {
    window.resolveLocalFileSystemURL(cordova.file.dataDirectory, resolve, reject);
  }) as Promise<DirectoryEntry>;
}

function getBaseDirEntry(): Promise<DirectoryEntry> {
  return getDataDirectoryEntry().then((dataDirectory) => {
    return new Promise<DirectoryEntry>((resolve, reject) => {
      dataDirectory.getDirectory(BASE_DIR_NAME, {create: false}, resolve, reject);
    });
  });
}

function listDir(dirEntry: DirectoryEntry): Promise<Entry[]> {
  const directoryReader = dirEntry.createReader();
  return new Promise<Entry[]>((resolve, reject) => {
    const entries: Entry[] = [];
    function readEntries(): void {
      directoryReader.readEntries(function (results) {
        if (!results.length) {
          resolve(entries);
        } else {
          entries.push(...results);
          readEntries();
        }
      }, reject);
    }
    readEntries();
  });
}

function deleteDir(dirEntry: DirectoryEntry): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    dirEntry.removeRecursively(resolve, reject);
  });
}

export async function clearAppJS(): Promise<void> {
  // eslint-disable-next-line no-console
  console.log("deleting app JavaScript");
  console.assert(window.cordova);
  // get rid of startedVersion, failedVersion keys...
  localStorage.clear();
  const baseDirectory = await getBaseDirEntry();
  const entryArray = await listDir(baseDirectory);
  // eslint-disable-next-line no-console
  console.log(`entries in dowloaded versions dir: ${JSON.stringify(entryArray)}`);
  const dirEntryArray = entryArray.filter((entry) => entry.isDirectory) as DirectoryEntry[];
  // eslint-disable-next-line no-console
  console.log(`directory entries in dowloaded versions dir: ${JSON.stringify(dirEntryArray)}`);
  await Promise.all(dirEntryArray.map(deleteDir));
  return;
}
