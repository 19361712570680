import {
  CalculatorSpecification,
  Product,
  ProductUseWithOrder,
  Unit,
} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {
  BUTTON_COLUMN_STYLE,
  CONVERSION_UNIT_COLUMN_WIDTH,
  COUNT_COLUMN_WIDTH,
  TOGGLE_COLUMN_WIDTH,
  UNIT_COLUMN_WIDTH,
} from "./constants";
import {DisplayTableRowWithEntryCallbacks} from "./display-table-row";

interface DisplayTableProps {
  conversionRelatedValues: {
    readonly [unit: string]: number | null;
  } | null;
  onCountChange: (identifier: string, value: number | null) => void;
  onDeleteClick: ((identifier: string) => void) | null;
  onNotesChange: ((identifier: string, value: string) => void) | null;
  onOursChange: (identifier: string, value: boolean) => void;
  onSwitchProductClick: ((identifier: string) => void) | null;
  showIconColumn: boolean;
  showNotes: boolean;
  showRelationConversionColumns: boolean;
  sortedProductUses: readonly {
    readonly autoProduct: boolean;
    readonly calculator: CalculatorSpecification | null;
    readonly conversionUnit: Unit | null;
    readonly editable: boolean;
    readonly identifier: string;
    readonly notesEditable: boolean;
    readonly product: Product;
    readonly productUse: ProductUseWithOrder;
    readonly removable: boolean;
    readonly switchable: boolean;
    readonly unit: Unit | null;
  }[];
}

export const DisplayTable = React.memo(function DisplayTable(
  props: DisplayTableProps,
): React.JSX.Element {
  const {
    conversionRelatedValues,
    onCountChange,
    onNotesChange,
    onOursChange,
    onSwitchProductClick,
    showIconColumn,
    showNotes,
    showRelationConversionColumns,
    sortedProductUses,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);
  const onDeleteClick =
    !customerSettings.noProducts && customerSettings.enableAddProducts ? props.onDeleteClick : null;

  const rows = sortedProductUses.map(
    ({
      autoProduct,
      calculator,
      conversionUnit,
      editable,
      identifier,
      notesEditable,
      product,
      productUse,
      removable,
      switchable,
      unit,
    }) => (
      <DisplayTableRowWithEntryCallbacks
        autoProduct={autoProduct}
        calculator={calculator}
        conversionRelatedValues={conversionRelatedValues}
        conversionUnit={conversionUnit}
        editable={editable}
        identifier={identifier}
        key={identifier}
        notesEditable={notesEditable}
        onCountChange={onCountChange}
        onDeleteClick={onDeleteClick}
        onDetailButtonClick={null}
        onNotesChange={onNotesChange}
        onOursChange={onOursChange}
        onSwitchProduct={null}
        onSwitchProductClick={onSwitchProductClick}
        product={product}
        productUse={productUse}
        removable={removable}
        showIconColumn={showIconColumn}
        showNotes={showNotes}
        showRelationConversionColumns={showRelationConversionColumns}
        switchable={switchable}
        unit={unit}
      />
    ),
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage defaultMessage="Produkt" />
          </TableCell>
          <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
            <FormattedMessage defaultMessage="Antal" />
          </TableCell>
          {showIconColumn ? <TableCell style={BUTTON_COLUMN_STYLE} /> : null}
          <TableCell style={{width: UNIT_COLUMN_WIDTH}}>
            <FormattedMessage defaultMessage="Enhed" />
          </TableCell>
          {showRelationConversionColumns ? (
            <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Antal" />
            </TableCell>
          ) : null}
          {showRelationConversionColumns ? (
            <TableCell style={{width: CONVERSION_UNIT_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Enhed" />
            </TableCell>
          ) : null}
          {customerSettings.showOursToggle ? (
            <TableCell style={{width: TOGGLE_COLUMN_WIDTH}} />
          ) : null}
          {showNotes ? (
            <TableCell>
              <FormattedMessage defaultMessage="Noter" />
            </TableCell>
          ) : null}
          {onSwitchProductClick || onDeleteClick ? <TableCell style={BUTTON_COLUMN_STYLE} /> : null}
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
});
