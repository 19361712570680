import React from "react";
import {
  ConnectedMultipleWorkTypesDialog,
  ConnectedWorkTypeDialog,
} from "./connected-work-type-dialog";
import {
  alwaysFalse,
  getActiveExternalTaskPrimaryWorkTypeWithNonTimePriceItemArray,
  getFilteredActiveExternalTaskPrimaryWorkTypeArray,
  getSuggestRecentlyUsedWorkTypes,
} from "./selectors";
import {MultipleWorkTypesDialogProps, WorkTypeDialogProps} from "./types";

interface ConnectedExternalWorkTypeDialogProps extends WorkTypeDialogProps {
  disableSuggestions?: boolean;
}

export const ConnectedExternalWorkTypeDialog = React.memo(function ConnectedExternalWorkTypeDialog(
  props: ConnectedExternalWorkTypeDialogProps,
): React.JSX.Element {
  const {disableSuggestions, ...other} = props;
  return (
    <ConnectedWorkTypeDialog
      suggestRecentlyUsedWorkTypesSelector={
        disableSuggestions ? alwaysFalse : getSuggestRecentlyUsedWorkTypes
      }
      workTypeArraySelector={getFilteredActiveExternalTaskPrimaryWorkTypeArray}
      {...other}
    />
  );
});

export const ConnectedExternalWorkTypeWithNonTimePriceItemDialog = React.memo(
  function ConnectedExternalWorkTypeWithNonTimePriceItemDialog(
    props: WorkTypeDialogProps,
  ): React.JSX.Element {
    return (
      <ConnectedWorkTypeDialog
        suggestRecentlyUsedWorkTypesSelector={alwaysFalse}
        workTypeArraySelector={getActiveExternalTaskPrimaryWorkTypeWithNonTimePriceItemArray}
        {...props}
      />
    );
  },
);

export const ConnectedMultipleExternalWorkTypesDialog = React.memo(
  function ConnectedMultipleExternalWorkTypesDialog(
    props: MultipleWorkTypesDialogProps,
  ): React.JSX.Element {
    return (
      <ConnectedMultipleWorkTypesDialog
        workTypeArraySelector={getFilteredActiveExternalTaskPrimaryWorkTypeArray}
        {...props}
      />
    );
  },
);
