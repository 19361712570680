import {Task} from "@co-common-libs/resources";
import {formatDuration, priceItemIsTime} from "@co-common-libs/resources-utils";
import {MINUTE_MILLISECONDS, notUndefined, sortByOrderMember} from "@co-common-libs/utils";
import {
  getCustomerSettings,
  getPriceGroupLookup,
  getPriceItemLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import _ from "lodash";
import React, {CSSProperties} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {IntervalWithTimer} from "./types";

interface IntervalRowProps {
  interval: IntervalWithTimer;
  task: Task;
}

const boldTextStyle: CSSProperties = {fontWeight: "bold"};

function IntervalRow(props: IntervalRowProps): React.JSX.Element {
  const {interval, task} = props;
  const {fromTimestamp, timer, toTimestamp} = interval;
  const timerURL = timer.url;
  const timerPriceGroupUrl = timer.priceGroup;
  const customerSettings = useSelector(getCustomerSettings);
  const fromDate = new Date(fromTimestamp);
  const toDate = new Date(toTimestamp);
  const totalMinutes = Math.round((toDate.valueOf() - fromDate.valueOf()) / MINUTE_MILLISECONDS);
  const intl = useIntl();

  const minutesParts: number[] = [];

  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const timerPriceGroup = timerPriceGroupUrl ? priceGroupLookup(timerPriceGroupUrl) : undefined;

  if (timerPriceGroup?.timeAfterMinutesEffect === "per_interval") {
    const timePriceItemsWithTimer = sortByOrderMember(Object.values(task.priceItemUses || {}))
      .filter((priceItemUse) => priceItemUse.timer === timerURL)
      .map((priceItemUse) => priceItemLookup(priceItemUse.priceItem))
      .filter(notUndefined)
      .filter((priceItem) => priceItemIsTime(unitLookup, priceItem));

    if (timePriceItemsWithTimer.some((priceItem) => priceItem.timeAfterMinutes)) {
      const thresholds = new Set(
        timePriceItemsWithTimer.map((priceItem) => priceItem.timeAfterMinutes || 0),
      );
      // ensure an entry for the "free" part on "only billed after X" setup
      thresholds.add(0);
      // we process the largest threshold first
      const sortedThresholds = _.reverse(_.sortBy(Array.from(thresholds)));
      let remainingMinutes = totalMinutes;
      sortedThresholds.forEach((thresholdMinutes) => {
        const overThreshold = Math.max(remainingMinutes - thresholdMinutes, 0);
        // output array is build last-to-first
        minutesParts.unshift(overThreshold);
        remainingMinutes = Math.min(remainingMinutes, thresholdMinutes);
      });
    }
  }

  return (
    <TableRow>
      <TableCell style={boldTextStyle}>{timer.label}:</TableCell>
      <TableCell style={boldTextStyle}>
        <FormattedMessage
          defaultMessage="{fromDate, date, ::HHmm} - {toDate, date, ::HHmm}"
          values={{fromDate, toDate}}
        />
      </TableCell>
      <TableCell>
        <FormattedMessage
          defaultMessage="tid: {formattedTime} {totalMinutes, plural, =0 {(0 min)} one {(1 min)} other {(# min)}}"
          values={{
            formattedTime: formatDuration(customerSettings.durationFormat, totalMinutes),
            totalMinutes,
          }}
        />
      </TableCell>
    </TableRow>
  );
}

interface TimelineTableProps {
  intervals: readonly IntervalWithTimer[];
  showAllInTimelineTable?: boolean;
  task: Task;
}

export function TimelineTable(props: TimelineTableProps): React.JSX.Element | null {
  const {intervals, showAllInTimelineTable, task} = props;

  const timelineTableIntervals = showAllInTimelineTable
    ? intervals
    : intervals.filter((interval) => interval.timer.showInTimelineTable);

  return (
    <Table padding="normal" size="small">
      <TableBody>
        {timelineTableIntervals.map((interval) => (
          <IntervalRow interval={interval} key={interval.fromTimestamp} task={task} />
        ))}
      </TableBody>
    </Table>
  );
}
