import {Unit, UnitUrl} from "@co-common-libs/resources";
import {ConnectedMultipleUnitDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
  getUnitArray,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

export function SelectUnits(props: SettingViewProps): React.JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const selectedUnits: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);
  const unitArray = useSelector(getUnitArray);
  const unitLookup = useSelector(getUnitLookup);
  const unitInstances = useMemo(() => {
    return selectedUnits
      .map((lowcaseUnit) => unitArray.find((w) => w.name.toLowerCase() === lowcaseUnit))
      .filter(Boolean);
  }, [selectedUnits, unitArray]) as Unit[];

  const unitNames = useMemo(() => {
    const names = unitInstances.map((unit) => unit.symbol || unit.name);
    names.sort();
    return names;
  }, [unitInstances]);

  const [unitDialogOpen, setUnitDialogOpen] = useState(false);
  const setUnitDialogOpenTrue = useCallWithTrue(setUnitDialogOpen);
  const setUnitDialogOpenFalse = useCallWithFalse(setUnitDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleUnitDialogOk = useCallback(
    (urls: ReadonlySet<UnitUrl>) => {
      setUnitDialogOpen(false);

      const newValue = [...urls].map((url) => unitLookup(url)?.name.toLowerCase());

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry, unitLookup],
  );

  return (
    <>
      <DisplaySelected
        currentValue={unitNames}
        onClick={setUnitDialogOpenTrue}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
      />
      <ConnectedMultipleUnitDialog
        onCancel={setUnitDialogOpenFalse}
        onOk={handleUnitDialogOk}
        open={unitDialogOpen}
        selected={new Set(unitInstances.map((unit) => unit?.url))}
      />
    </>
  );
}
