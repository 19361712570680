import {TextField} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {ImportColumnConfigurationWithoutUpdateExisting} from "../types";

export function StaticValueTextField(props: {
  columnName: string;
  disabled: boolean;
  onImportColumnConfigurationChange: (
    columnName: string,
    configurationChange: ImportColumnConfigurationWithoutUpdateExisting,
  ) => void;
  staticValue: string | undefined;
}): React.JSX.Element {
  const {columnName, disabled, onImportColumnConfigurationChange, staticValue} = props;

  const intl = useIntl();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {value} = event.target;
      onImportColumnConfigurationChange(columnName, {
        value,
        valueType: "staticValue",
      });
    },
    [onImportColumnConfigurationChange, columnName],
  );

  return (
    <TextField
      disabled={disabled}
      label={intl.formatMessage({
        defaultMessage: "Fast værdi",
      })}
      margin="dense"
      onChange={handleChange}
      value={staticValue || ""}
      variant="outlined"
    />
  );
}
