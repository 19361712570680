import {ImportPreviewAccount} from "@co-common-libs/resources-utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {EntryData, GenericSingleSelectionSearchDialog} from "../../search-dialog";

function computeBaseChoices(
  previewAccounts: readonly ImportPreviewAccount[],
): readonly EntryData<string>[] {
  return previewAccounts.map((instance) => {
    const {identifier, name} = instance;
    return {
      category: "standard",
      exactMatchValue: identifier,
      identifier,
      primaryText: name,
      searchFields: [
        {label: "Nr", priority: 10, text: identifier},
        {label: "Navn", priority: 5, text: name},
      ],
      secondaryText: identifier,
    };
  });
}

interface ImportAccountPreviewDialogProps {
  error: string | undefined;
  onCancel(): void;
  onOk(identifier: string): void;
  open: boolean;
  previewAccounts: readonly ImportPreviewAccount[] | null;
}

export const ImportAccountPreviewDialog = React.memo(function ImportAccountPreviewDialog(
  props: ImportAccountPreviewDialogProps,
) {
  const {error, onCancel, onOk, open, previewAccounts} = props;
  const intl = useIntl();

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );

  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = previewAccounts ? getBaseChoices(previewAccounts) : null;

  return (
    <GenericSingleSelectionSearchDialog<string>
      data={data}
      error={error}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={intl.formatMessage({defaultMessage: "Søg konti"})}
      sorting="SECONDARY_TEXT"
      title={intl.formatMessage({
        defaultMessage: "Vælg konto",
      })}
    />
  );
});
