import {
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationUrl,
  urlToId,
} from "@co-common-libs/resources";
import {dateFromString} from "@co-common-libs/utils";
import {createHref, getPricePercentFuelSurchargeSpecificationLookup} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader, IconButton, useTheme} from "@material-ui/core";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface PricePercentFuelSurchargeIssuesLineProps {
  fromDate: string;
  specification: PricePercentFuelSurchargeSpecification | undefined;
  specificationUrl: string;
  toDate: string;
}

const PricePercentFuelSurchargeIssuesLine = React.memo(function PricePercentFuelSurchargeIssuesLine(
  props: PricePercentFuelSurchargeIssuesLineProps,
): React.JSX.Element {
  const {fromDate, specification, specificationUrl, toDate} = props;

  const intl = useIntl();
  const theme = useTheme();

  const name = specification
    ? specification.name
    : intl.formatMessage({defaultMessage: "Ukendt brændstoftillæg"});

  const href = createHref("/settings/pricePercentFuelSurcharge/:id", {
    id: urlToId(specificationUrl),
  });

  return (
    <div style={{color: theme.palette.error.main}}>
      <FormattedMessage
        defaultMessage="{name} mangler pris for opgaver i perioden fra {fromDate, date, ::MMdd} til {toDate, date, ::MMdd}"
        values={{
          fromDate: dateFromString(fromDate),
          name,
          toDate: dateFromString(toDate),
        }}
      />
      <IconButton color="primary" href={href}>
        <PencilIcon />
      </IconButton>
    </div>
  );
});

interface PricePercentFuelSurchargeIssuesCardProps {
  specificationProblemDates: ReadonlyMap<
    PricePercentFuelSurchargeSpecificationUrl,
    ReadonlySet<string>
  >;
}

export const PricePercentFuelSurchargeIssuesCard = React.memo(
  function PricePercentFuelSurchargeIssuesCard(
    props: PricePercentFuelSurchargeIssuesCardProps,
  ): React.JSX.Element {
    const {specificationProblemDates} = props;
    const intl = useIntl();

    const pricePercentFuelSurchargeSpecificationLookup = useSelector(
      getPricePercentFuelSurchargeSpecificationLookup,
    );

    const entries = useMemo(() => {
      const unsortedEntries = Array.from(specificationProblemDates).map(
        ([specificationUrl, dates]) => {
          const sortedDates = Array.from(dates).sort();
          return {
            fromDate: sortedDates[0],
            specification: pricePercentFuelSurchargeSpecificationLookup(specificationUrl),
            specificationUrl,
            toDate: sortedDates[sortedDates.length - 1],
          };
        },
      );
      return _.sortBy(unsortedEntries, [
        ({specification}) => specification?.name || "",
        ({fromDate}) => fromDate,
        ({toDate}) => toDate,
        ({specificationUrl}) => specificationUrl,
      ]);
    }, [pricePercentFuelSurchargeSpecificationLookup, specificationProblemDates]);

    const lines = entries.map(({fromDate, specification, specificationUrl, toDate}) => (
      <PricePercentFuelSurchargeIssuesLine
        fromDate={fromDate}
        key={specificationUrl}
        specification={specification}
        specificationUrl={specificationUrl}
        toDate={toDate}
      />
    ));

    return (
      <Card style={{marginTop: 10}}>
        <CardHeader title={intl.formatMessage({defaultMessage: "Brændstoftillæg"})} />
        <CardContent>{lines}</CardContent>
      </Card>
    );
  },
);
