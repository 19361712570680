import {
  CustomerUrl,
  emptyOrder,
  Order,
  OrderUrl,
  TaskUrl,
  urlToId,
} from "@co-common-libs/resources";
import {DeleteDialog} from "@co-frontend-libs/components";
import {ConnectedOrderDialog} from "@co-frontend-libs/connected-components";
import {actions, getCurrentUserURL, getOrderLookup, getTaskArray} from "@co-frontend-libs/redux";
import {IconButton} from "@material-ui/core";
import {CustomerSelectCreateDialog} from "app-components";
import {useFalseCallback, useTrueCallback} from "app-utils";
import {instanceURL} from "frontend-global-config";
import DirectionsForkIcon from "mdi-react/DirectionsForkIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

interface MoveOrderProps {
  customerURL: CustomerUrl | null;
  orderURL: OrderUrl;
  taskURL: TaskUrl;
}

export const MoveTask = React.memo(function MoveOrder(props: MoveOrderProps): React.JSX.Element {
  const {customerURL, orderURL, taskURL} = props;
  const [orderDialogOpen, setOrderDialogOpen] = useState(false);
  const [deleteDialogOpenedFor, setDeleteDialogOpenedFor] = useState<OrderUrl | null>(null);
  const dispatch = useDispatch();
  const taskArray = useSelector(getTaskArray);
  const orderLookup = useSelector(getOrderLookup);
  const handleOrderDialogOk = useCallback(
    (url: OrderUrl) => {
      setOrderDialogOpen(false);
      if (taskArray.filter((task) => task.order === orderURL).length > 1) {
        dispatch(actions.update(taskURL, [{member: "order", value: url}]));
        dispatch(
          actions.go("/orderEntry/:id", {
            id: urlToId(url),
          }),
        );
      } else {
        setDeleteDialogOpenedFor(url);
      }
    },
    [dispatch, orderURL, taskArray, taskURL],
  );

  const handleDeleteOrder = useCallback(() => {
    if (!deleteDialogOpenedFor) {
      return;
    }
    setDeleteDialogOpenedFor(null);
    dispatch(actions.update(taskURL, [{member: "order", value: deleteDialogOpenedFor}]));

    dispatch(actions.remove(orderURL));

    dispatch(
      actions.go("/orderEntry/:id", {
        id: urlToId(deleteDialogOpenedFor),
      }),
    );
  }, [deleteDialogOpenedFor, dispatch, orderURL, taskURL]);

  const handleDeleteOrderCancel = useCallback(() => {
    if (!deleteDialogOpenedFor) {
      return;
    }
    dispatch(actions.update(taskURL, [{member: "order", value: deleteDialogOpenedFor}]));
    setDeleteDialogOpenedFor(null);
    dispatch(
      actions.go("/orderEntry/:id", {
        id: urlToId(deleteDialogOpenedFor),
      }),
    );
  }, [deleteDialogOpenedFor, dispatch, taskURL]);

  const currentUserURL = useSelector(getCurrentUserURL);

  const handleCustomerDialogOk = useCallback(
    (customer: CustomerUrl) => {
      setCustomerDialogOpen(false);
      const order = orderLookup(orderURL);
      const userURL = currentUserURL;
      const id = uuid();
      const url = instanceURL("order", id);
      const instance: Order = {
        ...emptyOrder,
        createdBy: userURL,
        customer,
        draft: order?.draft || false,
        durationDays: 1,
        id,
        url,
      };
      dispatch(actions.create(instance));

      if (taskArray.filter((task) => task.order === orderURL).length > 1) {
        dispatch(actions.update(taskURL, [{member: "order", value: url}]));
        dispatch(
          actions.go("/orderEntry/:id", {
            id: urlToId(url),
          }),
        );
      } else {
        setDeleteDialogOpenedFor(url);
      }
    },
    [currentUserURL, dispatch, orderLookup, orderURL, taskArray, taskURL],
  );

  const handleNewOrder = useCallback(() => {
    setOrderDialogOpen(false);
    setCustomerDialogOpen(true);
  }, []);

  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);

  return (
    <>
      <IconButton onClick={useTrueCallback(setOrderDialogOpen, [setOrderDialogOpen])}>
        <DirectionsForkIcon />
      </IconButton>
      <ConnectedOrderDialog
        onCancel={useFalseCallback(setOrderDialogOpen, [setOrderDialogOpen])}
        onNewOrder={handleNewOrder}
        onOk={handleOrderDialogOk}
        open={orderDialogOpen}
        preferredCustomer={customerURL}
        skipOrder={orderURL}
      />
      <CustomerSelectCreateDialog
        onCancel={useFalseCallback(setCustomerDialogOpen, [setCustomerDialogOpen])}
        onOk={handleCustomerDialogOk}
        open={customerDialogOpen}
      />
      <DeleteDialog
        cancelLabel={<FormattedMessage defaultMessage="Nej" id="move-task.no" />}
        onCancel={handleDeleteOrderCancel}
        onOk={handleDeleteOrder}
        open={!!deleteDialogOpenedFor}
        title={
          <FormattedMessage defaultMessage="Slet tom ordre?" id="move-task.delete-empty-order" />
        }
      >
        <FormattedMessage
          defaultMessage="Der er ingen opgaver tilbage på ordren, skal den slettes?"
          id="move-task.delete-order-message"
        />
      </DeleteDialog>
    </>
  );
});
