import {ResponsiveDialog, ResponsiveDialogProps} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import bowser from "bowser";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";

type PickedResponsiveProps = Pick<
  ResponsiveDialogProps,
  "children" | "onCancel" | "open" | "title"
>;

interface ConfirmDialogPropsAdditonalProps {
  onOk: (ok: boolean) => void;
}

export type ConfirmDialogProps = ConfirmDialogPropsAdditonalProps & PickedResponsiveProps;

export function ConfirmDialog(props: ConfirmDialogProps): React.JSX.Element {
  const {children, onCancel, onOk, open, title} = props;
  const intl = useIntl();

  const handleOnOK = useCallback(() => {
    onOk(true);
  }, [onOk]);

  return (
    <ResponsiveDialog
      autoFocusCancel
      cancelLabel={intl.formatMessage({defaultMessage: "Nej"})}
      fullscreen={bowser.mobile || bowser.tablet}
      key="confirm-propagate-workplace-dialog"
      okLabel={intl.formatMessage({defaultMessage: "Ja"})}
      onCancel={onCancel}
      onOk={handleOnOK}
      open={open}
      title={title}
    >
      <DialogContent>{children}</DialogContent>
    </ResponsiveDialog>
  );
}
