import {getCustomerSettings, getSettingsEntryLookupByIdentifier} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";
import {defaultAbsenceTypeLabels} from "./absence-type-labels";

export function AbsenceTypeColors(props: SettingValueDisplayProps): React.JSX.Element {
  const {settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const customerSettings = useSelector(getCustomerSettings);

  const absenceTypeLabels: {[label: string]: string} = useMemo(
    () => customerSettings.absenceTypeLabels || {},
    [customerSettings.absenceTypeLabels],
  );
  const absenceTypeColors: {[label: string]: string} = useMemo(
    () => settingEntry?.data || {},
    [settingEntry?.data],
  );

  const usedAbsenceTypeLabels: {[key: string]: string} = {
    ...defaultAbsenceTypeLabels,
    ...absenceTypeLabels,
  };

  const colors: {[identifier: string]: {color: string; label: string}} = {};
  Object.entries(usedAbsenceTypeLabels).forEach(([identifier, label]) => {
    colors[identifier] = {
      color: absenceTypeColors[identifier] || customerSettings.absenceCalendarBlockColor,
      label,
    };
  });

  return (
    <div>
      <i>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage
                  defaultMessage="Fraværstype"
                  id="system-setup.dialog-title.absence-type"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Farve" id="system-setup.dialog-title.color" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(colors).map(([identifier, entry]) => {
              return (
                <TableRow key={identifier}>
                  <TableCell>{entry.label}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        backgroundColor: entry.color,
                        height: 20,
                        width: 100,
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </i>
    </div>
  );
}
