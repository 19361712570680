import {Config} from "@co-common-libs/config";
import {
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  Machine,
  MachineUrl,
  Project,
  ProjectUrl,
  ReportSetup,
  ReportSetupUrl,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getMachineString, getWorkTypeString} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {ResponsiveDialog, SingleTextFieldDialog} from "@co-frontend-libs/components";
import {
  ConnectedMultipleCulturesDialog,
  ConnectedMultipleCustomersDialog,
  ConnectedMultipleDepartmentsDialog,
  ConnectedMultipleExternalWorkTypesDialog,
  ConnectedMultipleMachinesDialog,
  ConnectedMultipleProjectsDialog,
  ConnectedMultipleUsersDialog,
  ConnectedReportSetupDialog,
} from "@co-frontend-libs/connected-components";
import {Button, Card, CardContent, CardHeader, DialogContent} from "@material-ui/core";
import {getDepartmentName, PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import ContentSaveIcon from "mdi-react/ContentSaveIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import FilterRemoveIcon from "mdi-react/FilterRemoveIcon";
import FolderOpenIcon from "mdi-react/FolderOpenIcon";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext} from "react-intl";

const ENTRIES_BEFORE_ELLIPSIS = 3;
const USERS_ENTRIES_BEFORE_ELLIPSIS = 5;

interface FilterCardProps {
  cultureLookup: (url: CultureUrl) => Culture | undefined;
  cultures: ReadonlySet<CultureUrl>;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customers: ReadonlySet<CustomerUrl>;
  customerSettings: Config;
  departments: ReadonlySet<string>;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  machines: ReadonlySet<MachineUrl>;
  name?: string | null;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  projects: ReadonlySet<ProjectUrl>;
  reportSetupArray: readonly ReportSetup[];
  reportSetupLookup: (url: ReportSetupUrl) => ReportSetup | undefined;
  requestReportSetupDelete: (url: string) => void;
  requestSave: (name: string) => void;
  requestSetupUpdate: (data: {
    cultures?: ReadonlySet<CultureUrl>;
    customers?: ReadonlySet<CustomerUrl>;
    departments?: ReadonlySet<string>;
    machines?: ReadonlySet<MachineUrl>;
    name?: string | null;
    projects?: ReadonlySet<ProjectUrl>;
    url?: ReportSetupUrl | null;
    users?: ReadonlySet<UserUrl>;
    workTypes?: ReadonlySet<WorkTypeUrl>;
  }) => void;
  url?: string | null;
  users: ReadonlySet<UserUrl>;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  workTypes: ReadonlySet<WorkTypeUrl>;
}

interface FilterCardState {
  culturesDialogOpen: boolean;
  customersDialogOpen: boolean;
  deleteReportSetupName: string | null;
  departmentDialogOpen: boolean;
  machinesDialogOpen: boolean;
  projectDialogOpen: boolean;
  reportSetupDialogOpen: boolean;
  saveDialogOpen: boolean;
  usersDialogOpen: boolean;
  workTypeDialogOpen: boolean;
}

export class FilterCard extends PureComponent<FilterCardProps, FilterCardState> {
  static contextType = IntlContext;

  context!: React.ContextType<typeof IntlContext>;
  state: FilterCardState = {
    culturesDialogOpen: false,
    customersDialogOpen: false,
    deleteReportSetupName: null,
    departmentDialogOpen: false,
    machinesDialogOpen: false,
    projectDialogOpen: false,
    reportSetupDialogOpen: false,
    saveDialogOpen: false,
    usersDialogOpen: false,
    workTypeDialogOpen: false,
  };

  @bind
  handleCulturesButton(): void {
    this.setState({culturesDialogOpen: true});
  }

  @bind
  handleCulturesDialogCancel(): void {
    this.setState({culturesDialogOpen: false});
  }

  @bind
  handleCulturesDialogOk(cultures: ReadonlySet<CultureUrl>): void {
    this.setState({culturesDialogOpen: false});
    this.props.requestSetupUpdate({cultures});
  }

  @bind
  handleCustomersButton(): void {
    this.setState({customersDialogOpen: true});
  }

  @bind
  handleCustomersDialogCancel(): void {
    this.setState({customersDialogOpen: false});
  }

  @bind
  handleCustomersDialogOk(customers: ReadonlySet<CustomerUrl>): void {
    this.setState({customersDialogOpen: false});
    this.props.requestSetupUpdate({customers});
  }

  @bind
  handleDeleteButton(): void {
    this.setState({deleteReportSetupName: this.props.name || null});
  }

  @bind
  handleDeleteCancel(): void {
    this.setState({deleteReportSetupName: null});
  }

  @bind
  handleDeleteOk(): void {
    if (this.props.url) {
      this.setState({deleteReportSetupName: null});
      this.props.requestReportSetupDelete(this.props.url);
    }
  }

  @bind
  handleDepartmentsButton(): void {
    this.setState({departmentDialogOpen: true});
  }

  @bind
  handleDepartmentsDialogCancel(): void {
    this.setState({departmentDialogOpen: false});
  }

  @bind
  handleDepartmentsDialogOk(departments: ReadonlySet<string>): void {
    this.setState({departmentDialogOpen: false});
    this.props.requestSetupUpdate({departments});
  }

  @bind
  handleMachinesButton(): void {
    this.setState({machinesDialogOpen: true});
  }

  @bind
  handleMachinesDialogCancel(): void {
    this.setState({machinesDialogOpen: false});
  }

  @bind
  handleMachinesDialogOk(machines: ReadonlySet<MachineUrl>): void {
    this.setState({machinesDialogOpen: false});
    this.props.requestSetupUpdate({machines});
  }

  @bind
  handleOpenCancel(): void {
    this.setState({reportSetupDialogOpen: false});
  }

  @bind
  handleOpenClick(): void {
    this.setState({reportSetupDialogOpen: true});
  }

  @bind
  handleOpenOk(reportSetupURL: ReportSetupUrl): void {
    const reportSetup = this.props.reportSetupLookup(reportSetupURL);
    this.setState({reportSetupDialogOpen: false});
    if (reportSetup) {
      this.props.requestSetupUpdate({
        customers: new Set(reportSetup.customers),
        departments: new Set(reportSetup.departments || []),
        machines: new Set(reportSetup.machines),
        name: reportSetup.name,
        url: reportSetup.url,
        users: new Set(reportSetup.employees),
        workTypes: new Set(reportSetup.workTypes),
      });
    }
  }

  @bind
  handleProjectsButton(): void {
    this.setState({projectDialogOpen: true});
  }

  @bind
  handleProjectsDialogCancel(): void {
    this.setState({projectDialogOpen: false});
  }

  @bind
  handleProjectsDialogOk(projects: ReadonlySet<ProjectUrl>): void {
    this.setState({projectDialogOpen: false});
    this.props.requestSetupUpdate({projects});
  }

  @bind
  handleResetButton(): void {
    const emptySet: ReadonlySet<any> = new Set();
    this.props.requestSetupUpdate({
      customers: emptySet,
      departments: emptySet,
      machines: emptySet,
      name: null,
      projects: emptySet,
      url: null,
      users: emptySet,
      workTypes: emptySet,
    });
  }

  @bind
  handleSaveButton(): void {
    this.setState({saveDialogOpen: true});
  }

  @bind
  handleSaveDialogCancel(): void {
    this.setState({saveDialogOpen: false});
  }

  @bind
  handleSaveDialogOk(name: string): void {
    this.setState({saveDialogOpen: false});
    this.props.requestSave(name);
  }

  @bind
  handleUsersButton(): void {
    this.setState({usersDialogOpen: true});
  }

  @bind
  handleUsersDialogCancel(): void {
    this.setState({usersDialogOpen: false});
  }

  @bind
  handleUsersDialogOk(users: ReadonlySet<UserUrl>): void {
    this.setState({usersDialogOpen: false});
    this.props.requestSetupUpdate({users});
  }

  @bind
  handleWorktypeButton(): void {
    this.setState({workTypeDialogOpen: true});
  }

  @bind
  handleWorkTypeDialogCancel(): void {
    this.setState({workTypeDialogOpen: false});
  }

  @bind
  handleWorkTypeDialogOk(workTypes: ReadonlySet<WorkTypeUrl>): void {
    this.setState({workTypeDialogOpen: false});
    this.props.requestSetupUpdate({workTypes});
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const {
      cultureLookup,
      cultures,
      customerLookup,
      customers,
      departments,
      machineLookup,
      machines,
      projectLookup,
      projects,
      reportSetupArray,
      users,
      userUserProfileLookup,
      workTypeLookup,
      workTypes,
    } = this.props;
    let customersCell;
    if (customerSettings.externalTaskCustomer) {
      customersCell = (
        <Cell>
          <Button
            color="secondary"
            onClick={this.handleCustomersButton}
            style={{marginTop: 2}}
            variant="contained"
          >
            {formatMessage({defaultMessage: "Vælg kunder"})}
          </Button>
          <div style={{whiteSpace: "pre-line"}}>
            {!customers.size
              ? formatMessage({defaultMessage: "Ingen filtrering"})
              : Array.from(customers)
                  .map((customerURL) => customerLookup(customerURL)?.name)
                  .sort()
                  .slice(0, ENTRIES_BEFORE_ELLIPSIS)
                  .concat(customers.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                  .join(",\n")}
          </div>
        </Cell>
      );
    }
    let culturesCell;
    if (customerSettings.externalTaskCulture) {
      culturesCell = (
        <Cell>
          <Button
            color="secondary"
            onClick={this.handleCulturesButton}
            style={{marginTop: 2}}
            variant="contained"
          >
            {formatMessage({defaultMessage: "Vælg kulturer"})}
          </Button>
          <div style={{whiteSpace: "pre-line"}}>
            {!cultures.size
              ? formatMessage({defaultMessage: "Ingen filtrering"})
              : Array.from(cultures)
                  .map((cultureURL) => cultureLookup(cultureURL)?.name)
                  .sort()
                  .slice(0, ENTRIES_BEFORE_ELLIPSIS)
                  .concat(cultures.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                  .join(",\n")}
          </div>
        </Cell>
      );
    }
    let departmentCell;
    if (
      customerSettings.enableExternalTaskDepartmentField ||
      customerSettings.enableInternalTaskDepartmentField
    ) {
      departmentCell = (
        <Cell>
          <Button
            color="secondary"
            onClick={this.handleDepartmentsButton}
            style={{marginTop: 2}}
            variant="contained"
          >
            {formatMessage({defaultMessage: "Vælg afdelinger"})}
          </Button>
          <div style={{whiteSpace: "pre-line"}}>
            {!departments.size
              ? formatMessage({defaultMessage: "Ingen filtrering"})
              : Array.from(departments)
                  .map((departmentID) =>
                    departmentID === ""
                      ? formatMessage({defaultMessage: "Uden afdeling"})
                      : getDepartmentName(departmentID, this.props.customerSettings),
                  )
                  .sort()
                  .slice(0, ENTRIES_BEFORE_ELLIPSIS)
                  .concat(departments.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                  .join(",\n")}
          </div>
        </Cell>
      );
    }

    let projectsCell;
    if (customerSettings.enableProjects) {
      projectsCell = (
        <Cell>
          <Button
            color="secondary"
            onClick={this.handleProjectsButton}
            style={{marginTop: 2}}
            variant="contained"
          >
            {customerSettings.projectLabelVariant === "PROJECT"
              ? formatMessage({defaultMessage: "Vælg projekter"})
              : formatMessage({defaultMessage: "Vælg sager"})}
          </Button>
          <div style={{whiteSpace: "pre-line"}}>
            {!projects.size
              ? formatMessage({defaultMessage: "Ingen filtrering"})
              : Array.from(projects)
                  .map((projectURL) => {
                    const project = projectLookup(projectURL);
                    return project ? `${project.projectNumber}: ${project.name}` : "";
                  })
                  .sort()
                  .slice(0, ENTRIES_BEFORE_ELLIPSIS)
                  .concat(projects.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                  .join(",\n")}
          </div>
        </Cell>
      );
    }
    const title = bowser.mobile
      ? formatMessage({defaultMessage: "Opg.-statistik"})
      : formatMessage({defaultMessage: "Opgavestatistik, opsætning"});
    return (
      <div>
        <Card style={{marginTop: "1em"}}>
          <div style={{position: "relative"}}>
            <Button
              disabled={!reportSetupArray.length}
              onClick={this.handleOpenClick}
              startIcon={<FolderOpenIcon />}
              style={{
                position: "absolute",
                right: "1em",
                top: 14,
              }}
              variant="text"
            >
              {formatMessage({defaultMessage: "Åbn"})}
            </Button>
            <Button
              onClick={this.handleSaveButton}
              startIcon={<ContentSaveIcon />}
              style={{
                position: "absolute",
                right: 105,
                top: 14,
              }}
              variant="text"
            >
              {formatMessage({defaultMessage: "Gem"})}
            </Button>
          </div>
          <CardHeader title={title} />
          <CardContent>
            <Grid>
              {!this.props.customerSettings.noExternalTaskWorkType ? (
                <Cell>
                  <ConnectedMultipleExternalWorkTypesDialog
                    onCancel={this.handleWorkTypeDialogCancel}
                    onOk={this.handleWorkTypeDialogOk}
                    open={!!this.state.workTypeDialogOpen}
                    selected={workTypes}
                  />
                  <Button
                    color="secondary"
                    onClick={this.handleWorktypeButton}
                    style={{marginTop: 2}}
                    variant="contained"
                  >
                    {formatMessage({defaultMessage: "Vælg arbejdsområder"})}
                  </Button>
                  <div style={{whiteSpace: "pre-line"}}>
                    {!workTypes.size
                      ? formatMessage({defaultMessage: "Ingen filtrering"})
                      : Array.from(workTypes)
                          .map((workTypeURL) => getWorkTypeString(workTypeLookup(workTypeURL)))
                          .sort()
                          .slice(0, ENTRIES_BEFORE_ELLIPSIS)
                          .concat(workTypes.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                          .join(",\n")}
                  </div>
                </Cell>
              ) : null}
              <Cell>
                <Button
                  color="secondary"
                  onClick={this.handleMachinesButton}
                  style={{marginTop: 2}}
                  variant="contained"
                >
                  {customerSettings.machineLabelVariant === "MACHINE"
                    ? formatMessage({defaultMessage: "Vælg maskiner"})
                    : formatMessage({defaultMessage: "Vælg køretøjer"})}
                </Button>
                <div style={{whiteSpace: "pre-line"}}>
                  {!machines.size
                    ? formatMessage({defaultMessage: "Ingen filtrering"})
                    : Array.from(machines)
                        .map((machineURL) => getMachineString(machineLookup(machineURL)))
                        .sort()
                        .slice(0, ENTRIES_BEFORE_ELLIPSIS)
                        .concat(machines.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                        .join(",\n")}
                </div>
              </Cell>
              {customersCell}
              {culturesCell}
              {departmentCell}
              {projectsCell}
              <Cell>
                <Button
                  color="secondary"
                  onClick={this.handleUsersButton}
                  style={{marginTop: 2}}
                  variant="contained"
                >
                  {formatMessage({defaultMessage: "Vælg medarbejdere"})}
                </Button>
                <div>
                  {!users.size
                    ? formatMessage({defaultMessage: "Ingen filtrering"})
                    : Array.from(users)
                        .map(userUserProfileLookup)
                        .filter(notUndefined)
                        .map((userProfile) => userProfile.alias)
                        .sort()
                        .slice(0, USERS_ENTRIES_BEFORE_ELLIPSIS)
                        .concat(users.size > USERS_ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                        .join(", ")}
                </div>
              </Cell>
            </Grid>
            <div>
              {this.props.url
                ? [
                    <div key="save-name">
                      <FormattedMessage
                        defaultMessage="Navn: {name}"
                        id="filter-card.label.name"
                        tagName="strong"
                        values={{name: this.props.name}}
                      />
                    </div>,
                    <Button
                      key="save-button"
                      onClick={this.handleDeleteButton}
                      startIcon={<DeleteIcon />}
                      style={{float: "left"}}
                      variant="text"
                    >
                      {formatMessage({defaultMessage: "Slet"})}
                    </Button>,
                  ]
                : null}
              <Button
                onClick={this.handleResetButton}
                startIcon={<FilterRemoveIcon />}
                style={{float: "right"}}
                variant="text"
              >
                {formatMessage({defaultMessage: "Nulstil"})}
              </Button>
              <br />
            </div>
          </CardContent>
        </Card>
        <ConnectedMultipleMachinesDialog
          onCancel={this.handleMachinesDialogCancel}
          onOk={this.handleMachinesDialogOk}
          open={this.state.machinesDialogOpen}
          selected={machines}
        />
        <ConnectedMultipleUsersDialog
          onCancel={this.handleUsersDialogCancel}
          onOk={this.handleUsersDialogOk}
          open={this.state.usersDialogOpen}
          selected={users}
        />
        <ConnectedMultipleCustomersDialog
          onCancel={this.handleCustomersDialogCancel}
          onOk={this.handleCustomersDialogOk}
          open={this.state.customersDialogOpen}
          selected={customers}
        />
        <ConnectedMultipleCulturesDialog
          onCancel={this.handleCulturesDialogCancel}
          onOk={this.handleCulturesDialogOk}
          open={this.state.culturesDialogOpen}
          selected={cultures}
        />
        <ConnectedMultipleDepartmentsDialog
          onCancel={this.handleDepartmentsDialogCancel}
          onOk={this.handleDepartmentsDialogOk}
          open={this.state.departmentDialogOpen}
          selected={departments}
          showBlankChoice={customerSettings.enableNoDepartment}
        />
        <SingleTextFieldDialog
          label={<FormattedMessage defaultMessage="Navn" id="reports.label.name" />}
          onCancel={this.handleSaveDialogCancel}
          onOk={this.handleSaveDialogOk}
          open={this.state.saveDialogOpen}
          title={formatMessage({defaultMessage: "Gem rapportopsætning"})}
        />
        <ConnectedReportSetupDialog
          onCancel={this.handleOpenCancel}
          onOk={this.handleOpenOk}
          open={this.state.reportSetupDialogOpen}
        />
        <ConnectedMultipleProjectsDialog
          onCancel={this.handleProjectsDialogCancel}
          onOk={this.handleProjectsDialogOk}
          open={this.state.projectDialogOpen}
          selected={projects}
          suggestRecentlyUsed={false}
        />
        <ResponsiveDialog
          onCancel={this.handleDeleteCancel}
          onOk={this.handleDeleteOk}
          open={!!this.state.deleteReportSetupName}
          title={formatMessage({defaultMessage: "Slet?"})}
        >
          <DialogContent>
            <FormattedMessage
              defaultMessage="Er du sikker på at du vil slette opsætningen: {name}"
              id="filter-card.label.deleteReportSetup"
              values={{name: this.state.deleteReportSetupName}}
            />
          </DialogContent>
        </ResponsiveDialog>
      </div>
    );
  }
}
