import {actions, getCurrentlyFetchingChanges} from "@co-frontend-libs/redux";
import {Fab} from "@material-ui/core";
import _ from "lodash";
import RefreshIcon from "mdi-react/RefreshIcon";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

const PULL_TO_REFRESH_THRESHOLD = 200;

const ICON_BUTTON_SIZE = 40;
const ICON_RADIUS = 12;

const HALF = 0.5;

const DISPLAY_SPINNER_MIN_MS = 1000;

function scrollingNotRefreshing(element: HTMLElement): boolean {
  let checkingElement: HTMLElement | null = element;
  while (checkingElement) {
    if (checkingElement.scrollTop > 0) {
      return true;
    }
    checkingElement = checkingElement.parentElement;
  }
  return false;
}

function circleSegmentPath(
  radius: number,
  centerX: number,
  centerY: number,
  partFraction: number,
): string {
  const partPolar = partFraction * 2 * Math.PI;
  const pastHalfCircle = partFraction > HALF;
  return `M ${centerX} ${centerY} L ${centerX + radius} ${centerY} A ${radius} ${radius} 0 ${
    pastHalfCircle ? 1 : 0
  } 1 ${centerX + radius * Math.cos(partPolar)} ${centerY + radius * Math.sin(partPolar)} Z`;
}

export const TouchPullRefreshHandler = React.memo(
  function TouchPullRefreshHandler(): React.JSX.Element | null {
    const dispatch = useDispatch();

    const currentlyFetching = useSelector(getCurrentlyFetchingChanges);

    const pullRefreshState = useRef({
      currentY: 0,
      dragStartY: 0,
      pullingTowardsRefresh: false,
      refreshing: false,
      timeoutId: 0,
    });
    const [refreshingIconPosition, setRefreshingIconPosition] = useState(0);
    const [refreshIconPendingTimeout, setRefreshIconPendingTimeout] = useState(false);

    useEffect(() => {
      if (!currentlyFetching && !refreshIconPendingTimeout) {
        pullRefreshState.current.refreshing = false;
        setRefreshingIconPosition(0);
      }
    }, [currentlyFetching, refreshIconPendingTimeout]);

    const handleTouchStart = useCallback((event: Event): void => {
      if (pullRefreshState.current.refreshing) {
        return;
      }
      const touchEvent = event as TouchEvent;
      if (scrollingNotRefreshing(touchEvent.target as HTMLElement)) {
        // element or some parent may be scrolled into view;
        // assume that will happen; don't refresh
        return;
      }
      pullRefreshState.current.dragStartY = pullRefreshState.current.currentY =
        touchEvent.touches[0].clientY;

      pullRefreshState.current.pullingTowardsRefresh = true;
    }, []);

    const handleTouchMove = useCallback((event: Event): void => {
      if (!pullRefreshState.current.pullingTowardsRefresh) {
        return;
      }
      const touchEvent = event as TouchEvent;
      pullRefreshState.current.currentY = touchEvent.touches[0].clientY;
      const distancePulled =
        pullRefreshState.current.currentY - pullRefreshState.current.dragStartY;
      setRefreshingIconPosition(_.clamp(distancePulled, 0, PULL_TO_REFRESH_THRESHOLD));
    }, []);

    const handleTouchEnd = useCallback(
      (_event: Event): void => {
        if (!pullRefreshState.current.pullingTowardsRefresh) {
          return;
        }
        const distancePulled =
          pullRefreshState.current.currentY - pullRefreshState.current.dragStartY;
        if (distancePulled > PULL_TO_REFRESH_THRESHOLD) {
          pullRefreshState.current.refreshing = true;
          setRefreshIconPendingTimeout(true);
          pullRefreshState.current.timeoutId = window.setTimeout(() => {
            setRefreshIconPendingTimeout(false);
            pullRefreshState.current.timeoutId = 0;
          }, DISPLAY_SPINNER_MIN_MS);
          dispatch(actions.requestChangesFetch());
        } else {
          setRefreshingIconPosition(0);
        }
        pullRefreshState.current.pullingTowardsRefresh = false;
      },
      [dispatch],
    );

    const handleTouchCancel = useCallback((_event: Event): void => {
      pullRefreshState.current.pullingTowardsRefresh = false;
    }, []);

    useEffect(() => {
      if (!window.cordova && process.env.NODE_ENV === "production") {
        return undefined;
      }
      window.addEventListener("touchstart", handleTouchStart, {
        passive: true,
      });
      window.addEventListener("touchmove", handleTouchMove, {
        passive: true,
      });
      window.addEventListener("touchend", handleTouchEnd, {
        passive: true,
      });
      window.addEventListener("touchcancel", handleTouchCancel, {
        passive: true,
      });
      return () => {
        window.removeEventListener("touchstart", handleTouchStart);
        window.removeEventListener("touchmove", handleTouchMove);
        window.removeEventListener("touchend", handleTouchEnd);
        window.removeEventListener("touchcancel", handleTouchCancel);
        if (pullRefreshState.current.timeoutId) {
          window.clearTimeout(pullRefreshState.current.timeoutId);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          pullRefreshState.current.timeoutId = 0;
        }
      };
    }, [handleTouchCancel, handleTouchEnd, handleTouchMove, handleTouchStart]);

    const refreshIconStyle = useMemo((): React.CSSProperties => {
      if (!refreshingIconPosition) {
        return {};
      }
      const refreshPullPart = refreshingIconPosition / PULL_TO_REFRESH_THRESHOLD;
      const refreshPullFirstHalfPart = refreshPullPart >= HALF ? 1 : refreshPullPart / HALF;
      const refreshPullSecondHalfPart =
        refreshPullPart <= HALF ? 0 : (refreshPullPart - HALF) / HALF;
      const result: React.CSSProperties = {
        opacity: refreshPullFirstHalfPart,
        transform: `rotate(${refreshPullSecondHalfPart * HALF}turn)`,
      };
      if (refreshPullFirstHalfPart < 1) {
        result.clipPath = `path('${circleSegmentPath(
          ICON_RADIUS,
          ICON_RADIUS,
          ICON_RADIUS,
          refreshPullFirstHalfPart,
        )}')`;
      }
      return result;
    }, [refreshingIconPosition]);

    const refreshIconWrapperStyle = useMemo(
      (): React.CSSProperties => ({
        position: "fixed",
        textAlign: "center",
        top: refreshingIconPosition - ICON_BUTTON_SIZE,
        width: "100%",
        zIndex: 9999,
      }),
      [refreshingIconPosition],
    );

    if (!refreshingIconPosition) {
      return null;
    }

    return (
      <div style={refreshIconWrapperStyle}>
        <Fab size="small">
          <span
            className={currentlyFetching || refreshIconPendingTimeout ? "rotate" : ""}
            style={{animationDirection: "reverse", height: 24, width: 24}}
          >
            <RefreshIcon style={refreshIconStyle} />
          </span>
        </Fab>
      </div>
    );
  },
);
