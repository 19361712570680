import {DebouncedAppbarSearchField} from "@co-frontend-libs/components";
import {MenuToolbar, PageLayout} from "app-components";
import {useQueryParameterState} from "app-utils";
import React from "react";
import {useIntl} from "react-intl";
import {ProductsList} from "./products/products-list";

interface ProductsSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const ProductsSettingsPage = React.memo(function ProductsSettingsPage(
  props: ProductsSettingsPageProps,
): React.JSX.Element {
  const intl = useIntl();

  const [filter, setFilter] = useQueryParameterState<string>("q", "");

  return (
    <PageLayout
      toolbar={
        <MenuToolbar
          onMenuButton={props.onMenuButton}
          rightElement={
            <DebouncedAppbarSearchField debounceTimeout={200} onChange={setFilter} value={filter} />
          }
          title={intl.formatMessage({defaultMessage: "Varer"})}
        />
      }
      withBottomScrollPadding
    >
      <ProductsList />
    </PageLayout>
  );
});
