import {Config} from "@co-common-libs/config";
import {
  PatchUnion,
  PriceGroup,
  PriceItem,
  PriceItemUrl,
  urlToId,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getPriceItemIdentifierString, getUnitString} from "@co-common-libs/resources-utils";
import {
  MultipleMachinesDialog,
  MultipleWorkTypesDialog,
  NullBooleanSelection,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRole,
  getExtendedCustomerSettings,
  getFilteredMachineArray,
  getFilteredMachineLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getUnitLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Card,
  CardContent,
  CardHeader,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
} from "@material-ui/core";
import {PageLayout} from "app-components";
import {
  checkedModelUpdaterFactoryFactory,
  getPriceItemsForSelectedPriceGroup,
  getSortedMachines,
  getSortedWorkTypes,
  PureComponent,
} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL, theme} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {defineMessages, FormattedMessage, IntlContext, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AddPriceItemToPriceGroup} from "../add-price-item-to-price-group";
import {RelationsTable} from "../relations-table";
import {MachinePriceGroupKrPerLiterFuelSurchargeCard} from "./machine-price-group-kr-per-liter-fuel-surcharge-card";
import {MachinePriceGroupPricePercentFuelSurchargeCard} from "./machine-price-group-price-percent-fuel-surcharge-card";
import {WorkTypePriceGroupKrPerLiterFuelSurchargeCard} from "./work-type-price-group-kr-per-liter-fuel-surcharge-card";
import {WorkTypePriceGroupPricePercentFuelSurchargeCard} from "./work-type-price-group-price-percent-fuel-surcharge-card";

const messages = defineMessages({
  active: {defaultMessage: "Aktiv", id: "pricegroups-settings.label.active"},
  editPriceGroup: {
    defaultMessage: "Redigér variant",
    id: "pricegroups-settings.header.edit-pricegroup",
  },
  id: {
    defaultMessage: "ID",
    id: "pricegroups-settings.header.id",
  },
  identifier: {
    defaultMessage: "Søgenavn",
    id: "pricegroups-settings.label.identifier",
  },
  machines: {
    defaultMessage: "Maskiner",
    id: "pricegroups-settings.header.machines",
  },
  name: {
    defaultMessage: "Navn",
    id: "pricegroups-settings.label.name",
  },
  priceItems: {
    defaultMessage: "Prislinjer",
    id: "pricegroups-settings.header.price-items",
  },
  unit: {
    defaultMessage: "Enhed",
    id: "pricegroups-settings.header.unit",
  },
  vehicles: {
    defaultMessage: "Køretøjer",
    id: "pricegroups-settings.header.vehicles",
  },
  workTypes: {
    defaultMessage: "Arbejdsområder",
    id: "pricegroups-settings.header.worktypes",
  },
});

interface PriceGroupEditBlockProps {
  customerSettings: Config;
  priceGroup: PriceGroup;
  update: (url: string, patch: PatchUnion) => void;
}

class PriceGroupEditBlock extends PureComponent<PriceGroupEditBlockProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  makeCheckedModelUpdater = checkedModelUpdaterFactoryFactory<PriceGroup>(this.props.update);

  @bind
  handleIdentifierChange(value: string): void {
    const {priceGroup, update} = this.props;
    update(priceGroup.url, [{member: "identifier", value}]);
  }

  @bind
  handleNameChange(value: string): void {
    const {priceGroup, update} = this.props;
    update(priceGroup.url, [{member: "name", value}]);
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {priceGroup} = this.props;
    let identifierField;
    let activeToggle;
    if (!this.props.customerSettings.onlyAllowEditingNameOnPriceGroupSettingsPage) {
      identifierField = (
        <TrimTextField
          fullWidth
          label={formatMessage(messages.identifier)}
          margin="dense"
          onChange={this.handleIdentifierChange}
          value={priceGroup.identifier}
          variant="outlined"
        />
      );
      activeToggle = (
        <FormControlLabel
          control={
            <Switch
              checked={priceGroup.active}
              onChange={this.makeCheckedModelUpdater(priceGroup.url, "active")}
            />
          }
          label={formatMessage(messages.active)}
        />
      );
    }
    return (
      <Card style={{marginBottom: 22}}>
        <CardContent>
          <TrimTextField
            fullWidth
            label={formatMessage(messages.name)}
            margin="dense"
            onChange={this.handleNameChange}
            value={priceGroup.name}
            variant="outlined"
          />
          {identifierField}
          {activeToggle}
        </CardContent>
      </Card>
    );
  }
}

export const EditPriceGroupPage = React.memo(function EditPriceGroup(): React.JSX.Element {
  const [machineDialogOpen, setMachineDialogOpen] = useState(false);
  const setMachineDialogOpenTrue = useCallWithTrue(setMachineDialogOpen);
  const setMachineDialogOpenFalse = useCallWithFalse(setMachineDialogOpen);

  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);
  const setWorkTypeDialogOpenTrue = useCallWithTrue(setWorkTypeDialogOpen);
  const setWorkTypeDialogOpenFalse = useCallWithFalse(setWorkTypeDialogOpen);

  const currentRole = useSelector(getCurrentRole);
  const customerSettings = useSelector(getExtendedCustomerSettings);
  const machineArray = useSelector(getFilteredMachineArray);
  const machineLookup = useSelector(getFilteredMachineLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);

  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);
  const workTypeArray = useSelector(getWorkTypeArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const {priceItems} = useSelector(getExtendedCustomerSettings);

  const getId = useMemo(() => makePathParameterGetter("id"), []);
  const id = useSelector(getId);

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleWorkTypeRemoved = useCallback(
    (worktypeURL: WorkTypeUrl) => {
      const selectedPriceGroupURL = instanceURL("priceGroup", id);
      const workType = workTypeLookup(worktypeURL);
      if (!workType) {
        return;
      }
      const {pricegroups} = workType;
      const updatedPriceGroups = pricegroups.filter((url) => url !== selectedPriceGroupURL);
      dispatch(actions.update(worktypeURL, [{member: "pricegroups", value: updatedPriceGroups}]));
    },
    [dispatch, id, workTypeLookup],
  );

  const handleMachineRemoved = useCallback(
    (machineURL: string) => {
      const selectedPriceGroupURL = instanceURL("priceGroup", id);
      const machine = machineLookup(machineURL);
      if (!machine) {
        return;
      }
      const {pricegroups} = machine;
      const newPriceGroups = pricegroups.slice();
      newPriceGroups.splice(pricegroups.indexOf(selectedPriceGroupURL), 1);
      dispatch(actions.update(machineURL, [{member: "pricegroups", value: newPriceGroups}]));
    },
    [dispatch, id, machineLookup],
  );

  const handleWorkTypeDialogOk = useCallback(
    (workTypeURLS: ReadonlySet<WorkTypeUrl>) => {
      setWorkTypeDialogOpen(false);
      const selectedPriceGroupURL = instanceURL("priceGroup", id);
      workTypeURLS.forEach((workTypeURL) => {
        const workType = workTypeLookup(workTypeURL);
        if (!workType) {
          return;
        }
        const {pricegroups} = workType;
        const newPriceGroups = [...pricegroups, selectedPriceGroupURL];
        if (!_.isEqual(newPriceGroups, pricegroups)) {
          dispatch(actions.update(workTypeURL, [{member: "pricegroups", value: newPriceGroups}]));
        }
      });
    },
    [dispatch, id, workTypeLookup],
  );

  const handleMachineDialogOk = useCallback(
    (machineURLS: ReadonlySet<string>) => {
      setMachineDialogOpen(false);
      const selectedPriceGroupURL = instanceURL("priceGroup", id);
      machineURLS.forEach((machineURL) => {
        const machine = machineLookup(machineURL);
        if (!machine) {
          return;
        }
        const {pricegroups} = machine;
        const newPriceGroups = [...pricegroups, selectedPriceGroupURL];
        if (!_.isEqual(newPriceGroups, pricegroups)) {
          dispatch(actions.update(machineURL, [{member: "pricegroups", value: newPriceGroups}]));
        }
      });
    },
    [dispatch, id, machineLookup],
  );

  const handlePriceItemRemoved = useCallback(
    (priceItemURL: string) => {
      const selectedPriceGroupURL = instanceURL("priceGroup", id);
      const selectedPriceGroup = priceGroupLookup(selectedPriceGroupURL) as PriceGroup;
      const oldPriceGroupItemSet = selectedPriceGroup.priceGroupItemSet;
      const newPriceGroupItemSet = oldPriceGroupItemSet.filter(
        (priceGroupItem) => priceGroupItem.priceItem !== priceItemURL,
      );
      dispatch(
        actions.update(selectedPriceGroup.url, [
          {member: "priceGroupItemSet", value: newPriceGroupItemSet},
        ]),
      );
    },
    [dispatch, id, priceGroupLookup],
  );

  const handlePriceItemEdit = useCallback(
    (priceItemURL: PriceItemUrl) => {
      dispatch(actions.go("/settings/priceitem/:id", {id: urlToId(priceItemURL)}));
    },
    [dispatch],
  );

  const getSortedPriceItems = useCallback((): PriceItem[] => {
    const selectedPriceGroupURL = instanceURL("priceGroup", id);
    const priceGroup = priceGroupLookup(selectedPriceGroupURL);
    if (!priceGroup) {
      return [];
    }
    return getPriceItemsForSelectedPriceGroup(
      priceGroup,
      priceItemLookup,
      unitLookup,
      customerSettings,
    );
  }, [customerSettings, id, priceGroupLookup, priceItemLookup, unitLookup]);

  const handlePriceItemDragDrop = useCallback(
    (source: string, target: string) => {
      const selectedPriceGroupURL = instanceURL("priceGroup", id);

      const oldPriceItemList = getSortedPriceItems();
      const sourceIndex = oldPriceItemList.findIndex((priceItem) => priceItem.url === source);
      const targetIndex = oldPriceItemList.findIndex((priceItem) => priceItem.url === target);
      const sourceItem = oldPriceItemList[sourceIndex];
      const newPriceItemList = oldPriceItemList.slice();
      newPriceItemList.splice(sourceIndex, 1);
      newPriceItemList.splice(targetIndex, 0, sourceItem);

      const selectedPriceGroup = priceGroupLookup(selectedPriceGroupURL) as PriceGroup;

      const priceGroupItemSet = newPriceItemList.map((priceItem, index) => ({
        order: index,
        priceItem: priceItem.url,
      }));

      dispatch(
        actions.update(selectedPriceGroup.url, [
          {member: "priceGroupItemSet", value: priceGroupItemSet},
        ]),
      );
    },
    [dispatch, getSortedPriceItems, id, priceGroupLookup],
  );

  const handleRequirePriceLinesChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      const url = instanceURL("priceGroup", id);
      dispatch(actions.update(url, [{member: "requirePriceLines", value}]));
    },
    [dispatch, id],
  );

  const handleRequireCompletionConfirmationIfNoProductsChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      const url = instanceURL("priceGroup", id);
      dispatch(actions.update(url, [{member: "requireCompletionConfirmationIfNoProducts", value}]));
    },
    [dispatch, id],
  );

  const handleRequireAtLeastOneOptionalPriceItemUseGreaterThanZeroChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      const url = instanceURL("priceGroup", id);
      dispatch(
        actions.update(url, [
          {
            member: "requireAtLeastOneOptionalPriceItemUseGreaterThanZero",
            value,
          },
        ]),
      );
    },
    [dispatch, id],
  );

  const handleOnlyForExtraTimersChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      const url = instanceURL("priceGroup", id);
      dispatch(actions.update(url, [{member: "onlyForExtraTimers", value}]));
    },
    [dispatch, id],
  );

  const handleRequirePhotoOnTaskCompletionChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      const url = instanceURL("priceGroup", id);
      dispatch(actions.update(url, [{member: "requirePhotoOnTaskCompletion", value}]));
    },
    [dispatch, id],
  );

  const handleRequireAttachmentChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      const url = instanceURL("priceGroup", id);
      dispatch(actions.update(url, [{member: "requireAttachment", value}]));
    },
    [dispatch, id],
  );

  const handleRequireMachineUseChange = useCallback(
    (value: boolean | null) => {
      const url = instanceURL("priceGroup", id);
      dispatch(actions.update(url, [{member: "requireMachineUse", value}]));
    },
    [dispatch, id],
  );

  const handleRequireNotesFromMachineOperatorChange = useCallback(
    (value: boolean | null) => {
      const url = instanceURL("priceGroup", id);
      dispatch(actions.update(url, [{member: "requireNotesFromMachineOperator", value}]));
    },
    [dispatch, id],
  );

  const handleRequireAtLeastOneProductUseGreaterThanZeroChange = useCallback(
    (value: boolean | null) => {
      const url = instanceURL("priceGroup", id);
      dispatch(
        actions.update(url, [{member: "requireAtLeastOneProductUseGreaterThanZero", value}]),
      );
    },
    [dispatch, id],
  );

  const handleNoProductUsesOrAtLeastOneProductUseGreaterThanZeroChange = useCallback(
    (value: boolean | null) => {
      const url = instanceURL("priceGroup", id);
      dispatch(
        actions.update(url, [
          {
            member: "noProductUsesOrAtLeastOneProductUseGreaterThanZero",
            value,
          },
        ]),
      );
    },
    [dispatch, id],
  );

  const handleTimeAfterMinutesEffectChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      const url = instanceURL("priceGroup", id);
      if (value === "per_interval") {
        dispatch(actions.update(url, [{member: "timeAfterMinutesEffect", value: "per_interval"}]));
      } else if (value === "on_sum") {
        dispatch(actions.update(url, [{member: "timeAfterMinutesEffect", value: "on_sum"}]));
      } else {
        dispatch(actions.update(url, [{member: "timeAfterMinutesEffect", value: "unused"}]));
      }
    },
    [dispatch, id],
  );

  const handleRequireEffectiveTimeChange = useCallback(
    (value: boolean | null) => {
      const url = instanceURL("priceGroup", id);
      dispatch(actions.update(url, [{member: "requireEffectiveTime", value}]));
    },
    [dispatch, id],
  );

  const boundUpdate = useCallback(
    (url: string, patch: PatchUnion): void => {
      dispatch(actions.update(url, patch));
    },
    [dispatch],
  );

  const selectedPriceGroupURL = instanceURL("priceGroup", id);
  const selectedPriceGroup = priceGroupLookup(selectedPriceGroupURL) as PriceGroup;

  let priceGroupPriceItems: React.JSX.Element | undefined;
  let machineRelationEditBlock: React.JSX.Element | undefined;
  let workTypeRelationEditBlock: React.JSX.Element | undefined;
  const priceGroupEditBlock = (
    <PriceGroupEditBlock
      customerSettings={customerSettings}
      priceGroup={selectedPriceGroup}
      update={boundUpdate}
    />
  );

  const dialogs = [];
  let canEditPriceGroupsContent;
  if (customerSettings.canEditPriceGroups) {
    if (!customerSettings.onlyAllowEditingNameOnPriceGroupSettingsPage) {
      const {priceGroupItemSet} = selectedPriceGroup;
      const selectedPriceItems = priceGroupItemSet
        ? priceGroupItemSet.map((priceItemUse) => priceItemUse.priceItem)
        : [];

      const priceItemsData = getSortedPriceItems().map((priceItem) => {
        const identifier = getPriceItemIdentifierString(priceItem);
        const unit = getUnitString(priceItem, unitLookup);
        const readonly = !!priceItem.c5_recid && customerSettings.c5Sync;

        return {
          columns: [identifier, unit, priceItem.name],
          identifier: priceItem.url,
          readonly,
        };
      });

      const connectPriceItemButton =
        !customerSettings.onlyAllowEditingNameOnPriceGroupSettingsPage ? (
          <AddPriceItemToPriceGroup
            priceGroupUrl={instanceURL("priceGroup", id)}
            selectedPriceItems={selectedPriceItems}
            showAddCoPriceitemFab={customerSettings.canCreatePriceItems}
            showAsFab
          />
        ) : null;
      priceGroupPriceItems = (
        <Card>
          <div style={{position: "relative"}}>{connectPriceItemButton}</div>
          <CardHeader title={intl.formatMessage(messages.priceItems)} />
          <RelationsTable
            data={priceItemsData}
            header={[
              intl.formatMessage(messages.id),
              intl.formatMessage(messages.unit),
              intl.formatMessage(messages.name),
            ]}
            onDragDrop={
              priceItems.allowManualPriceGroupPriceItemOrdering
                ? handlePriceItemDragDrop
                : undefined
            }
            onEdit={handlePriceItemEdit}
            onRemove={handlePriceItemRemoved}
          />
        </Card>
      );

      dialogs.push();

      const header = [intl.formatMessage(messages.id), intl.formatMessage(messages.name)];

      if (customerSettings.enableMachinePriceStructureOverviewSettingsPage) {
        const selectedMachineList = machineArray.filter((machine) =>
          machine.pricegroups.includes(selectedPriceGroupURL),
        );
        const selectedMachines = new Set(selectedMachineList.map(({url}) => url));

        const machineData = getSortedMachines(
          selectedMachineList,
          customerSettings.disabledMachines,
        ).map((machine) => {
          return {
            columns: [machine.c5_machine, machine.name],
            identifier: machine.url,
          };
        });

        dialogs.push(
          <MultipleMachinesDialog
            includeSelectAll={false}
            key="select-multiple-machines-dialog"
            machineArray={machineArray.filter((w) => !selectedMachines.has(w.url) && w.active)}
            machineLabelVariant={customerSettings.machineLabelVariant}
            onCancel={setMachineDialogOpenFalse}
            onOk={handleMachineDialogOk}
            open={machineDialogOpen}
          />,
        );

        const connectButton = (
          <Fab
            onClick={setMachineDialogOpenTrue}
            size="small"
            style={{
              position: "absolute",
              right: 16,
              top: 16,
            }}
          >
            <PlusIcon />
          </Fab>
        );
        machineRelationEditBlock = (
          <Card style={{marginBottom: 22}}>
            <div style={{position: "relative"}}>{connectButton}</div>
            <CardHeader
              title={
                customerSettings.machineLabelVariant === "MACHINE"
                  ? intl.formatMessage(messages.machines)
                  : intl.formatMessage(messages.vehicles)
              }
            />
            <RelationsTable data={machineData} header={header} onRemove={handleMachineRemoved} />
          </Card>
        );
      }

      if (customerSettings.enableWorkTypePriceStructureOverviewSettingsPage) {
        const selectedWorktypeList = workTypeArray.filter((workType) =>
          workType.pricegroups.includes(selectedPriceGroupURL),
        );
        const selectedWorkTypes = new Set(selectedWorktypeList.map(({url}) => url));
        const {disabledWorkTypes} = customerSettings;

        const worktypesData = getSortedWorkTypes(selectedWorktypeList, disabledWorkTypes).map(
          (workType) => {
            return {
              columns: [workType.identifier, workType.name],
              identifier: workType.url,
            };
          },
        );

        dialogs.push(
          <MultipleWorkTypesDialog
            currentUserURL=""
            disabledWorkTypes={[]}
            includeSelectAll={false}
            key="select-multiple-work-types-dialog"
            onCancel={setWorkTypeDialogOpenFalse}
            onOk={handleWorkTypeDialogOk}
            open={workTypeDialogOpen}
            suggestRecentlyUsedWorkTypes={false}
            workTypeArray={workTypeArray.filter(
              (w) => w.active && w.externalTaskPrimary && !selectedWorkTypes.has(w.url),
            )}
          />,
        );

        const connectButton = (
          <Fab
            onClick={setWorkTypeDialogOpenTrue}
            size="small"
            style={{
              position: "absolute",
              right: 16,
              top: 16,
            }}
          >
            <PlusIcon />
          </Fab>
        );

        workTypeRelationEditBlock = (
          <Card style={{marginBottom: 22}}>
            <div style={{position: "relative"}}>{connectButton}</div>
            <CardHeader title={intl.formatMessage(messages.workTypes)} />
            <RelationsTable data={worktypesData} header={header} onRemove={handleWorkTypeRemoved} />
          </Card>
        );
      }
    }
    canEditPriceGroupsContent = (
      <div style={{padding: "1em"}}>
        <Grid container spacing={2}>
          {!(selectedPriceGroup.c5_recid && customerSettings.c5Sync) ? (
            <Grid item lg={6} xs={12}>
              {priceGroupEditBlock}
              {machineRelationEditBlock}
              {workTypeRelationEditBlock}
            </Grid>
          ) : null}
          <Grid item lg={6} xs={12}>
            {priceGroupPriceItems}
          </Grid>
        </Grid>
      </div>
    );
  }

  let requireEffectiveTimeToggle: React.JSX.Element | null = null;
  let requireEffectiveTimeToggleConsultant: React.JSX.Element | null = null;
  if (customerSettings.c5Sync) {
    requireEffectiveTimeToggleConsultant = (
      <div>
        <NullBooleanSelection
          label={intl.formatMessage({
            defaultMessage: "Kræver effektiv tid. (Kontakt udvikler for opsætning i CO Sync)",
          })}
          nullLabel={intl.formatMessage({
            defaultMessage: "Følg områdets krav om effektiv tid på opgaven",
          })}
          onChange={handleRequireEffectiveTimeChange}
          value={selectedPriceGroup.requireEffectiveTime ?? null}
        />
      </div>
    );
  } else {
    requireEffectiveTimeToggle = (
      <div>
        <NullBooleanSelection
          label={intl.formatMessage({
            defaultMessage: "Kræver effektiv tid.",
          })}
          nullLabel={intl.formatMessage({
            defaultMessage: "Følg områdets krav om effektiv tid på opgaven",
          })}
          onChange={handleRequireEffectiveTimeChange}
          value={selectedPriceGroup.requireEffectiveTime ?? null}
        />
      </div>
    );
  }

  let consultantContent;
  if (currentRole?.consultant) {
    consultantContent = (
      <Grid container>
        <Grid item xs={12}>
          <Card
            style={{
              backgroundColor: theme.palette.consultant.main,
              margin: 16,
            }}
          >
            <CardContent>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedPriceGroup.requirePriceLines !== false}
                      onChange={handleRequirePriceLinesChange}
                    />
                  }
                  label={
                    <FormattedMessage
                      defaultMessage="Kræv at der findes prislinjer på prisgruppen"
                      id="pricegroup-settings.label.require-price-lines"
                    />
                  }
                  labelPlacement="end"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!selectedPriceGroup.onlyForExtraTimers}
                      onChange={handleOnlyForExtraTimersChange}
                    />
                  }
                  label={
                    <FormattedMessage
                      defaultMessage="Denne prisgruppe anvendes kun ifm. ekstraknapper og vises derfor ikke i valglister"
                      id="pricegroup-settings.label.only-for-extra-timers"
                    />
                  }
                  labelPlacement="end"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!selectedPriceGroup.requirePhotoOnTaskCompletion}
                      onChange={handleRequirePhotoOnTaskCompletionChange}
                    />
                  }
                  label={
                    <FormattedMessage
                      defaultMessage="Kræv mindst ét foto ifm. udførelse af opgaver med denne prisgruppe"
                      id="pricegroup-settings.label.require-photo-on-task-completion"
                    />
                  }
                  labelPlacement="end"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!selectedPriceGroup.requireAttachment}
                      onChange={handleRequireAttachmentChange}
                    />
                  }
                  label={
                    <FormattedMessage defaultMessage="Kræv mindst ét foto eller én PDF ifm. udførelse af opgaver med denne prisgruppe" />
                  }
                  labelPlacement="end"
                />
              </div>
              <div>
                <NullBooleanSelection
                  label={intl.formatMessage({
                    defaultMessage: "Kræv at notefeltet er udfyldt ifm. udførelse af opgaver",
                  })}
                  nullLabel={intl.formatMessage({
                    defaultMessage: "Følg områdets krav om note",
                  })}
                  onChange={handleRequireNotesFromMachineOperatorChange}
                  value={selectedPriceGroup.requireNotesFromMachineOperator}
                />
              </div>
              <div>
                <NullBooleanSelection
                  label={intl.formatMessage({
                    defaultMessage: "Kræver maskine på opgaven",
                  })}
                  nullLabel={intl.formatMessage({
                    defaultMessage: "Følg områdets krav om maskine på opgaven",
                  })}
                  onChange={handleRequireMachineUseChange}
                  value={selectedPriceGroup.requireMachineUse ?? null}
                />
              </div>
              <div>
                <FormControl component="fieldset">
                  <FormattedMessage
                    defaultMessage='Variant af "overtager tiden fra timeren der overstiger givne minutter"'
                    tagName="b"
                  />
                  <RadioGroup
                    name="time-after-minutes-effect"
                    onChange={handleTimeAfterMinutesEffectChange}
                    value={selectedPriceGroup.timeAfterMinutesEffect || "unused"}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={<FormattedMessage defaultMessage="Per interval (per gang)" />}
                      value="per_interval"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label={<FormattedMessage defaultMessage="Samlet tid for timer på opgaven" />}
                      value="on_sum"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label={<FormattedMessage defaultMessage="Feature slukket" />}
                      value="unused"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {requireEffectiveTimeToggleConsultant}
              <div>
                <NullBooleanSelection
                  label={intl.formatMessage({
                    defaultMessage:
                      "Kræv at der er tilføjet mindst én vare på opgaven og at denne er udfyldt med en værdi større end 0",
                  })}
                  nullLabel={intl.formatMessage({
                    defaultMessage: "Følg områdets eller maskinens krav",
                  })}
                  onChange={handleRequireAtLeastOneProductUseGreaterThanZeroChange}
                  value={selectedPriceGroup.requireAtLeastOneProductUseGreaterThanZero ?? null}
                />
              </div>
              <div>
                <NullBooleanSelection
                  label={intl.formatMessage({
                    defaultMessage:
                      "Hvis der er varer på opgaven, så skal mindst én af disse være udfyldt med større end 0",
                  })}
                  nullLabel={intl.formatMessage({
                    defaultMessage: "Følg områdets krav",
                  })}
                  onChange={handleNoProductUsesOrAtLeastOneProductUseGreaterThanZeroChange}
                  value={
                    selectedPriceGroup.noProductUsesOrAtLeastOneProductUseGreaterThanZero ?? null
                  }
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  let fuelSurchargeBlock: React.JSX.Element | undefined;
  if (customerSettings.fuelSurcharge) {
    fuelSurchargeBlock = (
      <div style={{padding: "1em"}}>
        <Grid container spacing={2}>
          {customerSettings.enableMachinePriceStructureOverviewSettingsPage &&
          customerSettings.fuelSurcharge === "PRICE_PERCENT" ? (
            <Grid item lg={6} xs={12}>
              <MachinePriceGroupPricePercentFuelSurchargeCard priceGroup={selectedPriceGroup} />
            </Grid>
          ) : null}
          {customerSettings.enableMachinePriceStructureOverviewSettingsPage &&
          customerSettings.fuelSurcharge === "KR_PER_LITER" ? (
            <Grid item lg={6} xs={12}>
              <MachinePriceGroupKrPerLiterFuelSurchargeCard priceGroup={selectedPriceGroup} />
            </Grid>
          ) : null}
          {customerSettings.enableWorkTypePriceStructureOverviewSettingsPage &&
          customerSettings.fuelSurcharge === "PRICE_PERCENT" ? (
            <Grid item lg={6} xs={12}>
              <WorkTypePriceGroupPricePercentFuelSurchargeCard priceGroup={selectedPriceGroup} />
            </Grid>
          ) : null}
          {customerSettings.enableWorkTypePriceStructureOverviewSettingsPage &&
          customerSettings.fuelSurcharge === "KR_PER_LITER" ? (
            <Grid item lg={6} xs={12}>
              <WorkTypePriceGroupKrPerLiterFuelSurchargeCard priceGroup={selectedPriceGroup} />
            </Grid>
          ) : null}
        </Grid>
      </div>
    );
  }

  return (
    <PageLayout toolbar={intl.formatMessage(messages.editPriceGroup)} withPadding>
      {canEditPriceGroupsContent}
      {fuelSurchargeBlock}
      <Grid container>
        <Grid item xs={12}>
          <Card
            style={{
              marginLeft: 16,
              marginRight: 16,
            }}
          >
            <CardContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedPriceGroup.requireCompletionConfirmationIfNoProducts}
                    onChange={handleRequireCompletionConfirmationIfNoProductsChange}
                  />
                }
                label={
                  <FormattedMessage defaultMessage="Spørg om der er brugt materialer på opgaven, hvis materiale listen er tom." />
                }
                labelPlacement="end"
              />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      selectedPriceGroup.requireAtLeastOneOptionalPriceItemUseGreaterThanZero
                    }
                    onChange={handleRequireAtLeastOneOptionalPriceItemUseGreaterThanZeroChange}
                  />
                }
                label={
                  <FormattedMessage defaultMessage="Kræv at mindst én valgfri prislinje er udfyldt med en værdi større end 0." />
                }
                labelPlacement="end"
              />
              {requireEffectiveTimeToggle}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {consultantContent}
      {dialogs}
    </PageLayout>
  );
});
