import {MachineUrl, PriceGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {MultiplePriceGroupsDialog} from "@co-frontend-libs/components";
import {
  getMachineLookup,
  getPriceGroupArray,
  getPriceGroupLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";

interface ConnectedMultiplePriceGroupDialogProps {
  includeSelectAll?: boolean;
  machineURL?: MachineUrl;
  onCancel(): void;
  onlyPriceGroups?: readonly PriceGroupUrl[];
  onOk(urls: ReadonlySet<PriceGroupUrl>): void;
  open: boolean;
  selected?: ReadonlySet<PriceGroupUrl>;
  workTypeURL?: WorkTypeUrl;
}

export const ConnectedMultiplePriceGroupDialog: React.FunctionComponent<ConnectedMultiplePriceGroupDialogProps> =
  React.memo(function ConnectedMultiplePriceGroupDialog(
    props: ConnectedMultiplePriceGroupDialogProps,
  ): React.JSX.Element {
    const {
      includeSelectAll,
      machineURL,
      onCancel,
      onlyPriceGroups,
      onOk,
      open,
      selected,
      workTypeURL,
    } = props;
    console.assert(!workTypeURL || !machineURL);

    const priceGroupArray = useSelector(getPriceGroupArray);
    const workTypeLookup = useSelector(getWorkTypeLookup);
    const machineLookup = useSelector(getMachineLookup);
    const priceGroupLookup = useSelector(getPriceGroupLookup);

    const filteredPriceGroupArray = useMemo(() => {
      if (onlyPriceGroups) {
        // don't filter on active if specific array given
        return onlyPriceGroups.map(priceGroupLookup).filter(notUndefined);
      }
      const limitedURLs = workTypeURL
        ? workTypeLookup(workTypeURL)?.pricegroups
        : machineURL
          ? machineLookup(machineURL)?.pricegroups
          : undefined;
      const base = limitedURLs
        ? limitedURLs.map(priceGroupLookup).filter(notUndefined)
        : priceGroupArray;
      return base.filter((priceGroup) => priceGroup.active);
    }, [
      machineLookup,
      machineURL,
      onlyPriceGroups,
      priceGroupArray,
      priceGroupLookup,
      workTypeLookup,
      workTypeURL,
    ]);
    return (
      <MultiplePriceGroupsDialog
        includeSelectAll={includeSelectAll}
        onCancel={onCancel}
        onOk={onOk}
        open={open}
        priceGroupArray={filteredPriceGroupArray}
        selected={selected}
      />
    );
  });
