import {TaskUrl} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Grid, Typography, useTheme} from "@material-ui/core";
import {ButtonStyle, EditCustomerButton, TaskEditButton} from "app-components";
import _ from "lodash";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {isEconomicCustomerIssue} from "./economic-issue-type-guards";
import {translateEconomicIssue} from "./translate-economic-issue";
import {EconomicIssue, EconomicIssueCategory, IssueContext} from "./types";

interface EconomicIssueLineProps {
  category: EconomicIssueCategory;
  context: IssueContext;
  issue: EconomicIssue;
  taskUrl?: TaskUrl | undefined;
}
const EconomicIssueLine = React.memo(function EconomicIssueLine(
  props: EconomicIssueLineProps,
): React.JSX.Element {
  const {category, context, issue, taskUrl} = props;

  const customerSettings = useSelector(getCustomerSettings);

  const theme = useTheme();
  const intl = useIntl();

  const textStyle = useMemo(() => {
    return category === "error"
      ? {color: theme.palette.error.main}
      : {color: theme.palette.warning.main};
  }, [category, theme.palette.error.main, theme.palette.warning.main]);

  return (
    <Grid alignItems="center" container>
      <Grid item>
        <Typography style={textStyle} variant="body1">
          {translateEconomicIssue(issue, intl, customerSettings)}
        </Typography>
      </Grid>
      {taskUrl ? (
        <Grid item>
          {isEconomicCustomerIssue(issue) ? (
            <EditCustomerButton
              allowChangeCurrentTaskOnly={context === "task"}
              buttonStyle={ButtonStyle.ICON_BUTTON}
              customer={issue.instance}
              taskUrl={taskUrl}
            />
          ) : (
            <TaskEditButton compact taskUrl={taskUrl} />
          )}
        </Grid>
      ) : null}
    </Grid>
  );
});

interface EconomicIssuesListProps {
  context: IssueContext;
  issues: readonly EconomicIssue[];
  taskUrl?: TaskUrl | undefined;
}

export const EconomicIssuesList = React.memo(function EconomicIssuesList(
  props: EconomicIssuesListProps,
): React.JSX.Element {
  const {issues, ...other} = props;

  const sortedIssues = _.sortBy(issues, ({resourceName}) => resourceName);

  const errorsGroupByResourceName = _.groupBy(
    sortedIssues.filter(({category}) => category === "error"),
    ({resourceName}) => resourceName,
  );

  const warningsGroupByResourceName = _.groupBy(
    sortedIssues.filter(({category}) => category === "warning"),
    ({resourceName}) => resourceName,
  );

  return (
    <>
      {Object.values(errorsGroupByResourceName).map((errorIssuesForResource) => {
        return errorIssuesForResource.map((issue, index) => (
          <EconomicIssueLine category="error" issue={issue} key={index} {...other} />
        ));
      })}
      {Object.values(warningsGroupByResourceName).map((warningIssuesForResource) => {
        return warningIssuesForResource.map((issue, index) => (
          <EconomicIssueLine category="warning" issue={issue} key={index} {...other} />
        ));
      })}
    </>
  );
});
