import {jsonFetch, ResponseWithData} from "@co-frontend-libs/utils";
import {CircularProgress} from "@material-ui/core";
import {useAsync} from "@react-hookz/web";
import {ApiUrl} from "api-endpoint-urls";
import React, {useEffect} from "react";

export interface AwaitingBackendRequestSpinnerProps {
  apiUrl: ApiUrl;
  data?: object;
  execute: boolean;
  method: "GET" | "POST";
  onSuccess: (response?: ResponseWithData) => void;
}

export const AwaitingBackendRequestSpinner = React.memo(function AwaitingBackendRequestSpinner(
  props: AwaitingBackendRequestSpinnerProps,
): React.JSX.Element {
  const {apiUrl, data, execute, method, onSuccess} = props;

  const [state, actions] = useAsync(() => jsonFetch(apiUrl, method, data));

  useEffect(() => {
    if (execute) {
      actions.execute();
    } else {
      actions.reset();
    }
  }, [actions, execute]);

  useEffect(() => {
    if (state.status === "success") {
      onSuccess(state.result);
    }
  }, [onSuccess, state.result, state.status]);

  return <CircularProgress style={{marginLeft: "50%"}} />;
});
