import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button} from "@material-ui/core";
import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {ClearAppDialog} from "./clear-app-dialog";

export const ClearAppButton = React.memo(function ClearAppButton(): React.JSX.Element {
  const [clearAppDialogOpen, setClearAppDialogOpen] = useState(false);
  const setClearAppDialogOpenTrue = useCallWithTrue(setClearAppDialogOpen);
  const setClearAppDialogOpenFalse = useCallWithFalse(setClearAppDialogOpen);

  return (
    <>
      <Button onClick={setClearAppDialogOpenTrue} variant="text">
        {window.cordova ? (
          <FormattedMessage defaultMessage="Nulstil app" />
        ) : (
          <FormattedMessage defaultMessage="Nulstil CustomOffice" />
        )}
      </Button>
      <ClearAppDialog onCancel={setClearAppDialogOpenFalse} open={clearAppDialogOpen} />
    </>
  );
});
