import {Project, urlToId} from "@co-common-libs/resources";
import {AwaitingBackendRequestSpinner} from "@co-frontend-libs/components";
import {ResponseWithData} from "@co-frontend-libs/utils";
import {getApiEndpoint} from "api-endpoint-urls";
import {LogsCard, LogTaskData} from "app-components";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useState} from "react";

interface ProjectLogAppendicesProps {
  project: Pick<Project, "url">;
}

export const ProjectLogAppendices = React.memo(function ProjectLogAppendices({
  project,
}: ProjectLogAppendicesProps): React.JSX.Element {
  const [taskLogs, setTaskLogs] = useState<LogTaskData[] | null>(null);

  const handleSuccess = useCallback((response?: ResponseWithData) => {
    if (response) {
      setTaskLogs(response.data);
    }
  }, []);
  const data = {
    projectId: urlToId(project.url),
  };
  if (!taskLogs) {
    return (
      <div data-testid="loading">
        <AwaitingBackendRequestSpinner
          apiUrl={getApiEndpoint(globalConfig.baseURL).projectLogs()}
          data={data}
          execute={!taskLogs}
          method="POST"
          onSuccess={handleSuccess}
        />
      </div>
    );
  }
  return (
    <div data-testid="project-logs">
      <LogsCard data={taskLogs} />
    </div>
  );
});
