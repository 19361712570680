import {Config} from "@co-common-libs/config";
import {RemunerationReport, RemunerationReportUrl} from "@co-common-libs/resources";
import {formatDate, formatTime, notUndefined} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  FilePdfIcon,
  iconButtonColumnSpecification,
  RowData,
} from "@co-frontend-libs/components";
import {ConnectedDownloadButton} from "@co-frontend-libs/connected-components";
import {AppState, getCustomerSettings, getRemunerationReportArray} from "@co-frontend-libs/redux";
import {ConnectedTableWithPagination, PaginationPageSize} from "app-components";
import _ from "lodash";
import CheckIcon from "mdi-react/CheckIcon";
import FileDelimitedIcon from "mdi-react/FileDelimitedIcon";
import memoize from "memoize-one";
import React from "react";
import {defineMessages, IntlContext, IntlShape} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  createdTimestamp: {
    defaultMessage: "Oprettet",
    id: "remuneration-report-list.table-header.created-timestamp",
  },
  download: {
    defaultMessage: "Download",
    id: "remuneration-report-list.table-header.download",
  },
  periodEndDate: {
    defaultMessage: "Til",
    id: "remuneration-report-list.table-header.period-end-date",
  },
  periodStartDate: {
    defaultMessage: "Fra",
    id: "remuneration-report-list.table-header.period-start-date",
  },
  reportTitle: {
    defaultMessage: "Løngruppe",
    id: "remuneration-report-list.table-header.report-title",
  },
  useForSalaryVouchers: {
    defaultMessage: "Lønbilag",
    id: "remuneration-report-list.table-header.use-for-salary-vouchers",
  },
});

type RemunerationReportTableFieldID =
  | "createdTimestamp"
  | "createdTimestampRaw"
  | "csvURL"
  | "error"
  | "fromDate"
  | "fromDateRaw"
  | "pdfURL"
  | "title"
  | "toDate"
  | "toDateRaw"
  | "useForSalaryVouchers";

type RemunerationReportTableColumnID =
  | "createdTimestamp"
  | "csvDownload"
  | "fromDate"
  | "pdfDownload"
  | "reportTitle"
  | "toDate"
  | "useForSalaryVouchers";

interface RemunerationReportTableDataType
  extends RowData<RemunerationReportTableFieldID, RemunerationReportUrl> {
  createdTimestamp: string;
  createdTimestampRaw: string;
  csvURL: string | null;
  error: string;
  fromDate: string;
  fromDateRaw: string;
  pdfURL: string | null;
  title: string;
  toDate: string;
  toDateRaw: string;
  useForSalaryVouchers: boolean;
}

function renderPDFDownload(data: RemunerationReportTableDataType): React.JSX.Element {
  return (
    <ConnectedDownloadButton
      downloadURL={data.pdfURL || undefined}
      error={data.error || undefined}
      Icon={FilePdfIcon}
    />
  );
}

function renderCSVDownload(data: RemunerationReportTableDataType): React.JSX.Element {
  return (
    <ConnectedDownloadButton
      downloadURL={data.csvURL || undefined}
      error={data.error || undefined}
      Icon={FileDelimitedIcon}
    />
  );
}

function renderUseForSalaryVouchers(
  data: RemunerationReportTableDataType,
): React.JSX.Element | null {
  if (data.useForSalaryVouchers) {
    return <CheckIcon />;
  } else {
    return null;
  }
}

function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onClick: (remunerationReportURL: RemunerationReportUrl) => void,
): ColumnSpecifications<
  RemunerationReportTableFieldID,
  RemunerationReportTableColumnID,
  RemunerationReportUrl,
  RemunerationReportTableDataType
> {
  return {
    createdTimestamp: {
      field: "createdTimestamp",
      label: formatMessage(messages.createdTimestamp),
      onClick,
      sortField: "createdTimestampRaw",
    },
    csvDownload: iconButtonColumnSpecification({
      field: "csvURL",
      render: renderCSVDownload,
    }),
    fromDate: {
      field: "fromDate",
      label: formatMessage(messages.periodStartDate),
      onClick,
      sortField: "fromDateRaw",
    },
    pdfDownload: iconButtonColumnSpecification({
      field: "pdfURL",
      label: formatMessage(messages.download),
      render: renderPDFDownload,
    }),
    reportTitle: {
      field: "title",
      label: formatMessage(messages.reportTitle),
      onClick,
    },
    toDate: {
      field: "toDate",
      label: formatMessage(messages.periodEndDate),
      onClick,
      sortField: "toDateRaw",
    },
    useForSalaryVouchers: {
      field: "useForSalaryVouchers",
      label: formatMessage(messages.useForSalaryVouchers),
      onClick,
      render: renderUseForSalaryVouchers,
    },
  };
}

function sortRemunerationReportArray(
  remunerationReportArray: readonly RemunerationReport[],
): readonly RemunerationReport[] {
  return _.sortBy(remunerationReportArray, [
    (remunerationReport) => remunerationReport.fromDate,
    (remunerationReport) => remunerationReport.toDate,
    (remunerationReport) => remunerationReport.deviceTimestamp,
  ]).reverse();
}

function buildRowData(
  remunerationReportArray: readonly RemunerationReport[],
): readonly RemunerationReportTableDataType[] {
  return remunerationReportArray.map((report) => ({
    createdTimestamp: `${formatDate(report.deviceTimestamp)} ${formatTime(report.deviceTimestamp)}`,
    createdTimestampRaw: report.deviceTimestamp,
    csvURL: report.csvDownload,
    error: report.error,
    fromDate: formatDate(report.fromDate),
    fromDateRaw: report.fromDate,
    key: report.url,
    pdfURL: report.pdfDownload,
    title: report.title,
    toDate: formatDate(report.toDate),
    toDateRaw: report.toDate,
    useForSalaryVouchers: report.useForSalaryVouchers,
  }));
}

function computeVisibleColumns(
  remunerationReportPerVariation: boolean,
): readonly RemunerationReportTableColumnID[] {
  return (
    [
      "fromDate",
      "toDate",
      "createdTimestamp",
      remunerationReportPerVariation ? "reportTitle" : undefined,
      "useForSalaryVouchers",
      "pdfDownload",
      "csvDownload",
    ] as const
  ).filter(notUndefined);
}

interface RemunerationReportTableStateProps {
  customerSettings: Config;
  remunerationReportArray: readonly RemunerationReport[];
}

interface RemunerationReportTableOwnProps {
  onClick: (remunerationReportURL: RemunerationReportUrl) => void;
}

type RemunerationReportTableProps = RemunerationReportTableOwnProps &
  RemunerationReportTableStateProps;

class RemunerationReportTable extends React.Component<RemunerationReportTableProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  private buildColumnSpecifications: (
    formatMessage: IntlShape["formatMessage"],
    onClick: (remunerationReportURL: RemunerationReportUrl) => void,
  ) => ColumnSpecifications<
    RemunerationReportTableFieldID,
    RemunerationReportTableColumnID,
    RemunerationReportUrl,
    RemunerationReportTableDataType
  >;

  private buildRowData: (
    remunerationReportArray: readonly RemunerationReport[],
  ) => readonly RemunerationReportTableDataType[];
  private computeVisibleColumns: (
    remunerationReportPerVariation: boolean,
  ) => readonly RemunerationReportTableColumnID[];
  private sortRemunerationReportArray: (
    remunerationReportArray: readonly RemunerationReport[],
  ) => readonly RemunerationReport[];
  constructor(props: RemunerationReportTableProps) {
    super(props);
    this.buildColumnSpecifications = memoize(buildColumnSpecifications);
    this.sortRemunerationReportArray = memoize(sortRemunerationReportArray);
    this.buildRowData = memoize(buildRowData);
    this.computeVisibleColumns = memoize(computeVisibleColumns);
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings, onClick, remunerationReportArray} = this.props;
    const {remunerationReportPerVariation} = customerSettings;
    const columnSpecifications = this.buildColumnSpecifications(formatMessage, onClick);
    const sortedRemunerationReportArray = this.sortRemunerationReportArray(remunerationReportArray);
    const data = this.buildRowData(sortedRemunerationReportArray);
    const visibleColumns = this.computeVisibleColumns(remunerationReportPerVariation);
    return (
      <ConnectedTableWithPagination
        columns={columnSpecifications}
        defaultRowsPerPage={PaginationPageSize.SMALL}
        defaultSortDirection="DESC"
        defaultSortKey="fromDate"
        entries={data}
        filteringData={null}
        savePaginationIdentifier="RemunerationTable"
        saveSortingIdentifier="RemunerationTable"
        visibleColumns={visibleColumns}
      />
    );
  }
}

const ConnectedRemunerationReportTable: React.ComponentType<RemunerationReportTableOwnProps> =
  connect<RemunerationReportTableStateProps, object, RemunerationReportTableOwnProps, AppState>(
    createStructuredSelector<AppState, RemunerationReportTableStateProps>({
      customerSettings: getCustomerSettings,
      remunerationReportArray: getRemunerationReportArray,
    }),
  )(RemunerationReportTable);

export {ConnectedRemunerationReportTable as RemunerationReportTable};
