import type {Writable} from "ts-essentials";
import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  Order,
  OrderUrl,
  Project,
  Task,
} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import {SearchField} from "app-utils";
import _ from "lodash";
import React, {useMemo} from "react";
import {IntlShape, useIntl} from "react-intl";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

const PREFERRED_COUNT_LIMIT = 6;

function computeBaseChoices(
  intl: IntlShape,
  customerSettings: Pick<Config, "projectLabelVariant">,
  customerArray: readonly Customer[],
  contactArray: readonly Contact[],
  taskArray: readonly Task[] | undefined,
  orderLookup: ((url: OrderUrl) => Order | undefined) | undefined,
  suggestRecentlyUsed: boolean,
  currentUserURL: string | null | undefined,
  projectArray?: readonly Project[],
): readonly EntryData<CustomerUrl>[] {
  const {projectLabelVariant} = customerSettings;
  const customerDefaultContactMap = new Map<string, Contact>();
  const customerContactsMap = new Map<string, [Contact]>();
  contactArray.forEach((instance) => {
    const customerURL = instance.customer;

    if (instance.defaultContact) {
      customerDefaultContactMap.set(customerURL, instance);
    } else {
      const customerContacts = customerContactsMap.get(customerURL);
      if (customerContacts) {
        customerContacts.push(instance);
      } else {
        customerContactsMap.set(customerURL, [instance]);
      }
    }
  });

  const customerProjectsMap = new Map<string, [Project]>();
  if (projectArray) {
    projectArray.forEach((instance) => {
      const customerURL = instance.customer;
      const customerProjects = customerProjectsMap.get(customerURL);
      if (customerProjects) {
        customerProjects.push(instance);
      } else {
        customerProjectsMap.set(customerURL, [instance]);
      }
    });
  }
  const currentUserOrderURLs = new Map<OrderUrl, string>();

  if (taskArray && currentUserURL && suggestRecentlyUsed) {
    _.sortBy(
      taskArray.filter(
        (t) => t.order && (t.createdBy === currentUserURL || t.machineOperator === currentUserURL),
      ),
      (t) => t.created || "X",
    )
      .reverse()
      .forEach((t) => {
        const existing = t.order ? currentUserOrderURLs.get(t.order) : null;
        if (t.order && t.created && (!existing || existing < t.created)) {
          currentUserOrderURLs.set(t.order, t.created);
        }
      });
  }

  const lastUsedCustomerURLS = new Map<CustomerUrl, string>();
  const currentUserOrderURLArray = [...currentUserOrderURLs.entries()];
  for (let i = 0; i < currentUserOrderURLArray.length; i += 1) {
    const [orderURL, createdDate] = currentUserOrderURLArray[i];

    const order = orderLookup ? orderLookup(orderURL) : null;
    if (!order || !order.customer) {
      continue;
    }
    const existing = lastUsedCustomerURLS.get(order.customer);
    if (!existing || existing < createdDate) {
      lastUsedCustomerURLS.set(order.customer, createdDate);
    }
    if (lastUsedCustomerURLS.size === PREFERRED_COUNT_LIMIT) {
      break;
    }
  }

  const data: EntryData<CustomerUrl>[] = [];
  customerArray.forEach((instance) => {
    const {name, url} = instance;
    const account = instance.c5_account || "";
    const {alias} = instance;
    const projectManager = instance.projectManagerName || "";
    const address = formatAddress(instance);
    let phone = instance.phone || "";
    let cellphone = "";
    const contact = customerDefaultContactMap.get(url);
    if (contact) {
      const contactPhone = contact.phone;
      const contactCellphone = contact.cellphone;
      if (contactPhone) {
        phone = contactPhone;
      }
      if (contactCellphone) {
        cellphone = contactCellphone;
      }
    }
    let projects: string[] | undefined;
    if (projectArray) {
      const customerProjects = customerProjectsMap.get(url);
      if (customerProjects) {
        const innerProjects: string[] = [];
        customerProjects.forEach((project) => {
          const projectName = project.name;
          const {projectNumber} = project;
          if (projectName && projectNumber) {
            innerProjects.push(`${projectName} – ${projectNumber}`);
          } else if (projectName) {
            innerProjects.push(projectName);
          } else if (projectNumber) {
            innerProjects.push(projectNumber);
          }
        });
        if (innerProjects.length) {
          projects = innerProjects;
        }
      }
    }
    let contacts: string[] | undefined;
    const customerContacts = customerContactsMap.get(url);
    if (customerContacts) {
      const innerContacts: string[] = [];
      customerContacts.forEach((c) => {
        const contactName = c.name;
        if (contactName) {
          innerContacts.push(contactName);
        }
      });
      if (innerContacts.length) {
        contacts = innerContacts;
      }
    }
    const lastUsedDate = lastUsedCustomerURLS.get(instance.url);
    const searchFields: SearchField[] = [
      {
        label: intl.formatMessage({defaultMessage: "Søgenavn"}),
        priority: 10,
        text: alias,
      },
      {
        label: intl.formatMessage({defaultMessage: "Telefon"}),
        priority: 9,
        text: phone,
      },
      {
        label: intl.formatMessage({defaultMessage: "Kontonr."}),
        priority: 9,
        text: account,
      },
      {
        label: intl.formatMessage({defaultMessage: "Formand"}),
        priority: 7,
        text: projectManager,
      },
      {
        label: intl.formatMessage({defaultMessage: "Navn"}),
        priority: 5,
        text: name,
      },
      {
        label: intl.formatMessage({defaultMessage: "Mobil"}),
        priority: 5,
        text: cellphone,
      },
      {
        label: intl.formatMessage({defaultMessage: "Adresse"}),
        priority: 2,
        text: address,
      },
    ];
    if (projects) {
      for (const project of projects) {
        searchFields.push({
          label:
            projectLabelVariant === "PROJECT"
              ? intl.formatMessage({defaultMessage: "Projekt"})
              : intl.formatMessage({defaultMessage: "Sag"}),
          priority: 9,
          text: project,
        });
      }
    }
    if (contacts) {
      for (const contactText of contacts) {
        searchFields.push({
          label: intl.formatMessage({defaultMessage: "Kontakt"}),
          priority: 5,
          text: contactText,
        });
      }
    }
    const entry: Writable<EntryData<CustomerUrl>> = {
      category: lastUsedDate ? "recentlyUsed" : instance.favorite ? "favorite" : "standard",
      exactMatchValue: alias,
      identifier: url,
      primaryText: alias ? `${alias}: ${name}` : name,

      searchFields,
      secondaryText:
        phone && cellphone
          ? intl.formatMessage(
              {
                defaultMessage: "{phone}/{cellphone}; {address}  (Konto: {account})",
              },
              {account, address, cellphone, phone},
            )
          : phone || cellphone
            ? `${phone || cellphone}; ${address}  (Konto: ${account})`
            : `${address}  (Konto: ${account})`,
    };
    if (lastUsedDate) {
      entry.recentlyUsedSortKey = new Date(lastUsedDate).valueOf();
    }
    data.push(entry);
  });
  return data;
}

export interface CustomerDialogProps {
  contactArray: readonly Contact[];
  currentUserURL?: string | null;
  customerArray: readonly Customer[];
  customerSettings: Pick<Config, "projectLabelVariant">;
  fullscreen?: boolean;
  onAdd?: ((searchString: string) => void) | undefined;
  onCancel(): void;
  onNone?: () => void;
  onOk(url: CustomerUrl): void;
  open: boolean;
  orderLookup?: (url: OrderUrl) => Order | undefined;
  projectArray?: readonly Project[];
  suggestRecentlyUsed: boolean;
  taskArray?: readonly Task[] | undefined;
}

export const CustomerDialog = React.memo(function CustomerDialog(props: CustomerDialogProps) {
  const {
    contactArray,
    currentUserURL,
    customerArray,
    customerSettings,
    fullscreen,
    onAdd,
    onCancel,
    onNone,
    onOk,
    open,
    orderLookup,
    projectArray,
    suggestRecentlyUsed,
    taskArray,
  } = props;
  const intl = useIntl();
  const title = intl.formatMessage({defaultMessage: "Vælg kunde"});
  const searchTitle = intl.formatMessage({defaultMessage: "Søg kunde"});

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(
    intl,
    customerSettings,
    customerArray,
    contactArray,
    taskArray,
    orderLookup,
    suggestRecentlyUsed,
    currentUserURL,
    projectArray,
  );
  return (
    <GenericSingleSelectionSearchDialog<CustomerUrl>
      addShortLabel={intl.formatMessage({defaultMessage: "Opret kunde"})}
      addUnnamedLabel={intl.formatMessage({defaultMessage: "Opret kunde"})}
      data={data}
      fullscreen={fullscreen}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={2}
      mobileSearchSecondaryLines={2}
      mobileSecondaryLines={2}
      onAdd={onAdd}
      onCancel={onCancel}
      onNone={onNone}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      title={title}
    />
  );
});

interface MultipleCustomersDialogProps {
  contactArray: readonly Contact[];
  customerArray: readonly Customer[];
  customerSettings: Pick<Config, "projectLabelVariant">;
  fullscreen?: boolean;
  includeSelectAll?: boolean;
  onCancel(): void;
  onOk(urls: ReadonlySet<CustomerUrl>): void;
  open: boolean;
  projectArray?: readonly Project[];
  selected?: ReadonlySet<CustomerUrl>;
}

export const MultipleCustomersDialog = React.memo(function MultipleCustomersDialog(
  props: MultipleCustomersDialogProps,
) {
  const {
    contactArray,
    customerArray,
    customerSettings,
    fullscreen,
    includeSelectAll,
    onCancel,
    onOk,
    open,
    projectArray,
    selected,
  } = props;
  const intl = useIntl();
  const title = intl.formatMessage({defaultMessage: "Vælg kunder"});
  const searchTitle = intl.formatMessage({defaultMessage: "Søg kunder"});

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(
    intl,
    customerSettings,
    customerArray,
    contactArray,
    undefined,
    undefined,
    false,
    null,
    projectArray,
  );

  return (
    <GenericMultiSelectionSearchDialog<CustomerUrl>
      data={data}
      fullscreen={fullscreen}
      includeSelectAll={includeSelectAll}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={2}
      mobileSearchSecondaryLines={2}
      mobileSecondaryLines={2}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      title={title}
    />
  );
});
