import {DialogContent} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {ResponsiveDialog} from "./responsive-dialog";
import {TrimTextField} from "./trim-text-field";

interface SingleTextFieldDialogProps {
  label: string | React.JSX.Element;
  onCancel: () => void;
  onOk: (value: string) => void;
  open: boolean;
  title: string | React.JSX.Element;
  value?: string | undefined;
}

export function SingleTextFieldDialog(props: SingleTextFieldDialogProps): React.JSX.Element {
  const {label, onCancel, onOk, open, title, value: propsValue} = props;
  const initialValue = propsValue || "";
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (open) {
      setValue(initialValue);
    }
  }, [initialValue, open]);

  const handleOk = useCallback(() => {
    onOk(value.trim());
  }, [onOk, value]);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <ResponsiveDialog
      okDisabled={!value.trim()}
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      title={title}
    >
      <DialogContent>
        <TrimTextField
          autoFocus
          fullWidth
          label={label}
          margin="dense"
          onChange={setValue}
          value={value}
          variant="outlined"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
