import {Config} from "@co-common-libs/config";
import {
  Machine,
  PatchUnion,
  Role,
  Task,
  TaskUrl,
  urlToId,
  UserProfile,
  UserUrl,
  WorkshopChecklist,
  WorkshopChecklistItem,
  WorkshopChecklistItemUrl,
  WorkshopChecklistUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {dateFromString, dateToString, formatDate, WEEK_DAYS} from "@co-common-libs/utils";
import {DateField, TrimTextField} from "@co-frontend-libs/components";
import {Check, makeQuery, Query} from "@co-frontend-libs/db-resources";
import {
  actions,
  AppState,
  getCurrentRole,
  getCustomerSettings,
  getMachineLookup,
  getPathName,
  getSyncedState,
  getTaskArray,
  getUserUserProfileLookup,
  getWorkshopChecklistItemLookup,
  getWorkshopChecklistLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
  makeQueryParameterGetter,
  PathTemplate,
  QueryQueryStateStruct,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {DoLoadInstance, PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {endOfDay, startOfDay, subDays} from "date-fns";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  fromDate: {
    defaultMessage: "Fra",
    id: "machine-profile.label.from-date",
  },
  onlineArchiveError: {
    defaultMessage: "Fejl ved adgang til arkiv",
    id: "machine-profile.label.archive-error",
  },
  onlineArchiveWaiting: {
    defaultMessage: "Henter fra arkiv",
    id: "machine-profile.label.archive-waiting",
  },
  // TODO: Indsæt maskinens navn
  tasksForPeriod: {
    defaultMessage: "Opgaver i periode",
    id: "machine-profile.card-title.tasks-for-period",
  },
  title: {
    defaultMessage: "Maskinprofil",
    id: "machine-profile.title.machine-profile",
  },
  toDate: {
    defaultMessage: "Til",
    id: "machine-profile.label.to-date",
  },
  vehicleIdentificationNumber: {
    defaultMessage: "Stelnummer",
    id: "machine-profile.label.vehicle-identification-number",
  },
});

const WORK_TYPE_COLUMN_WIDTH = "8em";
const EMPLOYEE_COLUMN_WIDTH = "8em";
const DATE_COLUMN_WIDTH = "15em";
const CHECKLIST_COLUMN_WIDTH = "30em";

interface TaskRowProps {
  checklistNames: readonly string[];
  customerSettings: Config;
  machineOperatorProfile: UserProfile | undefined;
  onClick: (url: TaskUrl) => void;
  task: Task;
  workType?: WorkType | undefined;
}

class TaskRow extends PureComponent<TaskRowProps> {
  @bind
  handleClick(): void {
    this.props.onClick(this.props.task.url);
  }
  render(): React.JSX.Element {
    const {machineOperatorProfile, task, workType} = this.props;
    const workTypeIdentifier = workType ? workType.identifier : null;
    const notes = task.notesFromManager || task.notesFromMachineOperator;
    const machineOperatorInitials = machineOperatorProfile ? machineOperatorProfile.alias : null;
    const completedDate = formatDate(task.workToTimestamp);

    return (
      <TableRow onClick={this.handleClick} style={{cursor: "pointer"}}>
        <TableCell
          style={{
            width: WORK_TYPE_COLUMN_WIDTH,
          }}
        >
          {workTypeIdentifier}
        </TableCell>
        <TableCell>{notes}</TableCell>
        {this.props.customerSettings.enableWorkshopChecklistsFor.length ? (
          <TableCell style={{width: CHECKLIST_COLUMN_WIDTH}}>
            {this.props.checklistNames.map((name) => (
              <div key={`${task.url}-${name}`}>{name}</div>
            ))}
          </TableCell>
        ) : null}
        <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}}>{machineOperatorInitials}</TableCell>
        <TableCell style={{width: DATE_COLUMN_WIDTH}}>{completedDate}</TableCell>
      </TableRow>
    );
  }
}

interface TaskListProps {
  customerSettings: Config;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  machineURL: string;
  taskList: readonly Task[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workshopChecklistItemLookup: (url: WorkshopChecklistItemUrl) => WorkshopChecklistItem | undefined;
  workshopChecklistLookup: (url: WorkshopChecklistUrl) => WorkshopChecklist | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

class TaskList extends PureComponent<TaskListProps> {
  @bind
  handleRowClick(taskURL: string): void {
    const id = urlToId(taskURL);
    this.props.go("/task/:id", {id});
  }
  render(): React.JSX.Element {
    const {taskList, userUserProfileLookup, workTypeLookup} = this.props;
    const taskRowList = taskList.map((task) => {
      const machineOperatorProfile = task.machineOperator
        ? userUserProfileLookup(task.machineOperator)
        : undefined;
      const workTypeURL = task.workType;
      const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
      let checklistNames: string[] = [];
      const answers = task.workshopchecklistanswerSet;
      if (answers && answers.length) {
        const checklistURLs: WorkshopChecklistUrl[] = [];
        answers.forEach((answer) => {
          const workshopChecklistItem = this.props.workshopChecklistItemLookup(
            answer.checklistItem,
          );
          if (workshopChecklistItem) {
            const checklistURL = workshopChecklistItem.checklist;
            if (!checklistURLs.includes(checklistURL)) {
              checklistURLs.push(checklistURL);
            }
          }
        });
        checklistNames = checklistURLs
          .map((url) => {
            const workshopChecklist = this.props.workshopChecklistLookup(url);
            return (workshopChecklist && workshopChecklist.name) || "";
          })
          .sort();
      }
      return (
        <TaskRow
          checklistNames={checklistNames}
          customerSettings={this.props.customerSettings}
          key={task.url}
          machineOperatorProfile={machineOperatorProfile}
          onClick={this.handleRowClick}
          task={task}
          workType={workType}
        />
      );
    });
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                width: WORK_TYPE_COLUMN_WIDTH,
              }}
            >
              <FormattedMessage defaultMessage="Omr." id="machine-profile.table-header.task-type" />
            </TableCell>
            <TableCell>
              <FormattedMessage defaultMessage="Noter" id="machine-profile.table-header.notes" />
            </TableCell>
            {this.props.customerSettings.enableWorkshopChecklistsFor.length ? (
              <TableCell
                style={{
                  width: CHECKLIST_COLUMN_WIDTH,
                }}
              >
                <FormattedMessage
                  defaultMessage="Tjeklister"
                  id="machine-profile.table-header.checklist"
                />
              </TableCell>
            ) : null}
            <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}}>
              <FormattedMessage
                defaultMessage="Medarb."
                id="machine-profile.table-header.employee-short"
              />
            </TableCell>
            <TableCell style={{width: DATE_COLUMN_WIDTH}}>
              <FormattedMessage
                defaultMessage="Udf. d."
                id="machine-profile.table-header.date-short"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{taskRowList}</TableBody>
      </Table>
    );
  }
}

interface MachineProfileStateProps {
  currentRole: Role | null;
  customerSettings: Config;
  fromDate: string;
  pathName: string;
  taskArray: readonly Task[];
  taskSyncedState: ReadonlyMap<string, QueryQueryStateStruct>;
  toDate: string;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workshopChecklistItemLookup: (url: WorkshopChecklistItemUrl) => WorkshopChecklistItem | undefined;
  workshopChecklistLookup: (url: WorkshopChecklistUrl) => WorkshopChecklist | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface MachineProfileDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  putQueryKeys: (update: QueryParameters, navigationKind?: PartialNavigationKind) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface MachineProfileOwnProps {
  instance: Machine;
}

type MachineProfileProps = MachineProfileDispatchProps &
  MachineProfileOwnProps &
  MachineProfileStateProps;

interface MachineProfileState {
  query: Query | null;
}

const TEMPORARY_QUERIES_KEY = "MachineProfile";

class MachineProfile extends PureComponent<MachineProfileProps, MachineProfileState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: MachineProfileState = {
    query: null,
  };
  fetchFromArchive(fromDate: string, toDate: string, pathName: string): void {
    const machine = this.props.instance;
    if (fromDate && toDate && toDate >= fromDate) {
      const rangeStartString = startOfDay(dateFromString(fromDate) as Date).toISOString();
      const rangeEndString = endOfDay(dateFromString(toDate) as Date).toISOString();
      const taskCheck: Check = {
        checks: [
          {
            checks: [
              {
                checks: [
                  {
                    memberName: "workToTimestamp",
                    type: "memberGt",
                    value: rangeStartString,
                  },
                  {
                    memberName: "workFromTimestamp",
                    type: "memberLt",
                    value: rangeEndString,
                  },
                  {memberName: "completed", type: "memberTruthy"},
                ],
                type: "and",
              },
              {
                checks: [
                  {
                    memberName: "date",
                    type: "memberGte",
                    value: dateToString(subDays(new Date(rangeStartString), WEEK_DAYS)),
                  },
                  {
                    memberName: "date",
                    type: "memberLte",
                    value: toDate,
                  },
                ],
                type: "and",
              },
              {memberName: "completed", type: "memberFalsy"},
            ],
            type: "or",
          },
          {memberName: "order", type: "memberFalsy"},
        ],
        type: "and",
      };
      const query = makeQuery({
        check: taskCheck,
        filter: {
          fromDate,
          internalOnly: "",
          machineID: urlToId(machine.url),
          toDate,
        },
        independentFetch: true,
        resourceName: "task",
      });
      this.props.temporaryQueriesRequestedForPath([query], pathName, TEMPORARY_QUERIES_KEY);
      this.setState({query});
    }
  }
  @bind
  handleFromDateChange(value: string | null): void {
    this.props.putQueryKey("fromDate", value || "");
  }
  @bind
  handleToDateChange(value: string | null): void {
    this.props.putQueryKey("toDate", value || "");
  }
  @bind
  handleVinChange(value: string): void {
    const {instance, update} = this.props;
    update(instance.url, [{member: "vehicleIdentificationNumber", value}]);
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings, fromDate, taskArray, taskSyncedState, toDate} = this.props;
    const machine = this.props.instance;
    let message;
    let showSpinner = false;
    {
      const {query} = this.state;
      if (query) {
        const querySyncedState = taskSyncedState.get(query.keyString);
        if (!querySyncedState || querySyncedState.queryState.currentlyFullFetching) {
          message = formatMessage(messages.onlineArchiveWaiting);
          showSpinner = true;
        } else {
          const lastTimestamp = querySyncedState.queryState.fullFetchDataComputedAtTimestamp;
          const {lastErrorTimestamp} = querySyncedState.queryState;
          if (lastErrorTimestamp && lastTimestamp && lastErrorTimestamp > lastTimestamp) {
            message = formatMessage(messages.onlineArchiveError);
          }
        }
      }
    }
    let content;
    if (message) {
      let spinner;
      if (showSpinner) {
        spinner = <CircularProgress />;
      }
      content = (
        <div style={{padding: 8, textAlign: "center"}}>
          <div>{message}</div>
          {spinner}
        </div>
      );
    } else if (fromDate && toDate && toDate >= fromDate) {
      const workShopWorkTypeURLS = new Set(
        this.props.workTypeArray
          .filter((workType) => customerSettings.workshopWorkTypes.includes(workType.identifier))
          .map((w) => w.url),
      );
      const machineURL = machine.url;
      const taskList = _.sortBy(
        taskArray.filter((task) => {
          if (task.order) {
            return false;
          }
          if (!task.workType || !workShopWorkTypeURLS.has(task.workType)) {
            return false;
          }
          const {workToTimestamp} = task;
          if (workToTimestamp == null) {
            return false;
          }
          const taskDate = dateToString(dateFromString(workToTimestamp));
          return (
            taskDate >= fromDate &&
            taskDate <= toDate &&
            (task.machineuseSet || []).some((m) => m.machine === machineURL)
          );
        }),
        (task) => {
          if (task.workFromTimestamp) {
            return new Date(task.workFromTimestamp);
          } else if (task.date) {
            return dateFromString(task.date);
          } else {
            return null;
          }
        },
      );
      if (!taskList.length) {
        content = (
          <div style={{padding: 8, textAlign: "center"}}>
            {customerSettings.machineLabelVariant === "MACHINE" ? (
              <FormattedMessage
                defaultMessage="Maskinen har ingen opgaver i den valgte periode."
                id="machine-profile.text.no-tasks-found"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Køretøjet har ingen opgaver i den valgte periode."
                id="machine-profile.text.vehicle-no-tasks-found"
              />
            )}
          </div>
        );
      } else {
        content = (
          <TaskList
            customerSettings={this.props.customerSettings}
            go={this.props.go}
            machineURL={machineURL}
            taskList={taskList}
            userUserProfileLookup={this.props.userUserProfileLookup}
            workshopChecklistItemLookup={this.props.workshopChecklistItemLookup}
            workshopChecklistLookup={this.props.workshopChecklistLookup}
            workTypeLookup={this.props.workTypeLookup}
          />
        );
      }
    }
    let vinBlock;
    if (this.props.customerSettings.workshopVehicleIdentificationNumber) {
      if (this.props.currentRole && this.props.currentRole.manager) {
        vinBlock = (
          <div>
            <TrimTextField
              label={formatMessage(messages.vehicleIdentificationNumber)}
              margin="dense"
              onChange={this.handleVinChange}
              value={machine.vehicleIdentificationNumber || ""}
              variant="outlined"
            />
          </div>
        );
      } else {
        vinBlock = (
          <div>
            <FormattedMessage
              defaultMessage="Stelnummer:"
              id="machine-profile.label.vehicle-identification-number-x"
            />
            &nbsp;
            {machine.vehicleIdentificationNumber}
          </div>
        );
      }
    }
    return (
      <PageLayout toolbar={formatMessage(messages.title)} withPadding>
        <Card>
          <CardContent>
            <div>
              {customerSettings.machineLabelVariant === "MACHINE" ? (
                <FormattedMessage defaultMessage="Maskine:" id="machine-profile.label.machine" />
              ) : (
                <FormattedMessage defaultMessage="Køretøj:" id="machine-profile.label.vehicle" />
              )}
              &nbsp;
              {machine.c5_machine}
            </div>
            <div>
              <FormattedMessage defaultMessage="Navn:" id="machine-profile.label.name" />
              &nbsp;
              {machine.name}
            </div>
            {vinBlock}
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardHeader title={formatMessage(messages.tasksForPeriod)} />
          <CardContent>
            <Grid>
              <Cell palm="12/12">
                <DateField
                  autoOk
                  fullWidth
                  label={formatMessage(messages.fromDate)}
                  margin="dense"
                  onChange={this.handleFromDateChange}
                  value={this.props.fromDate}
                />
              </Cell>
              <Cell palm="12/12">
                <DateField
                  autoOk
                  fullWidth
                  label={formatMessage(messages.toDate)}
                  margin="dense"
                  onChange={this.handleToDateChange}
                  value={this.props.toDate}
                />
              </Cell>
            </Grid>
          </CardContent>
          {content}
        </Card>
      </PageLayout>
    );
  }
  UNSAFE_componentWillMount(): void {
    const {fromDate, pathName, toDate} = this.props;
    if (!fromDate && !toDate) {
      const date = new Date();
      const newToDate = dateToString(date);
      const MONTHS_BACK = 12;
      date.setMonth(date.getMonth() - MONTHS_BACK);
      const newFromDate = dateToString(date);
      this.props.putQueryKeys({
        fromDate: newFromDate,
        toDate: newToDate,
      });
    } else {
      this.fetchFromArchive(fromDate, toDate, pathName);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: MachineProfileProps): void {
    if (nextProps.fromDate !== this.props.fromDate || nextProps.toDate !== this.props.toDate) {
      this.fetchFromArchive(nextProps.fromDate, nextProps.toDate, nextProps.pathName);
    }
  }
}

const ConnectedMachineProfile = connect<
  MachineProfileStateProps,
  MachineProfileDispatchProps,
  MachineProfileOwnProps,
  AppState
>(
  createStructuredSelector<AppState, MachineProfileStateProps>({
    currentRole: getCurrentRole,
    customerSettings: getCustomerSettings,
    fromDate: makeQueryParameterGetter("fromDate"),
    pathName: getPathName,
    taskArray: getTaskArray,
    taskSyncedState: getSyncedState.bind(null, "task"),
    toDate: makeQueryParameterGetter("toDate"),
    userUserProfileLookup: getUserUserProfileLookup,
    workshopChecklistItemLookup: getWorkshopChecklistItemLookup,
    workshopChecklistLookup: getWorkshopChecklistLookup,
    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    go: actions.go,
    putQueryKey: actions.putQueryKey,
    putQueryKeys: actions.putQueryKeys,
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
    update: actions.update,
  },
)(MachineProfile);

class LoadMachineInstance extends React.Component<undefined> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    return (
      <DoLoadInstance
        Component={ConnectedMachineProfile}
        loadingTitle={formatMessage(messages.title)}
        lookupSelector={getMachineLookup}
        resourceName="machine"
      />
    );
  }
}

export default LoadMachineInstance;
