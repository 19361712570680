import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";

const messages = defineMessages({
  newTaskReceived: {
    defaultMessage: "Ny opgave modtaget",
    id: "task-recieved-dialog.title.new-task-received",
  },
});

interface TaskReceivedDialogProps {
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

export class TaskReceivedDialog extends PureComponent<TaskReceivedDialogProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {onCancel, onOk, open} = this.props;

    return (
      <ResponsiveDialog
        onCancel={onCancel}
        onOk={onOk}
        open={open}
        title={formatMessage(messages.newTaskReceived)}
      >
        <DialogContent>
          <FormattedMessage
            defaultMessage="Du har modtaget en ny opgave der skal udføres idag. Vil du åbne opgaven?"
            id="task-recieved-dialog.message"
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}
