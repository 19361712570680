import {
  PartialResourceTypeUnion,
  ResourceInstance,
  ResourceName,
  ResourceTypeUnion,
} from "@co-common-libs/resources";
import {actions} from "@co-frontend-libs/redux";
import {jsonFetch, serializeFile, serializeImage} from "@co-frontend-libs/utils";
import {instanceURL, resourceURL} from "frontend-global-config";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";
import {useBoundActions} from "./use-bound-actions";

function uploadFilePC(
  file: File,
  resourceName: ResourceName,
  extraData: PartialResourceTypeUnion,
  addToOffline: (instance: ResourceTypeUnion) => void,
  fileMember: string,
): void {
  const id = uuid();
  const listURL = resourceURL(resourceName);
  const url = instanceURL(resourceName, id);
  const data = new FormData();
  // const name = file.name.replace(/\.[^.]*$/, "");
  data.append("id", id);
  data.append("url", url);
  Object.entries(extraData).forEach(([key, value]) => {
    data.append(key, value);
  });
  data.append(fileMember, file);
  jsonFetch(listURL, "post", data)
    .then((response) => {
      addToOffline(response.data as any);
      return response;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      /* ignore */
    });
  return;
}

const FILE_KEY_PREFIX = "_file_";

function uploadPhotoApp(
  file: File,
  resourceName: ResourceName,
  extraData: PartialResourceTypeUnion,
  create: (instance: ResourceTypeUnion) => void,
  fileMember: string,
): Promise<void> {
  return serializeImage(file).then((fileData) => {
    const id = uuid();
    const url = instanceURL(resourceName, id);
    const instance = {
      [`${FILE_KEY_PREFIX}${fileMember}`]: fileData,
      id,
      url,
      ...extraData,
    } as any;
    create(instance);
    return;
  });
}

function uploadFileApp(
  file: File,
  resourceName: ResourceName,
  extraData: PartialResourceTypeUnion,
  create: (instance: ResourceTypeUnion) => void,
  fileMember: string,
): Promise<void> {
  return serializeFile(file).then((fileData) => {
    const id = uuid();
    const url = instanceURL(resourceName, id);
    const instance = {
      [`${FILE_KEY_PREFIX}${fileMember}`]: fileData,
      id,
      url,
      ...extraData,
    } as any;
    create(instance);
    return;
  });
}

export function uploadFile(
  file: File,
  resourceName: ResourceName,
  extraData: PartialResourceTypeUnion,
  fileMember: string,
  create: (instance: ResourceTypeUnion) => void,
  addToOffline: (instance: ResourceTypeUnion) => void,
): Promise<void> {
  if (window.cordova) {
    if (file.type === "image/jpeg" || file.type === "image/png") {
      return uploadPhotoApp(file, resourceName, extraData, create, fileMember);
    } else {
      return uploadFileApp(file, resourceName, extraData, create, fileMember);
    }
  } else {
    uploadFilePC(file, resourceName, extraData, addToOffline, fileMember);
    return Promise.resolve();
  }
}

export function useFileUploader<T extends ResourceInstance>(
  resourceName: ResourceName,
  fileMember: string,
): (file: File, extraData: Partial<T>) => Promise<void> {
  const dispatch = useDispatch();
  const {boundCreate} = useBoundActions();

  const boundAddToOffline = useCallback(
    (instance: ResourceTypeUnion): void => {
      dispatch(actions.addToOffline(instance));
    },
    [dispatch],
  );
  const uploader = useCallback(
    (file: File, extraData: Partial<T>): Promise<void> =>
      uploadFile(file, resourceName, extraData as any, fileMember, boundCreate, boundAddToOffline),
    [boundCreate, boundAddToOffline, fileMember, resourceName],
  );
  return uploader;
}
