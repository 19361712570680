import {
  createMultiPathBuilder,
  getNormalisedSearchParamsString,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {history} from "./history";
import {PathTemplate, pathTemplates} from "./path-templates";

const getPathname = createMultiPathBuilder(pathTemplates);

export function createHref(
  pathTemplate: PathTemplate,
  pathParameters: PathParameters = {},
  queryParameters: QueryParameters = {},
): string {
  const pathname = getPathname(pathTemplate, pathParameters);
  if (!pathname) {
    throw new Error(`no matching path for: ${pathTemplate}, ${JSON.stringify(pathParameters)}`);
  }
  const searchParams = new URLSearchParams(Object.entries(queryParameters) as [string, string][]);
  searchParams.sort();
  const search = getNormalisedSearchParamsString(searchParams);
  return history.createHref({pathname, search});
}
