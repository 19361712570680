import {Config} from "@co-common-libs/config";
import {CalculatorSpecification} from "@co-common-libs/resources";
import {ThrottledTextField} from "@co-frontend-libs/components";
import {AppState, getCustomerSettings} from "@co-frontend-libs/redux";
import {IconButton, Paper} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {PureComponent} from "app-utils";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {CalculatorButton} from "./calculator-button";
import {MaterialField} from "./material-field";

const messages = defineMessages({
  notesLabel: {
    defaultMessage: "Noter",
    id: "product-table.label.notes",
  },
});

interface MobileMaterialEntryStateProps {
  customerSettings: Config;
}

interface MobileMaterialEntryOwnProps {
  baseUnit?: string | undefined;
  baseValue?: number | undefined;
  booleanSelection?: boolean | undefined;
  calculator?: CalculatorSpecification | undefined;
  dangling?: boolean | undefined;
  decimalPlaces: number;
  disabled: boolean;
  hasConversions: boolean;
  includeNoteInput: boolean;
  name: string;
  notes: string;
  onChange: (value: number | null) => void;
  onDetailButtonClick?: ((event: React.MouseEvent<unknown, MouseEvent>) => void) | undefined;
  onNotesChange?: (value: string) => void;
  onPlusClick: (event: React.MouseEvent<unknown, MouseEvent>) => void;
  onPriceItemUseDetailButtonClick?:
    | ((event: React.MouseEvent<unknown, MouseEvent>) => void)
    | undefined;
  onRelationChange: (value: number | null) => void;
  showDetails?: boolean;
  unit: string;
  value?: number | undefined;
}

type MobileMaterialEntryProps = MobileMaterialEntryOwnProps & MobileMaterialEntryStateProps;

/** @deprecated */
class MobileMaterialEntry extends PureComponent<MobileMaterialEntryProps> {
  static contextType = IntlContext;
  static defaultProps = {
    showDetails: false,
  };
  context!: React.ContextType<typeof IntlContext>;

  render(): React.JSX.Element {
    const {
      baseUnit,
      baseValue,
      booleanSelection,
      calculator,
      dangling,
      decimalPlaces,
      disabled,
      hasConversions,
      includeNoteInput,
      name,
      onChange,
      onDetailButtonClick,
      onPlusClick,
      onPriceItemUseDetailButtonClick,
      onRelationChange,
      showDetails,
      unit,
      value,
    } = this.props;

    const {formatMessage} = this.context;
    let conversionInputField;
    let conversionUnit;
    if (hasConversions && baseUnit) {
      let conversionValue;
      if (baseValue) {
        if (value != null) {
          const computedValue = value / baseValue;

          const factor = 10 ** decimalPlaces;
          conversionValue = Math.round(computedValue * factor) / factor;
        }
      }
      conversionUnit = `${unit}/${baseUnit}`;
      conversionInputField = (
        <MaterialField
          customerSettings={this.props.customerSettings}
          disabled={disabled || !baseValue}
          hideLabel
          id={`${name}-conversion-field`}
          onChange={onRelationChange}
          unit={conversionUnit}
          value={conversionValue}
        />
      );
    }

    let calculatorColumn;
    let plusButton;
    if (calculator) {
      calculatorColumn = (
        <Cell size="1/12" style={{paddingBottom: 10, paddingTop: 10}}>
          <CalculatorButton
            buttonStyle={{height: "auto", padding: 0, width: "auto"}}
            disabled={disabled}
            onInsertValue={onChange}
            specification={calculator}
            unit={unit}
          />
        </Cell>
      );
    } else {
      plusButton = (
        <Cell size="1/12" style={{paddingBottom: 10, paddingTop: 10}}>
          {!booleanSelection ? (
            <IconButton
              color="primary"
              disabled={disabled}
              onClick={onPlusClick}
              style={{height: "auto", padding: 0, width: "auto"}}
            >
              <PlusIcon />
            </IconButton>
          ) : null}
        </Cell>
      );
    }

    let conversionCellBlock;
    if (hasConversions && baseUnit) {
      conversionCellBlock = (
        <Grid gutter="0px">
          <Cell style={{paddingBottom: 10, paddingTop: 10}} />
          <Cell size="3/12">{conversionInputField}</Cell>
          <Cell size="28" style={{paddingBottom: 10, paddingTop: 10, width: 24}} />
          <Cell size="2/12" style={{paddingBottom: 10, paddingTop: 10}}>
            {conversionUnit}
          </Cell>
        </Grid>
      );
    }
    let notesBlock;
    if (includeNoteInput) {
      notesBlock = (
        <Grid gutter="0px">
          <Cell style={{paddingLeft: 12, paddingRight: 12}}>
            <ThrottledTextField
              disabled={disabled}
              fullWidth
              margin="dense"
              onChange={this.props.onNotesChange}
              placeholder={formatMessage(messages.notesLabel)}
              value={this.props.notes}
              variant="outlined"
            />
          </Cell>
        </Grid>
      );
    }

    return (
      <Paper
        style={
          dangling
            ? {
                backgroundColor: "rgb(247, 247, 247)",
                color: red[500],
                marginBottom: 2,
              }
            : {
                backgroundColor: "rgb(247, 247, 247)",
                marginBottom: 2,
              }
        }
      >
        <Grid gutter="0px">
          <Cell size="1/12" style={{paddingBottom: 10, paddingTop: 10}}>
            {onDetailButtonClick && !includeNoteInput ? (
              <IconButton
                color="primary"
                onClick={onDetailButtonClick}
                style={{height: "auto", padding: 0, width: "auto"}}
              >
                <FileDocumentIcon />
              </IconButton>
            ) : null}
            {showDetails && onPriceItemUseDetailButtonClick && !includeNoteInput ? (
              <IconButton
                color="primary"
                onClick={onPriceItemUseDetailButtonClick}
                style={{height: "auto", padding: 0, width: "auto"}}
              >
                <FileDocumentIcon />
              </IconButton>
            ) : null}
          </Cell>
          <Cell
            style={{
              overflow: "hidden",
              paddingBottom: 10,
              paddingTop: 12,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {name}
          </Cell>
          <Cell size="3/12">
            <MaterialField
              booleanSelection={booleanSelection}
              customerSettings={this.props.customerSettings}
              disabled={disabled}
              hideLabel
              id={`${name}-value-field`}
              onChange={onChange}
              unit={unit}
              value={value}
            />
          </Cell>
          {plusButton}
          {calculatorColumn}
          <Cell
            size="2/12"
            style={{
              overflow: "hidden",
              paddingBottom: 10,
              paddingTop: 12,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {!booleanSelection ? unit : null}
          </Cell>
        </Grid>
        {conversionCellBlock}
        {notesBlock}
      </Paper>
    );
  }
}

/** @deprecated */
const ConnectedMobileMaterialEntry: React.ComponentType<MobileMaterialEntryOwnProps> = connect<
  MobileMaterialEntryStateProps,
  object,
  MobileMaterialEntryOwnProps,
  AppState
>(
  createStructuredSelector<AppState, MobileMaterialEntryStateProps>({
    customerSettings: getCustomerSettings,
  }),
)(MobileMaterialEntry);

export {ConnectedMobileMaterialEntry as MobileMaterialEntry};
