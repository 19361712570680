import {ProductUrl} from "@co-common-libs/resources";
import {notUndefined, setRemove} from "@co-common-libs/utils";
import {MultipleProductDialog} from "@co-frontend-libs/components";
import {getCustomerSettings, getProductLookup, getUnitLookup} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, useTheme} from "@material-ui/core";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {DeletableChip} from "./deletable-chip";

interface ProductFilteringProps {
  onSelectedChange: (urls: ReadonlySet<ProductUrl>) => void;
  options: readonly ProductUrl[];
  selected: ReadonlySet<ProductUrl>;
}

export function ProductFiltering(props: ProductFilteringProps): React.JSX.Element {
  const {onSelectedChange, options, selected} = props;

  const unitLookup = useSelector(getUnitLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const productLookup = useSelector(getProductLookup);

  const [dialogOpen, setDialogOpen] = useState(false);
  const setDialogOpenTrue = useCallWithTrue(setDialogOpen);
  const setDialogOpenFalse = useCallWithFalse(setDialogOpen);

  const handleDialogOk = useCallback(
    (urls: ReadonlySet<ProductUrl>) => {
      onSelectedChange(urls);
      setDialogOpen(false);
    },
    [onSelectedChange],
  );
  const potentialProductInstances = useMemo(
    () => options.map(productLookup).filter(notUndefined),
    [productLookup, options],
  );

  const handleDelete = useCallback(
    (url: ProductUrl): void => {
      onSelectedChange(setRemove(selected, url));
    },
    [selected, onSelectedChange],
  );

  const theme = useTheme();

  return (
    <>
      <div>
        <h4>
          <FormattedMessage defaultMessage="Varer" />
        </h4>
        <Button color="primary" onClick={setDialogOpenTrue} variant="contained">
          <FormattedMessage defaultMessage="Vælg varer" />
        </Button>
        <div>
          {_.sortBy(
            Array.from(selected).map(productLookup).filter(notUndefined),
            (product) => product.name,
          ).map((product) => (
            <span
              key={product.url}
              style={{
                marginRight: theme.spacing(0.5),
              }}
            >
              <DeletableChip
                deletionId={product.url}
                label={`${product.catalogNumber}: ${product.name}`}
                onDelete={handleDelete}
              />
            </span>
          ))}
        </div>
      </div>
      <MultipleProductDialog
        materialUseAlternativeText={customerSettings.materialUseAlternativeText}
        onCancel={setDialogOpenFalse}
        onOk={handleDialogOk}
        open={dialogOpen}
        productArray={potentialProductInstances}
        selected={selected}
        unitLookup={unitLookup}
      />
    </>
  );
}
