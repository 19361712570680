import {ContactUrl, CustomerUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface Contact extends ResourceInstance<ContactUrl> {
  readonly active: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly c5_fromCustomer: boolean;
  readonly c5_recid: number | null;
  readonly cellphone: string;
  readonly customer: CustomerUrl;
  readonly defaultContact: boolean;
  readonly email: string;
  readonly fax: string;
  readonly name: string;
  readonly phone: string;
  readonly remoteUrl: string;
}

export const emptyContact: Omit<Contact, "customer" | "id" | "url"> = {
  active: true,

  // eslint-disable-next-line @typescript-eslint/naming-convention
  c5_fromCustomer: false,

  c5_recid: null,
  cellphone: "",
  defaultContact: false,
  email: "",
  fax: "",
  name: "",
  phone: "",
  remoteUrl: "",
};
