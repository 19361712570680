import {
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  resourceNameFor,
  Role,
  Unit,
  UnitUrl,
  urlToId,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  getUnitCode,
  HOUR_UNITS,
  ITEM_TYPE_DIFFERENT,
  ITEM_TYPE_MINIMUM,
  ITEM_TYPE_STARTPRIS,
  ITEM_TYPE_TILLAEG,
  ITEM_TYPE_TIMER,
  ITEM_TYPE_VARE,
} from "@co-common-libs/resources-utils";
import {
  actions,
  AppState,
  getCurrentRole,
  getExtendedCustomerSettings,
  getPriceGroupLookup,
  getPriceItemLookup,
  getUnitLookup,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {IconButton, TableCell, TableRow, Theme, withTheme} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {ExtendedConfig} from "extended-config";
import CalculatorVariantIcon from "mdi-react/CalculatorVariantIcon";
import ChevronRightBoxIcon from "mdi-react/ChevronRightBoxIcon";
import CodeGreaterThanOrEqualIcon from "mdi-react/CodeGreaterThanOrEqualIcon";
import CurrencyUsdOffIcon from "mdi-react/CurrencyUsdOffIcon";
import EyeOffIcon from "mdi-react/EyeOffIcon";
import GasStationIcon from "mdi-react/GasStationIcon";
import GasStationOffIcon from "mdi-react/GasStationOffIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PlusIcon from "mdi-react/PlusIcon";
import SquareOutlineIcon from "mdi-react/SquareOutlineIcon";
import TableColumnIcon from "mdi-react/TableColumnIcon";
import TimerIcon from "mdi-react/TimerIcon";
import React from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {AddPriceItemToPriceGroup} from "./add-price-item-to-price-group";

const border = "1px solid rgb(224, 224, 224)" as const;
const noBorder = "none" as const;

interface ItemTypeTextProps {
  explicitWithoutFuelSurcharge: boolean;
  priceItem: PriceItem;
}

const ItemTypeText = React.memo(function ItemTypeText(props: ItemTypeTextProps): React.JSX.Element {
  const {explicitWithoutFuelSurcharge, priceItem} = props;
  let text: React.JSX.Element | undefined;
  switch (priceItem.itemtype) {
    case ITEM_TYPE_DIFFERENT:
      text = <FormattedMessage defaultMessage="Different" />;
      break;
    case ITEM_TYPE_MINIMUM:
      text = <FormattedMessage defaultMessage="Minimum" />;
      break;
    case ITEM_TYPE_STARTPRIS:
      text = <FormattedMessage defaultMessage="Startpris" />;
      break;
    case ITEM_TYPE_TILLAEG:
      text = <FormattedMessage defaultMessage="Tillæg" />;
      break;
    case ITEM_TYPE_TIMER:
      text = <FormattedMessage defaultMessage="Timer" />;
      break;
    case ITEM_TYPE_VARE:
      text = <FormattedMessage defaultMessage="Vare" />;
      break;
    default:
      break;
  }

  return (
    <div style={{fontStyle: "italic"}}>
      {explicitWithoutFuelSurcharge ? <GasStationOffIcon style={{marginBottom: -3}} /> : null}
      {text}
    </div>
  );
});

interface PriceItemIconsProps {
  explicitWithoutFuelSurcharge: boolean;
  priceItem: PriceItem;
}

const PriceItemIcons = React.memo(function PriceItemIcons(
  props: PriceItemIconsProps,
): React.JSX.Element {
  const {explicitWithoutFuelSurcharge, priceItem} = props;
  const relevantForExecution = priceItem.relevantForExecution ?? true;
  const genericEffectiveTimerTarget = !!priceItem.genericEffectiveTimerTarget;
  const useManualDistribution = !!priceItem.useManualDistribution;
  const required = priceItem.required ?? true;
  const requiredGreaterThanZero = !!priceItem.requiredGreaterThanZero;
  const hasConversion = !!priceItem.conversionFactor;
  let requiredIcon: React.JSX.Element | undefined;
  if (genericEffectiveTimerTarget) {
    requiredIcon = <TimerIcon />;
  } else if (requiredGreaterThanZero) {
    requiredIcon = <ChevronRightBoxIcon />;
  } else if (required) {
    requiredIcon = <CodeGreaterThanOrEqualIcon />;
  } else {
    requiredIcon = <SquareOutlineIcon />;
  }
  return (
    <div>
      {explicitWithoutFuelSurcharge ? <GasStationOffIcon /> : null}
      {!relevantForExecution ? <EyeOffIcon /> : null}
      {useManualDistribution ? <TableColumnIcon /> : null}
      {!priceItem.billable ? <CurrencyUsdOffIcon /> : null}
      {requiredIcon}
      {hasConversion ? <CalculatorVariantIcon /> : null}
    </div>
  );
});

const TimeAfterMinutesText = React.memo(function TimeAfterMinutesText({
  effect,
  minutes,
}: {
  effect: "on_sum" | "per_interval";
  minutes: number;
}): React.JSX.Element {
  if (effect === "per_interval") {
    return (
      <FormattedMessage
        defaultMessage="Overtager tiden efter {minutes} min (interval)"
        values={{minutes}}
      />
    );
  } else if (effect === "on_sum") {
    return (
      <FormattedMessage
        defaultMessage="Overtager tiden efter {minutes} min (samlet)"
        values={{minutes}}
      />
    );
  } else {
    return <FormattedMessage defaultMessage="Ugyldig opsætning, kontakt support" />;
  }
});

interface OverviewRowStateProps {
  currentRole: Role | null;
  customerSettings: ExtendedConfig;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface OverviewRowDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
}

interface OverviewRowOwnProps {
  color?: string | undefined;
  defaultFuelSurcharge?: string | null | undefined;
  firstRowForPriceItem?: boolean;
  itemName?: string;
  onAddPriceGroupClick?: ((url: MachineUrl | WorkTypeUrl) => void) | undefined;
  onChangeColorClick?: (url: MachineUrl | WorkTypeUrl) => void;
  price?: number | undefined;
  priceGroupFuelSurcharge?: string | null | undefined;
  priceItemConfiguration?: boolean;
  priceItemIdentifier?: string | undefined;
  priceItemURL?: PriceItemUrl;
  showPriceItemIdentifiers?: boolean;
  theme: Theme;
  unit?: string;
  variantIdentifier?: string | undefined;
  variantName?: string | undefined;
  variantOnlyForExtraTimers?: boolean | undefined;
  variantURL?: PriceGroupUrl;
  workTypeOrMachineFuelSurcharge?: string | null | undefined;
  workTypeOrMachineIdentifier?: string | undefined;
  workTypeOrMachineName?: string | undefined;
  workTypeOrMachineURL?: MachineUrl | WorkTypeUrl | undefined;
}

type OverviewRowProps = OverviewRowDispatchProps & OverviewRowOwnProps & OverviewRowStateProps;

class OverviewRow extends PureComponent<OverviewRowProps> {
  @bind
  handleAddPriceGroupClick(event: React.MouseEvent): void {
    event.stopPropagation();
    const {onAddPriceGroupClick, workTypeOrMachineURL} = this.props;
    if (workTypeOrMachineURL && onAddPriceGroupClick) {
      onAddPriceGroupClick(workTypeOrMachineURL);
    }
  }
  @bind
  handleChangeColorClick(): void {
    if (this.props.onChangeColorClick && this.props.workTypeOrMachineURL) {
      this.props.onChangeColorClick(this.props.workTypeOrMachineURL);
    }
  }

  @bind
  handlePriceGroupEditClick(): void {
    if (this.props.variantURL) {
      this.props.go("/settings/pricegroup/:id", {
        id: urlToId(this.props.variantURL),
      });
    }
  }

  @bind
  handlePriceItemEditClick(): void {
    if (this.props.priceItemURL) {
      this.props.go("/settings/priceitem/:id", {
        id: urlToId(this.props.priceItemURL),
      });
    }
  }

  @bind
  handleWorkTypeOrMachineEditClick(): void {
    const {workTypeOrMachineURL} = this.props;
    if (!workTypeOrMachineURL) {
      return;
    }
    const resourceName = resourceNameFor(workTypeOrMachineURL);
    const id = urlToId(workTypeOrMachineURL);
    if (resourceName === "machine") {
      this.props.go("/settings/machine/:id", {id});
    } else {
      this.props.go("/settings/externalWorktype/:id", {id});
    }
  }

  render(): React.JSX.Element {
    const {
      color,
      currentRole,
      customerSettings,
      defaultFuelSurcharge,
      firstRowForPriceItem,
      itemName,
      price,
      priceGroupFuelSurcharge,
      priceGroupLookup,
      priceItemConfiguration,
      priceItemIdentifier,
      priceItemLookup,
      priceItemURL,
      showPriceItemIdentifiers,
      theme,
      unit,
      unitLookup,
      variantIdentifier,
      variantName,
      variantOnlyForExtraTimers,
      variantURL,
      workTypeOrMachineFuelSurcharge,
      workTypeOrMachineIdentifier,
      workTypeOrMachineName,
      workTypeOrMachineURL,
    } = this.props;

    const {machines: machineSettings, workTypes: workTypeSettings} = customerSettings;

    const formattedPrice =
      typeof price === "number" ? (
        <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={price} />
      ) : price === null ? (
        "-"
      ) : null;

    const groupedBy = workTypeOrMachineURL && resourceNameFor(workTypeOrMachineURL);

    const canEditMachineOrWorktype =
      (groupedBy === "workType" && workTypeSettings.canAccessDetailsPage) ||
      (groupedBy === "machine" && machineSettings.canAccessDetailsPage);

    const canCreatePriceGroup = customerSettings.canCreatePriceGroups;
    const canEditPriceGroup =
      (customerSettings.canEditPriceGroups || currentRole?.consultant) &&
      variantURL &&
      (firstRowForPriceItem || !priceItemURL) &&
      ((variantIdentifier && !variantOnlyForExtraTimers) ||
        (currentRole && currentRole.consultant));

    const canCreatePriceItem = customerSettings.canCreatePriceItems;
    const canEditPriceItem = priceItemURL && customerSettings.canEditPriceItems;

    let editMachineOrWorkTypeButton;
    if (canEditMachineOrWorktype && workTypeOrMachineURL) {
      editMachineOrWorkTypeButton = (
        <IconButton onClick={this.handleWorkTypeOrMachineEditClick} style={{float: "right"}}>
          <PencilIcon />
        </IconButton>
      );
    }
    let addPriceGroupButton;
    if (canCreatePriceGroup && workTypeOrMachineURL) {
      addPriceGroupButton = (
        <IconButton onClick={this.handleAddPriceGroupClick} style={{float: "right"}}>
          <PlusIcon />
        </IconButton>
      );
    }
    let editPriceGroupButton;
    if (canEditPriceGroup && variantURL) {
      editPriceGroupButton = (
        <IconButton onClick={this.handlePriceGroupEditClick} style={{float: "right"}}>
          <PencilIcon />
        </IconButton>
      );
    }
    const econProductsSync =
      customerSettings.economicSync && !customerSettings.economicEnableProjectActivitiesImport;
    let addPriceItemButton;
    if (
      (canCreatePriceItem || econProductsSync) &&
      variantURL &&
      (firstRowForPriceItem || !priceItemURL)
    ) {
      addPriceItemButton = <AddPriceItemToPriceGroup priceGroupUrl={variantURL} />;
    }
    let editPriceItemButton;
    if (canEditPriceItem && priceItemURL) {
      editPriceItemButton = (
        <IconButton onClick={this.handlePriceItemEditClick} style={{float: "right"}}>
          <PencilIcon />
        </IconButton>
      );
    }

    // ... WTF was the idea with exact checks for null *and* undefined for
    // workTypeOrMachineIdentifier?
    const workTypeColumnStyle: React.CSSProperties = {
      borderBottom: "none",
      borderTop: workTypeOrMachineIdentifier !== undefined ? border : noBorder,
      cursor: canEditMachineOrWorktype ? "pointer" : "auto",
      fontWeight: "bold",
    };
    if (workTypeOrMachineIdentifier === null) {
      workTypeColumnStyle.backgroundColor = grey[300];
    }

    // ... WTF was the idea with exact checks for null *and* undefined for
    // variantIdentifier?
    const variantColumnStyle: React.CSSProperties = {
      borderBottom: "none",
      borderTop: variantIdentifier !== undefined ? border : noBorder,
      fontStyle: "italic",
    };
    if (variantIdentifier === null) {
      variantColumnStyle.backgroundColor = grey[300];
    }

    // ... WTF was the idea with exact checks for null *and* undefined for
    // priceItemIdentifier?
    const priceItemColumnStyle: React.CSSProperties = {
      borderBottom: "none",
      borderTop: priceItemConfiguration ? noBorder : border,
    };
    if (priceItemIdentifier === undefined && itemName === undefined) {
      priceItemColumnStyle.backgroundColor = grey[300];
    }

    const rowBorderStyle = workTypeOrMachineIdentifier ? border : noBorder;

    let colorBox: React.JSX.Element | null = null;
    if (color !== undefined) {
      colorBox = (
        <div
          onClick={this.handleChangeColorClick}
          style={{
            backgroundColor: color || "#FFF",
            border: "1px solid #000",
            height: 16,
            width: 16,
          }}
        />
      );
    }
    let priceItemIconBlock: React.JSX.Element | undefined;
    let timeAfterMinutesBlock: React.JSX.Element | undefined;
    if (priceItemURL) {
      const priceItem = priceItemLookup(priceItemURL);
      if (priceItem) {
        const priceGroup = variantURL ? priceGroupLookup(variantURL) : undefined;
        if (
          priceItem.timeAfterMinutes &&
          priceGroup &&
          priceGroup.timeAfterMinutesEffect &&
          priceGroup.timeAfterMinutesEffect !== "unused"
        ) {
          timeAfterMinutesBlock = (
            <TimeAfterMinutesText
              effect={priceGroup.timeAfterMinutesEffect}
              minutes={priceItem.timeAfterMinutes}
            />
          );
        }
        const explicitWithoutFuelSurcharge =
          customerSettings.fuelSurcharge === "PRICE_PERCENT" &&
          priceItem.contributesToFuelSurchargeSalesPrice === false;
        if (customerSettings.c5Sync) {
          priceItemIconBlock = (
            <ItemTypeText
              explicitWithoutFuelSurcharge={explicitWithoutFuelSurcharge}
              priceItem={priceItem}
            />
          );
        } else {
          priceItemIconBlock = (
            <PriceItemIcons
              explicitWithoutFuelSurcharge={explicitWithoutFuelSurcharge}
              priceItem={priceItem}
            />
          );
        }
        if (
          currentRole &&
          currentRole.consultant &&
          priceItem.relatedUnit &&
          HOUR_UNITS.includes(getUnitCode(priceItem, unitLookup).toLowerCase()) &&
          ((customerSettings.c5Sync && priceItem.itemtype !== ITEM_TYPE_TIMER) ||
            (!customerSettings.c5Sync && !priceItem.genericEffectiveTimerTarget))
        ) {
          priceItemColumnStyle.backgroundColor = theme.palette.warning.light;
        }
      }
    }

    return (
      <TableRow
        style={{
          borderColor: grey[300],
          borderTop: rowBorderStyle,
          verticalAlign: "top",
        }}
      >
        <TableCell style={workTypeColumnStyle}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{workTypeOrMachineIdentifier}</div>
            {colorBox}
          </div>
        </TableCell>
        <TableCell style={workTypeColumnStyle}>
          {workTypeOrMachineName}
          {addPriceGroupButton}
          {editMachineOrWorkTypeButton}
          {typeof workTypeOrMachineFuelSurcharge === "string" ? (
            <div
              style={{
                color: theme.palette.text.secondary,
                fontWeight: "normal",
              }}
            >
              <GasStationIcon style={{marginBottom: -3}} />
              {workTypeOrMachineFuelSurcharge}
            </div>
          ) : null}
          {workTypeOrMachineFuelSurcharge === null ? (
            <div>
              <GasStationOffIcon style={{color: theme.palette.text.secondary, marginBottom: -3}} />
            </div>
          ) : null}
          {workTypeOrMachineFuelSurcharge === undefined && defaultFuelSurcharge != null ? (
            <div
              style={{
                color: theme.palette.text.secondary,
                fontStyle: "italic",
                fontWeight: "normal",
              }}
            >
              <GasStationIcon style={{marginBottom: -3}} />
              {defaultFuelSurcharge}
            </div>
          ) : null}
        </TableCell>
        <TableCell style={variantColumnStyle}>{variantIdentifier}</TableCell>
        <TableCell style={variantColumnStyle}>
          {variantName}
          {addPriceItemButton}
          {editPriceGroupButton}
          {typeof priceGroupFuelSurcharge === "string" ? (
            <div style={{fontStyle: "normal", fontWeight: "normal"}}>
              <GasStationIcon style={{marginBottom: -3}} />
              {priceGroupFuelSurcharge}
            </div>
          ) : null}
          {priceGroupFuelSurcharge === null ? (
            <div>
              <GasStationOffIcon style={{marginBottom: -3}} />
            </div>
          ) : null}
        </TableCell>
        {showPriceItemIdentifiers ? (
          <TableCell style={priceItemColumnStyle}>{priceItemIdentifier}</TableCell>
        ) : null}
        <TableCell style={priceItemColumnStyle}>
          {itemName}
          {editPriceItemButton}
          {priceItemIconBlock}
          {timeAfterMinutesBlock}
        </TableCell>
        <TableCell style={priceItemColumnStyle}>{unit}</TableCell>
        {customerSettings.overviewShowPrices ? (
          <TableCell
            style={{
              ...priceItemColumnStyle,
              textAlign: "right",
            }}
          >
            {formattedPrice}
          </TableCell>
        ) : null}
      </TableRow>
    );
  }
}

const ConnectedOverviewRow: React.ComponentType<OverviewRowOwnProps> = connect<
  OverviewRowStateProps,
  OverviewRowDispatchProps,
  OverviewRowOwnProps,
  AppState
>(
  createStructuredSelector<AppState, OverviewRowStateProps>({
    currentRole: getCurrentRole,
    customerSettings: getExtendedCustomerSettings,
    priceGroupLookup: getPriceGroupLookup,
    priceItemLookup: getPriceItemLookup,
    unitLookup: getUnitLookup,
  }),
  {
    go: actions.go,
  },
)(OverviewRow);

const ConnectedOverviewRowWithTheme: React.ComponentType<Omit<OverviewRowOwnProps, "theme">> =
  withTheme(ConnectedOverviewRow);

export {ConnectedOverviewRowWithTheme as OverviewRow};
