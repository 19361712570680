import {DialogContent, List, ListItem, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {ResponsiveDialog} from "../responsive-dialog";

export type WorkTypeTypeChoices = "external" | "internal";

const WorkTypeTypeItem = React.memo(function WorkTypeTypeItem({
  name,
  onClick,
  workTypeType,
}: {
  name: string;
  onClick: (workTypeType: WorkTypeTypeChoices) => void;
  workTypeType: WorkTypeTypeChoices;
}): React.JSX.Element {
  const handleClick = useCallback(() => {
    onClick(workTypeType);
  }, [onClick, workTypeType]);
  return (
    <ListItem button onClick={handleClick}>
      <ListItemText>{name}</ListItemText>
    </ListItem>
  );
});

export const WorkTypeTypeSelectionDialog = React.memo(function WorkTypeTypeSelectionDialog({
  onCancel,
  onOk,
  open,
  title,
}: {
  onCancel?: () => void;
  onOk: (workTypeType: WorkTypeTypeChoices) => void;
  open: boolean;
  title: string;
}): React.JSX.Element {
  const intl = useIntl();
  return (
    <ResponsiveDialog onCancel={onCancel} open={open} title={title}>
      <DialogContent style={{padding: 0}}>
        <List>
          <WorkTypeTypeItem
            name={intl.formatMessage({defaultMessage: "Eksternt område"})}
            onClick={onOk}
            workTypeType="external"
          />
          <WorkTypeTypeItem
            name={intl.formatMessage({defaultMessage: "Internt område"})}
            onClick={onOk}
            workTypeType="internal"
          />
        </List>
      </DialogContent>
    </ResponsiveDialog>
  );
});
