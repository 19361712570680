import {ResourceInstance, ResourceName, resourceNames} from "@co-common-libs/resources";
import {Check} from "@co-frontend-libs/db-resources";
import {Draft} from "@reduxjs/toolkit";
import {GenericInstanceRecord, ResourceInstanceRecords, ResourcesState} from "../../types";
import {buildCheckFunction, FkRules, ReverseFkRules} from "../../utils";
import {computeInstancesToCheckFromRemovals} from "./compute-instances-to-check-from-removals";

export function checkRemoveInstances(
  state: Draft<ResourcesState>,
  instancesToCheck: {readonly [N in ResourceName]: ReadonlySet<string>},
  potentialData: ResourceInstanceRecords,
  persistedResourceCheckMapping: {readonly [N in ResourceName]: Check},
  temporaryResourceCheckMapping: {readonly [N in ResourceName]: Check},
  oldData: ResourceInstanceRecords,
  persistedFkRules: FkRules,
  persistedReverseFkRules: ReverseFkRules,
  temporaryFkRules: FkRules,
  temporaryReverseFkRules: ReverseFkRules,
): void {
  const extraRemoved: Partial<{
    [N in ResourceName]: string[];
  }> = {};

  resourceNames.forEach((resourceName) => {
    const resourceInstancesToCheck = instancesToCheck[resourceName];
    if (resourceInstancesToCheck.size === 0) {
      return;
    }
    const resourcePersistedCheck = persistedResourceCheckMapping[resourceName];
    if (resourcePersistedCheck.type === "alwaysOk") {
      return;
    }
    const resourceTemporaryCheck = temporaryResourceCheckMapping[resourceName];
    const persistedCheckFunction = buildCheckFunction(resourcePersistedCheck, potentialData);
    const temporaryCheckFunction = buildCheckFunction(resourceTemporaryCheck, potentialData);
    const persistedForResourceDraft = state.persistedData[resourceName];
    const temporaryForResourceDraft = state.temporaryData[resourceName];

    const potentialDataForResource: GenericInstanceRecord = potentialData[resourceName];

    const extraRemovedForResource = new Set<string>();

    resourceInstancesToCheck.forEach((url: string): void => {
      const instance = potentialDataForResource[url];
      if (!instance) {
        return;
      }
      if (persistedCheckFunction(instance)) {
        if (!persistedForResourceDraft[url] && temporaryForResourceDraft[url]) {
          // promotion -- otherwise, it's new or already persisted
          persistedForResourceDraft[url] = instance as ResourceInstance as Draft<any>;
          delete temporaryForResourceDraft[url];
        }
      } else if (temporaryCheckFunction(instance)) {
        if (!temporaryForResourceDraft[url] && persistedForResourceDraft[url]) {
          // demotion -- othervise, it's new or already temporary
          temporaryForResourceDraft[url] = instance as ResourceInstance as Draft<any>;
          delete persistedForResourceDraft[url];
        }
      } else {
        if (temporaryForResourceDraft[url]) {
          console.assert(!persistedForResourceDraft[url]);
          delete temporaryForResourceDraft[url];
          extraRemovedForResource.add(url);
        }
        if (persistedForResourceDraft[url]) {
          console.assert(!temporaryForResourceDraft[url]);
          delete persistedForResourceDraft[url];
          extraRemovedForResource.add(url);
        }
      }
    });

    if (extraRemovedForResource.size) {
      extraRemoved[resourceName] = Array.from(extraRemovedForResource);
    }
  });

  if (
    Object.values(extraRemoved).some((extraRemovedForResource) => extraRemovedForResource?.length)
  ) {
    const instancesToCheckFromExtraRemovals = computeInstancesToCheckFromRemovals(
      oldData,
      extraRemoved,
      persistedFkRules,
      persistedReverseFkRules,
      temporaryFkRules,
      temporaryReverseFkRules,
    );
    if (Object.values(instancesToCheckFromExtraRemovals).some((entry) => entry.size)) {
      checkRemoveInstances(
        state,
        instancesToCheckFromExtraRemovals,
        potentialData,
        persistedResourceCheckMapping,
        temporaryResourceCheckMapping,
        oldData,
        persistedFkRules,
        persistedReverseFkRules,
        temporaryFkRules,
        temporaryReverseFkRules,
      );
    }
  }
}
