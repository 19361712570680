import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, IntlShape, useIntl} from "react-intl";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

const messages = defineMessages({
  department: {
    defaultMessage: "Afdeling",
    id: "department-dialog.dialog-title.department",
  },
  departmentAgriculture: {
    defaultMessage: "Landbrug",
    id: "department-dialog.choice.agriculture",
  },
  departmentConstruction: {
    defaultMessage: "Anlæg",
    id: "task-instance.label.construction",
  },
  departmentContractor: {
    defaultMessage: "Entreprenør",
    id: "department-dialog.choice.",
  },
  departmentFiber: {
    defaultMessage: "Fiber",
    id: "task-instance.label.fiber",
  },
  departmentForestry: {
    defaultMessage: "Skov & natur",
    id: "department-dialog.choice.forestry",
  },
  departmentMolding: {
    defaultMessage: "Støbe/håndmand",
    id: "task-instance.label.molding",
  },
  departmentNone: {
    defaultMessage: "Ikke anvendt",
    id: "department-dialog.choice.none",
  },
  departmentParkSport: {
    defaultMessage: "Park & sport",
    id: "department-dialog.choice.park-sport",
  },
  departmentSewer: {
    defaultMessage: "Kloak",
    id: "task-instance.label.sewage",
  },
  departmentStable: {
    defaultMessage: "Staldarbejde",
    id: "task-instance.label.stable",
  },
  departmentTruck: {
    defaultMessage: "Lastbil",
    id: "task-instance.label.truck",
  },
  noDepartment: {
    defaultMessage: "Uden afdeling",
    id: "department-dialog.label.no-department",
  },
  searchDepartment: {
    defaultMessage: "Søg afdeling",
    id: "department-dialog.dialog-title.search-department",
  },
});

function computeBaseChoices(
  departments: {readonly [identifier: string]: string},
  formatMessage: IntlShape["formatMessage"],
  showBlankChoice?: boolean,
): readonly EntryData<string>[] {
  const result: EntryData<string>[] = [];

  Object.entries(departments).forEach(([identifier, label]) => {
    result.push({
      category: "standard",
      exactMatchValue: label,
      identifier,
      primaryText: label,
      searchFields: [{label: "Afdeling", priority: 10, text: label}],
    });
  });

  if (showBlankChoice) {
    const noDepartment = formatMessage(messages.noDepartment);
    result.push({
      category: "standard",
      exactMatchValue: noDepartment,
      identifier: "",
      primaryText: noDepartment,
      searchFields: [{label: "Afdeling", priority: 10, text: noDepartment}],
    });
  }
  return result;
}

export interface DepartmentDialogProps {
  departments: {readonly [identifier: string]: string};
  fullscreen?: boolean;
  onCancel(): void;
  onOk(identifier: string): void;
  open: boolean;
  showBlankChoice?: boolean;
}

export const DepartmentDialog = React.memo(function DepartmentDialog(props: DepartmentDialogProps) {
  const {departments, fullscreen, onCancel, onOk, open, showBlankChoice} = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.department);
  const searchTitle = intl.formatMessage(messages.searchDepartment);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(departments, intl.formatMessage, showBlankChoice);

  return (
    <GenericSingleSelectionSearchDialog<string>
      data={data}
      fullscreen={fullscreen}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={0}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      title={title}
    />
  );
});

export interface MultipleDepartmentsDialogProps {
  departments: {readonly [identifier: string]: string};
  fullscreen?: boolean;
  onCancel(): void;
  onOk(identifiers: ReadonlySet<string>): void;
  open: boolean;
  selected?: ReadonlySet<string>;
  showBlankChoice?: boolean;
}

export const MultipleDepartmentsDialog = React.memo(function MultipleDepartmentsDialog(
  props: MultipleDepartmentsDialogProps,
) {
  const {departments, fullscreen, onCancel, onOk, open, selected, showBlankChoice} = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.department);
  const searchTitle = intl.formatMessage(messages.searchDepartment);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;

  const data = getBaseChoices(departments, intl.formatMessage, showBlankChoice);
  return (
    <GenericMultiSelectionSearchDialog<string>
      data={data}
      fullscreen={fullscreen}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={0}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      title={title}
    />
  );
});
