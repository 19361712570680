import {
  daySeconds,
  HOUR_SECONDS,
  hourMinutesStringDaySeconds,
  MINUTE_SECONDS,
  objectSet,
} from "@co-common-libs/utils";
import _ from "lodash";
import {SimpleInterval} from "./types";

export function applyWorkPeriodDayEndRounding<T extends SimpleInterval>(
  intervals: readonly T[],
  roundingStartSeconds: number,
  roundingEndSeconds: number,
): readonly T[] {
  if (!intervals.length) {
    return intervals;
  }
  const lastToSeconds = daySeconds(new Date(intervals[intervals.length - 1].toTimestamp));
  if (roundingStartSeconds < lastToSeconds && lastToSeconds <= roundingEndSeconds) {
    // The last interval ends within the rounding period.
    // Filter any entirely within the rounding period.
    const indexBefore = _.findLastIndex(intervals, (interval) => {
      const fromSeconds = daySeconds(new Date(interval.fromTimestamp));
      const toSeconds = daySeconds(new Date(interval.toTimestamp));
      // There needs to be at least a full minute after rounding...
      return !(
        roundingStartSeconds - MINUTE_SECONDS < fromSeconds && toSeconds <= roundingEndSeconds
      );
    });
    // We might not find anything before the rounding period...
    if (indexBefore === -1) {
      return [];
    }
    const remainingIntervals = intervals.slice(0, indexBefore + 1);
    // The resulting last interval *may* start before the rounding period and
    // end within it.  Assuming absence of overlap, no earlier interval
    // should have that property.
    const innerLastToSeconds = daySeconds(
      new Date(remainingIntervals[remainingIntervals.length - 1].toTimestamp),
    );
    if (roundingEndSeconds >= innerLastToSeconds) {
      const lastInterval = remainingIntervals[remainingIntervals.length - 1];
      const roundedEndDate = new Date(lastInterval.toTimestamp);
      const roundingResultHours = Math.floor(roundingStartSeconds / HOUR_SECONDS);
      const roundingResultMinutes = (roundingStartSeconds % HOUR_SECONDS) / MINUTE_SECONDS;
      roundedEndDate.setHours(roundingResultHours, roundingResultMinutes, 0, 0);
      const intervalCopy = objectSet(lastInterval, "toTimestamp", roundedEndDate.toISOString());
      remainingIntervals[remainingIntervals.length - 1] = intervalCopy;
    }
    return remainingIntervals;
  } else {
    return intervals;
  }
}

/** For each "work period"/interval sequence, if the work period *ends*
 * between the times of day specified by `dayEndRounding`, cut off the part
 * between those.
 *
 * Some customers want to specify e.g. that the normal workday starts at 15:10,
 * and anyone clocking in in the last few minutes after that will be paid
 * until 15:10.
 *
 * @param workPeriodsIntervals Array of "work period" arrays.
 * @param dayEndRounding Pair [from-time, to-time].
 */
export function applyDayEndRounding<T extends SimpleInterval>(
  workPeriodsIntervals: readonly (readonly T[])[],
  dayEndRounding: readonly [string, string],
): T[][];
export function applyDayEndRounding<T extends SimpleInterval>(
  workPeriodIntervals: readonly (readonly T[])[],
  dayEndRounding: readonly [string, string],
): readonly (readonly T[])[] {
  const [roundingStartString, roundingEndString] = dayEndRounding;
  const roundingStartSeconds = hourMinutesStringDaySeconds(roundingStartString);
  const roundingEndSeconds = hourMinutesStringDaySeconds(roundingEndString);

  return workPeriodIntervals
    .map((intervals) =>
      applyWorkPeriodDayEndRounding(intervals, roundingStartSeconds, roundingEndSeconds),
    )
    .filter((intervals) => intervals.length > 0);
}
