import {
  CustomerUrl,
  LocationUrl,
  Project,
  ProjectAccess,
  ProjectUrl,
  Task,
} from "@co-common-libs/resources";
import {getProjectLookup} from "@co-frontend-libs/redux";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {InformationBlockPropsBase} from "../information-block-props-base";
import {ConfirmDialogVisibility, ProjectBlock, UserConfirmations} from "../project-block";

interface TaskDetailsProjectBlockProps extends InformationBlockPropsBase {
  customerUrl: CustomerUrl | null;
  defaultAccessOnCreate: ProjectAccess;
  onCustomerChanged: (customerUrl: CustomerUrl | null) => void;
  onLocationChanged: (locationUrl: LocationUrl | null) => void;
  onProjectChanged: (projectUrl: ProjectUrl | null) => void;
  projectUrl: ProjectUrl | null;
  task: Task;
}

export const TaskDetailsProjectBlock = function (
  props: TaskDetailsProjectBlockProps,
): React.JSX.Element {
  const {
    customerUrl,
    defaultAccessOnCreate,
    onCustomerChanged,
    onLocationChanged,
    onProjectChanged,
    projectUrl,
    task,
  } = props;

  const projectLookup = useSelector(getProjectLookup);

  const handleDialogConfirmed = useCallback(
    (userConfirmations: UserConfirmations, project: Project) => {
      if (userConfirmations.workplaceConfirmDialogResponse) {
        onLocationChanged(project.relatedWorkplace);
      }
    },
    [onLocationChanged],
  );

  const computeConfirmDialogVisibility = useCallback(
    (selectedProjectUrl: ProjectUrl): ConfirmDialogVisibility => {
      const newProject = projectLookup(selectedProjectUrl);
      const showRelatedWorkplaceConfirmDialog =
        newProject?.relatedWorkplace !== task.relatedWorkplace;

      const showContactConfirmDialog = false;

      return {
        showContactConfirmDialog,
        showRelatedWorkplaceConfirmDialog,
      };
    },

    [projectLookup, task.relatedWorkplace],
  );

  const handleProjectChanged = useCallback(
    (newProjectUrl: ProjectUrl | null) => {
      if (newProjectUrl) {
        onProjectChanged(newProjectUrl);
        const project = newProjectUrl && projectLookup(newProjectUrl);
        if (!task.relatedWorkplace && project) {
          onLocationChanged(project.relatedWorkplace);
        }
      } else {
        onProjectChanged(newProjectUrl);
      }
    },
    [onLocationChanged, onProjectChanged, projectLookup, task.relatedWorkplace],
  );

  return (
    <ProjectBlock
      computeConfirmDialogVisibility={computeConfirmDialogVisibility}
      customerUrl={customerUrl}
      defaultAccessOnCreate={defaultAccessOnCreate}
      onCustomerChanged={onCustomerChanged}
      onDialogsConfirmed={handleDialogConfirmed}
      onProjectChanged={handleProjectChanged}
      projectUrl={projectUrl}
    />
  );
};
