import {notUndefined} from "@co-common-libs/utils";
import {getProductArray} from "@co-frontend-libs/redux";
import {useTheme} from "@material-ui/core";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";

export function DisplaySelectedProducts(props: SettingValueDisplayProps): React.JSX.Element | null {
  const {settingEntry} = props;

  const theme = useTheme();
  const products: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);

  const productArray = useSelector(getProductArray);
  const productInstances = useMemo(() => {
    return products
      .map((identifier) => productArray.find((m) => m.catalogNumber === identifier))
      .filter(notUndefined);
  }, [products, productArray]);

  const productNames = useMemo(() => {
    const names = productInstances.map((product) => `${product.catalogNumber}: ${product.name}`);
    names.sort();
    return names;
  }, [productInstances]);
  if (!productNames.length) {
    return null;
  }
  return (
    <div>
      <span
        style={{
          backgroundColor: theme.palette.grey[200],
          padding: 2,
          whiteSpace: "pre-wrap",
        }}
      >
        {productNames.join("\n")}
      </span>
    </div>
  );
}
