import {PureComponent} from "app-utils";
import React from "react";
import {Cell as ChartCell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import {capitalizeFirstLetter} from "./report-overview-table";

interface ReportPieProps {
  colors: {[identifier: string]: string};
  hours: {
    nonProductive: {
      [identifier: string]: {hours: number; name: string; percent: number};
    };
    nonProductiveSameColor: {
      [identifier: string]: {hours: number; name: string; percent: number};
    };
    productive: {
      [identifier: string]: {hours: number; name: string; percent: number};
    };
    total: number;
  };
}

export class ReportPie extends PureComponent<ReportPieProps> {
  render(): React.JSX.Element {
    const {colors, hours} = this.props;

    const chartColors = [colors.effective];
    const pieData = [
      {
        name: capitalizeFirstLetter(hours.productive.effective.name),
        value: hours.productive.effective.percent,
      },
    ];

    Object.keys(hours.nonProductive).forEach((key) => {
      const area = hours.nonProductive[key];
      pieData.push({
        name: capitalizeFirstLetter(area.name),
        value: area.percent,
      });
      chartColors.push(colors[key]);
    });

    Object.keys(hours.nonProductiveSameColor).forEach((key) => {
      const area = hours.nonProductiveSameColor[key];
      pieData.push({
        name: capitalizeFirstLetter(area.name),
        value: area.percent,
      });
      chartColors.push(colors[key]);
    });

    return (
      <ResponsiveContainer debounce={200} height={250} minWidth={200} width="100%">
        <PieChart height={250} width={200}>
          <Pie data={pieData} dataKey="value" innerRadius={0} labelLine={false} outerRadius={100}>
            {chartColors.map((cellColor, index) => {
              return <ChartCell fill={`#${cellColor}`} key={index} />;
            })}
          </Pie>
          <Legend align="right" layout="vertical" verticalAlign="top" />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
