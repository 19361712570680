import {
  CustomerUrl,
  FuelSurchargeKrPerLiterInvoiceData,
  FuelSurchargePricePercentInvoiceData,
  KrPerLiterDefaultFuelSurchargeUse,
  KrPerLiterMachineFuelSurchargeUse,
  KrPerLiterWorkTypeFuelSurchargeUse,
  MachineUrl,
  Patch,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUsesDict,
  PriceItemUseWithOrder,
  PricePercentDefaultFuelSurchargeUse,
  PricePercentMachineFuelSurchargeUse,
  PricePercentWorkTypeFuelSurchargeUse,
  ProductUsesDict,
  Task,
  Unit,
  UnitUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  getFuelSurchargeKrPerLiterInvoiceData,
  getFuelSurchargePricePercentInvoiceData,
  priceItemIsManualDistributionTime,
  priceItemIsTime,
  priceItemIsVisible,
} from "@co-common-libs/resources-utils";
import {
  HOUR_MINUTES,
  notNullOrUndefined,
  notUndefined,
  valuesSortedByOrderMember,
} from "@co-common-libs/utils";
import {
  actions,
  getCustomerSettings,
  getKrPerLiterDefaultFuelSurchargeUseArray,
  getKrPerLiterFuelSurchargeBasisArray,
  getKrPerLiterFuelSurchargeSpecificationEntryArray,
  getKrPerLiterFuelSurchargeSpecificationLookup,
  getKrPerLiterMachineFuelSurchargeUseArray,
  getKrPerLiterWorkTypeFuelSurchargeUseArray,
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getPricePercentDefaultFuelSurchargeUseArray,
  getPricePercentFuelSurchargeBasisArray,
  getPricePercentFuelSurchargeSpecificationEntryArray,
  getPricePercentFuelSurchargeSpecificationLookup,
  getPricePercentMachineFuelSurchargeUseArray,
  getPricePercentWorkTypeFuelSurchargeUseArray,
  getProductGroupLookup,
  getProductLookup,
  getTimerArray,
  getTimerLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Table, TableBody} from "@material-ui/core";
import {
  adjustMinutes,
  computeIntervalSums,
  getBreakTimer,
  getGenericPrimaryTimer,
  hasMultipleManualDistributionPriceItemUses,
  intervalMinutes,
  mergeIntervals,
} from "app-utils";
import _ from "lodash";
import React, {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {KrPerLiterSurchargeLine} from "./kr-per-liter-surcharge-line";
import {PriceItemUseLine} from "./price-item-use-line";
import {PricePercentSurchargeLine} from "./price-percent-surcharge-line";
import {ProductUseLine} from "./product-use-line";

interface FuelSurchargePricePercentIssueData {
  readonly [priceItemOrProductUrl: string]: {
    readonly text: string;
    readonly useRule: {
      readonly customer: CustomerUrl | null;
      readonly machine: MachineUrl | null;
      readonly variant: PriceGroupUrl | null;
      readonly workType: WorkTypeUrl | null;
    };
  };
}

type PricePercentFuelSurchargeUsePart = Partial<
  Pick<PricePercentMachineFuelSurchargeUse, "customer" | "machine" | "variant">
> &
  Partial<Pick<PricePercentWorkTypeFuelSurchargeUse, "customer" | "variant" | "workType">> &
  Pick<PricePercentDefaultFuelSurchargeUse, "customer">;

interface FuelSurchargeKrPerLiterIssueData {
  readonly [machine: string]: {
    readonly missingFuelConsumptionLiterPerHour: boolean;
    readonly missingSpecificationEntry: boolean;
    readonly text: string;
    readonly useRule: {
      readonly customer: CustomerUrl | null;
      readonly machine: MachineUrl | null;
      readonly variant: PriceGroupUrl | null;
      readonly workType: WorkTypeUrl | null;
    };
  };
}

type KrPerLiterFuelSurchargeUsePart = Partial<
  Pick<KrPerLiterMachineFuelSurchargeUse, "customer" | "machine" | "variant">
> &
  Partial<Pick<KrPerLiterWorkTypeFuelSurchargeUse, "customer" | "variant" | "workType">> &
  Pick<KrPerLiterDefaultFuelSurchargeUse, "customer">;

function hasWorkFromTimestamp(instance: {
  workFromTimestamp: string | null;
}): instance is {workFromTimestamp: string} {
  return instance.workFromTimestamp !== null;
}

function findTimeAfterMinutesForTimer(
  timer: string,
  priceItemUses: PriceItemUsesDict,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): number[] {
  return Object.values(priceItemUses)
    .filter((priceItemUse) => priceItemUse.timer === timer)
    .map(({priceItem}) => priceItemLookup(priceItem))
    .filter(notUndefined)
    .filter((priceItem) => priceItemIsTime(unitLookup, priceItem))
    .map(({timeAfterMinutes}) => timeAfterMinutes)
    .filter(notNullOrUndefined);
}

function findTimeAfterMinutesRange(
  timer: string,
  priceItem: PriceItem,
  priceItemUses: PriceItemUsesDict,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): {from: number; to: number | undefined} {
  const timeAfterMinutesForTimer = findTimeAfterMinutesForTimer(
    timer as string,
    priceItemUses,
    priceItemLookup,
    unitLookup,
  );
  if (priceItem.timeAfterMinutes || timeAfterMinutesForTimer.some(_.identity)) {
    const thisAfterMinutes = priceItem.timeAfterMinutes || 0;
    const nextTimeAfterMinutes = _.min(
      timeAfterMinutesForTimer.filter((n) => n > thisAfterMinutes),
    );
    console.assert(thisAfterMinutes || nextTimeAfterMinutes);
    return {from: thisAfterMinutes, to: nextTimeAfterMinutes};
  } else {
    return {from: 0, to: undefined};
  }
}

function computePerIntervalTimeAfterMinutesMinutes(
  priceItemUse: PriceItemUseWithOrder,
  priceItem: PriceItem,
  priceItemUses: PriceItemUsesDict,
  intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: string | null;
    readonly toTimestamp: string;
  }[],
  timerMinutes: ReadonlyMap<string, number>,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): number {
  const {from, to} = findTimeAfterMinutesRange(
    priceItemUse.timer as string,
    priceItem,
    priceItemUses,
    priceItemLookup,
    unitLookup,
  );
  if (from || to) {
    const relevantIntervals = intervals.filter((interval) => interval.timer === priceItemUse.timer);
    return _.sum(
      relevantIntervals
        .map((interval) => intervalMinutes(interval))
        .map((inInterval) => {
          const beforeNext = to ? Math.min(inInterval, to) : inInterval;
          return Math.max(beforeNext - from, 0);
        }),
    );
  } else {
    return timerMinutes.get(priceItemUse.timer as string) || 0;
  }
}

function comptuteOnSumTimeAfterMinutesMinutes(
  priceItemUse: PriceItemUseWithOrder,
  priceItem: PriceItem,
  priceItemUses: PriceItemUsesDict,
  timerMinutes: ReadonlyMap<string, number>,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): number {
  const {from, to} = findTimeAfterMinutesRange(
    priceItemUse.timer as string,
    priceItem,
    priceItemUses,
    priceItemLookup,
    unitLookup,
  );
  if (from || to) {
    const totalForTimer = timerMinutes.get(priceItemUse.timer as string) || 0;
    const beforeNext = to ? Math.min(totalForTimer, to) : totalForTimer;
    return Math.max(beforeNext - from, 0);
  } else {
    return timerMinutes.get(priceItemUse.timer as string) || 0;
  }
}

const invoiceLineTablePropKeysExceptTask = ["editDisabled", "striped"] as const;

const invoiceLineTablePropsAreEqual = (
  prevProps: InvoiceLineTableProps,
  nextProps: InvoiceLineTableProps,
): boolean => {
  for (let i = 0; i < invoiceLineTablePropKeysExceptTask.length; i += 1) {
    const key = invoiceLineTablePropKeysExceptTask[i];
    if (prevProps[key] !== nextProps[key]) {
      return false;
    }
  }
  return _.isEqual(prevProps.task, nextProps.task);
};

interface InvoiceLineTableProps {
  editDisabled: boolean;
  striped: boolean;
  task: Task & {
    readonly priceItemUses: PriceItemUsesDict;
    readonly productUses: ProductUsesDict;
  };
}

export const InvoiceLineTable = React.memo(function InvoiceLineTable(
  props: InvoiceLineTableProps,
): React.JSX.Element {
  const {editDisabled, striped = false, task} = props;

  const dispatch = useDispatch();

  const customerSettings = useSelector(getCustomerSettings);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const timerArray = useSelector(getTimerArray);
  const unitLookup = useSelector(getUnitLookup);
  const timerLookup = useSelector(getTimerLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);

  const intl = useIntl();

  const {enableInvoiceCorrections, fuelSurcharge} = customerSettings;

  const handlePriceItemCorrectedCountChange = useCallback(
    (identifier: string, value: number | null): void => {
      const patch: Patch<Task> = [{path: ["priceItemUses", identifier, "correctedCount"], value}];
      dispatch(actions.update(task.url, patch));
    },
    [dispatch, task.url],
  );

  const handleProductCorrectedCountChange = useCallback(
    (identifier: string, value: number | null): void => {
      const patch: Patch<Task> = [{path: ["productUses", identifier, "correctedCount"], value}];
      dispatch(actions.update(task.url, patch));
    },
    [dispatch, task.url],
  );

  const sortedPriceItemUses = useMemo(
    () =>
      Object.entries(task.priceItemUses)
        .map(([identifier, priceItemUse]) => ({
          identifier,
          priceItemUse,
        }))
        .sort((a, b) => a.priceItemUse.order - b.priceItemUse.order),
    [task.priceItemUses],
  );
  const filteredSortedPriceItemUses = useMemo(() => {
    const priceItemUseList = valuesSortedByOrderMember(task.priceItemUses);
    return sortedPriceItemUses.filter(({priceItemUse}) => {
      const priceItem = priceItemLookup(priceItemUse.priceItem);
      return (
        !!priceItem &&
        priceItemIsVisible(priceItem, true, priceItemUseList, unitLookup, priceItemLookup)
      );
    });
  }, [priceItemLookup, sortedPriceItemUses, task.priceItemUses, unitLookup]);

  const sortedProductUses = useMemo(
    () =>
      Object.entries(task.productUses)
        .map(([identifier, productUse]) => ({
          identifier,
          productUse,
        }))
        .sort((a, b) => a.productUse.order - b.productUse.order),
    [task.productUses],
  );

  const {department} = task;
  const allowMoreThanTwoMachines =
    customerSettings.alwaysAllowMoreThanTwoMachines ||
    customerSettings.allowMoreThanTwoMachinesForDepartments.includes(department);
  const usesDistributionTable = useMemo(
    () =>
      allowMoreThanTwoMachines &&
      hasMultipleManualDistributionPriceItemUses(task, priceItemLookup, unitLookup),
    [allowMoreThanTwoMachines, priceItemLookup, task, unitLookup],
  );

  const genericPrimaryTimer = getGenericPrimaryTimer(timerArray);
  const genericPrimaryTimerURL = genericPrimaryTimer ? genericPrimaryTimer.url : null;
  const breakTimer = getBreakTimer(timerArray);
  const breakTimerURL = breakTimer ? breakTimer.url : null;
  const taskURL = task.url;
  const computedIntervals = task.computedTimeSet;
  const correctionIntervals = task.machineOperatorTimeCorrectionSet;
  const managerCorrectionIntervals = task.managerTimeCorrectionSet;
  const intervals = useMemo(
    () => mergeIntervals(computedIntervals, correctionIntervals, managerCorrectionIntervals),
    [computedIntervals, correctionIntervals, managerCorrectionIntervals],
  );
  const timerMinutes = useMemo(() => computeIntervalSums(intervals), [intervals]);

  const priceItemUsesData = useMemo(
    () =>
      filteredSortedPriceItemUses
        .map((entry) => {
          const {identifier, priceItemUse} = entry;
          let minutes: number | null = null;
          const priceItemURL = priceItemUse.priceItem;
          const priceItem = priceItemLookup(priceItemURL);
          if (!priceItem) {
            return undefined;
          }
          let {count} = priceItemUse;
          const {correctedCount} = priceItemUse;
          const isDistributionTime = priceItemIsManualDistributionTime(unitLookup, priceItem);
          const isTime = priceItemIsTime(unitLookup, priceItem);
          if (usesDistributionTable && isDistributionTime) {
            if (count == null) {
              // blank in distribution table should count as zero...
              count = 0;
            }
            minutes = count;
            const adjustedMinutes = adjustMinutes(
              customerSettings.distributionTableAdjustTimeMethod,
              minutes,
            );
            count = adjustedMinutes / HOUR_MINUTES;
          } else if (
            count == null &&
            correctedCount == null &&
            !isTime &&
            priceItem.relevantForExecution !== false &&
            !priceItem.minimumCount
          ) {
            // Don't clutter the invoice table with unused optional priceitemuses
            // unless they have a minimum count
            return undefined;
          } else if (count == null && isTime) {
            if (priceItemUse.timer) {
              const timerUrl = priceItemUse.timer;
              const timer = timerLookup(timerUrl);
              const priceGroupUrl = timer?.priceGroup;
              const priceGroup = priceGroupUrl ? priceGroupLookup(priceGroupUrl) : undefined;
              if (priceGroup?.timeAfterMinutesEffect === "per_interval") {
                minutes = computePerIntervalTimeAfterMinutesMinutes(
                  priceItemUse,
                  priceItem,
                  task.priceItemUses,
                  intervals,
                  timerMinutes,
                  priceItemLookup,
                  unitLookup,
                );
              } else if (priceGroup?.timeAfterMinutesEffect === "on_sum") {
                minutes = comptuteOnSumTimeAfterMinutesMinutes(
                  priceItemUse,
                  priceItem,
                  task.priceItemUses,
                  timerMinutes,
                  priceItemLookup,
                  unitLookup,
                );
              } else {
                minutes = timerMinutes.get(priceItemUse.timer) || 0;
              }
            }
            if (minutes == null) {
              minutes = (genericPrimaryTimerURL && timerMinutes.get(genericPrimaryTimerURL)) || 0;
              if (customerSettings.billedBreaks && breakTimerURL) {
                minutes += timerMinutes.get(breakTimerURL) || 0;
              }
            }
            const adjustedMinutes = adjustMinutes(customerSettings.adjustBilledMinutes, minutes);
            count = adjustedMinutes / HOUR_MINUTES;
          } else if (isTime) {
            minutes = count;
            count = (minutes || 0) / HOUR_MINUTES;
          }
          return {
            correctedCount,
            count,
            identifier,
            minutes,
            priceItem,
          };
        })
        .filter(notUndefined),
    [
      breakTimerURL,
      customerSettings.adjustBilledMinutes,
      customerSettings.billedBreaks,
      customerSettings.distributionTableAdjustTimeMethod,
      genericPrimaryTimerURL,
      intervals,
      priceGroupLookup,
      priceItemLookup,
      filteredSortedPriceItemUses,
      task.priceItemUses,
      timerLookup,
      timerMinutes,
      unitLookup,
      usesDistributionTable,
    ],
  );

  const priceItemUseEntries = priceItemUsesData.map(
    ({correctedCount, count, identifier, minutes, priceItem}) => (
      <PriceItemUseLine
        correctedCount={correctedCount ?? undefined}
        count={count ?? undefined}
        editDisabled={editDisabled}
        identifier={identifier}
        invoiceCorrectionEnabled={enableInvoiceCorrections}
        key={`${taskURL}-priceItem-${identifier}`}
        minutes={minutes ?? undefined}
        onChange={handlePriceItemCorrectedCountChange}
        priceItem={priceItem}
        striped={striped}
      />
    ),
  );

  const productUsesData = useMemo(
    () =>
      sortedProductUses
        .map((entry) => {
          const {identifier, productUse} = entry;
          if (!productUse.ours) {
            return undefined;
          }
          const {correctedCount, count} = productUse;
          if (count == null && correctedCount == null) {
            return undefined;
          }
          const productURL = productUse.product;
          const product = productLookup(productURL);
          if (!product) {
            return undefined;
          }
          return {
            correctedCount: productUse.correctedCount,
            count,
            identifier,
            notes: productUse.notes,
            product,
          };
        })
        .filter(notUndefined),
    [productLookup, sortedProductUses],
  );

  const productUseEntries = productUsesData.map(
    ({correctedCount, count, identifier, notes, product}) => (
      <ProductUseLine
        correctedCount={correctedCount ?? undefined}
        count={count ?? undefined}
        editDisabled={editDisabled}
        identifier={identifier}
        invoiceCorrectionEnabled={enableInvoiceCorrections}
        key={`${taskURL}-product-${identifier}`}
        note={notes}
        onChange={handleProductCorrectedCountChange}
        product={product}
        striped={striped}
      />
    ),
  );

  const orderLookup = useSelector(getOrderLookup);

  const pricePercentFuelSurchargeSpecificationLookup = useSelector(
    getPricePercentFuelSurchargeSpecificationLookup,
  );
  const pricePercentMachineFuelSurchargeUseArray = useSelector(
    getPricePercentMachineFuelSurchargeUseArray,
  );
  const pricePercentWorkTypeFuelSurchargeUseArray = useSelector(
    getPricePercentWorkTypeFuelSurchargeUseArray,
  );
  const pricePercentDefaultFuelSurchargeUseArray = useSelector(
    getPricePercentDefaultFuelSurchargeUseArray,
  );
  const pricePercentFuelSurchargeSpecificationEntryArray = useSelector(
    getPricePercentFuelSurchargeSpecificationEntryArray,
  );
  const pricePercentFuelSurchargeBasisArray = useSelector(getPricePercentFuelSurchargeBasisArray);

  const machineLookup = useSelector(getMachineLookup);

  const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
    getKrPerLiterFuelSurchargeSpecificationLookup,
  );
  const krPerLiterMachineFuelSurchargeUseArray = useSelector(
    getKrPerLiterMachineFuelSurchargeUseArray,
  );
  const krPerLiterWorkTypeFuelSurchargeUseArray = useSelector(
    getKrPerLiterWorkTypeFuelSurchargeUseArray,
  );
  const krPerLiterDefaultFuelSurchargeUseArray = useSelector(
    getKrPerLiterDefaultFuelSurchargeUseArray,
  );
  const krPerLiterFuelSurchargeSpecificationEntryArray = useSelector(
    getKrPerLiterFuelSurchargeSpecificationEntryArray,
  );
  const krPerLiterFuelSurchargeBasisArray = useSelector(getKrPerLiterFuelSurchargeBasisArray);

  const fuelSurchargePricePercentInvoiceData = useMemo(():
    | {
        issues: FuelSurchargePricePercentIssueData;
        pricePercent: FuelSurchargePricePercentInvoiceData;
      }
    | undefined => {
    if (task.recordedInC5 && task.invoiceData?.fuelSurcharge) {
      if (task.invoiceData.fuelSurcharge.pricePercent) {
        return {
          issues: {},
          pricePercent: task.invoiceData.fuelSurcharge.pricePercent,
        };
      } else {
        return undefined;
      }
    } else if (fuelSurcharge === "PRICE_PERCENT" && hasWorkFromTimestamp(task)) {
      const order = task.order ? orderLookup(task.order) : null;
      const customerUrl = order ? order.customer : null;
      const data = getFuelSurchargePricePercentInvoiceData(
        intl,
        priceItemLookup,
        productLookup,
        productGroupLookup,
        pricePercentFuelSurchargeSpecificationLookup,
        pricePercentMachineFuelSurchargeUseArray,
        pricePercentWorkTypeFuelSurchargeUseArray,
        pricePercentDefaultFuelSurchargeUseArray,
        pricePercentFuelSurchargeSpecificationEntryArray,
        pricePercentFuelSurchargeBasisArray,
        task,
        customerUrl,
      );
      const issues: FuelSurchargePricePercentIssueData = Object.fromEntries(
        Array.from(data.issues).map(
          ([priceItemOrProductUrl, {specification, use}]): [
            string,
            FuelSurchargePricePercentIssueData[string],
          ] => {
            const usePart: PricePercentFuelSurchargeUsePart = use;
            const product = specification.invoiceLineProduct
              ? productLookup(specification.invoiceLineProduct)
              : null;
            const text = product ? product.name : specification.name;
            return [
              priceItemOrProductUrl,
              {
                text,
                useRule: {
                  customer: usePart.customer,
                  machine: usePart.machine || null,
                  variant: usePart.variant || null,
                  workType: usePart.workType || null,
                },
              },
            ];
          },
        ),
      );
      return {issues, pricePercent: data.pricePercent};
    } else {
      return undefined;
    }
  }, [
    fuelSurcharge,
    intl,
    orderLookup,
    priceItemLookup,
    pricePercentDefaultFuelSurchargeUseArray,
    pricePercentFuelSurchargeBasisArray,
    pricePercentFuelSurchargeSpecificationEntryArray,
    pricePercentFuelSurchargeSpecificationLookup,
    pricePercentMachineFuelSurchargeUseArray,
    pricePercentWorkTypeFuelSurchargeUseArray,
    productGroupLookup,
    productLookup,
    task,
  ]);

  const percentFuelSurchargeData = useMemo(():
    | {
        readonly key: string;
        readonly missingSpecificationEntry: boolean;
        readonly text: string;
        readonly uses: ReadonlySet<{
          readonly customer: CustomerUrl | null;
          readonly machine?: MachineUrl | null;
          readonly variant?: PriceGroupUrl | null;
          readonly workType?: WorkTypeUrl | null;
        }>;
        readonly value: number | null;
      }[]
    | undefined => {
    if (!fuelSurchargePricePercentInvoiceData) {
      return undefined;
    }
    const specificationsUses = new Map<
      string,
      {
        readonly text: string;
        readonly uses: Map<
          string,
          {
            readonly customer: CustomerUrl | null;
            readonly machine?: MachineUrl | null;
            readonly variant?: PriceGroupUrl | null;
            readonly workType?: WorkTypeUrl | null;
          }
        >;
        readonly value: number | null;
      }
    >();
    Object.values(fuelSurchargePricePercentInvoiceData.pricePercent).forEach(
      ({resultingPercent: value, text, useRule}) => {
        const key = `${text}-${value}`;
        const useKey = `${useRule.customer}\0${useRule.machine}\0${useRule.variant}\0${useRule.workType}`;
        const existingEntry = specificationsUses.get(key);
        if (existingEntry) {
          existingEntry.uses.set(useKey, useRule);
        } else {
          specificationsUses.set(key, {
            text,
            uses: new Map([[useKey, useRule]]),
            value,
          });
        }
      },
    );
    const issueSpecificationsUses = new Map<
      string,
      {
        readonly text: string;
        readonly uses: Map<
          string,
          {
            readonly customer: CustomerUrl | null;
            readonly machine?: MachineUrl | null;
            readonly variant?: PriceGroupUrl | null;
            readonly workType?: WorkTypeUrl | null;
          }
        >;
      }
    >();
    Object.values(fuelSurchargePricePercentInvoiceData.issues).forEach(({text, useRule}) => {
      const key = text;
      const useKey = `${useRule.customer}\0${useRule.machine}\0${useRule.variant}\0${useRule.workType}`;
      const existingEntry = issueSpecificationsUses.get(key);
      if (existingEntry) {
        existingEntry.uses.set(useKey, useRule);
      } else {
        issueSpecificationsUses.set(key, {
          text,
          uses: new Map([[useKey, useRule]]),
        });
      }
    });
    return Array.from(specificationsUses)
      .map(([key, {text, uses, value}]) => ({
        key,
        missingSpecificationEntry: false,
        text,
        uses: new Set(uses.values()),
        value,
      }))
      .concat(
        Array.from(issueSpecificationsUses).map(([key, {text, uses}]) => ({
          key,
          missingSpecificationEntry: true,
          text,
          uses: new Set(uses.values()),
          value: null,
        })),
      );
  }, [fuelSurchargePricePercentInvoiceData]);

  let surchargeEntries: React.JSX.Element[] | undefined;

  if (percentFuelSurchargeData) {
    surchargeEntries = percentFuelSurchargeData.map(
      ({key, missingSpecificationEntry, text, uses, value}) => {
        return (
          <PricePercentSurchargeLine
            invoiceCorrectionEnabled={enableInvoiceCorrections}
            key={key}
            missingSpecificationEntry={missingSpecificationEntry}
            text={text}
            uses={uses}
            value={value}
          />
        );
      },
    );
  }

  const fuelSurchargeKrPerLiterInvoiceData = useMemo(():
    | {
        issues: FuelSurchargeKrPerLiterIssueData;
        krPerLiter: FuelSurchargeKrPerLiterInvoiceData;
      }
    | undefined => {
    if (task.recordedInC5 && task.invoiceData?.fuelSurcharge) {
      if (task.invoiceData.fuelSurcharge.krPerLiter) {
        return {
          issues: {},
          krPerLiter: task.invoiceData.fuelSurcharge.krPerLiter,
        };
      } else {
        return undefined;
      }
    } else if (fuelSurcharge === "KR_PER_LITER" && hasWorkFromTimestamp(task)) {
      const order = task.order ? orderLookup(task.order) : null;
      const customerUrl = order ? order.customer : null;
      const data = getFuelSurchargeKrPerLiterInvoiceData(
        intl,
        machineLookup,
        productLookup,
        krPerLiterFuelSurchargeSpecificationLookup,
        krPerLiterMachineFuelSurchargeUseArray,
        krPerLiterWorkTypeFuelSurchargeUseArray,
        krPerLiterDefaultFuelSurchargeUseArray,
        krPerLiterFuelSurchargeSpecificationEntryArray,
        krPerLiterFuelSurchargeBasisArray,
        task,
        customerUrl,
      );
      const issues: FuelSurchargeKrPerLiterIssueData = Object.fromEntries(
        Array.from(data.issues).map(
          ([
            priceItemOrProductUrl,
            {fuelConsumptionLiterPerHour, specification, specificationEntry, use},
          ]): [string, FuelSurchargeKrPerLiterIssueData[MachineUrl]] => {
            const usePart: KrPerLiterFuelSurchargeUsePart = use;
            const product = specification.invoiceLineProduct
              ? productLookup(specification.invoiceLineProduct)
              : null;
            const text = product ? product.name : specification.name;
            return [
              priceItemOrProductUrl,
              {
                missingFuelConsumptionLiterPerHour: fuelConsumptionLiterPerHour === null,
                missingSpecificationEntry: specificationEntry === null,
                text,
                useRule: {
                  customer: usePart.customer,
                  machine: usePart.machine || null,
                  variant: usePart.variant || null,
                  workType: usePart.workType || null,
                },
              },
            ];
          },
        ),
      );
      return {issues, krPerLiter: data.krPerLiter};
    } else {
      return undefined;
    }
  }, [
    fuelSurcharge,
    intl,
    krPerLiterDefaultFuelSurchargeUseArray,
    krPerLiterFuelSurchargeBasisArray,
    krPerLiterFuelSurchargeSpecificationEntryArray,
    krPerLiterFuelSurchargeSpecificationLookup,
    krPerLiterMachineFuelSurchargeUseArray,
    krPerLiterWorkTypeFuelSurchargeUseArray,
    machineLookup,
    orderLookup,
    productLookup,
    task,
  ]);

  const krPerLiterFuelSurchargeData = useMemo(():
    | {
        readonly key: string;
        readonly missingFuelConsumptionLiterPerHour: boolean;
        readonly missingSpecificationEntry: boolean;
        readonly text: string;
        readonly uses: ReadonlySet<{
          readonly customer: CustomerUrl | null;
          readonly machine?: MachineUrl | null;
          readonly variant?: PriceGroupUrl | null;
          readonly workType?: WorkTypeUrl | null;
        }>;
        readonly value: number | null;
      }[]
    | undefined => {
    if (!fuelSurchargeKrPerLiterInvoiceData) {
      return undefined;
    }
    const specificationsUses = new Map<
      string,
      {
        readonly text: string;
        readonly uses: Map<
          string,
          {
            readonly customer: CustomerUrl | null;
            readonly machine?: MachineUrl | null;
            readonly variant?: PriceGroupUrl | null;
            readonly workType?: WorkTypeUrl | null;
          }
        >;
        readonly value: number | null;
      }
    >();
    Object.values(fuelSurchargeKrPerLiterInvoiceData.krPerLiter).forEach(
      ({krPerLiter: value, text, useRule}) => {
        const key = `${text}-${value}`;
        const useKey = `${useRule.customer}\0${useRule.machine}\0${useRule.variant}\0${useRule.workType}`;
        const existingEntry = specificationsUses.get(key);
        if (existingEntry) {
          existingEntry.uses.set(useKey, useRule);
        } else {
          specificationsUses.set(key, {
            text,
            uses: new Map([[useKey, useRule]]),
            value,
          });
        }
      },
    );
    const issueSpecificationsUses = new Map<
      string,
      {
        readonly missingFuelConsumptionLiterPerHour: boolean;
        readonly missingSpecificationEntry: boolean;
        readonly text: string;
        readonly uses: Map<
          string,
          {
            readonly customer: CustomerUrl | null;
            readonly machine?: MachineUrl | null;
            readonly variant?: PriceGroupUrl | null;
            readonly workType?: WorkTypeUrl | null;
          }
        >;
      }
    >();
    Object.values(fuelSurchargeKrPerLiterInvoiceData.issues).forEach(
      ({missingFuelConsumptionLiterPerHour, missingSpecificationEntry, text, useRule}) => {
        const key = text;
        const useKey = `${useRule.customer}\0${useRule.machine}\0${useRule.variant}\0${useRule.workType}`;
        const existingEntry = issueSpecificationsUses.get(key);
        if (existingEntry) {
          existingEntry.uses.set(useKey, useRule);
        } else {
          issueSpecificationsUses.set(key, {
            missingFuelConsumptionLiterPerHour,
            missingSpecificationEntry,
            text,
            uses: new Map([[useKey, useRule]]),
          });
        }
      },
    );
    return Array.from(specificationsUses)
      .map(([key, {text, uses, value}]) => ({
        key,
        missingFuelConsumptionLiterPerHour: false,
        missingSpecificationEntry: false,
        text,
        uses: new Set(uses.values()),
        value,
      }))
      .concat(
        Array.from(issueSpecificationsUses).map(
          ([key, {missingFuelConsumptionLiterPerHour, missingSpecificationEntry, text, uses}]) => ({
            key,
            missingFuelConsumptionLiterPerHour,
            missingSpecificationEntry,
            text,
            uses: new Set(uses.values()),
            value: null,
          }),
        ),
      );
  }, [fuelSurchargeKrPerLiterInvoiceData]);

  if (krPerLiterFuelSurchargeData) {
    surchargeEntries = krPerLiterFuelSurchargeData.map(
      ({key, missingFuelConsumptionLiterPerHour, missingSpecificationEntry, text, uses, value}) => {
        return (
          <KrPerLiterSurchargeLine
            invoiceCorrectionEnabled={enableInvoiceCorrections}
            key={key}
            missingFuelConsumptionLiterPerHour={missingFuelConsumptionLiterPerHour}
            missingSpecificationEntry={missingSpecificationEntry}
            text={text}
            uses={uses}
            value={value}
          />
        );
      },
    );
  }

  return (
    <Table>
      <TableBody>
        {priceItemUseEntries}
        {productUseEntries}
        {surchargeEntries}
      </TableBody>
    </Table>
  );
}, invoiceLineTablePropsAreEqual);
