import {Order, RouteTaskUrl, Task} from "@co-common-libs/resources";
import React from "react";
import {LogTabContent} from "./log-tab-content";

export {computeSums} from "./compute-sums";
export {getRouteTaskData} from "./get-route-task-data";

interface LogTabProps {
  hasActivity: boolean;
  onEditClick: (routeTaskUrl: RouteTaskUrl) => void;
  onRequestBuildReport: () => void;
  order?: Order | undefined;
  task: Task;
}

export const LogTab = React.memo(function LogTab(props: LogTabProps): React.JSX.Element {
  const {hasActivity, onEditClick, onRequestBuildReport, order, task} = props;

  if (task.completed) {
    return (
      <LogTabContent
        hasActivity={hasActivity}
        onEditClick={onEditClick}
        onRequestBuildReport={onRequestBuildReport}
        task={task}
      />
    );
  } else if (order) {
    if (!order.routePlan) {
      throw new Error("no routePlan on order");
    }
    return (
      <LogTabContent
        hasActivity={hasActivity}
        onEditClick={onEditClick}
        onRequestBuildReport={onRequestBuildReport}
        routePlanUrl={order.routePlan}
        task={task}
      />
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
});
