import {CustomerUrl, LocationTypeUrl, LocationUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface Location extends ResourceInstance<LocationUrl> {
  readonly active: boolean;
  readonly address: string;
  readonly attention: string;
  readonly barred: boolean | undefined;
  readonly c5_recid: number | null;
  readonly cellphone: string;
  readonly city: string;
  readonly coordinatesFromAddress: boolean | null;
  readonly created: string | null;
  readonly customer: CustomerUrl | null;
  readonly email: string;
  readonly favorite: boolean;
  readonly fieldAreaHa: number | null;
  readonly fieldBlock: string;
  readonly fieldCrop: string;
  readonly fieldDataChanged: string | null;
  readonly fieldFromUpload: boolean;
  readonly fieldJournalNumber: string;
  readonly fieldNumber: string;
  readonly fieldRecordYear: number | null;
  readonly fieldVatNumber: string;
  readonly geojson: {
    geometry: {
      bbox: [number, number, number, number];
      coordinates: [number, number][][] | [number, number][][][];
      type: "MultiPolygon" | "Polygon";
    };
    properties?: {[key: string]: unknown};
    type: "Feature";
  } | null;
  readonly latitude: number | null;
  readonly locationType: LocationTypeUrl | null;
  readonly logOnlyLocation: boolean;
  readonly longitude: number | null;
  readonly mergedTo: LocationUrl | null;
  readonly name: string;
  readonly phone: string;
  readonly postalCode: string;
  readonly remoteUrl: string;
  readonly workplaceOnlyLocation: boolean;
}

export const emptyLocation: Omit<Location, "id" | "url"> = {
  active: true,
  address: "",
  attention: "",
  barred: false,
  c5_recid: null,
  cellphone: "",
  city: "",
  coordinatesFromAddress: false,
  created: null,
  customer: null,
  email: "",
  favorite: false,
  fieldAreaHa: null,
  fieldBlock: "",
  fieldCrop: "",
  fieldDataChanged: null,
  fieldFromUpload: false,
  fieldJournalNumber: "",
  fieldNumber: "",
  fieldRecordYear: null,
  fieldVatNumber: "",
  geojson: null,
  latitude: null,
  locationType: null,
  logOnlyLocation: false,
  longitude: null,
  mergedTo: null,
  name: "",
  phone: "",
  postalCode: "",
  remoteUrl: "",
  workplaceOnlyLocation: false,
};
