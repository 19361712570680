import {EmployeeGroup} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions, diffResourceInstanceProperties} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {simpleObjectReducer} from "app-utils";
import React, {useCallback, useMemo, useReducer} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {getEditFormValidator} from "./edit-form-validator";
import {EmployeeGroupForm} from "./employee-group-form";

export const EmployeeGroupEditDialog = React.memo(function EmployeeGroupEditDialog(props: {
  employeeGroup: EmployeeGroup;
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}): React.JSX.Element {
  const {employeeGroup: initialEmployeeGroup, onCancel, onOk, open} = props;
  const [employeeGroup, reducerDispatch] = useReducer(
    simpleObjectReducer<EmployeeGroup>,
    initialEmployeeGroup,
  );
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleSaveClick = useCallback(() => {
    const updatedProperties = diffResourceInstanceProperties(employeeGroup, initialEmployeeGroup, [
      "name",
      "active",
    ]);
    if (updatedProperties) {
      dispatch(actions.update(employeeGroup.url, updatedProperties));
    }
    onOk();
  }, [employeeGroup, initialEmployeeGroup, onOk, dispatch]);

  const validator = useMemo(
    () => getEditFormValidator(intl, initialEmployeeGroup.url),
    [initialEmployeeGroup.url, intl],
  );

  const validationResult = validator.safeParse(employeeGroup);
  const errors = validationResult.success ? null : validationResult.error;

  return (
    <ResponsiveDialog
      cancelLabel={intl.formatMessage({defaultMessage: "Fortryd"})}
      okDisabled={!validationResult.success}
      okLabel={intl.formatMessage({defaultMessage: "Gem"})}
      onCancel={onCancel}
      onOk={handleSaveClick}
      open={open}
      title={intl.formatMessage({defaultMessage: "Redigér medarbejdergruppe"})}
    >
      <DialogContent>
        <EmployeeGroupForm
          dispatch={reducerDispatch}
          employeeGroup={employeeGroup}
          validationError={errors}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
