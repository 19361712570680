import {ResponsiveInfoDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import {MachineRemovalBlockedReason} from "app-utils";
import React from "react";
import {FormattedMessage} from "react-intl";

interface MachineRemovalBlockedDialogProp {
  blockedReason: MachineRemovalBlockedReason | null;
  onClose: () => void;
  open: boolean;
}

export const MachineRemovalBlockedDialog = React.memo(function MachineRemovalBlockedDialog(
  props: MachineRemovalBlockedDialogProp,
): React.JSX.Element {
  const {blockedReason, onClose, open} = props;
  return (
    <ResponsiveInfoDialog
      onClose={onClose}
      open={open}
      title={<FormattedMessage defaultMessage="Du kan ikke fjerne maskinen" />}
    >
      <DialogContent>
        {blockedReason === "extraTimer" ? (
          <FormattedMessage defaultMessage="Der er målt tid på på en timer der tilhører maskinen." />
        ) : null}
        {blockedReason === "log" ? (
          <FormattedMessage defaultMessage="Der er udfyldt logdata på opgaven." />
        ) : null}
      </DialogContent>
    </ResponsiveInfoDialog>
  );
});
