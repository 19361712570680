import {Unit, UnitUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

const messages = defineMessages({
  searchUnit: {
    defaultMessage: "Søg enhed",
    id: "uinit-dialog.dialog-title.search-uinit",
  },
  selectUnits: {
    defaultMessage: "Vælg enheder",
    id: "uinit-dialog.dialog-title.select-uinit",
  },
});

function computeBaseChoices(unitArray: readonly Unit[]): readonly EntryData<UnitUrl>[] {
  const result: EntryData<UnitUrl>[] = unitArray
    .filter((instance) => instance.active)
    .map((instance) => {
      const {name, symbol, url} = instance;
      const entry: EntryData<UnitUrl> = {
        category: "standard",
        exactMatchValue: name,
        identifier: url,
        primaryText: name,
        searchFields: [{label: "Navn", priority: 5, text: name}],
        secondaryText: symbol,
      };
      return entry;
    });
  return result;
}

interface MultipleUnitDialogProps {
  onCancel(): void;
  onOk(urls: ReadonlySet<UnitUrl>): void;
  open: boolean;
  selected?: ReadonlySet<UnitUrl> | undefined;
  unitArray: readonly Unit[];
}

export const MultipleUnitDialog = React.memo(function MultipleUnitDialog(
  props: MultipleUnitDialogProps,
): React.JSX.Element {
  const {formatMessage} = useIntl();
  const {onCancel, onOk, open, selected, unitArray} = props;

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(unitArray);

  const title = formatMessage(messages.selectUnits);
  const searchTitle = formatMessage(messages.searchUnit);
  const selectedSet = selected;
  return (
    <GenericMultiSelectionSearchDialog<UnitUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      selected={selectedSet}
      title={title}
    />
  );
});

interface SingleUnitDialogProps {
  onCancel(): void;
  onOk(url: UnitUrl): void;
  open: boolean;
  unitArray: readonly Unit[];
}

export const SingleUnitDialog = React.memo(function SingleUnitDialog(
  props: SingleUnitDialogProps,
): React.JSX.Element {
  const {formatMessage} = useIntl();
  const {onCancel, onOk, open, unitArray} = props;

  const [doComputeBaseChoices, reuseBaseChoices] = React.useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(unitArray);

  const title = formatMessage(messages.selectUnits);
  const searchTitle = formatMessage(messages.searchUnit);
  return (
    <GenericSingleSelectionSearchDialog<UnitUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      title={title}
    />
  );
});
