import {PriceItem, Product, Unit, UnitUrl} from "@co-common-libs/resources";
import {getUnitString, priceItemIsTime} from "@co-common-libs/resources-utils";
import {TableCell, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedNumber} from "react-intl";

interface DisplaySharedTotalsRowProps {
  count?: number;
  deliveryCount?: number | undefined;
  instance: PriceItem | Product;
  isProduct: boolean;
  loads: number | undefined;
  showLoads: boolean;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export function DisplaySharedTotalsRow(
  props: DisplaySharedTotalsRowProps,
): React.JSX.Element | null {
  const {count, deliveryCount, instance, isProduct, loads, showLoads, unitLookup} = props;
  if (!isProduct) {
    if (
      (instance as PriceItem).relevantForExecution === false ||
      priceItemIsTime(unitLookup, instance as PriceItem)
    ) {
      return null;
    }
  }
  const {name} = instance;
  const unit = getUnitString(instance, unitLookup);
  const label = unit ? `${name}, ${unit}` : name;
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <FormattedNumber maximumFractionDigits={2} value={count || 0} />
      </TableCell>
      <TableCell>
        <FormattedNumber maximumFractionDigits={2} value={deliveryCount || 0} />
      </TableCell>
      {showLoads ? <TableCell>{loads}</TableCell> : null}
      <TableCell>
        <FormattedNumber
          maximumFractionDigits={2}
          value={count ? count - (deliveryCount ?? 0) : 0}
        />
      </TableCell>
    </TableRow>
  );
}
