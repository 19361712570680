import {
  CalculatorSpecifications,
  PriceGroup,
  PriceItemUsesDict,
  ProductUrl,
  ProductUsesDict,
  WorkType,
} from "@co-common-libs/resources";
import {getAutoProductsMapping} from "@co-common-libs/resources-utils";
import {notNullOrUndefined, notUndefined} from "@co-common-libs/utils";
import {ConnectedSingleProductDialog} from "@co-frontend-libs/connected-components";
import {
  getCustomerSettings,
  getPriceItemLookup,
  getProductGroupLookup,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {buildConversionRelatedValues, makeSortedProductUsesWithData} from "app-utils";
import bowser from "bowser";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {DisplayGrid} from "./display-grid";
import {DisplayTable} from "./display-table";

// NTS: deleteDisabled only used for generic log input...

interface ProductTableProps {
  calculators?: CalculatorSpecifications | undefined;
  extraConversionRelatedValues?:
    | {
        readonly [unit: string]: number | null;
      }
    | undefined;
  onCountChange: (identifier: string, value: number | null) => void;
  onDeleteClick?: (identifier: string) => void;
  onNotesChange?: (identifier: string, value: string) => void;
  onOursChange: (identifier: string, value: boolean) => void;
  onSwitchProduct?: (identifier: string, productUrl: ProductUrl) => void;
  priceItemUses: PriceItemUsesDict;
  productDialogPreferredProductURLs?: readonly ProductUrl[] | undefined;
  productDialogPriceGroups?: readonly PriceGroup[];
  productDialogWorkType?: WorkType | undefined;
  productsWithLogData?: ReadonlySet<ProductUrl> | undefined;
  productUses: ProductUsesDict;
  readonly?: boolean;
  readonlyProducts?: ReadonlySet<string>;
  showEvenIfEmpty?: boolean;
  showNotes?: boolean;
  withSwitchProduct?: boolean;
}

export const ProductTable = React.memo(function ProductTable(
  props: ProductTableProps,
): React.JSX.Element | null {
  const {
    calculators,
    extraConversionRelatedValues,
    onCountChange,
    onDeleteClick,
    onNotesChange,
    onOursChange,
    onSwitchProduct,
    priceItemUses,
    productDialogPreferredProductURLs,
    productDialogPriceGroups,
    productDialogWorkType,
    productsWithLogData,
    productUses,
    readonly,
    readonlyProducts,
    showEvenIfEmpty,
    showNotes,
    withSwitchProduct,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);
  const productLookup = useSelector(getProductLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);

  const {autoCopyMaterialNoteToSupplementingProductNote} = customerSettings;

  const sortedPriceItemUsesWithData = useMemo(
    () =>
      _.sortBy(
        Object.entries(priceItemUses)
          .map(([identifier, priceItemUse]) => {
            const priceItem = priceItemLookup(priceItemUse.priceItem);
            if (!priceItem) {
              return undefined;
            }
            const unit = priceItem.relatedUnit ? unitLookup(priceItem.relatedUnit) || null : null;
            const conversionUnit =
              priceItem.conversionUnit && priceItem.conversionFactor
                ? unitLookup(priceItem.conversionUnit) || null
                : null;
            return {conversionUnit, identifier, priceItem, priceItemUse, unit};
          })
          .filter(notUndefined),
        (entry) => entry.priceItemUse.order,
      ),
    [priceItemLookup, priceItemUses, unitLookup],
  );

  const conversionRelatedKeys = useMemo(
    () => Object.keys(customerSettings.priceItemRelationUnitConversionHelpers),
    [customerSettings.priceItemRelationUnitConversionHelpers],
  );

  const conversionRelatedValues = useMemo(
    () =>
      buildConversionRelatedValues(
        conversionRelatedKeys,
        sortedPriceItemUsesWithData,
        extraConversionRelatedValues,
      ),
    [conversionRelatedKeys, extraConversionRelatedValues, sortedPriceItemUsesWithData],
  );

  const conversionRelatedInputUnits = useMemo(
    () =>
      conversionRelatedValues
        ? new Set(
            Object.entries(customerSettings.priceItemRelationUnitConversionHelpers)
              .filter(([baseUnit, _inputUnits]) => conversionRelatedValues[baseUnit] !== undefined)
              .flatMap(([_baseUnit, inputUnits]) => inputUnits),
          )
        : null,
    [conversionRelatedValues, customerSettings.priceItemRelationUnitConversionHelpers],
  );

  const {autoLinesToLines, linesToAutoLines} = useMemo(
    () => getAutoProductsMapping(productUses, productLookup, productGroupLookup, customerSettings),
    [customerSettings, productGroupLookup, productLookup, productUses],
  );

  const autoProductUses = useMemo(() => {
    if (autoLinesToLines) {
      return new Set(autoLinesToLines.keys());
    } else {
      return null;
    }
  }, [autoLinesToLines]);

  const sortedProductUsesWithData = useMemo(
    () =>
      makeSortedProductUsesWithData(
        productUses,
        productLookup,
        unitLookup,
        autoProductUses,
        readonly,
        readonlyProducts,
        customerSettings,
        productsWithLogData,
        calculators,
      ),
    [
      autoProductUses,
      calculators,
      customerSettings,
      productLookup,
      productUses,
      productsWithLogData,
      readonly,
      readonlyProducts,
      unitLookup,
    ],
  );

  const showRelationConversionColumns = useMemo(
    () =>
      conversionRelatedInputUnits
        ? sortedProductUsesWithData.some(({conversionUnit, unit}) => {
            if (conversionUnit) {
              return conversionRelatedInputUnits.has(conversionUnit.name);
            } else if (unit) {
              return conversionRelatedInputUnits.has(unit.name);
            } else {
              return false;
            }
          })
        : false,
    [conversionRelatedInputUnits, sortedProductUsesWithData],
  );

  const showIconColumn = !!(
    calculators &&
    sortedProductUsesWithData.some(({unit}) => unit && calculators[unit.name.toLocaleLowerCase()])
  );

  const handleCountChangeWithAutoProducts = useCallback(
    (identifier: string, value: number | null): void => {
      onCountChange(identifier, value);
      const autoLines = linesToAutoLines?.get(identifier);
      if (autoLines) {
        for (const autoIdentifier of autoLines) {
          const sourcesForAutoIdentifiers = autoLinesToLines?.get(autoIdentifier) as string[];
          console.assert(sourcesForAutoIdentifiers);
          console.assert(sourcesForAutoIdentifiers.includes(identifier));
          if (sourcesForAutoIdentifiers.length === 1) {
            onCountChange(autoIdentifier, value);
          } else {
            const values = sourcesForAutoIdentifiers.map((id) =>
              id === identifier ? value : productUses[id]?.count,
            );
            const numberValues = values.filter(notNullOrUndefined);
            if (numberValues.length) {
              onCountChange(autoIdentifier, _.sum(numberValues));
            } else {
              onCountChange(autoIdentifier, null);
            }
          }
        }
      }
    },
    [autoLinesToLines, linesToAutoLines, onCountChange, productUses],
  );

  const handleNotesChangeWithAutoProducts = useCallback(
    (identifier: string, value: string): void => {
      if (!onNotesChange) {
        return;
      }
      onNotesChange(identifier, value);
      const autoLines = linesToAutoLines?.get(identifier);
      if (autoLines) {
        for (const autoIdentifier of autoLines) {
          const sourcesForAutoIdentifiers = autoLinesToLines?.get(autoIdentifier) as string[];
          console.assert(sourcesForAutoIdentifiers);
          console.assert(sourcesForAutoIdentifiers.includes(identifier));
          if (sourcesForAutoIdentifiers.length === 1) {
            onNotesChange(autoIdentifier, value);
          } else {
            const values = sourcesForAutoIdentifiers.map((id) =>
              id === identifier ? value : productUses[id]?.notes,
            );
            const nonEmptyValues = values.filter(notNullOrUndefined).filter(Boolean);
            onNotesChange(autoIdentifier, nonEmptyValues.join(" "));
          }
        }
      }
    },
    [autoLinesToLines, linesToAutoLines, onNotesChange, productUses],
  );

  const handleDeleteClickWithAutoProducts = useCallback(
    (identifier: string): void => {
      if (!onDeleteClick) {
        return;
      }
      onDeleteClick(identifier);
      const autoLines = linesToAutoLines?.get(identifier);
      if (autoLines) {
        for (const autoIdentifier of autoLines) {
          const sourcesForAutoIdentifiers = autoLinesToLines?.get(autoIdentifier) as string[];
          console.assert(sourcesForAutoIdentifiers);
          console.assert(sourcesForAutoIdentifiers.includes(identifier));
          if (sourcesForAutoIdentifiers.length === 1) {
            onDeleteClick(autoIdentifier);
          }
        }
      }
    },
    [autoLinesToLines, linesToAutoLines, onDeleteClick],
  );

  const deleteClickHandler =
    onDeleteClick && customerSettings.enableAddProducts
      ? autoProductUses
        ? handleDeleteClickWithAutoProducts
        : onDeleteClick
      : null;

  const [switchProductDialogOpen, setSwitchProductDialogOpen] = useState(false);
  const [switchProductDialogOpenFor, setSwitchProductDialogOpenFor] = useState<string | null>(null);

  const handleSwitchProductDialogOk = useCallback(
    (productUrl: ProductUrl): void => {
      if (switchProductDialogOpenFor && onSwitchProduct) {
        onSwitchProduct(switchProductDialogOpenFor, productUrl);
      }
      setSwitchProductDialogOpen(false);
    },
    [onSwitchProduct, switchProductDialogOpenFor],
  );

  const handleSwitchProductDialogCancel = useCallback((): void => {
    setSwitchProductDialogOpen(false);
  }, []);

  const handleSwitchProductClick = useCallback((identifier: string): void => {
    setSwitchProductDialogOpenFor(identifier);
    setSwitchProductDialogOpen(true);
  }, []);

  if (customerSettings.noProducts) {
    return null;
  }
  if (!sortedProductUsesWithData.length && !showEvenIfEmpty) {
    return null;
  }

  if (bowser.mobile || bowser.tablet) {
    return (
      <>
        <DisplayGrid
          conversionRelatedValues={conversionRelatedValues}
          onCountChange={autoProductUses ? handleCountChangeWithAutoProducts : onCountChange}
          onDeleteClick={deleteClickHandler}
          onNotesChange={
            onNotesChange
              ? autoProductUses && autoCopyMaterialNoteToSupplementingProductNote
                ? handleNotesChangeWithAutoProducts
                : onNotesChange
              : null
          }
          onOursChange={onOursChange}
          onSwitchProduct={onSwitchProduct || null}
          onSwitchProductClick={null}
          productDialogPreferredProductURLs={productDialogPreferredProductURLs}
          productDialogPriceGroups={productDialogPriceGroups}
          productDialogWorkType={productDialogWorkType}
          showIconColumn={showIconColumn}
          showNotes={showNotes || false}
          showRelationConversionColumns={showRelationConversionColumns}
          sortedProductUses={sortedProductUsesWithData}
        />
        <ConnectedSingleProductDialog
          onCancel={handleSwitchProductDialogCancel}
          onOk={handleSwitchProductDialogOk}
          open={switchProductDialogOpen}
        />
      </>
    );
  } else {
    return (
      <>
        <DisplayTable
          conversionRelatedValues={conversionRelatedValues}
          onCountChange={autoProductUses ? handleCountChangeWithAutoProducts : onCountChange}
          onDeleteClick={
            onDeleteClick
              ? autoProductUses
                ? handleDeleteClickWithAutoProducts
                : onDeleteClick
              : null
          }
          onNotesChange={
            onNotesChange
              ? autoProductUses && autoCopyMaterialNoteToSupplementingProductNote
                ? handleNotesChangeWithAutoProducts
                : onNotesChange
              : null
          }
          onOursChange={onOursChange}
          onSwitchProductClick={withSwitchProduct ? handleSwitchProductClick : null}
          showIconColumn={showIconColumn}
          showNotes={showNotes || false}
          showRelationConversionColumns={showRelationConversionColumns}
          sortedProductUses={sortedProductUsesWithData}
        />
        <ConnectedSingleProductDialog
          onCancel={handleSwitchProductDialogCancel}
          onOk={handleSwitchProductDialogOk}
          open={switchProductDialogOpen && switchProductDialogOpenFor !== null}
          preferredCategory="recentlyUsed"
          preferredProductURLs={productDialogPreferredProductURLs}
          priceGroups={productDialogPriceGroups}
          workType={productDialogWorkType}
        />
      </>
    );
  }
});
