import {
  CsvImportSpecification,
  getImportSpecifications,
} from "@co-common-libs/csv-import-specifications";
import {Grid} from "@material-ui/core";
import React, {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {CsvUploadCard} from "./csv-upload-card";
import {ImportConfigurationCard} from "./import-configuration-card";
import {ImportDelimiter, ImportPreviewCard} from "./import-preview-card";
import {SelectImportSpecificationCard} from "./select-import-specification-card";
import {ImportColumnConfiguration} from "./types";

const PREVIEW_ROWS_LIMIT = 10;

function autoMapColumns(
  selectedImportSpecification: CsvImportSpecification,
  headers: readonly string[],
  currentImportColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>,
): Map<string, ImportColumnConfiguration> {
  const newImportColumnConfigurations = new Map<string, ImportColumnConfiguration>();
  selectedImportSpecification.columns.forEach((columnSpecification) => {
    const oldConfiguration = currentImportColumnConfigurations.get(columnSpecification.name);
    if (oldConfiguration && oldConfiguration.valueType === "staticValue") {
      newImportColumnConfigurations.set(columnSpecification.name, oldConfiguration);
    } else {
      const index = headers.indexOf(columnSpecification.label);
      if (index > -1) {
        newImportColumnConfigurations.set(columnSpecification.name, {
          columnIndex: index,
          updateExisting: oldConfiguration?.updateExisting || false,
          valueType: "sourceColumn",
        });
      }
    }
  });
  return newImportColumnConfigurations;
}

export const ImportContent = React.memo(function ImportContent(): React.JSX.Element {
  const intl = useIntl();

  const availableImportSpecifications = useMemo(() => getImportSpecifications(intl), [intl]);

  const [selectedImportSpecification, setSelectedImportSpecification] =
    useState<CsvImportSpecification | null>();

  const [originalRows, setOriginalRows] = useState<readonly (readonly string[])[]>([]);

  const [chosenDelimiter, setChosenDelimiter] = useState<ImportDelimiter>(";");

  const [importColumnConfigurations, setImportColumnConfigurations] = useState<
    ReadonlyMap<string, ImportColumnConfiguration>
  >(new Map());

  const handleImportSelectorChange = useCallback(
    (newSelectedImport: string) => {
      const newSelectedImportSpecification = newSelectedImport
        ? availableImportSpecifications.find(
            (importSpecification) => importSpecification.name === newSelectedImport,
          )
        : null;
      setSelectedImportSpecification(newSelectedImportSpecification);
      setOriginalRows([]);
      setImportColumnConfigurations(new Map());
    },
    [availableImportSpecifications],
  );

  const [succesfullyParsedDelimitersAndRows, setSuccesfullyParsedDelimitersAndRows] = useState<
    ReadonlyMap<ImportDelimiter, readonly (readonly string[])[]>
  >(new Map());
  const [firstRowIsHeader, setFirstRowIsHeader] = useState<boolean>(true);

  const {headers, rows} = useMemo(() => {
    if (originalRows.length) {
      if (firstRowIsHeader) {
        return {headers: originalRows[0], rows: originalRows.slice(1)};
      } else {
        const standardHeaders: readonly string[] = originalRows[0].map((_value, index) =>
          intl.formatMessage({defaultMessage: "Kolonne {rowIndex, number}"}, {rowIndex: index + 1}),
        );
        return {headers: standardHeaders, rows: originalRows};
      }
    } else {
      return {headers: [], rows: []};
    }
  }, [firstRowIsHeader, intl, originalRows]);

  const handleFirstRowIsHeaderCheckboxChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      setFirstRowIsHeader(checked);
      if (checked && selectedImportSpecification && originalRows.length) {
        const newColumnConfiguration = autoMapColumns(
          selectedImportSpecification,
          originalRows[0],
          importColumnConfigurations,
        );
        setImportColumnConfigurations(newColumnConfiguration);
      }
    },
    [importColumnConfigurations, originalRows, selectedImportSpecification],
  );

  const handleParsedRowsChanged = useCallback(
    (newFileRows: readonly (readonly string[])[]): void => {
      setOriginalRows(newFileRows);
      if (!selectedImportSpecification) {
        return;
      }
      if (firstRowIsHeader && newFileRows.length) {
        const newColumnConfiguration = autoMapColumns(
          selectedImportSpecification,
          newFileRows[0],
          importColumnConfigurations,
        );
        setImportColumnConfigurations(newColumnConfiguration);
      } else {
        const alteredimportColumnConfigurations = new Map(importColumnConfigurations);

        alteredimportColumnConfigurations.forEach((columnconfiguration, name) => {
          if (
            columnconfiguration.valueType === "sourceColumn" &&
            columnconfiguration.columnIndex > newFileRows[0].length
          ) {
            alteredimportColumnConfigurations.delete(name);
          }
        });
        setImportColumnConfigurations(alteredimportColumnConfigurations);
      }
    },
    [firstRowIsHeader, importColumnConfigurations, selectedImportSpecification],
  );

  const handleDelimiterChange = useCallback(
    (delimiter: ImportDelimiter) => {
      const parsedRows = succesfullyParsedDelimitersAndRows.get(delimiter);
      if (parsedRows) {
        handleParsedRowsChanged(parsedRows);
        setChosenDelimiter(delimiter);
      }
    },
    [handleParsedRowsChanged, succesfullyParsedDelimitersAndRows],
  );

  const workingDelimiters = useMemo((): ReadonlySet<ImportDelimiter> => {
    return new Set(succesfullyParsedDelimitersAndRows.keys());
  }, [succesfullyParsedDelimitersAndRows]);

  const handleFileRead = useCallback(
    (delimitersAndRowsMap: ReadonlyMap<ImportDelimiter, readonly (readonly string[])[]>) => {
      setSuccesfullyParsedDelimitersAndRows(delimitersAndRowsMap);

      if (delimitersAndRowsMap.size) {
        const parsedRowsWithCurrentDelimiter = delimitersAndRowsMap.get(chosenDelimiter);

        if (parsedRowsWithCurrentDelimiter) {
          handleParsedRowsChanged(parsedRowsWithCurrentDelimiter);
        } else {
          const [firstDelimiter, parsedrows] = Array.from(delimitersAndRowsMap)[0];

          handleParsedRowsChanged(parsedrows);
          setChosenDelimiter(firstDelimiter);
        }
      } else {
        handleParsedRowsChanged([]);
      }
    },
    [chosenDelimiter, handleParsedRowsChanged],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <SelectImportSpecificationCard
          availableImportSpecification={availableImportSpecifications}
          onSelectorChange={handleImportSelectorChange}
          selected={selectedImportSpecification ? selectedImportSpecification.name : ""}
        />
      </Grid>
      {selectedImportSpecification ? (
        <Grid item xs={9}>
          <CsvUploadCard key={selectedImportSpecification.name} onFileRead={handleFileRead} />
        </Grid>
      ) : null}
      {selectedImportSpecification?.name && originalRows.length ? (
        <Grid item xs={12}>
          <ImportPreviewCard
            checked={firstRowIsHeader}
            chosenDelimiter={chosenDelimiter}
            headers={headers}
            onDelimiterChange={handleDelimiterChange}
            onHeaderRowChange={handleFirstRowIsHeaderCheckboxChange}
            rows={rows.slice(0, PREVIEW_ROWS_LIMIT)}
            workingDelimiters={workingDelimiters}
          />
        </Grid>
      ) : null}
      {selectedImportSpecification?.columns && rows.length ? (
        <Grid item xs={12}>
          <ImportConfigurationCard
            headers={headers}
            importColumnConfigurations={importColumnConfigurations}
            rows={rows}
            selectedImportSpecification={selectedImportSpecification}
            setImportColumnConfigurations={setImportColumnConfigurations}
          />
        </Grid>
      ) : null}
    </Grid>
  );
});
