import {SpeedDialAction, SpeedDialActionProps} from "@material-ui/lab";
import React, {useCallback} from "react";

interface NormalSpeedDialActionProps
  extends Partial<Pick<SpeedDialActionProps, "delay" | "FabProps" | "id" | "open">> {
  buttonIcon: React.JSX.Element;
  onClick: () => void;
  onClose: () => void;
  tooltipPlacement: "left" | "right"; //other allowed tooltip placements in the speeddial component don't work with persistent tooltips (tooltipOpen).
  tooltipTitle: string;
}

export const NormalSpeedDialAction = React.memo(function NormalSpeedDialAction(
  props: NormalSpeedDialActionProps,
): React.JSX.Element {
  const {buttonIcon, FabProps, onClick, onClose, tooltipPlacement, tooltipTitle, ...rest} = props;

  const handleClick = useCallback(() => {
    onClose();
    onClick();
  }, [onClick, onClose]);

  return (
    <SpeedDialAction
      icon={buttonIcon}
      onClick={handleClick}
      style={{cursor: "pointer"}}
      tooltipOpen
      tooltipPlacement={tooltipPlacement}
      tooltipTitle={tooltipTitle}
      {...rest}
    />
  );
});
