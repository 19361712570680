import {CustomerUrl, Project, ProjectAccess, ProjectUrl, Task} from "@co-common-libs/resources";
import {actions, getOrderLookup, getProjectLookup} from "@co-frontend-libs/redux";
import {IconButton, TableCell} from "@material-ui/core";
import {
  ConfirmDialogVisibility,
  useHandleTaskProjectChange,
  useProjectSetters,
  UserConfirmations,
} from "app-components";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

interface ProjectCellProps {
  allowProjectChange: boolean;
  tableRowColumnStyle: React.CSSProperties;
  task: Task;
}

export const ProjectCell = function ProjectCell(props: ProjectCellProps): React.JSX.Element {
  const {allowProjectChange, tableRowColumnStyle, task} = props;

  const projectLookup = useSelector(getProjectLookup);
  const project = task.project && projectLookup(task.project);

  const projectButtonRef = React.createRef<HTMLButtonElement>();
  const {reportApproved, validatedAndRecorded} = task;
  const orderLookup = useSelector(getOrderLookup);
  const order = task.order && orderLookup(task.order);
  const customerUrl = order?.customer ?? null;

  const defaultAccessOnCreate = ProjectAccess.Open;

  const dispatch = useDispatch();

  const onTaskMovedToNewOrder = useCallback(
    (orderId: string, taskId: string): void => {
      dispatch(actions.go("/order/:id/:taskID", {id: orderId, taskID: taskId}, {}, "REPLACE"));
    },
    [dispatch],
  );

  const [confirmMoveTaskDialog, handleTaskProjectChange] =
    useHandleTaskProjectChange(onTaskMovedToNewOrder);

  const onCustomerChanged = useCallback(
    (newCustomerUrl: CustomerUrl | null): void => {
      if (order) dispatch(actions.updateDiff({customer: newCustomerUrl}, order));
    },
    [dispatch, order],
  );

  const computeConfirmDialogVisibility = useCallback(
    (selectedProjectUrl: ProjectUrl): ConfirmDialogVisibility => {
      const newProject = projectLookup(selectedProjectUrl);
      const showRelatedWorkplaceConfirmDialog =
        newProject?.relatedWorkplace !== task.relatedWorkplace;

      const showContactConfirmDialog = false;

      return {
        showContactConfirmDialog,
        showRelatedWorkplaceConfirmDialog,
      };
    },

    [projectLookup, task.relatedWorkplace],
  );

  const handleDialogConfirmed = useCallback(
    (userConfirmations: UserConfirmations, newProject: Project | null) => {
      if (newProject) {
        if (userConfirmations.workplaceConfirmDialogResponse) {
          dispatch(actions.updateDiff({relatedWorkplace: newProject?.relatedWorkplace}, task));
        }
      }
    },
    [dispatch, task],
  );

  const handleProjectChanged = useCallback(
    (newProjectUrl: ProjectUrl | null) => {
      if (newProjectUrl) {
        dispatch(actions.updateDiff({project: newProjectUrl}, task));

        const newProject = newProjectUrl && projectLookup(newProjectUrl);
        if (!task.relatedWorkplace && project) {
          dispatch(actions.updateDiff({relatedWorkplace: newProject?.relatedWorkplace}, task));
        }
        handleTaskProjectChange(newProjectUrl, task.url);
      }
    },
    [dispatch, handleTaskProjectChange, project, projectLookup, task],
  );

  const [
    confirmWorkplaceChangeDialog,
    confirmContactChangeDialog,
    selectProjectModal,
    createProjectModal,
    customerModal,
    handleProjectSelectButton,
  ] = useProjectSetters(
    customerUrl,
    defaultAccessOnCreate,
    onCustomerChanged,
    handleDialogConfirmed,
    handleProjectChanged,
    computeConfirmDialogVisibility,
  );
  return (
    <>
      <TableCell
        key="project"
        onClick={handleProjectSelectButton}
        ref={projectButtonRef}
        style={{
          ...tableRowColumnStyle,
          cursor:
            validatedAndRecorded || reportApproved || !allowProjectChange
              ? "not-allowed"
              : "pointer",
        }}
      >
        {project ? (
          `${project.projectNumber}: ${project.name}`
        ) : (
          <IconButton disabled={validatedAndRecorded || reportApproved || !allowProjectChange}>
            <PencilIcon />
          </IconButton>
        )}
      </TableCell>
      {selectProjectModal}
      {createProjectModal}
      {customerModal}
      {confirmWorkplaceChangeDialog}
      {confirmMoveTaskDialog}
      {confirmContactChangeDialog}
    </>
  );
};
