// generated by script generate-export-types

import {DecimalJsLike} from "./types";
import {z} from "zod";

export interface WorktypeexternalExportInterface {
  allowMaxOneMachine: boolean;
  color: string;
  disallowMachineUse: boolean;
  identifier: string;
  name: string;
  noProductUsesOrAtLeastOneProductUseGreaterThanZero: boolean;
  onlyForExtraTimers: boolean;
  requireAtLeastOneOptionalPriceItemUseGreaterThanZero: boolean;
  requireAtLeastOneProductUseGreaterThanZero: boolean;
  requireAttachment: boolean;
  requireEffectiveTime: boolean;
  requireField: boolean;
  requireMachineUse: boolean;
  requireNotesFromMachineOperator: boolean;
  requirePhotoOnTaskCompletion: boolean;
  requireWorkplace: boolean;
  salesAccount: number | null;
  towingCostMultiplier: DecimalJsLike | null;
}

export const workTypeExternalColumnSchema = z.union([
  z.literal("allowMaxOneMachine"),
  z.literal("color"),
  z.literal("disallowMachineUse"),
  z.literal("identifier"),
  z.literal("name"),
  z.literal("noProductUsesOrAtLeastOneProductUseGreaterThanZero"),
  z.literal("onlyForExtraTimers"),
  z.literal("requireAtLeastOneOptionalPriceItemUseGreaterThanZero"),
  z.literal("requireAtLeastOneProductUseGreaterThanZero"),
  z.literal("requireAttachment"),
  z.literal("requireEffectiveTime"),
  z.literal("requireField"),
  z.literal("requireMachineUse"),
  z.literal("requireNotesFromMachineOperator"),
  z.literal("requirePhotoOnTaskCompletion"),
  z.literal("requireWorkplace"),
  z.literal("salesAccount"),
  z.literal("towingCostMultiplier"),
]);

export type WorktypeexternalColumn = z.infer<typeof workTypeExternalColumnSchema>;

export interface WorktypeinternalExportInterface {
  allowMaxOneMachine: boolean;
  color: string;
  disallowMachineUse: boolean;
  identifier: string;
  internalTaskPrimary: boolean;
  name: string;
  onlyForExtraTimers: boolean;
  requireAttachment: boolean;
  requireMachineUse: boolean;
  requireNotesFromMachineOperator: boolean;
  requirePhotoOnTaskCompletion: boolean;
}

export const workTypeInternalColumnSchema = z.union([
  z.literal("allowMaxOneMachine"),
  z.literal("color"),
  z.literal("disallowMachineUse"),
  z.literal("identifier"),
  z.literal("internalTaskPrimary"),
  z.literal("name"),
  z.literal("onlyForExtraTimers"),
  z.literal("requireAttachment"),
  z.literal("requireMachineUse"),
  z.literal("requireNotesFromMachineOperator"),
  z.literal("requirePhotoOnTaskCompletion"),
]);

export type WorktypeinternalColumn = z.infer<typeof workTypeInternalColumnSchema>;

export interface UnitExportInterface {
  name: string;
  symbol: string;
}

export const unitColumnSchema = z.union([z.literal("name"), z.literal("symbol")]);

export type UnitColumn = z.infer<typeof unitColumnSchema>;

export interface CustomerExportInterface {
  address: string;
  alias: string;
  barred: boolean;
  billingEmail: string;
  c5Account: string | null;
  cellphone: string;
  city: string;
  createInvoices: boolean;
  disallowTaskValidation: boolean;
  favorite: boolean;
  logEmail: string;
  name: string;
  phone: string;
  postalCode: string;
  projectManagerName: string;
  receiveLogMails: boolean;
  vatNumber: string;
}

export const customerColumnSchema = z.union([
  z.literal("address"),
  z.literal("alias"),
  z.literal("barred"),
  z.literal("billingEmail"),
  z.literal("c5Account"),
  z.literal("cellphone"),
  z.literal("city"),
  z.literal("createInvoices"),
  z.literal("disallowTaskValidation"),
  z.literal("favorite"),
  z.literal("logEmail"),
  z.literal("name"),
  z.literal("phone"),
  z.literal("postalCode"),
  z.literal("projectManagerName"),
  z.literal("receiveLogMails"),
  z.literal("vatNumber"),
]);

export type CustomerColumn = z.infer<typeof customerColumnSchema>;

export interface MachineExportInterface {
  c5Machine: string;
  canPull: boolean | null;
  department: string;
  hourlyTowingCostEffective: DecimalJsLike | null;
  hourlyTowingCostTransport: DecimalJsLike | null;
  name: string;
  requireAtLeastOneProductUseGreaterThanZero: boolean;
  requirePhotoOnTaskCompletion: boolean;
  selfPropelled: boolean | null;
  smallMachine: boolean;
}

export const machineColumnSchema = z.union([
  z.literal("c5Machine"),
  z.literal("canPull"),
  z.literal("department"),
  z.literal("hourlyTowingCostEffective"),
  z.literal("hourlyTowingCostTransport"),
  z.literal("name"),
  z.literal("requireAtLeastOneProductUseGreaterThanZero"),
  z.literal("requirePhotoOnTaskCompletion"),
  z.literal("selfPropelled"),
  z.literal("smallMachine"),
]);

export type MachineColumn = z.infer<typeof machineColumnSchema>;

export interface UserExportInterface {
  address: string;
  alias: string;
  alwaysVisible: boolean;
  askAboutAvailability: boolean;
  averageHourlyCost: DecimalJsLike | null;
  birthDate: Date | null;
  canReadFoodBookings: boolean;
  canUnapproveTasks: boolean;
  cellphone: string;
  cellphoneExtra: string;
  city: string;
  email: string;
  employmentDate: Date | null;
  loginIdentifier: string;
  name: string;
  note: string;
  owner: boolean;
  postalCode: string;
  receiveTimerStartNotifications: boolean;
  remunerationGroup: string;
  requireGps: boolean;
  role:
    | "Løsarbejder"
    | "Mekaniker"
    | "Projektleder"
    | "Maskinfører"
    | "Senior Maskinfører"
    | "Administrator"
    | "Info, ikke angivet"
    | "Info, pauseskærm"
    | "Info, fuldskærmskort"
    | "Info, fuldskærmskalender"
    | "Info, fuldskærmskalender, side 1"
    | "Info, fuldskærmskalender, side 2"
    | "Info, fuldskærmsordrekalender"
    | "Info, fuldskærmsordrekalender (minimal)"
    | "Info, fuldskærmsfraværskalender"
    | null;
  showDinnerBookingPopup: boolean;
  showLunchBookingPopup: boolean;
  showNotePopupOnTask: boolean;
}

export const userColumnSchema = z.union([
  z.literal("address"),
  z.literal("alias"),
  z.literal("alwaysVisible"),
  z.literal("askAboutAvailability"),
  z.literal("averageHourlyCost"),
  z.literal("birthDate"),
  z.literal("canReadFoodBookings"),
  z.literal("canUnapproveTasks"),
  z.literal("cellphone"),
  z.literal("cellphoneExtra"),
  z.literal("city"),
  z.literal("email"),
  z.literal("employmentDate"),
  z.literal("loginIdentifier"),
  z.literal("name"),
  z.literal("note"),
  z.literal("owner"),
  z.literal("postalCode"),
  z.literal("receiveTimerStartNotifications"),
  z.literal("remunerationGroup"),
  z.literal("requireGps"),
  z.literal("role"),
  z.literal("showDinnerBookingPopup"),
  z.literal("showLunchBookingPopup"),
  z.literal("showNotePopupOnTask"),
]);

export type UserColumn = z.infer<typeof userColumnSchema>;

export interface LocationExportInterface {
  customerName: string;
  customerNumber: string;
  locationAddress: string;
  locationCity: string;
  locationContactCellphone: string;
  locationContactEmail: string;
  locationContactName: string;
  locationContactPhone: string;
  locationId: string;
  locationLatitude: number | null;
  locationLongitude: number | null;
  locationName: string;
  locationPostalCode: string;
  locationTypeIdentifier: string;
  locationTypeName: string;
  usage:
    | "Kan bruges som både arbejdssted og logsted"
    | "Kan kun bruges som logsted"
    | "Kan kun bruges som arbejdssted"
    | "!!! Ugyldig værdi !!!";
}

export const locationColumnSchema = z.union([
  z.literal("customerName"),
  z.literal("customerNumber"),
  z.literal("locationAddress"),
  z.literal("locationCity"),
  z.literal("locationContactCellphone"),
  z.literal("locationContactEmail"),
  z.literal("locationContactName"),
  z.literal("locationContactPhone"),
  z.literal("locationId"),
  z.literal("locationLatitude"),
  z.literal("locationLongitude"),
  z.literal("locationName"),
  z.literal("locationPostalCode"),
  z.literal("locationTypeIdentifier"),
  z.literal("locationTypeName"),
  z.literal("usage"),
]);

export type LocationColumn = z.infer<typeof locationColumnSchema>;

export interface ProductgroupExportInterface {
  identifier: string;
  name: string;
  salesAccount: number | null;
}

export const productGroupColumnSchema = z.union([
  z.literal("identifier"),
  z.literal("name"),
  z.literal("salesAccount"),
]);

export type ProductgroupColumn = z.infer<typeof productGroupColumnSchema>;

export interface ProductExportInterface {
  catalogNumber: string;
  name: string;
  price: DecimalJsLike | null;
  productGroupIdentifier: string | null;
  productGroupName: string | null;
  relatedUnitName: string | null;
}

export const productColumnSchema = z.union([
  z.literal("catalogNumber"),
  z.literal("name"),
  z.literal("price"),
  z.literal("productGroupIdentifier"),
  z.literal("productGroupName"),
  z.literal("relatedUnitName"),
]);

export type ProductColumn = z.infer<typeof productColumnSchema>;
