import {resourceNameFor} from "@co-common-libs/resources";
import {typedMemo} from "@co-frontend-libs/components";
import {
  Collapse,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import clsx from "clsx";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import React, {useCallback} from "react";

const NESTED_LIST_SPACING = 4;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fadedText: {
      color: theme.palette.text.disabled,
    },
    nested: {
      paddingLeft: theme.spacing(NESTED_LIST_SPACING),
    },
  }),
);

interface SelectableListItemProps<Value extends string> {
  expandanded?: boolean | undefined;
  faded?: boolean | undefined;
  isSelected: boolean;
  nested?: boolean;
  onClick: (event: React.MouseEvent, value: Value) => void;
  primaryText: string;
  secondaryText?: string | undefined;
  value: Value;
}

const SelectableListItem = typedMemo(function SelectableListItem<Value extends string>(
  props: SelectableListItemProps<Value>,
): React.JSX.Element {
  const {expandanded, faded, isSelected, nested, onClick, primaryText, secondaryText, value} =
    props;

  const classes = useStyles();

  const handleClick = useCallback(
    (event: React.MouseEvent): void => {
      onClick(event, value);
    },
    [onClick, value],
  );

  return (
    <ListItem
      button
      className={clsx({[classes.fadedText]: faded, [classes.nested]: nested})}
      onClick={handleClick}
      selected={isSelected}
    >
      <ListItemText primary={primaryText} secondary={secondaryText} />
      {expandanded !== undefined ? expandanded ? <ChevronUpIcon /> : <ChevronDownIcon /> : null}
    </ListItem>
  );
});

interface Entry<Value extends string> {
  readonly faded?: boolean;
  readonly primaryText: string;
  readonly secondaryText?: string;
  readonly value: Value;
}

export interface EntryWithSubEntries<Value extends string> extends Entry<Value> {
  readonly subEntries?: Entry<Value>[];
}

interface SelectableListProps<EntryValue extends string> {
  data: readonly EntryWithSubEntries<EntryValue>[];
  emptyPrimaryText?: string | undefined;
  emptySecondaryText?: string | undefined;
  onChange: (event: React.SyntheticEvent<unknown>, value: EntryValue | null) => void;
  style?: React.CSSProperties;
  value?: string | undefined;
}

export const SelectableList = typedMemo(function SelectableList<EntryValue extends string>(
  props: SelectableListProps<EntryValue>,
): React.JSX.Element {
  const {data, emptyPrimaryText, emptySecondaryText, onChange, style, value} = props;

  const handleItemClick = useCallback(
    (event: React.MouseEvent, entryValue: EntryValue): void => {
      if (entryValue !== value) {
        onChange(event, entryValue);
      } else if (resourceNameFor(entryValue) !== "priceGroup") {
        onChange(event, null);
      }
    },
    [onChange, value],
  );
  const entryList = data.map((entryData) => {
    const entryValue = entryData.value;
    const isSelected = entryValue === value;
    const subEntrySelected =
      entryData.subEntries?.some((subEntry) => subEntry.value === value) ?? false;
    let subEntries: React.JSX.Element | null = null;
    if (entryData.subEntries?.length) {
      subEntries = (
        <Collapse in={isSelected || subEntrySelected} timeout="auto" unmountOnExit>
          <List disablePadding>
            {entryData.subEntries.map((subEntry) => (
              <SelectableListItem
                faded={subEntry.faded}
                isSelected={subEntry.value === value}
                key={subEntry.value}
                nested
                onClick={handleItemClick}
                primaryText={subEntry.primaryText}
                secondaryText={subEntry.secondaryText}
                value={subEntry.value}
              />
            ))}
          </List>
        </Collapse>
      );
    }
    return (
      <React.Fragment key={entryValue}>
        <SelectableListItem
          expandanded={entryData.subEntries?.length ? isSelected || subEntrySelected : undefined}
          faded={entryData.faded}
          isSelected={isSelected}
          onClick={handleItemClick}
          primaryText={entryData.primaryText}
          secondaryText={entryData.secondaryText}
          value={entryValue}
        />
        {subEntries}
      </React.Fragment>
    );
  });
  let emptyEntry;
  if (!entryList.length && emptyPrimaryText) {
    emptyEntry = (
      <ListItem disabled>
        <ListItemText primary={emptyPrimaryText} secondary={emptySecondaryText} />
      </ListItem>
    );
  }
  return (
    <List component="nav" style={style || {}}>
      {entryList}
      {emptyEntry}
    </List>
  );
});
