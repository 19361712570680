import {LocationUrl} from "@co-common-libs/resources";
import {Checkbox, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {FieldEntry} from "app-utils";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";

interface AllItemProps {
  allSelected: boolean;
  entries: readonly FieldEntry[];
  isSearchResult: boolean;
  multiSelectMax: number | undefined;
  onSelectMultiple: (identifiers: ReadonlySet<LocationUrl>, selected: boolean) => void;
  style: React.CSSProperties;
}

export const AllItem = React.memo(function AllItem(props: AllItemProps) {
  const {allSelected, entries, isSearchResult, multiSelectMax, onSelectMultiple, style} = props;

  const handleClick = useCallback(() => {
    const changed = new Set(entries.map((entry) => entry.field.url));
    onSelectMultiple(changed, !allSelected);
  }, [allSelected, entries, onSelectMultiple]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleClick();
      }
    },
    [handleClick],
  );

  const disabled = !!multiSelectMax && entries.length > multiSelectMax;

  const intl = useIntl();

  return (
    <ListItem
      button
      disabled={disabled}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      style={style}
    >
      <ListItemIcon>
        <Checkbox checked={allSelected} disableRipple edge="start" tabIndex={-1} />
      </ListItemIcon>
      <ListItemText
        primary={
          isSearchResult
            ? intl.formatMessage({
                defaultMessage: "Vælg søgeresultater",
              })
            : intl.formatMessage({
                defaultMessage: "Vælg alle",
              })
        }
      />
    </ListItem>
  );
});
