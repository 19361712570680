import {
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationUrl,
  Machine,
  MachineUrl,
  urlToId,
} from "@co-common-libs/resources";
import {dateFromString} from "@co-common-libs/utils";
import {
  createHref,
  getKrPerLiterFuelSurchargeSpecificationLookup,
  getMachineLookup,
} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader, IconButton, useTheme} from "@material-ui/core";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface KrPerLiterSpecificationMissingEntryLineProps {
  fromDate: string;
  specification: KrPerLiterFuelSurchargeSpecification | undefined;
  specificationUrl: string;
  toDate: string;
}

const KrPerLiterSpecificationMissingEntryLine = React.memo(
  function KrPerLiterSpecificationMissingEntryLine(
    props: KrPerLiterSpecificationMissingEntryLineProps,
  ): React.JSX.Element {
    const {fromDate, specification, specificationUrl, toDate} = props;

    const intl = useIntl();
    const theme = useTheme();

    const name = specification
      ? specification.name
      : intl.formatMessage({defaultMessage: "Ukendt brændstoftillæg"});

    const href = createHref("/settings/krPerLiterFuelSurcharge/:id", {
      id: urlToId(specificationUrl),
    });

    return (
      <div style={{color: theme.palette.error.main}}>
        <FormattedMessage
          defaultMessage="{name} mangler pris for opgaver i perioden fra {fromDate, date, ::MMdd} til {toDate, date, ::MMdd}"
          values={{
            fromDate: dateFromString(fromDate),
            name,
            toDate: dateFromString(toDate),
          }}
        />
        <IconButton color="primary" href={href}>
          <PencilIcon />
        </IconButton>
      </div>
    );
  },
);

interface MachineMissingConsumptionLineProps {
  machine: Machine | undefined;
  machineUrl: string;
}

const MachineMissingConsumptionLine = React.memo(function MachineMissingConsumptionLine(
  props: MachineMissingConsumptionLineProps,
): React.JSX.Element {
  const {machine, machineUrl} = props;

  const intl = useIntl();
  const theme = useTheme();

  const machineName = machine
    ? machine.name
    : intl.formatMessage({defaultMessage: "Ukendt maskine"});
  const machineNumber = machine ? machine.c5_machine : "";

  const href = createHref("/settings/machine/:id", {
    id: urlToId(machineUrl),
  });

  return (
    <div style={{color: theme.palette.error.main}}>
      <FormattedMessage
        defaultMessage="Maskine {machineName} ({machineNumber}) mangler brændstofforbrug for brændstoftillæg"
        values={{
          machineName,
          machineNumber,
        }}
      />
      <IconButton color="primary" href={href}>
        <PencilIcon />
      </IconButton>
    </div>
  );
});

interface KrPerLiterFuelSurchargeIssuesCardProps {
  machinesMissingFuelConsumption: ReadonlySet<MachineUrl>;
  specificationProblemDates: ReadonlyMap<
    KrPerLiterFuelSurchargeSpecificationUrl,
    ReadonlySet<string>
  >;
}

export const KrPerLiterFuelSurchargeIssuesCard = React.memo(
  function KrPerLiterFuelSurchargeIssuesCard(
    props: KrPerLiterFuelSurchargeIssuesCardProps,
  ): React.JSX.Element {
    const {machinesMissingFuelConsumption, specificationProblemDates} = props;
    const intl = useIntl();

    const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
      getKrPerLiterFuelSurchargeSpecificationLookup,
    );
    const machineLookup = useSelector(getMachineLookup);

    const specificationEntries = useMemo(() => {
      const unsortedEntries = Array.from(specificationProblemDates).map(
        ([specificationUrl, dates]) => {
          const sortedDates = Array.from(dates).sort();
          return {
            fromDate: sortedDates[0],
            specification: krPerLiterFuelSurchargeSpecificationLookup(specificationUrl),
            specificationUrl,
            toDate: sortedDates[sortedDates.length - 1],
          };
        },
      );
      return _.sortBy(unsortedEntries, [
        ({specification}) => specification?.name || "",
        ({fromDate}) => fromDate,
        ({toDate}) => toDate,
        ({specificationUrl}) => specificationUrl,
      ]);
    }, [krPerLiterFuelSurchargeSpecificationLookup, specificationProblemDates]);

    const specificationLines = specificationEntries.map(
      ({fromDate, specification, specificationUrl, toDate}) => (
        <KrPerLiterSpecificationMissingEntryLine
          fromDate={fromDate}
          key={specificationUrl}
          specification={specification}
          specificationUrl={specificationUrl}
          toDate={toDate}
        />
      ),
    );

    const machineEntries = useMemo(() => {
      const unsortedEntries = Array.from(machinesMissingFuelConsumption).map((machineUrl) => ({
        machine: machineLookup(machineUrl),
        machineUrl,
      }));
      return _.sortBy(unsortedEntries, [
        ({machine}) => machine?.c5_machine || "",
        ({machine}) => machine?.name || "",
        ({machineUrl}) => machineUrl,
      ]);
    }, [machineLookup, machinesMissingFuelConsumption]);

    const machineLines = machineEntries.map(({machine, machineUrl}) => (
      <MachineMissingConsumptionLine key={machineUrl} machine={machine} machineUrl={machineUrl} />
    ));

    return (
      <Card style={{marginTop: 10}}>
        <CardHeader title={intl.formatMessage({defaultMessage: "Brændstoftillæg"})} />
        <CardContent>
          {specificationLines}
          {machineLines}
        </CardContent>
      </Card>
    );
  },
);
