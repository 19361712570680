import {OrderTableColumnID} from "../../connected-order-table";

export function getSortOptionOrderArchive(
  sortDirection: "ASC" | "DESC",
  sortKey: OrderTableColumnID,
): string[] {
  if (sortKey) {
    const desc = sortDirection === "DESC";
    switch (sortKey) {
      case "address":
        return [
          `${desc ? "-" : ""}related_workplace__name`,
          `${desc ? "-" : ""}related_workplace__address`,
          `${desc ? "-" : ""}address`,
        ];
      case "createdDate":
      case "createdDateShort":
        return [`${desc ? "-" : ""}version_birth_date`];
      case "customer":
      case "customerShort":
        return [`${desc ? "-" : ""}customer__name`];
      case "customerAddress":
        return [
          `${desc ? "-" : ""}customer__address`,
          `${desc ? "-" : ""}customer__postal_code`,
          `${desc ? "-" : ""}customer__city`,
        ];
      case "customerAddressShort":
        return [`${desc ? "-" : ""}customer__address`];
      case "date":
      case "dateShort":
        return [`${desc ? "-" : ""}date`];
      case "department":
        return [`${desc ? "-" : ""}department`, `${desc ? "-" : ""}task__department`];
      case "employees":
        return [`${desc ? "-" : ""}task__machine_operator__login_identifier`];
      case "managerInternalNotes":
        return [`${desc ? "-" : ""}manager_internal_notes`];
      case "note":
        return [`${desc ? "-" : ""}notes`];
      case "projectNumbers":
        return [`${desc ? "-" : ""}task__project__project_number`];
      case "referenceNumber":
        return [`${desc ? "-" : ""}reference_number`];
      case "workType":
      case "workTypes":
      case "workTypeShort":
        return [
          `${desc ? "-" : ""}work_type__identifier`,
          `${desc ? "-" : ""}work_type__name`,
          `${desc ? "-" : ""}task__work_type__identifier`,
          `${desc ? "-" : ""}task__work_type__name`,
        ];
      default:
        return [`${desc ? "-" : ""}created`];
    }
  } else {
    return ["-date", "customer__name"];
  }
}
