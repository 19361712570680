import {PriceGroupUrl, TimerUrl, WorkTypeUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export const towingCostTypes = ["none", "effective", "transport"] as const;

export type TowingCostType = (typeof towingCostTypes)[number];

export interface Timer extends ResourceInstance<TimerUrl> {
  readonly active: boolean;
  readonly color: string;
  readonly identifier: string;
  readonly includeForExternalTask: boolean;
  readonly includeForInternalTask: boolean;
  readonly isBreak: boolean;
  readonly isGenericEffectiveTime: boolean;
  readonly label: string;
  readonly notifyUsersOnStart: boolean | undefined;
  readonly priceGroup: PriceGroupUrl | null;
  readonly pullersShareOfSalaryCost: number | null;
  readonly requiresNotes: boolean;
  readonly showInTimelineTable: boolean | undefined;
  readonly towingCostType: TowingCostType;
  readonly transferTimerNotesToExternalSystem: boolean;
  readonly warnIfNoTime: boolean | undefined;
  readonly workType: WorkTypeUrl | null;
}

export const emptyTimer: Omit<Timer, "id" | "label" | "url"> = {
  active: false,
  color: "#FFF",
  identifier: "",
  includeForExternalTask: false,
  includeForInternalTask: false,
  isBreak: false,
  isGenericEffectiveTime: false,
  notifyUsersOnStart: false,
  priceGroup: null,
  pullersShareOfSalaryCost: null,
  requiresNotes: false,
  showInTimelineTable: false,
  towingCostType: "none",
  transferTimerNotesToExternalSystem: false,
  warnIfNoTime: false,
  workType: null,
};
