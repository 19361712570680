import {PriceItem} from "@co-common-libs/resources";
import {formatDuration, getUnitString} from "@co-common-libs/resources-utils";
import {DecimalField} from "@co-frontend-libs/components";
import {getCustomerSettings, getUnitLookup} from "@co-frontend-libs/redux";
import {TableCell, TableRow} from "@material-ui/core";
import {getPriceItemNameAndIdentifier} from "app-utils";
import _ from "lodash";
import CurrencyUsdOffIcon from "mdi-react/CurrencyUsdOffIcon";
import React, {useCallback} from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {useSelector} from "react-redux";
import {MaterialField} from "../material-field";
import {correctedCountColumnStyle, countColumnStyle, DECIMALS, unitColumnStyle} from "./constants";

interface PriceItemUseLineProps {
  correctedCount?: number | undefined;
  count?: number | undefined;
  editDisabled: boolean;
  identifier: string;
  invoiceCorrectionEnabled: boolean;
  minutes?: number | undefined;
  onChange: (identifier: string, value: number | null) => void;
  priceItem: PriceItem;
  striped?: boolean;
}

const priceItemUseLineValueStyle: React.CSSProperties = {
  marginRight: 25,
};

export const PriceItemUseLine = React.memo(function PriceItemUseLine(
  props: PriceItemUseLineProps,
): React.JSX.Element {
  const {
    correctedCount,
    editDisabled,
    identifier,
    invoiceCorrectionEnabled,
    minutes,
    onChange,
    priceItem,
    striped,
  } = props;
  const unitLookup = useSelector(getUnitLookup);
  const customerSettings = useSelector(getCustomerSettings);
  let {count} = props;
  const handleChange = useCallback(
    (value: number | null) => onChange(identifier, value),
    [identifier, onChange],
  );

  const {billable, name} = priceItem;
  const unit = getUnitString(priceItem, unitLookup);
  // Use <MaterialField> for booleanSelection for consistent UI;
  // otherwise <DecimalField>, as we specifically don't want to show
  // a HH:MM field for hours-types...
  const inputField = priceItem.booleanSelection ? (
    <MaterialField
      booleanSelection
      customerSettings={customerSettings}
      disabled={editDisabled || !billable}
      onChange={handleChange}
      unit={unit}
      value={correctedCount != null ? correctedCount : undefined}
    />
  ) : (
    <DecimalField
      decimalPlaces={customerSettings.materialDecimals}
      disabled={editDisabled || !billable}
      fullWidth
      hideError
      margin="dense"
      maxDigits={9}
      name={name}
      onChange={handleChange}
      style={priceItemUseLineValueStyle}
      value={correctedCount != null ? correctedCount : null}
    />
  );
  const tableRowColumnStyle: React.CSSProperties = striped
    ? {
        backgroundColor: "rgba(129, 212, 249, 0.4)",
      }
    : {};
  let valueBlock: React.JSX.Element | null;
  let unitBlock = "";
  if (priceItem.booleanSelection) {
    switch (count) {
      case 0:
        valueBlock = <FormattedMessage defaultMessage="Nej" id="invoice-line-table.label.no" />;
        break;
      case 1:
        valueBlock = <FormattedMessage defaultMessage="Ja" id="invoice-line-table.label.yes" />;
        break;
      default:
        valueBlock = null;
    }
  } else {
    unitBlock = unit;
    let minutesString;
    if (minutes != null) {
      minutesString = `(${formatDuration(customerSettings.durationFormat, minutes)}) `;
    }
    let countUnderMinimumString;

    // minimumCount available only for imported price-items
    const {minimumCount} = priceItem;
    if (minimumCount != null && (count || 0) < minimumCount) {
      countUnderMinimumString = `(${_.round(count || 0, DECIMALS)})`;
      count = minimumCount;
    }

    // transferAsMinutes available only for CO price-items
    if (priceItem.transferAsMinutes) {
      count = minutes;
    }

    valueBlock = (
      <>
        {minutesString} {countUnderMinimumString}{" "}
        <FormattedNumber maximumFractionDigits={2} value={count || 0} />
      </>
    );
  }

  return (
    <TableRow>
      <TableCell style={tableRowColumnStyle}>
        {!billable ? (
          <CurrencyUsdOffIcon style={{marginRight: 5, position: "relative", top: 7}} />
        ) : null}
        {getPriceItemNameAndIdentifier(priceItem, customerSettings)}
      </TableCell>
      <TableCell style={{...tableRowColumnStyle, ...countColumnStyle}}>{valueBlock}</TableCell>
      {invoiceCorrectionEnabled ? (
        <TableCell style={{...tableRowColumnStyle, ...correctedCountColumnStyle}}>
          {inputField}
        </TableCell>
      ) : null}
      <TableCell style={{...tableRowColumnStyle, ...unitColumnStyle}}>{unitBlock}</TableCell>
      {customerSettings.showNoteOnInvoiceLineTable ? (
        <TableCell style={tableRowColumnStyle} />
      ) : null}
    </TableRow>
  );
});
