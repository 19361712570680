import {Config} from "@co-common-libs/config";
import {Machine, urlToId} from "@co-common-libs/resources";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {FormattedMessage} from "react-intl";

interface MachineRowProps {
  customerSettings: Config;
  machine: Machine;
  onClick: (machineURL: string) => void;
}

class MachineRow extends PureComponent<MachineRowProps> {
  @bind
  handleClick(): void {
    this.props.onClick(this.props.machine.url);
  }
  render(): React.JSX.Element {
    const {machine} = this.props;
    const identifier = machine.c5_machine;
    const {name} = machine;
    let machineString;
    if (identifier) {
      machineString = `${identifier}: ${name}`;
    } else {
      machineString = name;
    }
    let vinColumn;
    if (this.props.customerSettings.workshopVehicleIdentificationNumber) {
      vinColumn = <TableCell>{machine.vehicleIdentificationNumber}</TableCell>;
    }
    return (
      <TableRow onClick={this.handleClick} style={{cursor: "pointer"}}>
        <TableCell>{machineString}</TableCell>
        {vinColumn}
      </TableRow>
    );
  }
}

interface MachineTableProps {
  customerSettings: Config;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  machineList: readonly Machine[];
}

export class MachineTable extends PureComponent<MachineTableProps> {
  @bind
  handleRowClick(machineURL: string): void {
    const id = urlToId(machineURL);
    this.props.go("/machine/:id", {id});
  }
  render(): React.JSX.Element {
    const {machineList} = this.props;
    const machineRowList = machineList.map((machine) => {
      return (
        <MachineRow
          customerSettings={this.props.customerSettings}
          key={machine.url}
          machine={machine}
          onClick={this.handleRowClick}
        />
      );
    });
    let vinHeaderColumn;
    if (this.props.customerSettings.workshopVehicleIdentificationNumber) {
      vinHeaderColumn = (
        <TableCell>
          <FormattedMessage
            defaultMessage="Stelnummer"
            id="workshop.table-header.vehicle-identification-number"
          />
        </TableCell>
      );
    }
    return (
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              {this.props.customerSettings.machineLabelVariant === "MACHINE" ? (
                <FormattedMessage defaultMessage="Maskine" id="workshop.table-header.machine" />
              ) : (
                <FormattedMessage defaultMessage="Køretøj" id="workshop.table-header.vehicle" />
              )}
            </TableCell>
            {vinHeaderColumn}
          </TableRow>
        </TableHead>
        <TableBody>{machineRowList}</TableBody>
      </Table>
    );
  }
}
