import {Config} from "@co-common-libs/config";
import {
  Machine,
  MachineUrl,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {identifierComparator} from "@co-common-libs/utils";
import {
  actions,
  AppState,
  getCustomerSettings,
  getFilteredMachineArray,
  getPriceGroupLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {MultiSelectableListCard, SelectableListCard} from "app-components";
import {getPriceGroupsFromWorkTypeOrMachine, getSortedWorkTypes, PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  chooseWorkType: {
    defaultMessage: "Vælg først arbejdsområde.",
    id: "worktype-settings.label.choose-work-type",
  },
  favoriteMachines: {
    defaultMessage: "Favoritmaskiner",
    id: "worktype-settings.header.standard-machines",
  },
  favoriteVehicles: {
    defaultMessage: "Favoritkøretøjer",
    id: "worktype-settings.header.favorite-vehicles",
  },
  helpText: {
    defaultMessage:
      "De valgte maskiner bliver vist øverst på listen når der vælges maskiner på en opgave.",
    id: "worktype-settings.label.standard-machines-help-text",
  },
  helpTextVehicles: {
    defaultMessage:
      "De valgte køretøjer bliver vist øverst på listen når der vælges køretøjer på en opgave.",
    id: "worktype-settings.label.standard-machines-vehicles-help-text",
  },
  worktypes: {
    defaultMessage: "Arbejdsområder",
    id: "worktype-settings.header.worktypes",
  },
});

interface StandardMachinesStateProps {
  customerSettings: Config;
  machineArray: readonly Machine[];
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface StandardMachinesDispatchProps {
  update: (url: string, patch: PatchUnion) => void;
}

type StandardMachinesProps = StandardMachinesDispatchProps & StandardMachinesStateProps;

interface StandardMachinesState {
  selectedUrl: PriceGroupUrl | WorkTypeUrl | null;
}

class StandardMachines extends PureComponent<StandardMachinesProps> {
  static contextType = IntlContext;

  context!: React.ContextType<typeof IntlContext>;
  state: StandardMachinesState = {
    selectedUrl: null,
  };

  getMachineSetForSelectedInstance(): Set<MachineUrl> {
    const {priceGroupLookup, workTypeLookup} = this.props;
    const {selectedUrl} = this.state;
    if (selectedUrl) {
      const instance =
        workTypeLookup(selectedUrl as WorkTypeUrl) ||
        priceGroupLookup(selectedUrl as PriceGroupUrl);
      if (instance?.machines) {
        return new Set(_.sortBy(instance.machines));
      }
    }
    return new Set();
  }

  getSortedMachines(): Machine[] {
    const {machineArray} = this.props;
    return machineArray
      .filter((m) => m.active)
      .sort((a, b) => identifierComparator(a.c5_machine, b.c5_machine));
  }

  @bind
  handleMachineCheckedChanged(
    _event: React.SyntheticEvent<unknown>,
    newValue: ReadonlySet<MachineUrl>,
  ): void {
    const oldValue = this.getMachineSetForSelectedInstance();
    if (this.state.selectedUrl && !_.isEqual(newValue, oldValue)) {
      const machineList = Array.from(newValue);
      this.props.update(this.state.selectedUrl, [{member: "machines", value: machineList}]);
    }
  }

  @bind
  handleSelected(
    _event: React.SyntheticEvent<unknown>,
    url: PriceGroupUrl | WorkTypeUrl | null,
  ): void {
    this.setState({
      selectedUrl: url,
    });
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const {selectedUrl} = this.state;

    const sortedworkTypes = getSortedWorkTypes(
      this.props.workTypeArray,
      customerSettings.disabledWorkTypes,
    );
    const sortedMachines = selectedUrl ? this.getSortedMachines() : [];

    const worktypesData = sortedworkTypes.map((workType) => {
      const pricegroups = getPriceGroupsFromWorkTypeOrMachine(
        workType,
        this.props.priceGroupLookup,
      );
      return {
        primaryText: workType.name,
        secondaryText: workType.identifier,
        subEntries: pricegroups.map((priceGroup) => ({
          primaryText: priceGroup.name,
          secondaryText: priceGroup.identifier,
          value: priceGroup.url,
        })),
        value: workType.url,
      };
    });

    const machinesData = sortedMachines.map((machine) => {
      return {
        primaryText: machine.name,
        secondaryText: machine.c5_machine,
        value: machine.url,
      };
    });
    const mobileWidth = 360;
    // Grid/Cell types do not accept style prop ...?
    const GridNoType = Grid as any;
    const CellNoType = Cell as any;
    return (
      <GridNoType
        style={{
          height: bowser.mobile ? "auto" : "calc(100% - 64px)",
          margin: 5,
        }}
      >
        <CellNoType
          palm="12/12"
          style={{
            height: bowser.mobile ? mobileWidth : "100%",
            paddingBottom: 11,
            paddingTop: 11,
          }}
        >
          <SelectableListCard<PriceGroupUrl | WorkTypeUrl>
            data={worktypesData}
            onChange={this.handleSelected}
            title={formatMessage(messages.worktypes)}
            value={this.state.selectedUrl || undefined}
          />
        </CellNoType>
        <CellNoType
          palm="12/12"
          style={{
            height: bowser.mobile ? mobileWidth : "100%",
            paddingBottom: 11,
            paddingTop: 11,
          }}
        >
          <MultiSelectableListCard<MachineUrl>
            data={machinesData}
            emptyPrimaryText={
              this.state.selectedUrl ? undefined : formatMessage(messages.chooseWorkType)
            }
            onChange={this.handleMachineCheckedChanged}
            subtitle={
              customerSettings.machineLabelVariant === "MACHINE"
                ? formatMessage(messages.helpText)
                : formatMessage(messages.helpTextVehicles)
            }
            title={
              customerSettings.machineLabelVariant === "MACHINE"
                ? formatMessage(messages.favoriteMachines)
                : formatMessage(messages.favoriteVehicles)
            }
            value={this.getMachineSetForSelectedInstance()}
          />
        </CellNoType>
      </GridNoType>
    );
  }
}

const ConnectedStandardMachines = connect<
  StandardMachinesStateProps,
  StandardMachinesDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, StandardMachinesStateProps>({
    customerSettings: getCustomerSettings,
    machineArray: getFilteredMachineArray,
    priceGroupLookup: getPriceGroupLookup,
    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    update: actions.update,
  },
)(StandardMachines);

export default ConnectedStandardMachines;
