import {urlToId} from "@co-common-libs/resources";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {SvgIcon} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import TagTextOutlineIcon from "mdi-react/TagTextOutlineIcon";
import React from "react";

const wrapperStyle = {
  height: 24,
  width: 24,
};

interface OrderIconLinkProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  url: string;
}

export class OrderIconLink extends PureComponent<OrderIconLinkProps> {
  @bind
  handleClick(event: React.MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    const id = urlToId(this.props.url);
    this.props.go("/orderEntry/:id", {id});
  }
  render(): React.JSX.Element {
    return (
      <div onClick={this.handleClick} style={wrapperStyle}>
        <SvgIcon>
          <TagTextOutlineIcon />
        </SvgIcon>
      </div>
    );
  }
}
