import {
  Command,
  CustomerUrl,
  instanceURL,
  LocationUrl,
  LocationUseLog,
  ReportingLocations,
  Task,
  urlToId,
} from "@co-common-libs/resources";
import {sortByOrderMember} from "@co-common-libs/utils";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getCustomerSettings,
  getLocationUseLogArray,
  getOrderLookup,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";
import {getBaseURL} from "./get-base-url";

const MAX_SAVED_LOCATION_COUNT = 5;

function getLocationURLsPerCustomerFromReportingLocations(
  reportingLocations: ReportingLocations,
): ReadonlyMap<CustomerUrl, LocationUrl[]> {
  const customerLocationURLs = new Map<CustomerUrl, LocationUrl[]>();
  for (const locationEntry of sortByOrderMember(Object.values(reportingLocations))) {
    if (locationEntry.location && locationEntry.customer) {
      const existing = customerLocationURLs.get(locationEntry.customer);
      if (existing) {
        existing.push(locationEntry.location);
      } else {
        customerLocationURLs.set(locationEntry.customer, [locationEntry.location]);
      }
    }
  }
  return customerLocationURLs;
}

// only triggered on *adding* workplace, fields or locations;
// *after* setting customer and machineoperator
export function writeLocationUseLog(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  if (!newTask) {
    return null;
  }

  if (
    !newTask.machineOperator ||
    !newTask.order ||
    ((!newTask.relatedWorkplace || newTask.relatedWorkplace === oldTask?.relatedWorkplace) &&
      (!newTask.fielduseSet?.length || _.isEqual(newTask.fielduseSet, oldTask?.fielduseSet)) &&
      (!newTask.reportingLocations ||
        _.isEqual(newTask.reportingLocations, oldTask?.reportingLocations)))
  ) {
    return null;
  }

  const state = middlewareApi.getState();
  const customerSettings = getCustomerSettings(state);
  if (
    customerSettings.defaultTaskEmployee &&
    urlToId(newTask.machineOperator) === customerSettings.defaultTaskEmployee
  ) {
    // nothing to save if machineOperator is defaultTaskEmployee
    return null;
  }

  const orderLookup = getOrderLookup(state);

  const {machineOperator} = newTask;
  const order = orderLookup(newTask.order);
  const customer = order?.customer;
  const addedLocationURLs = new Map<CustomerUrl, LocationUrl[]>();
  if (
    newTask.relatedWorkplace &&
    newTask.relatedWorkplace !== oldTask?.relatedWorkplace &&
    customer
  ) {
    addedLocationURLs.set(customer, [newTask.relatedWorkplace]);
  }
  if (
    newTask.fielduseSet?.length &&
    !_.isEqual(newTask.fielduseSet, oldTask?.fielduseSet) &&
    customer &&
    !newTask.reportingSpecification
  ) {
    const fieldURLs = newTask.fielduseSet.map((f) => f.relatedField);
    const oldfieldUseSet = oldTask?.fielduseSet || [];
    const oldfieldURLs = oldfieldUseSet.map((p) => p.relatedField);
    const addedFieldsOnTask = _.difference(fieldURLs, oldfieldURLs);
    if (addedFieldsOnTask.length) {
      const existing = addedLocationURLs.get(customer);
      if (existing) {
        addedLocationURLs.set(customer, [...addedFieldsOnTask, ...existing]);
      } else {
        addedLocationURLs.set(customer, addedFieldsOnTask);
      }
    }
  }

  if (
    newTask.reportingLocations &&
    Object.keys(newTask).length &&
    !_.isEqual(newTask.reportingLocations, oldTask?.reportingLocations)
  ) {
    const newLogLocations = getLocationURLsPerCustomerFromReportingLocations(
      newTask.reportingLocations,
    );
    const oldLogLocations = oldTask?.reportingLocations
      ? getLocationURLsPerCustomerFromReportingLocations(oldTask.reportingLocations)
      : null;
    newLogLocations.forEach((locationURLs, customerURL) => {
      const oldLocationURLsForCustomer = oldLogLocations
        ? oldLogLocations.get(customerURL) || []
        : [];
      const addedLocationsForCustomer = _.difference(locationURLs, oldLocationURLsForCustomer);
      if (addedLocationsForCustomer.length) {
        const existing = addedLocationURLs.get(customerURL);
        if (existing) {
          addedLocationURLs.set(customerURL, [...addedLocationsForCustomer, ...existing]);
        } else {
          addedLocationURLs.set(customerURL, addedLocationsForCustomer);
        }
      }
    });
  }

  if (!addedLocationURLs.size) {
    return null;
  }

  const commands: Command[] = [];

  addedLocationURLs.forEach((locationURLs, customerURL) => {
    const locationUseLogArray = getLocationUseLogArray(state);
    const filteredLocationUseLogArray = locationUseLogArray.filter(
      (p) => p.user === machineOperator && p.customer === customerURL,
    );
    const existingLocationUseLog = _.maxBy(
      filteredLocationUseLogArray,
      (p) => p.lastChanged || "x",
    );
    if (existingLocationUseLog) {
      const newLocationList = [
        ...new Set(locationURLs.concat(existingLocationUseLog.locations)),
      ].slice(0, MAX_SAVED_LOCATION_COUNT);
      if (_.isEqual(newLocationList, existingLocationUseLog.locations)) {
        return;
      }
      const patchLocationUseLog: Command = {
        action: "UPDATE",
        patch: [{member: "locations", value: newLocationList}],
        url: existingLocationUseLog.url,
      };
      commands.push(patchLocationUseLog);
    } else {
      const newLocationList = [...new Set(locationURLs)].slice(0, MAX_SAVED_LOCATION_COUNT);
      const id = uuid();
      const baseURL = getBaseURL(command.url);
      const url = instanceURL(baseURL, "locationUseLog", id);
      const newLocationUseLog: LocationUseLog = {
        customer: customerURL,
        id,
        locations: newLocationList,
        url,
        user: machineOperator,
      };
      commands.push({action: "CREATE", instance: newLocationUseLog, url});
    }
  });
  return {after: commands};
}
