import {defaultTo} from "lodash";

export const defaultToNull = <T>(value: T | null | undefined): T | null => defaultTo(value, null);

export const defaultToUndefined = <T>(value: T | null | undefined): T | undefined =>
  defaultTo(value, undefined);

export const defaultToEmptyString = <T extends string>(value: T | null | undefined): T =>
  defaultTo(value, "" as T);

export const defaultToEmpty = <T>(values: readonly T[] | null | undefined): readonly T[] =>
  defaultTo(values, []);
