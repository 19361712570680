import {Customer, emptyCustomer} from "@co-common-libs/resources";
import {Divider} from "@material-ui/core";
import {SPACING, theme} from "frontend-global-config";
import React, {useCallback, useState} from "react";
import {CVRAutocompleteField} from "../cvr-autocomplete/cvr-autocomplete-field";
import {CvrResult} from "../cvr-autocomplete/cvr-types";
import {CustomerForm, CustomerFormProps} from "./customer-form";
import {transformToCustomerFormPart} from "./transform-to-customer-form-part";
import {CustomerFormPart} from "./types";

function cvrResultToCustomer(cvrResult: CvrResult): Partial<Customer> {
  const {address, city, email, name, phoneNumber, postalCode, vatNumber} = cvrResult;

  return {
    address,
    billingEmail: email,
    city,
    name,
    phone: phoneNumber,
    postalCode,
    vatNumber,
  };
}

interface CustomerFormWithCvrAutocompleteProps extends Omit<CustomerFormProps, "autoFocus"> {
  customer?: Partial<CustomerFormPart> | undefined;
  formWarningText?: string;
}

export const CustomerFormWithCvrAutocomplete = React.memo(function CustomerFormWithCvrAutocomplete(
  props: CustomerFormWithCvrAutocompleteProps,
): React.JSX.Element {
  const {
    customer,
    disabledInputFields,
    formCustomer,
    formDispatch,
    formWarningText,
    visibleInputFields,
    ...other
  } = props;

  const [cvrSearch, setCvrSearch] = useState<string>(customer?.name || "");

  const handleCVRResultSelected = useCallback(
    (cvrResult: CvrResult): void => {
      const cvrResultCustomer = {
        ...emptyCustomer,
        ...cvrResultToCustomer(cvrResult),
      };

      formDispatch({
        type: "reset",
        value: transformToCustomerFormPart(cvrResultCustomer),
      });
    },
    [formDispatch],
  );

  // hide cvr search if all inputs are disabled
  const showCVRSearch = disabledInputFields.size !== visibleInputFields.size;

  return (
    <>
      {showCVRSearch ? (
        <>
          <CVRAutocompleteField
            autoFocus={!formCustomer.address}
            onChange={setCvrSearch}
            onCVRResultSelected={handleCVRResultSelected}
            open={!formCustomer.address}
            value={cvrSearch}
          />
          <Divider
            style={{
              marginBottom: theme.spacing(SPACING.SMALL),
              marginTop: theme.spacing(SPACING.SMALL),
            }}
          />
        </>
      ) : null}
      {formWarningText ? (
        <div style={{color: theme.palette.warning.main}}>{formWarningText}</div>
      ) : null}

      <CustomerForm
        autoFocus={!!formCustomer.address}
        disabledInputFields={disabledInputFields}
        formCustomer={formCustomer}
        formDispatch={formDispatch}
        visibleInputFields={visibleInputFields}
        {...other}
      />
    </>
  );
});
