import {Config} from "@co-common-libs/config";
import {CustomerUrl, Location, LocationUrl, Order, OrderUrl} from "@co-common-libs/resources";
import {formatAddress, formatDateNumeric} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getOrderLookup,
} from "@co-frontend-libs/redux";
import {DialogContent, FormControlLabel, Radio, RadioGroup, useTheme} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

function makeOrderLabel(
  order: Order,
  customerSettings: Config,
  locationLookup: (url: LocationUrl) => Location | undefined,
): string {
  const label: string[] = [];
  if (order.relatedWorkplace) {
    const location = locationLookup(order.relatedWorkplace);
    label.push(formatAddress(location));
  }
  if (customerSettings.enableOrderReferenceNumber) {
    label.push(order.referenceNumber);
  }
  label.push(formatDateNumeric(order.date));

  return label.join(", ");
}

interface OrderMergeDialogProps {
  customer?: CustomerUrl | undefined;
  onCancel: () => void;
  onOk: (orderUrl: OrderUrl) => void;
  open: boolean;
  orderList: readonly OrderUrl[];
}

export const OrderMergeDialog = React.memo(function OrderMergeDialog({
  customer,
  onCancel,
  onOk,
  open,
  orderList,
}: OrderMergeDialogProps): React.JSX.Element | null {
  const [selectedOrder, setSelectedOrder] = useState<OrderUrl | null>(null);

  useEffect(() => {
    if (!open) {
      setSelectedOrder(null);
    }
  }, [open]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOrder((event.target.value as OrderUrl) || null);
  }, []);

  const handleOk = useCallback(() => {
    if (!selectedOrder) {
      return;
    }
    onOk(selectedOrder);
  }, [onOk, selectedOrder]);

  const theme = useTheme();
  const customerLookup = useSelector(getCustomerLookup);
  const orderLookup = useSelector(getOrderLookup);
  const locationLookup = useSelector(getLocationLookup);
  const customerSettings = useSelector(getCustomerSettings);
  if (!customer) {
    return null;
  }

  return (
    <ResponsiveDialog
      okDisabled={!selectedOrder}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={<FormattedMessage defaultMessage="Flet ordre" id="order-merge-dialog.title" />}
    >
      <DialogContent>
        <div style={{color: theme.palette.error.main}}>
          <FormattedMessage
            defaultMessage="Vælg den ordre som de andre ordre skal flettes ind på. Handlingen kan ikke fortrydes."
            id="order-merge-dialog.text"
          />
        </div>
        <FormattedMessage
          defaultMessage="Kunde: {customer}"
          id="order-merge-dialog.customer"
          values={{customer: customerLookup(customer)?.name}}
        />
        <RadioGroup onChange={handleChange} value={selectedOrder}>
          {orderList.map((url) => {
            const order = orderLookup(url);
            if (!order) {
              return null;
            }
            const label = makeOrderLabel(order, customerSettings, locationLookup);
            return (
              <FormControlLabel
                control={<Radio />}
                key={order.url}
                label={label}
                value={order.url}
              />
            );
          })}
        </RadioGroup>
      </DialogContent>
    </ResponsiveDialog>
  );
});
