import {Crop, CustomerUrl, Location} from "@co-common-libs/resources";
import {createSelector, ParametricSelector} from "reselect";
import {getCropArray, getLocationArray} from "../resources/selectors";
import {AppState} from "../root-reducer";
import {filterOnActive} from "./utils";

function filterVisible(locationArray: readonly Location[]): readonly Location[] {
  return locationArray.filter((location) => location.mergedTo == null);
}

function filterOutFields(locationArray: readonly Location[]): readonly Location[] {
  return locationArray.filter((location) => !location.geojson);
}

function filterFields(locationArray: readonly Location[]): readonly Location[] {
  return locationArray.filter((location) => location.geojson);
}

function makeCropColorMap(cropArray: readonly Crop[]): ReadonlyMap<string, string> {
  const map = new Map<string, string>();
  cropArray.forEach((crop) => map.set(crop.name, crop.color));
  return map;
}

export const getVisibleLocationArray: (state: AppState) => readonly Location[] = createSelector(
  getLocationArray,
  filterVisible,
);

export const getActiveLocationArray: (state: AppState) => readonly Location[] = createSelector(
  getVisibleLocationArray,
  filterOnActive,
);

export const getNonFieldLocationArray: (state: AppState) => readonly Location[] = createSelector(
  getVisibleLocationArray,
  filterOutFields,
);

export const getActiveNonFieldLocationArray: (state: AppState) => readonly Location[] =
  createSelector(getActiveLocationArray, filterOutFields);

export const getFieldLocationArray: (state: AppState) => readonly Location[] = createSelector(
  getVisibleLocationArray,
  filterFields,
);

export const getActiveFieldLocationArray: (state: AppState) => readonly Location[] = createSelector(
  getActiveLocationArray,
  filterFields,
);

export const getCropColorMap: (state: AppState) => ReadonlyMap<string, string> = createSelector(
  getCropArray,
  makeCropColorMap,
);

function filterLocationsOnCustomer(
  locations: readonly Location[],
  customerUrl: CustomerUrl | null,
): readonly Location[] {
  return locations.filter(({customer}) => customer === customerUrl);
}

export function makeSelectCustomerActiveFields(): ParametricSelector<
  AppState,
  CustomerUrl | null,
  readonly Location[]
> {
  return createSelector(
    getActiveFieldLocationArray,
    (_: AppState, customerUrl: CustomerUrl | null) => customerUrl,
    filterLocationsOnCustomer,
  );
}
