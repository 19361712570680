import {getCurrentRole} from "@co-frontend-libs/redux";
import {NetworkError} from "@co-frontend-libs/utils";
import {useTheme} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

export const EconomicDownloadErrorBlock = React.memo(function EconomicDownloadErrorBlock({
  downloadError,
}: {
  downloadError: string | Error;
}): React.JSX.Element {
  const theme = useTheme();
  const currentRole = useSelector(getCurrentRole);

  return (
    <>
      {downloadError instanceof NetworkError ? (
        <FormattedMessage
          defaultMessage="Der er sket en fejl ved hentning af data fra e-conomic: Kunne ikke forbinde til server."
          tagName="div"
        />
      ) : (
        <FormattedMessage
          defaultMessage="Der er sket en fejl ved hentning af data fra e-conomic. Prøv igen om lidt."
          tagName="div"
        />
      )}
      {currentRole?.consultant && typeof downloadError === "string" ? (
        <div style={{backgroundColor: theme.palette.consultant.main}}>{downloadError}</div>
      ) : null}
    </>
  );
});
