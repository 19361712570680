import {OrderUrl, urlToId} from "@co-common-libs/resources";
import {AwaitingBackendRequestSpinner} from "@co-frontend-libs/components";
import {ResponseWithData} from "@co-frontend-libs/utils";
import {getApiEndpoint} from "api-endpoint-urls";
import {PhotosCard, PhotoTaskData} from "app-components";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useState} from "react";

interface OrderPhotoFileAppendicesProps {
  orderUrl: OrderUrl;
}

export const OrderPhotoFileAppendices = React.memo(function OrderPhotoFileAppendices(
  props: OrderPhotoFileAppendicesProps,
): React.JSX.Element {
  const {orderUrl} = props;
  const [photoTasks, setPhotoTasks] = useState<PhotoTaskData[] | null>(null);

  const handleSucces = useCallback((response?: ResponseWithData) => {
    if (response) setPhotoTasks(response.data);
  }, []);

  const data = {
    orderId: urlToId(orderUrl),
  };

  if (!photoTasks) {
    return (
      <AwaitingBackendRequestSpinner
        apiUrl={getApiEndpoint(globalConfig.baseURL).orderPhotoFiles()}
        data={data}
        execute={!photoTasks}
        method="POST"
        onSuccess={handleSucces}
      />
    );
  }

  return <PhotosCard data={photoTasks} />;
});
