import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  EmployeeGroupUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PatchUnion,
  Role,
  RoutePlan,
  RoutePlanUrl,
  Task,
  TimerStart,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  dateFromString,
  dateToString,
  DAY_MILLISECONDS,
  getDateStringSequence,
  WEEK_DAYS,
} from "@co-common-libs/utils";
import {Check, makeQuery, Query} from "@co-frontend-libs/db-resources";
import {
  actions,
  AppState,
  getCompletedOrderMap,
  getCurrentRole,
  getCustomerLookup,
  getCustomerSettings,
  getCustomerURLToDefaultContactMap,
  getFilteredMachineLookup,
  getOrderArray,
  getOrderLookup,
  getRoutePlanLookup,
  getTaskArray,
  getTimerStartArray,
  getUserUserProfileLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {plannedTasksForPeriodDates, PureComponent} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import {endOfDay, startOfDay, subDays} from "date-fns";
import _ from "lodash";
import React from "react";
import {IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {PureOrderTabContent} from "./order-tab-content";

const ARCHIVE_DAYS = 7;

const previousMonday = (date: Date): ImmutableDate => {
  const startDate = new ImmutableDate(date);
  const weekDay = date.getDay();
  // JS has sunday as day 0; so go back to previous "JS-week"

  const offset = weekDay === 0 ? 6 : weekDay - 1;
  return startDate.setDate(startDate.getDate() - offset);
};

interface OrderTabContentContainerStateProps {
  completedOrderMap: ReadonlyMap<string, boolean>;
  currentRole: Role | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  customerURLToDefaultContactMap: ReadonlyMap<string, Contact>;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderArray: readonly Order[];
  orderLookup: (url: OrderUrl) => Order | undefined;
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  taskArray: readonly Task[];
  timerStartArray: readonly TimerStart[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface OrderTabContentContainerDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKeys: (
    update: {readonly [key: string]: string | undefined},
    navigationKind?: PartialNavigationKind,
  ) => void;
  temporaryQueriesDiscardedForPath: (pathName: string, key: string) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface OrderTabContentContainerOwnProps {
  allFolded?: boolean;
  dndMode?: boolean;
  dndSave?: boolean;
  lastVisibleDate?: string | undefined;
  onRequestFilterClear?: () => void;
  pathName: string;
  scrollX?: string;
  scrollY?: string;
  selectedDate: string;
  selectedDepartmentIdentifierSet?: ReadonlySet<string>;
  selectedEmployeeGroupUrlSet?: ReadonlySet<EmployeeGroupUrl>;
  selectedWorkTypeURLSet?: ReadonlySet<string>;
  userIsOnlyMachineOperator: boolean;
}

type OrderTabContentContainerProps = OrderTabContentContainerDispatchProps &
  OrderTabContentContainerOwnProps &
  OrderTabContentContainerStateProps;

const TEMPORARY_QUERIES_KEY = "OrderTabContentContainer";

class OrderTabContentContainer extends PureComponent<OrderTabContentContainerProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  componentWillUnmount(): void {
    const {pathName, temporaryQueriesDiscardedForPath} = this.props;
    temporaryQueriesDiscardedForPath(pathName, TEMPORARY_QUERIES_KEY);
  }

  render(): React.JSX.Element {
    const {
      customerSettings,
      lastVisibleDate,
      selectedDate,
      selectedDepartmentIdentifierSet,
      selectedEmployeeGroupUrlSet,
      selectedWorkTypeURLSet,
      taskArray,
      userUserProfileLookup,
    } = this.props;
    let filteredOrderArray;
    if (customerSettings.orderCalendarAsTaskCalendar) {
      const usedOrderURLs = new Set<string>();
      taskArray.forEach((t) => {
        usedOrderURLs.add(t.order as string);
      });
      filteredOrderArray = this.props.orderArray.filter((order) => usedOrderURLs.has(order.url));
    } else {
      filteredOrderArray = this.props.orderArray.filter((order) => !order.routePlan);
    }
    const {orderDrafts} = this.props.customerSettings;
    const periodLengthWeeks = 3;
    let periodLengthDays = WEEK_DAYS * periodLengthWeeks;
    console.assert(selectedDate);
    const periodStartDate = previousMonday(dateFromString(selectedDate) as Date);
    periodStartDate.setDate(periodStartDate.getDate() - WEEK_DAYS);
    let periodStart = dateToString(periodStartDate);
    if (lastVisibleDate && periodStart > lastVisibleDate) {
      periodStart = lastVisibleDate;
    }
    const periodEndDate = new Date(periodStartDate.valueOf());
    periodEndDate.setDate(periodEndDate.getDate() + periodLengthDays - 1);
    let periodEnd = dateToString(periodEndDate);
    if (lastVisibleDate && periodEnd > lastVisibleDate) {
      periodEnd = lastVisibleDate;
    }
    if (lastVisibleDate) {
      const periodLengthMilliseconds =
        (dateFromString(periodEnd) as Date).valueOf() -
        (dateFromString(periodStart) as Date).valueOf();
      periodLengthDays = Math.round(periodLengthMilliseconds / DAY_MILLISECONDS) + 1;
    }
    const {completedOrderMap} = this.props;
    if (orderDrafts) {
      filteredOrderArray = filteredOrderArray.filter((order) => !order.draft);
    }
    if (selectedWorkTypeURLSet?.size) {
      const ordersWithTasksWithWorkTypes = new Set<string>();
      taskArray.forEach((task) => {
        const workTypeURL = task.workType;
        if (workTypeURL && selectedWorkTypeURLSet.has(workTypeURL)) {
          ordersWithTasksWithWorkTypes.add(task.order as string);
        }
      });
      filteredOrderArray = filteredOrderArray.filter((order) =>
        ordersWithTasksWithWorkTypes.has(order.url),
      );
    }

    if (selectedDepartmentIdentifierSet?.size) {
      const ordersWithTasksWithDepartments = new Set<string>();
      taskArray.forEach((task) => {
        if (task.department && selectedDepartmentIdentifierSet.has(task.department)) {
          ordersWithTasksWithDepartments.add(task.order as string);
        }
      });
      filteredOrderArray = filteredOrderArray.filter((order) =>
        ordersWithTasksWithDepartments.has(order.url),
      );
    }

    if (selectedEmployeeGroupUrlSet && selectedEmployeeGroupUrlSet.size) {
      const orderWithEmployeeGroups = new Set<OrderUrl>();
      taskArray.forEach((task) => {
        if (task.machineOperator) {
          const employeeGroupUrl =
            task.machineOperator && userUserProfileLookup(task.machineOperator)?.employeeGroup;

          if (employeeGroupUrl && selectedEmployeeGroupUrlSet.has(employeeGroupUrl) && task.order)
            orderWithEmployeeGroups.add(task.order);
        }
      });
      filteredOrderArray = filteredOrderArray.filter((order) =>
        orderWithEmployeeGroups.has(order.url),
      );
    }

    const plannedTasksMap = plannedTasksForPeriodDates(periodStart, periodEnd, taskArray);
    const unplannedOrders: Order[] = [];
    const partiallyPlannedOrders: Order[] = [];
    const tasksWithDateOrderURLSet = new Set<string>();
    plannedTasksMap.forEach((taskList, date) => {
      if (!date) {
        return;
      }
      taskList.forEach((task) => {
        tasksWithDateOrderURLSet.add(task.order as string);
      });
    });
    filteredOrderArray.forEach((order) => {
      const orderURL = order.url;
      // FIXME: we won't catch orders where the tasks are outside the period...
      const planned = tasksWithDateOrderURLSet.has(orderURL);
      if (planned) {
        return;
      }
      const {earliestDate} = order;
      const {latestDate} = order;
      if (!earliestDate || !latestDate || earliestDate > latestDate) {
        const hasTaskWithDate = taskArray.some(
          (task) => task.order === orderURL && !!(task.date || task.workFromTimestamp),
        );

        if (!hasTaskWithDate) {
          unplannedOrders.push(order);
          return;
        }
      }
      if (!customerSettings.orderCalendarAsTaskCalendar) {
        // Overlap unless order starts too late or ends too soon.
        const overlapsPeriod =
          earliestDate && latestDate
            ? earliestDate <= periodEnd && periodStart <= latestDate
            : false;
        if (overlapsPeriod) {
          partiallyPlannedOrders.push(order);
        }
      }
    });
    const dateToOrderGroupedTaskListMap = new Map<string, Map<OrderUrl, Task[]>>();
    plannedTasksMap.forEach((taskList, dateString) => {
      if (!dateString) {
        return;
      }
      const taskListByOrder = new Map<OrderUrl, Task[]>();
      taskList.forEach((task) => {
        const orderURL = task.order;
        if (!orderURL) {
          return;
        }
        const existing = taskListByOrder.get(orderURL);
        if (existing) {
          existing.push(task);
        } else {
          taskListByOrder.set(orderURL, [task]);
        }
      });
      const sortedTaskListByOrderEntries = _.sortBy(Array.from(taskListByOrder.entries()), [
        ([orderURL, _taskList]) => !!completedOrderMap.get(orderURL),
      ]);
      dateToOrderGroupedTaskListMap.set(dateString, new Map(sortedTaskListByOrderEntries));
    });

    const dateToOrderMap = new Map<string, Order[]>();
    filteredOrderArray.forEach((order) => {
      const orderStartDateString = order.date;
      if (!orderStartDateString) {
        // no date set...
        return;
      }
      if (orderStartDateString > periodEnd) {
        // not included in period; starts too late
        return;
      }
      const {durationDays} = order;
      if (durationDays && durationDays > 1) {
        // ends *after* start date
        const orderEndDate = dateFromString(orderStartDateString) as Date;
        orderEndDate.setDate(orderEndDate.getDate() + durationDays - 1);
        const orderEndDateString = dateToString(orderEndDate);
        if (orderEndDateString < periodStart) {
          // not included in period; ends too early
          return;
        } else {
          // in period, one or more days
          const visibleStart =
            orderStartDateString >= periodStart ? orderStartDateString : periodStart;
          const visibleEnd = orderEndDateString <= periodEnd ? orderEndDateString : periodEnd;
          getDateStringSequence(visibleStart, visibleEnd).forEach((dateString) => {
            const oldList = dateToOrderMap.get(dateString) || [];
            const newList = [...oldList, order];
            dateToOrderMap.set(dateString, newList);
          });
        }
      } else {
        // ends *on* start date...
        if (orderStartDateString < periodStart) {
          // not included in period; ends too early
          return;
        } else {
          // in period; one day
          const oldList = dateToOrderMap.get(orderStartDateString) || [];
          const newList = [...oldList, order];
          dateToOrderMap.set(orderStartDateString, newList);
        }
      }
    });
    const orderToTaskListMap = new Map<string, Task[]>();
    taskArray.forEach((task) => {
      const orderURL = task.order as string;
      const orderTaskArray = orderToTaskListMap.get(orderURL);
      if (!orderTaskArray) {
        orderToTaskListMap.set(orderURL, [task]);
      } else {
        orderTaskArray.push(task);
      }
    });
    const filteredOrderMap = new Map(filteredOrderArray.map((order) => [order.url, order]));
    const filteredOrderLookup = filteredOrderMap.get.bind(filteredOrderMap);
    return (
      <div style={{height: "100%", width: "100%"}}>
        <PureOrderTabContent
          dateToOrderGroupedTaskListMap={dateToOrderGroupedTaskListMap}
          dateToOrderMap={dateToOrderMap}
          orderToTaskListMap={orderToTaskListMap}
          partiallyPlannedOrders={partiallyPlannedOrders}
          periodEnd={dateToString(periodEndDate)}
          periodLengthDays={periodLengthDays}
          periodStart={dateToString(periodStartDate)}
          plannedTasksMap={plannedTasksMap}
          unplannedOrders={unplannedOrders}
          {...this.props}
          allFolded={!!this.props.allFolded}
          completedOrderMap={completedOrderMap}
          dndMode={!!this.props.dndMode}
          dndSave={!!this.props.dndSave}
          // should use React.memo when changed to function component
          // eslint-disable-next-line react/jsx-no-bind
          filteredOrderLookup={filteredOrderLookup}
          go={this.props.go}
          isManager={this.props.currentRole?.manager ?? false}
          scrollX={this.props.scrollX || "0"}
          scrollY={this.props.scrollY || "0"}
        />
      </div>
    );
  }
  UNSAFE_componentWillMount(): void {
    const {selectedDate} = this.props;
    this.updateQuery(selectedDate);
  }

  UNSAFE_componentWillReceiveProps(nextProps: OrderTabContentContainerProps): void {
    if (nextProps.selectedDate !== this.props.selectedDate) {
      this.updateQuery(nextProps.selectedDate);
    }
  }
  updateQuery(selectedDate: string): void {
    const {lastVisibleDate} = this.props;
    console.assert(selectedDate);
    const periodStartDate = previousMonday(dateFromString(selectedDate) as Date);

    periodStartDate.setDate(periodStartDate.getDate() - 7);
    let periodStart = dateToString(periodStartDate);
    if (lastVisibleDate && periodStart > lastVisibleDate) {
      periodStart = lastVisibleDate;
    }
    const periodEndDate = new Date(periodStartDate.valueOf());

    periodEndDate.setDate(periodEndDate.getDate() + 7 + 7 + 6);
    let periodEnd = dateToString(periodEndDate);
    if (lastVisibleDate && periodEnd > lastVisibleDate) {
      periodEnd = lastVisibleDate;
    }
    const timeBackDateDate = new Date();
    timeBackDateDate.setDate(timeBackDateDate.getDate() - ARCHIVE_DAYS);
    const timeBackDate = dateToString(timeBackDateDate);
    if (periodStart <= timeBackDate) {
      // archive relevant --- completed/recorded tasks for this date may not
      // be present in normal working set
      const {pathName, temporaryQueriesRequestedForPath} = this.props;
      const rangeStartString = startOfDay(dateFromString(periodStart) as Date).toISOString();
      const rangeEndString = endOfDay(dateFromString(periodEnd) as Date).toISOString();
      const taskCheck: Check = {
        checks: [
          {
            checks: [
              {
                memberName: "workToTimestamp",
                type: "memberGt",
                value: rangeStartString,
              },
              {
                memberName: "workFromTimestamp",
                type: "memberLt",
                value: rangeEndString,
              },
              {memberName: "completed", type: "memberTruthy"},
            ],
            type: "and",
          },
          {
            checks: [
              {
                memberName: "date",
                type: "memberGte",
                value: dateToString(subDays(new Date(rangeStartString), WEEK_DAYS)),
              },
              {
                memberName: "date",
                type: "memberLte",
                value: periodEnd,
              },
            ],
            type: "and",
          },
          {memberName: "completed", type: "memberFalsy"},
        ],
        type: "or",
      };
      const orderCheck: Check = {
        check: taskCheck,
        fromResource: "task",
        memberName: "order",
        type: "targetOfForeignKey",
      };
      const relatedTaskCheck: Check = {
        check: taskCheck,
        memberName: "task",
        targetType: "task",
        type: "hasForeignKey",
      };
      const queries = [
        makeQuery({
          check: taskCheck,
          filter: {
            fromDate: periodStart,
            toDate: periodEnd,
          },
          independentFetch: true,
          resourceName: "task",
        }),
        makeQuery({
          check: orderCheck,
          independentFetch: false,
          resourceName: "order",
        }),
        makeQuery({
          check: relatedTaskCheck,
          independentFetch: false,
          resourceName: "timerStart",
        }),
      ];
      temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY);
    }
  }
}

export const OrderTabContent = connect<
  OrderTabContentContainerStateProps,
  OrderTabContentContainerDispatchProps,
  OrderTabContentContainerOwnProps,
  AppState
>(
  createStructuredSelector<AppState, OrderTabContentContainerStateProps>({
    completedOrderMap: getCompletedOrderMap,
    currentRole: getCurrentRole,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    customerURLToDefaultContactMap: getCustomerURLToDefaultContactMap,
    machineLookup: getFilteredMachineLookup,
    orderArray: getOrderArray,
    orderLookup: getOrderLookup,
    routePlanLookup: getRoutePlanLookup,
    taskArray: getTaskArray,
    timerStartArray: getTimerStartArray,
    userUserProfileLookup: getUserUserProfileLookup,

    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    go: actions.go,
    putQueryKeys: actions.putQueryKeys,
    temporaryQueriesDiscardedForPath: actions.temporaryQueriesDiscardedForPath,
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
    update: actions.update,
  },
)(OrderTabContentContainer);
