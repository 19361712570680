import {Task} from "@co-common-libs/resources";
import {HALF_HOUR_MINUTES} from "@co-common-libs/utils";
import {
  getContactLookup,
  getCultureLookup,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPriceItemLookup,
  getProductLookup,
  getProjectLookup,
  getRoutePlanLookup,
  getTimerLookup,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import React, {useMemo} from "react";
import {useDragLayer, XYCoord} from "react-dnd";
import {useSelector} from "react-redux";
import {HALF_HOUR_ROW_HEIGHT, NO_ESTIMATE_TASK_MINUTES} from "./constants";
import {TaskBlock} from "./task-block";
import {resolveTaskRelations} from "./utils";

const layerStyles: React.CSSProperties = {
  height: "100%",
  left: 0,
  pointerEvents: "none",
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 10000,
};

export const TaskDragPreview = (): React.JSX.Element | null => {
  const {difference, initialOffset, isDragging, item, itemType} = useDragLayer<{
    difference: XYCoord | null;
    initialOffset: XYCoord | null;
    isDragging: boolean;
    item: Task;
    itemType: any;
  }>((monitor) => ({
    difference: monitor.getDifferenceFromInitialOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
  }));
  const customerSettings = useSelector(getCustomerSettings);
  const machineLookup = useSelector(getMachineLookup);
  const unitLookup = useSelector(getUnitLookup);
  const contactLookup = useSelector(getContactLookup);
  const cultureLookup = useSelector(getCultureLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const locationLookup = useSelector(getLocationLookup);
  const orderLookup = useSelector(getOrderLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const projectLookup = useSelector(getProjectLookup);
  const routePlanLookup = useSelector(getRoutePlanLookup);
  const timerLookup = useSelector(getTimerLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const taskWithRelations = useMemo(() => {
    if (!isDragging || itemType !== "task") {
      return null;
    }
    return resolveTaskRelations(
      item,
      null,
      null,
      new Date().toISOString(),
      false,
      customerSettings,
      {
        contactLookup,
        cultureLookup,
        customerLookup,
        locationLookup,
        machineLookup,
        orderLookup,
        priceItemLookup,
        productLookup,
        projectLookup,
        routePlanLookup,
        timerLookup,
        workTypeLookup,
      },
    );
  }, [
    contactLookup,
    cultureLookup,
    customerLookup,
    customerSettings,
    isDragging,
    item,
    itemType,
    locationLookup,
    machineLookup,
    orderLookup,
    priceItemLookup,
    productLookup,
    projectLookup,
    routePlanLookup,
    timerLookup,
    workTypeLookup,
  ]);

  if (!taskWithRelations) {
    return null;
  }
  let style: React.CSSProperties | undefined;
  let content;
  if (!initialOffset) {
    style = {
      display: "none",
    };
  } else {
    const transform = `translate(${difference?.x}px, ${difference?.y}px)`;

    const containerStyle: React.CSSProperties = {
      height:
        (item.minutesExpectedTotalTaskDuration || NO_ESTIMATE_TASK_MINUTES) *
        (HALF_HOUR_ROW_HEIGHT / HALF_HOUR_MINUTES),
      left: initialOffset.x,
      opacity: 0.8,
      top: initialOffset.y,
      transform,
      WebkitTransform: transform,
    };

    content = (
      <TaskBlock
        containerStyle={containerStyle}
        {...taskWithRelations}
        blockType="planned"
        customerSettings={customerSettings}
        task={item}
        unitLookup={unitLookup}
      />
    );
  }
  return (
    <div style={layerStyles}>
      <div style={style || {}}>{content}</div>
    </div>
  );
};
