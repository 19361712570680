import {formatDateTimeWithoutWeekday} from "@co-common-libs/utils";
import _ from "lodash";
import React from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";

export type JournalDataEntry = {
  change: number | null;
  correction: boolean;
  employee: string;
  invoiceCustomer: string;
  otherLocation: string;
  otherLocationCustomer: string;
  product: string;
  resultingBalance: number;
  startTimestamp: Date | null;
  timestamp: Date;
  unit: string;
};

interface StorageJournalTableProps {
  data: readonly Readonly<JournalDataEntry>[];
  showCustomerColumn: boolean;
  showStatusColumn: boolean;
}

export function StorageJournalTable(props: StorageJournalTableProps): React.JSX.Element {
  const {data, showCustomerColumn, showStatusColumn} = props;

  const sortedData = _.sortBy(data, [(entry) => entry.timestamp, (entry) => entry.product]);

  const unitLeftPadding = "10pt";

  const rows = sortedData.map(
    (
      {
        change,
        correction,
        employee,
        invoiceCustomer,
        otherLocation,
        otherLocationCustomer,
        product,
        resultingBalance,
        startTimestamp,
        timestamp,
        unit,
      },
      index,
    ) => {
      return (
        <tr key={index} style={{borderTop: "1px solid black"}}>
          <td>{formatDateTimeWithoutWeekday(timestamp)}</td>
          <td style={{textAlign: "right"}}>
            {change !== null && change >= 0 ? (
              <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={change} />
            ) : null}
          </td>
          <td style={{textAlign: "right"}}>
            {change !== null && change < 0 ? (
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-change}
              />
            ) : null}
          </td>
          {showStatusColumn ? (
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={resultingBalance}
              />
            </td>
          ) : null}
          <td style={{paddingLeft: unitLeftPadding}}>{unit}</td>
          <td>{product}</td>
          <td>{otherLocation}</td>
          <td>{otherLocationCustomer}</td>
          {showCustomerColumn ? <td>{invoiceCustomer}</td> : null}
          <td>{formatDateTimeWithoutWeekday(startTimestamp)}</td>
          <td>
            {correction ? (
              <i>
                <FormattedMessage
                  defaultMessage="regulering"
                  id="storage-journal.text.adjustment"
                />
              </i>
            ) : (
              employee
            )}
          </td>
        </tr>
      );
    },
  );

  return (
    <table style={{borderCollapse: "collapse", width: "100%"}}>
      <thead>
        <tr>
          <th style={{minWidth: 120, textAlign: "left"}}>
            <FormattedMessage defaultMessage="Tidspunkt" />
          </th>
          <th style={{textAlign: "right"}}>
            <FormattedMessage defaultMessage="Tilgang" />
          </th>
          <th style={{textAlign: "right"}}>
            <FormattedMessage defaultMessage="Afgang" />
          </th>
          {showStatusColumn ? (
            <th style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="Lagerstand" />
            </th>
          ) : null}
          <th style={{paddingLeft: unitLeftPadding, textAlign: "left"}}>
            <FormattedMessage defaultMessage="Enhed" />
          </th>
          <th style={{textAlign: "left"}}>
            <FormattedMessage defaultMessage="Vare" />
          </th>
          <th style={{textAlign: "left"}}>
            <FormattedMessage defaultMessage="Til/fra sted" />
          </th>
          <th style={{textAlign: "left"}}>
            <FormattedMessage defaultMessage="Stedkunde" />
          </th>
          {showCustomerColumn ? (
            <th style={{textAlign: "left"}}>
              <FormattedMessage defaultMessage="Fakturakunde" />
            </th>
          ) : null}
          <th style={{minWidth: 120, textAlign: "left"}}>
            <FormattedMessage defaultMessage="Opg. start" />
          </th>
          <th style={{textAlign: "left"}}>
            <FormattedMessage defaultMessage="Medarb." />
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
