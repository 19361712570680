import {
  PatchUnion,
  Task,
  Unit,
  UnitUrl,
  WorkshopChecklistAnswer,
  WorkshopChecklistItem,
  WorkshopChecklist as WorkshopChecklistResource,
} from "@co-common-libs/resources";
import {DecimalField} from "@co-frontend-libs/components";
import {Card, CardHeader, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, IntlContext} from "react-intl";

const messages = defineMessages({
  no: {
    defaultMessage: "Nej",
    id: "workshop-checklist.label.no",
  },
  notChecked: {
    defaultMessage: "Ej tjekket",
    id: "workshop-checklist.label.not-checked",
  },
  required: {
    defaultMessage: "Skal udfyldes",
    id: "workshop-checklist.label.required",
  },
  yes: {
    defaultMessage: "Ja",
    id: "workshop-checklist.label.yes",
  },
});

interface WorkshopChecklistProps {
  task: Task;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  update: (url: string, patch: PatchUnion) => void;
  workshopChecklistArray: readonly WorkshopChecklistResource[];
  workshopChecklistItemArray: readonly WorkshopChecklistItem[];
}

export class WorkshopChecklist extends PureComponent<WorkshopChecklistProps, object> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleAnswerChange(answer: WorkshopChecklistAnswer): void {
    const {task, update} = this.props;
    const oldSet = task.workshopchecklistanswerSet || [];
    const {checklistItem} = answer;
    const oldIndex = oldSet
      ? oldSet.findIndex((instance) => instance.checklistItem === checklistItem)
      : -1;
    const newSet = oldSet.slice();
    if (oldIndex > -1) {
      newSet[oldIndex] = answer;
    } else {
      newSet.push(answer);
    }
    update(task.url, [{member: "workshopchecklistanswerSet", value: newSet}]);
  }

  render(): React.JSX.Element {
    const {task, unitLookup, workshopChecklistArray, workshopChecklistItemArray} = this.props;
    const machineUseSet = task.machineuseSet || [];
    const taskMachines = machineUseSet.map((machineUse) => machineUse.machine);

    const checklists = _.sortBy(
      workshopChecklistArray.filter((checklist) => {
        if (!checklist.draft && checklist.active) {
          const checklistMachines = checklist.machines;
          return taskMachines.some((machine) => checklistMachines.includes(machine));
        } else {
          return false;
        }
      }),
      (checklist) => checklist.name,
    );

    return (
      <Grid>
        {checklists.map((checklist) => (
          <Cell key={checklist.url as string} palm="12/12">
            <WorkshopChecklistCard
              onAnswerChange={this.handleAnswerChange}
              task={task}
              unitLookup={unitLookup}
              workshopChecklist={checklist}
              workshopChecklistItems={workshopChecklistItemArray.filter(
                (item) => item.checklist === checklist.url,
              )}
            />
          </Cell>
        ))}
      </Grid>
    );
  }
}

const getAnswer = (
  item: WorkshopChecklistItem,
  answers: readonly WorkshopChecklistAnswer[] | null | undefined,
): WorkshopChecklistAnswer | undefined =>
  answers ? answers.find((answer) => answer.checklistItem === item.url) : undefined;

interface WorkshopChecklistCardProps {
  onAnswerChange(answer: WorkshopChecklistAnswer): void;
  task: Task;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workshopChecklist: WorkshopChecklistResource;
  workshopChecklistItems: readonly WorkshopChecklistItem[];
}

class WorkshopChecklistCard extends PureComponent<WorkshopChecklistCardProps, object> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {onAnswerChange, task, unitLookup, workshopChecklist, workshopChecklistItems} =
      this.props;
    const answers = task.workshopchecklistanswerSet as
      | readonly WorkshopChecklistAnswer[]
      | null
      | undefined;

    return (
      <Card style={{margin: 10}}>
        <CardHeader
          subheader={workshopChecklist.required ? formatMessage(messages.required) : undefined}
          title={workshopChecklist.name}
        />
        <ol style={{margin: 5}}>
          {_.sortBy(workshopChecklistItems, (item) => item.priority).map((item) => (
            <ChecklistItem
              answer={getAnswer(item, answers)}
              disabled={!!task.completed}
              item={item}
              key={item.url as string}
              onAnswerChange={onAnswerChange}
              unitLookup={unitLookup}
            />
          ))}
        </ol>
      </Card>
    );
  }
}

interface ChecklistItemProps {
  answer?: WorkshopChecklistAnswer | undefined;
  disabled: boolean;
  item: WorkshopChecklistItem;
  onAnswerChange(answer: WorkshopChecklistAnswer): void;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}
const DECIMAL_PLACES = 2;
const MAX_DIGETS = 9;

class ChecklistItem extends PureComponent<ChecklistItemProps, object> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  changeAnswer(value: number): void {
    const {answer, item, onAnswerChange} = this.props;
    if (answer) {
      if (answer.value !== value) {
        onAnswerChange({...answer, value});
      }
    } else {
      onAnswerChange({checklistItem: item.url, value});
    }
  }

  @bind
  handleFieldChange(value: number | null): void {
    this.changeAnswer(value || 0);
  }

  @bind
  handleValueChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    this.changeAnswer(parseInt(value));
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {answer, disabled, item, unitLookup} = this.props;
    let field: React.ReactElement;
    let unitString = "";
    if (item.type === "decimal") {
      field = (
        <DecimalField
          decimalPlaces={DECIMAL_PLACES}
          disabled={disabled}
          margin="dense"
          maxDigits={MAX_DIGETS}
          onChange={this.handleFieldChange}
          value={answer ? answer.value : null}
        />
      );
      const unit = item.unit ? unitLookup(item.unit) : null;
      unitString = unit ? unit.symbol || unit.name || "" : "";
    } else {
      field = (
        <RadioGroup
          name="value"
          onChange={this.handleValueChange}
          value={answer ? answer.value : -1}
        >
          <FormControlLabel
            control={<Radio />}
            disabled={disabled}
            label={formatMessage(messages.yes)}
            value={1}
          />
          <FormControlLabel
            control={<Radio />}
            disabled={disabled}
            label={formatMessage(messages.no)}
            value={0}
          />
          <FormControlLabel
            control={<Radio />}
            disabled={disabled}
            label={formatMessage(messages.notChecked)}
            value={-1}
          />
        </RadioGroup>
      );
    }
    return (
      <li
        style={{
          marginLeft: 0,
          padding: "20px 16px 16px",
          position: "relative",
        }}
      >
        <div style={{fontSize: 16}}>{item.name}</div>
        {field} {unitString}
      </li>
    );
  }
}
