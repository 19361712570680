import {Task} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {
  getCurrentUserURL,
  getLocationLookup,
  getRouteTaskArray,
  getTimerStartArray,
} from "@co-frontend-libs/redux";
import {Card, CardContent} from "@material-ui/core";
import _ from "lodash";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {RouteEntry} from "./route-entry";

interface RouteTabRouteProps {
  task: Task;
}

export function RouteTabRoute(props: RouteTabRouteProps): React.JSX.Element {
  const {task} = props;

  const taskURL = task.url;

  const locationLookup = useSelector(getLocationLookup);
  const routeTaskArray = useSelector(getRouteTaskArray);
  const currentUserURL = useSelector(getCurrentUserURL);
  const timerStartArray = useSelector(getTimerStartArray);

  const routeTaskList = useMemo(
    () =>
      _.sortBy(
        routeTaskArray.filter((routeTask) => routeTask.route === taskURL),
        (routeTask) => routeTask.order,
      ),
    [routeTaskArray, taskURL],
  );

  const startedRouteTaskUrl = routeTaskList.find(
    (routeTask) => routeTask.started && !routeTask.completed,
  )?.url;

  const entries = routeTaskList
    .map((routeTask) => {
      const workplace = locationLookup(routeTask.relatedLocation);
      if (!workplace) {
        return undefined;
      }
      return (
        <RouteEntry
          currentUserURL={currentUserURL}
          key={routeTask.url}
          otherTaskStarted={!!(startedRouteTaskUrl && startedRouteTaskUrl !== routeTask.url)}
          routeTask={routeTask}
          task={task}
          timerStartArray={timerStartArray}
          workplace={workplace}
        />
      );
    })
    .filter(notUndefined);
  return (
    <Card style={{margin: "1em"}}>
      <CardContent>{entries}</CardContent>
    </Card>
  );
}
