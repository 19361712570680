import {Command} from "@co-common-libs/resources";
import {sharePromiseFactory} from "@co-frontend-libs/utils";
import {getCordovaSQLiteStorageConnection} from "./cordova-sqlite-storage";
import {getIndexedDBConnection} from "./indexeddb";
import {CommitDBConnection, SerializableError} from "./types";

// vacuum about once every 100 starts...
const VACUUM_FREQUENCY = 0.01;

const getDBConnection = (): Promise<CommitDBConnection> => {
  if (typeof cordova !== "undefined" && window.sqlitePlugin) {
    return getCordovaSQLiteStorageConnection(window.sqlitePlugin, "commit");
  }
  return getIndexedDBConnection(window.indexedDB, "commit");
};

export class CommitDB {
  private activeCalls: number;
  private closeCallbacks: {
    reject(error: unknown): void;
    resolve(): void;
  } | null;
  private closing: boolean;
  private connection: CommitDBConnection;
  private initialized: boolean;
  private updatePromise: Promise<void>;
  constructor(connection: CommitDBConnection) {
    this.connection = connection;
    this.initialized = false;
    this.updatePromise = Promise.resolve();
    this.activeCalls = 0;
    this.closing = false;
    this.closeCallbacks = null;
  }
  _closeConnection(): Promise<void> {
    this.closing = true;
    if (this.activeCalls) {
      return new Promise<void>((resolve, reject) => {
        this.closeCallbacks = {reject, resolve};
      });
    } else {
      return this.connection.close();
    }
  }
  _deleteDatabase(): Promise<void> {
    return this.connection.deleteDatabase();
  }
  delete(id: number): Promise<void> {
    return this.trackActive("delete", () => {
      console.assert(this.initialized);
      const pendingResult = this.updatePromise.then(() => this.connection.delete(id));
      this.updatePromise = pendingResult;
      return pendingResult;
    });
  }
  getAll(): Promise<
    readonly {
      readonly apiVersion: string | null;
      readonly command: Command;
      readonly error: SerializableError | null;
      readonly errorTimestamp: string | null;
      readonly frontendVersion: string | null;
      readonly id: number;
    }[]
  > {
    return this.trackActive("getAll", async () => {
      console.assert(!this.initialized);
      this.initialized = true;
      const data = await this.connection.getAll();
      if (Math.random() < VACUUM_FREQUENCY) {
        await this.connection.vacuum();
      }
      return data;
    });
  }
  put(id: number, command: Command, apiVersion: string, frontendVersion: string): Promise<void> {
    return this.trackActive("put", () => {
      console.assert(this.initialized);
      const pendingResult = this.updatePromise.then(() =>
        this.connection.put(id, command, apiVersion, frontendVersion),
      );
      this.updatePromise = pendingResult;
      return pendingResult;
    });
  }
  setError(id: number, error: SerializableError, errorTimestamp: string): Promise<void> {
    return this.trackActive("setError", () => {
      console.assert(this.initialized);
      const pendingResult = this.updatePromise.then(() =>
        this.connection.setError(id, error, errorTimestamp),
      );
      this.updatePromise = pendingResult;
      return pendingResult;
    });
  }
  private async trackActive<T>(label: string, callback: () => Promise<T>): Promise<T> {
    if (this.closing) {
      throw new Error(`CommitDB: closing; cannot perform: ${label}`);
    }
    this.activeCalls += 1;
    let result: Awaited<T>;
    try {
      result = await callback();
    } finally {
      this.activeCalls -= 1;
      if (this.closing && this.activeCalls === 0 && this.closeCallbacks) {
        const {reject, resolve} = this.closeCallbacks;
        // eslint-disable-next-line promise/catch-or-return
        this.connection.close().then(resolve, reject);
      }
    }
    return result;
  }
}

export const getCommitDB = sharePromiseFactory(() =>
  getDBConnection().then((connection) => new CommitDB(connection)),
);
