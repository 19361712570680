/* eslint-disable react/no-unescaped-entities */
import {Config} from "@co-common-libs/config";
import {PureComponent} from "app-utils";
import React from "react";

interface ProcessorAgreementProps {
  customerSettings: Config;
}

class ProcessorAgreement extends PureComponent<ProcessorAgreementProps> {
  render(): React.JSX.Element {
    const {address, companyName, contactMail, contactPerson, contactPhone, cvr} =
      this.props.customerSettings;
    return (
      <div>
        <h1 style={{lineHeight: "48px", overflowWrap: "break-word"}}>
          CustomOffice Databehandleraftale
        </h1>

        <p>
          Her kan du se den databehandleraftale, der ligger til grund for behandling af persondata
          når du er kunde hos CustomOffice.
        </p>

        <p>Imellem:</p>

        <p>
          <b>Dataansvarlig</b>:
          <br />
          {companyName}, CVR-nummer {cvr}, {address}
        </p>

        <p>
          <b>Kontaktperson</b>:
          <br />
          {contactPerson}, Tlf.: {contactPhone}, E-mail: {contactMail}
        </p>

        <p>og</p>

        <p>
          <b>Databehandler</b>:
          <br />
          CustomOffice ApS, CVR-nummer 37098329, Toldboden 3, 2. c, 8800 Viborg
        </p>

        <p>
          <b>Kontakt</b>:
          <br />
          <a href="mailto:privatliv@customoffice.dk">privatliv@customoffice.dk</a>
        </p>

        <p>
          Parterne kaldes i det følgende henholdsvis den "Dataansvarlige" og "Databehandleren", og
          "Part" eller tilsammen "Parterne".
        </p>

        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>INTRODUKTION</h2>

        <p>
          Ved brug af programmet CustomOffice og ethvert modul eller funktion i forbindelse med
          applikationen (i det hele benævnt "Applikationen"), vil den Dataansvarlige være ansvarlig
          for sin Behandling af Personoplysninger i Applikationen. Databehandleren vil behandle
          personoplysninger på vegne af den Dataansvarlige. For at sikre, at Parterne lever op til
          sine forpligtelser under nationale databeskyttelsesregler samt Europa-Parlamentet og
          Rådets forordning (EU) 2016/279 ("GDPR"), har Parterne indgået denne databehandleraftale
          ("Aftalen"), som udgør instruksen fra den Dataansvarlige til Databehandleren og dermed
          regulerer Databehandlerens Behandling af personoplysninger på vegne af den Dataansvarlige.
        </p>

        <p>Begge Parter bekræfter, at de har fuldmagt til at underskrive Aftalen.</p>

        <p>
          Databehandleren behandler desuden udelukkende Personoplysninger i overensstemmelse med
          CustomOffice Privatlivspolitik.
        </p>

        <p>
          Det gælder for hele Aftalen og i forholdet mellem den Dataansvarlige og Databehandleren,
          at krav, der følger af GDPR, som er beskrevet i denne aftale og som ikke følger af
          nugældende lovgivning, først er gældende fra 25. maj 2018, hvor GDPR finder anvendelse
          fra.
        </p>

        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>DEFINITIONER</h2>

        <p>
          Definitionen af Personoplysninger, Særlige Kategorier af Data (Følsomme Oplysninger),
          Behandling, den Registrerede, Dataansvarlig og Databehandler er den samme som den
          relevante persondatalovgivning, herunder GDPR.
        </p>

        <p>
          Aftalen regulerer Databehandlerens Behandling af Personoplysninger på vegne af den
          Dataansvarlige, og beskriver, hvordan Databehandleren skal medvirke til at beskytte
          privatliv på vegne af den Dataansvarlige og dennes Registrerede gennem tekniske og
          organisatoriske foranstaltninger som er krævet under den gældende
          databeskyttelseslovgivning, herunder GDPR fra den 25. maj 2018.
        </p>

        <p>
          Formålet med Databehandlerens Behandling af Personoplysninger på vegne af den
          Dataansvarlige er at sikre, den Dataansvarliges brug af Applikationen og opfyldelsen af
          denne Aftale.
        </p>

        <p>
          Aftalen har forrang for andre modstridende bestemmelser vedrørende Behandling af
          Personoplysninger for så vidt angår vilkår for brugen af Applikationen eller i andre
          aftaler gældende Parterne imellem. Aftalen er gyldig, så længe den Dataansvarlige
          abonnerer på Applikationen, og Databehandleren derfor skal behandle Personoplysninger på
          vegne af den Dataansvarlige. Aftalen har dog ikke forrang, såfremt Parterne har indgået en
          anden databehandleraftale, hvoraf fremgår, at den databehandleraftale har forrang frem for
          denne Aftale.
        </p>

        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          DATABEHANDLERENS FORPLIGTELSER
        </h2>

        <p>
          Databehandleren skal udelukkende behandle Personoplysninger på vegne af og som følge af
          den Dataansvarliges instruktioner. Ved at indgå denne Aftale, instruerer den
          Dataansvarlige Databehandleren i at behandle Personoplysninger på følgende måder:
        </p>
        <ol>
          <li>i overensstemmelse med gældende lovgivning</li>
          <li>
            for at opfylde sine forpligtelser i henhold til abonnementsvilkår for Applikationen
          </li>
          <li>som yderligere specificeret ved den Dataansvarliges normale brug af Applikationen</li>
          <li>som beskrevet i denne Aftale</li>
        </ol>
        <p>
          Som en del af at kunne levere Applikationen er Databehandleren forpligtet til enhver tid
          at give den Dataansvarlige gode og konkurrencedygtige løsninger der følger med
          udviklingen. Databehandleren kan tilbyde bedre løsninger, som er tilpasset den enkelte
          Dataansvarliges behov, ved at registrere hvordan Dataansvarlig og dennes repræsentanter
          bruger Applikationen. Dette gør Databehandler for at kunne lave en bedre version af
          Applikationen, og generelt yde bedre tjenester og give mere relevant kommunikation til den
          Dataansvarlige og dennes repræsentanter. Målet er at Dataansvarlig skal løse så mange
          udfordringer som muligt på et sted. I den grad at personoplysninger fra Applikationen
          indgår i dette arbejde, behandles disse i henhold til denne Aftale og gældende lovgivning.
        </p>

        <p>
          Databehandleren har ikke nogen grund til at tro, at gældende lovgivning forhindrer
          Databehandleren i at efterleve instruktionerne gengivet ovenfor. Databehandleren skal,
          hvis denne bliver opmærksom på det, give den Dataansvarlige besked om instruktioner eller
          andre Behandlingsaktiviteter udført af den Dataansvarlige, som efter Databehandlerens
          opfattelse strider imod den gældende databeskyttelseslovgivning. Kategorierne af
          Registrerede og Personoplysninger som behandles i henhold til denne Aftale er beskrevet i
          Bilag A.
        </p>

        <p>
          Under hensyntagen til den teknologi, der er tilgængelig, og omkostningerne ved
          implementeringen, samt omfanget, konteksten og formålet med Behandlingen, er
          Databehandleren forpligtet til at foretage alle rimelige foranstaltninger, herunder
          tekniske og organisatoriske, for at sikre et tilstrækkeligt sikkerhedsniveau i forhold til
          den risiko og kategorien af Personoplysninger, der skal beskyttes.
        </p>

        <p>
          Databehandleren skal bistå den Dataansvarlige med passende tekniske og organisatoriske
          foranstaltninger, som dette er muligt og under hensyntagen til Behandlingens art og
          kategorien af oplysninger, der er tilgængelige for Databehandleren, for at sikre
          overholdelse af den Dataansvarliges forpligtelser i henhold til gældende
          Databeskyttelseslovgivning, herunder for så vidt angår bistand i forhold til opfyldelse af
          anmodninger fra Registrerede samt generel overholdelse af bestemmelserne under GDPR
          artikel 32-36.
        </p>

        <p>
          Databehandleren skal underrette den Dataansvarlige uden unødig forsinkelse via
          kontaktperson oplyst i Databehandleraftalen, hvis Databehandleren bliver bekendt med
          sikkerhedsbrist. Endvidere skal Databehandleren så vidt muligt og lovligt underrette den
          Dataansvarlige, hvis;
        </p>
        <ol>
          <li>En anmodning om indsigt i Personoplysninger modtages direkte fra den Registrerede</li>
          <li>
            En anmodning om indsigt i Personoplysninger modtages direkte fra statslige myndigheder,
            herunder politiet.
          </li>
        </ol>
        <p>
          Databehandleren må ikke besvare sådanne anmodninger fra Registrerede, medmindre denne er
          autoriseret af den Dataansvarlige til at gøre det. Databehandleren vil endvidere ikke
          videregive information om denne Aftale til statslige myndigheder såsom politiet, herunder
          Personoplysninger, medmindre Databehandleren er forpligtet til det i medfør af
          lovgivningen, såsom ved en retskendelse eller lignende.
        </p>

        <p>
          Hvis den Dataansvarlige kræver information eller assistance omkring
          sikkerhedsforanstaltninger, dokumentation eller information om, hvordan Databehandleren
          behandler Personoplysninger generelt, og en sådan anmodning indeholder information, som
          går ud over, hvad der er nødvendigt ifølge gældende Databeskyttelseslovgivning, må
          Databehandleren kræve betaling for sådanne yderligere services. Databehandleren og dennes
          ansatte skal sikre fortrolighed i forhold til Personoplysninger, som behandles i henhold
          til Aftalen. Denne bestemmelse skal ligeledes gælde efter ophør af Aftalen.
        </p>

        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          DEN DATAANSVARLIGES FORPLIGTELSER
        </h2>

        <p>Den Dataansvarlige bekræfter ved indgåelse af denne aftale, at:</p>
        <ul>
          <li>
            Den Dataansvarlige skal ved brug af Applikationen stillet til rådighed af
            Databehandleren, udelukkende behandle Personoplysninger i overensstemmelse med kravene i
            den gældende Databeskyttelseslovgivning.
          </li>
          <li>
            Den Dataansvarlige har et lovligt grundlag for at behandle og videregive
            Personoplysninger til Databehandleren (herunder til underdatabehandlere som
            Databehandleren anvender).
          </li>
          <li>
            Den Dataansvarlige har ansvaret for nøjagtigheden, integriteten, indholdet af
            pålideligheden og lovligheden af de Personoplysninger som behandles af Databehandleren.
          </li>
          <li>
            Den Dataansvarlige har opfyldt alle obligatoriske krav og pligter i forhold til
            anmeldelse hos eller opnåelse af tilladelse fra de relevante offentlige myndigheder for
            så vidt angår Behandlingen af Personoplysninger.
          </li>
          <li>
            Den Dataansvarlige har opfyldt sin oplysningsforpligtelser over for de Registrerede
            vedrørende behandlingen af Personoplysninger i henhold til gældende
            databeskyttelseslovgivning.
          </li>
        </ul>
        <ul>
          <li>
            Den Dataansvarlige er enig i, at Databehandleren har givet de relevante garantier for så
            vidt angår implementeringen af tekniske og organisatoriske sikkerhedsforanstaltninger
            for at sikre de Registreredes rettigheder og deres Personoplysninger.
          </li>
          <li>
            Den Dataansvarlige skal ved brug af Applikationen ikke behandle Følsomme oplysninger,
            medmindre dette er specificeret i Bilag A til denne Aftale.
          </li>
          <li>
            Den Dataansvarlige skal have en opdateret liste over de kategorier af Personoplysninger,
            som denne behandler, dette gælder særligt i det omfang sådan Behandling afviger fra de
            kategorier af Oplysninger, som fremgår af Bilag A.
          </li>
        </ul>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          BRUG AF UNDERDATABEHANDLERE OG VIDEREGIVELSE AF DATA
        </h2>

        <p>
          Som en del af driften af Applikationen anvender Databehandleren underleverandører
          ("Underdatabehandlere"). Sådanne Underdatabehandlere kan være tredjepartsleverandører i og
          uden for EU/EØS. Databehandlerens underleverandører er oplistet i den til enhver tid
          opdaterede liste over underdatabehandlere. Databehandleren skal sikre sig, at dennes
          Underdatabehandlere skal overholde tilsvarende forpligtelser og krav, som er beskrevet i
          Aftalen. Al brug af Underdatabehandlere er endvidere underlagt CustomOffices
          privatlivspolitik.
        </p>

        <p>
          Denne Aftale udgør den Dataansvarliges forudgående generelle og specifikke skriftlige
          godkendelse af Databehandlerens brug af Underdatabehandlere.
        </p>

        <p>
          Hvis en Underdatabehandler er etableret uden for eller Personoplysninger opbevares uden
          for EU/EØS giver den Dataansvarlige Databehandleren autorisation til at sikre et
          tilstrækkeligt grundlag for overførsel af Personoplysninger til tredjeland på vegne af den
          Dataansvarlige, herunder ved anvendelse af EU Kommissionens Standardkontrakter eller i
          overensstemmelse med Privacy Shield.
        </p>

        <p>
          Den Dataansvarlige skal orienteres, inden Databehandlere udskifter sine
          Underdatabehandlere. Den Dataansvarlige har dog kun ret til at protestere imod en ny
          Underdatabehandler, som behandler Personoplysninger på vegne af den Dataansvarlige, hvis
          denne ikke behandler data i overensstemmelse med gældende databeskyttelseslovgivning. I en
          sådan situation skal Databehandleren demonstrere overensstemmelse ved at give den
          Dataansvarlige adgang til Databehandlerens databeskyttelsesvurdering af
          Underdatabehandleren. Hvis der stadig er uenighed om anvendelsen af Underdatabehandleren
          kan den Dataansvarlige opsige sit abonnement på Applikationen, herunder med et kortere
          varsel end normalt for at sikre, at den Dataansvarliges Personoplysninger ikke behandles
          af den pågældende Underdatabehandler.
        </p>

        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>SIKKERHED</h2>

        <p>
          Databehandleren er forpligtet til at sikre et højt sikkerhedsniveau i sine produkter og
          services, hvilket sikres ved relevante organisatoriske, tekniske og fysiske
          sikkerhedsforanstaltninger, som er påkrævede i henhold til information om
          sikkerhedsforanstaltninger som beskrevet i GDPR artikel 32.
        </p>

        <p>
          Endvidere har CustomOffices interne databeskyttelses politikker til formål at sikre
          fortrolighed, integritet, modstandsdygtigheden og adgangen til Personoplysninger. De
          følgende foranstaltninger er særligt væsentlige:
        </p>
        <ul>
          <li>
            Klassificering af Personoplysninger for at sikre implementering af
            sikkerhedsforanstaltninger relevante i forhold til risikovurderinger.
          </li>
          <li>Vurdering af kryptering og pseudonymisering som risikoreducerende faktorer</li>
          <li>
            Begrænse adgangen til Personoplysninger til de relevante personer, der skal til for at
            overholde krav og forpligtelser i Aftalen eller i henhold til Parternes aftale om
            anvendelse af Applikationen.
          </li>
          <li>
            Drift og implementering af systemer der kan opdage, genoprette, imødegå og rapportere
            hændelser i forhold til Personoplysninger.
          </li>
          <li>
            Kortlægge sikkerhedsstrukturen samt hvordan Personoplysninger overføres imellem
            Parterne.
          </li>
          <li>
            Foretage vurdering af eget sikkerhedsniveau for at sikre, at nuværende tekniske og
            organisatoriske foranstaltninger er tilstrækkelige til beskyttelse af Personoplysninger,
            herunder i henhold til GDPR artikel 32 om behandlingssikkerhed samt artikel 25 om
            privacy by design og default.
          </li>
        </ul>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>ADGANG TIL REVISION</h2>

        <p>
          Den Dataansvarlige er berettiget til at igangsætte en revision af Databehandlerens
          forpligtelser i henhold til Aftalen én gang årligt. Hvis den Dataansvarlige er forpligtet
          hertil efter gældende lovgivning, kan der foretages revision oftere en én gang årligt. Den
          Dataansvarlige skal i forbindelse med anmodning om en revision medsende en detaljeret
          revisionsplan med en beskrivelse af omfang, varighed og startdato minimum fire uger forud
          for den foreslåede startdato. Det skal besluttes i fællesskab mellem Parterne, hvis en
          tredjepart skal foretage revisionen. Imidlertid kan den Dataansvarlige lade
          Databehandleren bestemme, at revisionen af sikkerhedsårsager skal foretages af en neutral
          tredjepart efter Databehandlerens valg, såfremt der er tale om et behandlingsmiljø hvor
          flere dataansvarliges data er anvendt.
        </p>

        <p>
          Hvis det foreslåede omfang for revisionen følger en ISAE, ISO eller lignende
          certificeringsrapport udført af en kvalificeret tredjepartsrevisor inden for de
          forudgående tolv måneder, og Databehandleren bekræfter, at der ikke har været nogen
          materielle ændringer i de foranstaltninger, som har været under revision, skal den
          Dataansvarlige acceptere denne revision i stedet for at anmode om en ny revision af de
          foranstaltninger, som allerede er dækket.
        </p>

        <p>
          Under alle omstændigheder skal revision finde sted i normal kontortid på den relevante
          facilitet i overensstemmelse med Databehandlerens politikker og må ikke på urimelig vis
          forstyrre Databehandlerens sædvanlige kommercielle aktiviteter
        </p>

        <p>
          Den Dataansvarlige er ansvarlig for alle omkostninger i forbindelse med anmodningen om
          revision. Databehandlerens assistance i forbindelse hermed, som overskrider den
          almindelige service som Databehandleren skal stille til rådighed som følge af gældende
          databeskyttelseslovgivning, afregnes særskilt.
        </p>

        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>VARIGHED OG OPHØR</h2>

        <p>
          Aftalen er gældende, så længe Databehandleren behandler Personoplysninger på vegne af den
          Dataansvarlige i forbindelse med den Dataansvarliges brug af Applikationen.
        </p>

        <p>
          Denne Aftale vil automatisk ophøre ved udgangen af den Dataansvarliges opsigelsesperiode i
          forhold til abonnement på Applikationen. Ved ophør af abonnementet vil Databehandleren
          slette eller returnere alle Personoplysninger i det relevante format, som Databehandleren
          har behandlet på vegne af den Dataansvarlige under Aftalen. Såfremt den Dataansvarlige
          ønsker bistand til returnering af data, fastsættes omkostninger forbundet hermed i
          fællesskab af Parterne og skal baseres på:
        </p>
        <ul>
          <li>timetakster for Databehandlerens anvendte tid</li>
          <li>kompleksiteten af den anmodede proces og</li>
          <li>det valgte format.</li>
        </ul>
        <p>
          Databehandleren er berettiget til at beholde Personoplysninger efter ophør af Aftalen i
          det omfang, det er nødvendigt i henhold til gældende lov, hvilket i så fald vil ske i
          overensstemmelse med de tekniske og organisatoriske sikkerhedsforanstaltninger, som er
          beskrevet i Aftalen.
        </p>

        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>ÆNDRINGER</h2>

        <p>Ændringer til Aftalen skal vedlægges i et særskilt bilag til Aftalen.</p>

        <p>
          Hvis nogen af bestemmelserne i Aftalen er ugyldige, får dette ikke indvirkning på de
          resterende bestemmelser. Parterne skal erstatte ugyldige bestemmelser med en lovlig
          bestemmelse, som afspejler formålet med den ugyldige bestemmelse.
        </p>

        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>ANSVAR</h2>

        <p>
          Ansvar for handlinger i strid med bestemmelserne i denne Aftale reguleres af ansvars- og
          erstatningsbestemmelser i Abonnementsvilkårene for Applikationen. Dette gælder ligeledes
          for enhver overtrædelse, som foretages af Databehandlerens Underdatabehandlere.
        </p>

        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>LOVVALG OG VÆRNETING</h2>

        <p>Aftalen er underlagt dansk ret og enhver tvist skal forelægges en dansk domstol.</p>
        <hr />
        <h1 style={{lineHeight: "48px", overflowWrap: "break-word"}}>
          Bilag A – Kategorier af Personoplysninger og Registrerede
        </h1>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          Kategorier af Registrerede og Personoplysninger som behandles i henhold til Aftalen
        </h2>
        <h3>1.1. KATEGORIER AF REGISTREREDE</h3>
        <ol>
          <li>Den Dataansvarliges slutbrugere</li>
          <li>Den Dataansvarliges medarbejdere</li>
          <li>Den Dataansvarliges kontaktpersoner</li>
          <li>Den Dataansvarliges kunders medarbejdere</li>
          <li>Den Dataansvarliges kunders kontaktpersoner</li>
          <li>Evt.</li>
        </ol>
        <h3>1.2. KATEGORIER AF PERSONOPLYSNINGER</h3>
        <ol>
          <li>Navn</li>
          <li>Titel</li>
          <li>Telefonnummer</li>
          <li>E-mail</li>
          <li>Adresse</li>
          <li>Evt.</li>
        </ol>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          2. Kategorier af Følsomme Personoplysninger som behandles i henhold til Aftalen
        </h2>
        <p>
          Databehandleren skal på vegne af den Dataansvarlige behandle én eller flere af
          nedenstående informationer om:
        </p>
        <ol>
          <li>Politisk, filosofisk eller religiøs overbevisning</li>
          <li>Fagforeningsmæssige tilhørsforhold</li>
          <li>Race eller etnisk oprindelse</li>
          <li>Helbredsoplysninger</li>
          <li>Oplysninger om en fysisk persons seksuelle forhold eller seksuelle orientering</li>
          <li>
            Genetiske eller biometriske data med det formål entydigt at identificere en fysisk
            person
          </li>
        </ol>
      </div>
    );
  }
}

export {ProcessorAgreement};
