import {
  CultureUrl,
  Customer,
  CustomerUrl,
  DaysAbsence,
  Delivery,
  DeliveryLocation,
  emptyTransportLog,
  HoursAbsence,
  Location,
  LocationUrl,
  LocationUseLog,
  Machine,
  MachineUrl,
  MachineUse,
  Order,
  OrderUrl,
  PatchOperation,
  PatchUnion,
  Pickup,
  PickupLocation,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUsesDict,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUseLog,
  ProductUsesDict,
  ReportingSpecification,
  ReportingSpecificationUrl,
  ResourceTypeUnion,
  Role,
  Task,
  TaskPhoto,
  Timer,
  TimerStart,
  TimerUrl,
  TransportLog,
  Unit,
  UnitUrl,
  urlToId,
  User,
  UserPhoto,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  addToProductUses,
  getNormalisedDeviceTimestamp,
  getReadonlyPriceItems,
  getWorkTypeString,
} from "@co-common-libs/resources-utils";
import {
  formatAddress,
  formatDate,
  formatDateTime,
  notUndefined,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {
  DateField,
  DeleteDialog,
  ErrorColorButton,
  MachineChip,
  MinutesField,
  ThrottledTextField,
  TimeField,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  ConnectedDepartmentDialog,
  ConnectedProductDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  AppState,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerLookup,
  getDaysAbsenceArray,
  getDeliveryArray,
  getDeliveryLocationArray,
  getExtendedCustomerSettings,
  getHoursAbsenceArray,
  getLocationArray,
  getLocationLookup,
  getLocationUseLogArray,
  getMachineArray,
  getMachineLookup,
  getOrderLookup,
  getPickupArray,
  getPickupLocationArray,
  getPriceGroupLookup,
  getPriceItemLookup,
  getProductArray,
  getProductGroupLookup,
  getProductLookup,
  getProductUseLogArray,
  getReportingSpecificationArray,
  getReportingSpecificationLookup,
  getRoleArray,
  getTaskArray,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getTransportLogArray,
  getUnitLookup,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {colorMap, matchingTextColor} from "@co-frontend-libs/utils";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  ChipProps,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import {bindActionCreators, Dispatch} from "@reduxjs/toolkit";
import {
  getOtherTaskCount,
  LocationBlock,
  MachinePriceGroupWizard,
  MachineRemovalBlockedDialog,
  PhotoAndPdfUpload,
  PhotoDisplayDialog,
  ProductGroupTreeDialog,
  RecordInC5Dialog,
  RemoveRecordedInC5Dialog,
  TaskCountMessage,
  TaskFields,
  TaskPriceItemTable,
  TaskProductTable,
  WorkTypeChangeBlockedDialog,
  WorkTypeSelectionWrapper,
} from "app-components";
import {
  checkUserAbsence,
  computeIntervalsTruncated,
  computeIntervalSums,
  computeWorkFromTo,
  focusButton,
  getBreakTimer,
  getDepartmentName,
  getRelevantPriceGroupSet,
  machineAlreadySelected,
  machineRemovalBlocked,
  MachineRemovalBlockedReason,
  mergeIntervals,
  readProductUseLog,
  removeUnicodeCf,
  saveTimerStart,
  skippedLogChange,
  updateTaskPriceGroupsPriceItemUses,
  willTaskBeRecorded,
  workTypeChangeBlocked,
  WorkTypeChangeBlockedReason,
} from "app-utils";
import {bind} from "bind-decorator";
import {ExtendedConfig} from "extended-config";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {MaterialWarningDialog} from "../task-instance/reporting/material-warning-dialog";
import {LogPlaces} from "./log-places";
import TransportLogBlock from "./transport-log-block";

const messages = defineMessages({
  addProductButton: {
    defaultMessage: "Tilføj materiel",
    id: "order-instance.label.add-product",
  },
  addTransportLog: {
    defaultMessage: "Tilføj transportlog",
    id: "task-instance.label.add-transport-log",
  },
  alternativeAddProduct: {
    defaultMessage: "Tilføj materialer",
    id: "order-instance.alternative-dialog-title.add-product",
  },
  alternativeAddProductButton: {
    defaultMessage: "Tilføj materialer",
    id: "order-instance.alternative-label.add-product",
  },
  arrivalAtLocation: {
    defaultMessage: "Start ved kunden kl.",
    id: "task-instance.label.arrival-at-customer",
  },
  breaks: {defaultMessage: "Pause"},
  cancel: {defaultMessage: "Fortryd", id: "dialog.label.cancel"},
  completed: {
    defaultMessage: "Fuldført",
  },
  confirmDelete: {
    defaultMessage: "Slet",
    id: "damage-report-instance.confirm-delete",
  },
  createTaskCopy: {
    defaultMessage: "Opret kopi",
    id: "order-instance.task-instance.button.label.copy",
  },
  date: {defaultMessage: "Dato"},
  deleteQuestion: {
    defaultMessage: "Slet?",
    id: "order-instance.dialog-title.delete",
  },
  deleteTask: {
    defaultMessage: "Slet opgave",
  },
  department: {
    defaultMessage: "Afdeling",
    id: "order-instance.hint-text.department",
  },
  downtime: {defaultMessage: "Driftstop"},
  effective: {
    defaultMessage: "Effektiv tid",
  },
  endTime: {
    defaultMessage: "Sluttidspunkt",
  },
  expectedTotalTaskDuration: {
    defaultMessage: "Forventet varighed",
    id: "task-instance.label.expectedTotalTaskDuration",
  },
  invoiceNote: {
    defaultMessage: "Faktura note",
    id: "order-instance.label.invoice-note",
  },
  notesFromAdministration: {
    defaultMessage: "Noter fra administration",
  },
  notesFromChauffeur: {
    defaultMessage: "Noter fra chauffør",
    id: "order-instance.label.notes-from-chauffeur",
  },
  notesFromEmployee: {
    defaultMessage: "Noter fra medarbejder",
  },
  notesFromMachineOperator: {
    defaultMessage: "Noter fra maskinfører",
    id: "order-instance.label.notes-from-machine-operator",
  },
  now: {defaultMessage: "NU"},
  recorded: {
    defaultMessage: "Bogført",
  },
  referenceNumber: {
    defaultMessage: "Referencenummer",
    id: "task-instance.label.reference-number",
  },
  selectDepartmentButton: {
    defaultMessage: "Vælg",
    id: "order-instance.label.select-department",
  },
  selectMachineButton: {
    defaultMessage: "Vælg",
  },
  selectMachineOperatorButton: {
    defaultMessage: "Vælg",
  },
  selectTaskTypeButton: {
    defaultMessage: "Vælg",
  },
  startTime: {
    defaultMessage: "Starttidspunkt",
  },
  taskCancelled: {
    defaultMessage: "Opgaven er lukket som aflyst",
  },
  taskCardTitle: {defaultMessage: "Opgave"},
  taskCompletedAsInternal: {
    defaultMessage: "Opgaven er afleveret som intern",
  },
  taskInternalManagerNotes: {
    defaultMessage: "Interne noter",
  },
  time: {defaultMessage: "Klokkeslæt"},
  transportation: {
    defaultMessage: "Transport",
  },
  validateButton: {
    defaultMessage: "Godkend",
  },
  validated: {
    defaultMessage: "Godkendt",
  },
});

export const allowMachineUseForWorktype = (workType?: WorkType): boolean => {
  return !workType || !workType.disallowMachineUse;
};

interface TaskInstanceStateProps {
  currentRole: Role | null;
  currentUserURL: UserUrl | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: ExtendedConfig;
  daysAbsenceArray: readonly DaysAbsence[];
  deliveryArray: readonly Delivery[];
  deliveryLocationArray: readonly DeliveryLocation[];
  hoursAbsenceArray: readonly HoursAbsence[];
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  locationUseLogArray: readonly LocationUseLog[];
  machineArray: readonly Machine[];
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  pickupArray: readonly Pickup[];
  pickupLocationArray: readonly PickupLocation[];
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productArray: readonly Product[];
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  productUseLogArray: readonly ProductUseLog[];
  reportingSpecificationArray: readonly ReportingSpecification[];
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  roleArray: readonly Role[];
  taskArray: readonly Task[];
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  transportLogArray: readonly TransportLog[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (userURL: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface TaskInstanceDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  dispatch: Dispatch;
  registerTimerStartPosition: (timerStart: TimerStart) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface TaskInstanceOwnProps {
  createdBy?: UserUrl | undefined;
  culture?: CultureUrl | undefined;
  customer?: CustomerUrl | undefined;
  onCopy: (task: Task) => void;
  onDeleteTask: (taskURL: string) => void;
  onlyOneTask: boolean;
  onRequestDeleteDialog: (callback: () => void) => void;
  onRequestDeliveryLocationDialog: (task: Task, deliveryLocation: DeliveryLocation | null) => void;
  onRequestEditTransportLogDialog: (
    task: Task,
    transportLog: TransportLog | null,
    ref: HTMLButtonElement | null,
  ) => void;
  onRequestMachineOperatorDialog: (
    callback: (url: UserUrl) => void,
    ref: HTMLButtonElement | null,
  ) => void;
  onRequestPickupLocationDialog: (task: Task, pickupLocation: PickupLocation | null) => void;
  onRequestValidatedDialog: (callback: () => void, task: Task) => void;
  onTaskMovedToNewOrder: (orderId: string, taskId: string) => void;
  onWorkTypeSelect: (url: WorkTypeUrl) => void;
  order: Order;
  task: Task;
  userID?: string;
}

type TaskInstanceProps = TaskInstanceDispatchProps & TaskInstanceOwnProps & TaskInstanceStateProps;

interface TaskInstanceState {
  deletingFile: string | null;
  deletingPhoto: string | null;
  departmentDialogOpen: boolean;
  displayedImage: TaskPhoto | UserPhoto | null;
  machineDialogOpen: boolean;
  machineRemovalBlockedDialogOpen: boolean;
  machineRemovalBlockedReason: MachineRemovalBlockedReason | null;
  materialWarningDialogOpen: boolean;
  productDialogOpen: boolean;
  recordInC5DialogOpen: boolean;
  removeRecordedInC5DialogOpen: boolean;
  workTypeChangeBlockedDialogOpen: boolean;
  workTypeChangeBlockedReason: WorkTypeChangeBlockedReason | null;
}

class TaskInstance extends React.Component<TaskInstanceProps, TaskInstanceState> {
  static contextType = IntlContext;

  addProductButton = React.createRef<HTMLButtonElement>();
  addTransportLogButton = React.createRef<HTMLButtonElement>();
  context!: React.ContextType<typeof IntlContext>;
  createTaskCopyButton = React.createRef<HTMLButtonElement>();
  selectDepartmentButton = React.createRef<HTMLButtonElement>();
  selectMachineButton = React.createRef<HTMLButtonElement>();
  selectMachineOperatorButton = React.createRef<HTMLButtonElement>();
  selectTaskTypeButton = React.createRef<HTMLButtonElement>();
  state: TaskInstanceState = {
    deletingFile: null,
    deletingPhoto: null,
    departmentDialogOpen: false,
    displayedImage: null,
    machineDialogOpen: false,
    machineRemovalBlockedDialogOpen: false,
    machineRemovalBlockedReason: null,
    materialWarningDialogOpen: false,
    productDialogOpen: false,
    recordInC5DialogOpen: false,
    removeRecordedInC5DialogOpen: false,
    workTypeChangeBlockedDialogOpen: false,
    workTypeChangeBlockedReason: null,
  };

  workTypeSelectionControl = React.createRef<{start: () => void}>();

  addTransportLog(): void {
    const id = urlToId(this.props.task.url);
    const logURL = instanceURL("transportLog", id);
    const params: TransportLog = {
      ...emptyTransportLog,
      id,
      task: this.props.task.url,
      url: logURL,
    };
    this.props.create(params);
  }

  componentDidMount(): void {
    const {
      customerSettings,
      machineLookup,
      orderLookup,
      priceGroupLookup,
      priceItemLookup,
      task,
      timerLookup,
      timerStartArray,
      unitLookup,
      update,
      workTypeLookup,
    } = this.props;
    updateTaskPriceGroupsPriceItemUses(
      task,
      workTypeLookup,
      machineLookup,
      priceGroupLookup,
      priceItemLookup,
      orderLookup,
      timerLookup,
      unitLookup,
      timerStartArray,
      customerSettings,
      update,
    );
  }
  getTimerStarts(): TimerStart[] {
    const {task} = this.props;
    const taskURL = task.url;
    return _.sortBy(
      this.props.timerStartArray.filter((instance) => instance.task === taskURL),
      getNormalisedDeviceTimestamp,
    );
  }
  @bind
  handleAddTransportLog(): void {
    const {task} = this.props;
    if (this.props.customerSettings.dontShowTransportLogDialog) {
      this.addTransportLog();
    } else {
      this.props.onRequestEditTransportLogDialog(task, null, this.addTransportLogButton.current);
    }
  }
  @bind
  handleArrivalAtLocationChange(value: string | null): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({arrivalAtLocation: value}, task));
  }
  @bind
  handleCopyButton(_event: unknown): void {
    const {task} = this.props;
    this.props.onCopy(task);
    focusButton(this.createTaskCopyButton.current);
  }
  @bind
  handleDateChange(newValue: string | null): void {
    const {dispatch, task, taskArray} = this.props;
    const orderURL = task.order;
    dispatch(actions.updateDiff({date: newValue}, task));
    if (orderURL && taskArray.filter((t) => t.order === orderURL).length === 1) {
      this.props.update(orderURL, [{member: "date", value: newValue}]);
    }
  }
  @bind
  handleDeleteButton(): void {
    this.props.onRequestDeleteDialog(this.handleDeleteDialogOk);
  }
  @bind
  handleDeleteDialogOk(): void {
    const {onDeleteTask, task} = this.props;
    onDeleteTask(task.url);
  }

  @bind
  handleDepartmentDialogCancel(): void {
    focusButton(this.selectDepartmentButton.current);
    this.setState({departmentDialogOpen: false});
  }
  @bind
  handleDepartmentDialogOk(selected: string): void {
    const {dispatch} = this.props;
    focusButton(this.selectDepartmentButton.current);
    this.setState({departmentDialogOpen: false});
    dispatch(actions.updateDiff({department: selected}, this.props.task));
  }

  @bind
  handleDepartmentSelectButton(): void {
    this.setState({departmentDialogOpen: true});
  }
  @bind
  handleDisplayImageRequestClose(): void {
    this.setState({displayedImage: null});
  }

  @bind
  handleFileDeleteDialogCancel(): void {
    this.setState({deletingFile: null});
  }

  @bind
  handleFileDeleteDialogOk(): void {
    if (this.state.deletingFile) {
      this.props.remove(this.state.deletingFile);
      this.setState({deletingFile: null});
    }
  }
  @bind
  handleInvoiceNoteChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({invoiceNote: filteredValue}, task));
  }
  @bind
  handleLocationDelete(location: DeliveryLocation | PickupLocation): void {
    this.props.remove(location.url);
  }
  @bind
  handleLogSkippedChange(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const {priceItemLookup, reportingSpecificationLookup, task, unitLookup} = this.props;

    const logSpecification = task.reportingSpecification
      ? reportingSpecificationLookup(task.reportingSpecification)
      : null;

    skippedLogChange(
      logSpecification,
      checked,
      task,
      priceItemLookup,
      unitLookup,
      this.setMaterialWarningDialogOpen,
      this.props.dispatch,
    );
  }
  @bind
  handleMachineDialogCancel(): void {
    this.setState({machineDialogOpen: false});
    focusButton(this.selectMachineButton.current);
  }
  @bind
  handleMachineDialogOk(machineURL: MachineUrl, priceGroupURL: PriceGroupUrl | null): void {
    this.setState({machineDialogOpen: false});
    focusButton(this.selectMachineButton.current);

    const {dispatch, task} = this.props;

    const oldMachines = task.machineuseSet || [];
    if (machineAlreadySelected(task, machineURL)) {
      return;
    }
    const newEntry: MachineUse = {
      machine: machineURL,
      priceGroup: priceGroupURL,
      transporter: false,
    };

    dispatch(actions.updateDiff({machineuseSet: [...oldMachines, newEntry]}, task));
    const workType = task.workType ? this.props.workTypeLookup(task.workType) : undefined;
    if (workType?.department) {
      return;
    }
    const machine = this.props.machineLookup(machineURL);
    if (!machine?.department) {
      return;
    }
    const oldMachineDepartments = new Set(
      oldMachines
        .map((machineUse) => this.props.machineLookup(machineUse.machine))
        .filter(notUndefined)
        .map((oldMachine) => oldMachine.department)
        .filter(Boolean),
    );
    if (oldMachineDepartments.size && !oldMachineDepartments.has(machine.department)) {
      this.setState({departmentDialogOpen: true});
    }
  }
  @bind
  handleMachineOperatorDialogOk(url: UserUrl): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({machineOperator: url}, task));
  }
  @bind
  handleMachineOperatorSelectButton(): void {
    this.props.onRequestMachineOperatorDialog(
      this.handleMachineOperatorDialogOk,
      this.selectMachineOperatorButton.current,
    );
  }
  @bind
  handleMachineRemovalBlockedDialogClose(): void {
    this.setState({machineRemovalBlockedDialogOpen: false});
  }
  @bind
  handleMachineSelectButton(): void {
    this.setState({machineDialogOpen: true});
  }
  @bind
  handleMaterialWarningDialogClose(): void {
    this.setState({materialWarningDialogOpen: false});
  }
  @bind
  handleMinutesExpectedTotalTaskDurationChange(value: number | null): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({minutesExpectedTotalTaskDuration: value}, task));
  }
  @bind
  handleNotesFromMachineOperatorChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({notesFromMachineOperator: filteredValue}, task));
  }
  @bind
  handleNotesFromManagerChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({notesFromManager: filteredValue}, task));
  }
  @bind
  handlePhotoDeleteDialogCancel(): void {
    this.setState({deletingPhoto: null});
  }

  @bind
  handlePhotoDeleteDialogOk(): void {
    if (this.state.deletingPhoto) {
      this.props.remove(this.state.deletingPhoto);
      this.setState({deletingPhoto: null});
    }
  }
  @bind
  handlePhotoDisplay(taskPhoto: TaskPhoto | UserPhoto): void {
    this.setState({displayedImage: taskPhoto});
  }

  @bind
  handleProductDialogCancel(): void {
    focusButton(this.addProductButton.current);
    this.setState({productDialogOpen: false});
  }
  @bind
  handleProductDialogOk(urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>): void {
    const {productArray, productGroupLookup, productLookup, task, update} = this.props;
    const patch = addToProductUses(
      task.productUses || {},
      urlOrURLs,
      productArray,
      productLookup,
      productGroupLookup,
      this.props.customerSettings,
      this.props.currentUserURL,
    );
    update(task.url, patch);
    this.handleProductDialogCancel();
  }
  @bind
  handleProductSelectButton(): void {
    this.setState({productDialogOpen: true});
  }
  @bind
  handleRecordInC5DialogCancel(): void {
    this.setState({recordInC5DialogOpen: false});
  }

  @bind
  handleRecordInC5DialogOk(): void {
    this.setState({recordInC5DialogOpen: false});
    const {dispatch, task} = this.props;
    dispatch(
      actions.updateDiff(
        {
          archivable: true,
          recordedInC5: new Date().toISOString(),
          validatedAndRecorded: true,
        },
        task,
      ),
    );
  }

  @bind
  handleReferenceNumberChange(value: string): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({referenceNumber: value}, task));
  }

  @bind
  handleRelatedPickupLocationChanged(url: LocationUrl | null): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({relatedPickupLocation: url}, task));
  }

  @bind
  handleRelatedWorkplaceChanged(url: LocationUrl | null): void {
    const {dispatch, task, update} = this.props;
    update(task.url, [{member: "relatedWorkplace", value: url}]);
    dispatch(actions.updateDiff({relatedWorkplace: url}, task));
  }

  @bind
  handleRemoveMachine(index: number): void {
    const {
      customerSettings,
      dispatch,
      machineLookup,
      priceGroupLookup,
      reportingSpecificationLookup,
      task,
      timerArray,
      workTypeLookup,
    } = this.props;
    const machineUse = task.machineuseSet[index];
    if (!machineUse) {
      return;
    }
    const machineUrl = machineUse.machine;
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet || []
      : computeIntervalsTruncated(this.getTimerStarts());
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    const now = new Date();
    now.setUTCMilliseconds(0);
    const timerMinutes = computeIntervalSums(intervals, now);
    const machineRemovalBlockedReason = machineRemovalBlocked(
      task,
      machineUrl,
      timerMinutes,
      customerSettings,
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationLookup,
        timerArray,
        workTypeLookup,
      },
    );
    if (machineRemovalBlockedReason) {
      this.setState({
        machineRemovalBlockedDialogOpen: true,
        machineRemovalBlockedReason,
      });
    } else {
      const oldMachines = task.machineuseSet || [];
      const newMachines = oldMachines.slice();
      newMachines.splice(index, 1);

      dispatch(actions.updateDiff({machineuseSet: newMachines}, task));
    }
  }

  @bind
  handleRemoveMachineOperator(): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({machineOperator: null}, task));
  }

  @bind
  handleRemoveRecordedInC5DialogCancel(): void {
    this.setState({removeRecordedInC5DialogOpen: false});
  }

  @bind
  handleRemoveRecordedInC5DialogOk(): void {
    this.setState({removeRecordedInC5DialogOpen: false});
    const {dispatch, task} = this.props;
    dispatch(
      actions.updateDiff(
        {
          archivable: false,
          recordedInC5: null,
        },
        task,
      ),
    );
  }

  @bind
  handleRequestDeliveryLocationDialog(deliveryLocation: DeliveryLocation | null): void {
    const {task} = this.props;
    this.props.onRequestDeliveryLocationDialog(task, deliveryLocation);
  }

  @bind
  handleRequestEditTransportLogDialog(): void {
    const {task} = this.props;
    const taskURL = task.url;
    const transportLog = this.props.transportLogArray.find((t) => t.task === taskURL);
    this.props.onRequestEditTransportLogDialog(task, transportLog || null, null);
  }

  @bind
  handleRequestFileDelete(taskFileURL: string): void {
    this.setState({deletingFile: taskFileURL});
  }

  @bind
  handleRequestPhotoDelete(taskPhotoURL: string): void {
    this.setState({deletingPhoto: taskPhotoURL});
  }

  @bind
  handleRequestPickupLocationDialog(pickupLocation: PickupLocation | null): void {
    const {task} = this.props;
    this.props.onRequestPickupLocationDialog(task, pickupLocation);
  }

  @bind
  handleTaskInternalManagerNotesChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({managerInternalNotes: filteredValue}, task));
  }

  @bind
  handleTaskTypeSelectButton(_event: unknown): void {
    const {
      customerSettings,
      machineLookup,
      priceGroupLookup,
      reportingSpecificationArray,
      reportingSpecificationLookup,
      task,
      timerArray,
      workTypeLookup,
    } = this.props;
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet || []
      : computeIntervalsTruncated(this.getTimerStarts());
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    const now = new Date();
    now.setUTCMilliseconds(0);
    const timerMinutes = computeIntervalSums(intervals, now);
    const workTypeChangeBlockedReason = workTypeChangeBlocked(
      task,
      timerMinutes,
      customerSettings,
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationArray,
        reportingSpecificationLookup,
        timerArray,
        workTypeLookup,
      },
    );
    if (workTypeChangeBlockedReason) {
      this.setState({
        workTypeChangeBlockedDialogOpen: true,
        workTypeChangeBlockedReason,
      });
    } else {
      if (this.workTypeSelectionControl.current) {
        this.workTypeSelectionControl.current.start();
      }
    }
  }

  @bind
  handleTimeChange(value: string | null): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({time: value}, task));
  }

  @bind
  handleToggleCompleted(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const {currentUserURL, customerSettings, dispatch, registerTimerStartPosition, task} =
      this.props;
    if (checked) {
      const userURL = task.machineOperator;
      const taskURL = task.url;
      if (userURL) {
        saveTimerStart(
          this.props.create,
          null,
          taskURL,
          userURL,
          null,
          customerSettings.geolocation.registerPositionOnTimerClick &&
            currentUserURL === task.machineOperator
            ? registerTimerStartPosition
            : undefined,
        );
      }
    }
    if (checked) {
      const now = new Date();
      const taskURL = task.url;
      const timerStarts = _.sortBy(
        this.props.timerStartArray.filter((instance) => instance.task === taskURL),
        getNormalisedDeviceTimestamp,
      );
      const computedIntervals = computeIntervalsTruncated(timerStarts, now.toISOString());
      dispatch(actions.updateDiff({completed: true, computedTimeSet: computedIntervals}, task));
    } else {
      dispatch(
        actions.updateDiff(
          {
            cancelled: false,
            completed: false,
            completedAsInternal: false,
            computedTimeSet: [],
            continuationTask: null,
          },
          task,
        ),
      );
    }
  }

  @bind
  handleToggleRecordedInC5(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    if (!checked) {
      this.setState({removeRecordedInC5DialogOpen: true});
    } else {
      this.setState({recordInC5DialogOpen: true});
    }
  }

  @bind
  handleToggleValidated(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const {dispatch, task} = this.props;
    if (!checked) {
      dispatch(
        actions.updateDiff(
          {
            archivable: false,
            reportApproved: false,
            validatedAndRecorded: false,
          },
          task,
        ),
      );
    }
  }
  @bind
  handleValidatedButton(): void {
    const {task} = this.props;
    const userURL = task.machineOperator;
    const taskURL = task.url;
    if (userURL) {
      saveTimerStart(this.props.create, null, taskURL, userURL, null);
    }
    this.props.onRequestValidatedDialog(this.handleValidatedDialogOk, task);
  }
  @bind
  handleValidatedDialogOk(): void {
    // FIXME: handle admins stopping task on behalf of others
    const {task, timerArray, timerStartArray} = this.props;
    const breakTimer = getBreakTimer(timerArray);
    const computedIntervals = task.computedTimeSet || [];
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    const {workFromTimestamp, workToTimestamp} = computeWorkFromTo(intervals);

    const patch: PatchOperation<Task>[] = [
      {member: "computedTimeSet", value: computedIntervals},
      {member: "reportApproved", value: true},
      {member: "workFromTimestamp", value: workFromTimestamp},
      {member: "workToTimestamp", value: workToTimestamp},
    ];
    if (!this.props.customerSettings.useApproveReport) {
      patch.push({member: "validatedAndRecorded", value: true});
    }
    const orderURL = task.order;
    const order = orderURL ? this.props.orderLookup(orderURL) : null;
    if (
      !willTaskBeRecorded(
        task,
        order || undefined,
        this.props.customerSettings,
        timerStartArray,
        breakTimer?.url,
      )
    ) {
      patch.push({member: "archivable", value: true});
    }
    this.props.update(task.url, patch);
  }
  @bind
  handleWorkTypeChangeBlockedDialogClose(): void {
    this.setState({workTypeChangeBlockedDialogOpen: false});
  }
  @bind
  handleWorkTypeSelection(workTypeURL: WorkTypeUrl, priceGroupURL: PriceGroupUrl | null): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({priceGroup: priceGroupURL, workType: workTypeURL}, task));
    focusButton(this.selectTaskTypeButton.current);
  }
  @bind
  handleWorkTypeSelectionCancelled(): void {
    focusButton(this.selectTaskTypeButton.current);
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      customerSettings,
      daysAbsenceArray,
      hoursAbsenceArray,
      locationArray,
      machineLookup,
      onlyOneTask,
      orderLookup,
      reportingSpecificationLookup,
      task,
    } = this.props;

    const {
      locations: {canCreateWorkplace},
    } = customerSettings;

    const userURL = this.props.currentUserURL;
    const role = this.props.roleArray.find((r) => r.user === userURL);
    const userIsNotManager = !!(role && !role.manager);
    const userIsSeniorMachineOperator = !!(role && role.seniorMachineOperator);
    const machineOperatorURL = task.machineOperator;
    const userIsOther = userURL !== machineOperatorURL;
    const userIsOtherMachineOperator =
      userIsOther && userIsNotManager && !userIsSeniorMachineOperator;
    const userIsManager = role && role.manager;
    const canUnapproveTasks = role && role.canUnapproveTasks;
    const userIsConsultant = role && role.consultant;
    const customerURL = this.props.customer || null;

    const taskURL = task.url;
    const transportLog = this.props.transportLogArray.find((t) => t.task === taskURL);

    const validated = !!task.validatedAndRecorded || !!task.reportApproved;
    const completed = !!task.completed;
    const deletable =
      !validated && (!completed || !userIsNotManager) && !userIsOtherMachineOperator;

    const now = new Date();
    now.setUTCMilliseconds(0);
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet || []
      : computeIntervalsTruncated(this.getTimerStarts());
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    const hasActivity = !!intervals.length;

    const timerMinutes = computeIntervalSums(intervals, now);

    let referenceNumberBlock;
    if (customerSettings.enableTaskReferenceNumber) {
      referenceNumberBlock = (
        <Cell palm="12/12">
          <TrimTextField
            disabled={validated || userIsOtherMachineOperator}
            inputProps={{maxLength: 255}}
            label={
              customerSettings.taskReferenceNumberLabel || formatMessage(messages.referenceNumber)
            }
            margin="dense"
            onChange={this.handleReferenceNumberChange}
            value={task.referenceNumber || ""}
          />
        </Cell>
      );
    }

    let machineOperator = null;
    const allowMachineOperatorRemoval = deletable && !hasActivity;
    const disableMachineOperatorSelection =
      !customerSettings.allowCustomerTaskEmployeeChange || !allowMachineOperatorRemoval;
    if (machineOperatorURL && this.props.userLookup(machineOperatorURL)) {
      const profile = this.props.userUserProfileLookup(machineOperatorURL);
      const chipOptionalDeletHandler: ChipProps = {};
      if (allowMachineOperatorRemoval) {
        chipOptionalDeletHandler.onDelete = this.handleRemoveMachineOperator;
      }
      machineOperator = (
        <div>
          <Chip
            avatar={
              <Avatar
                style={{
                  backgroundColor: colorMap.MACHINE_OPERATOR_AVATAR_BACKGROUND,
                  color: matchingTextColor(colorMap.MACHINE_OPERATOR_AVATAR_BACKGROUND),
                }}
              >
                {profile && profile.alias}
              </Avatar>
            }
            label={profile && profile.name}
            {...chipOptionalDeletHandler}
          />
        </div>
      );
    }
    let machine = null;
    const machineRemovalAllowed = !task.order || !!customerSettings.allowCustomerTaskMachineChange;
    const entries = task.machineuseSet || [];
    const renderedEntries = entries.map((entry, index) => {
      const machineURL = entry.machine;
      let machineID = "";
      let machineName = "";
      const foundMachine = machineLookup(machineURL);
      if (foundMachine) {
        machineID = foundMachine.c5_machine;
        machineName = foundMachine.name;
      }
      const priceGroupURL = entry.priceGroup;
      let priceGroupName = "";
      const priceGroup = priceGroupURL ? this.props.priceGroupLookup(priceGroupURL) : null;
      if (priceGroup) {
        priceGroupName = priceGroup.name;
      }
      const text =
        machineName +
        (machineID ? ` (${machineID})` : "") +
        (priceGroupName ? ` (${priceGroupName})` : "");

      return (
        <MachineChip
          deletable={deletable && machineRemovalAllowed}
          index={index}
          key={index}
          onDelete={this.handleRemoveMachine}
          text={text}
        />
      );
    });
    machine = <div>{renderedEntries}</div>;
    let workType = null;
    let priceGroup = null;
    const workTypeURL = task.workType;
    const workTypeData = workTypeURL ? this.props.workTypeLookup(workTypeURL) : null;
    if (workTypeData) {
      const workTypeString = getWorkTypeString(workTypeData);
      workType = <div>{workTypeString}</div>;
    }

    const priceGroupURL = task.priceGroup;
    const priceGroupData = priceGroupURL ? this.props.priceGroupLookup(priceGroupURL) : null;
    if (priceGroupData) {
      priceGroup = <div>({priceGroupData.name})</div>;
    }

    let addProductButton = null;
    if (!customerSettings.noProducts && customerSettings.enableAddProducts) {
      addProductButton = (
        <Button
          color="secondary"
          disabled={validated}
          onClick={this.handleProductSelectButton}
          ref={this.addProductButton}
          variant="contained"
        >
          {customerSettings.materialUseAlternativeText
            ? formatMessage(messages.alternativeAddProductButton)
            : formatMessage(messages.addProductButton)}
        </Button>
      );
    }

    const departmentID = task.department;
    const department = <div>{getDepartmentName(departmentID, this.props.customerSettings)}</div>;

    let workplaceOrAddressBlock;
    if (
      customerSettings.taskOrderTaskShowWorkPlace &&
      !customerSettings.workplaceButtonUnderCustomer
    ) {
      const hasSelectableWorkPlaces = locationArray.some(
        (location) =>
          !location.logOnlyLocation && location.customer === customerURL && location.active,
      );

      const workplaceSelectDisabled = !(
        (hasSelectableWorkPlaces || canCreateWorkplace) &&
        !validated &&
        (userIsManager || userIsSeniorMachineOperator || (!userIsOther && !completed))
      );

      workplaceOrAddressBlock = (
        <Grid>
          <Cell>
            {customerSettings.taskPickupDelivery ? (
              <>
                <LocationBlock
                  clearDisabled={workplaceSelectDisabled || !task.relatedPickupLocation}
                  customerUrl={customerURL}
                  hideFieldLocations
                  includeLogOnlyLocations={false}
                  includeWorkplaceOnlyLocations
                  locationUrl={task.relatedPickupLocation}
                  onLocationChanged={this.handleRelatedPickupLocationChanged}
                  selectDisabled={workplaceSelectDisabled}
                  title={formatMessage({defaultMessage: "Afhentningssted"})}
                  titleVariant="WORKPLACE"
                />
                <LocationBlock
                  clearDisabled={workplaceSelectDisabled || !task.relatedWorkplace}
                  customerUrl={customerURL}
                  hideFieldLocations
                  includeLogOnlyLocations={false}
                  includeWorkplaceOnlyLocations
                  locationUrl={task.relatedWorkplace}
                  onLocationChanged={this.handleRelatedWorkplaceChanged}
                  selectDisabled={workplaceSelectDisabled}
                  title={formatMessage({defaultMessage: "Arbejds-/leveringssted"})}
                  titleVariant="WORKPLACE"
                />
              </>
            ) : (
              <LocationBlock
                clearDisabled={workplaceSelectDisabled || !task.relatedWorkplace}
                customerUrl={customerURL}
                hideFieldLocations
                includeLogOnlyLocations={false}
                includeWorkplaceOnlyLocations
                locationUrl={task.relatedWorkplace}
                onLocationChanged={this.handleRelatedWorkplaceChanged}
                selectDisabled={workplaceSelectDisabled}
                title={formatMessage({defaultMessage: "Arbejdssted"})}
                titleVariant="WORKPLACE"
              />
            )}
          </Cell>
        </Grid>
      );
    }
    let deleteDisabled = completed || validated || onlyOneTask;
    if (!deleteDisabled) {
      deleteDisabled = this.props.timerStartArray.some((t) => t.task === taskURL && !!t.timer);
    }
    const allowMachineUse = allowMachineUseForWorktype(workTypeData || undefined);
    const machineBlock = (
      <Cell palm="12/12">
        <Typography variant="body1">
          {customerSettings.machineLabelVariant === "MACHINE" ? (
            <FormattedMessage defaultMessage="Maskine" id="order-instance.header.machine" />
          ) : (
            <FormattedMessage defaultMessage="Køretøj" id="order-instance.header.vehicle" />
          )}
        </Typography>
        <Button
          color="secondary"
          disabled={
            !customerSettings.allowCustomerTaskMachineChange ||
            !allowMachineUse ||
            (workTypeData?.allowMaxOneMachine && entries.length > 0) ||
            validated ||
            (completed && userIsNotManager) ||
            userIsOtherMachineOperator
          }
          onClick={this.handleMachineSelectButton}
          ref={this.selectMachineButton}
          variant="contained"
        >
          {formatMessage(messages.selectMachineButton)}
        </Button>
        {machine}
      </Cell>
    );
    let absent;
    const machineOperatorAbsent =
      !!task.machineOperator &&
      checkUserAbsence(
        task.machineOperator,
        task,
        orderLookup,
        daysAbsenceArray,
        hoursAbsenceArray,
        this.props.customerSettings.absenceWarningDisabledFor,
      );
    if (machineOperatorAbsent) {
      absent = (
        <div style={{color: "red"}}>
          {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
            <FormattedMessage
              defaultMessage="Den valgte maskinfører har registreret fravær på den valgte dato"
              id="order-instance.header.machine-operator-absent"
              tagName="h4"
            />
          ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
            <FormattedMessage
              defaultMessage="Den valgte medarbejder har registreret fravær på den valgte dato"
              id="order-instance.header.employee-absent"
              tagName="h4"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Den valgte chauffør har registreret fravær på den valgte dato"
              id="order-instance.header.chauffeur-absent"
              tagName="h4"
            />
          )}
        </div>
      );
    }

    let hasOtherTasks: React.JSX.Element | undefined;
    if (customerSettings.employeeSameDayTasksWarning) {
      const machineOperatorOtherTaskCount = getOtherTaskCount(task, this.props.taskArray);
      if (machineOperatorOtherTaskCount > 0) {
        hasOtherTasks = (
          <TaskCountMessage
            customerSettings={customerSettings}
            machineOperatorOtherTaskCount={machineOperatorOtherTaskCount}
          />
        );
      }
    }

    const machineOperatorBlock = (
      <Cell palm="12/12">
        <Typography variant="body1">
          {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
            <FormattedMessage
              defaultMessage="Maskinfører"
              id="order-instance.header.machine-operator"
            />
          ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
            <FormattedMessage defaultMessage="Medarbejder" id="order-instance.header.employee" />
          ) : (
            <FormattedMessage defaultMessage="Chauffør" id="order-instance.header.chauffeur" />
          )}
        </Typography>

        <Button
          color="secondary"
          disabled={
            validated ||
            (userIsNotManager && !userIsSeniorMachineOperator) ||
            disableMachineOperatorSelection
          }
          onClick={this.handleMachineOperatorSelectButton}
          ref={this.selectMachineOperatorButton}
          variant="contained"
        >
          {formatMessage(messages.selectMachineOperatorButton)}
        </Button>
        {machineOperator}
        {absent}
        {hasOtherTasks}
      </Cell>
    );
    let timeBlock;
    if (customerSettings.taskShowTimeField) {
      timeBlock = (
        <Cell palm="12/12">
          <TimeField
            disabled={validated || (completed && userIsNotManager) || userIsOtherMachineOperator}
            fullWidth
            label={formatMessage(messages.time)}
            margin="dense"
            onChange={this.handleTimeChange}
            value={task.time || undefined}
          />
        </Cell>
      );
    }
    let createTaskCopyButtonCell = null;
    if (customerSettings.includeTaskCopy) {
      createTaskCopyButtonCell = (
        <Cell palm="12/12" style={{paddingBottom: 8}}>
          <Button
            color="secondary"
            disabled={validated}
            onClick={this.handleCopyButton}
            ref={this.createTaskCopyButton}
            variant="contained"
          >
            {formatMessage(messages.createTaskCopy)}
          </Button>
        </Cell>
      );
    }

    const reportingSpecification =
      task.reportingSpecification && !task.logSkipped
        ? reportingSpecificationLookup(task.reportingSpecification)
        : undefined;
    let readonlyPriceItems: Set<string> | undefined;
    if (reportingSpecification) {
      readonlyPriceItems = getReadonlyPriceItems(
        reportingSpecification,
        sortByOrderMember(Object.values(task.priceItemUses || {})),
        this.props.priceItemLookup,
        this.props.unitLookup,
      );
    }

    const logSpecification = task.reportingSpecification
      ? this.props.reportingSpecificationLookup(task.reportingSpecification)
      : null;

    const readonly =
      (completed && userIsNotManager) || validated || (userIsOther && userIsNotManager);

    return (
      <div>
        <Card>
          <CardHeader title={formatMessage(messages.taskCardTitle)} />
          <CardContent>
            {task && task.cancelled && task.completed ? (
              <h2 style={{color: colorMap.ERROR}}>{formatMessage(messages.taskCancelled)}</h2>
            ) : null}
            {task && task.completedAsInternal && task.completed ? (
              <h2 style={{color: colorMap.ERROR}}>
                {formatMessage(messages.taskCompletedAsInternal)}
              </h2>
            ) : null}
            <Grid>
              {referenceNumberBlock}
              {!customerSettings.noExternalTaskWorkType ? (
                <Cell palm="12/12">
                  <Typography variant="body1">
                    <FormattedMessage defaultMessage="Arbejdsområde" />
                  </Typography>
                  {customerSettings.enableExternalTaskWorkTypeAndVariantSwitch ? (
                    <Button
                      color="secondary"
                      disabled={
                        validated || (completed && userIsNotManager) || userIsOtherMachineOperator
                      }
                      onClick={this.handleTaskTypeSelectButton}
                      ref={this.selectTaskTypeButton}
                      variant="contained"
                    >
                      {formatMessage(messages.selectTaskTypeButton)}
                    </Button>
                  ) : null}
                  {workType}
                  {priceGroup}
                </Cell>
              ) : null}
              {customerSettings.machineBeforeMachineOperator ? machineBlock : machineOperatorBlock}
              {customerSettings.enableExternalTaskDepartmentField ? (
                <Cell palm="12/12">
                  <Typography variant="body1">
                    <FormattedMessage defaultMessage="Afdeling" />
                  </Typography>
                  <Button
                    color="secondary"
                    disabled={
                      validated ||
                      (completed && userIsNotManager) ||
                      userIsOtherMachineOperator ||
                      (customerSettings.onlyAdminCanChangeDepartment && userIsNotManager)
                    }
                    onClick={this.handleDepartmentSelectButton}
                    ref={this.selectDepartmentButton}
                    variant="contained"
                  >
                    {formatMessage(messages.selectDepartmentButton)}
                  </Button>
                  {department}
                </Cell>
              ) : null}
              {customerSettings.machineBeforeMachineOperator ? machineOperatorBlock : machineBlock}
            </Grid>
            {workplaceOrAddressBlock}
            {!customerSettings.workplaceButtonUnderCustomer ? (
              <TaskFields
                defaultEditMode
                order={this.props.order}
                showFieldMap={false}
                task={task}
              />
            ) : null}
            <Grid>
              <Cell palm="12/12">
                {userIsNotManager &&
                !(
                  (userIsSeniorMachineOperator ||
                    (customerSettings.allowMachineOperatorToEditTaskDate &&
                      !userIsOtherMachineOperator)) &&
                  !hasActivity
                ) ? (
                  <h4>Dato: {formatDate(new Date(task.date || new Date()))}</h4>
                ) : (
                  <DateField
                    autoOk
                    disabled={validated}
                    fullWidth
                    label={formatMessage(messages.date)}
                    margin="dense"
                    onChange={this.handleDateChange}
                    value={task.date}
                  />
                )}
              </Cell>
              {timeBlock}
              {customerSettings.showArrivalAtLocationField ? (
                <Cell palm="12/12">
                  <TimeField
                    disabled={
                      validated || (completed && userIsNotManager) || userIsOtherMachineOperator
                    }
                    fullWidth
                    label={formatMessage(messages.arrivalAtLocation)}
                    margin="dense"
                    onChange={this.handleArrivalAtLocationChange}
                    value={task.arrivalAtLocation || undefined}
                  />
                </Cell>
              ) : null}
              {customerSettings.enableTaskEstimation &&
              (!userIsNotManager || userIsSeniorMachineOperator) ? (
                <Cell palm="12/12">
                  <MinutesField
                    disabled={validated}
                    fullWidth
                    label={formatMessage(messages.expectedTotalTaskDuration)}
                    margin="dense"
                    onChange={this.handleMinutesExpectedTotalTaskDurationChange}
                    value={task.minutesExpectedTotalTaskDuration}
                  />
                </Cell>
              ) : null}
            </Grid>

            {logSpecification && logSpecification.allowSkip ? (
              <>
                <FormControlLabel
                  control={
                    <Checkbox checked={task.logSkipped} onChange={this.handleLogSkippedChange} />
                  }
                  disabled={
                    readonly || !!(task.reportingLog && Object.keys(task.reportingLog).length)
                  }
                  label={<FormattedMessage defaultMessage="Ingen log" />}
                  style={{paddingLeft: "1em", paddingRight: "1em"}}
                />
                <MaterialWarningDialog
                  onClose={this.handleMaterialWarningDialogClose}
                  open={this.state.materialWarningDialogOpen}
                />
              </>
            ) : null}

            {reportingSpecification ? (
              <LogPlaces
                logSpecification={reportingSpecification}
                order={this.props.order}
                readonly={
                  validated || (completed && userIsNotManager) || userIsOtherMachineOperator
                }
                task={task}
                userIsOtherMachineOperator={userIsOtherMachineOperator}
              />
            ) : null}

            {customerSettings.enableTransportLog ? (
              transportLog ? (
                <TransportLogBlock
                  disabled={
                    validated || (completed && userIsNotManager) || userIsOtherMachineOperator
                  }
                  onRequestDeliveryLocationDialog={this.handleRequestDeliveryLocationDialog}
                  onRequestEditTransportLogDialog={this.handleRequestEditTransportLogDialog}
                  onRequestLocationDelete={this.handleLocationDelete}
                  onRequestPickupLocationDialog={this.handleRequestPickupLocationDialog}
                  transportLog={transportLog}
                />
              ) : (
                <Button
                  color="secondary"
                  disabled={
                    validated || (completed && userIsNotManager) || userIsOtherMachineOperator
                  }
                  onClick={this.handleAddTransportLog}
                  ref={this.addTransportLogButton}
                  style={{marginBottom: 16}}
                  variant="contained"
                >
                  {formatMessage(messages.addTransportLog)}
                </Button>
              )
            ) : null}
            {!userIsNotManager && customerSettings.enableTaskInternalManagerNotes ? (
              <Grid>
                <Cell palm="12/12">
                  <ThrottledTextField
                    disabled={validated}
                    fullWidth
                    label={formatMessage(messages.taskInternalManagerNotes)}
                    margin="dense"
                    maxRows={30}
                    minRows={2}
                    multiline
                    onChange={this.handleTaskInternalManagerNotesChange}
                    value={task.managerInternalNotes}
                    variant="outlined"
                  />
                </Cell>
              </Grid>
            ) : null}
            {(!userIsNotManager || customerSettings.machineOperaterCanEditInvoiceNote) &&
            customerSettings.showInvoiceNote ? (
              <Grid>
                <Cell palm="12/12">
                  <ThrottledTextField
                    disabled={validated}
                    fullWidth
                    label={formatMessage(messages.invoiceNote)}
                    margin="dense"
                    maxRows={30}
                    minRows={2}
                    multiline
                    onChange={this.handleInvoiceNoteChange}
                    value={task.invoiceNote}
                    variant="outlined"
                  />
                </Cell>
              </Grid>
            ) : null}
            {userIsNotManager &&
            (!userIsSeniorMachineOperator ||
              !customerSettings.seniorMachineOperatorCanEditNotesFromManager) ? null : (
              <Grid>
                <Cell palm="12/12">
                  <ThrottledTextField
                    disabled={
                      validated && !customerSettings.alwaysAllowManagerTaskAndOrderNotesEdit
                    }
                    fullWidth
                    label={formatMessage(messages.notesFromAdministration)}
                    margin="dense"
                    maxRows={30}
                    minRows={2}
                    multiline
                    onChange={this.handleNotesFromManagerChange}
                    value={task.notesFromManager}
                    variant="outlined"
                  />
                </Cell>
              </Grid>
            )}
            <Grid>
              <Cell palm="12/12">
                <ThrottledTextField
                  disabled={
                    (validated && !customerSettings.alwaysAllowManagerTaskAndOrderNotesEdit) ||
                    (completed && userIsNotManager) ||
                    userIsOtherMachineOperator
                  }
                  fullWidth
                  label={
                    customerSettings.employeeLabelVariant === "MACHINEOPERATOR"
                      ? formatMessage(messages.notesFromMachineOperator)
                      : customerSettings.employeeLabelVariant === "EMPLOYEE"
                        ? formatMessage(messages.notesFromEmployee)
                        : formatMessage(messages.notesFromChauffeur)
                  }
                  margin="dense"
                  maxRows={30}
                  minRows={2}
                  multiline
                  onChange={this.handleNotesFromMachineOperatorChange}
                  value={task.notesFromMachineOperator}
                  variant="outlined"
                />
              </Cell>
            </Grid>
            {userIsNotManager ? null : (
              <Grid>
                <Cell palm="12/12" style={{paddingBottom: 12}}>
                  <FormControlLabel
                    control={
                      <Switch checked={task.completed} onChange={this.handleToggleCompleted} />
                    }
                    disabled={validated || !completed}
                    label={formatMessage(messages.completed)}
                    labelPlacement="end"
                  />
                </Cell>
                <Cell palm="12/12" style={{paddingBottom: 12}}>
                  <FormControlLabel
                    control={<Switch checked={validated} onChange={this.handleToggleValidated} />}
                    disabled={!canUnapproveTasks || !!task.recordedInC5 || !validated}
                    label={formatMessage(messages.validated)}
                    labelPlacement="end"
                  />
                </Cell>
                <Cell palm="12/12" style={{paddingBottom: 12}}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!task.recordedInC5}
                        onChange={this.handleToggleRecordedInC5}
                      />
                    }
                    disabled={!userIsConsultant || !task.completed || !validated}
                    label={formatMessage(messages.recorded)}
                    labelPlacement="end"
                  />
                  {task.recordedInC5 && userIsManager ? (
                    <FormattedMessage
                      defaultMessage="Bogført {timestamp}"
                      id="order-instance.alternative-header.transfered-timestamp"
                      tagName="div"
                      values={{timestamp: formatDateTime(task.recordedInC5)}}
                    />
                  ) : null}
                </Cell>
              </Grid>
            )}
            {!userIsNotManager ? (
              <Grid>
                <Cell>
                  {customerSettings.materialUseAlternativeText ? (
                    <FormattedMessage
                      defaultMessage="Materialer:"
                      id="order-instance.alternative-header.products"
                      tagName="h4"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Materiel:"
                      id="order-instance.header.products"
                      tagName="h4"
                    />
                  )}
                  <TaskPriceItemTable
                    readonly={
                      task.validatedAndRecorded ||
                      (!userIsManager && (completed || userIsOtherMachineOperator))
                    }
                    readonlyPriceItems={readonlyPriceItems}
                    task={
                      task as Task & {
                        readonly priceItemUses: PriceItemUsesDict;
                      }
                    }
                  />
                  <TaskProductTable
                    hasActivity={hasActivity}
                    productDialogTimerMinutesMap={timerMinutes}
                    reportingSpecification={reportingSpecification}
                    task={
                      task as Task & {
                        readonly priceItemUses: PriceItemUsesDict;
                        readonly productUses: ProductUsesDict;
                      }
                    }
                  />
                  {addProductButton}
                </Cell>
              </Grid>
            ) : null}
            <Grid>
              <Cell>
                <FormattedMessage defaultMessage="Vedhæft filer:" tagName="h4" />
                <PhotoAndPdfUpload
                  editDisabled={false}
                  onPhotoDisplay={this.handlePhotoDisplay}
                  onRequestFileDelete={this.handleRequestFileDelete}
                  onRequestPhotoDelete={this.handleRequestPhotoDelete}
                  task={task}
                />
              </Cell>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid>
              {userIsNotManager ? null : createTaskCopyButtonCell}
              {userIsNotManager ? null : (
                <Cell palm="12/12" style={{paddingBottom: 8}}>
                  <Button
                    color="secondary"
                    disabled={validated || !completed}
                    onClick={this.handleValidatedButton}
                    variant="contained"
                  >
                    {formatMessage(messages.validateButton)}
                  </Button>
                </Cell>
              )}
              {!userIsOtherMachineOperator /* FIXME: disable delete for machine operator if any time registered... */ ? (
                <Cell palm="12/12" style={{paddingBottom: 8}}>
                  <ErrorColorButton
                    disabled={deleteDisabled}
                    onClick={this.handleDeleteButton}
                    variant="contained"
                  >
                    {formatMessage(messages.deleteTask)}
                  </ErrorColorButton>
                </Cell>
              ) : null}
            </Grid>
          </CardActions>
        </Card>
        <RemoveRecordedInC5Dialog
          onCancel={this.handleRemoveRecordedInC5DialogCancel}
          onOk={this.handleRemoveRecordedInC5DialogOk}
          open={this.state.removeRecordedInC5DialogOpen}
        />
        <RecordInC5Dialog
          onCancel={this.handleRecordInC5DialogCancel}
          onOk={this.handleRecordInC5DialogOk}
          open={this.state.recordInC5DialogOpen}
        />
        {customerSettings.productImageSelection ? (
          <ProductGroupTreeDialog
            onCancel={this.handleProductDialogCancel}
            onOk={this.handleProductDialogOk}
            open={!!this.state.productDialogOpen}
          />
        ) : (
          <ConnectedProductDialog
            machines={task.machineuseSet
              .map((machineUse) => machineLookup(machineUse.machine))
              .filter(notUndefined)}
            onCancel={this.handleProductDialogCancel}
            onOk={this.handleProductDialogOk}
            open={!!this.state.productDialogOpen}
            preferredCategory="recentlyUsed"
            preferredProductURLs={
              customerSettings.enableRecentlyUsedProducts
                ? readProductUseLog(
                    this.props.productUseLogArray,
                    this.props.productLookup,
                    this.props.task,
                  )
                : undefined
            }
            priceGroups={Array.from(
              getRelevantPriceGroupSet(task, {
                timerLookup: this.props.timerLookup,
                timerMinutesMap: timerMinutes,
              }),
            )
              .map(this.props.priceGroupLookup)
              .filter(notUndefined)}
            workType={workTypeData || undefined}
          />
        )}
        <WorkTypeSelectionWrapper
          customerURL={customerURL}
          onWorkTypeSelection={this.handleWorkTypeSelection}
          onWorkTypeSelectionCancelled={this.handleWorkTypeSelectionCancelled}
          ref={this.workTypeSelectionControl}
          task={task}
        />
        <MachinePriceGroupWizard
          key="machine-dialog"
          onCancel={this.handleMachineDialogCancel}
          onOk={this.handleMachineDialogOk}
          open={this.state.machineDialogOpen}
          task={task}
        />
        <ConnectedDepartmentDialog
          onCancel={this.handleDepartmentDialogCancel}
          onOk={this.handleDepartmentDialogOk}
          open={!!this.state.departmentDialogOpen}
        />
        <WorkTypeChangeBlockedDialog
          blockedReason={this.state.workTypeChangeBlockedReason}
          onClose={this.handleWorkTypeChangeBlockedDialogClose}
          open={this.state.workTypeChangeBlockedDialogOpen}
        />
        <MachineRemovalBlockedDialog
          blockedReason={this.state.machineRemovalBlockedReason}
          onClose={this.handleMachineRemovalBlockedDialogClose}
          open={this.state.machineRemovalBlockedDialogOpen}
        />
        <PhotoDisplayDialog
          instance={this.state.displayedImage || undefined}
          key="photo-display-dialog"
          onRequestClose={this.handleDisplayImageRequestClose}
        />
        <DeleteDialog
          key="delete-photo-dialog"
          onCancel={this.handlePhotoDeleteDialogCancel}
          onOk={this.handlePhotoDeleteDialogOk}
          open={!!this.state.deletingPhoto}
        >
          <FormattedMessage defaultMessage="Slet foto?" />
        </DeleteDialog>
        <DeleteDialog
          key="delete-file-dialog"
          onCancel={this.handleFileDeleteDialogCancel}
          onOk={this.handleFileDeleteDialogOk}
          open={!!this.state.deletingFile}
        >
          <FormattedMessage defaultMessage="Slet fil?" />
        </DeleteDialog>
      </div>
    );
  }
  @bind
  setMaterialWarningDialogOpen(): void {
    this.setState({materialWarningDialogOpen: true});
  }

  UNSAFE_componentWillReceiveProps(nextProps: TaskInstanceProps): void {
    if (nextProps.customer !== this.props.customer) {
      const customerURL = nextProps.customer;
      const {dispatch, task} = this.props;
      const workplaceURL = task.relatedWorkplace;
      if (workplaceURL) {
        const workplace = this.props.locationLookup(workplaceURL);
        if (workplace && workplace.customer !== customerURL) {
          dispatch(actions.updateDiff({relatedWorkplace: null}, task));
        }
      }
      if (this.props.customerSettings.useCustomerAddressAsDefaultTaskAddress) {
        const customer = customerURL && this.props.customerLookup(customerURL);
        if (customer) {
          const address = formatAddress(customer);
          dispatch(actions.updateDiff({address}, task));
        }
      }
    }
  }
}

const ConnectedTaskInstance = connect<
  TaskInstanceStateProps,
  TaskInstanceDispatchProps,
  TaskInstanceOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TaskInstanceStateProps>({
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getExtendedCustomerSettings,
    daysAbsenceArray: getDaysAbsenceArray,
    deliveryArray: getDeliveryArray,
    deliveryLocationArray: getDeliveryLocationArray,
    hoursAbsenceArray: getHoursAbsenceArray,
    locationArray: getLocationArray,
    locationLookup: getLocationLookup,
    locationUseLogArray: getLocationUseLogArray,
    machineArray: getMachineArray,
    machineLookup: getMachineLookup,
    orderLookup: getOrderLookup,
    pickupArray: getPickupArray,
    pickupLocationArray: getPickupLocationArray,
    priceGroupLookup: getPriceGroupLookup,
    priceItemLookup: getPriceItemLookup,
    productArray: getProductArray,
    productGroupLookup: getProductGroupLookup,
    productLookup: getProductLookup,
    productUseLogArray: getProductUseLogArray,
    reportingSpecificationArray: getReportingSpecificationArray,
    reportingSpecificationLookup: getReportingSpecificationLookup,
    roleArray: getRoleArray,
    taskArray: getTaskArray,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    transportLogArray: getTransportLogArray,
    unitLookup: getUnitLookup,
    userLookup: getUserLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  (dispatch) => ({
    dispatch,
    ...bindActionCreators(
      {
        create: actions.create,
        registerTimerStartPosition: actions.registerTimerStartPosition,
        remove: actions.remove,
        update: actions.update,
      },
      dispatch,
    ),
  }),
  null,
  {forwardRef: true},
)(TaskInstance);

export default ConnectedTaskInstance;
