import {Customer} from "@co-common-libs/resources";
import {ExtendedConfig} from "extended-config";
import {CustomerFormMember} from "./types";

export function computeDisabledInputFields(
  inputFields: ReadonlySet<CustomerFormMember>,
  editCustomer: Customer | null,
  customerSettings: Pick<ExtendedConfig, "customers">,
): ReadonlySet<CustomerFormMember> {
  // all fields disabled if user is not allowed to edit
  if (!customerSettings.customers.canManage) {
    return new Set(inputFields);
  }

  // all fields disabled if customer is barred
  if (editCustomer?.barred) {
    return new Set(inputFields);
  }

  if (inputFields.has(CustomerFormMember.CUSTOMER_NUMBER) && !!editCustomer?.remoteUrl) {
    return new Set([CustomerFormMember.CUSTOMER_NUMBER]);
  }

  return new Set();
}
