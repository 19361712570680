import {jsonFetch, ResponseWithData, translateNetworkError} from "@co-frontend-libs/utils";
import {useAsync} from "@react-hookz/web";
import {ApiUrl} from "api-endpoint-urls";
import React, {useCallback, useEffect} from "react";
import {useIntl} from "react-intl";
import {ErrorDialog} from "./error-dialog";
import {SpinnerDialog} from "./spinner-dialog";

export interface AwaitingBackendRequestProps {
  apiUrl: ApiUrl;
  data?: object;
  errorTitle: string;
  execute: boolean;
  loadingTitle: string;
  method: "GET" | "POST";
  onErrorDialogDismiss?: () => void;
  onSuccess: (response?: ResponseWithData) => void;
}

export const AwaitingBackendRequest = React.memo(function AwaitingBackendRequest(
  props: AwaitingBackendRequestProps,
): React.JSX.Element {
  const {apiUrl, data, errorTitle, execute, loadingTitle, method, onErrorDialogDismiss, onSuccess} =
    props;

  const [state, actions] = useAsync(() => jsonFetch(apiUrl, method, data));

  useEffect(() => {
    if (execute) {
      actions.execute();
    } else {
      actions.reset();
    }
  }, [actions, execute]);

  useEffect(() => {
    if (state.status === "success") {
      onSuccess(state.result);
    }
  }, [onSuccess, state.result, state.status]);

  const handleErrorDialogOk = useCallback(() => {
    actions.reset();
    if (onErrorDialogDismiss) {
      onErrorDialogDismiss();
    }
  }, [actions, onErrorDialogDismiss]);

  const intl = useIntl();

  return (
    <>
      <SpinnerDialog open={state.status === "loading"} title={loadingTitle} />
      <ErrorDialog
        message={translateNetworkError(state.error, intl)}
        onOk={handleErrorDialogOk}
        open={state.status === "error"}
        title={errorTitle}
      />
    </>
  );
});
