import {getExtendedCustomerSettings} from "@co-frontend-libs/redux";
import {Tab, Tabs} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {useQueryParameterState} from "app-utils";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ExportContent} from "./export/export-content";
import {ImportContent} from "./import/import-content";

interface CsvImportAndExportPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type TabValue = "export" | "import";

export const CsvImportAndExportPage = React.memo(function CsvImportAndExportPage(
  props: CsvImportAndExportPageProps,
): React.JSX.Element {
  const {onMenuButton} = props;
  const intl = useIntl();

  const {
    csv: {canExport, canImport},
  } = useSelector(getExtendedCustomerSettings);

  const showTabs = canExport && canImport;

  const [activeTab, setActiveTab] = useQueryParameterState<TabValue>(
    "tab",
    canImport ? "import" : "export",
  );

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: TabValue) => {
      setActiveTab(value);
    },
    [setActiveTab],
  );

  const tabs = showTabs ? (
    <Tabs onChange={handleTabChange} scrollButtons="auto" value={activeTab} variant="scrollable">
      <Tab label={intl.formatMessage({defaultMessage: "Import"})} value="import" />
      <Tab label={intl.formatMessage({defaultMessage: "Eksport"})} value="export" />
    </Tabs>
  ) : undefined;

  const title =
    canExport && canImport
      ? intl.formatMessage({defaultMessage: "Import og eksport"})
      : canExport
        ? intl.formatMessage({defaultMessage: "Eksport"})
        : intl.formatMessage({defaultMessage: "Import"});

  let content: React.JSX.Element | undefined;
  if (activeTab === "import" && canImport) {
    content = <ImportContent />;
  } else if (activeTab === "export" && canExport) {
    content = <ExportContent />;
  }

  return (
    <PageLayout
      tabs={tabs}
      toolbar={<MenuToolbar onMenuButton={onMenuButton} title={title} />}
      withPadding
    >
      {content}
    </PageLayout>
  );
});
