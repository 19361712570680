import {AccomodationAllowance as AccommodationAllowance} from "@co-common-libs/resources";
import {ListItemIcon, MenuItem, Typography} from "@material-ui/core";
import HotelIcon from "mdi-react/HotelIcon";
import React, {useCallback} from "react";

interface AccommodationMenuItemProps {
  accommodationAllowanceLabel: string;
  instance: AccommodationAllowance;
  onClick: (data: {instance: AccommodationAllowance; label: string}) => void;
}

export function AccommodationMenuItem(props: AccommodationMenuItemProps): React.JSX.Element {
  const {accommodationAllowanceLabel, instance, onClick} = props;

  const handleClick = useCallback(() => {
    onClick({instance, label: accommodationAllowanceLabel});
  }, [accommodationAllowanceLabel, instance, onClick]);
  return (
    <MenuItem key={instance.url} onClick={handleClick}>
      <ListItemIcon>
        <HotelIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="inherit">{accommodationAllowanceLabel}</Typography>
    </MenuItem>
  );
}
