import {ErrorDialog, SpinnerDialog} from "@co-frontend-libs/components";
import {getSettingsArray} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  translateNetworkError,
  useCallWithFalse,
  useCallWithTrue,
} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import {DRAWER_WIDTH, FloatingActionButtonData, FloatingActionButtons} from "app-components";
import {globalConfig} from "frontend-global-config";
import {reject} from "lodash";
import CloudUploadIcon from "mdi-react/CloudUploadIcon";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import ReactCrop, {Crop, PercentCrop, PixelCrop} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {canvasPreview} from "./canvas-preview";
import {UploadImageDialog} from "./upload-image-dialog";

const LOGO_MAX_HEIGHT = 96;
const PREVIEW_AREA_HEIGHT = 96;

const noCrop: PercentCrop = {
  height: 100,
  unit: "%",
  width: 100,
  x: 0,
  y: 0,
};

export const LogoTab = React.memo(function LogoTab(): React.JSX.Element {
  const [settings] = useSelector(getSettingsArray);

  const [imgSrc, setImgSrc] = useState<string>(globalConfig.logoURL);

  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const setImageUploadDialogOpenTrue = useCallWithTrue(setImageUploadDialogOpen);
  const setImageUploadDialogOpenFalse = useCallWithFalse(setImageUploadDialogOpen);

  const [crop, setCrop] = useState<Crop>(noCrop);
  const [spinnerDialogOpen, setSpinnerDialogOpen] = useState(false);

  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleCompletedCrop = useCallback((completedCrop: PixelCrop) => {
    if (imgRef.current && previewCanvasRef.current) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
    }
  }, []);

  const handleImageLoad = useCallback(() => {
    if (imgRef.current && previewCanvasRef.current) {
      canvasPreview(imgRef.current, previewCanvasRef.current);
    }
    setCrop(noCrop);
  }, []);

  const imageStyle: React.CSSProperties = {
    alignSelf: "flex-end",
    bottom: 0,
    display: "block",
    height: "auto",
    maxHeight: LOGO_MAX_HEIGHT,
    maxWidth: "100%",
    position: "absolute",
    width: "auto",
  };
  const [alignment, setAlignment] = useState<"CENTER" | "LEFT" | "RIGHT">(
    settings.menuLogoAlignment,
  );

  const handleAlignmentChange = useCallback(
    (event: React.ChangeEvent<{value: unknown}>) =>
      setAlignment(event.target.value as "CENTER" | "LEFT" | "RIGHT"),
    [setAlignment],
  );

  // menuLogoAlignment is not correctly set on initial render
  useEffect(() => {
    if (alignment !== settings.menuLogoAlignment) {
      setAlignment(settings.menuLogoAlignment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.menuLogoAlignment]);

  const handleImageUploadDialogOn = useCallback((uploadedImageUrl: string) => {
    setImgSrc(uploadedImageUrl);
    setImageUploadDialogOpen(false);
  }, []);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const intl = useIntl();

  const handleErrorDialogOk = useCallback(() => setErrorMessage(""), []);

  const {baseURL} = globalConfig.resources;

  const getImageBlob = (image: HTMLCanvasElement): Promise<Blob> => {
    return new Promise((resolve) => {
      image.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Failed to create Blob"));
        }
      });
    });
  };

  const handleSaveLogo = useCallback(async () => {
    if (previewCanvasRef.current) {
      const formData = new FormData();
      formData.append("logo", await getImageBlob(previewCanvasRef.current));
      formData.append("alignment", alignment);

      const url = `${baseURL}logo_upload/`;

      try {
        setSpinnerDialogOpen(true);
        await jsonFetch(url, "POST", formData);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } catch (error) {
        setErrorMessage(translateNetworkError(error, intl));
      } finally {
        setSpinnerDialogOpen(false);
      }
    }
  }, [baseURL, alignment, intl]);

  const buttonData = useMemo((): FloatingActionButtonData[] => {
    return [
      {
        buttonIcon: <CloudUploadIcon />,
        name: "logo-upload",
        onClick: setImageUploadDialogOpenTrue,
        tooltipTitle: intl.formatMessage({defaultMessage: "Upload nyt logo"}),
      },
    ];
  }, [intl, setImageUploadDialogOpenTrue]);

  return (
    <Grid container direction="row" spacing={2} wrap="wrap">
      <Grid item lg={6} xs={12}>
        <Card>
          <FloatingActionButtons buttons={buttonData} name="Upload" variant="component" />
          <CardHeader title={intl.formatMessage({defaultMessage: "Logo"})} />
          <CardContent>
            {imgSrc ? (
              <ReactCrop
                crop={crop}
                onChange={setCrop}
                onComplete={handleCompletedCrop}
                ruleOfThirds
              >
                <img onLoad={handleImageLoad} ref={imgRef} src={imgSrc} />
              </ReactCrop>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Card>
          <CardHeader title={intl.formatMessage({defaultMessage: "Forhåndsvisning"})} />
          <CardContent>
            <FormControl
              style={{
                display: "block",
                marginBottom: "1em",
                marginLeft: "auto",
                marginRight: "auto",
                width: DRAWER_WIDTH,
              }}
            >
              <InputLabel htmlFor="alignment">
                <FormattedMessage defaultMessage="Horisontal justering" />
              </InputLabel>
              <Select
                fullWidth
                inputProps={{
                  id: "alignment",
                  name: "alignment",
                }}
                onChange={handleAlignmentChange}
                value={alignment}
              >
                <MenuItem value="LEFT">
                  <FormattedMessage defaultMessage="Venstre" />
                </MenuItem>
                <MenuItem value="CENTER">
                  <FormattedMessage defaultMessage="Centreret" />
                </MenuItem>
                <MenuItem value="RIGHT">
                  <FormattedMessage defaultMessage="Højre" />
                </MenuItem>
              </Select>
            </FormControl>
            <Paper
              elevation={20}
              square
              style={{
                height: PREVIEW_AREA_HEIGHT,
                marginBottom: "1em",
                marginLeft: "auto",
                marginRight: "auto",
                width: DRAWER_WIDTH,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent:
                    alignment === "CENTER"
                      ? "center"
                      : alignment === "LEFT"
                        ? "flex-start"
                        : "flex-end",
                  marginTop: "auto",
                  minHeight: 96,
                  position: "relative",
                }}
              >
                <canvas ref={previewCanvasRef} style={imageStyle} />
              </div>
            </Paper>
            <Button
              color="primary"
              onClick={handleSaveLogo}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              variant="contained"
            >
              <FormattedMessage defaultMessage="Brug som logo" />
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <UploadImageDialog
        onClose={setImageUploadDialogOpenFalse}
        onOk={handleImageUploadDialogOn}
        open={imageUploadDialogOpen}
      />
      <SpinnerDialog
        open={spinnerDialogOpen}
        title={intl.formatMessage({defaultMessage: "Uploader logo"})}
      />
      <ErrorDialog
        message={errorMessage}
        onOk={handleErrorDialogOk}
        open={!!errorMessage}
        title={intl.formatMessage({defaultMessage: "Upload af logo fejlede"})}
      />
    </Grid>
  );
});
