import {Machine, MachineUrl, PriceGroup, PriceGroupUrl, urlToId} from "@co-common-libs/resources";
import {mapSet, mapUpdate, notNull, notUndefined, setAdd} from "@co-common-libs/utils";
import {MachineDialog, PriceGroupDialog} from "@co-frontend-libs/components";
import {getCustomerSettings, getMachineLookup, getPriceGroupLookup} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, useTheme} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {DeletableChip} from "../deletable-chip";
import {FilterOptions} from "../types";

const messages = defineMessages({
  allVariants: {
    defaultMessage: "Alle",
    id: "transport-report.label.all-machine-variants",
  },
});

function getPotentialPriceGroupsForMachine(
  options: FilterOptions["machines"],
  machineURL: MachineUrl,
): readonly (PriceGroupUrl | null)[] {
  return options.find((entry) => entry.machine === machineURL)?.priceGroups || [];
}

interface MachineFilteringProps {
  onSelectedMachinesPriceGroupsChange: (
    newState: ReadonlyMap<MachineUrl, ReadonlySet<PriceGroupUrl>>,
  ) => void;
  options: FilterOptions["machines"];
  selectedMachinesPriceGroups: ReadonlyMap<MachineUrl, ReadonlySet<PriceGroupUrl>>;
}

export function MachineFiltering(props: MachineFilteringProps): React.JSX.Element {
  const {onSelectedMachinesPriceGroupsChange, options, selectedMachinesPriceGroups} = props;

  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const machineLookup = useSelector(getMachineLookup);
  const customerSettings = useSelector(getCustomerSettings);

  const [machineDialogOpen, setMachineDialogOpen] = useState(false);
  const setMachineDialogOpenTrue = useCallWithTrue(setMachineDialogOpen);
  const setMachineDialogOpenFalse = useCallWithFalse(setMachineDialogOpen);

  const [priceGroupDialogOpenForMachine, setPriceGroupDialogOpenForMachine] =
    useState<MachineUrl | null>(null);
  const handleMachineDialogOk = useCallback(
    (url: MachineUrl) => {
      setMachineDialogOpen(false);
      const potentialPriceGroups = getPotentialPriceGroupsForMachine(options, url);
      if (potentialPriceGroups.length <= 1) {
        onSelectedMachinesPriceGroupsChange(
          mapSet(selectedMachinesPriceGroups, url, new Set(potentialPriceGroups.filter(notNull))),
        );
      } else {
        setPriceGroupDialogOpenForMachine(url);
      }
    },
    [options, selectedMachinesPriceGroups, onSelectedMachinesPriceGroupsChange],
  );
  const handlePriceGroupsForMachineDialogCancel = useCallback(() => {
    setPriceGroupDialogOpenForMachine(null);
  }, []);
  const handlePriceGroupsForMachineDialogOk = useCallback(
    (url: PriceGroupUrl | null) => {
      if (priceGroupDialogOpenForMachine) {
        setPriceGroupDialogOpenForMachine(null);
        if (url) {
          onSelectedMachinesPriceGroupsChange(
            mapUpdate(selectedMachinesPriceGroups, priceGroupDialogOpenForMachine, (urls) =>
              setAdd(urls, url),
            ),
          );
        } else {
          onSelectedMachinesPriceGroupsChange(
            mapSet(selectedMachinesPriceGroups, priceGroupDialogOpenForMachine, new Set()),
          );
        }
      }
    },
    [
      priceGroupDialogOpenForMachine,
      selectedMachinesPriceGroups,
      onSelectedMachinesPriceGroupsChange,
    ],
  );
  const handlePriceGroupsForMachineDialogNone = useCallback(() => {
    handlePriceGroupsForMachineDialogOk(null);
  }, [handlePriceGroupsForMachineDialogOk]);
  const potentialMachines = useMemo(
    () => options.map((entry) => machineLookup(entry.machine)).filter(notUndefined),
    [options, machineLookup],
  );
  const potentialPriceGroupsForMachine = useMemo(() => {
    if (priceGroupDialogOpenForMachine) {
      return getPotentialPriceGroupsForMachine(options, priceGroupDialogOpenForMachine)
        .filter(notNull)
        .map(priceGroupLookup)
        .filter(notUndefined);
    } else {
      return [];
    }
  }, [options, priceGroupDialogOpenForMachine, priceGroupLookup]);

  const handleDeleteMachinePriceGroup = useCallback(
    (machinePriceGroupIdPair: string): void => {
      const [machineId, priceGroupId] = machinePriceGroupIdPair.split(",");
      const machineURL = instanceURL("machine", machineId);
      const priceGroupURL = instanceURL("priceGroup", priceGroupId);
      const newSelectedMachinesPriceGroups = new Map(selectedMachinesPriceGroups);
      const oldMachinePriceGroups = newSelectedMachinesPriceGroups.get(machineURL);
      if (oldMachinePriceGroups) {
        const newMachinePriceGroups = new Set(oldMachinePriceGroups);
        newMachinePriceGroups.delete(priceGroupURL);
        if (newMachinePriceGroups.size) {
          newSelectedMachinesPriceGroups.set(machineURL, newMachinePriceGroups);
        } else {
          newSelectedMachinesPriceGroups.delete(machineURL);
        }
      }
      onSelectedMachinesPriceGroupsChange(newSelectedMachinesPriceGroups);
    },
    [selectedMachinesPriceGroups, onSelectedMachinesPriceGroupsChange],
  );

  const handleDeleteMachine = useCallback(
    (machineId: string): void => {
      const newSelectedMachinesPriceGroups = new Map(selectedMachinesPriceGroups);
      newSelectedMachinesPriceGroups.delete(instanceURL("machine", machineId));
      onSelectedMachinesPriceGroupsChange(newSelectedMachinesPriceGroups);
    },
    [selectedMachinesPriceGroups, onSelectedMachinesPriceGroupsChange],
  );

  const {formatMessage} = useIntl();

  const theme = useTheme();

  return (
    <>
      <div>
        <h4>
          {customerSettings.machineLabelVariant === "MACHINE" ? (
            <FormattedMessage defaultMessage="Maskiner" />
          ) : (
            <FormattedMessage defaultMessage="Køretøjer" />
          )}
        </h4>
        <Button color="primary" onClick={setMachineDialogOpenTrue} variant="contained">
          {customerSettings.machineLabelVariant === "MACHINE" ? (
            <FormattedMessage defaultMessage="Tilføj maskine" />
          ) : (
            <FormattedMessage defaultMessage="Tilføj køretøj" />
          )}
        </Button>
        <div>
          {_.sortBy(
            Array.from(selectedMachinesPriceGroups.entries())
              .map(
                ([machineURL, priceGroupURLs]):
                  | {machine: Machine; priceGroups: PriceGroup[]}
                  | undefined => {
                  const machine = machineLookup(machineURL);
                  if (!machine) {
                    return undefined;
                  }
                  const priceGroups = _.sortBy(
                    Array.from(priceGroupURLs).map(priceGroupLookup).filter(notUndefined),
                    (priceGroup) => priceGroup.name,
                  );
                  return {machine, priceGroups};
                },
              )
              .filter(notUndefined),
            ({machine}) => machine.name,
          ).map(({machine, priceGroups}) => {
            if (priceGroups.length) {
              return priceGroups.map((priceGroup) => {
                const key = `${urlToId(machine.url)},${urlToId(priceGroup.url)}`;
                return (
                  <span
                    key={key}
                    style={{
                      marginRight: theme.spacing(0.5),
                    }}
                  >
                    <DeletableChip
                      deletionId={key}
                      label={`${machine.name} (${priceGroup.name})`}
                      onDelete={handleDeleteMachinePriceGroup}
                    />
                  </span>
                );
              });
            } else {
              const key = urlToId(machine.url);
              return (
                <span
                  key={key}
                  style={{
                    marginRight: theme.spacing(0.5),
                  }}
                >
                  <DeletableChip
                    deletionId={key}
                    label={machine.name}
                    onDelete={handleDeleteMachine}
                  />
                </span>
              );
            }
          })}
        </div>
      </div>
      <MachineDialog
        machineArray={potentialMachines}
        machineLabelVariant={customerSettings.machineLabelVariant}
        onCancel={setMachineDialogOpenFalse}
        onOk={handleMachineDialogOk}
        open={machineDialogOpen}
      />
      <PriceGroupDialog
        noneLabel={formatMessage(messages.allVariants)}
        onCancel={handlePriceGroupsForMachineDialogCancel}
        onNone={handlePriceGroupsForMachineDialogNone}
        onOk={handlePriceGroupsForMachineDialogOk}
        open={!!priceGroupDialogOpenForMachine}
        priceGroupArray={potentialPriceGroupsForMachine}
      />
    </>
  );
}
