import {CustomerFile, CustomerUrl} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {actions, AppState, getCustomerFileArray} from "@co-frontend-libs/redux";
import {Card, CardHeader} from "@material-ui/core";
import {useFileUploader} from "app-utils";
import CollectionsIcon from "mdi-react/CollectionsIcon";
import FilePdfBoxIcon from "mdi-react/FilePdfBoxIcon";
import PhotoCameraIcon from "mdi-react/PhotoCameraIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo} from "react";
import {defineMessages, FormattedMessage, IntlShape, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {DragAndDropUploadOverlay} from "./drag-and-drop-upload-overlay";
import {FileTable} from "./file-table";
import {appPhotoHelper, SourceType, useFileInputChangeHandler} from "./files";
import {type FloatingActionButtonData, FloatingActionButtons} from "./floating-action-buttons";

const messages = defineMessages({
  invalidFile: {
    defaultMessage: "{name} er ikke en gyldig pdf eller billedfil",
  },
});
interface UploadButtonsProps {
  intl: IntlShape;
  onFilesAccepted: (files: File[]) => void;
  onFilesRejected: (file: File) => void;
}

function UploadButtons(props: UploadButtonsProps): React.JSX.Element {
  const {intl, onFilesAccepted, onFilesRejected} = props;

  const handleFileInputChange = useFileInputChangeHandler(
    onFilesAccepted,
    onFilesRejected,
    "image/jpeg,image/png,application/pdf",
  );

  const isMobile = !!window.cordova;

  const handleAppCameraButton = useCallback((): void => {
    appPhotoHelper(SourceType.CAMERA, onFilesAccepted);
  }, [onFilesAccepted]);

  const buttonData = useMemo((): FloatingActionButtonData[] => {
    if (isMobile) {
      return [
        {
          buttonIcon: <PhotoCameraIcon />,
          name: "camera-button",
          onClick: handleAppCameraButton,
          tooltipTitle: intl.formatMessage({defaultMessage: "Kamera"}),
        },
        {
          accept: "application/pdf",
          buttonIcon: <FilePdfBoxIcon />,
          id: "customer-pdf-input",
          name: "pdf-button",
          onChange: handleFileInputChange,
          tooltipTitle: intl.formatMessage({defaultMessage: "PDF"}),
        },
        {
          accept: "image/jpeg,image/png",
          buttonIcon: <CollectionsIcon />,
          id: "customer-photos-input",
          name: "photo-button",
          onChange: handleFileInputChange,
          tooltipTitle: intl.formatMessage({defaultMessage: "Foto"}),
        },
      ];
    } else {
      return [
        {
          accept: "image/jpeg,application/pdf",
          buttonIcon: <PlusIcon />,
          id: "customer-file-input",
          name: "file-button",
          onChange: handleFileInputChange,
          tooltipTitle: intl.formatMessage({defaultMessage: "Filer"}),
        },
      ];
    }
  }, [handleAppCameraButton, handleFileInputChange, intl, isMobile]);

  const speedDial = (
    <FloatingActionButtons buttons={buttonData} name="customer-file-list" variant="component" />
  );

  return speedDial;
}

function getFilteredCustomerFileArray(
  customerURL: string,
): (state: AppState) => readonly CustomerFile[] {
  const filterSort = (customerFileArray: readonly CustomerFile[]): readonly CustomerFile[] =>
    customerFileArray
      .filter((customerFile) => customerFile.customer === customerURL)
      .sort((a, b) => caseAccentInsensitiveCollator.compare(a.filename, b.filename));

  return createSelector(getCustomerFileArray, filterSort);
}

interface CustomerFileListCardProps {
  customerURL: CustomerUrl;
  editable?: boolean;
  title?: string;
}

export const CustomerFileListCard = React.memo(function CustomerFileListCard(
  props: CustomerFileListCardProps,
): React.JSX.Element {
  const {customerURL, editable, title} = props;

  const getSelectedCustomerFiles = useMemo(
    () => getFilteredCustomerFileArray(customerURL),
    [customerURL],
  );
  const files = useSelector(getSelectedCustomerFiles);

  const mutableFiles = useMemo(() => Array.from(files), [files]);

  const content = (
    <FileTable currentUserCanManageFiles={!!editable} editMode={!!editable} files={mutableFiles} />
  );

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleFilesRejected = useCallback(
    (rejectedFile: File): void => {
      const message = intl.formatMessage(messages.invalidFile, {
        name: rejectedFile.name,
      });
      window.setTimeout(() => {
        dispatch(actions.setMessage(message, new Date()));
      }, 0);
    },
    [dispatch, intl],
  );

  const uploader = useFileUploader<CustomerFile>("customerFile", "data");

  const handleFilesAccepted = useCallback(
    (acceptedFiles: File[]): void => {
      const commonExtraData = {
        customer: customerURL,
        description: "",
      };
      acceptedFiles.forEach((file) => {
        const name = file.name.replace(/\.[^.]*$/, "");
        const extraData = {name, ...commonExtraData};
        uploader(file, extraData);
      });
    },
    [customerURL, uploader],
  );

  const handleFiles = useCallback(
    (recievedFiles: File[]): void => {
      const invalidFile = recievedFiles.find(
        (file) => !["application/pdf", "image/jpeg", "image/png"].includes(file.type),
      );

      if (invalidFile) {
        handleFilesRejected(invalidFile);
      } else {
        handleFilesAccepted(recievedFiles);
      }
    },
    [handleFilesAccepted, handleFilesRejected],
  );

  if (editable) {
    return (
      <Card style={{overflow: "visible"}}>
        <DragAndDropUploadOverlay onFiles={handleFiles}>
          <UploadButtons
            intl={intl}
            onFilesAccepted={handleFilesAccepted}
            onFilesRejected={handleFilesRejected}
          />
          <CardHeader
            title={
              title ?? (
                <FormattedMessage
                  defaultMessage="Filer, kunde"
                  id="customer-file-list-card.card-title.file-list"
                />
              )
            }
          />
          {content}
        </DragAndDropUploadOverlay>
      </Card>
    );
  } else {
    return (
      <Card>
        <CardHeader
          title={
            title ?? (
              <FormattedMessage
                defaultMessage="Filer, kunde"
                id="customer-file-list-card.card-title.file-list"
              />
            )
          }
        />
        {content}
      </Card>
    );
  }
});
