import {UserProfile} from "@co-common-libs/resources";
import {DecimalField} from "@co-frontend-libs/components";
import {Card, CardContent} from "@material-ui/core";
import {useFieldUpdater} from "app-utils";
import React from "react";
import {useIntl} from "react-intl";

export const CostCard = React.memo(function CostCard({
  userProfile,
}: {
  userProfile: UserProfile;
}): React.JSX.Element {
  const intl = useIntl();

  return (
    <Card>
      <CardContent>
        <DecimalField
          decimalPlaces={2}
          fullWidth
          label={intl.formatMessage({
            defaultMessage: "Gennemsnitlig omkostning pr. time",
          })}
          margin="dense"
          maxDigits={6}
          onChange={useFieldUpdater<UserProfile, number | null>(
            userProfile.url,
            "averageHourlyCost",
          )}
          value={userProfile.averageHourlyCost}
        />
      </CardContent>
    </Card>
  );
});
