import {LocationUrl} from "@co-common-libs/resources";
import {common, grey, lightBlue} from "@material-ui/core/colors";
import bowser from "bowser";
import _ from "lodash";
import React, {useEffect, useRef, useState} from "react";
import {LocationGeoJsonWithUrl, LocationWithGeoJson} from "./types";

const DEBOUNCE_CLICK_MS = 500;

function updateMap(
  map: google.maps.Map,
  polygonData: google.maps.Data,
  instances: readonly LocationWithGeoJson[],
  readonlySet: ReadonlySet<LocationUrl> | undefined,
  selected: ReadonlySet<string> | undefined,
  displayPolygonsZoomThreshold: number,
): void {
  const zoom = map.getZoom() || 0;

  if (zoom > displayPolygonsZoomThreshold) {
    const bounds = map.getBounds();
    const currentInstanceUrls = new Map<LocationUrl, LocationWithGeoJson>(
      instances.map((instance) => [instance.url, instance]),
    );

    polygonData.forEach((feature) => {
      const url = feature.getId() as LocationUrl;
      const instance = currentInstanceUrls.get(url);
      if (!instance || !bounds) {
        polygonData.remove(feature);
      } else {
        const [west, south, east, north] = instance.geojson.geometry.bbox;
        if (!bounds.intersects({east, north, south, west})) {
          polygonData.remove(feature);
        } else {
          const lastChanged = feature.getProperty("lastChanged");
          if (lastChanged !== instance.lastChanged) {
            polygonData.remove(feature);
          }
        }
      }
    });

    const toAdd: LocationGeoJsonWithUrl[] = [];

    for (const instance of instances) {
      const currentData = polygonData.getFeatureById(instance.url);
      if (!currentData && bounds) {
        const {fieldCrop, geojson, lastChanged, url} = instance;
        const [west, south, east, north] = instance.geojson.geometry.bbox;
        if (bounds.intersects({east, north, south, west})) {
          // data in properties is available to styling function
          // and click handler
          toAdd.push({
            ...geojson,
            properties: {
              crop: fieldCrop,
              disabled: !!readonlySet?.has(url),
              isSelected: !!selected?.has(url),
              lastChanged,
              url,
            },
          });
        }
      }
    }

    if (toAdd.length) {
      polygonData.addGeoJson({features: toAdd, type: "FeatureCollection"}, {idPropertyName: "url"});
    }
  } else {
    polygonData.forEach((feature) => {
      polygonData.remove(feature);
    });
  }
}

function updateFeatureBooleanProperties(
  propertyName: string,
  polygonData: google.maps.Data,
  newTrueSet: ReadonlySet<LocationUrl> | undefined,
): undefined {
  polygonData.forEach((feature) => {
    const url = feature.getId() as LocationUrl;
    const value = feature.getProperty(propertyName);
    const desiredValue = !!newTrueSet?.has(url);
    if (value !== desiredValue) {
      feature.setProperty(propertyName, desiredValue);
    }
  });
}

interface FieldsGeoJsonProps {
  cropColorMap: ReadonlyMap<string, string>;
  displayPolygonsZoomThreshold: number;
  instances: readonly LocationWithGeoJson[];
  map: google.maps.Map;
  onSelect: ((url: LocationUrl, selected: boolean) => void) | undefined;
  readonlySet: ReadonlySet<LocationUrl> | undefined;
  selected: ReadonlySet<LocationUrl> | undefined;
}

/**
 * NOTE: Will update map in case of changes to `lastChanged` on existing
 * location instances -- local GeoJSON changes without changes to
 * `lastChanged` will not take effect.
 */
export function FieldsGeoJson(props: FieldsGeoJsonProps): React.JSX.Element {
  const {
    cropColorMap,
    displayPolygonsZoomThreshold,
    instances,
    map,
    onSelect,
    readonlySet,
    selected,
  } = props;

  // state rather than ref to use initializer function
  const [polygonData] = useState(() => new google.maps.Data({map}));

  useEffect(() => {
    polygonData.setMap(map);
    return () => {
      polygonData.setMap(null);
    };
  }, [map, polygonData]);

  useEffect(() => {
    polygonData.setStyle((feature: google.maps.Data.Feature): google.maps.Data.StyleOptions => {
      const crop = feature.getProperty("crop") as string;
      const isSelected = feature.getProperty("isSelected") as boolean;
      const disabled = feature.getProperty("disabled") as boolean;

      const color = isSelected
        ? disabled
          ? lightBlue[700]
          : lightBlue[500]
        : cropColorMap.get(crop) || grey[500];

      return {
        fillColor: color,
        strokeColor: isSelected ? common.black : color,
        zIndex: isSelected ? 1 : 0,
      };
    });
  }, [cropColorMap, polygonData]);

  useEffect(() => {
    if (onSelect) {
      // mitigates iOS issue where onClick sometimes fire twice with ~450ms
      // delay between first and second event.
      const maybeDebouncedOnSelect = bowser.ios
        ? _.debounce(onSelect, DEBOUNCE_CLICK_MS, {
            leading: true,
            trailing: false,
          })
        : onSelect;
      const listener = polygonData.addListener("click", (event: google.maps.Data.MouseEvent) => {
        const {feature} = event;
        const disabled = feature.getProperty("disabled") as boolean;
        if (disabled) {
          return;
        }
        const url = feature.getProperty("url") as LocationUrl;
        const isSelected = feature.getProperty("isSelected") as boolean;
        maybeDebouncedOnSelect(url, !isSelected);
      });
      return () => {
        listener.remove();
      };
    } else {
      return undefined;
    }
  }, [onSelect, polygonData]);

  // update after changes to `instances` (search/filtering)
  useEffect(() => {
    const timeout = setTimeout(() => {
      updateMap(map, polygonData, instances, readonlySet, selected, displayPolygonsZoomThreshold);
    });
    return () => {
      clearTimeout(timeout);
    };
    // changes to readonlySet and selected handled separately
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayPolygonsZoomThreshold, instances, map, polygonData]);

  // HACK: make current state available to map event handler without
  // rebinding event handler on every change
  const instancesRef = useRef(instances);
  instancesRef.current = instances;

  // update after zoom/pan (i.e. on "idle" event)
  useEffect(() => {
    const listener = map.addListener("idle", () => {
      updateMap(
        map,
        polygonData,
        instancesRef.current,
        readonlySet,
        selected,
        displayPolygonsZoomThreshold,
      );
    });
    return () => {
      listener.remove();
    };
    // changes to readonlySet and selected handled separately
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayPolygonsZoomThreshold, map, polygonData]);

  // changing properties will re-run styling function for the feature
  useEffect(() => {
    updateFeatureBooleanProperties("isSelected", polygonData, selected);
  }, [polygonData, selected]);

  // changing properties will re-run styling function for the feature
  useEffect(() => {
    updateFeatureBooleanProperties("disabled", polygonData, readonlySet);
  }, [polygonData, readonlySet]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
