import {emptyLocation, Location} from "@co-common-libs/resources";
import {instanceURL} from "frontend-global-config";
import {v4 as uuid} from "uuid";

export function createLocation(instance: Partial<Omit<Location, "id" | "url">>): Location {
  const id = uuid();
  const url = instanceURL("location", id);

  return {
    id,
    url,
    ...emptyLocation,
    ...instance,
  };
}
