import {dateFromString} from "@co-common-libs/utils";
import React from "react";
import {FormattedMessage} from "react-intl";
import {JournalDataEntry, StorageJournalTable} from "./storage-journal-table";
import {
  JournalSummarizedDataByProductEntry,
  StorageSummarizedByProductJournalTable,
} from "./storage-summarized-by-product";
import {
  JournalSummarizedDataEntry,
  StorageSummarizedByProductPerLocationJournalTable,
} from "./storage-summarized-by-product-per-location";

export {StorageJournalTable} from "./storage-journal-table";

export type {JournalDataEntry} from "./storage-journal-table";
export type {JournalSummarizedDataByProductEntry} from "./storage-summarized-by-product";
export type {JournalSummarizedDataEntry} from "./storage-summarized-by-product-per-location";

export enum ContentSelection {
  EVENTS = "events",
  EVERYTHING = "everything",
  SUMMARY_BY_PRODUCT = "summaryByProduct",
  SUMMARY_PRODUCT_PER_LOCATION = "summaryProductPerLocation",
}

interface StorageJournalContentProps {
  contentSelection: ContentSelection;
  data: readonly JournalDataEntry[];
  fromDate: string;
  invoiceCustomerNames: readonly string[];
  locationCustomerNames: readonly string[];
  locationName: string;
  machineWithPriceGroupLabels: readonly string[];
  productLabels: readonly string[];
  showCustomerColumn: boolean;
  showFiltering: boolean;
  showStatusColumn: boolean;
  summarizedByProductData: readonly JournalSummarizedDataByProductEntry[];
  summarizedData: readonly JournalSummarizedDataEntry[];
  toDate: string;
  workTypeWithPriceGroupLabels: readonly string[];
}

export function StorageJournalContent(props: StorageJournalContentProps): React.JSX.Element {
  const {
    contentSelection,
    data,
    fromDate,
    invoiceCustomerNames,
    locationCustomerNames,
    locationName,
    machineWithPriceGroupLabels,
    productLabels,
    showCustomerColumn,
    showFiltering,
    showStatusColumn,
    summarizedByProductData,
    summarizedData,
    toDate,
    workTypeWithPriceGroupLabels,
  } = props;
  return (
    <div
      style={{
        fontFamily: "Linux Biolinum O, sans-serif",
        fontSize: "11pt",
        width: "100%",
      }}
    >
      <h3>
        <FormattedMessage defaultMessage="Sted: {locationName}" values={{locationName}} />
      </h3>
      <div>
        <FormattedMessage
          defaultMessage="Periode: {fromDate, date, short} - {toDate, date, short}"
          values={{
            fromDate: dateFromString(fromDate),
            toDate: dateFromString(toDate),
          }}
        />
      </div>
      {showFiltering && (
        <>
          {!!workTypeWithPriceGroupLabels.length && (
            <div>
              <FormattedMessage
                defaultMessage="{
                    count, plural,
                    one {Område: {workTypes}}
                    other {Områder: {workTypes}}
                  }"
                values={{
                  count: workTypeWithPriceGroupLabels.length,
                  workTypes: workTypeWithPriceGroupLabels.join(", "),
                }}
              />
            </div>
          )}
          {!!productLabels.length && (
            <div>
              <FormattedMessage
                defaultMessage="{
                  count, plural,
                  one {Produkt: {products}}
                  other {Produkter: {products}}
                }"
                values={{count: productLabels.length, products: productLabels.join(", ")}}
              />
            </div>
          )}
          {!!machineWithPriceGroupLabels.length && (
            <div>
              <FormattedMessage
                defaultMessage="{
                  count, plural,
                  one {Maskine: {machines}}
                  other {Maskiner: {machines}}
                }"
                values={{
                  count: machineWithPriceGroupLabels.length,
                  machines: machineWithPriceGroupLabels.join(", "),
                }}
              />
            </div>
          )}
          {!!locationCustomerNames.length && (
            <div>
              <FormattedMessage
                defaultMessage="{
                  count, plural,
                  one {Afhentings-/leveringskunde: {customers}}
                  other {Afhentnings-/leveringskunder: {customers}}
                }"
                values={{
                  count: locationCustomerNames.length,
                  customers: locationCustomerNames.join(", "),
                }}
              />
            </div>
          )}
          {!!invoiceCustomerNames.length && (
            <div>
              <FormattedMessage
                defaultMessage="{
                  count, plural,
                  one {Fakturakunde: {customers}}
                  other {Fakturakunder: {customers}}
                }"
                values={{
                  count: invoiceCustomerNames.length,
                  customers: invoiceCustomerNames.join(", "),
                }}
              />
            </div>
          )}
        </>
      )}
      {contentSelection === ContentSelection.EVERYTHING ||
      contentSelection === ContentSelection.EVENTS ? (
        <StorageJournalTable
          data={data}
          showCustomerColumn={showCustomerColumn}
          showStatusColumn={showStatusColumn}
        />
      ) : null}
      {contentSelection === ContentSelection.EVERYTHING ||
      contentSelection === ContentSelection.SUMMARY_PRODUCT_PER_LOCATION ? (
        <div style={{paddingTop: 20}}>
          <FormattedMessage defaultMessage="Summeret pr. vare/sted" tagName="h3" />
          <StorageSummarizedByProductPerLocationJournalTable data={summarizedData} />
        </div>
      ) : null}
      {contentSelection === ContentSelection.EVERYTHING ||
      contentSelection === ContentSelection.SUMMARY_BY_PRODUCT ? (
        <div style={{paddingTop: 20}}>
          <FormattedMessage defaultMessage="Summeret pr. vare" tagName="h3" />
          <StorageSummarizedByProductJournalTable data={summarizedByProductData} />
        </div>
      ) : null}
    </div>
  );
}
