import {CustomerUrl, Journal, Location, LocationUrl} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {AppState, getCustomerSettings} from "@co-frontend-libs/redux";
import {alpha, createStyles, makeStyles, Theme} from "@material-ui/core";
import {createSelector} from "reselect";

export const getGoogleMapsApiKey: (state: AppState) => string = createSelector(
  getCustomerSettings,
  (customerSettings) => customerSettings.googleMapsAPIKey,
);

export function compareFields(a: Location, b: Location): number {
  return (
    caseAccentInsensitiveCollator.compare(a.fieldNumber || "", b.fieldNumber || "") ||
    caseAccentInsensitiveCollator.compare(a.fieldCrop || "", b.fieldCrop || "") ||
    (a.fieldAreaHa || 0) - (b.fieldAreaHa || 0) ||
    a.url.localeCompare(b.url)
  );
}

export function compareFieldsWithLastUsed(
  lastUsedLocations: ReadonlySet<string>,
  a: Location,
  b: Location,
): number {
  return (
    Number(lastUsedLocations.has(b.url)) - Number(lastUsedLocations.has(a.url)) ||
    compareFields(a, b)
  );
}

export function computeFilteredSortedFieldArray(
  fieldArray: readonly Location[],
  lastUsedLocations?: ReadonlySet<LocationUrl>,
): readonly Location[] {
  return fieldArray
    .filter((field) => field.active && !!field.geojson)
    .sort(
      lastUsedLocations?.size
        ? compareFieldsWithLastUsed.bind(null, lastUsedLocations)
        : compareFields,
    );
}

// styling mostly from
// https://material-ui.com/components/app-bar/#app-bar-with-a-primary-search-field

export const useFieldDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "fixed",
    },
    inputInput: {
      "&::placeholder": {
        color: "#fff",
        opacity: 0.87,
      },
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    inputRoot: {
      color: "inherit",
    },
    noPadding: {
      padding: 0,
    },
    search: {
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      marginRight: 0,
      position: "relative",
      width: "100%",
    },
    searchIcon: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "none",
      position: "absolute",
      width: theme.spacing(7),
    },
  }),
);

export const useFieldItemStyles = makeStyles((_theme: Theme) =>
  createStyles({
    overflowOne: {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 1,
    },
  }),
);

export const getLocationJournalMap = (
  journalArray: readonly Journal[],
  customerUrl: CustomerUrl | null,
): Map<LocationUrl, Journal[]> => {
  const locationJournalMap = new Map<LocationUrl, Journal[]>();
  const filteredJournalArray = customerUrl
    ? journalArray.filter((entry) => customerUrl === entry.customer)
    : journalArray;

  filteredJournalArray
    .filter((journalEntry) => journalEntry.reminder)
    .forEach((journalEntry) => {
      journalEntry.locations?.forEach((location) =>
        locationJournalMap.set(location, [
          ...(locationJournalMap.get(location) || []),
          journalEntry,
        ]),
      );
    });
  return locationJournalMap;
};
