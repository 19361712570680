import {Config, SettingID} from "@co-common-libs/config";
import {
  PatchOperation,
  PriceGroupUrl,
  SettingEntry,
  Timer,
  UserUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {
  DecimalField,
  SelectColor,
  SingleTextFieldDialog,
  WorkTypeDialog,
} from "@co-frontend-libs/components";
import {
  ConnectedInternalWorkTypeDialog,
  ConnectedPriceGroupDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getExternalTaskPrimaryWorkTypeArray,
  getPriceGroupLookup,
  getSettingsEntryLookupByIdentifier,
  getTimerArray,
  getTimerLookup,
  getWorkTypeLookup,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";
import {PageLayout} from "app-components";
import {useEventTargetCheckedUpdater} from "app-utils";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useMemo, useState} from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {PriceGroupHideTimers} from "./price-group-hide-timers";
import {TimerDepartmentsBlock} from "./timer-departments-block";
import {TimerIdentifierDialog} from "./timer-identifier-dialog";
import {TimerWorkTypeMachinePriceGroupBlock} from "./timer-work-type-machine-price-group-block";
import {WorkTypeHideTimers} from "./work-type-hide-timers";

const messages = defineMessages({
  active: {defaultMessage: "Aktiv", id: "timer-entry.label.active"},
  editLabel: {
    defaultMessage: "Redigér label",
    id: "timer-entry.label.editLabel",
  },
  editTimer: {
    defaultMessage: "Redigér timer",
    id: "timer-entry.header.edit-timer",
  },
  identifier: {
    defaultMessage: "ID",
    id: "timer-entry.label.identifier",
  },
  includeForExternalTask: {
    defaultMessage: "Synlig på alle eksterne områder",
    id: "timer-entry.label.includeForExternalTask",
  },
  includeForInternalTask: {
    defaultMessage: "Synlig på alle interne områder",
    id: "timer-entry.label.includeForInternalTask",
  },
  requiresNotes: {
    defaultMessage: "Vis påkrævet notefelt når der er målt tid",
    id: "timer-entry.label.requiresNotes",
  },
  selectExternalWorkType: {
    defaultMessage: "Vælg ekstern område",
    id: "timer-entry.label.selectExternalWorkType",
  },
  selectInternalWorkType: {
    defaultMessage: "Vælg intern område",
    id: "timer-entry.label.selectInternalWorkType",
  },
  selectPriceGroup: {
    defaultMessage: "Vælg variant",
    id: "timer-entry.label.selectPriceGroup",
  },
  transferTimerNotesToExternalSystem: {
    defaultMessage: "Send timer-noter til økonomisystem",
    id: "timer-entry.label.transferTimerNotesToExternalSystem",
  },
  visibility: {
    defaultMessage: "Synlighed",
    id: "timer-entry.headel.visibility",
  },
});

function isActive(obj: {active: boolean}): boolean {
  return obj.active;
}

const updateSettingIfChanges = (
  settingId: SettingID,
  newSettingsData: any,
  settingsEntryLookupByIdentifier: (identifier: SettingID) => Readonly<SettingEntry> | undefined,
  dispatch: Dispatch,
  currentUserURL: UserUrl,
): void => {
  const oldSetting = settingsEntryLookupByIdentifier(settingId);
  if (oldSetting && !_.isEqual(oldSetting.data, newSettingsData)) {
    dispatch(
      actions.update(oldSetting.url, [
        {member: "changedBy", value: currentUserURL},
        {member: "data", value: newSettingsData},
      ]),
    );
  }
};

export const updateTimerIdentifierInSettings = (
  newIdentifier: string,
  oldIdentifier: string,
  customerSettings: Config,
  settingsEntryLookupByIdentifier: (identifier: SettingID) => Readonly<SettingEntry> | undefined,
  dispatch: Dispatch,
  currentUserURL: UserUrl,
): void => {
  const newDepartmentExtraTimers: {
    [x: string]: string[];
  } = {};
  Object.entries(customerSettings.departmentExtraTimers).forEach(([department, identifiers]) => {
    newDepartmentExtraTimers[department] = identifiers.map((i) =>
      i === oldIdentifier ? newIdentifier : i,
    );
  });

  updateSettingIfChanges(
    "departmentExtraTimers",
    newDepartmentExtraTimers,
    settingsEntryLookupByIdentifier,
    dispatch,
    currentUserURL,
  );

  const newMachineExtraTimers: {
    [x: string]: string[];
  } = {};
  Object.entries(customerSettings.machineExtraTimers).forEach(([machine, identifiers]) => {
    newMachineExtraTimers[machine] = identifiers.map((i) =>
      i === oldIdentifier ? newIdentifier : i,
    );
  });

  updateSettingIfChanges(
    "machineExtraTimers",
    newMachineExtraTimers,
    settingsEntryLookupByIdentifier,
    dispatch,
    currentUserURL,
  );

  const newWorkTypeMachineExtraTimers: {
    [x: string]: {
      [x: string]: readonly string[];
    };
  } = {};
  Object.entries(customerSettings.workTypeMachineExtraTimers).forEach(([workType, machines]) => {
    newWorkTypeMachineExtraTimers[workType] = Object.fromEntries(
      Object.entries(machines).map(([oldKey, oldValue]) => [
        oldKey,
        oldValue.map((i) => (i === oldIdentifier ? newIdentifier : i)),
      ]),
    );
  });

  updateSettingIfChanges(
    "workTypeMachineExtraTimers",
    newWorkTypeMachineExtraTimers,
    settingsEntryLookupByIdentifier,
    dispatch,
    currentUserURL,
  );

  const newPriceGroupHideTimers: {
    [x: string]: string[];
  } = {};
  Object.entries(customerSettings.priceGroupHideTimers).forEach(([priceGorup, identifiers]) => {
    newPriceGroupHideTimers[priceGorup] = identifiers.map((i) =>
      i === oldIdentifier ? newIdentifier : i,
    );
  });

  updateSettingIfChanges(
    "priceGroupHideTimers",
    newPriceGroupHideTimers,
    settingsEntryLookupByIdentifier,
    dispatch,
    currentUserURL,
  );

  const newMachinePriceGroupExtraTimers: {
    [x: string]: {
      [x: string]: readonly string[];
    };
  } = {};
  Object.entries(customerSettings.machinePriceGroupExtraTimers).forEach(
    ([machine, priceGroups]) => {
      newMachinePriceGroupExtraTimers[machine] = Object.fromEntries(
        Object.entries(priceGroups).map(([oldKey, oldValue]) => [
          oldKey,
          oldValue.map((i) => (i === oldIdentifier ? newIdentifier : i)),
        ]),
      );
    },
  );

  updateSettingIfChanges(
    "machinePriceGroupExtraTimers",
    newMachinePriceGroupExtraTimers,
    settingsEntryLookupByIdentifier,
    dispatch,
    currentUserURL,
  );

  updateSettingIfChanges(
    "transportResponsibleEffectiveTimers",
    customerSettings.transportResponsibleEffectiveTimers.map((i) =>
      i === oldIdentifier ? newIdentifier : i,
    ),
    settingsEntryLookupByIdentifier,
    dispatch,
    currentUserURL,
  );

  const newWorkTypePriceGroupExtraTimers: {
    [x: string]: {
      [x: string]: readonly string[];
    };
  } = {};
  Object.entries(customerSettings.workTypePriceGroupExtraTimers).forEach(
    ([workType, priceGroups]) => {
      newWorkTypePriceGroupExtraTimers[workType] = Object.fromEntries(
        Object.entries(priceGroups).map(([oldKey, oldValue]) => [
          oldKey,
          oldValue.map((i) => (i === oldIdentifier ? newIdentifier : i)),
        ]),
      );
    },
  );

  updateSettingIfChanges(
    "workTypePriceGroupExtraTimers",
    newWorkTypePriceGroupExtraTimers,
    settingsEntryLookupByIdentifier,
    dispatch,
    currentUserURL,
  );

  const newWorkTypeExtraTimers: {
    [x: string]: string[];
  } = {};
  Object.entries(customerSettings.workTypeExtraTimers).forEach(([workType, identifiers]) => {
    newWorkTypeExtraTimers[workType] = identifiers.map((i) =>
      i === oldIdentifier ? newIdentifier : i,
    );
  });

  updateSettingIfChanges(
    "workTypeExtraTimers",
    newWorkTypeExtraTimers,
    settingsEntryLookupByIdentifier,
    dispatch,
    currentUserURL,
  );

  const newWorkTypeHideTimers: {
    [x: string]: string[];
  } = {};
  Object.entries(customerSettings.workTypeHideTimers).forEach(([workType, identifiers]) => {
    newWorkTypeHideTimers[workType] = identifiers.map((i) =>
      i === oldIdentifier ? newIdentifier : i,
    );
  });

  updateSettingIfChanges(
    "workTypeHideTimers",
    newWorkTypeHideTimers,
    settingsEntryLookupByIdentifier,
    dispatch,
    currentUserURL,
  );

  updateSettingIfChanges(
    "navTimersToTransferWithoutMachines",
    customerSettings.navTimersToTransferWithoutMachines.map((i) =>
      i === oldIdentifier ? newIdentifier : i,
    ),
    settingsEntryLookupByIdentifier,
    dispatch,
    currentUserURL,
  );
};

interface TimerEditBlockProps {
  timer: Timer;
}

function TimerEditBlock(props: TimerEditBlockProps): React.JSX.Element {
  const {timer} = props;
  const timerURL = timer.url;

  const [editLabelDialogOpen, setEditLabelDialogOpen] = useState(false);
  const [externalWorkTypeDialogOpen, setExternalWorkTypeDialogOpen] = useState(false);
  const [internalWorkTypeDialogOpen, setInternalWorkTypeDialogOpen] = useState(false);
  const [priceGroupDialogOpen, setPriceGroupDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const customerSettings = useSelector(getCustomerSettings);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const timerArray = useSelector(getTimerArray);
  const otherIdentifiers = useMemo(
    () =>
      new Set(
        timerArray
          .filter((instance) => instance.url !== timerURL)
          .map((instance) => instance.identifier),
      ),
    [timerArray, timerURL],
  );

  const [identifierDialogOpen, setIdentifierDialogOpen] = useState(false);
  const setIdentifierDialogOpenTrue = useCallWithTrue(setIdentifierDialogOpen);
  const setIdentifierDialogOpenFalse = useCallWithFalse(setIdentifierDialogOpen);

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const currentUserURL = useSelector(getCurrentUserURL);

  const handleIdentifierDialogOK = useCallback(
    (identifier: string) => {
      if (!currentUserURL) {
        return;
      }
      setIdentifierDialogOpen(false);
      dispatch(actions.update(timerURL, [{member: "identifier", value: identifier}]));
      updateTimerIdentifierInSettings(
        identifier,
        timer.identifier,
        customerSettings,
        settingsEntryLookupByIdentifier,
        dispatch,
        currentUserURL,
      );
    },
    [
      currentUserURL,
      customerSettings,
      dispatch,
      settingsEntryLookupByIdentifier,
      timer.identifier,
      timerURL,
    ],
  );

  const handleEditTimerLabel = useCallback((): void => {
    setEditLabelDialogOpen(true);
  }, []);

  const handleEditLabelDialogOk = useCallback(
    (label: string): void => {
      setEditLabelDialogOpen(false);
      dispatch(actions.update(timerURL, [{member: "label", value: label}]));
    },
    [dispatch, timerURL],
  );

  const handleEditLabelDialogCancel = useCallback((): void => {
    setEditLabelDialogOpen(false);
  }, []);

  const handleColorSelected = useCallback(
    (color: string): void => {
      if (timer.color !== color) {
        dispatch(actions.update(timerURL, [{member: "color", value: color}]));
      }
    },
    [dispatch, timer.color, timerURL],
  );

  const handleSelectInternalWorkType = useCallback((): void => {
    setInternalWorkTypeDialogOpen(true);
  }, []);

  const handleInternalWorkTypeDialogCancel = useCallback((): void => {
    setInternalWorkTypeDialogOpen(false);
  }, []);

  const handleInternalWorkTypeDialogOk = useCallback(
    (url: WorkTypeUrl): void => {
      setInternalWorkTypeDialogOpen(false);
      const workType = workTypeLookup(url);

      const patch: PatchOperation<Timer>[] = [
        {member: "priceGroup", value: null},
        {member: "workType", value: url},
      ];
      if (workType?.color) {
        patch.push({member: "color", value: workType.color});
      }
      dispatch(actions.update(timerURL, patch));
    },
    [dispatch, timerURL, workTypeLookup],
  );

  const handleSelectExternalWorkType = useCallback((): void => {
    setExternalWorkTypeDialogOpen(true);
  }, []);

  const handleExternalWorkTypeDialogCancel = useCallback((): void => {
    setExternalWorkTypeDialogOpen(false);
  }, []);

  const handleExternalWorkTypeDialogOk = useCallback(
    (url: WorkTypeUrl): void => {
      setExternalWorkTypeDialogOpen(false);
      if (timer.workType !== url) {
        dispatch(
          actions.update(timerURL, [
            {member: "includeForInternalTask", value: false},
            {member: "workType", value: url},
          ]),
        );
      }
    },
    [dispatch, timerURL, timer.workType],
  );

  const handleRemovePriceGroup = useCallback((): void => {
    dispatch(actions.update(timerURL, [{member: "priceGroup", value: null}]));
  }, [dispatch, timerURL]);

  const handleSelectPriceGroup = useCallback((): void => {
    setPriceGroupDialogOpen(true);
  }, []);

  const handlePriceGroupDialogCancel = useCallback((): void => {
    setPriceGroupDialogOpen(false);
  }, []);

  const handlePriceGroupDialogOk = useCallback(
    (url: PriceGroupUrl): void => {
      setPriceGroupDialogOpen(false);
      dispatch(
        actions.update(timerURL, [
          {member: "includeForInternalTask", value: false},
          {member: "priceGroup", value: url},
        ]),
      );
    },
    [dispatch, timerURL],
  );

  const handleActiveChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      dispatch(actions.update(timerURL, [{member: "active", value: checked}]));
    },
    [dispatch, timerURL],
  );

  const handleIncludeForInternalTaskChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      dispatch(actions.update(timerURL, [{member: "includeForInternalTask", value: checked}]));
    },
    [dispatch, timerURL],
  );

  const handleIncludeForExternalTaskChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      dispatch(actions.update(timerURL, [{member: "includeForExternalTask", value: checked}]));
    },
    [dispatch, timerURL],
  );

  const handleRequiresNotesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      dispatch(actions.update(timerURL, [{member: "requiresNotes", value: checked}]));

      if (!checked) {
        dispatch(
          actions.update(timerURL, [{member: "transferTimerNotesToExternalSystem", value: false}]),
        );
      }
    },
    [dispatch, timerURL],
  );

  const handletransferTimerNotesToExternalSystemChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      dispatch(
        actions.update(timerURL, [{member: "transferTimerNotesToExternalSystem", value: checked}]),
      );
    },
    [dispatch, timerURL],
  );

  const handleNotifyUsersOnStartChange = useEventTargetCheckedUpdater<Timer>(
    timerURL,
    "notifyUsersOnStart",
  );

  const handleWarnIfNoTimeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      dispatch(actions.update(timerURL, [{member: "warnIfNoTime", value: checked}]));
    },
    [dispatch, timerURL],
  );

  const handleTowingCostTypeChange = useCallback(
    (
      event: React.ChangeEvent<{name?: string | undefined; value: unknown}>,
      _child: React.ReactNode,
    ): void => {
      const value = event.target.value as "effective" | "none" | "transport";
      const patch: PatchOperation<Timer>[] = [{member: "towingCostType", value}];
      if (value !== "none") {
        patch.push({member: "pullersShareOfSalaryCost", value: null});
      }
      dispatch(actions.update(timerURL, patch));
    },
    [dispatch, timerURL],
  );

  const pullersShareOfSalaryCostPercent =
    timer.pullersShareOfSalaryCost != null
      ? _.round(timer.pullersShareOfSalaryCost * 100, 2)
      : null;
  const handlePullersShareOfSalaryCostPercent = useCallback(
    (value: number | null): void => {
      dispatch(
        actions.update(timerURL, [
          {
            member: "pullersShareOfSalaryCost",
            value: value != null ? value / 100 : null,
          },
        ]),
      );
    },
    [dispatch, timerURL],
  );

  const handleShowInTimelineTableChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      dispatch(actions.update(timerURL, [{member: "showInTimelineTable", value: checked}]));
    },
    [dispatch, timerURL],
  );

  const {formatMessage} = useIntl();
  const {identifier, label} = timer;
  const active = !!timer.active;

  const workType = timer.workType ? workTypeLookup(timer.workType) : null;
  const priceGroup = timer.priceGroup ? priceGroupLookup(timer.priceGroup) : null;

  const readOnlyTimer = timer.isGenericEffectiveTime || timer.isBreak;

  let towingCostBlock;
  let pullersShareOfSalaryCostBlock;
  if (customerSettings.enableMachineAnalysis) {
    towingCostBlock = (
      <FormControl fullWidth style={{marginTop: "2em"}}>
        <InputLabel id="towing-cost-type-select-label">
          <FormattedMessage
            defaultMessage="Bugsering, omkostningstype"
            id="timers.label.towing-cost-type"
          />
        </InputLabel>
        <Select
          fullWidth
          id="towing-cost-type-select"
          labelId="towing-cost-type-select-label"
          onChange={handleTowingCostTypeChange}
          value={timer.towingCostType}
        >
          <MenuItem key="none" value="none">
            <FormattedMessage defaultMessage="Ingen" id="timers.label.towing-cost-type-none" />
          </MenuItem>
          <MenuItem key="effective" value="effective">
            <FormattedMessage
              defaultMessage="Effektiv"
              id="timers.label.towing-cost-type-effective"
            />
          </MenuItem>
          <MenuItem key="transport" value="transport">
            <FormattedMessage
              defaultMessage="Transport"
              id="timers.label.towing-cost-type-transport"
            />
          </MenuItem>
        </Select>
      </FormControl>
    );
    pullersShareOfSalaryCostBlock = (
      <DecimalField
        decimalPlaces={2}
        disabled={timer.towingCostType !== "none"}
        fullWidth
        label={
          <FormattedMessage
            defaultMessage="Trækkers andel af lønomkostning (%)"
            id="timers.label.pullers-share-of-salary-cost"
          />
        }
        margin="dense"
        maxDigits={5}
        onChange={handlePullersShareOfSalaryCostPercent}
        style={{marginTop: "2em"}}
        value={pullersShareOfSalaryCostPercent}
      />
    );
  }

  const workTypeArray = useSelector(getExternalTaskPrimaryWorkTypeArray);

  const activeWorkTypeArray = useMemo(() => workTypeArray.filter(isActive), [workTypeArray]);

  return (
    <>
      <Card style={{marginBottom: 22}}>
        <CardContent>
          <div>
            <div style={{float: "left"}}>
              <h4>
                <FormattedMessage defaultMessage="Label" id="timers.label.label" />
              </h4>
              {label}
            </div>
            <IconButton
              disabled={readOnlyTimer}
              onClick={handleEditTimerLabel}
              style={{float: "right"}}
            >
              <PencilIcon />
            </IconButton>
          </div>
          <hr style={{border: "none", borderTop: "1px double", clear: "both"}} />
          <div>
            <div style={{float: "left"}}>
              <h4>
                <FormattedMessage defaultMessage="Identifier" id="timers.identifier.identifier" />
              </h4>
              {identifier}
            </div>
            <IconButton
              disabled={readOnlyTimer}
              onClick={setIdentifierDialogOpenTrue}
              style={{float: "right"}}
            >
              <PencilIcon />
            </IconButton>
          </div>
          <div style={{clear: "both"}} />
          <FormControlLabel
            control={<Switch checked={active} onChange={handleActiveChange} />}
            disabled={readOnlyTimer}
            label={formatMessage(messages.active)}
            labelPlacement="end"
          />
          <br />
          <FormControlLabel
            control={<Switch checked={timer.requiresNotes} onChange={handleRequiresNotesChange} />}
            disabled={readOnlyTimer}
            label={formatMessage(messages.requiresNotes)}
            labelPlacement="end"
          />
          <br />
          <FormControlLabel
            control={
              <Switch
                checked={timer.transferTimerNotesToExternalSystem}
                onChange={handletransferTimerNotesToExternalSystemChange}
              />
            }
            disabled={!timer.requiresNotes}
            label={formatMessage(messages.transferTimerNotesToExternalSystem)}
            labelPlacement="end"
          />
          <br />
          <FormControlLabel
            control={<Switch checked={!!timer.warnIfNoTime} onChange={handleWarnIfNoTimeChange} />}
            disabled={readOnlyTimer}
            label={
              <FormattedMessage
                defaultMessage="Advar hvis ingen tid er registreret"
                id="timers.label.warn-if-no-time"
              />
            }
            labelPlacement="end"
          />
          <br />
          <FormControlLabel
            control={
              <Switch
                checked={!!timer.showInTimelineTable}
                onChange={handleShowInTimelineTableChange}
              />
            }
            label={
              <FormattedMessage
                defaultMessage="Vis i tabel under tidslinje"
                id="timers.label.show-in-timeline-table"
              />
            }
            labelPlacement="end"
          />
          <br />
          <FormControlLabel
            control={
              <Switch
                checked={!!timer.notifyUsersOnStart}
                onChange={handleNotifyUsersOnStartChange}
              />
            }
            label={
              <FormattedMessage defaultMessage="Send notfikation til udpegede brugere, som opsættes under brugerprofil" />
            }
            labelPlacement="end"
          />
          <br />
          <h3>
            <FormattedMessage defaultMessage="Peger på" id="timers.label.target" />
          </h3>
          <br />
          <Button
            color="primary"
            disabled={readOnlyTimer}
            onClick={handleSelectInternalWorkType}
            variant="contained"
          >
            {formatMessage(messages.selectInternalWorkType)}
          </Button>
          {workType && workType.internalTaskPrimary ? (
            <Chip label={getWorkTypeString(workType)} style={{marginTop: 5}} />
          ) : null}
          <Grid style={{marginTop: 10}}>
            <Cell>
              <Button
                color="primary"
                disabled={readOnlyTimer}
                onClick={handleSelectExternalWorkType}
                variant="contained"
              >
                {formatMessage(messages.selectExternalWorkType)}
              </Button>

              {workType && workType.externalTaskPrimary ? (
                <Chip label={getWorkTypeString(workType)} style={{marginTop: 5}} />
              ) : null}
            </Cell>
            <Cell>
              <Button
                color="primary"
                disabled={readOnlyTimer || (!!workType && workType.internalTaskPrimary)}
                onClick={handleSelectPriceGroup}
                variant="contained"
              >
                {formatMessage(messages.selectPriceGroup)}
              </Button>
              {priceGroup ? (
                <Chip
                  label={`${priceGroup.identifier} ${priceGroup.name}`}
                  onDelete={handleRemovePriceGroup}
                  style={{marginTop: 5}}
                />
              ) : null}
            </Cell>
          </Grid>
          <SelectColor color={timer.color} onColorSelected={handleColorSelected} required />
          {towingCostBlock}
          {pullersShareOfSalaryCostBlock}
        </CardContent>
      </Card>
      {!readOnlyTimer ? (
        <Card>
          <CardHeader title={formatMessage(messages.visibility)} />
          <CardContent>
            <FormControlLabel
              control={
                <Switch
                  checked={timer.includeForInternalTask}
                  onChange={handleIncludeForInternalTaskChange}
                />
              }
              disabled={readOnlyTimer || !!timer.priceGroup || !!workType?.externalTaskPrimary}
              label={formatMessage(messages.includeForInternalTask)}
              labelPlacement="end"
            />
            <br />
            <FormControlLabel
              control={
                <Switch
                  checked={timer.includeForExternalTask}
                  onChange={handleIncludeForExternalTaskChange}
                />
              }
              disabled={readOnlyTimer}
              label={formatMessage(messages.includeForExternalTask)}
              labelPlacement="end"
            />
            <TimerDepartmentsBlock timer={timer} />
          </CardContent>
          <TimerWorkTypeMachinePriceGroupBlock timer={timer} />
          <CardContent>
            <PriceGroupHideTimers timer={timer} />
            <WorkTypeHideTimers timer={timer} />
          </CardContent>
        </Card>
      ) : null}
      <SingleTextFieldDialog
        label={<FormattedMessage defaultMessage="Label" id="system-setup.label.label" />}
        onCancel={handleEditLabelDialogCancel}
        onOk={handleEditLabelDialogOk}
        open={editLabelDialogOpen}
        title={formatMessage(messages.editLabel)}
        value={label}
      />
      <TimerIdentifierDialog
        identifier={identifier}
        onCancel={setIdentifierDialogOpenFalse}
        onOk={handleIdentifierDialogOK}
        open={identifierDialogOpen}
        otherTimerIdentifiers={otherIdentifiers}
      />
      <ConnectedInternalWorkTypeDialog
        onCancel={handleInternalWorkTypeDialogCancel}
        onOk={handleInternalWorkTypeDialogOk}
        open={internalWorkTypeDialogOpen}
      />
      <WorkTypeDialog
        currentUserURL={currentUserURL}
        disabledWorkTypes={customerSettings.disabledWorkTypes}
        onCancel={handleExternalWorkTypeDialogCancel}
        onOk={handleExternalWorkTypeDialogOk}
        open={externalWorkTypeDialogOpen}
        suggestRecentlyUsedWorkTypes={false}
        workTypeArray={activeWorkTypeArray}
      />
      <ConnectedPriceGroupDialog
        includeOnlyForExtraTimers
        onCancel={handlePriceGroupDialogCancel}
        onOk={handlePriceGroupDialogOk}
        open={priceGroupDialogOpen}
        workTypeURL={workType?.url}
      />
    </>
  );
}

export function TimerEntry(): React.JSX.Element {
  const getID = useMemo(() => makePathParameterGetter("id"), []);
  const id = useSelector(getID);

  const timerLookup = useSelector(getTimerLookup);

  const {formatMessage} = useIntl();
  const timer = timerLookup(instanceURL("timer", id));

  return (
    <PageLayout toolbar={formatMessage(messages.editTimer)}>
      <Grid
        style={{
          margin: 5,
        }}
      >
        <Cell
          palm="12/12"
          size="6/12"
          style={{
            paddingBottom: 11,
            paddingTop: 11,
          }}
        >
          {timer ? <TimerEditBlock timer={timer} /> : null}
        </Cell>
      </Grid>
    </PageLayout>
  );
}
