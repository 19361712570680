import {formatAddress} from "@co-common-libs/utils";
import React from "react";
import {AddressDisplay} from "./address-display";

export function AddressLine({
  address,
}: {
  address:
    | string
    | {
        address: string;
        city: string;
        postalCode: string;
      };
}): React.JSX.Element {
  const formattedAddress = typeof address !== "string" ? formatAddress(address) : address;
  return <AddressDisplay address={formattedAddress} label={formattedAddress} />;
}
