import {Query} from "@co-frontend-libs/db-resources";
import {current, Draft} from "@reduxjs/toolkit";
import {clearPersistentQueries} from "../actions";
import {emptyQueryState, ResourcesState} from "../types";

export function handleClearPersistentQueries(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof clearPersistentQueries>,
): void {
  const {persistedQueries} = state;
  const {queries: newQueries} = action.payload;

  newQueries.forEach((query) => {
    const {keyString} = query;
    const currentEntry = persistedQueries[keyString];
    if (currentEntry) {
      currentEntry.query = query as Draft<Query>;
      currentEntry.queryState = emptyQueryState;
      persistedQueries[keyString] = currentEntry;
    }
  });
  const updatedState = current(state) as ResourcesState;
  updatedState.persistedQueries = persistedQueries;
}
