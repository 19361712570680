import {Patch, Task} from "@co-common-libs/resources";
import _ from "lodash";
import {ProvisionaryCommand} from "../../resources/actions";
import {getLocationLookup} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function addRemoveFieldsOnLogLocationChanges(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }

  if (
    !newTask.reportingLocations ||
    _.isEqual(newTask.reportingLocations, oldTask?.reportingLocations)
  ) {
    // no log location changes
    return null;
  }

  const state = middlewareApi.getState();

  if (!newTask.reportingSpecification) {
    // sanity check; should not occur; but just in case,
    // don't modify fields if we e.g. mistakenly use `{}` as a "blank"
    // reportingLocationData value for a task without reportingSpecification...
    return null;
  }

  const currentLogLocations = new Set(
    Object.values(newTask.reportingLocations).map((entry) => entry.location),
  );

  const extendedFieldUses = [...newTask.fielduseSet];

  const locationLookup = getLocationLookup(state);

  currentLogLocations.forEach((locationURL) => {
    if (extendedFieldUses.some((fieldUse) => fieldUse.relatedField === locationURL)) {
      return;
    }
    if (locationURL) {
      const location = locationLookup(locationURL);
      if (location?.geojson) {
        extendedFieldUses.push({
          fieldAreaHa: location.fieldAreaHa,
          fieldCrop: location.fieldCrop,
          geojson: location.geojson,
          notes: "",
          relatedField: locationURL,
        });
      }
    }
  });

  const filteredExtendedFieldUses = extendedFieldUses.filter((fieldUse) =>
    currentLogLocations.has(fieldUse.relatedField),
  );

  if (_.isEqual(newTask.fielduseSet, filteredExtendedFieldUses)) {
    return null;
  }

  return [{member: "fielduseSet", value: filteredExtendedFieldUses}];
}
