import {urlToId} from "@co-common-libs/resources";
import {makeQuery, Query} from "@co-frontend-libs/db-resources";
import {
  actions,
  AppState,
  getPathName,
  makeQueryParameterGetter,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {RoutePlanTable} from "./route-plan-table";

const MAX_LIST_ENTRIES_DISPLAYED = 100;

interface RoutePlanListStateProps {
  pathName: string;
  q: string;
}

interface RoutePlanListDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
}

interface RoutePlanListOwnProps {
  showInactive: boolean;
}

type RoutePlanListProps = RoutePlanListDispatchProps &
  RoutePlanListOwnProps &
  RoutePlanListStateProps;

const TEMPORARY_QUERIES_KEY = "RoutePlanList";

class RoutePlanList extends React.PureComponent<RoutePlanListProps> {
  archiveSearchChange = _.debounce((value: string, pathName: string): void => {
    const newQuery = makeQuery({
      check: {type: "alwaysOk"},
      filter: {
        search: value,
      },
      independentFetch: true,
      limit: MAX_LIST_ENTRIES_DISPLAYED + 1,
      resourceName: "routePlan",
      sortBy: ["name"],
    });
    this.props.temporaryQueriesRequestedForPath([newQuery], pathName, TEMPORARY_QUERIES_KEY);
  }, 300);
  componentWillUnmount(): void {
    this.archiveSearchChange.cancel();
  }

  @bind
  handleRoutePlanTableClick(routePlanURL: string): void {
    const {go} = this.props;
    const id = urlToId(routePlanURL);
    go("/settings/route/:id", {id});
  }

  render(): React.JSX.Element {
    const filterString = this.props.q;

    return (
      <RoutePlanTable
        filterString={filterString}
        onClick={this.handleRoutePlanTableClick}
        onlyActive={!this.props.showInactive}
      />
    );
  }
  UNSAFE_componentWillMount(): void {
    const filterString = this.props.q;
    const {pathName, showInactive} = this.props;
    if (showInactive) {
      this.archiveSearchChange(filterString, pathName);
    }
  }
}

const ConnectedRoutePlanList: React.ComponentType<RoutePlanListOwnProps> = connect<
  RoutePlanListStateProps,
  RoutePlanListDispatchProps,
  RoutePlanListOwnProps,
  AppState
>(
  createStructuredSelector<AppState, RoutePlanListStateProps>({
    pathName: getPathName,
    q: makeQueryParameterGetter("q"),
  }),
  {
    go: actions.go,
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
  },
)(RoutePlanList);

export {ConnectedRoutePlanList as RoutePlanList};
