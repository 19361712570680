import {Config} from "@co-common-libs/config";
import {
  Machine,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getMachineString} from "@co-common-libs/resources-utils";
import {formatDateNumeric, notUndefined} from "@co-common-libs/utils";
import {
  AppState,
  getCustomerSettings,
  getUserLookup,
  getUserProfileArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {getRemunerationGroupLabels, PureComponent} from "app-utils";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {WorkTypeDisplayCard} from "./work-type-display-card";

const messages = defineMessages({
  header: {
    defaultMessage: "{machine} lønomkostning {fromDate} - {toDate}",
    id: "reports.HEADER.machine-salary-cost",
  },
});

interface DisplayCardsStateProps {
  customerSettings: Config;
  userLookup: (url: UserUrl) => User | undefined;
  userProfileArray: readonly UserProfile[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface DisplayCardsOwnProps {
  data: {
    [workTypeID: string]: {
      [initials: string]: [string, number, string, number][];
    };
  };
  fromDate: string;
  machine?: Machine | undefined;
  toDate: string;
}

type DisplayCardsProps = DisplayCardsOwnProps & DisplayCardsStateProps;

class DisplayCards extends PureComponent<DisplayCardsProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      customerSettings,
      data,
      fromDate,
      machine,
      toDate,
      userLookup,
      userProfileArray,
      workTypeLookup,
    } = this.props;
    const rateLabels = customerSettings.remunerationRateLabels || [""];
    const groupLabels = getRemunerationGroupLabels(
      customerSettings,
      formatMessage,
      userProfileArray,
      userLookup,
    );
    const bonusLabels = customerSettings.remunerationIntervalBonusLabels;

    const withWorkTypes = Array.from(Object.entries(data)).map(([workTypeID, workTypeData]) => ({
      workTypeData,
      workTypeID,
      workTypes: workTypeID
        ? workTypeID
            .split(":::")
            .map((id) => workTypeLookup(instanceURL("workType", id)))
            .filter(notUndefined)
        : [],
    }));
    const sorted = _.sortBy(withWorkTypes, [
      (entry) => entry.workTypes[0]?.name || "",
      (entry) => entry.workTypeID,
    ]);

    const cards = sorted.map(({workTypeData, workTypeID, workTypes}) => (
      <WorkTypeDisplayCard
        bonusLabels={bonusLabels}
        data={workTypeData}
        groupLabels={groupLabels}
        key={workTypeID}
        rateLabels={rateLabels}
        workTypes={workTypes}
      />
    ));

    return (
      <>
        <h1 style={{marginBottom: "1em", marginTop: "1em"}}>
          {formatMessage(messages.header, {
            fromDate: formatDateNumeric(fromDate),
            machine: getMachineString(machine),
            toDate: formatDateNumeric(toDate),
          })}
        </h1>
        {cards}
      </>
    );
  }
}

const ConnectedDisplayCards: React.ComponentType<DisplayCardsOwnProps> = connect<
  DisplayCardsStateProps,
  object,
  DisplayCardsOwnProps,
  AppState
>(
  createStructuredSelector<AppState, DisplayCardsStateProps>({
    customerSettings: getCustomerSettings,
    userLookup: getUserLookup,
    userProfileArray: getUserProfileArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {},
)(DisplayCards);

export {ConnectedDisplayCards as DisplayCards};
