import {Command, ResourceName} from "@co-common-libs/resources";
import {createSelector, Selector} from "reselect";
import {
  CreateCommandPromise,
  ResourceInstanceRecords,
  ResourcesState,
  StateWithResources,
} from "../types";
import {combineResourceOfflineCommittingData} from "../utils";
import {combineOfflineData, getCommittingPerResource} from "./resources-helpers";

function getPersistedData<T extends ResourceName>(
  resourceName: T,
): Selector<StateWithResources, ResourceInstanceRecords[T]> {
  return function (state: StateWithResources): ResourceInstanceRecords[T] {
    console.assert(
      state.resources.persistedData[resourceName],
      `resource missing in persistedData: ${resourceName}`,
    );
    return state.resources.persistedData[resourceName] || {};
  };
}

function getTemporaryData<T extends ResourceName>(
  resourceName: T,
): Selector<StateWithResources, ResourceInstanceRecords[T]> {
  return function (state: StateWithResources): ResourceInstanceRecords[T] {
    console.assert(
      state.resources.persistedData[resourceName],
      `resource missing in temporaryData: ${resourceName}`,
    );
    return state.resources.temporaryData[resourceName] || {};
  };
}

function getCommitting<T extends ResourceName>(
  resourceName: T,
): Selector<StateWithResources, readonly (Command | CreateCommandPromise)[]> {
  return function (state: {
    resources: ResourcesState;
  }): readonly (Command | CreateCommandPromise)[] {
    const committing = getCommittingPerResource(state);
    return committing[resourceName];
  };
}

function getOfflineData<T extends ResourceName>(
  resourceName: T,
): Selector<StateWithResources, ResourceInstanceRecords[T]> {
  return createSelector(
    getPersistedData(resourceName),
    getTemporaryData(resourceName),
    combineOfflineData,
  );
}

export function getData<T extends ResourceName>(
  resourceName: T,
): Selector<StateWithResources, ResourceInstanceRecords[T]> {
  return createSelector(
    getOfflineData(resourceName),
    getCommitting(resourceName),
    combineResourceOfflineCommittingData,
  );
}
