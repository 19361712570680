import {
  apiVersion as currentApiVersion,
  frontendVersion as currentFrontendVersion,
} from "@co-common-libs/frontend-version";
import {
  applyPatch,
  Command,
  isMemberPatchOperation,
  Patch,
  ResourceInstance,
  resourceNameFor,
} from "@co-common-libs/resources";
import {current, Draft} from "@reduxjs/toolkit";
import _ from "lodash";
import {save} from "../actions";
import {ResourcesState} from "../types";

function combinePatches<T extends ResourceInstance>(
  oldPatch: Patch<T>,
  newPatch: Patch<T>,
): Patch<T> {
  console.assert(
    oldPatch.length,
    `oldPatch empty; should not be; combining with: ${JSON.stringify(newPatch)}`,
  );
  console.assert(
    newPatch.length,
    `newPatch empty; should not be; combining with: ${JSON.stringify(oldPatch)}`,
  );
  const result = oldPatch.slice();
  newPatch.forEach((operation) => {
    const oldLast = result[result.length - 1];
    if (isMemberPatchOperation(operation)) {
      if (isMemberPatchOperation(oldLast) && operation.member === oldLast.member) {
        result[result.length - 1] = operation;
        return;
      }
    } else {
      if (!isMemberPatchOperation(oldLast) && _.isEqual(operation.path, oldLast.path)) {
        result[result.length - 1] = operation;
        return;
      }
    }
    result.push(operation);
  });
  return result;
}

export function handleSave(state: Draft<ResourcesState>, action: ReturnType<typeof save>): void {
  const inputCommand = action.payload;
  const {url} = inputCommand;
  const {commitQueue} = state;

  if (process.env.NODE_ENV !== "production") {
    if (inputCommand.action === "UPDATE") {
      const resourceName = resourceNameFor(inputCommand.url);
      let existing = state.persistedData[resourceName][url] ||
        state.temporaryData[resourceName][url] || {url};
      commitQueue.forEach((commitEntry) => {
        const {command} = commitEntry;
        if (command.url === url) {
          if (command.action === "UPDATE") {
            existing = applyPatch(existing, command.patch as any);
          } else if (command.action === "CREATE") {
            existing = command.instance;
          }
          console.assert(command.action !== "DELETE");
        }
      });
      const combined = applyPatch(existing, inputCommand.patch as any);
      if (_.isEqual(existing, combined)) {
        // eslint-disable-next-line no-console
        console.error("No-op patch dispatched");
        // eslint-disable-next-line no-console
        console.error(inputCommand);
      }
    }
  }

  const mergeCandidate = _.last(commitQueue);
  if (
    (inputCommand.action === "UPDATE" || inputCommand.action === "CREATE_OR_UPDATE") &&
    mergeCandidate &&
    mergeCandidate.command.url === url &&
    mergeCandidate.status !== "SAVING_ONLINE" &&
    mergeCandidate.apiVersion === currentApiVersion &&
    mergeCandidate.frontendVersion === currentFrontendVersion &&
    mergeCandidate.command.action === "UPDATE"
  ) {
    const patch = combinePatches(
      current(mergeCandidate.command.patch) as any,
      inputCommand.patch as any,
    );
    commitQueue[commitQueue.length - 1] = {
      apiVersion: currentApiVersion,
      command: {
        action: mergeCandidate.command.action,
        patch: patch as Draft<typeof patch>,
        url,
      },
      error: null,
      errorTimestamp: null,
      frontendVersion: currentFrontendVersion,
      id: mergeCandidate.id,
      requestId: null,
      status: "UNSAVED",
    };
    return;
  }
  let command: Command;
  if (inputCommand.action === "CREATE_OR_UPDATE") {
    const isUpdate = commitQueue.some(
      (commitEntry) => commitEntry.command.url === url && commitEntry.command.action !== "DELETE",
    );
    command = isUpdate
      ? {action: "UPDATE", patch: inputCommand.patch, url}
      : {action: "CREATE", instance: inputCommand.instance, url};
  } else {
    command = inputCommand;
  }
  const id = state.commitLoaded ? (_.last(commitQueue)?.id || 0) + 1 : 0;
  commitQueue.push({
    apiVersion: currentApiVersion,
    command: command as Draft<Command>,
    error: null,
    errorTimestamp: null,
    frontendVersion: currentFrontendVersion,
    id,
    requestId: null,
    status: "UNSAVED",
  });
}
