import {BarcodeFormat, getBarcodeScanner} from "@co-frontend-libs/utils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputBase,
  Toolbar,
} from "@material-ui/core";
import {TransitionHandlerProps} from "@material-ui/core/transitions";
import bowser from "bowser";
import CloseIcon from "mdi-react/CloseIcon";
import SearchIcon from "mdi-react/SearchIcon";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDebounce} from "use-debounce";
import {BarcodeScannerIconButton} from "../../barcode-scanner-icon-button";
import {OfflineAwareAppBar} from "../../offline-aware-app-bar";
import {SlideUpTransition} from "../../slide-up-transition";
import {TrimTextField} from "../../trim-text-field";
import {typedMemo} from "../../types";
import {ErrorMessage} from "../error-message";
import {Loading} from "../loading";
import {useDialogStyles} from "../styles";
import {categoriesWithIcons, EntryData, MaybeSearchResultEntryData} from "../types";
import {
  categoryComparator,
  filterData,
  getUniqueFilterResult,
  primaryIdentifierComparator,
  primaryTextComparator,
  secondaryIdentifierComparator,
  secondaryTextComparator,
} from "../utils";
import {MultiSelectionList} from "./multi-selection-list";

export interface BaseMultiSelectionDialogProps<Identifier extends string>
  extends TransitionHandlerProps {
  addLabel?: string | undefined;
  addNamedLabel?: string;
  addShortLabel?: string;
  addUnnamedLabel?: string;
  barcodeScannerFormats?: readonly BarcodeFormat[] | null | undefined;
  data: readonly EntryData<Identifier>[] | null;
  error?: string | undefined;
  filterVariant?: "AND" | "OR";
  fullscreen?: boolean | undefined;
  includeSelectAll?: boolean | undefined;
  listEmptyMessage?: string | undefined;
  mobilePrimaryLines: 1 | 2;
  mobileSearchPrimaryLines: 1 | 2;
  mobileSearchSecondaryLines: 1 | 2;
  mobileSecondaryLines: 0 | 1 | 2;
  multiSelectMax?: number | undefined;
  onAdd?: ((searchString: string) => void) | undefined;
  onCancel: () => void;
  onOk: () => void;
  onSelect: (identifier: Identifier, isSelected: boolean) => void;
  onSelectMultiple?:
    | ((identifiers: ReadonlySet<Identifier>, isSelected: boolean) => void)
    | undefined;
  onToggleSelected: (identifier: Identifier) => void;
  open: boolean;
  searchTitle: string;
  selected: ReadonlySet<Identifier>;
  sorting?: "PRIMARY_IDENTIFIER" | "PRIMARY_TEXT" | "SECONDARY_IDENTIFIER" | "SECONDARY_TEXT";
  title: string | React.JSX.Element;
}

export const BaseMultiSelectionDialog = typedMemo(function BaseMultiSelectionDialog<
  Identifier extends string,
>(props: BaseMultiSelectionDialogProps<Identifier>): React.JSX.Element {
  const intl = useIntl();

  const {
    addLabel,
    addNamedLabel,
    addShortLabel,
    addUnnamedLabel,
    barcodeScannerFormats,
    data,
    filterVariant = "AND",
    fullscreen,
    includeSelectAll,
    mobilePrimaryLines,
    mobileSearchPrimaryLines,
    mobileSearchSecondaryLines,
    mobileSecondaryLines,
    multiSelectMax,
    onAdd,
    onCancel,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    onOk,
    onSelect,
    onSelectMultiple,
    onToggleSelected,
    open,
    searchTitle,
    selected,
    sorting = "PRIMARY_TEXT",
    title,
  } = props;

  const withIcons = useMemo(
    () =>
      onAdd !== undefined ||
      data?.some((entry) => categoriesWithIcons.has(entry.category)) ||
      false,
    [data, onAdd],
  );

  const [filterString, setFilterString] = useState("");
  useEffect(() => {
    if (!open) {
      setFilterString("");
    }
  }, [open]);

  const handleAdd = useCallback(() => {
    if (onAdd) {
      onAdd(filterString);
    }
  }, [filterString, onAdd]);

  const handleFilterFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setFilterString(event.target.value);
    },
    [],
  );

  const debounceMilliseconds = 200;
  const [debouncedFilterString] = useDebounce(filterString, debounceMilliseconds);

  const trimmedFilterString = debouncedFilterString.trim();

  const comparator =
    sorting === "PRIMARY_TEXT"
      ? primaryTextComparator
      : sorting === "SECONDARY_TEXT"
        ? secondaryTextComparator
        : sorting === "PRIMARY_IDENTIFIER"
          ? primaryIdentifierComparator
          : secondaryIdentifierComparator;

  const sortedData = useMemo(
    () => (data || []).slice().sort((a, b) => categoryComparator(a, b) || comparator(a, b)),
    [comparator, data],
  );

  const filteredData = useMemo(
    (): readonly MaybeSearchResultEntryData<Identifier>[] =>
      filterData(sortedData, filterVariant, trimmedFilterString),
    [filterVariant, sortedData, trimmedFilterString],
  );

  const handleFilterFieldKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key !== "Enter") {
        return;
      }
      const entry = getUniqueFilterResult(filteredData, trimmedFilterString);
      if (entry && !entry.disabled) {
        onToggleSelected(entry.identifier);
      }
    },
    [filteredData, onToggleSelected, trimmedFilterString],
  );

  const handleBarcodeScannerResult = useCallback(
    (text: string): void => {
      if (!text) {
        return;
      }
      const trimmedBarcodeText = text.trim();
      const barcodeScanResultFilteredData = filterData(
        sortedData,
        filterVariant,
        trimmedBarcodeText,
      );
      const entry = getUniqueFilterResult(barcodeScanResultFilteredData, trimmedBarcodeText);
      if (entry && !entry.disabled) {
        onToggleSelected(entry.identifier);
      }
      setFilterString(trimmedBarcodeText);
    },
    [filterVariant, onToggleSelected, sortedData],
  );

  const classes = useDialogStyles();

  const enterExitCallbacks: Partial<DialogProps> = {};
  if (onEnter) {
    enterExitCallbacks.onEnter = onEnter;
  }
  if (onEntered) {
    enterExitCallbacks.onEntered = onEntered;
  }
  if (onEntering) {
    enterExitCallbacks.onEntering = onEntering;
  }
  if (onExit) {
    enterExitCallbacks.onExit = onExit;
  }
  if (onExited) {
    enterExitCallbacks.onExited = onExited;
  }
  if (onExiting) {
    enterExitCallbacks.onExiting = onExiting;
  }
  const fullscreenLayout = fullscreen ?? !!(bowser.mobile || bowser.tablet);
  const barcodeScanner = barcodeScannerFormats?.length
    ? getBarcodeScanner(barcodeScannerFormats)
    : null;
  const barcodeScannerInputEndAdornment = barcodeScanner ? (
    <InputAdornment position="end">
      <BarcodeScannerIconButton
        barcodeScanner={barcodeScanner}
        onScanResult={handleBarcodeScannerResult}
      />
    </InputAdornment>
  ) : null;

  let dialogContent: React.JSX.Element;
  let removePadding = false;
  if (props.error) {
    dialogContent = <ErrorMessage error={props.error} />;
  } else if (!data) {
    dialogContent = <Loading />;
  } else if (props.listEmptyMessage && data?.length === 0) {
    dialogContent = <h3>{props.listEmptyMessage}</h3>;
  } else {
    let formattedAddLabel: string;
    if (fullscreenLayout) {
      formattedAddLabel =
        addShortLabel ||
        addLabel ||
        (onAdd
          ? intl.formatMessage({
              defaultMessage: "Opret",
            })
          : "");
    } else {
      formattedAddLabel =
        addLabel ||
        (onAdd
          ? trimmedFilterString
            ? addNamedLabel ||
              intl.formatMessage({defaultMessage: 'Opret "{name}"'}, {name: trimmedFilterString})
            : addUnnamedLabel || intl.formatMessage({defaultMessage: "Opret"})
          : "");
    }
    removePadding = true;
    dialogContent = (
      <MultiSelectionList
        addLabel={formattedAddLabel}
        entries={filteredData}
        fullscreenLayout={fullscreenLayout}
        isSearchResult={!!trimmedFilterString}
        mobilePrimaryLines={mobilePrimaryLines}
        mobileSearchPrimaryLines={mobileSearchPrimaryLines}
        mobileSearchSecondaryLines={mobileSearchSecondaryLines}
        mobileSecondaryLines={mobileSecondaryLines}
        multiSelectMax={multiSelectMax}
        onAdd={onAdd ? handleAdd : undefined}
        onSelect={onSelect}
        onSelectMultiple={includeSelectAll !== false ? onSelectMultiple : undefined}
        selected={selected}
        withIcons={withIcons}
      />
    );
  }

  if (fullscreenLayout) {
    return (
      <Dialog
        fullScreen
        onClose={onCancel}
        open={open}
        TransitionComponent={SlideUpTransition}
        {...enterExitCallbacks}
      >
        <OfflineAwareAppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={onCancel}>
              <CloseIcon />
            </IconButton>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                autoFocus
                classes={{
                  input: classes.inputInput,
                  root: classes.inputRoot,
                }}
                endAdornment={barcodeScannerInputEndAdornment}
                onChange={handleFilterFieldChange}
                onKeyDown={handleFilterFieldKeyDown}
                placeholder={searchTitle}
                value={filterString}
              />
            </div>
            <Button color="inherit" onClick={onOk}>
              <FormattedMessage defaultMessage="OK" id="multi-slection-dialog.label.ok" />
            </Button>
          </Toolbar>
        </OfflineAwareAppBar>
        <Toolbar />
        {dialogContent}
      </Dialog>
    );
  } else {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={onCancel}
        open={open}
        scroll="paper"
        {...enterExitCallbacks}
      >
        <DialogTitle>
          {title}
          <TrimTextField
            autoFocus
            fullWidth
            InputProps={{
              endAdornment: barcodeScannerInputEndAdornment,
            }}
            margin="dense"
            onChange={setFilterString}
            onKeyDown={handleFilterFieldKeyDown}
            placeholder={searchTitle}
            value={filterString}
            variant="outlined"
          />
        </DialogTitle>

        <DialogContent className={removePadding ? classes.noPaddingNoOverflow : ""} dividers>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCancel}>
            <FormattedMessage defaultMessage="Fortryd" />
          </Button>
          <Button color="primary" onClick={onOk}>
            <FormattedMessage defaultMessage="OK" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
});
