import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  RoutePlan,
  RoutePlanUrl,
  Task,
  TaskUrl,
  TimerStart,
  urlToId,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  caseAccentInsensitiveCollator,
  dateFromString,
  dateToString,
  formatDate,
  HOUR_MINUTES,
  initialComparator,
  notUndefined,
} from "@co-common-libs/utils";
import {
  getCurrentRole,
  getCustomerSettings,
  getProjectLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {colorMap, matchingTextColor} from "@co-frontend-libs/utils";
import {SvgIcon} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import {PureComponent} from "app-utils";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DropTarget,
  DropTargetMonitor,
} from "react-dnd";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {TaskStatusIcon} from "../task-status-icon";
import {ORDER_CALENDAR_COLUMN_WIDTH} from "./constants";

const DND_MODE_DRAGGABLE_OPACITY = 1;
const UNDRAGGABLE_OPACITY = 0.5;
const IS_DRAGGING_OPACITY = 0.2;
const ORDER_COMPLETED_OPACITY = 0.5;
const TASK_COMPLETED_OPACITY = 1;
const COLUMN_MARGIN = 4;
const SIDE_PADDING = 4;
const BORDER_WIDTH = 1;

const EDIT_ICON_SIZE = 13;
const ORDER_WORK_TYPE_STRING_WIDTH_WHEN_EDIT_ICON_IS_SHOWN =
  ORDER_CALENDAR_COLUMN_WIDTH -
  (COLUMN_MARGIN +
    COLUMN_MARGIN +
    SIDE_PADDING +
    SIDE_PADDING +
    BORDER_WIDTH +
    BORDER_WIDTH +
    EDIT_ICON_SIZE);

const minutesSinceMidnightFromTimeIsoString = (isoString: string): number => {
  const [hours, minutes] = isoString.split(":");
  return HOUR_MINUTES * parseInt(hours) + parseInt(minutes);
};

interface CustomerBlockProps {
  contact?: Contact | undefined;
  customer?: Customer | undefined;
  onClick: () => void;
  projectNumber: string;
  referenceNumber: string;
  routePlan: RoutePlan | undefined;
  taskList: readonly Task[];
}

const CustomerBlock = React.memo(function CustomerBlock(
  props: CustomerBlockProps,
): React.JSX.Element {
  const {contact, customer, onClick, projectNumber, referenceNumber, routePlan} = props;
  const customerSettings = useSelector(getCustomerSettings);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const customerName = customer && customer.name;

  let right = "";
  if (customerSettings.orderCalendarAsTaskCalendar) {
    if (customerSettings.orderCalendarAsTaskCalendarBlockExtraInfo === "phone") {
      right =
        (contact && (contact.phone || contact.cellphone)) || (customer && customer.phone) || "";
    } else if (customerSettings.orderCalendarAsTaskCalendarBlockExtraInfo === "projectNumber") {
      right = projectNumber;
    } else if (
      customerSettings.orderCalendarAsTaskCalendarBlockExtraInfo === "orderReferenceNumber"
    ) {
      right = referenceNumber;
    }
  } else {
    if (customerSettings.orderCalendarBlockExtraInfo === "phone") {
      right =
        (contact && (contact.phone || contact.cellphone)) || (customer && customer.phone) || "";
    } else if (customerSettings.orderCalendarBlockExtraInfo === "orderReferenceNumber") {
      right = referenceNumber;
    } else if (customerSettings.orderCalendarBlockExtraInfo === "employeeAlias") {
      const initials = new Set<string>();
      props.taskList.forEach((task) => {
        if (task.machineOperator) {
          const alias = userUserProfileLookup(task.machineOperator)?.alias;
          if (alias) {
            initials.add(alias);
          }
        }
      });

      const sortedInitials = [...initials];
      sortedInitials.sort(initialComparator);
      right = sortedInitials.join(", ");
    }
  }
  const style: React.CSSProperties = {
    backgroundColor: "#fff",
    borderColor: grey[700],
    borderStyle: "solid",
    borderWidth: "0px 0px 1px 0px",
    color: customerName || routePlan ? matchingTextColor("#fff") : colorMap.ERROR,
    cursor: "pointer",
    fontWeight: "bold",
    maxWidth: "100%",
    overflow: "hidden",
    padding: "0px 4px 0px 4px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div onClick={onClick} style={style}>
      <span style={{float: "right"}}>{right}</span>
      <span>
        {customerName ? (
          customerName
        ) : routePlan ? (
          <FormattedMessage defaultMessage={"Rute: {name}"} values={{name: routePlan.name}} />
        ) : (
          "???"
        )}
      </span>
    </div>
  );
});

interface OrderTypeBlockProps {
  onClick: () => void;
  onEditClick?: (() => void) | undefined;
  orderWorkType?: WorkType | undefined;
}

class OrderTypeBlock extends PureComponent<OrderTypeBlockProps> {
  static defaultProps = {
    hasPriority: false,
  };
  render(): React.JSX.Element {
    const {onClick, onEditClick, orderWorkType} = this.props;
    const headerColor = (orderWorkType && orderWorkType.color) || colorMap.ORDER;
    let orderWorkTypeStr = null;
    if (orderWorkType) {
      orderWorkTypeStr = `${orderWorkType.identifier}: ${orderWorkType.name}`;
    }
    const style: React.CSSProperties = {
      backgroundColor: headerColor,
      color: orderWorkTypeStr ? matchingTextColor(headerColor) : colorMap.ERROR,
      fontWeight: "bold",
      maxWidth: "100%",
      paddingBottom: 4,
      paddingLeft: SIDE_PADDING,
      paddingRight: SIDE_PADDING,
      paddingTop: 2,
    };
    let editIcon;
    if (onEditClick) {
      editIcon = (
        <SvgIcon
          onClick={onEditClick}
          style={{
            cursor: "pointer",
            float: "right",
            height: EDIT_ICON_SIZE,
            marginTop: 3,
            width: EDIT_ICON_SIZE,
          }}
        >
          <PencilIcon />
        </SvgIcon>
      );
    }
    return (
      <div style={style}>
        {editIcon}
        <span
          onClick={onClick}
          style={{
            cursor: "pointer",
            display: "inline-block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: editIcon ? ORDER_WORK_TYPE_STRING_WIDTH_WHEN_EDIT_ICON_IS_SHOWN : "100%",
          }}
        >
          {orderWorkTypeStr || "???"}
        </span>
      </div>
    );
  }
}

interface TaskMachineBlockProps {
  customerSettings: Config;
  machineList: readonly Machine[];
  machineOperatorProfile: UserProfile | undefined;
  notesFromMachineOperator: string;
  notesFromManager: string;
}

class TaskMachineBlock extends PureComponent<TaskMachineBlockProps> {
  render(): React.JSX.Element {
    const {machineList, machineOperatorProfile} = this.props;
    const initials = machineOperatorProfile && machineOperatorProfile.alias;
    const machineNumbers = machineList
      .slice()
      .sort(
        (a, b) =>
          // canPull before !canPull
          Number(b.canPull) - Number(a.canPull) ||
          caseAccentInsensitiveCollator.compare(a.c5_machine, b.c5_machine),
      )
      .map((machine) => {
        return machine.c5_machine;
      })
      .join(", ");
    let productiveMachineName = "";
    let productiveMachineNameSeparator;
    let productiveMachine;
    if (machineList.length === 1) {
      productiveMachine = machineList[0];
    } else {
      const notPullers = machineList.filter((m) => !m.canPull);
      if (notPullers.length === 1) {
        productiveMachine = notPullers[0];
      }
    }
    if (productiveMachine) {
      if (this.props.customerSettings.orderCalendarMachineLinebreak) {
        productiveMachineNameSeparator = (
          <span>
            <br />
            &nbsp;&nbsp;
          </span>
        );
      } else {
        productiveMachineNameSeparator = ": ";
      }
      productiveMachineName = productiveMachine.name;
    }
    const notes: string[] = [];
    if (this.props.customerSettings.taskNoteLinesOnTaskOrderCalender) {
      if (this.props.notesFromMachineOperator) {
        notes.push(`M: ${this.props.notesFromMachineOperator}`);
      }
      if (this.props.notesFromManager) {
        notes.push(`A: ${this.props.notesFromManager}`);
      }
    }
    return (
      <div style={{padding: "0px 4px 0px 4px", width: "100%"}}>
        <span
          style={{
            color: initials ? matchingTextColor(colorMap.ORDER) : colorMap.ERROR,
            float: "right",
          }}
        >
          {initials || "???"}
        </span>
        <span style={{color: matchingTextColor(colorMap.ORDER)}}>
          {machineNumbers}
          {productiveMachineNameSeparator}
          {productiveMachineName}
        </span>
        {notes.length ? (
          <div
            style={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: this.props.customerSettings.taskNoteLinesOnTaskOrderCalender,
              whiteSpace: "pre-line",
            }}
          >
            {notes.join("\n")}
          </div>
        ) : null}
      </div>
    );
  }
}

interface OrderBlockOwnProps {
  completed: boolean;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  customerURLToDefaultContactMap: ReadonlyMap<string, Contact>;
  dndMode: boolean;
  draggable: boolean;
  folded: boolean;
  forDate: string;
  isManager: boolean;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  onDrop: (
    item: {orderUrl: OrderUrl; taskUrl?: TaskUrl},
    toDate: string,
    onOrder: Order,
    onTask?: Task,
  ) => void;
  onEditTaskClick: (orderId: OrderUrl, taskId: string) => void;
  onGoToOrder: (orderId: string) => void;
  onGoToTask?: (taskId: string) => void;
  order: Order;
  orderTasksForDate: readonly Task[];
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  taskList: readonly Task[];
  timerStartArray: readonly TimerStart[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface OrderBlockDragSourceProps {
  connectDragSource: ConnectDragSource;
  isDragging: boolean;
}

interface OrderBlockDropTargetProps {
  connectDropTarget: ConnectDropTarget;
  isOver: boolean;
}

type OrderBlockProps = OrderBlockDragSourceProps & OrderBlockDropTargetProps & OrderBlockOwnProps;
function OrderBlockImpl(props: OrderBlockProps): React.JSX.Element {
  const projectLookup = useSelector(getProjectLookup);
  const {
    completed,
    connectDragSource,
    connectDropTarget,
    customerLookup,
    customerSettings,
    customerURLToDefaultContactMap,
    dndMode,
    draggable,
    folded,
    forDate,
    isDragging,
    machineLookup,
    onEditTaskClick,
    onGoToOrder,
    onGoToTask,
    order,
    orderTasksForDate,
    routePlanLookup,
    taskList,
    timerStartArray,
    userUserProfileLookup,
    workTypeLookup,
  } = props;
  const handleGoTo = useCallback((): void => {
    if (customerSettings.orderCalendarAsTaskCalendar) {
      if (taskList.length !== 1 || !onGoToTask) {
        return;
      }
      onGoToTask(urlToId(taskList[0].url));
    } else {
      onGoToOrder(urlToId(order.url));
    }
  }, [customerSettings.orderCalendarAsTaskCalendar, onGoToOrder, onGoToTask, order.url, taskList]);

  const handleEditTaskClick = useCallback((): void => {
    if (!customerSettings.orderCalendarAsTaskCalendar || taskList.length !== 1) {
      return;
    }
    onEditTaskClick(order.url, urlToId(taskList[0].url));
  }, [customerSettings.orderCalendarAsTaskCalendar, onEditTaskClick, order.url, taskList]);

  const forDateStr = forDate;
  let orderWorkTypeURL;
  const currentRole = useSelector(getCurrentRole);

  const isManager = currentRole && currentRole.manager;

  if (!customerSettings.noExternalTaskWorkType) {
    const oldestTaskWithWorkType = _.minBy(
      taskList.filter((t) => t.workType),
      (task) => task.created,
    );
    if (oldestTaskWithWorkType) {
      orderWorkTypeURL = oldestTaskWithWorkType.workType;
    }
  }
  const orderWorkType = (orderWorkTypeURL && workTypeLookup(orderWorkTypeURL)) || undefined;

  const showEditButton = isManager && customerSettings.orderCalendarAsTaskCalendar;
  let opacity;
  if (isDragging) {
    console.assert(draggable);
    opacity = IS_DRAGGING_OPACITY;
  } else if (dndMode) {
    if (draggable) {
      opacity = DND_MODE_DRAGGABLE_OPACITY;
    } else {
      opacity = UNDRAGGABLE_OPACITY;
    }
  } else if (completed) {
    if (
      customerSettings.orderCalendarAsTaskCalendar &&
      !customerSettings.taskCalendarFadeCompletedTasks
    ) {
      opacity = TASK_COMPLETED_OPACITY;
    } else {
      opacity = ORDER_COMPLETED_OPACITY;
    }
  }
  const orderBlockStyle: React.CSSProperties = {
    backgroundColor: colorMap.ORDER,
    borderColor: grey[700],
    borderStyle: "solid",
    borderWidth: "1px",
    fontSize: "10pt",
    lineHeight: "20px",
    margin: 4,
    overflow: "hidden",
    position: "relative",
  };
  if (!showEditButton) {
    orderBlockStyle.cursor = "pointer";
  }
  if (opacity != null) {
    orderBlockStyle.opacity = opacity;
  }
  if (dndMode) {
    orderBlockStyle.userSelect = "none";
  }
  const truncateStyle: React.CSSProperties = {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  const textPadding = "0px 4px 0px 4px";
  const customerURL = order ? order.customer : null;
  let customer: Customer | undefined;
  let contact: Contact | undefined;
  if (customerURL) {
    customer = customerLookup(customerURL);
    contact = customerURLToDefaultContactMap.get(customerURL);
  }
  let projectNumber = "";
  if (
    customerSettings.orderCalendarAsTaskCalendar &&
    customerSettings.orderCalendarAsTaskCalendarBlockExtraInfo === "projectNumber" &&
    taskList.length === 1
  ) {
    projectNumber = taskList[0].project
      ? projectLookup(taskList[0].project)?.projectNumber || ""
      : "";
  }

  const routeplan = order.routePlan ? routePlanLookup(order.routePlan) : undefined;

  const customerNameDiv = (
    <CustomerBlock
      contact={contact}
      customer={customer}
      onClick={handleGoTo}
      projectNumber={projectNumber}
      referenceNumber={order.referenceNumber}
      routePlan={routeplan}
      taskList={props.taskList}
    />
  );
  const orderType = (
    <OrderTypeBlock
      onClick={handleGoTo}
      onEditClick={showEditButton ? handleEditTaskClick : undefined}
      orderWorkType={orderWorkType}
    />
  );
  const taskMachineDivs = orderTasksForDate.map((task) => {
    const machineOperatorURL = task.machineOperator;
    const machineOperatorProfile = machineOperatorURL
      ? userUserProfileLookup(machineOperatorURL)
      : undefined;
    const machineList = (task.machineuseSet || [])
      .map((machineUse) => {
        const machineURL = machineUse.machine;
        return machineLookup(machineURL);
      })
      .filter(notUndefined);
    return (
      <TaskMachineBlock
        customerSettings={customerSettings}
        key={urlToId(task.url)}
        machineList={machineList}
        machineOperatorProfile={machineOperatorProfile}
        notesFromMachineOperator={task.notesFromMachineOperator}
        notesFromManager={task.notesFromManager}
      />
    );
  });
  const timeChars = 5;
  const startTimeStr = _.min(
    orderTasksForDate
      .filter((task) => {
        return task.workFromTimestamp || task.time || task.arrivalAtLocation;
      })
      .map((task) => {
        console.assert(task.workFromTimestamp || task.date);
        let timeStr = "";
        let dateStr = "";
        if (task.workFromTimestamp) {
          timeStr = new Date(task.workFromTimestamp).toTimeString().substring(0, timeChars);
          dateStr = dateToString(new Date(task.workFromTimestamp));
        } else {
          console.assert(task.time || task.arrivalAtLocation);
          timeStr = (task.time || task.arrivalAtLocation) as string;
          console.assert(task.date);
          dateStr = task.date as string;
        }

        if (dateStr < forDateStr) {
          return "00:00";
        } else {
          return timeStr.substring(0, timeChars);
        }
      }),
  );
  let endTimeStr;
  if (startTimeStr) {
    endTimeStr = _.max(
      orderTasksForDate
        .filter((task) => {
          return (task.completed && task.workToTimestamp) || task.minutesExpectedTotalTaskDuration;
        })
        .map((task) => {
          console.assert(task.workFromTimestamp || task.date);
          let date = task.workFromTimestamp
            ? new Date(task.workFromTimestamp)
            : (dateFromString(task.date as string) as Date);

          if (!task.completed) {
            const timeStr =
              (task.workFromTimestamp &&
                new Date(task.workFromTimestamp).toTimeString().substring(0, timeChars)) ||
              task.time ||
              startTimeStr;
            const duration = task.minutesExpectedTotalTaskDuration as number;
            date.setHours(0, minutesSinceMidnightFromTimeIsoString(timeStr));
            date.setUTCMinutes(date.getUTCMinutes() + duration);
          } else if (task.workToTimestamp) {
            date = new Date(task.workToTimestamp);
          }
          if (forDateStr < dateToString(date)) {
            return "24:00";
          } else {
            return date.toTimeString().substring(0, timeChars);
          }
        }),
    );
  }
  let taskTimeRange;
  if (startTimeStr) {
    taskTimeRange = (
      <span>
        {startTimeStr}&nbsp;-&nbsp;{endTimeStr || "??:??"}
      </span>
    );
  }
  let taskDuration;
  if (startTimeStr && endTimeStr) {
    const minutesStart = minutesSinceMidnightFromTimeIsoString(startTimeStr);
    const minutesEnd = minutesSinceMidnightFromTimeIsoString(endTimeStr);
    const duration = minutesEnd - minutesStart;
    if (duration > 0) {
      const hours = Math.floor(duration / HOUR_MINUTES);
      const minutes = duration % HOUR_MINUTES;
      taskDuration = (
        <span>
          ,&nbsp;
          {hours}
          &nbsp;
          {"t."}
          {minutes > 0 ? ` ${minutes} min.` : null}
        </span>
      );
    }
  }
  let taskTimeInfo;
  if (startTimeStr) {
    taskTimeInfo = (
      <div style={truncateStyle}>
        {taskTimeRange}
        {taskDuration}
      </div>
    );
  }
  let taskStatusIcon;
  if (customerSettings.orderCalendarAsTaskCalendar && taskList.length === 1) {
    taskStatusIcon = (
      <div style={{bottom: 0, position: "absolute", right: 0}}>
        <TaskStatusIcon task={taskList[0]} timerStartArray={timerStartArray} />
      </div>
    );
  }
  let details;
  if (!folded) {
    details = (
      <div onClick={handleGoTo} style={{cursor: "pointer", position: "relative"}}>
        {taskMachineDivs}
        <div style={{marginTop: 8, padding: textPadding}}>
          {taskTimeInfo}
          {customerSettings.orderCalendarShowCreatedDate ? (
            <FormattedMessage
              defaultMessage="Oprettet: {date}"
              id="order-calendar.task-block.created"
              values={{date: formatDate(order.created)}}
            />
          ) : null}
          {customerSettings.orderCalendarShowOrderNoteLines ? (
            <div
              style={{
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: customerSettings.orderCalendarShowOrderNoteLines,
                whiteSpace: "pre-line",
              }}
            >
              {order.notes}
            </div>
          ) : null}
          {isManager && customerSettings.showManagerInternalOrderNoteLinesOnOrderCalendar ? (
            <div
              style={{
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: customerSettings.showManagerInternalOrderNoteLinesOnOrderCalendar,
                whiteSpace: "pre-line",
              }}
            >
              {order.managerInternalNotes}
            </div>
          ) : null}
        </div>
        {taskStatusIcon}
      </div>
    );
  }
  return connectDragSource(
    connectDropTarget(
      <div style={orderBlockStyle}>
        {orderType}
        {customerNameDiv}
        {details}
      </div>,
    ),
  ) as React.JSX.Element;
}

const orderTarget = {
  drop(props: OrderBlockOwnProps, monitor: DropTargetMonitor) {
    const {customerSettings, forDate, order, taskList} = props;
    let task: Task | undefined;
    if (customerSettings.orderCalendarAsTaskCalendar && taskList.length === 1) {
      task = taskList[0];
    }
    props.onDrop(monitor.getItem(), forDate, order, task);
  },
};

const orderSource = {
  beginDrag(props: OrderBlockOwnProps) {
    const {customerSettings, forDate, order, taskList} = props;
    let taskUrl: string | undefined;
    if (customerSettings.orderCalendarAsTaskCalendar && taskList.length === 1) {
      taskUrl = taskList[0].url;
    }
    return {
      fromDate: forDate,
      orderUrl: order.url,
      taskUrl,
    };
  },
  canDrag(props: OrderBlockOwnProps) {
    return props.dndMode && props.draggable;
  },
};

const DropTargetOrderBlock = DropTarget("order", orderTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(OrderBlockImpl);

export const OrderBlock = DragSource("order", orderSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(DropTargetOrderBlock);
