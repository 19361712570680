import {ReadonlyConfig} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {roleIsConsultant} from "@co-common-libs/utils";
import {SyncedResourceHandleKey} from "integration-constants";

const resourcesThatSupportsBeingTurnedOff: ReadonlySet<SyncedResourceHandleKey> = new Set([
  "location",
  "project",
]);

export type ExtendedResourcesSyncSettingsPart = Pick<ReadonlyConfig, "syncResources">;

export interface ExtendedResourcesSettings {
  canManageSync: (resource: SyncedResourceHandleKey) => boolean;
  syncEnabled: (resource: SyncedResourceHandleKey) => boolean;
}

export function extendedResourcesSettings(
  config: ExtendedResourcesSyncSettingsPart,
  role: Role | null,
): ExtendedResourcesSettings {
  const {syncResources} = config;

  const syncResourcesSet: ReadonlySet<string> = new Set(syncResources);

  return {
    canManageSync: (resource) =>
      roleIsConsultant(role) && resourcesThatSupportsBeingTurnedOff.has(resource),
    syncEnabled: (resource) => syncResourcesSet.has(resource),
  };
}
