import {Config} from "@co-common-libs/config";
import {formatDateNumeric} from "@co-common-libs/utils";
import {FilePdfIcon} from "@co-frontend-libs/components";
import {Card, CardContent, CardHeader, Checkbox, FormControlLabel} from "@material-ui/core";
import {AjaxDownloadButton} from "app-components";
import {PureComponent, useEventTargetCheckedCallback} from "app-utils";
import {globalConfig} from "frontend-global-config";
import FileDelimitedIcon from "mdi-react/FileDelimitedIcon";
import React, {useState} from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {ReportOverviewRow, ReportOverviewTable} from "./report-overview-table";
import {ReportPie} from "./report-pie";
import {ReportResultsTotalsTable} from "./report-results-totals-table";
import {ReportData} from "./types";

const messages = defineMessages({
  csv: {
    defaultMessage: "CSV",
  },
  csvFilename: {
    defaultMessage: "Områderapport {fromDate} - {toDate}.csv",
    id: "report-display-card.label.csvFilename",
  },
  internalTime: {
    defaultMessage: "Intern tid",
    id: "report-display-card.label.internal-time",
  },
  other: {
    defaultMessage: "Anden effektiv tid",
    id: "report-display-card.label.other-effective",
  },
  pdf: {
    defaultMessage: "PDF",
  },
  pdfFilename: {
    defaultMessage: "Områderapport {fromDate} - {toDate}.pdf",
    id: "report-display-card.label.pdfFilename",
  },
  title: {
    defaultMessage: "Rapport {from} - {to}",
    id: "report-display-card.label.title",
  },
  total: {
    defaultMessage: "Tilsammen",
    id: "report-display-card.label.total",
  },
});

interface FilterListProps {
  name: string;
  strings: readonly string[];
}

export class FilterList extends PureComponent<FilterListProps> {
  render(): React.JSX.Element | null {
    const {name, strings} = this.props;
    if (strings.length > 0) {
      return (
        <div>
          <strong>{name}:</strong> {strings.join(", ")}
        </div>
      );
    }
    return null;
  }
}

interface ReportDisplayCardProps {
  customerSettings: Config;
  reportData: ReportData;
  token: string | null;
}

export const ReportDisplayCard = React.memo(function ReportDisplayCard(
  props: ReportDisplayCardProps,
): React.JSX.Element {
  const {formatMessage} = useIntl();
  const {customerSettings, reportData} = props;
  const {materialDecimals, reportHourDecimals: hourDecimals} = customerSettings;

  const fromDate = formatDateNumeric(reportData.fromTimestamp);
  const toDate = formatDateNumeric(reportData.toTimestamp);
  let validFromDate = fromDate;
  let alertBlock;
  const [distribution, setDistribution] = useState(true);
  const reportDataWithDistributionOption = {
    ...reportData,
    distribution,
  };
  if (reportData.validDataAlert) {
    validFromDate = formatDateNumeric(reportData.validDataAlert);
    alertBlock = (
      <FormattedMessage
        defaultMessage="Advarsel: Startdato rettet fra {fromDate} til {validFromDate}, da der først findes data for perioden fra {validFromDate}"
        id="report-display-card.label.validDataAlert"
        values={{
          fromDate,
          validFromDate,
        }}
      />
    );
  }
  const pdfUrl = `${globalConfig.baseURL}/download/report/pdf`;
  const pdfDownload = (
    <div
      style={{
        position: "absolute",
        right: "1em",
        top: 14,
      }}
    >
      <AjaxDownloadButton
        data={reportDataWithDistributionOption}
        downloadURL={pdfUrl}
        filename={formatMessage(messages.pdfFilename, {
          fromDate: validFromDate,
          toDate,
        })}
        Icon={FilePdfIcon}
        label={formatMessage(messages.pdf)}
        token={props.token}
      />
    </div>
  );

  const csvUrl = `${globalConfig.baseURL}/download/report/csv`;
  const csvDownload = (
    <div
      style={{
        position: "absolute",
        right: 120,
        top: 14,
      }}
    >
      <AjaxDownloadButton
        data={reportDataWithDistributionOption}
        downloadURL={csvUrl}
        filename={formatMessage(messages.csvFilename, {
          fromDate: validFromDate,
          toDate,
        })}
        Icon={FileDelimitedIcon}
        label={formatMessage(messages.csv)}
        token={props.token}
      />
    </div>
  );
  const {effectiveTimeEntries, hours, otherEffectiveTime, otherEffectiveTimePercent} = reportData;
  return (
    <div>
      <Card style={{marginTop: "1em"}}>
        <div style={{position: "relative"}}>
          {pdfDownload}
          {csvDownload}
          <div
            style={{
              position: "absolute",
              right: 200,
              top: 12,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={distribution}
                  onChange={useEventTargetCheckedCallback(setDistribution, [setDistribution])}
                />
              }
              label={
                <FormattedMessage
                  defaultMessage="Vis fordeling"
                  id="calculate-card.show-distribution"
                />
              }
            />
          </div>
        </div>
        <CardHeader
          title={formatMessage(messages.title, {
            from: validFromDate,
            to: toDate,
          })}
        >
          {pdfDownload}
          {csvDownload}
        </CardHeader>
        <CardContent>
          {alertBlock}
          <Grid>
            <Cell>
              <FormattedMessage
                defaultMessage="Effektiv tid"
                id="report-display-card.label.effective-time"
                tagName="h3"
              />
              <ReportOverviewTable>
                <>
                  {distribution ? (
                    <>
                      {Object.keys(effectiveTimeEntries)
                        .sort()
                        .map((key) => {
                          const entry = effectiveTimeEntries[key];
                          return (
                            <ReportOverviewRow
                              hourDecimals={hourDecimals}
                              hours={entry?.hours ?? 0}
                              key={key}
                              share={entry?.percent}
                              title={key}
                            />
                          );
                        })}
                      <ReportOverviewRow
                        hourDecimals={hourDecimals}
                        hours={otherEffectiveTime}
                        share={otherEffectiveTimePercent}
                        title={formatMessage(messages.other)}
                      />
                    </>
                  ) : null}
                  <ReportOverviewRow
                    bold
                    hourDecimals={hourDecimals}
                    hours={hours.productive.effective.hours}
                    share={hours.productive.effective.percent}
                    title={formatMessage(messages.total)}
                  />
                </>
              </ReportOverviewTable>
              <br />
              <FormattedMessage
                defaultMessage="Intern tid"
                id="report-display-card.label.internal-time"
                tagName="h3"
              />
              <ReportOverviewTable>
                <>
                  {Object.keys(hours.nonProductive)
                    .sort()
                    .map((key) => {
                      const area = hours.nonProductive[key];
                      return (
                        <ReportOverviewRow
                          hourDecimals={hourDecimals}
                          hours={area.hours}
                          key={key}
                          share={area.percent}
                          title={area.name}
                        />
                      );
                    })}
                  {Object.keys(hours.nonProductiveSameColor).map((key) => {
                    const area = hours.nonProductiveSameColor[key];
                    return (
                      <ReportOverviewRow
                        hourDecimals={hourDecimals}
                        hours={area.hours}
                        key={key}
                        share={area.percent}
                        title={area.name}
                      />
                    );
                  })}
                  <ReportOverviewRow
                    bold
                    hourDecimals={hourDecimals}
                    hours={hours.totalNonProductive}
                    share={hours.totalNonProductivePercent}
                    title={formatMessage(messages.total)}
                  />
                </>
              </ReportOverviewTable>
            </Cell>
            <Cell>
              <ReportPie colors={reportData.colors} hours={reportData.hours} />
            </Cell>
          </Grid>
          <FormattedMessage
            defaultMessage="Resultater"
            id="report-display-card.label.results"
            tagName="h3"
          />
        </CardContent>
        <ReportResultsTotalsTable decimals={materialDecimals} results={reportData.results} />
      </Card>
    </div>
  );
});
