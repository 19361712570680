import {actions} from "@co-frontend-libs/redux";
import {Tab, Tabs} from "@material-ui/core";
import {PageLayout} from "app-components";
import {useQueryParameter} from "app-utils";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {MirrorPage} from "./mirror-page";
import {ResourceHistoryPage} from "./resource-history-page";

enum ConsultantToolsPageTab {
  Mirroring = "Mirroring",
  ResourceHistory = "ResourceHistory",
}

export function ConsultantToolsPage(): React.JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeTab = useQueryParameter<ConsultantToolsPageTab>(
    "tab",
    ConsultantToolsPageTab.Mirroring,
  );

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string): void => {
      dispatch(actions.putQueryKey("tab", value));
    },
    [dispatch],
  );

  return (
    <PageLayout
      tabs={
        <Tabs onChange={handleTabChange} value={activeTab}>
          <Tab
            label={intl.formatMessage({defaultMessage: "Spejling"})}
            value={ConsultantToolsPageTab.Mirroring}
          />
          <Tab
            label={intl.formatMessage({defaultMessage: "Resurse historik"})}
            value={ConsultantToolsPageTab.ResourceHistory}
          />
        </Tabs>
      }
      toolbar={intl.formatMessage({
        defaultMessage: "Konsulent værktøjer",
      })}
      withBottomScrollPadding={!ConsultantToolsPageTab.Mirroring}
      withPadding
    >
      {activeTab === ConsultantToolsPageTab.Mirroring && <MirrorPage />}
      {activeTab === ConsultantToolsPageTab.ResourceHistory && <ResourceHistoryPage />}
    </PageLayout>
  );
}
