import {DaysAbsence, HoursAbsence, Role} from "@co-common-libs/resources";
import React from "react";

const contentText: Readonly<React.CSSProperties> = {
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "14px",
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "uppercase",
  whiteSpace: "normal",
};

interface AbsenceBlockNoteProps {
  absence: DaysAbsence | HoursAbsence;
  currentRole: Role | null;
  currentUserURL: string | null;
}

export const AbsenceBlockNote = React.memo(function AbsenceBlockNote(
  props: AbsenceBlockNoteProps,
): React.JSX.Element | null {
  const {absence, currentRole, currentUserURL} = props;

  const isManager = currentRole && currentRole.manager;

  const {employeeReason, note, user} = absence;

  if (isManager && (note || employeeReason)) {
    const employeeString = employeeReason ? `M: ${employeeReason} ` : "";
    const adminString = note ? `(A: ${note})` : "";
    return <div style={contentText}>{employeeString + adminString}</div>;
  }

  if (currentUserURL === user && employeeReason) {
    return <div style={contentText}>{employeeReason}</div>;
  }

  return null;
});
