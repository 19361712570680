import {
  Machine,
  MachineUrl,
  PriceGroup,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentMachineFuelSurchargeUse,
} from "@co-common-libs/resources";
import {ConnectedPricePercentFuelSurchargeSpecificationDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getMachineArray,
  getPricePercentFuelSurchargeSpecificationLookup,
  getPricePercentMachineFuelSurchargeUseArray,
} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

const iconButtonWidth = 48;
const cellPadding = 16;
const iconColumnWidth = cellPadding + iconButtonWidth + cellPadding;

interface MachinePriceGroupPricePercentFuelSurchargeRowProps {
  machine: Machine;
  onEditClick: (machineUrl: MachineUrl) => void;
  onRemoveClick: (surChargeUse: string) => void;
  surChargeUse: PricePercentMachineFuelSurchargeUse | undefined;
}

function MachinePriceGroupPricePercentFuelSurchargeRow(
  props: MachinePriceGroupPricePercentFuelSurchargeRowProps,
): React.JSX.Element {
  const {machine, onEditClick, onRemoveClick, surChargeUse} = props;

  const surChargeUseUrl = surChargeUse?.url;
  const machineUrl = machine.url;

  const pricePercentFuelSurchargeSpecificationLookup = useSelector(
    getPricePercentFuelSurchargeSpecificationLookup,
  );

  const intl = useIntl();

  const handleEditClick = useCallback((): void => {
    onEditClick(machineUrl);
  }, [onEditClick, machineUrl]);

  const handleRemoveClick = useCallback((): void => {
    if (surChargeUseUrl) {
      onRemoveClick(surChargeUseUrl);
    }
  }, [onRemoveClick, surChargeUseUrl]);

  let surChargeUseText: string;
  if (surChargeUse) {
    if (!surChargeUse.fuelSurcharge) {
      surChargeUseText = intl.formatMessage({
        defaultMessage: "Ingen brændstoftillæg for maskine",
      });
    } else {
      const fuelSurcharge = pricePercentFuelSurchargeSpecificationLookup(
        surChargeUse.fuelSurcharge,
      );
      if (fuelSurcharge) {
        surChargeUseText = fuelSurcharge.name;
      } else {
        surChargeUseText = intl.formatMessage({
          defaultMessage: "Opslag fejlet",
        });
      }
    }
  } else {
    surChargeUseText = "";
  }

  return (
    <TableRow>
      <TableCell>{machine.name}</TableCell>
      <TableCell>
        {surChargeUseText}{" "}
        {surChargeUse ? (
          <IconButton onClick={handleRemoveClick}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </TableCell>
      <TableCell width={iconColumnWidth}>
        <IconButton onClick={handleEditClick}>
          <PencilIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

interface MachinePriceGroupPricePercentFuelSurchargeCardProps {
  priceGroup: PriceGroup;
}

export const MachinePriceGroupPricePercentFuelSurchargeCard = React.memo(
  function MachinePriceGroupPricePercentFuelSurchargeCard(
    props: MachinePriceGroupPricePercentFuelSurchargeCardProps,
  ): React.JSX.Element {
    const {priceGroup} = props;
    const priceGroupUrl = priceGroup.url;

    const machineArray = useSelector(getMachineArray);
    const pricePercentMachineFuelSurchargeUseArray = useSelector(
      getPricePercentMachineFuelSurchargeUseArray,
    );

    const intl = useIntl();

    const dispatch = useDispatch();

    const priceGroupSurchargeUses = useMemo(
      () =>
        pricePercentMachineFuelSurchargeUseArray.filter(
          (surchargeUse) =>
            surchargeUse.customer === null && surchargeUse.variant === priceGroupUrl,
        ),
      [priceGroupUrl, pricePercentMachineFuelSurchargeUseArray],
    );

    const machinesSurchargeUses = useMemo(
      () =>
        new Map(
          priceGroupSurchargeUses.map((surchargeUse) => [surchargeUse.machine, surchargeUse]),
        ),
      [priceGroupSurchargeUses],
    );

    const machines = _.sortBy(
      machineArray.filter(
        (machine) =>
          machinesSurchargeUses.has(machine.url) || machine.pricegroups.includes(priceGroupUrl),
      ),
      [(machine) => machine.name, (machine) => machine.url],
    );

    const [fuelSurchargeDialogOpenForMachine, setFuelSurchargeDialogOpenForMachine] =
      useState<MachineUrl>();

    const handleFuelSurchargeDialogCancel = useCallback(() => {
      setFuelSurchargeDialogOpenForMachine(undefined);
    }, []);

    const handleRemoveClick = useCallback(
      (fuelSurchargeUseUrl: string): void => {
        dispatch(actions.remove(fuelSurchargeUseUrl));
      },
      [dispatch],
    );

    const handleFuelSurchargeDialogOk = useCallback(
      (fuelSurchargeUrl?: PricePercentFuelSurchargeSpecificationUrl): void => {
        if (!fuelSurchargeDialogOpenForMachine) {
          return;
        }
        const machineUrl = fuelSurchargeDialogOpenForMachine;
        setFuelSurchargeDialogOpenForMachine(undefined);
        const currentUse = machinesSurchargeUses.get(machineUrl);

        const value = fuelSurchargeUrl ?? null;
        if (currentUse && value === currentUse.fuelSurcharge) {
          return;
        } else if (currentUse) {
          dispatch(actions.remove(currentUse.url));
        }
        const id = uuid();
        const url = instanceURL("pricePercentMachineFuelSurchargeUse", id);
        const instance: PricePercentMachineFuelSurchargeUse = {
          customer: null,
          fuelSurcharge: value,
          id,
          machine: machineUrl,
          url,
          variant: priceGroupUrl,
        };
        dispatch(actions.create(instance));
      },
      [dispatch, fuelSurchargeDialogOpenForMachine, priceGroupUrl, machinesSurchargeUses],
    );

    const rows = machines.map((machine) => (
      <MachinePriceGroupPricePercentFuelSurchargeRow
        key={machine.url}
        machine={machine}
        onEditClick={setFuelSurchargeDialogOpenForMachine}
        onRemoveClick={handleRemoveClick}
        surChargeUse={machinesSurchargeUses.get(machine.url)}
      />
    ));

    return (
      <>
        <Card>
          <CardHeader
            title={intl.formatMessage({
              defaultMessage: "Brændstoftillæg for brug ved maskiner",
            })}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Maskine" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Brændstoftillæg" />
                </TableCell>
                <TableCell width={iconColumnWidth} />
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </Card>
        <ConnectedPricePercentFuelSurchargeSpecificationDialog
          onCancel={handleFuelSurchargeDialogCancel}
          onNone={handleFuelSurchargeDialogOk}
          onOk={handleFuelSurchargeDialogOk}
          open={!!fuelSurchargeDialogOpenForMachine}
        />
      </>
    );
  },
);
