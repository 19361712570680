import {DecimalField, ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

interface LocationStorageStatusDialogProps {
  amount: number;
  onCancel: () => void;
  onOk: (amount: number) => void;
  open: boolean;
}

export function LocationStorageStatusDialog(
  props: LocationStorageStatusDialogProps,
): React.JSX.Element {
  const {amount, onCancel, onOk, open} = props;
  const [newAmount, setNewAmount] = useState<number | null>(amount);
  useEffect(() => {
    if (open) {
      setNewAmount(amount);
    }
  }, [amount, open]);
  const handleOk = useCallback(() => {
    if (newAmount != null) {
      onOk(newAmount);
    }
  }, [onOk, newAmount]);
  return (
    <ResponsiveDialog
      okDisabled={amount == null}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={
        <FormattedMessage defaultMessage="Ret lagerstand" id="location-storage.dialog-title.edit" />
      }
    >
      <DialogContent>
        <DecimalField
          autoFocus
          decimalPlaces={3}
          fullWidth
          label={
            <FormattedMessage
              defaultMessage="Ny lagerstand"
              id="location-storage.label.new-amount"
            />
          }
          margin="dense"
          maxDigits={10}
          onChange={setNewAmount}
          value={newAmount}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
