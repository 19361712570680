import type {Writable} from "ts-essentials";
import {
  Location,
  LocationUrl,
  ReportingLocation,
  ReportingLocations,
  ReportingSpecification,
  WorkplaceRegistration,
} from "@co-common-libs/resources";
import _ from "lodash";
import {v4 as uuid} from "uuid";

export function translateLogLocations(
  locationLookup: (url: LocationUrl) => Location | undefined,
  reportingLocations: ReportingLocations,
  reportingSpecification: ReportingSpecification,
  oldReportingSpecification: ReportingSpecification | undefined,
): ReportingLocations {
  const newReportingLocations: Writable<ReportingLocations> = {};
  for (const entry of Object.values(reportingLocations)) {
    if (
      reportingSpecification.workplaceRegistration === WorkplaceRegistration.workplace &&
      entry.type !== "workplace"
    ) {
      continue;
    } else if (
      reportingSpecification.workplaceRegistration === WorkplaceRegistration.transportation &&
      entry.type !== "pickup" &&
      entry.type !== "delivery"
    ) {
      continue;
    }
    const newReportingLocation: Writable<ReportingLocation> = {
      customer: entry.customer,
      order: entry.order,
      type: entry.type,
    };
    if (entry.location) {
      newReportingLocation.location = entry.location;
    }
    const locationInputs = reportingSpecification.workplaceData[entry.type]?.inputs;
    const oldLocationInputs = oldReportingSpecification?.workplaceData[entry.type]?.inputs;
    if (locationInputs) {
      const values: Record<string, unknown> = {};
      for (const input of locationInputs) {
        if (input.fieldAreaTarget) {
          const dataValue = entry.values?.[input.identifier];
          if (dataValue !== undefined) {
            const oldInput = oldLocationInputs?.find(
              ({identifier}) => identifier === input.identifier,
            );
            if (_.isEqual(input, oldInput)) {
              values[input.identifier] = dataValue;
              continue;
            }
          }
          const fieldAreaHa = entry.location && locationLookup(entry.location)?.fieldAreaHa;
          if (fieldAreaHa != null) {
            const decimals = 2;
            values[input.identifier] = _.floor(fieldAreaHa, decimals);
            continue;
          }
        }
        if (input.fieldCropTarget) {
          const fieldCrop = entry.location && locationLookup(entry.location)?.fieldCrop;
          if (fieldCrop) {
            values[input.identifier] = fieldCrop;
            continue;
          }
        }
      }

      if (Object.keys(values).length > 0) {
        newReportingLocation.values = values;
      }
    }
    newReportingLocations[uuid()] = newReportingLocation;
  }
  return newReportingLocations;
}
