import {Config} from "@co-common-libs/config";
import {
  Machine,
  MachineUrl,
  Patch,
  PatchUnion,
  Task,
  TaskUrl,
  TimerStart,
  urlToId,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import React from "react";
import {FormattedMessage} from "react-intl";
import {
  DATE_COLUMN_WIDTH,
  DRAG_HANDLE_CELL_WIDTH,
  DURATION_COLUMN_WIDTH,
  EMPLOYEE_COLUMN_WIDTH,
  MACHINES_COLUMN_WIDTH,
  MACHINES_NAME_COLUMN_WIDTH,
  MOBILE_STATUS_COLUMN_WIDTH,
  NOTES_COLUMN_WIDTH,
  PHOTO_ICON_COLUMN_WIDTH,
  STATUS_ICON_COLUMN_WIDTH,
  TABLE_MIN_WIDTH,
  VEHICLE_IDENTIFICATION_NUMBER_COLUMN_WIDTH,
  WORK_TYPE_COLUMN_WIDTH,
} from "./column-widths";
import {TodoSplitRow} from "./todo-split-row";
import {TodoTaskRow} from "./todo-task-row";

const NOT_PRIORITIZED_ID = "--not-prioritized--";
const PRIORITIZED_ID = "--prioritized--";

type Priority = typeof NOT_PRIORITIZED_ID | typeof PRIORITIZED_ID;

const INITIAL = Math.pow(2, 28);

const INCREMENT = Math.pow(2, 14);

const THRESHOLD = Math.pow(2, -14);

interface TodoTaskTableProps {
  customerSettings: Config;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  tasksWithPhotos: ReadonlySet<string>;
  timerStartArray: readonly TimerStart[];
  update: (url: string, patch: PatchUnion) => void;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  withoutPriority: readonly Task[];
  withPriority: readonly Task[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

export class TodoTaskTable extends PureComponent<TodoTaskTableProps> {
  @bind
  handleDropTask(movedURL: TaskUrl, targetURL: Priority | TaskUrl): void {
    // An attempt to implement something like
    // https://news.ycombinator.com/item?id=10957165
    const {withPriority} = this.props;
    if (!withPriority.length) {
      // withPriority is empty; so must have been dragged from below
      // "not prioritized"-header up to it or the "prioritized"-header
      console.assert(targetURL === PRIORITIZED_ID || targetURL === NOT_PRIORITIZED_ID);
      // give priority; further logic wrt. position/order among elements in
      // withPriority not necessary...
      const patch: Patch<Task> = [{member: "priority", value: INITIAL}];
      this.props.update(movedURL, patch);
      return;
    }
    // withPriority nonempty from here
    console.assert(withPriority.length);
    if (targetURL === NOT_PRIORITIZED_ID) {
      // target is the "not prioritized below this" header;
      // insert last in withPriority
      const oldLast = withPriority[withPriority.length - 1];
      console.assert(oldLast.priority != null);
      if (targetURL === oldLast.url) {
        // no-op; already last in withPriority
        return;
      }
      const newLastPriority = (oldLast.priority as number) + INCREMENT;
      const patch: Patch<Task> = [{member: "priority", value: newLastPriority}];
      this.props.update(movedURL, patch);
      return;
    }
    const moved = withPriority.find((t) => t.url === movedURL);
    const oldPriority = moved ? moved.priority : null;
    // if target is the "prioritized below this" header, then insert first;
    // i.e. dragging to the "prioritized"-header is equivalent to dragging
    // to the first element when withPriority nonempty
    const targetIndex =
      targetURL === PRIORITIZED_ID ? 0 : withPriority.findIndex((t) => t.url === targetURL);
    const target = withPriority[targetIndex];
    if (movedURL === target.url) {
      // no-op; dragged to self or to "prioritized"-header while already first
      return;
    }
    const targetPriority = target.priority as number;
    console.assert(targetPriority != null);
    let newPriority: number;
    let insertAfter: boolean;
    if (oldPriority != null && oldPriority < targetPriority) {
      // Old position of moved object was before/above target;
      // move it down past it.
      insertAfter = true;
      const afterTarget = withPriority[targetIndex + 1];
      if (afterTarget) {
        console.assert(afterTarget.priority != null);

        newPriority = (targetPriority + (afterTarget.priority as number)) / 2;
      } else {
        newPriority = targetPriority + INCREMENT;
      }
    } else {
      // Old position of moved object was after/below target;
      // (possibly due to not having a priority defined) move it up past it.
      insertAfter = false;
      const beforeTarget = targetIndex ? withPriority[targetIndex - 1] : null;
      if (beforeTarget) {
        console.assert(beforeTarget.priority != null);

        newPriority = (targetPriority + (beforeTarget.priority as number)) / 2;
      } else {
        newPriority = targetPriority / 2;
      }
    }
    if (Math.abs(targetPriority - newPriority) > THRESHOLD) {
      const patch: Patch<Task> = [{member: "priority", value: newPriority}];
      this.props.update(movedURL, patch);
    } else {
      // renumbers everything now...
      const newOrder = withPriority.map((task) => task.url).filter((url) => url !== movedURL);
      const newTargetIndex = newOrder.indexOf(target.url);
      if (insertAfter) {
        newOrder.splice(newTargetIndex + 1, 0, movedURL);
      } else {
        newOrder.splice(newTargetIndex, 0, movedURL);
      }
      for (let i = newOrder.length - 1; i >= 0; i -= 1) {
        const url = newOrder[i];
        const priority = INITIAL + i * INCREMENT;
        const patch: Patch<Task> = [{member: "priority", value: priority}];
        this.props.update(url, patch);
      }
    }
  }
  @bind
  handleRowClick(taskURL: string): void {
    const id = urlToId(taskURL);
    this.props.go("/task/:id", {id});
  }
  render(): React.JSX.Element {
    const {machineLookup, userUserProfileLookup, withoutPriority, withPriority, workTypeLookup} =
      this.props;
    const taskTimerStartArrayMap = new Map<string, TimerStart[]>();
    this.props.timerStartArray.forEach((timerStart) => {
      const taskURL = timerStart.task;
      let taskTimerStartArray = taskTimerStartArrayMap.get(taskURL);
      if (!taskTimerStartArray) {
        taskTimerStartArray = [];
        taskTimerStartArrayMap.set(taskURL, taskTimerStartArray);
      }
      taskTimerStartArray.push(timerStart);
    });
    const {customerSettings} = this.props;
    const machineColumnWidth = customerSettings.showWorkshopMachineName
      ? MACHINES_NAME_COLUMN_WIDTH
      : MACHINES_COLUMN_WIDTH;
    const taskRowList = [];
    taskRowList.push(
      <TodoSplitRow
        customerSettings={this.props.customerSettings}
        id={PRIORITIZED_ID}
        key={PRIORITIZED_ID}
        onDragDrop={this.handleDropTask}
      >
        <FormattedMessage defaultMessage="Prioriteret:" id="workshop.label.prioritized" />
      </TodoSplitRow>,
    );
    const addTaskToTaskRowList = (task: Task): void => {
      const machineOperatorURL = task.machineOperator;
      const machineOperatorProfile = machineOperatorURL
        ? userUserProfileLookup(machineOperatorURL)
        : undefined;
      const createdByUserURL = task.createdBy;
      const createdByUserProfile = createdByUserURL
        ? userUserProfileLookup(createdByUserURL)
        : undefined;
      const workTypeURL = task.workType;
      const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
      const machineList = (task.machineuseSet || [])
        .map((machineUse) => machineLookup(machineUse.machine))
        .filter(notUndefined);
      const taskURL = task.url;
      const hasPhoto = this.props.tasksWithPhotos.has(taskURL);
      const taskTimerStartArray = taskTimerStartArrayMap.get(taskURL) || [];
      taskRowList.push(
        <TodoTaskRow
          createdByUserProfile={createdByUserProfile}
          customerSettings={this.props.customerSettings}
          hasPhoto={hasPhoto}
          key={taskURL}
          machineList={machineList}
          machineOperatorProfile={machineOperatorProfile}
          onClick={this.handleRowClick}
          onDragDrop={this.handleDropTask}
          task={task}
          taskTimerStartArray={taskTimerStartArray}
          workType={workType}
        />,
      );
    };
    withPriority.forEach(addTaskToTaskRowList);
    taskRowList.push(
      <TodoSplitRow
        customerSettings={this.props.customerSettings}
        id={NOT_PRIORITIZED_ID}
        key={NOT_PRIORITIZED_ID}
        onDragDrop={this.handleDropTask}
      >
        <FormattedMessage defaultMessage="Ikke prioriteret:" id="workshop.label.not-prioritized" />
      </TodoSplitRow>,
    );
    withoutPriority.forEach(addTaskToTaskRowList);
    let dragHandleCell;
    if (bowser.mobile || bowser.tablet) {
      const style = {
        width: DRAG_HANDLE_CELL_WIDTH,
      };
      dragHandleCell = <TableCell style={style} />;
    }
    let vinHeaderColumn;
    if (customerSettings.workshopVehicleIdentificationNumber) {
      vinHeaderColumn = (
        <TableCell
          style={{
            width: VEHICLE_IDENTIFICATION_NUMBER_COLUMN_WIDTH,
          }}
        >
          <FormattedMessage
            defaultMessage="Stelnr."
            id="workshop.table-header.vehicle-identification-number-short"
          />
        </TableCell>
      );
    }
    let createdByHeaderColumn;
    if (customerSettings.workshopCreatedByColumn && !bowser.mobile) {
      createdByHeaderColumn = (
        <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}}>
          <FormattedMessage defaultMessage="Opr. af" id="workshop.table-header.created-by-short" />
        </TableCell>
      );
    }
    let createdDateHeaderColumn;
    if (customerSettings.workshopCreatedDateColumn && !bowser.mobile) {
      createdDateHeaderColumn = (
        <TableCell style={{width: DATE_COLUMN_WIDTH}}>
          <FormattedMessage defaultMessage="Opr. dato" id="workshop.table-header.created-date" />
        </TableCell>
      );
    }

    const STATUS_ICON_COLUMN = bowser.mobile
      ? MOBILE_STATUS_COLUMN_WIDTH
      : STATUS_ICON_COLUMN_WIDTH;

    let worktypeHeaderColumn;
    let photoHeaderColumn;
    if (!bowser.mobile) {
      worktypeHeaderColumn = (
        <TableCell
          style={{
            paddingRight: 0,
            width: WORK_TYPE_COLUMN_WIDTH,
          }}
        >
          <FormattedMessage defaultMessage="Område" id="workshop.table-header.task-type" />
        </TableCell>
      );

      photoHeaderColumn = (
        <TableCell
          style={{
            width: PHOTO_ICON_COLUMN_WIDTH,
          }}
        />
      );
    }
    const tableColumnsWidth =
      (worktypeHeaderColumn ? WORK_TYPE_COLUMN_WIDTH : 0) +
      machineColumnWidth +
      (vinHeaderColumn ? VEHICLE_IDENTIFICATION_NUMBER_COLUMN_WIDTH : 0) +
      NOTES_COLUMN_WIDTH +
      EMPLOYEE_COLUMN_WIDTH +
      DURATION_COLUMN_WIDTH +
      (createdByHeaderColumn ? EMPLOYEE_COLUMN_WIDTH : 0) +
      (createdDateHeaderColumn ? DATE_COLUMN_WIDTH : 0) +
      (photoHeaderColumn ? PHOTO_ICON_COLUMN_WIDTH : 0) +
      STATUS_ICON_COLUMN +
      (dragHandleCell ? DRAG_HANDLE_CELL_WIDTH : 0);
    const tableMinWidth = Math.max(tableColumnsWidth, TABLE_MIN_WIDTH);
    return (
      <Table stickyHeader style={{minWidth: tableMinWidth}}>
        <TableHead>
          <TableRow>
            {worktypeHeaderColumn}
            <TableCell
              style={{
                width: machineColumnWidth,
              }}
            >
              {customerSettings.machineLabelVariant === "MACHINE" ? (
                <FormattedMessage defaultMessage="Mask." />
              ) : (
                <FormattedMessage defaultMessage="Køret." />
              )}
            </TableCell>
            {vinHeaderColumn}
            <TableCell style={{minWidth: NOTES_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Noter" id="workshop.table-header.notes" />
            </TableCell>
            <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}}>
              <FormattedMessage
                defaultMessage="Medarb."
                id="workshop.table-header.employee-short"
              />
            </TableCell>
            <TableCell style={{width: DURATION_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Varighed" id="workshop.table-header.duration" />
            </TableCell>
            {createdByHeaderColumn}
            {createdDateHeaderColumn}
            {photoHeaderColumn}
            <TableCell
              style={{
                width: STATUS_ICON_COLUMN,
              }}
            />
            {dragHandleCell}
          </TableRow>
        </TableHead>
        <TableBody>{taskRowList}</TableBody>
      </Table>
    );
  }
}
