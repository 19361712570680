import {Location, LocationUrl} from "@co-common-libs/resources";
import {IconButton} from "@material-ui/core";
import {getGoogleMapsUrl, getWorkplaceString, PureComponent} from "app-utils";
import bowser from "bowser";
import MapIcon from "mdi-react/MapIcon";
import React from "react";

interface WorkplaceLineProps {
  locationLookup: (url: LocationUrl) => Location | undefined;
  workplaceURL?: LocationUrl | undefined;
}

export class WorkplaceLine extends PureComponent<WorkplaceLineProps> {
  render(): React.JSX.Element | null {
    const {locationLookup, workplaceURL} = this.props;
    if (!workplaceURL) {
      return null;
    }
    const location = locationLookup(workplaceURL);
    if (!location) {
      return null;
    }
    const mapsURL = getGoogleMapsUrl(location);
    let link;
    if (mapsURL) {
      link = (
        <IconButton
          color="primary"
          href={mapsURL}
          rel="noopener noreferrer"
          target={bowser.ios ? "_system" : "_blank"}
        >
          <MapIcon />
        </IconButton>
      );
    }
    // https://www.google.com/maps/search/?api=1&query=centurylink+field
    return (
      <h4>
        {getWorkplaceString(workplaceURL, locationLookup)} {link}
      </h4>
    );
  }
}
