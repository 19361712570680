import {
  CsvImportColumnSpecificationValidatePart,
  CsvImportColumnSpecificationValidateTypePart,
} from "@co-common-libs/csv-import-specifications";
import {IntlShape} from "react-intl";
import {ValueParseError} from "./parse-validate";

const VALIDATION_ERROR_LIMIT = 5;

function reportRequiredMissing(intl: IntlShape, errorIndexes: readonly number[]): string {
  const prettiedRowIndexText = errorIndexes.slice(0, VALIDATION_ERROR_LIMIT).join(", ");

  if (errorIndexes.length > VALIDATION_ERROR_LIMIT) {
    return intl.formatMessage(
      {
        defaultMessage:
          "Der mangler værdier i {length, number} rækker, bl.a. i række: {rowIndexText}.",
      },
      {
        length: errorIndexes.length,
        rowIndexText: prettiedRowIndexText,
      },
    );
  } else {
    return intl.formatMessage(
      {
        defaultMessage:
          "{length, plural, one {Række nr.} other {Rækker nr.}} {rowIndexText} mangler {length, plural, one {en værdi} other {værdier}}.",
      },
      {
        length: errorIndexes.length,
        rowIndexText: prettiedRowIndexText,
      },
    );
  }
}

// TypeScript validates that this always returns strings
// eslint-disable-next-line consistent-return
function reportWrongFormat(
  intl: IntlShape,
  column: CsvImportColumnSpecificationValidateTypePart,
  errorIndexes: readonly number[],
): string {
  const prettiedRowIndexText = errorIndexes.slice(0, VALIDATION_ERROR_LIMIT).join(", ");

  switch (column.type) {
    case "boolean":
      if (errorIndexes.length > VALIDATION_ERROR_LIMIT) {
        return intl.formatMessage(
          {
            defaultMessage:
              "Der er {length, number} rækker hvis værdier ikke blev genkendt som Sandt/Falsk værdier, det gælder bl.a. rækkerne: {rowIndexText}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage:
              "{length, plural, one {Række nr.} other {Rækker nr.}} {rowIndexText} blev ikke genkendt som {length, plural, one {en Sandt/Falsk værdi} other {Sandt/Falsk værdier}}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      }
      // should not satisfy must-return-string by fall-through
      break;
    case "choices":
      if (errorIndexes.length > VALIDATION_ERROR_LIMIT) {
        return intl.formatMessage(
          {
            defaultMessage:
              "Der er {length, number} rækker som ikke angiver tilladte værdier, det gælder bl.a. rækkerne: {rowIndexText}. Tilladte værdier er: {choices}",
          },
          {
            choices: column.choices.join(", "),
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage:
              "{length, plural, one {Række nr.} other {Rækker nr.}} {rowIndexText} angiver ikke {length, plural, one {en tilladt værdi} other {tilladte værdier}}. Tilladte værdier er: {choices}",
          },
          {
            choices: column.choices.join(", "),
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      }
      // should not satisfy must-return-string by fall-through
      break;
    case "color":
      if (errorIndexes.length > VALIDATION_ERROR_LIMIT) {
        return intl.formatMessage(
          {
            defaultMessage:
              "Der er {length, number} rækker hvis værdier ikke blev genkendt som hex-farver, det gælder bl.a. rækkerne: {rowIndexText}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage:
              "{length, plural, one {Række nr.} other {Rækker nr.}} {rowIndexText} blev ikke genkendt som {length, plural, one {en hex-farve} other {hex-farver}}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      }
      // should not satisfy must-return-string by fall-through
      break;
    case "cvr":
      if (errorIndexes.length > VALIDATION_ERROR_LIMIT) {
        return intl.formatMessage(
          {
            defaultMessage:
              "Der er {length, number} rækker hvis værdier ikke blev genkendt som CVR-numre (8 cifre), det gælder bl.a. rækkerne: {rowIndexText}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage:
              "{length, plural, one {Række nr.} other {Rækker nr.}} {rowIndexText} blev ikke genkendt som {length, plural, one {et CVR-nummer} other {CVR-numre}} (8 cifre).",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      }
      // should not satisfy must-return-string by fall-through
      break;
    case "date":
      if (errorIndexes.length > VALIDATION_ERROR_LIMIT) {
        return intl.formatMessage(
          {
            defaultMessage:
              "Der er {length, number} rækker hvis værdier ikke blev genkendt som datoer, det gælder bl.a. rækkerne: {rowIndexText}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage:
              "{length, plural, one {Række nr.} other {Rækker nr.}} {rowIndexText} blev ikke genkendt som {length, plural, one {en dato} other {datoer}}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      }
      // should not satisfy must-return-string by fall-through
      break;
    case "decimal":
      if (errorIndexes.length > VALIDATION_ERROR_LIMIT) {
        return intl.formatMessage(
          {
            defaultMessage:
              "Der er {length, number} rækker hvis værdier ikke blev genkendt som talværdier, det gælder bl.a. rækkerne: {rowIndexText}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage:
              "{length, plural, one {Række nr.} other {Rækker nr.}} {rowIndexText} blev ikke genkendt som {length, plural, one {en talværdi} other {talværdier}}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      }
      // should not satisfy must-return-string by fall-through
      break;
    case "email":
      if (errorIndexes.length > VALIDATION_ERROR_LIMIT) {
        return intl.formatMessage(
          {
            defaultMessage:
              "Der er {length, number} rækker hvis værdier ikke blev genkendt som e-mail-adesser, det gælder bl.a. rækkerne: {rowIndexText}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage:
              "{length, plural, one {Række nr.} other {Rækker nr.}} {rowIndexText} blev ikke genkendt som {length, plural, one {en e-mail-adesse} other {e-mail-adesser}}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      }
      // should not satisfy must-return-string by fall-through
      break;
    case "integer":
      if (errorIndexes.length > VALIDATION_ERROR_LIMIT) {
        return intl.formatMessage(
          {
            defaultMessage:
              "Der er {length, number} rækker hvis værdier ikke blev genkendt som heltalsværdier, det gælder bl.a. rækkerne: {rowIndexText}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage:
              "{length, plural, one {Række nr.} other {Rækker nr.}} {rowIndexText} blev ikke genkendt som {length, plural, one {en heltalsværdi} other {heltalsværdier}}.",
          },
          {
            length: errorIndexes.length,
            rowIndexText: prettiedRowIndexText,
          },
        );
      }
      // should not satisfy must-return-string by fall-through
      break;
    case "string":
      throw new Error("WRONG_FORMAT should never occur for strings");
  }
}

function reportUnknownReference(intl: IntlShape, errorIndexes: readonly number[]): string {
  const prettiedRowIndexText = errorIndexes.slice(0, VALIDATION_ERROR_LIMIT).join(", ");

  if (errorIndexes.length > VALIDATION_ERROR_LIMIT) {
    return intl.formatMessage(
      {
        defaultMessage:
          "Der er {length, number} rækker hvis værdier ikke er blandt de mulige/tilladte, det gælder bl.a. rækkerne: {rowIndexText}.",
      },
      {
        length: errorIndexes.length,
        rowIndexText: prettiedRowIndexText,
      },
    );
  } else {
    return intl.formatMessage(
      {
        defaultMessage:
          "{length, plural, one {Række nr.} other {Rækker nr.}} {rowIndexText} indeholder ikke {length, plural, one {en mulig/tilladt værdi} other {mulige/tiladte værdier}}.",
      },
      {
        length: errorIndexes.length,
        rowIndexText: prettiedRowIndexText,
      },
    );
  }
}

export function convertErrorsToStrings(
  incomingErrors: readonly {
    readonly error: ValueParseError;
    readonly errorIndex: number;
  }[],
  column: CsvImportColumnSpecificationValidatePart,
  intl: IntlShape,
): string[] {
  if (!incomingErrors.length) {
    return [];
  }

  const errorMapping = new Map<ValueParseError, number[]>();

  incomingErrors.forEach((incomingError) => {
    const baseOneErrorIndex = incomingError.errorIndex + 1;
    const existingErrorsOfType = errorMapping.get(incomingError.error);
    if (existingErrorsOfType) {
      existingErrorsOfType.push(baseOneErrorIndex);
    } else {
      errorMapping.set(incomingError.error, [baseOneErrorIndex]);
    }
  });

  const errorStringArray = Array.from(errorMapping).map(
    // TypeScript validates that this always returns strings
    // eslint-disable-next-line array-callback-return, consistent-return
    ([errorType, errorIndexes]): string => {
      switch (errorType) {
        case "REQIRED_MISSING":
          return reportRequiredMissing(intl, errorIndexes);
        case "UNKNOWN_REFERENCE":
          return reportUnknownReference(intl, errorIndexes);
        case "WRONG_FORMAT":
          return reportWrongFormat(intl, column, errorIndexes);
      }
    },
  );

  return errorStringArray;
}
