import {Config} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {AppState, getCustomerSettings} from "@co-frontend-libs/redux";
import {Checkbox, DialogContent, FormControlLabel} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {ProcessorAgreement} from "./processor-agreement";

const messages = defineMessages({
  approve: {
    defaultMessage: "Godkend",
    id: "processor-agreement-dialog.label.approve",
  },
  close: {
    defaultMessage: "Luk",
    id: "processor-agreement-dialog.label.close",
  },
  termsAccept: {
    defaultMessage:
      "Som dataansvarlig i virksomheden bekræfter jeg at der ikke optræder nogle af de oplysninger omtalt i Bilag A stk. 2.",
    id: "processor-agreement-dialog.label.termsAccept",
  },
  termsRead: {
    defaultMessage: "Jeg har læst og er indforstået med databehandleraftalen",
    id: "processor-agreement-dialog.label.termsRead",
  },
  title: {
    defaultMessage: "Godkend databehandleraftale",
    id: "processor-agreement-dialog.label.title",
  },
});

interface ProcessorAgreementDialogStateProps {
  customerSettings: Config;
}

interface ProcessorAgreementDialogOwnProps {
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

type ProcessorAgreementDialogProps = ProcessorAgreementDialogOwnProps &
  ProcessorAgreementDialogStateProps;

interface ProcessorAgreementDialogState {
  accepted: boolean;
  confirmed: boolean;
}

class ProcessorAgreementDialog extends PureComponent<
  ProcessorAgreementDialogProps,
  ProcessorAgreementDialogState
> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: ProcessorAgreementDialogState = {
    accepted: false,
    confirmed: false,
  };

  @bind
  handleCancel(): void {
    this.props.onCancel();
    this.setState({
      accepted: false,
      confirmed: false,
    });
  }

  @bind
  handleTermsAcceptChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.setState({accepted: checked});
  }

  @bind
  handleTermsReadChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.setState({confirmed: checked});
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {onOk, open} = this.props;
    const {accepted, confirmed} = this.state;

    return (
      <ResponsiveDialog
        cancelLabel={formatMessage(messages.close)}
        okDisabled={!confirmed || !accepted}
        okLabel={formatMessage(messages.approve)}
        onCancel={this.handleCancel}
        onOk={onOk}
        open={open}
        title={formatMessage(messages.title)}
      >
        <DialogContent>
          <ProcessorAgreement customerSettings={this.props.customerSettings} />
          <hr />
          <FormControlLabel
            control={<Checkbox checked={confirmed} onChange={this.handleTermsReadChanged} />}
            label={formatMessage(messages.termsRead)}
          />
          <FormControlLabel
            control={<Checkbox checked={accepted} onChange={this.handleTermsAcceptChanged} />}
            label={formatMessage(messages.termsAccept)}
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}

const ConnectedProcessorAgreementDialog: React.ComponentType<ProcessorAgreementDialogOwnProps> =
  connect<ProcessorAgreementDialogStateProps, object, ProcessorAgreementDialogOwnProps, AppState>(
    createStructuredSelector<AppState, ProcessorAgreementDialogStateProps>({
      customerSettings: getCustomerSettings,
    }),
    {},
  )(ProcessorAgreementDialog);

export {ConnectedProcessorAgreementDialog as ProcessorAgreementDialog};
