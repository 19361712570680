import bowser from "bowser";
import React, {useCallback, useState} from "react";
import {TASK_WIDTH} from "./constants";
import {useLongPress} from "./use-long-press";

const LONG_PRESS_DELAY_MS = 300;
const IS_DESKTOP = !bowser.mobile && !bowser.tablet;

function handleStopPropagation(event: React.MouseEvent<HTMLDivElement>): void {
  event.stopPropagation();
}
export const TaskDragHandle = React.memo(function TaskDragHandle({
  onLongPress,
  onMouseDown,
  onResizeStop,
}: {
  onLongPress?: (event: React.TouchEvent<HTMLDivElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onResizeStop?: (() => void) | undefined;
}): React.JSX.Element {
  const [active, setActive] = useState(false);

  const handleLongPress = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (onLongPress) {
        setActive(true);
        onLongPress(event);
      }
    },
    [onLongPress],
  );
  const {clearLongPressTimer, onTouchStart} = useLongPress<HTMLDivElement>(
    handleLongPress,
    LONG_PRESS_DELAY_MS,
  );

  const handleTouchEnd = useCallback(() => {
    if (onResizeStop) {
      onResizeStop();
    }
    clearLongPressTimer();
    setActive(false);
  }, [clearLongPressTimer, onResizeStop]);

  const style: React.CSSProperties = {
    bottom: 1,
    cursor: "s-resize",
    height: 8,
    position: "absolute",
    width: TASK_WIDTH,
  };
  if (active) {
    style.backgroundColor = "rgb(0 0 0 / 35%)";
  }

  const eventHandleProps: React.HTMLAttributes<HTMLDivElement> = {};
  if (IS_DESKTOP) {
    if (onMouseDown) {
      eventHandleProps.onMouseDown = onMouseDown;
    }
    if (onResizeStop) {
      eventHandleProps.onMouseUp = onResizeStop;
    }
  }
  if (onLongPress) {
    eventHandleProps.onTouchEnd = handleTouchEnd;
    eventHandleProps.onTouchMove = clearLongPressTimer;
    eventHandleProps.onTouchStart = onTouchStart;
  }

  return (
    <div
      className="taskDragHandle"
      onClick={handleStopPropagation}
      style={style}
      {...eventHandleProps}
    />
  );
});
