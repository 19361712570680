import {createReducer, Draft} from "@reduxjs/toolkit";
import _ from "lodash";
import * as actions from "./actions";

export type Position = {
  lat: number;
  lng: number;
  timestamp: string;
};

export type DeviceState = {
  lastKnownPosition: Position | null;
};

const initialState = {lastKnownPosition: null};

function lastKnownPosition(state: Draft<DeviceState>, payload: Position): void {
  state.lastKnownPosition = payload;
}

export const reducer = createReducer(initialState, (builder) =>
  builder.addCase(actions.lastKnownPosition, (state, action) =>
    lastKnownPosition(state, action.payload),
  ),
);
