import React from "react";
import {mergeOverlappingFromToSequence} from "./merge-overlapping-from-to-sequence";

export function emphasizeSubstrings(
  text: string,
  toEmphasize: readonly {readonly fromPos: number; readonly toPos: number}[],
): React.JSX.Element[] {
  const nonOverlappingToEmphasize = mergeOverlappingFromToSequence(toEmphasize);
  const parts: React.JSX.Element[] = [];
  let previousToPos = 0;
  for (const {fromPos, toPos} of nonOverlappingToEmphasize) {
    if (fromPos > previousToPos) {
      // always true except potentially for initial...
      const normalText = text.slice(previousToPos, fromPos);
      parts.push(<span key={previousToPos}>{normalText}</span>);
    }
    const emphasizedText = text.slice(fromPos, toPos);
    parts.push(<strong key={fromPos}>{emphasizedText}</strong>);
    previousToPos = toPos;
  }
  if (previousToPos < text.length) {
    const normalText = text.slice(previousToPos);
    parts.push(<span key={previousToPos}>{normalText}</span>);
  }
  return parts;
}
