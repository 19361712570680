import {
  CustomerUrl,
  LocationUrl,
  MachineUrl,
  PriceGroupUrl,
  urlToId,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {CircularProgress, Grid} from "@material-ui/core";
import {PeriodSelection} from "app-components";
import {useFetchGet} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useState} from "react";
import {FilteringSetup} from "../filtering-setup";
import {FilterOptions} from "../types";
import {DisplayResults} from "./display-results";
import {ResultEntry} from "./types";

export function TransportReport(): React.JSX.Element {
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);

  const [selectedInvoiceCustomers, setSelectedInvoiceCustomers] = useState<
    ReadonlySet<CustomerUrl>
  >(new Set());
  const [selectedLocationCustomers, setSelectedLocationCustomers] = useState<
    ReadonlySet<CustomerUrl>
  >(new Set());
  const [selectedWorkTypesPriceGroups, setSelectedWorkTypesPriceGroups] = useState<
    ReadonlyMap<WorkTypeUrl, ReadonlySet<PriceGroupUrl>>
  >(new Map());
  const [selectedMachinesPriceGroups, setSelectedMachinesPriceGroups] = useState<
    ReadonlyMap<MachineUrl, ReadonlySet<PriceGroupUrl>>
  >(new Map());
  const [selectedLocations, setSelectedLocations] = useState<ReadonlySet<LocationUrl>>(new Set());

  const [
    fetchFilterOptions,
    fetchFilterOptionsCancel,
    filterOptions,
    fetchingFilterOptions,
    // filterOptionsError,
  ] = useFetchGet<FilterOptions>();

  const [
    fetchResults,
    fetchResultsCancel,
    results,
    fetchingResults,
    // resultsError
  ] = useFetchGet<{
    data: ResultEntry[];
    fromDate: string;
    invoiceCustomers: string[];
    locationCustomers: string[];
    locations: string[];
    machines: {machine: string; priceGroups: string[]}[];
    toDate: string;
    workTypes: {priceGroups: string[]; workType: string}[];
  }>();

  const clearFilteringOptions = useCallback(() => {
    setSelectedInvoiceCustomers(new Set());
    setSelectedLocations(new Set());
    setSelectedLocationCustomers(new Set());
    setSelectedWorkTypesPriceGroups(new Map());
    setSelectedMachinesPriceGroups(new Map());
  }, []);

  useEffect(() => {
    // cancel/clear filtering data and results on period change
    clearFilteringOptions();
    fetchFilterOptionsCancel();
    fetchResultsCancel();
  }, [clearFilteringOptions, fetchFilterOptionsCancel, fetchResultsCancel, fromDate, toDate]);

  useEffect(() => {
    // cancel/clear result on filtering options change
    fetchResultsCancel();
  }, [
    fetchResultsCancel,
    selectedInvoiceCustomers,
    selectedLocationCustomers,
    selectedLocations,
    selectedMachinesPriceGroups,
    selectedWorkTypesPriceGroups,
  ]);

  const handlePeriodOk = useCallback(() => {
    const filterOptionsBaseURL = `${globalConfig.baseURL}/api/report/transport_report/filter_options/`;
    const filterOptionsURL = `${filterOptionsBaseURL}?fromDate=${fromDate}&toDate=${toDate}`;
    fetchResultsCancel();
    clearFilteringOptions();
    fetchFilterOptions(filterOptionsURL);
  }, [clearFilteringOptions, fetchFilterOptions, fetchResultsCancel, fromDate, toDate]);

  const handleFilteringOk = useCallback(() => {
    const resultsBaseURL = `${globalConfig.baseURL}/api/report/transport_report/results/`;

    const invoiceCustomerFilterString = Array.from(selectedInvoiceCustomers)
      .map((customerURL) => `&invoiceCustomer=${urlToId(customerURL)}`)
      .join("");

    const locationCustomerFilterString = Array.from(selectedLocationCustomers)
      .map((customerURL) => `&locationCustomer=${urlToId(customerURL)}`)
      .join("");

    const locationFilterString = Array.from(selectedLocations)
      .map((locationURL) => `&location=${urlToId(locationURL)}`)
      .join("");

    const workTypeFilterString = Array.from(selectedWorkTypesPriceGroups)
      .map(([workTypeURL, priceGroupURLs]) => {
        const workTypeId = urlToId(workTypeURL);
        if (!priceGroupURLs.size) {
          return `&workType=${workTypeId},`;
        } else {
          return Array.from(priceGroupURLs)
            .map((priceGroupURL) => {
              return `&workType=${workTypeId},${urlToId(priceGroupURL)}`;
            })
            .join("");
        }
      })
      .join("");

    const machineFilterString = Array.from(selectedMachinesPriceGroups)
      .map(([machineURL, priceGroupURLs]) => {
        const machineId = urlToId(machineURL);
        if (!priceGroupURLs.size) {
          return `&machine=${machineId},`;
        } else {
          return Array.from(priceGroupURLs)
            .map((priceGroupURL) => {
              return `&machine=${machineId},${urlToId(priceGroupURL)}`;
            })
            .join("");
        }
      })
      .join("");

    const resultURL = [
      `${resultsBaseURL}?fromDate=${fromDate}&toDate=${toDate}`,
      invoiceCustomerFilterString,
      locationCustomerFilterString,
      locationFilterString,
      workTypeFilterString,
      machineFilterString,
    ].join("");
    fetchResults(resultURL);
  }, [
    fetchResults,
    fromDate,
    selectedInvoiceCustomers,
    selectedLocationCustomers,
    selectedLocations,
    selectedMachinesPriceGroups,
    selectedWorkTypesPriceGroups,
    toDate,
  ]);

  return (
    <div style={{padding: "1em"}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PeriodSelection
            fromDate={fromDate}
            onFromDateChange={setFromDate}
            onOk={handlePeriodOk}
            onToDateChange={setToDate}
            toDate={toDate}
          />
        </Grid>
        <Grid item xs={12}>
          {filterOptions ? (
            <FilteringSetup
              filteringOptions={filterOptions}
              onOk={handleFilteringOk}
              onSelectedInvoiceCustomersChange={setSelectedInvoiceCustomers}
              onSelectedLocationCustomersChange={setSelectedLocationCustomers}
              onSelectedLocationsChange={setSelectedLocations}
              onSelectedMachinesPriceGroupsChange={setSelectedMachinesPriceGroups}
              onSelectedWorkTypesPriceGroupsChange={setSelectedWorkTypesPriceGroups}
              selectedInvoiceCustomers={selectedInvoiceCustomers}
              selectedLocationCustomers={selectedLocationCustomers}
              selectedLocations={selectedLocations}
              selectedMachinesPriceGroups={selectedMachinesPriceGroups}
              selectedWorkTypesPriceGroups={selectedWorkTypesPriceGroups}
            />
          ) : fetchingFilterOptions ? (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          {results ? (
            <DisplayResults {...results} />
          ) : fetchingResults ? (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
