import {
  CsvImportSpecification,
  getAvailableColumns,
} from "@co-common-libs/csv-import-specifications";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {TableCell, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {ImportColumnConfiguration, ImportColumnConfigurationWithoutUpdateExisting} from "../types";
import {getColumnConfiguration} from "./get-column-configuration";
import {StaticValueTextField} from "./static-value-text-field";

export function StaticValueInputRow(props: {
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>;
  onImportColumnConfigurationChange: (
    columnName: string,
    configurationChange: ImportColumnConfigurationWithoutUpdateExisting,
  ) => void;
  selectedImportSpecification: CsvImportSpecification;
}): React.JSX.Element {
  const {
    importColumnConfigurations,
    onImportColumnConfigurationChange,
    selectedImportSpecification,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);

  return (
    <TableRow>
      <TableCell>
        <FormattedMessage defaultMessage="Fast værdi:" />
      </TableCell>
      {getAvailableColumns(customerSettings, selectedImportSpecification).map((column, index) => {
        const configuration = getColumnConfiguration(importColumnConfigurations, column.name);
        let disabled: boolean = true;
        let value: string | undefined;
        if (configuration.valueType === "staticValue") {
          disabled = false;
          ({value} = configuration);
        } else {
          disabled = true;
          value = undefined;
        }

        return (
          <TableCell key={column.name}>
            {index !== 0 ? (
              <StaticValueTextField
                columnName={column.name}
                disabled={disabled}
                onImportColumnConfigurationChange={onImportColumnConfigurationChange}
                staticValue={value}
              />
            ) : null}
          </TableCell>
        );
      })}
      <TableCell />
    </TableRow>
  );
}
