import {
  Customer,
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterWorkTypeFuelSurchargeUse,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {notNull} from "@co-common-libs/utils";
import {
  ConnectedExternalWorkTypeDialog,
  ConnectedKrPerLiterFuelSurchargeSpecificationDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getKrPerLiterFuelSurchargeSpecificationLookup,
  getKrPerLiterWorkTypeFuelSurchargeUseArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

interface WorkTypeRowProps {
  surchargeUse: KrPerLiterWorkTypeFuelSurchargeUse;
  workType: WorkType;
}

function WorkTypeRow(props: WorkTypeRowProps): React.JSX.Element {
  const {surchargeUse, workType} = props;

  const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
    getKrPerLiterFuelSurchargeSpecificationLookup,
  );

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleClearButton = useCallback(() => {
    dispatch(actions.remove(surchargeUse.url));
  }, [surchargeUse, dispatch]);

  let text: string;

  if (!surchargeUse.fuelSurcharge) {
    text = intl.formatMessage({
      defaultMessage: "Ingen brændstoftillæg for område",
    });
  } else {
    const fuelSurcharge = krPerLiterFuelSurchargeSpecificationLookup(surchargeUse.fuelSurcharge);
    if (fuelSurcharge) {
      text = fuelSurcharge.name;
    } else {
      text = intl.formatMessage({defaultMessage: "Opslag fejlet"});
    }
  }

  return (
    <TableRow>
      <TableCell>{workType.name}</TableCell>
      <TableCell>
        {text}
        <IconButton onClick={handleClearButton}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

interface CustomerWorkTypeKrPerLiterFuelSurchargeCardProps {
  customer: Customer;
}

export const CustomerWorkTypeKrPerLiterFuelSurchargeCard = React.memo(
  function CustomerWorkTypeKrPerLiterFuelSurchargeCard(
    props: CustomerWorkTypeKrPerLiterFuelSurchargeCardProps,
  ): React.JSX.Element {
    const {customer} = props;
    const customerUrl = customer.url;

    const intl = useIntl();

    const workTypeLookup = useSelector(getWorkTypeLookup);

    const krPerLiterWorkTypeFuelSurchargeUseArray = useSelector(
      getKrPerLiterWorkTypeFuelSurchargeUseArray,
    );

    const dispatch = useDispatch();

    const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);
    const [fuelSurchargeDialogOpenForWorkType, setFuelSurchargeDialogOpenForWorkType] =
      useState<WorkTypeUrl>();

    const handleAddClick = useCallback(() => {
      setWorkTypeDialogOpen(true);
    }, []);
    const handleWorkTypeDialogCancel = useCallback(() => {
      setWorkTypeDialogOpen(false);
    }, []);

    const handleWorkTypeDialogOk = useCallback((url: WorkTypeUrl) => {
      setWorkTypeDialogOpen(false);
      setFuelSurchargeDialogOpenForWorkType(url);
    }, []);

    const handleFuelSurchargeDialogCancel = useCallback(() => {
      setFuelSurchargeDialogOpenForWorkType(undefined);
    }, []);

    const handleFuelSurchargeDialogOk = useCallback(
      (fuelSurchargeUrl?: KrPerLiterFuelSurchargeSpecificationUrl): void => {
        setFuelSurchargeDialogOpenForWorkType(undefined);
        if (fuelSurchargeDialogOpenForWorkType) {
          const value = fuelSurchargeUrl ?? null;
          const currentUse = krPerLiterWorkTypeFuelSurchargeUseArray.find(
            (surchargeUse) =>
              surchargeUse.customer === customerUrl &&
              surchargeUse.workType === fuelSurchargeDialogOpenForWorkType,
          );
          if (currentUse && value === currentUse.fuelSurcharge) {
            return;
          } else if (currentUse) {
            dispatch(actions.remove(currentUse.url));
          }
          const id = uuid();
          const url = instanceURL("krPerLiterWorkTypeFuelSurchargeUse", id);
          const instance: KrPerLiterWorkTypeFuelSurchargeUse = {
            customer: customerUrl,
            fuelSurcharge: value,
            id,
            url,
            variant: null,
            workType: fuelSurchargeDialogOpenForWorkType,
          };
          dispatch(actions.create(instance));
        }
      },
      [
        customerUrl,
        dispatch,
        fuelSurchargeDialogOpenForWorkType,
        krPerLiterWorkTypeFuelSurchargeUseArray,
      ],
    );

    const customerWorkTypeFuelSurchargeUses = useMemo(
      () =>
        krPerLiterWorkTypeFuelSurchargeUseArray.filter(
          (surchargeUse) => surchargeUse.customer === customerUrl,
        ),
      [customerUrl, krPerLiterWorkTypeFuelSurchargeUseArray],
    );

    const data = useMemo(
      () =>
        _.sortBy(
          customerWorkTypeFuelSurchargeUses
            .map((surchargeUse) => {
              const workType = workTypeLookup(surchargeUse.workType);
              if (workType) {
                return {
                  surchargeUse,
                  workType,
                };
              } else {
                return null;
              }
            })
            .filter(notNull),
          [({workType}) => workType.name],
        ),
      [customerWorkTypeFuelSurchargeUses, workTypeLookup],
    );

    const rows = data.map(({surchargeUse, workType}) => (
      <WorkTypeRow key={surchargeUse.url} surchargeUse={surchargeUse} workType={workType} />
    ));

    return (
      <>
        <Card>
          <CardHeader
            action={
              <Fab onClick={handleAddClick} size="small">
                <PlusIcon />
              </Fab>
            }
            title={intl.formatMessage({
              defaultMessage: "Kundespecifikt område-brændstoftillæg",
            })}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Område" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Brændstoftillæg" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </Card>
        <ConnectedExternalWorkTypeDialog
          onCancel={handleWorkTypeDialogCancel}
          onOk={handleWorkTypeDialogOk}
          open={workTypeDialogOpen}
        />
        <ConnectedKrPerLiterFuelSurchargeSpecificationDialog
          onCancel={handleFuelSurchargeDialogCancel}
          onNone={handleFuelSurchargeDialogOk}
          onOk={handleFuelSurchargeDialogOk}
          open={!!fuelSurchargeDialogOpenForWorkType}
        />
      </>
    );
  },
);
