import {IconButton} from "@material-ui/core";
import React from "react";

export interface IconLinkButtonProps {
  href?: string;
  Icon: React.ElementType<object>;
}

export const IconLinkButton = React.memo(function IconLinkButton(
  props: IconLinkButtonProps,
): React.JSX.Element {
  const {href, Icon} = props;
  if (href) {
    return (
      <IconButton color="primary" href={href}>
        <Icon />
      </IconButton>
    );
  } else {
    return (
      <IconButton color="primary" disabled>
        <Icon />
      </IconButton>
    );
  }
});
