import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";

interface NewVersionDialogProps {
  onCancel: () => void;
  onOk: (versionNote: string) => void;
  open: boolean;
}

export function NewVersionDialog(props: NewVersionDialogProps): React.JSX.Element {
  const {onCancel, onOk, open} = props;
  const [versionNote, setVersionNote] = useState("");
  const trimmedVersionNote = versionNote.trim();

  const handleOk = useCallback(() => {
    onOk(trimmedVersionNote);
  }, [onOk, trimmedVersionNote]);

  const okDisabled = !trimmedVersionNote;
  return (
    <ResponsiveDialog
      okDisabled={okDisabled}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Gem ny version"
          id="new-version-dialog.dialog-title.create-new-version"
        />
      }
    >
      <DialogContent>
        <TrimTextField
          autoFocus
          fullWidth
          label={
            <FormattedMessage
              defaultMessage="Versionsnote"
              id="new-version-dialog.label.version-note"
            />
          }
          margin="dense"
          onChange={setVersionNote}
          value={versionNote}
          variant="outlined"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
