import {
  ComputedTime,
  Customer,
  Project,
  Task,
  Timer,
  UserProfile,
  WorkType,
} from "@co-common-libs/resources";
import {
  formatDuration,
  getUnitString,
  priceItemIsTime,
  priceItemIsVisible,
} from "@co-common-libs/resources-utils";
import {formatTime, MINUTE_MILLISECONDS, sortByOrderMember} from "@co-common-libs/utils";
import {
  getCurrentRole,
  getCustomerSettings,
  getMachineLookup,
  getPriceItemLookup,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {colorMap} from "@co-frontend-libs/utils";
import {computeIntervalSums, useComputedTaskTime} from "app-utils";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Linkify} from "./linkify";

interface MeasuredIfDifferentProps {
  entered?: number;
  measured?: number;
}

const MeasuredIfDifferent = React.memo(function MeasuredIfDifferent(
  props: MeasuredIfDifferentProps,
): React.JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  const {entered, measured} = props;
  if (entered === measured) {
    return <td />;
  } else {
    return (
      <FormattedMessage
        defaultMessage="(Målt: {measuredTime})"
        tagName="td"
        values={{
          measuredTime: formatDuration(customerSettings.durationFormat, measured),
        }}
      />
    );
  }
});

interface TaskInformationProps {
  address?: string | undefined;
  breakTimer?: Timer | undefined;
  cancelled?: boolean | undefined;
  computedIntervals: readonly ComputedTime[];
  customer?: Customer | undefined;
  date?: string | undefined;
  department?: string | undefined;
  finalEndTime?: string | undefined;
  finalIntervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  finalStartTime?: string | undefined;
  genericPrimaryTimer?: Timer | undefined;
  machineOperatorProfile?: UserProfile | undefined;
  onlyCompletedChecks?: boolean | undefined;
  project?: Project | undefined;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  workType?: WorkType | undefined;
}

export const TaskInformation = React.memo(function TaskInformation(
  props: TaskInformationProps,
): React.JSX.Element {
  const {
    breakTimer,
    cancelled,
    computedIntervals,
    customer,
    finalIntervals,
    genericPrimaryTimer,
    machineOperatorProfile,
    onlyCompletedChecks,
    project,
    secondaryTimers,
    task,
    workType,
  } = props;

  const intl = useIntl();
  const machineLookup = useSelector(getMachineLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const unitLookup = useSelector(getUnitLookup);
  const role = useSelector(getCurrentRole);
  const customerSettings = useSelector(getCustomerSettings);

  let finalStartTime;
  let finalEndTime;
  if (finalIntervals && finalIntervals.length) {
    const now = new Date();
    finalStartTime = finalIntervals[0].fromTimestamp;
    finalEndTime = finalIntervals[finalIntervals.length - 1].toTimestamp || now.toISOString();
  }
  let workInterval;
  if (finalStartTime && finalEndTime) {
    workInterval = Math.round(
      (new Date(finalEndTime).getTime() - new Date(finalStartTime).getTime()) / MINUTE_MILLISECONDS,
    );
  }
  const productUseList = sortByOrderMember(Object.values(task.productUses || {}));
  const priceItemUseList = sortByOrderMember(Object.values(task.priceItemUses || {}));
  const machineUseList = task.machineuseSet || [];
  const {notesFromMachineOperator} = task;
  const {address} = task;
  const now = new Date();
  const products: React.JSX.Element[] = [];
  productUseList.forEach((instance, index) => {
    const count = instance.correctedCount ?? instance.count ?? "?";
    const {ours} = instance;
    const product = productLookup(instance.product);
    const note = instance.notes;
    let productString = null;
    let unitString = null;
    if (product) {
      productString = `${product.name} (${product.catalogNumber})`;
      unitString = getUnitString(product, unitLookup);
    }
    products.push(
      <tr key={`product${index}`}>
        <td>{productString}</td>
        <td colSpan={2}>
          {`${count} ${unitString} ${
            customerSettings.showOursToggle
              ? `(${
                  ours
                    ? intl.formatMessage({defaultMessage: "vores"})
                    : intl.formatMessage({defaultMessage: "deres"})
                })`
              : ""
          }`}
        </td>
      </tr>,
    );
    if (note) {
      products.push(
        <tr key={`product-note${index}`}>
          <td />
          <td colSpan={2}>
            <em>
              <Linkify>{note}</Linkify>
            </em>
          </td>
        </tr>,
      );
    }
  });

  let priceitems: (React.JSX.Element | null)[] = [];
  if (priceItemLookup) {
    priceitems = priceItemUseList
      .map((instance, index) => {
        const priceItem = priceItemLookup(instance.priceItem);
        if (!priceItem) {
          return null;
        }
        if (
          (onlyCompletedChecks &&
            !priceItemIsVisible(priceItem, false, priceItemUseList, unitLookup, priceItemLookup)) ||
          priceItemIsTime(unitLookup, priceItem)
        ) {
          return null;
        }

        const count: number | string =
          instance.correctedCount ?? instance.count ?? priceItem.minimumCount ?? "?";

        return (
          <tr key={`priceitem${index}`}>
            <td>{priceItem.name}</td>
            <td colSpan={2}>
              {count} {getUnitString(priceItem, unitLookup)}
            </td>
          </tr>
        );
      })
      .filter((x) => x);
  }
  const machines = machineUseList.map((instance, index) => {
    const machine = machineLookup(instance.machine);
    let machineID = "";
    let machineName = "";
    if (machine) {
      machineID = machine.c5_machine;
      machineName = machine.name;
    }
    const text = machineName + (machineID ? ` (${machineID})` : "");
    return (
      <tr key={index}>
        <td colSpan={3}>{text}</td>
      </tr>
    );
  });
  const startTimestamp = finalStartTime ? new Date(finalStartTime) : undefined;
  const endTimestamp = finalEndTime ? new Date(finalEndTime) : undefined;
  if (startTimestamp && endTimestamp) {
    workInterval = Math.round(
      (new Date(endTimestamp).getTime() - new Date(startTimestamp).getTime()) / MINUTE_MILLISECONDS,
    );
  }

  const machineOperatorData = machineOperatorProfile
    ? `${machineOperatorProfile.alias} — ${machineOperatorProfile.name}`
    : "";

  let notesBlock;
  if (notesFromMachineOperator) {
    notesBlock = (
      <div>
        {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
          <FormattedMessage defaultMessage="Noter fra maskinfører:" tagName="h4" />
        ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
          <FormattedMessage defaultMessage="Noter fra medarbejder:" tagName="h4" />
        ) : (
          <FormattedMessage defaultMessage="Noter fra chauffør:" tagName="h4" />
        )}

        <em>
          <Linkify>{notesFromMachineOperator}</Linkify>
        </em>
      </div>
    );
  }

  const finalSums = computeIntervalSums(finalIntervals, now);

  const {computeTaskTime} = useComputedTaskTime();
  const {externalEfficiencyPercent, minutesTotal, timerMinutesMap} = computeTaskTime(task.url);

  let workTypeString;
  if (workType) {
    const workTypeIdentifier = workType.identifier;
    const workTypeName = workType.name;
    if (workTypeIdentifier) {
      workTypeString = `${workTypeIdentifier}: ${workTypeName}`;
    } else {
      workTypeString = workTypeName;
    }
  }
  let primaryWorkTypeRow;
  if (genericPrimaryTimer) {
    const genericPrimaryTimerURL = genericPrimaryTimer.url;
    const computedMinutes = timerMinutesMap.get(genericPrimaryTimerURL) || 0;
    const enteredMinutes = finalSums.get(genericPrimaryTimerURL) || 0;
    primaryWorkTypeRow = (
      <tr>
        <td>{workType ? workType.name : genericPrimaryTimer.label}</td>
        <td>{formatDuration(customerSettings.durationFormat, enteredMinutes)}</td>
        <MeasuredIfDifferent entered={enteredMinutes} measured={computedMinutes} />
      </tr>
    );
  }
  const secondaryTimerRows = Array.from(secondaryTimers).map((timer) => {
    const timerURL = timer.url;
    const computedMinutes = timerMinutesMap.get(timerURL) || 0;
    const enteredMinutes = finalSums.get(timerURL) || 0;
    return (
      <tr key={timerURL}>
        <td>{timer.label}</td>
        <td>{formatDuration(customerSettings.durationFormat, enteredMinutes)}</td>
        <MeasuredIfDifferent entered={enteredMinutes} measured={computedMinutes} />
      </tr>
    );
  });
  let workplaceRow;
  if (address && address.trim()) {
    workplaceRow = (
      <tr>
        <FormattedMessage defaultMessage="Arbejdssted:" tagName="td" />
        <td colSpan={2}>{address}</td>
      </tr>
    );
  }

  const userIsManager = role && role.manager;

  let projectRow;
  if (
    customerSettings.enableProjects &&
    (!customerSettings.onlyEnableProjectsForDepartments.length ||
      customerSettings.onlyEnableProjectsForDepartments.includes(task.department)) &&
    (userIsManager || customerSettings.machineOperatorsCanChooseProject)
  ) {
    projectRow = (
      <tr>
        {customerSettings.projectLabelVariant === "PROJECT" ? (
          <FormattedMessage defaultMessage="Projekt:" tagName="td" />
        ) : (
          <FormattedMessage defaultMessage="Sag:" tagName="td" />
        )}
        <td colSpan={2}>{project ? `${project.projectNumber}: ${project.name}` : null}</td>
      </tr>
    );
  }

  return (
    <div>
      {notesBlock}
      <table>
        <tbody>
          <tr>
            <FormattedMessage defaultMessage="Kunde:" tagName="td" />
            <td colSpan={2}>{customer ? customer.name : ""}</td>
          </tr>
          {workplaceRow}
          <tr>
            <FormattedMessage defaultMessage="Arbejdsområde:" tagName="td" />
            <td colSpan={2}>{workTypeString}</td>
          </tr>
          <tr>
            {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
              <FormattedMessage defaultMessage="Maskinfører:" tagName="td" />
            ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
              <FormattedMessage defaultMessage="Medarbejder:" tagName="td" />
            ) : (
              <FormattedMessage defaultMessage="Chauffør:" tagName="td" />
            )}
            <td colSpan={2}>{machineOperatorData}</td>
          </tr>
          {projectRow}
          <tr>
            <td colSpan={3}>
              {customerSettings.machineLabelVariant === "MACHINE" ? (
                <FormattedMessage defaultMessage="Maskiner:" tagName="h4" />
              ) : (
                <FormattedMessage defaultMessage="Køretøjer:" tagName="h4" />
              )}
            </td>
          </tr>
          {machines}
          <tr>
            <td colSpan={3}>
              <FormattedMessage defaultMessage="Registreret tid (timer:minutter):" tagName="h4" />
            </td>
          </tr>
          {primaryWorkTypeRow}
          {secondaryTimerRows}
          <tr>
            <td>
              <strong>
                <FormattedMessage defaultMessage="Total registreret tid:" />
              </strong>
            </td>
            <td>
              <strong>{formatDuration(customerSettings.durationFormat, minutesTotal)}</strong>
            </td>
            <td />
          </tr>
          <tr>
            <FormattedMessage defaultMessage="Effektivitet:" tagName="td" />
            <td>{externalEfficiencyPercent}%</td>
            <td />
          </tr>
          <tr>
            <td colSpan={3}>
              <FormattedMessage defaultMessage="Arbejdstid:" />
            </td>
          </tr>
          <tr>
            <td>
              {formatTime(startTimestamp)}–{formatTime(endTimestamp)}
            </td>
            <td>{formatDuration(customerSettings.durationFormat, workInterval)}</td>
            <td />
          </tr>
          <tr>
            <td colSpan={3}>
              {task.order ? (
                customerSettings.materialUseAlternativeText ? (
                  <FormattedMessage defaultMessage="Materialer:" tagName="h4" />
                ) : (
                  <FormattedMessage defaultMessage="Materiel:" tagName="h4" />
                )
              ) : null}
              {cancelled ? (
                <span style={{color: colorMap.WARNING}}>
                  <FormattedMessage defaultMessage="Materialer bogføres ikke for aflyste opgaver" />
                </span>
              ) : null}
            </td>
          </tr>
          {products}
          {priceitems}
        </tbody>
      </table>
    </div>
  );
});
