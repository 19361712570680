import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React, {useCallback} from "react";
import {typedMemo} from "../../types";
import {EntryCategoryCode, entryCategoryIcons, MaybeSearchResultEntryData} from "../types";

interface GroupListItemProps<Identifier extends string> {
  category: EntryCategoryCode;
  entries: readonly MaybeSearchResultEntryData<Identifier>[];
  onSelectGroup: ((Identifiers: ReadonlySet<Identifier>, selected: boolean) => void) | undefined;
  primaryText: string;
  selected: ReadonlySet<Identifier>;
  style: React.CSSProperties;
  value: Identifier;
}

export const GroupListItem = typedMemo(function GroupListItem<Identifier extends string>(
  props: GroupListItemProps<Identifier>,
) {
  const {category, entries, onSelectGroup, primaryText, selected, style, value} = props;

  const entriesInGroup = entries.filter((instance) => instance.group === value);

  const groupSelected = entriesInGroup.every((instance) => selected.has(instance.identifier));
  const groupPartlySelected = entriesInGroup.some((instance) => selected.has(instance.identifier));
  const handleClick = useCallback(() => {
    if (!onSelectGroup) {
      return;
    }
    const changed = new Set(entriesInGroup.map((instance) => instance.identifier));
    onSelectGroup(changed, !groupSelected);
  }, [onSelectGroup, entriesInGroup, groupSelected]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleClick();
      }
    },
    [handleClick],
  );

  const Icon = entryCategoryIcons[category];

  return (
    <ListItem button onClick={handleClick} onKeyDown={handleKeyDown} style={style}>
      <ListItemIcon>
        <Checkbox
          checked={groupSelected}
          disableRipple
          edge="start"
          indeterminate={!groupSelected && groupPartlySelected}
          tabIndex={-1}
        />
      </ListItemIcon>
      {Icon && (
        <ListItemSecondaryAction>
          <Icon />
        </ListItemSecondaryAction>
      )}
      <ListItemText primary={primaryText} />
    </ListItem>
  );
});
