import {
  dateFromString,
  dateToString,
  DayTypeHoliday,
  MINUTE_MILLISECONDS,
  objectMerge,
  objectSet,
  WEEKDAY_SATURDAY,
  WEEKDAY_SUNDAY,
  WeekdayNumberType,
} from "@co-common-libs/utils";
import {computeIntervalDurationMilliseconds} from "./duration";
import {
  HolidayCheckFunction,
  OvertimeThresholdsFunction,
  Rate,
  RemunerationGroup,
  TaskIntervalWithRate,
  WeekThresholdsSpecification,
  WorkDay,
} from "./types";

export function setWorkDayThresholdsOvertimeLevel(
  fromRate: Rate,
  workDay: WorkDay,
  getDateOvertimeLevelThresholds: (date: Date) => readonly (number | readonly [number, Rate])[],
  updatedRates: Set<Rate>,
): WorkDay {
  const dateString = workDay.date;
  const date = dateFromString(dateString) as Date;
  const dayThresholds = getDateOvertimeLevelThresholds(date);
  const threshold = dayThresholds[fromRate];
  if (threshold == null) {
    return workDay;
  }
  const [thresholdMinutes, overtimeRate] =
    typeof threshold === "number" ? [threshold, fromRate + 1] : threshold;
  let {extraRateMinutes, workPeriods} = workDay;
  let remainingMilliseconds = thresholdMinutes * MINUTE_MILLISECONDS;
  const extraFromRateMinutes = extraRateMinutes.get(fromRate);
  if (extraFromRateMinutes) {
    const extraFromRateMilliseconds = extraFromRateMinutes * MINUTE_MILLISECONDS;
    if (extraFromRateMilliseconds > remainingMilliseconds) {
      const updatedExtraRateMinutes = new Map(extraRateMinutes);
      updatedExtraRateMinutes.set(fromRate, remainingMilliseconds / MINUTE_MILLISECONDS);
      updatedExtraRateMinutes.set(
        overtimeRate,
        (updatedExtraRateMinutes.get(overtimeRate) || 0) +
          (extraFromRateMilliseconds - remainingMilliseconds) / MINUTE_MILLISECONDS,
      );
      updatedRates.add(overtimeRate);
      extraRateMinutes = updatedExtraRateMinutes;
      remainingMilliseconds = 0;
    } else {
      remainingMilliseconds -= extraFromRateMilliseconds;
    }
  }
  workPeriods = workPeriods.map((workPeriod) => {
    const work: TaskIntervalWithRate[] = [];
    const inputIntervals = workPeriod.work;
    for (let i = 0; i < inputIntervals.length; i += 1) {
      const interval = inputIntervals[i];
      if (interval.rate !== fromRate) {
        work.push(interval);
      } else if (!remainingMilliseconds) {
        work.push(objectSet(interval, "rate", overtimeRate));
        updatedRates.add(overtimeRate);
      } else {
        const intervalDuration = computeIntervalDurationMilliseconds(interval);
        if (intervalDuration <= remainingMilliseconds) {
          console.assert(interval.rate === fromRate);
          work.push(interval);
          remainingMilliseconds -= intervalDuration;
        } else {
          const splitDate = new Date(interval.fromTimestamp);
          splitDate.setUTCMilliseconds(splitDate.getUTCMilliseconds() + remainingMilliseconds);
          const splitTimestamp = splitDate.toISOString();
          console.assert(interval.rate === fromRate);
          work.push(objectSet(interval, "toTimestamp", splitTimestamp));
          work.push(
            objectMerge(interval, {
              fromTimestamp: splitTimestamp,
              rate: overtimeRate,
            }),
          );
          updatedRates.add(overtimeRate);
          remainingMilliseconds = 0;
        }
      }
    }
    return objectSet(workPeriod, "work", work);
  });
  return {
    date: dateString,
    extraRateMinutes,
    workPeriods,
  };
}

/** When a threshold is reached for `Rate.NORMAL` work, set subsequent
 * `Rate.NORMAL` work in the same work period to `Rate.OVERTIME_1`.
 * The threshold is per work period, and based on the calendar day where the
 * period started.  Danish holidays use criteria for sundays.
 *
 * This is often combined with use of "normal hours"; only time not already
 * set overtime due to time-of day (or set to "special start rate")
 * contributes towards reaching thresholds.
 *
 * Different overtime-rates based on thresholds must be computed
 * separetely, afterwards.
 *
 * @param workDays The work days to work with.
 * @param overtimeThresholds Array of specifications of thresholds per
 *   weekday; sunday to saturday; each containing thresholds for
 *   `Rate.NORMAL` to `Rate.OVERTIME_1`, `Rate.OVERTIME_1` to
 *   `Rate.OVERTIME_2` and so on; though only the first value is used here.
 *   If no threshold is specified for a day, then all time for that day is
 *   allowed to have "normal" rate.
 * @param updatedRates Set of rates needing recomputation afterwards;
 *   to be added to during computation here.
 */
export function setThresholdsOvertimeLevel(
  fromRate: Rate,
  workDays: readonly WorkDay[],
  getDateOvertimeLevelThresholds: (date: Date) => readonly (number | readonly [number, Rate])[],
  updatedRates: Set<Rate>,
): WorkDay[] {
  return workDays.map((workDay) =>
    setWorkDayThresholdsOvertimeLevel(
      fromRate,
      workDay,
      getDateOvertimeLevelThresholds,
      updatedRates,
    ),
  );
}

export function getOvertimeThresholdsWithoutWeekendsFunction(
  getDateOvertimeThresholds: OvertimeThresholdsFunction,
): OvertimeThresholdsFunction {
  const overtimeThresholdsWithoutWeekendsFunction = (
    date: Date,
  ): readonly (number | readonly [number, Rate])[] => {
    const day = date.getDay();
    if (day === WEEKDAY_SATURDAY || day === WEEKDAY_SUNDAY) {
      return [];
    }
    return getDateOvertimeThresholds(date);
  };
  return overtimeThresholdsWithoutWeekendsFunction;
}

export function getDateOvertimeThresholdsFunction(
  checkHoliday: HolidayCheckFunction,
  remunerationGroup: RemunerationGroup,
  poolDayThresholds?: WeekThresholdsSpecification,
): OvertimeThresholdsFunction {
  const overtimeThresholds = poolDayThresholds || remunerationGroup.overtimeThresholds;
  const {halfHolidayHalfThresholds} = remunerationGroup;
  const dateOvertimeThresholdFunction = (
    date: Date,
  ): readonly (number | readonly [number, Rate])[] => {
    const weekDay = date.getDay() as WeekdayNumberType;
    const dateString = dateToString(date);
    const dayType = checkHoliday(dateString);
    if (dayType === DayTypeHoliday.HOLIDAY || dayType === DayTypeHoliday.VALID_ABSENCE) {
      const dayThresholds = overtimeThresholds[WEEKDAY_SUNDAY];
      return dayThresholds;
    } else {
      console.assert(dayType === DayTypeHoliday.NORMAL || dayType === DayTypeHoliday.HALF_HOLIDAY);
      const dayThresholds = overtimeThresholds[weekDay];
      if (
        dayType === DayTypeHoliday.HALF_HOLIDAY &&
        dayThresholds[0] &&
        halfHolidayHalfThresholds
      ) {
        const HALF_MULTIPLIER = 0.5;
        const thresholds = dayThresholds.slice();
        const normalToOvertimeThreshold = thresholds[0];
        if (typeof normalToOvertimeThreshold === "number") {
          thresholds[0] = normalToOvertimeThreshold * HALF_MULTIPLIER;
        } else {
          const [value, target] = normalToOvertimeThreshold;
          thresholds[0] = [value * HALF_MULTIPLIER, target];
        }
        return thresholds;
      } else {
        return dayThresholds;
      }
    }
  };
  return dateOvertimeThresholdFunction;
}
