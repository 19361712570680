import bowser from "bowser";
import React from "react";

interface FieldLabelProps {
  area?: string | undefined;
  extraText?: string | undefined;
  fieldRecordYear?: number | null;
  latLng?: google.maps.LatLng | undefined;
  text: string;
  x: number;
  y: number;
}

export function FieldLabel(props: FieldLabelProps): React.JSX.Element {
  const {area, extraText, fieldRecordYear, latLng, text, x, y} = props;
  const style: React.CSSProperties = {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: 4,
    color: "#000",
    display: "inline-block",
    left: x,
    paddingLeft: 4,
    paddingRight: 4,
    position: "absolute",
    textDecoration: "none",
    top: y,
    transform: "translate(-50%, -50%)",
    whiteSpace: "pre",
  };
  const content = (
    <>
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        {text}
      </span>
      {area !== undefined ? ` ${area} ha` : null}
      {fieldRecordYear != null ? ` (${fieldRecordYear})` : null}
      {extraText ? <br /> : null}
      {extraText}
    </>
  );
  if (latLng !== undefined) {
    const googleMapsURL = `http://maps.google.com/?q=${latLng.lat()},${latLng.lng()}`;
    return (
      <a
        href={googleMapsURL}
        rel="noopener noreferrer"
        style={style}
        target={window.cordova && bowser.ios ? "_system" : "_blank"}
      >
        {content}
      </a>
    );
  } else {
    return <span style={style}>{content}</span>;
  }
}
