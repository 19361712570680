import {ResponsiveDialog} from "@co-frontend-libs/components";
import React from "react";
import {FormattedMessage} from "react-intl";

interface RemoveFieldsDialogProps {
  onNo: () => void;
  onYes: () => void;
  open: boolean;
}

export function RemoveFieldsDialog(props: RemoveFieldsDialogProps): React.JSX.Element {
  const {onNo, onYes, open} = props;

  return (
    <ResponsiveDialog
      cancelLabel={<FormattedMessage defaultMessage="Nej" id="remove-fields-dialog.label.no" />}
      okLabel={<FormattedMessage defaultMessage="Ja" id="remove-fields-dialog.label.yes" />}
      onCancel={onNo}
      onOk={onYes}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Skal valgte marker fjernes fra opgave og log?"
          id="remove-fields-dialog.dialog-title.remove-fields"
        />
      }
    />
  );
}
