import _ from "lodash";
import {z} from "zod";
import {CvrResult} from "./cvr-types";

const cvrPhoneNumberSchema = z.array(
  z.object({
    hemmelig: z.boolean(),
    kontaktoplysning: z.string(),
    periode: z.object({
      gyldigFra: z.string(),
      gyldigTil: z.nullable(z.string()),
    }),
    sidstOpdateret: z.string(),
  }),
);

const cvrEmailSchema = z.array(
  z.object({
    hemmelig: z.boolean(),
    kontaktoplysning: z.string(),
    periode: z.object({
      gyldigFra: z.string(),
      gyldigTil: z.nullable(z.string()),
    }),
    sidstOpdateret: z.string(),
  }),
);

const cvrMostRecentAddressSchema = z.object({
  bogstavFra: z.nullable(z.string()),
  bogstavTil: z.nullable(z.string()),
  bynavn: z.nullable(z.string()),
  conavn: z.nullable(z.string()),
  etage: z.nullable(z.string()),
  husnummerFra: z.nullable(z.number()),
  husnummerTil: z.nullable(z.number()),
  kommune: z.object({
    kommuneNavn: z.nullable(z.string()),
  }),
  postdistrikt: z.string(),
  postnummer: z.number(),
  vejnavn: z.string(),
});

const cvrResponseSchema = z.object({
  hits: z.object({
    hits: z.array(
      z.object({
        _score: z.number(),
        _source: z.object({
          Vrvirksomhed: z.object({
            cvrNummer: z.number(),
            elektroniskPost: cvrEmailSchema,
            telefonNummer: cvrPhoneNumberSchema,
            virksomhedMetadata: z.object({
              nyesteBeliggenhedsadresse: cvrMostRecentAddressSchema,
              nyesteNavn: z.object({
                navn: z.string(),
              }),
            }),
          }),
        }),
      }),
    ),
  }),
});

const extractPhoneNumber = (cvrPhoneNumbers: z.infer<typeof cvrPhoneNumberSchema>): string => {
  const publicAndStillValid = cvrPhoneNumbers.filter(
    (phoneNumber) => !phoneNumber.hemmelig && phoneNumber.periode.gyldigTil === null,
  );

  const lastUpdated = _.maxBy(publicAndStillValid, (phoneNumber) => phoneNumber.sidstOpdateret);

  return lastUpdated?.kontaktoplysning || "";
};

const extractEmail = (cvrEmails: z.infer<typeof cvrEmailSchema>): string => {
  const publicAndStillValid = cvrEmails.filter(
    (email) => !email.hemmelig && email.periode.gyldigTil === null,
  );

  const lastUpdated = _.maxBy(publicAndStillValid, (email) => email.sidstOpdateret);

  return lastUpdated?.kontaktoplysning || "";
};

const extractCity = (cvrMostRecentAddress: z.infer<typeof cvrMostRecentAddressSchema>): string =>
  cvrMostRecentAddress.bynavn ||
  cvrMostRecentAddress.postdistrikt ||
  cvrMostRecentAddress.kommune.kommuneNavn?.split("-").map(_.capitalize).join("-") ||
  "";

const extractAddress = (
  cvrMostRecentAddress: z.infer<typeof cvrMostRecentAddressSchema>,
): string => {
  const {bogstavFra, bogstavTil, etage, husnummerFra, husnummerTil, vejnavn} = cvrMostRecentAddress;

  const numberFrom = [husnummerFra, bogstavFra].join("");
  const numberTo = [husnummerTil, bogstavTil].join("");
  const addressNumber = numberFrom && numberTo ? [numberFrom, numberTo].join("-") : numberFrom;
  const floor = etage ? `${etage}.` : "";

  return `${vejnavn} ${addressNumber} ${floor}`.trim();
};

export const transformResponseToCVRResult = (data: unknown): CvrResult[] => {
  try {
    const cvrResponse = cvrResponseSchema.parse(data);

    return cvrResponse.hits.hits.map((hit) => {
      const {Vrvirksomhed: virksomhed} = hit._source;

      return {
        address: extractAddress(virksomhed.virksomhedMetadata.nyesteBeliggenhedsadresse),
        city: extractCity(virksomhed.virksomhedMetadata.nyesteBeliggenhedsadresse),
        email: extractEmail(virksomhed.elektroniskPost),
        name: virksomhed.virksomhedMetadata.nyesteNavn.navn,
        phoneNumber: extractPhoneNumber(virksomhed.telefonNummer),
        postalCode: virksomhed.virksomhedMetadata.nyesteBeliggenhedsadresse.postnummer.toString(),
        postalDistrict: virksomhed.virksomhedMetadata.nyesteBeliggenhedsadresse.postdistrikt,
        vatNumber: virksomhed.cvrNummer.toString(),
      };
    });
  } catch (err) {
    if (err instanceof z.ZodError) {
      // eslint-disable-next-line no-console
      console.error(err.issues);
      return [];
    }

    throw err;
  }
};
