import {
  applyPatch,
  Patch,
  ResourceInstance,
  resourceNameFor,
  Task,
  TaskUrl,
} from "@co-common-libs/resources";
import {ProvisionaryCommand} from "../../resources/actions";
import {getTaskLookup} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";
import {getInstances} from "../utils";
import {addLogLocationsForFieldsOnAddingLog} from "./add-log-locations-for-fields-on-adding-log";
import {addMachinesFromWorkTypeAutoMachines} from "./add-machines-from-work-type-auto-machines";
import {addRemoveFieldsOnLogLocationChanges} from "./add-remove-fields-on-log-location-changes";
import {addRemoveLogLocationsOnFieldChanges} from "./add-remove-log-locations-on-field-changes";
import {autoDepartment} from "./auto-department";
import {autoMachinePriceGroups} from "./auto-machine-price-groups";
import {autoWorkTypePriceGroup} from "./auto-work-type-price-group";
import {disallowMachineUseForWorkTypes} from "./disallow-machine-use-for-work-types";
import {loadMachinesFromMachineUseLog} from "./load-machines-from-machine-use-log";
import {removeDanglingZeroPriceItemUses} from "./remove-dangling-zero-price-item-uses";
import {resetLastTransferError} from "./reset-last-transfer-error";
import {updateGenericLog} from "./update-generic-log";
import {updatePriceItemProductUsesOnLogChanges} from "./update-price-item-product-uses-on-log-changes";
import {updatePriceItemUses} from "./update-price-item-uses";
import {updateWorkFromTimestampFromCorrections} from "./update-work-from-timestamp-from-corrections";

type RewriteFunction<T extends ResourceInstance> = (
  newInstance: T | null,
  oldInstance: T | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
) => Patch<T> | null;

function combineRewrites<T extends ResourceInstance, Url extends string>(
  lookup: (url: Url) => T | undefined,
  rewriters: readonly RewriteFunction<T>[],
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  inputCommand: ProvisionaryCommand,
): ProvisionaryCommand {
  const result = {...inputCommand};
  let {newInstance, oldInstance} = getInstances<T, Url>(lookup, result);
  for (let i = 0; i < rewriters.length; i += 1) {
    const fn = rewriters[i];
    const patch = fn(newInstance, oldInstance, middlewareApi, result);
    if (patch) {
      // result of combining with DELETE not specified...
      console.assert(result.action !== "DELETE");
      if (result.action === "CREATE" || result.action === "CREATE_OR_UPDATE") {
        result.instance = applyPatch<T>(result.instance as unknown as T, patch) as any;
      }
      if (result.action === "UPDATE" || result.action === "CREATE_OR_UPDATE") {
        result.patch = (result.patch as Patch<T>).concat(patch) as any;
      }
      ({newInstance, oldInstance} = getInstances<T, Url>(lookup, result));
    }
  }
  return result;
}

export function rewriteSaveCommand(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): ProvisionaryCommand {
  const {url} = command;
  const resourceName = resourceNameFor(url);
  if (resourceName === "task") {
    const state = middlewareApi.getState();
    const taskLookup = getTaskLookup(state);
    const taskRewriteFunctions: readonly RewriteFunction<Task>[] = [
      autoWorkTypePriceGroup,
      loadMachinesFromMachineUseLog,
      addMachinesFromWorkTypeAutoMachines,
      disallowMachineUseForWorkTypes,
      autoMachinePriceGroups,
      updatePriceItemUses,
      removeDanglingZeroPriceItemUses,
      updateGenericLog,
      addLogLocationsForFieldsOnAddingLog,
      addRemoveLogLocationsOnFieldChanges,
      addRemoveFieldsOnLogLocationChanges,
      autoDepartment,
      resetLastTransferError,
      updatePriceItemProductUsesOnLogChanges,
      updateWorkFromTimestampFromCorrections,
    ];
    return combineRewrites<Task, TaskUrl>(taskLookup, taskRewriteFunctions, middlewareApi, command);
  }
  return command;
}
