import {Location} from "@co-common-libs/resources";

export function getLocationButtonString(
  logButtonText: "ADDRESS" | "NAME_AND_ADDRESS" | "NAME_AND_ADRESS_AND_ZIPCODE_AND_CITY" | "NAME",
  location: Location | undefined,
): string {
  if (!location) {
    return "";
  }
  switch (logButtonText) {
    case "ADDRESS":
      return location.address || location.name;
    case "NAME_AND_ADDRESS":
      return `${location.name} ${location.address}`.trim();

    case "NAME_AND_ADRESS_AND_ZIPCODE_AND_CITY":
      return [
        `${location.name} ${location.address}`.trim(),
        `${location.postalCode} ${location.city}`.trim(),
      ]
        .filter(Boolean)
        .join(", ");

    case "NAME":

    default:
      return location.name || location.address;
  }
}
