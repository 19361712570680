import {DialogContent, useTheme} from "@material-ui/core";
import React from "react";
import {ResponsiveDialog, ResponsiveDialogProps} from "../responsive-dialog";

export interface WarningDialogProps
  extends Pick<ResponsiveDialogProps, "onCancel" | "onOk" | "open" | "title"> {
  message: string;
}

export function WarningDialog(props: WarningDialogProps): React.JSX.Element {
  const {message, onCancel, onOk, open, title} = props;
  const theme = useTheme();
  return (
    <ResponsiveDialog onCancel={onCancel} onOk={onOk} open={open} title={title}>
      <DialogContent>
        <div style={{color: theme.palette.warning.main}}>{message}</div>
      </DialogContent>
    </ResponsiveDialog>
  );
}
