import {PriceItemUrl, ProductUrl, Task} from "@co-common-libs/resources";
import {
  computeLoadsPerProduct,
  getTransportedSumsPerProduct,
} from "@co-common-libs/resources-utils";
import {getPriceItemLookup} from "@co-frontend-libs/redux";
import _ from "lodash";
import React from "react";
import {useSelector} from "react-redux";
import {DisplaySharedTotalsTable} from "./display-shared-totals-table";

interface TransportTotalsTableProps {
  otherTasks: readonly Task[];
  showLoadCounts: boolean;
  task: Task;
}

export function TransportTotalsTable({
  otherTasks,
  showLoadCounts,
  task,
}: TransportTotalsTableProps): React.JSX.Element | null {
  const priceItemLookup = useSelector(getPriceItemLookup);
  let deliveryCounts: Map<PriceItemUrl | ProductUrl, number> | undefined;
  let pickupCounts: Map<PriceItemUrl | ProductUrl, number> | undefined;
  let loadsPerProduct: Map<string, number> | undefined;
  let allLoadsPerProduct: Map<string, number> | undefined;

  if (!_.isEmpty(task.reportingLog)) {
    ({deliveryCounts, pickupCounts} = getTransportedSumsPerProduct(
      task.reportingLog,
      priceItemLookup,
    ));
    if (showLoadCounts) {
      loadsPerProduct = computeLoadsPerProduct(task.reportingLog, priceItemLookup);
      allLoadsPerProduct = new Map<string, number>(loadsPerProduct);
    }
  }

  const allDeliveryCounts = new Map<PriceItemUrl | ProductUrl, number>(deliveryCounts);
  const allPickupCounts = new Map<PriceItemUrl | ProductUrl, number>(pickupCounts);

  otherTasks.forEach((otherTask) => {
    if (_.isEmpty(otherTask.reportingLog)) {
      return;
    }
    const otherCounts = getTransportedSumsPerProduct(otherTask.reportingLog, priceItemLookup);

    otherCounts.deliveryCounts.forEach((count, url) => {
      allDeliveryCounts.set(url, (allDeliveryCounts.get(url) || 0) + count);
    });
    otherCounts.pickupCounts.forEach((count, url) => {
      allPickupCounts.set(url, (allPickupCounts.get(url) || 0) + count);
    });

    if (showLoadCounts) {
      computeLoadsPerProduct(task.reportingLog, priceItemLookup).forEach((count, url) => {
        if (allLoadsPerProduct) {
          allLoadsPerProduct.set(url, (allLoadsPerProduct.get(url) || 0) + count);
        }
      });
    }
  });

  return (
    <DisplaySharedTotalsTable
      allDeliveryCounts={allDeliveryCounts}
      allLoadsPerProduct={allLoadsPerProduct}
      allPickupCounts={allPickupCounts}
      deliveryCounts={deliveryCounts}
      loadsPerProduct={loadsPerProduct}
      pickupCounts={pickupCounts}
    />
  );
}
