import {MachineUrl} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {DecimalField, MachineDialog} from "@co-frontend-libs/components";
import {getCustomerSettings, getMachineLookup} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Card, CardActions, CardContent, CardHeader, Grid} from "@material-ui/core";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {DeletableChip} from "../deletable-chip";

export interface Configuration {
  directCosts: {
    foreignWorkshop: number | null;
    other: number | null;
    spareParts: number | null;
  };
  indirectCosts: {
    financing: number | null;
    insurance: number | null;
    other: number | null;
  };
  machine: string;
  turnover: {invoiced: number | null};
}

interface ConfigurationCardProps {
  machineURLs: MachineUrl[];
  onConfigurationChange: () => void;
  onOk: (configuration: Configuration) => void;
}

export function ConfigurationCard(props: ConfigurationCardProps): React.JSX.Element {
  const {machineURLs, onConfigurationChange, onOk} = props;
  const customerSettings = useSelector(getCustomerSettings);
  const machineLookup = useSelector(getMachineLookup);
  const potentialMachines = useMemo(
    () => machineURLs.map((url) => machineLookup(url)).filter(notUndefined),
    [machineURLs, machineLookup],
  );

  const [machineDialogOpen, setMachineDialogOpen] = useState(false);
  const setMachineDialogOpenTrue = useCallWithTrue(setMachineDialogOpen);
  const setMachineDialogOpenFalse = useCallWithFalse(setMachineDialogOpen);

  const [machineURL, setMachineURL] = useState<MachineUrl | null>(null);
  const [turnoverInvoiced, setTurnoverInvoiced] = useState<number | null>(null);
  const [directCostsSpareParts, setDirectCostsSpareParts] = useState<number | null>(null);
  const [directCostsForeignWorkshop, setDirectCostsForeignWorkshop] = useState<number | null>(null);
  const [directCostsOther, setDirectCostsOther] = useState<number | null>(null);
  const [indirectCostsInsurance, setIndirectCostsInsurance] = useState<number | null>(null);
  const [indirectCostsFinancing, setIndirectCostsFinancing] = useState<number | null>(null);
  const [indirectCostsOther, setIndirectCostsOther] = useState<number | null>(null);

  const handleMachineDialogOk = useCallback((url: MachineUrl) => {
    setMachineDialogOpen(false);
    setMachineURL(url);
  }, []);

  const handleRemoveMachine = useCallback((): void => {
    setMachineURL(null);
  }, []);

  const handleOk = useCallback(() => {
    if (!machineURL) {
      return;
    }
    onOk({
      directCosts: {
        foreignWorkshop: directCostsForeignWorkshop,
        other: directCostsOther,
        spareParts: directCostsSpareParts,
      },
      indirectCosts: {
        financing: indirectCostsFinancing,
        insurance: indirectCostsInsurance,
        other: indirectCostsOther,
      },
      machine: machineURL,
      turnover: {invoiced: turnoverInvoiced},
    });
  }, [
    directCostsForeignWorkshop,
    directCostsOther,
    directCostsSpareParts,
    indirectCostsFinancing,
    indirectCostsInsurance,
    indirectCostsOther,
    machineURL,
    onOk,
    turnoverInvoiced,
  ]);

  useEffect(() => {
    onConfigurationChange();
  }, [
    directCostsForeignWorkshop,
    directCostsOther,
    directCostsSpareParts,
    indirectCostsFinancing,
    indirectCostsInsurance,
    indirectCostsOther,
    machineURL,
    onConfigurationChange,
    turnoverInvoiced,
  ]);

  const gridSizing = {
    lg: 3,
    sm: 6,
    xs: 12,
  } as const;

  const selectedMachine = machineURL ? machineLookup(machineURL) : null;

  let turnoverFetchedFromEconomic = false;
  let directCostsFetchedFromEconomic = false;
  let indirectCostsFetchedFromEconomic = false;
  if (
    customerSettings.economicSync &&
    (customerSettings.economicEnableMachinesDepartmentSync ||
      customerSettings.economicEnableMachinesProjectEmployeesSync)
  ) {
    Object.values(customerSettings.machineAnalysisAccounts).forEach((accountType) => {
      if (accountType === "TURNOVER") {
        turnoverFetchedFromEconomic = true;
      } else if (accountType === "DIRECT_COSTS") {
        directCostsFetchedFromEconomic = true;
      } else if (accountType === "INDIRECT_COSTS") {
        indirectCostsFetchedFromEconomic = true;
      }
    });
  }

  const noEconomicTurnoverFields = turnoverFetchedFromEconomic ? (
    <FormattedMessage
      defaultMessage="Omsætning udlæses fra E-conomic"
      id="machine-analysis.text.turnover-from-economic"
    />
  ) : (
    <DecimalField
      decimalPlaces={2}
      fullWidth
      label={
        <FormattedMessage
          defaultMessage="Faktureret (kr)"
          id="user-profile.label.turnover-invoiced"
        />
      }
      margin="dense"
      maxDigits={11}
      onChange={setTurnoverInvoiced}
      value={turnoverInvoiced}
    />
  );
  const noEconomicDirectCostsFields = directCostsFetchedFromEconomic ? (
    <FormattedMessage defaultMessage="Direkte omkostninger udlæses fra E-conomic" />
  ) : (
    <>
      <DecimalField
        decimalPlaces={2}
        fullWidth
        label={
          <FormattedMessage
            defaultMessage="Reservedele (kr)"
            id="user-profile.label.direct-costs-spare-parts"
          />
        }
        margin="dense"
        maxDigits={11}
        onChange={setDirectCostsSpareParts}
        value={directCostsSpareParts}
      />
      <DecimalField
        decimalPlaces={2}
        fullWidth
        label={
          <FormattedMessage
            defaultMessage="Fremmed værksted (kr)"
            id="user-profile.label.direct-costs-foreign-workshop"
          />
        }
        margin="dense"
        maxDigits={11}
        onChange={setDirectCostsForeignWorkshop}
        value={directCostsForeignWorkshop}
      />
    </>
  );

  const noEconomicIndirectCostsFields = indirectCostsFetchedFromEconomic ? (
    <FormattedMessage defaultMessage="Indirekte omkostninger udlæses fra E-conomic" />
  ) : (
    <>
      <DecimalField
        decimalPlaces={2}
        fullWidth
        label={
          <FormattedMessage
            defaultMessage="Forsikring (kr)"
            id="user-profile.label.indirect-costs-insurance"
          />
        }
        margin="dense"
        maxDigits={11}
        onChange={setIndirectCostsInsurance}
        value={indirectCostsInsurance}
      />
      <DecimalField
        decimalPlaces={2}
        fullWidth
        label={
          <FormattedMessage
            defaultMessage="Finansiering (kr)"
            id="user-profile.label.indirect-costs-financing"
          />
        }
        margin="dense"
        maxDigits={11}
        onChange={setIndirectCostsFinancing}
        value={indirectCostsFinancing}
      />
    </>
  );

  return (
    <>
      <Card>
        <CardHeader
          title={
            <FormattedMessage
              defaultMessage="Opsætning"
              id="machine-analysis.card-header.configuration"
            />
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item {...gridSizing}>
              <div>
                <FormattedMessage
                  defaultMessage="Maskine"
                  id="machine-analysis.label.machine"
                  tagName="h4"
                />
                <Button color="primary" onClick={setMachineDialogOpenTrue} variant="contained">
                  <FormattedMessage
                    defaultMessage="Vælg maskine"
                    id="machine-analysis.label.select-machine"
                  />
                </Button>
              </div>
              {selectedMachine ? (
                <DeletableChip
                  deletionId={selectedMachine.url}
                  label={selectedMachine.name}
                  onDelete={handleRemoveMachine}
                />
              ) : null}
            </Grid>
            <Grid item {...gridSizing}>
              <FormattedMessage
                defaultMessage="Omsætning"
                id="machine-analysis.header.turnover"
                tagName="h4"
              />
              {noEconomicTurnoverFields}
            </Grid>
            <Grid item {...gridSizing}>
              <FormattedMessage
                defaultMessage="Direkte omkostninger"
                id="machine-analysis.header.direct-costs"
                tagName="h4"
              />
              {noEconomicDirectCostsFields}
              <DecimalField
                decimalPlaces={2}
                fullWidth
                label={
                  <FormattedMessage
                    defaultMessage="Andet (kr)"
                    id="user-profile.label.direct-costs-other"
                  />
                }
                margin="dense"
                maxDigits={11}
                onChange={setDirectCostsOther}
                value={directCostsOther}
              />
            </Grid>
            <Grid item {...gridSizing}>
              <FormattedMessage
                defaultMessage="Indirekte omkostninger"
                id="machine-analysis.header.indirect-costs"
                tagName="h4"
              />
              {noEconomicIndirectCostsFields}
              <DecimalField
                decimalPlaces={2}
                fullWidth
                label={
                  <FormattedMessage
                    defaultMessage="Andet (kr)"
                    id="user-profile.label.indirect-costs-other"
                  />
                }
                margin="dense"
                maxDigits={11}
                onChange={setIndirectCostsOther}
                value={indirectCostsOther}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button color="primary" disabled={!machineURL} onClick={handleOk} variant="contained">
            <FormattedMessage defaultMessage="Hent" id="machine-analysis.label.fetch" />
          </Button>
        </CardActions>
      </Card>
      <MachineDialog
        machineArray={potentialMachines}
        machineLabelVariant={customerSettings.machineLabelVariant}
        onCancel={setMachineDialogOpenFalse}
        onOk={handleMachineDialogOk}
        open={machineDialogOpen}
      />
    </>
  );
}
