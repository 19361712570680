import {getCurrentRole} from "@co-frontend-libs/redux";
import HistoryIcon from "mdi-react/HistoryIcon";
import React from "react";
import {useSelector} from "react-redux";
import {GoToPathButton} from "../go-to-path-button";

interface ResourceHistoryButtonProps {
  resourceUUID: string;
}

export function ResourceHistoryButton(props: ResourceHistoryButtonProps): React.JSX.Element | null {
  const {resourceUUID} = props;

  const currentRole = useSelector(getCurrentRole);

  if (!currentRole?.consultant) {
    return null;
  }

  return (
    <GoToPathButton
      path="/consultant-tools"
      queryParameters={{q: resourceUUID, tab: "ResourceHistory"}}
    >
      <HistoryIcon />
    </GoToPathButton>
  );
}
