import {Location} from "@co-common-libs/resources";
import {getLocationProductNonZeroCounts} from "@co-common-libs/resources-utils";
import {
  getCustomerSettings,
  getLocationStorageAdjustmentArray,
  getLocationStorageChangeArray,
  getLocationStorageStatusArray,
  getLocationTypeArray,
} from "@co-frontend-libs/redux";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {LocationMarkersWithoutMenu} from "./location-markers-without-menu";

interface FieldsInformationProps {
  fields: readonly Location[];
  googleMapsLinks: boolean;
  map: google.maps.Map;
}

export const FieldsInformation = React.memo(function FieldsInformation(
  props: FieldsInformationProps,
): React.JSX.Element {
  const {fields, googleMapsLinks, map} = props;
  const customerSettings = useSelector(getCustomerSettings);

  const fieldLocationType = customerSettings.fieldDefaultLocationType || "field";

  const selectedMarkerTypes = [fieldLocationType];
  const locationStorageStatusArray = useSelector(getLocationStorageStatusArray);
  const locationStorageChangeArray = useSelector(getLocationStorageChangeArray);
  const locationStorageAdjustmentArray = useSelector(getLocationStorageAdjustmentArray);
  const locationTypeArray = useSelector(getLocationTypeArray);

  const locationProductCounts = useMemo(
    () =>
      getLocationProductNonZeroCounts(
        locationStorageStatusArray,
        locationStorageAdjustmentArray,
        locationStorageChangeArray,
      ),
    [locationStorageAdjustmentArray, locationStorageChangeArray, locationStorageStatusArray],
  );

  const usedLocationTypeURLs = useMemo(() => {
    const result = new Set<string>();
    fields.forEach((location) => {
      if (location.locationType) {
        result.add(location.locationType);
      }
    });
    return result;
  }, [fields]);

  const usedLocationTypes = useMemo(
    () => locationTypeArray.filter((locationType) => usedLocationTypeURLs.has(locationType.url)),
    [locationTypeArray, usedLocationTypeURLs],
  );

  return (
    <LocationMarkersWithoutMenu
      filteredLocationArray={fields}
      googleMapsLinks={googleMapsLinks}
      locationProductCounts={locationProductCounts}
      map={map}
      selectedMarkerTypes={selectedMarkerTypes}
      usedLocationTypes={usedLocationTypes}
    />
  );
});
