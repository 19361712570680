import {CustomerUrl, LocationUrl} from "@co-common-libs/resources";
import {assign, createMachine, StateMachine} from "@xstate/fsm";

interface LocationFieldSelectionContext {
  customer: CustomerUrl | null;
  customerHasFields: boolean | null;
  fields: readonly LocationUrl[];
  location: LocationUrl | null;
  locationCreateInitialAddress: string | null;
  locationCreationAllowed: boolean | null;
  taskWizardAskForWorkPlace: boolean | null;
  taskWizardFieldSelection: boolean | null;
}

export type LocationFieldSelectionEvent =
  | {
      customer: CustomerUrl | null;
      customerHasFields: boolean;
      customerHasWorkPlaces: boolean;
      fields: readonly LocationUrl[];
      locationCreationAllowed: boolean;
      taskWizardAskForWorkPlace: boolean;
      taskWizardFieldSelection: boolean;
      type: "START";
    }
  | {searchText: string; type: "ADD_LOCATION"}
  | {type: "CANCEL"}
  | {type: "CREATED"; url: LocationUrl}
  | {type: "FIELDS_SELECTED"; urls: ReadonlySet<LocationUrl>}
  | {type: "LOCATION_SELECTED"; url: LocationUrl}
  | {type: "NONE"};

type LocationFieldSelectionTypeState =
  | {
      context: LocationFieldSelectionContext;
      value: "initial";
    }
  | {
      context: LocationFieldSelectionContext & {
        customerHasFields: boolean;
      };
      value: "createNewLocation";
    }
  | {
      context: LocationFieldSelectionContext & {
        customerHasFields: boolean;
      };
      value: "selectLocation";
    }
  | {
      context: LocationFieldSelectionContext & {
        customerHasFields: true;
      };
      value: "selectFields";
    };

export type LocationFieldSelectionState = StateMachine.State<
  LocationFieldSelectionContext,
  LocationFieldSelectionEvent,
  LocationFieldSelectionTypeState
>;

export const locationFieldSelectionStateMachine = createMachine<
  LocationFieldSelectionContext,
  LocationFieldSelectionEvent,
  LocationFieldSelectionTypeState
>({
  context: {
    customer: null,
    customerHasFields: null,
    fields: [],
    location: null,
    locationCreateInitialAddress: null,
    locationCreationAllowed: null,
    taskWizardAskForWorkPlace: null,
    taskWizardFieldSelection: null,
  },
  id: "locationSelection",
  initial: "initial",
  states: {
    createNewLocation: {
      on: {
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
        CREATED: {
          actions: [
            assign({
              location: (_context, event) => event.url,
            }),
            "signalDone",
          ],
          target: "initial",
        },
      },
    },
    initial: {
      on: {
        START: [
          {
            actions: assign({
              customer: (_context, event) => event.customer,
              customerHasFields: (_context, event) => event.customerHasFields,
              fields: (_context, _event) => [],
              location: (_context, _event) => null,
              locationCreationAllowed: (_context, event) => event.locationCreationAllowed,
              taskWizardAskForWorkPlace: (_context, event) => event.taskWizardAskForWorkPlace,
              taskWizardFieldSelection: (_context, event) => event.taskWizardFieldSelection,
            }),
            cond: (_context, event) =>
              event.taskWizardAskForWorkPlace &&
              (event.customerHasWorkPlaces || event.locationCreationAllowed) &&
              !event.fields.length,
            target: "selectLocation",
          },
          {
            actions: assign({
              customer: (_context, event) => event.customer,
              customerHasFields: (_context, event) => event.customerHasFields,
              fields: (_context, _event) => [],
              location: (_context, _event) => null,
              locationCreationAllowed: (_context, event) => event.locationCreationAllowed,
            }),
            cond: (_context, event) =>
              event.taskWizardFieldSelection && event.customerHasFields && !event.fields.length,
            target: "selectFields",
          },
          {
            actions: [
              assign({
                fields: (_context, event) => event.fields,
                location: (_context, _event) => null,
              }),
              "signalDone",
            ],
            target: "initial",
          },
        ],
      },
    },
    selectFields: {
      on: {
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
        FIELDS_SELECTED: {
          actions: [
            assign({
              fields: (_context, event) => Array.from(event.urls),
            }),
            "signalDone",
          ],
          target: "initial",
        },
        NONE: {
          actions: "signalDone",
          target: "initial",
        },
      },
    },
    selectLocation: {
      on: {
        ADD_LOCATION: {
          actions: assign({
            locationCreateInitialAddress: (_context, event) => event.searchText,
          }),
          target: "createNewLocation",
        },
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
        LOCATION_SELECTED: {
          actions: [
            assign({
              location: (_context, event) => event.url,
            }),
            "signalDone",
          ],
          target: "initial",
        },
        NONE: [
          {
            cond: (context, _event) =>
              (context.taskWizardFieldSelection as boolean) &&
              (context.customerHasFields as boolean),
            target: "selectFields",
          },
          {
            actions: "signalDone",
            target: "initial",
          },
        ],
      },
    },
  },
});
