import {
  LocationUrl,
  PatchOperation,
  ReportingLocation,
  ReportingSpecification,
  Task,
} from "@co-common-libs/resources";
import {DeleteDialog} from "@co-frontend-libs/components";
import {
  actions,
  AppState,
  getLocationLookup,
  getOrderLookup,
  makeSelectUserCustomerLastUsedLocations,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {IconButton, Menu, MenuItem, PropTypes, useTheme} from "@material-ui/core";
import _ from "lodash";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {EditReportingLocation, ValuesState} from "./edit-reporting-location-dialog";
import {FieldWithLogDataDialog} from "./field-with-log-data-dialogs";
import {NonFieldLocationWithLogDataDialog} from "./non-field-location-with-log-data-dialogs";

const emptyData: ValuesState = {};

interface EditReportingLocationFabProps {
  color?: PropTypes.Color;
  disabled?: boolean;
  logLocationId: string;
  logSpecification: ReportingSpecification;
  style?: React.CSSProperties;
  task: Task;
  withDelete?: boolean;
}

export const EditReportingLocationFab = React.memo(function EditReportingLocationFab(
  props: EditReportingLocationFabProps,
): React.JSX.Element {
  const {
    color = "default",
    disabled,
    logLocationId,
    logSpecification,
    style = {},
    task,
    withDelete,
  } = props;

  const theme = useTheme();

  const locationLookup = useSelector(getLocationLookup);
  const orderLookup = useSelector(getOrderLookup);

  const dispatch = useDispatch();

  const actionMenuButtonRef = useRef<HTMLButtonElement>(null);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const setActionMenuOpenTrue = useCallWithTrue(setActionMenuOpen);
  const setActionMenuOpenFalse = useCallWithFalse(setActionMenuOpen);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const setEditDialogOpenTrue = useCallWithTrue(setEditDialogOpen);
  const setEditDialogOpenFalse = useCallWithFalse(setEditDialogOpen);

  const [switchFieldDialogOpen, setSwitchFieldDialogOpen] = useState(false);
  const setSwitchFieldDialogOpenTrue = useCallWithTrue(setSwitchFieldDialogOpen);
  const setSwitchFieldDialogOpenFalse = useCallWithFalse(setSwitchFieldDialogOpen);

  const [switchLocationDialogOpen, setSwitchLocationDialogOpen] = useState(false);
  const setSwitchLocationDialogOpenTrue = useCallWithTrue(setSwitchLocationDialogOpen);
  const setSwitchLocationDialogOpenFalse = useCallWithFalse(setSwitchLocationDialogOpen);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const setDeleteDialogOpenTrue = useCallWithTrue(setDeleteDialogOpen);
  const setDeleteDialogOpenFalse = useCallWithFalse(setDeleteDialogOpen);

  const logLocation: ReportingLocation | undefined = task.reportingLocations[logLocationId];
  const logLocationType = logLocation?.type;
  const locationUrl = logLocation?.location;

  const inputSpecifications = useMemo(
    () => logSpecification.workplaceData[logLocationType]?.inputs || [],
    [logLocationType, logSpecification.workplaceData],
  );

  const location = locationUrl && locationLookup(locationUrl);

  const currentData = logLocation?.values || emptyData;

  const handleEditOk = useCallback(
    (data: ValuesState): void => {
      const patch: PatchOperation<Task>[] = [];
      if (!_.isEqual(data, currentData)) {
        patch.push({
          path: ["reportingLocations", logLocationId, "values"],
          value: data,
        });
      }
      if (patch.length) {
        dispatch(actions.update(task.url, patch));
      }
      setEditDialogOpen(false);
    },
    [currentData, dispatch, logLocationId, task.url],
  );

  const handleSwitchFieldOrLocationOk = useCallback(
    (newLocationUrl: LocationUrl, data: ValuesState): void => {
      const patch: PatchOperation<Task>[] = [];
      if (newLocationUrl !== locationUrl) {
        patch.push({
          path: ["reportingLocations", logLocationId, "location"],
          value: newLocationUrl,
        });
      }
      if (!_.isEqual(data, currentData)) {
        patch.push({
          path: ["reportingLocations", logLocationId, "values"],
          value: data,
        });
      }
      if (patch.length) {
        dispatch(actions.update(task.url, patch));
      }
      setSwitchFieldDialogOpen(false);
      setSwitchLocationDialogOpen(false);
    },
    [currentData, dispatch, locationUrl, logLocationId, task.url],
  );

  const handleDeleteOk = useCallback((): void => {
    if (logLocationId && task.reportingLocations) {
      dispatch(
        actions.update(task.url, [{path: ["reportingLocations", logLocationId], value: undefined}]),
      );
    }
    setDeleteDialogOpen(false);
  }, [dispatch, logLocationId, task.reportingLocations, task.url]);

  const removable = useMemo(
    () =>
      withDelete &&
      !Object.values(task.reportingLog).some((entry) => entry.location === logLocationId),
    [logLocationId, task.reportingLog, withDelete],
  );

  const order = task.order && orderLookup(task.order);

  const customerAndUser = useMemo(
    () => ({customer: order?.customer || null, user: task.machineOperator}),
    [order?.customer, task.machineOperator],
  );

  const selectUserCustomerLastUsedLocations = useMemo(makeSelectUserCustomerLastUsedLocations, []);
  const lastUsedLocations = useSelector((state: AppState) =>
    selectUserCustomerLastUsedLocations(state, customerAndUser),
  );

  return (
    <>
      <IconButton
        color={color}
        disabled={!!disabled}
        onClick={setActionMenuOpenTrue}
        ref={actionMenuButtonRef}
        style={style}
      >
        <DotsHorizontalIcon />
      </IconButton>
      <Menu
        anchorEl={actionMenuButtonRef?.current}
        onClick={setActionMenuOpenFalse}
        onClose={setActionMenuOpenFalse}
        open={actionMenuOpen}
      >
        {inputSpecifications.length ? (
          <MenuItem onClick={setEditDialogOpenTrue}>
            <FormattedMessage defaultMessage="Rediger" />
          </MenuItem>
        ) : null}
        {location?.geojson ? (
          <MenuItem onClick={setSwitchFieldDialogOpenTrue}>
            <FormattedMessage defaultMessage="Skift mark" />
          </MenuItem>
        ) : (
          <MenuItem onClick={setSwitchLocationDialogOpenTrue}>
            <FormattedMessage defaultMessage="Skift sted" />
          </MenuItem>
        )}
        {withDelete ? (
          <MenuItem
            disabled={!removable}
            onClick={setDeleteDialogOpenTrue}
            style={removable ? {color: theme.palette.error.main} : {}}
          >
            <FormattedMessage defaultMessage="Fjern" />
          </MenuItem>
        ) : null}
      </Menu>
      {logLocation.type && locationUrl ? (
        <EditReportingLocation
          currentData={currentData}
          inputSpecifications={inputSpecifications}
          locationUrl={locationUrl}
          logSpecification={logSpecification}
          onCancel={setEditDialogOpenFalse}
          onOk={handleEditOk}
          open={editDialogOpen}
          type={logLocation.type}
        />
      ) : null}
      {logLocation.type ? (
        <FieldWithLogDataDialog
          customerUrl={location?.customer || null}
          lastUsedLocations={lastUsedLocations}
          logSpecification={logSpecification}
          onCancel={setSwitchFieldDialogOpenFalse}
          onOk={handleSwitchFieldOrLocationOk}
          open={switchFieldDialogOpen}
          type={logLocation.type}
        />
      ) : null}
      {logLocation.type ? (
        <NonFieldLocationWithLogDataDialog
          customerUrl={location?.customer || null}
          lastUsedLocations={lastUsedLocations}
          logSpecification={logSpecification}
          onCancel={setSwitchLocationDialogOpenFalse}
          onOk={handleSwitchFieldOrLocationOk}
          open={switchLocationDialogOpen}
          type={logLocation.type}
        />
      ) : null}
      <DeleteDialog
        onCancel={setDeleteDialogOpenFalse}
        onOk={handleDeleteOk}
        open={deleteDialogOpen}
      >
        <FormattedMessage defaultMessage="Fjern sted?" />
      </DeleteDialog>
    </>
  );
});
