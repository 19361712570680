import {
  CultureUrl,
  CustomerUrl,
  LocationUrl,
  MachineUrl,
  ProjectUrl,
  ResourceName,
  ResourceUrls,
  TaskPhoto,
  urlToId,
  UserUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {actions} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {CircularProgress, Tab, Tabs} from "@material-ui/core";
import {getApiEndpoint} from "api-endpoint-urls";
import {
  FilterCard,
  LogsCard,
  LogTaskData,
  MenuToolbar,
  PageLayout,
  PhotoDisplayDialog,
  PhotosCard,
  PhotoTaskData,
} from "app-components";
import {useIDListQueryParameterState, useQueryParameterState} from "app-utils";
import {globalConfig, instanceURL} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";

// code moved and type parameter and typecast added for compatibility with
// URL type checks in other code; not a good aproach
function useURLListQueryParameterState<Resource extends ResourceName>(
  key: string,
  defaultValue: string[],
  resourceName: Resource,
  alwaysWriteToQueryParam: boolean = false,
): [ResourceUrls[Resource][], (newValue: ResourceUrls[Resource][]) => void] {
  const [baseValue, baseSetValue] = useIDListQueryParameterState(
    key,
    defaultValue,
    alwaysWriteToQueryParam,
  );
  const value = baseValue.map((id) => instanceURL(resourceName, id));
  const setValue = useCallback(
    (newValue: ResourceUrls[Resource][]): void => {
      baseSetValue(newValue.map(urlToId));
    },
    [baseSetValue],
  );
  const result = useMemo(
    (): [ResourceUrls[Resource][], (newValue: ResourceUrls[Resource][]) => void] => [
      value,
      setValue,
    ],
    [setValue, value],
  );
  return result;
}

export const Appendices = React.memo(function Appendices({
  onMenuButton,
}: {
  onMenuButton: (event: React.MouseEvent) => void;
}): React.JSX.Element {
  const [data, setData] = useState<{
    readonly logTasks: readonly LogTaskData[];
    readonly photoTasks: readonly PhotoTaskData[];
  } | null>(null);

  const [photoFilesData, setPhotoFilesData] = useState<PhotoTaskData[] | null>(null);
  const [logTasks, setTaskLogs] = useState<LogTaskData[] | null>(null);
  const {formatMessage} = useIntl();
  const dispatch = useDispatch();
  const [displayedImage, setDisplayedImage] = useState<TaskPhoto>();
  const [fetching, setFetching] = useState(false);

  const [activeTab, setActiveTab] = useQueryParameterState<"logs" | "photos">("tab", "photos");
  const [toDate, setToDate] = useQueryParameterState<string>("toDate", "");
  const [fromDate, setFromDate] = useQueryParameterState<string>("fromDate", "");
  const [cultures, setCultures] = useURLListQueryParameterState("cultures", [], "culture");
  const [customers, setCustomers] = useURLListQueryParameterState("customers", [], "customer");
  const [locations, setLocations] = useURLListQueryParameterState("locations", [], "location");
  const [machines, setMachines] = useURLListQueryParameterState("machines", [], "machine");
  const [users, setUsers] = useURLListQueryParameterState("users", [], "user");
  const [workTypes, setWorkTypes] = useURLListQueryParameterState("workTypes", [], "workType");
  const [project, setProject] = useQueryParameterState<string>("project", "");
  const [referenceNumber, setReferenceNumber] = useQueryParameterState<string>(
    "referenceNumber",
    "",
  );

  const handleSearchClick = useCallback(() => {
    if (fetching) {
      return;
    }

    const fetchData = {
      cultures: cultures.length ? cultures.map(urlToId) : [],
      customers: customers.length ? customers.map(urlToId) : [],
      fromDate,
      locations: locations.length ? locations.map(urlToId) : [],
      machines: machines.length ? machines.map(urlToId) : [],
      referenceNumber,
      toDate,
      users: users.length ? users.map(urlToId) : [],
      workTypes: workTypes.length ? workTypes.map(urlToId) : [],
    };

    if (activeTab === "logs") {
      const url = getApiEndpoint(globalConfig.baseURL).archivedLogs();

      const logsData = {
        ...fetchData,
        projectId: project ? urlToId(project) : null,
      };

      setData(null);
      setFetching(true);

      jsonFetch(url, "POST", logsData)
        .then((response) => {
          setTaskLogs(response.data);
          setFetching(false);
          return;
        })
        .catch(() => {
          setFetching(false);
        });
    } else if (activeTab === "photos") {
      const url = getApiEndpoint(globalConfig.baseURL).archivedPhotoFiles();
      setData(null);
      setFetching(true);

      const photosData = {
        ...fetchData,
        projectId: project ? urlToId(project) : null,
      };

      jsonFetch(url, "POST", photosData)
        .then((response) => {
          setPhotoFilesData(response.data);
          setFetching(false);
          return;
        })
        .catch(() => {
          setFetching(false);
        });
    }
  }, [
    activeTab,
    cultures,
    customers,
    fetching,
    fromDate,
    locations,
    machines,
    project,
    referenceNumber,
    toDate,
    users,
    workTypes,
  ]);

  useEffect(() => {
    if (fromDate && toDate) {
      handleSearchClick();
    }
    // We only want to autostart search when entering page with from and to dates set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCustomersDialogOk = useCallback(
    (selectedCustomers: ReadonlySet<CustomerUrl>) => {
      setCustomers([...selectedCustomers]);
    },
    [setCustomers],
  );

  const handleUsersDialogOk = useCallback(
    (selectedUsers: ReadonlySet<UserUrl>) => {
      setUsers([...selectedUsers]);
    },
    [setUsers],
  );

  const handleLocationsDialogOk = useCallback(
    (selectedLocations: ReadonlySet<LocationUrl>) => {
      setLocations([...selectedLocations]);
    },
    [setLocations],
  );

  const handleCulturesDialogOk = useCallback(
    (selectedCultures: ReadonlySet<CultureUrl>) => {
      setCultures([...selectedCultures]);
    },
    [setCultures],
  );

  const handleWorkTypesDialogOk = useCallback(
    (selectedWorkTypes: ReadonlySet<WorkTypeUrl>) => {
      setWorkTypes([...selectedWorkTypes]);
    },
    [setWorkTypes],
  );

  const handleMachinesDialogOk = useCallback(
    (selectedMachines: ReadonlySet<MachineUrl>) => {
      setMachines([...selectedMachines]);
    },
    [setMachines],
  );

  const handleProjectDialogOk = useCallback(
    (selectedProject: string) => {
      setProject(selectedProject);
    },
    [setProject],
  );

  const handleFromDateChange = useCallback(
    (value: string | null) => {
      setFromDate(value || "");
    },
    [setFromDate],
  );

  const handleToDateChange = useCallback(
    (value: string | null) => {
      setToDate(value || "");
    },
    [setToDate],
  );

  const handleDisplayImageRequestClose = useCallback(() => {
    setDisplayedImage(undefined);
  }, []);

  const handleGoToTask = useCallback(
    (url: string) => {
      const id = urlToId(url);
      dispatch(actions.go("/task/:id", {id}));
    },
    [dispatch],
  );

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string) => {
      setActiveTab(value as "logs" | "photos");
    },
    [setActiveTab],
  );

  const searchDisabled = !fromDate || !toDate || fromDate > toDate || fetching;

  const photoDisplayDialog = (
    <PhotoDisplayDialog
      instance={displayedImage}
      key="photo-display-dialog"
      onRequestClose={handleDisplayImageRequestClose}
      onRequestGoToTask={handleGoToTask}
    />
  );
  const tabs = (
    <Tabs onChange={handleTabChange} scrollButtons="auto" value={activeTab} variant="scrollable">
      <Tab label={formatMessage({defaultMessage: "Fotos"})} value="photos" />
      <Tab label={formatMessage({defaultMessage: "Logs"})} value="logs" />
    </Tabs>
  );

  return (
    <PageLayout
      dialogs={[photoDisplayDialog]}
      performScrolling={!!data}
      tabs={tabs}
      toolbar={
        <MenuToolbar onMenuButton={onMenuButton} title={formatMessage({defaultMessage: "Bilag"})} />
      }
      withBottomScrollPadding
      withPadding
    >
      <FilterCard
        fromDate={fromDate}
        onCulturesDialogOk={handleCulturesDialogOk}
        onCustomersDialogOk={handleCustomersDialogOk}
        onFromDateChange={handleFromDateChange}
        onLocationsDialogOk={handleLocationsDialogOk}
        onMachinesDialogOk={handleMachinesDialogOk}
        onProjectDialogOk={handleProjectDialogOk}
        onReferenceNumberChange={setReferenceNumber}
        onSearchClick={handleSearchClick}
        onToDateChange={handleToDateChange}
        onUsersDialogOk={handleUsersDialogOk}
        onWorkTypesDialogOk={handleWorkTypesDialogOk}
        referenceNumber={referenceNumber}
        searchDisabled={searchDisabled}
        selectedCultures={cultures}
        selectedCustomers={customers}
        selectedLocations={locations}
        selectedMachines={machines}
        selectedProject={project ? (project as ProjectUrl) : null}
        selectedUsers={users}
        selectedWorkTypes={workTypes}
        toDate={toDate}
      />
      {fetching ? (
        <div>
          <div
            style={{
              height: 62,
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1em",
              width: 50,
            }}
          >
            <CircularProgress />
          </div>
        </div>
      ) : null}
      {activeTab === "photos" ? <PhotosCard data={photoFilesData} /> : <LogsCard data={logTasks} />}
    </PageLayout>
  );
});
