import type {Writable} from "ts-essentials";
import {Config} from "@co-common-libs/config";
import {
  DaysAbsence,
  HoursAbsence,
  Machine,
  MachineUrl,
  MachineUse,
  PatchOperation,
  PatchUnion,
  ResourceTypeUnion,
  Role,
  Task,
  TimerStart,
  urlToId,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {dateToString, formatDate, notUndefined} from "@co-common-libs/utils";
import {
  DateField,
  DeleteDialog,
  ErrorColorButton,
  MachineChip,
  MinutesField,
  TimeField,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  ConnectedDepartmentDialog,
  ConnectedInternalWorkTypeDialog,
  ConnectedMachineDialog,
  ConnectedMachineOperatorDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  AppState,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getDaysAbsenceArray,
  getDeviceConfigKey,
  getHoursAbsenceArray,
  getMachineLookup,
  getTaskArray,
  getTaskLookup,
  getTimerStartArray,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {colorMap, matchingTextColor} from "@co-frontend-libs/utils";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  ChipProps,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  DoLoadInstance,
  getOtherTaskCount,
  PageLayout,
  RecordInC5Dialog,
  RemoveRecordedInC5Dialog,
  TaskCountMessage,
} from "app-components";
import {
  checkUserAbsence,
  computeIntervalsTruncated,
  copyTask,
  getDepartmentName,
  machineAlreadySelected,
  mergeIntervals,
  removeUnicodeCf,
} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
// Allowed for existing code...
import React from "react";
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, IntlContext, useIntl} from "react-intl";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {createStructuredSelector} from "reselect";
import {allowMachineUseForWorktype} from "./order-instance/task-instance";
import CopiedDialog from "./task-instance/copied-dialog";

const EMPTY_LIST = [] as const;

const messages = defineMessages({
  address: {
    defaultMessage: "Arbejdssted",
    id: "order-instance.label.task-address",
  },
  arrivalAtLocation: {
    defaultMessage: "Start ved kunden kl.",
    id: "task-instance.label.arrival-at-customer",
  },
  breaks: {defaultMessage: "Pause"},
  cancel: {defaultMessage: "Fortryd", id: "dialog.label.cancel"},
  completed: {
    defaultMessage: "Fuldført",
  },
  confirmDelete: {
    defaultMessage: "Slet",
    id: "damage-report-instance.confirm-delete",
  },
  copyTask: {
    defaultMessage: "Opret kopi",
    id: "internal-task.button.label.copy",
  },
  date: {defaultMessage: "Dato"},
  delete: {defaultMessage: "Slet", id: "order-instance.label.delete"},
  deleteQuestion: {
    defaultMessage: "Slet?",
    id: "order-instance.dialog-title.delete",
  },
  department: {
    defaultMessage: "Afdeling",
    id: "order-instance.hint-text.department",
  },
  downtime: {defaultMessage: "Driftstop"},
  effective: {
    defaultMessage: "Effektiv tid",
  },
  endTime: {
    defaultMessage: "Sluttidspunkt",
  },
  expectedTotalTaskDuration: {
    defaultMessage: "Forventet varighed",
    id: "task-instance.label.expectedTotalTaskDuration",
  },
  notesFromAdministration: {
    defaultMessage: "Noter fra administration",
  },
  notesFromChauffeur: {
    defaultMessage: "Noter fra chauffør",
    id: "order-instance.label.notes-from-chauffeur",
  },
  notesFromEmployee: {
    defaultMessage: "Noter fra medarbejder",
  },
  notesFromMachineOperator: {
    defaultMessage: "Noter fra maskinfører",
    id: "order-instance.label.notes-from-machine-operator",
  },
  now: {defaultMessage: "NU"},
  recorded: {
    defaultMessage: "Bogført",
  },
  selectDepartmentButton: {
    defaultMessage: "Vælg",
    id: "order-instance.label.select-department",
  },
  selectMachineButton: {
    defaultMessage: "Vælg",
    id: "order-instance.label.select-machine",
  },
  selectMachineOperatorButton: {
    defaultMessage: "Vælg",
  },
  selectTaskTypeButton: {
    defaultMessage: "Vælg",
  },
  selectWorkplaceButton: {
    defaultMessage: "Vælg",
    id: "order-instance.label.select-workplace",
  },
  selectWorkTypeButton: {
    defaultMessage: "Vælg",
    id: "internal-task.label.select-work-type",
  },
  startTime: {
    defaultMessage: "Starttidspunkt",
  },
  taskCardTitle: {defaultMessage: "Opgave"},
  taskTitle: {defaultMessage: "Opgave", id: "internal-task.title.task"},
  time: {defaultMessage: "Klokkeslæt"},
  transportation: {
    defaultMessage: "Transport",
  },
  validateButton: {
    defaultMessage: "Godkend",
  },
  validated: {
    defaultMessage: "Godkendt",
  },
  validatedDialogTitle: {
    defaultMessage: "Godkend?",
    id: "task-instance.dialog-title.validate-and-record",
  },
});

interface InternalTaskStateProps {
  currentRole: Role | null;
  currentUserURL: UserUrl | null;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  deviceMachine?: string;
  hoursAbsenceArray: readonly HoursAbsence[];
  machineLookup: (url: MachineUrl) => Machine | undefined;
  taskArray: readonly Task[];
  timerStartArray: readonly TimerStart[];
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface InternalTaskDispatchProps {
  backSkip: (skip: PathTemplate[], fallback?: PathTemplate) => void;
  create: (instance: ResourceTypeUnion) => void;
  dispatch: Dispatch;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface InternalTaskOwnProps {
  instance: Task;
}

type InternalTaskProps = InternalTaskDispatchProps & InternalTaskOwnProps & InternalTaskStateProps;

interface InternalTaskState {
  copiedDialogOpen: boolean;
  deleteDialogOpen: boolean;
  departmentDialogOpen: boolean;
  machineDialogOpen: boolean;
  machineOperatorDialogOpen: boolean;
  recordInC5DialogOpen: boolean;
  removeRecordedInC5DialogOpen: boolean;
  workTypeDialogOpen: boolean;
}

class InternalTask extends React.Component<InternalTaskProps, InternalTaskState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: InternalTaskState = {
    copiedDialogOpen: false,
    deleteDialogOpen: false,
    departmentDialogOpen: false,
    machineDialogOpen: false,
    machineOperatorDialogOpen: false,
    recordInC5DialogOpen: false,
    removeRecordedInC5DialogOpen: false,
    workTypeDialogOpen: false,
  };
  getTimerStarts(): TimerStart[] {
    const task = this.props.instance;
    const taskURL = task.url;
    return this.props.timerStartArray.filter((instance) => instance.task === taskURL);
  }
  @bind
  handleCompletedChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.props.update(this.props.instance.url, [{member: "completed", value: checked}]);
  }
  @bind
  handleCopiedDialogClose(): void {
    this.setState({copiedDialogOpen: false});
  }
  @bind
  handleCopyButton(): void {
    const {currentRole, currentUserURL, customerSettings, instance, machineLookup} = this.props;
    if (!instance) {
      return;
    }
    const today = dateToString(new Date());
    const overrides: Partial<Writable<Task>> = {
      createdBy: currentUserURL,
      order: null,
    };
    if (instance.date == null || instance.date < today) {
      overrides["date"] = today;
    }
    if (
      !currentRole ||
      !currentRole.manager ||
      customerSettings.taskCopyAlwaysOverridemachineOperator
    ) {
      overrides.machineOperator = currentUserURL;
    }
    const taskCopy = copyTask(
      instance,
      customerSettings.taskCopyFields,
      overrides,
      {
        locationLookup: _.constant(undefined), // Not used for internal tasks
        machineLookup,
        priceGroupLookup: _.constant(undefined), // Only used for generic log
        productLookup: _.constant(undefined), // Not used for internal tasks
        projectLookup: _.constant(undefined), // Not used for internal tasks
        reportingSpecificationLookup: _.constant(undefined), // Only used for generic log
        workTypeLookup: _.constant(undefined), // Only used for generic log
      },
      this.props.dispatch,
      this.context,
      customerSettings,
    );
    this.props.create(taskCopy);
    window.setTimeout(() => {
      this.props.go("/internalTask/:id", {id: urlToId(taskCopy.url)}, {}, "REPLACE");
      this.setState({copiedDialogOpen: true});
    }, 0);
  }
  @bind
  handleDateUpdate(value: string | null): void {
    const {instance, update} = this.props;
    update(instance.url, [{member: "date", value}]);
  }
  @bind
  handleDeleteButton(): void {
    this.setState({
      deleteDialogOpen: true,
    });
  }
  @bind
  handleDeleteDialogCancel(): void {
    this.setState({
      deleteDialogOpen: false,
    });
  }
  @bind
  handleDeleteDialogOk(): void {
    this.setState({deleteDialogOpen: false});
    const task = this.props.instance;
    this.props.remove(task.url);
    window.setTimeout(() => {
      this.props.backSkip(["/task/:id", "/taskDetails/:id", "/internalTask/:id"]);
    });
  }
  @bind
  handleDepartmentDialogCancel(): void {
    this.setState({departmentDialogOpen: false});
  }
  @bind
  handleDepartmentDialogOk(selected: string): void {
    const task = this.props.instance;
    this.props.update(task.url, [{member: "department", value: selected}]);
    this.setState({departmentDialogOpen: false});
  }
  @bind
  handleDepartmentSelectButton(): void {
    this.setState({departmentDialogOpen: true});
  }
  @bind
  handleGoToTask(): void {
    this.props.go("/task/:id", {id: urlToId(this.props.instance.url)});
  }
  @bind
  handleMachineDialogCancel(): void {
    this.setState({
      machineDialogOpen: false,
    });
  }
  @bind
  handleMachineDialogOk(url: MachineUrl): void {
    this.setState({machineDialogOpen: false});
    const task = this.props.instance;
    const oldMachines = task.machineuseSet || EMPTY_LIST;
    if (machineAlreadySelected(task, url)) {
      return;
    }
    const newEntry: MachineUse = {
      machine: url,
      priceGroup: null,
      transporter: false,
    };
    const newMachines = [...oldMachines, newEntry];
    this.props.update(task.url, [{member: "machineuseSet", value: newMachines}]);
  }
  @bind
  handleMachineOperatorDialogCancel(): void {
    this.setState({machineOperatorDialogOpen: false});
  }
  @bind
  handleMachineOperatorDialogOk(url: UserUrl): void {
    const task = this.props.instance;
    this.props.update(task.url, [{member: "machineOperator", value: url}]);
    this.setState({machineOperatorDialogOpen: false});
  }
  @bind
  handleMachineOperatorSelectButton(): void {
    this.setState({machineOperatorDialogOpen: true});
  }
  @bind
  handleMachineSelectButton(): void {
    this.setState({machineDialogOpen: true});
  }
  @bind
  handleMinutesExpectedTotalTaskDurationChange(value: number | null): void {
    const {instance, update} = this.props;
    update(instance.url, [{member: "minutesExpectedTotalTaskDuration", value}]);
  }
  @bind
  handleNotesFromMachineOperatorChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {instance, update} = this.props;
    update(instance.url, [{member: "notesFromMachineOperator", value: filteredValue}]);
  }
  @bind
  handleNotesFromManagerChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {instance, update} = this.props;
    update(instance.url, [{member: "notesFromManager", value: filteredValue}]);
  }
  @bind
  handleRecordInC5DialogCancel(): void {
    this.setState({recordInC5DialogOpen: false});
  }
  @bind
  handleRecordInC5DialogOk(): void {
    this.setState({recordInC5DialogOpen: false});

    const {instance} = this.props;
    this.props.update(instance.url, [
      {member: "archivable", value: true},
      {member: "recordedInC5", value: new Date().toISOString()},
    ]);
  }
  @bind
  handleRemoveMachine(index: number): void {
    const task = this.props.instance;
    const oldMachines = [...task.machineuseSet];
    oldMachines.splice(index, 1);
    const newMachines = oldMachines;
    this.props.update(task.url, [{member: "machineuseSet", value: newMachines}]);
  }
  @bind
  handleRemoveMachineOperator(): void {
    const task = this.props.instance;
    this.props.update(task.url, [{member: "machineOperator", value: null}]);
  }
  @bind
  handleRemoveRecordedInC5DialogCancel(): void {
    this.setState({removeRecordedInC5DialogOpen: false});
  }

  @bind
  handleRemoveRecordedInC5DialogOk(): void {
    this.setState({removeRecordedInC5DialogOpen: false});

    const {instance} = this.props;
    this.props.update(instance.url, [
      {member: "archivable", value: false},
      {member: "recordedInC5", value: null},
    ]);
  }
  @bind
  handleTimeChange(value: string | null): void {
    const {instance, update} = this.props;
    update(instance.url, [{member: "time", value}]);
  }

  @bind
  handleToggleRecordedInC5(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    if (!checked) {
      this.setState({removeRecordedInC5DialogOpen: true});
    } else {
      this.setState({recordInC5DialogOpen: true});
    }
  }
  @bind
  handleToggleValidated(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const task = this.props.instance;
    if (!checked) {
      this.props.update(task.url, [
        {member: "archivable", value: false},
        {member: "reportApproved", value: false},
        {member: "validatedAndRecorded", value: false},
      ]);
    }
  }

  @bind
  handleWorkTypeDialogCancel(): void {
    this.setState({workTypeDialogOpen: false});
  }

  @bind
  handleWorkTypeDialogOk(url: WorkTypeUrl): void {
    const {update: updateFn, workTypeLookup} = this.props;
    const task = this.props.instance;

    const patch: PatchOperation<Task>[] = [{member: "workType", value: url}];
    const workType = workTypeLookup(url);
    const allowMachineUse = allowMachineUseForWorktype(workType);

    if (!allowMachineUse) {
      patch.push({member: "machineuseSet", value: []});
    }
    updateFn(task.url, patch);
    this.setState({workTypeDialogOpen: false});
  }
  @bind
  handleWorkTypeSelectButton(): void {
    this.setState({workTypeDialogOpen: true});
  }

  hasActivity(): boolean {
    // NTS: expensive computation with the getTimerStarts and mergeIntervals...?
    const task = this.props.instance;
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet || EMPTY_LIST
      : computeIntervalsTruncated(this.getTimerStarts());
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || EMPTY_LIST;
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || EMPTY_LIST;
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    return !!intervals.length;
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const task = this.props.instance;
    const userURL = this.props.currentUserURL;
    const role = this.props.currentRole;
    const userIsManager = role && role.manager;
    const userIsSeniorMachineOperator = role && role.seniorMachineOperator;
    const machineOperatorURL = task.machineOperator;
    const taskAssignedToUser = userURL === machineOperatorURL;
    const hasActivity = this.hasActivity();
    const canUnapproveTasks = role && role.canUnapproveTasks;
    const validated = !!task.validatedAndRecorded || task.reportApproved;
    const completed = !!task.completed;
    const userIsConsultant = role && role.consultant;

    const canEdit =
      !validated &&
      (userIsManager ||
        (userIsSeniorMachineOperator && !hasActivity) ||
        (taskAssignedToUser && !completed));

    const canDelete = canEdit && !completed && !hasActivity;

    const workTypeURL = task.workType;
    const workType = workTypeURL ? this.props.workTypeLookup(workTypeURL) : null;
    let workTypeBlock;
    if (workType) {
      workTypeBlock = <div>{workType.name}</div>;
    }

    let machineOperator = null;
    if (machineOperatorURL && this.props.userLookup(machineOperatorURL)) {
      const profile = this.props.userUserProfileLookup(machineOperatorURL);

      const allowMachineOperatorRemoval = canDelete && !hasActivity;

      let absent;
      const machineOperatorAbsent = checkUserAbsence(
        machineOperatorURL,
        task,
        null,
        this.props.daysAbsenceArray,
        this.props.hoursAbsenceArray,
        this.props.customerSettings.absenceWarningDisabledFor,
      );
      if (machineOperatorAbsent) {
        absent = (
          <div style={{color: "red"}}>
            {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
              <FormattedMessage
                defaultMessage="Den valgte maskinfører har registreret fravær på den valgte dato"
                id="order-instance.header.machine-operator-absent"
                tagName="h4"
              />
            ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
              <FormattedMessage
                defaultMessage="Den valgte medarbejder har registreret fravær på den valgte dato"
                id="order-instance.header.employee-absent"
                tagName="h4"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Den valgte chauffør har registreret fravær på den valgte dato"
                id="order-instance.header.chauffeur-absent"
                tagName="h4"
              />
            )}
          </div>
        );
      }

      let hasOtherTasks: React.JSX.Element | undefined;
      if (customerSettings.employeeSameDayTasksWarning) {
        const machineOperatorOtherTaskCount = getOtherTaskCount(task, this.props.taskArray);
        if (machineOperatorOtherTaskCount > 0) {
          hasOtherTasks = (
            <TaskCountMessage
              customerSettings={customerSettings}
              machineOperatorOtherTaskCount={machineOperatorOtherTaskCount}
            />
          );
        }
      }

      const machineOperatorChipOptionalDeleteHandlerProps: ChipProps = {};
      if (allowMachineOperatorRemoval) {
        machineOperatorChipOptionalDeleteHandlerProps.onDelete = this.handleRemoveMachineOperator;
      }

      machineOperator = (
        <div>
          <Chip
            avatar={
              <Avatar
                style={{
                  backgroundColor: colorMap.MACHINE_OPERATOR_AVATAR_BACKGROUND,
                  color: matchingTextColor(colorMap.MACHINE_OPERATOR_AVATAR_BACKGROUND),
                }}
              >
                {profile && profile.alias}
              </Avatar>
            }
            label={profile && profile.name}
            {...machineOperatorChipOptionalDeleteHandlerProps}
          />
          {absent}
          {hasOtherTasks}
        </div>
      );
    }
    let machineBlock = null;
    const machineList = (task.machineuseSet || [])
      .map((machineUse) => {
        const machineURL = machineUse.machine;
        return this.props.machineLookup(machineURL);
      })
      .filter(notUndefined);
    const renderedEntries = machineList.map((machine, index) => {
      const machineID = machine && machine.c5_machine;
      const machineName = machine ? machine.name : "";
      const text = machineName + (machineID ? ` (${machineID})` : "");
      return (
        <MachineChip
          deletable={canEdit}
          index={index}
          key={index}
          onDelete={this.handleRemoveMachine}
          text={text}
        />
      );
    });
    machineBlock = <div>{renderedEntries}</div>;

    let createTaskCopyButtonCell = null;
    if (customerSettings.includeTaskCopy) {
      createTaskCopyButtonCell = (
        <Button
          color="primary"
          disabled={validated}
          onClick={this.handleCopyButton}
          variant="contained"
        >
          {formatMessage(messages.copyTask)}
        </Button>
      );
    }
    let departmentBlock;
    if (customerSettings.enableInternalTaskDepartmentField) {
      const departmentID = task.department;
      const department = <div>{getDepartmentName(departmentID, this.props.customerSettings)}</div>;
      departmentBlock = (
        <Cell palm="12/12">
          <FormattedMessage
            defaultMessage="Afdeling"
            id="order-instance.header.department"
            tagName="h4"
          />
          <Button
            color="secondary"
            disabled={!canEdit || (customerSettings.onlyAdminCanChangeDepartment && !userIsManager)}
            onClick={this.handleDepartmentSelectButton}
            variant="contained"
          >
            {formatMessage(messages.selectDepartmentButton)}
          </Button>
          {department}
        </Cell>
      );
    }
    let timeBlock;
    if (customerSettings.taskShowTimeField) {
      timeBlock = (
        <Cell palm="12/12">
          <TimeField
            disabled={!canEdit}
            fullWidth
            label={formatMessage(messages.time)}
            margin="dense"
            onChange={this.handleTimeChange}
            value={task.time || undefined}
          />
        </Cell>
      );
    }
    const allowMachineUse = allowMachineUseForWorktype(workType || undefined);

    const dialogs = (
      <>
        <ConnectedMachineOperatorDialog
          onCancel={this.handleMachineOperatorDialogCancel}
          onOk={this.handleMachineOperatorDialogOk}
          open={this.state.machineOperatorDialogOpen}
        />
        <ConnectedMachineDialog
          onCancel={this.handleMachineDialogCancel}
          onOk={this.handleMachineDialogOk}
          open={this.state.machineDialogOpen}
        />
        <ConnectedInternalWorkTypeDialog
          onCancel={this.handleWorkTypeDialogCancel}
          onOk={this.handleWorkTypeDialogOk}
          open={this.state.workTypeDialogOpen}
        />
        <DeleteDialog
          onCancel={this.handleDeleteDialogCancel}
          onOk={this.handleDeleteDialogOk}
          open={this.state.deleteDialogOpen}
        >
          <FormattedMessage
            defaultMessage="Slet opgave?"
            id="order-instance.label.do-delete-task"
          />
        </DeleteDialog>
        <ConnectedDepartmentDialog
          onCancel={this.handleDepartmentDialogCancel}
          onOk={this.handleDepartmentDialogOk}
          open={this.state.departmentDialogOpen}
        />
        <CopiedDialog
          machineList={machineList}
          onRequestClose={this.handleCopiedDialogClose}
          open={this.state.copiedDialogOpen}
          workType={workType || undefined}
        />
        <RemoveRecordedInC5Dialog
          onCancel={this.handleRemoveRecordedInC5DialogCancel}
          onOk={this.handleRemoveRecordedInC5DialogOk}
          open={this.state.removeRecordedInC5DialogOpen}
        />
        <RecordInC5Dialog
          onCancel={this.handleRecordInC5DialogCancel}
          onOk={this.handleRecordInC5DialogOk}
          open={this.state.recordInC5DialogOpen}
        />
      </>
    );

    return (
      <PageLayout dialogs={dialogs} toolbar={formatMessage(messages.taskTitle)} withPadding>
        <Card>
          <CardHeader title={formatMessage(messages.taskCardTitle)} />
          <CardContent>
            <Grid>
              <Cell palm="12/12">
                <FormattedMessage
                  defaultMessage="Arbejdsområde"
                  id="internal-task.header.work-type"
                  tagName="h4"
                />
                <Button
                  color="secondary"
                  disabled={!canEdit}
                  onClick={this.handleWorkTypeSelectButton}
                  variant="contained"
                >
                  {formatMessage(messages.selectWorkTypeButton)}
                </Button>
                {workTypeBlock}
              </Cell>
              {departmentBlock}
              <Cell palm="12/12">
                {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
                  <FormattedMessage
                    defaultMessage="Maskinfører"
                    id="order-instance.header.machine-operator"
                    tagName="h4"
                  />
                ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
                  <FormattedMessage
                    defaultMessage="Medarbejder"
                    id="order-instance.header.employee"
                    tagName="h4"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Chauffør"
                    id="order-instance.header.chauffeur"
                    tagName="h4"
                  />
                )}

                <Button
                  color="secondary"
                  disabled={!canEdit || (!userIsManager && !userIsSeniorMachineOperator)}
                  onClick={this.handleMachineOperatorSelectButton}
                  variant="contained"
                >
                  {formatMessage(messages.selectMachineOperatorButton)}
                </Button>
                {machineOperator}
              </Cell>
              <Cell palm="12/12">
                {customerSettings.machineLabelVariant === "MACHINE" ? (
                  <FormattedMessage
                    defaultMessage="Maskine"
                    id="order-instance.header.machine"
                    tagName="h4"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Køretøj"
                    id="order-instance.header.vehicle"
                    tagName="h4"
                  />
                )}
                <Button
                  color="secondary"
                  disabled={
                    !allowMachineUse ||
                    (workType?.allowMaxOneMachine && machineList.length > 0) ||
                    !canEdit
                  }
                  onClick={this.handleMachineSelectButton}
                  variant="contained"
                >
                  {formatMessage(messages.selectMachineButton)}
                </Button>
                {machineBlock}
              </Cell>
            </Grid>
            <Grid>
              <Cell palm="12/12">
                {!canEdit || (!userIsManager && !userIsSeniorMachineOperator) ? (
                  <h4>Dato: {formatDate(new Date(task.date || new Date()))}</h4>
                ) : (
                  <DateField
                    autoOk
                    disabled={validated}
                    fullWidth
                    label={formatMessage(messages.date)}
                    margin="dense"
                    onChange={this.handleDateUpdate}
                    value={task.date}
                  />
                )}
              </Cell>
              {timeBlock}
            </Grid>
            {customerSettings.enableTaskEstimation &&
            canEdit &&
            (userIsManager || userIsSeniorMachineOperator) ? (
              <Grid>
                <Cell palm="12/12">
                  <MinutesField
                    disabled={validated}
                    fullWidth
                    label={formatMessage(messages.expectedTotalTaskDuration)}
                    margin="dense"
                    onChange={this.handleMinutesExpectedTotalTaskDurationChange}
                    value={task.minutesExpectedTotalTaskDuration}
                  />
                </Cell>
              </Grid>
            ) : null}
            {!userIsManager &&
            (!userIsSeniorMachineOperator ||
              !customerSettings.seniorMachineOperatorCanEditNotesFromManager) ? null : (
              <Grid>
                <Cell palm="12/12">
                  <TrimTextField
                    disabled={validated}
                    fullWidth
                    label={formatMessage(messages.notesFromAdministration)}
                    margin="dense"
                    maxRows={30}
                    minRows={2}
                    multiline
                    onChange={this.handleNotesFromManagerChange}
                    value={task.notesFromManager}
                    variant="outlined"
                  />
                </Cell>
              </Grid>
            )}
            <Grid>
              <Cell palm="12/12">
                <TrimTextField
                  disabled={!canEdit}
                  fullWidth
                  label={
                    customerSettings.employeeLabelVariant === "MACHINEOPERATOR"
                      ? formatMessage(messages.notesFromMachineOperator)
                      : customerSettings.employeeLabelVariant === "EMPLOYEE"
                        ? formatMessage(messages.notesFromEmployee)
                        : formatMessage(messages.notesFromChauffeur)
                  }
                  margin="dense"
                  maxRows={30}
                  minRows={2}
                  multiline
                  onChange={this.handleNotesFromMachineOperatorChange}
                  value={task.notesFromMachineOperator}
                  variant="outlined"
                />
              </Cell>
            </Grid>
            {!userIsManager ? null : (
              <Grid>
                <Cell palm="12/12" style={{paddingBottom: 12}}>
                  <FormControlLabel
                    control={
                      <Switch checked={task.completed} onChange={this.handleCompletedChanged} />
                    }
                    disabled={validated || !completed}
                    label={formatMessage(messages.completed)}
                    labelPlacement="end"
                  />
                </Cell>
                <Cell palm="12/12" style={{paddingBottom: 12}}>
                  <FormControlLabel
                    control={<Switch checked={validated} onChange={this.handleToggleValidated} />}
                    disabled={!canUnapproveTasks || !!task.recordedInC5 || !validated}
                    label={formatMessage(messages.validated)}
                    labelPlacement="end"
                  />
                </Cell>
                <Cell palm="12/12" style={{paddingBottom: 12}}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!task.recordedInC5}
                        onChange={this.handleToggleRecordedInC5}
                      />
                    }
                    disabled={!userIsConsultant || !task.completed || !validated}
                    label={formatMessage(messages.recorded)}
                    labelPlacement="end"
                  />
                </Cell>
              </Grid>
            )}
          </CardContent>
          <CardActions>
            <Button
              color="secondary"
              disabled={validated}
              onClick={this.handleGoToTask}
              variant="contained"
            >
              <FormattedMessage defaultMessage="Start" id="internal-task.label.start" />
            </Button>
            {userIsManager || userIsSeniorMachineOperator ? createTaskCopyButtonCell : null}
            {userIsManager || userIsSeniorMachineOperator ? (
              <ErrorColorButton
                disabled={!canDelete}
                onClick={this.handleDeleteButton}
                variant="contained"
              >
                {formatMessage(messages.delete)}
              </ErrorColorButton>
            ) : null}
          </CardActions>
        </Card>
      </PageLayout>
    );
  }
}

const ConnectedInternalTask = connect<
  InternalTaskStateProps,
  InternalTaskDispatchProps,
  InternalTaskOwnProps,
  AppState
>(
  createStructuredSelector<AppState, InternalTaskStateProps>({
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerSettings: getCustomerSettings,
    daysAbsenceArray: getDaysAbsenceArray,
    deviceMachine: getDeviceConfigKey("machine"),
    hoursAbsenceArray: getHoursAbsenceArray,
    machineLookup: getMachineLookup,
    taskArray: getTaskArray,
    timerStartArray: getTimerStartArray,
    userLookup: getUserLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  (dispatch) => ({
    dispatch,
    ...bindActionCreators(
      {
        backSkip: actions.backSkip,
        create: actions.create,
        go: actions.go,
        remove: actions.remove,
        update: actions.update,
      },
      dispatch,
    ),
  }),
)(InternalTask);

function LoadInternalTask(): React.JSX.Element {
  const {formatMessage} = useIntl();
  return (
    <DoLoadInstance
      Component={ConnectedInternalTask}
      loadingTitle={formatMessage(messages.taskTitle)}
      lookupSelector={getTaskLookup}
      resourceName="task"
    />
  );
}

export default LoadInternalTask;
