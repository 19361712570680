import {ContactUrl, CustomerUrl, LocationUrl, ProjectUrl, UserUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export enum ProjectAccess {
  Barred = "barred",
  Closed = "closed",
  Hidden = "hidden",
  Open = "open",
}

export const ProjectAccessChoices: readonly ProjectAccess[] = Object.values(ProjectAccess);

export const ProjectAccessSortOrdering: Record<ProjectAccess, number> = {
  barred: 3,
  closed: 2,
  hidden: 1,
  open: 0,
};

export interface Project extends ResourceInstance<ProjectUrl> {
  readonly access: ProjectAccess | null;
  readonly alias: string;
  readonly c5_recid: number;
  readonly contact: ContactUrl | null;
  readonly customer: CustomerUrl;
  readonly distanceInKm: number | null;
  readonly managerInternalNotes: string;
  readonly name: string;
  readonly notes: string;
  readonly projectNumber: string;
  readonly relatedWorkplace: LocationUrl | null;
  readonly remoteUrl: string;
  readonly responsible: UserUrl | null;
  readonly travelTimeInMinutes: number | null;
}

export interface ProjectDefaults extends Omit<Project, "customer" | "id" | "url"> {
  readonly access: ProjectAccess;
}

export const emptyProject: ProjectDefaults = {
  access: ProjectAccess.Hidden,
  alias: "",
  c5_recid: 0,
  contact: null,
  distanceInKm: null,
  managerInternalNotes: "",
  name: "",
  notes: "",
  projectNumber: "",
  relatedWorkplace: null,
  remoteUrl: "",
  responsible: null,
  travelTimeInMinutes: null,
};
