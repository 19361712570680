import {frontendVersion} from "@co-common-libs/frontend-version";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {SerializableError} from "@co-frontend-libs/db-resources";
import {actions, buildErrorObject, getDeviceConfigKey, getError} from "@co-frontend-libs/redux";
import {jsonFetch, useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, DialogContent, TextField} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {TimeoutDialog} from "app-components";
import {
  checkDoneSignal,
  checkErrorEvent,
  clearApp,
  closeDatabases,
  currentNewestSignal,
  performUpdateCheck,
  updateReadySignal,
  useEventTargetValueCallback,
} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

export function Login(): React.JSX.Element {
  const [clearAppDialogOpen, setClearAppDialogOpen] = useState(false);
  const setClearAppDialogOpenTrue = useCallWithTrue(setClearAppDialogOpen);
  const setClearAppDialogOpenFalse = useCallWithFalse(setClearAppDialogOpen);

  const [clearingApp, setClearingApp] = useState(false);
  const [initials, setInitials] = useState("");
  const [password, setPassword] = useState("");
  const [updateMessageOpen, setUpdateMessageOpen] = useState(false);
  const setUpdateMessageOpenTrue = useCallWithTrue(setUpdateMessageOpen);
  const [updateError, setUpdateError] = useState<SerializableError | null>(null);
  const [checkingForUpdate, setCheckingForUpdate] = useState(false);

  const loginError = useSelector(getError);
  const getRegistrationId = useMemo(() => getDeviceConfigKey("registrationId"), []);
  const registrationId = useSelector(getRegistrationId);

  const error = loginError || updateError;

  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof cordova !== "undefined" && registrationId) {
      const {baseURL} = globalConfig.resources;
      const url = `${baseURL}device_unregistration`;
      jsonFetch(url, "POST", {
        registrationId,
      });
    }
  }, [registrationId]);

  useEffect(() => {
    const unsubscribe = updateReadySignal.subscribe(setUpdateMessageOpenTrue);
    return unsubscribe;
  }, [setUpdateMessageOpenTrue]);

  const handlePasswordChange = useEventTargetValueCallback(setPassword, [setPassword]);

  const handleSubmit = useCallback((): void => {
    if (initials && password) {
      dispatch(
        actions.login({
          loginURL: globalConfig.authentication.loginURL,
          password,
          username: initials,
        }),
      );
    }
  }, [dispatch, initials, password]);

  const handleUpdateCheckError = useCallback((err: Error): void => {
    setUpdateError(buildErrorObject(err));
  }, []);

  const handleTryLogin = useCallback((): void => {
    setCheckingForUpdate(true);
    const handleUpdateCheckDone = (): void => {
      setCheckingForUpdate(false);
      currentNewestSignal.unsubscribe(handleSubmit);
      checkErrorEvent.unsubscribe(handleUpdateCheckError);
      updateReadySignal.unsubscribe(setUpdateMessageOpenTrue);
      checkDoneSignal.unsubscribe(handleUpdateCheckDone);
    };
    currentNewestSignal.subscribe(handleSubmit);
    checkErrorEvent.subscribe(handleUpdateCheckError);
    updateReadySignal.subscribe(setUpdateMessageOpenTrue);
    checkDoneSignal.subscribe(handleUpdateCheckDone);
    performUpdateCheck();
  }, [handleSubmit, handleUpdateCheckError, setUpdateMessageOpenTrue]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<unknown>): void => {
      if (event.key === "Enter") {
        handleTryLogin();
      }
    },
    [handleTryLogin],
  );

  const handleClearAppDialogOk = useCallback((): Promise<void> => {
    setClearingApp(true);
    return clearApp();
  }, []);

  const handleUpdateMessageOk = useCallback((): void => {
    window.setTimeout(() => {
      // eslint-disable-next-line promise/catch-or-return
      closeDatabases().then(() => {
        window.location.reload();
        return;
      });
    }, 200);
  }, []);

  const intl = useIntl();

  let errorElement: React.JSX.Element | null = null;
  if (error) {
    const httpBadRequest = 400;
    let errorMessage: React.JSX.Element;
    if (error.type === "NetworkError") {
      errorMessage = (
        <FormattedMessage defaultMessage="Kunne ikke oprette forbindelse til serveren" />
      );
    } else if (error.type === "StatusError" && error.statusCode === httpBadRequest) {
      errorMessage = <FormattedMessage defaultMessage="Forkert brugernavn eller adgangskode" />;
    } else {
      errorMessage = (
        <FormattedMessage defaultMessage="Der opstod en fejl under login, prøv igen senere" />
      );
    }
    errorElement = <span style={{color: red[900]}}>{errorMessage}</span>;
  }

  return (
    <>
      <div
        style={{
          alignItems: "center",
          backgroundColor: "white",
          display: "flex",
          height: "100vh",
        }}
      >
        <div style={{textAlign: "center", width: "100%"}}>
          <img src={globalConfig.logoURL} style={{width: 256}} />
          <form onSubmit={handleTryLogin}>
            <TrimTextField
              disabled={checkingForUpdate}
              label={intl.formatMessage({defaultMessage: "Brugernavn"})}
              margin="dense"
              onChange={setInitials}
              onKeyDown={handleKeyDown}
              style={{width: 256}}
              type="text"
              value={initials}
              variant="outlined"
            />
            <br />
            <TextField
              disabled={checkingForUpdate}
              label={intl.formatMessage({defaultMessage: "Adgangskode"})}
              margin="dense"
              onChange={handlePasswordChange}
              onKeyDown={handleKeyDown}
              style={{width: 256}}
              type="password"
              value={password}
              variant="outlined"
            />
            <br />
            {errorElement}
            <br />
            <Button
              color="secondary"
              disabled={checkingForUpdate}
              onClick={handleTryLogin}
              variant="contained"
            >
              {intl.formatMessage({defaultMessage: "Log ind"})}
            </Button>
          </form>
          <br />
          <span style={{fontSize: 12, margin: 16, opacity: 0.4}}>v. {frontendVersion}</span>
        </div>
      </div>
      {window.cordova || process.env.NODE_ENV === "development" ? (
        <>
          <Button
            onClick={setClearAppDialogOpenTrue}
            style={{bottom: 0, position: "fixed", right: 0}}
            variant="text"
          >
            <FormattedMessage defaultMessage="Nulstil app" />
          </Button>
          <ResponsiveDialog
            okDisabled={clearingApp}
            onCancel={setClearAppDialogOpenFalse}
            onOk={handleClearAppDialogOk}
            open={clearAppDialogOpen}
            title={intl.formatMessage({defaultMessage: "Nulstil app?"})}
          >
            <DialogContent>
              <FormattedMessage defaultMessage="Slet appens offline data/offline tilstand?" />
            </DialogContent>
          </ResponsiveDialog>
        </>
      ) : null}
      <TimeoutDialog
        onOk={handleUpdateMessageOk}
        open={updateMessageOpen}
        timeoutSeconds={5}
        title={intl.formatMessage({defaultMessage: "Opdatering"})}
      >
        <DialogContent>
          <FormattedMessage defaultMessage="Der er en opdatering; denne skal hentes inden login." />
        </DialogContent>
      </TimeoutDialog>
    </>
  );
}
