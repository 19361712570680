import {getCustomerLookup, getExtendedCustomerSettings} from "@co-frontend-libs/redux";
import {Typography} from "@material-ui/core";
import React from "react";
import {useSelector} from "react-redux";
import {LocationInfo, LocationInfoProps} from "./location-info";

export function LocationInfoWithLocationCustomer(props: LocationInfoProps): React.JSX.Element {
  const {location} = props;

  const customerLookup = useSelector(getCustomerLookup);

  const {
    locations: {canSeeLocationCustomerName},
  } = useSelector(getExtendedCustomerSettings);

  const customerName = location.customer ? customerLookup(location.customer)?.name : null;

  return (
    <>
      {canSeeLocationCustomerName && customerName && (
        <Typography variant="body2">
          <i>{customerName}</i>
        </Typography>
      )}
      <LocationInfo location={location} />
    </>
  );
}
