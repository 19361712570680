import {Config} from "@co-common-libs/config";
import {
  Information,
  InformationUrl,
  PatchUnion,
  UserProfile,
  UserUrl,
} from "@co-common-libs/resources";
import {
  actions,
  AppState,
  getCustomerSettings,
  getInformationLookup,
  getUserUserProfileLookup,
  makePathParameterGetter,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Paper} from "@material-ui/core";
import {PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {InformationForm} from "./information-form";

const messages = defineMessages({
  title: {
    defaultMessage: "Redigér Nyhed",
    id: "information-entry.title.edit-information",
  },
});

interface InformationEntryStateProps {
  customerSettings: Config;
  id: string;
  informationLookup: (url: InformationUrl) => Information | undefined;
  userUserprofileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface InformationEntryDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

type InformationEntryProps = InformationEntryDispatchProps & InformationEntryStateProps;

class InformationEntry extends PureComponent<InformationEntryProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleDeleteClick(): void {
    const informationURL = instanceURL("information", this.props.id);
    this.props.remove(informationURL);
    setTimeout(() => {
      this.props.go("/information");
    });
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const informationURL = instanceURL("information", this.props.id);
    const information = this.props.informationLookup(informationURL);

    return (
      <PageLayout toolbar={formatMessage(messages.title)} withPadding>
        <Paper style={{padding: 10}}>
          {information ? (
            <InformationForm
              customerSettings={this.props.customerSettings}
              information={information}
              onDeleteClick={this.handleDeleteClick}
              update={this.props.update}
              userUserprofileLookup={this.props.userUserprofileLookup}
            />
          ) : null}
        </Paper>
      </PageLayout>
    );
  }
}

const ConnectedInformationEntry = connect<
  InformationEntryStateProps,
  InformationEntryDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, InformationEntryStateProps>({
    customerSettings: getCustomerSettings,
    id: makePathParameterGetter("id"),
    informationLookup: getInformationLookup,
    userUserprofileLookup: getUserUserProfileLookup,
  }),
  {
    go: actions.go,
    remove: actions.remove,
    update: actions.update,
  },
)(InformationEntry);

export {ConnectedInformationEntry as InformationEntry};
