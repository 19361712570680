import {PriceItem} from "@co-common-libs/resources";
import {
  ImportPreviewProduct,
  ImportPreviewProductPriceItems,
} from "@co-common-libs/resources-utils";
import {ImportProductPriceItemsPreviewDialog} from "@co-frontend-libs/components";
import {getUnitLookup} from "@co-frontend-libs/redux";
import {jsonFetch, translateNetworkError} from "@co-frontend-libs/utils";
import {useAsyncAbortable} from "@react-hookz/web";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface ImportProductPriceItemsDialogProps {
  addLabel?: string;
  onAdd?(searchString: string): void;
  onCancel(): void;
  onOk(products: ImportPreviewProduct[], priceItems: PriceItem[]): void;
  open: boolean;
}

export const ImportProductPriceItemsDialog = React.memo(function ImportProductPriceItemsDialog(
  props: ImportProductPriceItemsDialogProps,
): React.JSX.Element {
  const {open} = props;

  const unitLookup = useSelector(getUnitLookup);

  const {baseURL} = globalConfig.resources;
  const [state, actions] = useAsyncAbortable<ImportPreviewProductPriceItems | null>(
    (signal) =>
      jsonFetch(`${baseURL}economic/economic-products-co-priceitems/`).then(({data}) =>
        signal.aborted ? null : data,
      ),
    null,
  );

  useEffect(() => {
    if (open) {
      actions.execute();
    } else {
      actions.reset();
    }
  }, [actions, open]);

  const handleOk = useCallback(
    (identities: ReadonlySet<string>) => {
      props.onOk(
        (state.result?.previewProducts || []).filter((item) => identities.has(item.remoteUrl)),
        (state.result?.priceItems || []).filter((item) => identities.has(item.url)),
      );
    },
    [state.result, props],
  );

  const intl = useIntl();

  return (
    <ImportProductPriceItemsPreviewDialog
      {...props}
      error={state.status === "error" ? translateNetworkError(state.error, intl) : undefined}
      itemArrays={state.result}
      onOk={handleOk}
      unitLookup={unitLookup}
    />
  );
});
