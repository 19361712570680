import {isValidEmail} from "@co-common-libs/utils";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  emailNotValid: {
    defaultMessage: "Email adressen er ikke gyldig",
    id: "mail-report-dialog.email-not-valid",
  },
  message: {
    defaultMessage: "Besked",
    id: "mail-report-dialog.message",
  },
  recipient: {
    defaultMessage: "Modtager email",
    id: "mail-report-dialog.mail",
  },
  send: {
    defaultMessage: "Send",
    id: "mail-report-dialog.send",
  },
  sendPDFToCustomer: {
    defaultMessage: "Send PDF til kunde",
    id: "mail-report-dialog.send-pdf-to-customer",
  },
  subject: {
    defaultMessage: "Emne",
    id: "mail-report-dialog.subject",
  },
});

export const MailReportDialog = function MailReportDialog({
  defaultMessage,
  logName,
  onCancel,
  onOk,
  open,
  recipient,
}: {
  defaultMessage: string;
  logName: string;
  onCancel?: () => void;
  onOk: (subject: string, message: string, recipient: string) => void;
  open: boolean;
  recipient?: string | undefined;
}): React.JSX.Element {
  const {formatMessage} = useIntl();

  const customerSettings = useSelector(getCustomerSettings);

  const [message, setMessage] = useState(defaultMessage);
  const [recipientState, setRecipientState] = useState(recipient || "");
  const [subject, setSubject] = useState(`${customerSettings.companyName}: ${logName}`);

  const handleOk = useCallback(() => {
    if (!recipientState) {
      return;
    }
    onOk(subject, message, recipientState);
  }, [onOk, subject, message, recipientState]);

  const emailValid = recipientState ? isValidEmail(recipientState) : true;
  return (
    <ResponsiveDialog
      okDisabled={!subject || !message || !recipientState || !emailValid}
      okLabel={formatMessage(messages.send)}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={formatMessage(messages.sendPDFToCustomer)}
    >
      <DialogContent>
        <TrimTextField
          error={!emailValid}
          fullWidth
          helperText={!emailValid ? formatMessage(messages.emailNotValid) : undefined}
          label={formatMessage(messages.recipient)}
          margin="dense"
          onChange={setRecipientState}
          value={recipientState}
          variant="outlined"
        />

        <TrimTextField
          fullWidth
          label={formatMessage(messages.subject)}
          margin="dense"
          onChange={setSubject}
          value={subject}
          variant="outlined"
        />

        <TrimTextField
          fullWidth
          label={formatMessage(messages.message)}
          margin="dense"
          minRows={5}
          multiline
          onChange={setMessage}
          value={message}
          variant="outlined"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
};
