import {useLastKnownPosition} from "app-utils";
import CrosshairsGpsIcon from "mdi-react/CrosshairsGpsIcon";
import React, {useCallback} from "react";
import {MapButton} from "./map-button";

interface CurrentLocationButtonProps {
  googleMap: google.maps.Map;
}

export const CurrentLocationButton = React.memo(function CurrentLocationButton(
  props: CurrentLocationButtonProps,
): React.JSX.Element | null {
  const {googleMap} = props;
  const lastKnownPosition = useLastKnownPosition();

  const handleCurrentLocationButtonClick = useCallback(() => {
    if (!lastKnownPosition) {
      return;
    }
    googleMap.setCenter({
      lat: lastKnownPosition.lat,
      lng: lastKnownPosition.lng,
    });
  }, [lastKnownPosition, googleMap]);

  return navigator.geolocation ? (
    <div>
      <MapButton onClick={handleCurrentLocationButtonClick} variant="contained">
        <CrosshairsGpsIcon />
      </MapButton>
    </div>
  ) : null;
});
