import {
  ComputedTime,
  Task,
  Timer,
  WorkshopChecklist,
  WorkshopChecklistItem,
  WorkType,
} from "@co-common-libs/resources";
import {InternalTaskCheckDialog} from "app-components";
import {ErrorAction, PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";

const messages = defineMessages({
  completedContinuationHeader: {
    defaultMessage:
      "Viderefør til ny opgave, og markerer indeværende som fuldført med følgende data:",
    id: "task-instance.header.mark-as-completed-continuation",
  },
  completedHeaderError: {
    defaultMessage: "Opgaven kan ikke afleveres på grund af:",
    id: "task-instance.header.mark-as-completed-error",
  },
  completedHeaderOk: {
    defaultMessage: "Aflevér opgaven med følgende data:",
    id: "task-instance.header.mark-as-completed-ok",
  },
  toAdministrationDialogTitle: {
    defaultMessage: "Aflevér til administrationen?",
  },
});

interface InternalCompletedDialogProps {
  computedEndTime?: string | undefined;
  computedIntervals?: readonly ComputedTime[];
  computedStartTime?: string | undefined;
  continuation: boolean;
  finalEndTime?: string | undefined;
  finalIntervals?: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  finalStartTime?: string | undefined;
  genericPrimaryTimer: Timer;
  onAction?: (action: ErrorAction) => void;
  onCancel: (event: unknown) => void;
  onOk?: (event: unknown, continuation: boolean, registerAccommodationGroup: string | null) => void;
  open: boolean;
  primaryWorkType?: WorkType | undefined;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  workshopChecklistItemArray: readonly WorkshopChecklistItem[];
  workshopChecklists: readonly WorkshopChecklist[];
}

class InternalCompletedDialog extends PureComponent<InternalCompletedDialogProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleCancel(): void {
    this.props.onCancel(null);
  }
  @bind
  handleOk(registerAccommodationAllowanceRemunerationGroup: string | null): void {
    const {continuation, onOk} = this.props;
    if (onOk) {
      onOk(null, continuation, registerAccommodationAllowanceRemunerationGroup);
    }
  }
  render(): React.JSX.Element | null {
    if (!this.props.task) {
      return null;
    }
    const {continuation, onCancel, onOk, ...other} = this.props;
    const {formatMessage} = this.context;
    let completedHeader;
    if (continuation) {
      completedHeader = formatMessage(messages.completedContinuationHeader);
    } else {
      completedHeader = formatMessage(messages.completedHeaderOk);
    }
    const errorHeader = formatMessage(messages.completedHeaderError);
    const title = formatMessage(messages.toAdministrationDialogTitle);
    return (
      <InternalTaskCheckDialog
        errorHeaderText={errorHeader}
        okHeaderText={completedHeader}
        onCancel={this.handleCancel}
        onlyCompletedChecks
        onOk={this.handleOk}
        title={title}
        {...other}
      />
    );
  }
}

export default InternalCompletedDialog;
