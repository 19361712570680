import {formatDuration} from "@co-common-libs/resources-utils";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {computeIntervalSums, mapSum} from "app-utils";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {IntervalWithTimer} from "./types";

export const TotalTaskTimeBlock = React.memo(function TotalTaskTimeBlock({
  intervals,
}: {
  intervals: readonly IntervalWithTimer[];
}): React.JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  const totalTime = useMemo(() => {
    const finalSums = computeIntervalSums(intervals);
    const minutesTotal = mapSum(finalSums);
    return formatDuration(customerSettings.durationFormat, minutesTotal);
  }, [customerSettings, intervals]);

  return (
    <FormattedMessage
      defaultMessage={"Total tid: {totalTime}"}
      tagName="div"
      values={{totalTime}}
    />
  );
});
