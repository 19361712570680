import {Checkbox, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";

interface DisplayAllFieldsProps {
  readonly displayAllFields: boolean;
  readonly onDisplayAllFieldsChange: (displayAll: boolean) => void;
  readonly style: React.CSSProperties;
}

export const DisplayAllFields = React.memo(function DisplayAllFields(
  props: DisplayAllFieldsProps,
): React.JSX.Element {
  const {displayAllFields, onDisplayAllFieldsChange, style} = props;

  const handleClick = useCallback(() => {
    onDisplayAllFieldsChange(!displayAllFields);
  }, [displayAllFields, onDisplayAllFieldsChange]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleClick();
      }
    },
    [handleClick],
  );

  const intl = useIntl();

  return (
    <ListItem button onClick={handleClick} onKeyDown={handleKeyDown} style={style}>
      <ListItemIcon>
        <Checkbox checked={!displayAllFields} disableRipple edge="start" tabIndex={-1} />
      </ListItemIcon>
      <ListItemText
        primary={intl.formatMessage({
          defaultMessage: "Vis kun kundens marker",
        })}
      />
    </ListItem>
  );
});
