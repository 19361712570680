import {Config} from "@co-common-libs/config";
import {Task, Unit, UnitUrl} from "@co-common-libs/resources";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {dateFromDateAndTime, PureComponent} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import React from "react";
import {TaskBlock} from "./task-block";
import {TaskWithRelations} from "./utils";

interface TasksFromOverflowProps {
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  customerSettings: Config;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  normalFromTimestamp: ImmutableDate;
  normalToTimestamp: ImmutableDate;
  now: string;
  onRequestTaskInfo?: ((position: {x: number; y: number}, task: Task) => void) | undefined;
  overflowTasks: readonly {
    readonly overflowMinutes: number;
    readonly task: TaskWithRelations;
  }[];
  singleDateFocus?: boolean | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export class TasksFromOverflow extends PureComponent<TasksFromOverflowProps> {
  render(): React.JSX.Element {
    const {
      calendarFromTimestamp,
      calendarToTimestamp,
      normalFromTimestamp,
      normalToTimestamp,
      onRequestTaskInfo,
      overflowTasks,
      singleDateFocus,
      unitLookup,
    } = this.props;
    const taskBlocks = overflowTasks.map((overflowTask) => {
      const fromTimestamp = normalFromTimestamp;
      let toTimestamp = fromTimestamp.setUTCMinutes(
        fromTimestamp.getUTCMinutes() + overflowTask.overflowMinutes,
      );
      if (toTimestamp > normalToTimestamp) {
        toTimestamp = normalToTimestamp;
      }
      const {taskOverlapWarningAfterMinutes} = this.props.customerSettings;
      const afterFromTimestamp = new Date(fromTimestamp.valueOf());
      afterFromTimestamp.setUTCMinutes(
        afterFromTimestamp.getUTCMinutes() + taskOverlapWarningAfterMinutes,
      );
      const beforeToTimestamp = new Date(toTimestamp.valueOf());
      beforeToTimestamp.setUTCMinutes(
        beforeToTimestamp.getUTCMinutes() - taskOverlapWarningAfterMinutes,
      );
      let displayToTimestamp = toTimestamp;
      if (normalFromTimestamp && normalToTimestamp && fromTimestamp && toTimestamp) {
        if (normalFromTimestamp < fromTimestamp && fromTimestamp < normalToTimestamp) {
          if (toTimestamp > normalToTimestamp) {
            displayToTimestamp = normalToTimestamp;
          }
        }
      }
      console.assert(overflowTask.task.task.date);
      return (
        <TaskBlock
          blockType="planned"
          calendarFromTimestamp={calendarFromTimestamp}
          calendarToTimestamp={calendarToTimestamp}
          customerSettings={this.props.customerSettings}
          fade={
            singleDateFocus &&
            dateFromDateAndTime(overflowTask.task.task.date as string, "00:00") <
              new Date(calendarFromTimestamp.valueOf())
          }
          fromTimestamp={fromTimestamp}
          go={this.props.go}
          key={overflowTask.task.task.url}
          onRequestTaskInfo={onRequestTaskInfo}
          toTimestamp={displayToTimestamp}
          {...overflowTask.task}
          unitLookup={unitLookup}
        />
      );
    });
    return <div>{taskBlocks}</div>;
  }
}
