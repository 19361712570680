/* eslint-disable max-classes-per-file */
import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Order,
  OrderUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  Role,
  Spray,
  SprayLocation,
  SprayLog,
  SprayLogReport,
  Task,
  TaskUrl,
  Unit,
  UnitUrl,
  urlToId,
  UserProfile,
  UserUrl,
} from "@co-common-libs/resources";
import {getUnitCode, getUnitString, priceItemIsVisible} from "@co-common-libs/resources-utils";
import {sortByOrderMember} from "@co-common-libs/utils";
import {ResponsiveDialog, SpinnerDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getOrderLookup,
  getPriceItemLookup,
  getProductLookup,
  getShareToken,
  getSortedActiveContactArrayPerCustomer,
  getSprayArray,
  getSprayLocationArray,
  getSprayLogReportArray,
  getTaskLookup,
  getUnitLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {getLocationButtonString, MailReportDialog, WindDirection} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
// Allowed for existing code.
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {SprayLogTotalsCard} from "./spray-log-totals-card";
import {getCustomerMailAddress, ReportRow} from "./yield-log-card";

const messages = defineMessages({
  addLocation: {
    defaultMessage: "Tilføj mark",
    id: "spray-log-card.label.add-location",
  },
  clear: {
    defaultMessage: "Høj sol",
    id: "spray-log-card.label.clear",
  },
  clouds: {
    defaultMessage: "Skydække",
    id: "spray-log-card.label.clouds",
  },
  e: {
    defaultMessage: "Ø",
    id: "spray-log-card.label.wind-direction.e",
  },
  edit: {
    defaultMessage: "Ret",
    id: "spray-log-card.label.edit",
  },
  ha: {
    defaultMessage: "ha",
    id: "spray-log-card.label.ha",
  },
  mailError: {
    defaultMessage: "Der skete en fejl under afsendelsen af emailen",
    id: "spray-log-card.label.mailerror",
  },
  mailMessage: {
    defaultMessage: `Denne mail er sendt til dig fra {companyName} via software leveret af CustomOffice ApS og kan derfor ikke besvares.
Ønsker du at svare på denne mail eller ønsker du ikke at modtage denne type mails fremover, skal du henvende dig til {companyName}.`,
    id: "spray-log-card.label.message",
  },
  n: {
    defaultMessage: "N",
    id: "spray-log-card.label.wind-direction.n",
  },
  ne: {
    defaultMessage: "NØ",
    id: "spray-log-card.label.wind-direction.ne",
  },
  nw: {
    defaultMessage: "NV",
    id: "spray-log-card.label.wind-direction.nw",
  },
  overcast: {
    defaultMessage: "Overskyet",
    id: "spray-log-card.label.overcast",
  },
  reports: {
    defaultMessage: "Udskrifter, sprøjtelog",
    id: "spray-log-card.label.reports",
  },
  s: {
    defaultMessage: "S",
    id: "spray-log-card.label.wind-direction.s",
  },
  se: {
    defaultMessage: "SØ",
    id: "spray-log-card.label.wind-direction.se",
  },
  sendingMail: {
    defaultMessage: "Sender mail",
    id: "spray-log-card.label.sending-mail",
  },
  sprayLog: {
    defaultMessage: "Sprøjtelog",
    id: "spray-log-card.label.spray-log",
  },
  sw: {
    defaultMessage: "SV",
    id: "spray-log-card.label.wind-direction.sw",
  },
  w: {
    defaultMessage: "V",
    id: "spray-log-card.label.wind-direction.w",
  },
});

const AREA_COLUMN_STYLE = {width: 86} as const;
const AMOUNT_COLUMN_STYLE = {width: 100} as const;
const DOSE_COLUMN_STYLE = {width: 120} as const;

interface ReportsCardStateProps {
  contactArrayPerCustomer: ReadonlyMap<string, readonly Contact[]>;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  orderLookup: (url: OrderUrl) => Order | undefined;
  taskLookup: (url: TaskUrl) => Task | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface ReportsCardOwnProps {
  currentUserURL: string | null;
  onRequestBuildReports: () => void;
  shareToken: string | null;
  sprayLog: SprayLog;
  sprayLogReportArray: readonly SprayLogReport[];
  workTypeString: string;
}

type ReportsCardProps = ReportsCardOwnProps & ReportsCardStateProps;

interface ReportsCardState {
  mailError: boolean;
  mailReportDialogOpenFor: string | null;
  sendingMail: boolean;
}

class ReportsCard extends PureComponent<ReportsCardProps, ReportsCardState> {
  static contextType = IntlContext;
  _sending: boolean = false;
  context!: React.ContextType<typeof IntlContext>;
  state = {
    mailError: false,
    mailReportDialogOpenFor: null,
    sendingMail: false,
  };
  @bind
  handleMailClick(url: string): void {
    this.setState({mailReportDialogOpenFor: url});
  }
  @bind
  handleMailErrorDialogOk(): void {
    this.setState({mailError: false});
  }

  @bind
  handleMailReportDialogCancel(): void {
    this.setState({mailReportDialogOpenFor: null});
  }

  @bind
  handleMailReportDialogOk(subject: string, message: string, recipient: string): void {
    const {mailReportDialogOpenFor} = this.state;
    if (this._sending || !mailReportDialogOpenFor) {
      return;
    }
    this._sending = true;

    const {baseURL} = globalConfig;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const reportId = urlToId(mailReportDialogOpenFor!);
    const url = `${baseURL}/api/mail/spraylog_report/${reportId}`;
    this.setState({mailReportDialogOpenFor: null, sendingMail: true});
    jsonFetch(url, "POST", {
      message,
      recipient,
      subject,
    })
      .then(() => {
        this._sending = false;
        this.setState({
          sendingMail: false,
        });
        return;
      })
      .catch(() => {
        this._sending = false;
        this.setState({
          mailError: true,
          sendingMail: false,
        });
      });
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      contactArrayPerCustomer,
      customerLookup,
      customerSettings,
      orderLookup,
      sprayLog,
      sprayLogReportArray,
      taskLookup,
      userUserProfileLookup,
    } = this.props;
    const sprayLogURL = sprayLog.url;
    const recipient = getCustomerMailAddress(sprayLog.task, {
      contactArrayPerCustomer,
      customerLookup,
      orderLookup,
      taskLookup,
    });
    const tokenPart = `?token=${this.props.shareToken}`;
    const buttonWidth = 48;
    const buttonSidePadding = 24;
    const buttonColumnWidth = buttonSidePadding + buttonWidth + buttonSidePadding;
    const addButtonStyle: React.CSSProperties = {
      position: "absolute",
      right: 23,
      top: 23,
    };
    const tableRows = _.sortBy(
      sprayLogReportArray.filter((r) => r.spraylog === sprayLogURL),
      (r) => r.serverTimestamp,
    )
      .reverse()
      .map((report) => {
        const pdfDownloadURL = report.pdfDownload;
        const createdByURL = report.createdBy;
        const createdByProfile = userUserProfileLookup(createdByURL);
        return (
          <ReportRow
            createdBy={createdByProfile?.alias}
            deviceTimestamp={report.deviceTimestamp}
            key={report.url}
            onMailIconClick={this.handleMailClick}
            pdfDownloadURL={pdfDownloadURL}
            tokenPart={tokenPart}
            url={report.url}
          />
        );
      });
    return (
      <>
        <Card style={{margin: "1em"}}>
          <div style={{position: "relative"}}>
            <Fab onClick={this.props.onRequestBuildReports} size="small" style={addButtonStyle}>
              <PlusIcon />
            </Fab>
          </div>
          <CardHeader title={formatMessage(messages.reports)} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Udskrevet af"
                    id="transportlog-report.table-header.printed-by"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Tidspunkt"
                    id="transportlog-report.table-header.printed-on"
                  />
                </TableCell>
                <TableCell style={{width: buttonColumnWidth}}>
                  <FormattedMessage
                    defaultMessage="Download"
                    id="transportlog-report.table-header.download"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </Card>
        <MailReportDialog
          defaultMessage={formatMessage(messages.mailMessage, {
            companyName: customerSettings.companyName,
          })}
          logName={formatMessage(messages.sprayLog)}
          onCancel={this.handleMailReportDialogCancel}
          onOk={this.handleMailReportDialogOk}
          open={!!this.state.mailReportDialogOpenFor}
          recipient={recipient}
        />
        <SpinnerDialog open={this.state.sendingMail} title={formatMessage(messages.sendingMail)} />
        <ResponsiveDialog
          onOk={this.handleMailErrorDialogOk}
          open={this.state.mailError === true}
          title={formatMessage(messages.mailError)}
        />
      </>
    );
  }
}

const ConnectedReportsCard: React.ComponentType<ReportsCardOwnProps> = connect<
  ReportsCardStateProps,
  object,
  ReportsCardOwnProps,
  AppState
>(
  createStructuredSelector<AppState, ReportsCardStateProps>({
    contactArrayPerCustomer: getSortedActiveContactArrayPerCustomer,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    orderLookup: getOrderLookup,
    taskLookup: getTaskLookup,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {},
)(ReportsCard);

export {ConnectedReportsCard as ReportsCard};

interface WeatherInfoProps {
  nozzleType?: string;
  sun?: "" | "clear" | "clouds" | "overcast";
  temperature?: number | undefined;
  windDirection?: WindDirection;
  windSpeed?: number | undefined;
}

class WeatherInfo extends PureComponent<WeatherInfoProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): React.JSX.Element {
    const {nozzleType, temperature, windSpeed} = this.props;
    return (
      <div>
        <div>
          <FormattedMessage
            defaultMessage="Vindretning: {windDirection}"
            id="spray-log-card.label.wind-direction."
            values={{windDirection: this.windDirectionToFormattedString()}}
          />
        </div>
        <div>
          <FormattedMessage
            defaultMessage="Vindhastighed: {windSpeed} m/s"
            id="spray-log-card.label.wind-speed."
            values={{windSpeed}}
          />
        </div>
        <div>
          <FormattedMessage
            defaultMessage="Temperatur: {temperature} °C"
            id="spray-log-card.label.temperature."
            values={{temperature}}
          />
        </div>
        <div>
          <FormattedMessage
            defaultMessage="Sol: {sun}"
            id="spray-log-card.label.sun."
            values={{sun: this.sunToFormattedString()}}
          />
        </div>
        <div>
          <FormattedMessage
            defaultMessage="Dysetype: {nozzleType}"
            id="spray-log-card.label.nozzle-type."
            values={{nozzleType}}
          />
        </div>
      </div>
    );
  }

  sunToFormattedString(): string {
    const {formatMessage} = this.context;
    if (this.props.sun) {
      return formatMessage(messages[this.props.sun]);
    } else {
      return "";
    }
  }

  windDirectionToFormattedString(): string {
    const {formatMessage} = this.context;
    if (this.props.windDirection) {
      return formatMessage(messages[this.props.windDirection]);
    } else {
      return "";
    }
  }
}

interface ItemRowProps {
  area: number;
  count: number;
  customerSettings: Config;
  name: string;
  unit: string;
}

class ItemRow extends PureComponent<ItemRowProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): React.JSX.Element {
    const {formatNumber} = this.context;
    const {area, count, name, unit} = this.props;

    return (
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell style={AREA_COLUMN_STYLE}>
          <FormattedMessage
            defaultMessage="{area} ha"
            id="spray-log-card.label.area-ha"
            values={{area}}
          />
        </TableCell>
        <TableCell style={DOSE_COLUMN_STYLE}>
          <FormattedMessage
            defaultMessage="{dose} {unit}/ha"
            id="spray-log-card.label.dose-ha"
            values={{
              dose:
                count && area
                  ? formatNumber(count / area, {
                      maximumFractionDigits: this.props.customerSettings.conversionFieldDecimals,
                    })
                  : 0,
              unit,
            }}
          />
        </TableCell>
        <TableCell style={AMOUNT_COLUMN_STYLE}>
          {count} {unit}
        </TableCell>
      </TableRow>
    );
  }
}

interface FieldRowProps {
  area?: number | undefined;
  crop?: string;
  field?: string;
}

class FieldRow extends PureComponent<FieldRowProps> {
  render(): React.JSX.Element {
    const {area, crop, field} = this.props;
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <strong>
            <FormattedMessage
              defaultMessage="{field}, {area} ha: {crop}"
              id="spray-log-card.label.fieldrow"
              values={{area, crop, field}}
            />
          </strong>
        </TableCell>
      </TableRow>
    );
  }
}

interface SprayEntryProps {
  area?: number | undefined;
  crop?: string;
  disabled: boolean;
  index: number;
  label: string;
  onClick: (index: number) => void;
}

class SprayEntry extends PureComponent<SprayEntryProps> {
  @bind
  handleClick(): void {
    this.props.onClick(this.props.index);
  }

  render(): React.JSX.Element {
    const {area, crop, disabled, label} = this.props;
    return (
      <div>
        <Button
          color="secondary"
          disabled={disabled}
          onClick={this.handleClick}
          style={{width: "100%"}}
          variant="contained"
        >
          {label}
        </Button>
        <FormattedMessage
          defaultMessage="{area} ha - {crop}"
          id="spray-log-card.label.spray-entry"
          values={{area, crop}}
        />
      </div>
    );
  }
}

interface SprayLogCardStateProps {
  currentRole: Role | null;
  currentUserURL: string | null;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  shareToken: string | null;
  sprayArray: readonly Spray[];
  sprayLocationArray: readonly SprayLocation[];
  sprayLogReportArray: readonly SprayLogReport[];
  taskLookup: (url: TaskUrl) => Task | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface SprayLogCardOwnProps {
  completed: boolean;
  disabled: boolean;
  onRequestBuildReports: () => void;
  onRequestLocationDialog: (sprayLocation: SprayLocation | null) => void;
  onRequestSprayDialog: (sprayLocation: SprayLocation) => void;
  onRequestWeatherDialog: () => void;
  sprayLog: SprayLog;
  validated: boolean;
  workTypeString: string;
}

type SprayLogCardProps = SprayLogCardOwnProps & SprayLogCardStateProps;

class SprayLogCard extends PureComponent<SprayLogCardProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  generateEntryList(
    sprayArray: readonly Spray[],
    sprayLocations: readonly SprayLocation[],
  ): {
    blocks: React.JSX.Element[];
    entryData: Map<
      string,
      {
        area: number;
        crop: string;
        fieldArea: number | null;
        items: Map<
          string,
          {
            count: number;
            unit: string;
          }
        >;
      }
    >;
  } {
    const entryData = new Map<
      string,
      {
        area: number;
        crop: string;
        fieldArea: number | null;
        items: Map<
          string,
          {
            count: number;
            unit: string;
          }
        >;
      }
    >();
    const task = this.props.taskLookup(this.props.sprayLog.task);
    const taskPriceItemUseList = task
      ? sortByOrderMember(Object.values(task.priceItemUses || {}))
      : [];
    const blocks = sprayLocations.map((sprayLocation, index) => {
      const location =
        sprayLocation.relatedLocation && this.props.locationLookup(sprayLocation.relatedLocation);
      const address = getLocationButtonString(
        this.props.customerSettings.logButtonText,
        location || undefined,
      );
      const sprayLocationURL = sprayLocation.url;
      const {area} = sprayLocation;
      const {crop} = sprayLocation;
      const locationTotals = {
        area: 0,
        crop,
        fieldArea: area,
        items: new Map<string, {count: number; unit: string}>(),
      };

      let count = 0;

      sprayArray.forEach((spray) => {
        if (spray.location !== sprayLocationURL) {
          return;
        }
        count += 1;
        spray.priceitemuseSet.forEach((priceItemUse) => {
          const priceItem = this.props.priceItemLookup(priceItemUse.priceItem);
          if (
            !priceItem ||
            !priceItemIsVisible(
              priceItem,
              false,
              taskPriceItemUseList,
              this.props.unitLookup,
              this.props.priceItemLookup,
            )
          ) {
            return;
          }
          const priceItemUnit = getUnitCode(priceItem, this.props.unitLookup).toLowerCase();
          if (priceItemUnit === "ha" || priceItemUnit === "ha.") {
            locationTotals.area = priceItemUse.count || 0;
          } else {
            const {name} = priceItem;
            const existingTotalsEntry = locationTotals.items.get(name);
            if (existingTotalsEntry) {
              existingTotalsEntry.count += priceItemUse.count || 0;
            } else {
              locationTotals.items.set(name, {
                count: priceItemUse.count || 0,
                unit: getUnitString(priceItem, this.props.unitLookup),
              });
            }
          }
        });
        spray.productuseSet.forEach((productUse) => {
          const product = this.props.productLookup(productUse.product);
          if (!product) {
            return;
          }
          const {name} = product;
          const x = locationTotals.items.get(name);
          if (x) {
            x.count += productUse.count || 0;
          } else {
            locationTotals.items.set(name, {
              count: productUse.count || 0,
              unit: getUnitString(product, this.props.unitLookup),
            });
          }
        });
      });

      entryData.set(address, locationTotals);

      let label = address;
      const includeCount = this.props.customerSettings.logWorkplaceDisplayCount;
      if (includeCount && count) {
        label = `${address} (${count})`;
      }
      return (
        <SprayEntry
          area={area != null ? area : undefined}
          crop={crop}
          disabled={this.props.disabled}
          index={index}
          key={index}
          label={label}
          onClick={this.handleDoSpray}
        />
      );
    });
    return {
      blocks,
      entryData,
    };
  }

  @bind
  handleAddLocation(): void {
    this.props.onRequestLocationDialog(null);
  }

  @bind
  handleDoSpray(index: number): void {
    const {sprayLocationArray, sprayLog} = this.props;
    const sprayLogURL = sprayLog.url;
    const sprayLocations = _.sortBy(
      sprayLocationArray.filter((p) => p.spraylog === sprayLogURL),
      (p) => p.order,
    );
    const sprayLocation = sprayLocations[index];
    this.props.onRequestSprayDialog(sprayLocation);
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      completed,
      disabled,
      sprayArray,
      sprayLocationArray,
      sprayLog,
      sprayLogReportArray,
      workTypeString,
    } = this.props;
    const {currentUserURL} = this.props;
    const role = this.props.currentRole;
    const isManager = role && role.manager;

    const sprayLogURL = sprayLog.url;
    const sprayLocations = _.sortBy(
      sprayLocationArray.filter((p) => p.spraylog === sprayLogURL),
      (p) => p.order,
    );

    const sprayEntryData = this.generateEntryList(sprayArray, sprayLocations);
    const sprayLocationBlocks = sprayEntryData.blocks;
    const {entryData} = sprayEntryData;
    const totalData = new Map<string, {area: number; count: number; unit: string}>();
    const entries: React.JSX.Element[] = [];

    const entryDataKeys = Array.from(entryData.keys());
    entryDataKeys.sort();

    entryDataKeys.forEach((field) => {
      const {area, crop, fieldArea, items} = entryData.get(field) as {
        area: number;
        crop: string;
        fieldArea: number | null;
        items: Map<
          string,
          {
            count: number;
            unit: string;
          }
        >;
      };
      entries.push(
        <FieldRow
          area={fieldArea != null ? fieldArea : undefined}
          crop={crop}
          field={field}
          key={field}
        />,
      );
      const itemKeys = Array.from(items.keys());
      itemKeys.sort();
      itemKeys.forEach((key, index) => {
        const item = items.get(key) as {
          count: number;
          unit: string;
        };
        const {count, unit} = item;

        const existingTotalDataEntry = totalData.get(key);
        if (existingTotalDataEntry) {
          existingTotalDataEntry.area += area;
          existingTotalDataEntry.count += count;
        } else {
          totalData.set(key, {area, count, unit});
        }
        entries.push(
          <ItemRow
            area={area}
            count={count}
            customerSettings={this.props.customerSettings}
            key={field + index}
            name={key}
            unit={unit}
          />,
        );
      });
    });

    return (
      <div>
        <Card style={{margin: "1em"}}>
          <CardContent>
            <Grid>
              <Cell palm="12/12">
                <FormattedMessage
                  defaultMessage="Marker"
                  id="spray-log-card.header.fields"
                  tagName="h3"
                />
                {sprayLocationBlocks}
                <Button
                  color="primary"
                  disabled={disabled}
                  onClick={this.handleAddLocation}
                  variant="contained"
                >
                  {formatMessage(messages.addLocation)}
                </Button>
              </Cell>
            </Grid>
            <WeatherInfo
              nozzleType={sprayLog.nozzleType}
              sun={sprayLog.sun}
              temperature={sprayLog.temperature != null ? sprayLog.temperature : undefined}
              windDirection={sprayLog.windDirection}
              windSpeed={sprayLog.windSpeed != null ? sprayLog.windSpeed : undefined}
            />
            <Button
              color="primary"
              disabled={disabled}
              onClick={this.props.onRequestWeatherDialog}
              variant="contained"
            >
              {formatMessage(messages.edit)}
            </Button>
          </CardContent>
        </Card>
        <SprayLogTotalsCard totals={totalData} />
        <Card style={{margin: "1em"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Middel"
                    id="spray-log-card.spray.table-header.stuff"
                  />
                </TableCell>
                <TableCell style={AREA_COLUMN_STYLE}>
                  <FormattedMessage
                    defaultMessage="Areal"
                    id="spray-log-card.spray.table-header.area"
                  />
                </TableCell>
                <TableCell style={DOSE_COLUMN_STYLE}>
                  <FormattedMessage
                    defaultMessage="Dosering"
                    id="spray-log-card.spray.table-header.dose"
                  />
                </TableCell>
                <TableCell style={AMOUNT_COLUMN_STYLE}>
                  <FormattedMessage
                    defaultMessage="Mængde"
                    id="spray-log-card.spray.table-header.amount"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{entries}</TableBody>
          </Table>
        </Card>
        {completed && isManager ? (
          <ConnectedReportsCard
            currentUserURL={currentUserURL}
            onRequestBuildReports={this.props.onRequestBuildReports}
            shareToken={this.props.shareToken}
            sprayLog={sprayLog}
            sprayLogReportArray={sprayLogReportArray}
            workTypeString={workTypeString}
          />
        ) : null}
      </div>
    );
  }
}

const ConnectedSprayLogCard: React.ComponentType<SprayLogCardOwnProps> = connect<
  SprayLogCardStateProps,
  object,
  SprayLogCardOwnProps,
  AppState
>(
  createStructuredSelector<AppState, SprayLogCardStateProps>({
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    priceItemLookup: getPriceItemLookup,
    productLookup: getProductLookup,
    shareToken: getShareToken,
    sprayArray: getSprayArray,
    sprayLocationArray: getSprayLocationArray,
    sprayLogReportArray: getSprayLogReportArray,
    taskLookup: getTaskLookup,
    unitLookup: getUnitLookup,
  }),
  {},
)(SprayLogCard);

export {ConnectedSprayLogCard as SprayLogCard};
