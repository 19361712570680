import {Order} from "@co-common-libs/resources";
import {actions} from "@co-frontend-libs/redux";
import {Button} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch} from "react-redux";

const CREATE_BD_ORDER_TEXT = "Opretter...";

interface AddOrderBDReferenceProps {
  order: Order | undefined;
}

export function AddOrderBDReference(props: AddOrderBDReferenceProps): React.JSX.Element {
  const {order} = props;
  const [clicked, setClicked] = useState(false);

  const dispatch = useDispatch();

  const disabled = !order || !!order.remoteUrl || !order.customer || clicked;

  const handleClick = useCallback(() => {
    if (order && !disabled) {
      setClicked(true);
      dispatch(
        actions.update(order.url, [
          {member: "remoteUrl", value: CREATE_BD_ORDER_TEXT},
          {member: "referenceNumber", value: ""},
        ]),
      );
    }
  }, [disabled, dispatch, order]);

  return (
    <Button color="primary" disabled={disabled} onClick={handleClick} variant="contained">
      <FormattedMessage defaultMessage="Opret i BrugerData" id="add-order-bd-reference" />
    </Button>
  );
}
