import {Card, CardContent, Checkbox, FormControlLabel, MenuItem, Select} from "@material-ui/core";
import {SimpleDataTable} from "app-components";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";

//To add new delimiter for import, expand supportedImportDelimiters and fix resulting type error in delimiterNames.
export const supportedImportDelimiters = [",", ";"] as const;
export type ImportDelimiter = (typeof supportedImportDelimiters)[number];

export function ImportPreviewCard(props: {
  checked: boolean;
  chosenDelimiter: ImportDelimiter;
  headers: readonly string[];
  onDelimiterChange: (delimiter: ImportDelimiter) => void;
  onHeaderRowChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  rows: readonly (readonly string[])[];
  workingDelimiters: ReadonlySet<ImportDelimiter>;
}): React.JSX.Element {
  const {
    checked,
    chosenDelimiter,
    headers,
    onDelimiterChange,
    onHeaderRowChange,
    rows,
    workingDelimiters,
  } = props;

  const intl = useIntl();

  const delimiterNames = {
    ",": intl.formatMessage({defaultMessage: "Komma"}),
    ";": intl.formatMessage({defaultMessage: "Semikolon"}),
  };

  const delimiterMenuItems: React.JSX.Element[] = Array.from(workingDelimiters).map((delimiter) => (
    <MenuItem key={delimiter} style={{justifyContent: "center"}} value={delimiter}>
      {delimiterNames[delimiter]}
    </MenuItem>
  ));

  const handleDelimiterChange = useCallback(
    (
      event: React.ChangeEvent<{
        value: unknown;
      }>,
    ) => {
      const value = event.target.value as ImportDelimiter;

      if (value !== chosenDelimiter && workingDelimiters.has(value)) {
        onDelimiterChange(value);
      }
    },
    [chosenDelimiter, onDelimiterChange, workingDelimiters],
  );

  return (
    <Card style={{minWidth: "auto", overflow: "auto", width: "100%"}}>
      <CardContent>
        <FormControlLabel
          control={<Checkbox checked={checked} color="primary" onChange={onHeaderRowChange} />}
          label={intl.formatMessage({
            defaultMessage: "Første linje i CSV-fil indeholder overskrifter.",
          })}
        />
        <FormControlLabel
          control={
            <Select
              color="primary"
              onChange={handleDelimiterChange}
              style={{marginLeft: "1em", minWidth: 150}}
              value={chosenDelimiter}
            >
              {delimiterMenuItems}
            </Select>
          }
          label={intl.formatMessage({
            defaultMessage: "Separator:",
          })}
          labelPlacement="start"
        />
      </CardContent>
      <SimpleDataTable headers={headers} rows={rows} />
    </Card>
  );
}
