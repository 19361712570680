import {getFrontendSentry} from "@co-frontend-libs/utils";
import {current, Draft} from "@reduxjs/toolkit";
import _ from "lodash";
import {saveLocally} from "../actions";
import {ResourcesState} from "../types";

export function handleSaveLocallyPending(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof saveLocally.pending>,
): void {
  const {command, id} = action.meta.arg;
  const {requestId} = action.meta;
  const index = state.commitQueue.findIndex((entry) => entry.id === id);
  console.assert(index !== -1);
  if (index !== -1) {
    const entry = state.commitQueue[index];
    console.assert(_.isEqual(command, current(entry.command)));
    console.assert(entry.status === "UNSAVED");
    console.assert(entry.requestId === null);
    entry.status = "SAVING_LOCALLY";
    entry.requestId = requestId;
  }
}

export function handleSaveLocallyFulfilled(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof saveLocally.fulfilled>,
): void {
  const {command, id} = action.meta.arg;
  const {requestId} = action.meta;
  const index = state.commitQueue.findIndex((entry) => entry.id === id);
  console.assert(index !== -1);
  if (index !== -1) {
    const entry = state.commitQueue[index];
    if (entry.requestId === requestId) {
      console.assert(_.isEqual(command, current(entry.command)));
      console.assert(entry.status === "SAVING_LOCALLY");
      entry.status = "SAVED_LOCALLY";
      entry.requestId = null;
    }
  }
}

export function handleSaveLocallyRejected(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof saveLocally.rejected>,
): void {
  const sentry = getFrontendSentry();
  sentry.withScope((scope) => {
    scope.setTag("action", "handleSaveLocallyRejected");
    sentry.captureMessage("Local DB Error", "error");
  });
  state.localDBError = action.payload || (action.error as any);
}
