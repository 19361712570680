import {CsvImportColumnSpecification} from "@co-common-libs/csv-import-specifications";
import {IntlShape} from "react-intl";
import {ValidationData} from "../parse-validate";
import {ImportColumnConfiguration} from "../types";
import {getColumnConfiguration} from "./get-column-configuration";
import {validateStaticValue} from "./static-value-validation";
import {validateImportColumnValues} from "./validate-import-column-values";
import {validateNoValue} from "./validate-no-value";

// eslint-disable is okay because typescript validates.
// eslint-disable-next-line consistent-return
export function validateValues(
  column: CsvImportColumnSpecification,
  rows: readonly (readonly string[])[],
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>,
  intl: IntlShape,
  data: ValidationData,
): string[] {
  const importColumnConfiguration = getColumnConfiguration(importColumnConfigurations, column.name);

  const valuetype = importColumnConfiguration.valueType;
  switch (valuetype) {
    case "none": {
      return validateNoValue(column, intl);
    }
    case "sourceColumn": {
      const columnValues = rows.map((row) => row[importColumnConfiguration.columnIndex]);
      return validateImportColumnValues(column, columnValues, intl, data);
    }
    case "staticValue": {
      return validateStaticValue(column, importColumnConfiguration.value, intl, data);
    }
  }
}
