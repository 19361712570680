import {PositiveIntegerField, ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

type AccountType = "DIRECT_COSTS" | "INDIRECT_COSTS" | "TURNOVER";

interface MachineAnalysisAccountCreateEditDialogProps {
  accountNumber?: string | undefined;
  accountType?: AccountType | undefined;
  onCancel: () => void;
  onDelete: (accountNumber: string) => void;
  onOk: (accountNumber: string, accountType: AccountType) => void;
  open: boolean;
}

export function MachineAnalysisAccountCreateEditDialog(
  props: MachineAnalysisAccountCreateEditDialogProps,
): React.JSX.Element {
  const {
    accountNumber: propsAccountNumber,
    accountType: propsAccountType,
    onCancel,
    onDelete,
    onOk,
    open,
  } = props;
  const [accountNumber, setAccountNumber] = useState(
    propsAccountNumber ? parseInt(propsAccountNumber) : null,
  );
  const [accountType, setAccountType] = useState<"NONE" | AccountType>(propsAccountType || "NONE");

  useEffect(() => {
    if (open) {
      setAccountNumber(propsAccountNumber ? parseInt(propsAccountNumber) : null);
      setAccountType(propsAccountType || "NONE");
    }
  }, [open, propsAccountNumber, propsAccountType]);

  const handleAccountNumberChange = useCallback((value: number | null) => {
    setAccountNumber(value);
  }, []);

  const handleAccountTypeChange = useCallback(
    (_event: React.SyntheticEvent<unknown> | null, value: string) => {
      setAccountType(value as AccountType);
    },
    [],
  );

  const handleOk = useCallback(() => {
    if (accountNumber && accountType !== "NONE") {
      onOk(`${accountNumber}`, accountType);
    }
  }, [accountNumber, accountType, onOk]);

  const handleDelete = useCallback(() => {
    if (propsAccountNumber) {
      onDelete(propsAccountNumber);
    }
  }, [onDelete, propsAccountNumber]);

  const title =
    propsAccountNumber === undefined ? (
      <FormattedMessage defaultMessage="Tilføj konto" />
    ) : (
      <FormattedMessage defaultMessage="Redigér konto" />
    );

  const okDisabled = !accountNumber || accountType === "NONE";

  return (
    <ResponsiveDialog
      okDisabled={okDisabled}
      onCancel={onCancel}
      onDelete={propsAccountNumber ? handleDelete : undefined}
      onOk={handleOk}
      open={open}
      title={title}
    >
      <DialogContent>
        <PositiveIntegerField
          autoFocus
          fullWidth
          label={
            <FormattedMessage
              defaultMessage="Kontonr."
              id="machine-analysis-config.label.account-number"
            />
          }
          margin="dense"
          onChange={handleAccountNumberChange}
          value={accountNumber}
        />
        <div>
          <FormattedMessage
            defaultMessage="Kontoen indeholder:"
            id="machine-analysis-config.label.account-contains"
          />
          <RadioGroup name="accountType" onChange={handleAccountTypeChange} value={accountType}>
            <FormControlLabel
              control={<Radio />}
              label={
                <FormattedMessage
                  defaultMessage="Omsætning"
                  id="machine-analysis-config.label.turnover"
                />
              }
              value="TURNOVER"
            />
            <FormControlLabel
              control={<Radio />}
              label={
                <FormattedMessage
                  defaultMessage="Direkte omkostninger"
                  id="machine-analysis-config.label.direct-costs"
                />
              }
              value="DIRECT_COSTS"
            />
            <FormControlLabel
              control={<Radio />}
              label={
                <FormattedMessage
                  defaultMessage="Indirekte omkostninger"
                  id="machine-analysis-config.label.indirect-costs"
                />
              }
              value="INDIRECT_COSTS"
            />
          </RadioGroup>
        </div>
      </DialogContent>
    </ResponsiveDialog>
  );
}
