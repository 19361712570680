import {createStyles, IconButton, IconButtonProps, makeStyles, Theme} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    compact: {
      padding: theme.spacing(1),
    },
  }),
);

export interface CustomOfficeIconButtonProps extends IconButtonProps {
  children: React.ReactNode;
  compact?: boolean | undefined;
}

/**
 * Simple extension of MUI IconButton with the added option of decreasing
 * padding by providing the `compact` prop.
 */
export const CustomOfficeIconButton = React.memo(function CustomOfficeIconButton(
  props: CustomOfficeIconButtonProps,
): React.JSX.Element {
  const {children, compact, ...other} = props;

  const classes = useStyles();

  return (
    <IconButton className={compact ? classes.compact : ""} {...other}>
      {children}
    </IconButton>
  );
});
