import {Config} from "@co-common-libs/config";
import {
  PriceItemUse,
  ProductUse,
  ReportingInputSpecification,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import _ from "lodash";
import React, {useCallback, useEffect} from "react";
import {ReportingInputField} from "./reporting-input-field";

interface ReportingSubtractionInputFieldProps {
  customerSettings: Config;
  inputSpecification: ReportingInputSpecification;
  minuendIdentifier: string;
  minuendInputSpecification: ReportingInputSpecification;
  minuendValue?: number;
  onChange: (identifier: string, value: number | null) => void;
  onTransferOk?: ((value: number | null, url: string, unit: string) => void) | undefined;
  priceItemUseList?: readonly PriceItemUse[] | undefined;
  productUseList?: readonly ProductUse[] | undefined;
  subtrahendValue?: number;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export const ReportingSubtractionInputField = React.memo(function ReportingSubtractionInputField(
  props: ReportingSubtractionInputFieldProps,
): React.JSX.Element {
  const {
    inputSpecification,
    minuendIdentifier: minuendIdentifierFromProps,
    minuendInputSpecification,
    minuendValue,
    onChange,
    onTransferOk,
    priceItemUseList,
    productUseList,
    subtrahendValue,
    unitLookup,
  } = props;

  const handleChange = useCallback(
    (minuendIdentifier: string, value: unknown): void => {
      if (value == null) {
        return;
      }
      if (subtrahendValue == null) {
        return;
      }
      const minuendFormatSpecification = minuendInputSpecification.format;
      const decimalPlaces = minuendFormatSpecification.decimalPlaces || 0;
      const numberValue = value as number;
      const resultValue = _.round(subtrahendValue + numberValue, decimalPlaces);
      onChange(minuendIdentifier, resultValue);
    },
    [minuendInputSpecification.format, onChange, subtrahendValue],
  );

  let value: number | null = null;
  let disabled;
  if (subtrahendValue == null) {
    disabled = true;
  } else {
    disabled = false;
    if (minuendValue != null) {
      const computedValue = minuendValue - subtrahendValue;
      const decimalPlaces = props.customerSettings.materialDecimals;
      value = _.round(computedValue, decimalPlaces);
    }
  }
  useEffect(
    () => onChange(inputSpecification.identifier, value),
    [onChange, inputSpecification.identifier, value],
  );

  return (
    <ReportingInputField
      disabled={disabled}
      identifier={minuendIdentifierFromProps}
      inputSpecification={inputSpecification}
      onChange={handleChange}
      onTransferOk={onTransferOk}
      priceItemUseList={priceItemUseList}
      productUseList={productUseList}
      unitLookup={unitLookup}
      value={value || undefined}
    />
  );
});
