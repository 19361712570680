import {DaysAbsence, HoursAbsence} from "@co-common-libs/resources";
import {
  dateFromString,
  dateToString,
  DayTypeHoliday,
  getCheckHolidayFunction,
} from "@co-common-libs/utils";
import {CommonRemunerationSettings, HolidayCheckFunction, RemunerationGroup} from "./types";

export function getDayToDaysAbsenceMapping(daysAbsenceList: readonly DaysAbsence[]): {
  [date: string]: DaysAbsence[] | undefined;
} {
  const dayToDaysAbsence: {[date: string]: DaysAbsence[] | undefined} = {};
  daysAbsenceList.forEach((daysAbsence) => {
    const absenceFromDate = daysAbsence.fromDate;
    const absenceToDate = daysAbsence.toDate;
    let date = absenceFromDate;
    const dateDate = dateFromString(date) as Date;
    while (date <= absenceToDate) {
      const {absenceType} = daysAbsence;
      const dateAbsenceArray = dayToDaysAbsence[date];
      if (dateAbsenceArray) {
        if (!dateAbsenceArray.find((a) => a.absenceType === absenceType)) {
          dateAbsenceArray.push(daysAbsence);
        }
      } else {
        dayToDaysAbsence[date] = [daysAbsence];
      }
      dateDate.setDate(dateDate.getDate() + 1);
      date = dateToString(dateDate);
    }
  });
  return dayToDaysAbsence;
}

export function getDayToHoursAbsenceMapping(hoursAbsenceList: readonly HoursAbsence[]): {
  [date: string]: HoursAbsence[] | undefined;
} {
  const dayToHoursAbsence: {[date: string]: HoursAbsence[] | undefined} = {};
  hoursAbsenceList.forEach((hoursAbsence) => {
    const date = dateToString(new Date(hoursAbsence.fromTimestamp));
    const dateAbsenceArray = dayToHoursAbsence[date];
    if (dateAbsenceArray) {
      dateAbsenceArray.push(hoursAbsence);
    } else {
      dayToHoursAbsence[date] = [hoursAbsence];
    }
  });
  return dayToHoursAbsence;
}

export function getAbsenceHolidayCheckFunction(
  daysAbsenceList: readonly DaysAbsence[],
  remunerationGroup: RemunerationGroup,
  commonRemunerationSettings: CommonRemunerationSettings,
): HolidayCheckFunction {
  const {validAbsenceTypes} = commonRemunerationSettings;
  const {extraHalfHolidays, extraHolidays} = remunerationGroup;
  const validDaysAbsenceList = daysAbsenceList.filter((absence) =>
    validAbsenceTypes.includes(absence.absenceType),
  );
  const dayToDaysAbsence = getDayToDaysAbsenceMapping(validDaysAbsenceList);
  const base = getCheckHolidayFunction(extraHolidays, extraHalfHolidays);
  return (dateString: string) => {
    if (dayToDaysAbsence[dateString]) {
      return DayTypeHoliday.VALID_ABSENCE;
    } else {
      return base(remunerationGroup.holidayCalendars, dateString);
    }
  };
}
