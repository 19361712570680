import {
  isOnlineTaskPhoto,
  isOnlineUserPhoto,
  OnlineTaskPhoto,
  OnlineUserPhoto,
  TaskPhoto,
  UserPhoto,
} from "@co-common-libs/resources";
import {OfflineAwareAppBar, SlideUpTransition} from "@co-frontend-libs/components";
import {getShareToken} from "@co-frontend-libs/redux";
import {
  getScaledWidthHeight,
  IMAGE_DISPLAY_HEIGHT,
  IMAGE_DISPLAY_WIDTH,
} from "@co-frontend-libs/utils";
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {bind} from "bind-decorator";
import bowser from "bowser";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import React, {CSSProperties, useCallback, useRef} from "react";
import {defineMessages, IntlContext, useIntl} from "react-intl";
import QuickPinchZoom, {make3dTransformValue, type UpdateAction} from "react-quick-pinch-zoom";
import {useSelector} from "react-redux";
import {Linkify} from "./linkify";

const messages = defineMessages({
  gotoTask: {
    defaultMessage: "Gå til opgave",
    id: "photo-display-dialog.go-to-task",
  },
  title: {
    defaultMessage: "Fotovisning",
    id: "photo-display-dialog.dialog-title.photo-display",
  },
});

export const ImgZoom = ({
  height,
  src,
  style,
  width,
}: {
  height: number | undefined;
  src: string;
  style: CSSProperties;
  width: number | undefined;
}): React.JSX.Element => {
  const imgRef = useRef<HTMLImageElement>(null);
  const onUpdate = useCallback(({scale, x, y}: UpdateAction) => {
    if (imgRef.current) {
      const value = make3dTransformValue({scale, x, y});

      imgRef.current.style.setProperty("transform", value);
    }
  }, []);

  return (
    <QuickPinchZoom onUpdate={onUpdate}>
      <img
        alt=""
        height={height as any}
        ref={imgRef}
        src={src}
        style={style}
        width={width as any}
      />
    </QuickPinchZoom>
  );
};

interface PhotoDisplayDialogContentProps {
  fullscreenLayout: boolean;
  instance: TaskPhoto | UserPhoto;
  onRequestGoToTask?: ((url: string) => void) | undefined;
  shareToken: string | null;
}

interface PhotoDisplayDialogContentState {
  deviceHeight: number;
  deviceWidth: number;
}

function instanceOfTaskPhoto(object: TaskPhoto | UserPhoto): object is TaskPhoto {
  return "task" in object;
}

function isOnlinePhoto(photo: TaskPhoto | UserPhoto): photo is OnlineTaskPhoto | OnlineUserPhoto {
  if (instanceOfTaskPhoto(photo)) {
    return isOnlineTaskPhoto(photo);
  } else {
    return isOnlineUserPhoto(photo);
  }
}

function getPhotoDisplayData(
  photo: TaskPhoto | UserPhoto,
  shareToken: string | null,
): {
  height: number;
  src: string;
  width: number;
} {
  if (isOnlinePhoto(photo)) {
    const tokenPart = `?token=${shareToken}`;
    const width = photo.displayWidth;
    const height = photo.displayHeight;
    const src = photo.displayDownload + tokenPart;
    return {height, src, width};
  } else {
    const data = photo._file_original;
    const {height, width} = getScaledWidthHeight(
      data.width,
      data.height,
      IMAGE_DISPLAY_WIDTH,
      IMAGE_DISPLAY_HEIGHT,
    );
    const src = data.data;
    return {height, src, width};
  }
}

class PhotoDisplayDialogContent extends React.PureComponent<
  PhotoDisplayDialogContentProps,
  PhotoDisplayDialogContentState
> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state = {
    deviceHeight: window.innerHeight,
    deviceWidth: window.innerWidth,
  };
  @bind
  _bindResize(): void {
    window.addEventListener("resize", this._updateDeviceSize);
  }
  @bind
  _unbindResize(): void {
    window.removeEventListener("resize", this._updateDeviceSize);
  }
  @bind
  _updateDeviceSize(): void {
    const width = window.innerWidth;
    if (width !== this.state.deviceWidth) {
      this.setState({deviceWidth: width});
    }
    const height = window.innerHeight;
    if (height !== this.state.deviceHeight) {
      this.setState({deviceHeight: height});
    }
  }
  componentDidMount(): void {
    this._updateDeviceSize();
    this._bindResize();
  }
  componentWillUnmount(): void {
    this._unbindResize();
  }
  @bind
  handleGoToTask(): void {
    if (!this.props.onRequestGoToTask || !instanceOfTaskPhoto(this.props.instance)) {
      return;
    }
    this.props.onRequestGoToTask(this.props.instance.task);
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {fullscreenLayout, instance, shareToken} = this.props;

    const {height, src, width} = getPhotoDisplayData(instance, shareToken);

    const availableWidth = Math.floor(this.state.deviceWidth);
    const availableHeight = Math.floor(this.state.deviceHeight - 64 - 20);
    const widthDivisor = width / availableWidth;
    const heightDivisor = height / availableHeight;
    const divisor = Math.max(widthDivisor, heightDivisor);

    const style: React.CSSProperties = {
      backgroundColor: "lightgrey",
      display: "block",
      height: Math.floor(height / divisor),
      width: Math.floor(width / divisor),
    };

    return (
      <div>
        {this.props.onRequestGoToTask ? (
          <Button
            color="secondary"
            onClick={this.handleGoToTask}
            style={fullscreenLayout ? {margin: 10} : {marginBottom: 10}}
            variant="contained"
          >
            {formatMessage(messages.gotoTask)}
          </Button>
        ) : null}
        <div style={{textAlign: "center"}}>
          <div>
            <ImgZoom height={height} src={src} style={style} width={width} />
          </div>
          <span>
            <Linkify>{instance.note || ""}</Linkify>
          </span>
        </div>
      </div>
    );
  }
}

const closeTitleSpacing = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      flex: 1,
      marginLeft: theme.spacing(closeTitleSpacing),
    },
  }),
);

interface PhotoDisplayDialogProps {
  fullscreen?: boolean;
  instance?: TaskPhoto | UserPhoto | undefined;
  onRequestClose: () => void;
  onRequestGoToTask?: (url: string) => void;
}

export const PhotoDisplayDialog = React.memo(function PhotoDisplayDialog(
  props: PhotoDisplayDialogProps,
): React.JSX.Element {
  const {fullscreen, instance, onRequestClose, onRequestGoToTask} = props;
  const open = !!instance;

  const classes = useStyles();
  const shareToken = useSelector(getShareToken);

  const fullscreenLayout = fullscreen ?? !!(bowser.tablet || bowser.mobile);

  const content = instance ? (
    <PhotoDisplayDialogContent
      fullscreenLayout={fullscreenLayout}
      instance={instance}
      onRequestGoToTask={onRequestGoToTask}
      shareToken={shareToken}
    />
  ) : null;

  const {formatMessage} = useIntl();

  if (fullscreenLayout) {
    return (
      <Dialog
        fullScreen
        onClose={onRequestClose}
        open={open}
        TransitionComponent={SlideUpTransition}
      >
        <OfflineAwareAppBar className={classes.appBar}>
          <Toolbar>
            <IconButton aria-label="close" color="inherit" edge="start" onClick={onRequestClose}>
              <ArrowLeftIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6">
              {formatMessage(messages.title)}
            </Typography>
          </Toolbar>
        </OfflineAwareAppBar>
        {content}
      </Dialog>
    );
  } else {
    return (
      <Dialog maxWidth="lg" onClose={onRequestClose} open={open}>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }
});
