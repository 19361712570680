export type MatchResult<T> = {parameters: {[key: string]: string}; value: T};

export class PathMatcher<T> {
  private leafValue: T | null;
  private literalChildren: Map<string, PathMatcher<T>>;
  private parameterChildren: Map<string, PathMatcher<T>>;
  constructor() {
    this.parameterChildren = new Map();
    this.literalChildren = new Map();
    this.leafValue = null;
  }
  addRoute(pathParts: readonly string[], value: T): void {
    if (pathParts.length === 0) {
      console.assert(this.leafValue === null);
      this.leafValue = value;
    } else {
      const childMatcher = this.getOrAddChild(pathParts[0]);
      childMatcher.addRoute(pathParts.slice(1), value);
    }
  }
  match(pathParts: readonly string[]): MatchResult<T> | null {
    if (pathParts.length === 0) {
      return this.leafMatch();
    } else {
      return this.literalChildMatch(pathParts) || this.parameterChildMatch(pathParts);
    }
  }
  private getOrAddChild(pathPart: string): PathMatcher<T> {
    const key = pathPart[0] === ":" ? pathPart.substring(1) : pathPart;
    const mapping = pathPart[0] === ":" ? this.parameterChildren : this.literalChildren;
    const existing = mapping.get(key);
    if (existing) {
      return existing;
    } else {
      const added = new PathMatcher<T>();
      mapping.set(key, added);
      return added;
    }
  }
  private leafMatch(): MatchResult<T> | null {
    if (this.leafValue != null) {
      return {parameters: {}, value: this.leafValue};
    } else {
      return null;
    }
  }
  private literalChildMatch(pathParts: readonly string[]): MatchResult<T> | null {
    console.assert(pathParts.length > 0);
    const key = pathParts[0];
    const partMatcher = this.literalChildren.get(key);
    if (partMatcher) {
      return partMatcher.match(pathParts.slice(1));
    } else {
      return null;
    }
  }
  private parameterChildMatch(pathParts: readonly string[]): MatchResult<T> | null {
    console.assert(pathParts.length > 0);
    let result: {
      parameters: {[key: string]: string};
      value: T;
    } | null = null;
    this.parameterChildren.forEach((partMatcher, parameterKey) => {
      if (result) {
        return;
      }
      const partMatch = partMatcher.match(pathParts.slice(1));
      if (partMatch) {
        const {parameters, value} = partMatch;
        result = {
          parameters: {[parameterKey]: pathParts[0], ...parameters},
          value,
        };
      }
    });
    return result;
  }
}

export function buildMatcher<T>(routeMapping: {
  [path: string]: T;
}): (path: string) => MatchResult<T> | null {
  const baseMatcher = new PathMatcher<T>();
  const paths = Object.keys(routeMapping);
  for (let i = 0; i < paths.length; i += 1) {
    const path = paths[i];
    const value = routeMapping[path];
    const pathParts = path.split("/");
    baseMatcher.addRoute(pathParts, value);
  }
  return (path: string) => {
    return baseMatcher.match(path.split("/"));
  };
}

export function buildPathSelfMatcher<T extends string>(
  pathTemplates: readonly T[],
): (path: string) => MatchResult<T> | null {
  const baseMatcher = new PathMatcher<T>();
  for (let i = 0; i < pathTemplates.length; i += 1) {
    const pathTemplate = pathTemplates[i];
    const pathParts = pathTemplate.split("/");
    baseMatcher.addRoute(pathParts, pathTemplate);
  }
  return (path: string) => {
    return baseMatcher.match(path.split("/"));
  };
}
