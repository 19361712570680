import {Customer} from "@co-common-libs/resources";

export enum CustomerFormMember {
  "ACTIVE" = "active",
  "ADDRESS" = "address",
  "BILLING_EMAIL" = "billingEmail",
  "CITY" = "city",
  "CUSTOMER_NUMBER" = "c5_account",
  "NAME" = "name",
  "PHONE" = "phone",
  "POSTAL_CODE" = "postalCode",
  "VAT_NUMBER" = "vatNumber",
}

export const customerFormMembers = Object.values(CustomerFormMember);

// adding _exact to prevent duck typing from allowing Customer
// in place of CustomerFormPart
export type CustomerFormPart = Pick<Customer, CustomerFormMember> & {
  readonly _exact: unique symbol;
};
