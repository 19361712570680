import {ImportPreviewMachine} from "@co-common-libs/resources-utils";
import {
  ImportEconomicMachinesPreviewDialog,
  ImportMachinesPreviewDialog,
} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {jsonFetch, translateNetworkError} from "@co-frontend-libs/utils";
import {useAsyncAbortable} from "@react-hookz/web";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface ImportMachinesDialogProps {
  omitted?: ReadonlySet<string>;
  onCancel(): void;
  onOk(machines: ImportPreviewMachine[]): void;
  open: boolean;
}

export const ImportMachinesDialog = React.memo(function ImportMachinesDialog(
  props: ImportMachinesDialogProps,
): React.JSX.Element {
  const {omitted, open} = props;

  const {baseURL} = globalConfig.resources;
  const [state, actions] = useAsyncAbortable<readonly ImportPreviewMachine[] | null>(
    (signal) =>
      jsonFetch(`${baseURL}economic/department-employees/`).then(({data}) => {
        if (signal.aborted) {
          return null;
        } else {
          return omitted
            ? (data as ImportPreviewMachine[]).filter(
                (entry) => !omitted.has(entry.number.toString()),
              )
            : data;
        }
      }),
    null,
  );

  useEffect(() => {
    if (open) {
      actions.execute();
    } else {
      actions.reset();
    }
  }, [actions, open]);

  const handleOk = useCallback(
    (identifiers: ReadonlySet<string>) => {
      props.onOk(
        (state.result || []).filter((machine) => identifiers.has(machine.number.toString())),
      );
    },
    [state.result, props],
  );

  const intl = useIntl();
  const {
    economicEnableMachinesDepartmentSync,
    economicEnableMachinesProjectEmployeesSync,
    economicSync,
  } = useSelector(getCustomerSettings);

  return economicSync ? (
    <ImportEconomicMachinesPreviewDialog
      departmentImport={economicEnableMachinesDepartmentSync}
      employeeImport={economicEnableMachinesProjectEmployeesSync}
      error={state.status === "error" ? translateNetworkError(state.error, intl) : undefined}
      onCancel={props.onCancel}
      onOk={handleOk}
      open={props.open}
      previewMachines={state.result}
    />
  ) : (
    <ImportMachinesPreviewDialog
      error={state.status === "error" ? translateNetworkError(state.error, intl) : undefined}
      onCancel={props.onCancel}
      onOk={handleOk}
      open={props.open}
      previewMachines={state.result}
    />
  );
});
