import {PricePercentFuelSurchargeSpecification} from "@co-common-libs/resources";
import {actions, getCustomerSettings} from "@co-frontend-libs/redux";
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface PricePercentFuelSurchargeInvoiceLineTextProps {
  specification: PricePercentFuelSurchargeSpecification;
}

export const PricePercentFuelSurchargeInvoiceLineText = React.memo(
  function PricePercentFuelSurchargeInvoiceLineText(
    props: PricePercentFuelSurchargeInvoiceLineTextProps,
  ): React.JSX.Element {
    const {specification} = props;

    const {invoiceLineText} = specification;

    const intl = useIntl();

    const dispatch = useDispatch();

    const customerSettings = useSelector(getCustomerSettings);

    const handleInvoiceLineTextChange = useCallback(
      (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (
          value === "product_text" ||
          value === "product_text_and_surcharge_details" ||
          value === "product_percent_period"
        ) {
          dispatch(actions.update(specification.url, [{member: "invoiceLineText", value}]));
        }
      },
      [dispatch, specification.url],
    );

    return (
      <Card>
        <CardHeader
          title={intl.formatMessage({
            defaultMessage: "Fakturalinjetekst",
          })}
        />
        <CardContent>
          <RadioGroup onChange={handleInvoiceLineTextChange} value={invoiceLineText ?? "-"}>
            <FormControlLabel
              control={<Radio />}
              label={
                customerSettings.economicSync
                  ? intl.formatMessage({defaultMessage: "Varenavn, %"})
                  : intl.formatMessage({
                      defaultMessage: "Varenavn/tillægsnavn, %",
                    })
              }
              value="product_text"
            />
            <FormControlLabel
              control={<Radio />}
              label={
                customerSettings.economicSync
                  ? intl.formatMessage({
                      defaultMessage: "Varenavn, %, indeks-periode, indeks-pris",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Varenavn/tillægsnavn, %, indeks-periode, indeks-pris",
                    })
              }
              value="product_text_and_surcharge_details"
            />
            <FormControlLabel
              control={<Radio />}
              label={
                customerSettings.economicSync
                  ? intl.formatMessage({
                      defaultMessage: "Varenavn, %, indeks-periode",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Varenavn/tillæggets navn, %, indeks-periode",
                    })
              }
              value="product_percent_period"
            />
          </RadioGroup>
        </CardContent>
      </Card>
    );
  },
);
