import {
  Contact,
  ContactUrl,
  CustomerUrl,
  resourceNameFor,
  urlToId,
} from "@co-common-libs/resources";
import {
  DebouncedAppbarSearchField,
  VerticalStackingFloatingActionButton,
  WhiteCheckbox,
} from "@co-frontend-libs/components";
import {actions, getContactLookup, getExtendedCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {FormControlLabel} from "@material-ui/core";
import {
  CustomerCreateDialog,
  ExternalCustomerCreateDialog,
  MenuToolbar,
  PageLayout,
} from "app-components";
import {useBooleanQueryParameterState, useQueryParameter} from "app-utils";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {CustomerTable} from "./customer-table";

export const isDefaultContact = (contact: Contact): boolean => contact.defaultContact;

interface CustomerListProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const CustomerList = React.memo(function CustomerList(
  props: CustomerListProps,
): React.JSX.Element {
  const filterString = useQueryParameter("q", "");

  const contactLookup = useSelector(getContactLookup);

  const {
    customers: {canManage, liveSyncWithThirdParty},
  } = useSelector(getExtendedCustomerSettings);

  const dispatch = useDispatch();

  const handleFilterStringChange = useCallback(
    (value: string) => {
      dispatch(actions.putQueryKey("q", value || ""));
    },
    [dispatch],
  );

  const [customerCreateEditDialogOpen, setCustomerCreateEditDialogOpen] = useState(false);
  const setCustomerCreateEditDialogOpenTrue = useCallWithTrue(setCustomerCreateEditDialogOpen);
  const setCustomerCreateEditDialogOpenFalse = useCallWithFalse(setCustomerCreateEditDialogOpen);

  const handleCustomerContactClick = useCallback(
    (customerOrContactURL: ContactUrl | CustomerUrl) => {
      const resourceName = resourceNameFor(customerOrContactURL);
      if (resourceName === "customer") {
        const id = urlToId(customerOrContactURL);
        dispatch(actions.go("/customer/:id", {id}));
      } else if (resourceName === "contact") {
        const contact = contactLookup(customerOrContactURL as ContactUrl);
        if (contact) {
          const id = urlToId(contact.customer);
          dispatch(actions.go("/customer/:id", {id}));
        }
      }
    },
    [contactLookup, dispatch],
  );

  const handleCustomerCreateEditDialogOk = useCallback(
    (customerURL: string) => {
      const id = urlToId(customerURL);
      dispatch(actions.go("/customer/:id", {id}));
    },
    [dispatch],
  );

  const [showInactive, setShowInactive] = useBooleanQueryParameterState("showInactive");

  const handleShowInactiveCheckboxChange = useCallback(
    (_event: unknown, checked: boolean) => {
      setShowInactive(checked);
    },
    [setShowInactive],
  );

  const intl = useIntl();

  const toolbarRightElement = (
    <>
      <FormControlLabel
        checked={showInactive}
        control={<WhiteCheckbox />}
        label={<FormattedMessage defaultMessage="Vis inaktive" />}
        onChange={handleShowInactiveCheckboxChange}
      />
      <DebouncedAppbarSearchField
        debounceTimeout={200}
        onChange={handleFilterStringChange}
        value={filterString}
      />
    </>
  );

  let action;
  if (canManage) {
    action = (
      <VerticalStackingFloatingActionButton
        onClick={setCustomerCreateEditDialogOpenTrue}
        stackIndex={0}
      >
        <PlusIcon />
      </VerticalStackingFloatingActionButton>
    );
  }
  return (
    <PageLayout
      floatingActionButton={action}
      toolbar={
        <MenuToolbar
          onMenuButton={props.onMenuButton}
          rightElement={toolbarRightElement}
          title={intl.formatMessage({defaultMessage: "Kunder"})}
        />
      }
      withBottomScrollPadding
    >
      <CustomerTable
        filterString={filterString}
        onClick={handleCustomerContactClick}
        showInactive={showInactive}
      />
      {liveSyncWithThirdParty ? (
        <ExternalCustomerCreateDialog
          onCancel={setCustomerCreateEditDialogOpenFalse}
          onSuccess={handleCustomerCreateEditDialogOk}
          open={customerCreateEditDialogOpen}
        />
      ) : (
        <CustomerCreateDialog
          onCancel={setCustomerCreateEditDialogOpenFalse}
          onCreateSuccess={handleCustomerCreateEditDialogOk}
          open={customerCreateEditDialogOpen}
        />
      )}
    </PageLayout>
  );
});

export default CustomerList;
