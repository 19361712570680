import {getCustomerSettings} from "@co-frontend-libs/redux";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ConfirmDialog, ConfirmDialogProps} from "./confirm-dialog";

export function ConfirmContactChangeDialog(
  props: Omit<ConfirmDialogProps, "children" | "title">,
): React.JSX.Element {
  const intl = useIntl();
  const {projectLabelVariant} = useSelector(getCustomerSettings);
  return (
    <ConfirmDialog title={intl.formatMessage({defaultMessage: "Skift kontaktperson"})} {...props}>
      {projectLabelVariant === "PROJECT" ? (
        <FormattedMessage defaultMessage="Skal projektets kontaktperson erstatte den nuværende kontaktperson?" />
      ) : (
        <FormattedMessage defaultMessage="Skal sagens kontaktperson erstatte den nuværende kontaktperson?" />
      )}
    </ConfirmDialog>
  );
}
