import {
  CustomerUrl,
  Location,
  LocationUrl,
  ReportingLocation,
  ReportingSpecification,
  ReportingWorkplaceInputSpecification,
} from "@co-common-libs/resources";
import {
  BaseGenericMultiSelectionSearchDialog,
  computeBaseChoicesForLocations,
  EntryData,
  getLabels,
  TitleVariant,
} from "@co-frontend-libs/components";
import {ConnectedLocationDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCustomerLookup,
  getExtendedCustomerSettings,
  getVisibleLocationArray,
} from "@co-frontend-libs/redux";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import {createLocation, useFalseCallback} from "app-utils";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {LocationCreateEditDialog} from "../location-create-edit-dialog";
import {EditReportingLocation, ValuesState} from "./edit-reporting-location-dialog";

function locationDialogTitleVariant(type: ReportingLocation["type"]): TitleVariant {
  if (type === "workplace") {
    return "WORKPLACE";
  } else if (type === "delivery") {
    return "DELIVERY";
  } else {
    console.assert(type === "pickup", `unknown type log location type: ${type}`);
    return "PICKUP";
  }
}

const emptyData: ValuesState = {};
const noInputSpecifications: readonly ReportingWorkplaceInputSpecification[] = [];

interface NonFieldLocationWithLogDataDialogProps {
  customerUrl: CustomerUrl | null;
  lastUsedLocations: ReadonlySet<LocationUrl> | undefined;
  logSpecification: ReportingSpecification | undefined;
  onCancel: () => void;
  onOk: (locationUrl: LocationUrl, data: ValuesState) => void;
  open: boolean;
  type: ReportingLocation["type"];
}

export const NonFieldLocationWithLogDataDialog = React.memo(
  function NonFieldLocationWithLogDataDialog(
    props: NonFieldLocationWithLogDataDialogProps,
  ): React.JSX.Element {
    const {customerUrl, lastUsedLocations, logSpecification, onCancel, onOk, open, type} = props;
    const customerLookup = useSelector(getCustomerLookup);

    const {
      locationFavoritesEnabled,
      locations: {canCreateLocation},
      setLogOnlyLocationOnCreate,
    } = useSelector(getExtendedCustomerSettings);

    const dispatch = useDispatch();

    const inputSpecifications =
      logSpecification?.workplaceData[type]?.inputs || noInputSpecifications;

    const hasRequiredInputs =
      inputSpecifications !== undefined && inputSpecifications.some(({required}) => required);

    const [dataDialogLocation, setDataDialogLocation] = useState<LocationUrl>();
    const [dataDialogOpen, setDataDialogOpen] = useState(false);

    const setDataDialogOpenFalse = useFalseCallback(setDataDialogOpen, [setDataDialogOpen]);

    const handleSelect = useCallback(
      (locationUrl: LocationUrl): void => {
        if (hasRequiredInputs) {
          setDataDialogLocation(locationUrl);
          setDataDialogOpen(true);
        } else {
          onOk(locationUrl, {});
        }
      },
      [hasRequiredInputs, onOk],
    );

    const handleDataOk = useCallback(
      (data: ValuesState): void => {
        setDataDialogOpen(false);
        if (dataDialogLocation) {
          onOk(dataDialogLocation, data);
        }
      },
      [dataDialogLocation, onOk],
    );

    const [createSearch, setCreateSearch] = useState("");
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const setLocationCreateDialogOpenFalse = useFalseCallback(setCreateDialogOpen, [
      setCreateDialogOpen,
    ]);

    const handleRequestCreate = useCallback((searchString: string): void => {
      setCreateSearch(searchString);
      setCreateDialogOpen(true);
    }, []);

    const handleCreate = useCallback(
      (locationData: Partial<Omit<Location, "id" | "url">>): void => {
        const instance: Location = createLocation(locationData);
        dispatch(actions.create(instance));
        setCreateDialogOpen(false);
        handleSelect(instance.url);
      },
      [dispatch, handleSelect, setCreateDialogOpen],
    );

    return (
      <>
        <ConnectedLocationDialog
          customerURL={customerUrl}
          hideFieldLocations
          includeLogOnlyLocations
          includeWorkplaceOnlyLocations={false}
          lastUsedLocations={lastUsedLocations}
          onAdd={canCreateLocation ? handleRequestCreate : undefined}
          onCancel={onCancel}
          onOk={handleSelect}
          open={open}
          titleVariant={locationDialogTitleVariant(type)}
        />
        <LocationCreateEditDialog
          customerLookup={customerLookup}
          initialCustomer={customerUrl}
          initialSearch={createSearch}
          locationFavoritesEnabled={locationFavoritesEnabled}
          logOnlyLocation={setLogOnlyLocationOnCreate}
          onCancel={setLocationCreateDialogOpenFalse}
          onOk={handleCreate}
          open={createDialogOpen}
          workplaceOnlyLocation={false}
        />
        {logSpecification && dataDialogLocation ? (
          <EditReportingLocation
            currentData={emptyData}
            inputSpecifications={inputSpecifications}
            locationUrl={dataDialogLocation}
            logSpecification={logSpecification}
            onCancel={setDataDialogOpenFalse}
            onOk={handleDataOk}
            open={dataDialogOpen}
            type={type}
          />
        ) : null}
      </>
    );
  },
);

interface MultiNonFieldLocationWithLogDataDialogProps {
  customerUrl: CustomerUrl | null;
  lastUsedLocations: ReadonlySet<LocationUrl> | undefined;
  locationDataMap: ReadonlyMap<LocationUrl, ValuesState>;
  logSpecification: ReportingSpecification | undefined;
  multiSelectMax?: number;
  onCancel: () => void;
  onOk: (data: ReadonlyMap<LocationUrl, ValuesState>) => void;
  open: boolean;
  readonlySet?: ReadonlySet<LocationUrl> | undefined;
  type: ReportingLocation["type"];
}

export const MultiNonFieldLocationWithLogDataDialog = React.memo(
  function MultiNonFieldLocationWithLogDataDialog(
    props: MultiNonFieldLocationWithLogDataDialogProps,
  ): React.JSX.Element {
    const {
      customerUrl,
      lastUsedLocations,
      locationDataMap: locationDataMapFromProps,
      logSpecification,
      multiSelectMax,
      onCancel,
      onOk,
      open,
      readonlySet,
      type,
    } = props;
    const customerLookup = useSelector(getCustomerLookup);

    const {
      locationCrossCustomerSelectionEnabled,
      locationFavoritesEnabled,
      locations: {canCreateLocation},
      setLogOnlyLocationOnCreate,
    } = useSelector(getExtendedCustomerSettings);

    const locationArray = useSelector(getVisibleLocationArray);

    const dispatch = useDispatch();

    const inputSpecifications =
      logSpecification?.workplaceData[type]?.inputs || noInputSpecifications;

    const hasRequiredInputs =
      inputSpecifications !== undefined && inputSpecifications.some(({required}) => required);

    const [dataDialogLocation, setDataDialogLocation] = useState<LocationUrl>();
    const [dataDialogOpen, setDataDialogOpen] = useState(false);

    const setDataDialogOpenFalse = useFalseCallback(setDataDialogOpen, [setDataDialogOpen]);

    const [locationDataMap, setLocationDataMap] = useState<ReadonlyMap<LocationUrl, ValuesState>>(
      new Map(),
    );

    useEffect(() => {
      if (open) {
        setLocationDataMap(locationDataMapFromProps);
      }
    }, [locationDataMapFromProps, open]);

    const setLocationData = useCallback(
      (location: LocationUrl, data: ValuesState): void => {
        const changed = new Map(locationDataMap);
        changed.set(location, data);
        setLocationDataMap(changed);
      },
      [locationDataMap],
    );

    const removeLocationData = useCallback(
      (location: LocationUrl): void => {
        const changed = new Map(locationDataMap);
        changed.delete(location);
        setLocationDataMap(changed);
      },
      [locationDataMap],
    );

    const handleOk = useCallback((): void => {
      onOk(locationDataMap);
    }, [locationDataMap, onOk]);

    const selectedLocations = useMemo(
      (): ReadonlySet<LocationUrl> => new Set(locationDataMap.keys()),
      [locationDataMap],
    );

    const handleSelect = useCallback(
      (locationUrl: string, isSelected: boolean): void => {
        if (!isSelected) {
          removeLocationData(locationUrl as LocationUrl);
        } else {
          if (hasRequiredInputs) {
            setDataDialogLocation(locationUrl as LocationUrl);
            setDataDialogOpen(true);
          } else {
            setLocationData(locationUrl as LocationUrl, {});
          }
        }
      },
      [hasRequiredInputs, removeLocationData, setLocationData],
    );

    const handleSelectMultiple = useCallback(
      (identifiers: ReadonlySet<LocationUrl>, isSelected: boolean): void => {
        const changedLocationDataMap = new Map(locationDataMap);
        if (isSelected) {
          identifiers.forEach((locationUrl) => {
            if (!changedLocationDataMap.has(locationUrl)) {
              changedLocationDataMap.set(locationUrl, {});
            }
          });
        } else {
          identifiers.forEach((locationUrl) => {
            changedLocationDataMap.delete(locationUrl);
          });
        }
        setLocationDataMap(changedLocationDataMap);
      },
      [locationDataMap],
    );

    const handleDataOk = useCallback(
      (data: ValuesState): void => {
        setDataDialogOpen(false);
        if (dataDialogLocation) {
          setLocationData(dataDialogLocation, data);
        }
      },
      [dataDialogLocation, setLocationData],
    );

    const handleToggleSelected = useCallback(
      (locationUrl: string): void => {
        const wasSelected = locationDataMap.has(locationUrl as LocationUrl);
        handleSelect(locationUrl, !wasSelected);
      },
      [handleSelect, locationDataMap],
    );

    const [createSearch, setCreateSearch] = useState("");
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const setLocationCreateDialogOpenFalse = useFalseCallback(setCreateDialogOpen, [
      setCreateDialogOpen,
    ]);

    const handleRequestLocationCreate = useCallback((searchString: string): void => {
      setCreateSearch(searchString);
      setCreateDialogOpen(true);
    }, []);

    const handleCreate = useCallback(
      (locationData: Partial<Omit<Location, "id" | "url">>): void => {
        const instance: Location = createLocation(locationData);
        dispatch(actions.create(instance));
        setCreateDialogOpen(false);
        handleSelect(instance.url, true);
      },
      [dispatch, handleSelect, setCreateDialogOpen],
    );

    const intl = useIntl();
    const {searchLabel, title} = useMemo(
      () => getLabels(intl, locationDialogTitleVariant(type)),
      [intl, type],
    );
    const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
      () => memoizeForceReuse(computeBaseChoicesForLocations, []),
      [],
    );
    const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
    const filteredLocationArray = useMemo(() => {
      return locationArray.filter((location) => !location.workplaceOnlyLocation);
    }, [locationArray]);

    const mayShowMultipleCustomers =
      locationFavoritesEnabled || locationCrossCustomerSelectionEnabled;

    const hideFieldLocations = true;
    const onlyActive = true;

    const data = getBaseChoices(
      intl,
      filteredLocationArray,
      customerUrl,
      customerLookup,
      locationFavoritesEnabled,
      locationCrossCustomerSelectionEnabled,
      onlyActive,
      mayShowMultipleCustomers,
      hideFieldLocations,
      lastUsedLocations,
    );

    const dataWithReadonly = useMemo(() => {
      if (readonlySet) {
        return data.map(
          (entry): EntryData<LocationUrl> => ({
            ...entry,
            disabled: readonlySet.has(entry.identifier),
          }),
        );
      } else {
        return data;
      }
    }, [data, readonlySet]);

    return (
      <>
        <BaseGenericMultiSelectionSearchDialog
          data={dataWithReadonly}
          mobilePrimaryLines={1}
          mobileSearchPrimaryLines={1}
          mobileSearchSecondaryLines={1}
          mobileSecondaryLines={1}
          multiSelectMax={multiSelectMax}
          onAdd={canCreateLocation ? handleRequestLocationCreate : undefined}
          onCancel={onCancel}
          onOk={handleOk}
          onSelect={handleSelect}
          onSelectMultiple={handleSelectMultiple}
          onToggleSelected={handleToggleSelected}
          open={open}
          searchTitle={searchLabel}
          selected={selectedLocations}
          title={title}
        />
        <LocationCreateEditDialog
          customerLookup={customerLookup}
          initialCustomer={customerUrl}
          initialSearch={createSearch}
          locationFavoritesEnabled={locationFavoritesEnabled}
          logOnlyLocation={setLogOnlyLocationOnCreate}
          onCancel={setLocationCreateDialogOpenFalse}
          onOk={handleCreate}
          open={createDialogOpen}
          workplaceOnlyLocation={false}
        />
        {logSpecification && dataDialogLocation ? (
          <EditReportingLocation
            currentData={emptyData}
            inputSpecifications={inputSpecifications}
            locationUrl={dataDialogLocation}
            logSpecification={logSpecification}
            onCancel={setDataDialogOpenFalse}
            onOk={handleDataOk}
            open={dataDialogOpen}
            type={type}
          />
        ) : null}
      </>
    );
  },
);
