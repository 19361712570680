import {Task} from "@co-common-libs/resources";
import {getCurrentRole, getCustomerSettings} from "@co-frontend-libs/redux";
import useMergedRef from "@react-hook/merged-ref";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {DragSourceMonitor, useDrag} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";
import {useSelector} from "react-redux";
import {TaskBlock, TaskBlockProps} from "./task-block";

const LONG_PRESS_DELAY_MS = 300;

export const DraggableTaskBlock = React.memo(function DraggableTaskBlock(
  props: TaskBlockProps,
): React.JSX.Element {
  const {onDragHandleLongPress, onDragHandleMouseDown} = props;
  const resizing = useRef<boolean>(false);
  const customerSettings = useSelector(getCustomerSettings);
  const currentRole = useSelector(getCurrentRole);
  const isManager = !!currentRole?.manager;
  const isSeniorMachineOperator = !!currentRole?.seniorMachineOperator;

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, task: Task, taskBlock: HTMLDivElement) => {
      resizing.current = true;
      if (onDragHandleMouseDown) {
        onDragHandleMouseDown(event, task, taskBlock);
      }
    },
    [onDragHandleMouseDown],
  );

  const handleResizeStop = useCallback(() => {
    resizing.current = false;
  }, []);

  const handleLongPress = useCallback(
    (event: React.TouchEvent<HTMLDivElement>, task: Task, taskBlock: HTMLDivElement) => {
      resizing.current = true;
      if (onDragHandleLongPress) {
        onDragHandleLongPress(event, task, taskBlock);
      }
    },
    [onDragHandleLongPress],
  );

  const hasTaskStarted =
    !!props.task.machineOperatorTimeCorrectionSet.length ||
    !!props.task.managerTimeCorrectionSet.length ||
    !!props.task.workFromTimestamp;

  const allowDrag =
    customerSettings.calendarPlanningFunctions &&
    !hasTaskStarted &&
    (isManager || isSeniorMachineOperator) &&
    !resizing.current;

  const [dragCollectedProps, drag, preview] = useDrag({
    canDrag: (_monitor: DragSourceMonitor) => allowDrag,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: props.task,
    type: "task",
  });

  useEffect(() => {
    preview(getEmptyImage(), {captureDraggingState: true});
  }, [preview]);

  const [dragStart, setDragStart] = useState(false);

  const taskBlockRef = useRef<HTMLDivElement>(null);
  const multiRef = useMergedRef(drag, taskBlockRef);

  const timeout = useRef<number>();

  useEffect(() => {
    const taskBlock = taskBlockRef.current;
    if (!taskBlock) {
      return undefined;
    }

    const handleTouchStart = (event: TouchEvent): void => {
      if ((event.targetTouches[0].target as HTMLDivElement).className === "taskDragHandle") {
        return;
      }
      window.clearTimeout(timeout.current);
      timeout.current = window.setTimeout(() => {
        setDragStart(true);
      }, LONG_PRESS_DELAY_MS);
    };

    const handleMove = (): void => {
      window.clearTimeout(timeout.current);
      setDragStart(false);
    };

    if (allowDrag) {
      taskBlock.addEventListener("touchstart", handleTouchStart);
      taskBlock.addEventListener("touchmove", handleMove);
    }
    return () => {
      window.clearTimeout(timeout.current);
      taskBlock.removeEventListener("touchstart", handleTouchStart);
      taskBlock.removeEventListener("touchmove", handleMove);
    };
  }, [allowDrag]);

  return (
    <TaskBlock
      ref={multiRef}
      {...props}
      containerStyle={{
        ...props.containerStyle,
        boxShadow:
          dragCollectedProps.isDragging || dragStart
            ? "0 0 5px 1px"
            : props.containerStyle?.boxShadow || "none",
      }}
      onDragHandleLongPress={onDragHandleLongPress ? handleLongPress : undefined}
      onDragHandleMouseDown={onDragHandleMouseDown ? handleMouseDown : undefined}
      onResizeStop={handleResizeStop}
    />
  );
});
