import {WhiteCheckbox} from "@co-frontend-libs/components";
import {FormControlLabel} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedMessage} from "react-intl";

interface ShowInactiveToggleProps {
  setShowInactive: (show: boolean) => void;
  showInactive: boolean;
}

export const ShowInactiveToggle = React.memo(function ShowInactiveToggle(
  props: ShowInactiveToggleProps,
): React.JSX.Element {
  const {setShowInactive, showInactive} = props;

  const handleShowInactiveCheckboxChange = useCallback(
    (_event: React.ChangeEvent<unknown>, checked: boolean) => {
      setShowInactive(checked);
    },
    [setShowInactive],
  );

  return (
    <FormControlLabel
      checked={showInactive}
      control={<WhiteCheckbox />}
      label={<FormattedMessage defaultMessage="Vis inaktive" />}
      onChange={handleShowInactiveCheckboxChange}
    />
  );
});
