import {emptyUnit, Unit} from "@co-common-libs/resources";
import {instanceURL} from "frontend-global-config";
import {v4 as uuid} from "uuid";

export function createUnit(instance: Partial<Omit<Unit, "id" | "url">>): Unit {
  const id = uuid();
  const url = instanceURL("unit", id);

  return {
    id,
    url,
    ...emptyUnit,
    ...instance,
  };
}
