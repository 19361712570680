import React, {useCallback} from "react";
import {CustomTextField, TextFieldPropsWithoutOnChange} from "./custom-text-field";

function trimString(text: string): string {
  return text.trim();
}

function textOrBlank(value: string | null): string {
  return value ?? "";
}

export interface TrimTextFieldProps extends TextFieldPropsWithoutOnChange {
  errorText?: string | undefined;
  onChange?: ((value: string) => void) | undefined;
  readonly?: boolean | undefined;
  value?: string | undefined;
  warningText?: string | undefined;
}

export function TrimTextField({onChange, ...others}: TrimTextFieldProps): React.JSX.Element {
  const handleChange = useCallback(
    (newValue: string | null) => {
      if (onChange) {
        onChange(textOrBlank(newValue));
      }
    },
    [onChange],
  );

  return (
    <CustomTextField<string>
      {...others}
      onChange={handleChange}
      textFrom={textOrBlank}
      valueFrom={trimString}
    />
  );
}
