import {CustomerDialog, CustomerDialogProps} from "@co-frontend-libs/components";
import {
  getActiveContactArray,
  getActiveCustomerArray,
  getCurrentUserSelectableProjectArray,
  getCurrentUserURL,
  getCustomerSettings,
  getOrderLookup,
  getTaskArray,
} from "@co-frontend-libs/redux";
import {filterWithExternalId} from "external-id-utils";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";

export const ExternalCustomerSelectDialog = React.memo(function ExternalCustomerSelectDialog(
  props: Pick<CustomerDialogProps, "onCancel" | "onOk" | "open">,
): React.JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  const activeContactArray = useSelector(getActiveContactArray);
  const currentUserURL = useSelector(getCurrentUserURL);
  const orderLookup = useSelector(getOrderLookup);
  const activeProjectArray = useSelector(getCurrentUserSelectableProjectArray);
  const taskArray = useSelector(getTaskArray);
  const activeCustomers = useSelector(getActiveCustomerArray);

  return (
    <CustomerDialog
      contactArray={activeContactArray}
      currentUserURL={currentUserURL}
      customerArray={useMemo(() => filterWithExternalId(activeCustomers), [activeCustomers])}
      customerSettings={customerSettings}
      orderLookup={orderLookup}
      projectArray={activeProjectArray}
      suggestRecentlyUsed={false}
      taskArray={taskArray}
      {...props}
    />
  );
});
