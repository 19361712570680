import {actions, PathTemplate} from "@co-frontend-libs/redux";
import {PathParameters, QueryParameters} from "@co-frontend-libs/routing-sync-history";
import {IconButton} from "@material-ui/core";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";

export const GoToPathButton = ({
  children,
  disabled,
  parameters,
  path,
  queryParameters,
}: {
  children: React.ReactElement;
  disabled?: boolean;
  parameters?: PathParameters;
  path: PathTemplate;
  queryParameters?: QueryParameters;
}): React.JSX.Element => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(actions.go(path, parameters, queryParameters));
  }, [dispatch, path, parameters, queryParameters]);
  return (
    <IconButton color="primary" disabled={!!disabled} onClick={handleClick}>
      {children}
    </IconButton>
  );
};
