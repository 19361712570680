import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PatchUnion,
  Task,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {dateFromString, dateToString, MINUTE_MILLISECONDS, WEEK_DAYS} from "@co-common-libs/utils";
import {Check, makeQuery, Query} from "@co-frontend-libs/db-resources";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {dateFromDateAndTime, PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {endOfDay, startOfDay, subDays} from "date-fns";
import _ from "lodash";
import React from "react";
import {Column} from "./column";
import {
  ARCHIVE_DAYS,
  DAY_HEIGHT,
  DAY_WIDTH,
  DAYS_BACK,
  DAYS_FORWARD,
  HEADER_HEIGHT,
  MACHINES_WIDTH,
  TOTAL_DAYS,
} from "./constants";
import {DateCell} from "./date-cell";
import {MachineCell} from "./machine-cell";
import ScrollLayout from "./scroll-layout";

const EMPTY_MAP = new Map();

const getDateArray = (selectedDate: string): string[] => {
  const result = [];
  console.assert(selectedDate);
  const startDate = dateFromString(selectedDate) as Date;
  for (let i = -DAYS_BACK; i < DAYS_FORWARD; i += 1) {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);
    result.push(dateToString(date));
  }
  return result;
};

interface MachinesTabContentProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  dateList: readonly string[];
  dateMachineTaskMap: ReadonlyMap<string, ReadonlyMap<string, readonly Task[]>>;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  machineList: readonly Machine[];
  machineMaxTaskMap: ReadonlyMap<string, number>;
  machineOrderingMode: boolean;
  orderLookup: (url: OrderUrl) => Order | undefined;
  putQueryKeys: (
    update: {readonly [key: string]: string | undefined},
    navigationKind?: PartialNavigationKind,
  ) => void;
  scrollX: string;
  scrollY: string;
  updateOrder: (machineCalendarOrder: string[]) => void;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

class MachinesTabContent extends PureComponent<MachinesTabContentProps> {
  @bind
  handleMachineDragDrop(source: MachineUrl, target: MachineUrl): void {
    const originalOrder = this.props.machineList.map((m) => m.url);
    const sourceIndex = originalOrder.indexOf(source);
    const targetIndex = originalOrder.indexOf(target);
    // this moves source past target, up or down:
    // * if source after target, this inserts before
    // * if source before target, target is shifted by removal first...
    const newOrder = originalOrder.slice();
    newOrder.splice(sourceIndex, 1);
    newOrder.splice(targetIndex, 0, source);
    this.props.updateOrder(newOrder);
  }

  render(): React.JSX.Element {
    const {
      customerLookup,
      dateList,
      dateMachineTaskMap,
      machineList,
      machineMaxTaskMap,
      machineOrderingMode,
      orderLookup,
      userUserProfileLookup,
      workTypeLookup,
    } = this.props;
    const now = new Date();
    const today = dateToString(now);
    const dateCells = dateList.map((date) => {
      return <DateCell date={date} isToday={date === today} key={date} />;
    });
    const machineCells = machineList.map((machine) => {
      const machineURL = machine.url;
      const maxTasks = machineMaxTaskMap.get(machineURL) || 0;
      return (
        <MachineCell
          key={machineURL}
          machine={machine}
          machineOrderingMode={machineOrderingMode}
          maxTasks={maxTasks}
          onDragDrop={this.handleMachineDragDrop}
        />
      );
    });
    const machineTaskOffsetMap = new Map<string, number>();
    let contentElementCount = 0;
    machineList.forEach((machine) => {
      const machineURL = machine.url;
      machineTaskOffsetMap.set(machineURL, contentElementCount);
      contentElementCount += machineMaxTaskMap.get(machineURL) || 1;
    });
    const columnList = dateList.map((date) => {
      return (
        <Column
          contentElementCount={contentElementCount}
          customerLookup={customerLookup}
          date={date}
          go={this.props.go}
          key={date}
          machineTaskMap={dateMachineTaskMap.get(date) || EMPTY_MAP}
          machineTaskOffsetMap={machineTaskOffsetMap}
          orderLookup={orderLookup}
          userUserProfileLookup={userUserProfileLookup}
          workTypeLookup={workTypeLookup}
        />
      );
    });
    return (
      <ScrollLayout
        contentHeight={contentElementCount * DAY_HEIGHT}
        contentWidth={TOTAL_DAYS * DAY_WIDTH}
        headerContent={dateCells}
        initialScrollLeft={DAYS_BACK * DAY_WIDTH}
        leftContent={machineCells}
        leftWidth={MACHINES_WIDTH}
        putQueryKeys={this.props.putQueryKeys}
        scrollX={this.props.scrollX}
        scrollY={this.props.scrollY}
        topHeight={HEADER_HEIGHT}
      >
        {columnList}
      </ScrollLayout>
    );
  }
}

interface MachinesTabContentContainerProps {
  currentUserProfile?: UserProfile | undefined;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  machineArray: readonly Machine[];
  machineCalendarOrder: readonly string[];
  machineOrderingMode: boolean;
  onToggleMachineOrderingMode: (on: boolean) => void;
  orderLookup: (url: OrderUrl) => Order | undefined;
  pathName: string;
  putQueryKeys: (
    update: {readonly [key: string]: string | undefined},
    navigationKind?: PartialNavigationKind,
  ) => void;
  scrollX: string;
  scrollY: string;
  selectedDate: string;
  taskArray: readonly Task[];
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
  updateOrder: (machineCalendarOrder: string[]) => void;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

const TEMPORARY_QUERIES_KEY = "MachinesTabContentContainer";

class MachinesTabContentContainer extends PureComponent<MachinesTabContentContainerProps> {
  componentDidMount(): void {
    const {selectedDate} = this.props;
    this.updateQuery(selectedDate);
  }

  componentDidUpdate(prevProps: MachinesTabContentContainerProps): void {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.updateQuery(this.props.selectedDate);
    }
  }

  render(): React.JSX.Element {
    const {machineArray, machineCalendarOrder, machineOrderingMode, selectedDate, taskArray} =
      this.props;

    let machineList = _.sortBy(
      machineArray.filter((m) => m.active),
      (m) => m.c5_machine,
    );
    if (machineCalendarOrder) {
      const machineOrder = new Map<string, number>();
      machineCalendarOrder.forEach((machineURL, index) => {
        machineOrder.set(machineURL, index);
      });
      machineList = _.sortBy(machineList, (m) => machineOrder.get(m.url) || 0);
    }
    const dates = getDateArray(selectedDate);
    const dateSet = new Set(dates);
    const machineMaxTasks = new Map<string, number>();
    const dateMachineTaskMap = new Map<string, Map<string, Task[]>>();
    const addMachineDateTaskMapEntry = (machineURL: string, date: string, task: Task): void => {
      // only if date in range --- to avoid messing up machineMaxTasks, waste computation...
      if (!dateSet.has(date)) {
        return;
      }
      let machineTaskMap = dateMachineTaskMap.get(date);
      if (!machineTaskMap) {
        machineTaskMap = new Map<string, Task[]>();
        dateMachineTaskMap.set(date, machineTaskMap);
      }
      const existing = machineTaskMap.get(machineURL);
      const oldMaxCount = machineMaxTasks.get(machineURL) || 0;
      let count;
      if (existing) {
        existing.push(task);
        count = existing.length;
      } else {
        machineTaskMap.set(machineURL, [task]);
        count = 1;
      }
      if (count > oldMaxCount) {
        machineMaxTasks.set(machineURL, count);
      }
    };
    taskArray.forEach((task) => {
      const {date} = task;
      if (!date) {
        return;
      }
      const taskDates = [date];
      const {time} = task;
      const duration = task.minutesExpectedTotalTaskDuration;
      if (time && duration) {
        const dateObj = dateFromDateAndTime(date, time);
        const otherDateObj = new Date(dateObj.valueOf() + duration * MINUTE_MILLISECONDS);
        const otherDate = dateToString(otherDateObj);
        if (otherDate !== date) {
          taskDates.push(otherDate);
        }
      }
      const machineUseList = task.machineuseSet;
      if (machineUseList) {
        machineUseList.forEach((machineUse) => {
          for (let i = 0; i < taskDates.length; i += 1) {
            const taskDate = taskDates[i];
            const machineURL = machineUse.machine;
            addMachineDateTaskMapEntry(machineURL, taskDate, task);
          }
        });
      }
    });

    return (
      <MachinesTabContent
        customerLookup={this.props.customerLookup}
        dateList={dates}
        dateMachineTaskMap={dateMachineTaskMap}
        go={this.props.go}
        machineList={machineList}
        machineMaxTaskMap={machineMaxTasks}
        machineOrderingMode={machineOrderingMode}
        orderLookup={this.props.orderLookup}
        putQueryKeys={this.props.putQueryKeys}
        scrollX={this.props.scrollX}
        scrollY={this.props.scrollY}
        updateOrder={this.props.updateOrder}
        userUserProfileLookup={this.props.userUserProfileLookup}
        workTypeLookup={this.props.workTypeLookup}
      />
    );
  }
  updateQuery(selectedDate: string): void {
    const periodStartDate = dateFromString(selectedDate) as Date;
    periodStartDate.setDate(periodStartDate.getDate() - DAYS_BACK);
    const periodStart = dateToString(periodStartDate);
    const periodEndDate = new Date(periodStartDate);
    periodEndDate.setDate(periodEndDate.getDate() + DAYS_FORWARD);
    const periodEnd = dateToString(periodEndDate);
    const timeBackDateDate = new Date();
    timeBackDateDate.setDate(timeBackDateDate.getDate() - ARCHIVE_DAYS);
    const timeBackDate = dateToString(timeBackDateDate);
    if (periodStart <= timeBackDate) {
      // archive relevant --- completed/recorded tasks for this date may not
      // be present in normal working set

      const {pathName, temporaryQueriesRequestedForPath} = this.props;
      const rangeStartString = startOfDay(dateFromString(periodStart) as Date).toISOString();
      const rangeEndString = endOfDay(dateFromString(periodEnd) as Date).toISOString();
      const taskCheck: Check = {
        checks: [
          {
            checks: [
              {
                memberName: "workToTimestamp",
                type: "memberGt",
                value: rangeStartString,
              },
              {
                memberName: "workFromTimestamp",
                type: "memberLt",
                value: rangeEndString,
              },
              {memberName: "completed", type: "memberTruthy"},
            ],
            type: "and",
          },
          {
            checks: [
              {
                memberName: "date",
                type: "memberGte",
                value: dateToString(subDays(new Date(rangeStartString), WEEK_DAYS)),
              },
              {
                memberName: "date",
                type: "memberLte",
                value: periodEnd,
              },
            ],
            type: "and",
          },
          {memberName: "completed", type: "memberFalsy"},
        ],
        type: "or",
      };
      const orderCheck: Check = {
        check: taskCheck,
        fromResource: "task",
        memberName: "order",
        type: "targetOfForeignKey",
      };
      const queries = [
        makeQuery({
          check: taskCheck,
          filter: {
            fromDate: periodStart,
            toDate: periodEnd,
          },
          independentFetch: true,
          resourceName: "task",
        }),
        makeQuery({
          check: orderCheck,
          independentFetch: false,
          resourceName: "order",
        }),
      ];
      temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY);
    }
  }
}

export default MachinesTabContentContainer;
