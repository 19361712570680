import {DeleteDialog, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  DialogContent,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import DeleteIcon from "mdi-react/DeleteIcon";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {StatusDot} from "../status-dot";
import {SettingViewProps} from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

function EnterStringsRow({
  index,
  onRequestDelete,
  onValueChange,
  value,
}: {
  index: number;
  onRequestDelete: (index: number) => void;
  onValueChange: (index: number, value: string) => void;
  value: string;
}): React.JSX.Element {
  const handleValueChange = useCallback(
    (newValue: string) => {
      onValueChange(index, newValue);
    },
    [index, onValueChange],
  );

  const handleDeleteClick = useCallback(() => {
    onRequestDelete(index);
  }, [index, onRequestDelete]);
  return (
    <TableRow>
      <TableCell>
        <TrimTextField
          fullWidth
          margin="dense"
          onChange={handleValueChange}
          value={value}
          variant="outlined"
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function EnterStringsDialog({
  description,
  onCancel,
  onOk,
  open,
  values: initialValues,
}: {
  description?: string;
  onCancel: () => void;
  onOk: (newValue: readonly string[]) => void;
  open: boolean;
  values: string[];
}): React.JSX.Element {
  const [values, setValues] = useState<readonly string[]>(initialValues);
  useEffect(() => {
    if (open) {
      setValues(initialValues);
    }
  }, [initialValues, open]);

  const handleValueChange = useCallback(
    (index: number, value: string) => {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
    },
    [values],
  );

  const [newEnterStringsDialogOpen, setNewEnterStringsDialogOpen] = useState(false);
  const setNewEnterStringsDialogOpenTrue = useCallWithTrue(setNewEnterStringsDialogOpen);
  const setNewEnterStringsDialogOpenFalse = useCallWithFalse(setNewEnterStringsDialogOpen);

  const handleOk = useCallback(() => {
    onOk(values);
  }, [onOk, values]);
  const handleNewEnterStringsDialogOk = useCallback(
    (value: string) => {
      setNewEnterStringsDialogOpen(false);
      const newValues = [...values, value];
      setValues(newValues);
    },
    [values],
  );

  const duplicateKeys = values.length !== new Set(values.map((key) => key)).size;

  const [deleteDialogOpenFor, setDeleteDialogOpenFor] = useState<number | null>(null);

  const handleDeleteClick = useCallback((index: number) => {
    setDeleteDialogOpenFor(index);
  }, []);

  const handleDeleteDialogOk = useCallback(() => {
    setDeleteDialogOpenFor(null);
    if (deleteDialogOpenFor === null) {
      return;
    }
    const newValues = [...values];
    newValues.splice(deleteDialogOpenFor, 1);
    setValues(newValues);
  }, [deleteDialogOpenFor, values]);

  const handleDeleteDialogCancel = useCallback(() => {
    setDeleteDialogOpenFor(null);
  }, []);

  const deleteDialogOpen = deleteDialogOpenFor !== null;

  return (
    <>
      <ResponsiveDialog
        okDisabled={duplicateKeys}
        onCancel={onCancel}
        onOk={handleOk}
        open={open && !newEnterStringsDialogOpen && !deleteDialogOpen}
        title={
          <FormattedMessage defaultMessage="Rediger værdier" id="enter-strings.edit-strings" />
        }
      >
        <DialogContent>
          {description}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Værdi" id="enter-strings.label" />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((value, index) => {
                return (
                  <EnterStringsRow
                    index={index}
                    key={index}
                    onRequestDelete={handleDeleteClick}
                    onValueChange={handleValueChange}
                    value={value}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Button onClick={setNewEnterStringsDialogOpenTrue}>Tilføj</Button>
        </DialogContent>
      </ResponsiveDialog>
      <AddStringsDialog
        onCancel={setNewEnterStringsDialogOpenFalse}
        onOk={handleNewEnterStringsDialogOk}
        open={newEnterStringsDialogOpen}
      />
      <DeleteDialog
        onCancel={handleDeleteDialogCancel}
        onOk={handleDeleteDialogOk}
        open={deleteDialogOpen}
      >
        <FormattedMessage
          defaultMessage="Hvis du sletter denne mens den er i brug, kan du ødelægge opsætningen!"
          id="key-value-setting.delete-message"
        />
      </DeleteDialog>
    </>
  );
}

function AddStringsDialog({
  onCancel,
  onOk,
  open,
}: {
  onCancel: () => void;
  onOk: (value: string) => void;
  open: boolean;
}): React.JSX.Element {
  const [value, setValue] = useState<string>("");

  const handleOk = useCallback(() => {
    onOk(value);
  }, [onOk, value]);
  return (
    <ResponsiveDialog
      okDisabled={!value}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={<FormattedMessage defaultMessage="Tilføj ny" id="absence-type-labels-dialog.title" />}
    >
      <DialogContent>
        <TrimTextField
          fullWidth
          label={<FormattedMessage defaultMessage="Værdi" id="enter-strings.label" />}
          margin="dense"
          onChange={setValue}
          value={value}
          variant="outlined"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}

export function EnterStringsSetting(props: SettingViewProps): React.JSX.Element {
  const {settingID, settingMetaData} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const settingsData: string[] = settingEntry?.data || [];
  const classes = useStyles();

  const [enterStringsDialogOpen, setEnterStringsDialogOpen] = useState(false);
  const setEnterStringsDialogOpenTrue = useCallWithTrue(setEnterStringsDialogOpen);
  const setEnterStringsDialogOpenFalse = useCallWithFalse(setEnterStringsDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleEnterStringsDialogOk = useCallback(
    (newValue: readonly string[]) => {
      setEnterStringsDialogOpen(false);

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry],
  );

  return (
    <>
      <div className={classes.root} style={{display: "flex"}}>
        <div onClick={setEnterStringsDialogOpenTrue} style={{cursor: "pointer", flex: "0 0 50px"}}>
          <StatusDot settingEntry={settingEntry} />
        </div>
        <div onClick={setEnterStringsDialogOpenTrue} style={{cursor: "pointer", flex: 1}}>
          {settingMetaData.description}
          <div>
            <i>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{width: 200}}>
                      <FormattedMessage defaultMessage="Værdi" id="enter-strings.label" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settingsData.map((value, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </i>
          </div>
        </div>
        <div style={{flex: "0 0 100px", paddingRight: 50}}>
          <small>{settingID}</small>
        </div>
      </div>
      <EnterStringsDialog
        description={settingMetaData.description}
        onCancel={setEnterStringsDialogOpenFalse}
        onOk={handleEnterStringsDialogOk}
        open={enterStringsDialogOpen}
        values={settingsData}
      />
    </>
  );
}
