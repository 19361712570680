import {Config} from "@co-common-libs/config";
import {IntlShape} from "react-intl";
import {MenuItem} from "../types";

export const menuItems = (
  intl: IntlShape,
  settings: Pick<Config, "displayProjectsPageMenu" | "machineLabelVariant" | "projectLabelVariant">,
): {[key: string]: MenuItem} => {
  return {
    anniversary: {
      route: "/settings/anniversary",
      text: intl.formatMessage({
        defaultMessage: "Mærkedage",
      }),
    },

    crops: {
      route: "/settings/crops",
      text: intl.formatMessage({
        defaultMessage: "Afgrøder",
      }),
    },
    csvExport: {
      route: "/settings/importAndExport",
      text: intl.formatMessage({
        defaultMessage: "Eksport",
      }),
    },
    csvImport: {
      route: "/settings/importAndExport",
      text: intl.formatMessage({
        defaultMessage: "Import",
      }),
    },
    csvImportAndExport: {
      route: "/settings/importAndExport",
      text: intl.formatMessage({
        defaultMessage: "Import og eksport",
      }),
    },
    employeeGroup: {
      route: "/settings/employeeGroup",
      text: intl.formatMessage({
        defaultMessage: "Medarbejdergrupper",
      }),
    },
    favoriteMachines: {
      route: "/settings/favoriteMachines",
      text:
        settings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage({defaultMessage: "Favoritmaskiner"})
          : intl.formatMessage({defaultMessage: "Favoritkøretøjer"}),
    },
    favoriteProducts: {
      route: "/settings/favoriteProducts",
      text: intl.formatMessage({
        defaultMessage: "Favoritvarer",
      }),
    },
    fields: {
      route: "/settings/fields",
      text: intl.formatMessage({
        defaultMessage: "Marker",
      }),
    },
    foodBooking: {
      route: "/settings/foodBooking",
      text: intl.formatMessage({
        defaultMessage: "Madbestillinger",
      }),
    },
    fuelSurcharge: {
      route: "/settings/fuelSurcharge",
      text: intl.formatMessage({
        defaultMessage: "Brændstoftillæg",
      }),
    },
    locations: {
      route: "/settings/locations",
      text: intl.formatMessage({
        defaultMessage: "Steder",
      }),
    },
    locationTypes: {
      route: "/settings/locationTypes",
      text: intl.formatMessage({
        defaultMessage: "Stedtyper",
      }),
    },
    machineAnalysis: {
      route: "/settings/machineAnalysis",
      text: intl.formatMessage({
        defaultMessage: "Maskinanalyse",
      }),
    },
    machines: {
      route: "/settings/machines",
      text:
        settings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage({defaultMessage: "Maskiner"})
          : intl.formatMessage({defaultMessage: "Køretøjer"}),
    },
    overview: {
      route: "/settings/overview",
      text: intl.formatMessage({
        defaultMessage: "Oversigt",
      }),
    },
    productGroups: {
      route: "/settings/productGroups",
      text: intl.formatMessage({
        defaultMessage: "Varegrupper",
      }),
    },
    products: {
      route: "/settings/products",
      text: intl.formatMessage({
        defaultMessage: "Varer",
      }),
    },
    projects: {
      route: "/settings/projects",
      text:
        settings.projectLabelVariant === "PROJECT"
          ? intl.formatMessage({
              defaultMessage: "Projekter",
            })
          : intl.formatMessage({
              defaultMessage: "Sager",
            }),
    },
    routes: {
      route: "/settings/routes",
      text: intl.formatMessage({
        defaultMessage: "Ruter",
      }),
    },
    units: {
      route: "/settings/units",
      text: intl.formatMessage({
        defaultMessage: "Enheder",
      }),
    },
    workshopChecklists: {
      route: "/settings/workshopChecklists",
      text: intl.formatMessage({
        defaultMessage: "Værkstedstjeklister",
      }),
    },
    workTypes: {
      route: "/settings/workTypes",
      text: intl.formatMessage({
        defaultMessage: "Arbejdsområder",
      }),
    },
  };
};
