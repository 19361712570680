import {
  CalculatorSpecification,
  Product,
  ProductUrl,
  ProductUseWithOrder,
  Unit,
} from "@co-common-libs/resources";
import {formatUnitString} from "@co-common-libs/resources-utils";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import _ from "lodash";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";

export interface EntryBaseProps {
  autoProduct: boolean;
  calculator: CalculatorSpecification | null;
  conversionRelatedValues: {
    readonly [unit: string]: number | null;
  } | null;
  conversionUnit: Unit | null;
  editable: boolean;
  identifier: string;
  notesEditable: boolean;
  onCountChange: (identifier: string, value: number | null) => void;
  onDeleteClick: ((identifier: string) => void) | null;
  onDetailButtonClick: ((identifier: string) => void) | null;
  onNotesChange: ((identifier: string, value: string) => void) | null;
  onOursChange: (identifier: string, value: boolean) => void;
  onSwitchProduct: ((identifier: string, productUrl: ProductUrl) => void) | null;
  onSwitchProductClick: ((identifier: string) => void) | null;
  product: Product;
  productUse: ProductUseWithOrder;
  removable: boolean;
  showIconColumn: boolean;
  showNotes: boolean;
  showRelationConversionColumns: boolean;
  switchable: boolean;
  unit: Unit | null;
}

export interface EntryProps {
  addedBy: string | null;
  autoProduct: boolean;
  calculator: CalculatorSpecification | null;
  countValue: number | null;
  editable: boolean;
  inputUnitSymbol: string;
  notes: string;
  notesEditable: boolean;
  onCountChange: (value: number | null) => void;
  onDeleteClick: (() => void) | null;
  onDetailButtonClick: (() => void) | null;
  onNotesChange?: ((value: string) => void) | undefined;
  onOursChange: (value: boolean) => void;
  onPlusClick: () => void;
  onRelationCountChange: (value: number | null) => void;
  onSwitchProduct: ((productUrl: ProductUrl) => void) | null;
  onSwitchProductClick: (() => void) | null;
  ours: boolean;
  productName: string;
  productNumber: string;
  relationConversionBaseUnit: string | undefined;
  relationConversionUnitSymbol: string;
  relationConversionValue: number | null | undefined;
  relationCountValue: number | null;
  removable: boolean;
  showIconColumn: boolean;
  showNotes: boolean;
  showRelationConversionColumns: boolean;
  switchable: boolean;
}

export function withEntryCallbacks(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WrappedComponent: React.ComponentType<EntryProps>,
): React.ComponentType<EntryBaseProps> {
  function WithEntryCallbacks(props: EntryBaseProps): React.JSX.Element {
    const {
      autoProduct,
      calculator,
      conversionRelatedValues,
      conversionUnit,
      editable,
      identifier,
      notesEditable,
      onCountChange,
      onDeleteClick,
      onDetailButtonClick,
      onNotesChange,
      onOursChange,
      onSwitchProduct,
      onSwitchProductClick,
      product,
      productUse,
      removable,
      showIconColumn,
      showNotes,
      showRelationConversionColumns,
      switchable,
      unit,
    } = props;

    const customerSettings = useSelector(getCustomerSettings);

    const {addedBy, count, notes, ours} = productUse;
    const {catalogNumber: productNumber, conversionFactor, name: productName} = product;

    const inputUnit = conversionUnit && conversionFactor ? conversionUnit : unit;
    const inputUnitName = inputUnit?.name || "";
    const inputUnitSymbol = formatUnitString(inputUnit || undefined);

    const countValue =
      count == null
        ? count
        : _.round(
            conversionUnit && conversionFactor && count ? count / conversionFactor : count,
            customerSettings.materialDecimals,
          );

    const handleCountChange = useCallback(
      (value: number | null): void => {
        if (conversionUnit && conversionFactor && value) {
          const convertedValue = value * conversionFactor;
          const maxStoredDecimals = 5;
          const roundedValue = _.round(convertedValue, maxStoredDecimals);
          onCountChange(identifier, roundedValue);
        } else {
          onCountChange(identifier, value);
        }
      },
      [conversionFactor, conversionUnit, identifier, onCountChange],
    );

    const handlePlusClick = useCallback((): void => {
      handleCountChange(countValue == null ? 0 : countValue + 1);
    }, [handleCountChange, countValue]);

    let relationConversionBaseUnit: string | undefined;
    let relationConversionValue: number | null | undefined;

    if (showRelationConversionColumns && conversionRelatedValues) {
      for (const [baseUnit, targetUnits] of Object.entries(
        customerSettings.priceItemRelationUnitConversionHelpers,
      )) {
        if (
          conversionRelatedValues[baseUnit] !== undefined &&
          targetUnits.includes(inputUnitName)
        ) {
          relationConversionBaseUnit = baseUnit;
          relationConversionValue = conversionRelatedValues[baseUnit];
          break;
        }
      }
    }

    const relationCountValue =
      relationConversionValue && countValue != null
        ? _.round(countValue / relationConversionValue, customerSettings.materialDecimals)
        : null;

    const relationConversionUnitSymbol =
      relationConversionBaseUnit !== undefined
        ? `${inputUnitSymbol}/${relationConversionBaseUnit}`
        : "";

    const handleRelationCountChange = useCallback(
      (value: number | null): void => {
        if (value === null || relationConversionValue == null) {
          return;
        }
        handleCountChange(value * relationConversionValue);
      },
      [handleCountChange, relationConversionValue],
    );

    const handleNotesChange = useCallback(
      (value: string): void => {
        if (onNotesChange) {
          onNotesChange(identifier, value);
        }
      },
      [identifier, onNotesChange],
    );

    const handleDetailButtonClick = useCallback((): void => {
      if (onDetailButtonClick) {
        onDetailButtonClick(identifier);
      }
    }, [identifier, onDetailButtonClick]);

    const handleOursChange = useCallback(
      (value: boolean): void => {
        onOursChange(identifier, value);
      },
      [identifier, onOursChange],
    );

    const handleDeleteClick = useCallback((): void => {
      if (onDeleteClick) {
        onDeleteClick(identifier);
      }
    }, [identifier, onDeleteClick]);

    const handleSwitchProductClick = useCallback((): void => {
      if (onSwitchProductClick) {
        onSwitchProductClick(identifier);
      }
    }, [identifier, onSwitchProductClick]);

    const handleSwitchProduct = useCallback(
      (productUrl: ProductUrl): void => {
        if (onSwitchProduct) {
          onSwitchProduct(identifier, productUrl);
        }
      },
      [identifier, onSwitchProduct],
    );

    return (
      <WrappedComponent
        addedBy={addedBy}
        autoProduct={autoProduct}
        calculator={calculator}
        countValue={countValue}
        editable={editable && !autoProduct}
        inputUnitSymbol={inputUnitSymbol}
        notes={notes}
        notesEditable={notesEditable}
        onCountChange={handleCountChange}
        onDeleteClick={onDeleteClick ? handleDeleteClick : null}
        onDetailButtonClick={onDetailButtonClick ? handleDetailButtonClick : null}
        onNotesChange={onNotesChange ? handleNotesChange : undefined}
        onOursChange={handleOursChange}
        onPlusClick={handlePlusClick}
        onRelationCountChange={handleRelationCountChange}
        onSwitchProduct={onSwitchProduct ? handleSwitchProduct : null}
        onSwitchProductClick={onSwitchProductClick ? handleSwitchProductClick : null}
        ours={ours}
        productName={productName}
        productNumber={productNumber}
        relationConversionBaseUnit={relationConversionBaseUnit}
        relationConversionUnitSymbol={relationConversionUnitSymbol}
        relationConversionValue={relationConversionValue}
        relationCountValue={relationCountValue}
        removable={removable}
        showIconColumn={showIconColumn}
        showNotes={showNotes}
        showRelationConversionColumns={showRelationConversionColumns}
        switchable={switchable}
      />
    );
  }
  if (process.env.NODE_ENV !== "production") {
    const wrappedDisplayName = WrappedComponent.displayName || WrappedComponent.name || "Component";
    WithEntryCallbacks.displayName = `WithEntryCallbacks(${wrappedDisplayName})`;
  }
  return WithEntryCallbacks;
}
