import {Project} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Typography} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

export function ProjectInfo({
  project: {alias, name, projectNumber},
}: {
  project: Project;
}): React.JSX.Element {
  const {showProjectAlias} = useSelector(getCustomerSettings);
  const nameAndOrAlias = showProjectAlias && name && alias ? `${name}, ${alias}` : name || alias;
  return (
    <Typography variant="body2">
      <FormattedMessage
        defaultMessage="{number}: {name}"
        values={{name: nameAndOrAlias, number: projectNumber}}
      />
    </Typography>
  );
}
