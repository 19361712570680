import {Config} from "@co-common-libs/config";
import {Machine, PriceGroup, Timer, TimerUrl, WorkType} from "@co-common-libs/resources";
import {
  colorBoxColumnSpecification,
  ColumnSpecifications,
  GenericTable,
  iconColumnSpecification,
  RowData,
} from "@co-frontend-libs/components";
import {
  actions,
  getCustomerSettings,
  getMachineArray,
  getPriceGroupArray,
  getTableSortingState,
  getTimerArray,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {useQueryParameter} from "app-utils";
import _ from "lodash";
import CheckIcon from "mdi-react/CheckIcon";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const MAX_LOCATIONS_DISPLAYED = 100;

const TABLE_SORTING_IDENTIFIER = "TimerTable";

type TimerTableColumnID =
  | "active"
  | "color"
  | "hideFor"
  | "identifier"
  | "includeFor"
  | "includeForDepartments"
  | "includeForExternalTask"
  | "includeForInternalTask"
  | "label";

type TimerTableFieldID =
  | "active"
  | "color"
  | "hideFor"
  | "identifier"
  | "includeFor"
  | "includeForDepartments"
  | "includeForExternalTask"
  | "includeForInternalTask"
  | "label";

interface TimerTableDataType extends RowData<TimerTableFieldID, TimerUrl> {
  active: boolean;
  color: string;
  hideFor: string;
  identifier: string;
  includeFor: string;
  includeForDepartments: string;
  includeForExternalTask: boolean;
  includeForInternalTask: boolean;
  label: string | null;
}

function makeColorRender(
  onColorClick: (timerURL: TimerUrl) => void,
): (data: TimerTableDataType) => React.JSX.Element {
  const changeHandlerMap = new Map<
    string,
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  >();
  return function renderColor(data: TimerTableDataType): React.JSX.Element {
    let handleClick = changeHandlerMap.get(data.key);
    if (!handleClick) {
      handleClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onColorClick(data.key);
      };
      changeHandlerMap.set(data.key, handleClick);
    }
    return data.color ? (
      <div
        onClick={handleClick}
        style={{
          backgroundColor: data.color,
          border: "1px solid #000",
          height: 16,
          width: 16,
        }}
      />
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <></>
    );
  };
}

function renderIncludeForExternalTask(data: TimerTableDataType): React.JSX.Element {
  return data.includeForExternalTask ? <CheckIcon /> : <span />;
}

function renderIncludeForInternalTask(data: TimerTableDataType): React.JSX.Element {
  return data.includeForInternalTask ? <CheckIcon /> : <span />;
}

function renderActive(data: TimerTableDataType): React.JSX.Element {
  return data.active ? <CheckIcon /> : <span />;
}

function buildColumnSpecifications(
  onClick: (timerURL: TimerUrl) => void,
  onColorClick: (timerURL: TimerUrl) => void,
): ColumnSpecifications<TimerTableFieldID, TimerTableColumnID, TimerUrl, TimerTableDataType> {
  return {
    active: iconColumnSpecification({
      field: "active",
      label: <FormattedMessage defaultMessage="Aktiv" />,
      onClick,
      render: renderActive,
    }),
    color: colorBoxColumnSpecification({
      field: "color",
      render: makeColorRender(onColorClick),
    }),
    hideFor: {
      field: "hideFor",
      label: (
        <FormattedMessage defaultMessage="Skjult ved" id="timer-list.table-header.hidden-for" />
      ),
      onClick,
    },
    identifier: {
      field: "identifier",
      label: <FormattedMessage defaultMessage="ID" id="timer-list.table-header.identifier" />,
      onClick,
      width: 150,
    },
    includeFor: {
      field: "includeFor",
      label: (
        <FormattedMessage defaultMessage="Synlig ved" id="timer-list.table-header.visible-for" />
      ),
      onClick,
    },
    includeForDepartments: {
      field: "includeForDepartments",
      label: (
        <FormattedMessage defaultMessage="Afdelinger" id="timer-list.table-header.departments" />
      ),
      onClick,
    },
    includeForExternalTask: iconColumnSpecification({
      field: "includeForExternalTask",
      label: <FormattedMessage defaultMessage="Eksterne" id="timer-list.table-header.external" />,
      onClick,
      render: renderIncludeForExternalTask,
    }),
    includeForInternalTask: iconColumnSpecification({
      field: "includeForInternalTask",
      label: <FormattedMessage defaultMessage="Interne" id="timer-list.table-header.internal" />,
      onClick,
      render: renderIncludeForInternalTask,
    }),
    label: {
      field: "label",
      label: <FormattedMessage defaultMessage="Label" id="timer-list.table-header.label" />,
      onClick,
    },
  };
}

function buildRowData(
  timerArray: readonly Timer[],
  customerSettings: Config,
  machineArray: readonly Readonly<Machine>[],
  workTypeArray: readonly Readonly<WorkType>[],
  priceGroupArray: readonly Readonly<PriceGroup>[],
): TimerTableDataType[] {
  const getDepartmentLabel = (departmentID: string): string =>
    customerSettings.departments[departmentID] || departmentID;

  const departmentsPerTimer = new Map<string, string[]>();
  Object.entries(customerSettings.departmentExtraTimers).forEach(([department, timers]) => {
    timers.forEach((timer) => {
      const oldDepartmentsForTimer = departmentsPerTimer.get(timer) || [];
      const newDepartmentsForTimer = [...oldDepartmentsForTimer, getDepartmentLabel(department)];
      departmentsPerTimer.set(timer, newDepartmentsForTimer);
    });
  });

  const getMachineLabel = (machineID: string): string => {
    const machine = machineArray.find((m) => m.c5_machine === machineID);
    if (machine) {
      return `${machineID}: ${machine.name}`;
    } else {
      return machineID;
    }
  };
  const getWorkTypeLabel = (workTypeID: string): string => {
    const workType = workTypeArray.find((w) => w.identifier === workTypeID);
    if (workType) {
      return `${workTypeID}: ${workType.name}`;
    } else {
      return workTypeID;
    }
  };
  const getPriceGroupLabel = (priceGroupID: string): string => {
    const priceGroup = priceGroupArray.find((p) => p.identifier === priceGroupID);
    if (priceGroup) {
      return `${priceGroupID}: ${priceGroup.name}`;
    } else {
      return priceGroupID;
    }
  };

  const includeForPerTimer = new Map<string, string[]>();
  const {
    machineExtraTimers,
    machinePriceGroupExtraTimers,
    workTypeExtraTimers,
    workTypeMachineExtraTimers,
    workTypePriceGroupExtraTimers,
  } = customerSettings;
  Object.entries(machineExtraTimers).forEach(([machine, timers]) => {
    timers.forEach((timer) => {
      const oldForTimer = includeForPerTimer.get(timer) || [];
      const newForTimer = [...oldForTimer, getMachineLabel(machine)];
      includeForPerTimer.set(timer, newForTimer);
    });
  });
  Object.entries(machinePriceGroupExtraTimers).forEach(([machine, priceGroupTimerMapping]) => {
    Object.entries(priceGroupTimerMapping).forEach(([priceGroup, timers]) => {
      timers.forEach((timer) => {
        const oldForTimer = includeForPerTimer.get(timer) || [];
        const newForTimer = [
          ...oldForTimer,
          `${getMachineLabel(machine)}+${getPriceGroupLabel(priceGroup)}`,
        ];
        includeForPerTimer.set(timer, newForTimer);
      });
    });
  });
  Object.entries(workTypeExtraTimers).forEach(([workType, timers]) => {
    timers.forEach((timer) => {
      const oldForTimer = includeForPerTimer.get(timer) || [];
      const newForTimer = [...oldForTimer, getWorkTypeLabel(workType)];
      includeForPerTimer.set(timer, newForTimer);
    });
  });
  Object.entries(workTypePriceGroupExtraTimers).forEach(([workType, priceGroupTimerMapping]) => {
    Object.entries(priceGroupTimerMapping).forEach(([priceGroup, timers]) => {
      timers.forEach((timer) => {
        const oldForTimer = includeForPerTimer.get(timer) || [];
        const newForTimer = [
          ...oldForTimer,
          `${getWorkTypeLabel(workType)}+${getPriceGroupLabel(priceGroup)}`,
        ];
        includeForPerTimer.set(timer, newForTimer);
      });
    });
  });
  Object.entries(workTypeMachineExtraTimers).forEach(([workType, machineTimerMapping]) => {
    Object.entries(machineTimerMapping).forEach(([machine, timers]) => {
      timers.forEach((timer) => {
        const oldForTimer = includeForPerTimer.get(timer) || [];
        const newForTimer = [
          ...oldForTimer,
          `${getWorkTypeLabel(workType)}+${getMachineLabel(machine)}`,
        ];
        includeForPerTimer.set(timer, newForTimer);
      });
    });
  });

  const hideForPerTimer = new Map<string, string[]>();
  const {priceGroupHideTimers, workTypeHideTimers} = customerSettings;
  Object.entries(priceGroupHideTimers).forEach(([priceGroup, timers]) => {
    timers.forEach((timer) => {
      const oldForTimer = hideForPerTimer.get(timer) || [];
      const newForTimer = [...oldForTimer, getPriceGroupLabel(priceGroup)];
      hideForPerTimer.set(timer, newForTimer);
    });
  });
  Object.entries(workTypeHideTimers).forEach(([workType, timers]) => {
    timers.forEach((timer) => {
      const oldForTimer = hideForPerTimer.get(timer) || [];
      const newForTimer = [...oldForTimer, getWorkTypeLabel(workType)];
      hideForPerTimer.set(timer, newForTimer);
    });
  });

  return timerArray.map((timer) => ({
    active: timer.active,
    color: timer.color,
    hideFor: timer.identifier ? (hideForPerTimer.get(timer.identifier) || []).join(", ") : "",
    identifier: timer.identifier,
    includeFor: timer.identifier ? (includeForPerTimer.get(timer.identifier) || []).join(", ") : "",
    includeForDepartments: timer.identifier
      ? (departmentsPerTimer.get(timer.identifier) || []).join(", ")
      : "",
    includeForExternalTask: timer.includeForExternalTask,
    includeForInternalTask: timer.includeForInternalTask,
    key: timer.url,
    label: timer.label,
  }));
}

interface TimerTableProps {
  onClick: (timerURL: TimerUrl) => void;
  onColorClick: (timerURL: TimerUrl) => void;
}

const visibleColumns: readonly TimerTableColumnID[] = [
  "identifier",
  "color",
  "label",
  "includeForExternalTask",
  "includeForInternalTask",
  "includeForDepartments",
  "includeFor",
  "hideFor",
  "active",
];

export function TimerTable(props: TimerTableProps): React.JSX.Element {
  const {onClick, onColorClick} = props;

  const timerArray = useSelector(getTimerArray);
  const customerSettings = useSelector(getCustomerSettings);
  const machineArray = useSelector(getMachineArray);
  const workTypeArray = useSelector(getWorkTypeArray);
  const priceGroupArray = useSelector(getPriceGroupArray);

  const dispatch = useDispatch();

  const sortedTimers = useMemo(() => {
    return _.sortBy(timerArray, [(t) => -t.active, (t) => t.label]);
  }, [timerArray]);

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(onClick, onColorClick),
    [onClick, onColorClick],
  );

  const rowData = useMemo(
    () =>
      buildRowData(sortedTimers, customerSettings, machineArray, workTypeArray, priceGroupArray),
    [customerSettings, machineArray, priceGroupArray, sortedTimers, workTypeArray],
  );

  const filterString = useQueryParameter("q", "");

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: TimerTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  return (
    <GenericTable
      columns={columnSpecifications}
      entries={rowData}
      filterString={filterString}
      maxDisplayed={MAX_LOCATIONS_DISPLAYED}
      onHeaderClick={handleHeaderClick}
      sortBy={sortKey as any}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
    />
  );
}
