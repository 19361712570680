import {Config, SettingID, settings} from "@co-common-libs/config";
import {DecimalField, IntegerField, ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {
  Checkbox,
  DialogContent,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface GeolocationDialogDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

type Geolocation = Config["geolocation"];

export function GeolocationDialog(props: GeolocationDialogDialogProps): React.JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const settingMeta = settings[settingID];
  const geolocation: Geolocation = useMemo(() => {
    const data = settingEntry?.data;
    return data
      ? {...(settingMeta.schema.default as Geolocation), ...data}
      : settingMeta.schema.default;
  }, [settingEntry?.data, settingMeta.schema.default]);

  const [values, setValues] = useState<Geolocation>(geolocation);

  useEffect(() => {
    if (open) {
      setValues(geolocation);
    }
  }, [geolocation, open]);
  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);

  const handleSave = useCallback(() => {
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: values},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, settingEntry, values]);

  const handeEnableChanged = useCallback(
    (value: boolean) => {
      setValues({...values, enabled: value});
    },
    [values],
  );

  const handeRegisterPositionOnTimerClickChanged = useCallback(
    (value: boolean) => {
      setValues({...values, registerPositionOnTimerClick: value});
    },
    [values],
  );

  const handleInitialZoomChange = useCallback(
    (value: number | null) => {
      if (value !== null) {
        setValues({...values, initialZoom: value});
      }
    },
    [values],
  );

  const handleHomeMarkerLatitudeChange = useCallback(
    (value: number | null) => {
      if (value !== null) {
        setValues({...values, homeMarkerLatitude: value});
      }
    },
    [values],
  );

  const handleHomeMarkerLongitudeChange = useCallback(
    (value: number | null) => {
      if (value !== null) {
        setValues({...values, homeMarkerLongitude: value});
      }
    },
    [values],
  );

  const handleInitialPositionLatitudeChange = useCallback(
    (value: number | null) => {
      if (value !== null) {
        setValues({...values, initialPositionLatitude: value});
      }
    },
    [values],
  );
  const handleInitialPositionLongitudeChange = useCallback(
    (value: number | null) => {
      if (value !== null) {
        setValues({...values, initialPositionLongitude: value});
      }
    },
    [values],
  );

  const handleActiveMarkerWarningThresholdInMinutesChange = useCallback(
    (value: number | null) => {
      if (value !== null) {
        setValues({...values, activeMarkerWarningThresholdInMinutes: value});
      }
    },
    [values],
  );

  return (
    <ResponsiveDialog
      fullWidth
      okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
      onCancel={onClose}
      onOk={handleSave}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Kort og GPS"
          id="system-setup.dialog-title.select-colors"
        />
      }
    >
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage defaultMessage="Navn" id="system-setup.dialog-title.name" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Værdi" id="system-setup.dialog-title.value" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage
                  defaultMessage="Kort og GPS aktiveret"
                  id="geolocation-dialog.geolocation-enabled"
                />
              </TableCell>
              <TableCell>
                <FormControl>
                  <Checkbox
                    checked={!!values.enabled}
                    onChange={useEventTargetCheckedCallback(handeEnableChanged, [
                      handeEnableChanged,
                    ])}
                  />
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage
                  defaultMessage="Registrer position ved klik på tidsknap"
                  id="geolocation-dialog.register-position-on-timer-click"
                />
              </TableCell>
              <TableCell>
                <FormControl>
                  <Checkbox
                    checked={!!values.registerPositionOnTimerClick}
                    onChange={useEventTargetCheckedCallback(
                      handeRegisterPositionOnTimerClickChanged,
                      [handeRegisterPositionOnTimerClickChanged],
                    )}
                  />
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage
                  defaultMessage="Hjemme markering, breddegrad"
                  id="geolocation-dialog.home-marker-latitude"
                />
              </TableCell>
              <TableCell>
                <DecimalField
                  decimalPlaces={7}
                  fullWidth
                  margin="dense"
                  maxDigits={12}
                  onChange={handleHomeMarkerLatitudeChange}
                  value={values.homeMarkerLatitude}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage
                  defaultMessage="Hjemme markering, længdegrad"
                  id="geolocation-dialog.home-marker-longitude"
                />
              </TableCell>
              <TableCell>
                <DecimalField
                  decimalPlaces={7}
                  fullWidth
                  margin="dense"
                  maxDigits={12}
                  onChange={handleHomeMarkerLongitudeChange}
                  value={values.homeMarkerLongitude}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage
                  defaultMessage="Initiel kort zoom"
                  id="geolocation-dialog.initial-zoom"
                />
              </TableCell>
              <TableCell>
                <IntegerField
                  fullWidth
                  margin="dense"
                  onChange={handleInitialZoomChange}
                  value={values.initialZoom}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage
                  defaultMessage="Initiel kort position, breddegrad"
                  id="geolocation-dialog.initial-position-latitude"
                />
              </TableCell>
              <TableCell>
                <DecimalField
                  decimalPlaces={7}
                  fullWidth
                  margin="dense"
                  maxDigits={12}
                  onChange={handleInitialPositionLatitudeChange}
                  value={values.initialPositionLatitude}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage
                  defaultMessage="Initiel kort position, længdegrad"
                  id="geolocation-dialog.initial-position-longitude"
                />
              </TableCell>
              <TableCell>
                <DecimalField
                  decimalPlaces={7}
                  fullWidth
                  margin="dense"
                  maxDigits={12}
                  onChange={handleInitialPositionLongitudeChange}
                  value={values.initialPositionLongitude}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage
                  defaultMessage="Vis advarselsmarking på kort efter antal minutter uden positionsdata"
                  id="geolocation-dialog.active-marker-warning-threshold-in-minutes"
                />
              </TableCell>
              <TableCell>
                <IntegerField
                  fullWidth
                  margin="dense"
                  onChange={handleActiveMarkerWarningThresholdInMinutesChange}
                  value={values.activeMarkerWarningThresholdInMinutes}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </ResponsiveDialog>
  );
}
