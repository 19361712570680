import {
  alpha,
  createStyles,
  IconButton,
  InputAdornment,
  InputBase,
  InputBaseProps,
  makeStyles,
  Theme,
} from "@material-ui/core";
import {MuiPickersContext} from "@material-ui/pickers";
import bowser from "bowser";
import {format, formatISO, Locale, parseISO} from "date-fns";
import CalendarIcon from "mdi-react/CalendarIcon";
import CalendarTodayIcon from "mdi-react/CalendarTodayIcon";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {AppbarButton} from "./appbar-button";
import {DateDialog, DateDialogProps} from "./date-dialog";
import {parseDate} from "./date-field/parse-date";

// based on
// https://material-ui.com/components/app-bar/#app-bar-with-search-field
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateSelect: {
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },

      backgroundColor: alpha(theme.palette.common.white, 0.15),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      position: "relative",
      width: "auto",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 1),
      width: 90,
    },
    inputRoot: {
      color: "inherit",
    },
    todayButton: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
  }),
);

type InputBasePropsPart = Omit<InputBaseProps, "inputRef" | "onChange">;

interface AppbarDateSelectionProps extends InputBasePropsPart {
  autoOk?: boolean;
  calendarStartDate?: string | null;
  maxDate?: string | null | undefined;
  minDate?: string | null | undefined;
  native?: boolean;
  onChange: (value: string | null) => void;
  showButton?: boolean;
  value?: string | null;
}

export function AppbarDateSelection(props: AppbarDateSelectionProps): React.JSX.Element {
  const {
    autoOk = true,
    calendarStartDate,
    maxDate,
    minDate,
    native: nativeFromProps,
    onBlur: onBlurFromProps,
    onChange,
    onFocus: onFocusFromProps,
    showButton: showButtonFromProps,
    value: valueFromProps,
    ...others
  } = props;

  // for iOS/Android, we use <input type="date"> to show native picker;
  // prop override is for testing
  const native = nativeFromProps ?? (bowser.ios || bowser.android || false);
  const locale = useContext(MuiPickersContext)?.locale as Locale | undefined;

  const localeOption = useMemo(() => (locale ? {locale} : undefined), [locale]);

  // value from prop -- ISO format expected; displayed text localised
  const dateFromProps = valueFromProps ? parseISO(valueFromProps) : undefined;
  const textFromProps = dateFromProps
    ? native
      ? valueFromProps
      : format(dateFromProps, "P", localeOption)
    : "";

  // value in local state while focused/while typing;
  // but "synced out" if resulting value changed
  const [text, setText] = useState(textFromProps);
  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newText = event.target.value;
      setText(newText);
      if (native) {
        const newValue = event.target.validity && event.target.validity.valid ? newText : null;
        if (newValue !== (valueFromProps ?? null)) {
          onChange(newValue);
        }
      } else {
        const date = parseDate(newText, new Date(), locale);
        const newValue = date ? formatISO(date, {representation: "date"}) : null;
        if (newValue !== (valueFromProps ?? null)) {
          onChange(newValue);
        }
      }
    },
    [locale, native, onChange, valueFromProps],
  );

  const ref = useRef<HTMLInputElement>();
  // NTS: "clear" hack for iOS still required:
  // Where Android clears to "", iOS clears to defaultValue,
  // which React unhelpfully sets to the same as value...
  useEffect(() => {
    if (bowser.ios) {
      return () => {
        // intentionally accessing
        if (ref.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          ref.current.defaultValue = "";
        }
      };
    } else {
      return undefined;
    }
  });

  // track focus to display local state when focused;
  // copy current outer value into local state on gaining focus
  const [focused, setFocused] = useState(props.autoFocus || false);
  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      setFocused(true);
      setText(textFromProps);
      if (onFocusFromProps) {
        onFocusFromProps(event);
      }
    },
    [onFocusFromProps, textFromProps],
  );
  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      setFocused(false);
      if (onBlurFromProps) {
        onBlurFromProps(event);
      }
    },
    [onBlurFromProps],
  );

  // dialog open state
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);
  const selectedInDialog = useCallback(
    (date: Date | null) => {
      setDialogOpen(false);
      const newValue = date ? formatISO(date, {representation: "date"}) : null;
      const newText = date ? (native ? (newValue as string) : format(date, "P", localeOption)) : "";
      setText(newText);
      if (newValue !== (valueFromProps ?? null)) {
        onChange(newValue);
      }
    },
    [localeOption, native, onChange, valueFromProps],
  );

  const showButton = showButtonFromProps ?? !(native && bowser.android);

  const now = new Date();
  const todayDateString = formatISO(now, {representation: "date"});
  const isToday =
    dateFromProps &&
    dateFromProps.getFullYear() === now.getFullYear() &&
    dateFromProps.getMonth() === now.getMonth() &&
    dateFromProps.getDate() === now.getDate();

  const handleTodayButtonClick = useCallback(() => {
    if (!isToday) {
      onChange(todayDateString);
    }
  }, [isToday, todayDateString, onChange]);

  const endAdornment = useMemo(
    () =>
      showButton ? (
        <InputAdornment position="end">
          <IconButton color="inherit" onClick={openDialog}>
            {isToday ? <CalendarTodayIcon /> : <CalendarIcon />}
          </IconButton>
        </InputAdornment>
      ) : undefined,
    [isToday, openDialog, showButton],
  );

  const classes = useStyles();

  const todayButton = (
    <AppbarButton
      className={classes.todayButton}
      onClick={handleTodayButtonClick}
      variant="outlined"
    >
      <FormattedMessage defaultMessage="I dag" id="appbar-date-selection.label.today" />
    </AppbarButton>
  );

  const dateDialogOptionalProps: Partial<DateDialogProps> = {};
  if (dateFromProps) {
    dateDialogOptionalProps.value = dateFromProps;
  }

  return (
    <>
      {todayButton}
      <div className={classes.dateSelect}>
        <InputBase
          classes={{
            input: classes.inputInput,
            root: classes.inputRoot,
          }}
          endAdornment={endAdornment}
          style={{whiteSpace: "nowrap"}}
          type={native ? "date" : "text"}
          {...others}
          inputRef={ref}
          onBlur={onBlur}
          onChange={onTextChange}
          onFocus={onFocus}
          value={focused ? text : textFromProps}
        />
      </div>
      <DateDialog
        autoOk={autoOk}
        initialFocusedDate={calendarStartDate || null}
        maxDate={maxDate || null}
        minDate={minDate || null}
        onCancel={closeDialog}
        onOk={selectedInDialog}
        open={dialogOpen}
        {...dateDialogOptionalProps}
      />
    </>
  );
}

export default AppbarDateSelection;
