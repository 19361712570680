import {
  CustomerUrl,
  emptyProject,
  Project,
  ProjectAccess,
  ProjectUrl,
  TaskUrl,
  urlToId,
} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {useModal} from "@co-frontend-libs/components";
import {ConnectedProjectDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  diffResourceInstanceProperties,
  getContactArray,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getExtendedCustomerSettings,
  getOrderLookup,
  getProjectLookup,
  getTaskArray,
  getTaskLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Button, IconButton, Typography} from "@material-ui/core";
import {taskChangeCustomerCulture} from "app-utils";
import {instanceURL} from "frontend-global-config";
import CloseIcon from "mdi-react/CloseIcon";
import React, {forwardRef, Ref, useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {v4 as uuid} from "uuid";
import {
  ConfirmContactChangeDialog,
  ConfirmMoveTaskDialog,
  ConfirmWorkplaceChangeDialog,
} from "../confirm-dialogs";
import {ProjectCreateEditDialog, ProjectData} from "../create-edit-dialogs";
import {CustomerSelectCreateDialog} from "../customer-select-create-dialog";
import {InformationBlockPropsBase} from "./information-block-props-base";
import {ProjectInfo} from "./project-info";

export type UserConfirmations = {
  contactConfirmDialogResponse: boolean;
  workplaceConfirmDialogResponse: boolean;
};

export type ConfirmDialogVisibility = {
  showContactConfirmDialog: boolean;
  showRelatedWorkplaceConfirmDialog: boolean;
};

export function useHandleTaskProjectChange(
  onTaskMovedToNewOrder?: (orderId: string, taskId: string) => void,
): [typeof confirmMoveTaskDialog, typeof handleTaskProjectChange] {
  const contactArray = useSelector(getContactArray);
  const customerLookup = useSelector(getCustomerLookup);
  const currentUserUrl = useSelector(getCurrentUserURL);
  const taskArray = useSelector(getTaskArray);
  const orderLookup = useSelector(getOrderLookup);
  const unitLookup = useSelector(getUnitLookup);
  const projectLookup = useSelector(getProjectLookup);
  const taskLookup = useSelector(getTaskLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const dispatch = useDispatch();
  const [confirmMoveTaskDialog, prompteConfirmMoveTaskDialog] = useModal(ConfirmMoveTaskDialog);

  const handleTaskProjectChange = useCallback(
    async (url: ProjectUrl, taskUrl: TaskUrl): Promise<void> => {
      const task = taskUrl && taskLookup(taskUrl);
      if (!task) {
        return;
      }

      const newProject = projectLookup(url);
      const projectCustomerUrl = newProject && newProject.customer;
      const projectWorkplaceUrl = newProject && newProject.relatedWorkplace;
      const proejctContactUrl = newProject && newProject.contact;

      const orderId = task.order && urlToId(task.order);
      const order = task.order && orderLookup(task.order);

      const numberOfTasks = taskArray.filter((instance) => instance.order === order?.url).length;

      if (!projectCustomerUrl || !orderId) {
        return;
      }

      if (numberOfTasks > 1) {
        const ok = await prompteConfirmMoveTaskDialog();
        if (ok) {
          const possiblyNewOrderId = taskChangeCustomerCulture(
            projectCustomerUrl,
            null,
            {
              contactArray,
              create: bindActionCreators(actions.create, dispatch),
              customerLookup,
              orderLookup,
              task,
              taskArray,
              unitLookup,
              update: bindActionCreators(actions.update, dispatch),
            },
            customerSettings,
            currentUserUrl,
          );
          const customer = projectCustomerUrl && customerLookup(projectCustomerUrl);
          const address = customer ? formatAddress(customer) : "";

          const changes = diffResourceInstanceProperties(
            {address, project: url, relatedWorkplace: projectWorkplaceUrl},
            task,
          );
          dispatch(actions.update(taskUrl, changes));

          if (onTaskMovedToNewOrder && possiblyNewOrderId && possiblyNewOrderId !== orderId) {
            const newOrder = orderLookup(instanceURL("order", possiblyNewOrderId));
            if (newOrder) {
              dispatch(actions.updateDiff({contact: proejctContactUrl}, newOrder));
            }
            onTaskMovedToNewOrder(possiblyNewOrderId, urlToId(task.url));
          }
        }
      }
    },
    [
      contactArray,
      currentUserUrl,
      customerLookup,
      customerSettings,
      dispatch,
      onTaskMovedToNewOrder,
      orderLookup,
      projectLookup,
      prompteConfirmMoveTaskDialog,
      taskArray,
      taskLookup,
      unitLookup,
    ],
  );
  return [confirmMoveTaskDialog, handleTaskProjectChange];
}

export function useProjectSetters(
  customerUrl: CustomerUrl | null,
  defaultAccessOnCreate: ProjectAccess,
  onCustomerChanged: (customerUrl: CustomerUrl | null) => void,
  onDialogsConfirmed: (userConfirmations: UserConfirmations, project: Project) => void,
  onProjectChanged: (projectUrl: ProjectUrl | null) => void,
  computeConfirmDialogVisibility: (projectUrl: ProjectUrl) => ConfirmDialogVisibility,
): [
  typeof confirmWorkplaceChangeDialog,
  typeof confirmContactChangeDialog,
  typeof selectProjectModal,
  typeof createProjectModal,
  typeof customerModal,
  typeof handleProjectSelectButton,
] {
  const dispatch = useDispatch();
  const {
    projects: {canCreate},
  } = useSelector(getExtendedCustomerSettings);
  const projectLookup = useSelector(getProjectLookup);
  const [projectSelectionAbortController] = useState<AbortController>(new AbortController());

  const [customerModal, customerPrompt] = useModal(CustomerSelectCreateDialog);
  const [selectProjectModal, promptForSelectProject] = useModal(ConnectedProjectDialog);
  const [createProjectModal, promptForCreateProject] = useModal(ProjectCreateEditDialog);
  const [confirmWorkplaceChangeDialog, promptConfirmWorkplaceChangeDialog] = useModal(
    ConfirmWorkplaceChangeDialog,
  );

  const [confirmContactChangeDialog, prompteConfirmContactChangeDialog] = useModal(
    ConfirmContactChangeDialog,
  );

  const handleProjectCreateEditDialogOk = useCallback(
    async (data: ProjectData): Promise<void> => {
      const id = uuid();
      const url = instanceURL("project", id);
      const newProject: Project = {
        ...emptyProject,
        ...data,
        id,
        url,
      };
      dispatch(actions.create(newProject));
      if (data.customer !== customerUrl) {
        onCustomerChanged(data.customer);
      }
      onProjectChanged(newProject.url);

      const {showContactConfirmDialog, showRelatedWorkplaceConfirmDialog} =
        computeConfirmDialogVisibility(newProject.url);

      const userConfirmations = {
        contactConfirmDialogResponse: showContactConfirmDialog
          ? ((await prompteConfirmContactChangeDialog()) ?? false)
          : false,
        workplaceConfirmDialogResponse: showRelatedWorkplaceConfirmDialog
          ? ((await promptConfirmWorkplaceChangeDialog()) ?? false)
          : false,
      };

      projectSelectionAbortController.abort();
      onDialogsConfirmed(userConfirmations, newProject);
    },
    [
      dispatch,
      customerUrl,
      onProjectChanged,
      computeConfirmDialogVisibility,
      prompteConfirmContactChangeDialog,
      promptConfirmWorkplaceChangeDialog,
      onDialogsConfirmed,
      projectSelectionAbortController,
      onCustomerChanged,
    ],
  );

  const handleAddProject = useCallback(async (): Promise<void> => {
    const selectedCustomerUrl = customerUrl || (await customerPrompt());
    if (!selectedCustomerUrl) {
      return;
    }
    const data = await promptForCreateProject({data: selectedCustomerUrl, defaultAccessOnCreate});
    if (data) {
      handleProjectCreateEditDialogOk(data);
    }
  }, [
    customerUrl,
    customerPrompt,
    promptForCreateProject,
    defaultAccessOnCreate,
    handleProjectCreateEditDialogOk,
  ]);

  const handleProjectSelectButton = useCallback(async (): Promise<void> => {
    const selectedProjectUrl = await promptForSelectProject(
      {
        customerURL: customerUrl,
        onAdd: canCreate ? handleAddProject : undefined,
        suggestRecentlyUsed: true,
      },
      projectSelectionAbortController.signal,
    );
    if (selectedProjectUrl) {
      const newProject = selectedProjectUrl && projectLookup(selectedProjectUrl);
      if (newProject) {
        if (newProject.customer !== customerUrl) {
          onCustomerChanged(newProject.customer);
        }
        onProjectChanged(selectedProjectUrl);

        const {showContactConfirmDialog, showRelatedWorkplaceConfirmDialog} =
          computeConfirmDialogVisibility(selectedProjectUrl);

        const userConfirmations = {
          contactConfirmDialogResponse: showContactConfirmDialog
            ? ((await prompteConfirmContactChangeDialog()) ?? false)
            : false,
          workplaceConfirmDialogResponse: showRelatedWorkplaceConfirmDialog
            ? ((await promptConfirmWorkplaceChangeDialog()) ?? false)
            : false,
        };

        onDialogsConfirmed(userConfirmations, newProject);
      }
    }
  }, [
    promptForSelectProject,
    customerUrl,
    canCreate,
    handleAddProject,
    projectSelectionAbortController.signal,
    projectLookup,
    computeConfirmDialogVisibility,
    prompteConfirmContactChangeDialog,
    promptConfirmWorkplaceChangeDialog,
    onDialogsConfirmed,
    onProjectChanged,
    onCustomerChanged,
  ]);

  return [
    confirmWorkplaceChangeDialog,
    confirmContactChangeDialog,
    selectProjectModal,
    createProjectModal,
    customerModal,
    handleProjectSelectButton,
  ];
}

export interface ProjectBlockProps extends InformationBlockPropsBase {
  computeConfirmDialogVisibility: (projectUrl: ProjectUrl) => ConfirmDialogVisibility;
  customerUrl: CustomerUrl | null;
  defaultAccessOnCreate: ProjectAccess;
  onCustomerChanged: (customerUrl: CustomerUrl | null) => void;
  onDialogsConfirmed: (userConfirmations: UserConfirmations, project: Project) => void;
  onProjectChanged: (projectUrl: ProjectUrl | null) => void;
  projectUrl: ProjectUrl | null;
}

export const ProjectBlock = forwardRef(function ProjectBlock(
  {
    computeConfirmDialogVisibility,
    customerUrl,
    defaultAccessOnCreate,
    hideButtons,
    onCustomerChanged,
    onDialogsConfirmed,
    onProjectChanged,
    projectUrl,
    selectDisabled,
  }: ProjectBlockProps,
  selectButtonRef: Ref<HTMLButtonElement>,
): React.JSX.Element {
  const projectLookup = useSelector(getProjectLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const {projectLabelVariant} = customerSettings;

  const [
    confirmWorkplaceChangeDialog,
    confirmContactChangeDialog,
    selectProjectModal,
    createProjectModal,
    customerModal,
    handleProjectSelectButton,
  ] = useProjectSetters(
    customerUrl,
    defaultAccessOnCreate,
    onCustomerChanged,
    onDialogsConfirmed,
    onProjectChanged,
    computeConfirmDialogVisibility,
  );

  const handleRemoveProject = useCallback((): void => {
    onProjectChanged(null);
  }, [onProjectChanged]);

  const project = projectUrl && projectLookup(projectUrl);

  return (
    <>
      <Typography variant="body1">
        {projectLabelVariant === "PROJECT" ? (
          <FormattedMessage defaultMessage="Projekt" />
        ) : (
          <FormattedMessage defaultMessage="Sag" />
        )}
      </Typography>

      {!hideButtons && (
        <>
          <Button
            color="secondary"
            disabled={selectDisabled}
            onClick={handleProjectSelectButton}
            ref={selectButtonRef}
            variant="contained"
          >
            <FormattedMessage defaultMessage="Vælg" />
          </Button>
          <IconButton disabled={!projectUrl} onClick={handleRemoveProject}>
            <CloseIcon />
          </IconButton>
        </>
      )}
      {project && <ProjectInfo project={project} />}
      {selectProjectModal}
      {createProjectModal}
      {customerModal}
      {confirmWorkplaceChangeDialog}
      {confirmContactChangeDialog}
    </>
  );
});
