import {
  ReportingLocations,
  ReportingLog,
  ReportingSpecification,
  Task,
} from "@co-common-libs/resources";
import {getInputSpecificationsMap} from "@co-common-libs/resources-utils";
import {getCustomerSettings, getLocationLookup} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader, Fab, Grid, useTheme} from "@material-ui/core";
import bowser from "bowser";
import {SPACING} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import SwapVerticalIcon from "mdi-react/SwapVerticalIcon";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {AddReportingLocationsFab} from "../../reporting-locations/add-reporting-locations-fab";
import {LocationButton} from "./location-button";

interface PickupDeliveryLocationsBlockProps {
  completedDeliveryLocations: ReadonlySet<string>;
  completedPickupLocations: ReadonlySet<string>;
  fieldNotesPerLocation?: ReadonlyMap<string, [string | undefined, string | undefined]> | undefined;
  locationCounts: ReadonlyMap<string, number>;
  logSpecification: ReportingSpecification;
  onEditLocationButtonClick: (
    locationEntryType: "delivery" | "pickup" | "workplace",
    locationEntryIdentifier: string,
  ) => void;
  onLocationButtonClick?: (type: "delivery" | "pickup" | "workplace", identifier: string) => void;
  onReorderDeliveryLocations: (fromIdentifier: string, toIdentifier: string) => void;
  onReorderPickupLocations: (fromIdentifier: string, toIdentifier: string) => void;
  onRequestAddDeliveryLocation: () => void;
  onRequestAddPickupLocation: () => void;
  onSwapButtonClick: () => void;
  readonly: boolean;
  reportingLocations: ReportingLocations;
  reportingLog: ReportingLog;
  swappingLocations: boolean;
  task: Task;
}

export const PickupDeliveryLocationsBlock = React.memo(function PickupDeliveryLocationsBlock(
  props: PickupDeliveryLocationsBlockProps,
): React.JSX.Element {
  const {
    completedDeliveryLocations,
    completedPickupLocations,
    fieldNotesPerLocation,
    locationCounts,
    logSpecification,
    onEditLocationButtonClick,
    onLocationButtonClick,
    onReorderDeliveryLocations,
    onReorderPickupLocations,
    onRequestAddDeliveryLocation,
    onRequestAddPickupLocation,
    onSwapButtonClick,
    readonly,
    reportingLocations,
    reportingLog,
    swappingLocations,
    task,
  } = props;

  const locationLookup = useSelector(getLocationLookup);

  const theme = useTheme();
  const customerSettings = useSelector(getCustomerSettings);

  const inputSpecificationsMap = useMemo(
    () => getInputSpecificationsMap(logSpecification),
    [logSpecification],
  );

  const sortedPickupLocationEntries = useMemo(
    () =>
      _.sortBy(
        Object.entries(reportingLocations).filter(
          ([_identifier, reportingLocation]) => reportingLocation.type === "pickup",
        ),
        ([_identifier, reportingLocation]) => reportingLocation.order,
      ),
    [reportingLocations],
  );

  const sortedDeliveryLocationEntries = useMemo(
    () =>
      _.sortBy(
        Object.entries(reportingLocations).filter(
          ([_identifier, reportingLocation]) => reportingLocation.type === "delivery",
        ),
        ([_identifier, reportingLocation]) => reportingLocation.order,
      ),
    [reportingLocations],
  );

  return (
    <div style={{padding: "1em"}}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Card style={{overflow: "visible"}}>
            {customerSettings.enableTaskLogLocationChange ? (
              <div style={{position: "relative"}}>
                {!bowser.mobile && !bowser.tablet ? (
                  <Fab
                    disabled={readonly}
                    onClick={onSwapButtonClick}
                    size="small"
                    style={{
                      position: "absolute",
                      right: 64,
                      top: theme.spacing(SPACING.SMALL),
                    }}
                  >
                    <SwapVerticalIcon />
                  </Fab>
                ) : null}
                {customerSettings.addEditLogLocationSkipsCustomerSelection ? (
                  <AddReportingLocationsFab
                    disabled={readonly}
                    logSpecification={logSpecification}
                    task={task}
                    type="pickup"
                  />
                ) : (
                  <Fab
                    disabled={readonly}
                    onClick={onRequestAddPickupLocation}
                    size="small"
                    style={{
                      position: "absolute",
                      right: theme.spacing(SPACING.SMALL),
                      top: theme.spacing(SPACING.SMALL),
                    }}
                  >
                    <PlusIcon />
                  </Fab>
                )}
              </div>
            ) : null}
            <CardHeader
              title={
                <FormattedMessage
                  defaultMessage="Afhentning"
                  id="pickup-delivery-location-block.header.pickup"
                />
              }
            />
            <CardContent style={{minHeight: 100, paddingBottom: 0}}>
              {sortedPickupLocationEntries.length === 0 ? (
                <div style={{color: theme.palette.grey[500]}}>
                  <FormattedMessage defaultMessage="Der er ingen afhentningssteder på denne opgave" />
                </div>
              ) : null}
              {sortedPickupLocationEntries.map(([identifier, entry], index) => (
                <LocationButton
                  count={locationCounts.get(identifier) || 0}
                  customerSettings={customerSettings}
                  disabled={completedPickupLocations.has(identifier) || readonly}
                  editable={!readonly}
                  fieldNotesPerLocation={fieldNotesPerLocation}
                  index={index}
                  inputSpecificationsMap={inputSpecificationsMap}
                  key={identifier}
                  location={entry.location && locationLookup(entry.location)}
                  locationData={
                    logSpecification.showLocationMaterialSums
                      ? Object.values(reportingLog).filter((data) => data.location === identifier)
                      : []
                  }
                  logSpecification={logSpecification}
                  onClick={onLocationButtonClick}
                  onDragDrop={onReorderPickupLocations}
                  onEditLocationButtonClick={onEditLocationButtonClick}
                  reportingLocation={entry}
                  reportingLocationIdentifier={identifier}
                  swappingLocations={swappingLocations}
                  task={task}
                  type="pickup"
                />
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card style={{overflow: "visible"}}>
            {customerSettings.enableTaskLogLocationChange ? (
              <div style={{position: "relative"}}>
                {!bowser.mobile && !bowser.tablet ? (
                  <Fab
                    disabled={readonly}
                    onClick={onSwapButtonClick}
                    size="small"
                    style={{
                      position: "absolute",
                      right: 64,
                      top: theme.spacing(SPACING.SMALL),
                    }}
                  >
                    <SwapVerticalIcon />
                  </Fab>
                ) : null}
                {customerSettings.addEditLogLocationSkipsCustomerSelection ? (
                  <AddReportingLocationsFab
                    disabled={readonly}
                    logSpecification={logSpecification}
                    task={task}
                    type="delivery"
                  />
                ) : (
                  <Fab
                    disabled={readonly}
                    onClick={onRequestAddDeliveryLocation}
                    size="small"
                    style={{
                      position: "absolute",
                      right: theme.spacing(SPACING.SMALL),
                      top: theme.spacing(SPACING.SMALL),
                    }}
                  >
                    <PlusIcon />
                  </Fab>
                )}
              </div>
            ) : null}
            <CardHeader
              title={
                <FormattedMessage
                  defaultMessage="Levering"
                  id="pickup-delivery-location-block.header.delivery"
                />
              }
            />
            <CardContent style={{minHeight: 100, paddingBottom: 0}}>
              {sortedDeliveryLocationEntries.length === 0 ? (
                <div style={{color: theme.palette.grey[500]}}>
                  <FormattedMessage defaultMessage="Der er ingen leveringsteder på denne opgave" />
                </div>
              ) : null}
              {sortedDeliveryLocationEntries.map(([identifier, entry], index) => (
                <LocationButton
                  count={locationCounts.get(identifier) || 0}
                  customerSettings={customerSettings}
                  disabled={completedDeliveryLocations.has(identifier) || readonly}
                  editable={!readonly}
                  fieldNotesPerLocation={fieldNotesPerLocation}
                  index={index}
                  inputSpecificationsMap={inputSpecificationsMap}
                  key={identifier}
                  location={entry.location && locationLookup(entry.location)}
                  locationData={
                    logSpecification.showLocationMaterialSums
                      ? Object.values(reportingLog).filter((data) => data.location === identifier)
                      : []
                  }
                  logSpecification={logSpecification}
                  onClick={onLocationButtonClick}
                  onDragDrop={onReorderDeliveryLocations}
                  onEditLocationButtonClick={onEditLocationButtonClick}
                  reportingLocation={entry}
                  reportingLocationIdentifier={identifier}
                  swappingLocations={swappingLocations}
                  task={task}
                  type="delivery"
                />
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
});
