import {Config} from "@co-common-libs/config";
import {
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  EmployeeGroupUrl,
  FieldUse,
  Location,
  LocationUrl,
  Order,
  OrderUrl,
  Project,
  ProjectUrl,
  Task,
  Timer,
  TimerStart,
  TimerUrl,
  urlToId,
  User,
  UserProfile,
  UserUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {dateToString, defaultToEmptyString, formatDate} from "@co-common-libs/utils";
import {ColumnSpecifications, RowData} from "@co-frontend-libs/components";
import {makeQuery, Query} from "@co-frontend-libs/db-resources";
import {
  actions,
  AppState,
  getCultureLookup,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getOrderLookup,
  getPathName,
  getProjectLookup,
  getTaskArray,
  getTimerArray,
  getTimerStartArray,
  getUserLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {ConnectedTableWithPagination, PaginationPageSize} from "app-components";
import {
  dateFromDateAndTime,
  getBreakTimer,
  getReferenceNumberLabel,
  willTaskBeRecorded,
} from "app-utils";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import memoize from "memoize-one";
import React from "react";
import {defineMessages, IntlContext, IntlShape} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {generateVariableBookeepingInfo, generateVariableBookeepingInfoHeader} from "./utils";

const messages = defineMessages({
  approved: {
    defaultMessage: "Godk.",
    id: "task-list.table-header.approved",
  },
  completed: {
    defaultMessage: "Fuldf.",
    id: "task-list.table-header.completed",
  },
  date: {
    defaultMessage: "Dato",
    id: "task-list.table-header.date",
  },
  departments: {
    defaultMessage: "Afd.",
  },
  employee: {
    defaultMessage: "Medarb.",
    id: "task-list.table-header.employee",
  },
  open: {
    defaultMessage: "Uafsluttede",
    id: "task-list.table-header.open",
  },
  posted: {
    defaultMessage: "Bogf.",
    id: "task-list.table-header.posted",
  },
});

export type BookkeepingTableFieldID =
  | "approved"
  | "completed"
  | "customerCultureProjectWorkplace"
  | "date"
  | "dateRaw"
  | "departments"
  | "machineOperatorInitials"
  | "machineOperatorName"
  | "machineOperatorURL"
  | "open"
  | "posted"
  | "referenceNumbers"
  | "total";

export type BookkeepingTableColumnID =
  | "approved"
  | "completed"
  | "customerCultureProjectWorkplace"
  | "customerCultureProjectWorkplaceMobile"
  | "date"
  | "dateMobile"
  | "departments"
  | "employee"
  | "employeeName"
  | "open"
  | "posted"
  | "referenceNumber";

export interface BookkeepingTableDataType extends RowData<BookkeepingTableFieldID, string> {
  approved: number;
  completed: number;
  customerCultureProjectWorkplace: string;
  date: string;
  dateRaw: string;
  departments: string;
  machineOperatorInitials: string;
  machineOperatorName: string;
  machineOperatorURL: string;
  open: number;
  posted: number;
  referenceNumbers: string;
  total: number;
}

export function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onClick: (dateAndMachineOperator: string) => void,
  customerSettings: Config,
): ColumnSpecifications<
  BookkeepingTableFieldID,
  BookkeepingTableColumnID,
  string,
  BookkeepingTableDataType
> {
  const {
    enableOrderReferenceNumber,
    enableTaskReferenceNumber,
    orderReferenceNumberLabel,
    taskReferenceNumberLabel,
  } = customerSettings;
  return {
    approved: {
      field: "approved",
      label: formatMessage(messages.approved),
      onClick,
      width: 100,
    },
    completed: {
      field: "completed",
      label: formatMessage(messages.completed),
      onClick,
      width: 100,
    },
    customerCultureProjectWorkplace: {
      field: "customerCultureProjectWorkplace",
      label: generateVariableBookeepingInfoHeader(formatMessage, customerSettings),
      onClick,
    },
    customerCultureProjectWorkplaceMobile: {
      field: "customerCultureProjectWorkplace",
      label: generateVariableBookeepingInfoHeader(formatMessage, customerSettings),
      onClick,
      width: 180,
    },
    date: {
      field: "date",
      label: formatMessage(messages.date),
      onClick,
      sortField: "dateRaw",
      width: 150,
    },
    dateMobile: {
      field: "date",
      label: formatMessage(messages.date),
      onClick,
      sortField: "dateRaw",
      width: 70,
    },
    departments: {
      field: "departments",
      label: formatMessage(messages.departments),
      onClick,
    },
    employee: {
      field: "machineOperatorInitials",
      label: formatMessage(messages.employee),
      onClick,
      width: 120,
    },
    employeeName: {
      field: "machineOperatorName",
      label: formatMessage(messages.employee),
      onClick,
    },
    open: {
      field: "open",
      label: formatMessage(messages.open),
      onClick,
      width: 120,
    },
    posted: {
      field: "posted",
      label: formatMessage(messages.posted),
      onClick,
      width: 100,
    },
    referenceNumber: {
      field: "referenceNumbers",
      label: getReferenceNumberLabel(
        enableTaskReferenceNumber,
        taskReferenceNumberLabel,
        enableOrderReferenceNumber,
        orderReferenceNumberLabel,
        formatMessage,
      ),
      onClick,
    },
  };
}

export function computeVisibleColumns(
  mobile: boolean,
  tablet: boolean,
  bookkeepingListColumns: {
    readonly desktop: readonly BookkeepingTableColumnID[];
    readonly mobile: readonly BookkeepingTableColumnID[];
    readonly tablet: readonly BookkeepingTableColumnID[];
  },
): readonly BookkeepingTableColumnID[] {
  const deviceType = mobile ? "mobile" : tablet ? "tablet" : "desktop";
  return bookkeepingListColumns[deviceType];
}

function buildRowData(
  taskArray: readonly Task[],
  selectedDepartmentIdentifierSet: ReadonlySet<string>,
  selectedEmployeeGroupUrlSet: ReadonlySet<EmployeeGroupUrl>,
  selectedWorkTypeURLSet: ReadonlySet<WorkTypeUrl>,
  customerSettings: Config,
  cultureLookup: (url: CultureUrl) => Culture | undefined,
  locationLookup: (url: LocationUrl) => Location | undefined,
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  orderLookup: (url: OrderUrl) => Order | undefined,
  projectLookup: (url: ProjectUrl) => Project | undefined,
  userLookup: (url: UserUrl) => User | undefined,
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined,
  timerStartArray: readonly TimerStart[],
  breakTimer: TimerUrl | undefined,
): readonly BookkeepingTableDataType[] {
  const {
    enableOrderReferenceNumber,
    enableTaskReferenceNumber,
    recordCultureTasks,
    recordCustomerTasks,
    recordInternalTasks,
    useApproveReport,
  } = customerSettings;
  // date -> userURL -> data
  const counts = new Map<
    string,
    Map<
      UserUrl,
      {
        approved: number;
        completed: number;
        customerCultureProjectWorkplace: {
          cultureURL: CultureUrl | null;
          customerURL: CustomerUrl | null;
          fielduseSet: readonly FieldUse[];
          pickupLocationURL: LocationUrl | null;
          projectURL: ProjectUrl | null;
          task: Task;
          workplaceURL: LocationUrl | null;
        }[];
        departments: string[];
        open: number;
        posted: number;
        referenceNumbers: string[];
        total: number;
      }
    >
  >();
  const now = new Date();
  const defaultTaskEmployeeUrl = customerSettings.defaultTaskEmployee
    ? instanceURL("user", customerSettings.defaultTaskEmployee)
    : null;
  taskArray
    .filter((task) => {
      if (selectedEmployeeGroupUrlSet.size && task.machineOperator) {
        const employeeGroupUrl = defaultToEmptyString(
          userUserProfileLookup(task.machineOperator)?.employeeGroup,
        );
        if (!selectedEmployeeGroupUrlSet.has(employeeGroupUrl)) {
          return false;
        }
      }
      if (selectedWorkTypeURLSet.size) {
        if (!(task.workType && selectedWorkTypeURLSet.has(task.workType))) {
          return false;
        }
      }
      if (selectedDepartmentIdentifierSet.size) {
        if (!(task.department && selectedDepartmentIdentifierSet.has(task.department))) {
          return false;
        }
      }
      return true;
    })

    .forEach((task) => {
      const {workFromTimestamp} = task;
      const date = workFromTimestamp ? dateToString(new Date(workFromTimestamp)) : task.date;

      if (
        !date ||
        !task.machineOperator ||
        task.machineOperator === defaultTaskEmployeeUrl ||
        dateFromDateAndTime(date, "00:00") > now
      ) {
        return;
      }

      const machineOperatorURL = task.machineOperator;
      let dateCounts = counts.get(date);
      if (!dateCounts) {
        dateCounts = new Map();
        counts.set(date, dateCounts);
      }

      let machineOperatorDateCounts = dateCounts.get(machineOperatorURL);
      if (!machineOperatorDateCounts) {
        machineOperatorDateCounts = {
          approved: 0,
          completed: 0,
          customerCultureProjectWorkplace: [],
          departments: [],
          open: 0,
          posted: 0,
          referenceNumbers: [],
          total: 0,
        };
        dateCounts.set(machineOperatorURL, machineOperatorDateCounts);
      }
      if (task.completed) {
        machineOperatorDateCounts.completed += 1;
      } else {
        machineOperatorDateCounts.open += 1;
      }
      if (task.validatedAndRecorded || (useApproveReport && task.reportApproved)) {
        machineOperatorDateCounts.approved += 1;
      }

      const orderURL = task.order;
      const order = orderURL ? orderLookup(orderURL) : undefined;
      if (task.recordedInC5) {
        machineOperatorDateCounts.posted += 1;
      } else {
        if (
          (!recordInternalTasks || !recordCustomerTasks || !recordCultureTasks) &&
          task.validatedAndRecorded
        ) {
          if (!willTaskBeRecorded(task, order, customerSettings, timerStartArray, breakTimer)) {
            machineOperatorDateCounts.posted += 1;
          }
        }
      }
      machineOperatorDateCounts.total += 1;
      const projectURL = task.project || null;
      const workplaceURL = task.relatedWorkplace || null;
      const customerURL = (order && order.customer) || null;
      const cultureURL = (order && order.culture) || null;
      const pickupLocationURL = task.relatedPickupLocation || null;
      const {fielduseSet} = task;

      machineOperatorDateCounts.customerCultureProjectWorkplace.push({
        cultureURL,
        customerURL,
        fielduseSet,
        pickupLocationURL,
        projectURL,
        task,
        workplaceURL,
      });
      if (enableOrderReferenceNumber) {
        if (order && order.referenceNumber) {
          machineOperatorDateCounts.referenceNumbers.push(order.referenceNumber);
        }
      } else if (enableTaskReferenceNumber) {
        machineOperatorDateCounts.referenceNumbers.push(task.referenceNumber);
      }
      machineOperatorDateCounts.departments.push(task.department);
    });
  const result: BookkeepingTableDataType[] = [];

  const getDepartmentLabel = (departmentID: string): string =>
    customerSettings.departments[departmentID] || departmentID;

  counts.forEach((dateData, date) => {
    dateData.forEach((data, machineOperatorURL) => {
      const {
        approved,
        completed,
        customerCultureProjectWorkplace,
        departments,
        open,
        posted,
        referenceNumbers,
        total,
      } = data;
      if (completed || posted || open) {
        const machineOperator = userLookup(machineOperatorURL);
        if (!machineOperator) {
          return;
        }
        const profile = userUserProfileLookup(machineOperatorURL);

        const customerCultureProjectWorkplaceData = customerCultureProjectWorkplace.map((entry) =>
          generateVariableBookeepingInfo(entry, {
            cultureLookup,
            customerLookup,
            customerSettings,
            locationLookup,
            projectLookup,
          }).join(", "),
        );

        const departmentLabels = [...new Set(departments)].map((department) =>
          getDepartmentLabel(department),
        );
        departmentLabels.sort();
        const entry: BookkeepingTableDataType = {
          approved,
          completed,
          customerCultureProjectWorkplace: customerCultureProjectWorkplaceData.join("\n"),
          date: formatDate(date),
          dateRaw: date,
          departments: departmentLabels.join(", "),
          key: `${date}:${urlToId(machineOperatorURL)}`,
          machineOperatorInitials: profile ? profile.alias : "",
          machineOperatorName: profile ? profile.name : "",
          machineOperatorURL,
          open,
          posted,
          referenceNumbers: referenceNumbers.join("\n"),
          total,
        };
        result.push(entry);
      }
    });
  });
  return _.sortBy(result, [(entry) => entry.date, (entry) => entry.machineOperatorInitials]);
}

const someWaitingForBilling = (entry: BookkeepingTableDataType): boolean =>
  entry.posted < entry.approved;

const createSomeWaitingForValidationFilterPredicate =
  (today: string): ((entry: BookkeepingTableDataType) => boolean) =>
  (entry: BookkeepingTableDataType): boolean =>
    entry.approved < entry.completed || (entry.open > 0 && entry.dateRaw !== today);

function filterData(
  entries: readonly BookkeepingTableDataType[],
  tab: "all" | "readyForBilling" | "readyForValidation",
): readonly BookkeepingTableDataType[] {
  let filterPredicate: ((entry: BookkeepingTableDataType) => boolean) | undefined;

  if (tab === "readyForBilling") {
    filterPredicate = someWaitingForBilling;
  } else if (tab === "readyForValidation") {
    const today = dateToString(new Date());
    filterPredicate = createSomeWaitingForValidationFilterPredicate(today);
  }
  const filteredEntries = filterPredicate ? entries.filter(filterPredicate) : entries;
  return filteredEntries;
}

function filteringData(
  filterString: string,
  tab: "all" | "readyForBilling" | "readyForValidation",
): Record<string, unknown> {
  return {
    filterString,
    tab,
  };
}

interface BookkeepingTableStateProps {
  cultureLookup: (url: CultureUrl) => Culture | undefined;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  pathName: string;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  taskArray: readonly Task[];
  timerArray: readonly Timer[];
  timerStartArray: readonly TimerStart[];
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface BookkeepingTableDispatchProps {
  temporaryQueriesDiscardedForPath: (pathName: string, key: string) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
}

interface BookkeepingTableOwnProps {
  filterString: string;
  onClick: (dateAndMachineOperator: string) => void;
  selectedDepartmentIdentifierSet: ReadonlySet<string>;
  selectedEmployeeGroupUrlSet: ReadonlySet<EmployeeGroupUrl>;
  selectedWorkTypeURLSet: ReadonlySet<WorkTypeUrl>;
  tab: "readyForBilling" | "readyForValidation";
}

type BookkeepingTableProps = BookkeepingTableDispatchProps &
  BookkeepingTableOwnProps &
  BookkeepingTableStateProps;

interface BookkeepingTableState {
  dateSet: ReadonlySet<string> | null;
  initialsSet: ReadonlySet<string> | null;
  query: Query | null;
}

const TEMPORARY_QUERIES_KEY = "BookkeepingTable";

class BookkeepingTable extends React.PureComponent<BookkeepingTableProps, BookkeepingTableState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: BookkeepingTableState = {
    dateSet: null,
    initialsSet: null,
    query: null,
  };
  private buildColumnSpecifications: (
    formatMessage: IntlShape["formatMessage"],
    onClick: (dateAndMachineOperator: string) => void,
    customerSettings: Config,
  ) => ColumnSpecifications<
    BookkeepingTableFieldID,
    BookkeepingTableColumnID,
    string,
    BookkeepingTableDataType
  >;
  private buildRowData: (
    taskArray: readonly Task[],
    selectedDepartmentIdentifierSet: ReadonlySet<string>,
    selectedEmployeeGroupUrlSet: ReadonlySet<EmployeeGroupUrl>,
    selectedWorkTypeURLSet: ReadonlySet<WorkTypeUrl>,
    customerSettings: Config,
    cultureLookup: (url: CultureUrl) => Culture | undefined,
    locationLookup: (url: LocationUrl) => Location | undefined,
    customerLookup: (url: CustomerUrl) => Customer | undefined,
    orderLookup: (url: OrderUrl) => Order | undefined,
    projectLookup: (url: ProjectUrl) => Project | undefined,
    userLookup: (url: UserUrl) => User | undefined,
    userUserProfileLookup: (url: UserUrl) => UserProfile | undefined,
    timerStartArray: readonly TimerStart[],
    breakTimer: TimerUrl | undefined,
  ) => readonly BookkeepingTableDataType[];
  private computeVisibleColumns: (
    mobile: boolean,
    tablet: boolean,
    bookkeepingListColumns: {
      readonly desktop: readonly BookkeepingTableColumnID[];
      readonly mobile: readonly BookkeepingTableColumnID[];
      readonly tablet: readonly BookkeepingTableColumnID[];
    },
  ) => readonly BookkeepingTableColumnID[];
  private filterData: (
    entries: readonly BookkeepingTableDataType[],
    tab: "all" | "readyForBilling" | "readyForValidation",
  ) => readonly BookkeepingTableDataType[];
  private filteringData: (
    filterString: string,
    tab: "all" | "readyForBilling" | "readyForValidation",
  ) => Record<string, unknown>;
  constructor(props: BookkeepingTableProps) {
    super(props);
    this.buildColumnSpecifications = memoize(buildColumnSpecifications);
    this.computeVisibleColumns = memoize(computeVisibleColumns);
    this.buildRowData = memoize(buildRowData);
    this.filterData = memoize(filterData);
    this.filteringData = memoize(filteringData);
  }
  componentDidMount(): void {
    this.updateNormalQuery();
  }
  componentDidUpdate(
    _prevProps: BookkeepingTableProps,
    _prevState: BookkeepingTableState,
    _snapshot: unknown,
  ): void {
    this.updateNormalQuery();
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      cultureLookup,
      customerLookup,
      customerSettings,
      filterString,
      locationLookup,
      onClick,
      orderLookup,
      projectLookup,
      selectedDepartmentIdentifierSet,
      selectedEmployeeGroupUrlSet,
      selectedWorkTypeURLSet,
      tab,
      taskArray,
      timerArray,
      timerStartArray,
      userLookup,
      userUserProfileLookup,
    } = this.props;
    const breakTimer = getBreakTimer(timerArray);
    const {bookkeepingListColumns} = customerSettings;
    const columnSpecifications = this.buildColumnSpecifications(
      formatMessage,
      onClick,
      customerSettings,
    );
    const visibleColumns = this.computeVisibleColumns(
      !!bowser.mobile,
      !!bowser.tablet,
      bookkeepingListColumns,
    );
    const data = this.buildRowData(
      taskArray,
      selectedDepartmentIdentifierSet,
      selectedEmployeeGroupUrlSet,
      selectedWorkTypeURLSet,
      customerSettings,
      cultureLookup,
      locationLookup,
      customerLookup,
      orderLookup,
      projectLookup,
      userLookup,
      userUserProfileLookup,
      timerStartArray,
      breakTimer?.url,
    );
    const filteredData = this.filterData(data, tab);

    return (
      <ConnectedTableWithPagination
        columns={columnSpecifications}
        defaultRowsPerPage={PaginationPageSize.SMALL}
        defaultSortDirection="ASC"
        defaultSortKey="date"
        entries={filteredData}
        filteringData={this.filteringData(filterString, tab)}
        filterString={filterString}
        savePaginationIdentifier="BookkeepingTable"
        saveSortingIdentifier={`BookkeepingTable${_.upperFirst(tab)}`}
        visibleColumns={visibleColumns}
      />
    );
  }
  private getDatesInitials(): {
    dateSet: ReadonlySet<string>;
    initialsSet: ReadonlySet<string>;
  } {
    const {
      cultureLookup,
      customerLookup,
      customerSettings,
      locationLookup,
      orderLookup,
      projectLookup,
      selectedDepartmentIdentifierSet,
      selectedEmployeeGroupUrlSet,
      selectedWorkTypeURLSet,
      tab,
      taskArray,
      timerArray,
      timerStartArray,
      userLookup,
      userUserProfileLookup,
    } = this.props;
    const breakTimer = getBreakTimer(timerArray);
    const data = this.buildRowData(
      taskArray,
      selectedDepartmentIdentifierSet,
      selectedEmployeeGroupUrlSet,
      selectedWorkTypeURLSet,
      customerSettings,
      cultureLookup,
      locationLookup,
      customerLookup,
      orderLookup,
      projectLookup,
      userLookup,
      userUserProfileLookup,
      timerStartArray,
      breakTimer?.url,
    );
    const filteredData = this.filterData(data, tab);
    const dateSet = new Set<string>();
    const initialsSet = new Set<string>();
    filteredData.forEach((entry) => {
      dateSet.add(entry.dateRaw);
      initialsSet.add(entry.machineOperatorInitials);
    });
    return {dateSet, initialsSet};
  }

  private updateNormalQuery(): void {
    const {dateSet, initialsSet} = this.getDatesInitials();
    if (_.isEqual(dateSet, this.state.dateSet) && _.isEqual(initialsSet, this.state.initialsSet)) {
      return;
    }
    this.setState({dateSet, initialsSet});
    const {pathName, temporaryQueriesDiscardedForPath, temporaryQueriesRequestedForPath} =
      this.props;
    if (dateSet.size && initialsSet.size) {
      const bookkeepingDate = Array.from(dateSet).sort().join(",");
      const initials = Array.from(initialsSet).sort().join(",");
      const query = makeQuery({
        check: {type: "alwaysOk"},
        filter: {
          archived: "",
          bookkeepingDate,
          initials,
        },
        independentFetch: true,
        resourceName: "task",
      });
      // dateSet and/or initialsSet different; query *must* be different...
      console.assert(this.state.query?.keyString !== query.keyString);
      temporaryQueriesRequestedForPath([query], pathName, TEMPORARY_QUERIES_KEY);
      this.setState({query});
    } else {
      if (this.state.query) {
        temporaryQueriesDiscardedForPath(pathName, TEMPORARY_QUERIES_KEY);
        this.setState({query: null});
      }
    }
  }
}

const ConnectedBookkeepingTable: React.ComponentType<BookkeepingTableOwnProps> = connect<
  BookkeepingTableStateProps,
  BookkeepingTableDispatchProps,
  BookkeepingTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, BookkeepingTableStateProps>({
    cultureLookup: getCultureLookup,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    orderLookup: getOrderLookup,
    pathName: getPathName,
    projectLookup: getProjectLookup,
    taskArray: getTaskArray,
    timerArray: getTimerArray,
    timerStartArray: getTimerStartArray,
    userLookup: getUserLookup,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {
    temporaryQueriesDiscardedForPath: actions.temporaryQueriesDiscardedForPath,
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
  },
)(BookkeepingTable);

export {ConnectedBookkeepingTable as BookkeepingTable};
