import {Config} from "@co-common-libs/config";
import {
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  Product,
  ProductUrl,
  ProductUse,
  ReportingLog,
  ReportingSpecification,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitString, getValue, priceItemIsVisible} from "@co-common-libs/resources-utils";
import _ from "lodash";
import memoizeOne from "memoize-one";
import React from "react";
import {RotatedTableCell} from "../rotated-table-cell";
import {RotatedTableHeaderColumn} from "./tables/legacy-log-table";

export const getRelationCountValue = (
  priceItemRelationUnitConversionHelpers: {
    readonly [x: string]: readonly string[];
  },
  conversionRelatedValues: {
    readonly [unit: string]: number | null;
  } | null,
  materialDecimals: number,
  inputUnitName: string,
  countValue: number,
): number | null => {
  let relationConversionValue: number | null | undefined;

  if (conversionRelatedValues) {
    for (const [baseUnit, targetUnits] of Object.entries(priceItemRelationUnitConversionHelpers)) {
      if (conversionRelatedValues[baseUnit] !== undefined && targetUnits.includes(inputUnitName)) {
        relationConversionValue = conversionRelatedValues[baseUnit];
        break;
      }
    }
  }
  return relationConversionValue && countValue != null
    ? _.round(countValue / relationConversionValue, materialDecimals)
    : null;
};

export const getDataColumns = (
  logSpecification: ReportingSpecification,
): [React.JSX.Element[], Map<string, number>, boolean] => {
  const logHeaderColumns: React.JSX.Element[] = [];
  const logColumnPositions = new Map<string, number>();
  let includePriceItems = false;
  (["workplace", "pickup", "delivery"] as const).forEach(
    (type: "delivery" | "pickup" | "workplace") => {
      const workplaceData = logSpecification.workplaceData && logSpecification.workplaceData[type];

      includePriceItems = includePriceItems || !!workplaceData?.logPriceItems;

      const logInputSpecifications = workplaceData && workplaceData.logInputs;
      if (logInputSpecifications) {
        logInputSpecifications.forEach((inputSpecification) => {
          const {identifier} = inputSpecification;
          if (logColumnPositions.has(identifier)) {
            return;
          }
          const position = logHeaderColumns.length;
          logColumnPositions.set(identifier, position);
          let {label} = inputSpecification;
          const {unit} = inputSpecification;
          if (unit) {
            label = `${label}, ${unit}`;
          }
          logHeaderColumns.push(<RotatedTableCell key={identifier}>{label}</RotatedTableCell>);
        });
      }
    },
  );
  return [logHeaderColumns, logColumnPositions, includePriceItems];
};

export const getMaterialColumns = memoizeOne(
  (
    priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
    unitLookup: (url: UnitUrl) => Unit | undefined,
    includeMaterials: boolean,
    taskPriceItemUseList: readonly PriceItemUse[],
    includeProducts: boolean,
    taskProductUseList: readonly ProductUse[],
    productLookup: (url: ProductUrl) => Product | undefined,
    logData: ReportingLog | undefined,
  ): {
    materialColumnPositions: Map<string, number>;
    materialHeaderColumns: React.JSX.Element[];
  } => {
    const materialHeaderColumns: React.JSX.Element[] = [];
    const materialColumnPositions = new Map<string, number>();
    if (includeMaterials) {
      const priceItemsNonZeroInLog = new Set<string>();
      if (logData) {
        for (const entry of Object.values(logData)) {
          if (entry.priceItemUses) {
            for (const priceItemUse of Object.values(entry.priceItemUses)) {
              if (priceItemUse.count) {
                priceItemsNonZeroInLog.add(priceItemUse.priceItem);
              }
            }
          }
        }
      }
      taskPriceItemUseList.forEach((priceItemUse) => {
        const priceItemURL = priceItemUse.priceItem;
        if (!priceItemsNonZeroInLog.has(priceItemURL)) {
          return;
        }
        const priceItem = priceItemLookup(priceItemURL);
        if (
          !priceItem ||
          !priceItemIsVisible(
            priceItem,
            false,
            taskPriceItemUseList,
            unitLookup,
            priceItemLookup,
          ) ||
          !priceItem?.includeInLogs
        ) {
          return;
        }
        const {name} = priceItem;
        const unit = getUnitString(priceItem, unitLookup);
        const position = materialHeaderColumns.length;
        materialColumnPositions.set(priceItemURL, position);
        materialHeaderColumns.push(
          <RotatedTableHeaderColumn key={priceItemURL}>
            {name} {unit}
          </RotatedTableHeaderColumn>,
        );
      });
    }
    if (includeProducts) {
      const productsNonZeroInLog = new Set<string>();
      if (logData) {
        for (const entry of Object.values(logData)) {
          if (entry.productUses) {
            for (const productUse of Object.values(entry.productUses)) {
              if (productUse.count) {
                productsNonZeroInLog.add(productUse.product);
              }
            }
          }
        }
      }
      taskProductUseList.forEach((productUse) => {
        const productURL = productUse.product;
        if (!productsNonZeroInLog.has(productURL)) {
          return;
        }
        if (materialColumnPositions.has(productURL)) {
          // only display once in log table; even if multiple on task;
          // e.g. from ours/theirs variants in product table...
          return;
        }
        const product = productLookup(productURL);
        if (!product) {
          return;
        }
        const {name} = product;
        const unit = getUnitString(product, unitLookup);
        const position = materialHeaderColumns.length;
        materialColumnPositions.set(productURL, position);
        materialHeaderColumns.push(
          <RotatedTableHeaderColumn key={productURL}>
            {name} {unit}
          </RotatedTableHeaderColumn>,
        );
      });
    }

    return {materialColumnPositions, materialHeaderColumns};
  },
);

export const getLocationDataColumns = (
  logSpecification: any,
): [React.JSX.Element[], Map<string, number>] => {
  const locationHeaderColumns: React.JSX.Element[] = [];
  const locationColumnPositions = new Map<string, number>();
  ["workplace", "pickup", "delivery"].forEach((type) => {
    const worktypeLogInputSpecifications =
      logSpecification.workplaceData &&
      logSpecification.workplaceData[type] &&
      logSpecification.workplaceData[type].inputs;
    if (worktypeLogInputSpecifications) {
      worktypeLogInputSpecifications.forEach((inputSpecification: any) => {
        const {identifier} = inputSpecification;
        if (locationColumnPositions.has(identifier)) {
          return;
        }
        const position = locationHeaderColumns.length;
        locationColumnPositions.set(identifier, position);
        let {label} = inputSpecification;
        const {unit} = inputSpecification;
        if (unit) {
          label = `${label}, ${unit}`;
        }
        locationHeaderColumns.push(
          <RotatedTableHeaderColumn key={identifier}>{label}</RotatedTableHeaderColumn>,
        );
      });
    }
  });

  return [locationHeaderColumns, locationColumnPositions];
};

/** @deprecated */
export function computeLegacyLogSums(
  customerSettings: Config,

  logData: any,

  reportingLocationDataMapping: any,

  summedIdentifierLabels: any,

  inputSpecificationsMap: any,
): Map<string, number> {
  const sums = new Map<string, number>();
  if (logData) {
    logData.forEach((data: any) => {
      const locationID = data.location;
      const {type} = data;
      const locationDataEntry: any = _.get(reportingLocationDataMapping, [type, locationID]);
      if (!locationDataEntry) {
        return;
      }
      const locationData = locationDataEntry[1];
      const {values} = data;
      const locationValues = locationData.values;
      const valueMaps = [values, locationValues];
      const localGetValue = getValue.bind(
        null,
        customerSettings,
        valueMaps,
        inputSpecificationsMap,
      );

      summedIdentifierLabels.forEach((_label: string, identifier: string) => {
        const value = localGetValue(identifier);
        if (typeof value === "number") {
          sums.set(identifier, (sums.get(identifier) || 0) + value);
        }
      });
    });
  }
  return sums;
}
