import {ResponsiveDialog} from "@co-frontend-libs/components";
import {getCustomerSettings, isEmployeeGroupsActivated} from "@co-frontend-libs/redux";
import {DialogContent, List, ListItem, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

export type FilterTypeChoices = "deparment" | "employeeGroup" | "workType";

const FilterTypeItem = React.memo(function FilterTypeItem({
  filterType,
  name,
  onClick,
}: {
  filterType: FilterTypeChoices;
  name: string;
  onClick: (filterType: FilterTypeChoices) => void;
}): React.JSX.Element {
  const handleClick = useCallback(() => {
    onClick(filterType);
  }, [onClick, filterType]);
  return (
    <ListItem button onClick={handleClick}>
      <ListItemText>{name}</ListItemText>
    </ListItem>
  );
});

export const FilterTypeSelectionDialog = React.memo(function FilterTypeSelectionDialog({
  onCancel,
  onOk,
  open,
}: {
  onCancel?: () => void;
  onOk: (filterType: FilterTypeChoices) => void;
  open: boolean;
}): React.JSX.Element {
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);

  const activateEmployeeGroups = useSelector(isEmployeeGroupsActivated);

  return (
    <ResponsiveDialog
      onCancel={onCancel}
      open={open}
      title={<FormattedMessage defaultMessage="Vælg filtrering" />}
    >
      <DialogContent style={{padding: 0}}>
        <List>
          {customerSettings.enableOrderTaskWorkTypeFilter && (
            <FilterTypeItem
              filterType="workType"
              name={intl.formatMessage({defaultMessage: "Arbejdsområder"})}
              onClick={onOk}
            />
          )}
          {customerSettings.enableOrderTaskDepartmentFilter && (
            <FilterTypeItem
              filterType="deparment"
              name={intl.formatMessage({defaultMessage: "Afdelinger"})}
              onClick={onOk}
            />
          )}

          {activateEmployeeGroups && (
            <FilterTypeItem
              filterType="employeeGroup"
              name={intl.formatMessage({defaultMessage: "Medarbejdergrupper"})}
              onClick={onOk}
            />
          )}
        </List>
      </DialogContent>
    </ResponsiveDialog>
  );
});
