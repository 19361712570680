import {getSettingsEntryLookupByIdentifier} from "@co-frontend-libs/redux";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {KeyValueSetting} from "./key-value-setting";

export const defaultAbsenceTypeLabels = {
  childsFirstSickDay: "Barns første sygedag",
  compensatory: "Afspadsering",
  daysOffWithoutPay: "Fridage uden løn",
  floatingHoliday: "Ferie-fridage",
  sickLeave: "Sygefravær",
  timeOff: "FRI",
  vacation: "Feriedage",
};

export function AbsenceTypeLabels(props: SettingViewProps): React.JSX.Element {
  const {settingID, settingMetaData} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const absenceTypeLabels: {[label: string]: string} = settingEntry?.data || {};

  const usedAbsenceTypeLabels: {[key: string]: string} = {
    ...defaultAbsenceTypeLabels,
    ...absenceTypeLabels,
  };

  const intl = useIntl();

  return (
    <KeyValueSetting
      data={usedAbsenceTypeLabels}
      disableKeyEdit={Object.keys(defaultAbsenceTypeLabels)}
      keyLabel={intl.formatMessage({defaultMessage: "Fraværstype"})}
      settingID={settingID}
      settingMetaData={settingMetaData}
      title={intl.formatMessage({defaultMessage: "Fraværstyper"})}
      valueLabel={intl.formatMessage({defaultMessage: "Label"})}
    />
  );
}
