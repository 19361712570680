import {
  daySeconds,
  HOUR_SECONDS,
  hourMinutesStringDaySeconds,
  MINUTE_SECONDS,
  objectSet,
} from "@co-common-libs/utils";
import {SimpleInterval} from "./types";

export function applyWorkPeriodDayStartRounding<T extends SimpleInterval>(
  intervals: readonly T[],
  roundingStartSeconds: number,
  roundingEndSeconds: number,
): readonly T[] {
  if (!intervals.length) {
    return intervals;
  }
  const firstFromSeconds = daySeconds(new Date(intervals[0].fromTimestamp));
  if (roundingStartSeconds <= firstFromSeconds && firstFromSeconds < roundingEndSeconds) {
    // The first interval starts within the rounding period.
    // Filter any entirely within the rounding period.
    const indexAfter = intervals.findIndex((interval) => {
      const fromSeconds = daySeconds(new Date(interval.fromTimestamp));
      const toSeconds = daySeconds(new Date(interval.toTimestamp));
      // There needs to be at least a full minute after rounding...
      return !(
        roundingStartSeconds <= fromSeconds && toSeconds < roundingEndSeconds + MINUTE_SECONDS
      );
    });
    // We might not find anything after the rounding period...
    if (indexAfter === -1) {
      return [];
    }
    const remainingIntervals = intervals.slice(indexAfter);
    // The resulting first interval *may* start within the rounding period
    // and end after.  Assuming absence of overlap, no subsequent interval
    // should have that property.
    const innerFirstFromSeconds = daySeconds(new Date(remainingIntervals[0].fromTimestamp));
    if (roundingStartSeconds <= innerFirstFromSeconds) {
      const firstInterval = remainingIntervals[0];
      const roundedStartDate = new Date(firstInterval.fromTimestamp);
      const roundingResultHours = Math.floor(roundingEndSeconds / HOUR_SECONDS);
      const roundingResultMinutes = (roundingEndSeconds % HOUR_SECONDS) / MINUTE_SECONDS;
      roundedStartDate.setHours(roundingResultHours, roundingResultMinutes, 0, 0);
      const intervalCopy = objectSet(
        firstInterval,
        "fromTimestamp",
        roundedStartDate.toISOString(),
      );
      remainingIntervals[0] = intervalCopy;
    }
    return remainingIntervals;
  } else {
    return intervals;
  }
}

/** For each "work period"/interval sequence, if the work period *starts*
 * between the times of day specified by `dayStartRounding`, cut off the part
 * between those.
 *
 * Some customers want to specify e.g. that the normal workday starts at 7:00,
 * and anyone clocking in in the last few minutes before that will be paid
 * from 7:00.
 *
 * @param workPeriodsIntervals Array of "work period" arrays.
 * @param dayStartRounding Pair [from-time, to-time].
 */
export function applyDayStartRounding<T extends SimpleInterval>(
  workPeriodsIntervals: readonly (readonly T[])[],
  dayStartRounding: readonly [string, string],
): T[][];
export function applyDayStartRounding<T extends SimpleInterval>(
  workPeriodIntervals: readonly (readonly T[])[],
  dayStartRounding: readonly [string, string],
): readonly (readonly T[])[] {
  const [roundingStartString, roundingEndString] = dayStartRounding;
  const roundingStartSeconds = hourMinutesStringDaySeconds(roundingStartString);
  const roundingEndSeconds = hourMinutesStringDaySeconds(roundingEndString);

  return workPeriodIntervals
    .map((intervals) =>
      applyWorkPeriodDayStartRounding(intervals, roundingStartSeconds, roundingEndSeconds),
    )
    .filter((intervals) => intervals.length > 0);
}
