import {
  ReportingInputSpecification,
  ReportingSpecification,
  ReportingWorkplaceTypeDataSpecification,
  schema as reportSpecificationSchema,
  ResourceInstance,
  TaskDataSpecification,
  WorkplaceDataSpecification,
  WorkplaceRegistration,
} from "@co-common-libs/resources";
import {formatUnitString} from "@co-common-libs/resources-utils";
import {formatDateTime, notNull, notUndefined} from "@co-common-libs/utils";
import {ErrorColorButton, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  actions,
  getMachineArray,
  getPriceGroupArray,
  getUnitArray,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@material-ui/core";
import Ajv from "ajv";
import {useFalseCallback, useTrueCallback} from "app-utils";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {NewVersionDialog} from "./new-version-dialog";

const messages = defineMessages({
  confirmDisable: {
    defaultMessage: "Deaktivér",
    id: "reporting.entry.confirm-disable",
  },
  disableQuestion: {
    defaultMessage: "Er du sikker på at du vil deaktivere denne log?",
    id: "reporting.entry.disable-question",
  },
});
const JSON_INDENTATION_LEVEL = 2;

function checkReportingInputsReferences(
  data: readonly ReportingInputSpecification[],
  otherIdentifiers?: ReadonlySet<string>,
): {
  duplicate: Set<string>;
  missing: Set<string>;
  observed: Set<string>;
  selfReferences: Set<string>;
} {
  const observedArray = data.map(({identifier}) => identifier);
  const observed = new Set(observedArray);
  const duplicate = new Set(
    Object.entries(_.countBy(observedArray))
      .filter(([_identifier, count]) => count > 1)
      .map(([identifier, _count]) => identifier),
  );
  const missing = new Set<string>();
  const selfReferences = new Set<string>();

  data.forEach((entry) => {
    const entryReferences = [
      entry.conversion?.numerator,
      entry.conversion?.denominator,
      entry.subtraction?.minuend,
      entry.subtraction?.subtrahend,
      entry.multiplication?.multiplicand,
      entry.multiplication?.multiplier,
    ].filter(notUndefined);
    if (entryReferences.length) {
      if (entryReferences.includes(entry.identifier)) {
        selfReferences.add(entry.identifier);
      }
      for (const referenced of entryReferences) {
        if (!observed.has(referenced) && !otherIdentifiers?.has(referenced)) {
          missing.add(referenced);
        }
      }
    }
  });
  return {duplicate, missing, observed, selfReferences};
}

function checkReportingWorkplaceTypeDataReferences(
  data: ReportingWorkplaceTypeDataSpecification,
  unitSymbolsLowerCase: ReadonlySet<string>,
): {
  duplicate: Set<string>;
  locationsObserved: Set<string>;
  logObserved: Set<string>;
  missing: Set<string>;
  nonLowerCaseUnits: Set<string>;
  selfReferences: Set<string>;
  unknownUnits: Set<string>;
} {
  // "calculators" specify that for price items/products with given units,
  // we want a button to get a popup to help compute `A - B` with UI labels
  // specified for A and B... (... so *not* references to identifiers...)
  // (... with room for extension to other operations than `-`...)
  //
  // priceItemConversion and productConversion may refer to identifiers from
  // log entry or location
  const locationsCheckResult = data.inputs ? checkReportingInputsReferences(data.inputs) : null;
  const locationsObserved = locationsCheckResult?.observed ?? new Set<string>();
  const logCheckResult = data.logInputs
    ? checkReportingInputsReferences(data.logInputs, locationsObserved)
    : null;
  const logObserved = logCheckResult?.observed ?? new Set<string>();
  const partialResults = [locationsCheckResult, logCheckResult].filter(notNull);
  const duplicate =
    partialResults.length === 1
      ? partialResults[0].duplicate
      : new Set(partialResults.flatMap((entry) => [...entry.duplicate]));
  const missing =
    partialResults.length === 1
      ? partialResults[0].missing
      : new Set(partialResults.flatMap((entry) => [...entry.missing]));
  if (data.priceItemConversion) {
    if (
      !logObserved.has(data.priceItemConversion.field) &&
      !locationsObserved.has(data.priceItemConversion.field)
    ) {
      missing.add(data.priceItemConversion.field);
    }
  }
  if (data.productConversion) {
    if (
      !logObserved.has(data.productConversion.field) &&
      !locationsObserved.has(data.productConversion.field)
    ) {
      missing.add(data.productConversion.field);
    }
  }
  const nonLowerCaseUnits = new Set<string>();
  const unknownUnits = new Set<string>();
  if (data.calculators) {
    for (const unitString of Object.keys(data.calculators)) {
      if (unitString !== unitString.toLocaleLowerCase()) {
        nonLowerCaseUnits.add(unitString);
      } else if (!unitSymbolsLowerCase.has(unitString)) {
        unknownUnits.add(unitString);
      }
    }
  }
  const selfReferences =
    partialResults.length === 1
      ? partialResults[0].selfReferences
      : new Set(partialResults.flatMap((entry) => [...entry.selfReferences]));
  return {
    duplicate,
    locationsObserved,
    logObserved,
    missing,
    nonLowerCaseUnits,
    selfReferences,
    unknownUnits,
  };
}

function checkWorkplaceDataReferences(
  data: WorkplaceDataSpecification,
  unitSymbolsLowerCase: ReadonlySet<string>,
): {
  duplicate: Set<string>;
  missing: Set<string>;
  nonLowerCaseUnits: Set<string>;
  selfReferences: Set<string>;
  unknownUnits: Set<string>;
} | null {
  // copyValueFromFirstValidPickup only makes sense on delivery log...
  // copyValueFromLastDelivery only makes sense on delivery log...
  // computations refer to other fields on same entry type and on same location type...?
  const pickupCheckResult = data.pickup
    ? checkReportingWorkplaceTypeDataReferences(data.pickup, unitSymbolsLowerCase)
    : null;
  const deliveryCheckResult = data.delivery
    ? checkReportingWorkplaceTypeDataReferences(data.delivery, unitSymbolsLowerCase)
    : null;
  const workplaceCheckResult = data.workplace
    ? checkReportingWorkplaceTypeDataReferences(data.workplace, unitSymbolsLowerCase)
    : null;
  const partialResults = [pickupCheckResult, deliveryCheckResult, workplaceCheckResult].filter(
    notNull,
  );
  if (partialResults.length === 0) {
    return null;
  }
  const duplicate =
    partialResults.length === 1
      ? partialResults[0].duplicate
      : new Set(partialResults.flatMap((entry) => [...entry.duplicate]));
  const missing =
    partialResults.length === 1
      ? partialResults[0].missing
      : new Set(partialResults.flatMap((entry) => [...entry.missing]));
  if (data.delivery?.logInputs) {
    for (const entry of data.delivery.logInputs) {
      if (
        entry.copyValueFromFirstValidPickup != null &&
        (!pickupCheckResult ||
          !pickupCheckResult.logObserved.has(entry.copyValueFromFirstValidPickup))
      ) {
        missing.add(entry.copyValueFromFirstValidPickup);
      }
      if (
        entry.copyValueFromLastDelivery != null &&
        (!deliveryCheckResult ||
          !deliveryCheckResult.logObserved.has(entry.copyValueFromLastDelivery))
      ) {
        missing.add(entry.copyValueFromLastDelivery);
      }
    }
  }
  const selfReferences =
    partialResults.length === 1
      ? partialResults[0].selfReferences
      : new Set(partialResults.flatMap((entry) => [...entry.selfReferences]));

  const nonLowerCaseUnits =
    partialResults.length === 1
      ? partialResults[0].nonLowerCaseUnits
      : new Set(partialResults.flatMap((entry) => [...entry.nonLowerCaseUnits]));

  const unknownUnits =
    partialResults.length === 1
      ? partialResults[0].unknownUnits
      : new Set(partialResults.flatMap((entry) => [...entry.unknownUnits]));

  if (
    !duplicate.size &&
    !missing.size &&
    !selfReferences.size &&
    !nonLowerCaseUnits.size &&
    !unknownUnits.size
  ) {
    return null;
  }

  return {duplicate, missing, nonLowerCaseUnits, selfReferences, unknownUnits};
}

function checkTaskDataReferences(data: TaskDataSpecification): {
  duplicate: Set<string>;
  missing: Set<string>;
  selfReferences: Set<string>;
} | null {
  if (!data.inputs) {
    return null;
  }
  const {duplicate, missing, selfReferences} = checkReportingInputsReferences(data.inputs);
  if (!duplicate.size && !missing.size && !selfReferences.size) {
    return null;
  }
  return {
    duplicate,
    missing,
    selfReferences,
  };
}

function UnitDialog({onOk, open}: {onOk: () => void; open: boolean}): React.JSX.Element {
  const intl = useIntl();
  const unitArray = useSelector(getUnitArray);
  const filteredSortedUnits = _.sortBy(
    unitArray.filter((unit) => unit.active),
    (unit) => unit.name,
  );
  return (
    <ResponsiveDialog
      autoFocusCancel
      onOk={onOk}
      open={open}
      title={intl.formatMessage({defaultMessage: "Enheder"})}
    >
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage defaultMessage="Navn" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Vist som" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSortedUnits.map((unit) => (
              <TableRow key={unit.url}>
                <TableCell>{unit.name}</TableCell>
                <TableCell>{unit.symbol || unit.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </ResponsiveDialog>
  );
}

interface DisableDialogProps {
  fullscreen?: boolean;
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

const DisableDialog = function DisableDialog({
  fullscreen,
  onCancel,
  onOk,
  open,
}: DisableDialogProps): React.JSX.Element {
  const {formatMessage} = useIntl();

  return (
    <ResponsiveDialog
      autoFocusCancel
      fullscreen={fullscreen}
      okLabel={formatMessage(messages.confirmDisable)}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      title={formatMessage(messages.disableQuestion)}
    />
  );
};

export const ReportingSpecificationNewVersionForm = React.memo(
  function ReportingSpecificationNewVersionForm({
    reportingSpecification,
  }: {
    reportingSpecification: ReportingSpecification;
  }): React.JSX.Element {
    const dispatch = useDispatch();

    const units = useSelector(getUnitArray);
    const unitSymbolsLowerCase = useMemo(
      () => new Set(units.map((unit) => formatUnitString(unit).toLocaleLowerCase())),
      [units],
    );

    const [workplaceRegistration, setWorkplaceRegistration] = useState(
      reportingSpecification.workplaceRegistration,
    );
    const [taskData, setTaskData] = useState(
      JSON.stringify(reportingSpecification.taskData, null, JSON_INDENTATION_LEVEL),
    );
    const [workplaceData, setWorkplaceData] = useState(
      JSON.stringify(reportingSpecification.workplaceData, null, JSON_INDENTATION_LEVEL),
    );
    const [extraFooterText, setExtraFooterText] = useState(
      reportingSpecification.extraFooterText || "",
    );
    const handleWorkplaceRegistrationChange = useCallback(
      (
        event: React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>,
      ) => {
        setWorkplaceRegistration(event.target.value as WorkplaceRegistration);
      },
      [],
    );

    const [unitDialogOpen, setUnitDialogOpen] = useState(false);
    const setUnitDialogOpenTrue = useCallWithTrue(setUnitDialogOpen);
    const setUnitDialogOpenFalse = useCallWithFalse(setUnitDialogOpen);

    const [newVersionDialogOpen, setNewVersionDialogOpen] = useState(false);
    const [disableDialogOpen, setDisableDialogOpen] = useState(false);
    const workTypeArray = useSelector(getWorkTypeArray);
    const machineArray = useSelector(getMachineArray);
    const priceGroupArray = useSelector(getPriceGroupArray);

    const workTypes = useMemo(
      () => workTypeArray.filter((w) => w.reportingSpecification === reportingSpecification.url),
      [reportingSpecification.url, workTypeArray],
    );
    const machines = useMemo(
      () => machineArray.filter((m) => m.reportingSpecification === reportingSpecification.url),
      [machineArray, reportingSpecification.url],
    );
    const priceGroups = useMemo(
      () =>
        priceGroupArray.filter((pg) => pg.reportingSpecification === reportingSpecification.url),
      [priceGroupArray, reportingSpecification.url],
    );

    let editedTaskDataValue: TaskDataSpecification | undefined;
    let editedWorkplaceDataValue: WorkplaceDataSpecification | undefined;

    let taskDataParseError: string | undefined;
    let workplaceDataParseError: string | undefined;

    try {
      editedTaskDataValue = JSON.parse(taskData);
    } catch (error) {
      taskDataParseError = `${error}` || " ";
    }

    const taskDataSchemaErrors = useMemo(() => {
      if (!editedTaskDataValue) {
        return null;
      }
      const ajv = new Ajv({allErrors: true});
      const check = ajv.compile({
        ...reportSpecificationSchema.properties.taskData,
        definitions: reportSpecificationSchema.definitions,
      });
      check(editedTaskDataValue);
      return check.errors || null;
    }, [editedTaskDataValue]);

    const taskDataReferenceErrors = useMemo(() => {
      if (!editedTaskDataValue || !!taskDataSchemaErrors) {
        return null;
      }
      return checkTaskDataReferences(editedTaskDataValue as TaskDataSpecification);
    }, [editedTaskDataValue, taskDataSchemaErrors]);

    try {
      editedWorkplaceDataValue = JSON.parse(workplaceData);
    } catch (error) {
      workplaceDataParseError = `${error}` || " ";
    }

    const workplaceDataSchemaErrors = useMemo(() => {
      if (!editedWorkplaceDataValue) {
        return null;
      }
      const ajv = new Ajv({allErrors: true});
      const check = ajv.compile({
        ...reportSpecificationSchema.properties.workplaceData,
        definitions: reportSpecificationSchema.definitions,
      });
      check(editedWorkplaceDataValue);
      return check.errors || null;
    }, [editedWorkplaceDataValue]);

    const workplaceDataReferenceErrors = useMemo(() => {
      if (!editedWorkplaceDataValue || !!workplaceDataSchemaErrors) {
        return null;
      }
      return checkWorkplaceDataReferences(
        editedWorkplaceDataValue as WorkplaceDataSpecification,
        unitSymbolsLowerCase,
      );
    }, [editedWorkplaceDataValue, unitSymbolsLowerCase, workplaceDataSchemaErrors]);

    const newVersionDialogOk = useCallback(
      (versionNote: string): void => {
        setNewVersionDialogOpen(false);
        if (!editedTaskDataValue || !editedWorkplaceDataValue) {
          return;
        }
        const id = uuid();
        const url = instanceURL("reportingSpecification", id);
        const newVersion: ReportingSpecification = {
          ...reportingSpecification,
          extraFooterText,
          id,
          taskData: editedTaskDataValue,
          url,
          versionNote,
          workplaceData: editedWorkplaceDataValue,
          workplaceRegistration,
        };
        dispatch(actions.create(newVersion));
        dispatch(actions.update(reportingSpecification.url, [{member: "active", value: false}]));

        [...workTypes, ...machines, ...priceGroups].forEach((instance: ResourceInstance) => {
          dispatch(actions.update(instance.url, [{member: "reportingSpecification", value: url}]));
        });
        dispatch(actions.go("/systemSetup/reportingSpecification/:id", {id}, {}, "REPLACE"));
      },
      [
        dispatch,
        editedTaskDataValue,
        editedWorkplaceDataValue,
        extraFooterText,
        machines,
        priceGroups,
        reportingSpecification,
        workTypes,
        workplaceRegistration,
      ],
    );
    const theme = useTheme();

    const disableDialogOK = useCallback(() => {
      dispatch(actions.update(reportingSpecification.url, [{member: "active", value: false}]));
      [...workTypes, ...machines, ...priceGroups].forEach((instance: ResourceInstance) => {
        dispatch(actions.update(instance.url, [{member: "reportingSpecification", value: null}]));
      });
      setDisableDialogOpen(false);
      dispatch(
        actions.go("/systemSetup", undefined, {
          tab: "reporting",
        }),
      );
    }, [dispatch, machines, priceGroups, reportingSpecification.url, workTypes]);
    return (
      <>
        <Card>
          <CardHeader
            subheader={
              <FormattedMessage
                defaultMessage="Ændringer du laver her er ikke bagudkompatible. Der vil blive oprettet en ny version. Opgaver der allerede er oprettet vil pege på den gamle version"
                id="reporting.entry.subtitle"
              />
            }
            title={<FormattedMessage defaultMessage="Avanceret" id="reporting.entry.advanced" />}
          />
          <CardContent>
            <div>
              <strong>
                <FormattedMessage
                  defaultMessage="Ændringstidspunkt:"
                  id="reporting.entry.last-changed"
                />{" "}
              </strong>
              {formatDateTime(reportingSpecification.lastChanged)}
            </div>
            <div>
              <strong>
                <FormattedMessage
                  defaultMessage="Versionsnote:"
                  id="reporting.entry.version-note"
                />{" "}
              </strong>
              {reportingSpecification.versionNote}
            </div>

            <FormControl fullWidth>
              <InputLabel>
                <FormattedMessage
                  defaultMessage="Registretingstype"
                  id="reporting.entry.registration-type"
                />
              </InputLabel>
              <Select
                fullWidth
                onChange={handleWorkplaceRegistrationChange}
                value={workplaceRegistration}
              >
                <MenuItem value={WorkplaceRegistration.none}>
                  <FormattedMessage defaultMessage="Ingen" id="reporting.entry.none" />
                </MenuItem>
                <MenuItem value={WorkplaceRegistration.workplace}>
                  <FormattedMessage defaultMessage="Arbejdssted" id="reporting.entry.workplace" />
                </MenuItem>
                <MenuItem value={WorkplaceRegistration.transportation}>
                  <FormattedMessage
                    defaultMessage="Afhentning/Levering"
                    id="reporting.entry.pickup-delivery"
                  />
                </MenuItem>
              </Select>
            </FormControl>

            <TrimTextField
              fullWidth
              label={
                <FormattedMessage defaultMessage="Opgave data" id="reporting.entry.task-data" />
              }
              margin="dense"
              maxRows={30}
              minRows={2}
              multiline
              onChange={setTaskData}
              value={taskData}
              variant="outlined"
            />
            {taskDataParseError ? (
              <div style={{color: theme.palette.error.main}}>
                <FormattedMessage
                  defaultMessage="Ugyldig JSON: {error}"
                  id="reporting.entry.invalid-json"
                  values={{error: taskDataParseError}}
                />
              </div>
            ) : null}
            {taskDataSchemaErrors ? (
              <div style={{color: theme.palette.error.main}}>
                <pre>
                  <code>{JSON.stringify(taskDataSchemaErrors, null, JSON_INDENTATION_LEVEL)}</code>
                </pre>
              </div>
            ) : null}
            {taskDataReferenceErrors ? (
              <div style={{color: theme.palette.error.main}}>
                <ul>
                  {[...taskDataReferenceErrors.duplicate].map((identifier) => (
                    <li key={identifier}>
                      <FormattedMessage
                        defaultMessage="Gentagen identifier: {identifier}"
                        values={{identifier}}
                      />
                    </li>
                  ))}
                  {[...taskDataReferenceErrors.missing].map((identifier) => (
                    <li key={identifier}>
                      <FormattedMessage
                        defaultMessage="Reference til manglende identifier: {identifier}"
                        values={{identifier}}
                      />
                    </li>
                  ))}
                  {[...taskDataReferenceErrors.selfReferences].map((identifier) => (
                    <li key={identifier}>
                      <FormattedMessage
                        defaultMessage="Circulær definition ved identifier: {identifier}"
                        values={{identifier}}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <TrimTextField
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Arbejdsstedsdata"
                  id="reporting.entry.workplace-data"
                />
              }
              margin="dense"
              maxRows={30}
              minRows={2}
              multiline
              onChange={setWorkplaceData}
              value={workplaceData}
              variant="outlined"
            />
            {workplaceDataParseError ? (
              <div style={{color: theme.palette.error.main}}>
                <FormattedMessage
                  defaultMessage="Ugyldig JSON: {error}"
                  id="reporting.entry.invalid-json"
                  values={{error: workplaceDataParseError}}
                />
              </div>
            ) : null}
            {workplaceDataSchemaErrors ? (
              <div style={{color: theme.palette.error.main}}>
                <pre>
                  <code>
                    {JSON.stringify(workplaceDataSchemaErrors, null, JSON_INDENTATION_LEVEL)}
                  </code>
                </pre>
              </div>
            ) : null}
            {workplaceDataReferenceErrors ? (
              <div style={{color: theme.palette.error.main}}>
                <ul>
                  {[...workplaceDataReferenceErrors.duplicate].map((identifier) => (
                    <li key={identifier}>
                      <FormattedMessage
                        defaultMessage="Gentagen identifier: {identifier}"
                        values={{identifier}}
                      />
                    </li>
                  ))}
                  {[...workplaceDataReferenceErrors.missing].map((identifier) => (
                    <li key={identifier}>
                      <FormattedMessage
                        defaultMessage="Reference til manglende identifier: {identifier}"
                        values={{identifier}}
                      />
                    </li>
                  ))}
                  {[...workplaceDataReferenceErrors.selfReferences].map((identifier) => (
                    <li key={identifier}>
                      <FormattedMessage
                        defaultMessage="Circulær definition ved identifier: {identifier}"
                        values={{identifier}}
                      />
                    </li>
                  ))}
                  {[...workplaceDataReferenceErrors.nonLowerCaseUnits].map((unit) => (
                    <li key={unit}>
                      <FormattedMessage
                        defaultMessage='Enhed for "calculators" skal skrives med småt: {unit}'
                        values={{unit}}
                      />
                    </li>
                  ))}
                  {[...workplaceDataReferenceErrors.unknownUnits].map((unit) => (
                    <li key={unit}>
                      <FormattedMessage
                        defaultMessage='Ukendt enhed for "calculators": {unit}'
                        values={{unit}}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <Button onClick={setUnitDialogOpenTrue}>
              <FormattedMessage defaultMessage="Vis enheder" />
            </Button>
            <TrimTextField
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Ekstra sidefodstekst"
                  id="reporting.entry.extra-footer-text"
                />
              }
              margin="dense"
              maxRows={30}
              minRows={2}
              multiline
              onChange={setExtraFooterText}
              value={extraFooterText}
              variant="outlined"
            />
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              disabled={!!taskDataParseError || !!workplaceDataParseError}
              onClick={useTrueCallback(setNewVersionDialogOpen, [setNewVersionDialogOpen])}
              variant="contained"
            >
              <FormattedMessage
                defaultMessage="Gem ny version"
                id="reporting.entry.save-new-version"
              />
            </Button>
            <ErrorColorButton
              onClick={useTrueCallback(setDisableDialogOpen, [setDisableDialogOpen])}
              variant="contained"
            >
              <FormattedMessage defaultMessage="Deaktiver" id="reporting.entry.disable" />
            </ErrorColorButton>
          </CardActions>
        </Card>
        <DisableDialog
          onCancel={useFalseCallback(setDisableDialogOpen, [setDisableDialogOpen])}
          onOk={disableDialogOK}
          open={disableDialogOpen}
        />
        <NewVersionDialog
          onCancel={useFalseCallback(setNewVersionDialogOpen, [setNewVersionDialogOpen])}
          onOk={newVersionDialogOk}
          open={newVersionDialogOpen}
        />
        <UnitDialog onOk={setUnitDialogOpenFalse} open={unitDialogOpen} />
      </>
    );
  },
);
