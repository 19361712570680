import {DAY_MILLISECONDS, MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {jsonFetch} from "@co-frontend-libs/utils";
import {globalConfig} from "frontend-global-config";
import {SignalDispatcher} from "ste-signals";
import {SimpleEventDispatcher} from "ste-simple-events";
import {closeDatabases} from "./close-databases";

export const getScriptSources = (): Set<string> => {
  const scripts = Array.from(document.querySelectorAll("script[src]"));
  const sources = new Set(scripts.map((s) => s.getAttribute("src")).filter((s) => s !== null));
  return sources;
};

const getStylesheetLinks = (): Set<string> => {
  const stylesheets = Array.from(document.querySelectorAll("link[rel=stylesheet]"));
  const links = new Set(stylesheets.map((s) => s.getAttribute("href")).filter((s) => s !== null));
  return links;
};

const MANIFEST_FILENAME = "app.json";

interface ManifestFileEntry {
  filename: string;
  md5sum: string;
}

interface ManifestData {
  files: ManifestFileEntry[];
  version: string;
}

export const fetchManifest = (): Promise<ManifestData> => {
  const timestamp = new Date();
  const url = `${MANIFEST_FILENAME}?${timestamp.getTime()}`;
  return jsonFetch(url).then((response) => response.data);
};

const APP_ONLY_MANIFEST_JS_FILES = new Set(["native-app-version.js", "origin-url.js"]);

const pcNeedsUpdate = async (): Promise<boolean> => {
  if (process.env.NODE_ENV !== "production") {
    return false;
  }
  return await fetchManifest().then((manifest) => {
    const manifestJsFiles = [];
    const manifestCssFiles = [];
    for (let i = 0; i < manifest.files.length; i += 1) {
      const entry = manifest.files[i];
      const {filename} = entry;
      if (filename.endsWith(".js") && !APP_ONLY_MANIFEST_JS_FILES.has(filename)) {
        manifestJsFiles.push(filename);
      } else if (filename.endsWith(".css")) {
        manifestCssFiles.push(filename);
      }
    }
    if (manifestJsFiles.length) {
      const scriptSources = getScriptSources();
      if (manifestJsFiles.some((f) => !scriptSources.has(f))) {
        return true;
      }
    }
    if (manifestCssFiles.length) {
      const stylesheelLinks = getStylesheetLinks();
      if (manifestCssFiles.some((f) => !stylesheelLinks.has(f))) {
        return true;
      }
    }
    return false;
  });
};

export function updateAutoReloadCallback(): void {
  // eslint-disable-next-line promise/catch-or-return
  closeDatabases().then(() => {
    window.location.reload();
    return;
  });
}

export const currentNewestSignal = new SignalDispatcher();
export const updateFoundSignal = new SignalDispatcher();
export const updateReadySignal = new SignalDispatcher();
export const checkStartedSignal = new SignalDispatcher();
export const checkDoneSignal = new SignalDispatcher();
export const checkErrorEvent = new SimpleEventDispatcher<Error>();

let updateReady = false;
updateReadySignal.subscribe(() => {
  updateReady = true;
});
updateReadySignal.onSubscriptionChange.subscribe((_newCount: number): void => {
  if (updateReady) {
    updateReadySignal.dispatch();
  }
});

function performPcUpdateCheck(): Promise<void> {
  return pcNeedsUpdate().then((result) => {
    if (result) {
      updateReadySignal.dispatch();
    } else {
      currentNewestSignal.dispatch();
    }
    return;
  });
}

function performAppUpdateCheckFetch(): Promise<void> {
  console.assert((window as any).app?.update?.check, "app missing update check function?");
  const {baseURL} = globalConfig;
  return (window as any).app.update
    .check(baseURL)
    .then(
      (result: {
        fetched: boolean;
        fetchTimestamp: Date;
        isNewer: boolean;
        manifest: ManifestData;
      }) => {
        if (result.isNewer) {
          const {version} = result.manifest;
          const {failedVersion} = (window as any).app.bootstrap;
          if (failedVersion && failedVersion.version === version) {
            // app won't retry this version before FAILED_RETRY_TIMEOUT
            const FAILED_RETRY_TIMEOUT = DAY_MILLISECONDS;
            const EXTRA_MINUTES = 5;
            const EXTRA = EXTRA_MINUTES * MINUTE_MILLISECONDS;
            // don't try to fetch or show popup for this version until a
            // couple of minutes *after* the app bootstrap logic will
            // accept it...
            if (failedVersion.failedAt > new Date().valueOf() - FAILED_RETRY_TIMEOUT - EXTRA) {
              return undefined;
            }
          }
          if (result.fetched) {
            updateReadySignal.dispatch();
          } else {
            updateFoundSignal.dispatch();
            return (window as any).app.update.fetch(baseURL, version).then(() => {
              updateReadySignal.dispatch();
              return;
            });
          }
        } else {
          currentNewestSignal.dispatch();
        }
        return undefined;
      },
    );
}

let currentlyChecking = false;

export function performUpdateCheck(): void {
  if (currentlyChecking) {
    return;
  }
  currentlyChecking = true;
  checkStartedSignal.dispatch();
  const checkPromise =
    typeof cordova !== "undefined" ? performAppUpdateCheckFetch() : performPcUpdateCheck();
  checkPromise
    .catch((error) => {
      checkErrorEvent.dispatch(error);
    })
    .finally(() => {
      currentlyChecking = false;
      checkDoneSignal.dispatch();
    });
}
