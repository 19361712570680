import {Customer} from "@co-common-libs/resources";
import {Typography} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {AddressLine} from "./info-lines/address-line";
import {EmailLine} from "./info-lines/email-line";
import {PhoneLine} from "./info-lines/phone-line";

export function CustomerInfo({customer}: {customer: Customer}): React.JSX.Element {
  return (
    <div>
      <Typography variant="body2">
        <FormattedMessage defaultMessage="Navn: {name}" values={{name: customer.name}} />
      </Typography>
      {customer.c5_account && (
        <Typography variant="body2">
          <FormattedMessage defaultMessage="Kontonr: {name}" values={{name: customer.c5_account}} />
        </Typography>
      )}
      {customer.address && <AddressLine address={customer} />}
      {customer.phone && <PhoneLine phone={customer.phone} type="landline" />}
      {customer.cellphone && <PhoneLine phone={customer.cellphone} type="cellphone" />}
      {customer.billingEmail && <EmailLine email={customer.billingEmail} />}
    </div>
  );
}
