import {
  Card,
  CardHeader,
  CircularProgress,
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import clsx from "clsx";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import React, {ReactNode, useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: "1em",
    },
    expand: {
      position: "absolute",
      right: 8,
      top: 8,
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    expandWrapper: {position: "relative"},
  }),
);

interface ExpandableLoadingWrapperProps {
  children: React.ReactNode;
  noTasks: boolean;
  onlineError: boolean;
  onlineWaiting: boolean;
  title: string;
  validPeriod: boolean;
}

export function ExpandableLoadingWrapper(props: ExpandableLoadingWrapperProps): React.JSX.Element {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleExpandChange = useCallback((): void => {
    setExpanded(!expanded);
  }, [expanded]);
  const {children, noTasks, onlineError, onlineWaiting, title, validPeriod} = props;
  let message: React.JSX.Element | undefined;
  if (onlineWaiting) {
    message = (
      <FormattedMessage
        defaultMessage="Henter fra arkiv"
        id="customer-instance.label.archive-waiting"
      />
    );
  } else if (onlineError) {
    message = (
      <FormattedMessage
        defaultMessage="Fejl ved adgang til arkiv"
        id="customer-instance.label.archive-error"
      />
    );
  }
  let content: ReactNode;
  if (message) {
    content = (
      <div style={{padding: 8, textAlign: "center"}}>
        <div>{message}</div>
        {onlineWaiting ? <CircularProgress /> : null}
      </div>
    );
  } else if (validPeriod) {
    if (noTasks) {
      content = (
        <div style={{padding: 8, textAlign: "center"}}>
          <FormattedMessage
            defaultMessage="Kunden har ingen opgaver i den valgte periode."
            id="customer-instance.text.no-tasks-found"
          />
        </div>
      );
    } else {
      content = children;
    }
  }
  return (
    <Card className={classes.card}>
      <div className={classes.expandWrapper}>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandChange}
        >
          <ChevronDownIcon />
        </IconButton>
      </div>
      <CardHeader title={title} />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {content}
      </Collapse>
    </Card>
  );
}
