/// <reference types="cordova-sqlite-storage" />
import {ResourceInstance} from "@co-common-libs/resources";
import * as _ from "lodash";
import {DBConnection} from "./db-connection";
import {getListURL} from "./utils";

export class SQLError extends Error {
  override cause: Error;
  constructor(cause: Error, message: string) {
    super(message);
    this.cause = cause;
  }
}

export const wrapSQLError =
  (fnname: string): ((e: Error) => never) =>
  (e) => {
    if (e && e.message) {
      throw new SQLError(e, `${fnname}: ${e.message}`);
    } else {
      throw e;
    }
  };

const baseOpenConnection = (
  sqlitePlugin: SQLitePlugin.SQLite,
  dbName: string,
): Promise<SQLitePlugin.Database> => {
  return new Promise(
    (resolve: SQLitePlugin.DatabaseSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
      sqlitePlugin.openDatabase({location: "default", name: dbName}, resolve, reject);
    },
  ).catch(() => {
    return new Promise(
      (resolve: SQLitePlugin.DatabaseSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        const RETRY_AFTER_MILLISECONDS = 2;
        setTimeout(() => {
          sqlitePlugin.openDatabase({location: "default", name: dbName}, resolve, reject);
        }, RETRY_AFTER_MILLISECONDS);
        sqlitePlugin.openDatabase({location: "default", name: dbName}, resolve, reject);
      },
    );
  });
};

const createTables = (db: SQLitePlugin.Database, storeNames: string[]): Promise<any> => {
  const sql = storeNames.map((storeName) =>
    [
      "CREATE TABLE IF NOT EXISTS",
      `${storeName}_store`,
      "(url BLOB PRIMARY KEY, listURL BLOB, value BLOB)",
    ].join(" "),
  );
  const promise = new Promise<void>((resolve, reject) => {
    db.sqlBatch(sql, resolve, reject);
  });
  return promise.catch(wrapSQLError("createTables"));
};

const openConnectionAndCreateTables = async (
  sqlitePlugin: SQLitePlugin.SQLite,
  dbName: string,
  storeNames: string[],
): Promise<SQLitePlugin.Database> => {
  const db = await baseOpenConnection(sqlitePlugin, dbName);
  return createTables(db, storeNames).then(_.constant(db));
};

class CordovaSQLiteStorageDBConnection extends DBConnection {
  private connection: SQLitePlugin.Database;
  private dbName: string;
  private sqlitePlugin: SQLitePlugin.SQLite;
  constructor(
    connection: SQLitePlugin.Database,
    sqlitePlugin: SQLitePlugin.SQLite,
    dbName: string,
  ) {
    super();
    this.connection = connection;
    this.sqlitePlugin = sqlitePlugin;
    this.dbName = dbName;
  }
  close(): Promise<void> {
    return new Promise<void>(
      (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        this.connection.close(resolve, reject);
      },
    ).catch(() => {
      return new Promise<void>(
        (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
          const RETRY_AFTER_MILLISECONDS = 2;
          setTimeout(() => {
            this.connection.close(resolve, reject);
          }, RETRY_AFTER_MILLISECONDS);
        },
      );
    });
  }
  deleteDatabase(): Promise<void> {
    return new Promise(
      (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        return this.sqlitePlugin.deleteDatabase(
          {location: "default", name: this.dbName},
          resolve,
          reject,
        );
      },
    );
  }
  fetchAll(storeName: string): Promise<ResourceInstance[]> {
    const sql = ["SELECT * FROM", `${storeName}_store`].join(" ");
    return new Promise(
      (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        this.connection.executeSql(sql, [], resolve, reject);
      },
    )
      .then(({rows}) => {
        const values = [];
        for (let i = 0; i < rows.length; i += 1) {
          values.push(JSON.parse(rows.item(i).value));
        }
        return values;
      })
      .catch(wrapSQLError("fetchAll"));
  }
  fetchInstance(storeName: string, url: string): Promise<ResourceInstance> {
    const sql = ["SELECT * FROM", `${storeName}_store`, "WHERE url = ?"].join(" ");
    return new Promise(
      (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        this.connection.executeSql(sql, [url], resolve, reject);
      },
    )
      .then(({rows}) => {
        if (rows.length > 0) {
          return JSON.parse(rows.item(0).value);
        }
        throw new Error(`no rows found for ${url}`);
      })
      .catch(wrapSQLError("fetchInstance"));
  }
  removeInstance(storeName: string, url: string): Promise<void> {
    const sql = ["DELETE FROM", `${storeName}_store`, "WHERE url = ?"].join(" ");
    return new Promise(
      (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        this.connection.executeSql(sql, [url], resolve, reject);
      },
    )
      .then(() => undefined)
      .catch(wrapSQLError("removeInstance")) as Promise<void>;
  }
  replaceInstance(storeName: string, instance: ResourceInstance): Promise<void> {
    const sql = [
      "INSERT OR REPLACE INTO",
      `${storeName}_store`,
      "(url, listURL, value) VALUES (?, ?, ?)",
    ].join(" ");
    const {url} = instance;
    const listURL = getListURL(instance.url);
    const value = JSON.stringify(instance);
    return new Promise(
      (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        this.connection.executeSql(sql, [url, listURL, value], resolve, reject);
      },
    )
      .then(() => undefined)
      .catch(wrapSQLError("replaceInstance"));
  }
}

export const getCordovaSQLiteStorageConnection = (
  sqlitePlugin: SQLitePlugin.SQLite,
  dbName: string,
  storeNames: string[],
): Promise<DBConnection> =>
  openConnectionAndCreateTables(sqlitePlugin, dbName, storeNames).then(
    (connection) => new CordovaSQLiteStorageDBConnection(connection, sqlitePlugin, dbName),
  );
