import {
  getMachineString,
  getWorkTypeString,
  OrderArchiveData,
} from "@co-common-libs/resources-utils";
import {
  caseAccentInsensitiveCollator,
  formatDate,
  formatDateShort,
  formatTime,
  notNull,
} from "@co-common-libs/utils";
import {getCurrentRole, getCustomerSettings} from "@co-frontend-libs/redux";
import {
  buildOrderColumnSpecifications,
  computeOrderVisibleColumns,
  OrderTableColumnID,
  OrderTableDataType,
  OrderTableFieldID,
  TableWithPagination,
  TableWithPaginationProps,
} from "app-components";
import {getLocationString} from "app-utils";
import bowser from "bowser";
import _ from "lodash";
import React, {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

const numericStatusMapping = {
  active: 0,
  completed: 3,
  error: 6,
  paused: 1,
  pending: 2,
  recorded: 5,
  validated: 4,
} as const;

// TODO: hide merge stuff...

function buildRowData(
  data: OrderArchiveData[],
  departments: {
    readonly [x: string]: string | undefined;
  },
): readonly OrderTableDataType[] {
  const getDepartmentLabel = (departmentID: string): string =>
    departments[departmentID] || departmentID;

  return data.map((entry) => {
    const taskDepartments = entry.tasks.map((task) => getDepartmentLabel(task.department));
    taskDepartments.sort();
    const department = Array.from(
      new Set([getDepartmentLabel(entry.department), ...taskDepartments].filter(Boolean)),
    ).join(", ");

    const employees = Array.from(
      new Set(entry.tasks.map((task) => task.employeeAlias).filter(notNull)),
    )
      .sort()
      .join(",");

    const status =
      _.minBy(
        entry.tasks.map((task) => task.status),
        (s) => numericStatusMapping[s],
      ) || "pending";

    const projectNumbers = Array.from(
      new Set(entry.tasks.map((task) => task.projectNumber).filter(notNull)),
    )
      .sort()
      .join(", ");

    const time = entry.time || _.minBy(entry.tasks.map((task) => task.time).filter(notNull));

    const workType = entry.workType || _.minBy(entry.tasks, (task) => task.created)?.workType;
    const workTypeString = workType
      ? workType.identifier
        ? `${workType.identifier}: ${workType.name}`
        : workType.name
      : "";
    const machineName = new Set<string>();
    const machineNumber = new Set<string>();
    const machineNumberAndName = new Set<string>();

    const workTypes = Array.from(
      new Set(
        entry.tasks.map((task) => getWorkTypeString(task.workType || undefined)).filter(notNull),
      ),
    )
      .sort()
      .join(", ");

    const deliveryLogLocationSet = new Set<string>();
    const pickupLogLocationSet = new Set<string>();
    entry.tasks.forEach((task) => {
      task.machines.forEach((machine) => {
        machineName.add(machine.name);
        machineNumber.add(machine.id);
        machineNumberAndName.add(getMachineString({c5_machine: machine.id, name: machine.name}));
      });
      if (task.deliveryLogLocations) {
        for (const logLocation of task.deliveryLogLocations) {
          deliveryLogLocationSet.add(getLocationString(logLocation));
        }
      }
      if (task.pickupLogLocations) {
        for (const logLocation of task.pickupLogLocations) {
          pickupLogLocationSet.add(getLocationString(logLocation));
        }
      }
    });
    const machineNameArray = [...machineName];
    machineNameArray.sort();
    const machineNumberArray = [...machineNumber];
    machineNumberArray.sort();
    const machineNumberAndNameArray = [...machineNumberAndName];
    machineNumberAndNameArray.sort();

    const deliveryLogLocations = [...deliveryLogLocationSet];
    const pickupLogLocations = [...pickupLogLocationSet];
    deliveryLogLocations.sort(caseAccentInsensitiveCollator.compare);
    pickupLogLocations.sort(caseAccentInsensitiveCollator.compare);

    let projectString = "";
    let projectWithAliasString = "";
    if (entry.project) {
      const {alias, name, projectNumber} = entry.project;
      const nameAndAlias = name && alias ? `${name}, ${alias}` : name || alias;
      const nameOrAlias = name || alias;
      projectString = `${projectNumber}: ${nameOrAlias}`;
      projectWithAliasString = `${projectNumber}: ${nameAndAlias}`;
    }

    const customerAddress = [entry.customerAddress, entry.customerPostalCode, entry.customerCity]
      .filter((part) => !!part)
      .join(", ");
    const customerAddressShort = entry.customerAddress;

    const result: OrderTableDataType = {
      address: entry.workplace?.name || entry.workplace?.address || entry.address || "",
      createdDate: formatDate(entry.created),
      createdDateRaw: entry.created || "",
      createdDateShort: formatDateShort(entry.created),
      customer: entry.customerName || "",
      customerAddress,
      customerAddressShort,
      customerURL: "",
      date: formatDate(entry.date),
      dateRaw: entry.date || "X",
      dateShort: formatDateShort(entry.date),
      deliveryLogLocations: deliveryLogLocations.join("\n"),
      department,
      employees,
      key: entry.url,
      machineName: machineNameArray.join(", "),
      machineNumber: machineNumberArray.join(", "),
      machineNumberAndName: machineNumberAndNameArray.join(", "),
      managerInternalNotes: entry.managerInternalNotes,
      merge: "",
      note: entry.notes,
      numericStatus: numericStatusMapping[status],
      orderURL: entry.url,
      pickupLogLocations: pickupLogLocations.join("\n"),
      project: projectString,
      projectNumbers,
      projectWithAlias: projectWithAliasString,
      referenceNumber: entry.referenceNumber,
      status,
      time: formatTime(time),
      validatedAndRecorded: entry.validatedAndRecorded,
      workType: workTypeString,
      workTypeColor: workType?.color || null,
      workTypes,
    };
    return result;
  });
}

interface ArchiveOrderTableProps
  extends Omit<
    TableWithPaginationProps<OrderTableFieldID, OrderTableColumnID>,
    "columns" | "entries" | "visibleColumns"
  > {
  data: OrderArchiveData[];
  onClick: (orderkURL: string) => void;
}

export function ArchiveOrderTable(props: ArchiveOrderTableProps): React.JSX.Element {
  const {
    count,
    data,
    onClick,
    onHeaderClick,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    sortBy,
    sortDirection,
  } = props;
  const currentRole = useSelector(getCurrentRole);
  const userIsManager = !!currentRole?.manager;

  const customerSettings = useSelector(getCustomerSettings);
  const {
    departments,
    enableOrderReferenceNumber,
    enableTaskReferenceNumber,
    machineLabelVariant,
    orderListColumns,
    orderListNoteLines,
    orderReferenceNumberLabel,
    projectLabelVariant,
    taskReferenceNumberLabel,
  } = customerSettings;

  const intl = useIntl();

  // don't support "merge" on archive...
  const onCheckChanged = useCallback((_url: string, _checked: boolean): void => {
    return;
  }, []);
  const selectedOrders = useMemo(() => [], []);

  const columnSpecifications = useMemo(
    () =>
      buildOrderColumnSpecifications(
        intl.formatMessage,
        onClick,
        onCheckChanged,
        selectedOrders,
        enableOrderReferenceNumber,
        enableTaskReferenceNumber,
        orderReferenceNumberLabel,
        taskReferenceNumberLabel,
        orderListNoteLines,
        machineLabelVariant,
        projectLabelVariant,
        undefined,
      ),
    [
      intl.formatMessage,
      onClick,
      onCheckChanged,
      selectedOrders,
      enableOrderReferenceNumber,
      enableTaskReferenceNumber,
      orderReferenceNumberLabel,
      taskReferenceNumberLabel,
      orderListNoteLines,
      machineLabelVariant,
      projectLabelVariant,
    ],
  );

  const visibleColumns = useMemo(
    () =>
      computeOrderVisibleColumns(
        "archive",
        !!bowser.mobile,
        !!bowser.tablet,
        userIsManager,
        orderListColumns,
      ).filter((column) => column !== "merge"),
    [orderListColumns, userIsManager],
  );
  const rowData = buildRowData(data, departments);

  return (
    <TableWithPagination
      columns={columnSpecifications}
      count={count}
      entries={rowData}
      onHeaderClick={onHeaderClick}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      page={page}
      rowsPerPage={rowsPerPage}
      sortBy={sortBy}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
    />
  );
}
