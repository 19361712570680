import {formatDateWeekdayShort} from "@co-common-libs/utils";
import {PureComponent} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import React from "react";
import {CalendarGridBlock} from "./calendar-grid-block";
import {HOUR_COLUMN_WIDTH} from "./constants";
import {addHour} from "./utils";

interface HoursColumnProps {
  dateTransitionMark?: boolean;
  fromTimestamp: ImmutableDate;
  toTimestamp: ImmutableDate;
}

export class HoursColumn extends PureComponent<HoursColumnProps> {
  static defaultProps = {
    dateTransitionMark: false,
  };
  render(): React.JSX.Element {
    const {dateTransitionMark, fromTimestamp, toTimestamp} = this.props;
    const hourBlocks = [];
    for (
      let currentTimestamp = fromTimestamp;
      currentTimestamp < toTimestamp;
      currentTimestamp = addHour(currentTimestamp)
    ) {
      const hour = currentTimestamp.getHours();
      let hourStr = `${hour}:00`;
      const tenHours = 10;
      if (hour < tenHours) {
        hourStr = `0${hourStr}`;
      }
      let transitionMark = false;
      if (dateTransitionMark && hour === 0) {
        transitionMark = true;
        hourStr = formatDateWeekdayShort(currentTimestamp as any as Date);
      }
      hourBlocks.push(
        <CalendarGridBlock
          key={currentTimestamp.toISOString()}
          text={hourStr}
          transitionMark={transitionMark}
        />,
      );
    }
    const style: React.CSSProperties = {
      backgroundColor: "#fff",
      borderColor: "rgb(247, 247, 247)",
      borderStyle: "solid",
      borderWidth: "0px 0px 1px 0px",
      display: "inline-block",
      textAlign: "center",
      verticalAlign: "top",
      width: HOUR_COLUMN_WIDTH,
    };
    return <div style={style}>{hourBlocks}</div>;
  }
}
