import React, {useCallback, useRef, useState} from "react";
import {ResponsiveDialog} from "../responsive-dialog";

interface ModalPromptComponentProps<ReturnType> {
  onCancel: () => void;
  onOk: (result: ReturnType) => void;
}

type ModalComponent<ReturnType, PropsType> = React.ComponentType<
  ModalPromptComponentProps<ReturnType> & PropsType
>;

type AdditionalPropsType<ReturnType, PropsType> = Omit<
  PropsType,
  "onNone" | "open" | keyof ModalPromptComponentProps<ReturnType>
>;

export const useModal = <ReturnType, PropsType>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ModalContent: ModalComponent<ReturnType, PropsType>,
  title: string = "",
): [
  React.JSX.Element,
  (
    additionalProps?: AdditionalPropsType<ReturnType, PropsType>,
    abortSignal?: AbortSignal,
  ) => Promise<ReturnType | undefined>,
] => {
  const [isOpen, setIsOpen] = useState(false);

  const additionalPropsRef = useRef<AdditionalPropsType<ReturnType, PropsType> | undefined>(
    undefined,
  );
  const resolverRef = useRef<((value: ReturnType | undefined) => void) | null>(null);

  const onOk = useCallback((result: ReturnType) => {
    if (resolverRef.current) {
      resolverRef.current(result);
    }
    setIsOpen(false);
    resolverRef.current = null;
  }, []);

  const onCancel = useCallback(() => {
    if (resolverRef.current) {
      resolverRef.current(undefined);
    }
    setIsOpen(false);
    resolverRef.current = null;
  }, []);

  const prompt = useCallback(
    (
      additionalProps?: AdditionalPropsType<ReturnType, PropsType>,
      abortSignal?: AbortSignal,
    ): Promise<ReturnType | undefined> => {
      return new Promise<ReturnType | undefined>((resolve) => {
        if (resolverRef.current) {
          resolverRef.current(undefined);
        }

        additionalPropsRef.current = additionalProps;
        resolverRef.current = resolve;
        if (abortSignal) {
          abortSignal.onabort = (ev) => {
            ev.stopPropagation();
            onCancel();
          };
        }
        setIsOpen(true);
      });
    },
    [onCancel],
  );

  const modal = (
    <ResponsiveDialog hideActions onCancel={onCancel} open={isOpen} title={title}>
      <ModalContent
        onCancel={onCancel}
        onOk={onOk}
        open={isOpen}
        {...(additionalPropsRef.current as PropsType)}
      />
    </ResponsiveDialog>
  );

  return [modal, prompt];
};
