import {SettingID} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {ColorResult, RGBColor, SketchPicker} from "react-color";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import tinycolor from "tinycolor2";

interface SelectColorDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

const stringToRGBA = (color?: string): RGBColor => {
  if (!color) {
    return {
      b: 255,
      g: 255,
      r: 255,
    };
  }
  return tinycolor(color).toRgb();
};

export function SelectColorDialog(props: SelectColorDialogProps): React.JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const data: string | undefined = settingEntry?.data;

  const currentUserURL = useSelector(getCurrentUserURL);

  const dispatch = useDispatch();

  const [color, setColor] = useState<RGBColor>(stringToRGBA(data));

  useEffect(() => {
    if (open) {
      setColor(stringToRGBA(data));
    }
  }, [data, open]);

  const handleSave = useCallback(() => {
    const newValue = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValue},
        ]),
      );
    }
    onClose();
  }, [color, currentUserURL, dispatch, onClose, settingEntry]);

  const handleChangeComplete = useCallback((newColor: ColorResult) => {
    setColor(newColor.rgb);
  }, []);

  return (
    <ResponsiveDialog
      fullWidth
      okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
      onCancel={onClose}
      onOk={handleSave}
      open={open}
      title={
        <FormattedMessage defaultMessage="Vælg farve" id="system-setup.dialog-title.select-color" />
      }
    >
      <DialogContent
        style={{
          backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
        }}
      >
        <div style={{marginLeft: "auto", marginRight: "auto", width: 220}}>
          <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
        </div>
      </DialogContent>
    </ResponsiveDialog>
  );
}
