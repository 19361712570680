import {ReadonlyConfig} from "@co-common-libs/config";

export type EconomySystemIntegrationKindPart = Pick<
  ReadonlyConfig,
  "brugerdataSync" | "c5Sync" | "economicSync" | "navSync" | "navSyncProfile" | "unicontaSync"
>;

export enum ActiveEconomySystemIntegrationKind {
  NONE,
  LEGACY,
  LIVE_SYNC,
}

export function getEconomySystemIntegrationKind(
  config: EconomySystemIntegrationKindPart,
): ActiveEconomySystemIntegrationKind {
  const {brugerdataSync, c5Sync, economicSync, navSync, unicontaSync} = config;

  const legacy = c5Sync || navSync || brugerdataSync;

  const liveSync = economicSync || unicontaSync;

  if (legacy) {
    return ActiveEconomySystemIntegrationKind.LEGACY;
  } else if (liveSync) {
    return ActiveEconomySystemIntegrationKind.LIVE_SYNC;
  } else {
    return ActiveEconomySystemIntegrationKind.NONE;
  }
}
