import {ComputedTime, Task, TaskUrl, Timer, TimerUrl} from "@co-common-libs/resources";
import {timerMayBeBilled} from "@co-common-libs/resources-utils";
import {mapFilter} from "@co-common-libs/utils";
import {
  getPriceGroupLookup,
  getPriceItemLookup,
  getTaskLookup,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {sortBy} from "lodash";
import {useCallback} from "react";
import {useSelector} from "react-redux";
import {mapSum} from "./iterable";
import {
  computeIntervalsNormalisedInputTruncated,
  computeIntervalSums,
  mergeIntervals,
  normaliseTimerStarts,
} from "./task-timers";
import {getBreakTimer, getGenericEffectiveTimer} from "./timers";

export interface ComputedTaskTimeResult {
  activeTimerUrl: TimerUrl | null;
  computedIntervals: readonly ComputedTime[];
  externalEfficiencyPercent: number;
  externalEfficientMinutes: number;
  intervals: ReturnType<typeof mergeIntervals>;
  minutesTotal: number;
  nowTimestamp: Date;
  task: Task | null;
  timerMinutesMap: ReadonlyMap<TimerUrl, number>;
}

interface ComputedTaskTime {
  breakTimer: Timer | undefined;
  computeTaskTime: (taskUrl: TaskUrl) => ComputedTaskTimeResult;
  genericEffectiveTimer: Timer | undefined;
}

export function useComputedTaskTime(): ComputedTaskTime {
  const taskLookup = useSelector(getTaskLookup);
  const timerLookup = useSelector(getTimerLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);

  const timerStartArray = useSelector(getTimerStartArray);
  const timerArray = useSelector(getTimerArray);
  const breakTimer = getBreakTimer(timerArray);

  const genericEffectiveTimer = getGenericEffectiveTimer(timerArray);

  const genericEffectiveTimerUrl = (genericEffectiveTimer && genericEffectiveTimer.url) || null;
  const breakTimerUrl = (breakTimer && breakTimer.url) || null;

  const computeTaskTime = useCallback(
    (taskUrl: TaskUrl) => {
      const task = taskLookup(taskUrl);

      if (!genericEffectiveTimerUrl || !task) {
        return {
          activeTimerUrl: null,
          computedIntervals: [],
          externalEfficiencyPercent: 0,
          externalEfficientMinutes: 0,
          intervals: [],
          minutesTotal: 0,
          nowTimestamp: new Date(),
          task: null,
          timerMinutesMap: new Map(),
        } satisfies ComputedTaskTimeResult;
      }

      const now = new Date();
      now.setUTCMilliseconds(0);
      const timestamp = now.toISOString();
      const nowMinute = new Date(now);
      nowMinute.setUTCSeconds(0, 0);

      let computedIntervals: readonly ComputedTime[];
      let activeTimerUrl = null;
      if (task.recordedInC5) {
        computedIntervals = task.computedTimeSet;
      } else {
        const taskTimerStartList = normaliseTimerStarts(
          timerStartArray.filter((instance) => instance.task === taskUrl),
        );
        const lastTimerStart = taskTimerStartList[taskTimerStartList.length - 1];
        activeTimerUrl = lastTimerStart && lastTimerStart.timer;
        computedIntervals = computeIntervalsNormalisedInputTruncated(
          taskTimerStartList,
          nowMinute.toISOString(),
        );
      }
      const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
      const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
      const intervals = sortBy(
        mergeIntervals(
          computedIntervals,
          correctionIntervals,
          managerCorrectionIntervals,
          timestamp,
        ),
        (interval) => interval.fromTimestamp,
      );

      const timerMinutesMap = computeIntervalSums(intervals, now);

      const minutesTotal = mapSum(timerMinutesMap);

      const breakMinutes = (breakTimerUrl && timerMinutesMap.get(breakTimerUrl)) || 0;

      const isInternalTask = !task.order;

      const externalEfficientMinutes = isInternalTask
        ? 0
        : mapSum(
            mapFilter(timerMinutesMap, (_val, key) =>
              timerMayBeBilled(
                key,
                genericEffectiveTimerUrl,
                timerLookup,
                workTypeLookup,
                priceGroupLookup,
                priceItemLookup,
              ),
            ),
          );

      const paidMinutes = minutesTotal - breakMinutes;

      const percentMultiplier = 100;
      const externalEfficiencyPercent = paidMinutes
        ? Math.round((percentMultiplier * externalEfficientMinutes) / paidMinutes)
        : 0;

      return {
        activeTimerUrl,
        computedIntervals,
        externalEfficiencyPercent,
        externalEfficientMinutes,
        intervals,
        minutesTotal,
        nowTimestamp: now,
        task,
        timerMinutesMap,
      } satisfies ComputedTaskTimeResult;
    },
    [
      breakTimerUrl,
      genericEffectiveTimerUrl,
      priceGroupLookup,
      priceItemLookup,
      taskLookup,
      timerLookup,
      timerStartArray,
      workTypeLookup,
    ],
  );

  return {
    breakTimer,
    computeTaskTime,
    genericEffectiveTimer,
  };
}
