import {Checkbox, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {typedMemo} from "../../types";
import {MaybeSearchResultEntryData} from "../types";

interface AllItemProps<Identifier extends string> {
  allSelected: boolean;
  entries: readonly MaybeSearchResultEntryData<Identifier>[];
  isSearchResult: boolean;
  multiSelectMax: number | undefined;
  onSelectMultiple: ((identifiers: ReadonlySet<Identifier>, selected: boolean) => void) | undefined;
  style: React.CSSProperties;
}

export const AllItem = typedMemo(function AllItem<Identifier extends string>(
  props: AllItemProps<Identifier>,
) {
  const {allSelected, entries, isSearchResult, multiSelectMax, onSelectMultiple, style} = props;

  const handleClick = useCallback(() => {
    if (!onSelectMultiple) {
      return;
    }
    const entriesWithOutGroups = entries.filter((entry) => entry.category !== "group");
    const changed = new Set(entriesWithOutGroups.map((entry) => entry.identifier));
    onSelectMultiple(changed, !allSelected);
  }, [allSelected, entries, onSelectMultiple]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleClick();
      }
    },
    [handleClick],
  );

  const disabled = !!multiSelectMax && entries.length > multiSelectMax;

  const intl = useIntl();

  return (
    <ListItem
      button
      disabled={disabled}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      style={style}
    >
      <ListItemIcon>
        <Checkbox checked={allSelected} disableRipple edge="start" tabIndex={-1} />
      </ListItemIcon>
      <ListItemText
        primary={
          isSearchResult
            ? intl.formatMessage({
                defaultMessage: "Vælg søgeresultater",
              })
            : intl.formatMessage({
                defaultMessage: "Vælg alle",
              })
        }
      />
    </ListItem>
  );
});
