import {ReadonlyConfig} from "@co-common-libs/config";
import {Role, User, UserProfile} from "@co-common-libs/resources";
import {roleIsManager, roleIsProjectManager} from "@co-common-libs/utils";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";
import {liveSyncResource} from "../live-sync-resource";

export type UserAndProfileFields = keyof Pick<User, "loginIdentifier"> | keyof UserProfile;

export type ExtendedUserProfileSettingsPart = EconomySystemIntegrationKindPart &
  Pick<
    ReadonlyConfig,
    | "canEditAllUserDataOnProfilePage"
    | "enableEmployeeGroups"
    | "enableLessorUpload"
    | "enableMaanssonsSalaryUpload"
    | "remunerationGroups"
    | "userCellphoneExtra"
    | "userProfileEmployeeNumber"
  >;

export interface ExtendedUserProfileSettings {
  canCreate: boolean;
  canEditField: (
    profile: Pick<
      UserProfile,
      | "barred"
      | "externalDataloenIdentifier"
      | "externalEconomicIdentifier"
      | "externalLessorIdentifier"
      | "externalUnicontaIdentifier"
      | "user"
    >,
    currentUser: Pick<User, "url">,
    field: UserAndProfileFields,
  ) => boolean;
  canOpenEditDialog: (
    profile: Pick<UserProfile, "user">,
    currentUser: Pick<User, "url">,
  ) => boolean;
  canSeeField: (
    profile: Pick<UserProfile, "employeeGroup" | "user"> | null,
    currentUser: Pick<User, "url">,
    field: UserAndProfileFields,
  ) => boolean;
}

export const externalDataMembers: ReadonlySet<UserAndProfileFields> = new Set([
  "address",
  "averageHourlyCost",
  "cellphone",
  "cellphoneExtra",
  "city",
  "email",
  "employeeNumber",
  "employmentDate",
  "externalDataloenIdentifier",
  "externalEconomicIdentifier",
  "externalLessorIdentifier",
  "externalUnicontaIdentifier",
  "name",
  "postalCode",
]);

// TODO(mr): added alias
export const ownerOrManagerMembers: ReadonlySet<UserAndProfileFields> = new Set([
  "cellphoneExtra",
  "employeeGroup",
  "employeeNumber",
  "externalDataloenIdentifier",
  "externalLessorIdentifier",
  "loginIdentifier",
  "note",
]);

export const otherMembers: ReadonlySet<UserAndProfileFields> = new Set(["alias"]);

export function extendedUserProfileSettings(
  config: ExtendedUserProfileSettingsPart,
  role: Role | null,
): ExtendedUserProfileSettings {
  const {c5Sync, navSyncProfile} = config;

  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const integrationAllowsManage =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
    (economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC &&
      liveSyncResource(config, "userProfile"));

  const roleIsManagerOrProjectManager = roleIsManager(role) || roleIsProjectManager(role);

  const lockEditOfMembers: Set<UserAndProfileFields> = new Set();

  const c5AgromatSync = c5Sync && navSyncProfile !== "bejstrupm";

  if (c5AgromatSync) {
    lockEditOfMembers.add("name");
    lockEditOfMembers.add("address");
    lockEditOfMembers.add("postalCode");
    lockEditOfMembers.add("city");
  }

  return {
    canCreate: true,
    canEditField: (
      profile: Pick<
        UserProfile,
        | "barred"
        | "externalDataloenIdentifier"
        | "externalEconomicIdentifier"
        | "externalLessorIdentifier"
        | "externalUnicontaIdentifier"
        | "user"
      >,
      currentUser: Pick<User, "url">,
      field: keyof Pick<User, "loginIdentifier"> | keyof UserProfile,
    ) => {
      const isImportedExternally =
        profile.externalLessorIdentifier ||
        profile.externalDataloenIdentifier ||
        profile.externalEconomicIdentifier ||
        profile.externalUnicontaIdentifier;

      const currentUserIsProfileOwner = profile.user === currentUser.url;

      const passLocalEditRequirements =
        (currentUserIsProfileOwner || roleIsManagerOrProjectManager) &&
        !lockEditOfMembers.has(field);

      if (!isImportedExternally) {
        return passLocalEditRequirements;
      } else {
        const isInternalField = !externalDataMembers.has(field);
        return (
          passLocalEditRequirements &&
          !profile.barred &&
          (isInternalField || integrationAllowsManage)
        );
      }
    },
    canOpenEditDialog: (profile: Pick<UserProfile, "user">, currentUser: Pick<User, "url">) => {
      const currentUserIsProfileOwner = profile.user === currentUser.url;

      return (
        currentUserIsProfileOwner ||
        (config.canEditAllUserDataOnProfilePage && roleIsManagerOrProjectManager)
      );
    },
    canSeeField: (
      profile: Pick<UserProfile, "employeeGroup" | "user"> | null,
      currentUser: Pick<User, "url">,
      field: keyof Pick<User, "loginIdentifier"> | keyof UserProfile,
    ) => {
      let passAdditionalFieldRequirements = true;

      if (field === "employeeNumber") {
        passAdditionalFieldRequirements = config.userProfileEmployeeNumber;
      }

      if (field === "cellphoneExtra") {
        passAdditionalFieldRequirements = config.userCellphoneExtra;
      }

      if (field === "externalDataloenIdentifier") {
        passAdditionalFieldRequirements = Object.values(config.remunerationGroups).some(
          (group) => group.dataloen,
        );
      }

      if (field === "externalLessorIdentifier") {
        passAdditionalFieldRequirements =
          config.enableLessorUpload || config.enableMaanssonsSalaryUpload;
      }

      if (field === "employeeGroup") {
        if (profile) {
          passAdditionalFieldRequirements = config.enableEmployeeGroups || !!profile.employeeGroup;
        }
      }

      const isRestrictedField = ownerOrManagerMembers.has(field);
      const currentUserIsProfileOwner = profile?.user === currentUser.url;

      return (
        (!isRestrictedField || currentUserIsProfileOwner || roleIsManagerOrProjectManager) &&
        passAdditionalFieldRequirements
      );
    },
  };
}
