import {Config} from "@co-common-libs/config";
import {Machine, MachineUrl, Task} from "@co-common-libs/resources";
import {
  economicProjectImportEnabled,
  EconomicProjectSyncEnabledCustomerSettings,
  notUndefined,
} from "@co-common-libs/utils";
import {EconomicMachinesIssue} from "../types";

export function economicMachineIssues({
  customerSettings,
  machineLookup,
  task,
}: {
  customerSettings: EconomicProjectSyncEnabledCustomerSettings &
    Pick<Config, "economicEnableMachinesDepartmentSync">;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  task: Pick<Task, "machineuseSet">;
}): readonly EconomicMachinesIssue[] {
  const issues: EconomicMachinesIssue[] = [];
  if (
    customerSettings.economicEnableMachinesDepartmentSync ||
    economicProjectImportEnabled(customerSettings)
  ) {
    const machinesUsed = task.machineuseSet
      .map(({machine}) => machineLookup(machine))
      .filter(notUndefined);

    const isBarred = machinesUsed.filter(({barred}) => barred);
    if (isBarred.length) {
      issues.push({
        category: "error",
        instances: isBarred,
        issueType: "barred",
        resourceName: "machine",
      });
    } else {
      const inactive = machinesUsed.filter(({active}) => !active);
      if (inactive.length) {
        issues.push({
          category: "warning",
          instances: inactive,
          issueType: "inactive",
          resourceName: "machine",
        });
      }
    }

    const missingRemoteUrl = machinesUsed.filter(({remoteUrl}) => !remoteUrl);
    if (missingRemoteUrl.length) {
      issues.push({
        category: "error",
        instances: missingRemoteUrl,
        issueType: "missingRemoteUrl",
        resourceName: "machine",
      });
    }

    const missingRemoteIdentifier = machinesUsed.filter(
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ({c5_machine}) => !c5_machine,
    );
    if (missingRemoteIdentifier.length) {
      issues.push({
        category: "error",
        instances: missingRemoteIdentifier,
        issueType: "missingRemoteIdentifier",
        resourceName: "machine",
      });
    }
  }
  return issues;
}
