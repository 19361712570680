import {defaultToEmptyString} from "@co-common-libs/utils";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {useResettingState} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";

interface PriceGroupDialogProps {
  inputName?: string;
  onCancel: () => void;
  onOk: (name: string, identifier: string) => void;
  open: boolean;
}

export const PriceGroupDialog = function PriceGroupDialog(
  props: PriceGroupDialogProps,
): React.JSX.Element {
  const intl = useIntl();
  const {inputName, onCancel, onOk, open} = props;

  const [identifier, setIdentifier] = useResettingState<string>("", open);
  const [name, setName] = useResettingState<string>(defaultToEmptyString(inputName), open);

  const handleOk = useCallback((): void => {
    onOk(name, identifier);
  }, [identifier, name, onOk]);

  return (
    <ResponsiveDialog
      okDisabled={!name || !identifier}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={intl.formatMessage({defaultMessage: "Opret variant"})}
    >
      <DialogContent>
        <TrimTextField
          autoFocus
          fullWidth
          key="pricegroup-identifier-field"
          label={intl.formatMessage({defaultMessage: "ID-Nummer/Kode"})}
          margin="dense"
          onChange={setIdentifier}
          value={identifier}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          key="pricegroup-name-field"
          label={intl.formatMessage({defaultMessage: "Navn"})}
          margin="dense"
          onChange={setName}
          value={name}
          variant="outlined"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
};
