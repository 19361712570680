import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PatchOperation,
  PatchUnion,
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUse,
  ProductUseWithOrder,
  ResourceTypeUnion,
  Role,
  Spray,
  SprayLocation,
  SprayLocationUrl,
  SprayLog as SprayLogResource,
  Task,
  TaskUrl,
  Unit,
  UnitUrl,
  User,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {notUndefined, sortByOrderMember} from "@co-common-libs/utils";
import {DeleteDialog} from "@co-frontend-libs/components";
import {ConnectedProductDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  AppState,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPriceItemLookup,
  getProductArray,
  getProductGroupLookup,
  getProductLookup,
  getSprayArray,
  getSprayLocationArray,
  getSprayLocationLookup,
  getSprayLogArray,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  CustomerSelectCreateDialog,
  ProductGroupTreeDialog,
  SprayDialog,
  SprayLocationDialog,
  updateSprayArea,
  WeatherDialog,
  WindDirection,
} from "app-components";
import {computePatch} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {SprayLogCard} from "./spray-log-card";

const messages = defineMessages({
  contractorWork: {
    defaultMessage: "Entreprenørarbejde",
    id: "spray-log-card.label.contractor-work",
  },
});

interface SprayLogStateProps {
  currentRole: Role | null;
  currentUserURL: string | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productArray: readonly Product[];
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  sprayArray: readonly Spray[];
  sprayLocationArray: readonly SprayLocation[];
  sprayLocationLookup: (url: SprayLocationUrl) => SprayLocation | undefined;
  sprayLogArray: readonly SprayLogResource[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface SprayLogDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  registerTaskPosition: (taskUrl: TaskUrl) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface SprayLogOwnProps {
  addDialogs: (dialogs: readonly React.JSX.Element[]) => void;
  completed: boolean;
  disabled: boolean;
  machineOperator?: User | undefined;
  onRequestBuildReports: () => void;
  sprayLog: SprayLogResource;
  task: Task;
  userIsOnlyMachineOperator: boolean;
  userIsOtherMachineOperator: boolean;
  validated: boolean;
}

type SprayLogProps = SprayLogDispatchProps & SprayLogOwnProps & SprayLogStateProps;

interface SprayLogState {
  customerDialogCallback: ((customer: Customer) => void) | null;
  deleteLocation: string | null;
  productDialogCallback: ((urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>) => void) | null;
  sprayDialogEdit: Spray | null;
  sprayDialogLocation: SprayLocation | null;
  sprayLocationDialog: {
    area: number | null;
    crop: string;
    customer: Customer | null;
    location: Location | null;
    note: string;
    open: boolean;
    url: SprayLocationUrl | null;
  };
  weatherDialogOpen: boolean;
}

class SprayLog extends React.Component<SprayLogProps, SprayLogState> {
  static contextType = IntlContext;

  context!: React.ContextType<typeof IntlContext>;

  state: SprayLogState = {
    customerDialogCallback: null,
    deleteLocation: null,
    productDialogCallback: null,
    sprayDialogEdit: null,
    sprayDialogLocation: null,
    sprayLocationDialog: {
      area: null,
      crop: "",
      customer: null,
      location: null,
      note: "",
      open: false,
      url: null,
    },
    weatherDialogOpen: false,
  };

  componentDidMount(): void {
    this.handleDialogs(this.props, this.state);
  }

  @bind
  handleCustomerDialogCancel(): void {
    this.setState({customerDialogCallback: null});
  }
  @bind
  handleCustomerDialogOk(url: CustomerUrl): void {
    const callback = this.state.customerDialogCallback;
    this.setState({customerDialogCallback: null});
    const customer = this.props.customerLookup(url);
    if (customer && callback) {
      callback(customer);
    }
  }

  @bind
  handleDeleteLocationCancel(): void {
    this.setState({deleteLocation: null});
  }

  @bind
  handleDeleteLocationOk(): void {
    const {deleteLocation} = this.state;
    if (deleteLocation) {
      this.setState({deleteLocation: null, sprayDialogLocation: null});
      this.props.remove(deleteLocation);
    }
  }
  @bind
  handleDialogCancel(): void {
    this.setState({sprayDialogEdit: null, sprayDialogLocation: null});
  }
  handleDialogs(props: SprayLogProps, state: SprayLogState): void {
    const {
      customerLookup,
      locationLookup,
      priceItemLookup,
      productArray,
      productGroupLookup,
      productLookup,
      sprayLog,
      task,
      unitLookup,
      userIsOnlyMachineOperator,
      userIsOtherMachineOperator,
    } = props;

    const sprayDialogCustomer =
      state.sprayDialogLocation && state.sprayDialogLocation.customer
        ? customerLookup(state.sprayDialogLocation.customer)
        : undefined;
    const pickupDialogNote = undefined;
    const priceItemUseList = state.sprayDialogEdit
      ? state.sprayDialogEdit.priceitemuseSet
      : sortByOrderMember(Object.values(task.priceItemUses || {})).map((p) => {
          return {...p, count: null};
        });
    const productUseList = state.sprayDialogEdit ? state.sprayDialogEdit.productuseSet : [];

    const sprayLogURL = sprayLog.url;
    const sprayLocationDialog = (
      <SprayLocationDialog
        area={state.sprayLocationDialog.area != null ? state.sprayLocationDialog.area : undefined}
        canDelete={!userIsOtherMachineOperator}
        crop={state.sprayLocationDialog.crop}
        customer={state.sprayLocationDialog.customer || undefined}
        isNew={!state.sprayLocationDialog.url}
        key={`spraylog-location-${sprayLogURL}`}
        location={state.sprayLocationDialog.location || undefined}
        note={state.sprayLocationDialog.note}
        onCancel={this.handleLocationDialogCancel}
        onOk={this.handleLocationDialogOk}
        onRequestCustomerDialog={this.handleRequestCustomerDialog}
        onRequestDelete={this.handleLocationDelete}
        open={state.sprayLocationDialog.open}
      />
    );
    const sprayDialog = (
      <SprayDialog
        currentRole={this.props.currentRole}
        customer={sprayDialogCustomer}
        customerSettings={this.props.customerSettings}
        isNew={!state.sprayDialogEdit}
        key={`spraylog-pickup-${sprayLogURL}`}
        locationLookup={locationLookup}
        note={pickupDialogNote}
        onCancel={this.handleDialogCancel}
        onOk={this.handleSprayDialogOk}
        onRequestLocationDialog={this.handleRequestLocationDialog}
        onRequestProductSelectionDialog={this.handleRequestProductSelectionDialog}
        open={!!state.sprayDialogLocation}
        priceItemLookup={priceItemLookup}
        priceItemUseList={priceItemUseList}
        productArray={productArray}
        productGroupLookup={productGroupLookup}
        productLookup={productLookup}
        productUseList={productUseList}
        sprayLocation={state.sprayDialogLocation || undefined}
        task={task}
        unitLookup={unitLookup}
        userIsOnlyMachineOperator={userIsOnlyMachineOperator}
        userIsOtherMachineOperator={userIsOtherMachineOperator}
      />
    );

    const deleteLocationDialog = (
      <DeleteDialog
        key={`spraylog-delete-location-${sprayLogURL}`}
        onCancel={this.handleDeleteLocationCancel}
        onOk={this.handleDeleteLocationOk}
        open={!!state.deleteLocation}
      >
        <FormattedMessage defaultMessage="Slet mark?" id="spray-log.label.delete-location" />
      </DeleteDialog>
    );

    const weatherDialog = (
      <WeatherDialog
        key={`weather-dialog-${sprayLogURL}`}
        nozzleType={sprayLog.nozzleType || ""}
        onCancel={this.handleWeatherDialogCancel}
        onOk={this.handleWeatherDialogOk}
        open={state.weatherDialogOpen}
        sun={sprayLog.sun}
        temperature={sprayLog.temperature != null ? sprayLog.temperature : undefined}
        windDirection={sprayLog.windDirection}
        windSpeed={sprayLog.windSpeed != null ? sprayLog.windSpeed : undefined}
      />
    );
    const customerDialog = (
      <CustomerSelectCreateDialog
        key={`spraylog-customer-dialog-${sprayLogURL}`}
        onCancel={this.handleCustomerDialogCancel}
        onOk={this.handleCustomerDialogOk}
        open={!!state.customerDialogCallback}
      />
    );

    const productDialog = this.props.customerSettings.productImageSelection ? (
      <ProductGroupTreeDialog
        key="spraylog-product-dialog"
        onCancel={this.handleProductDialogCancel}
        onOk={this.handleProductDialogOk}
        open={!!state.productDialogCallback}
      />
    ) : (
      <ConnectedProductDialog
        key="spraylog-product-dialog"
        onCancel={this.handleProductDialogCancel}
        onOk={this.handleProductDialogOk}
        open={!!state.productDialogCallback}
      />
    );

    props.addDialogs([
      sprayDialog,
      sprayLocationDialog,
      deleteLocationDialog,
      weatherDialog,
      customerDialog,
      productDialog,
    ]);
  }

  @bind
  handleLocationDelete(): void {
    const existingURL = this.state.sprayLocationDialog.url;
    this.handleLocationDialogCancel();
    this.setState({deleteLocation: existingURL});
  }
  @bind
  handleLocationDialogCancel(): void {
    const sprayLocationDialog = {
      area: null,
      crop: "",
      customer: null,
      location: null,
      note: "",
      open: false,
      url: null,
    };
    this.setState({sprayLocationDialog});
  }
  @bind
  handleLocationDialogOk(params: {
    address: string;
    area: number | null;
    crop: string;
    customer: Customer | null;
    location: Location | null;
    note: string;
  }): void {
    const {area, crop, customer, location, note} = params;
    const existingURL = this.state.sprayLocationDialog.url;
    const {task} = this.props;
    const taskURL = task.url;
    let newLocation: SprayLocation;
    if (existingURL) {
      const oldLocation = this.props.sprayLocationLookup(existingURL);
      if (!oldLocation) {
        return;
      }
      newLocation = {
        ...oldLocation,
        area,
        crop,
        customer: customer ? customer.url : null,
        note,
        relatedLocation: location ? location.url : null,
      };
      const patch = computePatch(newLocation, oldLocation);
      if (patch) {
        this.props.update(oldLocation.url, patch);
      }
      const spray = this.state.sprayDialogEdit;
      if (spray) {
        const sprayPriceItemUse = spray.priceitemuseSet;
        if (sprayPriceItemUse) {
          const newSprayPriceItemUse = updateSprayArea(
            area || 0,
            sprayPriceItemUse,
            this.props.priceItemLookup,
            this.props.unitLookup,
          );
          if (!_.isEqual(newSprayPriceItemUse, sprayPriceItemUse)) {
            this.props.update(spray.url, [
              {member: "priceitemuseSet", value: newSprayPriceItemUse},
            ]);
            this.setState({
              sprayDialogEdit: {
                ...spray,
                priceitemuseSet: newSprayPriceItemUse,
              },
            });
          }
        }
      }
    } else {
      const id = uuid();
      const url = instanceURL("sprayLocation", id);
      const sprayLog = this.props.sprayLogArray.find((t) => t.task === taskURL);
      if (!sprayLog) {
        return;
      }
      const sprayLogURL = sprayLog.url;
      const order = this.props.sprayLocationArray.filter((d) => d.spraylog === sprayLogURL).length;
      newLocation = {
        area,
        crop,
        customer: customer ? customer.url : null,
        id,
        note,
        order,
        relatedLocation: location ? location.url : null,
        spraylog: sprayLogURL,
        url,
      };
      this.props.create(newLocation);
    }
    this.handleLocationDialogCancel();
    this.setState({sprayDialogLocation: newLocation});
  }

  @bind
  handleProductDialogCancel(): void {
    this.setState({productDialogCallback: null});
  }
  @bind
  handleProductDialogOk(urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>): void {
    const {productDialogCallback} = this.state;
    if (productDialogCallback) {
      productDialogCallback(urlOrURLs);
      this.setState({
        productDialogCallback: null,
      });
    }
  }
  @bind
  handleRequestCustomerDialog(callback: (customer: Customer) => void): void {
    this.setState({customerDialogCallback: callback});
  }

  @bind
  handleRequestLocationDialog(sprayLocation: SprayLocation | null): void {
    let sprayLocationDialog;
    if (sprayLocation) {
      sprayLocationDialog = {
        area: sprayLocation.area,
        crop: sprayLocation.crop,
        customer:
          (sprayLocation.customer && this.props.customerLookup(sprayLocation.customer)) || null,
        location:
          (sprayLocation.relatedLocation &&
            this.props.locationLookup(sprayLocation.relatedLocation)) ||
          null,
        note: sprayLocation.note,
        open: true,
        url: sprayLocation.url,
      };
    } else {
      const {task} = this.props;
      const orderURL = task && task.order;
      const order = orderURL ? this.props.orderLookup(orderURL) : null;
      const customerURL = order && order.customer;
      const customer = (customerURL && this.props.customerLookup(customerURL)) || null;
      sprayLocationDialog = {
        area: null,
        crop: "",
        customer,
        location: null,
        note: "",
        open: true,
        url: null,
      };
    }
    this.setState({sprayLocationDialog});
  }

  @bind
  handleRequestProductSelectionDialog(
    callback: (urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>) => void,
  ): void {
    this.setState({productDialogCallback: callback});
  }

  @bind
  handleRequestSprayDialog(sprayLocation: SprayLocation): void {
    const sprayLocationURL = sprayLocation.url;
    const spray = this.props.sprayArray.find((s) => s.location === sprayLocationURL) || null;
    this.setState({sprayDialogEdit: spray, sprayDialogLocation: sprayLocation});
  }

  @bind
  handleRequestWeatherDialog(): void {
    this.setState({weatherDialogOpen: true});
  }

  @bind
  handleSprayDialogOk(params: {
    priceItemUseList: readonly PriceItemUse[];
    productUseList: readonly ProductUse[];
  }): void {
    const {priceItemUseList, productUseList} = params;
    const sprayLocation = this.state.sprayDialogLocation;
    if (!sprayLocation) {
      return;
    }
    const existing = this.state.sprayDialogEdit;
    this.setState({sprayDialogEdit: null, sprayDialogLocation: null});
    let spray: Spray;
    if (existing) {
      spray = {
        ...existing,
        priceitemuseSet: priceItemUseList,
        productuseSet: productUseList,
      };
      const patch = computePatch(spray, existing);
      if (patch) {
        this.props.update(existing.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("spray", id);
      spray = {
        deviceTimestamp: new Date().toISOString(),
        id,
        location: sprayLocation.url,
        priceitemuseSet: priceItemUseList,
        productuseSet: productUseList,
        url,
      };
      this.props.create(spray);
    }
    const priceItemUseListSum = new Map<string, number>();
    const productUseListSum = new Map<
      string,
      {count: number; notes: string; ours: boolean; product: ProductUrl}
    >();
    const sprayLogURL = this.props.sprayLog.url;
    this.props.sprayLocationArray
      .filter((p) => p.spraylog === sprayLogURL)
      .forEach((location) => {
        const locationURL = location.url;
        let locationSpray;

        //hack to get the data that was just entered
        if (locationURL === spray.location) {
          locationSpray = spray;
        } else {
          locationSpray = this.props.sprayArray.find((s) => s.location === locationURL);
        }
        if (locationSpray) {
          locationSpray.priceitemuseSet.forEach((priceItemUse) => {
            const priceItemURL = priceItemUse.priceItem;
            priceItemUseListSum.set(
              priceItemURL,
              (priceItemUseListSum.get(priceItemURL) || 0) + (priceItemUse.count || 0),
            );
          });
          locationSpray.productuseSet.forEach((productUse) => {
            const productURL = productUse.product;
            const {ours} = productUse;
            const sumKey = productURL + ours;
            const notes = productUse.notes || "";
            const existingSumEntry = productUseListSum.get(sumKey);

            if (!existingSumEntry) {
              productUseListSum.set(sumKey, {
                count: productUse.count || 0,
                notes,
                ours: productUse.ours,
                product: productURL,
              });
            } else {
              existingSumEntry.count += productUse.count || 0;
              existingSumEntry.notes += ` ${notes}`;
            }
          });
        }
      });

    const decimals = 2;
    const priceItemUsesPatch = _.sortBy(
      Object.entries(this.props.task.priceItemUses || {}),
      ([_identifier, priceItemUse]) => priceItemUse.order,
    ).map(([identifier, priceItemUse]) => {
      const patchOperation: PatchOperation<Task> = {
        path: ["priceItemUses", identifier, "count"],
        value: _.round(priceItemUseListSum.get(priceItemUse.priceItem) || 0, decimals),
      };
      return patchOperation;
    });
    this.props.update(this.props.task.url, priceItemUsesPatch);

    const productUsesPatch: PatchOperation<Task>[] = [];
    const observedKeys = new Set<string>();
    let newOrder = 0;
    _.sortBy(
      Object.entries(this.props.task.productUses || {}),
      ([_identifier, productUse]) => productUse.order,
    ).forEach(([identifier, productUse]) => {
      const key = productUse.product + productUse.ours;
      observedKeys.add(key);
      const newData = productUseListSum.get(key);
      if (newData) {
        console.assert(newData.product === productUse.product);
        console.assert(newData.ours === productUse.ours);
        const newCount = _.round(newData.count, decimals);
        if (newCount !== productUse.count) {
          productUsesPatch.push({
            path: ["productUses", identifier, "count"],
            value: newCount,
          });
        }
        if (newData.notes !== productUse.notes) {
          productUsesPatch.push({
            path: ["productUses", identifier, "notes"],
            value: newData.notes,
          });
        }
      }
      newOrder = Math.max(newOrder, productUse.order + 1);
    });
    productUseListSum.forEach((value, key) => {
      if (observedKeys.has(key)) {
        return;
      }
      const newProductUse: ProductUseWithOrder = {
        ...value,
        addedBy: null,
        correctedCount: null,
        order: newOrder,
      };
      const newIdentifier = uuid();
      productUsesPatch.push({
        path: ["productUses", newIdentifier],
        value: newProductUse,
      });
      newOrder += 1;
    });

    this.registerGeolocation();
  }

  @bind
  handleWeatherDialogCancel(): void {
    this.setState({weatherDialogOpen: false});
  }

  @bind
  handleWeatherDialogOk(params: {
    nozzleType: string;
    sun: "" | "clear" | "clouds" | "overcast";
    temperature: number | null;
    windDirection: WindDirection;
    windSpeed: number | null;
  }): void {
    this.setState({weatherDialogOpen: false});
    const {sprayLog} = this.props;
    const {nozzleType, sun, temperature, windDirection, windSpeed} = params;
    const updated: SprayLogResource = {
      ...this.props.sprayLog,
      nozzleType,
      sun,
      temperature,
      windDirection,
      windSpeed,
    };
    const patch = computePatch(updated, sprayLog);
    if (patch) {
      this.props.update(sprayLog.url, patch);
    }
  }

  @bind
  registerGeolocation(): void {
    const {customerSettings, registerTaskPosition, sprayLog} = this.props;
    if (customerSettings.geolocation.registerPositionOnTimerClick) {
      const taskURL = sprayLog.task;
      registerTaskPosition(taskURL);
    }
  }

  render(): React.JSX.Element {
    const {completed, disabled, machineLookup, sprayLog, task, validated, workTypeLookup} =
      this.props;
    const {formatMessage} = this.context;

    const machineList = ((task && task.machineuseSet) || [])
      .map((machineUse) => {
        const machineURL = machineUse.machine;
        return machineLookup(machineURL);
      })
      .filter(notUndefined);

    let workTypeString = "";
    const workTypeURL = task ? task.workType : null;
    const workTypeInstance = workTypeURL && workTypeLookup(workTypeURL);
    if (workTypeInstance) {
      workTypeString = getWorkTypeString(workTypeInstance);
    } else {
      if (
        this.props.customerSettings.enableExternalTaskDepartmentField &&
        task.department === "E"
      ) {
        workTypeString = formatMessage(messages.contractorWork);
      } else {
        let primaryMachine;
        if (machineList.length === 1) {
          primaryMachine = machineList[0];
        } else {
          primaryMachine = machineList.find((machine) => !machine.canPull);
          if (!primaryMachine) {
            primaryMachine = machineList.find((machine) => machine.selfPropelled);
          }
          if (!primaryMachine) {
            primaryMachine = machineList[0];
          }
        }
        if (primaryMachine) {
          workTypeString = `${primaryMachine.c5_machine}: ${primaryMachine.name}`;
        }
      }
    }

    return (
      <SprayLogCard
        completed={completed}
        disabled={disabled}
        onRequestBuildReports={this.props.onRequestBuildReports}
        onRequestLocationDialog={this.handleRequestLocationDialog}
        onRequestSprayDialog={this.handleRequestSprayDialog}
        onRequestWeatherDialog={this.handleRequestWeatherDialog}
        sprayLog={sprayLog}
        validated={validated}
        workTypeString={workTypeString}
      />
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: SprayLogProps): void {
    const {
      customerLookup,
      locationLookup,
      priceItemLookup,
      productLookup,
      sprayArray,
      sprayLocationArray,
      sprayLocationLookup,
      sprayLog,
      userIsOnlyMachineOperator,
      userIsOtherMachineOperator,
    } = nextProps;

    if (
      this.props.locationLookup === locationLookup &&
      this.props.sprayLog === sprayLog &&
      this.props.userIsOtherMachineOperator === userIsOtherMachineOperator &&
      this.props.userIsOnlyMachineOperator === userIsOnlyMachineOperator &&
      this.props.customerLookup === customerLookup &&
      this.props.sprayLocationArray === sprayLocationArray &&
      this.props.sprayLocationLookup === sprayLocationLookup &&
      this.props.sprayArray === sprayArray &&
      this.props.priceItemLookup === priceItemLookup &&
      this.props.productLookup === productLookup
    ) {
      return;
    }
    this.handleDialogs(nextProps, this.state);
  }

  UNSAFE_componentWillUpdate(nextProps: SprayLogProps, nextState: SprayLogState): void {
    if (this.state !== nextState) {
      this.handleDialogs(nextProps, nextState);
    }
  }
}

const ConnectedSprayLog: React.ComponentType<SprayLogOwnProps> = connect<
  SprayLogStateProps,
  SprayLogDispatchProps,
  SprayLogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, SprayLogStateProps>({
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    machineLookup: getMachineLookup,
    orderLookup: getOrderLookup,
    priceItemLookup: getPriceItemLookup,
    productArray: getProductArray,
    productGroupLookup: getProductGroupLookup,
    productLookup: getProductLookup,
    sprayArray: getSprayArray,
    sprayLocationArray: getSprayLocationArray,
    sprayLocationLookup: getSprayLocationLookup,
    sprayLogArray: getSprayLogArray,
    unitLookup: getUnitLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    create: actions.create,
    registerTaskPosition: actions.registerTaskPosition,
    remove: actions.remove,
    update: actions.update,
  },
)(SprayLog);

export {ConnectedSprayLog as SprayLog};
