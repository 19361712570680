import {CsvImportResult} from "@co-common-libs/csv-import-specifications";
import {ResourceInstance, ResourceTypeUnion} from "@co-common-libs/resources";
import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {jsonFetch, ResponseWithData} from "@co-frontend-libs/utils";
import {CircularProgress, DialogContent, LinearProgress} from "@material-ui/core";
import {globalConfig} from "frontend-global-config";
import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {formatImportError} from "./import-errors";

export type UploadStatus =
  | {readonly error: string; readonly sent: false}
  | {readonly jobId: string; readonly sent: true}
  | null;

type CsvImportJobStatusResponse =
  | {readonly data: CsvImportResult<ResourceInstance>; readonly done: true}
  | {readonly done: false; readonly progress: number};

type BackgroundJobStatus =
  | {readonly data: CsvImportResult<ResourceInstance>; readonly done: true}
  | {readonly done: false; readonly progress: number}
  | null;

const POLL_TIMEOUT_SECONDS = 1;

const PERCENT_PARTS = 100;

interface BackgroundSyncStatusProps {
  columnLabels: ReadonlyMap<string, string>;
  jobId: string;
}

const BackgroundSyncStatus = React.memo(function BackgroundSyncStatus(
  props: BackgroundSyncStatusProps,
): React.JSX.Element {
  const {columnLabels, jobId} = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const [error, setError] = useState<unknown | null>(null);

  const [backgroundJobStatus, setBackgroundJobStatus] = useState<BackgroundJobStatus>(null);

  useEffect(() => {
    const url = new URL(globalConfig.baseURL);
    url.pathname = "/api/csv_import_job_status/";
    url.searchParams.append("jobId", jobId);
    let cancelled = false;
    let timeoutId = 0;

    const fetchAgain = (): void => {
      const handleResponse = (response: ResponseWithData): void => {
        if (cancelled) {
          return;
        }
        setError(null);
        const responseData = response.data as CsvImportJobStatusResponse | null;
        if (responseData) {
          setBackgroundJobStatus(responseData);
        }
        if (responseData?.done) {
          if (Array.isArray(responseData.data.instances)) {
            dispatch(actions.addToOffline(responseData.data.instances as ResourceTypeUnion[]));
          }
          timeoutId = 0;
        } else {
          timeoutId = window.setTimeout(fetchAgain, SECOND_MILLISECONDS * POLL_TIMEOUT_SECONDS);
        }
      };
      const handleError = (err: unknown): void => {
        if (cancelled) {
          return;
        }
        setError(err);
        timeoutId = window.setTimeout(fetchAgain, SECOND_MILLISECONDS * POLL_TIMEOUT_SECONDS);
      };
      // eslint-disable-next-line promise/catch-or-return
      jsonFetch(`${url}`, "GET").then(handleResponse, handleError);
    };

    fetchAgain();

    return () => {
      cancelled = true;
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [dispatch, jobId]);

  let errorMessage: string | undefined;
  if (backgroundJobStatus && backgroundJobStatus.done && backgroundJobStatus.data.errors) {
    errorMessage = formatImportError(intl, backgroundJobStatus.data.errors, columnLabels);
  } else if (error) {
    errorMessage = `${error}`;
  }

  return (
    <>
      <FormattedMessage defaultMessage="Opdaterer" tagName="div" />
      {backgroundJobStatus === null ? <LinearProgress variant="indeterminate" /> : null}
      {backgroundJobStatus ? (
        <LinearProgress
          value={backgroundJobStatus.done ? PERCENT_PARTS : backgroundJobStatus.progress}
          variant="determinate"
        />
      ) : null}
      {errorMessage ? (
        <FormattedMessage
          defaultMessage="Fejl ved import: {error}"
          values={{error: errorMessage}}
        />
      ) : null}
      {backgroundJobStatus && backgroundJobStatus.done ? (
        <>
          <FormattedMessage
            defaultMessage="{count, number} Oprettet."
            tagName="div"
            values={{count: backgroundJobStatus.data.createCount}}
          />
          <FormattedMessage
            defaultMessage="{count, number} Opdateret."
            tagName="div"
            values={{count: backgroundJobStatus.data.updateCount}}
          />
        </>
      ) : null}
      {backgroundJobStatus && backgroundJobStatus.done && !errorMessage ? (
        <FormattedMessage defaultMessage="Status: Færdig" />
      ) : null}
    </>
  );
});

interface ImportStatusDialogProps {
  columnLabels: ReadonlyMap<string, string>;
  onOk: () => void;
  open: boolean;
  uploadStatus: UploadStatus;
}

export const ImportStatusDialog = React.memo(function ImportStatusDialog(
  props: ImportStatusDialogProps,
): React.JSX.Element {
  const {columnLabels, onOk, open, uploadStatus} = props;

  const intl = useIntl();

  return (
    <ResponsiveDialog
      okDisabled={!uploadStatus}
      onOk={onOk}
      open={open}
      title={intl.formatMessage({
        defaultMessage: "Importeringsstatus",
      })}
    >
      <DialogContent>
        {!uploadStatus ? (
          <div style={{textAlign: "center"}}>
            <CircularProgress />
          </div>
        ) : null}
        {uploadStatus && !uploadStatus.sent ? (
          <FormattedMessage
            defaultMessage="Fejl ved import: {error}"
            values={{error: uploadStatus.error}}
          />
        ) : null}
        {uploadStatus && uploadStatus.sent ? (
          <BackgroundSyncStatus columnLabels={columnLabels} jobId={uploadStatus.jobId} />
        ) : null}
      </DialogContent>
    </ResponsiveDialog>
  );
});
