import {Culture, CultureUrl} from "@co-common-libs/resources";
import {
  ColumnSpecifications,
  GenericTable,
  iconColumnSpecification,
  RowData,
} from "@co-frontend-libs/components";
import {actions, AppState, getCultureArray, getTableSortingState} from "@co-frontend-libs/redux";
import {bind} from "bind-decorator";
import _ from "lodash";
import CheckIcon from "mdi-react/CheckIcon";
import memoize from "memoize-one";
import React from "react";
import {defineMessages, IntlContext, IntlShape} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const TABLE_SORTING_IDENTIFIER = "CultureTable";
const MAX_CULTURES_DISPLAYED = 100;

const messages = defineMessages({
  active: {
    defaultMessage: "Aktiv",
    id: "culture-list.table-header.active",
  },
  name: {
    defaultMessage: "Navn",
    id: "culture-list.table-header.name",
  },
});

type CultureTableFieldID = "active" | "name";

type CultureTableColumnID = "active" | "name";

interface CultureTableDataType extends RowData<CultureTableFieldID, CultureUrl> {
  active: boolean;
  name: string;
}

function renderActive(data: CultureTableDataType): React.JSX.Element {
  return data.active ? <CheckIcon /> : <span />;
}

function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onClick?: (orderURL: string) => void,
): ColumnSpecifications<
  CultureTableFieldID,
  CultureTableColumnID,
  CultureUrl,
  CultureTableDataType
> {
  return {
    active: iconColumnSpecification({
      disableSorting: false,
      field: "active",
      label: formatMessage(messages.active),
      render: renderActive,
      width: 36,
    }),
    name: {
      field: "name",
      label: formatMessage(messages.name),
      onClick,
    },
  };
}

function computeVisibleColumns(onlyActive: boolean): readonly CultureTableColumnID[] {
  if (onlyActive) {
    return ["name"];
  } else {
    return ["name", "active"];
  }
}

function filterCultures(cultureArray: readonly Culture[], onlyActive: boolean): readonly Culture[] {
  if (onlyActive) {
    return cultureArray.filter((culture) => culture.active);
  } else {
    return cultureArray;
  }
}

function sortCultures(cultureArray: readonly Culture[]): readonly Culture[] {
  return _.sortBy(cultureArray, [
    (culture) => culture.name,
    (culture) => culture.identifier,
    (culture) => culture.url,
  ]);
}

function buildRowData(cultureArray: readonly Culture[]): readonly CultureTableDataType[] {
  return cultureArray.map((culture) => {
    const {active, name, url} = culture;
    return {active, key: url, name};
  });
}

interface CultureTableStateProps {
  cultureArray: readonly Culture[];
  sortingState: {
    readonly sortDirection: "ASC" | "DESC";
    readonly sortKey: string | null;
  };
}

interface CultureTableDispatchProps {
  putTableSortingState: (
    identifier: string,
    sortKey: string,
    sortDirection: "ASC" | "DESC",
  ) => void;
}

interface CultureTableOwnProps {
  filterString: string;
  onClick?: ((cultureURL: string) => void) | undefined;
  onlyActive: boolean;
}

type CultureTableProps = CultureTableDispatchProps & CultureTableOwnProps & CultureTableStateProps;

class CultureTable extends React.PureComponent<CultureTableProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  private buildColumnSpecifications: (
    formatMessage: IntlShape["formatMessage"],
    onClick?: (orderURL: string) => void,
  ) => ColumnSpecifications<
    CultureTableFieldID,
    CultureTableColumnID,
    CultureUrl,
    CultureTableDataType
  >;
  private buildRowData: (cultureArray: readonly Culture[]) => readonly CultureTableDataType[];
  private computeVisibleColumns: (onlyActive: boolean) => readonly CultureTableColumnID[];
  private filterCultures: (
    cultureArray: readonly Culture[],
    onlyActive: boolean,
  ) => readonly Culture[];
  private sortCultures: (cultureArray: readonly Culture[]) => readonly Culture[];
  constructor(props: CultureTableProps) {
    super(props);
    this.buildColumnSpecifications = memoize(buildColumnSpecifications);
    this.computeVisibleColumns = memoize(computeVisibleColumns);
    this.filterCultures = memoize(filterCultures);
    this.sortCultures = memoize(sortCultures);
    this.buildRowData = memoize(buildRowData);
  }
  @bind
  handleHeaderClick(key: CultureTableColumnID): void {
    const {putTableSortingState, sortingState} = this.props;
    let direction: "ASC" | "DESC" = "ASC";
    if (sortingState.sortKey === key && sortingState.sortDirection === "ASC") {
      direction = "DESC";
    }
    putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {cultureArray, filterString, onClick, onlyActive, sortingState} = this.props;
    const columnSpecifications = this.buildColumnSpecifications(formatMessage, onClick);
    const visibleColumns = this.computeVisibleColumns(onlyActive);
    const filteredCultureArray = this.filterCultures(cultureArray, onlyActive);
    const filteredSortedCultureArray = this.sortCultures(filteredCultureArray);
    const data = this.buildRowData(filteredSortedCultureArray);
    return (
      <GenericTable
        columns={columnSpecifications}
        entries={data}
        filterString={filterString}
        maxDisplayed={MAX_CULTURES_DISPLAYED}
        onHeaderClick={this.handleHeaderClick}
        sortBy={sortingState.sortKey as any}
        sortDirection={sortingState.sortDirection}
        visibleColumns={visibleColumns}
      />
    );
  }
}

const ConnectedCultureTable: React.ComponentType<CultureTableOwnProps> = connect<
  CultureTableStateProps,
  CultureTableDispatchProps,
  CultureTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, CultureTableStateProps>({
    cultureArray: getCultureArray,
    sortingState: getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"),
  }),
  {
    putTableSortingState: actions.putTableSortingState,
  },
)(CultureTable);

export {ConnectedCultureTable as CultureTable};
