import {EmployeeGroup, EmployeeGroupUrl, User, UserProfile} from "@co-common-libs/resources";
import {getUserArray, getUserProfileArray} from "@co-frontend-libs/redux";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericMultiSelectionSearchDialogProps,
} from "../search-dialog";

export function employeeGroupsBaseChoices(
  employeeGroupArray: readonly EmployeeGroup[],
  nameLabel: string,
  userProfiles: readonly UserProfile[],
  users: readonly User[],
): readonly EntryData<EmployeeGroupUrl>[] {
  let entries = employeeGroupArray.map((employeeGroup) => {
    const entry: EntryData<EmployeeGroupUrl> = {
      category: "standard",
      exactMatchValue: employeeGroup.name,
      identifier: employeeGroup.url,
      primaryText: employeeGroup.name,
      searchFields: [{label: nameLabel, priority: 5, text: employeeGroup.name}],
    };
    return entry;
  });

  const usersNotInEmployeeGroup = users.filter((user) => {
    return (
      user.active &&
      userProfiles.some((userProfile) => {
        return !userProfile.employeeGroup && userProfile.user === user.url;
      })
    );
  });

  if (usersNotInEmployeeGroup.length) {
    const noEmployeeGroup: EntryData<EmployeeGroupUrl> = {
      category: "standard",
      exactMatchValue: "Uden medarbejdergruppe",
      identifier: "" as EmployeeGroupUrl,
      primaryText: "Uden medarbejdergruppe",
      priority: true,
      searchFields: [{label: nameLabel, priority: 5, text: "Uden medarbejdergruppe"}],
    };
    entries = [noEmployeeGroup, ...entries];
  }
  return entries;
}
export interface MultipleEmployeeGroupDialogProps
  extends Pick<
    GenericMultiSelectionSearchDialogProps<EmployeeGroupUrl>,
    "includeSelectAll" | "onCancel" | "onOk" | "open" | "selected"
  > {
  employeeGroupArray: readonly EmployeeGroup[];
}

export const MultipleEmployeeGroupDialog = React.memo(function MultipleEmployeeGroupDialog(
  props: MultipleEmployeeGroupDialogProps,
) {
  const {employeeGroupArray, includeSelectAll, onCancel, onOk, open, selected} = props;
  const userProfiles = useSelector(getUserProfileArray);
  const users = useSelector(getUserArray);

  const intl = useIntl();
  const title = intl.formatMessage({defaultMessage: "Vælg medarbejdergrupper"});
  const searchTitle = intl.formatMessage({
    defaultMessage: "Søg medarbejdergrupper",
  });
  const data = useMemo(() => {
    return employeeGroupsBaseChoices(employeeGroupArray, "Name", userProfiles, users);
  }, [employeeGroupArray, userProfiles, users]);

  return (
    <GenericMultiSelectionSearchDialog<EmployeeGroupUrl>
      data={data}
      includeSelectAll={includeSelectAll}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      sorting="PRIMARY_IDENTIFIER"
      title={title}
    />
  );
});
