import {resourceNames} from "@co-common-libs/resources";
import {Query} from "@co-frontend-libs/db-resources";
import {current, Draft} from "@reduxjs/toolkit";
import {computeNextFetchChangesTimestamp, fullFetchesPending} from "../../middleware/utils";
import {
  temporaryQueriesDiscardedForKey,
  temporaryQueriesDiscardedForPath,
  temporaryQueriesRequestedForKey,
  temporaryQueriesRequestedForPath,
} from "../actions";
import {emptyQueryState, ResourcesState} from "../types";
import {computeInstancesToFetchFromAddedQueries} from "./compute-instances-to-fetch-from-added-queries";
import {recomputeTemporaryQueriesPendingRemoval} from "./recompute-temporary-queries-pending-removal";
import {getAllResourcesData} from "./selectors";
import {
  combineResourceFetchByID,
  combineResourceFetchByRelation,
} from "./update-from-received-data";

export function handleTemporaryQueriesRequestedForPath(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof temporaryQueriesRequestedForPath>,
): void {
  const {forceReloadRelated, key, pathName, queries} = action.payload;
  console.assert(queries.length);

  const keyStrings = queries.map((query) => query.keyString);
  console.assert(new Set(keyStrings).size === keyStrings.length);

  const queriesForPathName = (state.temporaryQueriesForPathNames[pathName] =
    state.temporaryQueriesForPathNames[pathName] || {});
  const oldQueriesForPathNameKey = queriesForPathName[key];
  queriesForPathName[key] = keyStrings;

  const addedQueries: Query[] = [];

  queries.forEach((query) => {
    const {keyString} = query;
    const existing = state.temporaryQueries[keyString];
    if (existing) {
      existing.queryState.pendingRemoval = false;
    } else {
      state.temporaryQueries[keyString] = {
        query: query as Draft<Query>,
        queryState: emptyQueryState,
      };
      addedQueries.push(query);
    }
  });

  const updatedState = current(state) as ResourcesState;

  const allData = getAllResourcesData(updatedState);

  if (addedQueries.length) {
    const {toFetchByID, toFetchByRelation} = computeInstancesToFetchFromAddedQueries(
      allData,
      addedQueries,
      forceReloadRelated,
    );
    resourceNames.forEach((resourceName) => {
      const resourceTemporaryFetchByID = toFetchByID[resourceName];
      if (resourceTemporaryFetchByID?.size) {
        state.temporaryFetchByID[resourceName] = combineResourceFetchByID(
          state.temporaryFetchByID[resourceName],
          resourceTemporaryFetchByID,
        );
        const fetchByIdForResource = state.temporaryFetchByID[resourceName];
        if (fetchByIdForResource) {
          const uuidLength = 36;
          fetchByIdForResource.forEach((id) => {
            console.assert(id.length === uuidLength, `not an UUID: ${id}`);
          });
        }
      }
      const resourceTemporaryFetchByRelation = toFetchByRelation[resourceName];
      if (resourceTemporaryFetchByRelation) {
        state.temporaryFetchByRelation[resourceName] = combineResourceFetchByRelation(
          state.temporaryFetchByRelation[resourceName],
          resourceTemporaryFetchByRelation,
        );
      }
    });
  }

  if (oldQueriesForPathNameKey) {
    recomputeTemporaryQueriesPendingRemoval(state);
  }
  if (!fullFetchesPending(state)) {
    state.lastFetchChangesTimestamp = computeNextFetchChangesTimestamp(state);
  }
}

export function handleTemporaryQueriesDiscardedForPath(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof temporaryQueriesDiscardedForPath>,
): void {
  const {key, pathName} = action.payload;
  const queriesForPathName = state.temporaryQueriesForPathNames[pathName];
  const queryForPathNameAndKey = queriesForPathName?.[key];
  if (process.env.NODE_ENV !== "production") {
    if (!queriesForPathName || !queryForPathNameAndKey) {
      // eslint-disable-next-line no-console
      console.debug(`clearing no queries for ${pathName} / ${key}`);
    }
  }
  if (queriesForPathName && queryForPathNameAndKey) {
    if (Object.keys(queriesForPathName).length === 1) {
      delete state.temporaryQueriesForPathNames[pathName];
    } else {
      delete queriesForPathName[key];
    }
    recomputeTemporaryQueriesPendingRemoval(state);
  }
  if (!fullFetchesPending(state)) {
    state.lastFetchChangesTimestamp = computeNextFetchChangesTimestamp(state);
  }
}

export function handleTemporaryQueriesRequestedForKey(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof temporaryQueriesRequestedForKey>,
): void {
  const {forceReloadRelated, key, queries} = action.payload;
  console.assert(queries.length);

  const keyStrings = queries.map((query) => query.keyString);
  console.assert(new Set(keyStrings).size === keyStrings.length);

  const oldQueriesForKey = state.temporaryQueriesForKeys[key];
  state.temporaryQueriesForKeys[key] = keyStrings;

  const addedQueries: Query[] = [];

  queries.forEach((query) => {
    const {keyString} = query;
    const existing = state.temporaryQueries[keyString];
    if (existing) {
      existing.queryState.pendingRemoval = false;
    } else {
      state.temporaryQueries[keyString] = {
        query: query as Draft<Query>,
        queryState: emptyQueryState,
      };
      addedQueries.push(query);
    }
  });

  const updatedState = current(state) as ResourcesState;

  const allData = getAllResourcesData(updatedState);

  if (addedQueries.length) {
    const {toFetchByID, toFetchByRelation} = computeInstancesToFetchFromAddedQueries(
      allData,
      addedQueries,
      forceReloadRelated,
    );
    resourceNames.forEach((resourceName) => {
      const resourceTemporaryFetchByID = toFetchByID[resourceName];
      if (resourceTemporaryFetchByID?.size) {
        state.temporaryFetchByID[resourceName] = combineResourceFetchByID(
          state.temporaryFetchByID[resourceName],
          resourceTemporaryFetchByID,
        );
        const fetchByIdForResource = state.temporaryFetchByID[resourceName];
        if (fetchByIdForResource) {
          const uuidLength = 36;
          fetchByIdForResource.forEach((id) => {
            console.assert(id.length === uuidLength, `not an UUID: ${id}`);
          });
        }
      }
      const resourceTemporaryFetchByRelation = toFetchByRelation[resourceName];
      if (resourceTemporaryFetchByRelation) {
        state.temporaryFetchByRelation[resourceName] = combineResourceFetchByRelation(
          state.temporaryFetchByRelation[resourceName],
          resourceTemporaryFetchByRelation,
        );
      }
    });
  }

  if (oldQueriesForKey) {
    recomputeTemporaryQueriesPendingRemoval(state);
  }
  if (!fullFetchesPending(state)) {
    state.lastFetchChangesTimestamp = computeNextFetchChangesTimestamp(state);
  }
}

export function handleTemporaryQueriesDiscardedForKey(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof temporaryQueriesDiscardedForKey>,
): void {
  const {key} = action.payload;
  const queryForKey = state.temporaryQueriesForKeys[key];
  console.assert(queryForKey);
  if (queryForKey) {
    delete state.temporaryQueriesForKeys[key];
    recomputeTemporaryQueriesPendingRemoval(state);
  }
  if (!fullFetchesPending(state)) {
    state.lastFetchChangesTimestamp = computeNextFetchChangesTimestamp(state);
  }
}
