import {CustomerUrl} from "@co-common-libs/resources";
import {CustomerDialog, MultipleCustomersDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getActiveContactArray,
  getActiveCustomerArray,
  getCurrentUserSelectableProjectArray,
  getCurrentUserURL,
  getCustomerSettings,
  getOrderLookup,
  getTaskArray,
} from "@co-frontend-libs/redux";
import React from "react";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";

const getSuggestRecentlyUsedCustomers: (state: AppState) => boolean = createSelector(
  getCustomerSettings,
  (customerSettings) => customerSettings.suggestRecentlyUsedCustomers,
);

interface CustomerDialogProps {
  onAdd?: ((searchString: string) => void) | undefined;
  onCancel(): void;
  onNone?: () => void;
  onOk(url: CustomerUrl): void;
  open: boolean;
}

export const ConnectedCustomerDialog = React.memo(function ConnectedCustomerDialog(
  props: CustomerDialogProps,
): React.JSX.Element {
  const activeContactArray = useSelector(getActiveContactArray);
  const currentUserURL = useSelector(getCurrentUserURL);
  const activeCustomerArray = useSelector(getActiveCustomerArray);
  const orderLookup = useSelector(getOrderLookup);
  const activeProjectArray = useSelector(getCurrentUserSelectableProjectArray);
  const suggestRecentlyUsed = useSelector(getSuggestRecentlyUsedCustomers);
  const taskArray = useSelector(getTaskArray);
  const customerSettings = useSelector(getCustomerSettings);

  return (
    <CustomerDialog
      contactArray={activeContactArray}
      currentUserURL={currentUserURL}
      customerArray={activeCustomerArray}
      customerSettings={customerSettings}
      orderLookup={orderLookup}
      projectArray={activeProjectArray}
      suggestRecentlyUsed={suggestRecentlyUsed}
      taskArray={taskArray}
      {...props}
    />
  );
});

interface MultipleCustomersDialogProps {
  onCancel(): void;
  onOk(urls: ReadonlySet<CustomerUrl>): void;
  open: boolean;
  selected?: ReadonlySet<CustomerUrl>;
}

export const ConnectedMultipleCustomersDialog = React.memo(
  function ConnectedMultipleCustomersDialog(
    props: MultipleCustomersDialogProps,
  ): React.JSX.Element {
    const customerSettings = useSelector(getCustomerSettings);
    const activeContactArray = useSelector(getActiveContactArray);
    const activeCustomerArray = useSelector(getActiveCustomerArray);
    const activeProjectArray = useSelector(getCurrentUserSelectableProjectArray);

    return (
      <MultipleCustomersDialog
        contactArray={activeContactArray}
        customerArray={activeCustomerArray}
        customerSettings={customerSettings}
        projectArray={activeProjectArray}
        {...props}
      />
    );
  },
);
