import {identifierComparator} from "@co-common-libs/utils";
import {OfflineAwareAppBar, SlideUpTransition, TrimTextField} from "@co-frontend-libs/components";
import {getCustomerSettings, getLocationTypeArray} from "@co-frontend-libs/redux";
import {jsonFetch, ResponseWithData} from "@co-frontend-libs/utils";
import {
  alpha,
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Toolbar,
  useTheme,
} from "@material-ui/core";
import {useEventTargetValueCallback} from "app-utils";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import CloseIcon from "mdi-react/CloseIcon";
import SearchIcon from "mdi-react/SearchIcon";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {DialogFieldListItem} from "./dialog-field-list-item";
import {convertRemoteFieldFeatures, getKey, LocationPart, OnlineFieldLocationPart} from "./utils";

const messages = defineMessages({
  cvrNoSearch: {
    defaultMessage: "CVR-nr.",
    id: "field-list.placeholder.cvr-no-search",
  },
});

// styling mostly from
// https://material-ui.com/components/app-bar/#app-bar-with-a-primary-search-field

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "fixed",
    },
    inputInput: {
      "&::placeholder": {
        color: "#fff",
        opacity: 0.87,
      },
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    inputRoot: {
      color: "inherit",
    },
    noPadding: {
      padding: 0,
    },
    search: {
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      marginRight: 0,
      position: "relative",
      width: "100%",
    },
    searchIcon: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "none",
      position: "absolute",
      width: theme.spacing(7),
    },
  }),
);

interface FetchFieldsDialogProps {
  fullscreen?: boolean;
  initialSearch: string;
  onCancel: () => void;
  onOk: (locations: OnlineFieldLocationPart[]) => void;
  open: boolean;
}

export function FetchFieldsDialog(props: FetchFieldsDialogProps): React.JSX.Element | null {
  const {fullscreen, initialSearch, onCancel, onOk, open} = props;

  const customerSettings = useSelector(getCustomerSettings);
  const locationTypeArray = useSelector(getLocationTypeArray);

  const [searchString, setSearchString] = useState(initialSearch);
  const [foundFields, setFoundFields] = useState<OnlineFieldLocationPart[]>([]);
  const [selected, setSelected] = useState(new Set<string>());
  const [fetchState, setFetchState] = useState<"FAILED" | "FETCHED" | "FETCHING" | "INITIAL">(
    "INITIAL",
  );

  useEffect(() => {
    if (!open) {
      setSearchString(initialSearch);
      setSelected(new Set<string>());
      setFoundFields([]);
      setFetchState("INITIAL");
    }
  }, [open, initialSearch]);

  const handleOk = useCallback(() => {
    onOk(foundFields.filter((location) => selected.has(getKey(location))));
  }, [foundFields, onOk, selected]);

  const handleSearchFieldChange = useEventTargetValueCallback(setSearchString, [setSearchString]);

  const abortController = useRef<AbortController>();

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);
  useEffect(() => {
    if (!open && abortController.current) {
      abortController.current.abort();
      abortController.current = undefined;
    }
  }, [open]);

  const handleResponse = useCallback(
    (response: ResponseWithData) => {
      const fieldLocationType = customerSettings.fieldDefaultLocationType
        ? locationTypeArray.find(
            (locationType) => locationType.identifier === customerSettings.fieldDefaultLocationType,
          )
        : null;
      const locations = convertRemoteFieldFeatures(response.data, fieldLocationType?.url ?? null);
      setFoundFields(locations);
      setFetchState("FETCHED");
      abortController.current = undefined;
    },
    [customerSettings.fieldDefaultLocationType, locationTypeArray],
  );

  const handleError = useCallback((error: Error) => {
    setFetchState("FAILED");
    // eslint-disable-next-line no-console
    console.error(error);
    abortController.current = undefined;
  }, []);

  const handleSearchButtonClick = useCallback(() => {
    setSelected(new Set<string>());
    setFoundFields([]);
    if (searchString && fetchState !== "FETCHING") {
      const url = `${globalConfig.baseURL}/api/remote_field_search/?cvr=${encodeURIComponent(
        searchString,
      )}`;
      setFetchState("FETCHING");
      if (window.AbortController) {
        if (abortController.current) {
          abortController.current.abort();
        }
        abortController.current = new AbortController();
      }
      return jsonFetch(url, "GET", null, abortController.current?.signal).then(
        handleResponse,
        handleError,
      );
    } else {
      return null;
    }
  }, [fetchState, handleError, handleResponse, searchString]);

  const handleSearchFieldKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleSearchButtonClick();
      }
    },
    [handleSearchButtonClick],
  );

  const handleToggle = useCallback(
    (location: LocationPart) => {
      const key = getKey(location);
      const newSelected = new Set(selected);
      if (selected.has(key)) {
        newSelected.delete(key);
      } else {
        newSelected.add(key);
      }
      setSelected(newSelected);
    },
    [selected],
  );

  const handleToggleAll = useCallback(() => {
    if (selected.size === foundFields.length) {
      setSelected(new Set<string>());
    } else {
      setSelected(new Set(foundFields.map((field) => getKey(field))));
    }
  }, [foundFields, selected.size]);

  const theme = useTheme();

  const classes = useStyles();

  const fullscreenLayout = fullscreen ?? !!(bowser.mobile || bowser.tablet);

  let content: React.JSX.Element | undefined;

  if (fetchState === "FETCHING") {
    content = (
      <div style={{marginTop: 16, textAlign: "center"}}>
        <CircularProgress />
      </div>
    );
  } else if (fetchState === "FETCHED") {
    if (foundFields.length) {
      const listItems = foundFields
        .slice()
        .sort(
          (a, b) =>
            identifierComparator(a.fieldNumber, b.fieldNumber) ||
            identifierComparator(a.fieldBlock, b.fieldBlock),
        )
        .map((location) => {
          const key = getKey(location);
          return (
            <DialogFieldListItem
              checked={selected.has(key)}
              key={key}
              location={location}
              onToggle={handleToggle}
            />
          );
        });
      content = (
        <DialogContent className={classes.noPadding} dividers>
          <List>
            <ListItem button onClick={handleToggleAll}>
              <ListItemIcon>
                <Checkbox
                  checked={selected.size === foundFields.length}
                  disableRipple
                  edge="start"
                  indeterminate={selected.size > 0 && selected.size < foundFields.length}
                  onChange={handleToggleAll}
                  tabIndex={-1}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage defaultMessage="Vælg alle" id="field-list.label.select-all" />
                }
              />
            </ListItem>
            <Divider />
            {listItems}
          </List>
        </DialogContent>
      );
    } else {
      content = (
        <DialogContent>
          <FormattedMessage
            defaultMessage="Der findes ingen marker i databasen på det søgte CVR nummer."
            id="field-list.text.fields-not-found"
          />
        </DialogContent>
      );
    }
  } else if (fetchState === "FAILED") {
    content = (
      <DialogContent>
        <span style={{color: theme.palette.error.main}}>
          <FormattedMessage
            defaultMessage="Der skete en fejl"
            id="field-list.text.field-fetch-error"
          />
        </span>
      </DialogContent>
    );
  }

  const {formatMessage} = useIntl();

  const title = (
    <FormattedMessage
      defaultMessage="Hent marker for CVR-nr."
      id="field-list.dialog-title.fetch-field-cvr"
    />
  );

  if (fullscreenLayout) {
    return (
      <Dialog fullScreen onClose={onCancel} open={open} TransitionComponent={SlideUpTransition}>
        <OfflineAwareAppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={onCancel}>
              <CloseIcon />
            </IconButton>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                autoFocus
                classes={{
                  input: classes.inputInput,
                  root: classes.inputRoot,
                }}
                disabled={fetchState === "FETCHING"}
                onChange={handleSearchFieldChange}
                onKeyDown={handleSearchFieldKeyDown}
                placeholder={formatMessage(messages.cvrNoSearch)}
                value={searchString}
              />
            </div>
            <Button
              color="inherit"
              disabled={!searchString || fetchState === "FETCHING"}
              onClick={handleSearchButtonClick}
            >
              <FormattedMessage defaultMessage="Søg" id="field-list.label.search" />
            </Button>
            <Button color="inherit" disabled={!selected.size} onClick={handleOk}>
              <FormattedMessage defaultMessage="Importér" id="field-list.label.import" />
            </Button>
          </Toolbar>
        </OfflineAwareAppBar>
        <Toolbar />
        {content}
      </Dialog>
    );
  } else {
    return (
      <Dialog fullWidth maxWidth="md" onClose={onCancel} open={open} scroll="paper">
        <DialogTitle>
          {title}
          <Grid alignItems="baseline" container spacing={1}>
            <TrimTextField
              autoFocus
              disabled={fetchState === "FETCHING"}
              margin="dense"
              onChange={setSearchString}
              onKeyDown={handleSearchFieldKeyDown}
              placeholder={formatMessage(messages.cvrNoSearch)}
              style={{flexGrow: 1}}
              value={searchString}
              variant="outlined"
            />
            <Button
              color="primary"
              disabled={!searchString || fetchState === "FETCHING"}
              onClick={handleSearchButtonClick}
            >
              <FormattedMessage defaultMessage="Søg" id="field-list.label.search" />
            </Button>
          </Grid>
        </DialogTitle>
        {content}
        <DialogActions>
          <Button color="primary" onClick={onCancel}>
            <FormattedMessage defaultMessage="Fortryd" id="dialog.label.cancel" />
          </Button>
          <Button color="primary" disabled={!selected.size} onClick={handleOk}>
            <FormattedMessage defaultMessage="Importér" id="field-list.label.import" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
