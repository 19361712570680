import {ImportPreviewProductGroup} from "@co-common-libs/resources-utils";
import {ImportProductGroupsPreviewDialog} from "@co-frontend-libs/components";
import {getProductGroupArray} from "@co-frontend-libs/redux";
import {jsonFetch, translateNetworkError} from "@co-frontend-libs/utils";
import {useAsyncAbortable} from "@react-hookz/web";
import {externalIdMapFromArray, getExternalId} from "external-id-utils";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface ImportProductGroupsDialogProps {
  importType: "PRODUCT_GROUP" | "WORK_TYPE";
  onCancel(): void;
  onOk(productGroups: ImportPreviewProductGroup[]): void;
  open: boolean;
}

export const ImportProductGroupsDialog = React.memo(function ImportProductGroupsDialog(
  props: ImportProductGroupsDialogProps,
): React.JSX.Element {
  const {open} = props;

  const productGroupArray = useSelector(getProductGroupArray);

  const existingProductGroupsByExternalId = useMemo(
    () => externalIdMapFromArray(productGroupArray),
    [productGroupArray],
  );
  const {baseURL} = globalConfig.resources;
  const [state, actions] = useAsyncAbortable<readonly ImportPreviewProductGroup[] | null>(
    (signal) =>
      jsonFetch(`${baseURL}economic/product_groups/`, "GET", null, signal).then(({data}) => {
        return (data as ImportPreviewProductGroup[]).filter(
          (entry) => !existingProductGroupsByExternalId.get(getExternalId(entry))?.active,
        );
      }),
    null,
  );

  useEffect(() => {
    if (open) {
      actions.execute();
    } else {
      actions.reset();
    }
  }, [actions, open]);

  const onOk = useCallback(
    (identifiers: ReadonlySet<string>) => {
      props.onOk(
        (state.result || []).filter((productGroup) => identifiers.has(productGroup.identifier)),
      );
    },
    [state.result, props],
  );

  const intl = useIntl();

  return (
    <ImportProductGroupsPreviewDialog
      {...props}
      error={state.status === "error" ? translateNetworkError(state.error, intl) : undefined}
      onOk={onOk}
      previewProductGroups={state.result}
    />
  );
});
