import {
  ReportingChoiceAlternative,
  ReportingInputFormatSpecification,
} from "@co-common-libs/resources";
import React from "react";
import {defineMessages, IntlShape, useIntl} from "react-intl";

const messages = defineMessages({
  no: {
    defaultMessage: "Nej",
  },
  yes: {
    defaultMessage: "Ja",
  },
});

interface ReportingValueProps {
  formatSpecification: ReportingInputFormatSpecification;
  value?: boolean | number | string | string[];
}

const formatValue = (
  formatSpecification: ReportingInputFormatSpecification,
  intl: IntlShape,
  value: boolean | number | string | string[] | undefined,
): number | string | null => {
  const {type} = formatSpecification;
  if (value == null && type !== "boolean") {
    return null;
  }
  if (type === "boolean") {
    let checked = value;
    if (value == null) {
      checked = formatSpecification.booleanDefault;
    }
    if (checked) {
      return intl.formatMessage(messages.yes);
    } else {
      return intl.formatMessage(messages.no);
    }
  } else if (type === "text") {
    return value as string;
  } else if (type === "integer") {
    return value as number;
  } else if (type === "decimal") {
    // const maxDigits = formatSpecification.maxDigits;
    // const decimalPlaces = formatSpecification.decimalPlaces;
    return intl.formatNumber(value as number);
  } else if (type === "choice") {
    const alternatives = formatSpecification.alternatives as ReportingChoiceAlternative[];
    console.assert(alternatives);
    const chosen = alternatives.find((entry) => entry.identifier === value);
    if (chosen) {
      return chosen.label;
    }
  } else if (type === "multiple_choice") {
    const choices = formatSpecification.choices as ReportingChoiceAlternative[];
    const chosen = (value as string[])
      .map((valueIdentifier) => {
        const choice = choices.find(
          (entry) => entry.identifier === valueIdentifier,
        ) as ReportingChoiceAlternative;
        console.assert(choice);
        return choice.label;
      })
      .join(", ");
    if (chosen) {
      return chosen;
    }
  }
  return null;
};

export const ReportingValue = React.memo(function ReportingValue(
  props: ReportingValueProps,
): React.JSX.Element | null {
  const {formatSpecification, value} = props;
  const intl = useIntl();
  const formattedValue = formatValue(formatSpecification, intl, value);

  if (formattedValue == null) {
    return null;
  } else {
    return <span>{formattedValue}</span>;
  }
});
