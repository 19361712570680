import {Culture, CultureUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

const messages = defineMessages({
  searchCulture: {
    defaultMessage: "Søg kultur",
    id: "culture-dialog.dialog-title.search-culture",
  },
  searchCultures: {
    defaultMessage: "Søg kulturer",
    id: "culture-dialog.dialog-title.search-cultures",
  },
  selectCulture: {
    defaultMessage: "Vælg kultur",
    id: "culture-dialog.dialog-title.select-culture",
  },
  selectCultures: {
    defaultMessage: "Vælg kulturer",
    id: "culture-dialog.dialog-title.select-cultures",
  },
});

function computeBaseChoices(cultureArray: readonly Culture[]): readonly EntryData<CultureUrl>[] {
  const data: EntryData<CultureUrl>[] = [];
  cultureArray.forEach((instance) => {
    if (instance.active) {
      const {name, url} = instance;
      const entry: EntryData<CultureUrl> = {
        category: "standard",
        exactMatchValue: name,
        identifier: url,
        primaryText: name,
        searchFields: [{label: "Navn", priority: 5, text: name}],
      };
      data.push(entry);
    }
  });
  return data;
}

interface CultureDialogProps {
  cultureArray: readonly Culture[];
  onCancel(): void;
  onOk(url: CultureUrl): void;
  open: boolean;
}

export const CultureDialog = React.memo(function CultureDialog(props: CultureDialogProps) {
  const {cultureArray, onCancel, onOk, open} = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.selectCulture);
  const searchTitle = intl.formatMessage(messages.searchCulture);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(cultureArray);

  return (
    <GenericSingleSelectionSearchDialog<CultureUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={0}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      title={title}
    />
  );
});

interface MultipleCulturesDialogProps {
  cultureArray: readonly Culture[];
  onCancel(): void;
  onOk(urls: ReadonlySet<CultureUrl>): void;
  open: boolean;
  selected?: ReadonlySet<CultureUrl>;
}

export const MultipleCulturesDialog = React.memo(function MultipleCulturesDialog(
  props: MultipleCulturesDialogProps,
) {
  const {cultureArray, onCancel, onOk, open, selected} = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.selectCultures);
  const searchTitle = intl.formatMessage(messages.searchCultures);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(cultureArray);

  return (
    <GenericMultiSelectionSearchDialog<CultureUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={0}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      title={title}
    />
  );
});
