import {CustomerUrl, LocationUrl, Task} from "@co-common-libs/resources";
import {
  actions,
  getContactArray,
  getCurrentUserURL,
  getCustomerLookup,
  getExtendedCustomerSettings,
  getLocationArray,
  getOrderLookup,
  getTaskArray,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {useResettingState} from "@co-frontend-libs/utils";
import {taskChangeCustomerCulture} from "app-utils";
import React, {useCallback, useEffect, useMemo} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {LocationSelectCreateDialog} from "../../location-dialog";
import {ProcessingDialog} from "../../processing-dialog";

interface ChangeTaskCustomerProps {
  onFailure: () => void;
  onSuccess: () => void;
  open: boolean;
  task: Task;
  toCustomerUrl: CustomerUrl;
}

export const ChangeTaskCustomer = React.memo(function ChangeTaskCustomer(
  props: ChangeTaskCustomerProps,
): React.JSX.Element | null {
  const {onFailure, onSuccess, open, task, toCustomerUrl} = props;

  const [changeCustomerPromise, setChangeCustomerPromise] = useResettingState<Promise<void> | null>(
    null,
    !open,
  );

  const [workplaceSelectionDialogOpen, setWorkplaceSelectionDialogOpen] =
    useResettingState<boolean>(false, open);

  const dispatch = useDispatch();

  const handleChangeWorkplace = useCallback(
    (locationUrl: LocationUrl | null = null): void => {
      if (task.relatedWorkplace !== locationUrl) {
        dispatch(actions.update(task.url, [{member: "relatedWorkplace", value: locationUrl}]));
      }

      onSuccess();
    },
    [dispatch, onSuccess, task.relatedWorkplace, task.url],
  );

  const customerSettings = useSelector(getExtendedCustomerSettings);
  const locationArray = useSelector(getLocationArray);
  const contactArray = useSelector(getContactArray);
  const currentUserURL = useSelector(getCurrentUserURL);
  const customerLookup = useSelector(getCustomerLookup);
  const orderLookup = useSelector(getOrderLookup);
  const taskArray = useSelector(getTaskArray);
  const unitLookup = useSelector(getUnitLookup);

  const {
    locations: {canChangeWorkplace, canCreateWorkplace},
  } = customerSettings;

  const showWorkplaceSelectionAfterChange = useMemo(() => {
    const hasSelectableWorkPlaces = canChangeWorkplace
      ? locationArray.some(
          (location) =>
            location.customer === toCustomerUrl && location.active && !location.logOnlyLocation,
        )
      : locationArray.some(
          (location) =>
            location.remoteUrl && location.customer === toCustomerUrl && location.active,
        );

    return (
      customerSettings.taskWizardAskForWorkPlace && (canCreateWorkplace || hasSelectableWorkPlaces)
    );
  }, [
    canChangeWorkplace,
    canCreateWorkplace,
    customerSettings.taskWizardAskForWorkPlace,
    locationArray,
    toCustomerUrl,
  ]);

  const changeCustomer = useCallback(() => {
    taskChangeCustomerCulture(
      toCustomerUrl,
      null,
      {
        contactArray,
        create: bindActionCreators(actions.create, dispatch),
        customerLookup,
        orderLookup,
        task,
        taskArray,
        unitLookup,
        update: bindActionCreators(actions.update, dispatch),
      },
      customerSettings,
      currentUserURL,
    );
  }, [
    toCustomerUrl,
    contactArray,
    dispatch,
    customerLookup,
    orderLookup,
    task,
    taskArray,
    unitLookup,
    customerSettings,
    currentUserURL,
  ]);

  useEffect(() => {
    if (open) {
      setChangeCustomerPromise(
        Promise.resolve().then(() => {
          changeCustomer();
          return;
        }),
      );
    }
    // execute promise on open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleProcessingSuccess = useCallback(() => {
    if (showWorkplaceSelectionAfterChange) {
      setWorkplaceSelectionDialogOpen(true);
    } else {
      onSuccess();
    }
  }, [onSuccess, setWorkplaceSelectionDialogOpen, showWorkplaceSelectionAfterChange]);

  const intl = useIntl();

  // TODO: should we disallow cancel of workplace selection?
  //       or maybe we should make the user select new workplace before performing
  //       customer change.
  return (
    <>
      {changeCustomerPromise ? (
        <ProcessingDialog
          onFailure={onFailure}
          onSuccess={handleProcessingSuccess}
          processPromise={changeCustomerPromise}
          title={intl.formatMessage({defaultMessage: "Skifter kunde"})}
        />
      ) : null}
      <LocationSelectCreateDialog
        customerURL={toCustomerUrl}
        includeLogOnlyLocations={false}
        logOnlyLocation={false}
        machineOperator={task.machineOperator || undefined}
        onCancel={onSuccess}
        onNone={customerSettings.requireWorkplaceIfExists ? undefined : handleChangeWorkplace}
        onOk={handleChangeWorkplace}
        open={workplaceSelectionDialogOpen}
        titleVariant="WORKPLACE"
      />
    </>
  );
});
