import {ThrottledTextField} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Grid, IconButton, Paper} from "@material-ui/core";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {CalculatorButton} from "../calculator-button";
import {MaterialField} from "../material-field";
import {EntryProps, withEntryCallbacks} from "./with-entry-callbacks";

const NOTE_FIELD_CELL_PADDING = 12;

export const DisplayGridItem = React.memo(function DisplayTableRow(
  props: EntryProps,
): React.JSX.Element {
  const {
    autoProduct,
    calculator,
    countValue,
    editable,
    inputUnitSymbol,
    notes,
    notesEditable,
    onCountChange,
    onDetailButtonClick,
    onNotesChange,
    onPlusClick,
    onRelationCountChange,
    productName,
    productNumber,
    relationConversionUnitSymbol,
    relationConversionValue,
    relationCountValue,
    showNotes,
    showRelationConversionColumns,
  } = props;

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);

  const {alternativeMaterialsMobileLayout} = customerSettings;

  const productGridItemStyle: React.CSSProperties = {
    overflow: "hidden",
    paddingBottom: 10,
    paddingTop: 12,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  if (showNotes && alternativeMaterialsMobileLayout) {
    productGridItemStyle.paddingLeft = NOTE_FIELD_CELL_PADDING;
  }

  return (
    <Paper
      style={{
        backgroundColor: "rgb(247, 247, 247)",
        marginBottom: 2,
      }}
    >
      <Grid container>
        {onDetailButtonClick && !alternativeMaterialsMobileLayout ? (
          <Grid item style={{paddingBottom: 10, paddingTop: 10, width: 24}} xs="auto">
            <IconButton
              color="primary"
              onClick={onDetailButtonClick || undefined}
              style={{height: "auto", padding: 0, width: "auto"}}
            >
              <FileDocumentIcon />
            </IconButton>
          </Grid>
        ) : null}
        <Grid item style={productGridItemStyle} xs>
          {productName} ({productNumber})
        </Grid>
        <Grid item xs={3}>
          <MaterialField
            customerSettings={customerSettings}
            disabled={!editable}
            hideLabel
            onChange={onCountChange}
            unit={inputUnitSymbol}
            value={countValue ?? undefined}
          />
        </Grid>
        <Grid item style={{paddingBottom: 10, paddingTop: 10, width: 24}} xs="auto">
          {!autoProduct ? (
            calculator ? (
              <CalculatorButton
                disabled={!editable}
                onInsertValue={onCountChange}
                specification={calculator}
                unit={inputUnitSymbol}
              />
            ) : (
              <IconButton
                color="primary"
                disabled={!editable}
                onClick={onPlusClick}
                style={{height: "auto", padding: 0, width: "auto"}}
              >
                <PlusIcon />
              </IconButton>
            )
          ) : null}
        </Grid>
        <Grid
          item
          style={{
            overflow: "hidden",
            paddingBottom: 10,
            paddingTop: 12,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          xs={2}
        >
          {inputUnitSymbol}
        </Grid>
      </Grid>
      {showRelationConversionColumns && relationConversionUnitSymbol ? (
        <Grid container>
          {onDetailButtonClick && !alternativeMaterialsMobileLayout ? (
            <Grid item style={{width: 24}} xs="auto" />
          ) : null}
          <Grid item xs>
            {/* name */}
          </Grid>
          <Grid item xs={3}>
            <MaterialField
              customerSettings={customerSettings}
              disabled={!editable || !relationConversionValue}
              hideLabel
              onChange={onRelationCountChange}
              unit={relationConversionUnitSymbol}
              value={relationCountValue ?? undefined}
            />
          </Grid>
          <Grid item style={{width: 24}} xs="auto">
            {/* plus button*/}
          </Grid>
          <Grid item style={{paddingBottom: 10, paddingTop: 10}} xs={2}>
            {relationConversionUnitSymbol}
          </Grid>
        </Grid>
      ) : null}
      {showNotes && alternativeMaterialsMobileLayout ? (
        <Grid container>
          <Grid
            item
            style={{
              paddingLeft: NOTE_FIELD_CELL_PADDING,
              paddingRight: NOTE_FIELD_CELL_PADDING,
            }}
            xs
          >
            <ThrottledTextField
              disabled={!notesEditable}
              fullWidth
              margin="dense"
              onChange={onNotesChange}
              placeholder={intl.formatMessage({defaultMessage: "Noter"})}
              value={notes}
              variant="outlined"
            />
          </Grid>
        </Grid>
      ) : null}
    </Paper>
  );
});

export const DisplayGridItemWithEntryCallbacks = withEntryCallbacks(DisplayGridItem);
