import {TableCell} from "@material-ui/core";
import React from "react";

interface RotatedTableHeaderColumnProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  width?: number;
}

export const RotatedTableHeaderColumn = React.memo(function RotatedTableHeaderColumn(
  props: RotatedTableHeaderColumnProps,
): React.JSX.Element {
  const {children, style, width, ...others} = props;
  const normalWidth = 25;
  return (
    <TableCell
      style={{
        height: 140,
        paddingLeft: 5,
        paddingRight: 5,
        whiteSpace: "nowrap",
        ...style,
      }}
      {...others}
    >
      <div
        style={{
          transform: `translate(${width ?? normalWidth}px, 51px) rotate(315deg)`,
          width: 30,
        }}
      >
        <span
          style={{
            borderBottom: "1px solid #ccc",
            padding: "5px 10px",
          }}
        >
          {children}
        </span>
      </div>
    </TableCell>
  );
});
