import type {Writable} from "ts-essentials";
import {
  Contact,
  Customer,
  CustomerUrl,
  emptyContact,
  emptyCustomer,
  PatchOperation,
  PatchUnion,
  ResourceTypeUnion,
} from "@co-common-libs/resources";
import {TrimTextField} from "@co-frontend-libs/components";
import {
  actions,
  AppState,
  getContactArray,
  getCustomerLookup,
  makeOptionalPathParameterGetter,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Button, Paper} from "@material-ui/core";
import {PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
// Allowed for existing code...
import React from "react";
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";

const messages = defineMessages({
  address: {
    defaultMessage: "Adresse",
    id: "customer-entry.label.address",
  },
  attention: {
    defaultMessage: "Att.",
    id: "customer-entry.label.attention",
  },
  c5Account: {
    defaultMessage: "Konto",
    id: "customer-entry.label.c5Account",
  },
  cellphone: {
    defaultMessage: "Mobil",
    id: "customer-entry.label.cellphone",
  },
  city: {
    defaultMessage: "By",
    id: "customer-entry.label.city",
  },
  editCustomer: {
    defaultMessage: "Redigér kunde",
    id: "customer-entry.label.edit-customer",
  },
  email: {
    defaultMessage: "Email",
    id: "customer-entry.label.email",
  },
  fax: {
    defaultMessage: "Fax",
    id: "customer-entry.label.fax",
  },
  name: {
    defaultMessage: "Navn",
    id: "customer-entry.label.name",
  },
  newCustomer: {
    defaultMessage: "Ny kunde",
    id: "customer-entry.label.new-customer",
  },
  phone: {
    defaultMessage: "Telefon",
    id: "customer-entry.label.phone",
  },
  postalCode: {
    defaultMessage: "Postnr.",
    id: "customer-entry.label.postal-code",
  },
  save: {
    defaultMessage: "Gem",
    id: "customer-entry.label.save",
  },
  vat: {
    defaultMessage: "CVR",
    id: "customer-entry.label.vat",
  },
});

const customerDefaults = {
  address: "",
  attention: "",

  c5_account: "",
  city: "",
  name: "",
  postalCode: "",
  vatNumber: "",
} as const;

const contactDefaults = {
  cellphone: "",
  email: "",
  fax: "",
  phone: "",
};

const defaults = Object.assign({}, customerDefaults, contactDefaults);

const customerFields = [
  "address",
  "attention",
  "c5_account",
  "city",
  "name",
  "postalCode",
  "vatNumber",
] as const;
const contactFields = ["cellphone", "email", "fax", "phone"] as const;

interface CustomerEntryStateProps {
  contactArray: readonly Contact[];
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  id: string | null;
}

interface CustomerEntryDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

type CustomerEntryProps = CustomerEntryDispatchProps & CustomerEntryStateProps;

interface CustomerEntryState {
  address: string;
  attention: string;

  c5_account: string;
  cellphone: string;
  city: string;
  email: string;
  fax: string;
  name: string;
  phone: string;
  postalCode: string;
  vatNumber: string;
}

function computeStateFromProps(props: CustomerEntryProps): CustomerEntryState {
  const {contactArray, customerLookup, id} = props;
  const customerURL = id ? instanceURL("customer", id) : null;
  const customer = customerURL && customerLookup(customerURL);
  const stateUpdate: CustomerEntryState = {...defaults};
  if (customer) {
    customerFields.forEach((field) => {
      stateUpdate[field] = customer[field] || "";
    });
  }

  const contact =
    customerURL &&
    contactArray.find((c) => c.customer === customerURL && c.defaultContact === true);
  if (contact) {
    contactFields.forEach((field) => {
      stateUpdate[field] = contact[field];
    });
  }
  return stateUpdate;
}

class CustomerEntry extends PureComponent<CustomerEntryProps, CustomerEntryState> {
  static contextType = IntlContext;

  context!: React.ContextType<typeof IntlContext>;
  state: CustomerEntryState = defaults;

  private _changeHandlers = new Map<keyof CustomerEntryState, (value: string) => void>();
  @bind
  getChangeHandler(name: keyof CustomerEntryState): (value: string) => void {
    const handler = this._changeHandlers.get(name);
    if (handler) {
      return handler;
    }
    const newHandler = (value: string): void => {
      const stateUpdate: Partial<CustomerEntryState> = {[name]: value};
      // Partial type does not discern between fields being present with
      // value undefined and fields being absent; but this matters to
      // setState()
      this.setState(stateUpdate as any);
    };
    this._changeHandlers.set(name, newHandler);
    return newHandler;
  }

  @bind
  handleSave(): void {
    const {id} = this.props;
    if (id) {
      const customerURL = instanceURL("customer", id);
      const customer = this.props.customerLookup(customerURL);
      const contact = this.props.contactArray.find(
        (c) => c.customer === customerURL && c.defaultContact === true,
      );
      if (!customer || !contact) {
        return;
      }

      const customerPatch: PatchOperation<Customer>[] = [];
      customerFields.forEach((field) => {
        const value = this.state[field];
        if (value !== customer[field]) {
          customerPatch.push({member: field, value});
        }
      });
      if (customerPatch.length) {
        this.props.update(customerURL, customerPatch);
      }

      const contactPatch: PatchOperation<Contact>[] = [];
      contactFields.forEach((field) => {
        const value = this.state[field];
        if (value !== contact[field]) {
          contactPatch.push({member: field, value});
        }
      });
      if (contactPatch.length) {
        this.props.update(contact.url, contactPatch);
      }
    } else {
      const customerId = uuid();
      const customerURL = instanceURL("customer", customerId);
      const customer: Writable<Customer> = {
        ...emptyCustomer,
        id: customerId,
        url: customerURL,
      };
      customerFields.forEach((field) => {
        const value = this.state[field];
        customer[field] = value;
      });
      this.props.create(customer);

      const contactId = uuid();
      const contactURL = instanceURL("contact", contactId);
      const contact: Writable<Contact> = {
        ...emptyContact,
        customer: customerURL,
        defaultContact: true,
        id: contactId,
        url: contactURL,
      };
      contactFields.forEach((field) => {
        const value = this.state[field];
        contact[field] = value;
      });
      this.props.create(contact);
      this.props.go("/customer/entry/:id", {id: customerId}, {}, "REPLACE");
    }
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    return (
      <PageLayout
        toolbar={formatMessage(this.props.id ? messages.editCustomer : messages.newCustomer)}
        withBottomScrollPadding
      >
        <Paper
          style={{
            margin: 5,
            padding: 10,
          }}
        >
          <Grid>
            <Cell palm="12/12">
              <TrimTextField
                fullWidth
                id="customer-name-field"
                label={`${formatMessage(messages.name)} *`}
                margin="dense"
                onChange={this.getChangeHandler("name")}
                value={this.state.name}
                variant="outlined"
              />
              <TrimTextField
                fullWidth
                id="customer-c5Account-field"
                label={formatMessage(messages.c5Account)}
                margin="dense"
                onChange={this.getChangeHandler("c5_account")}
                value={this.state.c5_account}
                variant="outlined"
              />
              <TrimTextField
                fullWidth
                id="customer-address-field"
                label={formatMessage(messages.address)}
                margin="dense"
                onChange={this.getChangeHandler("address")}
                value={this.state.address}
                variant="outlined"
              />
              <TrimTextField
                fullWidth
                id="customer-postalCode-field"
                label={formatMessage(messages.postalCode)}
                margin="dense"
                onChange={this.getChangeHandler("postalCode")}
                value={this.state.postalCode}
                variant="outlined"
              />
              <TrimTextField
                fullWidth
                id="customer-city-field"
                label={formatMessage(messages.city)}
                margin="dense"
                onChange={this.getChangeHandler("city")}
                value={this.state.city}
                variant="outlined"
              />
              <TrimTextField
                fullWidth
                id="customer-vat-field"
                label={formatMessage(messages.vat)}
                margin="dense"
                onChange={this.getChangeHandler("vatNumber")}
                value={this.state.vatNumber}
                variant="outlined"
              />
              <TrimTextField
                fullWidth
                id="customer-attention-field"
                label={formatMessage(messages.attention)}
                margin="dense"
                onChange={this.getChangeHandler("attention")}
                value={this.state.attention}
                variant="outlined"
              />
              <TrimTextField
                fullWidth
                id="customer-email-field"
                label={formatMessage(messages.email)}
                margin="dense"
                onChange={this.getChangeHandler("email")}
                value={this.state.email}
                variant="outlined"
              />
              <TrimTextField
                fullWidth
                id="customer-phone-field"
                label={formatMessage(messages.phone)}
                margin="dense"
                onChange={this.getChangeHandler("phone")}
                value={this.state.phone}
                variant="outlined"
              />
              <TrimTextField
                fullWidth
                id="customer-cellphone-field"
                label={formatMessage(messages.cellphone)}
                margin="dense"
                onChange={this.getChangeHandler("cellphone")}
                value={this.state.cellphone}
                variant="outlined"
              />
              <TrimTextField
                fullWidth
                id="customer-fax-field"
                label={formatMessage(messages.fax)}
                margin="dense"
                onChange={this.getChangeHandler("fax")}
                value={this.state.fax}
                variant="outlined"
              />

              <Button
                color="primary"
                disabled={this.state.name === ""}
                onClick={this.handleSave}
                style={{float: "right", marginTop: 2}}
                variant="contained"
              >
                {formatMessage(messages.save)}
              </Button>
              <FormattedMessage
                defaultMessage="Felter markeret med * er påkrævet"
                id="customer-entry.label.required"
              />
            </Cell>
          </Grid>
        </Paper>
      </PageLayout>
    );
  }

  UNSAFE_componentWillMount(): void {
    if (this.props.id) {
      this.setState(computeStateFromProps(this.props));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: CustomerEntryProps): void {
    if (nextProps.id !== this.props.id) {
      this.setState(computeStateFromProps(nextProps));
    }
  }
}

const ConnectedCustomerEntry = connect<
  CustomerEntryStateProps,
  CustomerEntryDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, CustomerEntryStateProps>({
    contactArray: getContactArray,
    customerLookup: getCustomerLookup,
    id: makeOptionalPathParameterGetter("id"),
  }),
  {
    create: actions.create,
    go: actions.go,
    update: actions.update,
  },
)(CustomerEntry);

export {ConnectedCustomerEntry as CustomerEntry};
