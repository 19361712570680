import {
  KrPerLiterFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecification,
} from "@co-common-libs/resources";
import {actions} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader, Checkbox, FormControlLabel} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";

interface FuelSurchargeInvoicingRuleProps {
  specification: KrPerLiterFuelSurchargeSpecification | PricePercentFuelSurchargeSpecification;
}

export const FuelSurchargeInvoicingRule = React.memo(function FuelSurchargeInvoicingRule(
  props: FuelSurchargeInvoicingRuleProps,
): React.JSX.Element {
  const {specification} = props;

  const {zeroSurchargeInvoiceLines} = specification;

  const intl = useIntl();

  const dispatch = useDispatch();

  const handleZeroSurchargeInvoiceLinesChange = useCallback(() => {
    dispatch(
      actions.update(specification.url, [
        {
          member: "zeroSurchargeInvoiceLines",
          value: !zeroSurchargeInvoiceLines,
        },
      ]),
    );
  }, [dispatch, specification.url, zeroSurchargeInvoiceLines]);

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage: "Faktureringsregel",
        })}
      />
      <CardContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={zeroSurchargeInvoiceLines}
              onChange={handleZeroSurchargeInvoiceLinesChange}
            />
          }
          label={intl.formatMessage(
            {
              defaultMessage:
                "Inkludér fakturalinjer med brændstoftillæg, også hvis tillægget er {zero, number, ::.00} kr/L",
            },
            {zero: 0},
          )}
        />
      </CardContent>
    </Card>
  );
});
