import {CustomerUrl, MachineUrl, PriceGroupUrl} from "@co-common-libs/resources";
import {assign, createMachine, StateMachine} from "@xstate/fsm";
import _ from "lodash";

type MachineEntry = {
  machine: MachineUrl;
  priceGroup: PriceGroupUrl | null;
  priceGroupOptions: PriceGroupUrl[];
};

interface MultiMachineSelectionContext {
  // entry that we are currently selecting price group for
  currentMachine: MachineEntry | null;
  customer: CustomerUrl | null;
  // entries that have been processed
  machines: MachineEntry[];
  // entries to be processed after currentMachine
  remainingMachines: MachineEntry[];
}

export type MultiMachineSelectionEvent =
  | {
      customer: CustomerUrl | null;
      type: "START";
    }
  | {
      machines: MachineEntry[];
      type: "MACHINES_SELECTED";
    }
  | {type: "CANCEL"}
  | {type: "PRICE_GROUP_SELECTED"; url: PriceGroupUrl};

function needsPriceGroupSelection(entry: {
  priceGroup: PriceGroupUrl | null;
  priceGroupOptions: PriceGroupUrl[];
}): boolean {
  const {priceGroup, priceGroupOptions} = entry;
  return !priceGroup && !!priceGroupOptions.length;
}

type MultiMachineSelectionTypeState =
  | {context: MultiMachineSelectionContext; value: "initial"}
  | {
      context: MultiMachineSelectionContext & {currentMachine: MachineEntry};
      value: "selectPriceGroups";
    }
  | {
      context: MultiMachineSelectionContext & {currentMachine: null};
      value: "selectMachines";
    };

export type MultiMachineSelectionState = StateMachine.State<
  MultiMachineSelectionContext,
  MultiMachineSelectionEvent,
  MultiMachineSelectionTypeState
>;

export const multiMachineSelectionStateMachine = createMachine<
  MultiMachineSelectionContext,
  MultiMachineSelectionEvent,
  MultiMachineSelectionTypeState
>({
  context: {
    currentMachine: null,
    customer: null,
    machines: [],
    remainingMachines: [],
  },
  id: "machineSelection",
  initial: "initial",
  states: {
    initial: {
      on: {
        START: {
          actions: assign({
            currentMachine: (_context, _event) => null,
            customer: (_context, event) => event.customer,
            machines: (_context, _event) => [],
            remainingMachines: (_context, _event) => [],
          }),
          target: "selectMachines",
        },
      },
    },
    selectMachines: {
      on: {
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
        MACHINES_SELECTED: [
          {
            actions: assign({
              // first where selection *is* necessary
              currentMachine: (_context, event) =>
                event.machines.find(needsPriceGroupSelection) as MachineEntry,
              // those at the start where selection is unnecessary
              machines: (_context, event) =>
                _.takeWhile(event.machines, _.negate(needsPriceGroupSelection)),
              // those after currentMachine
              remainingMachines: (_context, event) =>
                _.drop(_.dropWhile(event.machines, _.negate(needsPriceGroupSelection))),
            }),
            cond: (_context, event) => event.machines.some(needsPriceGroupSelection),
            target: "selectPriceGroups",
          },
          {
            actions: [
              assign({
                machines: (_context, event) => event.machines,
              }),
              "signalDone",
            ],
            target: "initial",
          },
        ],
      },
    },
    selectPriceGroups: {
      on: {
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
        PRICE_GROUP_SELECTED: [
          {
            actions: assign({
              // first from remainingMachines where selection *is* necessary
              currentMachine: (context, _event) =>
                context.remainingMachines.find(needsPriceGroupSelection) as MachineEntry,
              // add currentMachine and those at the start of remainingMachines
              // where selection is unnecessary
              machines: (context, event) =>
                context.machines.concat(
                  [
                    {
                      ...(context.currentMachine as MachineEntry),
                      priceGroup: event.url,
                    },
                  ],
                  _.takeWhile(context.remainingMachines, _.negate(needsPriceGroupSelection)),
                ),
              // those after currentMachine
              remainingMachines: (context, _event) =>
                _.drop(_.dropWhile(context.remainingMachines, _.negate(needsPriceGroupSelection))),
            }),
            cond: (context, _event) => context.remainingMachines.some(needsPriceGroupSelection),
            target: "selectPriceGroups",
          },
          {
            actions: [
              assign({
                currentMachine: (_context, _event) => null,
                machines: (context, event) =>
                  context.machines.concat(
                    [
                      {
                        ...(context.currentMachine as MachineEntry),
                        priceGroup: event.url,
                      },
                    ],
                    context.remainingMachines,
                  ),
                remainingMachines: (_context, _event) => [],
              }),
              "signalDone",
            ],
            target: "initial",
          },
        ],
      },
    },
  },
});
