import {alpha, createStyles, makeStyles, Theme} from "@material-ui/core";

// styling mostly from
// https://material-ui.com/components/app-bar/#app-bar-with-a-primary-search-field

export const useDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "fixed",
    },
    inputInput: {
      "&::placeholder": {
        color: "#fff",
        opacity: 0.87,
      },
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    inputRoot: {
      color: "inherit",
    },
    noPaddingNoOverflow: {
      overflow: "hidden",
      padding: 0,
    },
    search: {
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      marginRight: 0,
      position: "relative",
      width: "100%",
    },
    searchIcon: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "none",
      position: "absolute",
      width: theme.spacing(7),
    },
  }),
);

export const useListStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlight: {
      color: theme.palette.primary.main,
    },
    overflowOne: {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 1,
    },
    overflowTwo: {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
    },
  }),
);
