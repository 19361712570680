import {locale} from "@co-common-libs/utils";
import DateFnsUtils from "@date-io/date-fns";
import {MuiThemeProvider} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import bowser from "bowser";
import {dateFnsLocale, store, theme} from "frontend-global-config";
import React from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import {IntlProvider} from "react-intl";
import {Provider as ReduxProvider} from "react-redux";
import Initialized from "./initialized";
import {SimpleRouter} from "./simple-router";
import "./why-did-you-render-setup";

const DragDropBackend = bowser.mobile || bowser.tablet ? TouchBackend : HTML5Backend;

function App(_props: unknown): React.JSX.Element {
  return (
    <ReduxProvider store={store}>
      <MuiThemeProvider theme={theme}>
        <IntlProvider defaultLocale="da" locale={locale}>
          <MuiPickersUtilsProvider locale={dateFnsLocale} utils={DateFnsUtils}>
            <DndProvider
              backend={DragDropBackend}
              options={bowser.mobile || bowser.tablet ? {delay: 300} : undefined}
            >
              <Initialized>
                <SimpleRouter />
              </Initialized>
            </DndProvider>
          </MuiPickersUtilsProvider>
        </IntlProvider>
      </MuiThemeProvider>
    </ReduxProvider>
  );
}

export default App;
