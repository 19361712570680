import {PriceGroupUrl, PriceItemUrl, ProductUrl, UnitUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

// changed from interface to type due to
// https://github.com/microsoft/TypeScript/issues/15300
// causing VolumeDiscount to be incompatible with generated `validateVolumeDiscountPartHelper`
// that expect data param to be of type `{[key: string]: unknown}`
export type VolumeDiscount = {
  readonly discountPercentage: number | null;
  readonly threshold: number | null;
};

export interface PriceItem extends ResourceInstance<PriceItemUrl> {
  readonly active: boolean;
  readonly billable: boolean;
  readonly booleanSelection: boolean | undefined;
  readonly c5_recid: number | null;
  readonly contributesToFuelSurchargeSalesPrice: boolean | undefined;
  readonly conversionFactor: number | null;
  readonly conversionUnit: UnitUrl | null;
  readonly defaultCount: number | null;
  readonly genericEffectiveTimerTarget: boolean | null;
  readonly includeInLogs: boolean | undefined;
  /** C5-Agromat AGLinieType... */
  readonly itemtype: number | null;
  /** NAV Line_No */
  readonly lineNumber: number | null;
  readonly minimumCount: number | null;
  readonly name: string;
  readonly onlyVisibleOnOverview: boolean | null;
  readonly price: number | null;
  /** Backwards compatibility; don't use... */
  readonly priceGroup?: PriceGroupUrl | null;
  /** Backwards compatibility; don't use... */
  readonly priceGroups?: readonly PriceGroupUrl[];
  readonly product: ProductUrl | null;
  readonly relatedUnit: UnitUrl | null;
  readonly relevantForExecution: boolean | null;
  /** Identifier in synced billing system; not necessarily an URL. */
  readonly remoteUrl: string;
  readonly required: boolean | null;
  readonly requiredGreaterThanZero: boolean | null;
  readonly timeAfterMinutes: number | null | undefined;
  readonly transferAsMinutes: boolean | null;
  readonly transferWhenCountZero: boolean;
  readonly transferWhenNetPriceZero: boolean;
  readonly useManualDistribution: boolean | null;
  readonly volumeDiscountSet: readonly VolumeDiscount[];
}

export const emptyPriceItem: Omit<PriceItem, "id" | "url"> = {
  active: true,
  billable: true,
  booleanSelection: false,

  c5_recid: null,
  contributesToFuelSurchargeSalesPrice: true,
  conversionFactor: null,
  conversionUnit: null,
  defaultCount: null,
  genericEffectiveTimerTarget: null,
  includeInLogs: true,
  itemtype: null,
  lineNumber: null,
  minimumCount: null,
  name: "",
  onlyVisibleOnOverview: null,
  price: null,
  priceGroup: null,
  priceGroups: [],
  product: null,
  relatedUnit: null,
  relevantForExecution: null,
  remoteUrl: "",
  required: null,
  requiredGreaterThanZero: null,
  timeAfterMinutes: null,
  transferAsMinutes: false,
  transferWhenCountZero: false,
  transferWhenNetPriceZero: true,
  useManualDistribution: null,
  volumeDiscountSet: [],
};
