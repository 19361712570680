import {
  KrPerLiterFuelSurchargeBasis,
  KrPerLiterFuelSurchargeSpecification,
} from "@co-common-libs/resources";
import {dateFromString} from "@co-common-libs/utils";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import DeleteIcon from "mdi-react/DeleteIcon";
import React, {useCallback} from "react";
import {FormattedDate, FormattedMessage, FormattedNumber} from "react-intl";

const buttonColumnCellStyle: React.CSSProperties = {
  paddingLeft: 0,
  paddingRight: 0,
  width: 48,
};

interface KrPerLiterFuelSurchargeBasisHistoricRowProps {
  basis: KrPerLiterFuelSurchargeBasis;
  onDeleteClick: (url: string) => void;
  specification: KrPerLiterFuelSurchargeSpecification;
}

export const KrPerLiterFuelSurchargeBasisHistoricRow = React.memo(
  function KrPerLiterFuelSurchargeBasisHistoricRow(
    props: KrPerLiterFuelSurchargeBasisHistoricRowProps,
  ): React.JSX.Element {
    const {basis, onDeleteClick, specification} = props;

    const {conversionFactor} = specification;
    const {
      basePriceKrPerLiter,
      fromDate,
      lastChanged,
      priceKrPerLiterIncreaseThreshold,
      truncateNegativeToZero,
      url,
    } = basis;

    const handleDeleteClick = useCallback((): void => {
      onDeleteClick(url);
    }, [onDeleteClick, url]);

    return (
      <TableRow>
        <TableCell>
          {fromDate ? <FormattedDate value={dateFromString(fromDate) as Date} /> : null}
        </TableCell>
        <TableCell>
          {lastChanged ? <FormattedDate value={new Date(lastChanged)} /> : null}
        </TableCell>
        <TableCell>
          <FormattedNumber
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={basePriceKrPerLiter * conversionFactor}
          />
        </TableCell>
        <TableCell>
          <FormattedNumber
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={priceKrPerLiterIncreaseThreshold * conversionFactor}
          />
        </TableCell>
        <TableCell>
          {truncateNegativeToZero ? (
            <FormattedMessage defaultMessage="Nej" />
          ) : (
            <FormattedMessage defaultMessage="Ja" />
          )}
        </TableCell>
        <TableCell style={buttonColumnCellStyle}>
          <IconButton disabled={!fromDate} onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  },
);
