import {getCustomerSettings} from "@co-frontend-libs/redux";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ConfirmDialog, ConfirmDialogProps} from "./confirm-dialog";

export function ConfirmProjectChangeDialog(
  props: Omit<ConfirmDialogProps, "children" | "title">,
): React.JSX.Element {
  const intl = useIntl();
  const {projectLabelVariant} = useSelector(getCustomerSettings);
  return projectLabelVariant === "PROJECT" ? (
    <ConfirmDialog title={intl.formatMessage({defaultMessage: "Ændret projekt"})} {...props}>
      <FormattedMessage defaultMessage="Skal ændringen af projekt ske på alle ordrens ikke-godkendte opgaver?" />
    </ConfirmDialog>
  ) : (
    <ConfirmDialog title={intl.formatMessage({defaultMessage: "Ændret sag "})} {...props}>
      <FormattedMessage defaultMessage="Skal ændringen af sag ske på alle ordrens ikke-godkendte opgaver?" />
    </ConfirmDialog>
  );
}
