import {CustomerUrl, LocationUrl, RoutePlanUrl} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {
  MultiLocationDialog,
  MultipleCustomersDialog,
  MultiRoutePlanDialog,
} from "@co-frontend-libs/components";
import {
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getRoutePlanLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {DeletableChip} from "../deletable-chip";

export interface Configuration {
  invoiceCustomerURLs: CustomerUrl[];
  locationURLs: LocationUrl[];
  routePlanURLs: RoutePlanUrl[];
}

interface ConfigurationCardProps {
  invoiceCustomerURLs: readonly CustomerUrl[];
  locationURLs: readonly LocationUrl[];
  onConfigurationChange: () => void;
  onOk: (configuration: Configuration) => void;
  routePlanURLs: readonly RoutePlanUrl[];
}

export function ConfigurationCard(props: ConfigurationCardProps): React.JSX.Element {
  const {invoiceCustomerURLs, locationURLs, onConfigurationChange, onOk, routePlanURLs} = props;
  const customerSettings = useSelector(getCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);
  const locationLookup = useSelector(getLocationLookup);
  const routePlanLookup = useSelector(getRoutePlanLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const [selectedRoutePlanURLSet, setSelectedRoutePlanURLSet] = useState<ReadonlySet<RoutePlanUrl>>(
    new Set(),
  );
  const [routePlansDialogOpen, setRoutePlansDialogOpen] = useState(false);
  const setRoutePlansDialogOpenTrue = useCallWithTrue(setRoutePlansDialogOpen);
  const setRoutePlansDialogOpenFalse = useCallWithFalse(setRoutePlansDialogOpen);

  const [selectedInvoiceCustomerURLSet, setSelectedInvoiceCustomerURLSet] = useState<
    ReadonlySet<CustomerUrl>
  >(new Set());
  const [invoiceCustomersDialogOpen, setInvoiceCustomersDialogOpen] = useState(false);
  const setInvoiceCustomersDialogOpenTrue = useCallWithTrue(setInvoiceCustomersDialogOpen);
  const setInvoiceCustomersDialogOpenFalse = useCallWithFalse(setInvoiceCustomersDialogOpen);

  const [selectedLocationURLSet, setSelectedLocationURLSet] = useState<ReadonlySet<LocationUrl>>(
    new Set(),
  );
  const [locationsDialogOpen, setLocationsDialogOpen] = useState(false);
  const setLocationsDialogOpenTrue = useCallWithTrue(setLocationsDialogOpen);
  const setLocationsDialogOpenFalse = useCallWithFalse(setLocationsDialogOpen);

  const routePlans = useMemo(
    () => routePlanURLs.map(routePlanLookup).filter(notUndefined),
    [routePlanLookup, routePlanURLs],
  );
  const invoiceCustomers = useMemo(
    () => invoiceCustomerURLs.map(customerLookup).filter(notUndefined),
    [customerLookup, invoiceCustomerURLs],
  );
  const locations = useMemo(
    () => locationURLs.map(locationLookup).filter(notUndefined),
    [locationLookup, locationURLs],
  );

  const handleRoutePlansDialogOk = useCallback((urls: ReadonlySet<RoutePlanUrl>) => {
    setSelectedRoutePlanURLSet(urls);
    setRoutePlansDialogOpen(false);
  }, []);

  const handleRemoveRoutePlan = useCallback(
    (removeURL: RoutePlanUrl) => {
      setSelectedRoutePlanURLSet(
        new Set(Array.from(selectedRoutePlanURLSet).filter((url) => url !== removeURL)),
      );
    },
    [selectedRoutePlanURLSet],
  );

  const handleInvoiceCustomersDialogOk = useCallback((urls: ReadonlySet<CustomerUrl>) => {
    setSelectedInvoiceCustomerURLSet(urls);
    setInvoiceCustomersDialogOpen(false);
  }, []);

  const handleRemoveInvoiceCustomer = useCallback(
    (removeURL: CustomerUrl) => {
      setSelectedInvoiceCustomerURLSet(
        new Set(Array.from(selectedInvoiceCustomerURLSet).filter((url) => url !== removeURL)),
      );
    },
    [selectedInvoiceCustomerURLSet],
  );

  const handleLocationsDialogOk = useCallback((urls: ReadonlySet<LocationUrl>) => {
    setSelectedLocationURLSet(urls);
    setLocationsDialogOpen(false);
  }, []);

  const handleRemoveLocation = useCallback(
    (removeURL: LocationUrl) => {
      setSelectedLocationURLSet(
        new Set(Array.from(selectedLocationURLSet).filter((url) => url !== removeURL)),
      );
    },
    [selectedLocationURLSet],
  );

  const handleOk = useCallback(() => {
    onOk({
      invoiceCustomerURLs: Array.from(selectedInvoiceCustomerURLSet),
      locationURLs: Array.from(selectedLocationURLSet),
      routePlanURLs: Array.from(selectedRoutePlanURLSet),
    });
  }, [onOk, selectedInvoiceCustomerURLSet, selectedLocationURLSet, selectedRoutePlanURLSet]);

  useEffect(() => {
    onConfigurationChange();
  }, [
    onConfigurationChange,
    selectedInvoiceCustomerURLSet,
    selectedLocationURLSet,
    selectedRoutePlanURLSet,
  ]);

  const theme = useTheme();
  const gridSizing = {
    lg: 3,
    sm: 6,
    xs: 12,
  } as const;

  return (
    <>
      <Card>
        <CardHeader
          title={
            <FormattedMessage
              defaultMessage="Opsætning"
              id="route-journal.card-header.configuration"
            />
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item {...gridSizing}>
              <h4>
                <FormattedMessage defaultMessage="Ruter" id="route-journal.header.route-plans" />
              </h4>
              <Button color="primary" onClick={setRoutePlansDialogOpenTrue} variant="contained">
                <FormattedMessage
                  defaultMessage="Vælg ruter"
                  id="route-journal.label.select-route-plans"
                />
              </Button>
              <div>
                {_.sortBy(
                  Array.from(selectedRoutePlanURLSet).map(routePlanLookup).filter(notUndefined),
                  (routePlan) => routePlan.name,
                ).map((routePlan) => (
                  <span
                    key={routePlan.url}
                    style={{
                      marginRight: theme.spacing(0.5),
                    }}
                  >
                    <DeletableChip
                      deletionId={routePlan.url}
                      label={routePlan.name}
                      onDelete={handleRemoveRoutePlan}
                    />
                  </span>
                ))}
              </div>
            </Grid>
            <Grid item {...gridSizing}>
              <h4>
                <FormattedMessage defaultMessage="Steder" id="route-journal.header.locations" />
              </h4>
              <Button color="primary" onClick={setLocationsDialogOpenTrue} variant="contained">
                <FormattedMessage
                  defaultMessage="Vælg steder"
                  id="route-journal.label.select-locations"
                />
              </Button>
              <div>
                {_.sortBy(
                  Array.from(selectedLocationURLSet).map(locationLookup).filter(notUndefined),
                  (location) => location.name,
                ).map((location) => (
                  <span
                    key={location.url}
                    style={{
                      marginRight: theme.spacing(0.5),
                    }}
                  >
                    <DeletableChip
                      deletionId={location.url}
                      label={location.name || location.address || ""}
                      onDelete={handleRemoveLocation}
                    />
                  </span>
                ))}
              </div>
            </Grid>
            <Grid item {...gridSizing}>
              <h4>
                <FormattedMessage
                  defaultMessage="Fakturakunder"
                  id="route-journal.header.invoice-customers"
                />
              </h4>
              <Button
                color="primary"
                onClick={setInvoiceCustomersDialogOpenTrue}
                variant="contained"
              >
                <FormattedMessage
                  defaultMessage="Vælg fakturakunder"
                  id="route-journal.label.select-invoice-customers"
                />
              </Button>
              <div>
                {_.sortBy(
                  Array.from(selectedInvoiceCustomerURLSet)
                    .map(customerLookup)
                    .filter(notUndefined),
                  (customer) => customer.name,
                ).map((customer) => (
                  <span
                    key={customer.url}
                    style={{
                      marginRight: theme.spacing(0.5),
                    }}
                  >
                    <DeletableChip
                      deletionId={customer.url}
                      label={customer.name}
                      onDelete={handleRemoveInvoiceCustomer}
                    />
                  </span>
                ))}
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button color="primary" onClick={handleOk} variant="contained">
            <FormattedMessage defaultMessage="Hent" id="route-journal.label.fetch" />
          </Button>
        </CardActions>
      </Card>
      <MultiRoutePlanDialog
        onCancel={setRoutePlansDialogOpenFalse}
        onOk={handleRoutePlansDialogOk}
        open={routePlansDialogOpen}
        routePlanArray={routePlans}
        selected={selectedRoutePlanURLSet}
        workTypeLookup={workTypeLookup}
      />
      <MultiLocationDialog
        customerLookup={customerLookup}
        customerURL={null}
        includeLogOnlyLocations
        includeSelectAll={false}
        includeWorkplaceOnlyLocations
        locationArray={locations}
        locationCrossCustomerSelectionEnabled
        locationFavoritesEnabled={false}
        onCancel={setLocationsDialogOpenFalse}
        onlyActive={false}
        onOk={handleLocationsDialogOk}
        open={locationsDialogOpen}
        selected={selectedLocationURLSet}
        titleVariant="LOCATION"
      />
      <MultipleCustomersDialog
        contactArray={[]}
        customerArray={invoiceCustomers}
        customerSettings={customerSettings}
        onCancel={setInvoiceCustomersDialogOpenFalse}
        onOk={handleInvoiceCustomersDialogOk}
        open={invoiceCustomersDialogOpen}
        selected={selectedInvoiceCustomerURLSet}
      />
    </>
  );
}
