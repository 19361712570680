import {DialogContent, useTheme} from "@material-ui/core";
import React from "react";
import {ResponsiveDialog, ResponsiveDialogProps} from "../responsive-dialog";

export interface ErrorDialogProps extends Pick<ResponsiveDialogProps, "onOk" | "open" | "title"> {
  message: string;
}
export function ErrorDialog(props: ErrorDialogProps): React.JSX.Element {
  const {message, onOk, open, title} = props;
  const theme = useTheme();
  return (
    <ResponsiveDialog onOk={onOk} open={open} title={title}>
      <DialogContent>
        <div style={{color: theme.palette.error.main}}>{message}</div>
      </DialogContent>
    </ResponsiveDialog>
  );
}
