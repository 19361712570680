import {Config} from "@co-common-libs/config";
import {
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  Machine,
  MachineUrl,
  Project,
  ProjectUrl,
  ReportSetup,
  ReportSetupUrl,
  ResourceTypeUnion,
  urlToId,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  actions,
  AppState,
  getCultureLookup,
  getCustomerLookup,
  getCustomerSettings,
  getMachineLookup,
  getProjectLookup,
  getReportSetupArray,
  getReportSetupLookup,
  getToken,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {CircularProgress} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {globalConfig, instanceURL} from "frontend-global-config";
import React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {CalculateCard} from "./calculate-card";
import {FilterCard} from "./filter-card";
import {ReportDisplayCard} from "./report-display-card";
import {ReportData} from "./types";

interface TaskStatisticsStateProps {
  cultureLookup: (url: CultureUrl) => Culture | undefined;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  reportSetupArray: readonly ReportSetup[];
  reportSetupLookup: (url: ReportSetupUrl) => ReportSetup | undefined;
  token: string | null;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface TaskStatisticsDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  remove: (url: string) => void;
}

type TaskStatisticsProps = TaskStatisticsDispatchProps & TaskStatisticsStateProps;

interface TaskStatisticsState {
  cultures: ReadonlySet<CultureUrl>;
  customers: ReadonlySet<CustomerUrl>;
  departments: ReadonlySet<string>;
  fetching: boolean;
  fromDate: string | null;
  machines: ReadonlySet<MachineUrl>;
  name: string | null;
  projects: ReadonlySet<ProjectUrl>;
  reportData: object | ReportData;
  toDate: string | null;
  url: string | null;
  users: ReadonlySet<UserUrl>;
  workTypes: ReadonlySet<WorkTypeUrl>;
}

class TaskStatistics extends PureComponent<TaskStatisticsProps, TaskStatisticsState> {
  state: TaskStatisticsState = {
    cultures: new Set(),
    customers: new Set(),
    departments: new Set(),
    fetching: false,
    fromDate: "",
    machines: new Set(),
    name: null,
    projects: new Set(),
    reportData: {},
    toDate: "",
    url: null,
    users: new Set(),
    workTypes: new Set(),
  };

  private abortController: AbortController | undefined;

  componentWillUnmount(): void {
    if (this.abortController) {
      this.abortController.abort();
    }
  }
  fetch(): void {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}report`;
    const {
      cultures,
      customers,
      departments,
      fromDate,
      machines,
      projects,
      toDate,
      users,
      workTypes,
    } = this.state;
    this.setState({fetching: true, reportData: {}});
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
    if (window.AbortController) {
      this.abortController = new AbortController();
    }
    jsonFetch(
      url,
      "POST",
      {
        cultures: Array.from(cultures).map((resourceURL) => urlToId(resourceURL)),
        customers: Array.from(customers).map((resourceURL) => urlToId(resourceURL)),
        departments: Array.from(departments),
        fromDate,
        machines: Array.from(machines).map((resourceURL) => urlToId(resourceURL)),
        projects: Array.from(projects).map((resourceURL) => urlToId(resourceURL)),
        toDate,
        users: Array.from(users).map((resourceURL) => urlToId(resourceURL)),
        workTypes: Array.from(workTypes).map((resourceURL) => urlToId(resourceURL)),
      },
      this.abortController?.signal,
    )
      .then((response) => {
        if (
          cultures === this.state.cultures &&
          customers === this.state.customers &&
          departments === this.state.departments &&
          fromDate === this.state.fromDate &&
          machines === this.state.machines &&
          projects === this.state.projects &&
          toDate === this.state.toDate &&
          users === this.state.users &&
          workTypes === this.state.workTypes
        ) {
          this.setState({
            fetching: false,
            reportData: response.data,
          });
        }
        return;
      })
      .catch((error) => {
        if (error.cause?.name === "AbortError") {
          return;
        }
        if (
          cultures === this.state.cultures &&
          customers === this.state.customers &&
          departments === this.state.departments &&
          fromDate === this.state.fromDate &&
          machines === this.state.machines &&
          projects === this.state.projects &&
          toDate === this.state.toDate &&
          users === this.state.users &&
          workTypes === this.state.workTypes
        ) {
          this.setState({
            fetching: false,
          });
        }
      });
  }

  @bind
  handleFromDateChanged(fromDate: string | null): void {
    this.setState({
      fromDate,
      reportData: {},
    });
  }

  @bind
  handleGenerateClick(): void {
    this.fetch();
  }

  @bind
  handleToDateChanged(toDate: string | null): void {
    this.setState({
      reportData: {},
      toDate,
    });
  }
  render(): React.JSX.Element {
    let content;
    if (this.state.fetching) {
      content = (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "1em",
            width: 50,
          }}
        >
          <CircularProgress />
        </div>
      );
    } else if (Object.keys(this.state.reportData).length !== 0) {
      content = (
        <ReportDisplayCard
          customerSettings={this.props.customerSettings}
          reportData={this.state.reportData as ReportData}
          token={this.props.token}
        />
      );
    } else {
      content = null;
    }
    return (
      <>
        <div style={{padding: "1em"}}>
          <FilterCard
            cultureLookup={this.props.cultureLookup}
            cultures={this.state.cultures}
            customerLookup={this.props.customerLookup}
            customers={this.state.customers}
            customerSettings={this.props.customerSettings}
            departments={this.state.departments}
            machineLookup={this.props.machineLookup}
            machines={this.state.machines}
            name={this.state.name}
            projectLookup={this.props.projectLookup}
            projects={this.state.projects}
            reportSetupArray={this.props.reportSetupArray}
            reportSetupLookup={this.props.reportSetupLookup}
            requestReportSetupDelete={this.requestSetupDelete}
            requestSave={this.requestSave}
            requestSetupUpdate={this.requestSetupUpdate}
            url={this.state.url}
            users={this.state.users}
            userUserProfileLookup={this.props.userUserProfileLookup}
            workTypeLookup={this.props.workTypeLookup}
            workTypes={this.state.workTypes}
          />
        </div>
        <div style={{padding: "1em"}}>
          <CalculateCard
            fromDate={this.state.fromDate}
            onFromDateChanged={this.handleFromDateChanged}
            onGenerateClick={this.handleGenerateClick}
            onToDateChanged={this.handleToDateChanged}
            toDate={this.state.toDate}
          />
          {content}
        </div>
      </>
    );
  }
  @bind
  requestSave(name: string): void {
    const {customers, departments, machines, projects, users, workTypes} = this.state;
    const id = uuid();
    const url = instanceURL("reportSetup", id);
    const obj: ReportSetup = {
      customers: Array.from(customers),
      departments: Array.from(departments),
      employees: Array.from(users),
      id,
      machines: Array.from(machines),
      name,
      projects: Array.from(projects),
      url,
      workTypes: Array.from(workTypes),
    };
    this.props.create(obj);
    this.setState({
      name,
      url,
    });
  }

  @bind
  requestSetupDelete(url: string): void {
    this.props.remove(url);
    this.setState({
      customers: new Set(),
      machines: new Set(),
      name: null,
      projects: new Set(),
      reportData: {},
      url: null,
      users: new Set(),
      workTypes: new Set(),
    });
  }

  // FIXME:
  // This approach, with the <FilterCard> child component essentially
  // managing the state of this component is a mess, not to be repeated...
  // (... not *quite* as bad when we add typechecks on this callback...)
  //
  // Possible solution:
  // Let that component be responsible for layout only, no state, call
  // callbacks on button clicks and have *this* component also have the
  // "dialog open" state and dialogs and dialog callbacks...
  //
  // Notes put here in case someone looks to the code of this page for
  // inspiration when trying to solve similar problems...
  @bind
  requestSetupUpdate(data: {
    cultures?: ReadonlySet<CultureUrl>;
    customers?: ReadonlySet<CustomerUrl>;
    departments?: ReadonlySet<string>;
    machines?: ReadonlySet<MachineUrl>;
    name?: string | null;
    projects?: ReadonlySet<ProjectUrl>;
    url?: string | null;
    users?: ReadonlySet<UserUrl>;
    workTypes?: ReadonlySet<WorkTypeUrl>;
  }): void {
    // HACK: the type on data allows fields to be present with value
    // undefined, which would not be legal in state/for setState...
    this.setState({
      reportData: {},
      ...(data as any),
    });
  }
}

const ConnectedTaskStatistics = connect<
  TaskStatisticsStateProps,
  TaskStatisticsDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, TaskStatisticsStateProps>({
    cultureLookup: getCultureLookup,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    machineLookup: getMachineLookup,
    projectLookup: getProjectLookup,
    reportSetupArray: getReportSetupArray,
    reportSetupLookup: getReportSetupLookup,
    token: getToken,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    create: actions.create,
    remove: actions.remove,
  },
)(TaskStatistics);

export {ConnectedTaskStatistics as TaskStatistics};
