import {FieldList, MenuToolbar, PageLayout} from "app-components";
import React from "react";
import {useIntl} from "react-intl";

interface FieldsSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const FieldsSettingsPage = React.memo(function FieldsSettingsPage(
  props: FieldsSettingsPageProps,
): React.JSX.Element {
  const intl = useIntl();

  return (
    <PageLayout
      toolbar={
        <MenuToolbar
          onMenuButton={props.onMenuButton}
          title={intl.formatMessage({defaultMessage: "Marker"})}
        />
      }
      withBottomScrollPadding
    >
      <FieldList customer={null} />
    </PageLayout>
  );
});
