import {
  dateToString,
  DAY_SECONDS,
  daySeconds,
  DayTypeHoliday,
  HOUR_SECONDS,
  MINUTE_SECONDS,
  WEEKDAY_SUNDAY,
  WeekdayNumberType,
} from "@co-common-libs/utils";
import {addMinutes} from "date-fns";
import _ from "lodash";
import {computeRoundedDurationMinutes} from "./duration";
import {
  BonusSpecification,
  IntervalMatchCriteria,
  TaskIntervalWithBonus,
  TaskIntervalWithRate,
  WorkDay,
  WorkPeriod,
  WorkPeriodWithBonus,
} from "./types";

export function getSortedSplitDaySeconds(
  matchCriteria: readonly IntervalMatchCriteria[],
): readonly number[] {
  if (!matchCriteria.length) {
    return [];
  }
  const splitDaySeconds = new Set<number>();
  matchCriteria.forEach((criteria) => {
    const {checkInterval} = criteria;
    if (checkInterval) {
      const {fromDaySeconds, toDaySeconds} = checkInterval;
      splitDaySeconds.add(fromDaySeconds);
      splitDaySeconds.add(toDaySeconds);
    }
  });
  splitDaySeconds.delete(0);
  splitDaySeconds.delete(DAY_SECONDS);
  return _.sortBy(Array.from(splitDaySeconds));
}

export function splitOnDaySeconds(
  sortedSplitDaySeconds: readonly number[],
  intervals: readonly TaskIntervalWithRate[],
): readonly TaskIntervalWithRate[] {
  const result: TaskIntervalWithRate[] = [];
  intervals.forEach((inputInterval) => {
    let interval = inputInterval;
    const intervalFrom = new Date(interval.fromTimestamp);
    const intervalTo = new Date(interval.toTimestamp);
    let intervalFromDaySeconds = daySeconds(intervalFrom);
    // end-of-day rather than start-of-day for midnight...
    const intervalToDaySeconds = daySeconds(intervalTo) || DAY_SECONDS;
    console.assert(intervalFromDaySeconds <= intervalToDaySeconds);
    for (const splitDaySeconds of sortedSplitDaySeconds) {
      if (intervalFromDaySeconds < splitDaySeconds && splitDaySeconds < intervalToDaySeconds) {
        const splitDate = new Date(intervalFrom);
        const hours = Math.floor(splitDaySeconds / HOUR_SECONDS);
        const minutesSeconds = splitDaySeconds % HOUR_SECONDS;
        const minutes = Math.floor(minutesSeconds / MINUTE_SECONDS);
        const seconds = minutesSeconds % MINUTE_SECONDS;
        splitDate.setHours(hours, minutes, seconds, 0);
        const splitTimestamp = splitDate.toISOString();
        const before: TaskIntervalWithRate = {
          ...interval,
          toTimestamp: splitTimestamp,
        };
        const after: TaskIntervalWithRate = {
          ...interval,
          fromTimestamp: splitTimestamp,
        };
        // further split positions are later, so will not affect "before", may affect "after"
        result.push(before);
        interval = after;
        intervalFromDaySeconds = splitDaySeconds;
      } else if (splitDaySeconds >= intervalToDaySeconds) {
        // further split positions are later, so cannot affect interval
        result.push(interval);
        return;
      }
      // further splits are later, may affect interval
    }
    // whatever was/is left after processing splits...
    result.push(interval);
  });
  return result;
}

function timeMatch(
  time: {fromDaySeconds: number; toDaySeconds: number},
  interval: TaskIntervalWithRate,
): boolean {
  const {fromDaySeconds, toDaySeconds} = time;
  const intervalFrom = new Date(interval.fromTimestamp);
  const intervalTo = new Date(interval.toTimestamp);
  const intervalFromDaySeconds = daySeconds(intervalFrom);
  // end-of-day rather than start-of-day for midnight...
  const intervalToDaySeconds = daySeconds(intervalTo) || DAY_SECONDS;
  // for convenience, intervals have previously been split on midnights...
  console.assert(intervalFromDaySeconds <= intervalToDaySeconds);
  // * If interval starts after the period we care about,
  //   there is no overlap.
  // * If interval ends before the period we care about,
  //   there is no overlap.
  const beforeOrAfter =
    intervalFromDaySeconds >= toDaySeconds || intervalToDaySeconds <= fromDaySeconds;
  return !beforeOrAfter;
}

function datesMatch(
  dates: {fromDay: number; fromMonth: number; toDay: number; toMonth: number},
  interval: TaskIntervalWithRate,
): boolean {
  const {fromDay, fromMonth, toDay, toMonth} = dates;
  // interval is within a day; we've already split on midnights;
  // but interval.toTimestamp might give the "wrong" date when exactly midnight
  const intervalFrom = new Date(interval.fromTimestamp);
  // JS date object count months from 0
  const intervalMonth = intervalFrom.getMonth() + 1;
  // JS date objects count days in month from 1, however...
  const intervalDay = intervalFrom.getDate();
  const beforeOrAfter =
    intervalMonth > toMonth ||
    intervalMonth < fromMonth ||
    (intervalMonth === toMonth && intervalDay > toDay) ||
    (intervalMonth === fromMonth && intervalDay < fromDay);
  return !beforeOrAfter;
}

function dayOfTheWeekMatch(
  checkHoliday: (dateString: string) => DayTypeHoliday,
  dayOfTheWeek: WeekdayNumberType,
  interval: TaskIntervalWithRate,
): boolean {
  // interval is within a day; we've already split on midnights;
  // but interval.toTimestamp might give the "wrong" date when exactly midnight
  const intervalFrom = new Date(interval.fromTimestamp);
  const dayType = checkHoliday(dateToString(intervalFrom));
  if (dayType === DayTypeHoliday.HOLIDAY) {
    return dayOfTheWeek === WEEKDAY_SUNDAY;
  } else {
    return dayOfTheWeek === intervalFrom.getDay();
  }
}

export function checkCriteria(
  checkHoliday: (dateString: string) => DayTypeHoliday,
  criteria: IntervalMatchCriteria,
  interval: TaskIntervalWithRate,
): boolean {
  const {
    checkDates,
    checkInterval,
    customerTask,
    dayOfTheWeek,
    departmentID,
    effectiveTime,
    machineURL,
    nextDayIsHoliday,
    priceGroupURL,
    rate,
    taskPriceGroupURL,
    taskWorkTypeURL,
    workTypeURL,
  } = criteria;
  if (rate != null && interval.rate !== rate) {
    return false;
  }
  if (checkInterval != null && !timeMatch(checkInterval, interval)) {
    return false;
  }
  if (checkDates != null && !datesMatch(checkDates, interval)) {
    return false;
  }
  if (dayOfTheWeek != null && !dayOfTheWeekMatch(checkHoliday, dayOfTheWeek, interval)) {
    return false;
  }
  if (nextDayIsHoliday != null) {
    const nextDate = new Date(interval.fromTimestamp);
    nextDate.setDate(nextDate.getDate() + 1);
    const nextDayType = checkHoliday(dateToString(nextDate));
    if (nextDayType === DayTypeHoliday.HOLIDAY || nextDate.getDay() === WEEKDAY_SUNDAY) {
      if (!nextDayIsHoliday) {
        return false;
      }
    } else {
      if (nextDayIsHoliday) {
        return false;
      }
    }
  }
  if (
    workTypeURL == null &&
    machineURL == null &&
    priceGroupURL == null &&
    departmentID == null &&
    customerTask == null &&
    effectiveTime == null &&
    taskWorkTypeURL == null &&
    taskPriceGroupURL == null
  ) {
    // No taskData checks; and we've passed all of the "interval" checks...
    // Special case for this needed to allow success then even if there are
    // no taskData entries.
    return true;
  }
  for (let i = 0; i < interval.taskData.length; i += 1) {
    const taskData = interval.taskData[i];
    if (workTypeURL != null && taskData.workTypeURL !== workTypeURL) {
      continue;
    }
    if (machineURL != null && !taskData.machineURLs.includes(machineURL)) {
      continue;
    }
    if (priceGroupURL != null && !taskData.priceGroupURLs.includes(priceGroupURL)) {
      continue;
    }
    if (departmentID != null && taskData.department !== departmentID) {
      continue;
    }
    if (customerTask != null && taskData.customerTask !== customerTask) {
      continue;
    }
    if (effectiveTime != null && taskData.effectiveTime !== effectiveTime) {
      continue;
    }
    if (taskWorkTypeURL != null && taskData.taskWorkTypeURL !== taskWorkTypeURL) {
      continue;
    }
    if (taskPriceGroupURL != null && taskData.taskPriceGroupURL !== taskPriceGroupURL) {
      continue;
    }
    // Passed all checks with this taskData entry.
    return true;
  }
  // No single taskData instance passed all checks.
  return false;
}

function splitAttachAfterThresholdGenericBonus<K>(
  minutes: number,
  label: string,
  key: K,
  afterThresholdRemainingMinutes: Map<K, number>,
  interval: TaskIntervalWithBonus,
): TaskIntervalWithBonus | TaskIntervalWithBonus[] {
  const intervalFrom = new Date(interval.fromTimestamp);
  const intervalTo = new Date(interval.toTimestamp);
  const remainingMinutesBeforeBonus = afterThresholdRemainingMinutes.get(key) ?? minutes;
  if (remainingMinutesBeforeBonus === 0) {
    return {...interval, bonus: _.uniq([...interval.bonus, label])};
  }
  const intervalDuration = computeRoundedDurationMinutes(intervalFrom, intervalTo);
  if (intervalDuration <= remainingMinutesBeforeBonus) {
    afterThresholdRemainingMinutes.set(key, remainingMinutesBeforeBonus - intervalDuration);
    return interval;
  }
  // split for partial bonus...
  const splitTimestamp = addMinutes(intervalFrom, remainingMinutesBeforeBonus).toISOString();
  const beforeBonusInterval: TaskIntervalWithBonus = {
    ...interval,
    toTimestamp: splitTimestamp,
  };
  const withBonusInterval: TaskIntervalWithBonus = {
    ...interval,
    bonus: _.uniq([...interval.bonus, label]),
    fromTimestamp: splitTimestamp,
  };
  afterThresholdRemainingMinutes.set(key, 0);
  return [beforeBonusInterval, withBonusInterval];
}

// generic type for workDay to limits its us to Map key...
function splitAttachAfterThresholdBonus<T>(
  afterThreshold: {
    readonly minutes: number;
    readonly sum: "calendarDay" | "workDay";
  },
  label: string,
  workDay: T,
  afterThresholdCalendarDayRemainingMinutes: Map<string, number>,
  afterThresholdWorkDayRemainingMinutes: Map<T, number>,
  interval: TaskIntervalWithBonus,
): TaskIntervalWithBonus | TaskIntervalWithBonus[] {
  const {minutes, sum} = afterThreshold;
  if (sum === "calendarDay") {
    const intervalFrom = new Date(interval.fromTimestamp);
    const date = dateToString(intervalFrom);
    return splitAttachAfterThresholdGenericBonus(
      minutes,
      label,
      date,
      afterThresholdCalendarDayRemainingMinutes,
      interval,
    );
  } else {
    console.assert(sum === "workDay", `unknown afterThreshold sum: ${sum}`);
    return splitAttachAfterThresholdGenericBonus(
      minutes,
      label,
      workDay,
      afterThresholdWorkDayRemainingMinutes,
      interval,
    );
  }
}

export function attachWorkPeriodIntervalBonuses(
  checkHoliday: (dateString: string) => DayTypeHoliday,
  intervalBonus: readonly BonusSpecification[],
  workDay: WorkDay,
  afterThresholdCalendarDayRemainingMinutes: Map<BonusSpecification, Map<string, number>>,
  afterThresholdWorkDayRemainingMinutes: Map<BonusSpecification, Map<WorkDay, number>>,
): WorkPeriodWithBonus[] {
  const splitAttachBonus = (
    criteria: BonusSpecification,
    interval: TaskIntervalWithBonus,
  ): TaskIntervalWithBonus | TaskIntervalWithBonus[] => {
    if (checkCriteria(checkHoliday, criteria, interval)) {
      if (criteria.afterThreshold) {
        let afterThresholdCalendarDayRemainingMinutesForCriteria =
          afterThresholdCalendarDayRemainingMinutes.get(criteria);
        if (!afterThresholdCalendarDayRemainingMinutesForCriteria) {
          afterThresholdCalendarDayRemainingMinutesForCriteria = new Map<string, number>();
          afterThresholdCalendarDayRemainingMinutes.set(
            criteria,
            afterThresholdCalendarDayRemainingMinutesForCriteria,
          );
        }
        let afterThresholdWorkDayRemainingMinutesForCriteria =
          afterThresholdWorkDayRemainingMinutes.get(criteria);
        if (!afterThresholdWorkDayRemainingMinutesForCriteria) {
          afterThresholdWorkDayRemainingMinutesForCriteria = new Map<WorkDay, number>();
          afterThresholdWorkDayRemainingMinutes.set(
            criteria,
            afterThresholdWorkDayRemainingMinutesForCriteria,
          );
        }
        return splitAttachAfterThresholdBonus(
          criteria.afterThreshold,
          criteria.label,
          workDay,
          afterThresholdCalendarDayRemainingMinutesForCriteria,
          afterThresholdWorkDayRemainingMinutesForCriteria,
          interval,
        );
      } else {
        // NTS: using arrays for "bonus" in data type was probably a mistake
        return {
          ...interval,
          bonus: _.uniq([...interval.bonus, criteria.label]),
        };
      }
    } else {
      return interval;
    }
  };

  const attachIntervalBonus = (
    intervals: TaskIntervalWithBonus[],
    criteria: BonusSpecification,
  ): TaskIntervalWithBonus[] => {
    return intervals.flatMap(splitAttachBonus.bind(null, criteria));
  };

  const attachIntervalBonuses = (interval: TaskIntervalWithRate): TaskIntervalWithBonus[] => {
    return intervalBonus.reduce(attachIntervalBonus, [{...interval, bonus: []}]);
  };

  const splitDaySeconds = getSortedSplitDaySeconds(intervalBonus);

  const attachWorkPeriodBonuses = (workPeriod: WorkPeriod): WorkPeriodWithBonus => {
    const baseWork = splitDaySeconds.length
      ? splitOnDaySeconds(splitDaySeconds, workPeriod.work)
      : workPeriod.work;
    return {
      ...workPeriod,
      work: baseWork.flatMap(attachIntervalBonuses),
    };
  };

  const result = workDay.workPeriods.map(attachWorkPeriodBonuses);

  return result;
}
