import {PathTemplate} from "@co-frontend-libs/redux";
import {Divider, ListSubheader, makeStyles} from "@material-ui/core";
import {SPACING} from "frontend-global-config";
import React from "react";
import {MenuGroup} from "../types";
import {SettingsMenuItem} from "./settings-menu-item";

interface SettingsMenuGroupProps {
  menuGroup: MenuGroup;
  onRequestChange: (route: PathTemplate) => void;
  selectedRoute: PathTemplate | null;
}

const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: "inherit",
  },
  smallHeader: {
    lineHeight: theme.typography.pxToRem(theme.spacing(SPACING.MEDIUM)),
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
}));
export const SettingsMenuGroup = React.memo(function SettingsMenuGroup(
  props: SettingsMenuGroupProps,
): React.JSX.Element | null {
  const {menuGroup, onRequestChange, selectedRoute} = props;
  const classes = useStyles();

  if (menuGroup.entries.length === 0) {
    return null;
  }

  return (
    <li className={classes.listSection} key={menuGroup.headerText}>
      <ul className={classes.ul}>
        <Divider />
        <ListSubheader className={classes.smallHeader} role="heading">
          {menuGroup.headerText}
        </ListSubheader>
        {menuGroup.entries.map(
          (menuItem, index): React.JSX.Element => (
            <SettingsMenuItem
              key={index}
              menuItem={menuItem}
              onRequestChange={onRequestChange}
              selectedRoute={selectedRoute}
            />
          ),
        )}
      </ul>
    </li>
  );
});
