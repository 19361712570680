import {
  ArchiveFile,
  ArchiveFileUrl,
  ArchiveLink,
  ArchiveLinkUrl,
  resourceNameFor,
  ResourceTypeUnion,
} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {
  AppbarSearchField,
  ColumnSpecifications,
  DeleteDialog,
  GenericTable,
  iconButtonColumnSpecification,
  ResponsiveDialog,
  RowData,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  actions,
  getArchiveFileArray,
  getArchiveFileLookup,
  getArchiveLinkArray,
  getArchiveLinkLookup,
  getCurrentRole,
  getCurrentUserURL,
  getPathName,
  getShareToken,
  getTableSortingState,
} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {
  DialogContent,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
} from "@material-ui/core";
import {
  appPhotoHelper,
  ArchiveLinkCreateEditDialog,
  BackToolbar,
  FloatingActionButtonData,
  FloatingActionButtons,
  MenuToolbar,
  PageLayout,
  useFileInputChangeHandler,
} from "app-components";
import {
  uploadFile,
  useEventTargetValueCallback,
  useQueryParameter,
  useQueryParameterState,
} from "app-utils";
import bowser from "bowser";
import CollectionsIcon from "mdi-react/CollectionsIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import FileIcon from "mdi-react/FileIcon";
import FilePdfBoxIcon from "mdi-react/FilePdfBoxIcon";
import MdInsertDriveFile from "mdi-react/InsertDriveFileIcon";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PhotoCameraIcon from "mdi-react/PhotoCameraIcon";
import memoize from "memoize-one";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {defineMessages, FormattedMessage, IntlShape, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

function isLink(arg: ArchiveFile | ArchiveLink): arg is ArchiveLink {
  return (arg as any).linkUrl !== undefined;
}

const messages = defineMessages({
  addFiles: {
    defaultMessage: "Tilføj filer",
    id: "file-archive.label.add-files",
  },
  category: {
    defaultMessage: "Kategori",
    id: "file-archive.category",
  },
  choosePhoto: {
    defaultMessage: "Vælg billede",
    id: "file-archive.label.choose-photo",
  },
  files: {
    defaultMessage: "Dokumenter",
    id: "file-archive.title.documents",
  },
  handbook: {
    defaultMessage: "Medarbejderhåndbog/APV",
    id: "file-archive.handbook",
  },
  invalidFile: {
    defaultMessage: "{name} er ikke en gyldig pdf eller billedfil",
    id: "file-archive.toast.invalid-image-file",
  },
  name: {
    defaultMessage: "Navn",
    id: "file-archive.name",
  },
  other: {
    defaultMessage: "Andet",
    id: "file-archive.other",
  },
  safety: {
    defaultMessage: "Sikkerhedsdatablade",
    id: "file-archive.safety",
  },
  takePhoto: {
    defaultMessage: "Tag billede",
    id: "file-archive.label.take-photo",
  },
});

// The table started out as FileTable, don't change as the users will loose their sorting state
const TABLE_SORTING_IDENTIFIER = "FileTable";
const MAX_ENTRIES_DISPLAYED = 100;

type ArchiveTableFieldID = "category" | "download" | "edit" | "name";

type ArchiveTableColumnID = "category" | "delete" | "edit" | "link" | "name";

interface ArchiveTableDataType
  extends RowData<ArchiveTableFieldID, ArchiveFileUrl | ArchiveLinkUrl> {
  category: string;
  download: string | null;
  name: string;
}

function renderCategory(data: ArchiveTableDataType): React.JSX.Element {
  switch (data.category) {
    case "handbook":
      return <FormattedMessage defaultMessage="Medarbejderhåndbog/APV" />;
    case "safety":
      return <FormattedMessage defaultMessage="Sikkerhedsdatablade" />;
    default:
      return <FormattedMessage defaultMessage="Andet" />;
  }
}

function makeEditButtonRender(
  onEditClick?: (url: ArchiveFileUrl | ArchiveLinkUrl) => void,
): (data: ArchiveTableDataType) => React.JSX.Element | null {
  return function renderEditButton(data: ArchiveTableDataType): React.JSX.Element | null {
    if (!onEditClick) {
      return null;
    }
    const handleEditClick = (): void => {
      onEditClick(data.key);
    };
    return (
      // buildColumnSpecifications should probably take a
      // ComponentType<{data: ...}> parameter; but whitelist this until then
      // eslint-disable-next-line react/jsx-no-bind
      <IconButton onClick={handleEditClick}>
        <PencilIcon />
      </IconButton>
    );
  };
}

function makeDeleteButtonRender(
  onDeleteClick?: (url: ArchiveFileUrl | ArchiveLinkUrl) => void,
): (data: ArchiveTableDataType) => React.JSX.Element | null {
  return function renderDeleteButton(data: ArchiveTableDataType): React.JSX.Element | null {
    const handleDeleteClick = (): void => {
      if (onDeleteClick) {
        onDeleteClick(data.key);
      }
    };
    return (
      // buildColumnSpecifications should probably take a
      // ComponentType<{data: ...}> parameter; but whitelist this until then
      // eslint-disable-next-line react/jsx-no-bind
      <IconButton disabled={!onDeleteClick} onClick={handleDeleteClick}>
        <DeleteIcon />
      </IconButton>
    );
  };
}

function makeLinkButtonRender(
  shareToken: string,
): (data: ArchiveTableDataType) => React.JSX.Element {
  return function renderLinkButton(data: ArchiveTableDataType): React.JSX.Element {
    if (resourceNameFor(data.key) === "archiveLink") {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    return (
      <IconButton
        color="primary"
        disabled={!data.download}
        href={`${data.download}?token=${shareToken}`}
        target="_blank"
      >
        <FileIcon />
      </IconButton>
    );
  };
}

function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  shareToken: string,
  onClick?: (url: ArchiveFileUrl | ArchiveLinkUrl) => void,
  onEditClick?: (url: ArchiveFileUrl | ArchiveLinkUrl) => void,
  onDeleteClick?: (url: ArchiveFileUrl | ArchiveLinkUrl) => void,
): ColumnSpecifications<
  ArchiveTableFieldID,
  ArchiveTableColumnID,
  ArchiveFileUrl | ArchiveLinkUrl,
  ArchiveTableDataType
> {
  return {
    category: {
      field: "category",
      label: formatMessage(messages.category),
      onClick,
      render: renderCategory,
    },
    delete: iconButtonColumnSpecification({
      field: "edit",
      render: makeDeleteButtonRender(onDeleteClick),
    }),
    edit: iconButtonColumnSpecification({
      field: "edit",
      render: makeEditButtonRender(onEditClick),
    }),
    link: iconButtonColumnSpecification({
      field: "edit",
      render: makeLinkButtonRender(shareToken),
    }),
    name: {
      field: "name",
      label: formatMessage(messages.name),
      onClick,
    },
  };
}

function sortArchiveEntries(
  archiveArray: readonly (ArchiveFile | ArchiveLink)[],
): readonly (ArchiveFile | ArchiveLink)[] {
  return archiveArray.slice().sort((a, b) => caseAccentInsensitiveCollator.compare(a.name, b.name));
}

function buildRowData(
  archiveArray: readonly (ArchiveFile | ArchiveLink)[],
): readonly ArchiveTableDataType[] {
  return archiveArray.map((instance) => {
    const {category, name, url} = instance;
    return {
      category,
      download: (isLink(instance) ? instance.linkUrl : instance.download) || null,
      edit: null,
      key: url,
      name: name || "",
    };
  });
}

interface ArchiveTableProps {
  archiveArray: readonly (ArchiveFile | ArchiveLink)[];
  filterString: string;
  onClick?: (url: ArchiveFileUrl | ArchiveLinkUrl) => void;
  onDeleteClick?: ((url: ArchiveFileUrl | ArchiveLinkUrl) => void) | undefined;
  onEditClick?: ((url: ArchiveFileUrl | ArchiveLinkUrl) => void) | undefined;
  visibleColumns: readonly ArchiveTableColumnID[];
}

const ArchiveTable = ({
  archiveArray,
  filterString,
  onClick,
  onDeleteClick,
  onEditClick,
  visibleColumns,
}: ArchiveTableProps): React.JSX.Element => {
  const memoedBuildColumnSpecifications = memoize(buildColumnSpecifications);
  const memoedSortArchiveEntries = memoize(sortArchiveEntries);
  const memoedBuildRowData = memoize(buildRowData);
  const dispatch = useDispatch();
  const sortingState = useSelector(getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"));
  const handleHeaderClick = useCallback(
    (key: ArchiveTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortingState.sortKey === key && sortingState.sortDirection === "ASC") {
        direction = "DESC";
      }
      dispatch(actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction));
    },
    [dispatch, sortingState.sortDirection, sortingState.sortKey],
  );

  const {formatMessage} = useIntl();

  const shareToken = useSelector(getShareToken);

  const columnSpecifications = memoedBuildColumnSpecifications(
    formatMessage,
    shareToken || "",
    onClick,
    onEditClick,
    onDeleteClick,
  );
  const filteredSortedArchiveArray = memoedSortArchiveEntries(archiveArray);
  const data = memoedBuildRowData(filteredSortedArchiveArray);
  return (
    <GenericTable
      columns={columnSpecifications}
      entries={data}
      filterString={filterString}
      maxDisplayed={MAX_ENTRIES_DISPLAYED}
      onHeaderClick={handleHeaderClick}
      sortBy={sortingState.sortKey as any}
      sortDirection={sortingState.sortDirection}
      visibleColumns={visibleColumns}
    />
  );
};

const FileEditDialog = ({
  initialCategory,
  initialName,
  onCancel,
  onOk,
  open,
}: {
  initialCategory?: string | undefined;
  initialName?: string | undefined;
  onCancel: () => void;
  onOk: (name: string, category: string) => void;
  open: boolean;
}): React.JSX.Element => {
  const [name, setName] = useState(initialName || "");
  const [category, setCategory] = useState(initialCategory || "");

  useEffect(() => {
    if (open) {
      setName(initialName || "");
      setCategory(initialCategory || "");
    }
  }, [initialCategory, initialName, open]);

  const handleOk = useCallback(() => {
    if (!name || category === null) {
      return;
    }
    onOk(name, category);
  }, [category, name, onOk]);

  const handleCategoryChange = useEventTargetValueCallback(setCategory, [setCategory]);
  const {formatMessage} = useIntl();
  return (
    <ResponsiveDialog
      okDisabled={!name || category === undefined}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={
        <FormattedMessage defaultMessage="Redigér filinformation" id="file-edit-dialog.edit-file" />
      }
    >
      <DialogContent>
        <TrimTextField
          fullWidth
          label={<FormattedMessage defaultMessage="Navn" id="file-edit-dialog.name" />}
          onChange={setName}
          value={name}
          variant="outlined"
        />
        <RadioGroup name="category" onChange={handleCategoryChange} value={category}>
          <FormControlLabel
            control={<Radio />}
            label={formatMessage(messages.safety)}
            value="safety"
          />
          <FormControlLabel
            control={<Radio />}
            label={formatMessage(messages.handbook)}
            value="handbook"
          />
          <FormControlLabel control={<Radio />} label={formatMessage(messages.other)} value="" />
        </RadioGroup>
      </DialogContent>
    </ResponsiveDialog>
  );
};

export const Archive = ({
  onMenuButton,
}: {
  onMenuButton: (event: React.MouseEvent) => void;
}): React.JSX.Element => {
  const dispatch = useDispatch();
  const filterString = useQueryParameter("q", "");
  const currentRole = useSelector(getCurrentRole);
  const isManager = currentRole && currentRole.manager;

  const handleFilterStringChange = useEventTargetValueCallback(
    (value: string): void => {
      dispatch(actions.putQueryKey("q", value || ""));
    },
    [dispatch],
  );

  const {formatMessage} = useIntl();

  const right = <AppbarSearchField onChange={handleFilterStringChange} value={filterString} />;

  const pathName = useSelector(getPathName);
  let toolbar: React.JSX.Element;
  if (pathName === "/files") {
    toolbar = (
      <MenuToolbar
        onMenuButton={onMenuButton}
        rightElement={right}
        title={formatMessage(messages.files)}
      />
    );
  } else {
    toolbar = <BackToolbar rightElement={right} title={formatMessage(messages.files)} />;
  }

  const currentUserURL = useSelector(getCurrentUserURL);

  const [fileEditDialogOpenFile, setFileEditDialogOpenFile] = useState<File | null>(null);
  const [fileEditDialogOpenFor, setFileEditDialogOpenFor] = useState<ArchiveFile | null>(null);
  const [linkEditDialogOpenFor, setLinkEditDialogOpenFor] = useState<ArchiveLink | null>(null);
  const [archiveLinkCreateEditDialogOpen, setArchiveLinkCreateEditDialogOpen] = useState(false);
  const setArchiveLinkCreateEditDialogOpenFalse = useCallWithFalse(
    setArchiveLinkCreateEditDialogOpen,
  );

  const [deleteDialogOpenFor, setDeleteDialogOpenFor] = useState<string | null>(null);
  const fileDropAccepted = useCallback((files: File[]): void => {
    // We only allow one file at the time
    setFileEditDialogOpenFile(files[0]);
  }, []);

  const fileDropRejected = useCallback(
    (file: File): void => {
      const message = formatMessage(messages.invalidFile, {name: file.name});
      window.setTimeout(() => {
        dispatch(actions.setMessage(message, new Date()));
      }, 0);
    },
    [dispatch, formatMessage],
  );

  const archiveFilesArray = useSelector(getArchiveFileArray);
  const archiveLinkArray = useSelector(getArchiveLinkArray);

  const archiveArray = useMemo(
    () => [...archiveFilesArray, ...archiveLinkArray],
    [archiveFilesArray, archiveLinkArray],
  );
  const handleFileInputChange = useFileInputChangeHandler(
    fileDropAccepted,
    fileDropRejected,
    "image/jpeg,image/png,application/pdf",
  );

  const handleAppCameraButton = useCallback((): void => {
    appPhotoHelper((window as any).Camera.PictureSourceType.CAMERA, fileDropAccepted);
  }, [fileDropAccepted]);

  const handleCreateLink = useCallback(() => {
    setLinkEditDialogOpenFor(null);
    setArchiveLinkCreateEditDialogOpen(true);
  }, []);

  const buttonData = useMemo((): FloatingActionButtonData[] => {
    if (window.cordova) {
      return [
        {
          buttonIcon: <PhotoCameraIcon />,
          name: "cameraButton",
          onClick: handleAppCameraButton,
          tooltipTitle: formatMessage({defaultMessage: "Kamera"}),
        },
        {
          accept: "image/jpeg,image/png",
          buttonIcon: <CollectionsIcon />,
          id: "archive-photos-input",
          name: "archive-photos-input",
          onChange: handleFileInputChange,
          tooltipTitle: formatMessage({defaultMessage: "Foto"}),
        },
        {
          accept: "application/pdf",
          buttonIcon: <FilePdfBoxIcon />,
          id: "archive-file-input",
          name: "application/pdf",
          onChange: handleFileInputChange,
          tooltipTitle: formatMessage({defaultMessage: "PDF"}),
        },
        {
          buttonIcon: <LinkVariantIcon />,
          name: "linkButton",
          onClick: handleCreateLink,
          tooltipTitle: formatMessage({defaultMessage: "Link"}),
        },
      ];
    } else {
      return [
        {
          accept: "image/jpeg,image/png,application/pdf",
          buttonIcon: <MdInsertDriveFile />,
          id: "archive-file-input",
          name: "image/jpeg,image/png,application/pdf",
          onChange: handleFileInputChange,
          tooltipTitle: formatMessage({defaultMessage: "PDF/Foto"}),
        },
        {
          buttonIcon: <LinkVariantIcon />,
          name: "linkButton",
          onClick: handleCreateLink,
          tooltipTitle: formatMessage({defaultMessage: "Link"}),
        },
      ];
    }
  }, [formatMessage, handleAppCameraButton, handleCreateLink, handleFileInputChange]);

  const handleFileEditDialogOk = useCallback(
    (name: string, category: string): void => {
      if (fileEditDialogOpenFor) {
        dispatch(
          actions.update(fileEditDialogOpenFor.url, [
            {member: "category", value: category},
            {member: "name", value: name},
          ]),
        );
        setFileEditDialogOpenFor(null);
      } else if (fileEditDialogOpenFile) {
        const boundCreate = (instance: ResourceTypeUnion): void => {
          dispatch(actions.create(instance));
        };
        const boundAddToOffline = (instance: ResourceTypeUnion): void => {
          dispatch(actions.addToOffline(instance));
        };
        const extraData = {
          category,
          created: new Date().toISOString(),
          createdBy: currentUserURL || undefined,
          name,
        };
        uploadFile(
          fileEditDialogOpenFile,
          "archiveFile",
          extraData,
          "data",
          boundCreate,
          boundAddToOffline,
        );
        setFileEditDialogOpenFile(null);
      }
    },
    [currentUserURL, dispatch, fileEditDialogOpenFile, fileEditDialogOpenFor],
  );
  const archiveFileLookup = useSelector(getArchiveFileLookup);
  const archiveLinkLookup = useSelector(getArchiveLinkLookup);
  const handleFileEditClick = useCallback(
    (url: ArchiveFileUrl | ArchiveLinkUrl) => {
      if (resourceNameFor(url) === "archiveFile") {
        const archiveFile = archiveFileLookup(url as ArchiveFileUrl);
        setFileEditDialogOpenFor(archiveFile || null);
      } else {
        setLinkEditDialogOpenFor(archiveLinkLookup(url as ArchiveLinkUrl) || null);
        setArchiveLinkCreateEditDialogOpen(true);
      }
    },
    [archiveFileLookup, archiveLinkLookup],
  );
  const handleFileEditDialogCancel = useCallback((): void => {
    setFileEditDialogOpenFile(null);
    setFileEditDialogOpenFor(null);
  }, []);

  const handleFileDeleteCancel = useCallback((): void => {
    setDeleteDialogOpenFor(null);
  }, []);
  const handleFileDeleteOk = useCallback((): void => {
    if (deleteDialogOpenFor) {
      dispatch(actions.remove(deleteDialogOpenFor));
    }
    setDeleteDialogOpenFor(null);
  }, [deleteDialogOpenFor, dispatch]);

  const visibleColumns: ArchiveTableColumnID[] = ["name", "category"];
  if (isManager) {
    visibleColumns.push("edit");
    visibleColumns.push("delete");
  }
  visibleColumns.push("link");

  const shareToken = useSelector(getShareToken);

  const onDownloadClick = useCallback(
    (url: ArchiveFileUrl | ArchiveLinkUrl): void => {
      const resourceName = resourceNameFor(url);
      const instance =
        resourceName === "archiveFile"
          ? archiveFileLookup(url as ArchiveFileUrl)
          : archiveLinkLookup(url as ArchiveLinkUrl);
      if (!instance) {
        return;
      }
      if (isLink(instance)) {
        window.open(instance.linkUrl, bowser.ios ? "_system" : "_blank");
      } else {
        window.open(`${instance.download}?token=${shareToken}`, bowser.ios ? "_system" : "_blank");
      }
    },
    [archiveFileLookup, archiveLinkLookup, shareToken],
  );
  const intl = useIntl();
  const [tab, setTab] = useQueryParameterState<string>("tab", "all");
  const handleTabChange = useCallback(
    (_event: unknown, value: string) => {
      setTab(value);
    },
    [setTab],
  );

  const speeddial: React.JSX.Element = (
    <FloatingActionButtons buttons={buttonData} name="archive" variant="page" />
  );

  return (
    <PageLayout
      speedDial={speeddial}
      tabs={
        <Tabs
          onChange={handleTabChange}
          value={tab}
          variant={bowser.mobile ? "fullWidth" : "standard"}
        >
          <Tab label={intl.formatMessage({defaultMessage: "Alle"})} value="all" />
          <Tab
            label={
              bowser.mobile
                ? intl.formatMessage({defaultMessage: "Sikkerh."})
                : intl.formatMessage({defaultMessage: "Sikkerhedsblade"})
            }
            value="safety"
          />
          <Tab
            label={
              bowser.mobile
                ? intl.formatMessage({
                    defaultMessage: "M.bog/APV",
                  })
                : intl.formatMessage({
                    defaultMessage: "Medarbejderhåndbog/APV",
                  })
            }
            value="handbook"
          />
          <Tab label={intl.formatMessage({defaultMessage: "Andet"})} value="other" />
        </Tabs>
      }
      toolbar={toolbar}
      withBottomScrollPadding
    >
      <ArchiveTable
        archiveArray={
          tab === "all"
            ? archiveArray
            : archiveArray.filter(
                (file) => file.category === tab || (tab === "other" && !file.category),
              )
        }
        filterString={filterString}
        onClick={onDownloadClick}
        onDeleteClick={isManager ? setDeleteDialogOpenFor : undefined}
        onEditClick={isManager ? handleFileEditClick : undefined}
        visibleColumns={visibleColumns}
      />
      <FileEditDialog
        initialName={fileEditDialogOpenFile?.name.replace(/\.[^.]*$/, "")}
        onCancel={handleFileEditDialogCancel}
        onOk={handleFileEditDialogOk}
        open={!!fileEditDialogOpenFile}
      />
      <ArchiveLinkCreateEditDialog
        archiveLink={linkEditDialogOpenFor || undefined}
        onCancel={setArchiveLinkCreateEditDialogOpenFalse}
        onOk={setArchiveLinkCreateEditDialogOpenFalse}
        open={archiveLinkCreateEditDialogOpen}
      />
      <FileEditDialog
        initialCategory={fileEditDialogOpenFor?.category}
        initialName={fileEditDialogOpenFor?.name}
        onCancel={handleFileEditDialogCancel}
        onOk={handleFileEditDialogOk}
        open={!!fileEditDialogOpenFor}
      />
      <DeleteDialog
        onCancel={handleFileDeleteCancel}
        onOk={handleFileDeleteOk}
        open={!!deleteDialogOpenFor}
      >
        <FormattedMessage defaultMessage="Slet fil?" id="file-archive.label.do-delete-file" />
      </DeleteDialog>
    </PageLayout>
  );
};
