import {OrderFile, ResourceTypeUnion} from "@co-common-libs/resources";
import {DeleteDialog, SingleTextFieldDialog} from "@co-frontend-libs/components";
import {actions, getCurrentUserURL, getOrderFileArray} from "@co-frontend-libs/redux";
import {Card, CardHeader, CardMedia} from "@material-ui/core";
import {uploadFile} from "app-utils";
import CollectionsIcon from "mdi-react/CollectionsIcon";
import FilePdfBoxIcon from "mdi-react/FilePdfBoxIcon";
import PhotoCameraIcon from "mdi-react/PhotoCameraIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DragAndDropUploadOverlay} from "./drag-and-drop-upload-overlay";
import {FileTable} from "./file-table";
import {appPhotoHelper, SourceType, useFileInputChangeHandler} from "./files";
import {type FloatingActionButtonData, FloatingActionButtons} from "./floating-action-buttons";

const messages = defineMessages({
  changeName: {
    defaultMessage: "Omdøb fil",
    id: "order-files-card.toast.change-name",
  },
  invalidFile: {
    defaultMessage: "{name} er ikke en gyldig pdf eller billedfil",
    id: "order-files-card.toast.invalid-image-file",
  },
});

export const OrderFilesCard = ({
  editable = false,
  orderURL,
}: {
  editable: boolean;
  orderURL: string;
}): React.JSX.Element => {
  const {formatMessage} = useIntl();
  const orderFileArray = useSelector(getOrderFileArray);
  let filteredOrderFilesArray: OrderFile[];
  if (!editable) {
    filteredOrderFilesArray = orderFileArray.filter((f) => f.order === orderURL && f.visibleToAll);
  } else {
    filteredOrderFilesArray = orderFileArray.filter((f) => f.order === orderURL);
  }
  const dispatch = useDispatch();
  const [deleteDialogOpenFor, setDeleteDialogOpenFor] = useState<string | null>(null);
  const [nameDialogOpenFor, setNameDialogOpenFor] = useState<OrderFile | null>(null);

  const fileDropRejected = useCallback(
    (file: File): void => {
      const message = formatMessage(messages.invalidFile, {name: file.name});
      window.setTimeout(() => {
        dispatch(actions.setMessage(message, new Date()));
      }, 0);
    },
    [dispatch, formatMessage],
  );

  const currentUserURL = useSelector(getCurrentUserURL);
  const fileDropAccepted = useCallback(
    (files: File[]): void => {
      const boundCreate = (instance: ResourceTypeUnion): void => {
        dispatch(actions.create(instance));
      };
      const boundAddToOffline = (instance: ResourceTypeUnion): void => {
        dispatch(actions.addToOffline(instance));
      };
      const commonExtraData = {
        created: new Date().toISOString(),
        createdBy: currentUserURL || undefined,
        order: orderURL,
      };
      files.forEach((file) => {
        const name = file.name.replace(/\.[^.]*$/, "");
        const extraData = {name, ...commonExtraData};
        uploadFile(file, "orderFile", extraData, "data", boundCreate, boundAddToOffline);
      });
    },
    [currentUserURL, dispatch, orderURL],
  );

  const handleFileInputChange = useFileInputChangeHandler(
    fileDropAccepted,
    fileDropRejected,
    "image/jpeg,image/png,application/pdf",
  );

  const handleAppCameraButton = useCallback((): void => {
    appPhotoHelper(SourceType.CAMERA, fileDropAccepted);
  }, [fileDropAccepted]);

  const deleteDialogOk = useCallback(() => {
    if (!deleteDialogOpenFor) {
      return;
    }
    dispatch(actions.remove(deleteDialogOpenFor));
    setDeleteDialogOpenFor(null);
  }, [deleteDialogOpenFor, dispatch]);
  const deleteDialogCancel = useCallback(() => {
    setDeleteDialogOpenFor(null);
  }, []);

  const nameDialogCancel = useCallback(() => {
    setNameDialogOpenFor(null);
  }, []);
  const nameDialogOk = useCallback(
    (newName: string) => {
      if (!nameDialogOpenFor) {
        return;
      }
      dispatch(actions.update(nameDialogOpenFor.url, [{member: "name", value: newName}]));
      setNameDialogOpenFor(null);
    },
    [dispatch, nameDialogOpenFor],
  );

  const handleFiles = useCallback(
    (files: File[]): void => {
      const invalidFile = files.find(
        (file) => !["application/pdf", "image/jpeg", "image/png"].includes(file.type),
      );

      if (invalidFile) {
        fileDropRejected(invalidFile);
      } else {
        fileDropAccepted(files);
      }
    },
    [fileDropAccepted, fileDropRejected],
  );

  const buttonData: FloatingActionButtonData[] = useMemo(() => {
    if (editable) {
      if (window.cordova) {
        return [
          {
            buttonIcon: <PhotoCameraIcon />,
            name: "camera-button",
            onClick: handleAppCameraButton,
            tooltipTitle: formatMessage({defaultMessage: "Kamera"}),
          },
          {
            accept: "application/pdf",
            buttonIcon: <FilePdfBoxIcon />,
            id: "order-pdf-input",
            name: "pdf-button",
            onChange: handleFileInputChange,
            tooltipTitle: formatMessage({defaultMessage: "Pdf"}),
          },
          {
            accept: "image/jpeg,image/png",
            buttonIcon: <CollectionsIcon />,
            id: "order-photos-input",
            name: "photo-button",
            onChange: handleFileInputChange,
            tooltipTitle: formatMessage({defaultMessage: "Foto"}),
          },
        ];
      } else {
        return [
          {
            accept: "image/jpeg,image/png,application/pdf",
            buttonIcon: <PlusIcon />,
            id: "order-file-input",
            name: "file-button",
            onChange: handleFileInputChange,
            tooltipTitle: formatMessage({defaultMessage: "Fil"}),
          },
        ];
      }
    }
    return [];
  }, [editable, formatMessage, handleAppCameraButton, handleFileInputChange]);

  const content = (
    <FileTable
      currentUserCanManageFiles={editable}
      editMode={editable}
      files={filteredOrderFilesArray}
    />
  );

  if (editable) {
    const speedDial = (
      <FloatingActionButtons buttons={buttonData} name="order files" variant="component" />
    );

    return (
      <Card style={{overflow: "visible"}}>
        <DragAndDropUploadOverlay onFiles={handleFiles}>
          {speedDial}
          <CardHeader
            title={<FormattedMessage defaultMessage="Filer, ordre" id="order-files-card.files" />}
          />
          <CardMedia>{content}</CardMedia>
          <DeleteDialog
            onCancel={deleteDialogCancel}
            onOk={deleteDialogOk}
            open={!!deleteDialogOpenFor}
          />
          <SingleTextFieldDialog
            label={<FormattedMessage defaultMessage="Navn" id="order-files-card.label.name" />}
            onCancel={nameDialogCancel}
            onOk={nameDialogOk}
            open={!!nameDialogOpenFor}
            title={formatMessage(messages.changeName)}
            value={nameDialogOpenFor?.name}
          />
        </DragAndDropUploadOverlay>
      </Card>
    );
  } else {
    return (
      <Card>
        <CardHeader
          title={<FormattedMessage defaultMessage="Filer, ordre" id="order-files-card.files" />}
        />
        <CardMedia>{content}</CardMedia>
        <DeleteDialog
          onCancel={deleteDialogCancel}
          onOk={deleteDialogOk}
          open={!!deleteDialogOpenFor}
        />
        <SingleTextFieldDialog
          label={<FormattedMessage defaultMessage="Navn" id="order-files-card.label.name" />}
          onCancel={nameDialogCancel}
          onOk={nameDialogOk}
          open={!!nameDialogOpenFor}
          title={formatMessage(messages.changeName)}
          value={nameDialogOpenFor?.name}
        />
      </Card>
    );
  }
};
