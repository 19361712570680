import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React, {useCallback} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";

const messages = defineMessages({
  fullscreenLinks: {
    defaultMessage: "Fullscreen links",
    id: "fullscreen-links-dialog.title.fullscreen-links",
  },
});

interface FullscreenLinksDialogProps {
  onCancel?: () => void;
  onOk: () => void;
  open: boolean;
}

const FullscreenLinksDialog = React.memo(function FullscreenLinksDialog(
  props: FullscreenLinksDialogProps,
): React.JSX.Element {
  const {onCancel, onOk, open} = props;
  const dispatch = useDispatch();

  const handleFullscreenGeolocationMapClick = useCallback((): void => {
    dispatch(actions.go("/fullscreenGeolocationMap"));
  }, [dispatch]);
  const handleFullscreenTaskCalendarClick = useCallback((): void => {
    dispatch(actions.go("/fullscreenTaskCalendar"));
  }, [dispatch]);
  const handleFullscreenTaskCalendar1Click = useCallback((): void => {
    dispatch(actions.go("/fullscreenTaskCalendar", {}, {screen: "1"}));
  }, [dispatch]);
  const handleFullscreenTaskCalendar2Click = useCallback((): void => {
    dispatch(actions.go("/fullscreenTaskCalendar", {}, {screen: "2"}));
  }, [dispatch]);
  const handleFullscreenOrderCalendarClick = useCallback((): void => {
    dispatch(actions.go("/fullscreenOrderCalendar"));
  }, [dispatch]);
  const handleMinimalFullscreenOrderCalendarClick = useCallback((): void => {
    dispatch(actions.go("/minimalFullscreenOrderCalendar"));
  }, [dispatch]);
  const handleFullscreenAbsenceCalendarClick = useCallback((): void => {
    dispatch(actions.go("/fullscreenAbsenceCalendar"));
  }, [dispatch]);

  const {formatMessage} = useIntl();
  const style: React.CSSProperties = {cursor: "pointer"};
  return (
    <ResponsiveDialog
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      title={formatMessage(messages.fullscreenLinks)}
    >
      <DialogContent>
        <ul>
          <li onClick={handleFullscreenGeolocationMapClick} style={style}>
            <FormattedMessage
              defaultMessage="Fullscreen kort"
              id="fullscreen-links-dialog.label.fullscreen-map"
            />
          </li>
          <li onClick={handleFullscreenTaskCalendarClick} style={style}>
            <FormattedMessage
              defaultMessage="Fullscreen kalender"
              id="fullscreen-links-dialog.label.fullscreen-calendar"
            />
          </li>
          <li onClick={handleFullscreenTaskCalendar1Click} style={style}>
            <FormattedMessage
              defaultMessage="Fullscreen kalender, låst side 1"
              id="fullscreen-links-dialog.label.fullscreen-calendar-locked-page-1"
            />
          </li>
          <li onClick={handleFullscreenTaskCalendar2Click} style={style}>
            <FormattedMessage
              defaultMessage="Fullscreen kalender, låst side 2"
              id="fullscreen-links-dialog.label.fullscreen-calendar-locked-page-2"
            />
          </li>
          <li onClick={handleFullscreenOrderCalendarClick} style={style}>
            <FormattedMessage defaultMessage="Fullscreen ordrekalender" />
          </li>
          <li onClick={handleMinimalFullscreenOrderCalendarClick} style={style}>
            <FormattedMessage defaultMessage="Fullscreen ordrekalender (minimal)" />
          </li>
          <li onClick={handleFullscreenAbsenceCalendarClick} style={style}>
            <FormattedMessage defaultMessage="Fullscreen fraværskalender" />
          </li>
        </ul>
      </DialogContent>
    </ResponsiveDialog>
  );
});

export {FullscreenLinksDialog};
