import {getCustomerSettings, getTaskLookup} from "@co-frontend-libs/redux";
import {DoLoadInstance} from "app-components";
import {LoadInstanceRelated} from "app-utils";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {TaskInstanceContainer} from "./task-instance-container";

export {getComputedTime} from "./get-computed-time";

const related: LoadInstanceRelated = [
  {
    memberName: "order",
    related: [
      {
        memberName: "customer",
        resourceType: "customer",
        type: "targetOfForeignKey",
      },
      {
        memberName: "order",
        resourceType: "task",
        type: "hasForeignKey",
      },
    ],
    resourceType: "order",
    type: "targetOfForeignKey",
  },
  {
    memberName: "task",
    resourceType: "timerStart",
    type: "hasForeignKey",
  },
  {
    memberName: "task",
    related: [
      {
        memberName: "transportlog",
        related: [
          {
            memberName: "location",
            resourceType: "pickup",
            type: "hasForeignKey",
          },
        ],
        resourceType: "pickupLocation",
        type: "hasForeignKey",
      },
      {
        memberName: "transportlog",
        related: [
          {
            memberName: "location",
            resourceType: "delivery",
            type: "hasForeignKey",
          },
        ],
        resourceType: "deliveryLocation",
        type: "hasForeignKey",
      },
      {
        memberName: "transportlog",
        resourceType: "transportLogReport",
        type: "hasForeignKey",
      },
    ],
    resourceType: "transportLog",
    type: "hasForeignKey",
  },
  {
    memberName: "task",
    related: [
      {
        memberName: "yieldlog",
        related: [
          {
            memberName: "location",
            resourceType: "yieldPickup",
            type: "hasForeignKey",
          },
        ],
        resourceType: "yieldPickupLocation",
        type: "hasForeignKey",
      },
      {
        memberName: "yieldlog",
        related: [
          {
            memberName: "location",
            resourceType: "yieldDelivery",
            type: "hasForeignKey",
          },
        ],
        resourceType: "yieldDeliveryLocation",
        type: "hasForeignKey",
      },
    ],
    resourceType: "yieldLog",
    type: "hasForeignKey",
  },
  {
    memberName: "task",
    related: [
      {
        memberName: "spraylog",
        related: [
          {
            memberName: "location",
            resourceType: "spray",
            type: "hasForeignKey",
          },
        ],
        resourceType: "sprayLocation",
        type: "hasForeignKey",
      },
      {
        memberName: "spraylog",
        resourceType: "sprayLogReport",
        type: "hasForeignKey",
      },
    ],
    resourceType: "sprayLog",
    type: "hasForeignKey",
  },
  {
    memberName: "task",
    resourceType: "reportingPrintout",
    type: "hasForeignKey",
  },
  {
    memberName: "task",
    resourceType: "taskPhoto",
    type: "hasForeignKey",
  },
  {
    memberName: "task",
    resourceType: "taskFile",
    type: "hasForeignKey",
  },
  {
    memberName: "task",
    resourceType: "locationStorageChange",
    type: "hasForeignKey",
  },
] as const;

const relatedRoutes: LoadInstanceRelated = [
  ...related,
  {
    memberName: "route",
    related: [
      {
        memberName: "routeTask",
        resourceType: "routeTaskResult",
        type: "hasForeignKey",
      },
      {
        memberName: "routeTask",
        resourceType: "routeTaskActivityOption",
        type: "hasForeignKey",
      },
    ],
    resourceType: "routeTask",
    type: "hasForeignKey",
  },
  {memberName: "route", resourceType: "routeLogReport", type: "hasForeignKey"},
] as const;

function LoadTaskInstance(): React.JSX.Element {
  const {formatMessage} = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  return (
    <DoLoadInstance
      Component={TaskInstanceContainer}
      loadingTitle={formatMessage({defaultMessage: "Opgave"})}
      lookupSelector={getTaskLookup}
      related={customerSettings.routesEnabled ? relatedRoutes : related}
      resourceName="task"
    />
  );
}

export default LoadTaskInstance;
