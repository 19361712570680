import {ProductGroup, ProductGroupUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {EntryData, GenericSingleSelectionSearchDialog} from "../search-dialog";

const messages = defineMessages({
  addProductGroup: {
    defaultMessage: "Vælg varegruppe",
    id: "product-dialog.dialog-title.add-product-group",
  },
  searchProductGroup: {
    defaultMessage: "Søg varegruppe",
    id: "product-dialog.dialog-title.search-product-group",
  },
});

const eConomicProductGroupPrefix = "https://restapi.e-conomic.com/product-groups/";

function computeBaseChoices(
  productGroupArray: readonly ProductGroup[],
): readonly EntryData<ProductGroupUrl>[] {
  return productGroupArray
    .filter(({active}) => active)
    .map((productGroup) => {
      const {name, remoteUrl, url} = productGroup;
      const identifier = remoteUrl.startsWith(eConomicProductGroupPrefix)
        ? remoteUrl.substring(eConomicProductGroupPrefix.length)
        : remoteUrl;
      const entry: EntryData<ProductGroupUrl> = {
        category: "standard",
        exactMatchValue: identifier,
        identifier: url,
        primaryText: name,
        searchFields: [
          {label: "Nr.", priority: 10, text: identifier},
          {label: "Navn", priority: 5, text: name},
        ],
        secondaryText: identifier,
      };
      return entry;
    });
}

interface SingleProductGroupDialogProps {
  onCancel(): void;
  onOk(url: ProductGroupUrl): void;
  open: boolean;
  productGroupArray: readonly ProductGroup[];
}

export const SingleProductGroupDialog = React.memo(function SingleProductGroupDialog(
  props: SingleProductGroupDialogProps,
) {
  const {onCancel, onOk, open, productGroupArray} = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.addProductGroup);
  const searchTitle = intl.formatMessage(messages.searchProductGroup);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(productGroupArray);

  return (
    <GenericSingleSelectionSearchDialog<ProductGroupUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
    />
  );
});
