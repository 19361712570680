import type {MarkOptional} from "ts-essentials";
import {EmployeeGroupUrl, Role, User, UserProfile, UserUrl} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {
  getActiveEmployeeGroupArray,
  getCustomerSettings,
  getCustomerSettingsMember,
  getSelectableUsers,
  getUserRoleLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";
import {SingleSelectionDialogProps} from "../search-dialog/single-selection/single-selection-dialog";

const messages = defineMessages({
  searchChauffeur: {
    defaultMessage: "Søg chauffør",
    id: "machine-operator-dialog.dialog-title.search-chauffeur",
  },
  searchEmployee: {
    defaultMessage: "Søg medarbejder",
    id: "machine-operator-dialog.dialog-title.search-employee",
  },
  searchEmployees: {
    defaultMessage: "Søg medarbejdere",
    id: "user-dialog.dialog-title.search-employees",
  },
  searchMachineOperator: {
    defaultMessage: "Søg maskinfører",
    id: "machine-operator-dialog.dialog-title.search-machine-operator",
  },
  selectChauffeur: {
    defaultMessage: "Vælg chauffør",
    id: "machine-operator-dialog.dialog-title.select-chauffeur",
  },
  selectEmployee: {
    defaultMessage: "Vælg medarbejder",
    id: "machine-operator-dialog.dialog-title.select-employee",
  },
  selectEmployees: {
    defaultMessage: "Vælg medarbejdere",
    id: "user-dialog.dialog-title.select-employees",
  },
  selectMachineOperator: {
    defaultMessage: "Vælg maskinfører",
    id: "machine-operator-dialog.dialog-title.select-machine-operator",
  },
});

function computeBaseChoices(
  userArray: readonly User[],
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined,
  userRoleLookup: (url: UserUrl) => Role | undefined,
): readonly EntryData<UserUrl>[] {
  const result: EntryData<UserUrl>[] = userArray
    .filter((instance) => {
      if (!instance.active) {
        return false;
      }
      const role = userRoleLookup(instance.url);
      return !role || (!role.consultant && !role.breakRoom);
    })
    .map((instance) => {
      const {url} = instance;
      const profile = userUserProfileLookup(url);
      const name = profile?.name || "";
      const initials = profile?.alias || "";
      const entry: EntryData<UserUrl> = {
        category: "standard",
        exactMatchValue: initials,
        identifier: url,
        primaryText: initials,
        searchFields: [
          {label: "Initialer", priority: 10, text: initials},
          {label: "Navn", priority: 5, text: name},
        ],
        secondaryText: name,
      };
      return entry;
    });

  return result;
}

export type UserDialogSelectionProps = Pick<
  SingleSelectionDialogProps<UserUrl>,
  "onCancel" | "onNone" | "onOk" | "open" | "title"
>;

export interface UserDialogProps extends MarkOptional<UserDialogSelectionProps, "title"> {
  userArray: readonly User[];
}

export const UserDialog = React.memo(function UserDialog(props: UserDialogProps) {
  const {onCancel, onNone, onOk, open, userArray} = props;
  const intl = useIntl();
  const userRoleLookup = useSelector(getUserRoleLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const employeeLabelVarient = useSelector(getCustomerSettingsMember("employeeLabelVariant"));

  const title =
    props.title ||
    (employeeLabelVarient === "MACHINEOPERATOR"
      ? intl.formatMessage(messages.selectMachineOperator)
      : employeeLabelVarient === "EMPLOYEE"
        ? intl.formatMessage(messages.selectEmployee)
        : intl.formatMessage(messages.selectChauffeur));
  const searchTitle =
    employeeLabelVarient === "MACHINEOPERATOR"
      ? intl.formatMessage(messages.searchMachineOperator)
      : employeeLabelVarient === "EMPLOYEE"
        ? intl.formatMessage(messages.searchEmployee)
        : intl.formatMessage(messages.searchChauffeur);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(userArray, userUserProfileLookup, userRoleLookup);

  return (
    <GenericSingleSelectionSearchDialog<UserUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onNone={onNone}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      title={title}
    />
  );
});

type UserOrEmployeeGroupUrl = EmployeeGroupUrl | UserUrl;

interface MultipleUsersDialogProps {
  onCancel(): void;
  onOk(urls: ReadonlySet<UserOrEmployeeGroupUrl>): void;
  open: boolean;
  selected?: ReadonlySet<UserOrEmployeeGroupUrl> | undefined;
}

export const MultipleUsersDialog = React.memo(function MultipleUsersDialog(
  props: MultipleUsersDialogProps,
) {
  const {onCancel, onOk, open, selected} = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.selectEmployees);
  const searchTitle = intl.formatMessage(messages.searchEmployees);
  const employeeGroupArray = useSelector(getActiveEmployeeGroupArray);
  const {enableEmployeeGroups} = useSelector(getCustomerSettings);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);

  const selectableUsers = useSelector(getSelectableUsers);

  const userEntries = useMemo(() => {
    const result: EntryData<UserOrEmployeeGroupUrl>[] = selectableUsers
      .map(({url}) => {
        const profile = userUserProfileLookup(url);
        if (!profile) {
          return undefined;
        }
        const {alias, employeeGroup, name} = profile;
        const entry: EntryData<UserOrEmployeeGroupUrl> = {
          category: "standard",
          exactMatchValue: alias,
          group: employeeGroup,
          identifier: url,
          primaryText: alias,
          searchFields: [
            {
              label: intl.formatMessage({defaultMessage: "Initialer"}),
              priority: 10,
              text: alias,
            },
            {label: intl.formatMessage({defaultMessage: "Navn"}), priority: 5, text: name},
          ],
          secondaryText: name,
        };
        return entry;
      })
      .filter(notUndefined);

    return result;
  }, [selectableUsers, userUserProfileLookup, intl]);

  const employeeGroupEntries = useMemo(() => {
    const result: readonly EntryData<UserOrEmployeeGroupUrl>[] = enableEmployeeGroups
      ? employeeGroupArray
          .map(({name, url}) => {
            const hasUsersInEmployeeGroup = userEntries.some(({group}) => group === url);

            if (!hasUsersInEmployeeGroup) {
              return undefined;
            }

            const entry: EntryData<UserOrEmployeeGroupUrl> = {
              category: "group",
              identifier: url,
              primaryText: name,
              priority: true,
              searchFields: [
                {label: intl.formatMessage({defaultMessage: "Navn"}), priority: 6, text: name},
              ],
              secondaryText: name,
            };
            return entry;
          })
          .filter(notUndefined)
      : [];

    return result;
  }, [employeeGroupArray, enableEmployeeGroups, intl, userEntries]);

  const data = useMemo(
    () => [...employeeGroupEntries, ...userEntries],
    [employeeGroupEntries, userEntries],
  );
  return (
    <GenericMultiSelectionSearchDialog<UserOrEmployeeGroupUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      title={title}
    />
  );
});
