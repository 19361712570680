import {LocationUrl} from "@co-common-libs/resources";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {LocationFiltering} from "../location-filtering";

interface LocationSelectionProps {
  locations: readonly LocationUrl[];
  onSelectedLocationsChange: (value: ReadonlySet<LocationUrl>) => void;
  selectedLocations: ReadonlySet<LocationUrl>;
}

export function LocationSelection(props: LocationSelectionProps): React.JSX.Element {
  const {locations, onSelectedLocationsChange, selectedLocations} = props;
  return (
    <Card>
      <CardHeader
        title={
          <FormattedMessage
            defaultMessage="Vælg steder"
            id="storage-journal.card-header.select-locations"
          />
        }
      />
      <CardContent>
        <LocationFiltering
          onSelectedChange={onSelectedLocationsChange}
          options={locations}
          selected={selectedLocations}
        />
      </CardContent>
    </Card>
  );
}
