import {
  ContactUrl,
  CustomerUrl,
  LocationUrl,
  Project,
  ProjectAccess,
  ProjectUrl,
  Task,
} from "@co-common-libs/resources";
import {getOrderLookup, getProjectLookup} from "@co-frontend-libs/redux";
import React, {RefObject, useCallback} from "react";
import {useSelector} from "react-redux";
import {InformationBlockPropsBase} from "../information-block-props-base";
import {ConfirmDialogVisibility, ProjectBlock, UserConfirmations} from "../project-block";

interface TaskProjectBlockProps extends InformationBlockPropsBase {
  customerUrl: CustomerUrl | null;
  defaultAccessOnCreate: ProjectAccess;
  onContactChanged: (contactUrl: ContactUrl | null) => void;
  onCustomerChanged: (customerUrl: CustomerUrl | null) => void;
  onLocationChanged: (locationUrl: LocationUrl | null) => void;
  onProjectChanged: (projectUrl: ProjectUrl | null) => void;
  projectUrl: ProjectUrl | null;
  ref: RefObject<HTMLButtonElement>;
  task: Task;
}

export const TaskProjectBlock = function (props: TaskProjectBlockProps): React.JSX.Element {
  const {
    customerUrl,
    defaultAccessOnCreate,
    onContactChanged,
    onCustomerChanged,
    onLocationChanged,
    onProjectChanged,
    projectUrl,
    task,
  } = props;

  const projectLookup = useSelector(getProjectLookup);
  const orderLookup = useSelector(getOrderLookup);

  const handleDialogsConfirmed = useCallback(
    (userConfirmations: UserConfirmations, project: Project) => {
      if (userConfirmations.contactConfirmDialogResponse) {
        onContactChanged(project.contact);
      }
      if (userConfirmations.workplaceConfirmDialogResponse) {
        onLocationChanged(project.relatedWorkplace);
      }
    },
    [onContactChanged, onLocationChanged],
  );

  const computeConfirmDialogVisibility = useCallback(
    (selectedProjectUrl: ProjectUrl): ConfirmDialogVisibility => {
      const newProject = projectLookup(selectedProjectUrl);
      const showRelatedWorkplaceConfirmDialog =
        newProject?.relatedWorkplace !== task.relatedWorkplace && !!task.relatedWorkplace;

      const order = task.order && orderLookup(task.order);
      const showContactConfirmDialog =
        !!order && newProject?.contact !== order.contact && !!order.contact;

      return {
        showContactConfirmDialog,
        showRelatedWorkplaceConfirmDialog,
      };
    },

    [orderLookup, projectLookup, task.order, task.relatedWorkplace],
  );

  const handleProjectChanged = useCallback(
    (newProjectUrl: ProjectUrl | null) => {
      if (newProjectUrl) {
        onProjectChanged(newProjectUrl);
        const project = newProjectUrl && projectLookup(newProjectUrl);
        const order = task.order && orderLookup(task.order);

        if (!task.relatedWorkplace && project) {
          onLocationChanged(project.relatedWorkplace);
        }

        if (order && !order.contact && project) {
          onContactChanged(project.contact);
        }
      } else {
        onProjectChanged(newProjectUrl);
      }
    },
    [
      onProjectChanged,
      projectLookup,
      task.order,
      task.relatedWorkplace,
      orderLookup,
      onLocationChanged,
      onContactChanged,
    ],
  );

  return (
    <ProjectBlock
      computeConfirmDialogVisibility={computeConfirmDialogVisibility}
      customerUrl={customerUrl}
      defaultAccessOnCreate={defaultAccessOnCreate}
      onCustomerChanged={onCustomerChanged}
      onDialogsConfirmed={handleDialogsConfirmed}
      onProjectChanged={handleProjectChanged}
      projectUrl={projectUrl}
    />
  );
};
