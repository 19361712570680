import {SettingID} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {
  Checkbox,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {MultiSelectableListItem} from "app-components";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {defaultAbsenceTypeLabels} from "./absence-type-labels";

interface SelectDayAbsenceTypesDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export const SelectDayAbsenceTypesDialog = React.memo(function SelectDayAbsenceTypesDialog(
  props: SelectDayAbsenceTypesDialogProps,
): React.JSX.Element {
  const {onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const currentlySelected: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);

  const customerSettings = useSelector(getCustomerSettings);
  const {absenceTypeLabels} = customerSettings;

  const knownAbsenceTypeLabels: {[key: string]: string} = useMemo(
    () => ({
      ...defaultAbsenceTypeLabels,
      ...absenceTypeLabels,
    }),
    [absenceTypeLabels],
  );

  const [selected, setSelected] = useState(new Set(currentlySelected));

  useEffect(() => {
    if (open) {
      setSelected(new Set(currentlySelected));
    }
  }, [currentlySelected, open]);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleDialogOk = useCallback(() => {
    const newValue = [...selected];
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValue},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, selected, settingEntry]);

  const handleCheck = useCallback(
    (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
      const newSelected = new Set(selected);
      if (selected.has(value)) {
        newSelected.delete(value);
      } else {
        newSelected.add(value);
      }
      setSelected(newSelected);
    },
    [selected],
  );
  const intl = useIntl();
  const {daysAbsenceTypes, singleDayAbsenceTypes} = customerSettings;

  const allowedAbsenceTypes = useMemo(
    () => new Set([...daysAbsenceTypes, ...singleDayAbsenceTypes]),
    [daysAbsenceTypes, singleDayAbsenceTypes],
  );
  const listItems = useMemo(() => {
    const items: React.JSX.Element[] = [...allowedAbsenceTypes].map((absenceType) => {
      return (
        <MultiSelectableListItem
          isSelected={selected.has(absenceType)}
          key={absenceType}
          onCheck={handleCheck}
          primaryText={
            knownAbsenceTypeLabels[absenceType] ||
            intl.formatMessage({defaultMessage: "Mangler label: {absenceType}"}, {absenceType})
          }
          value={absenceType}
        />
      );
    });
    selected.forEach((absenceType) => {
      if (!allowedAbsenceTypes.has(absenceType)) {
        items.push(
          <MultiSelectableListItem
            isSelected={selected.has(absenceType)}
            key={absenceType}
            onCheck={handleCheck}
            primaryText={intl.formatMessage(
              {defaultMessage: "Ikke gyldig valgt: {absenceTypeOrLabel}"},
              {
                absenceTypeOrLabel: knownAbsenceTypeLabels[absenceType] || absenceType,
              },
            )}
            value={absenceType}
          />,
        );
      }
    });
    return items;
  }, [allowedAbsenceTypes, handleCheck, intl, selected, knownAbsenceTypeLabels]);

  const allSelected = useMemo(
    () => [...allowedAbsenceTypes].every((absenceType) => selected.has(absenceType)),
    [allowedAbsenceTypes, selected],
  );
  const handleToggleAll = useCallback(() => {
    if (allSelected) {
      setSelected(new Set<string>());
    } else {
      setSelected(allowedAbsenceTypes);
    }
  }, [allSelected, allowedAbsenceTypes]);

  return (
    <ResponsiveDialog
      okDisabled={[...selected].some((absenceType) => !allowedAbsenceTypes.has(absenceType))}
      onCancel={props.onClose}
      onOk={handleDialogOk}
      open={open}
      title={
        <FormattedMessage defaultMessage="Vælg fraværstyper" id="select-absence-types.title" />
      }
    >
      <DialogContent dividers style={{padding: 0}}>
        <List>
          <ListItem button onClick={handleToggleAll}>
            <ListItemIcon>
              <Checkbox
                checked={allSelected}
                disableRipple
                edge="start"
                indeterminate={selected.size > 0 && !allSelected}
                onChange={handleToggleAll}
                tabIndex={-1}
              />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage defaultMessage="Vælg alle" />} />
          </ListItem>
          <Divider />
          {listItems}
        </List>
      </DialogContent>
    </ResponsiveDialog>
  );
});
