import {
  ComputedTime,
  EmployeeGroupUrl,
  Task,
  UserUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  getUnitString,
  groupIntervals,
  injectUnregisteredBreaks,
  priceItemIsVisible,
} from "@co-common-libs/resources-utils";
import {
  dateFromString,
  dateToString,
  defaultToEmpty,
  defaultToEmptyString,
  defaultToNull,
  formatAddress,
  getEndOfDate,
  getStartOfDate,
  makeMapFromArray,
  MINUTE_MILLISECONDS,
  notNull,
} from "@co-common-libs/utils";
import {
  AppbarSearchField,
  FilePdfIcon,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {ConnectedMachineOperatorDialog} from "@co-frontend-libs/connected-components";
import {Check, makeQuery, Query} from "@co-frontend-libs/db-resources";
import {
  actions,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPathName,
  getPriceGroupLookup,
  getPriceItemLookup,
  getProductLookup,
  getProjectArray,
  getProjectLookup,
  getSyncedState,
  getTaskArray,
  getTimerArray,
  getTimerLookup,
  getToken,
  getUnitLookup,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
  isEmployeeGroupsActivated,
} from "@co-frontend-libs/redux";
import {useCallWithTrue} from "@co-frontend-libs/utils";
import {Tab, Tabs} from "@material-ui/core";
import {FilterBar, FilterButton, MenuToolbar, PageLayout, PeriodDialog} from "app-components";
import {
  ComputedTaskTimeResult,
  computeIntervalSums,
  computeWorkFromTo,
  intervalMinutes,
  useComputedTaskTime,
  useDeviceConfig,
  useEventTargetValueCallback,
  useQueryParameterState,
} from "app-utils";
import bowser from "bowser";
import {endOfDay, startOfDay} from "date-fns";
import FileSaver from "file-saver";
import {globalConfig} from "frontend-global-config";
import _, {flatMap, partition, sortBy, sum, sumBy} from "lodash";
import SyncIcon from "mdi-react/SyncIcon";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {computeRowData} from "../bookkeeping-day/bookkeeping-task-table";
import {BookkeepingArchive} from "./bookkeeping-archive";
import {BookkeepingNormalTabs} from "./bookkeeping-normal-tabs";

function getStartEndDiff(computedTaskTimes: ComputedTaskTimeResult[]): {
  diff: number;
  firstStart: any;
  lastEnd: any;
} {
  if (computedTaskTimes.length < 2) {
    return {
      diff: 0,
      firstStart: null,
      lastEnd: null,
    };
  }

  const [firstTask, ...nextTasks] = sortBy(
    computedTaskTimes.map(({task}) => task),
    (task) => task?.workFromTimestamp,
  ).filter(notNull);

  let firstStart = firstTask.workFromTimestamp || null;
  let lastEnd = nextTasks[nextTasks.length - 1]?.workFromTimestamp || null;

  const combinedIntervals = flatMap(computedTaskTimes, ({intervals}) => intervals);

  if (combinedIntervals.length >= 2) {
    firstStart = combinedIntervals[0].fromTimestamp;
    lastEnd = combinedIntervals[combinedIntervals.length - 1].toTimestamp;
  }

  const startEndMilliseconds =
    lastEnd && firstStart ? new Date(lastEnd).valueOf() - new Date(firstStart).valueOf() : 0;
  const startEndMinutes = Math.round(startEndMilliseconds / MINUTE_MILLISECONDS);

  return {
    diff: startEndMinutes,
    firstStart,
    lastEnd,
  };
}

const MOBILE = bowser.mobile;

const messages = defineMessages({
  archive: {
    defaultMessage: "Arkiv",
  },
  pdfPeriod: {
    defaultMessage: "Vælg periode for PDF",
  },
  pdfUserChauffeur: {
    defaultMessage: "Vælg chauffør for PDF",
  },
  pdfUserEmployee: {
    defaultMessage: "Vælg medarbejder for PDF",
  },
  pdfUserMachineOperator: {
    defaultMessage: "Vælg maskinfører for PDF",
  },
  readyForBilling: {
    defaultMessage: "Fakturering",
  },
  readyForBillingHandheld: {
    defaultMessage: "Fak.",
  },
  readyForValidation: {
    defaultMessage: "Godkendelse",
  },
  readyForValidationHandheld: {
    defaultMessage: "Godk.",
  },
  title: {
    defaultMessage: "Dagsedler",
  },
});

const TAB_NAMES = ["readyForValidation", "readyForBilling", "archive"] as const;

type TabName = (typeof TAB_NAMES)[number];

const TEMPORARY_QUERIES_KEY = "Bookkeeping";

export const Bookkeeping = React.memo(function Bookeeping({
  onMenuButton,
}: {
  onMenuButton: (event: React.MouseEvent) => void;
}): React.JSX.Element {
  const [pdfOptionsDialogOpen, setPdfOptionsDialogOpen] = useState(false);

  const [pdfOptionsEmployee, setPdfOptionsEmployee] = useState<UserUrl | null>(null);
  const [pdfOptionsPeriodEnd, setPdfOptionsPeriodEnd] = useState<string | null>(null);
  const [pdfOptionsPeriodStart, setPdfOptionsPeriodStart] = useState<string | null>(null);
  const [pdfQuery, setPdfQuery] = useState<Query | null>(null);
  const userLookup = useSelector(getUserLookup);
  const pathName = useSelector(getPathName);
  const dispatch = useDispatch();
  const taskSyncedState = useSelector(getSyncedState.bind(null, "task"));
  const customerSettings = useSelector(getCustomerSettings);
  const taskArray = useSelector(getTaskArray);
  const timerArray = useSelector(getTimerArray);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);

  const customerLookup = useSelector(getCustomerLookup);
  const locationLookup = useSelector(getLocationLookup);
  const machineLookup = useSelector(getMachineLookup);
  const orderLookup = useSelector(getOrderLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const projectArray = useSelector(getProjectArray);
  const projectLookup = useSelector(getProjectLookup);
  const timerLookup = useSelector(getTimerLookup);
  const unitLookup = useSelector(getUnitLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const token = useSelector(getToken);

  const {breakTimer, computeTaskTime} = useComputedTaskTime();

  const [q, setQ] = useQueryParameterState<string>("q", "");

  const [bookkeepingListVisibleWorkTypes, setBookkeepingListVisibleWorkTypes] = useDeviceConfig<
    WorkTypeUrl[]
  >("bookkeepingListVisibleWorkTypes");
  const [bookkeepingListVisibleDepartments, setBookkeepingListVisibleDepartments] = useDeviceConfig<
    string[]
  >("bookkeepingListVisibleDepartments");
  const [bookkeepingListVisibleEmployeeGroup, setBookkeepingListVisibleEmployeeGroups] =
    useDeviceConfig<EmployeeGroupUrl[]>("bookkeepingListVisibleEmployeeGroups");

  const [selectedWorkTypeURLSet, setSelectedWorkTypeURLSet] = useState<ReadonlySet<WorkTypeUrl>>(
    new Set(bookkeepingListVisibleWorkTypes || []),
  );
  const [selectedEmployeeGroupURLSet, setSelectedEmployeeGroupURLSet] = useState<
    ReadonlySet<EmployeeGroupUrl>
  >(new Set(bookkeepingListVisibleEmployeeGroup || []));

  const [selectedDepartmentIdentifierSet, setSelectedDepartmentIdentifierSet] = useState<
    ReadonlySet<string>
  >(new Set(bookkeepingListVisibleDepartments || []));

  const [activeTab, setActiveTab] = useQueryParameterState<TabName>("tab", "readyForValidation");
  const {formatMessage} = useIntl();
  const handleFilterStringChange = useEventTargetValueCallback(setQ, [setQ]);

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string) => {
      console.assert(TAB_NAMES.includes(value as TabName));
      setActiveTab(value as TabName);
    },
    [setActiveTab],
  );

  const handlePDFButton = useCallback(() => {
    setPdfOptionsDialogOpen(true);
    setPdfOptionsEmployee(null);
  }, []);

  const handlePDFDialogCancel = useCallback(() => {
    setPdfOptionsDialogOpen(false);
    setPdfOptionsEmployee(null);
  }, []);

  const handleWorkTypeFilterChange = useCallback(
    (selected: ReadonlySet<WorkTypeUrl>) => {
      setSelectedWorkTypeURLSet(selected);
      setBookkeepingListVisibleWorkTypes([...selected]);
    },
    [setBookkeepingListVisibleWorkTypes],
  );

  const handleEmployeeGroupFilterChange = useCallback(
    (selected: ReadonlySet<EmployeeGroupUrl>) => {
      setSelectedEmployeeGroupURLSet(selected);
      setBookkeepingListVisibleEmployeeGroups([...selected]);
    },
    [setBookkeepingListVisibleEmployeeGroups],
  );

  const handleDepartmentFilterChange = useCallback(
    (selected: ReadonlySet<string>) => {
      setSelectedDepartmentIdentifierSet(selected);
      setBookkeepingListVisibleDepartments([...selected]);
    },
    [setBookkeepingListVisibleDepartments],
  );

  const handleFilterClear = useCallback(() => {
    setSelectedWorkTypeURLSet(new Set());
    setSelectedDepartmentIdentifierSet(new Set());
    setSelectedEmployeeGroupURLSet(new Set());
    setBookkeepingListVisibleWorkTypes([]);
    setBookkeepingListVisibleDepartments([]);
    setBookkeepingListVisibleEmployeeGroups([]);
  }, [
    setBookkeepingListVisibleDepartments,
    setBookkeepingListVisibleEmployeeGroups,
    setBookkeepingListVisibleWorkTypes,
  ]);

  const handlePDFPeriodDialogOk = useCallback(
    ({periodEnd, periodStart}: {periodEnd: string; periodStart: string}) => {
      if (!pdfOptionsEmployee) {
        return;
      }
      const machineOperator = userLookup(pdfOptionsEmployee);
      const machineOperatorProfile = userUserProfileLookup(pdfOptionsEmployee);
      if (!machineOperator || !machineOperatorProfile) {
        return;
      }
      const date = dateFromString(periodStart) as Date;
      let dateString = periodStart;
      const dates: string[] = [];
      while (dateString <= periodEnd) {
        dates.push(dateString);
        date.setDate(date.getDate() + 1);
        dateString = dateToString(date);
      }
      const rangeStartString = startOfDay(dateFromString(periodStart) as Date).toISOString();
      const rangeEndString = endOfDay(dateFromString(periodEnd) as Date).toISOString();

      const taskCheck: Check = {
        checks: [
          {
            memberName: "workFromTimestamp",
            type: "memberGte",
            value: rangeStartString,
          },
          {
            memberName: "workFromTimestamp",
            type: "memberLte",
            value: rangeEndString,
          },
          {
            memberName: "machineOperator",
            type: "memberEq",
            value: machineOperator.url,
          },
        ],
        type: "and",
      };
      const orderCheck: Check = {
        check: taskCheck,
        fromResource: "task",
        memberName: "order",
        type: "targetOfForeignKey",
      };

      const queries = [
        makeQuery({
          check: taskCheck,
          filter: {
            bookkeepingDate: dates.join(","),
            initials: machineOperatorProfile.alias,
          },
          independentFetch: true,
          resourceName: "task",
        }),
        makeQuery({
          check: orderCheck,
          independentFetch: false,
          resourceName: "order",
        }),
      ];
      dispatch(actions.temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY));

      setPdfOptionsDialogOpen(false);
      setPdfOptionsPeriodEnd(periodEnd);
      setPdfOptionsPeriodStart(periodStart);
      setPdfQuery(queries[0]);
    },
    [dispatch, pathName, pdfOptionsEmployee, userLookup, userUserProfileLookup],
  );

  const getQueryState = useCallback((): {
    error: boolean;
    fetched: boolean;
    fetching: boolean;
  } => {
    const query = pdfQuery as Query;
    console.assert(query);
    const syncedState = taskSyncedState.get(query.keyString);
    const result = {
      error: false,
      fetched: false,
      fetching: false,
    };
    if (!syncedState || syncedState.queryState.currentlyFullFetching) {
      result.fetching = true;
    } else {
      const lastTimestamp = syncedState.queryState.fullFetchDataComputedAtTimestamp as string;
      console.assert(lastTimestamp);
      const {lastErrorTimestamp} = syncedState.queryState;
      if (lastErrorTimestamp && lastErrorTimestamp > lastTimestamp) {
        result.error = true;
      } else if (lastTimestamp) {
        result.fetched = true;
      }
    }
    return result;
  }, [pdfQuery, taskSyncedState]);
  const isReady = useMemo(() => {
    if (!pdfOptionsEmployee || !pdfOptionsPeriodStart || !pdfOptionsPeriodEnd || !pdfQuery) {
      return false;
    }
    const state = getQueryState();
    return !state.fetching && !state.error && state.fetched;
  }, [getQueryState, pdfOptionsEmployee, pdfOptionsPeriodEnd, pdfOptionsPeriodStart, pdfQuery]);

  const getPDFRow = useCallback(
    (
      task: Task,
      now: Date,
      taskIntervals: readonly ComputedTime[],
      dateString: string,
    ): {
      customer: string;
      date: string;
      effectiveMinutes: number;
      end: string | null;
      machineList: string[];
      minutes: number;
      notesFromMachineOperator: string;
      notesFromManager: string;
      priceGroup: string;
      priceItemUseList: {
        count: number | null;
        priceItem: string;
        unit: string;
      }[];
      productUseList: {
        count: number | null;
        note: string;
        ours: boolean;
        product: string;
        unit: string;
      }[];
      referenceNumber: string;
      start: string | null;
      timerNotes: string[];
      workplace: string;
      workType: string;
    } => {
      const orderURL = task.order;
      const order = typeof orderURL === "string" ? orderLookup(orderURL) : orderURL;
      const customerURL = order ? order.customer : null;
      const customer = typeof customerURL === "string" ? customerLookup(customerURL) : customerURL;
      const workTypeURL = task.workType;
      const workType = typeof workTypeURL === "string" ? workTypeLookup(workTypeURL) : workTypeURL;
      const priceGroupUrl = task.priceGroup;
      const priceGroup =
        customerSettings.bookkeepingWorkTypeAsWorkTypeAndVariant &&
        typeof priceGroupUrl === "string"
          ? priceGroupLookup(priceGroupUrl)
          : null;
      // const hasOverlap = taskHasIntervalOverlap[taskURL];
      const {
        externalPrimaryMinutes,
        machineUseList,
        priceItemUseList,
        productUseList,
        totalRegistered,
        workplace,
      } = computeRowData({
        intervals: taskIntervals,
        locationLookup,
        machineLookup,
        now,
        priceGroupLookup,
        priceItemLookup,
        productLookup,
        projectArray,
        projectLookup,
        task,
        timerArray,
        timerLookup,
        workTypeLookup,
      });

      let workplaceString;
      if (customerSettings.bookkeepingWorkplaceAddressReplaceName && workplace) {
        workplaceString = formatAddress(workplace);
      } else if (workplace) {
        workplaceString = workplace.name || formatAddress(workplace);
      }
      if (!workplaceString) {
        workplaceString = task.address || "";
      }
      const customerName = customer ? customer.name : null;
      const machineList = machineUseList.map((machineUse) => {
        const {machine} = machineUse;
        if (machine) {
          if (customerSettings.bookkeepingMachineNames) {
            const {name} = machine;
            const identifier = machine.c5_machine;
            if (name && identifier) {
              return `${name} (${identifier})`;
            } else if (name) {
              return name;
            } else if (identifier) {
              return identifier;
            } else {
              return "";
            }
          } else {
            return machine.c5_machine;
          }
        } else {
          return "";
        }
      });
      let workTypeString = null;
      if (workType) {
        workTypeString = workType.name;
      }
      const priceGroupString = priceGroup ? priceGroup.name : "";
      const referenceNumberString = customerSettings.enableTaskReferenceNumber
        ? task.referenceNumber || ""
        : customerSettings.enableOrderReferenceNumber
          ? order?.referenceNumber || ""
          : "";
      const priceItemUseStringList = priceItemUseList
        .filter((priceItemUse) => {
          const {priceItem} = priceItemUse;
          return priceItem && priceItemIsVisible(priceItem, true, priceItemUseList, unitLookup);
        })
        .map((priceItemUse) => {
          const {priceItem} = priceItemUse;
          let priceItemString = "";
          let unitString = "";
          if (priceItem) {
            priceItemString = priceItem.name;
            unitString = getUnitString(priceItem, unitLookup);
          }
          return {
            count: priceItemUse.count,
            priceItem: priceItemString,
            unit: unitString,
          };
        });
      const productUseStringList = productUseList.map((productUse) => {
        const {product} = productUse;
        let productString = "";
        let unitString = "";
        if (product) {
          productString = `${product.name} (${product.catalogNumber})`;
          unitString = getUnitString(product, unitLookup);
        }
        return {
          count: productUse.count,
          note: productUse.notes || "",
          ours: productUse.ours,
          product: productString,
          unit: unitString,
        };
      });
      return {
        customer: customerName || "",
        date: dateString,
        effectiveMinutes: externalPrimaryMinutes,
        end: task.workToTimestamp,
        machineList,
        minutes: totalRegistered,
        notesFromMachineOperator: task.notesFromMachineOperator || "",
        notesFromManager: task.notesFromManager || "",
        priceGroup: priceGroupString,
        priceItemUseList: priceItemUseStringList,
        productUseList: productUseStringList,
        referenceNumber: referenceNumberString,
        start: task.workFromTimestamp,
        timerNotes: task.timernotesSet.map((entry) => {
          const timer = timerLookup(entry.timer);
          const timerLabel = timer ? timer.label : "";
          return `${timerLabel}: ${entry.notes}`;
        }),
        workplace: workplaceString || "",
        workType: workTypeString || "",
      };
    },
    [
      customerLookup,
      customerSettings.bookkeepingMachineNames,
      customerSettings.bookkeepingWorkTypeAsWorkTypeAndVariant,
      customerSettings.bookkeepingWorkplaceAddressReplaceName,
      customerSettings.enableOrderReferenceNumber,
      customerSettings.enableTaskReferenceNumber,
      locationLookup,
      machineLookup,
      orderLookup,
      priceGroupLookup,
      priceItemLookup,
      productLookup,
      projectArray,
      projectLookup,
      timerArray,
      timerLookup,
      unitLookup,
      workTypeLookup,
    ],
  );

  const getReportData = useCallback((): {
    dayEntries: any[];
    deviceTimestamp: string;
    machineOperatorInitials: any;
    machineOperatorName: any;
    referenceNumberLabel: string;
    withPriceGroup: boolean;
    withReferenceNumber: boolean;
  } => {
    const periodStartDate = pdfOptionsPeriodStart as string;
    console.assert(periodStartDate);
    const periodEndDate = pdfOptionsPeriodEnd as string;
    console.assert(periodEndDate);
    const machineOperatorURL = pdfOptionsEmployee as UserUrl;
    console.assert(machineOperatorURL);

    const machineOperatorProfile = userUserProfileLookup(machineOperatorURL);
    const machineOperatorInitials = machineOperatorProfile ? machineOperatorProfile.alias : "";
    const machineOperatorName = machineOperatorProfile ? machineOperatorProfile.name : "";
    const periodStartTimestamp = getStartOfDate(periodStartDate);
    const periodEndTimestamp = getEndOfDate(periodEndDate);
    const taskSeq = taskArray
      .filter((task) => task.machineOperator === machineOperatorURL)
      .filter((task) => {
        const {workFromTimestamp} = task;
        return (
          workFromTimestamp &&
          workFromTimestamp < periodEndTimestamp &&
          workFromTimestamp >= periodStartTimestamp
        );
      });
    const dateTaskSeqMap = new Map<string, Task[]>();
    _.sortBy(taskSeq, (task) => task.workFromTimestamp).forEach((task) => {
      console.assert(task.workFromTimestamp);
      const key = dateToString(new Date(task.workFromTimestamp as string));
      const tasksForDate = dateTaskSeqMap.get(key);
      if (tasksForDate) {
        tasksForDate.push(task);
      } else {
        dateTaskSeqMap.set(key, [task]);
      }
    });

    const dayEntries: {
      breakMiscMinutes: number;
      date: any;
      deviceTimestamp: string;
      effectiveMinutes: number;
      efficiencyPercent: number;
      firstStart: any;
      incompleteTaskRows: any;
      internalMinutes: number;
      lastEnd: any;
      machineOperatorInitials: string;
      machineOperatorName: string;
      paidMinutes: number;
      someIncomplete: boolean;
      startEndMinutes: number;
      taskRows: any;
    }[] = [];

    const {periodSplitThresholdMinutes, unregisteredBreakAfterMinutes} = customerSettings;
    const now = new Date();
    now.setUTCMilliseconds(0);

    dateTaskSeqMap.forEach((dateTaskSeq, dateString) => {
      const computedTaskResults = dateTaskSeq.map(({url}) => url).map(computeTaskTime);
      const computedTaskResultsByTask = makeMapFromArray(
        computedTaskResults,
        ({task}) => task?.url,
      );
      const [completedTaskResults, incompleteTaskResults] = partition(
        computedTaskResults,
        ({task}) => task && task.completed,
      );

      const combinedIntervals = flatMap(completedTaskResults, ({intervals}) => intervals);

      const breakTimerUrl = defaultToNull(breakTimer?.url);

      const intervalsWithBreaks = injectUnregisteredBreaks(
        combinedIntervals,
        unregisteredBreakAfterMinutes,
        periodSplitThresholdMinutes,
        breakTimerUrl,
      );
      const groupedIntervals = groupIntervals(intervalsWithBreaks, periodSplitThresholdMinutes);
      const workPeriods = groupedIntervals.map((x) => computeWorkFromTo(x));
      const timerMinutes = computeIntervalSums(intervalsWithBreaks);

      const breakMinutes = (breakTimerUrl && timerMinutes.get(breakTimerUrl)) || 0;
      const totalWorkPeriodMinutes = sumBy(
        workPeriods.map(
          (period) =>
            ({
              fromTimestamp: defaultToEmptyString(period.workFromTimestamp),
              toTimestamp: defaultToEmptyString(period.workToTimestamp),
            }) as const,
        ),
        intervalMinutes,
      );

      const paidMinutes = totalWorkPeriodMinutes - breakMinutes;

      const effectiveMinutes = sum(
        completedTaskResults.map(({externalEfficientMinutes}) => externalEfficientMinutes),
      );

      const efficiencyPercent = Math.round((effectiveMinutes / paidMinutes) * 100) || 0;

      const makePdfRow = (task: Task): ReturnType<typeof getPDFRow> =>
        getPDFRow(
          task,
          now,
          defaultToEmpty(computedTaskResultsByTask.get(task.url)?.computedIntervals),
          dateString,
        );

      const {diff: startEndMinutes, firstStart, lastEnd} = getStartEndDiff(completedTaskResults);

      dayEntries.push({
        breakMiscMinutes: startEndMinutes - paidMinutes,
        date: dateString,
        deviceTimestamp: now.toISOString(),
        effectiveMinutes,
        efficiencyPercent,
        firstStart,
        incompleteTaskRows: incompleteTaskResults.map(({task}) => (task ? makePdfRow(task) : null)),
        internalMinutes: paidMinutes - effectiveMinutes,
        lastEnd,
        machineOperatorInitials,
        machineOperatorName,
        paidMinutes,
        someIncomplete: !!incompleteTaskResults.length,
        startEndMinutes,
        taskRows: completedTaskResults.map(({task}) => (task ? makePdfRow(task) : null)),
      });
    });
    return {
      dayEntries,
      deviceTimestamp: now.toISOString(),
      machineOperatorInitials,
      machineOperatorName,
      referenceNumberLabel:
        (customerSettings.enableTaskReferenceNumber && customerSettings.taskReferenceNumberLabel) ||
        (customerSettings.enableOrderReferenceNumber &&
          customerSettings.orderReferenceNumberLabel) ||
        "",
      withPriceGroup: customerSettings.bookkeepingWorkTypeAsWorkTypeAndVariant,
      withReferenceNumber:
        customerSettings.enableTaskReferenceNumber || customerSettings.enableOrderReferenceNumber,
    };
  }, [
    breakTimer,
    computeTaskTime,
    customerSettings,
    getPDFRow,
    pdfOptionsEmployee,
    pdfOptionsPeriodEnd,
    pdfOptionsPeriodStart,
    taskArray,
    userUserProfileLookup,
  ]);

  const buildReport = useCallback(() => {
    const machineOperatorURL = pdfOptionsEmployee;
    const machineOperatorProfile = machineOperatorURL
      ? userUserProfileLookup(machineOperatorURL)
      : undefined;
    const machineOperatorInitials = machineOperatorProfile ? machineOperatorProfile.alias : "";
    const data = getReportData();
    const pdfURL = `${globalConfig.baseURL}/download/bookkeeping/multipdf`;
    const pdfFilename = `dagsedler-${machineOperatorInitials}.pdf`;
    fetch(pdfURL, {
      body: JSON.stringify(data),
      headers: {
        authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      method: "post",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.blob();
      })
      .then((blob) => {
        dispatch(actions.temporaryQueriesDiscardedForPath(pathName, TEMPORARY_QUERIES_KEY));
        setPdfOptionsEmployee(null);
        setPdfOptionsPeriodEnd(null);
        setPdfOptionsPeriodStart(null);
        setPdfQuery(null);
        return FileSaver.saveAs(blob, pdfFilename);
      })
      .catch((error) => {
        dispatch(actions.temporaryQueriesDiscardedForPath(pathName, TEMPORARY_QUERIES_KEY));
        setPdfOptionsEmployee(null);
        setPdfOptionsPeriodEnd(null);
        setPdfOptionsPeriodStart(null);
        setPdfQuery(null);
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [dispatch, getReportData, pathName, pdfOptionsEmployee, token, userUserProfileLookup]);

  const buildingRef = useRef(false);

  useEffect(() => {
    if (isReady && !buildingRef.current) {
      buildingRef.current = true;
      buildReport();
    }
  }, [buildReport, isReady]);
  const activateEmployeeGroups = useSelector(isEmployeeGroupsActivated);

  let filterButton;
  if (
    (customerSettings.enableOrderTaskDepartmentFilter ||
      customerSettings.enableOrderTaskWorkTypeFilter ||
      activateEmployeeGroups) &&
    activeTab !== "archive"
  ) {
    filterButton = (
      <span style={{display: "inline-block", overflow: "hidden", verticalAlign: "top"}}>
        <FilterButton
          onSelectedDepartmentIdentifierSetChange={handleDepartmentFilterChange}
          onSelectedEmployeeGroupUrlSetChange={handleEmployeeGroupFilterChange}
          onSelectedWorkTypeURLSetChange={handleWorkTypeFilterChange}
          selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
          selectedEmployeeGroupUrlSet={selectedEmployeeGroupURLSet}
          selectedWorkTypeURLSet={selectedWorkTypeURLSet}
        />
      </span>
    );
  }

  const right =
    activeTab !== "archive" ? (
      <>
        {filterButton}
        <AppbarSearchField onChange={handleFilterStringChange} value={q} />
      </>
    ) : undefined;

  const filterString = `${q}`.trim();

  const floatingActionButtons = [];
  let dialogs;
  if (customerSettings.bookkeepingDayPDF) {
    const buildingReport =
      !!pdfOptionsEmployee && !!pdfOptionsPeriodStart && !!pdfOptionsPeriodEnd && !!pdfQuery;

    let icon;
    if (buildingReport) {
      icon = <SyncIcon className="rotate" />;
    } else {
      icon = <FilePdfIcon />;
    }
    floatingActionButtons.push(
      <VerticalStackingFloatingActionButton
        disabled={buildingReport}
        key="pdf-fab"
        onClick={handlePDFButton}
        stackIndex={0}
      >
        {icon}
      </VerticalStackingFloatingActionButton>,
    );
    const userDialog = (
      <ConnectedMachineOperatorDialog
        key="user-dialog"
        onCancel={handlePDFDialogCancel}
        onOk={setPdfOptionsEmployee}
        open={pdfOptionsDialogOpen && !pdfOptionsEmployee}
        title={
          customerSettings.employeeLabelVariant === "MACHINEOPERATOR"
            ? formatMessage(messages.pdfUserMachineOperator)
            : customerSettings.employeeLabelVariant === "EMPLOYEE"
              ? formatMessage(messages.pdfUserEmployee)
              : formatMessage(messages.pdfUserChauffeur)
        }
      />
    );
    const periodDialog = (
      <PeriodDialog
        key="period-dialog"
        onCancel={handlePDFDialogCancel}
        onOk={handlePDFPeriodDialogOk}
        open={pdfOptionsDialogOpen && !!pdfOptionsEmployee}
        title={formatMessage(messages.pdfPeriod)}
      />
    );

    dialogs = [userDialog, periodDialog];
  }
  let readyForBillingTab;
  if (!customerSettings.hideTasklistBillingTab && !customerSettings.useApproveReport) {
    readyForBillingTab = (
      <Tab
        label={
          MOBILE
            ? formatMessage(messages.readyForBillingHandheld)
            : formatMessage(messages.readyForBilling)
        }
        value="readyForBilling"
      />
    );
  }

  const [dataLoaded, setDataLoaded] = useState(activeTab !== "archive");
  const setDataLoadedTrue = useCallWithTrue(setDataLoaded);

  let filteringBlock: React.JSX.Element | null = null;
  if (
    selectedWorkTypeURLSet.size ||
    selectedDepartmentIdentifierSet.size ||
    selectedEmployeeGroupURLSet.size
  ) {
    filteringBlock = (
      <FilterBar
        onRequestFilterClear={handleFilterClear}
        selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
        selectedEmployeeGroupURLSet={selectedEmployeeGroupURLSet}
        selectedWorkTypeURLSet={selectedWorkTypeURLSet}
      />
    );
  }

  return (
    <PageLayout
      dialogs={dialogs}
      floatingActionButton={floatingActionButtons}
      performScrolling={dataLoaded}
      tabs={
        <Tabs
          onChange={handleTabChange}
          value={activeTab}
          variant={bowser.mobile ? "fullWidth" : "standard"}
        >
          <Tab
            label={
              MOBILE
                ? formatMessage(messages.readyForValidationHandheld)
                : formatMessage(messages.readyForValidation)
            }
            value="readyForValidation"
          />
          {readyForBillingTab}
          <Tab label={formatMessage(messages.archive)} value="archive" />
        </Tabs>
      }
      toolbar={
        <MenuToolbar
          onMenuButton={onMenuButton}
          rightElement={right}
          title={formatMessage(messages.title)}
        />
      }
      withBottomScrollPadding
    >
      {activeTab === "readyForBilling" || activeTab === "readyForValidation" ? (
        <>
          {filteringBlock}
          <BookkeepingNormalTabs
            filterString={filterString}
            selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
            selectedEmployeeGroupUrlSet={selectedEmployeeGroupURLSet}
            selectedWorkTypeURLSet={selectedWorkTypeURLSet}
            tab={activeTab}
          />
        </>
      ) : (
        <>
          {filteringBlock}
          <BookkeepingArchive onDataLoaded={setDataLoadedTrue} />
        </>
      )}
    </PageLayout>
  );
});
