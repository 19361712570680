import {dateToString} from "@co-common-libs/utils";
import {
  AppbarDateSelection,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getUserLookup,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {IconButton, Tab, Tabs} from "@material-ui/core";
import {BackToolbar, PageLayout} from "app-components";
import {useQueryParameterState} from "app-utils";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {AddPunchInOutDialog} from "./add-punch-in-out-dialog";
import {FilesTab} from "./files-tab";
import NewPasswordDialog from "./new-password-dialog";
import {ProfileTab} from "./profile-tab";
import {PunchInOutList} from "./punch-in-out-list";
import {SalaryTab} from "./salary-tab";
import {SetupTab} from "./setup-tab";

const messages = defineMessages({
  profile: {
    defaultMessage: "Profil",
  },
  punchInOut: {
    defaultMessage: "Ind/udstemplinger",
  },
  title: {
    defaultMessage: "Brugerprofil",
  },
});

export const UserProfile = React.memo(function UserProfile(): React.JSX.Element {
  const currentRole = useSelector(getCurrentRole);
  const currentUserURL = useSelector(getCurrentUserURL);
  const customerSettings = useSelector(getCustomerSettings);
  const [date, setDate] = useQueryParameterState<string>("date", dateToString(new Date()));
  const idParameterGetter = useMemo(() => makePathParameterGetter("id"), []);
  const id = useSelector(idParameterGetter);
  const [tab, setTab] = useQueryParameterState<string>("tab", "profile");
  const intl = useIntl();
  const userLookup = useSelector(getUserLookup);
  const dispatch = useDispatch();

  const [addPunchInOutDialogOpen, setAddPunchInOutDialogOpen] = useState(false);
  const setAddPunchInOutDialogOpenTrue = useCallWithTrue(setAddPunchInOutDialogOpen);
  const setAddPunchInOutDialogOpenFalse = useCallWithFalse(setAddPunchInOutDialogOpen);

  const [newPasswordDialogOpen, setNewPasswordDialogOpen] = useState(false);
  const setNewPasswordDialogOpenTrue = useCallWithTrue(setNewPasswordDialogOpen);
  const setNewPasswordDialogOpenFalse = useCallWithFalse(setNewPasswordDialogOpen);

  const handleNewPasswordDialogOk = useCallback(
    (newPassword: string) => {
      const url = instanceURL("user", id);
      const userIsOther = currentUserURL !== url;
      const currentUserIsManager = !!(currentRole && currentRole.manager);
      if ((userIsOther && !currentUserIsManager) || !newPassword) {
        return;
      }
      setNewPasswordDialogOpen(false);
      dispatch(actions.update(url, [{member: "password", value: newPassword}]));
    },
    [currentRole, currentUserURL, dispatch, id],
  );

  const handleAddPunchInOutDialogOk = useCallback(
    (punchTimestamp: string, action: "PUNCH_IN" | "PUNCH_OUT") => {
      if (!addPunchInOutDialogOpen) {
        return;
      }
      setAddPunchInOutDialogOpen(false);
      const newId = uuid();
      const url = instanceURL("punchInOut", newId);
      const userURL = instanceURL("user", id);
      dispatch(
        actions.create({
          action,
          employee: userURL,
          id: newId,
          punchTimestamp,
          url,
        }),
      );
    },
    [addPunchInOutDialogOpen, dispatch, id],
  );

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string) => {
      setTab(value);
    },
    [setTab],
  );

  const handleDateChange = useCallback(
    (value: string | null) => {
      setDate(value || "");
    },
    [setDate],
  );

  const userIsManager = !!(currentRole && currentRole.manager && !currentRole.projectManager);

  const userURL = instanceURL("user", id);
  const user = userLookup(userURL);
  const tabs: React.JSX.Element[] = [];
  if (customerSettings.usePunchInOut && userIsManager) {
    tabs.push(
      <Tab
        key="punch-in-out"
        label={intl.formatMessage(messages.punchInOut)}
        value="punch-in-out"
      />,
    );
  }

  if (userIsManager || currentUserURL === userURL) {
    tabs.push(
      <Tab
        key="documents"
        label={
          bowser.mobile
            ? intl.formatMessage({defaultMessage: "Dok."})
            : intl.formatMessage({defaultMessage: "Dokumenter"})
        }
        value="documents"
      />,
    );
  }

  if (userIsManager) {
    tabs.push(
      <Tab
        key="setup"
        label={
          bowser.mobile
            ? intl.formatMessage({defaultMessage: "Opsæt."})
            : intl.formatMessage({defaultMessage: "Opsætning"})
        }
        value="setup"
      />,
    );
    tabs.push(
      <Tab key="salary" label={intl.formatMessage({defaultMessage: "Løn"})} value="salary" />,
    );
  }

  let tabsBlock: React.JSX.Element | undefined;
  if (tabs.length) {
    tabsBlock = (
      <Tabs
        onChange={handleTabChange}
        value={tab}
        variant={bowser.mobile ? "fullWidth" : "standard"}
      >
        <Tab label={intl.formatMessage(messages.profile)} value="profile" />
        {tabs}
      </Tabs>
    );
  }
  let appBarRightIcon;
  let floatingActionButton;
  let content;

  if (tab === "profile") {
    if (userIsManager || currentUserURL === userURL) {
      const newPasswordButton = (
        <span style={{display: "inline-block", verticalAlign: "top"}}>
          <IconButton disabled={!!user && !user.active} onClick={setNewPasswordDialogOpenTrue}>
            <KeyVariantIcon color="#fff" />
          </IconButton>
        </span>
      );
      appBarRightIcon = (
        <div style={{display: "inline-block", height: 48}}>{newPasswordButton}</div>
      );
    }

    content = <ProfileTab userUrl={userURL} />;
  } else if (tab === "setup") {
    if (userIsManager || currentUserURL === userURL) {
      const newPasswordButton = (
        <span style={{display: "inline-block", verticalAlign: "top"}}>
          <IconButton disabled={!!user && !user.active} onClick={setNewPasswordDialogOpenTrue}>
            <KeyVariantIcon color="#fff" />
          </IconButton>
        </span>
      );
      appBarRightIcon = (
        <div style={{display: "inline-block", height: 48}}>{newPasswordButton}</div>
      );
    }

    content = <SetupTab userUrl={userURL} />;
  } else if (tab === "salary") {
    if (userIsManager || currentUserURL === userURL) {
      const newPasswordButton = (
        <span style={{display: "inline-block", verticalAlign: "top"}}>
          <IconButton disabled={!!user && !user.active} onClick={setNewPasswordDialogOpenTrue}>
            <KeyVariantIcon color="#fff" />
          </IconButton>
        </span>
      );
      appBarRightIcon = (
        <div style={{display: "inline-block", height: 48}}>{newPasswordButton}</div>
      );
    }

    content = <SalaryTab userUrl={userURL} />;
  } else if (tab === "punch-in-out" && userIsManager) {
    appBarRightIcon = <AppbarDateSelection onChange={handleDateChange} value={date} />;
    content = <PunchInOutList currentUserIsManager={userIsManager} date={date} userURL={userURL} />;
    if (userIsManager) {
      floatingActionButton = (
        <VerticalStackingFloatingActionButton
          onClick={setAddPunchInOutDialogOpenTrue}
          stackIndex={0}
        >
          <PlusIcon />
        </VerticalStackingFloatingActionButton>
      );
    }
  } else if (tab === "documents" && (userIsManager || currentUserURL === userURL)) {
    content = <FilesTab userURL={userURL} />;
  }

  const dialogs = [
    <NewPasswordDialog
      key="new-password-dialog"
      onCancel={setNewPasswordDialogOpenFalse}
      onOk={handleNewPasswordDialogOk}
      open={newPasswordDialogOpen}
    />,
    <AddPunchInOutDialog
      date={date}
      key="punch-in-out-dialog"
      onCancel={setAddPunchInOutDialogOpenFalse}
      onOk={handleAddPunchInOutDialogOk}
      open={addPunchInOutDialogOpen}
    />,
  ];

  return (
    <PageLayout
      dialogs={dialogs}
      floatingActionButton={floatingActionButton}
      tabs={tabsBlock}
      toolbar={
        <BackToolbar rightElement={appBarRightIcon} title={intl.formatMessage(messages.title)} />
      }
    >
      {content}
    </PageLayout>
  );
});
