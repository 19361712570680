import {Config} from "@co-common-libs/config";
import {Customer, Location, LocationUrl, PickupLocation, Unit} from "@co-common-libs/resources";
import {
  dateAndTimeToTimestamp,
  dateToString,
  formatAddress,
  formatTime,
} from "@co-common-libs/utils";
import {
  DecimalField,
  ResponsiveDialog,
  TimeField,
  TrimTextField,
} from "@co-frontend-libs/components";
import {AppState, getCustomerSettings, getLocationLookup} from "@co-frontend-libs/redux";
import {Button, DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {Linkify} from "./linkify";

const messages = defineMessages({
  amount: {
    defaultMessage: "Mængde ({unit})",
    id: "pickup-dialog.label.amount",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  editPickup: {
    defaultMessage: "Ret afhentning",
    id: "pickup-dialog.title.edit-pickup",
  },
  makeWorkplaceCorrection: {
    defaultMessage: "Ret i arbejdssted",
    id: "pickup-dialog.label.make-workplace-correction",
  },
  note: {
    defaultMessage: "Note",
    id: "pickup-dialog.label.note",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  pickup: {
    defaultMessage: "Afhentning",
    id: "pickup-dialog.title.pickup",
  },
  pickupTime: {
    defaultMessage: "Tidspunkt",
    id: "pickup-dialog.label.time",
  },
});

interface PickupDialogStateProps {
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
}

interface PickupDialogOwnProps {
  amount?: number | undefined;
  customer?: Customer | undefined;
  isNew: boolean;
  note?: string | undefined;
  onCancel: () => void;
  onOk: (data: {amount: number | null; note: string; timestamp: string | null}) => void;
  onRequestPickupLocationDialog: (pickupLocation?: PickupLocation) => void;
  open: boolean;
  pickupLocation?: PickupLocation | undefined;
  relatedUnit?: Unit | undefined;
  timestamp?: string | undefined;
  unit?: string | undefined;
}

type PickupDialogProps = PickupDialogOwnProps & PickupDialogStateProps;

interface PickupDialogState {
  amount: number | null;
  disabled: boolean;
  note: string;
  time: string | null;
}

class PickupDialogImpl extends PureComponent<PickupDialogProps, PickupDialogState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: PickupDialogState = {
    amount: this.props.amount != null ? this.props.amount : null,
    disabled: false,
    note: this.props.note || "",
    time: null,
  };
  componentDidUpdate(prevProps: PickupDialogProps): void {
    if (this.props.open && !prevProps.open) {
      window.setTimeout(() => {
        this.setState({disabled: false});
      }, 350);
    }
  }
  @bind
  handleAmountChange(newValue: number | null): void {
    this.setState({amount: newValue});
  }
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleEditLocation(): void {
    this.props.onRequestPickupLocationDialog(this.props.pickupLocation);
  }
  @bind
  handleNoteChange(value: string): void {
    this.setState({note: value});
  }
  @bind
  handleOk(): void {
    const {amount, note, time} = this.state;
    let timestamp: string | null = null;
    if (this.props.timestamp) {
      ({timestamp} = this.props);
      if (time) {
        const dateString = dateToString(new Date(this.props.timestamp));
        timestamp = dateAndTimeToTimestamp(dateString, time);
      }
    }

    this.props.onOk({
      amount,
      note,
      timestamp,
    });
  }
  @bind
  handleTimeChange(newValue: string | null): void {
    this.setState({time: newValue});
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customer, isNew, locationLookup, open, pickupLocation, relatedUnit, unit} = this.props;
    const customerName = customer ? customer.name : null;
    const location = pickupLocation?.relatedLocation
      ? locationLookup(pickupLocation.relatedLocation)
      : null;
    const addressString = location ? location.name || location.address : pickupLocation?.address;
    const pickupLocationNote = pickupLocation ? pickupLocation.note : null;

    const unitString = relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit;
    const dialogContent = (
      <div>
        <Grid>
          <Cell palm="12/12">
            <div>Kunde: {customerName}</div>
            <div>Kundes adresse: {customer ? formatAddress(customer) : null}</div>
            <div>Arbejdssted: {addressString}</div>
            <div>
              Note: <Linkify>{pickupLocationNote}</Linkify>
            </div>
            <Button color="secondary" onClick={this.handleEditLocation} variant="contained">
              {formatMessage(messages.makeWorkplaceCorrection)}
            </Button>
          </Cell>
          <Cell palm="12/12">
            {this.props.customerSettings.canChangeTransportLogTime ? (
              <TimeField
                fullWidth
                label={formatMessage(messages.pickupTime)}
                margin="dense"
                onChange={this.handleTimeChange}
                value={this.state.time || undefined}
              />
            ) : null}
            <DecimalField
              decimalPlaces={this.props.customerSettings.transportLogDecimals}
              disabled={this.state.disabled}
              fullWidth
              label={formatMessage(messages.amount, {
                unit: unitString,
              })}
              margin="dense"
              maxDigits={9}
              onChange={this.handleAmountChange}
              value={this.state.amount}
            />
            <TrimTextField
              disabled={this.state.disabled}
              fullWidth
              label={formatMessage(messages.note)}
              margin="dense"
              onChange={this.handleNoteChange}
              value={this.state.note}
              variant="outlined"
            />
          </Cell>
        </Grid>
      </div>
    );
    return (
      <ResponsiveDialog
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        open={open}
        title={isNew ? formatMessage(messages.pickup) : formatMessage(messages.editPickup)}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps: PickupDialogProps): void {
    if (nextProps.open && !this.props.open) {
      let time;
      if (nextProps.timestamp) {
        time = formatTime(nextProps.timestamp).replace(".", ":");
      }
      this.setState({
        amount: nextProps.amount != null ? nextProps.amount : null,
        disabled: true,
        note: nextProps.note || "",
        time: time != null ? time : null,
      });
    }
  }
}

export const PickupDialog: React.ComponentType<PickupDialogOwnProps> = connect<
  PickupDialogStateProps,
  object,
  PickupDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, PickupDialogStateProps>({
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
  }),
  {},
)(PickupDialogImpl);
