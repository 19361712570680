import {Config} from "@co-common-libs/config";
import {Information, PatchUnion, UserProfile, UserUrl} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {ErrorColorButton, ThrottledTextField} from "@co-frontend-libs/components";
import {ConnectedMultipleUsersDialog} from "@co-frontend-libs/connected-components";
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";

const messages = defineMessages({
  selectUsers: {
    defaultMessage: "Vælg medarbejdere",
    id: "information-form.label.select-visible-for",
  },
});

interface InformationFormProps {
  customerSettings: Config;
  information: Information;
  onDeleteClick: () => void;
  update: (url: string, patch: PatchUnion) => void;
  userUserprofileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface InformationFormState {
  selectUsersDialogOpen: boolean;
}

export class InformationForm extends PureComponent<InformationFormProps, InformationFormState> {
  static contextType = IntlContext;

  context!: React.ContextType<typeof IntlContext>;
  state: InformationFormState = {
    selectUsersDialogOpen: false,
  };

  @bind
  handlePinnedChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.props.update(this.props.information.url, [{member: "pinned", value: checked}]);
  }

  @bind
  handlePublishedChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.props.update(this.props.information.url, [{member: "published", value: checked}]);
  }

  @bind
  handleSelectUsersDialogCancel(): void {
    this.setState({selectUsersDialogOpen: false});
  }

  @bind
  handleSelectUsersDialogOk(users: ReadonlySet<UserUrl>): void {
    this.setState({selectUsersDialogOpen: false});
    this.props.update(this.props.information.url, [
      {
        member: "visibleTo",
        value: Array.from(users).sort(),
      },
    ]);
  }

  @bind
  handleTextFieldChange(value: string): void {
    this.props.update(this.props.information.url, [{member: "text", value}]);
  }

  @bind
  handleTitleFieldChange(value: string): void {
    this.props.update(this.props.information.url, [{member: "title", value}]);
  }

  @bind
  handleUsersSelectButton(): void {
    this.setState({selectUsersDialogOpen: true});
  }

  render(): React.JSX.Element | null {
    const {formatMessage} = this.context;
    const {information, onDeleteClick, userUserprofileLookup} = this.props;

    let visibleToBlock;
    const {visibleTo} = information;
    if (this.props.customerSettings.selectInformationvisibleTo) {
      visibleToBlock = (
        <div>
          <Button color="secondary" onClick={this.handleUsersSelectButton} variant="contained">
            {formatMessage(messages.selectUsers)}
          </Button>
          <div>
            <FormattedMessage
              defaultMessage="Valgte"
              id="information-entry.chosen"
              tagName="strong"
            />
            :{" "}
            {visibleTo && visibleTo.length ? (
              visibleTo
                .map(userUserprofileLookup)
                .filter(notUndefined)
                .map((userProfile) => userProfile.alias)
                .sort()
                .join(", ")
            ) : (
              <FormattedMessage defaultMessage="Alle" id="information-entry.all" />
            )}
          </div>
          <FormattedMessage
            defaultMessage="Informationen vil altid være synlig for administratorer. Medarbejdere der oprettes efter opslagets synliggørelse vil ikke modtage notifikation"
            id="information-entry.visibleTo-helptext"
            tagName="i"
          />
        </div>
      );
    }

    return (
      <div>
        <ThrottledTextField
          fullWidth
          id="title"
          label={<FormattedMessage defaultMessage="Titel" id="information-entry.titleFieldName" />}
          margin="dense"
          onChange={this.handleTitleFieldChange}
          value={information.title}
          variant="outlined"
        />
        <ThrottledTextField
          fullWidth
          id="text"
          label={<FormattedMessage defaultMessage="Tekst" id="information-entry.textFieldName" />}
          margin="dense"
          multiline
          onChange={this.handleTextFieldChange}
          value={information.text}
          variant="outlined"
        />
        {visibleToBlock}
        <FormControlLabel
          control={
            <Checkbox
              checked={information.published}
              id="published"
              onChange={this.handlePublishedChange}
            />
          }
          label={
            <FormattedMessage
              defaultMessage="Synlig for medarbejdere"
              id="information-entry.publishedFieldName"
            />
          }
          style={{marginTop: 10}}
        />
        <FormControlLabel
          control={
            <Checkbox checked={information.pinned} id="pinned" onChange={this.handlePinnedChange} />
          }
          label={
            <FormattedMessage
              defaultMessage="Fastgjort til toppen"
              id="information-entry.pinnedFieldName"
            />
          }
          style={{marginTop: 10}}
        />
        <div style={{marginTop: 10}}>
          <ErrorColorButton onClick={onDeleteClick} variant="contained">
            <FormattedMessage defaultMessage="Slet" id="information-entry.deleteButtonLabel" />
          </ErrorColorButton>
        </div>
        {this.props.customerSettings.selectInformationvisibleTo ? (
          <ConnectedMultipleUsersDialog
            onCancel={this.handleSelectUsersDialogCancel}
            onOk={this.handleSelectUsersDialogOk}
            open={this.state.selectUsersDialogOpen}
            selected={visibleTo ? new Set(visibleTo) : undefined}
          />
        ) : null}
      </div>
    );
  }
}
