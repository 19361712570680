export const ADD_MACHINE_ACTION = "addMachine";
export const ADD_PHOTO_ACTION = "addPhoto";
export const ADD_PROJECT_ACTION = "addProject";
export const ADD_TIME_ACTION = "addTime";
export const ADD_FIELD_ACTION = "addField";
export const ADD_WORKPLACE_ACTION = "addWorkplace";
export const CHANGE_CUSTOMER_ACTION = "changeCustomer";
export const CHANGE_DEPARTMENT_ACTION = "changeDepartment";
export const CHANGE_EMPLOYEE_ACTION = "changeEmployee";
export const CHANGE_MATERIAL_ACTION = "changeMaterial";
export const CHANGE_SMALL_MACHINE_ACTION = "smallMachine";
export const COMPLETE_ROUTE_ACTION = "routeCompletion";
export const SELECT_WORK_TYPE_ACTION = "selectWorkType";
export const APPROVE_CUSTOMER_ACTION = "approveCustomer";
export const EDIT_LOG_ACTION = "editLog";
export const EDIT_NOTE_ACTION = "editNote";
export const EDIT_MACHINE_ACTION = "editMachines";
export const EDIT_PROJECT_ACTION = "editProject";

export type ErrorAction =
  | typeof ADD_FIELD_ACTION
  | typeof ADD_MACHINE_ACTION
  | typeof ADD_PHOTO_ACTION
  | typeof ADD_PROJECT_ACTION
  | typeof ADD_TIME_ACTION
  | typeof ADD_WORKPLACE_ACTION
  | typeof APPROVE_CUSTOMER_ACTION
  | typeof CHANGE_CUSTOMER_ACTION
  | typeof CHANGE_DEPARTMENT_ACTION
  | typeof CHANGE_EMPLOYEE_ACTION
  | typeof CHANGE_MATERIAL_ACTION
  | typeof CHANGE_SMALL_MACHINE_ACTION
  | typeof COMPLETE_ROUTE_ACTION
  | typeof EDIT_LOG_ACTION
  | typeof EDIT_MACHINE_ACTION
  | typeof EDIT_NOTE_ACTION
  | typeof EDIT_PROJECT_ACTION
  | typeof SELECT_WORK_TYPE_ACTION;

export type ErrorEntryObject = {
  readonly action?: ErrorAction;
  readonly children?: string[];
  readonly text: string;
};

export type ErrorEntry = string | ErrorEntryObject;
