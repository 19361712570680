import type {Writable} from "ts-essentials";
import {
  Machine,
  PriceGroup,
  Product,
  ProductUrl,
  Unit,
  UnitUrl,
  WorkType,
} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {BarcodeFormat, memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {
  EntryCategoryCode,
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

const messages = defineMessages({
  addProduct: {
    defaultMessage: "Tilføj materiel",
    id: "product-dialog.dialog-title.add-product",
  },
  alternativeAddProduct: {
    defaultMessage: "Tilføj materialer",
    id: "product-dialog.alternative-dialog-title.add-product",
  },
  searchAlternativeAddProduct: {
    defaultMessage: "Søg materialer",
    id: "product-dialog.alternative-dialog-title.search-product",
  },
  searchProduct: {
    defaultMessage: "Søg materiel",
    id: "product-dialog.dialog-title.search-product",
  },
});

function computeBaseChoices(
  productArray: readonly Product[],
  machines: readonly Machine[] | undefined,
  workType: WorkType | undefined,
  priceGroups: readonly PriceGroup[],
  unitLookup: (url: UnitUrl) => Unit | undefined,
  preferredProductURLs?: readonly ProductUrl[],
  preferredCategory?: EntryCategoryCode,
): readonly EntryData<ProductUrl>[] {
  let preferredProductSet: Set<ProductUrl> | undefined;
  if (workType) {
    const workTypeProducts = workType.products;
    if (workTypeProducts) {
      preferredProductSet = new Set(workTypeProducts);
    }
  } else if (machines) {
    const machinesPreferredProductSet = new Set<ProductUrl>();
    machines.forEach((machine) => {
      const machineProducts = machine.products;
      if (machineProducts.length) {
        machineProducts.forEach((productURL) => {
          machinesPreferredProductSet.add(productURL);
        });
      }
    });
    preferredProductSet = machinesPreferredProductSet;
  }

  if (priceGroups.length) {
    if (!preferredProductSet) {
      preferredProductSet = new Set<ProductUrl>();
    }
    for (const priceGroup of priceGroups) {
      for (const product of priceGroup.products) {
        preferredProductSet.add(product);
      }
    }
  }

  if (preferredProductSet) {
    if (!preferredProductSet.size) {
      preferredProductSet = undefined;
    }
  }
  const data: EntryData<ProductUrl>[] = productArray.map((instance) => {
    const {url} = instance;
    const name = instance.name || "";
    const catalogNumber = instance.catalogNumber || "";
    const unit = getUnitString(instance, unitLookup);

    const recentlyUsedIndex =
      preferredCategory === "recentlyUsed" && preferredProductURLs
        ? [...preferredProductURLs].indexOf(url)
        : -1;
    const recentlyUsedSortKey = recentlyUsedIndex > -1 ? -recentlyUsedIndex : undefined;

    const entry: Writable<EntryData<ProductUrl>> = {
      category:
        preferredCategory && preferredProductURLs && preferredProductURLs.includes(url)
          ? preferredCategory
          : preferredProductSet && preferredProductSet.has(url)
            ? "favorite"
            : "standard",
      exactMatchValue: catalogNumber,
      identifier: url,
      primaryText: unit ? `${name}, ${unit}` : name,
      searchFields: [
        {label: "Varenr.", priority: 10, text: catalogNumber},
        {label: "Navn", priority: 5, text: name},
        {label: "Enhed", priority: 1, text: unit},
      ],
      secondaryText: catalogNumber,
    };
    if (recentlyUsedSortKey) {
      entry.recentlyUsedSortKey = recentlyUsedSortKey;
    }
    return entry;
  });
  return data;
}

interface SingleProductDialogProps {
  barcodeScannerFormats?: readonly BarcodeFormat[] | null | undefined;
  machines?: readonly Machine[] | undefined;
  materialUseAlternativeText: boolean;
  onCancel(): void;
  onOk(url: ProductUrl): void;
  open: boolean;
  preferredCategory?: EntryCategoryCode | undefined;
  preferredProductURLs?: readonly ProductUrl[] | undefined;
  priceGroups?: readonly PriceGroup[] | undefined;
  productArray: readonly Product[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workType?: WorkType | undefined;
}

export const SingleProductDialog = React.memo(function SingleProductDialog(
  props: SingleProductDialogProps,
) {
  const {
    barcodeScannerFormats,
    machines,
    materialUseAlternativeText,
    onCancel,
    onOk,
    open,
    preferredCategory,
    preferredProductURLs,
    priceGroups,
    productArray,
    unitLookup,
    workType,
  } = props;
  const intl = useIntl();
  const title = materialUseAlternativeText
    ? intl.formatMessage(messages.alternativeAddProduct)
    : intl.formatMessage(messages.addProduct);
  const searchTitle = materialUseAlternativeText
    ? intl.formatMessage(messages.searchAlternativeAddProduct)
    : intl.formatMessage(messages.searchProduct);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(
    productArray,
    machines,
    workType,
    priceGroups || [],
    unitLookup,
    preferredProductURLs,
    preferredCategory,
  );

  return (
    <GenericSingleSelectionSearchDialog<ProductUrl>
      barcodeScannerFormats={barcodeScannerFormats}
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
    />
  );
});

interface MultipleProductDialogProps {
  barcodeScannerFormats?: readonly BarcodeFormat[] | null | undefined;
  machines?: readonly Machine[] | undefined;
  materialUseAlternativeText: boolean;
  onCancel(): void;
  onOk(urls: ReadonlySet<ProductUrl>): void;
  open: boolean;
  preferredCategory?: EntryCategoryCode | undefined;
  preferredProductURLs?: readonly ProductUrl[] | undefined;
  priceGroups?: readonly PriceGroup[] | undefined;
  productArray: readonly Product[];
  searchTitle?: string;
  selected?: ReadonlySet<ProductUrl> | undefined;
  title?: string | React.JSX.Element;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workType?: WorkType | undefined;
}

export const MultipleProductDialog = React.memo(function MultipleProductDialog(
  props: MultipleProductDialogProps,
) {
  const {
    barcodeScannerFormats,
    machines,
    materialUseAlternativeText,
    onCancel,
    onOk,
    open,
    preferredCategory,
    preferredProductURLs,
    priceGroups,
    productArray,
    selected,
    unitLookup,
    workType,
  } = props;
  const intl = useIntl();
  const title =
    props.title ||
    (materialUseAlternativeText
      ? intl.formatMessage(messages.alternativeAddProduct)
      : intl.formatMessage(messages.addProduct));
  const searchTitle =
    props.searchTitle ||
    (materialUseAlternativeText
      ? intl.formatMessage(messages.searchAlternativeAddProduct)
      : intl.formatMessage(messages.searchProduct));

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(
    productArray,
    machines,
    workType,
    priceGroups || [],
    unitLookup,
    preferredProductURLs,
    preferredCategory,
  );

  return (
    <GenericMultiSelectionSearchDialog<ProductUrl>
      barcodeScannerFormats={barcodeScannerFormats}
      data={data}
      includeSelectAll={false}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
    />
  );
});

interface ProductDialogProps {
  autoSupplementingProducts: {
    readonly [productGroup: string]: readonly string[] | undefined;
  } | null;
  barcodeScannerFormats?: readonly BarcodeFormat[] | null | undefined;
  machines?: readonly Machine[] | undefined;
  materialUseAlternativeText: boolean;
  onCancel(): void;
  onOk(urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>): void;
  open: boolean;
  preferredCategory?: EntryCategoryCode;
  preferredProductURLs?: readonly ProductUrl[] | undefined;
  priceGroups?: readonly PriceGroup[];
  productArray: readonly Product[];
  productMultiSelect: boolean;
  selected?: ReadonlySet<ProductUrl>;
  showMaterialAutoLinesToEmployees: boolean;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workType?: WorkType | undefined;
}

export class ProductDialog extends React.PureComponent<ProductDialogProps, object> {
  render(): React.JSX.Element {
    const {
      autoSupplementingProducts,
      barcodeScannerFormats,
      machines,
      materialUseAlternativeText,
      onCancel,
      onOk,
      open,
      preferredCategory,
      preferredProductURLs,
      priceGroups,
      productMultiSelect,
      selected,
      showMaterialAutoLinesToEmployees,
      unitLookup,
      workType,
    } = this.props;
    let productArray: readonly Product[];
    if (autoSupplementingProducts == null || showMaterialAutoLinesToEmployees) {
      ({productArray} = this.props);
    } else {
      const hiddenProducts: Set<string> = new Set();
      Object.values(autoSupplementingProducts).forEach((identifiers) => {
        if (identifiers) {
          identifiers.forEach((identifier) => hiddenProducts.add(identifier));
        }
      });
      productArray = this.props.productArray.filter(
        (product) => !hiddenProducts.has(product.catalogNumber),
      );
    }

    if (productMultiSelect) {
      return (
        <MultipleProductDialog
          barcodeScannerFormats={barcodeScannerFormats}
          machines={machines}
          materialUseAlternativeText={materialUseAlternativeText}
          onCancel={onCancel}
          onOk={onOk}
          open={open}
          preferredCategory={preferredCategory}
          preferredProductURLs={preferredProductURLs}
          priceGroups={priceGroups}
          productArray={productArray}
          selected={selected}
          unitLookup={unitLookup}
          workType={workType}
        />
      );
    } else {
      return (
        <SingleProductDialog
          barcodeScannerFormats={barcodeScannerFormats}
          machines={machines}
          materialUseAlternativeText={materialUseAlternativeText}
          onCancel={onCancel}
          onOk={onOk}
          open={open}
          preferredCategory={preferredCategory}
          preferredProductURLs={preferredProductURLs}
          priceGroups={priceGroups}
          productArray={productArray}
          unitLookup={unitLookup}
          workType={workType}
        />
      );
    }
  }
}
