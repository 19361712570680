import {
  CustomerUrl,
  LocationUrl,
  MachineUrl,
  PriceGroupUrl,
  ProductUrl,
  urlToId,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {CircularProgress, Grid} from "@material-ui/core";
import {FetchAndDisplayStorageJournals, PeriodSelection} from "app-components";
import {useEventTargetCheckedCallback, useFetchGet} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useState} from "react";
import {FilteringSetup} from "../filtering-setup";
import {FilterOptions} from "../types";
import {LocationSelection} from "./location-selection";

export function StorageJournal(): React.JSX.Element {
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);

  const [selectedInvoiceCustomers, setSelectedInvoiceCustomers] = useState<
    ReadonlySet<CustomerUrl>
  >(new Set());
  const [selectedProducts, setSelectedProducts] = useState<ReadonlySet<ProductUrl>>(new Set());
  const [selectedLocationCustomers, setSelectedLocationCustomers] = useState<
    ReadonlySet<CustomerUrl>
  >(new Set());
  const [selectedWorkTypesPriceGroups, setSelectedWorkTypesPriceGroups] = useState<
    ReadonlyMap<WorkTypeUrl, ReadonlySet<PriceGroupUrl>>
  >(new Map());
  const [selectedMachinesPriceGroups, setSelectedMachinesPriceGroups] = useState<
    ReadonlyMap<MachineUrl, ReadonlySet<PriceGroupUrl>>
  >(new Map());
  const [selectedLocations, setSelectedLocations] = useState<ReadonlySet<LocationUrl>>(new Set());
  const [restrictToAndFromToLocationCustomers, setRestrictToAndFromToLocationCustomers] =
    useState(false);
  const handleRestrictToAndFromToLocationCustomersCheck = useEventTargetCheckedCallback(
    setRestrictToAndFromToLocationCustomers,
    [setRestrictToAndFromToLocationCustomers],
  );

  const [
    fetchFilterOptions,
    fetchFilterOptionsCancel,
    filterOptions,
    fetchingFilterOptions,
    // filterOptionsError,
  ] = useFetchGet<FilterOptions>();

  const [
    fetchLocations,
    fetchLocationsCancel,
    locations,
    fetchingLocations,
    // locationsError
  ] = useFetchGet<LocationUrl[]>();

  const clearFilteringOptions = useCallback(() => {
    setSelectedInvoiceCustomers(new Set());
    setSelectedLocationCustomers(new Set());
    setSelectedProducts(new Set());
    setSelectedWorkTypesPriceGroups(new Map());
    setSelectedMachinesPriceGroups(new Map());
    setRestrictToAndFromToLocationCustomers(false);
  }, []);

  useEffect(() => {
    // cancel/clear filtering data, locations and results on period change
    clearFilteringOptions();
    fetchFilterOptionsCancel();
    fetchLocationsCancel();
    setSelectedLocations(new Set());
  }, [clearFilteringOptions, fetchFilterOptionsCancel, fetchLocationsCancel, fromDate, toDate]);

  useEffect(() => {
    setSelectedLocations(new Set());
    if (!selectedLocationCustomers.size) {
      setRestrictToAndFromToLocationCustomers(false);
    }
  }, [
    fromDate,
    toDate,
    restrictToAndFromToLocationCustomers,
    selectedInvoiceCustomers,
    selectedLocationCustomers,
    selectedMachinesPriceGroups,
    selectedWorkTypesPriceGroups,
    selectedProducts,
  ]);

  useEffect(() => {
    // cancel/clear result on filtering options change
    fetchLocationsCancel();
  }, [
    fetchLocationsCancel,
    restrictToAndFromToLocationCustomers,
    selectedInvoiceCustomers,
    selectedLocationCustomers,
    selectedMachinesPriceGroups,
    selectedWorkTypesPriceGroups,
    selectedProducts,
  ]);

  const handlePeriodOk = useCallback(() => {
    const filterOptionsBaseURL = `${globalConfig.baseURL}/api/report/storage_journal/filter_options/`;
    const filterOptionsURL = `${filterOptionsBaseURL}?fromDate=${fromDate}&toDate=${toDate}`;

    fetchLocationsCancel();
    clearFilteringOptions();
    fetchFilterOptions(filterOptionsURL);
  }, [clearFilteringOptions, fetchFilterOptions, fetchLocationsCancel, fromDate, toDate]);

  const handleFilteringOk = useCallback(() => {
    const locationsBaseURL = `${globalConfig.baseURL}/api/report/storage_journal/locations/`;

    const invoiceCustomerFilterString = Array.from(selectedInvoiceCustomers)
      .map((customerURL) => `&invoiceCustomer=${urlToId(customerURL)}`)
      .join("");

    const productFilterString = Array.from(selectedProducts)
      .map((productUrl) => `&product=${urlToId(productUrl)}`)
      .join("");

    const locationCustomerFilterString = Array.from(selectedLocationCustomers)
      .map((customerURL) => `&locationCustomer=${urlToId(customerURL)}`)
      .join("");

    const workTypeFilterString = Array.from(selectedWorkTypesPriceGroups)
      .map(([workTypeURL, priceGroupURLs]) => {
        const workTypeId = urlToId(workTypeURL);
        if (!priceGroupURLs.size) {
          return `&workType=${workTypeId},`;
        } else {
          return Array.from(priceGroupURLs)
            .map((priceGroupURL) => {
              return `&workType=${workTypeId},${urlToId(priceGroupURL)}`;
            })
            .join("");
        }
      })
      .join("");

    const machineFilterString = Array.from(selectedMachinesPriceGroups)
      .map(([machineURL, priceGroupURLs]) => {
        const machineId = urlToId(machineURL);
        if (!priceGroupURLs.size) {
          return `&machine=${machineId},`;
        } else {
          return Array.from(priceGroupURLs)
            .map((priceGroupURL) => {
              return `&machine=${machineId},${urlToId(priceGroupURL)}`;
            })
            .join("");
        }
      })
      .join("");

    const restrictToAndFromToLocationCustomersString = restrictToAndFromToLocationCustomers
      ? "&restrictToAndFromToLocationCustomers"
      : "";

    const locationsURL = [
      `${locationsBaseURL}?fromDate=${fromDate}&toDate=${toDate}`,
      invoiceCustomerFilterString,
      locationCustomerFilterString,
      workTypeFilterString,
      machineFilterString,
      productFilterString,
      restrictToAndFromToLocationCustomersString,
    ].join("");
    fetchLocations(locationsURL);
  }, [
    fetchLocations,
    fromDate,
    restrictToAndFromToLocationCustomers,
    selectedInvoiceCustomers,
    selectedLocationCustomers,
    selectedMachinesPriceGroups,
    selectedProducts,
    selectedWorkTypesPriceGroups,
    toDate,
  ]);

  return (
    <div style={{padding: "1em"}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PeriodSelection
            fromDate={fromDate}
            onFromDateChange={setFromDate}
            onOk={handlePeriodOk}
            onToDateChange={setToDate}
            toDate={toDate}
          />
        </Grid>
        <Grid item xs={12}>
          {filterOptions ? (
            <FilteringSetup
              filteringOptions={filterOptions}
              onOk={handleFilteringOk}
              onRestrictToAndFromToLocationCustomersCheck={
                handleRestrictToAndFromToLocationCustomersCheck
              }
              onSelectedInvoiceCustomersChange={setSelectedInvoiceCustomers}
              onSelectedLocationCustomersChange={setSelectedLocationCustomers}
              onSelectedMachinesPriceGroupsChange={setSelectedMachinesPriceGroups}
              onSelectedProductsChange={setSelectedProducts}
              onSelectedWorkTypesPriceGroupsChange={setSelectedWorkTypesPriceGroups}
              restrictToAndFromToLocationCustomers={restrictToAndFromToLocationCustomers}
              selectedInvoiceCustomers={selectedInvoiceCustomers}
              selectedLocationCustomers={selectedLocationCustomers}
              selectedMachinesPriceGroups={selectedMachinesPriceGroups}
              selectedProducts={selectedProducts}
              selectedWorkTypesPriceGroups={selectedWorkTypesPriceGroups}
            />
          ) : fetchingFilterOptions ? (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          {locations && locations.length ? (
            <LocationSelection
              locations={locations}
              onSelectedLocationsChange={setSelectedLocations}
              selectedLocations={selectedLocations}
            />
          ) : fetchingLocations ? (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : null}
        </Grid>
        {selectedLocations.size && fromDate && toDate ? (
          <FetchAndDisplayStorageJournals
            fromDate={fromDate}
            invoiceCustomerSet={selectedInvoiceCustomers}
            locationCustomerSet={selectedLocationCustomers}
            locationUrls={Array.from(selectedLocations)}
            machinePriceGroupsMap={selectedMachinesPriceGroups}
            products={selectedProducts}
            restrictToAndFromToLocationCustomers={restrictToAndFromToLocationCustomers}
            toDate={toDate}
            workTypePriceGroupsMap={selectedWorkTypesPriceGroups}
          />
        ) : null}
      </Grid>
    </div>
  );
}
