import {OrderUrl, TaskUrl} from "@co-common-libs/resources";
import {green, grey} from "@material-ui/core/colors";
import {PureComponent} from "app-utils";
import _ from "lodash";
import React, {ReactNode} from "react";
import {ConnectDropTarget, DropTarget, DropTargetMonitor} from "react-dnd";
import {ORDER_CALENDAR_COLUMN_WIDTH} from "./constants";

interface Item {
  fromDate: string;
  orderUrl: OrderUrl;
  taskUrl?: TaskUrl;
}

interface DayColumnOwnProps {
  date: string;
  onDrop: (item: Item, date: string) => void;
}

interface DayColumnDropTargetProps {
  connectDropTarget: ConnectDropTarget;
  isOver: boolean;
}

type DayColumnProps = DayColumnDropTargetProps & DayColumnOwnProps & {children: ReactNode[]};

class DayColumn extends PureComponent<DayColumnProps> {
  render(): React.JSX.Element {
    const {children, connectDropTarget, date, isOver} = this.props;
    const backgroundColor = new Date(date).getDate() % 2 ? grey[100] : grey[200];
    const style = {
      backgroundColor: isOver ? green[100] : backgroundColor,
      flexBasis: ORDER_CALENDAR_COLUMN_WIDTH,
      flexGrow: 0,
      flexShrink: 0,
      maxWidth: ORDER_CALENDAR_COLUMN_WIDTH,
      paddingBottom: 20,
    };
    return connectDropTarget(<div style={style}>{children}</div>) as React.JSX.Element;
  }
}

const dayTarget = {
  drop(props: DayColumnOwnProps, monitor: DropTargetMonitor<Item>) {
    const item = monitor.getItem();
    if (item) {
      props.onDrop(item, props.date);
    }
  },
};

const DropTargetDayColumn = DropTarget("order", dayTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(DayColumn);

export {DropTargetDayColumn as DayColumn};
