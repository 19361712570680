import {AutoFocusedIconButton} from "@co-frontend-libs/components";
import {IconButton} from "@material-ui/core";
import {ErrorAction, ErrorEntry, isErrorEntryObjectWithAction} from "app-utils";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";

const iconButtonStyle = {
  height: 24,
  marginLeft: 2,
  marginRight: 2,
  padding: 0,
  width: 24,
};

interface ActionErrorListEntryProps {
  autoFocus: boolean;
  entry: ErrorEntry;
  onAction: (action: ErrorAction) => void;
}

const ActionErrorListEntry = React.memo(function ActionErrorListEntry(
  props: ActionErrorListEntryProps,
): React.JSX.Element {
  const {autoFocus, entry, onAction} = props;

  const handleAction = useCallback((): void => {
    if (typeof entry === "object" && entry.action) {
      onAction(entry.action);
    }
  }, [entry, onAction]);

  if (typeof entry === "string") {
    return <li>{entry}</li>;
  } else {
    const {action, children, text} = entry;
    const header = action ? (
      <span>
        {text}
        {autoFocus ? (
          <AutoFocusedIconButton color="primary" onClick={handleAction} style={iconButtonStyle}>
            <PencilIcon />
          </AutoFocusedIconButton>
        ) : (
          <IconButton color="primary" onClick={handleAction} style={iconButtonStyle}>
            <PencilIcon />
          </IconButton>
        )}
      </span>
    ) : (
      text
    );
    const sublist = children ? <ErrorList errors={children} /> : null;
    return (
      <li>
        {header}
        {sublist}
      </li>
    );
  }
});

interface SimpleErrorListEntryProps {
  entry: ErrorEntry;
}

const SimpleErrorListEntry = React.memo(function SimpleErrorListEntry(
  props: SimpleErrorListEntryProps,
): React.JSX.Element {
  const {entry} = props;
  if (typeof entry === "string") {
    return <li>{entry}</li>;
  } else {
    const {children, text} = entry;
    const header = text;
    const sublist = children ? <ErrorList errors={children} /> : null;
    return (
      <li>
        {header}
        {sublist}
      </li>
    );
  }
});

interface ErrorListProps {
  errors: readonly ErrorEntry[];
  onAction?: ((action: ErrorAction) => void) | undefined;
  onActionWhitelist?: readonly ErrorAction[] | undefined;
}

export const ErrorList = React.memo(function ErrorList(props: ErrorListProps): React.JSX.Element {
  const {errors, onAction, onActionWhitelist} = props;
  const list: React.JSX.Element[] = [];
  if (onAction) {
    const autoFocusIndex = errors.findIndex(isErrorEntryObjectWithAction);
    errors.forEach((entry: ErrorEntry, index) => {
      if (
        isErrorEntryObjectWithAction(entry) &&
        (onActionWhitelist === undefined || onActionWhitelist.includes(entry.action))
      ) {
        list.push(
          <ActionErrorListEntry
            autoFocus={index === autoFocusIndex}
            entry={entry}
            key={index}
            onAction={onAction}
          />,
        );
      } else {
        list.push(<SimpleErrorListEntry entry={entry} key={index} />);
      }
    });
  } else {
    errors.forEach((entry, index) => {
      list.push(<SimpleErrorListEntry entry={entry} key={index} />);
    });
  }
  return <ul>{list}</ul>;
});
