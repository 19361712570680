import {SettingID} from "@co-common-libs/config";
import {WorkType, WorkTypeUrl} from "@co-common-libs/resources";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {ConnectedExternalWorkTypeDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {WorkTypeChip} from "app-components";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DeletableChip} from "../../reports/deletable-chip";

interface WorkTypePrimaryTimerLabelsDialogDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

const RowField = ({
  onChange,
  value,
  workTypeIdentifier,
}: {
  onChange: (workTypeIdentifier: string, value: string) => void;
  value: string;
  workTypeIdentifier: string;
}): React.JSX.Element => {
  const handleFieldChange = useCallback(
    (newValue: string) => {
      onChange(workTypeIdentifier, newValue);
    },
    [onChange, workTypeIdentifier],
  );

  return <TrimTextField fullWidth onChange={handleFieldChange} value={value} variant="outlined" />;
};

export function WorkTypePrimaryTimerLabelsDialog(
  props: WorkTypePrimaryTimerLabelsDialogDialogProps,
): React.JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const workTypeArray = useSelector(getWorkTypeArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const workTypeGenericPrimaryTimerLabel: {
    [worktypeIdentifier: string]: string | undefined;
  } = useMemo(() => settingEntry?.data || {}, [settingEntry?.data]);

  const [values, setValues] = useState(workTypeGenericPrimaryTimerLabel);

  useEffect(() => {
    if (open) {
      setValues(workTypeGenericPrimaryTimerLabel);
    }
  }, [open, workTypeGenericPrimaryTimerLabel]);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);

  const handleSave = useCallback(() => {
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: values},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, settingEntry, values]);

  const handleLabelChange = useCallback(
    (worktypeIdentifier: string, value: string) => {
      const newValues = {
        ...values,
        [worktypeIdentifier]: value,
      };
      setValues(newValues);
    },
    [values],
  );

  const [workTypeDialogOpen, setworkTypeDialogOpen] = useState(false);
  const setworkTypeDialogOpenTrue = useCallWithTrue(setworkTypeDialogOpen);
  const setworkTypeDialogOpenFalse = useCallWithFalse(setworkTypeDialogOpen);

  const handleWorkTypeDialogOk = useCallback(
    (url: WorkTypeUrl) => {
      setworkTypeDialogOpen(false);
      const workType = workTypeLookup(url);
      if (!workType) {
        return;
      }
      const newValues = {
        ...values,
        [workType.identifier]: "",
      };
      setValues(newValues);
    },
    [values, workTypeLookup],
  );

  const handleWorktypeDelete = useCallback(
    (workType: WorkType) => {
      setValues(_.omit(values, workType.identifier));
    },
    [values],
  );

  const handleNoneWorktypeIdDelete = useCallback(
    (identifier: string) => {
      setValues(_.omit(values, identifier));
    },
    [values],
  );

  const okDisabled = Object.values(values).some((label) => !label);

  const intl = useIntl();

  return (
    <>
      <ResponsiveDialog
        fullscreen
        okDisabled={okDisabled}
        okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
        onCancel={onClose}
        onOk={handleSave}
        open={open}
        title={
          <FormattedMessage
            defaultMessage="Opsæt timer labels"
            id="system-setup.dialog-title.timer-labels"
          />
        }
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage defaultMessage="Område" id="system-setup.work-type" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Label" id="system-setup.label" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(values).map(([worktypeIdentifier, label]) => {
              const workType = workTypeArray.find((w) => w.identifier === worktypeIdentifier);

              return (
                <TableRow key={worktypeIdentifier}>
                  <TableCell>
                    {workType ? (
                      <WorkTypeChip onDeleteClick={handleWorktypeDelete} workType={workType} />
                    ) : (
                      <DeletableChip
                        deletionId={worktypeIdentifier}
                        label={intl.formatMessage({
                          defaultMessage: "Ikke eksisterende område",
                        })}
                        onDelete={handleNoneWorktypeIdDelete}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <RowField
                      onChange={handleLabelChange}
                      value={label ?? ""}
                      workTypeIdentifier={worktypeIdentifier}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>
                <Button color="primary" onClick={setworkTypeDialogOpenTrue}>
                  <FormattedMessage defaultMessage="Tilføj" id="system-setup.add" />
                </Button>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </ResponsiveDialog>
      <ConnectedExternalWorkTypeDialog
        onCancel={setworkTypeDialogOpenFalse}
        onOk={handleWorkTypeDialogOk}
        open={workTypeDialogOpen}
      />
    </>
  );
}
