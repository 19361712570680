import {Button, CircularProgress} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import FileSaver from "file-saver";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";

interface AjaxDownloadButtonProps {
  data: (() => any[] | {[field: string]: any}) | any[] | {[field: string]: any};
  disabled?: boolean;
  downloadURL: string;
  filename: string;
  Icon?: React.ComponentType | React.ExoticComponent;
  label: string;
  token: string | null;
}

export const AjaxDownloadButton = React.memo(function AjaxDownloadButton(
  props: AjaxDownloadButtonProps,
): React.JSX.Element {
  const [error, setError] = useState("");
  const [fetching, setFetching] = useState(false);
  const intl = useIntl();
  const {data, disabled, downloadURL, filename, Icon, label, token} = props;

  const handleDownloadButtonClick = useCallback(() => {
    let requestData;
    if (typeof data === "function") {
      requestData = data();
    } else {
      requestData = data;
    }
    setFetching(true);
    return fetch(downloadURL, {
      body: JSON.stringify(requestData),
      headers: {
        authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      method: "post",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.blob();
      })
      .then((blob) => {
        setError("");
        setFetching(false);
        return FileSaver.saveAs(blob, filename);
      })
      .catch((_error) => {
        setError(
          intl.formatMessage({
            defaultMessage: "Der skete en fejl",
          }),
        );
        setFetching(false);
      });
  }, [downloadURL, filename, intl, data, token]);

  return (
    <div>
      <Button
        disabled={disabled || fetching}
        onClick={handleDownloadButtonClick}
        startIcon={fetching ? <CircularProgress size={24} /> : Icon ? <Icon /> : undefined}
        variant="text"
      >
        {label}
      </Button>
      {error && !fetching ? <div style={{color: red["A700"], marginLeft: 15}}>{error}</div> : null}
    </div>
  );
});
