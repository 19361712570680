import {ResourceName} from "@co-common-libs/resources";
import {EconomySystemIntegrationKindPart} from "./get-economy-system-integration-kind";

export function liveSyncResource(
  config: EconomySystemIntegrationKindPart,
  resourceName: ResourceName,
): boolean {
  const {economicSync, unicontaSync} = config;

  if (unicontaSync) {
    return resourceName === "customer";
  }

  // TODO(mr): economicSync lazy true for all resources
  return economicSync;
}
