import {DecimalField, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {DialogContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";

const messages = defineMessages({
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  clear: {
    defaultMessage: "Høj sol",
    id: "weather-dialog.label.clear",
  },
  clouds: {
    defaultMessage: "Skydække",
    id: "weather-dialog.label.clouds",
  },
  e: {
    defaultMessage: "Ø",
    id: "weather-dialog.label.wind-direction.e",
  },
  n: {
    defaultMessage: "N",
    id: "weather-dialog.label.wind-direction.n",
  },
  ne: {
    defaultMessage: "NØ",
    id: "weather-dialog.label.wind-direction.ne",
  },
  nw: {
    defaultMessage: "NV",
    id: "weather-dialog.label.wind-direction.nw",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  overcast: {
    defaultMessage: "Overskyet",
    id: "weather-dialog.label.overcast",
  },
  s: {
    defaultMessage: "S",
    id: "weather-dialog.label.wind-direction.s",
  },
  se: {
    defaultMessage: "SØ",
    id: "weather-dialog.label.wind-direction.se",
  },
  sw: {
    defaultMessage: "SV",
    id: "weather-dialog.label.wind-direction.sw",
  },
  w: {
    defaultMessage: "V",
    id: "weather-dialog.label.wind-direction.w",
  },
  weather: {
    defaultMessage: "Ret vejrdata",
    id: "weather-dialog.title.weather",
  },
});

export type WindDirection = "" | "e" | "n" | "ne" | "nw" | "s" | "se" | "sw" | "w";

interface WeatherDialogProps {
  nozzleType?: string | undefined;
  onCancel: () => void;
  onOk: (date: {
    nozzleType: string;
    sun: "" | "clear" | "clouds" | "overcast";
    temperature: number | null;
    windDirection: WindDirection;
    windSpeed: number | null;
  }) => void;
  open: boolean;
  sun?: "" | "clear" | "clouds" | "overcast";
  temperature?: number | undefined;
  windDirection?: WindDirection;
  windSpeed?: number | undefined;
}

interface WeatherDialogState {
  nozzleType: string;
  sun: "" | "clear" | "clouds" | "overcast";
  temperature: number | null;
  windDirection: WindDirection;
  windSpeed: number | null;
}

export class WeatherDialog extends PureComponent<WeatherDialogProps, WeatherDialogState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: WeatherDialogState = {
    nozzleType: this.props.nozzleType || "",
    sun: this.props.sun || "",
    temperature: this.props.temperature || null,
    windDirection: this.props.windDirection || "",
    windSpeed: this.props.windSpeed || null,
  };
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleNozzleTypeChange(value: string): void {
    this.setState({nozzleType: value});
  }

  @bind
  handleOk(): void {
    this.props.onOk({
      nozzleType: this.state.nozzleType,
      sun: this.state.sun,
      temperature: this.state.temperature,
      windDirection: this.state.windDirection,
      windSpeed: this.state.windSpeed,
    });
  }

  @bind
  handleSunChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const sun = event.target.value as "" | "clear" | "clouds" | "overcast";
    this.setState({sun});
  }

  @bind
  handleTemperatureChange(value: number | null): void {
    this.setState({temperature: value});
  }

  @bind
  handleWindDirectionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const windDirection = event.target.value as WindDirection;
    this.setState({windDirection});
  }

  @bind
  handleWindSpeedChange(value: number | null): void {
    this.setState({windSpeed: value});
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {open} = this.props;

    const dialogContent = (
      <div>
        <Grid>
          <Cell palm="1/1" size="1/5">
            <FormattedMessage
              defaultMessage="Vindretning"
              id="weather-dialog.label.wind-direction"
            />
            <RadioGroup
              name="windDirection"
              onChange={this.handleWindDirectionChange}
              value={this.state.windDirection}
            >
              <FormControlLabel control={<Radio />} label={formatMessage(messages.n)} value="n" />
              <FormControlLabel control={<Radio />} label={formatMessage(messages.ne)} value="ne" />
              <FormControlLabel control={<Radio />} label={formatMessage(messages.e)} value="e" />
              <FormControlLabel control={<Radio />} label={formatMessage(messages.se)} value="se" />
              <FormControlLabel control={<Radio />} label={formatMessage(messages.s)} value="s" />
              <FormControlLabel control={<Radio />} label={formatMessage(messages.sw)} value="sw" />
              <FormControlLabel control={<Radio />} label={formatMessage(messages.w)} value="w" />
              <FormControlLabel control={<Radio />} label={formatMessage(messages.nw)} value="nw" />
            </RadioGroup>
          </Cell>
          <Cell palm="1/1" size="1/5">
            <FormattedMessage
              defaultMessage="Vindhastighed (m/s)"
              id="weather-dialog.label.wind-speed"
            />
            <DecimalField
              autoFocus
              decimalPlaces={2}
              id="windSpeed"
              margin="dense"
              onChange={this.handleWindSpeedChange}
              style={{
                height: "auto",
                marginLeft: 5,
                marginRight: 5,
                width: "3em",
              }}
              value={this.state.windSpeed}
            />
          </Cell>
          <Cell palm="1/1" size="1/5">
            <FormattedMessage
              defaultMessage="Temperatur (°C):"
              id="weather-dialog.label.temperature"
            />
            <DecimalField
              decimalPlaces={2}
              id="temperature"
              margin="dense"
              onChange={this.handleTemperatureChange}
              style={{
                height: "auto",
                marginLeft: 5,
                marginRight: 5,
                width: "3em",
              }}
              value={this.state.temperature}
            />
          </Cell>
          <Cell palm="1/1" size="1/5">
            <FormattedMessage defaultMessage="Sol" id="weather-dialog.label.sun" />
            <RadioGroup name="sun" onChange={this.handleSunChange} value={this.state.sun}>
              <FormControlLabel
                control={<Radio />}
                label={formatMessage(messages.clear)}
                value="clear"
              />
              <FormControlLabel
                control={<Radio />}
                label={formatMessage(messages.clouds)}
                value="clouds"
              />
              <FormControlLabel
                control={<Radio />}
                label={formatMessage(messages.overcast)}
                value="overcast"
              />
            </RadioGroup>
          </Cell>
          <Cell palm="1/1" size="1/5">
            <FormattedMessage defaultMessage="Dysetype:" id="weather-dialog.label.nozzle-type" />
            <TrimTextField
              id="nozzle-type"
              margin="dense"
              onChange={this.handleNozzleTypeChange}
              style={{
                height: "auto",
                marginLeft: 5,
                marginRight: 5,
                width: "5em",
              }}
              value={this.state.nozzleType}
              variant="outlined"
            />
          </Cell>
        </Grid>
      </div>
    );
    return (
      <ResponsiveDialog
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        open={open}
        title={formatMessage(messages.weather)}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps: WeatherDialogProps): void {
    if (nextProps.open && !this.props.open) {
      this.setState({
        nozzleType: nextProps.nozzleType || "",
        sun: nextProps.sun || "",
        temperature: nextProps.temperature || null,
        windDirection: nextProps.windDirection || "",
        windSpeed: nextProps.windSpeed || null,
      });
    }
  }
}
