import {schema} from "@co-common-libs/config";
import React from "react";
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
  agriculturalContractor: {
    defaultMessage: "Maskinstation",
    id: "setting-dialog.label.agricultural-contractor",
  },
  agriculture: {
    defaultMessage: "Landbrug",
    id: "setting-dialog.label.agriculture",
  },
  contractor: {
    defaultMessage: "Entreprenør",
    id: "setting-dialog.label.contractor",
  },
  forestContractor: {
    defaultMessage: "Skoventreprenør",
    id: "setting-dialog.label.forest-contractor",
  },
  haulier: {
    defaultMessage: "Vognmand",
    id: "setting-dialog.label.haulier",
  },
  landscapeGardener: {
    defaultMessage: "Anlægsgartner",
    id: "setting-dialog.label.landscape-gardener",
  },
  marketGardener: {
    defaultMessage: "Frilandsgartner",
    id: "setting-dialog.label.market-gardener",
  },
  sewerContractor: {
    defaultMessage: "Kloakmester",
    id: "setting-dialog.label.sewer-contractor",
  },
  workshop: {
    defaultMessage: "Værksted",
    id: "setting-dialog.label.workshop",
  },
});

type Industry = (typeof schema.properties.industries.items.enum)[number];

interface IndustryLabelProps {
  industry: Industry;
}

export const IndustryLabel = React.memo(function IndustryLabel(
  props: IndustryLabelProps,
): React.JSX.Element {
  const {industry} = props;
  const {formatMessage} = useIntl();
  const labels: {[key in Industry]: string} = {
    AGRICULTURAL_CONTRACTOR: formatMessage(messages.agriculturalContractor),
    AGRICULTURE: formatMessage(messages.agriculture),
    CONTRACTOR: formatMessage(messages.contractor),
    FOREST_CONTRACTOR: formatMessage(messages.forestContractor),
    HAULIER: formatMessage(messages.haulier),
    LANDSCAPE_GARDENER: formatMessage(messages.landscapeGardener),
    MARKET_GARDENER: formatMessage(messages.marketGardener),
    SEWER_CONTRACTOR: formatMessage(messages.sewerContractor),
    WORKSHOP: formatMessage(messages.workshop),
  };
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{labels[industry]}</>;
});
