import {Config} from "@co-common-libs/config";
import {
  Machine,
  PatchUnion,
  PriceItem,
  PriceItemUrl,
  PriceItemUsesDict,
  Task,
  TimeCorrection,
  Timer,
  TimerUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {formatDateTimeShort, setFind} from "@co-common-libs/utils";
import {
  AppState,
  getPriceItemLookup,
  getPunchInOutPeriodsForCurrentUser,
  getUnitLookup,
  PunchWorkPeriod,
} from "@co-frontend-libs/redux";
import {Button, Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {TimeDistributionTable} from "app-components";
import {hasMultipleManualDistributionPriceItemUses} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import TimerIcon from "mdi-react/TimerIcon";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {TaskHorisontalTimeline} from "./task-horisontal-timeline";
import {TimeCorrectionRow} from "./time-correction-row";
import {TimelineTable} from "./timeline-table";
import {TimerButtonCard} from "./timer-button-card";
import {TotalTaskTimeBlock} from "./total-task-time-block";
import {IntervalWithTimer} from "./types";

const messages = defineMessages({
  addCorrection: {
    defaultMessage: "Tilføj",
    id: "task-instance.label.add-correction",
  },
  correctionsHeader: {
    defaultMessage: "Rettelser",
    id: "task-instance.header.time-corrections",
  },
  endTime: {defaultMessage: "Sluttidspunkt"},
  reportDowntimeLabel: {
    defaultMessage: "Indmeld maskinskade",
    id: "task-instance.label.report-downtime",
  },
  selectMachine: {
    defaultMessage: "Vælg",
    id: "task-instance.label.select-transport-machine",
  },
  startTime: {
    defaultMessage: "Starttidspunkt",
  },
  timeLine: {
    defaultMessage: "Tidslinje",
    id: "task-horisontal-timeline.header.timeline",
  },
  timeRemoved: {
    defaultMessage: "Tid slettet",
    id: "task-instance.label.time-removed",
  },
  timerNote: {
    defaultMessage: "Note *",
    id: "task-instance.label.timer-note",
  },
});

interface TimeCardStateProps {
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  punchInOutPeriodsForCurrentUser: readonly PunchWorkPeriod[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface TimeCardOwnProps {
  activeTimer?: TimerUrl | undefined;
  completed: boolean;
  customerSettings: Config;
  finalEndTime?: string | undefined;
  finalStartTime?: string | undefined;
  genericPrimaryTimer: Timer;
  genericPrimaryTimerLabel: string;
  hidePrimaryButton: boolean;
  intervals: readonly IntervalWithTimer[];
  now: string;
  onClickTimelineModal?: () => void;
  onRequestAddMachineOperatorTimeCorrection?: (() => void) | undefined;
  onRequestAddManagerTimeCorrection?: (() => void) | undefined;
  onTimelineIntervalClick:
    | ((fromTimestamp: string, toTimestamp: string, timer: Timer | null) => void)
    | undefined;
  onTimerButton: (timerURL: TimerUrl) => void;
  onTransportMachineSelectButton?: () => void;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  timerMinutes: ReadonlyMap<string, number>;
  transportMachine?: Machine | undefined;
  update: (url: string, patch: PatchUnion) => void;
  userIsOnlyMachineOperator: boolean;
  userIsOther: boolean;
  userIsOtherMachineOperator: boolean;
  validated: boolean;
}

type TimeCardProps = TimeCardOwnProps & TimeCardStateProps;

class TimeCard extends React.Component<TimeCardProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleMachineOperatorTimeCorrectionDelete(
    fromTimestamp: string,
    toTimestamp: string,
    timerURL: string | null,
  ): void {
    const {task} = this.props;
    const {url} = task;
    const deletedCorrectionIndex = task.machineOperatorTimeCorrectionSet.findIndex(
      (c) =>
        c.fromTimestamp === fromTimestamp && c.toTimestamp === toTimestamp && c.timer === timerURL,
    );
    const machineOperatorTimeCorrectionSet = task.machineOperatorTimeCorrectionSet.slice();
    machineOperatorTimeCorrectionSet.splice(deletedCorrectionIndex, 1);

    this.props.update(url, [
      {
        member: "machineOperatorTimeCorrectionSet",
        value: machineOperatorTimeCorrectionSet,
      },
    ]);
  }

  @bind
  handleManagerTimeCorrectionDelete(
    fromTimestamp: string,
    toTimestamp: string,
    timerURL: string | null,
  ): void {
    const {task} = this.props;
    const {url} = task;
    const deletedCorrectionIndex = task.managerTimeCorrectionSet.findIndex(
      (c) =>
        c.fromTimestamp === fromTimestamp && c.toTimestamp === toTimestamp && c.timer === timerURL,
    );
    const managerTimeCorrectionSet = task.managerTimeCorrectionSet.slice();
    managerTimeCorrectionSet.splice(deletedCorrectionIndex, 1);
    this.props.update(url, [{member: "managerTimeCorrectionSet", value: managerTimeCorrectionSet}]);
  }

  @bind
  handleNotesChange(timerURL: TimerUrl, notes: string): void {
    const {task, update} = this.props;
    const oldValue = task.timernotesSet;
    if (oldValue === undefined) {
      return;
    }
    const newValue = oldValue.slice();
    const index = newValue.findIndex((entry) => entry.timer === timerURL);
    if (index > -1) {
      newValue[index] = {...newValue[index], notes};
    } else {
      newValue.push({notes, timer: timerURL});
    }
    update(task.url, [{member: "timernotesSet", value: newValue}]);
  }

  timeCorrectionFormatter(
    deleteHandler: (fromTimestamp: string, toTimestamp: string, timerURL: string | null) => void,
    primaryLabel: string,
    canDelete: boolean,
  ): (interval: TimeCorrection, index: number) => React.JSX.Element {
    // eslint-disable-next-line react/display-name
    return (interval, index) => {
      const {formatMessage} = this.context;
      const timerURL = interval.timer;
      let timerLabel;
      if (timerURL) {
        const secondaryTimer = setFind(this.props.secondaryTimers, (w) => w.url === timerURL);
        if (secondaryTimer) {
          timerLabel = secondaryTimer.label;
        } else {
          timerLabel = primaryLabel;
        }
      } else {
        timerLabel = formatMessage(messages.timeRemoved);
      }
      const {fromTimestamp} = interval;
      const {toTimestamp} = interval;

      return (
        <TimeCorrectionRow
          canDelete={canDelete}
          fromTimestamp={fromTimestamp}
          key={index}
          onDelete={deleteHandler}
          timerLabel={timerLabel}
          timerURL={timerURL}
          toTimestamp={toTimestamp}
        />
      );
    };
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      activeTimer,
      completed,
      customerSettings,
      finalEndTime,
      finalStartTime,
      genericPrimaryTimer,
      genericPrimaryTimerLabel,
      hidePrimaryButton,
      intervals,
      onRequestAddMachineOperatorTimeCorrection,
      onRequestAddManagerTimeCorrection,
      onTimerButton,
      punchInOutPeriodsForCurrentUser,
      secondaryTimers,
      task,
      timerMinutes,
      userIsOnlyMachineOperator,
      userIsOther,
      userIsOtherMachineOperator,
      validated,
    } = this.props;

    const primaryTimerURL = genericPrimaryTimer.url;

    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    let machineOperatorCorrectionsBlock;
    if (correctionIntervals.length) {
      machineOperatorCorrectionsBlock = (
        <div>
          {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
            <FormattedMessage
              defaultMessage="Rettelser fra maskinfører"
              id="task-instance.header.machine-operator-time-corrections"
              tagName="h4"
            />
          ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
            <FormattedMessage
              defaultMessage="Rettelser fra medarbejder"
              id="task-instance.header.employee-time-corrections"
              tagName="h4"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Rettelser fra chauffør"
              id="task-instance.header.chauffeur-time-corrections"
              tagName="h4"
            />
          )}
          {correctionIntervals.map(
            this.timeCorrectionFormatter(
              this.handleMachineOperatorTimeCorrectionDelete,
              genericPrimaryTimerLabel,
              !!onRequestAddMachineOperatorTimeCorrection || !!onRequestAddManagerTimeCorrection,
            ),
          )}
        </div>
      );
    }
    let managerCorrectionsBlock;
    if (managerCorrectionIntervals.length) {
      managerCorrectionsBlock = (
        <div>
          <FormattedMessage
            defaultMessage="Rettelser fra administrationen"
            id="task-instance.header.manager-time-corrections"
            tagName="h4"
          />
          {managerCorrectionIntervals.map(
            this.timeCorrectionFormatter(
              this.handleManagerTimeCorrectionDelete,
              genericPrimaryTimerLabel,
              !!onRequestAddManagerTimeCorrection,
            ),
          )}
        </div>
      );
    }
    const effectiveMinutes = !hidePrimaryButton ? timerMinutes.get(primaryTimerURL) : 0;
    const transportTimer = Array.from(secondaryTimers).find(
      (t) => t.label.toLowerCase() === "transport",
    );
    const transportMinutes = transportTimer ? timerMinutes.get(transportTimer.url) || 0 : 0;
    const noTransportTime = !transportMinutes;
    let transportMachineDiv;
    if (transportMinutes && this.props.transportMachine) {
      const machine = this.props.transportMachine;
      const machineID = machine.c5_machine;
      const machineName = machine.name;
      const text = machineName + (machineID ? ` (${machineID})` : "");
      transportMachineDiv = <div>{text}</div>;
    }
    const {department} = task;
    const allowMoreThanTwoMachines =
      customerSettings.alwaysAllowMoreThanTwoMachines ||
      customerSettings.allowMoreThanTwoMachinesForDepartments.includes(department);

    return (
      <div>
        <Grid container>
          <Grid item sm xs={12}>
            <TimerButtonCard
              activeTimer={activeTimer}
              completed={completed}
              customerSettings={customerSettings}
              genericPrimaryTimer={genericPrimaryTimer}
              genericPrimaryTimerLabel={genericPrimaryTimerLabel}
              hidePrimaryButton={hidePrimaryButton}
              onNotesChange={this.handleNotesChange}
              onTimerButton={onTimerButton}
              punchInOutPeriodsForCurrentUser={punchInOutPeriodsForCurrentUser}
              secondaryTimers={secondaryTimers}
              task={task}
              timerMinutes={timerMinutes}
              update={this.props.update}
              userIsOnlyMachineOperator={userIsOnlyMachineOperator}
              userIsOther={userIsOther}
              userIsOtherMachineOperator={userIsOtherMachineOperator}
              validated={validated}
            />
          </Grid>
          {allowMoreThanTwoMachines &&
          hasMultipleManualDistributionPriceItemUses(
            task,
            this.props.priceItemLookup,
            this.props.unitLookup,
          ) ? (
            <Grid item sm={6} xs={12}>
              <Card style={{margin: "1em"}}>
                <CardContent>
                  <FormattedMessage
                    defaultMessage="Fordeling af effektiv tid"
                    id="order-instance.task-instance.TimeDistribution"
                    tagName="h4"
                  />
                  <TimeDistributionTable
                    disabled={
                      (completed && userIsOnlyMachineOperator) ||
                      validated ||
                      userIsOtherMachineOperator
                    }
                    expectedMinutes={effectiveMinutes}
                    task={
                      task as Task & {
                        readonly priceItemUses: PriceItemUsesDict;
                      }
                    }
                  />
                  {customerSettings.treatTransportTimeAsEffectiveForDepartments.includes(
                    task.department,
                  ) ? (
                    <div>
                      {customerSettings.machineLabelVariant === "MACHINE" ? (
                        <FormattedMessage
                          defaultMessage="Maskine for transport"
                          id="task-instance.header.transport-machine"
                          tagName="h4"
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Køretøj for transport"
                          id="task-instance.header.transport-vehicle"
                          tagName="h4"
                        />
                      )}
                      <Button
                        color="primary"
                        disabled={
                          (completed && userIsOnlyMachineOperator) ||
                          validated ||
                          userIsOtherMachineOperator ||
                          noTransportTime ||
                          !this.props.onTransportMachineSelectButton
                        }
                        onClick={this.props.onTransportMachineSelectButton || _.noop}
                        variant="contained"
                      >
                        {formatMessage(messages.selectMachine)}
                      </Button>
                      {transportMachineDiv}
                    </div>
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
          ) : null}
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Card style={{margin: "1em"}}>
              <CardHeader title={formatMessage(messages.timeLine)} />
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <FormattedMessage
                      defaultMessage="Start: {datetime}"
                      id="task-instance.time-card.task-start-label"
                      tagName="h3"
                      values={{datetime: formatDateTimeShort(finalStartTime)}}
                    />
                  </Grid>
                  <Grid item style={{textAlign: "right"}} xs={6}>
                    <FormattedMessage
                      defaultMessage="Slut: {datetime}"
                      id="task-instance.time-card.task-end-label"
                      tagName="h3"
                      values={{datetime: formatDateTimeShort(finalEndTime)}}
                    />
                  </Grid>
                </Grid>
                <TaskHorisontalTimeline
                  intervals={intervals}
                  onActiveTaskIntervalClick={this.props.onTimelineIntervalClick}
                  task={task}
                />
                <Grid item md={4} sm={6} xs={12}>
                  <TimelineTable intervals={intervals} task={task} />
                </Grid>
                <TotalTaskTimeBlock intervals={intervals} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Card style={{margin: "1em"}}>
              <CardHeader title={formatMessage(messages.correctionsHeader)} />
              <CardContent>
                {machineOperatorCorrectionsBlock}
                {managerCorrectionsBlock}
                <Button
                  color="primary"
                  disabled={
                    !task.machineOperator ||
                    (!onRequestAddManagerTimeCorrection &&
                      !onRequestAddMachineOperatorTimeCorrection)
                  }
                  onClick={
                    onRequestAddManagerTimeCorrection ||
                    onRequestAddMachineOperatorTimeCorrection ||
                    _.noop
                  }
                  startIcon={<TimerIcon color="#fff" />}
                  variant="contained"
                >
                  {formatMessage(messages.addCorrection)}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const ConnectedTimeCard: React.ComponentType<TimeCardOwnProps> = connect<
  TimeCardStateProps,
  object,
  TimeCardOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TimeCardStateProps>({
    priceItemLookup: getPriceItemLookup,
    punchInOutPeriodsForCurrentUser: getPunchInOutPeriodsForCurrentUser,
    unitLookup: getUnitLookup,
  }),
  {},
)(TimeCard);

export default ConnectedTimeCard;
