import {
  Machine,
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Unit,
  UnitUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {identifierComparator} from "@co-common-libs/utils";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {
  getPriceGroupsFromMap,
  getPriceItemIdentifier,
  getPriceItemsForSelectedPriceGroup,
  getSortedMachines,
  priceGroupCompare,
  PureComponent,
  remoteURLContainsData,
} from "app-utils";
import {ExtendedConfig} from "extended-config";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {OverviewRow} from "./overview-row";

const messages = defineMessages({
  minimumPrice: {
    defaultMessage: "Minimumspris - antal: {minimumCount}",
    id: "machine-overview.text.minimum-price",
  },
  volumeDiscount: {
    defaultMessage: "Rabat: {percentage}% - grænse: {threshold}",
    id: "machine-overview.text.volume-discount",
  },
});

interface MachinesOverviewProps {
  customerSettings: ExtendedConfig;
  defaultSurcharge: string | null | undefined;
  includePriceGroupsNotConnectedToAnyMachine: boolean;
  includePriceItemsNotConnectedToAnyPriceGroups: boolean;
  machineArray: readonly Machine[];
  machineLookup: (url: MachineUrl) => Machine | undefined;
  machinesWithSurcharges: ReadonlyMap<string, string | null> | undefined;
  onAddPriceGroupClick?: (url: MachineUrl | WorkTypeUrl) => void;
  priceGroupArray: readonly PriceGroup[];
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemArray: readonly PriceItem[];
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export class MachinesOverview extends PureComponent<MachinesOverviewProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  generatePriceGroupRows(
    pricegroups: PriceGroup[],
    machineURL: MachineUrl | null,
    machine: Machine | null,
    showPriceItemIdentifiers: boolean,
  ): [React.JSX.Element[], string[], string[]] {
    const {formatMessage, formatNumber} = this.context;
    const rows: React.JSX.Element[] = [];
    const includedPriceItems: string[] = [];
    const includedPriceGroups: string[] = [];
    const keyMachineURLPart = `machine-${machineURL || "not-connected-pricegroup"}`;

    pricegroups.forEach((priceGroup) => {
      const priceGroupURL = priceGroup.url;
      const priceItems = getPriceItemsForSelectedPriceGroup(
        priceGroup,
        this.props.priceItemLookup,
        this.props.unitLookup,
        this.props.customerSettings,
      );
      includedPriceGroups.push(priceGroupURL);
      priceItems.forEach((priceItem, index) => {
        const priceItemURL = priceItem.url;
        const unit = getUnitString(priceItem, this.props.unitLookup);
        const priceItemIdentifier = getPriceItemIdentifier(priceItem);
        const {price} = priceItem;
        const firstRowForPriceItem = index === 0;
        includedPriceItems.push(priceItemURL);
        const firstRowForMachine = rows.length === 0;
        const workTypeOrMachineIdentifier = machine
          ? firstRowForMachine
            ? machine.c5_machine
            : undefined
          : undefined;
        const workTypeOrMachineName = machine
          ? firstRowForMachine
            ? machine.name
            : undefined
          : undefined;
        const workTypeOrMachineURL = firstRowForMachine ? machineURL : undefined;
        const variantIdentifier = firstRowForPriceItem ? priceGroup.identifier : undefined;
        const variantName = firstRowForPriceItem ? priceGroup.name : undefined;
        const variantOnlyForExtraTimers = firstRowForPriceItem
          ? priceGroup.onlyForExtraTimers
          : undefined;

        rows.push(
          <OverviewRow
            defaultFuelSurcharge={
              firstRowForMachine && machineURL ? this.props.defaultSurcharge : undefined
            }
            firstRowForPriceItem={firstRowForPriceItem}
            itemName={priceItem.name}
            key={`${keyMachineURLPart}-${priceGroupURL}-${priceItemURL}`}
            onAddPriceGroupClick={this.props.onAddPriceGroupClick}
            price={price != null ? price : undefined}
            priceGroupFuelSurcharge={
              firstRowForPriceItem && machineURL
                ? this.props.machinesWithSurcharges?.get(`${machineURL}\0${priceGroupURL}`)
                : undefined
            }
            priceItemIdentifier={priceItemIdentifier != null ? priceItemIdentifier : undefined}
            priceItemURL={priceItemURL}
            showPriceItemIdentifiers={showPriceItemIdentifiers}
            unit={unit}
            variantIdentifier={variantIdentifier}
            variantName={variantName}
            variantOnlyForExtraTimers={variantOnlyForExtraTimers}
            variantURL={priceGroupURL}
            workTypeOrMachineFuelSurcharge={
              firstRowForMachine && machineURL
                ? this.props.machinesWithSurcharges?.get(machineURL)
                : undefined
            }
            workTypeOrMachineIdentifier={workTypeOrMachineIdentifier}
            workTypeOrMachineName={workTypeOrMachineName}
            workTypeOrMachineURL={workTypeOrMachineURL != null ? workTypeOrMachineURL : undefined}
          />,
        );

        const {minimumCount} = priceItem;
        if (minimumCount) {
          rows.push(
            <OverviewRow
              itemName={formatMessage(messages.minimumPrice, {
                minimumCount: formatNumber(minimumCount),
              })}
              key={`${keyMachineURLPart}-${priceGroupURL}-${priceItemURL}-minimumPrice`}
              price={price != null ? minimumCount * price : undefined}
              priceItemConfiguration
              showPriceItemIdentifiers={showPriceItemIdentifiers}
            />,
          );
        }
        const volumeDiscountList = priceItem.volumeDiscountSet || [];
        volumeDiscountList.forEach((volumeDiscount, discountIndex) => {
          const percentage = volumeDiscount.discountPercentage;
          const {threshold} = volumeDiscount;
          rows.push(
            <OverviewRow
              itemName={formatMessage(messages.volumeDiscount, {
                percentage: percentage != null ? formatNumber(percentage) : "",
                threshold: threshold != null ? formatNumber(threshold) : "",
              })}
              key={`${keyMachineURLPart}-${priceGroupURL}-${priceItemURL}-volumeDiscount-${discountIndex}`}
              price={price != null ? (price * (100 - (percentage || 0))) / 100 : undefined}
              priceItemConfiguration
              showPriceItemIdentifiers={showPriceItemIdentifiers}
              unit={unit}
            />,
          );
        });
      });
      if (!priceItems.length) {
        const firstRowForMachine = rows.length === 0;
        const workTypeOrMachineIdentifier = machine
          ? firstRowForMachine
            ? machine.c5_machine
            : undefined
          : undefined;

        const workTypeOrMachineName = machine
          ? firstRowForMachine
            ? machine.name
            : undefined
          : undefined;
        const workTypeOrMachineURL = firstRowForMachine ? machineURL : undefined;
        const variantIdentifier = priceGroup.identifier;
        const variantName = priceGroup.name;
        const variantOnlyForExtraTimers = priceGroup.onlyForExtraTimers;
        rows.push(
          <OverviewRow
            defaultFuelSurcharge={
              firstRowForMachine && machineURL ? this.props.defaultSurcharge : undefined
            }
            key={`${keyMachineURLPart}-${priceGroupURL}`}
            onAddPriceGroupClick={this.props.onAddPriceGroupClick}
            priceGroupFuelSurcharge={
              machineURL
                ? this.props.machinesWithSurcharges?.get(`${machineURL}\0${priceGroupURL}`)
                : undefined
            }
            showPriceItemIdentifiers={showPriceItemIdentifiers}
            variantIdentifier={variantIdentifier}
            variantName={variantName}
            variantOnlyForExtraTimers={variantOnlyForExtraTimers}
            variantURL={priceGroupURL}
            workTypeOrMachineFuelSurcharge={
              firstRowForMachine && machineURL
                ? this.props.machinesWithSurcharges?.get(machineURL)
                : undefined
            }
            workTypeOrMachineIdentifier={workTypeOrMachineIdentifier}
            workTypeOrMachineName={workTypeOrMachineName}
            workTypeOrMachineURL={workTypeOrMachineURL != null ? workTypeOrMachineURL : undefined}
          />,
        );
      }
    });
    return [rows, includedPriceItems, includedPriceGroups];
  }

  render(): React.JSX.Element {
    const {formatMessage, formatNumber} = this.context;
    const {
      customerSettings,
      machineArray,
      machineLookup,
      priceGroupArray,
      priceGroupLookup,
      priceItemArray,
    } = this.props;
    const {disabledMachines, overviewShowPrices} = this.props.customerSettings;

    const showPriceItemIdentifiers = remoteURLContainsData(priceItemArray);

    const sortedMachines = getSortedMachines(machineArray, disabledMachines);
    let rows: React.JSX.Element[] = [];

    let shownPriceItems: string[] = [];
    let shownPriceGroups: string[] = [];

    sortedMachines.forEach((machine) => {
      const machineURL = machine.url;
      const pricegroups = getPriceGroupsFromMap(machineURL, machineLookup, priceGroupLookup);
      const [newRows, includedPriceItems, includedPriceGroups] = this.generatePriceGroupRows(
        pricegroups,
        machineURL,
        machine,
        showPriceItemIdentifiers,
      );
      shownPriceItems = shownPriceItems.concat(includedPriceItems);
      shownPriceGroups = shownPriceGroups.concat(includedPriceGroups);
      rows = rows.concat(newRows);

      if (!pricegroups.length) {
        rows.push(
          <OverviewRow
            defaultFuelSurcharge={machineURL ? this.props.defaultSurcharge : undefined}
            key={`machine-${machineURL}`}
            onAddPriceGroupClick={this.props.onAddPriceGroupClick}
            showPriceItemIdentifiers={showPriceItemIdentifiers}
            workTypeOrMachineFuelSurcharge={
              machineURL ? this.props.machinesWithSurcharges?.get(machineURL) : undefined
            }
            workTypeOrMachineIdentifier={machine.c5_machine}
            workTypeOrMachineName={machine.name}
            workTypeOrMachineURL={machineURL}
          />,
        );
      }
    });

    const notConnectedPriceGroups = priceGroupArray
      .filter((instance) => instance.active && !shownPriceGroups.includes(instance.url))
      .sort(priceGroupCompare);

    if (notConnectedPriceGroups.length && this.props.includePriceGroupsNotConnectedToAnyMachine) {
      const [newRows, includedPriceItems, includedPriceGroups] = this.generatePriceGroupRows(
        notConnectedPriceGroups,
        null,
        null,
        showPriceItemIdentifiers,
      );
      shownPriceItems = shownPriceItems.concat(includedPriceItems);
      shownPriceGroups = shownPriceGroups.concat(includedPriceGroups);
      rows = rows.concat(newRows);
    }

    const notConnectedPriceItems = this.props.priceItemArray
      .filter((priceItem) => priceItem.active && !shownPriceItems.includes(priceItem.url))
      .sort((a, b) =>
        identifierComparator(getPriceItemIdentifier(a) || "", getPriceItemIdentifier(b) || ""),
      );
    if (notConnectedPriceItems.length && this.props.includePriceItemsNotConnectedToAnyPriceGroups) {
      notConnectedPriceItems.forEach((priceItem) => {
        const priceItemURL = priceItem.url;
        const unit = getUnitString(priceItem, this.props.unitLookup);
        const priceItemIdentifier = getPriceItemIdentifier(priceItem);
        const {price} = priceItem;

        rows.push(
          <OverviewRow
            itemName={priceItem.name}
            key={`machine-not-connected-price-item-${priceItemURL}`}
            price={price != null ? price : undefined}
            priceItemIdentifier={priceItemIdentifier != null ? priceItemIdentifier : undefined}
            priceItemURL={priceItemURL}
            showPriceItemIdentifiers={showPriceItemIdentifiers}
            unit={unit}
          />,
        );
        const {minimumCount} = priceItem;
        if (minimumCount) {
          rows.push(
            <OverviewRow
              itemName={formatMessage(messages.minimumPrice, {
                minimumCount: formatNumber(minimumCount),
              })}
              key={`machine-not-connected-price-item-${priceItemIdentifier}-minimumPrice`}
              price={price != null ? minimumCount * price : undefined}
              priceItemConfiguration
              showPriceItemIdentifiers={showPriceItemIdentifiers}
            />,
          );
        }
        const volumeDiscountList = priceItem.volumeDiscountSet || [];
        volumeDiscountList.forEach((volumeDiscount, discountIndex) => {
          const percentage = volumeDiscount.discountPercentage;
          const {threshold} = volumeDiscount;
          rows.push(
            <OverviewRow
              itemName={formatMessage(messages.volumeDiscount, {
                percentage: percentage != null ? formatNumber(percentage) : "",
                threshold: threshold != null ? formatNumber(threshold) : "",
              })}
              key={`machine-not-connected-price-item-${priceItemIdentifier}-volumeDiscount-${discountIndex}`}
              price={price != null ? (price * (100 - (percentage || 0))) / 100 : undefined}
              priceItemConfiguration
              showPriceItemIdentifiers={showPriceItemIdentifiers}
              unit={unit}
            />,
          );
        });
      });
    }
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {customerSettings.machineLabelVariant === "MACHINE" ? (
                <FormattedMessage
                  defaultMessage="Maskine-ID"
                  id="machine-overview.table-header.machine-id"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Køretøjs-ID"
                  id="machine-overview.table-header.vehicle-id"
                />
              )}
            </TableCell>
            <TableCell>
              {customerSettings.machineLabelVariant === "MACHINE" ? (
                <FormattedMessage
                  defaultMessage="Maskine-Navn"
                  id="machine-overview.table-header.machine-name"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Køretøjs-Navn"
                  id="machine-overview.table-header.vehicle-name"
                />
              )}
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Variant-ID"
                id="machine-overview.table-header.pricegroup-id"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Variant-Navn"
                id="machine-overview.table-header.pricegroup-name"
              />
            </TableCell>
            {showPriceItemIdentifiers ? (
              <TableCell>
                <FormattedMessage
                  defaultMessage="Pris-ID"
                  id="machine-overview.table-header.price-id"
                />
              </TableCell>
            ) : null}
            <TableCell>
              <FormattedMessage
                defaultMessage="Pris-Navn og Opsætning"
                id="machine-overview.table-header.price-name"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Pris-Enhed"
                id="machine-overview.table-header.price-unit"
              />
            </TableCell>
            {overviewShowPrices ? (
              <TableCell style={{textAlign: "right"}}>
                <FormattedMessage
                  defaultMessage="Pris (kr)"
                  id="machine-overview.table-header.price"
                />
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  }
}
