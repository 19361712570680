import {Config} from "@co-common-libs/config";
import {
  Machine,
  MachineUrl,
  PatchOperation,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUseWithOrder,
  Task,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {makeContainsPredicate, notUndefined, sortByOrderMember} from "@co-common-libs/utils";
import {TrimTextField} from "@co-frontend-libs/components";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {LegacyPriceItemEntry} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import _ from "lodash";
import React, {useCallback, useState} from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {v4 as uuid} from "uuid";

const BUTTON_COLUMN_STYLE = {
  paddingLeft: 0,
  paddingRight: 0,
  width: 48,
} as const;

const UNIT_COLUMN_WIDTH = 78;
const COUNT_INPUT_FIELD_WIDTH = 96;
const TABLE_PADDING_WIDTH = 48;
const COUNT_COLUMN_WIDTH = COUNT_INPUT_FIELD_WIDTH + TABLE_PADDING_WIDTH;

const MOBILE = bowser.mobile || bowser.tablet;

const messages = defineMessages({
  noSmallMachines: {
    defaultMessage: "Ingen småmaskiner anvendt.",
    id: "task-instance.label.no-small-machines",
  },
});

interface PriceItemBlockProps {
  customerSettings: Config;
  disabled: boolean;
  lastEntry?: boolean;
  machine: Machine;
  onChange: (
    value: number | null,
    priceItemURL: PriceItemUrl,
    priceGroupURL: PriceGroupUrl,
    machineURL: MachineUrl,
  ) => void;
  priceGroup: PriceGroup;
  priceItem: PriceItem;
  priceItemUseCount: number | null;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

class PriceItemBlock extends PureComponent<PriceItemBlockProps> {
  @bind
  handleChange(_index: number, value: number | null): void {
    const {machine, onChange, priceGroup, priceItem} = this.props;
    const priceItemURL = priceItem.url;
    const priceGroupURL = priceGroup.url;
    const machineURL = machine.url;
    onChange(value, priceItemURL, priceGroupURL, machineURL);
  }
  render(): React.JSX.Element {
    const {disabled, priceItem, priceItemUseCount, unitLookup} = this.props;
    const unit = getUnitString(priceItem, unitLookup);
    const priceItemName = priceItem.name;
    return (
      <LegacyPriceItemEntry
        customerSettings={this.props.customerSettings}
        disabled={disabled}
        hasConversions={false}
        lastEntry={this.props.lastEntry}
        name={priceItemName}
        notes=""
        onChange={this.handleChange}
        plusButtonStartingValue={1}
        priceItemIndex={0} // note the showNotes={false}
        showNotes={false}
        unit={unit}
        value={priceItemUseCount || undefined}
      />
    );
  }
}

interface SmallMachinesTabContentProps {
  completed?: boolean;
  customerSettings: Config;
  machineArray: readonly Machine[];
  machineLookup: (url: MachineUrl) => Machine | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  task: Task;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  update: (url: string, patch: PatchUnion) => void;
  userIsManager: boolean;
  userIsOtherMachineOperator: boolean;
  validated?: boolean;
}

export const SmallMachinesTabContent = React.memo(function SmallMachinesTabContent(
  props: SmallMachinesTabContentProps,
): React.JSX.Element {
  const {
    completed,
    machineArray,
    machineLookup,
    priceGroupLookup,
    priceItemLookup,
    task,
    unitLookup,
    update,
    userIsManager,
    userIsOtherMachineOperator,
    validated,
  } = props;
  const {formatMessage} = useIntl();
  const [searchString, setSearchString] = useState("");
  const handlePriceItemChange = useCallback(
    (
      value: number | null,
      priceItemURL: PriceItemUrl,
      priceGroupURL: PriceGroupUrl,
      machineURL: MachineUrl,
    ): void => {
      const patch: PatchOperation<Task>[] = [];
      if (!value) {
        // potentially remove machine and priceitemuse
        const machinePriceItemURLSet = new Set<string>();
        const machine = machineLookup(machineURL);
        if (machine) {
          machine.pricegroups.forEach((machinePriceGroupURL) => {
            const priceGroup = priceGroupLookup(machinePriceGroupURL);
            if (priceGroup) {
              priceGroup.priceGroupItemSet.forEach((priceGroupItem) => {
                const machinePriceItemURL = priceGroupItem.priceItem;
                machinePriceItemURLSet.add(machinePriceItemURL);
              });
            }
          });
        }
        const priceItemUseList = sortByOrderMember(Object.values(task.priceItemUses || {}));
        // machine should stay if at least one non-blank line demands it
        const machineShouldStillBePresent = priceItemUseList.some((priceItemUse) => {
          const url = priceItemUse.priceItem;
          return (
            url !== priceItemURL && machinePriceItemURLSet.has(url) && priceItemUse.count != null
          );
        });
        if (machineShouldStillBePresent) {
          // machine (and thus lines) still present, so just clear the count
          // (when machine/pricegroup stays, other code auto-adds lines for the
          // pricegroup, so removing the line won't work...)
          Object.entries(task.priceItemUses || {}).forEach(([identifier, priceItemUse]) => {
            if (priceItemUse.priceItem === priceItemURL) {
              patch.push({
                path: ["priceItemUses", identifier, "count"],
                value: null,
              });
            }
          });
        } else {
          // none of the prece item lines for the machine present/non-blank after change; remove machine and lines
          patch.push({
            member: "machineuseSet",
            value: task.machineuseSet.filter((m) => m.machine !== machineURL),
          });
          Object.entries(task.priceItemUses || {}).forEach(([identifier, priceItemUse]) => {
            if (machinePriceItemURLSet.has(priceItemUse.priceItem)) {
              patch.push({
                path: ["priceItemUses", identifier],
                value: undefined,
              });
            }
          });
        }
      } else {
        // potentially add machine and priceitemuse
        const oldMachinUseList = task.machineuseSet;
        if (
          !oldMachinUseList.some((m) => m.machine === machineURL && m.priceGroup === priceGroupURL)
        ) {
          patch.push({
            member: "machineuseSet",
            value: [
              ...oldMachinUseList,
              {
                machine: machineURL,
                priceGroup: priceGroupURL,
                transporter: false,
              },
            ],
          });
        }
        const sortedPriceItemUses = _.sortBy(
          Object.entries(task.priceItemUses || {}),
          ([_identifier, priceItemUse]) => priceItemUse.order,
        );
        const existing = sortedPriceItemUses.find(
          ([_identifier, priceItemUse]) => priceItemUse.priceItem === priceItemURL,
        );
        if (existing) {
          const [identifier, priceItemUse] = existing;
          if (priceItemUse.count !== value) {
            patch.push({
              path: ["priceItemUses", identifier, "count"],
              value,
            });
          }
        } else {
          const oldMaxOrder = _.max(
            Object.values(task.priceItemUses || {}).map((priceItemUse) => priceItemUse.order),
          );
          const newOrder = oldMaxOrder !== undefined ? oldMaxOrder + 1 : 0;
          const newPriceItemUse: PriceItemUseWithOrder = {
            correctedCount: null,
            count: value,
            dangling: false,
            machine: machineURL,
            notes: "",
            order: newOrder,
            priceGroup: priceGroupURL,
            priceItem: priceItemURL,
            timer: null,
            workType: null,
          };
          const newIdentifier = uuid();
          patch.push({
            path: ["priceItemUses", newIdentifier],
            value: newPriceItemUse,
          });
        }
      }
      update(task.url, patch);
    },
    [machineLookup, priceGroupLookup, task.machineuseSet, task.priceItemUses, task.url, update],
  );

  const handleWithoutSmallMachinesCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      update(task.url, [{member: "withoutSmallMachines", value: checked}]);
    },
    [task.url, update],
  );

  const priceItemUseList = sortByOrderMember(Object.values(task.priceItemUses || {}));
  const priceitemRows: React.JSX.Element[] = [];

  const check = makeContainsPredicate(searchString);

  let anySmallMachinesUsed = false;
  _.sortBy(
    machineArray.filter(
      (machine) =>
        machine.active && machine.smallMachine && check(`${machine.c5_machine} ${machine.name}`),
    ),
    (machine) => machine.name,
  ).forEach((machine) => {
    _.sortBy(
      machine.pricegroups
        .map((priceGroupURL) => priceGroupLookup(priceGroupURL))
        .filter(notUndefined),
      (priceGroup) => priceGroup.name,
    ).forEach((priceGroup) => {
      if (MOBILE) {
        priceitemRows.push(
          <div key={machine.url} style={{fontWeight: "bold", marginTop: 16}}>
            {machine.name || ""}
          </div>,
        );
      } else {
        priceitemRows.push(
          <TableRow key={machine.url} style={{borderBottomWidth: 0, borderTopWidth: 1}}>
            <TableCell colSpan={4} style={{fontWeight: "bold"}}>
              {machine.name || ""}
            </TableCell>
          </TableRow>,
        );
      }
      _.sortBy(
        priceGroup.priceGroupItemSet
          .map((priceGroupItem) => priceItemLookup(priceGroupItem.priceItem))
          .filter(notUndefined),
        (priceItem) => priceItem.name,
      ).forEach((priceItem, index) => {
        const priceItemURL = priceItem.url;
        const priceItemUse = priceItemUseList.find((p) => p.priceItem === priceItemURL);
        anySmallMachinesUsed = anySmallMachinesUsed || !!priceItemUse?.count;
        priceitemRows.push(
          <PriceItemBlock
            customerSettings={props.customerSettings}
            disabled={
              !!task.withoutSmallMachines ||
              validated ||
              (!userIsManager && (completed || userIsOtherMachineOperator))
            }
            key={`${priceItem.url}-${machine.name}`}
            lastEntry={index === priceGroup.priceGroupItemSet.length - 1}
            machine={machine}
            onChange={handlePriceItemChange}
            priceGroup={priceGroup}
            priceItem={priceItem}
            priceItemUseCount={priceItemUse?.count ?? null}
            unitLookup={unitLookup}
          />,
        );
      });
    });
  });
  // FIXME: copy from price-item-tabele, in order to be able to reuse <PriceItemEntry>
  const searchField = (
    <TrimTextField
      fullWidth
      label={<FormattedMessage defaultMessage="Søg småmaskiner" id="small-machines-search" />}
      margin="dense"
      onChange={setSearchString}
      value={searchString}
      variant="outlined"
    />
  );
  if (MOBILE) {
    return (
      <div>
        <Grid>
          <Cell>
            <Card>
              <CardContent>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={task.withoutSmallMachines}
                      onChange={handleWithoutSmallMachinesCheck}
                    />
                  }
                  disabled={
                    anySmallMachinesUsed ||
                    validated ||
                    (!userIsManager && (completed || userIsOtherMachineOperator))
                  }
                  label={formatMessage(messages.noSmallMachines)}
                />
              </CardContent>
            </Card>
          </Cell>
        </Grid>
        <Grid>
          <Cell size="12/12">
            <Card>
              <CardContent> {searchField}</CardContent>
            </Card>
            {priceitemRows}
          </Cell>
        </Grid>
      </div>
    );
  } else {
    const plusColumn = <TableCell style={BUTTON_COLUMN_STYLE} />;
    return (
      <div>
        <Grid style={{paddingLeft: "1em", paddingRight: "1em"}}>
          <Cell>
            <Card>
              <CardContent>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={task.withoutSmallMachines}
                      onChange={handleWithoutSmallMachinesCheck}
                    />
                  }
                  disabled={
                    anySmallMachinesUsed ||
                    validated ||
                    (!userIsManager && (completed || userIsOtherMachineOperator))
                  }
                  label={formatMessage(messages.noSmallMachines)}
                />
              </CardContent>
            </Card>
          </Cell>
        </Grid>
        <Grid style={{paddingLeft: "1em", paddingRight: "1em"}}>
          <Cell>
            <Card>
              <CardContent>{searchField}</CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tekst</TableCell>
                    <TableCell style={{width: COUNT_COLUMN_WIDTH}}>Antal</TableCell>
                    {plusColumn}
                    <TableCell style={{width: UNIT_COLUMN_WIDTH}}>Enhed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{priceitemRows}</TableBody>
              </Table>
            </Card>
          </Cell>
        </Grid>
      </div>
    );
  }
});
