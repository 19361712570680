import {CalculatorSpecification} from "@co-common-libs/resources";
import {DecimalField, ResponsiveDialog} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {DialogContent, IconButton} from "@material-ui/core";
import {useFalseCallback, useTrueCallback} from "app-utils";
import CalculatorVariantIcon from "mdi-react/CalculatorVariantIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

const CalculatorDialog = React.memo(function CalculatorDialog({
  onCancel,
  onOk,
  open,
  specification,
  unit,
}: {
  onCancel: () => void;
  onOk: (value: number) => void;
  open: boolean;
  specification: CalculatorSpecification;
  unit: string;
}): React.JSX.Element {
  const [value1, setValue1] = useState<number | null>(null);
  const [value2, setValue2] = useState<number | null>(null);

  let result: number | null = null;
  switch (specification.operation) {
    case "-":
      result = value1 != null && value2 != null ? value1 - value2 : null;
      break;
  }

  const handleOk = useCallback(() => {
    if (result != null) {
      onOk(result);
    }
  }, [onOk, result]);
  const customerSettings = useSelector(getCustomerSettings);
  return (
    <ResponsiveDialog
      okDisabled={!result}
      okLabel={<FormattedMessage defaultMessage="Indsæt" id="calucaltor-dialog.insert" />}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={<FormattedMessage defaultMessage="Beregn værdi" id="calucaltor-dialog.title" />}
    >
      <DialogContent>
        <DecimalField
          autoFocus
          decimalPlaces={customerSettings.materialDecimals}
          fullWidth
          label={specification.inputLabelA}
          margin="dense"
          maxDigits={9}
          onChange={setValue1}
          value={value1}
        />
        <DecimalField
          decimalPlaces={customerSettings.materialDecimals}
          fullWidth
          label={specification.inputLabelB}
          margin="dense"
          maxDigits={9}
          onChange={setValue2}
          value={value2}
        />
        <div style={{fontSize: 20, marginTop: 5}}>
          {specification.resultLabel}: {result} {result != null ? unit : null}
        </div>
      </DialogContent>
    </ResponsiveDialog>
  );
});

export const CalculatorButton = React.memo(function CalculatorButton({
  buttonStyle,
  disabled,
  onInsertValue,
  specification,
  unit,
}: {
  buttonStyle?: React.CSSProperties;
  disabled?: boolean;
  onInsertValue: (value: number) => void;
  specification: CalculatorSpecification;
  unit: string;
}): React.JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOk = useCallback(
    (value: number) => {
      setDialogOpen(false);
      onInsertValue(value);
    },
    [onInsertValue],
  );
  return (
    <>
      <IconButton
        color="primary"
        disabled={disabled || false}
        onClick={useTrueCallback(setDialogOpen, [setDialogOpen])}
        style={buttonStyle || {}}
      >
        <CalculatorVariantIcon />
      </IconButton>
      <CalculatorDialog
        onCancel={useFalseCallback(setDialogOpen, [setDialogOpen])}
        onOk={handleOk}
        open={dialogOpen}
        specification={specification}
        unit={unit}
      />
    </>
  );
});
