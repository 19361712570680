import {PatchOperation, WorkType} from "@co-common-libs/resources";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {actions, getWorkTypeArray} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {createWorkType} from "app-utils";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

export const WorkTypeCreateEditInternalDialog = React.memo(
  function WorkTypeCreateEditInternalDialog(props: {
    onCancel: () => void;
    onOk: (workTypeUrl: string) => void;
    open: boolean;
    workType?: WorkType | undefined;
  }): React.JSX.Element {
    const {onOk, open, workType} = props;
    const [name, setName] = useState(workType?.name || "");
    const [identifier, setIdentifier] = useState(workType?.identifier || "");

    const workTypeArray = useSelector(getWorkTypeArray);

    const workTypeNumberDuplicate: boolean = useMemo(() => {
      if (!identifier) {
        return false;
      } else {
        return workTypeArray.some(
          (group) => group.identifier === identifier && group.url !== workType?.url,
        );
      }
    }, [identifier, workType?.url, workTypeArray]);

    useEffect(() => {
      if (open) {
        setName(workType?.name || "");
        setIdentifier(workType?.identifier || "");
      }
    }, [open, workType?.identifier, workType?.name, workType?.salesAccount]);

    const intl = useIntl();

    const dispatch = useDispatch();
    const handleOk = useCallback(() => {
      if (workType) {
        const patch: PatchOperation<WorkType>[] = [];

        if (name !== workType.name) {
          patch.push({member: "name", value: name});
        }
        if (identifier !== workType.identifier) {
          patch.push({member: "identifier", value: identifier});
        }

        if (patch.length) {
          dispatch(actions.update(workType.url, patch));
        }
        onOk(workType.url);
      } else {
        const newWorkType = createWorkType({
          externalTaskPrimary: false,
          identifier,
          internalTaskPrimary: true,
          name,
        });

        dispatch(actions.create(newWorkType));
        onOk(newWorkType.url);
      }
    }, [workType, name, identifier, onOk, dispatch]);

    const workTypeNumberDuplicateError = workTypeNumberDuplicate
      ? intl.formatMessage({
          defaultMessage: "Der eksisterer allerede et område med det ID",
        })
      : undefined;

    return (
      <ResponsiveDialog
        okDisabled={!name || !identifier || workTypeNumberDuplicate}
        onCancel={props.onCancel}
        onOk={handleOk}
        open={open}
        title={
          props.workType ? (
            <FormattedMessage defaultMessage="Redigér internt område" />
          ) : (
            <FormattedMessage defaultMessage="Opret internt område" />
          )
        }
      >
        <DialogContent>
          <TrimTextField
            autoFocus={!props.workType}
            error={workTypeNumberDuplicate}
            fullWidth
            helperText={workTypeNumberDuplicateError}
            label={intl.formatMessage({
              defaultMessage: "ID *",
            })}
            margin="dense"
            onChange={setIdentifier}
            value={identifier}
            variant="outlined"
          />

          <TrimTextField
            autoFocus={!!props.workType}
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Navn *",
            })}
            margin="dense"
            onChange={setName}
            value={name}
            variant="outlined"
          />

          <FormattedMessage defaultMessage="* Skal udfyldes" tagName="div" />
        </DialogContent>
      </ResponsiveDialog>
    );
  },
);
