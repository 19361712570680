import {dateFromString} from "@co-common-libs/utils";
import {
  createStyles,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedTextFieldProps,
  TextField,
  TextFieldProps,
  Theme,
} from "@material-ui/core";
import {MuiPickersContext} from "@material-ui/pickers";
import bowser from "bowser";
import {format, formatISO, Locale, parseISO} from "date-fns";
import CalendarIcon from "mdi-react/CalendarIcon";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {DateDialog, DateDialogProps} from "../date-dialog";
import {useIsLandscapeOrientation} from "../use-is-landscape-orientation";
import {parseDateRelative} from "./parse-date";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    adornedEnd: {
      paddingRight: 0,
    },
  }),
);

interface CommonDateFieldProps {
  autoOk?: boolean;
  calendarStartDate?: string | null | undefined;
  maxDate?: string | null;
  minDate?: string | null;
  native?: boolean;
  onChange: (value: string | null) => void;
  onSelectedInDialog?: (value: string | null) => void;
  referenceDate?: string | undefined;
  showButton?: boolean;
  value?: string | null | undefined;
  yearSuggestions?: "DATE_AFTER" | "DATE_BEFORE" | "SAME";
}

// HACK: we've hardcoded variant="outlined" parameter to the TextField...
/*
type StandardDateFieldProps = Omit<
  StandardTextFieldProps,
  "onChange" | "inputRef"
> &
  CommonDateFieldProps;

type FilledDateFieldProps = Omit<
  FilledTextFieldProps,
  "onChange" | "inputRef"
> &
  CommonDateFieldProps;
*/
type OutlinedDateFieldProps = CommonDateFieldProps &
  Omit<OutlinedTextFieldProps, "inputRef" | "onChange" | "variant">;

export type DateFieldProps =
  // | StandardDateFieldProps
  // | FilledDateFieldProps
  OutlinedDateFieldProps;

export function DateField(props: DateFieldProps): React.JSX.Element {
  const {
    autoOk,
    calendarStartDate,
    InputProps: InputPropsFromProps,
    maxDate,
    minDate,
    native: nativeFromProps,
    onBlur,
    onChange,
    onFocus,
    onSelectedInDialog,
    referenceDate,
    showButton: showButtonFromProps,
    value: valueFromProps,
    yearSuggestions = "SAME",
    ...others
  } = props;

  const classes = useStyles();

  // for iOS/Android, we use <input type="date"> to show native picker;
  // prop override is for testing
  const native = nativeFromProps ?? (bowser.ios || bowser.android || false);
  const locale = useContext(MuiPickersContext)?.locale as Locale | undefined;

  const isLandscapeOrientation = useIsLandscapeOrientation();

  const localeOption = useMemo(() => (locale ? {locale} : undefined), [locale]);

  // value from prop -- ISO format expected; displayed text localised
  const dateFromProps = valueFromProps ? parseISO(valueFromProps) : undefined;
  const textFromProps = dateFromProps
    ? native
      ? valueFromProps
      : format(dateFromProps, "P", localeOption)
    : "";

  // value in local state while focused/while typing;
  // but "synced out" if resulting value changed
  const [text, setText] = useState(textFromProps);
  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newText = event.target.value;
      setText(newText);
      if (native) {
        const newValue =
          event.target.validity && event.target.validity.valid ? newText || null : null;
        if (newValue !== (valueFromProps ?? null)) {
          onChange(newValue);
        }
      } else {
        const referenceDateDate = referenceDate
          ? (dateFromString(referenceDate) as Date)
          : new Date();
        referenceDateDate.setHours(0, 0, 0, 0);
        const date = parseDateRelative(newText, referenceDateDate, yearSuggestions, locale);
        const newValue = date ? formatISO(date, {representation: "date"}) : null;
        if (newValue !== (valueFromProps ?? null)) {
          onChange(newValue);
        }
      }
    },
    [native, valueFromProps, onChange, referenceDate, yearSuggestions, locale],
  );

  const ref = useRef<HTMLInputElement>();
  // NTS: "clear" hack for iOS still required:
  // Where Android clears to "", iOS clears to defaultValue,
  // which React unhelpfully sets to the same as value...
  useEffect(() => {
    if (bowser.ios) {
      return () => {
        // intentionally accessing
        if (ref.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          ref.current.defaultValue = "";
        }
      };
    } else {
      return undefined;
    }
  });

  // track focus to display local state when focused;
  // copy current outer value into local state on gaining focus
  const [focused, setFocused] = useState(props.autoFocus || false);
  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      setFocused(true);
      setText(textFromProps);
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus, textFromProps],
  );
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      setFocused(false);
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur],
  );

  // dialog open state
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);
  const handleSelectedInDialog = useCallback(
    (date: Date | null) => {
      setDialogOpen(false);
      const newValue = date ? formatISO(date, {representation: "date"}) : null;
      const newText = date ? (native ? (newValue as string) : format(date, "P", localeOption)) : "";
      setText(newText);
      if (newValue !== (valueFromProps ?? null)) {
        if (onSelectedInDialog) {
          onSelectedInDialog(newValue);
        }
        onChange(newValue);
      }
    },
    [localeOption, native, onChange, onSelectedInDialog, valueFromProps],
  );

  // eslint-disable-next-line no-restricted-globals
  const enoughHeightForMUI = screen.availHeight >= 400;
  // eslint-disable-next-line no-restricted-globals
  const notEnoughWidthForNative = screen.availWidth <= 800;

  // TODO(Q2 2024 or later): check if native timepicker works in landscape mode on Android despite small width
  const useMUIOnNative =
    bowser.android && isLandscapeOrientation && enoughHeightForMUI && notEnoughWidthForNative;

  const showButton = showButtonFromProps ?? !(native && bowser.android);

  const InputProps = useMemo(() => {
    const InputPropsForButton = showButton
      ? {
          classes: {adornedEnd: classes.adornedEnd},
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="inherit"
                disabled={props.disabled || false}
                onClick={handleOpenDialog}
                tabIndex={-1}
              >
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          ),
        }
      : undefined;
    if (InputPropsFromProps && InputPropsForButton) {
      return {...InputPropsForButton, ...InputPropsFromProps};
    } else {
      return InputPropsForButton || InputPropsFromProps;
    }
  }, [InputPropsFromProps, handleOpenDialog, props.disabled, showButton, classes.adornedEnd]);

  const textFieldOptionalProps: Partial<TextFieldProps> = {};
  if (InputProps) {
    textFieldOptionalProps.InputProps = InputProps;
  }

  const dateDialogOptionalProps: Partial<DateDialogProps> = {};
  if (dateFromProps) {
    dateDialogOptionalProps.value = dateFromProps;
  }

  return (
    <>
      <TextField
        {...textFieldOptionalProps}
        style={{minWidth: 200}}
        type={native ? (useMUIOnNative ? "button" : "date") : "text"}
        {...others}
        inputRef={ref}
        onBlur={handleBlur}
        onChange={handleTextChange}
        onClick={useMUIOnNative ? handleOpenDialog : undefined}
        onFocus={handleFocus}
        value={focused ? text : textFromProps}
        variant="outlined"
      />
      <DateDialog
        autoOk={autoOk || false}
        initialFocusedDate={calendarStartDate || null}
        maxDate={maxDate || null}
        minDate={minDate || null}
        onCancel={handleCloseDialog}
        onOk={handleSelectedInDialog}
        open={dialogOpen}
        {...dateDialogOptionalProps}
      />
    </>
  );
}
