import {Config} from "@co-common-libs/config";
import {Culture, PatchUnion} from "@co-common-libs/resources";
import {TrimTextField} from "@co-frontend-libs/components";
import {Card, CardContent, CardHeader, FormControlLabel, Switch} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, IntlContext} from "react-intl";

const messages = defineMessages({
  active: {
    defaultMessage: "Aktiv",
    id: "culture-entry.label.active",
  },
  cultureCardTitle: {
    defaultMessage: "Kultur: {name}",
    id: "culture-instance.card-title.culture",
  },
  name: {
    defaultMessage: "Navn",
    id: "culture-entry.label.name",
  },
});

interface CultureDetailsCardProps {
  culture: Culture;
  customerSettings: Config;
  update: (url: string, patch: PatchUnion) => void;
}

export class CultureDetailsCard extends PureComponent<CultureDetailsCardProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleActiveChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const {culture, update} = this.props;
    update(culture.url, [{member: "active", value: checked}]);
  }
  @bind
  handleNameChange(value: string): void {
    const {culture, update} = this.props;
    update(culture.url, [{member: "name", value}]);
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {culture} = this.props;
    const {canEditCultures} = this.props.customerSettings;
    return (
      <Card>
        <CardHeader
          title={formatMessage(messages.cultureCardTitle, {
            name: culture.name,
          })}
        />
        <CardContent>
          <Grid>
            <Cell palm="12/12">
              {canEditCultures ? (
                <TrimTextField
                  fullWidth
                  id="culture-name-field"
                  label={formatMessage(messages.name)}
                  margin="dense"
                  name="name"
                  onChange={this.handleNameChange}
                  value={culture.name}
                  variant="outlined"
                />
              ) : null}
              <FormControlLabel
                control={<Switch checked={culture.active} onChange={this.handleActiveChange} />}
                label={formatMessage(messages.active)}
                labelPlacement="end"
              />
            </Cell>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}
