import {
  WorkshopChecklist as WorkshopChecklistResource,
  WorkshopChecklistUrl,
} from "@co-common-libs/resources";
import {actions} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {MenuToolbar, PageLayout} from "app-components";
import {instanceURL} from "frontend-global-config";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";
import {NewWorkshopChecklistDialog, WorkshopChecklist} from "./workshop-checklists";

interface WorkshopChecklistsSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const WorkshopChecklistsSettingsPage = React.memo(function WorkshopChecklistsSettingsPage(
  props: WorkshopChecklistsSettingsPageProps,
): React.JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [selectedWorkshopChecklistURL, setSelectedWorkshopChecklistURL] =
    useState<WorkshopChecklistUrl | null>(null);

  const [workshopChecklistDialogOpen, setWorkshopChecklistDialogOpen] = useState(false);
  const setWorkshopChecklistDialogOpenTrue = useCallWithTrue(setWorkshopChecklistDialogOpen);
  const setWorkshopChecklistDialogOpenFalse = useCallWithFalse(setWorkshopChecklistDialogOpen);

  const handleWorkshopChecklistDialogOk = useCallback(
    (name: string) => {
      setWorkshopChecklistDialogOpen(false);
      const id = uuid();
      const url = instanceURL("workshopChecklist", id);
      const data: WorkshopChecklistResource = {
        active: true,
        draft: true,
        id,
        machines: [],
        name,
        required: false,
        url,
      };
      dispatch(actions.create(data));
      setSelectedWorkshopChecklistURL(url);
    },
    [dispatch],
  );

  return (
    <PageLayout
      dialogs={
        <NewWorkshopChecklistDialog
          key="workshop-checklist-dialog"
          onCancel={setWorkshopChecklistDialogOpenFalse}
          onOk={handleWorkshopChecklistDialogOk}
          open={workshopChecklistDialogOpen}
        />
      }
      toolbar={
        <MenuToolbar
          onMenuButton={props.onMenuButton}
          title={intl.formatMessage({defaultMessage: "Værkstedstjeklister"})}
        />
      }
      withBottomScrollPadding
    >
      <WorkshopChecklist
        onAddButtonClick={setWorkshopChecklistDialogOpenTrue}
        selectedWorkshopChecklistURL={selectedWorkshopChecklistURL || undefined}
      />
    </PageLayout>
  );
});
