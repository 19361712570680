import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {UserCreateData, UserProfileData} from "./types";
import {UserCreateEditDialog} from "./user-create-edit-dialog";

interface UserCreateDialogProps {
  onCancel: () => void;
  onOk: (userData: UserCreateData, userProfileData: UserProfileData) => Promise<void>;
  open: boolean;
}

export const UserCreateDialog = React.memo(function UserCreateDialog(props: UserCreateDialogProps) {
  const {onCancel, onOk, open} = props;

  const intl = useIntl();

  // DO NOT COPY THIS APPROACH: onOk should never return a promise
  const handleOk = useCallback(
    (userData: UserCreateData, userProfileData: UserProfileData) => {
      return onOk(userData, userProfileData);
    },
    [onOk],
  );

  return (
    <UserCreateEditDialog
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      processingTitle={intl.formatMessage({
        defaultMessage: "Opretter medarbejder",
      })}
      title={intl.formatMessage({defaultMessage: "Opret medarbejder"})}
    />
  );
});
