import React from "react";
import {SettingValueDisplayProps} from "../types";

export function settingValueDisplayComponentWrapper<P>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: React.ComponentType<P>,
  props: React.PropsWithChildren<P>,
): (settingValueDisplayProps: SettingValueDisplayProps) => React.JSX.Element {
  function WrappedComponent(_props: SettingValueDisplayProps): React.ReactElement {
    return <Component {...props} />;
  }
  return WrappedComponent;
}
