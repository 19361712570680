import {Config} from "@co-common-libs/config";
import {
  buildCommonOptions,
  buildGroupOptions,
  getAbsenceHolidayCheckFunction,
  getDateHoursRatesFunction,
} from "@co-common-libs/payroll";
import {
  DaysAbsence,
  Task,
  TaskUrl,
  TimeCorrection,
  TimerStart,
  TimerUrl,
  User,
  UserProfile,
} from "@co-common-libs/resources";
import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import {
  computeIntervalsTruncated,
  computeWorkFromTo,
  getEmployeeRemunerationGroup,
  mergeIntervals,
} from "app-utils";
import React, {useCallback} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";

const messages = defineMessages({
  no: {
    defaultMessage: "Nej",
  },
  title: {
    defaultMessage: "Tilkaldeopgave",
  },
  yes: {
    defaultMessage: "Ja",
  },
});

export function getComputedTime(
  task: Task,
  timerStartArray: readonly TimerStart[],
  nowTimestamp: string,
): readonly {
  readonly fromTimestamp: string;
  readonly task?: TaskUrl;
  readonly timer: TimerUrl;
  readonly toTimestamp: string;
}[] {
  if (task.recordedInC5) {
    return task.computedTimeSet;
  } else {
    const taskURL = task.url;
    const taskTimerStartSeq = timerStartArray.filter((instance) => instance.task === taskURL);
    return computeIntervalsTruncated(taskTimerStartSeq, nowTimestamp);
  }
}

const timeStringToDateTime = (date: Date, timeString: string): Date => {
  const [hours, minutes] = timeString.split(":");
  const newTime = new Date(date);
  newTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);
  return newTime;
};

const adjustDateTimeMinutes = (date: Date, minutes: number): number =>
  date.setUTCMinutes(date.getUTCMinutes() + minutes);

export const showCalledInDialog = ({
  customerSettings,
  daysAbsenceArray,
  machineOperator,
  machineOperatorProfile,
  machineOperatorTimeCorrectionSet,
  task,
  timerStartArray,
}: {
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  machineOperator: User;
  machineOperatorProfile: UserProfile;
  machineOperatorTimeCorrectionSet?: readonly TimeCorrection[];
  task: Task;
  timerStartArray: readonly TimerStart[];
}): boolean => {
  const {remunerationGroups, showCalledInDialogAfterMinutes} = customerSettings;
  if (showCalledInDialogAfterMinutes == null) {
    return false;
  }

  const calledInAnswered = task.calledIn != null;
  if (calledInAnswered) {
    return false;
  }
  const now = new Date();

  const future = new Date(now);
  future.setTime(now.getTime() + MINUTE_MILLISECONDS);
  const computedIntervals = getComputedTime(task, timerStartArray, future.toISOString());
  const correctionIntervals =
    machineOperatorTimeCorrectionSet || task.machineOperatorTimeCorrectionSet || [];
  const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];

  const intervals = mergeIntervals(
    computedIntervals,
    correctionIntervals,
    managerCorrectionIntervals,
    now.toISOString(),
  );
  const {workFromTimestamp} = computeWorkFromTo(intervals);
  if (!workFromTimestamp) {
    return false;
  }

  const machineOperatorURL = machineOperator.url;
  const daysAbsenceList = daysAbsenceArray.filter((absence) => absence.user === machineOperatorURL);

  const groupID = getEmployeeRemunerationGroup(customerSettings, machineOperatorProfile);
  const groupSettingsMap = remunerationGroups[groupID] || {};
  const groupSettings = buildGroupOptions(groupSettingsMap, customerSettings, {}, {}, {}, []);
  const checkHoliday = getAbsenceHolidayCheckFunction(
    daysAbsenceList,
    groupSettings,
    buildCommonOptions(customerSettings, {}),
  );
  const {halfHolidayHoursRates, halfHolidaySundayAfterNoon, hoursRates} = groupSettings;

  const getDateHoursRates = getDateHoursRatesFunction(
    hoursRates,
    checkHoliday,
    halfHolidaySundayAfterNoon,
    halfHolidayHoursRates || undefined,
  );

  const taskStartTime = new Date(workFromTimestamp);
  const normalHours = getDateHoursRates(taskStartTime).filter((rate) => rate[2] === 0);

  let showDialog = true;
  normalHours.forEach((period) => {
    const [startTime, endTime] = period;
    const start = adjustDateTimeMinutes(
      timeStringToDateTime(taskStartTime, startTime),
      -showCalledInDialogAfterMinutes,
    );
    const end = adjustDateTimeMinutes(
      timeStringToDateTime(taskStartTime, endTime),
      showCalledInDialogAfterMinutes,
    );
    const startTimestamp = taskStartTime.getTime();
    if (start <= startTimestamp && startTimestamp <= end) {
      showDialog = false;
    }
  });

  return showDialog;
};

interface CalledInDialogProps {
  onAnswer: (value: boolean) => void;
  open: boolean;
}

export const CalledInDialog = React.memo(function CalledInDialog(
  props: CalledInDialogProps,
): React.JSX.Element {
  const {onAnswer, open} = props;
  const intl = useIntl();
  return (
    <ResponsiveDialog
      cancelLabel={intl.formatMessage(messages.no)}
      okLabel={intl.formatMessage(messages.yes)}
      onCancel={useCallback(() => {
        onAnswer(false);
      }, [onAnswer])}
      onOk={useCallback(() => {
        onAnswer(true);
      }, [onAnswer])}
      open={open}
      title={intl.formatMessage(messages.title)}
    >
      <DialogContent>
        <FormattedMessage
          defaultMessage="Er dette en tilkaldeopgave?"
          id="called-in-dialog.label.text"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
