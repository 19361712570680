import {getCustomerSettings, getWorkTypeArray} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";

export function DisplayWorkTypePrimaryTimerLabels(
  _props: SettingValueDisplayProps,
): React.JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  // eslint-disable-next-line prefer-destructuring
  const workTypeGenericPrimaryTimerLabel: {
    [worktypeIdentifier: string]: string | undefined;
  } = customerSettings.workTypeGenericPrimaryTimerLabel;
  const workTypeArray = useSelector(getWorkTypeArray);
  return (
    <div>
      <i>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage defaultMessage="Område" id="system-setup.absence-type" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Label" id="system-setup.label" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(workTypeGenericPrimaryTimerLabel).map(([worktypeIdentifier, label]) => {
              const workType = workTypeArray.find((w) => w.identifier === worktypeIdentifier);
              return (
                <TableRow key={worktypeIdentifier}>
                  <TableCell>
                    {worktypeIdentifier} {workType?.name}
                  </TableCell>
                  <TableCell>{label}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </i>
    </div>
  );
}
