import {Task, urlToId} from "@co-common-libs/resources";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {colorMap} from "@co-frontend-libs/utils";
import {common as commonColors} from "@material-ui/core/colors";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import ImmutableDate from "bloody-immutable-date";
import React from "react";
import {FormattedMessage} from "react-intl";
import {TASK_FRONT_Z_INDEX, TASK_WIDTH, TRACKED_TASK_LEFT} from "./constants";
import {calculateYPosition} from "./utils";

interface CalendarEstimateBlockProps {
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  fromTimestamp?: ImmutableDate;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  task: Task;
  toTimestamp?: ImmutableDate;
}

export class CalendarEstimateBlock extends PureComponent<CalendarEstimateBlockProps> {
  @bind
  handleGoToTask(): void {
    this.props.go("/task/:id", {id: urlToId(this.props.task.url)});
  }
  render(): React.JSX.Element {
    const {calendarFromTimestamp, calendarToTimestamp, fromTimestamp, toTimestamp} = this.props;

    let startY;
    if (fromTimestamp) {
      startY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, fromTimestamp);
    }

    let endY;
    if (toTimestamp) {
      endY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, toTimestamp);
    }

    const containerStyle: React.CSSProperties = {
      backgroundColor: commonColors.white,
      cursor: "pointer",
      left: TRACKED_TASK_LEFT,
      overflow: "hidden",
      position: "absolute",
      width: TASK_WIDTH,
      zIndex: TASK_FRONT_Z_INDEX,
    };
    if (startY != null) {
      containerStyle.top = startY;
    }
    if (endY != null && startY != null) {
      containerStyle.height = endY - startY;
    }

    const style: React.CSSProperties = {
      backgroundColor: colorMap.CALENDAR_TASK_ESTIMATE,
      borderColor: commonColors.black,
      borderStyle: "solid",
      borderWidth: "1px 1px 1px 1px",
      height: "100%",
      paddingLeft: 2,
      width: "100%",
    };
    const contentText: React.CSSProperties = {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "14px",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    };
    return (
      <div onClick={this.handleGoToTask} style={containerStyle}>
        <div style={style}>
          <div style={contentText}>
            <FormattedMessage
              defaultMessage="Forventet"
              id="calender-estimate-block.label.estimated"
            />
          </div>
        </div>
      </div>
    );
  }
}
