import {Query} from "@co-frontend-libs/db-resources";
import {
  getCommitQueue,
  getCurrentRole,
  getCustomerSettings,
  getSyncedState,
} from "@co-frontend-libs/redux";
import {NetworkError, StatusError} from "@co-frontend-libs/utils";
import {useTheme} from "@material-ui/core";
import React from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  fetchError: {
    defaultMessage:
      "Kunne ikke hente opgaver. Prøv igen, og hvis problemet består, kontakt support",
    id: "economic-sync.label.fetch-error",
  },
  fetching: {
    defaultMessage: "Henter opgaver",
    id: "economic-sync.label.fetching",
  },
});

export const EconomicUploadStatus = ({
  query,
  uploadError,
}: {
  query: Query | null;
  uploadError: string | Error | null;
}): React.JSX.Element => {
  const customerSettings = useSelector(getCustomerSettings);
  const taskSyncedState = useSelector(getSyncedState.bind(null, "task"));
  const {formatMessage} = useIntl();
  const commitQueue = useSelector(getCommitQueue);
  const theme = useTheme();
  const currentRole = useSelector(getCurrentRole);
  const count = commitQueue.filter(
    ({status}) =>
      status === "PROMISE" ||
      status === "UNSAVED" ||
      status === "SAVING_LOCALLY" ||
      status === "SAVED_LOCALLY",
  ).length;

  const {economicProjectsMachineHoursActivity, economicProjectsManHoursActivity} = customerSettings;

  let fetchMessage;

  if (economicProjectsManHoursActivity != null || economicProjectsMachineHoursActivity != null) {
    let onlineWaiting = false;
    let onlineError = false;
    if (query) {
      const querySyncedState = taskSyncedState.get(query.keyString);
      if (!querySyncedState || querySyncedState.queryState.currentlyFullFetching) {
        onlineWaiting = true;
      } else {
        const lastTimestamp = querySyncedState.queryState
          .fullFetchDataComputedAtTimestamp as string;
        const {lastErrorTimestamp} = querySyncedState.queryState;
        if (lastErrorTimestamp && lastErrorTimestamp > lastTimestamp) {
          onlineError = true;
        }
      }
    }

    if (onlineWaiting) {
      fetchMessage = formatMessage(messages.fetching);
    } else if (onlineError) {
      fetchMessage = formatMessage(messages.fetchError);
    }
  }

  let uploadErrorBlock;
  let consultantUploadErrorBlock;
  if (uploadError) {
    if (uploadError instanceof NetworkError) {
      uploadErrorBlock = (
        <div style={{color: theme.palette.error.main}}>
          <FormattedMessage
            defaultMessage="Der er sket en fejl ved overførslen til e-conomic: Kunne ikke forbinde til serveren."
            id="economic-sync.label.upload-offline"
          />
        </div>
      );
    } else if (uploadError instanceof StatusError && uploadError.status === 403) {
      uploadErrorBlock = (
        <div style={{color: theme.palette.warning.main}}>
          <FormattedMessage defaultMessage="Adgang nægtet: Du har ikke de nødvendige rettigheder til at udføre denne handling. Det er sandsynligt, at jeres e-conomic-token er udløbet eller blevet ændret." />
        </div>
      );
    } else {
      uploadErrorBlock = (
        <div style={{color: theme.palette.error.main}}>
          <FormattedMessage
            defaultMessage="Der er sket en fejl ved overførslen til e-conomic - der er sendt besked til support og de vender tilbage hurtigst muligt."
            id="economic-sync.label.upload-error"
            tagName="div"
          />
        </div>
      );
    }
    if (currentRole?.consultant && typeof uploadError === "string") {
      consultantUploadErrorBlock = (
        <div style={{backgroundColor: theme.palette.consultant.main}}>{uploadError}</div>
      );
    }
  }

  return (
    <>
      {uploadErrorBlock}
      {count > 0 ? (
        <FormattedMessage
          defaultMessage="{count} emner venter på synkronisering. Du kan ikke overføre til e-conomic før de er synkroniseret."
          id="econ-upload-button.label.sync-warning"
          tagName="div"
          values={{count}}
        />
      ) : null}
      <div>{fetchMessage}</div>
      {consultantUploadErrorBlock}
    </>
  );
};
