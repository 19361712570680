import {getCustomerSettings} from "@co-frontend-libs/redux";
import {CircularProgress} from "@material-ui/core";
import {GoogleMap, useDeviceConfig} from "app-utils";
import FullscreenExitIcon from "mdi-react/FullscreenExitIcon";
import MapMarkerOffIcon from "mdi-react/MapMarkerOffIcon";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {CurrentLocationButton} from "../geolocation-map/current-location-button";
import {CurrentLocationMarker} from "../geolocation-map/current-location-marker";
import {MapButton} from "../geolocation-map/map-button";
import {MapMarker} from "../map-marker";

interface LocationMapProps {
  centerLatitude: number;
  centerLongitude: number;
  latitude: number | null;
  longitude: number | null;
  mapLoaded: boolean;
  onClose: () => void;
  onLocationChange: (latitude: number | null, longitude: number | null) => void;
}

export function LocationMap(props: LocationMapProps): React.JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  const {
    centerLatitude,
    centerLongitude,
    latitude,
    longitude,
    mapLoaded,
    onClose,
    onLocationChange,
  } = props;

  const zoom = customerSettings.geolocation.initialZoom;

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const [mapTypeId, setMapTypeId] = useDeviceConfig<string>(
    "createEditLocationMapTypeId",
    "roadmap",
  );

  const handleClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        onLocationChange(event.latLng.lat(), event.latLng.lng());
      }
    },
    [onLocationChange],
  );

  const handleMapTypeIdChanged = useCallback(() => {
    const id = mapInstance?.getMapTypeId();
    if (id) {
      setMapTypeId(id);
    }
  }, [mapInstance, setMapTypeId]);

  const handleLoad = useCallback(
    (map: google.maps.Map) => {
      map.setCenter({lat: centerLatitude, lng: centerLongitude});
      map.setZoom(zoom);
      map.setMapTypeId(mapTypeId);
      setMapInstance(map);
    },
    [centerLatitude, centerLongitude, mapTypeId, zoom],
  );

  useEffect(() => {
    if (mapInstance) {
      const listener = mapInstance.addListener("click", handleClick);
      return () => {
        listener.remove();
      };
    }
    return undefined;
  }, [handleClick, mapInstance]);

  useEffect(() => {
    if (mapInstance) {
      const listeren = mapInstance.addListener("maptypeid_changed", handleMapTypeIdChanged);
      return () => {
        listeren.remove();
      };
    } else {
      return undefined;
    }
  }, [handleMapTypeIdChanged, mapInstance]);

  useEffect(() => {
    if (mapInstance && centerLongitude && centerLongitude) {
      mapInstance.setCenter({lat: centerLatitude, lng: centerLongitude});
    }
  }, [centerLatitude, centerLongitude, mapInstance]);

  const handleFullscreenChange = useCallback(() => {
    if (!mapInstance || !centerLatitude || !centerLongitude) {
      return;
    }
    mapInstance.setCenter({
      lat: latitude || centerLatitude,
      lng: longitude || centerLongitude,
    });
  }, [centerLatitude, centerLongitude, latitude, longitude, mapInstance]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [handleFullscreenChange]);

  const handleRemoveMarkerButtonClick = useCallback(() => {
    onLocationChange(null, null);
  }, [onLocationChange]);

  if (mapLoaded) {
    const removeMarkerDisabled = !longitude || !latitude;
    return (
      <GoogleMap
        id="map"
        mapContainerStyle={{height: "100%", width: "100%"}}
        onLoad={handleLoad}
        options={{
          clickableIcons: false,
          fullscreenControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: ["hybrid", "roadmap", "satellite"],
          },
          streetViewControl: false,
          styles: [
            {featureType: "poi", stylers: [{visibility: "off"}]},
            {featureType: "transit", stylers: [{visibility: "off"}]},
          ],
        }}
      >
        {mapInstance ? (
          <>
            <div style={{position: "absolute", right: 0}}>
              <div>
                <MapButton onClick={onClose} variant="contained">
                  <FullscreenExitIcon />
                </MapButton>
              </div>
              <CurrentLocationButton googleMap={mapInstance} />
              <div>
                <MapButton
                  disabled={removeMarkerDisabled}
                  onClick={handleRemoveMarkerButtonClick}
                  variant="contained"
                >
                  <MapMarkerOffIcon />
                </MapButton>
              </div>
            </div>

            {latitude != null && longitude != null ? (
              <MapMarker map={mapInstance} position={{lat: latitude, lng: longitude}} />
            ) : null}
            <CurrentLocationMarker map={mapInstance} />
          </>
        ) : null}
      </GoogleMap>
    );
  } else {
    return (
      <div style={{marginTop: 16, textAlign: "center"}}>
        <CircularProgress />
      </div>
    );
  }
}
