import {formatDate} from "@co-common-libs/utils";
import {actions, AppState, PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Checkbox, IconButton, TableCell, TableRow} from "@material-ui/core";
import {bind} from "bind-decorator";
import ClipboardTextIcon from "mdi-react/ClipboardTextIcon";
import React from "react";
import {connect} from "react-redux";
import {
  CHECKBOX_COLUMN_WIDTH,
  DATE_COLUMN_WIDTH,
  EMPLOYEE_ALIAS_COLUMN_WIDTH,
  TASK_LINK_COLUMN_WIDTH,
} from "./constants";
import {LogTaskData} from "./types";

interface LogTableRowOwnProps extends LogTaskData {
  checked: boolean;
  onCheck: (id: string, checked: boolean) => void;
}

interface LogTableRowDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
}

type LogTableRowProps = LogTableRowDispatchProps & LogTableRowOwnProps;

class LogTableRow extends React.PureComponent<LogTableRowProps> {
  @bind
  handleCheckboxCheck(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.props.onCheck(this.props.id, checked);
  }
  @bind
  handleGoToTaskClick(): void {
    const {go, id} = this.props;
    go("/task/:id", {id});
  }
  render(): React.JSX.Element {
    const {checked, customerName, date, employeeAlias, id, logTitle} = this.props;
    return (
      <TableRow key={id}>
        <TableCell style={{width: CHECKBOX_COLUMN_WIDTH}}>
          <Checkbox
            checked={checked}
            id={id}
            name={`checkbox-${id}`}
            onChange={this.handleCheckboxCheck}
          />
        </TableCell>
        <TableCell>{logTitle}</TableCell>
        <TableCell>{customerName}</TableCell>
        <TableCell style={{width: EMPLOYEE_ALIAS_COLUMN_WIDTH}}>{employeeAlias}</TableCell>
        <TableCell style={{width: DATE_COLUMN_WIDTH}}>{formatDate(date)}</TableCell>
        <TableCell style={{padding: 0, width: TASK_LINK_COLUMN_WIDTH}}>
          <IconButton onClick={this.handleGoToTaskClick}>
            <ClipboardTextIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

const ConnectedLogTableRow: React.ComponentType<LogTableRowOwnProps> = connect<
  object,
  LogTableRowDispatchProps,
  LogTableRowOwnProps,
  AppState
>(null, {go: actions.go})(LogTableRow);

export {ConnectedLogTableRow as LogTableRow};
