import {Config} from "@co-common-libs/config";
import {formatDateTime, SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {AppState, getCustomerSettings} from "@co-frontend-libs/redux";
import {jsonFetch, NetworkError} from "@co-frontend-libs/utils";
import {Button, SvgIcon} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {globalConfig} from "frontend-global-config";
import CloudDownloadIcon from "mdi-react/CloudDownloadIcon";
import CloudUploadIcon from "mdi-react/CloudUploadIcon";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  downloadFromBrugerdata: {
    defaultMessage: "Hent data fra Brugerdata",
    id: "brugerdata-sync.label.download-from-brugerdata",
  },
  includeContacts: {
    defaultMessage: "Inkludér kontakter",
    id: "brugerdata-sync.label.include-contacts",
  },
  pageTitle: {
    defaultMessage: "Brugerdata Sync",
    id: "brugerdata-sync.title.brugerdata-sync",
  },
  uploadToBrugerdata: {
    defaultMessage: "Overfør til Brugerdata",
    id: "brugerdata-sync.label.upload-to-brugerdata",
  },
});

const CHECK_SECONDS = 5;
const CHECK_MILLISECONDS = CHECK_SECONDS * SECOND_MILLISECONDS;

interface BrugerdataSyncStateProps {
  customerSettings: Config;
}

interface BrugerdataSyncOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type BrugerdataSyncProps = BrugerdataSyncOwnProps & BrugerdataSyncStateProps;

interface BrugerdataSyncState {
  downloadDoneTimestamp: string | null;
  downloadError: Error | null;
  downloading: boolean;
  downloadStartTimestamp: string | null;
  syncContacts: boolean;
  uploadDoneTimestamp: string | null;
  uploadError: Error | null;
  uploading: boolean;
  uploadStartTimestamp: string | null;
}

class BrugerdataSync extends PureComponent<BrugerdataSyncProps, BrugerdataSyncState> {
  static contextType = IntlContext;
  _checking = true;
  _downloadCheckTimeoutID: number | null = null;
  _uploadCheckTimeoutID: number | null = null;
  context!: React.ContextType<typeof IntlContext>;
  state: BrugerdataSyncState = {
    downloadDoneTimestamp: null,
    downloadError: null,
    downloading: false,
    downloadStartTimestamp: null,
    syncContacts: false,
    uploadDoneTimestamp: null,
    uploadError: null,
    uploading: false,
    uploadStartTimestamp: null,
  };
  componentDidMount(): void {
    this._checking = true;
    this.handleCheckUpload();
    this.handleCheckDownload();
  }
  componentWillUnmount(): void {
    this._checking = false;
    if (this._uploadCheckTimeoutID) {
      window.clearTimeout(this._uploadCheckTimeoutID);
    }
    if (this._downloadCheckTimeoutID) {
      window.clearTimeout(this._downloadCheckTimeoutID);
    }
  }
  @bind
  handleCheckDownload(): void {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}brugerdata/request_download/`;
    jsonFetch(url, "GET")
      .then((response) => {
        if (!this._checking) {
          return;
        }
        const {data} = response;
        const {doneTimestamp, error, startTimestamp} = data;
        this.setState({
          downloadDoneTimestamp: doneTimestamp,
          downloadError: error,
          downloading: !!startTimestamp && !doneTimestamp,
          downloadStartTimestamp: startTimestamp,
        });
        return;
      })
      .catch((reason) => {
        if (!this._checking) {
          return;
        }
        this.setState({
          downloadDoneTimestamp: null,
          downloadError: reason,
          downloading: false,
          downloadStartTimestamp: null,
        });
        return;
      })
      .then(() => {
        if (this._checking) {
          this._downloadCheckTimeoutID = window.setTimeout(
            this.handleCheckDownload,
            CHECK_MILLISECONDS,
          );
        }
        return;
      })
      .catch(() => {
        // impossible
      });
  }
  @bind
  handleCheckUpload(): void {
    if (!this._checking) {
      return;
    }
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}brugerdata/request_upload/`;
    jsonFetch(url, "GET")
      .then((response) => {
        if (!this._checking) {
          return;
        }
        const {data} = response;
        const {doneTimestamp, error, startTimestamp} = data;
        this.setState({
          uploadDoneTimestamp: doneTimestamp,
          uploadError: error,
          uploading: !!startTimestamp && !doneTimestamp,
          uploadStartTimestamp: startTimestamp,
        });
        return;
      })
      .catch((reason) => {
        if (!this._checking) {
          return;
        }
        this.setState({
          uploadDoneTimestamp: null,
          uploadError: reason,
          uploading: false,
          uploadStartTimestamp: null,
        });
        return;
      })
      .then(() => {
        if (this._checking) {
          this._uploadCheckTimeoutID = window.setTimeout(
            this.handleCheckUpload,
            CHECK_MILLISECONDS,
          );
        }
        return;
      })
      .catch(() => {
        // impossible
      });
  }
  @bind
  handleDownloadFromBrugerdata(): void {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}brugerdata/request_download/`;
    this.setState({downloadError: null, downloading: true});
    const {syncContacts} = this.state;
    jsonFetch(url, "POST", {syncContacts})
      .then((response) => {
        if (!this._checking) {
          return;
        }
        const {data} = response;
        const {doneTimestamp, startTimestamp} = data;
        this.setState({
          downloadError: null,
          downloading: !!startTimestamp && !doneTimestamp,
          downloadStartTimestamp: startTimestamp,
          uploadDoneTimestamp: doneTimestamp,
        });
        return;
      })
      .catch((reason) => {
        if (!this._checking) {
          return;
        }
        this.setState({
          downloadDoneTimestamp: null,
          downloadError: reason,
          downloading: false,
          downloadStartTimestamp: null,
        });
      });
  }
  @bind
  handleUploadToBrugerdata(): void {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}brugerdata/request_upload/`;
    this.setState({uploadError: null, uploading: true});
    jsonFetch(url, "POST")
      .then((response) => {
        if (!this._checking) {
          return;
        }
        const {data} = response;
        const {doneTimestamp, error, startTimestamp} = data;
        this.setState({
          uploadDoneTimestamp: doneTimestamp,
          uploadError: error,
          uploading: !!startTimestamp && !doneTimestamp,
          uploadStartTimestamp: startTimestamp,
        });
        return;
      })
      .catch((reason) => {
        if (!this._checking) {
          return;
        }
        this.setState({
          uploadDoneTimestamp: null,
          uploadError: reason,
          uploading: false,
          uploadStartTimestamp: null,
        });
      });
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      downloadDoneTimestamp,
      downloadError,
      downloading,
      downloadStartTimestamp,
      uploadDoneTimestamp,
      uploadError,
      uploading,
      uploadStartTimestamp,
    } = this.state;
    const uploadIconClassName = uploading ? "pulse" : "";
    const uploadIcon = (
      <SvgIcon className={uploadIconClassName}>
        <CloudUploadIcon />
      </SvgIcon>
    );
    const downloadIconClassName = downloading ? "pulse" : "";
    const downloadIcon = (
      <SvgIcon className={downloadIconClassName}>
        <CloudDownloadIcon />
      </SvgIcon>
    );
    let uploadErrorBlock;
    if (uploadError) {
      if (uploadError instanceof NetworkError) {
        uploadErrorBlock = (
          <FormattedMessage
            defaultMessage="Fejl ved upload: Kunne ikke forbinde til server."
            id="brugerdata-sync.label.upload-offline"
            tagName="div"
          />
        );
      } else if (typeof uploadError === "string") {
        uploadErrorBlock = (
          <FormattedMessage
            defaultMessage="Fejl ved upload: {error}"
            id="brugerdata-sync.label.upload-error-string"
            tagName="div"
            values={{error: uploadError}}
          />
        );
      } else {
        uploadErrorBlock = (
          <FormattedMessage
            defaultMessage="Fejl ved upload."
            id="brugerdata-sync.label.upload-error"
            tagName="div"
          />
        );
      }
    }
    let downloadErrorBlock;
    if (downloadError) {
      if (downloadError instanceof NetworkError) {
        downloadErrorBlock = (
          <FormattedMessage
            defaultMessage="Fejl ved download: Kunne ikke forbinde til server."
            id="brugerdata-sync.label.download-offline"
            tagName="div"
          />
        );
      } else if (typeof downloadError === "string") {
        downloadErrorBlock = (
          <FormattedMessage
            defaultMessage="Fejl ved download: {error}."
            id="brugerdata-sync.label.download-error-string"
            tagName="div"
            values={{error: downloadError}}
          />
        );
      } else {
        downloadErrorBlock = (
          <FormattedMessage
            defaultMessage="Fejl ved download."
            id="brugerdata-sync.label.download-error"
            tagName="div"
          />
        );
      }
    }
    let downloadStatusBlock;
    if (downloadStartTimestamp) {
      if (downloadDoneTimestamp) {
        downloadStatusBlock = (
          <FormattedMessage
            defaultMessage="Sidste download startet: {start}, fuldført: {done}"
            id="brugerdata-sync.label.download-start-done"
            tagName="div"
            values={{
              done: formatDateTime(downloadDoneTimestamp),
              start: formatDateTime(downloadStartTimestamp),
            }}
          />
        );
      } else {
        downloadStatusBlock = (
          <FormattedMessage
            defaultMessage="Sidste download startet: {start}"
            id="brugerdata-sync.label.download-start"
            tagName="div"
            values={{start: formatDateTime(downloadStartTimestamp)}}
          />
        );
      }
    }
    let uploadStatusBlock;
    if (uploadStartTimestamp) {
      if (uploadDoneTimestamp) {
        uploadStatusBlock = (
          <FormattedMessage
            defaultMessage="Sidste upload startet: {start}, fuldført: {done}"
            id="brugerdata-sync.label.upload-start-done"
            tagName="div"
            values={{
              done: formatDateTime(uploadDoneTimestamp),
              start: formatDateTime(uploadStartTimestamp),
            }}
          />
        );
      } else {
        uploadStatusBlock = (
          <FormattedMessage
            defaultMessage="Sidste upload startet: {start}"
            id="brugerdata-sync.label.upload-start"
            tagName="div"
            values={{start: formatDateTime(uploadStartTimestamp)}}
          />
        );
      }
    }

    let uploadSyncBlock;
    if (
      !this.props.customerSettings.brugerdataSyncOnCompleted &&
      !this.props.customerSettings.brugerdataSyncOnValidated
    ) {
      uploadSyncBlock = (
        <div style={{textAlign: "center"}}>
          <Button
            color="primary"
            disabled={uploading}
            onClick={this.handleUploadToBrugerdata}
            startIcon={uploadIcon}
            style={{minWidth: 260, width: 260}}
            variant="contained"
          >
            {formatMessage(messages.uploadToBrugerdata)}
          </Button>
          {uploadStatusBlock}
          {uploadErrorBlock}
        </div>
      );
    }
    return (
      <PageLayout
        toolbar={
          <MenuToolbar
            onMenuButton={this.props.onMenuButton}
            title={formatMessage(messages.pageTitle)}
          />
        }
        withPadding
      >
        <div style={{marginBottom: 24, textAlign: "center"}}>
          <Button
            color="primary"
            disabled={downloading}
            onClick={this.handleDownloadFromBrugerdata}
            startIcon={downloadIcon}
            style={{minWidth: 260, width: 260}}
            variant="contained"
          >
            {formatMessage(messages.downloadFromBrugerdata)}
          </Button>
          {downloadStatusBlock}
          {downloadErrorBlock}
        </div>
        {uploadSyncBlock}
      </PageLayout>
    );
  }
}

const ConnectedBrugerdataSync: React.ComponentType<BrugerdataSyncOwnProps> = connect<
  BrugerdataSyncStateProps,
  object,
  BrugerdataSyncOwnProps,
  AppState
>(
  createStructuredSelector<AppState, BrugerdataSyncStateProps>({
    customerSettings: getCustomerSettings,
  }),
  {},
)(BrugerdataSync);

export default ConnectedBrugerdataSync;
