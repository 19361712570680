import {PartialNavigationKind} from "@co-frontend-libs/routing-sync-history";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";

const SCROLLBAR_COMPENSATION = 24;

interface FixedTopProps {
  children: React.ReactNode;
  height: number;
  leftWidth: number;
  width: number;
}

const FixedTop = (props: FixedTopProps, ref: React.Ref<HTMLDivElement>): React.JSX.Element => {
  const {children, height, leftWidth, width} = props;
  const outerStyle = {
    marginLeft: leftWidth,
    overflow: "hidden",
    width: `calc(100% - ${leftWidth}px)`,
  };
  const innerStyle = {
    height,
    overflow: "hidden",
    width: width + SCROLLBAR_COMPENSATION,
  };
  return (
    <div ref={ref} style={outerStyle}>
      <div style={innerStyle}>{children}</div>
    </div>
  );
};

const FixedTopWithForwardRef = React.forwardRef<HTMLDivElement, FixedTopProps>(FixedTop);

interface MainProps {
  children: React.ReactNode;
  topHeight: number;
}

const Main = (props: MainProps): React.JSX.Element => {
  const {children, topHeight} = props;
  const style = {
    height: `calc(100% - ${topHeight}px`,
    overflow: "hidden",
    width: "100%",
  };
  return <div style={style}>{children}</div>;
};

interface FixedLeftProps {
  children: React.ReactNode;
  height: number;
  width: number;
}

const FixedLeft = (props: FixedLeftProps, ref: React.Ref<HTMLDivElement>): React.JSX.Element => {
  const {children, height, width} = props;
  const outerStyle = {
    display: "inline-block",
    height: "100%",
    overflow: "hidden",
    width,
  };
  const innerStyle = {
    height: height + SCROLLBAR_COMPENSATION,
    padding: 0,
  };
  return (
    <div ref={ref} style={outerStyle}>
      <div style={innerStyle}>{children}</div>
    </div>
  );
};

const FixedLeftWithForwardRef = React.forwardRef<HTMLDivElement, FixedLeftProps>(FixedLeft);

interface ContentProps {
  children: React.ReactNode;
  height: number;
  leftWidth: number;
  width: number;
}

const Content = (props: ContentProps, ref: React.Ref<HTMLDivElement>): React.JSX.Element => {
  const {children, height, leftWidth, width} = props;
  const outerStyle = {
    display: "inline-block",
    height: "100%",
    overflow: "auto",
    width: `calc(100% - ${leftWidth}px)`,
  };
  const innerStyle = {
    height,
    width,
  };
  return (
    <div className="scrollable" ref={ref} style={outerStyle}>
      <div style={innerStyle}>{children}</div>
    </div>
  );
};

const ContentWithForwardRef = React.forwardRef<HTMLDivElement, ContentProps>(Content);

interface ScrollLayoutProps {
  children: React.ReactNode;
  contentHeight: number;
  contentWidth: number;
  headerContent: React.ReactNode;
  initialScrollLeft?: number;
  initialScrollTop?: number;
  leftContent: React.ReactNode;
  leftWidth: number;
  putQueryKeys: (
    update: {readonly [key: string]: string | undefined},
    navigationKind?: PartialNavigationKind,
  ) => void;
  scrollX: string;
  scrollY: string;
  topHeight: number;
}

class ScrollLayout extends PureComponent<ScrollLayoutProps> {
  private _header = React.createRef<HTMLDivElement>();
  private _leftColumn = React.createRef<HTMLDivElement>();
  private _scrollContainer = React.createRef<HTMLDivElement>();
  private updateScrollQuery: _.DebouncedFunc<(left: number, top: number) => void>;
  constructor(props: ScrollLayoutProps) {
    super(props);
    this.updateScrollQuery = _.debounce((left, top) => {
      const scrollX = `${left}`;
      const scrollY = `${top}`;
      this.props.putQueryKeys({
        scrollX,
        scrollY,
      });
    }, 200);
  }
  componentDidMount(): void {
    const {initialScrollLeft, initialScrollTop} = this.props;
    const scrollNode = this._scrollContainer.current;
    if (scrollNode) {
      scrollNode.addEventListener("scroll", this.handleScroll);
      if (initialScrollLeft) {
        scrollNode.scrollLeft = initialScrollLeft;
      }
      if (initialScrollTop) {
        scrollNode.scrollTop = initialScrollTop;
      }
      const {scrollX, scrollY} = this.props;
      if (scrollX) {
        const left = parseFloat(scrollX);
        if (!isNaN(left)) {
          scrollNode.scrollLeft = left;
        }
      }
      if (scrollY) {
        const top = parseFloat(scrollY);
        if (!isNaN(top)) {
          scrollNode.scrollTop = top;
        }
      }
    }
  }
  componentWillUnmount(): void {
    const scrollNode = this._scrollContainer.current;
    if (scrollNode) {
      scrollNode.removeEventListener("scroll", this.handleScroll);
    }
    this.updateScrollQuery.cancel();
  }
  @bind
  handleScroll(): void {
    const scrollNode = this._scrollContainer.current;
    if (scrollNode) {
      const headerNode = this._header.current;
      const leftColumnNode = this._leftColumn.current;
      if (headerNode) {
        headerNode.scrollLeft = scrollNode.scrollLeft;
      }
      if (leftColumnNode) {
        leftColumnNode.scrollTop = scrollNode.scrollTop;
      }
      this.updateScrollQuery(scrollNode.scrollLeft, scrollNode.scrollTop);
    }
  }
  render(): React.JSX.Element {
    const {
      children,
      contentHeight,
      contentWidth,
      headerContent,
      leftContent,
      leftWidth,
      topHeight,
    } = this.props;
    return (
      <div style={{height: "100%", width: "100%"}}>
        <FixedTopWithForwardRef
          height={topHeight}
          leftWidth={leftWidth}
          ref={this._header}
          width={contentWidth}
        >
          {headerContent}
        </FixedTopWithForwardRef>
        <Main topHeight={topHeight}>
          <FixedLeftWithForwardRef height={contentHeight} ref={this._leftColumn} width={leftWidth}>
            {leftContent}
          </FixedLeftWithForwardRef>
          <ContentWithForwardRef
            height={contentHeight}
            leftWidth={leftWidth}
            ref={this._scrollContainer}
            width={contentWidth}
          >
            {children}
          </ContentWithForwardRef>
        </Main>
      </div>
    );
  }
}

export default ScrollLayout;
