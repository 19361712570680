import {Product, ProductGroup, ProductGroupUrl, ProductUrl} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator, makeContainsPredicate} from "@co-common-libs/utils";
import {
  EntryData,
  MultiSelectionList,
  OfflineAwareAppBar,
  SlideUpTransition,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerSettings,
  getProductArray,
  getProductGroupArray,
  getProductGroupLookup,
  getShareToken,
} from "@co-frontend-libs/redux";
import {
  alpha,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputBase,
  makeStyles,
  Theme,
  Toolbar,
} from "@material-ui/core";
import {TransitionHandlerProps} from "@material-ui/core/transitions";
import bowser from "bowser";
import CloseIcon from "mdi-react/CloseIcon";
import SearchIcon from "mdi-react/SearchIcon";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {connect, useSelector} from "react-redux";
import {createStructuredSelector} from "reselect";
import {useDebounce} from "use-debounce";
import {BreadCrumbs} from "./bread-crumbs";
import {ProductGroupGrid} from "./product-group-grid";
const messages = defineMessages({
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  ok: {
    defaultMessage: "Ok",
    id: "dialog.label.ok",
  },
  search: {
    defaultMessage: "Søg",
  },
  title: {
    defaultMessage: "Vælg produkt",
    id: "product-group-tree-dialog.title.product-selection",
  },
});
const MINIMUM_SEARCH_CHARS = 3;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "fixed",
    },
    inputInput: {
      "&::placeholder": {
        color: "#fff",
        opacity: 0.87,
      },
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    inputRoot: {
      color: "inherit",
    },
    noPadding: {
      padding: 0,
    },
    noPaddingNoOverflow: {
      overflow: "hidden",
      padding: 0,
    },
    productDialogContent: {
      margin: theme.spacing(3),
    },
    search: {
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      marginRight: 0,
      position: "relative",
      width: "100%",
    },
    searchIcon: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "none",
      position: "absolute",
      width: theme.spacing(7),
    },
  }),
);

interface ProductGroupTreeDialogContentProps {
  filterString: string;
  onProductGroupClick: (url: ProductGroupUrl | null) => void;
  onSelection: (url: ProductUrl, isInputChecked?: boolean) => void;
  productArray: readonly Product[];
  productGroupArray: readonly ProductGroup[];
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
  selectedGroup: ProductGroupUrl | null;
  selectedProducts: ReadonlySet<string>;
}
const ProductGroupTreeDialogContent = React.memo(function ProductGroupTreeDialogContent(
  props: ProductGroupTreeDialogContentProps,
): React.JSX.Element {
  const {
    filterString,
    onProductGroupClick,
    onSelection,
    productArray,
    productGroupArray,
    productGroupLookup,
    selectedGroup,
    selectedProducts,
  } = props;

  const shareToken = useSelector(getShareToken);
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const classes = useStyles();

  const handleProductGroupClick = useCallback(
    (_event: React.MouseEvent, url: ProductGroupUrl | null): void => {
      onProductGroupClick(url);
    },
    [onProductGroupClick],
  );

  const handleProductClick = useCallback(
    (url: ProductUrl, checked?: boolean): void => {
      onSelection(url, checked);
    },
    [onSelection],
  );

  const searching = filterString && filterString.length >= MINIMUM_SEARCH_CHARS;

  const productSearchBase = useMemo((): readonly Product[] => {
    const requireImages = customerSettings.productImageSelectShowOnlyProductsWithPhoto;
    return productArray.filter(({active, photoUrl}) =>
      requireImages ? active && photoUrl : active,
    );
  }, [customerSettings.productImageSelectShowOnlyProductsWithPhoto, productArray]);

  const filteredProductArray = useMemo((): readonly Product[] => {
    if (!searching) {
      return productSearchBase;
    }
    const checkString = makeContainsPredicate(filterString);
    return productSearchBase.filter(({catalogNumber, name}) =>
      checkString(`${name} ${catalogNumber}`),
    );
  }, [filterString, productSearchBase, searching]);
  const sortedProductArray = useMemo(() => {
    if (!selectedGroup && !searching) {
      return [];
    }
    return (
      selectedGroup
        ? filteredProductArray.filter((instance) => instance.group === selectedGroup)
        : filteredProductArray.slice()
    ).sort((a, b) => caseAccentInsensitiveCollator.compare(a.name, b.name));
  }, [filteredProductArray, searching, selectedGroup]);

  const entryData = useMemo((): EntryData<ProductUrl>[] => {
    const data: EntryData<ProductUrl>[] = [];
    sortedProductArray.forEach((product) => {
      if (product.active) {
        const entry: EntryData<ProductUrl> = {
          category: "standard",
          exactMatchValue: product.name,
          identifier: product.url,
          photoUrl: product.photoUrl,
          primaryText: product.name,
          searchFields: [{label: "Navn", priority: 5, text: product.name}],
        };
        data.push(entry);
      }
    });
    return data;
  }, [sortedProductArray]);

  return (
    <div className={classes.productDialogContent}>
      <BreadCrumbs
        onClick={handleProductGroupClick}
        productGroupLookup={productGroupLookup}
        selectedGroup={selectedGroup || undefined}
      />

      {!filterString ? (
        <ProductGroupGrid
          onClick={handleProductGroupClick}
          productGroupArray={productGroupArray}
          products={filteredProductArray}
          selectedGroup={selectedGroup || undefined}
          token={shareToken}
        />
      ) : null}

      {searching && !sortedProductArray.length ? (
        <div>
          <FormattedMessage defaultMessage="Søgningen gav ingen resultater" />
        </div>
      ) : filterString && filterString.length < MINIMUM_SEARCH_CHARS ? (
        <div>
          <FormattedMessage
            defaultMessage="Indtast mindst {minimumChars} tegn for at søge"
            values={{minimumChars: MINIMUM_SEARCH_CHARS}}
          />
        </div>
      ) : bowser.mobile || bowser.tablet ? (
        <MultiSelectionList
          addLabel={intl.formatMessage({
            defaultMessage: "Vælg produkt",
          })}
          entries={entryData}
          isSearchResult={false}
          mobilePrimaryLines={1}
          mobileSearchPrimaryLines={1}
          mobileSearchSecondaryLines={1}
          mobileSecondaryLines={0}
          multiSelectMax={10}
          onSelect={handleProductClick}
          selected={selectedProducts}
          withIcons={false}
        />
      ) : (
        <MultiSelectionList
          addLabel={intl.formatMessage({
            defaultMessage: "Vælg produkt",
          })}
          entries={entryData}
          isSearchResult={false}
          mobilePrimaryLines={1}
          mobileSearchPrimaryLines={1}
          mobileSearchSecondaryLines={1}
          mobileSecondaryLines={0}
          multiSelectMax={10}
          onSelect={handleProductClick}
          selected={selectedProducts}
          withIcons={false}
        />
      )}
    </div>
  );
});

interface ProductGroupTreeDialogStateProps {
  productArray: readonly Product[];
  productGroupArray: readonly ProductGroup[];
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
}

interface ProductGroupTreeDialogOwnProps extends TransitionHandlerProps {
  onCancel: () => void;
  onOk: (urls: ReadonlySet<ProductUrl>) => void;
  open: boolean;
}

type ProductGroupTreeDialogProps = ProductGroupTreeDialogOwnProps &
  ProductGroupTreeDialogStateProps;

const ProductGroupTreeDialog = React.memo(function ProductGroupTreeDialog(
  props: ProductGroupTreeDialogProps,
): React.JSX.Element {
  const {
    onCancel,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    onOk,
    open,
    productArray,
    productGroupArray,
    productGroupLookup,
  } = props;

  const classes = useStyles();

  const searchRef = useRef<HTMLInputElement>();

  const [selectedGroup, setSelectedGroup] = useState<ProductGroupUrl | null>(null);
  const handleProductGroupClick = useCallback((url: ProductGroupUrl | null): void => {
    setSelectedGroup(url);
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);
  const [selectedProducts, setSelectedProducts] = useState(new Set<ProductUrl>());
  const [filterString, setFilterString] = useState("");
  useEffect(() => {
    if (open) {
      setSelectedProducts(new Set());
      setFilterString("");
    }
  }, [open]);

  const handleFilterFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setFilterString(event.target.value);
    },
    [],
  );

  const debounceMilliseconds = 200;
  const [debouncedFilterString] = useDebounce(filterString, debounceMilliseconds);

  const trimmedFilterString = debouncedFilterString.trim();

  const handleSelection = useCallback(
    (url: ProductUrl, checked?: boolean): void => {
      const newSelectedProducts = new Set(selectedProducts);
      const add = checked === undefined ? !newSelectedProducts.has(url) : checked;
      if (add) {
        newSelectedProducts.add(url);
      } else {
        newSelectedProducts.delete(url);
      }
      setSelectedProducts(newSelectedProducts);
    },
    [selectedProducts],
  );

  const handleOk = useCallback((): void => {
    onOk(selectedProducts);
  }, [onOk, selectedProducts]);

  const {formatMessage} = useIntl();

  const content = open && (
    <ProductGroupTreeDialogContent
      filterString={trimmedFilterString}
      onProductGroupClick={handleProductGroupClick}
      onSelection={handleSelection}
      productArray={productArray}
      productGroupArray={productGroupArray}
      productGroupLookup={productGroupLookup}
      selectedGroup={selectedGroup}
      selectedProducts={selectedProducts}
    />
  );

  const optionalEnterExitCallbacks: Partial<DialogProps> = {};
  if (onCancel) {
    optionalEnterExitCallbacks.onClose = onCancel;
  }
  if (onEnter) {
    optionalEnterExitCallbacks.onEnter = onEnter;
  }
  if (onEntered) {
    optionalEnterExitCallbacks.onEntered = onEntered;
  }
  if (onEntering) {
    optionalEnterExitCallbacks.onEntering = onEntering;
  }
  if (onExit) {
    optionalEnterExitCallbacks.onExit = onExit;
  }
  if (onExited) {
    optionalEnterExitCallbacks.onExited = onExited;
  }
  if (onExiting) {
    optionalEnterExitCallbacks.onExiting = onExiting;
  }

  const fullscreenLayout = !!(bowser.mobile || bowser.tablet);
  if (fullscreenLayout) {
    return (
      <Dialog
        fullScreen
        onClose={onCancel}
        open={open}
        TransitionComponent={SlideUpTransition}
        {...optionalEnterExitCallbacks}
      >
        <OfflineAwareAppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={onCancel}>
              <CloseIcon />
            </IconButton>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                classes={{
                  input: classes.inputInput,
                  root: classes.inputRoot,
                }}
                onChange={handleFilterFieldChange}
                placeholder={formatMessage(messages.search)}
                value={filterString}
              />
            </div>
            <Button color="inherit" disabled={!selectedProducts.size} onClick={handleOk}>
              <FormattedMessage defaultMessage="OK" />
            </Button>
          </Toolbar>
        </OfflineAwareAppBar>
        <Toolbar />
        {content}
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={onCancel}
        open={open}
        {...optionalEnterExitCallbacks}
        fullWidth
        maxWidth={"md"}
        scroll="paper"
      >
        <DialogTitle>
          <FormattedMessage defaultMessage="Vælg produkt" />
          <TrimTextField
            autoFocus
            fullWidth
            inputRef={searchRef}
            margin="dense"
            onChange={setFilterString}
            placeholder={formatMessage(messages.search)}
            value={filterString}
            variant="outlined"
          />
        </DialogTitle>
        <DialogContent className={classes.noPaddingNoOverflow} dividers>
          {content}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCancel}>
            <FormattedMessage defaultMessage="Fortryd" />
          </Button>
          <Button color="primary" disabled={!selectedProducts.size} onClick={handleOk}>
            <FormattedMessage defaultMessage="OK" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
});

const ConnectedProductGroupTreeDialog: React.ComponentType<ProductGroupTreeDialogOwnProps> =
  connect<ProductGroupTreeDialogStateProps, object, ProductGroupTreeDialogOwnProps, AppState>(
    createStructuredSelector<AppState, ProductGroupTreeDialogStateProps>({
      productArray: getProductArray,
      productGroupArray: getProductGroupArray,
      productGroupLookup: getProductGroupLookup,
    }),
    {},
  )(ProductGroupTreeDialog);

export {ConnectedProductGroupTreeDialog as ProductGroupTreeDialog};
