import {ResourceName, resourceURL as resourceResquerceURL} from "@co-common-libs/resources";
import {globalConfig} from "./global";

export * from "./date-fns-locale";
export * from "./geolocation-tracker";
export {globalConfig} from "./global";

export type ConfigValue = number | string | ConfigMap;

export type ConfigMap = {[key: string]: ConfigValue};

const {baseURL} = globalConfig.resources;

export const resourceURL = (resourceName: ResourceName): string =>
  resourceResquerceURL(baseURL, resourceName);

export {instanceURL} from "./instance-url";
export * from "./push-notification";
export * from "./store";
export * from "./theme";
export {resourcesConfig} from "@co-common-libs/resources";
