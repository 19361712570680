import {formatTime} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  getTimerLookup,
  getTimerStartLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface TimerStartNotificationDialogProps {
  onCancel: () => void;
  onOk: (url: string | null) => void;
  open: boolean;
  timerStartId: string | null;
}

export const TimerStartNotificationDialog = React.memo(function TimerStartNotificationDialog(
  props: TimerStartNotificationDialogProps,
): React.JSX.Element {
  const {onCancel, onOk, open, timerStartId} = props;
  const timerStartUrl = timerStartId ? instanceURL("timerStart", timerStartId) : null;
  const timerStartLookup = useSelector(getTimerStartLookup);
  const timerLookup = useSelector(getTimerLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);

  const timerStart = timerStartUrl ? timerStartLookup(timerStartUrl) : null;
  const timer = timerStart?.timer ? timerLookup(timerStart.timer) : null;
  const userProfile = timerStart?.employee ? userUserProfileLookup(timerStart.employee) : null;

  const intl = useIntl();

  const handleOk = useCallback(() => {
    onOk(timerStart?.task || null);
  }, [onOk, timerStart?.task]);
  return (
    <ResponsiveDialog
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={intl.formatMessage({defaultMessage: "Tidsknapsnotifikation"})}
    >
      <DialogContent>
        <FormattedMessage
          defaultMessage="{employee} har aktiveret {timer} kl. {time}. Vil du gå til opgaven?"
          values={{
            employee: userProfile?.alias || "",
            time: formatTime(timerStart?.deviceTimestamp),
            timer: timer?.label || "",
          }}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
