import {Location, LocationUrl} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent, FormControlLabel, Radio, RadioGroup, useTheme} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

interface LocationMergeDialogProps {
  locationList: readonly LocationUrl[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  onCancel: () => void;
  onOk: (locationURL: LocationUrl) => void;
  open: boolean;
}

export const LocationMergeDialog = function LocationMergeDialog({
  locationList,
  locationLookup,
  onCancel,
  onOk,
  open,
}: LocationMergeDialogProps): React.JSX.Element {
  const [selectedLocation, setSelectedLocation] = useState<LocationUrl | null>(null);

  useEffect(() => {
    if (!open) {
      setSelectedLocation(null);
    }
  }, [open]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLocation((event.target.value as LocationUrl) || null);
  }, []);

  const handleOk = useCallback(() => {
    if (!selectedLocation) {
      return;
    }
    onOk(selectedLocation);
  }, [onOk, selectedLocation]);

  const theme = useTheme();

  return (
    <ResponsiveDialog
      okDisabled={!selectedLocation}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={<FormattedMessage defaultMessage="Flet steder" id="location-merge-dialog.title" />}
    >
      <DialogContent>
        <div style={{color: theme.palette.error.main}}>
          <FormattedMessage
            defaultMessage="Vælg det sted som de andre steder skal flettes ind på. Handlingen kan ikke fortrydes."
            id="location-merge-dialog.text"
          />
        </div>
        <RadioGroup onChange={handleChange} value={selectedLocation}>
          {locationList.map((url) => {
            const location = locationLookup(url);
            return (
              <FormControlLabel
                control={<Radio />}
                key={url}
                label={location?.name || location?.address}
                value={location?.url}
              />
            );
          })}
        </RadioGroup>
      </DialogContent>
    </ResponsiveDialog>
  );
};
