import {
  SettingID,
  SettingMetaData,
  settings,
  SettingsGroupID,
  settingsGroupIdentifiers,
  SettingsGroupMetaData,
  settingsGroups,
  settingsIdentifiers,
  SettingsModuleID,
  settingsModuleIdentifiers,
  settingsModules,
} from "@co-common-libs/config";
import {normalizeSearchText, normalizeSearchTextArray} from "@co-common-libs/utils";
import {AppbarSearchField} from "@co-frontend-libs/components";
import {actions, getCurrentRole, getCustomerSettings} from "@co-frontend-libs/redux";
import {BackToolbar, PageLayout} from "app-components";
import {useEventTargetValueCallback, useQueryParameter} from "app-utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {hiddenSettings} from "./hidden-settings";
import {SettingsMenu} from "./settings-menu";
import {SettingsView} from "./settings-view";
import {GroupData, ModuleData} from "./types";
import {settingsModulesOverrides} from "./ui-overrides";

const messages = defineMessages({
  expert: {
    defaultMessage: "Nightmare Mode ☠️",
    id: "system-setup.label.expert",
  },
  list: {
    defaultMessage: "Liste",
    id: "system-setup.label.list",
  },
  reportingSpecification: {
    defaultMessage: "Logopsætning",
    id: "system-setup.label.reporting-specification",
  },
  save: {
    defaultMessage: "Gem",
    id: "system-setup.label.save",
  },
  systemSetup: {
    defaultMessage: "Systemopsætning",
    id: "system-setup.title.setup",
  },
  timers: {
    defaultMessage: "Tidsknapper",
    id: "system-setup.label.timers",
  },
});

export function SystemSetup(): React.JSX.Element | null {
  const currentRole = useSelector(getCurrentRole);
  const q = useQueryParameter("q");
  const moduleParam = useQueryParameter("module", "");
  const module: SettingsModuleID | undefined = (
    settingsModuleIdentifiers as readonly string[]
  ).includes(moduleParam)
    ? (moduleParam as SettingsModuleID)
    : undefined;

  const groupParam = useQueryParameter("group", "");
  const group: SettingsGroupID | undefined = (
    settingsGroupIdentifiers as readonly string[]
  ).includes(groupParam)
    ? (groupParam as SettingsGroupID)
    : undefined;

  const settingsStructure = useMemo((): readonly ModuleData[] => {
    const settingsPerGroup = new Map<
      SettingsGroupID,
      {settingID: SettingID; settingMetaData: SettingMetaData}[]
    >();
    const groupsPerModule = new Map<
      SettingsModuleID,
      {groupID: SettingsGroupID; groupMetaData: SettingsGroupMetaData}[]
    >();
    settingsIdentifiers.forEach((settingID) => {
      if (hiddenSettings.has(settingID)) {
        return;
      }
      const settingMetaData = settings[settingID];
      const entry = {settingID, settingMetaData};
      const existing = settingsPerGroup.get(settingMetaData.group);
      if (existing) {
        existing.push(entry);
      } else {
        settingsPerGroup.set(settingMetaData.group, [entry]);
      }
    });

    settingsGroupIdentifiers.forEach((groupID) => {
      const groupMetaData = settingsGroups[groupID];

      const entry = {groupID, groupMetaData};
      const existing = groupsPerModule.get(groupMetaData.module);
      if (existing) {
        existing.push(entry);
      } else {
        groupsPerModule.set(groupMetaData.module, [entry]);
      }
    });

    const processGroup = ({
      groupID,
      groupMetaData,
    }: {
      groupID: SettingsGroupID;
      groupMetaData: SettingsGroupMetaData;
    }): GroupData => {
      const {
        customOfficeVariants,
        description: groupDescription = "",
        documentationURL: groupDocumentationURL,
        title: groupTitle,
      } = groupMetaData;
      const groupSettings = settingsPerGroup.get(groupID) || [];
      return {
        customOfficeVariants,
        groupDescription,
        groupDocumentationURL,
        groupID,
        groupSettings,
        groupTitle,
      };
    };
    const processModule = (moduleID: SettingsModuleID): ModuleData => {
      const {documentationURL: moduleDocumentationURL, title: moduleTitle} =
        settingsModules[moduleID];
      const moduleGroups = (groupsPerModule.get(moduleID) || []).map(processGroup);
      return {
        moduleDocumentationURL,
        moduleGroups,
        moduleID,
        moduleTitle,
      };
    };
    return settingsModuleIdentifiers
      .filter((moduleID) => !settingsModulesOverrides[moduleID])
      .map(processModule);
  }, []);

  const dispatch = useDispatch();

  const handleFilterStringChange = useEventTargetValueCallback(
    (value) => {
      dispatch(actions.putQueryKey("q", value || ""));
    },
    [dispatch],
  );

  const {formatMessage} = useIntl();
  const customerSettings = useSelector(getCustomerSettings);

  const searchTextArray = useMemo(() => (q.trim() ? normalizeSearchTextArray(q) : undefined), [q]);

  const filteredSettingsStructure = useMemo((): readonly ModuleData[] => {
    if (!searchTextArray) {
      return settingsStructure;
    }
    return settingsStructure
      .map((moduleData) => {
        const normalizedModuleTitle = normalizeSearchText(moduleData.moduleTitle);
        const unmatchedOnModule = searchTextArray.filter(
          (searchText) => normalizedModuleTitle.indexOf(searchText) === -1,
        );
        if (unmatchedOnModule.length === 0) {
          return moduleData;
        }
        return {
          ...moduleData,
          moduleGroups: moduleData.moduleGroups
            .map((groupData) => {
              const normalizedGroupTitle = normalizeSearchText(groupData.groupTitle);
              const unmatchedOnGroup = unmatchedOnModule.filter(
                (searchText) => normalizedGroupTitle.indexOf(searchText) === -1,
              );
              if (unmatchedOnGroup.length === 0) {
                return groupData;
              }
              return {
                ...groupData,
                groupSettings: groupData.groupSettings.filter((settingData) => {
                  const settingText = normalizeSearchText(
                    `${settingData.settingID} ${settingData.settingMetaData.description}`,
                  );
                  return unmatchedOnGroup.every(
                    (searchText) => settingText.indexOf(searchText) !== -1,
                  );
                }),
              };
            })
            .filter((groupData) => groupData.groupSettings.length),
        };
      })
      .filter((moduleData) => moduleData.moduleGroups.length);
  }, [searchTextArray, settingsStructure]);

  const consultant = !!currentRole && currentRole.consultant;
  if (!consultant) {
    return null;
  }

  const searchField = <AppbarSearchField onChange={handleFilterStringChange} value={q} />;

  let view: React.JSX.Element;
  const OverrideComponent = module && settingsModulesOverrides[module];
  if (OverrideComponent) {
    view = <OverrideComponent />;
  } else {
    view = (
      <SettingsView
        selectedGroup={group}
        selectedModule={module}
        settingsStructure={filteredSettingsStructure}
      />
    );
  }

  const {customOfficeProductVariant} = customerSettings;
  return (
    <PageLayout
      toolbar={
        <BackToolbar
          rightElement={
            <>
              {customOfficeProductVariant} {searchField}
            </>
          }
          title={formatMessage(messages.systemSetup)}
        />
      }
    >
      <div style={{alignItems: "stretch", display: "flex", height: "100%"}}>
        <div style={{flex: 1}}>{view}</div>
        <div style={{flex: "0 1 240px"}}>
          <SettingsMenu settingsStructure={filteredSettingsStructure} />
        </div>
      </div>
    </PageLayout>
  );
}
