import {Timer, TimerUrl} from "@co-common-libs/resources";
import {DateField, TimeField} from "@co-frontend-libs/components";
import {PunchWorkPeriod} from "@co-frontend-libs/redux";
import {FormControlLabel, Grid, Radio, RadioGroup, useTheme} from "@material-ui/core";
import {useEventTargetValueCallback} from "app-utils";
import {SPACING} from "frontend-global-config";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {selectionCheck} from "./time-correction-utils";

export interface TimeCorrectionFormProps {
  disabled?: boolean;
  fromDate: string | null;
  fromTime: string | null;
  genericPrimaryTimer: Timer;
  genericPrimaryTimerLabel: string;
  hidePrimaryTimer: boolean;
  legalIntervals: readonly PunchWorkPeriod[] | undefined;
  onFromDateChange: (fromDate: string | null) => void;
  onFromTimeChange: (fromTime: string | null) => void;
  onTimerUrlChange: (timer: TimerUrl | null) => void;
  onToDateChange: (toDate: string | null) => void;
  onToTimeChange: (toTime: string | null) => void;
  secondaryTimers: ReadonlySet<Timer>;
  // null is the 'remove time in period' option - undefined means none selected
  selectedTimer?: TimerUrl | null | undefined;
  toDate: string | null;
  toTime: string | null;
}

export const TimeCorrectionForm = React.memo(function TimeCorrectionForm(
  props: TimeCorrectionFormProps,
) {
  const {
    disabled = false,
    fromDate,
    fromTime,
    genericPrimaryTimer,
    genericPrimaryTimerLabel,
    hidePrimaryTimer,
    legalIntervals,
    onFromDateChange,
    onFromTimeChange,
    onTimerUrlChange,
    onToDateChange,
    onToTimeChange,
    secondaryTimers,
    selectedTimer,
    toDate,
    toTime,
  } = props;

  const intl = useIntl();
  const theme = useTheme();

  const handleRadioButtonChange = useEventTargetValueCallback(
    (value) => {
      onTimerUrlChange(value !== "" ? (value as TimerUrl) : null);
    },
    [onTimerUrlChange],
  );

  const handleFromDateChange = useCallback(
    (newValue: string | null) => {
      if (!toDate || (newValue && newValue > toDate)) {
        onFromDateChange(newValue);
        onToDateChange(newValue);
      } else {
        onFromDateChange(newValue);
      }
    },
    [onFromDateChange, onToDateChange, toDate],
  );

  const {formValid, fromAfterOrEqualToToDateTime, punchedInAtFrom, punchedInAtTo} = selectionCheck({
    fromDate,
    fromTime,
    legalIntervals,
    timerUrl: selectedTimer,
    toDate,
    toTime,
  });

  let notPunchedInAtFromError: React.JSX.Element | undefined;
  let notPunchedInAtToError: React.JSX.Element | undefined;
  let notPunchedInBetweenError: React.JSX.Element | undefined;
  if (!formValid && legalIntervals) {
    if (punchedInAtFrom === false) {
      notPunchedInAtFromError = (
        <div>
          <FormattedMessage defaultMessage="Ikke stemplet ind på tidspunktet" />
        </div>
      );
    }
    if (punchedInAtTo === false) {
      notPunchedInAtToError = (
        <div>
          <FormattedMessage defaultMessage="Ikke stemplet ind på tidspunktet" />
        </div>
      );
    }
    if (punchedInAtFrom === true && punchedInAtTo === true) {
      notPunchedInBetweenError = (
        <div>
          <FormattedMessage defaultMessage="Ikke stemplet ind i hele perioden" />
        </div>
      );
    }
  }

  return (
    <Grid container direction="column" spacing={SPACING.SMALL}>
      <Grid container direction="column" item>
        <Grid container direction="row" item spacing={SPACING.XSMALL}>
          <Grid item md={6} xs={12}>
            <TimeField
              disabled={disabled}
              fullWidth
              label={intl.formatMessage({defaultMessage: "Fra klokken"})}
              margin="dense"
              onChange={onFromTimeChange}
              value={fromTime || undefined}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DateField
              autoOk
              disabled={disabled}
              fullWidth
              label={intl.formatMessage({defaultMessage: "Fra dato"})}
              margin="dense"
              onChange={handleFromDateChange}
              value={fromDate}
            />
          </Grid>
        </Grid>
        <Grid item style={{color: theme.palette.error.main}}>
          {fromAfterOrEqualToToDateTime ? (
            <div>
              <FormattedMessage defaultMessage="Til-tidspunktet skal ligge senere end fra-tidspunktet" />
            </div>
          ) : null}
          {notPunchedInAtFromError}
        </Grid>
      </Grid>
      <Grid container direction="column" item>
        <Grid container direction="row" spacing={SPACING.XSMALL}>
          <Grid item md={6} xs={12}>
            <TimeField
              disabled={disabled}
              fullWidth
              label={intl.formatMessage({defaultMessage: "Til klokken"})}
              margin="dense"
              onChange={onToTimeChange}
              value={toTime || undefined}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DateField
              autoOk
              disabled={disabled}
              fullWidth
              label={intl.formatMessage({defaultMessage: "Til dato"})}
              margin="dense"
              onChange={onToDateChange}
              value={toDate}
            />
          </Grid>
        </Grid>
        <Grid item style={{color: theme.palette.error.main}}>
          {notPunchedInAtToError}
          {notPunchedInBetweenError}
        </Grid>
      </Grid>
      <Grid item>
        <RadioGroup
          name="timerSelection"
          onChange={handleRadioButtonChange}
          value={selectedTimer === null ? "" : selectedTimer}
        >
          {!hidePrimaryTimer ? (
            <div key={genericPrimaryTimer.url}>
              <FormControlLabel
                control={<Radio />}
                disabled={disabled}
                label={genericPrimaryTimerLabel}
                value={genericPrimaryTimer.url}
              />
            </div>
          ) : (
            //RadioButtonGroup doesn't allow null as child
            []
          )}
          {Array.from(secondaryTimers).map(({label, url}) => {
            return (
              <div key={url}>
                <FormControlLabel
                  control={<Radio />}
                  disabled={disabled}
                  label={label}
                  value={url}
                />
              </div>
            );
          })}
          <div>
            <FormControlLabel
              control={<Radio />}
              disabled={disabled}
              label={intl.formatMessage({
                defaultMessage: "Slet tiden i perioden",
              })}
              value=""
            />
          </div>
        </RadioGroup>
      </Grid>
    </Grid>
  );
});
