import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  DinnerBooking,
  Location,
  LocationUrl,
  LunchBooking,
  Order,
  OrderUrl,
  PatchUnion,
  ResourceTypeUnion,
  Role,
  Settings,
  Task,
  TaskUrl,
  TimerStart,
  User,
  UserProfile,
  UserUrl,
} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {
  dateToString,
  formatAddress,
  formatDate,
  formatTime,
  getMidnights,
} from "@co-common-libs/utils";
import {AppbarDateSelection, FilePdfIcon, IconLinkButton} from "@co-frontend-libs/components";
import {Check, makeQuery, Query} from "@co-frontend-libs/db-resources";
import {
  actions,
  AppState,
  getCurrentRole,
  getCustomerLookup,
  getCustomerSettings,
  getDinnerBookingArray,
  getLocationLookup,
  getLunchBookingArray,
  getOrderLookup,
  getPathName,
  getRoleArray,
  getSettingsArray,
  getTaskLookup,
  getTimerStartArray,
  getToken,
  getUserArray,
  getUserLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {AjaxDownloadButton, DinnerBookingDialog, MenuToolbar, PageLayout} from "app-components";
import {addDanishCountryPrefix, padZero, PureComponent, updateDinnerLunchBooking} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import CellphoneIcon from "mdi-react/CellphoneIcon";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const PHONE_NUMBER_COLUMN = bowser.mobile ? {padding: 0, width: 48} : {};

const messages = defineMessages({
  downloadPDF: {
    defaultMessage: "Download PDF",
    id: "dinner-booking-list.download-pdf",
  },

  title: {
    defaultMessage: "Madbestillinger",
    id: "dinner-booking-list.title.dinner-bookings",
  },
});

const initialsColumnStyle = {
  width: 80,
};

interface DinnerBookingRowProps {
  count?: number | undefined;
  customer?: string;
  customerSettings: Config;
  dinnerLocation?: string | undefined;
  editable: boolean;
  initials: string;
  lunchCount?: number | undefined;
  lunchLocation?: string | undefined;
  name: string;
  onClick: (userURL: UserUrl) => void;
  phone: string;
  time?: string | undefined;
  userURL: UserUrl;
  workplace?: string;
}

class DinnerBookingRow extends PureComponent<DinnerBookingRowProps> {
  @bind
  handleClick(): void {
    this.props.onClick(this.props.userURL);
  }
  render(): React.JSX.Element {
    const {
      count,
      customer,
      dinnerLocation,
      editable,
      initials,
      lunchCount,
      lunchLocation,
      name,
      phone,
      time,
      workplace,
    } = this.props;

    const cellOptionalClickHandlerProps: TableCellProps = {};
    if (editable) {
      cellOptionalClickHandlerProps.onClick = this.handleClick;
    }

    return (
      <TableRow style={editable ? {cursor: "pointer"} : {}}>
        <TableCell style={initialsColumnStyle} {...cellOptionalClickHandlerProps}>
          {initials}
        </TableCell>
        <TableCell {...cellOptionalClickHandlerProps}>{name}</TableCell>
        {bowser.mobile ? (
          <TableCell style={PHONE_NUMBER_COLUMN}>
            {phone ? (
              <IconLinkButton href={`tel:${addDanishCountryPrefix(phone)}`} Icon={CellphoneIcon} />
            ) : (
              <IconLinkButton Icon={CellphoneIcon} />
            )}
          </TableCell>
        ) : (
          <TableCell {...cellOptionalClickHandlerProps}>{phone}</TableCell>
        )}
        <TableCell {...cellOptionalClickHandlerProps}>{customer}</TableCell>
        <TableCell {...cellOptionalClickHandlerProps}>{workplace}</TableCell>
        <TableCell {...cellOptionalClickHandlerProps}>{count}</TableCell>
        {this.props.customerSettings.dinnerBookingLocations.length ? (
          <TableCell {...cellOptionalClickHandlerProps}>{dinnerLocation}</TableCell>
        ) : null}
        {this.props.customerSettings.lunchBookings ? (
          <TableCell {...cellOptionalClickHandlerProps}>{lunchCount}</TableCell>
        ) : null}
        {this.props.customerSettings.lunchBookingLocations.length ? (
          <TableCell {...cellOptionalClickHandlerProps}>{lunchLocation}</TableCell>
        ) : null}
        <TableCell {...cellOptionalClickHandlerProps}>{formatTime(time)}</TableCell>
      </TableRow>
    );
  }
}

interface DinnerBookingData {
  count: number | undefined;
  customer: string;
  dinnerLocation: string | undefined;
  initials: string;
  lunchCount: number | undefined;
  lunchLocation: string | undefined;
  name: string;
  phone: string;
  time: string | undefined;
  userURL: UserUrl;
  workplace: string;
}

const dinnerBookingComparer = (a: DinnerBookingData, b: DinnerBookingData): number => {
  if (a.dinnerLocation && !b.dinnerLocation) {
    return -1;
  }
  if (!a.dinnerLocation && b.dinnerLocation) {
    return 1;
  }
  if (!a.dinnerLocation && !b.dinnerLocation) {
    if (a.lunchLocation && !b.lunchLocation) {
      return -1;
    }
    if (!a.lunchLocation && b.lunchLocation) {
      return 1;
    }
    if (a.lunchLocation && b.lunchLocation) {
      const lunchLocation = a.lunchLocation.localeCompare(b.lunchLocation);
      if (lunchLocation === 0) {
        return a.initials.localeCompare(b.initials);
      }
      return lunchLocation;
    }
  }
  if (a.dinnerLocation && b.dinnerLocation) {
    const dinnerLocation = a.dinnerLocation.localeCompare(b.dinnerLocation);
    if (dinnerLocation === 0) {
      return a.initials.localeCompare(b.initials);
    }
    return dinnerLocation;
  }
  return a.initials.localeCompare(b.initials);
};

interface DinnerBookingListStateProps {
  currentRole: Role | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  dinnerBookingArray: readonly DinnerBooking[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  lunchBookingArray: readonly LunchBooking[];
  orderLookup: (url: OrderUrl) => Order | undefined;
  pathName: string;
  roleArray: readonly Role[];
  settingsArray: readonly Settings[];
  taskLookup: (url: TaskUrl) => Task | undefined;
  timerStartArray: readonly TimerStart[];
  token: string | null;
  userArray: readonly User[];
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface DinnerBookingListDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  remove: (url: string) => void;
  temporaryQueriesDiscardedForPath: (pathName: string, key: string) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface DinnerBookingListOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type DinnerBookingListProps = DinnerBookingListDispatchProps &
  DinnerBookingListOwnProps &
  DinnerBookingListStateProps;

interface DinnerBookingListState {
  dinnerBookingDialogOpenFor: UserUrl | null;
  dinnerBookingQuery: Query | null;
  lunchBookingQuery: Query | null;
  selectedDate: string;
}

const TEMPORARY_QUERIES_KEY = "DinnerBookingList";

class DinnerBookingList extends PureComponent<DinnerBookingListProps, DinnerBookingListState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: DinnerBookingListState = {
    dinnerBookingDialogOpenFor: null,
    dinnerBookingQuery: null,
    lunchBookingQuery: null,
    selectedDate: dateToString(new Date()),
  };
  componentWillUnmount(): void {
    if (this.state.dinnerBookingQuery || this.state.lunchBookingQuery) {
      const {pathName, temporaryQueriesDiscardedForPath} = this.props;
      temporaryQueriesDiscardedForPath(pathName, TEMPORARY_QUERIES_KEY);
    }
  }
  @bind
  handleBookingRowClick(employee: UserUrl): void {
    this.setState({dinnerBookingDialogOpenFor: employee});
  }
  @bind
  handleDinnerBookingCancel(): void {
    this.setState({dinnerBookingDialogOpenFor: null});
  }
  @bind
  handleDinnerBookingOk(
    date: string,
    employeeURL: UserUrl,
    dinnerCount: number,
    lunchCount: number | null,
    dinnerLocation: string | null,
    lunchLocation: string | null,
  ): void {
    updateDinnerLunchBooking(
      this.props.customerSettings,
      this.props.dinnerBookingArray,
      this.props.lunchBookingArray,
      this.props.create,
      this.props.update,
      date,
      employeeURL,
      dinnerCount,
      lunchCount,
      dinnerLocation,
      lunchLocation,
    );
    this.setState({dinnerBookingDialogOpenFor: null});
  }
  @bind
  handleSelectedDateChange(newValue: string | null): void {
    if (newValue) {
      const {pathName, temporaryQueriesRequestedForPath} = this.props;
      const dateCheck: Check = {
        memberName: "date",
        type: "memberEq",
        value: newValue,
      };
      const dinnerBookingQuery = makeQuery({
        check: dateCheck,
        filter: {date: newValue},
        independentFetch: true,
        resourceName: "dinnerBooking",
      });
      const lunchBookingQuery = makeQuery({
        check: dateCheck,
        filter: {date: newValue},
        independentFetch: true,
        resourceName: "lunchBooking",
      });
      temporaryQueriesRequestedForPath(
        [dinnerBookingQuery, lunchBookingQuery],
        pathName,
        TEMPORARY_QUERIES_KEY,
      );
      this.setState({
        dinnerBookingQuery,
        lunchBookingQuery,
        selectedDate: newValue,
      });
    }
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      customerLookup,
      customerSettings,
      locationLookup,
      orderLookup,
      roleArray,
      settingsArray,
      taskLookup,
      timerStartArray,
      userLookup,
      userUserProfileLookup,
    } = this.props;
    const now = new Date();
    const today = dateToString(now);
    const selectedDate = this.state.selectedDate || today;
    const right = (
      <AppbarDateSelection
        onChange={this.handleSelectedDateChange}
        value={this.state.selectedDate}
      />
    );

    const userRoles: {[userURL: string]: Role | undefined} = {};
    roleArray.forEach((role) => {
      userRoles[role.user] = role;
    });

    const {startOfDay, startOfNextDay} = getMidnights(selectedDate);
    const userToTimerstartMap = new Map<string, TimerStart>();
    timerStartArray.forEach((timerStart) => {
      if (!timerStart.timer) {
        return;
      }
      const timestamp = getNormalisedDeviceTimestamp(timerStart);
      if (timestamp < startOfNextDay && timestamp > startOfDay) {
        const {employee} = timerStart;
        const oldEntry = userToTimerstartMap.get(employee);
        if (!oldEntry || getNormalisedDeviceTimestamp(oldEntry) < timestamp) {
          userToTimerstartMap.set(employee, timerStart);
        }
      }
    });
    const dinnerBookings = this.props.dinnerBookingArray.filter(
      (booking) => booking.date === selectedDate,
    );

    const lunchBookings = this.props.lunchBookingArray.filter(
      (booking) => booking.date === selectedDate,
    );

    const dinnerBookingData: DinnerBookingData[] = _.sortBy(
      this.props.userArray.filter((user) => {
        if (!user.active) {
          return false;
        }
        const userRole = userRoles[user.url];
        return (
          !userRole ||
          (!userRole.breakRoom &&
            (!userRole.consultant || (this.props.currentRole && this.props.currentRole.consultant)))
        );
      }),
      (user) => userUserProfileLookup(user.url)?.alias || user.loginIdentifier,
    )
      .map((user) => {
        const userURL = user.url;
        const booking = dinnerBookings.find((b) => b.user === userURL);
        const count = booking ? booking.count : undefined;
        const dinnerLocation = booking ? booking.location : undefined;
        const lunchBooking = lunchBookings.find((l) => l.user === userURL);
        const lunchCount = lunchBooking ? lunchBooking.count : undefined;
        const lunchLocation = lunchBooking ? lunchBooking.location : undefined;
        const time = (booking && booking.time) || undefined;
        const userProfile = userUserProfileLookup(userURL);
        const initials = (userProfile && userProfile.alias) || "";
        const name = (userProfile && userProfile.name) || "";
        const phone = (userProfile && userProfile.cellphone) || "";
        const timerStart = userToTimerstartMap.get(userURL);

        const task = timerStart ? taskLookup(timerStart.task) : undefined;
        let workplace = "";
        let customerName = "";
        if (task) {
          const orderURL = task.order;
          const order = orderURL ? orderLookup(orderURL) : null;
          let customer;
          if (order && order.customer) {
            const customerURL = order.customer;
            customer = customerLookup(customerURL);
            customerName = customer ? customer.name : "";
          }
          const workplaceURL = task.relatedWorkplace;
          if (workplaceURL) {
            const workplaceInstance = locationLookup(workplaceURL);
            if (workplaceInstance) {
              workplace = workplaceInstance.address || workplaceInstance.name;
            }
          }
          if (!workplace) {
            workplace = task.address || "";
          }
          if (!workplace && customer) {
            workplace = formatAddress(customer);
          }
        }
        return {
          count,
          customer: customerName,
          dinnerLocation,
          initials,
          lunchCount,
          lunchLocation,
          name,
          phone,
          time,
          userURL,
          workplace,
        };
      })
      .sort(dinnerBookingComparer);
    const rowArray = dinnerBookingData.map(
      (
        {
          count,
          customer,
          dinnerLocation,
          initials,
          lunchCount,
          lunchLocation,
          name,
          phone,
          time,
          userURL,
          workplace,
        },
        _index,
      ) => (
        <DinnerBookingRow
          count={count}
          customer={customer}
          customerSettings={customerSettings}
          dinnerLocation={dinnerLocation}
          editable={
            !!(
              this.props.currentRole &&
              (this.props.currentRole.manager ||
                (customerSettings.allowSeniorMachineOperatorsToManageFoodBookings &&
                  this.props.currentRole?.seniorMachineOperator))
            )
          }
          initials={initials}
          key={userURL}
          lunchCount={lunchCount}
          lunchLocation={lunchLocation}
          name={name}
          onClick={this.handleBookingRowClick}
          phone={phone}
          time={time}
          userURL={userURL}
          workplace={workplace}
        />
      ),
    );
    let dialogEmployee: User | undefined;
    let dialogCount: number | undefined;
    let dialogLunchCount: number | undefined;
    let dialogDinnerLocation: string | undefined;
    let dialogLunchLocation: string | undefined;
    if (this.state.dinnerBookingDialogOpenFor) {
      const employeeURL = this.state.dinnerBookingDialogOpenFor;
      dialogEmployee = userLookup(employeeURL);
      const existingBooking = this.props.dinnerBookingArray.find((entry) => {
        return entry.user === employeeURL && entry.date === selectedDate;
      });
      const existingLunchBooking = this.props.lunchBookingArray.find((entry) => {
        return entry.user === employeeURL && entry.date === selectedDate;
      });
      dialogCount = existingBooking ? existingBooking.count : undefined;
      dialogDinnerLocation = existingBooking ? existingBooking.location : undefined;
      dialogLunchCount = existingLunchBooking ? existingLunchBooking.count : undefined;
      dialogLunchLocation = existingLunchBooking ? existingLunchBooking.location : undefined;
    }
    let isActive = false;
    let lunchIsActive = false;
    const settings = settingsArray[0];
    if (settings) {
      isActive = settings.dinnerBookingsActive;
      lunchIsActive = settings.lunchBookingsActive;
    }

    const dinnerBookingDialog = (
      <DinnerBookingDialog
        count={dialogCount}
        customerSettings={this.props.customerSettings}
        date={selectedDate}
        dinnerLocation={dialogDinnerLocation}
        employee={dialogEmployee}
        key="dinner-booking-dialog"
        lunchCount={dialogLunchCount}
        lunchLocation={dialogLunchLocation}
        onCancel={this.handleDinnerBookingCancel}
        onOk={this.handleDinnerBookingOk}
        open={!!this.state.dinnerBookingDialogOpenFor}
        showDinnerBooking={isActive}
        showLunchBooking={lunchIsActive}
      />
    );
    const dialogs = [dinnerBookingDialog];
    const totalCount = dinnerBookingData.reduce((acc, {count}) => acc + (count || 0), 0);
    const totalLunchCount = dinnerBookingData.reduce(
      (acc, {lunchCount}) => acc + (lunchCount || 0),
      0,
    );
    const pdfUrl = `${globalConfig.baseURL}/download/dinner_booking/pdf`;
    const pdfData = {
      data: dinnerBookingData.map(
        ({
          count,
          customer,
          dinnerLocation,
          initials,
          lunchCount,
          lunchLocation,
          name,
          phone,
          workplace,
        }) => {
          return {
            count,
            customer,
            dinnerLocation,
            initials,
            lunchCount,
            lunchLocation,
            name,
            phone,
            workplace,
          };
        },
      ),
      dateString: formatDate(selectedDate),
      deviceTime: Date.now(),
      dinnerBookingLocations: this.props.customerSettings.dinnerBookingLocations,
      lunchBookingLocations: this.props.customerSettings.lunchBookingLocations,
      lunchSum: totalLunchCount,
      showLunch: this.props.customerSettings.lunchBookings,
      sum: totalCount,
    };

    const hours = padZero(now.getHours(), 2);

    const minutes = padZero(now.getMinutes(), 2);
    const pdfFilename = `mad-${selectedDate}_${hours}.${minutes}.pdf`;

    let sumRowArray;
    if (
      !!this.props.customerSettings.dinnerBookingLocations.length ||
      !!this.props.customerSettings.lunchBookingLocations.length
    ) {
      const locations = Array.from(
        new Set(
          this.props.customerSettings.dinnerBookingLocations.concat(
            this.props.customerSettings.lunchBookingLocations,
          ),
        ),
      ).sort();
      const dinnerLocationCounts = new Map<string, number>();
      const lunchLocationCount = new Map<string, number>();
      dinnerBookingData.forEach((data) => {
        const {count, dinnerLocation, lunchCount, lunchLocation} = data;
        dinnerLocationCounts.set(
          dinnerLocation || "",
          (dinnerLocationCounts.get(dinnerLocation || "") || 0) + (count || 0),
        );
        lunchLocationCount.set(
          lunchLocation || "",
          (lunchLocationCount.get(lunchLocation || "") || 0) + (lunchCount || 0),
        );
      });
      sumRowArray = [];
      locations.forEach((locationLabel) => {
        const dinnerCount = dinnerLocationCounts.get(locationLabel) || 0;
        const lunchCount = lunchLocationCount.get(locationLabel) || 0;
        sumRowArray.push(
          <TableRow key={locationLabel}>
            <TableCell style={initialsColumnStyle}>
              <strong>
                <FormattedMessage defaultMessage="Sum" id="dinner-booking-list.table-row.sum" />
              </strong>
            </TableCell>
            <TableCell>
              <strong>{locationLabel}</strong>
            </TableCell>
            <TableCell style={PHONE_NUMBER_COLUMN} />
            <TableCell />
            <TableCell />
            <TableCell>
              <strong>{dinnerCount}</strong>
            </TableCell>
            {this.props.customerSettings.dinnerBookingLocations.length ? <TableCell /> : null}
            {this.props.customerSettings.lunchBookings ? (
              <TableCell>
                <strong>{lunchCount}</strong>
              </TableCell>
            ) : null}
            {this.props.customerSettings.lunchBookingLocations.length ? <TableCell /> : null}
            <TableCell />
          </TableRow>,
        );
      });
    }

    const tableMinWidthWithLunch = 1200;
    const normalTableMinWidth = 1000;
    return (
      <PageLayout
        dialogs={dialogs}
        toolbar={
          <MenuToolbar
            onMenuButton={this.props.onMenuButton}
            rightElement={right}
            title={formatMessage(messages.title)}
          />
        }
        withBottomScrollPadding
      >
        {!isActive ? (
          <Paper style={{padding: 10, textAlign: "center"}}>
            <h2>
              <FormattedMessage
                defaultMessage="Madbestilling er deaktiveret. Du kan aktivere det under Instillinger -> Madbestillinger"
                id="dinner-booking-list.not-activated"
              />
            </h2>
          </Paper>
        ) : null}
        <Table
          style={{
            backgroundColor: "#fff",
            minWidth: this.props.customerSettings.lunchBookings
              ? tableMinWidthWithLunch
              : normalTableMinWidth,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={initialsColumnStyle}>
                <FormattedMessage
                  defaultMessage="Initialer"
                  id="dinner-booking-list.table-header.initials"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Navn"
                  id="dinner-booking-list.table-header.name"
                />
              </TableCell>
              <TableCell style={PHONE_NUMBER_COLUMN}>
                {!bowser.mobile ? (
                  <FormattedMessage
                    defaultMessage="Telefon"
                    id="dinner-booking-list.table-header.phone"
                  />
                ) : null}
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Kunde"
                  id="dinner-booking-list.table-header.customer"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Arbejdssted"
                  id="dinner-booking-list.table-header.work-place"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Aften antal"
                  id="dinner-booking-list.table-header.count"
                />
              </TableCell>
              {this.props.customerSettings.dinnerBookingLocations.length ? (
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Aften afh."
                    id="dinner-booking-list.table-header.dinner-location"
                  />
                </TableCell>
              ) : null}
              {this.props.customerSettings.lunchBookings ? (
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Frokost antal"
                    id="dinner-booking-list.table-header.lunch-count"
                  />
                </TableCell>
              ) : null}
              {this.props.customerSettings.lunchBookingLocations.length ? (
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Frokost afh."
                    id="dinner-booking-list.table-header.lunch-location"
                  />
                </TableCell>
              ) : null}
              <TableCell>
                <FormattedMessage
                  defaultMessage="Bookningstidspkt."
                  id="dinner-booking-list.table-header.booking-time"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={initialsColumnStyle}>
                <strong>
                  <FormattedMessage defaultMessage="Sum" id="dinner-booking-list.table-row.sum" />
                </strong>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>
                <strong>{totalCount}</strong>
              </TableCell>
              {this.props.customerSettings.dinnerBookingLocations.length ? <TableCell /> : null}
              {this.props.customerSettings.lunchBookings ? (
                <TableCell>
                  <strong>{totalLunchCount}</strong>
                </TableCell>
              ) : null}
              {this.props.customerSettings.lunchBookingLocations.length ? <TableCell /> : null}
              <TableCell />
            </TableRow>
            {sumRowArray}
            {rowArray}
          </TableBody>
        </Table>
        <AjaxDownloadButton
          data={pdfData}
          downloadURL={pdfUrl}
          filename={pdfFilename}
          Icon={FilePdfIcon}
          label={formatMessage(messages.downloadPDF)}
          token={this.props.token}
        />
      </PageLayout>
    );
  }
}

const ConnectedDinnerBookingList: React.ComponentType<DinnerBookingListOwnProps> = connect<
  DinnerBookingListStateProps,
  DinnerBookingListDispatchProps,
  DinnerBookingListOwnProps,
  AppState
>(
  createStructuredSelector<AppState, DinnerBookingListStateProps>({
    currentRole: getCurrentRole,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    dinnerBookingArray: getDinnerBookingArray,
    locationLookup: getLocationLookup,
    lunchBookingArray: getLunchBookingArray,
    orderLookup: getOrderLookup,
    pathName: getPathName,
    roleArray: getRoleArray,
    settingsArray: getSettingsArray,
    taskLookup: getTaskLookup,
    timerStartArray: getTimerStartArray,
    token: getToken,
    userArray: getUserArray,
    userLookup: getUserLookup,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {
    create: actions.create,
    remove: actions.remove,
    temporaryQueriesDiscardedForPath: actions.temporaryQueriesDiscardedForPath,
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
    update: actions.update,
  },
)(DinnerBookingList);

export default ConnectedDinnerBookingList;
