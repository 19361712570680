import {NullableStringPair, SettingID} from "@co-common-libs/config";
import {
  WEEKDAY_FRIDAY,
  WEEKDAY_MONDAY,
  WEEKDAY_SATURDAY,
  WEEKDAY_SUNDAY,
  WEEKDAY_THURSDAY,
  WEEKDAY_TUESDAY,
  WEEKDAY_WEDNESDAY,
} from "@co-common-libs/utils";
import {ResponsiveDialog, TimeField} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {DialogContent, FormControlLabel, Grid, Radio, RadioGroup} from "@material-ui/core";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const HH_MM_STRING_LENGTH = "HH:mm".length;

function hourMinutePairOrNull(
  rangeStart: string | null,
  rangeEnd: string | null,
): NullableStringPair | undefined {
  if (rangeStart && rangeEnd) {
    return [
      rangeStart.substring(0, HH_MM_STRING_LENGTH),
      rangeEnd.substring(0, HH_MM_STRING_LENGTH),
    ];
  } else if (!rangeStart && !rangeEnd) {
    return null;
  } else {
    return undefined;
  }
}

interface DayEntryProps {
  disabled: boolean;
  endValue: string | null;
  label: string;
  onEndChange: (value: string | null) => void;
  onStartChange: (value: string | null) => void;
  startValue: string | null;
}

function DayEntry(props: DayEntryProps): React.JSX.Element {
  const {disabled, endValue, label, onEndChange, onStartChange, startValue} = props;
  const intl = useIntl();
  const startOnly = !!startValue && !endValue;
  const endOnly = !!endValue && !startValue;
  const startAfterTo = !!startValue && !!endValue && startValue > endValue;
  return (
    <>
      <h4>{label}</h4>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TimeField
            disabled={disabled}
            error={startOnly || startAfterTo}
            fullWidth
            helperText={
              startOnly
                ? intl.formatMessage({
                    defaultMessage: "Fra klokkeslæt uden til klokkeslæt",
                  })
                : startAfterTo
                  ? intl.formatMessage({
                      defaultMessage: "Fra klokkeslæt efter til klokkeslæt",
                    })
                  : ""
            }
            label={intl.formatMessage({defaultMessage: "Fra klokkeslæt"})}
            margin="dense"
            onChange={onStartChange}
            value={startValue || undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <TimeField
            disabled={disabled}
            error={endOnly}
            fullWidth
            helperText={
              endOnly
                ? intl.formatMessage({
                    defaultMessage: "Til klokkeslæt uden fra klokkeslæt",
                  })
                : ""
            }
            label={intl.formatMessage({defaultMessage: "Til klokkeslæt"})}
            margin="dense"
            onChange={onEndChange}
            value={endValue || undefined}
          />
        </Grid>
      </Grid>
    </>
  );
}

interface AllWeekDaysNullableHoursRangeSettingDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function AllWeekDaysNullableHoursRangeSettingDialog(
  props: AllWeekDaysNullableHoursRangeSettingDialogProps,
): React.JSX.Element {
  const {onClose, open, settingID} = props;

  const intl = useIntl();

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const data:
    | [
        NullableStringPair,
        NullableStringPair,
        NullableStringPair,
        NullableStringPair,
        NullableStringPair,
        NullableStringPair,
        NullableStringPair,
      ]
    | null
    | undefined = settingEntry?.data;

  const currentUserURL = useSelector(getCurrentUserURL);

  const dispatch = useDispatch();

  const [used, setUsed] = useState(!!data);

  const [sundayRangeStart, setSundayRangeStart] = useState<string | null>(
    data?.[WEEKDAY_SUNDAY]?.[0] || null,
  );
  const [sundayRangeEnd, setSundayRangeEnd] = useState<string | null>(
    data?.[WEEKDAY_SUNDAY]?.[1] || null,
  );
  const [mondayRangeStart, setMondayRangeStart] = useState<string | null>(
    data?.[WEEKDAY_MONDAY]?.[0] || null,
  );
  const [mondayRangeEnd, setMondayRangeEnd] = useState<string | null>(
    data?.[WEEKDAY_MONDAY]?.[1] || null,
  );
  const [tuesdayRangeStart, setTuesdayRangeStart] = useState<string | null>(
    data?.[WEEKDAY_TUESDAY]?.[0] || null,
  );
  const [tuesdayRangeEnd, setTuesdayRangeEnd] = useState<string | null>(
    data?.[WEEKDAY_TUESDAY]?.[1] || null,
  );
  const [wednesdayRangeStart, setWednesdayRangeStart] = useState<string | null>(
    data?.[WEEKDAY_WEDNESDAY]?.[0] || null,
  );
  const [wednesdayRangeEnd, setWednesdayRangeEnd] = useState<string | null>(
    data?.[WEEKDAY_WEDNESDAY]?.[1] || null,
  );
  const [thursdayRangeStart, setThursdayRangeStart] = useState<string | null>(
    data?.[WEEKDAY_THURSDAY]?.[0] || null,
  );
  const [thursdayRangeEnd, setThursdayRangeEnd] = useState<string | null>(
    data?.[WEEKDAY_THURSDAY]?.[1] || null,
  );
  const [fridayRangeStart, setFridayRangeStart] = useState<string | null>(
    data?.[WEEKDAY_FRIDAY]?.[0] || null,
  );
  const [fridayRangeEnd, setFridayRangeEnd] = useState<string | null>(
    data?.[WEEKDAY_FRIDAY]?.[1] || null,
  );
  const [saturdayRangeStart, setSaturdayRangeStart] = useState<string | null>(
    data?.[WEEKDAY_SATURDAY]?.[0] || null,
  );
  const [saturdayRangeEnd, setSaturdayRangeEnd] = useState<string | null>(
    data?.[WEEKDAY_SATURDAY]?.[1] || null,
  );

  useEffect(() => {
    if (open) {
      setSundayRangeStart(data?.[WEEKDAY_SUNDAY]?.[0] || null);
      setSundayRangeEnd(data?.[WEEKDAY_SUNDAY]?.[1] || null);
      setMondayRangeStart(data?.[WEEKDAY_MONDAY]?.[0] || null);
      setMondayRangeEnd(data?.[WEEKDAY_MONDAY]?.[1] || null);
      setTuesdayRangeStart(data?.[WEEKDAY_TUESDAY]?.[0] || null);
      setTuesdayRangeEnd(data?.[WEEKDAY_TUESDAY]?.[1] || null);
      setWednesdayRangeStart(data?.[WEEKDAY_WEDNESDAY]?.[0] || null);
      setWednesdayRangeEnd(data?.[WEEKDAY_WEDNESDAY]?.[1] || null);
      setThursdayRangeStart(data?.[WEEKDAY_THURSDAY]?.[0] || null);
      setThursdayRangeEnd(data?.[WEEKDAY_THURSDAY]?.[1] || null);
      setFridayRangeStart(data?.[WEEKDAY_FRIDAY]?.[0] || null);
      setFridayRangeEnd(data?.[WEEKDAY_FRIDAY]?.[1] || null);
      setSaturdayRangeStart(data?.[WEEKDAY_SATURDAY]?.[0] || null);
      setSaturdayRangeEnd(data?.[WEEKDAY_SATURDAY]?.[1] || null);
      setUsed(!!data);
    }
  }, [data, open]);

  const handleUsedChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setUsed(event.target.value === "USED");
  }, []);

  const newValue = useMemo(
    () =>
      used
        ? [
            hourMinutePairOrNull(sundayRangeStart, sundayRangeEnd),
            hourMinutePairOrNull(mondayRangeStart, mondayRangeEnd),
            hourMinutePairOrNull(tuesdayRangeStart, tuesdayRangeEnd),
            hourMinutePairOrNull(wednesdayRangeStart, wednesdayRangeEnd),
            hourMinutePairOrNull(thursdayRangeStart, thursdayRangeEnd),
            hourMinutePairOrNull(fridayRangeStart, fridayRangeEnd),
            hourMinutePairOrNull(saturdayRangeStart, saturdayRangeEnd),
          ]
        : null,
    [
      fridayRangeEnd,
      fridayRangeStart,
      mondayRangeEnd,
      mondayRangeStart,
      saturdayRangeEnd,
      saturdayRangeStart,
      sundayRangeEnd,
      sundayRangeStart,
      thursdayRangeEnd,
      thursdayRangeStart,
      tuesdayRangeEnd,
      tuesdayRangeStart,
      used,
      wednesdayRangeEnd,
      wednesdayRangeStart,
    ],
  );

  const newValueValid =
    !newValue ||
    (newValue.every((value) => value !== undefined) &&
      newValue.some((value) => value !== null) &&
      newValue.every((value) => !value || value[0] <= value[1]));

  const handleSave = useCallback(() => {
    if (!newValueValid) {
      return;
    }
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValue},
        ]),
      );
    }
    onClose();
  }, [newValueValid, settingEntry, onClose, dispatch, currentUserURL, newValue]);

  const okDisabled = !newValueValid;

  return (
    <ResponsiveDialog
      fullWidth
      okDisabled={okDisabled}
      okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
      onCancel={onClose}
      onOk={handleSave}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Angiv periode"
          id="system-setup.dialog-title.specify-period"
        />
      }
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGroup
              name="period-used"
              onChange={handleUsedChange}
              value={used ? "USED" : "UNUSED"}
            >
              <FormControlLabel
                control={<Radio />}
                label={
                  <FormattedMessage
                    defaultMessage="Ikke anvendt"
                    id="setting-dialog.label.unused"
                  />
                }
                value="UNUSED"
              />
              <FormControlLabel
                control={<Radio />}
                label={<FormattedMessage defaultMessage="Anvendt" id="setting-dialog.label.used" />}
                value="USED"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <DayEntry
              disabled={!used}
              endValue={mondayRangeEnd}
              label={intl.formatMessage({defaultMessage: "Mandag"})}
              onEndChange={setMondayRangeEnd}
              onStartChange={setMondayRangeStart}
              startValue={mondayRangeStart}
            />
          </Grid>
          <Grid item xs={12}>
            <DayEntry
              disabled={!used}
              endValue={tuesdayRangeEnd}
              label={intl.formatMessage({defaultMessage: "Tirsdag"})}
              onEndChange={setTuesdayRangeEnd}
              onStartChange={setTuesdayRangeStart}
              startValue={tuesdayRangeStart}
            />
          </Grid>
          <Grid item xs={12}>
            <DayEntry
              disabled={!used}
              endValue={wednesdayRangeEnd}
              label={intl.formatMessage({defaultMessage: "Onsdag"})}
              onEndChange={setWednesdayRangeEnd}
              onStartChange={setWednesdayRangeStart}
              startValue={wednesdayRangeStart}
            />
          </Grid>
          <Grid item xs={12}>
            <DayEntry
              disabled={!used}
              endValue={thursdayRangeEnd}
              label={intl.formatMessage({defaultMessage: "Torsdag"})}
              onEndChange={setThursdayRangeEnd}
              onStartChange={setThursdayRangeStart}
              startValue={thursdayRangeStart}
            />
          </Grid>
          <Grid item xs={12}>
            <DayEntry
              disabled={!used}
              endValue={fridayRangeEnd}
              label={intl.formatMessage({defaultMessage: "Fredag"})}
              onEndChange={setFridayRangeEnd}
              onStartChange={setFridayRangeStart}
              startValue={fridayRangeStart}
            />
          </Grid>
          <Grid item xs={12}>
            <DayEntry
              disabled={!used}
              endValue={saturdayRangeEnd}
              label={intl.formatMessage({defaultMessage: "Lørdag"})}
              onEndChange={setSaturdayRangeEnd}
              onStartChange={setSaturdayRangeStart}
              startValue={saturdayRangeStart}
            />
          </Grid>
          <Grid item xs={12}>
            <DayEntry
              disabled={!used}
              endValue={sundayRangeEnd}
              label={intl.formatMessage({defaultMessage: "Søndag"})}
              onEndChange={setSundayRangeEnd}
              onStartChange={setSundayRangeStart}
              startValue={sundayRangeStart}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </ResponsiveDialog>
  );
}
