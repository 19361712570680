import {Backdrop, FabProps, PropTypes, useTheme} from "@material-ui/core";
import {CloseReason, OpenReason, SpeedDial, SpeedDialIcon} from "@material-ui/lab";
import {SPACING} from "frontend-global-config";
import keycode from "keycode";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FileInputSpeedDialAction} from "./file-input-speed-dial-action";
import {NormalSpeedDialAction} from "./normal-speed-dial-action";
import {FloatingActionButtonData, isNormalFloatingActionButtonData} from "./types";

interface MultiFabSpeedDialProps {
  ariaLabel: string;
  buttons: readonly FloatingActionButtonData[];
  color?: PropTypes.Color | undefined;
  disabled?: boolean | undefined;
  forcedOpen: boolean;
  variant: "component" | "page";
}

const NORMAL_SPACING = 3;

export const MultiFabSpeedDial = React.memo(function MultiFabSpeedDial(
  props: MultiFabSpeedDialProps,
): React.JSX.Element {
  const {ariaLabel, buttons, color, disabled, forcedOpen, variant} = props;

  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const backDropZIndex = theme.zIndex.modal;

  const baseStyling = useMemo((): React.CSSProperties => {
    // while SpeedDial is open, display active SpeedDial above backdrop, otherwise below
    // to avoid pages with multiple SpeedDials appearing to have multiple active at
    // the same time.
    const zIndex = open ? backDropZIndex + 1 : theme.zIndex.speedDial;

    return variant === "component"
      ? {
          position: "absolute",
          right: theme.spacing(SPACING.XSMALL),
          top: theme.spacing(SPACING.SMALL),
          zIndex,
        }
      : {
          bottom: theme.spacing(NORMAL_SPACING),
          position: "fixed",
          right: theme.spacing(NORMAL_SPACING),
          zIndex,
        };
  }, [backDropZIndex, open, theme, variant]);

  const handleClose = useCallback(
    (_event?: object, reason?: CloseReason) => {
      if (reason !== "mouseLeave" && reason !== "blur" && !forcedOpen) {
        setOpen(false);
      }
    },
    [forcedOpen],
  );

  const handleOpen = useCallback(
    (_event: object, reason: OpenReason) => {
      if (reason === "toggle" && !forcedOpen) {
        setOpen(true);
      }
    },
    [forcedOpen],
  );

  const handleBackdropClick = useCallback(() => {
    setOpen(false);
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent): void => {
    if (keycode(event) === "esc") {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    if (!open) {
      return undefined;
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, open]);

  const fabProps: Partial<FabProps> = {
    disabled: disabled === true,
    size: variant === "component" ? "small" : "large",
  };
  if (color) {
    fabProps.color = color;
  }

  const speeddial = (
    <SpeedDial
      ariaLabel={`speeddial-${ariaLabel}`}
      direction={variant === "component" ? "down" : "up"}
      FabProps={fabProps}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={forcedOpen || open}
      style={baseStyling}
    >
      {buttons.map((button) => {
        const {buttonIcon, name, tooltipTitle} = button;

        if (isNormalFloatingActionButtonData(button)) {
          const {onClick} = button;
          return (
            <NormalSpeedDialAction
              buttonIcon={buttonIcon}
              key={name}
              onClick={onClick}
              onClose={handleClose}
              tooltipPlacement="left"
              tooltipTitle={tooltipTitle}
            />
          );
        } else {
          const {accept, id, onChange} = button;

          return (
            <FileInputSpeedDialAction
              accept={accept}
              buttonIcon={buttonIcon}
              inputId={id}
              key={name}
              onChange={onChange}
              onClose={handleClose}
              tooltipPlacement="left"
              tooltipTitle={tooltipTitle}
            />
          );
        }
      })}
    </SpeedDial>
  );

  return (
    <>
      <Backdrop
        onClick={handleBackdropClick}
        open={!forcedOpen && open}
        style={{zIndex: backDropZIndex}}
      />
      {variant === "component" ? <div style={{position: "relative"}}>{speeddial}</div> : speeddial}
    </>
  );
});
