import {Customer, CustomerUrl, Location, LocationUrl, UserUrl} from "@co-common-libs/resources";
import {
  ConnectedLocationDialog,
  LocationDialogOwnProps,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCommitQueue,
  getExtendedCustomerSettings,
  getLocationUseLogArray,
} from "@co-frontend-libs/redux";
import {createLocation} from "app-utils";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LocationCreateData, LocationCreateEditDialog} from "./location-create-edit-dialog";

type LocationSelectCreateDialogProps = Omit<
  LocationDialogOwnProps,
  "includeLogOnlyLocations" | "includeWorkplaceOnlyLocations" | "lastUsedLocations" | "onAdd"
> & {
  customerLookup?: ((url: CustomerUrl) => Customer | undefined) | undefined;
  includeLogOnlyLocations?: boolean;
  includeWorkplaceOnlyLocations?: boolean;
  logOnlyLocation: boolean;
  machineOperator?: UserUrl | null | undefined;
};

export const LocationSelectCreateDialog = React.memo(function LocationSelectCreateDialog({
  customerLookup,
  customerURL,
  hideFieldLocations,
  includeLogOnlyLocations = true,
  includeWorkplaceOnlyLocations = true,
  logOnlyLocation,
  machineOperator,
  onCancel,
  onNone,
  onOk,
  open,
  titleVariant,
}: LocationSelectCreateDialogProps): React.JSX.Element | null {
  const dispatch = useDispatch();

  const {
    locations: {canCreateLocation},
    setLogOnlyLocationOnCreate,
    setWorkplaceOnlyLocationOnCreate,
  } = useSelector(getExtendedCustomerSettings);

  const [createdLocationUrl, setCreatedLocationUrl] = useState<LocationUrl | null>(null);
  const [locationAddDialogOpen, setLocationAddDialogOpen] = useState(false);
  const [locationAddDialogInitialAddress, setLocationAddDialogInitialAddress] = useState("");

  const commitQueue = useSelector(getCommitQueue);

  const handleOnWorkPlaceAdd = useCallback((searchString: string): void => {
    setLocationAddDialogInitialAddress(searchString);
    setLocationAddDialogOpen(true);
  }, []);

  const handleLocationCreateEditDialogCancel = useCallback((): void => {
    setLocationAddDialogOpen(false);
    setLocationAddDialogInitialAddress("");
  }, []);

  // WORKAROUND: https://customoffice.monday.com/boards/2715683423/pulses/7970418866
  //             return of control is given too soon when a lookup for the url is made immediately after
  useEffect(() => {
    if (createdLocationUrl && commitQueue.length === 0) {
      onOk(createdLocationUrl);
    }
  }, [createdLocationUrl, commitQueue, onOk]);

  const handleLocationAddDialogOk = useCallback(
    (data: LocationCreateData): void => {
      setLocationAddDialogOpen(false);
      setLocationAddDialogInitialAddress("");

      const instance: Location = createLocation(data);

      dispatch(actions.create(instance));

      setCreatedLocationUrl(instance.url);
    },
    [dispatch],
  );

  const handleLocationDialogOk = useCallback(
    (location: LocationUrl): void => {
      onOk(location);
    },
    [onOk],
  );
  const locationUseLogArray = useSelector(getLocationUseLogArray);
  const lastUsedLocations = useMemo(() => {
    if (customerURL && machineOperator) {
      const locationUse = locationUseLogArray.find(
        (l) => l.customer === customerURL && l.user === machineOperator,
      );
      return new Set(locationUse?.locations);
    }
    return undefined;
  }, [customerURL, locationUseLogArray, machineOperator]);

  return (
    <>
      <ConnectedLocationDialog
        customerURL={customerURL}
        hideFieldLocations={hideFieldLocations}
        includeLogOnlyLocations={includeLogOnlyLocations}
        includeWorkplaceOnlyLocations={includeWorkplaceOnlyLocations}
        lastUsedLocations={lastUsedLocations}
        onAdd={canCreateLocation && customerURL ? handleOnWorkPlaceAdd : undefined}
        onCancel={onCancel}
        onNone={onNone}
        onOk={handleLocationDialogOk}
        open={open && !locationAddDialogOpen}
        titleVariant={titleVariant}
      />
      <LocationCreateEditDialog
        customerLookup={customerLookup}
        initialCustomer={customerURL}
        initialSearch={locationAddDialogInitialAddress}
        logOnlyLocation={logOnlyLocation ?? setLogOnlyLocationOnCreate}
        onCancel={handleLocationCreateEditDialogCancel}
        onOk={handleLocationAddDialogOk}
        open={locationAddDialogOpen}
        workplaceOnlyLocation={setWorkplaceOnlyLocationOnCreate && !logOnlyLocation}
      />
    </>
  );
});
