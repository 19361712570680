import {createReducer} from "@reduxjs/toolkit";
import * as actions from "./actions";
import {AuthenticationState} from "./types";

const blankState: Omit<AuthenticationState, "loaded"> = {
  error: null,
  organisationId: null,
  shareToken: null,
  token: null,
  userId: null,
};

const initialState: AuthenticationState = {...blankState, loaded: false};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.login.fulfilled, (state, action) => ({
      loaded: state.loaded,
      ...blankState,
      ...action.payload,
    }))
    .addCase(actions.login.rejected, (state, action) => {
      state.error = action.payload || (action.error as any);
    })
    .addCase(actions.logout.fulfilled, (state, _action) => ({
      loaded: state.loaded,
      ...blankState,
    }))
    .addCase(actions.loadSession.fulfilled, (_state, action) => ({
      loaded: true,
      ...blankState,
      ...action.payload,
    }))
    .addCase(actions.loadSession.rejected, (_state, _action) => ({
      loaded: true,
      ...blankState,
    }))
    .addCase(actions.refreshToken.fulfilled, (state, action) => ({
      loaded: state.loaded,
      ...blankState,
      ...action.payload,
    }))
    .addCase(actions.refreshToken.rejected, (state, action) => {
      state.error = action.payload || (action.error as any);
    }),
);
