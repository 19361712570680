import {Task, Timer} from "@co-common-libs/resources";
import {
  formatDateShort,
  formatTime,
  HOUR_MILLISECONDS,
  HOUR_MINUTES,
  MINUTE_MILLISECONDS,
} from "@co-common-libs/utils";
import {useModal} from "@co-frontend-libs/components";
import {computeWorkFromTo} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import _ from "lodash";
import React, {useCallback} from "react";
import {TimelineModal} from "./timeline-modal";
import {IntervalWithTimer} from "./types";

const INTERVAL_BLOCK_HEIGHT = 36;
const HOUR_WIDTH = 120;

interface IntervalBlockProps {
  endMinutes: number;
  startMinutes: number;
  timer: Timer;
}

function IntervalBlock(props: IntervalBlockProps): React.JSX.Element {
  const {endMinutes, startMinutes, timer} = props;
  const startX = (startMinutes * HOUR_WIDTH) / HOUR_MINUTES;
  const endX = (endMinutes * HOUR_WIDTH) / HOUR_MINUTES;
  const style: React.CSSProperties = {
    height: INTERVAL_BLOCK_HEIGHT,
    left: startX,
    position: "absolute",
    top: 0,
    width: endX - startX,
  };
  if (timer) {
    style.backgroundColor = timer.color;
  }
  return <div style={style} />;
}

interface TaskHorisontalTimelineProps {
  intervals: readonly IntervalWithTimer[];
  onActiveTaskIntervalClick:
    | ((fromTimestamp: string, toTimestamp: string, timer: Timer | null) => void)
    | undefined;
  task?: Task;
}

export function TaskHorisontalTimeline(
  props: TaskHorisontalTimelineProps,
): React.JSX.Element | null {
  const {intervals, onActiveTaskIntervalClick, task} = props;
  const [timelineModal, promptCompletedTaskTimelineModal] = useModal(TimelineModal);

  const handleIntervalClick = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      if (task && (task.reportApproved || task.validatedAndRecorded)) {
        promptCompletedTaskTimelineModal({intervals, task});
        return;
      }
      if (!onActiveTaskIntervalClick || !task || !intervals.length) {
        return;
      }
      const now = new Date();
      const nowString = now.toISOString();
      const {workFromTimestamp, workToTimestamp} = computeWorkFromTo(intervals, nowString);
      console.assert(workFromTimestamp);
      console.assert(workToTimestamp);
      const workFrom = new ImmutableDate(workFromTimestamp as string);
      const workTo = new ImmutableDate(workToTimestamp as string);
      const timelineStart = workFrom.setUTCMinutes(0, 0, 0);
      let element: HTMLElement = event.currentTarget;
      let elementX = 0;
      while (element) {
        elementX += element.offsetLeft - element.scrollLeft + element.clientLeft;
        element = element.offsetParent as HTMLElement;
      }
      const x = event.clientX - elementX;
      const minuteClick = (x * HOUR_MINUTES) / HOUR_WIDTH;
      const clickTimestamp = timelineStart.setUTCMinutes(minuteClick);

      if (clickTimestamp < workFrom || clickTimestamp > workTo) {
        return;
      }
      const clickTimestampString = clickTimestamp.toISOString();
      const interval = intervals.find(
        (i) =>
          i.fromTimestamp <= clickTimestampString &&
          (i.toTimestamp || workTo.toISOString()) >= clickTimestampString,
      );
      if (interval) {
        onActiveTaskIntervalClick(
          interval.fromTimestamp,
          interval.toTimestamp || workTo.toISOString(),
          interval.timer,
        );
      } else {
        const lastBefore = _.findLast(intervals, (i) => i.toTimestamp <= clickTimestampString);
        const firstAfter = intervals.find((i) => i.fromTimestamp >= clickTimestampString);
        if (lastBefore && firstAfter) {
          onActiveTaskIntervalClick(lastBefore.toTimestamp, firstAfter.fromTimestamp, null);
        }
      }
    },
    [task, onActiveTaskIntervalClick, intervals, promptCompletedTaskTimelineModal],
  );

  if (!task || !intervals.length) {
    return null;
  }
  const now = new Date();
  const nowString = now.toISOString();
  const {workFromTimestamp, workToTimestamp} = computeWorkFromTo(intervals, nowString);
  console.assert(workFromTimestamp);
  const startHour = new Date(workFromTimestamp as string);
  startHour.setUTCMinutes(0, 0, 0);
  console.assert(workToTimestamp);
  const endHour = new Date(workToTimestamp as string);
  endHour.setUTCHours(endHour.getUTCHours() + 1, 0, 0, 0);

  const hourCount = (endHour.valueOf() - startHour.valueOf()) / HOUR_MILLISECONDS;

  const intervalBlocks = intervals.map((interval, index) => {
    const fromTimestamp = new Date(interval.fromTimestamp);
    const toTimestamp = new Date(interval.toTimestamp || now);
    const startMinutes = Math.round(
      (fromTimestamp.valueOf() - startHour.valueOf()) / MINUTE_MILLISECONDS,
    );
    const endMinutes = Math.round(
      (toTimestamp.valueOf() - startHour.valueOf()) / MINUTE_MILLISECONDS,
    );
    return (
      <IntervalBlock
        endMinutes={endMinutes}
        key={index}
        startMinutes={startMinutes}
        timer={interval.timer}
      />
    );
  });

  const hourRange = _.range(0, hourCount + 1);
  const NUMBER_WIDTH = 38;
  const hourHeaders = hourRange.map((n) => {
    const offset = HOUR_WIDTH * n;
    const hour = new Date(startHour);
    hour.setUTCHours(startHour.getUTCHours() + n);
    const style: React.CSSProperties = {
      left: offset - NUMBER_WIDTH / 2,
      position: "absolute",
      textAlign: "center",
      width: NUMBER_WIDTH,
    };
    return (
      <span key={n} style={style}>
        {formatDateShort(hour)}
        <br />
        {formatTime(hour)}
      </span>
    );
  });

  const timeLineStyle: React.CSSProperties = {
    height: INTERVAL_BLOCK_HEIGHT,

    marginLeft: NUMBER_WIDTH / 2,

    marginRight: NUMBER_WIDTH / 2,
    position: "absolute",
    width: hourCount * HOUR_WIDTH,
  };
  return (
    <>
      {timelineModal}
      <div
        style={{
          height: 3 * INTERVAL_BLOCK_HEIGHT,
          overflowY: "auto",
          position: "relative",
        }}
      >
        <div style={timeLineStyle}>{hourHeaders}</div>
        <div
          onClick={handleIntervalClick}
          style={{
            backgroundColor: "#eee",

            top: 1.5 * INTERVAL_BLOCK_HEIGHT,
            ...timeLineStyle,
          }}
        >
          {intervalBlocks}
        </div>
      </div>
    </>
  );
}
