import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";

export const NullBooleanSelection = React.memo(function NullBooleanSelection({
  falseLabel,
  label,
  nullLabel,
  onChange,
  trueLabel,
  value,
}: {
  falseLabel?: string;
  label: string;
  nullLabel: string;
  onChange: (value: boolean | null) => void;
  trueLabel?: string;
  value: boolean | null;
}): React.JSX.Element {
  const intl = useIntl();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === "yes") {
        onChange(true);
      } else if (event.target.value === "no") {
        onChange(false);
      } else {
        console.assert(event.target.value === "inherit");
        onChange(null);
      }
    },
    [onChange],
  );
  return (
    <FormControl component="fieldset">
      <b>{label}</b>
      <RadioGroup onChange={handleChange} value={value == null ? "inherit" : value ? "yes" : "no"}>
        <FormControlLabel control={<Radio />} label={nullLabel} value="inherit" />
        <FormControlLabel
          control={<Radio />}
          label={trueLabel || intl.formatMessage({defaultMessage: "Ja"})}
          value="yes"
        />
        <FormControlLabel
          control={<Radio />}
          label={falseLabel || intl.formatMessage({defaultMessage: "Nej"})}
          value="no"
        />
      </RadioGroup>
    </FormControl>
  );
});
