import {getMachineString} from "@co-common-libs/resources-utils";
import {IntlShape} from "react-intl";
import {EconomicMachinesIssue} from "../types";

// eslint-disable-next-line consistent-return
export function economicVehicleIssueTranslation(
  issue: EconomicMachinesIssue,
  intl: IntlShape,
): string {
  const {instances, issueType} = issue;
  const count = instances.length;
  const names = instances.map(getMachineString).join(", ");

  switch (issueType) {
    case "barred":
      return intl.formatMessage(
        {
          defaultMessage:
            "{count, plural, one {Følgende køretøj} other {# køretøjer}} er slettet eller spærret i e-conomic: {names}",
        },
        {count, names},
      );
    case "inactive":
      return intl.formatMessage(
        {
          defaultMessage:
            "{count, plural, one {Følgende køretøj} other {# køretøjer}} er markeret inaktiv: {names}",
        },
        {
          count,
          names,
        },
      );
    case "missingRemoteIdentifier":
      return intl.formatMessage(
        {
          defaultMessage:
            "{count, plural, one {Følgende køretøj} other {# køretøjer}} mangler køretøjsnummer: {names}",
        },
        {count, names},
      );
    case "missingRemoteUrl":
      return intl.formatMessage(
        {
          defaultMessage:
            "{count, plural, one {Følgende køretøj} other {# køretøjer}} er ikke oprettet i e-conomic: {names}",
        },
        {count, names},
      );
  }
}
