import {useResettingState} from "@co-frontend-libs/utils";
import {DialogContent, IconButton, Typography} from "@material-ui/core";
import {green, red} from "@material-ui/core/colors";
import {useFalseCallback, useTrueCallback} from "app-utils";
import {clone} from "lodash";
import ThumbDownIcon from "mdi-react/ThumbDownIcon";
import ThumbDownOutlineIcon from "mdi-react/ThumbDownOutlineIcon";
import ThumbUpIcon from "mdi-react/ThumbUpIcon";
import ThumbUpOutlineIcon from "mdi-react/ThumbUpOutlineIcon";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ResponsiveDialog, ResponsiveDialogProps} from "./responsive-dialog";

const baseSize = 48;
const buttonStyle: React.CSSProperties = {
  height: 2 * baseSize,
  padding: baseSize / 2,
  width: 2 * baseSize,
};

const WEEKEND_DAYS = ["saturday", "sunday"] as const;

export type WeekDay =
  | "friday"
  | "monday"
  | "saturday"
  | "sunday"
  | "thursday"
  | "tuesday"
  | "wednesday";

interface DayBlockProps {
  day: WeekDay;
  initialValue: boolean | undefined;
  onChange: (day: WeekDay, value: boolean) => void;
  value: boolean;
}

function DayBlock(props: DayBlockProps): React.JSX.Element {
  const {day, initialValue, onChange, value} = props;

  const dayLabel = {
    friday: <FormattedMessage defaultMessage="Fredag" />,
    monday: <FormattedMessage defaultMessage="Mandag" />,
    saturday: <FormattedMessage defaultMessage="Lørdag" />,
    sunday: <FormattedMessage defaultMessage="Søndag" />,
    thursday: <FormattedMessage defaultMessage="Torsdag" />,
    tuesday: <FormattedMessage defaultMessage="Tirsdag" />,
    wednesday: <FormattedMessage defaultMessage="Onsdag" />,
  }[day];

  const handleThumbUpClick = useTrueCallback((): void => {
    onChange(day, true);
  }, [day, onChange]);

  const handleThumbDownClick = useFalseCallback((): void => {
    onChange(day, false);
  }, [day, onChange]);

  return (
    <Typography>
      <div style={{display: "inline-block", width: "7ex"}}>{dayLabel}</div>
      <IconButton onClick={handleThumbUpClick} style={buttonStyle}>
        {value ? (
          <ThumbUpIcon color={green[300]} size={baseSize} />
        ) : (
          <ThumbUpOutlineIcon size={baseSize} />
        )}
      </IconButton>
      <IconButton disabled={!!initialValue} onClick={handleThumbDownClick} style={buttonStyle}>
        {value ? (
          <ThumbDownOutlineIcon size={baseSize} />
        ) : (
          <ThumbDownIcon color={red[300]} size={baseSize} />
        )}
      </IconButton>
    </Typography>
  );
}

interface AvailabilityDialogProps extends Pick<ResponsiveDialogProps, "onCancel" | "open"> {
  initialValues: Partial<{[day in WeekDay]: boolean | null}>;
  onOk: (values: ReadonlyMap<WeekDay, boolean>) => void;
  weekdays: readonly WeekDay[] | undefined;
  weekends: boolean;
}

export function AvailabilityDialog(props: AvailabilityDialogProps): React.JSX.Element {
  const {initialValues, onCancel, onOk, open, weekdays, weekends} = props;

  const intl = useIntl();

  const days = useMemo((): ReadonlySet<WeekDay> => {
    if (weekdays?.length && weekends) {
      return new Set([...weekdays, ...WEEKEND_DAYS]);
    } else if (weekdays?.length) {
      return new Set(weekdays);
    } else if (weekends) {
      return new Set(WEEKEND_DAYS);
    } else {
      return new Set<WeekDay>();
    }
  }, [weekdays, weekends]);

  const initialState = useMemo(() => {
    return new Map(
      Array.from(days).map((day) => {
        const initialValue = initialValues && initialValues[day];
        return [day, initialValue != null ? initialValue : true];
      }),
    );
  }, [days, initialValues]);

  const [values, setValues] = useResettingState(initialState, open);

  const handleValueChange = useCallback(
    (day: WeekDay, value: boolean): void => {
      // need new object identity for React to update
      const valuesCopy = clone(values);
      valuesCopy.set(day, value);
      setValues(valuesCopy);
    },
    [setValues, values],
  );

  const handleDialogOk = useCallback((): void => {
    onOk(values);
  }, [onOk, values]);

  return (
    <ResponsiveDialog
      onCancel={onCancel}
      onOk={handleDialogOk}
      open={open}
      title={intl.formatMessage({defaultMessage: "Rådighed"})}
    >
      <DialogContent>
        {weekends && !weekdays ? (
          <FormattedMessage defaultMessage="Jeg kan arbejde i weekenden:" />
        ) : (
          <FormattedMessage defaultMessage="Jeg kan arbejde ekstra følgende dage:" />
        )}
        {Array.from(days).map((day) => {
          const value = initialValues && initialValues[day];
          return (
            <DayBlock
              day={day}
              initialValue={value != null ? value : undefined}
              key={day}
              onChange={handleValueChange}
              value={values.get(day) ?? true}
            />
          );
        })}
      </DialogContent>
    </ResponsiveDialog>
  );
}
