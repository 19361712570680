import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";

interface RemoveRecordedInC5DialogProps {
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

export function RemoveRecordedInC5Dialog(props: RemoveRecordedInC5DialogProps): React.JSX.Element {
  const {onCancel, onOk, open} = props;
  return (
    <ResponsiveDialog
      autoFocusCancel
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Fjern bogført status?"
          id="remove-recoded-in-c5-dialog.remove-recorded"
        />
      }
    >
      <DialogContent>
        <FormattedMessage
          defaultMessage="Er du sikker på at du vil fjerne denne opgaves bogført status?"
          id="remove-recoded-in-c5-dialog.confirmation-message"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
