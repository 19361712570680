import {Command} from "@co-common-libs/resources";
import {wrapSQLError} from "../types";
import {CommitDBConnection, OldCommand, SerializableError} from "./types";
import {translateOldCommand} from "./utils";

const EMPTY_VERSION = 0;
const UNVERSIONED_DATA_VERSION = 1;
const BEFORE_JSON_PATCH = 2;
const CURRENT_VERSION = 3;

function executeSql(
  db: SQLitePlugin.Database,
  statement: string,
  params: any[] = [],
): Promise<SQLitePlugin.Results> {
  return new Promise(
    (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
      db.executeSql(statement, params, resolve, reject);
    },
  );
}

function sqlBatch(
  db: SQLitePlugin.Database,
  sqlStatements: (string | [string, any[]])[],
): Promise<void> {
  return new Promise<void>(
    (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
      db.sqlBatch(sqlStatements, resolve, reject);
    },
  );
}

const getVersion = (db: SQLitePlugin.Database): Promise<number> =>
  executeSql(db, "SELECT version FROM __sys__")
    .then((result) => {
      const {rows} = result;
      if (rows.length > 0) {
        return rows.item(0).version;
      }

      return executeSql(db, "INSERT INTO __sys__ (version) VALUES (0)").then(() => 0);
    })
    .catch(wrapSQLError("getVersion"));

async function upgrade(
  db: SQLitePlugin.Database,
  oldVersion: number,
): Promise<SQLitePlugin.Database> {
  if (oldVersion === EMPTY_VERSION) {
    await sqlBatch(db, [
      "CREATE TABLE IF NOT EXISTS commitpatchqueue (" +
        "id INTEGER PRIMARY KEY, " +
        "action TEXT NOT NULL," +
        "url TEXT NOT NULL," +
        "instance BLOB," +
        "patch BLOB," +
        "error BLOB," +
        "error_timestamp TEXT," +
        "api_version TEXT," +
        "frontend_version TEXT)",
      ["UPDATE __sys__ SET version = ?", [CURRENT_VERSION]],
    ]);
  } else if (oldVersion === UNVERSIONED_DATA_VERSION) {
    await sqlBatch(db, [
      "CREATE TABLE IF NOT EXISTS commitpatchqueue (" +
        "id INTEGER PRIMARY KEY, " +
        "action TEXT NOT NULL," +
        "url TEXT NOT NULL," +
        "instance BLOB," +
        "patch BLOB," +
        "error BLOB," +
        "error_timestamp TEXT," +
        "api_version TEXT," +
        "frontend_version TEXT)",
    ]);
    // api_version and frontend_version not yet added
    const result = await executeSql(
      db,
      "SELECT id, action, instance, error, error_timestamp FROM commitqueue ORDER BY id ASC",
    );
    const {rows} = result;
    const translated: [string, any[]][] = [];
    for (let i = 0; i < rows.length; i += 1) {
      const row = rows.item(i);
      const {
        action,

        error: errorText,
        error_timestamp: errorTimestamp,
        id,
        instance: instanceText,
      } = row;
      const instance = JSON.parse(instanceText);
      const oldCommand: OldCommand = {action, instance};
      const command = translateOldCommand(oldCommand);
      const newInstanceText = command.action === "CREATE" ? JSON.stringify(command.instance) : null;
      const patchText = command.action === "UPDATE" ? JSON.stringify(command.patch) : null;
      translated.push([
        "INSERT INTO commitpatchqueue " +
          "(id, action, url, instance, patch, error, error_timestamp) " +
          "VALUES " +
          "(?, ?, ?, ?, ?, ?, ?) " +
          "ON CONFLICT(id) DO NOTHING",
        [id, action, command.url, newInstanceText, patchText, errorText, errorTimestamp],
      ]);
    }
    if (translated.length) {
      await sqlBatch(db, translated);
    }
    await sqlBatch(db, [
      "DROP TABLE commitqueue",
      ["UPDATE __sys__ SET version = ?", [CURRENT_VERSION]],
    ]);
  } else if (oldVersion === BEFORE_JSON_PATCH) {
    await sqlBatch(db, [
      "CREATE TABLE IF NOT EXISTS commitpatchqueue (" +
        "id INTEGER PRIMARY KEY, " +
        "action TEXT NOT NULL," +
        "url TEXT NOT NULL," +
        "instance BLOB," +
        "patch BLOB," +
        "error BLOB," +
        "error_timestamp TEXT," +
        "api_version TEXT," +
        "frontend_version TEXT)",
    ]);
    const result = await executeSql(
      db,
      "SELECT id, action, instance, error, error_timestamp, api_version, frontend_version FROM commitqueue ORDER BY id ASC",
    );
    const {rows} = result;
    const translated: [string, any[]][] = [];
    for (let i = 0; i < rows.length; i += 1) {
      const row = rows.item(i);
      const {
        action,

        api_version: apiVersion,

        error: errorText,
        error_timestamp: errorTimestamp,

        frontend_version: frontendVersion,
        id,
        instance: instanceText,
      } = row;
      const instance = JSON.parse(instanceText);
      const oldCommand: OldCommand = {action, instance};
      const command = translateOldCommand(oldCommand);
      const newInstanceText = command.action === "CREATE" ? JSON.stringify(command.instance) : null;
      const patchText = command.action === "UPDATE" ? JSON.stringify(command.patch) : null;
      translated.push([
        "INSERT INTO commitpatchqueue " +
          "(id, action, url, instance, patch, error, error_timestamp, api_version, frontend_version) " +
          "VALUES " +
          "(?, ?, ?, ?, ?, ?, ?, ?, ?) " +
          "ON CONFLICT(id) DO NOTHING",
        [
          id,
          action,
          command.url,
          newInstanceText,
          patchText,
          errorText,
          errorTimestamp,
          apiVersion,
          frontendVersion,
        ],
      ]);
    }
    if (translated.length) {
      await sqlBatch(db, translated);
    }
    await sqlBatch(db, [
      "DROP TABLE commitqueue",
      ["UPDATE __sys__ SET version = ?", [CURRENT_VERSION]],
    ]);
  }
  return db;
}

const baseOpenConnection = (
  sqlitePlugin: SQLitePlugin.SQLite,
  dbName: string,
): Promise<SQLitePlugin.Database> => {
  return new Promise(
    (resolve: SQLitePlugin.DatabaseSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
      sqlitePlugin.openDatabase({location: "default", name: dbName}, resolve, reject);
    },
  ).catch(() => {
    return new Promise(
      (resolve: SQLitePlugin.DatabaseSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        const RETRY_AFTER_MILLISECONDS = 2;
        setTimeout(() => {
          sqlitePlugin.openDatabase({location: "default", name: dbName}, resolve, reject);
        }, RETRY_AFTER_MILLISECONDS);
        sqlitePlugin.openDatabase({location: "default", name: dbName}, resolve, reject);
      },
    );
  });
};

const openConnection = async (
  sqlitePlugin: SQLitePlugin.SQLite,
  dbName: string,
): Promise<SQLitePlugin.Database> => {
  const db = await baseOpenConnection(sqlitePlugin, dbName);
  return new Promise(
    (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
      db.executeSql("CREATE TABLE IF NOT EXISTS __sys__ (version INTEGER)", [], resolve, reject);
    },
  )
    .then(() => getVersion(db))
    .then((version) => {
      if (version < CURRENT_VERSION) {
        return upgrade(db, version);
      } else {
        return db;
      }
    })
    .catch(wrapSQLError("openConnection"));
};

class CordovaSQLiteStorageCommitDBConnection extends CommitDBConnection {
  private connection: SQLitePlugin.Database;
  private dbName: string;
  private sqlitePlugin: SQLitePlugin.SQLite;
  constructor(
    connection: SQLitePlugin.Database,
    sqlitePlugin: SQLitePlugin.SQLite,
    dbName: string,
  ) {
    super();
    this.connection = connection;
    this.sqlitePlugin = sqlitePlugin;
    this.dbName = dbName;
  }
  close(): Promise<void> {
    return new Promise<void>(
      (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        this.connection.close(resolve, reject);
      },
    ).catch(() => {
      return new Promise<void>(
        (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
          const RETRY_AFTER_MILLISECONDS = 2;
          setTimeout(() => {
            this.connection.close(resolve, reject);
          }, RETRY_AFTER_MILLISECONDS);
        },
      );
    });
  }
  delete(id: number): Promise<void> {
    return this.query("DELETE FROM commitpatchqueue WHERE id=?", [id]).then(() => {
      return;
    });
  }
  deleteDatabase(): Promise<void> {
    return new Promise(
      (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        return this.sqlitePlugin.deleteDatabase(
          {location: "default", name: this.dbName},
          resolve,
          reject,
        );
      },
    );
  }
  getAll(): Promise<
    readonly {
      readonly apiVersion: string | null;
      readonly command: Command;
      readonly error: SerializableError | null;
      readonly errorTimestamp: string | null;
      readonly frontendVersion: string | null;
      readonly id: number;
    }[]
  > {
    return this.query(
      "SELECT id, action, url, instance, patch, error, error_timestamp, api_version, frontend_version FROM commitpatchqueue ORDER BY id ASC",
      [],
    ).then(({rows}) => {
      const result: {
        apiVersion: string | null;
        command: Command;
        error: SerializableError | null;
        errorTimestamp: string | null;
        frontendVersion: string | null;
        id: number;
      }[] = [];
      for (let i = 0; i < rows.length; i += 1) {
        const row = rows.item(i);
        const {
          action,

          api_version: apiVersion,

          error: errorText,
          error_timestamp: errorTimestamp,

          frontend_version: frontendVersion,
          id,
          instance: instanceText,
          patch: patchText,
          url,
        } = row;
        let command: Command;
        if (action === "CREATE") {
          command = {action: "CREATE", instance: JSON.parse(instanceText), url};
        } else if (action === "UPDATE") {
          command = {action: "UPDATE", patch: JSON.parse(patchText), url};
        } else {
          console.assert(action === "DELETE");
          command = {action: "DELETE", url};
        }
        const error = errorText ? JSON.parse(errorText) : null;
        console.assert(typeof id === "number");
        result.push({
          apiVersion,
          command,
          error,
          errorTimestamp,
          frontendVersion,
          id,
        });
      }
      return result;
    });
  }
  put(id: number, command: Command, apiVersion: string, frontendVersion: string): Promise<void> {
    // NTS: while upsert may not be supported by the system version of SQLite;
    // we use a more recent version included in cordova-sqlite-storage...
    const {action, url} = command;
    const instanceText = command.action === "CREATE" ? JSON.stringify(command.instance) : null;
    const patchText = command.action === "UPDATE" ? JSON.stringify(command.patch) : null;
    return this.query(
      "INSERT INTO commitpatchqueue " +
        "(id, action, url, instance, patch, error, error_timestamp, api_version, frontend_version) " +
        "VALUES " +
        "(?, ?, ?, ?, ?, NULL, NULL, ?, ?) " +
        "ON CONFLICT(id) " +
        "DO UPDATE SET " +
        "action=excluded.action, " +
        "url=excluded.url, " +
        "instance=excluded.instance, " +
        "patch=excluded.patch, " +
        "error=NULL, " +
        "error_timestamp=NULL, " +
        "api_version=excluded.api_version, " +
        "frontend_version=excluded.frontend_version",
      [id, action, url, instanceText, patchText, apiVersion, frontendVersion],
    ).then(() => {
      return;
    });
  }
  setError(id: number, error: SerializableError, errorTimestamp: string): Promise<void> {
    const errorText = JSON.stringify(error);
    return this.query("UPDATE commitpatchqueue SET error=?, error_timestamp=? WHERE id=?", [
      errorText,
      errorTimestamp,
      id,
    ]).then(() => {
      return;
    });
  }
  vacuum(): Promise<void> {
    return this.query("VACUUM", []).then(() => undefined);
  }
  private query(sql: string, params: any[] = []): Promise<SQLitePlugin.Results> {
    return new Promise(
      (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        this.connection.executeSql(sql, params, resolve, reject);
      },
    );
  }
}

export const getCordovaSQLiteStorageConnection = (
  sqlitePlugin: SQLitePlugin.SQLite,
  dbName: string,
): Promise<CommitDBConnection> =>
  openConnection(sqlitePlugin, dbName).then(
    (connection) => new CordovaSQLiteStorageCommitDBConnection(connection, sqlitePlugin, dbName),
  );
