import {Config} from "@co-common-libs/config";
import {DecimalField, HoursField} from "@co-frontend-libs/components";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedMessage} from "react-intl";

interface MaterialFieldProps {
  booleanSelection?: boolean | undefined;
  customerSettings: Config;
  decimalPlaces?: number;
  disabled: boolean;
  hideError?: boolean;
  hideLabel?: boolean;
  id?: string;
  onChange: (value: number | null) => void;
  unit: string;
  value?: number | undefined;
}

export const MaterialField = React.memo(function MaterialField(
  props: MaterialFieldProps,
): React.JSX.Element {
  const {
    booleanSelection,
    customerSettings,
    decimalPlaces,
    disabled,
    hideError,
    hideLabel,
    id,
    onChange,
    unit,
    value,
  } = props;
  const handleBooleanSelectionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => onChange(parseInt(event.target.value)),
    [onChange],
  );
  if (booleanSelection) {
    return (
      <RadioGroup name="boolean" onChange={handleBooleanSelectionChange} value={value ?? null}>
        <FormControlLabel
          control={<Radio />}
          disabled={disabled}
          label={<FormattedMessage defaultMessage="Ja" id="material-field.label.yes" />}
          value={1}
        />
        <FormControlLabel
          control={<Radio />}
          disabled={disabled}
          label={<FormattedMessage defaultMessage="Nej" id="material-field.label.no" />}
          value={0}
        />
      </RadioGroup>
    );
  }

  const optionalIdProp: {id?: string} = {};
  if (id) {
    optionalIdProp.id = id;
  }

  const unitLower = unit.toLowerCase();
  if (
    unitLower === "timer" ||
    unitLower === "time" ||
    unitLower === "tim" ||
    unitLower === "tim." ||
    unitLower === "time(r)"
  ) {
    return (
      <HoursField
        disabled={disabled}
        fullWidth
        hideError={hideError || false}
        label={hideLabel ? undefined : "Antal"}
        margin="dense"
        minQuarter
        onChange={onChange}
        value={value != null ? value : null}
        {...optionalIdProp}
      />
    );
  } else {
    return (
      <DecimalField
        decimalPlaces={decimalPlaces || customerSettings.materialDecimals}
        disabled={disabled}
        fullWidth
        hideError={hideError || false}
        label={hideLabel ? undefined : "Antal"}
        margin="dense"
        maxDigits={9}
        onChange={onChange}
        value={value != null ? value : null}
        {...optionalIdProp}
      />
    );
  }
});
