import {Config} from "@co-common-libs/config";
import {Culture, ResourceTypeUnion, Role, urlToId} from "@co-common-libs/resources";
import {
  AppbarSearchField,
  SingleTextFieldDialog,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {makeQuery, Query} from "@co-frontend-libs/db-resources";
import {
  actions,
  AppState,
  getCurrentRole,
  getCustomerSettings,
  getPathName,
  makeQueryParameterGetter,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Tab, Tabs} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {CultureTable} from "./culture-table";

const MAX_CULTURES_DISPLAYED = 100;

const messages = defineMessages({
  active: {
    defaultMessage: "Aktive",
    id: "culture-list.tab-label.active",
  },
  all: {
    defaultMessage: "Alle",
    id: "culture-list.tab-label.all",
  },
  cultures: {
    defaultMessage: "Kulturer",
    id: "culture-list.title.cultures",
  },
  newCulture: {
    defaultMessage: "Ny kultur",
    id: "culture-list.title.new-culture",
  },
});

const DEBOUNCE_WAIT_MS = 300;

interface CultureListStateProps {
  currentRole: Role | null;
  customerSettings: Config;
  pathName: string;
  q: string;
  tab: string;
}

interface CultureListDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  deleteQueryKey: (key: string, navigationKind?: PartialNavigationKind) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
}

interface CultureListOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type CultureListProps = CultureListDispatchProps & CultureListOwnProps & CultureListStateProps;

interface CultureListState {
  nameDialogOpen: boolean;
}

const TEMPORARY_QUERIES_KEY = "CultureList";

class CultureList extends React.Component<CultureListProps, CultureListState> {
  static contextType = IntlContext;
  archiveSearchChange = _.debounce((value: string, pathName: string) => {
    const newQuery = makeQuery({
      check: {type: "alwaysOk"},
      filter: {
        search: value,
      },
      independentFetch: true,
      limit: MAX_CULTURES_DISPLAYED + 1,
      resourceName: "culture",
      sortBy: ["name"],
    });
    this.props.temporaryQueriesRequestedForPath([newQuery], pathName, TEMPORARY_QUERIES_KEY);
  }, DEBOUNCE_WAIT_MS);
  context!: React.ContextType<typeof IntlContext>;
  state: CultureListState = {
    nameDialogOpen: false,
  };
  componentWillUnmount(): void {
    this.archiveSearchChange.cancel();
  }
  @bind
  handleCultureTableClick(cultureURL: string): void {
    const {go} = this.props;
    const id = urlToId(cultureURL);
    go("/culture/:id", {id});
  }
  @bind
  handleFabButton(): void {
    this.setState({nameDialogOpen: true});
  }

  @bind
  handleFilterStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    const {pathName, putQueryKey, tab} = this.props;
    if (tab === "all") {
      this.archiveSearchChange(value || "", pathName);
    }
    putQueryKey("q", value || "");
  }

  @bind
  handleNameDialogCancel(): void {
    this.setState({nameDialogOpen: false});
  }

  @bind
  handleNameDialogOk(name: string): void {
    this.setState({nameDialogOpen: false});
    const id = uuid();
    const url = instanceURL("culture", id);
    const data: Culture = {
      active: true,
      id,
      identifier: "",
      name,
      remoteUrl: "",
      url,
    };
    this.props.create(data);
    this.props.go("/culture/:id", {id});
  }

  @bind
  handleTabChange(_event: React.ChangeEvent<unknown>, value: string): void {
    const {pathName, putQueryKey, q} = this.props;
    putQueryKey("tab", value || "");
    if (value === "all") {
      this.archiveSearchChange(q, pathName);
    }
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const filterString = this.props.q;
    const activeTab = this.props.tab;

    const right = (
      <AppbarSearchField onChange={this.handleFilterStringChange} value={this.props.q} />
    );
    let action;
    if (this.props.customerSettings.canCreateCultures && (this.props.currentRole as Role).manager) {
      action = (
        <VerticalStackingFloatingActionButton onClick={this.handleFabButton} stackIndex={0}>
          <PlusIcon />
        </VerticalStackingFloatingActionButton>
      );
    }
    return (
      <PageLayout
        floatingActionButton={action}
        tabs={
          <Tabs
            onChange={this.handleTabChange}
            value={this.props.tab}
            variant={bowser.mobile ? "fullWidth" : "standard"}
          >
            <Tab label={formatMessage(messages.active)} value="active" />
            <Tab label={formatMessage(messages.all)} value="all" />
          </Tabs>
        }
        toolbar={
          <MenuToolbar
            onMenuButton={this.props.onMenuButton}
            rightElement={right}
            title={formatMessage(messages.cultures)}
          />
        }
        withBottomScrollPadding
      >
        <CultureTable
          filterString={filterString}
          onClick={this.props.currentRole?.manager ? this.handleCultureTableClick : undefined}
          onlyActive={activeTab === "active"}
        />
        <SingleTextFieldDialog
          label={<FormattedMessage defaultMessage="Navn" id="culture-list.label.name" />}
          onCancel={this.handleNameDialogCancel}
          onOk={this.handleNameDialogOk}
          open={this.state.nameDialogOpen}
          title={formatMessage(messages.newCulture)}
        />
      </PageLayout>
    );
  }

  UNSAFE_componentWillMount(): void {
    const {pathName, q, tab} = this.props;
    if (tab === "all") {
      this.archiveSearchChange(q, pathName);
    }
  }
}

const ConnectedCultureList: React.ComponentType<CultureListOwnProps> = connect<
  CultureListStateProps,
  CultureListDispatchProps,
  CultureListOwnProps,
  AppState
>(
  createStructuredSelector<AppState, CultureListStateProps>({
    currentRole: getCurrentRole,
    customerSettings: getCustomerSettings,
    pathName: getPathName,
    q: makeQueryParameterGetter("q", ""),
    tab: makeQueryParameterGetter("tab", "active"),
  }),
  {
    create: actions.create,
    deleteQueryKey: actions.deleteQueryKey,
    go: actions.go,
    putQueryKey: actions.putQueryKey,
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
  },
)(CultureList);

export {ConnectedCultureList as CultureList};
