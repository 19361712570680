import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Checkbox,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {MultiSelectableListItem} from "app-components";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {defaultAbsenceTypeLabels} from "./absence-type-labels";
import {DisplaySelected} from "./display-selected";

const messages = defineMessages({
  notExisting: {
    defaultMessage: "Fraværstypen eksisterer ikke",
    id: "select-absence-types.label.not-existing",
  },
});

export const SelectAbsenceTypes = React.memo(function SelectAbsenceTypes(
  props: SettingViewProps,
): React.JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const currentSelected: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);

  const customerSettings = useSelector(getCustomerSettings);
  const {absenceTypeLabels} = customerSettings;

  const usedAbsenceTypeLabels: {[key: string]: string} = useMemo(
    () => ({
      ...defaultAbsenceTypeLabels,
      ...absenceTypeLabels,
    }),
    [absenceTypeLabels],
  );
  const {formatMessage} = useIntl();
  const absenceTypeNames = currentSelected.map(
    (d) => usedAbsenceTypeLabels[d] || formatMessage(messages.notExisting),
  );

  const [selected, setSelected] = useState(new Set(currentSelected));

  useEffect(() => {
    setSelected(new Set(currentSelected));
  }, [currentSelected]);

  const [absenceTypesDialogOpen, setAbsenceTypesDialogOpen] = useState(false);
  const setAbsenceTypesDialogOpenTrue = useCallWithTrue(setAbsenceTypesDialogOpen);
  const setAbsenceTypesDialogOpenFalse = useCallWithFalse(setAbsenceTypesDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleDialogOk = useCallback(() => {
    setAbsenceTypesDialogOpen(false);

    const newValue = [...selected];
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValue},
        ]),
      );
    }
  }, [currentUserURL, dispatch, selected, settingEntry]);

  const handleCheck = useCallback(
    (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
      const newSelected = new Set(selected);
      if (selected.has(value)) {
        newSelected.delete(value);
      } else {
        newSelected.add(value);
      }
      setSelected(newSelected);
    },
    [selected],
  );

  const listItems = useMemo(
    () =>
      Object.keys(usedAbsenceTypeLabels).map((absenceType) => {
        return (
          <MultiSelectableListItem
            isSelected={selected.has(absenceType)}
            key={absenceType}
            onCheck={handleCheck}
            primaryText={usedAbsenceTypeLabels[absenceType]}
            value={absenceType}
          />
        );
      }),
    [handleCheck, selected, usedAbsenceTypeLabels],
  );

  const absenceTypeCount = _.size(usedAbsenceTypeLabels);
  const handleToggleAll = useCallback(() => {
    if (Object.keys(usedAbsenceTypeLabels).every((absenceType) => selected.has(absenceType))) {
      setSelected(new Set<string>());
    } else {
      setSelected(new Set(Object.keys(usedAbsenceTypeLabels)));
    }
  }, [selected, usedAbsenceTypeLabels]);

  return (
    <>
      <DisplaySelected
        currentValue={absenceTypeNames}
        onClick={setAbsenceTypesDialogOpenTrue}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
      />
      <ResponsiveDialog
        onCancel={setAbsenceTypesDialogOpenFalse}
        onOk={handleDialogOk}
        open={absenceTypesDialogOpen}
        title={
          <FormattedMessage defaultMessage="Vælg fraværstyper" id="select-absence-types.title" />
        }
      >
        <DialogContent dividers style={{padding: 0}}>
          <List>
            <ListItem button onClick={handleToggleAll}>
              <ListItemIcon>
                <Checkbox
                  checked={selected.size === absenceTypeCount}
                  disableRipple
                  edge="start"
                  indeterminate={selected.size > 0 && selected.size < absenceTypeCount}
                  onChange={handleToggleAll}
                  tabIndex={-1}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage defaultMessage="Vælg alle" id="field-list.label.select-all" />
                }
              />
            </ListItem>
            <Divider />
            {listItems}
          </List>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
});
