import {ResourceInstance} from "@co-common-libs/resources";

export abstract class DBConnection {
  abstract close(): Promise<void>;
  abstract deleteDatabase(): Promise<void>;
  abstract fetchAll(storeName: string): Promise<ResourceInstance[]>;
  abstract fetchInstance(storeName: string, url: string): Promise<ResourceInstance>;
  abstract removeInstance(storeName: string, url: string): Promise<void>;
  abstract replaceInstance(storeName: string, instance: ResourceInstance): Promise<void>;
}
