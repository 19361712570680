import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent, Divider, List, ListItem, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {pressingLog, sewerControlLog, sprayLog, transportLog} from "./templates";
import {ReportingSpecificationTemplate} from "./templates/types";

const templates = [transportLog, sprayLog, pressingLog, sewerControlLog];

const TemplateItem = React.memo(function TemplateItem({
  onClick,
  template,
}: {
  onClick: (template: ReportingSpecificationTemplate) => void;
  template: ReportingSpecificationTemplate;
}): React.JSX.Element {
  const handleClick = useCallback(() => {
    onClick(template);
  }, [onClick, template]);
  return (
    <ListItem button onClick={handleClick}>
      <ListItemText>{template.name}</ListItemText>
    </ListItem>
  );
});

export const SelectReportingSpecificationTemplateDialog = React.memo(
  function SelectReportingSpecificationTemplateDialog({
    onCancel,
    onOk,
    open,
  }: {
    onCancel?: () => void;
    onOk: (template: ReportingSpecificationTemplate | null) => void;
    open: boolean;
  }): React.JSX.Element {
    const handleEmptyClick = useCallback(() => {
      onOk(null);
    }, [onOk]);
    return (
      <ResponsiveDialog
        onCancel={onCancel}
        open={open}
        title={
          <FormattedMessage defaultMessage="Vælg skabelon" id="select-template-dialog.title" />
        }
      >
        <DialogContent style={{padding: 0}}>
          <List>
            <ListItem button onClick={handleEmptyClick}>
              <ListItemText>
                <FormattedMessage defaultMessage="Tom" id="select-template-dialog.empty" />
              </ListItemText>
            </ListItem>
            <Divider />
            {templates.map((template) => (
              <TemplateItem key={template.identifier} onClick={onOk} template={template} />
            ))}
          </List>
        </DialogContent>
      </ResponsiveDialog>
    );
  },
);
