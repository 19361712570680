import {useModal} from "@co-frontend-libs/components";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import EditIcon from "mdi-react/EditIcon";
import React, {useCallback, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {
  BackendConfigurationSection,
  getBackendConfiguration,
  getBackendConfigurationSections,
  setBackendConfiguration,
} from "./backend-configuration-client";
import {BackendConfigurationInputElement} from "./backend-configuration-input-element";

interface EditBackendConfigurationSectionProps {
  onCancel(): void;
  onOk(result: Record<string, any>): void;

  section: BackendConfigurationSection;
}

function EditBackendConfigurationSectionDialog({
  onCancel,
  onOk,
  section: {name, properties},
}: EditBackendConfigurationSectionProps): React.JSX.Element {
  const [sectionData, setSectionData] = React.useState<Record<string, any>>();
  const [loaded, setLoaded] = React.useState(false);
  const [changed, setChanged] = React.useState(false);

  const updateProperty = useCallback(
    (propertyName: string, value: any): void => {
      setSectionData({...sectionData, [propertyName]: value});
      setChanged(true);
    },
    [setSectionData, sectionData],
  );
  const saveChanges = useCallback(() => {
    if (sectionData) {
      onOk(sectionData);
    }
  }, [onOk, sectionData]);

  useEffect(() => {
    // eslint-disable-next-line promise/catch-or-return
    getBackendConfiguration(name)
      .then(setSectionData)
      .finally(() => setLoaded(true));
  }, [name, setSectionData, setLoaded]);

  return (
    <>
      <DialogContent style={{minWidth: 600}}>
        {loaded ? (
          <Grid container direction="column" spacing={2}>
            {sectionData &&
              properties.map((property) => (
                <Grid item key={property.name}>
                  <Typography style={{textTransform: "capitalize"}} variant="subtitle1">
                    {property.name}
                  </Typography>
                  <BackendConfigurationInputElement
                    name={property.name}
                    onChange={updateProperty}
                    type={property.type}
                    value={sectionData[property.name]}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          <FormattedMessage defaultMessage="Fortryd" />
        </Button>
        <Button color="primary" disabled={!changed} onClick={saveChanges}>
          <FormattedMessage defaultMessage="Gem ændringer" />
        </Button>
      </DialogActions>
    </>
  );
}

function EditBackendConfiguration({
  section,
}: {
  section: BackendConfigurationSection;
}): React.JSX.Element {
  const [modal, prompt] = useModal(EditBackendConfigurationSectionDialog, section.name);

  const saveUpdatedSection = useCallback(async () => {
    const updatedSection = await prompt({section});
    if (updatedSection) {
      await setBackendConfiguration(section.name, updatedSection);
    }
  }, [prompt, section]);

  return (
    <div key={section.name}>
      <div style={{alignItems: "center", display: "flex", flexDirection: "row"}}>
        <Typography variant="h6">{section.name}</Typography>
        <IconButton onClick={saveUpdatedSection}>
          <EditIcon />
        </IconButton>
      </div>
      <Typography color="textSecondary" variant="body2">
        <FormattedMessage defaultMessage="Indstillinger:" />
        {section.properties.map((p) => (
          <span
            key={p.name}
            style={{display: "inline-block", paddingLeft: "6px", textTransform: "capitalize"}}
          >
            {p.name}
          </span>
        ))}
      </Typography>
      {modal}
    </div>
  );
}

export function BackendConfigurations(): React.JSX.Element {
  const [loaded, setLoaded] = React.useState(false);
  const [sections, setSections] = React.useState<BackendConfigurationSection[]>([]);
  const intl = useIntl();

  useEffect(() => {
    // eslint-disable-next-line promise/catch-or-return
    getBackendConfigurationSections()
      .then(setSections)
      .finally(() => setLoaded(true));
  }, [setSections]);

  return (
    <Card style={{margin: "1em"}}>
      <CardHeader title={intl.formatMessage({defaultMessage: "Integrationskonfiguration"})} />
      <CardContent>
        {loaded ? (
          <Grid container direction="column" spacing={4} style={{padding: "16px"}}>
            {_.sortBy(sections, (s) => s.name).map((section) => (
              <EditBackendConfiguration key={section.name} section={section} />
            ))}
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </CardContent>
    </Card>
  );
}
