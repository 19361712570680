import {CustomerUrl, MachineUrl, PriceGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {
  findMatchingFuelSurchargeUses,
  FuelSurchargeUseCriteria,
} from "./find-matching-fuel-surcharge-uses";

interface SpecificationPart {
  active: boolean;
}

interface MachineFuelSurchargeUsePart<FuelSurchargeUrl extends string> {
  readonly customer: CustomerUrl | null;
  readonly fuelSurcharge: FuelSurchargeUrl | null;
  readonly machine: MachineUrl;
  readonly variant: PriceGroupUrl | null;
}

interface WorkTypeFuelSurchargeUsePart<FuelSurchargeUrl extends string> {
  readonly customer: CustomerUrl | null;
  readonly fuelSurcharge: FuelSurchargeUrl | null;
  readonly variant: PriceGroupUrl | null;
  readonly workType: WorkTypeUrl;
}

interface DefaultFuelSurchargeUsePart<FuelSurchargeUrl extends string> {
  readonly customer: CustomerUrl | null;
  readonly fuelSurcharge: FuelSurchargeUrl | null;
}

export function findFuelSurchargeUseAndSpecification<
  SpecificationUrl extends string,
  Specification extends SpecificationPart,
  MachineFuelSurchargeUse extends MachineFuelSurchargeUsePart<SpecificationUrl>,
  WorkTypeFuelSurchargeUse extends WorkTypeFuelSurchargeUsePart<SpecificationUrl>,
  DefaultFuelSurchargeUse extends DefaultFuelSurchargeUsePart<SpecificationUrl>,
>(
  criteria: FuelSurchargeUseCriteria,
  machineFuelSurchargeUseArray: readonly MachineFuelSurchargeUse[],
  workTypeFuelSurchargeUseArray: readonly WorkTypeFuelSurchargeUse[],
  defaultFuelSurchargeUseArray: readonly DefaultFuelSurchargeUse[],
  fuelSurchargeSpecificationLookup: (url: SpecificationUrl) => Specification | undefined,
): {
  specification: Specification | null;
  use: DefaultFuelSurchargeUse | MachineFuelSurchargeUse | WorkTypeFuelSurchargeUse;
} | null {
  const uses = findMatchingFuelSurchargeUses(
    criteria,
    machineFuelSurchargeUseArray,
    workTypeFuelSurchargeUseArray,
    defaultFuelSurchargeUseArray,
  );
  for (const use of uses) {
    if (!use.fuelSurcharge) {
      return {specification: null, use};
    }
    const specification = fuelSurchargeSpecificationLookup(use.fuelSurcharge);
    if (specification?.active) {
      return {specification, use};
    }
  }
  return null;
}
