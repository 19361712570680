import {DateField} from "@co-frontend-libs/components";
import {Button, Card, CardActions, CardContent, CardHeader, Grid} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedMessage} from "react-intl";

interface PoriodSelectionProps {
  fromDate: string | null;
  onFromDateChange: (value: string | null) => void;
  onOk: () => void;
  onToDateChange: (value: string | null) => void;
  toDate: string | null;
}

export function PeriodSelection(props: PoriodSelectionProps): React.JSX.Element {
  const {fromDate, onFromDateChange, onOk, onToDateChange, toDate} = props;

  const handleFromDateBlur = useCallback(() => {
    if (fromDate && (!toDate || fromDate > toDate)) {
      onToDateChange(fromDate);
    }
  }, [fromDate, onToDateChange, toDate]);

  const handlePeriodStartSelectedInDialog = useCallback(
    (date: string | null) => {
      if (date) {
        if (!toDate || date > toDate) {
          onFromDateChange(date);
          onToDateChange(date);
        } else {
          onFromDateChange(date);
        }
      }
    },
    [onFromDateChange, onToDateChange, toDate],
  );

  return (
    <Card>
      <CardHeader
        title={
          <FormattedMessage
            defaultMessage="Angiv periode"
            id="transport-report.card-header.select-period"
          />
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <DateField
              autoOk
              fullWidth
              label={<FormattedMessage defaultMessage="Fra" id="transport-report.label.from" />}
              margin="dense"
              onBlur={handleFromDateBlur}
              onChange={onFromDateChange}
              onSelectedInDialog={handlePeriodStartSelectedInDialog}
              referenceDate={toDate || undefined}
              value={fromDate}
              yearSuggestions="DATE_BEFORE"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DateField
              autoOk
              fullWidth
              label={<FormattedMessage defaultMessage="Til" id="transport-report.label.to" />}
              margin="dense"
              onChange={onToDateChange}
              referenceDate={fromDate || undefined}
              value={toDate}
              yearSuggestions={fromDate ? "DATE_AFTER" : "DATE_BEFORE"}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          disabled={!fromDate || !toDate || toDate < fromDate}
          onClick={onOk}
          variant="contained"
        >
          <FormattedMessage
            defaultMessage="Hent periode"
            id="transport-report.label.fetch-period"
          />
        </Button>
      </CardActions>
    </Card>
  );
}
