import {IntegerField, TrimTextField} from "@co-frontend-libs/components";
import {FormControlLabel, Switch} from "@material-ui/core";
import {
  postalCodes,
  SimpleObjectReducerDispatch,
  useEventTargetCheckedCallback,
  useUpdateObjectEntry,
} from "app-utils";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {CustomerFormMember, CustomerFormPart} from "./types";

export interface CustomerFormInputDefinitions {
  readonly disabledInputFields: ReadonlySet<CustomerFormMember>;
  readonly requiredInputFields: ReadonlySet<CustomerFormMember>;
  readonly visibleInputFields: ReadonlySet<CustomerFormMember>;
}

export interface CustomerFormErrorsAndWarnings {
  readonly errorText: ReadonlyMap<CustomerFormMember, string>;
  readonly warningText: ReadonlyMap<CustomerFormMember, string>;
}

export interface CustomerFormProps
  extends CustomerFormErrorsAndWarnings,
    CustomerFormInputDefinitions {
  autoFocus: boolean;
  formCustomer: CustomerFormPart;
  formDispatch: SimpleObjectReducerDispatch<CustomerFormPart>;
}

export const CustomerForm = React.memo(function CustomerForm(
  props: CustomerFormProps,
): React.JSX.Element {
  const {
    autoFocus,
    disabledInputFields,
    errorText,
    formCustomer,
    formDispatch,
    requiredInputFields,
    visibleInputFields,
    warningText,
  } = props;

  const {
    active,
    address,
    billingEmail,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    c5_account,
    city,
    name,
    phone,
    postalCode,
    vatNumber,
  } = formCustomer;

  const intl = useIntl();

  const insertIfVisible = useCallback(
    (member: CustomerFormMember, element: React.JSX.Element): React.JSX.Element | null =>
      visibleInputFields.has(member) ? element : null,
    [visibleInputFields],
  );

  const handleNameChange = useUpdateObjectEntry(formDispatch, CustomerFormMember.NAME);

  const nameInput = (
    <TrimTextField
      autoFocus={autoFocus}
      disabled={disabledInputFields.has(CustomerFormMember.NAME)}
      errorText={errorText.get(CustomerFormMember.NAME)}
      fullWidth
      label={
        requiredInputFields.has(CustomerFormMember.NAME)
          ? intl.formatMessage({
              defaultMessage: "Navn *",
            })
          : intl.formatMessage({
              defaultMessage: "Navn",
            })
      }
      margin="dense"
      onChange={handleNameChange}
      value={name}
      variant="outlined"
      warningText={warningText.get(CustomerFormMember.NAME)}
    />
  );

  const handleVatNumberChange = useUpdateObjectEntry(formDispatch, CustomerFormMember.VAT_NUMBER);

  const vatNumberInput = (
    <TrimTextField
      disabled={disabledInputFields.has(CustomerFormMember.VAT_NUMBER)}
      errorText={errorText.get(CustomerFormMember.VAT_NUMBER)}
      fullWidth
      label={
        requiredInputFields.has(CustomerFormMember.VAT_NUMBER)
          ? intl.formatMessage({
              defaultMessage: "CVR *",
            })
          : intl.formatMessage({
              defaultMessage: "CVR",
            })
      }
      margin="dense"
      onChange={handleVatNumberChange}
      value={vatNumber}
      variant="outlined"
      warningText={warningText.get(CustomerFormMember.VAT_NUMBER)}
    />
  );

  const handleCustomerNumberChange = useUpdateObjectEntry(
    formDispatch,
    CustomerFormMember.CUSTOMER_NUMBER,
    (value: number | null) => `${value ?? ""}`,
  );

  const customerNumberInput = (
    <IntegerField
      disabled={disabledInputFields.has(CustomerFormMember.CUSTOMER_NUMBER)}
      errorText={errorText.get(CustomerFormMember.CUSTOMER_NUMBER)}
      fullWidth
      inputProps={{
        maxLength: 9,
      }}
      label={
        requiredInputFields.has(CustomerFormMember.CUSTOMER_NUMBER)
          ? intl.formatMessage({
              defaultMessage: "Kundenummer *",
            })
          : intl.formatMessage({
              defaultMessage: "Kundenummer",
            })
      }
      margin="dense"
      onChange={handleCustomerNumberChange}
      value={c5_account ? parseInt(c5_account) : null}
      warningText={warningText.get(CustomerFormMember.CUSTOMER_NUMBER)}
    />
  );

  const handleAddressChange = useUpdateObjectEntry(formDispatch, CustomerFormMember.ADDRESS);

  const addressInput = (
    <TrimTextField
      disabled={disabledInputFields.has(CustomerFormMember.ADDRESS)}
      errorText={errorText.get(CustomerFormMember.ADDRESS)}
      fullWidth
      label={
        requiredInputFields.has(CustomerFormMember.ADDRESS)
          ? intl.formatMessage({
              defaultMessage: "Adresse * ",
            })
          : intl.formatMessage({
              defaultMessage: "Adresse",
            })
      }
      margin="dense"
      onChange={handleAddressChange}
      value={address}
      variant="outlined"
      warningText={warningText.get(CustomerFormMember.ADDRESS)}
    />
  );

  const handleCityChange = useUpdateObjectEntry(formDispatch, CustomerFormMember.CITY);

  const cityInput = (
    <TrimTextField
      disabled={disabledInputFields.has(CustomerFormMember.CITY)}
      errorText={errorText.get(CustomerFormMember.CITY)}
      fullWidth
      label={
        requiredInputFields.has(CustomerFormMember.CITY)
          ? intl.formatMessage({
              defaultMessage: "By *",
            })
          : intl.formatMessage({
              defaultMessage: "By",
            })
      }
      margin="dense"
      onChange={handleCityChange}
      value={city}
      variant="outlined"
      warningText={warningText.get(CustomerFormMember.CITY)}
    />
  );

  const handlePhoneChange = useUpdateObjectEntry(formDispatch, CustomerFormMember.PHONE);

  const phoneInput = (
    <TrimTextField
      disabled={disabledInputFields.has(CustomerFormMember.PHONE)}
      errorText={errorText.get(CustomerFormMember.PHONE)}
      fullWidth
      label={
        requiredInputFields.has(CustomerFormMember.PHONE)
          ? intl.formatMessage({
              defaultMessage: "Telefonnummer *",
            })
          : intl.formatMessage({
              defaultMessage: "Telefonnummer",
            })
      }
      margin="dense"
      onChange={handlePhoneChange}
      value={phone}
      variant="outlined"
      warningText={warningText.get(CustomerFormMember.PHONE)}
    />
  );

  const handleEmailChange = useUpdateObjectEntry(formDispatch, CustomerFormMember.BILLING_EMAIL);

  const emailInput = (
    <TrimTextField
      disabled={disabledInputFields.has(CustomerFormMember.BILLING_EMAIL)}
      errorText={errorText.get(CustomerFormMember.BILLING_EMAIL)}
      fullWidth
      label={
        requiredInputFields.has(CustomerFormMember.BILLING_EMAIL)
          ? intl.formatMessage({
              defaultMessage: "Mailadresse *",
            })
          : intl.formatMessage({
              defaultMessage: "Mailadresse",
            })
      }
      margin="dense"
      onChange={handleEmailChange}
      value={billingEmail}
      variant="outlined"
      warningText={warningText.get(CustomerFormMember.BILLING_EMAIL)}
    />
  );

  const handlePostalCodeChange = useUpdateObjectEntry(formDispatch, CustomerFormMember.POSTAL_CODE);

  const handlePostalCodeWithLookupCityChange = useCallback(
    (value: string): void => {
      const cityForPostalCode = postalCodes[value];
      handlePostalCodeChange(value);
      if (cityForPostalCode) {
        handleCityChange(cityForPostalCode);
      }
    },
    [handleCityChange, handlePostalCodeChange],
  );

  const postalCodeInput = (
    <TrimTextField
      disabled={disabledInputFields.has(CustomerFormMember.POSTAL_CODE)}
      errorText={errorText.get(CustomerFormMember.POSTAL_CODE)}
      fullWidth
      label={
        requiredInputFields.has(CustomerFormMember.POSTAL_CODE)
          ? intl.formatMessage({
              defaultMessage: "Postnummer *",
            })
          : intl.formatMessage({
              defaultMessage: "Postnummer",
            })
      }
      margin="dense"
      onChange={handlePostalCodeWithLookupCityChange}
      value={postalCode}
      variant="outlined"
      warningText={warningText.get(CustomerFormMember.POSTAL_CODE)}
    />
  );

  const handleActiveChange = useUpdateObjectEntry(formDispatch, CustomerFormMember.ACTIVE);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleActiveChangeEvent = useEventTargetCheckedCallback(handleActiveChange.bind(null), [
    handleActiveChange,
  ]);

  const activeInput = (
    <FormControlLabel
      control={<Switch checked={active || false} onChange={handleActiveChangeEvent} />}
      disabled={disabledInputFields.has(CustomerFormMember.ACTIVE)}
      label={intl.formatMessage({
        defaultMessage: "Aktiv",
      })}
      labelPlacement="end"
    />
  );

  return (
    <>
      {insertIfVisible(CustomerFormMember.NAME, nameInput)}
      {insertIfVisible(CustomerFormMember.VAT_NUMBER, vatNumberInput)}
      {insertIfVisible(CustomerFormMember.CUSTOMER_NUMBER, customerNumberInput)}
      {insertIfVisible(CustomerFormMember.ADDRESS, addressInput)}
      {insertIfVisible(CustomerFormMember.POSTAL_CODE, postalCodeInput)}
      {insertIfVisible(CustomerFormMember.CITY, cityInput)}
      {insertIfVisible(CustomerFormMember.PHONE, phoneInput)}
      {insertIfVisible(CustomerFormMember.BILLING_EMAIL, emailInput)}
      {insertIfVisible(CustomerFormMember.ACTIVE, activeInput)}
      {requiredInputFields.size ? (
        <FormattedMessage defaultMessage="* Skal udfyldes" tagName="div" />
      ) : null}
    </>
  );
});
