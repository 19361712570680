import {SettingEntry} from "@co-common-libs/resources";
import {Theme, useTheme} from "@material-ui/core";
import _ from "lodash";
import CircleIcon from "mdi-react/CircleIcon";
import React from "react";

const getStatusColor = (theme: Theme, settingEntry?: SettingEntry): string => {
  if (
    !settingEntry ||
    settingEntry.data === false ||
    settingEntry.data === "" ||
    (typeof settingEntry.data === "object" && _.isEmpty(settingEntry.data))
  ) {
    return theme.palette.grey[400];
  }
  return theme.palette.success.dark;
};

export function StatusDot({
  settingEntry,
}: {
  settingEntry?: SettingEntry | undefined;
}): React.JSX.Element {
  const theme = useTheme();
  const statusDotColor = getStatusColor(theme, settingEntry);

  return <CircleIcon color={statusDotColor} />;
}
