import {Customer, TaskUrl} from "@co-common-libs/resources";
import {CustomOfficeIconButton, CustomOfficeIconButtonProps} from "@co-frontend-libs/components";
import {getExtendedCustomerSettings, getTaskLookup} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {useTheme} from "@material-ui/core";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {
  CustomerApproveOrChangeDialog,
  CustomerEditDialog,
  ExternalCustomerCreateDialog,
  ExternalCustomerEditDialog,
} from "./customer-dialogs";
import {
  type FloatingActionButtonData,
  FloatingActionButtons,
  FloatingActionButtonsProps,
} from "./floating-action-buttons";

export enum ButtonStyle {
  FAB = "fab",
  ICON_BUTTON = "icon-button",
  TEXT = "text",
}

interface EditCustomerButtonProps {
  allowChangeCurrentTaskOnly?: boolean;
  buttonStyle: ButtonStyle;
  customer: Customer;
  disabled?: boolean;
  taskUrl?: TaskUrl;
}

type FloatingActionButtonProps = Omit<FloatingActionButtonsProps, "buttons" | "name">;
interface EditCustomerFABProps extends EditCustomerButtonProps, FloatingActionButtonProps {
  buttonStyle: ButtonStyle.FAB;
}

type IconButtonProps = Omit<CustomOfficeIconButtonProps, "children">;
interface EditCustomerIconButtonProps extends EditCustomerButtonProps, IconButtonProps {
  buttonStyle: ButtonStyle.ICON_BUTTON;
}

interface EditCustomerTextButtonProps extends EditCustomerButtonProps {
  buttonStyle: ButtonStyle.TEXT;
  color: "default" | "error" | "warning";
}

/**
 * Passing a taskUrl suggests that this customer is being edited in the context of a task.
 * In this case, instead of presenting an edit dialog, the user may be presented with the
 * customer approval dialog, which can either finalize creating the customer, or replace it with
 * one that has already been approved (created in the third-party economy system).
 */
export const EditCustomerButton = React.memo(function EditCustomerButton(
  props: EditCustomerFABProps | EditCustomerIconButtonProps | EditCustomerTextButtonProps,
): React.JSX.Element | null {
  const {
    allowChangeCurrentTaskOnly,
    buttonStyle,
    customer,
    disabled = false,
    taskUrl,
    ...other
  } = props;

  const customerSettings = useSelector(getExtendedCustomerSettings);

  const [customerEditDialogOpen, setCustomerEditDialogOpen] = useState(false);
  const setCustomerEditDialogOpenTrue = useCallWithTrue(setCustomerEditDialogOpen);
  const setCustomerEditDialogOpenFalse = useCallWithFalse(setCustomerEditDialogOpen);

  const intl = useIntl();
  const theme = useTheme();

  const lookupTask = useSelector(getTaskLookup);

  const task = taskUrl ? lookupTask(taskUrl) : null;

  let customerEditDialog: React.JSX.Element;
  if (customerSettings.customers.liveSyncWithThirdParty) {
    if (customer.remoteUrl) {
      customerEditDialog = (
        <ExternalCustomerEditDialog
          customer={customer}
          onCancel={setCustomerEditDialogOpenFalse}
          onSuccess={setCustomerEditDialogOpenFalse}
          open={customerEditDialogOpen}
        />
      );
    } else if (task) {
      customerEditDialog = (
        <CustomerApproveOrChangeDialog
          allowChangeCurrentTaskOnly={!!allowChangeCurrentTaskOnly}
          onCancel={setCustomerEditDialogOpenFalse}
          onSuccess={setCustomerEditDialogOpenFalse}
          open={customerEditDialogOpen}
          task={task}
        />
      );
    } else {
      customerEditDialog = (
        <ExternalCustomerCreateDialog
          customer={customer}
          onCancel={setCustomerEditDialogOpenFalse}
          onSuccess={setCustomerEditDialogOpenFalse}
          open={customerEditDialogOpen}
        />
      );
    }
  } else {
    customerEditDialog = (
      <CustomerEditDialog
        customer={customer}
        onCancel={setCustomerEditDialogOpenFalse}
        onEditSuccess={setCustomerEditDialogOpenFalse}
        open={customerEditDialogOpen}
      />
    );
  }

  const fabButtons = useMemo((): FloatingActionButtonData[] => {
    return [
      {
        buttonIcon: <PencilIcon />,
        name: "edit-customer",
        onClick: setCustomerEditDialogOpenTrue,
        tooltipTitle: intl.formatMessage({defaultMessage: "Ret kunde"}),
      },
    ];
  }, [intl, setCustomerEditDialogOpenTrue]);

  const textButtonTextStyle = useMemo(() => {
    if (buttonStyle === ButtonStyle.TEXT) {
      return props.color === "error"
        ? {color: theme.palette.error.main}
        : props.color === "warning"
          ? {color: theme.palette.warning.main}
          : {};
    }

    return {};
  }, [buttonStyle, props, theme]);

  return (
    <>
      {buttonStyle === ButtonStyle.TEXT ? (
        <div
          onClick={disabled ? undefined : setCustomerEditDialogOpenTrue}
          style={textButtonTextStyle}
        >
          {customer.name}
        </div>
      ) : null}

      {buttonStyle === ButtonStyle.FAB ? (
        <FloatingActionButtons
          buttons={fabButtons}
          disabled={disabled}
          name="edit"
          {...(other as FloatingActionButtonProps)}
        />
      ) : null}

      {buttonStyle === ButtonStyle.ICON_BUTTON ? (
        <CustomOfficeIconButton
          color="primary"
          compact
          disabled={disabled}
          onClick={setCustomerEditDialogOpenTrue}
          {...(other as IconButtonProps)}
        >
          <PencilIcon />
        </CustomOfficeIconButton>
      ) : null}

      {customerEditDialog}
    </>
  );
});
