import {ErrorColorFab} from "@co-frontend-libs/components";
import {actions, getShareToken} from "@co-frontend-libs/redux";
import {Button, Grid} from "@material-ui/core";
import {theme} from "frontend-global-config";
import DeleteIcon from "mdi-react/DeleteIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DragAndDropUploadOverlay} from "../drag-and-drop-upload-overlay";
import {useFileInputChangeHandler} from "../files";

interface PhotoUrlFieldProps {
  onChange: (file: File | null) => void;
  photoUrl: string | undefined;
  updatePhotoUrl: (photoUrl: string) => void;
}

const imageStyle: React.CSSProperties = {
  aspectRatio: "auto",
  borderStyle: "dotted",
  justifySelf: "flex-end",
  maxHeight: 160,
  maxWidth: 160,
};

const trashCan: React.CSSProperties = {position: "absolute", right: "15px", top: "15px"};

export function PhotoUrlField(props: PhotoUrlFieldProps): React.JSX.Element {
  const {onChange, photoUrl, updatePhotoUrl} = props;
  const [fileError, setFileError] = useState("");
  const dispatch = useDispatch();
  const [photo, setPhoto] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const intl = useIntl();
  const shareToken = useSelector(getShareToken);

  const fileDropAccepted = useCallback(
    (uploadedfiles: File[]): void => {
      if (uploadedfiles.length > 1) {
        setFileError(
          intl.formatMessage({
            defaultMessage: "Kun et billede kan vælges",
          }),
        );
        return;
      }
      setFileError("");
      const previewPhoto = uploadedfiles[0];
      setPhoto(previewPhoto);

      if (previewPhoto) {
        setPreview(URL.createObjectURL(previewPhoto));
      }

      onChange(previewPhoto);
    },
    [intl, onChange],
  );

  const fileDropRejected = useCallback(
    (file: File): void => {
      const message = intl.formatMessage(
        {defaultMessage: "{name} er ikke en gyldig billedfil"},
        {name: file.name},
      );
      window.setTimeout(() => {
        dispatch(actions.setMessage(message, new Date()));
      }, 0);
    },
    [dispatch, intl],
  );

  const handleRemovePictureUrl = useCallback((): void => {
    updatePhotoUrl("");
    setFileError(
      intl.formatMessage({
        defaultMessage: "Billedet vil blive slettet, når du trykker OK",
      }),
    );
  }, [intl, updatePhotoUrl]);

  const handleFileInputChange = useFileInputChangeHandler(
    fileDropAccepted,
    fileDropRejected,
    "image/jpeg,image/png",
  );

  const handleFiles = useCallback(
    (dropFiles: File[]): void => {
      const invalidFile = dropFiles.find(
        (file) => !["image/jpeg", "image/png"].includes(file.type),
      );

      if (invalidFile) {
        fileDropRejected(invalidFile);
      } else {
        fileDropAccepted(dropFiles);
      }
    },
    [fileDropAccepted, fileDropRejected],
  );

  const photoLabel = preview ? photo?.name : photoUrl && photoUrl.split("/").pop();

  const handleRemovePreview = useCallback((): void => {
    setPreview(null);
    onChange(null);
  }, [onChange]);
  return (
    <>
      <Grid alignItems="flex-start" container direction="column" spacing={2}>
        <Grid item>
          <DragAndDropUploadOverlay onFiles={handleFiles}>
            <input
              accept="image/jpeg,image/png"
              id="photos-input"
              onChange={handleFileInputChange}
              style={{display: "none"}}
              type="file"
            />
            <label htmlFor="photos-input">
              <Button color="secondary" component="span" style={{marginTop: 2}} variant="contained">
                <FormattedMessage defaultMessage="Vælg billede" />
              </Button>
            </label>
          </DragAndDropUploadOverlay>
          {photoLabel}
        </Grid>
        {(preview || !!photoUrl) && (
          <Grid item style={{position: "relative"}}>
            <>
              <img
                alt=""
                src={preview ? preview : `${photoUrl}?token=${shareToken}`}
                style={imageStyle}
              />
              <ErrorColorFab
                onClick={preview ? handleRemovePreview : handleRemovePictureUrl}
                size="small"
                style={trashCan}
              >
                <DeleteIcon />
              </ErrorColorFab>
            </>
          </Grid>
        )}
      </Grid>

      {fileError && <div style={{color: theme.palette.error.main}}> {fileError}</div>}
    </>
  );
}
