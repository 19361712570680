import {Config} from "@co-common-libs/config";
import {
  DaysAbsence,
  DaysAbsenceUrl,
  HoursAbsence,
  HoursAbsenceUrl,
  Role,
  User,
  UserProfile,
  UserUrl,
} from "@co-common-libs/resources";
import {
  dateFromString,
  dateToString,
  formatMonthDate,
  getHoliday,
  setFilter,
  WEEK_DAYS,
  WEEKDAY_MONDAY,
  WEEKDAY_SATURDAY,
  WEEKDAY_SUNDAY,
  weekNumber,
} from "@co-common-libs/utils";
import {Theme, withTheme} from "@material-ui/core";
import {FilterBar} from "app-components";
import {
  currentUserMayEditAbsence,
  currentUserMayRegisterAbsenceFor,
  getEmployeeHolidayCalendars,
  getExtraHolidaysForUser,
  PureComponent,
} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";

const messages = defineMessages({
  childsFirstSickDay: {
    defaultMessage: "BS",
    id: "absence-calendar.label.childs-first-sick-day",
  },
  compensatory: {
    defaultMessage: "A",
    id: "absence-calendar.label.compensatory",
  },
  daysOffWithoutPay: {
    defaultMessage: "FD",
    id: "absence-calendar.label.days-off-without-pay",
  },
  floatingHoliday: {
    defaultMessage: "FF",
    id: "absence-calendar.label.floating-holiday",
  },
  sickLeave: {
    defaultMessage: "SY",
    id: "absence-calendar.label.sick-leave",
  },
  timeOff: {
    defaultMessage: "FRI",
    id: "absence-calendar.label.time-off",
  },
  vacation: {
    defaultMessage: "FE",
    id: "absence-calendar.label.vacation",
  },
  weekNumber: {
    defaultMessage: "Uge {weekNumber}",
    id: "absence-calendar.label.week-number",
  },
});

const PAST_WEEKS = 4;
const FUTURE_WEEKS = 52;
const TOTAL_WEEKS = PAST_WEEKS + FUTURE_WEEKS;

const getDateSequence = (selectedDate: string): Date[] => {
  const result = [];
  const startDate = dateFromString(selectedDate) as Date;
  console.assert(startDate);
  for (let i = -WEEK_DAYS * PAST_WEEKS; i < FUTURE_WEEKS * WEEK_DAYS; i += 1) {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);
    result.push(date);
  }
  return result;
};

const DAY_WIDTH = 30;
const HEADER_CELL_HEIGHT = 24;
const DAY_HEIGHT = 24;

const BORDER_COLOR = "#dddddd";

const SCROLLBAR_COMPENSATION = 24;

const cellStyle: React.CSSProperties = {
  borderBottomWidth: 1,
  borderColor: BORDER_COLOR,
  borderLeftWidth: 0,
  borderRightWidth: 1,
  borderStyle: "solid",
  borderTopWidth: 0,
  display: "inline-block",
  height: DAY_HEIGHT,
  overflow: "hidden",
  textAlign: "center",
  userSelect: "none",
  width: DAY_WIDTH,
};

const weekendStyle = {...cellStyle, backgroundColor: BORDER_COLOR};
const mondayStyle = {
  ...cellStyle,
  borderLeftColor: "black",
  borderLeftWidth: 1,
};

interface AbsenceCellProps {
  currentRole?: Role | undefined;
  customerSettings: Config;
  date: string;
  daysAbsenceSet: ReadonlySet<DaysAbsence>;
  fullscreen: boolean;
  holiday: boolean;
  hoursAbsenceSet: ReadonlySet<HoursAbsence>;
  onAbsenceClick?: ((url: DaysAbsenceUrl | HoursAbsenceUrl) => void) | undefined;
  onEmptyClick?: ((date: string) => void) | undefined;
  weekDayNumber: number;
}

class AbsenceCell extends PureComponent<AbsenceCellProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  getAbsenceInstance(): DaysAbsence | HoursAbsence | null {
    const {daysAbsenceSet, hoursAbsenceSet} = this.props;
    if (daysAbsenceSet.size) {
      return Array.from(daysAbsenceSet)[0];
    } else if (hoursAbsenceSet.size) {
      return Array.from(hoursAbsenceSet)[0];
    }
    return null;
  }
  @bind
  handleAbsenceClick(): void {
    const {onAbsenceClick} = this.props;
    const absenceInstance = this.getAbsenceInstance();
    if (absenceInstance && onAbsenceClick) {
      onAbsenceClick(absenceInstance.url);
    }
  }
  @bind
  handleEmptyClick(): void {
    const {date, onEmptyClick} = this.props;
    if (onEmptyClick) {
      onEmptyClick(date);
    }
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      currentRole,
      customerSettings,
      fullscreen,
      holiday,
      onAbsenceClick,
      onEmptyClick,
      weekDayNumber,
    } = this.props;
    const canManageAbsence = currentUserMayEditAbsence(customerSettings, currentRole || null);

    const absenceDisplayLabel = canManageAbsence
      ? null
      : customerSettings.absenceCalendarDisplayLabel;

    let style: React.CSSProperties = cellStyle;

    const weekend = weekDayNumber === WEEKDAY_SUNDAY || weekDayNumber === WEEKDAY_SATURDAY;

    if (weekend) {
      style = weekendStyle;
    } else if (weekDayNumber === WEEKDAY_MONDAY) {
      style = mondayStyle;
    }

    if (holiday) {
      style = {
        ...style,
        backgroundColor: this.props.customerSettings.absenceCalendarBlockColor,
        borderBottomColor: this.props.customerSettings.absenceCalendarBlockColor,
        borderTopColor: this.props.customerSettings.absenceCalendarBlockColor,
      };
    }

    let label: string | undefined;
    let absenceType: string | undefined;

    const optionalClickHandlerProps: React.HTMLAttributes<HTMLDivElement> = {};

    const absenceInstance = this.getAbsenceInstance();
    if (absenceInstance) {
      ({absenceType} = absenceInstance);
    }

    const {absenceTypesVisibleOnFullscreenAbsenceCalendar} = customerSettings;

    if (
      absenceType &&
      (!fullscreen ||
        !absenceTypesVisibleOnFullscreenAbsenceCalendar ||
        absenceTypesVisibleOnFullscreenAbsenceCalendar.includes(absenceType))
    ) {
      label =
        absenceDisplayLabel ||
        customerSettings.absenceTypeShortLabels[absenceType] ||
        ((messages as any)[absenceType] && formatMessage((messages as any)[absenceType])) ||
        customerSettings.absenceTypeLabels[absenceType] ||
        absenceType;
      const backgroundColor =
        customerSettings.absenceTypeColors[absenceType] ||
        customerSettings.absenceCalendarBlockColor;

      if (onAbsenceClick) {
        optionalClickHandlerProps.onClick = this.handleAbsenceClick;
        style = {...style, backgroundColor, cursor: "pointer"};
      } else {
        style = {...style, backgroundColor};
      }
    } else {
      if (onEmptyClick) {
        optionalClickHandlerProps.onClick = this.handleEmptyClick;
        style = {...style, cursor: "pointer"};
      } else {
        style = {...style};
      }
    }

    return (
      <div style={style} {...optionalClickHandlerProps}>
        {label}
      </div>
    );
  }
}

interface AbsenceRowProps {
  currentRole?: Role | undefined;
  customerSettings: Config;
  daysAbsenceSet: ReadonlySet<DaysAbsence>;
  extraHalfHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined;
  extraHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined;
  fullscreen: boolean;
  hoursAbsenceSet: ReadonlySet<HoursAbsence>;
  onAbsenceClick?: ((url: DaysAbsenceUrl | HoursAbsenceUrl) => void) | undefined;
  onEmptyClick?: ((url: UserUrl, date: string) => void) | undefined;
  selectedDate: string;
  user: User;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

class AbsenceRow extends PureComponent<AbsenceRowProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleEmptyCellClick(date: string): void {
    const {onEmptyClick, user} = this.props;

    if (onEmptyClick) {
      onEmptyClick(user.url, date);
    }
  }

  render(): React.JSX.Element {
    const {
      currentRole,
      customerSettings,
      daysAbsenceSet,
      extraHalfHolidaysPerRemunerationGroup,
      extraHolidaysPerRemunerationGroup,
      fullscreen,
      hoursAbsenceSet,
      onAbsenceClick,
      onEmptyClick,
      selectedDate,
      user,
      userUserProfileLookup,
    } = this.props;

    let getUserHalfHoliday: ((date: string) => string | undefined) | undefined;
    let getUserHoliday: ((date: string) => string | undefined) | undefined;
    const userProfile = userUserProfileLookup(user.url);
    const holidaysVisible =
      this.props.currentRole?.manager ||
      this.props.customerSettings.holidaysVisibleToMachineOperators;

    if (holidaysVisible) {
      const extraHolidays = userProfile
        ? getExtraHolidaysForUser(
            this.props.customerSettings,
            userProfile,
            extraHolidaysPerRemunerationGroup,
            extraHalfHolidaysPerRemunerationGroup,
          )
        : undefined;

      getUserHalfHoliday = extraHolidays?.getUserHalfHoliday;
      getUserHoliday = extraHolidays?.getUserHoliday;
    }

    const holidayCalendars = getEmployeeHolidayCalendars(this.props.customerSettings, userProfile);

    const dateSequence = getDateSequence(selectedDate);
    const dateCells = dateSequence.map((date) => {
      const dateString = dateToString(date);
      const dateDaysAbsenceSet = setFilter(
        daysAbsenceSet,
        (d) => d.toDate >= dateString && d.fromDate <= dateString,
      );
      const dateHoursAbsenceSet = setFilter(
        hoursAbsenceSet,
        (d) =>
          dateToString(new Date(d.toTimestamp)) >= dateString &&
          dateToString(new Date(d.fromTimestamp)) <= dateString,
      );
      const holiday =
        (getUserHoliday && getUserHoliday(dateString)) || getHoliday(holidayCalendars, dateString);
      const halfHoliday = getUserHalfHoliday && getUserHalfHoliday(dateString);

      const mayEditAbsence =
        currentRole && currentUserMayEditAbsence(customerSettings, currentRole);

      const mayRegisterAbsence =
        currentRole && currentUserMayRegisterAbsenceFor(customerSettings, currentRole, user.url);

      return (
        <AbsenceCell
          currentRole={this.props.currentRole}
          customerSettings={this.props.customerSettings}
          date={dateString}
          daysAbsenceSet={dateDaysAbsenceSet}
          fullscreen={fullscreen}
          holiday={!!(holiday || halfHoliday)}
          hoursAbsenceSet={dateHoursAbsenceSet}
          key={dateString}
          onAbsenceClick={onAbsenceClick && mayEditAbsence ? onAbsenceClick : undefined}
          onEmptyClick={onEmptyClick && mayRegisterAbsence ? this.handleEmptyCellClick : undefined}
          weekDayNumber={date.getDay()}
        />
      );
    });
    return (
      <div key={user.url} style={{height: DAY_HEIGHT, width: TOTAL_WEEKS * WEEK_DAYS * DAY_WIDTH}}>
        {dateCells}
      </div>
    );
  }
}

interface AbsenceTabContentProps {
  currentRole?: Role | undefined;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  extraHalfHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined;
  extraHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined;
  fullscreen: boolean;
  hoursAbsenceArray: readonly HoursAbsence[];
  onAbsenceClick?: (url: DaysAbsenceUrl | HoursAbsenceUrl) => void;
  onEmptyClick?: (url: UserUrl, date: string) => void;
  onRequestFilterClear?: () => void;
  roleArray: readonly Role[];
  selectedDate: string;
  selectedEmployeeGroupUrlSet?: ReadonlySet<string>;
  theme: Theme;
  userArray: readonly User[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

class AbsenceTabContent extends PureComponent<AbsenceTabContentProps> {
  static contextType = IntlContext;
  _datesHeader = React.createRef<HTMLDivElement>();
  _initialsColumn = React.createRef<HTMLDivElement>();
  _scrollContainer = React.createRef<HTMLDivElement>();
  context!: React.ContextType<typeof IntlContext>;
  componentDidMount(): void {
    const scrollNode = this._scrollContainer.current;
    if (scrollNode) {
      scrollNode.addEventListener("scroll", this.handleScroll);
    }
    this.scrollToSelectedDate();
  }
  componentWillUnmount(): void {
    const scrollNode = this._scrollContainer.current;
    if (scrollNode) {
      scrollNode.removeEventListener("scroll", this.handleScroll);
    }
  }
  @bind
  handleScroll(): void {
    const scrollNode = this._scrollContainer.current;
    if (scrollNode) {
      const datesHeader = this._datesHeader.current;
      if (datesHeader) {
        datesHeader.scrollLeft = scrollNode.scrollLeft;
      }
      const initialsColumn = this._initialsColumn.current;
      if (initialsColumn) {
        initialsColumn.scrollTop = scrollNode.scrollTop;
      }
    }
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    // NTS: block per employee; i.e. per row, not per column
    // and, of course, scrolling for the entire block...
    // date absence is ISO date strings
    // hours absence is from/to timestamps
    const {
      daysAbsenceArray,
      extraHalfHolidaysPerRemunerationGroup,
      extraHolidaysPerRemunerationGroup,
      fullscreen,
      hoursAbsenceArray,
      onAbsenceClick,
      onEmptyClick,
      roleArray,
      selectedDate,
      selectedEmployeeGroupUrlSet,
      theme,
      userArray,
      userUserProfileLookup,
    } = this.props;
    const dateSequence = getDateSequence(selectedDate);
    const userList = _.sortBy(
      userArray
        .filter((user) => {
          const userURL = user.url;
          const role = roleArray.find((r) => r.user === userURL);
          return (
            !role || ((role.machineOperator || role.manager) && !role.breakRoom && !role.consultant)
          );
        })
        .filter((u) => u.active)
        .filter((user) => {
          const userProfile = userUserProfileLookup(user.url);

          return userProfile && userProfile.visibleOnAbsenceCalendar !== false;
        })
        .filter((user) => {
          if (selectedEmployeeGroupUrlSet && selectedEmployeeGroupUrlSet.size) {
            const employeeGroupUrl = userUserProfileLookup(user.url)?.employeeGroup;
            return (
              employeeGroupUrl &&
              selectedEmployeeGroupUrlSet &&
              selectedEmployeeGroupUrlSet.has(employeeGroupUrl)
            );
          }
          return true;
        }),
      (user) => userUserProfileLookup(user.url)?.alias || user.loginIdentifier,
    );

    const userStyle: React.CSSProperties = {
      borderBottomWidth: 1,
      borderColor: BORDER_COLOR,
      borderRightWidth: 1,
      borderStyle: "solid",
      borderWidth: 0,
      height: DAY_HEIGHT,
      paddingRight: "4px",
      textAlign: "right",
    };

    const userInitialsList = userList.map((user) => {
      return (
        <div key={user.url} style={userStyle}>
          {userUserProfileLookup(user.url)?.alias || user.loginIdentifier}
        </div>
      );
    });
    const daysAbsenceSeq = daysAbsenceArray;
    const hoursAbsenceSeq = hoursAbsenceArray;
    const rowList = userList.map((user) => {
      const userURL = user.url;
      const hasUser = (d: {user: string}): boolean => d.user === userURL;
      const daysAbsenceSet = new Set(daysAbsenceSeq.filter(hasUser));
      const hoursAbsenceSet = new Set(hoursAbsenceSeq.filter(hasUser));
      return (
        <AbsenceRow
          currentRole={this.props.currentRole}
          customerSettings={this.props.customerSettings}
          daysAbsenceSet={daysAbsenceSet}
          extraHalfHolidaysPerRemunerationGroup={extraHalfHolidaysPerRemunerationGroup}
          extraHolidaysPerRemunerationGroup={extraHolidaysPerRemunerationGroup}
          fullscreen={fullscreen}
          hoursAbsenceSet={hoursAbsenceSet}
          key={user.url}
          onAbsenceClick={onAbsenceClick}
          onEmptyClick={onEmptyClick}
          selectedDate={selectedDate}
          user={user}
          userUserProfileLookup={userUserProfileLookup}
        />
      );
    });

    const LONGEST_INITIALS_CHARACTER_COUNT = Math.max(
      ...userList.map(
        (user) => (userUserProfileLookup(user.url)?.alias || user.loginIdentifier).length,
      ),
    );

    const scrollContainerStyle: React.CSSProperties = {
      display: "inline-block",
      height: "100%",
      overflow: "auto",
      width: `calc(100% - ${LONGEST_INITIALS_CHARACTER_COUNT}em)`,
    };
    const headerDateCellStile: React.CSSProperties = {
      display: "inline-block",
      height: DAY_HEIGHT,
      overflow: "hidden",
      textAlign: "center",
      width: DAY_WIDTH,
    };
    const todayString = dateToString(new Date());
    const dateCells = dateSequence.map((date) => {
      const dateString = dateToString(date);
      let style = headerDateCellStile;
      if (dateString === todayString) {
        const backgroundColor: string = theme.palette.primary.main;
        const color = theme.palette.getContrastText(backgroundColor);
        style = {...style, backgroundColor, color};
      }
      if (date.getDay() === WEEKDAY_MONDAY) {
        style = {
          ...style,
          borderLeftColor: "black",
          borderLeftStyle: "solid",
          borderLeftWidth: 1,
        };
      }
      return (
        <div key={dateString} style={style}>
          {date.getDate()}
        </div>
      );
    });
    const monthDays = new Map<string, number>();
    const weekDays = new Map<string, number>();
    dateSequence.forEach((date) => {
      const dateString = dateToString(date);
      const yyyyXmmLength = 7;
      const yearMonthString = dateString.substr(0, yyyyXmmLength);
      monthDays.set(yearMonthString, (monthDays.get(yearMonthString) || 0) + 1);
      const {week, year} = weekNumber(date);
      const weekDigits = 2;
      const weekString = `${week}`.padStart(weekDigits, "0");
      const yearWeekString = `${year}-W${weekString}`;
      weekDays.set(yearWeekString, (weekDays.get(yearWeekString) || 0) + 1);
    });
    const monthCells = _.sortBy(
      Array.from(monthDays.entries()),
      ([yearMonthString, _n]) => yearMonthString,
    ).map(([yearMonthString, n]) => {
      const displayDateThresholdWidth = 3;
      let text;
      if (n > displayDateThresholdWidth) {
        text = formatMonthDate(yearMonthString);
      }
      return (
        <div
          key={yearMonthString}
          style={{
            display: "inline-block",
            height: HEADER_CELL_HEIGHT,
            overflow: "hidden",
            textAlign: "center",
            width: n * DAY_WIDTH,
          }}
        >
          {text}
        </div>
      );
    });

    let headerHeight = 2 * HEADER_CELL_HEIGHT;
    headerHeight += HEADER_CELL_HEIGHT;
    const weekCells = _.sortBy(
      Array.from(weekDays.entries()),
      ([yearWeekString, _n]) => yearWeekString,
    ).map(([yearWeekString, n]) => {
      const displayWeekThresholdWidth = 3;
      let text;
      if (n > displayWeekThresholdWidth) {
        const yyyyXWSkipIndex = 6;
        const weekNumberString = yearWeekString.substr(yyyyXWSkipIndex);
        text = formatMessage(messages.weekNumber, {
          weekNumber: parseInt(weekNumberString),
        });
      }
      const style: React.CSSProperties = {
        display: "inline-block",
        height: HEADER_CELL_HEIGHT,
        overflow: "hidden",
        textAlign: "center",
        width: n * DAY_WIDTH,
      };
      if (n >= WEEK_DAYS) {
        style.borderLeft = "1px solid black";
      }
      return (
        <div key={yearWeekString} style={style}>
          {text}
        </div>
      );
    });
    const weekHeader = (
      <div
        style={{
          height: HEADER_CELL_HEIGHT,
          overflow: "hidden",
          width: TOTAL_WEEKS * WEEK_DAYS * DAY_WIDTH + SCROLLBAR_COMPENSATION,
        }}
      >
        {weekCells}
      </div>
    );

    const filtering = !!(selectedEmployeeGroupUrlSet && selectedEmployeeGroupUrlSet.size);

    let filteringBlock: React.JSX.Element | undefined;

    if (filtering && this.props.onRequestFilterClear) {
      filteringBlock = (
        <FilterBar
          onRequestFilterClear={this.props.onRequestFilterClear}
          selectedEmployeeGroupURLSet={selectedEmployeeGroupUrlSet}
        />
      );
    }

    return (
      <div style={{height: "100%", width: "100%"}}>
        {filteringBlock}
        <div
          ref={this._datesHeader}
          style={{
            marginLeft: `${LONGEST_INITIALS_CHARACTER_COUNT}em`,
            overflow: "hidden",
            width: `calc(100% - ${LONGEST_INITIALS_CHARACTER_COUNT}em)`,
          }}
        >
          <div
            style={{
              height: HEADER_CELL_HEIGHT,
              overflow: "hidden",
              width: TOTAL_WEEKS * WEEK_DAYS * DAY_WIDTH + SCROLLBAR_COMPENSATION,
            }}
          >
            {monthCells}
          </div>
          {weekHeader}
          <div
            style={{
              height: HEADER_CELL_HEIGHT,
              overflow: "hidden",
              width: TOTAL_WEEKS * WEEK_DAYS * DAY_WIDTH + SCROLLBAR_COMPENSATION,
            }}
          >
            {dateCells}
          </div>
        </div>
        <div
          style={{
            height: `calc(100% - ${headerHeight}px`,
            overflow: "hidden",
            width: "100%",
          }}
        >
          <div
            ref={this._initialsColumn}
            style={{
              display: "inline-block",
              height: "100%",
              overflow: "hidden",
              width: `${LONGEST_INITIALS_CHARACTER_COUNT}em`,
            }}
          >
            <div
              style={{
                height: userList.length * DAY_HEIGHT + SCROLLBAR_COMPENSATION,
              }}
            >
              {userInitialsList}
            </div>
          </div>
          <div className="scrollable" ref={this._scrollContainer} style={scrollContainerStyle}>
            <div
              style={{
                height: userList.length * DAY_HEIGHT,
                width: TOTAL_WEEKS * WEEK_DAYS * DAY_WIDTH,
              }}
            >
              {rowList}
            </div>
          </div>
        </div>
      </div>
    );
  }

  scrollToSelectedDate(): void {
    const scrollNode = this._scrollContainer.current;
    if (scrollNode) {
      scrollNode.scrollLeft = PAST_WEEKS * WEEK_DAYS * DAY_WIDTH;
    }
  }
}

const AbsenceTabContentWithTheme: React.ComponentType<Omit<AbsenceTabContentProps, "theme">> =
  withTheme(AbsenceTabContent);

export default AbsenceTabContentWithTheme;
