import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getNotificationDialogContent,
  getNotificationDialogTitle,
} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

export const NotificationDialog = ({open}: {open: boolean}): React.JSX.Element => {
  const title = useSelector(getNotificationDialogTitle);
  const content = useSelector(getNotificationDialogContent);
  const dispatch = useDispatch();

  const handleOK = useCallback(() => {
    dispatch(actions.closeDialog());
  }, [dispatch]);

  return (
    <ResponsiveDialog onOk={handleOK} open={open} title={title}>
      <DialogContent style={{whiteSpace: "pre-wrap"}}>{content}</DialogContent>
    </ResponsiveDialog>
  );
};
