import {Patch, UserProfile} from "@co-common-libs/resources";
import {IconLinkButton, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader, DialogContent, IconButton} from "@material-ui/core";
import {addDanishCountryPrefix} from "app-utils";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import CellphoneIcon from "mdi-react/CellphoneIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";

const ICETable = React.memo(function ICETable({
  contact,
  contactLabel,
  contactLandline,
  contactMobile,
  contactNote,
  contactWorkNumber,
}: {
  contact: string;
  contactLabel?: string;
  contactLandline: string;
  contactMobile: string;
  contactNote: string;
  contactWorkNumber: string;
}): React.JSX.Element {
  return (
    <table>
      <tbody>
        <tr>
          <td>{contactLabel || <FormattedMessage defaultMessage="Navn:" />}</td>
          <td>{contact}</td>
        </tr>
        <tr>
          <td>
            <FormattedMessage defaultMessage="Note/relation:" />
          </td>
          <td>{contactNote}</td>
        </tr>
        <tr>
          <td>
            <FormattedMessage defaultMessage="Arbejdsplads:" />
          </td>
          <td>
            {contactWorkNumber}{" "}
            {contactWorkNumber ? (
              <IconLinkButton
                href={`tel:${addDanishCountryPrefix(contactWorkNumber)}`}
                Icon={PhoneIcon}
              />
            ) : (
              <IconLinkButton Icon={PhoneIcon} />
            )}
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage defaultMessage="Privat:" />
          </td>
          <td>
            {contactLandline}{" "}
            {contactLandline ? (
              <IconLinkButton
                href={`tel:${addDanishCountryPrefix(contactLandline)}`}
                Icon={PhoneIcon}
              />
            ) : (
              <IconLinkButton Icon={PhoneIcon} />
            )}
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage defaultMessage="Mobil:" />
          </td>
          <td>
            {contactMobile}{" "}
            {contactMobile ? (
              <IconLinkButton
                href={`tel:${addDanishCountryPrefix(contactMobile)}`}
                Icon={CellphoneIcon}
              />
            ) : (
              <IconLinkButton Icon={CellphoneIcon} />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
});

export const ICECard = React.memo(function ICECard({
  userProfile,
}: {
  userProfile: UserProfile;
}): React.JSX.Element {
  const intl = useIntl();
  const [iceEditDialogOpen, setIceEditDialogOpen] = useState(false);
  const handleEditClick = useCallback(() => {
    setIceEditDialogOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIceEditDialogOpen(false);
  }, []);
  const dispatch = useDispatch();
  const handleOk = useCallback(
    (values: {
      iceContact: string;
      iceContactLandline: string;
      iceContactMobile: string;
      iceContactNote: string;
      iceContactWorkNumber: string;
      iceSecondaryContact: string;
      iceSecondaryContactLandline: string;
      iceSecondaryContactMobile: string;
      iceSecondaryContactNote: string;
      iceSecondaryContactWorkNumber: string;
    }) => {
      const {
        iceContact,
        iceContactLandline,
        iceContactMobile,
        iceContactNote,
        iceContactWorkNumber,
        iceSecondaryContact,
        iceSecondaryContactLandline,
        iceSecondaryContactMobile,
        iceSecondaryContactNote,
        iceSecondaryContactWorkNumber,
      } = values;
      setIceEditDialogOpen(false);
      const patch: Patch<UserProfile> = [
        {
          member: "iceContact",
          value: iceContact,
        },
        {member: "iceContactLandline", value: iceContactLandline},
        {member: "iceContactMobile", value: iceContactMobile},
        {member: "iceContactNote", value: iceContactNote},
        {member: "iceContactWorkNumber", value: iceContactWorkNumber},
        {member: "iceSecondaryContact", value: iceSecondaryContact},
        {
          member: "iceSecondaryContactLandline",
          value: iceSecondaryContactLandline,
        },
        {member: "iceSecondaryContactMobile", value: iceSecondaryContactMobile},
        {member: "iceSecondaryContactNote", value: iceSecondaryContactNote},
        {
          member: "iceSecondaryContactWorkNumber",
          value: iceSecondaryContactWorkNumber,
        },
      ];
      dispatch(actions.update(userProfile.url, patch));
    },
    [dispatch, userProfile.url],
  );
  const headerPadding = 16;
  const iconButtonWidth = 48;
  const iconWidth = 24;
  const halfIconWidth = 12;
  const iconReservedWidth = iconButtonWidth + iconWidth;
  return (
    <>
      <Card>
        <div style={{position: "relative"}}>
          {!userProfile.iceContactLandline &&
          !userProfile.iceContactMobile &&
          !userProfile.iceContactWorkNumber ? (
            <div
              style={{
                color: "darkred",
                position: "absolute",
                right: headerPadding + iconButtonWidth,
                top: headerPadding + halfIconWidth,
              }}
            >
              <AlertCircleIcon />
            </div>
          ) : null}
          <IconButton
            onClick={handleEditClick}
            style={{
              position: "absolute",
              right: headerPadding,
              top: headerPadding,
            }}
          >
            <PencilIcon color="#000" />
          </IconButton>
        </div>
        <CardHeader
          style={{marginRight: iconReservedWidth}}
          title={<FormattedMessage defaultMessage="Nærmeste pårørende ved ulykke" />}
        />
        <CardContent>
          <ICETable
            contact={userProfile.iceContact}
            contactLandline={userProfile.iceContactLandline}
            contactMobile={userProfile.iceContactMobile}
            contactNote={userProfile.iceContactNote}
            contactWorkNumber={userProfile.iceContactWorkNumber}
          />

          {userProfile.iceSecondaryContact ||
          userProfile.iceSecondaryContactNote ||
          userProfile.iceSecondaryContactWorkNumber ||
          userProfile.iceSecondaryContactLandline ||
          userProfile.iceSecondaryContactMobile ? (
            <>
              <hr />
              <ICETable
                contact={userProfile.iceSecondaryContact}
                contactLabel={intl.formatMessage({
                  defaultMessage: "Navn, sekundær kontakt:",
                })}
                contactLandline={userProfile.iceSecondaryContactLandline}
                contactMobile={userProfile.iceSecondaryContactMobile}
                contactNote={userProfile.iceSecondaryContactNote}
                contactWorkNumber={userProfile.iceSecondaryContactWorkNumber}
              />
            </>
          ) : null}
        </CardContent>
      </Card>
      <ICEEditDialog
        iceContact={userProfile.iceContact}
        iceContactLandline={userProfile.iceContactLandline}
        iceContactMobile={userProfile.iceContactMobile}
        iceContactNote={userProfile.iceContactNote}
        iceContactWorkNumber={userProfile.iceContactWorkNumber}
        iceSecondaryContact={userProfile.iceSecondaryContact}
        iceSecondaryContactLandline={userProfile.iceSecondaryContactLandline}
        iceSecondaryContactMobile={userProfile.iceSecondaryContactMobile}
        iceSecondaryContactNote={userProfile.iceSecondaryContactNote}
        iceSecondaryContactWorkNumber={userProfile.iceSecondaryContactWorkNumber}
        onCancel={handleCancel}
        onOk={handleOk}
        open={iceEditDialogOpen}
      />
    </>
  );
});

const ICEEditDialog = React.memo(function ICEEditDialog({
  iceContact,
  iceContactLandline,
  iceContactMobile,
  iceContactNote,
  iceContactWorkNumber,
  iceSecondaryContact,
  iceSecondaryContactLandline,
  iceSecondaryContactMobile,
  iceSecondaryContactNote,
  iceSecondaryContactWorkNumber,
  onCancel,
  onOk,
  open,
}: {
  iceContact: string;
  iceContactLandline: string;
  iceContactMobile: string;
  iceContactNote: string;
  iceContactWorkNumber: string;
  iceSecondaryContact: string;
  iceSecondaryContactLandline: string;
  iceSecondaryContactMobile: string;
  iceSecondaryContactNote: string;
  iceSecondaryContactWorkNumber: string;
  onCancel: () => void;
  onOk: (values: {
    iceContact: string;
    iceContactLandline: string;
    iceContactMobile: string;
    iceContactNote: string;
    iceContactWorkNumber: string;
    iceSecondaryContact: string;
    iceSecondaryContactLandline: string;
    iceSecondaryContactMobile: string;
    iceSecondaryContactNote: string;
    iceSecondaryContactWorkNumber: string;
  }) => void;
  open: boolean;
}): React.JSX.Element {
  const [contact, setContact] = useState(iceContact);
  const [contactNote, setContactNote] = useState(iceContactNote);
  const [contactWorkNumber, setContactWorkNumber] = useState(iceContactWorkNumber);
  const [contactLandline, setContactLandline] = useState(iceContactLandline);
  const [contactMobile, setContactMobile] = useState(iceContactMobile);
  const [secondaryContact, setSecondaryContact] = useState(iceSecondaryContact);
  const [secondaryContactNote, setSecondaryContactNote] = useState(iceSecondaryContactNote);
  const [secondaryContactWorkNumber, setSecondaryContactWorkNumber] = useState(
    iceSecondaryContactWorkNumber,
  );
  const [secondaryContactLandline, setSecondaryContactLandline] = useState(
    iceSecondaryContactLandline,
  );
  const [secondaryContactMobile, setSecondaryContactMobile] = useState(iceSecondaryContactMobile);

  const intl = useIntl();

  useEffect(() => {
    if (open) {
      setContact(iceContact);
      setContactNote(iceContactNote);
      setContactWorkNumber(iceContactWorkNumber);
      setContactLandline(iceContactLandline);
      setContactMobile(iceContactMobile);
      setSecondaryContact(iceSecondaryContact);
      setSecondaryContactNote(iceSecondaryContactNote);
      setSecondaryContactWorkNumber(iceSecondaryContactWorkNumber);
      setSecondaryContactLandline(iceSecondaryContactLandline);
      setSecondaryContactMobile(iceSecondaryContactMobile);
    }
  }, [
    iceContact,
    iceContactLandline,
    iceContactMobile,
    iceContactNote,
    iceContactWorkNumber,
    iceSecondaryContact,
    iceSecondaryContactLandline,
    iceSecondaryContactMobile,
    iceSecondaryContactNote,
    iceSecondaryContactWorkNumber,
    open,
  ]);

  const handleOk = useCallback(() => {
    onOk({
      iceContact: contact,
      iceContactLandline: contactLandline,
      iceContactMobile: contactMobile,
      iceContactNote: contactNote,
      iceContactWorkNumber: contactWorkNumber,
      iceSecondaryContact: secondaryContact,
      iceSecondaryContactLandline: secondaryContactLandline,
      iceSecondaryContactMobile: secondaryContactMobile,
      iceSecondaryContactNote: secondaryContactNote,
      iceSecondaryContactWorkNumber: secondaryContactWorkNumber,
    });
  }, [
    contact,
    contactLandline,
    contactMobile,
    contactNote,
    contactWorkNumber,
    onOk,
    secondaryContact,
    secondaryContactLandline,
    secondaryContactMobile,
    secondaryContactNote,
    secondaryContactWorkNumber,
  ]);

  return (
    <ResponsiveDialog
      okDisabled={!contact || (!contactMobile && !contactLandline && !contactWorkNumber)}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Redigér nærmeste pårørende information"
          id="ice-edit-dialog.edit-ice"
        />
      }
    >
      <DialogContent>
        <TrimTextField
          fullWidth
          label={<FormattedMessage defaultMessage="Navn" id="ice-edit-dialog.name" />}
          margin="dense"
          onChange={setContact}
          value={contact}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          label={<FormattedMessage defaultMessage="Note/relation" id="ice-edit-dialog.note" />}
          margin="dense"
          onChange={setContactNote}
          value={contactNote}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          label={
            <FormattedMessage
              defaultMessage="Telefon, arbejdsplads"
              id="ice-edit-dialog.workplace"
            />
          }
          margin="dense"
          onChange={setContactWorkNumber}
          value={contactWorkNumber}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          label={
            <FormattedMessage defaultMessage="Telefon, privat" id="ice-edit-dialog.landline" />
          }
          margin="dense"
          onChange={setContactLandline}
          value={contactLandline}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          label={<FormattedMessage defaultMessage="Mobil" id="ice-edit-dialog.mobile" />}
          margin="dense"
          onChange={setContactMobile}
          value={contactMobile}
          variant="outlined"
        />
        <hr />
        <TrimTextField
          fullWidth
          label={intl.formatMessage({defaultMessage: "Navn, sekundær kontakt"})}
          margin="dense"
          onChange={setSecondaryContact}
          value={secondaryContact}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          label={intl.formatMessage({defaultMessage: "Note/relation"})}
          margin="dense"
          onChange={setSecondaryContactNote}
          value={secondaryContactNote}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          label={intl.formatMessage({defaultMessage: "Telefon, arbejdsplads"})}
          margin="dense"
          onChange={setSecondaryContactWorkNumber}
          value={secondaryContactWorkNumber}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          label={intl.formatMessage({defaultMessage: "Telefon, privat"})}
          margin="dense"
          onChange={setSecondaryContactLandline}
          value={secondaryContactLandline}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          label={intl.formatMessage({defaultMessage: "Mobil"})}
          margin="dense"
          onChange={setSecondaryContactMobile}
          value={secondaryContactMobile}
          variant="outlined"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
