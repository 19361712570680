import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";

const messages = defineMessages({
  startPrintout: {
    defaultMessage: "Start Udskrift?",
    id: "start-printout-dialog.title.start-printout",
  },
});

interface StartPrintoutDialogProps {
  onCancel?: () => void;
  onOk: () => void;
  open: boolean;
}

export const StartPrintoutDialog = ({
  onCancel,
  onOk,
  open,
}: StartPrintoutDialogProps): React.JSX.Element => {
  const {formatMessage} = useIntl();
  return (
    <ResponsiveDialog
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      title={formatMessage(messages.startPrintout)}
    >
      <DialogContent>
        <FormattedMessage
          defaultMessage="Alle opgaver der er klar til fakturing vil bliver overført til arkiv"
          id="start-printout-dialog.message"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
};
