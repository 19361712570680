import {CalendarWorkHours, Role, Task, TimerStart} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {
  dateFromString,
  dateToString,
  DAY_MINUTES,
  getMidnights,
  HolidayCalendarLabel,
  holidaysForYear,
  HOUR_MINUTES,
  MINUTE_MILLISECONDS,
  notNull,
} from "@co-common-libs/utils";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {format, formatISO, isSaturday, isSunday} from "date-fns";
import _ from "lodash";

export const dateFromDateAndTime = (dateString: string, timeString: string): Date => {
  const [year, month, day] = dateString.split("-");
  const [hours, minutes] = timeString.split(":");
  return new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hours),
    parseInt(minutes),
  );
};

const previousDateString = (dateString: string): string => {
  const previousDateDate = dateFromString(dateString) as Date;
  previousDateDate.setDate(previousDateDate.getDate() - 1);
  return dateToString(previousDateDate);
};

const getManagerSet = (roleArray: readonly Role[]): ReadonlySet<string> =>
  new Set(roleArray.filter((role) => role.manager).map((role) => role.user));

export const tasksForPeriod = (
  startOfPeriod: string,
  endOfPeriod: string,
  taskArray: readonly Task[],
  timerStartArray: readonly TimerStart[],
  roleArray: readonly Role[],
  onlyManagerCreatedTasksCalendarPlanned: boolean,
): {
  incompleteTasks: Task[];
  intersectingCompletedTasks: Task[];
  intersectingPlannedTasks: Task[];
  sortedTimerStarts: TimerStart[];
} => {
  const firstDate = dateToString(new Date(startOfPeriod));
  const lastDate = dateToString(new Date(endOfPeriod));
  const timeBack = new Date(startOfPeriod);
  timeBack.setDate(timeBack.getDate() - 1);
  const previousDate = previousDateString(firstDate);

  const managers = getManagerSet(roleArray);

  const intersectingPlannedTasks = taskArray.filter((task) => {
    const taskDate = task.date;
    if (!taskDate) {
      return false;
    }
    if (onlyManagerCreatedTasksCalendarPlanned && !managers.has(task.createdBy || "")) {
      // only tasks created by managers are "planned"
      return false;
    }
    if (taskDate > lastDate || taskDate < previousDate) {
      return false;
    }
    const taskTime = task.time;
    if (!taskTime) {
      return taskDate === firstDate;
    }
    const taskStartDate = dateFromString(taskDate) as Date;
    const [hours, minutes] = taskTime.split(":");
    taskStartDate.setHours(parseInt(hours), parseInt(minutes));
    const taskStartTimestamp = taskStartDate.toISOString();
    if (startOfPeriod <= taskStartTimestamp && taskStartTimestamp <= endOfPeriod) {
      return true;
    }
    const taskDuration = task.minutesExpectedTotalTaskDuration as number | null | undefined;
    if (!taskDuration) {
      return false;
    }
    const taskEndDate = new Date(taskStartDate);
    taskEndDate.setUTCMinutes(taskEndDate.getUTCMinutes() + taskDuration);
    const taskEndTimestamp = taskEndDate.toISOString();
    if (startOfPeriod <= taskEndTimestamp && taskEndTimestamp <= endOfPeriod) {
      return true;
    }
    return false;
  });

  const incompleteTasks = taskArray.filter((task) => !task.completed);

  const intersectingCompletedTasks = taskArray.filter(
    (task) =>
      !!task.completed &&
      task.workFromTimestamp &&
      task.workFromTimestamp < endOfPeriod &&
      task.workToTimestamp &&
      task.workToTimestamp > startOfPeriod,
  );

  const timerStartTaskURLSet = new Set<string>();
  incompleteTasks.forEach((task) => {
    timerStartTaskURLSet.add(task.url);
  });
  intersectingCompletedTasks.forEach((task) => {
    timerStartTaskURLSet.add(task.url);
  });
  intersectingPlannedTasks.forEach((task) => {
    timerStartTaskURLSet.add(task.url);
  });

  const sortedTimerStarts = _.sortBy(
    timerStartArray.filter((timerStart) => timerStartTaskURLSet.has(timerStart.task)),
    getNormalisedDeviceTimestamp as (timerStart: TimerStart) => string,
  );

  return {
    incompleteTasks,
    intersectingCompletedTasks,
    intersectingPlannedTasks,
    sortedTimerStarts,
  };
};

export const tasksForDate = (
  selectedDate: string,
  taskArray: readonly Task[],
  timerStartArray: readonly TimerStart[],
  roleArray: readonly Role[],
  onlyManagerCreatedTasksCalendarPlanned: boolean,
): {
  incompleteTasks: Task[];
  intersectingCompletedTasks: Task[];
  intersectingPlannedTasks: Task[];
  sortedTimerStarts: TimerStart[];
  startOfDay: string;
  startOfNextDay: string;
} => {
  const {startOfDay, startOfNextDay} = getMidnights(selectedDate);

  const {incompleteTasks, intersectingCompletedTasks, intersectingPlannedTasks, sortedTimerStarts} =
    tasksForPeriod(
      startOfDay,
      startOfNextDay,
      taskArray,
      timerStartArray,
      roleArray,
      onlyManagerCreatedTasksCalendarPlanned,
    );
  return {
    incompleteTasks,
    intersectingCompletedTasks,
    intersectingPlannedTasks,
    sortedTimerStarts,
    startOfDay,
    startOfNextDay,
  };
};

const taskCompletedTimeComparator = (a: Task, b: Task): -1 | 0 | 1 => {
  const aCompleted = a.completed;
  const bCompleted = b.completed;
  if (aCompleted && !bCompleted) {
    return -1;
  } else if (bCompleted && !aCompleted) {
    return 1;
  }
  // this is only valid and used wrt. sorting tasks that have time set
  const aTime = a.time as string;
  const bTime = b.time as string;
  if (aTime < bTime) {
    return -1;
  } else if (bTime < aTime) {
    return 1;
  }
  return 0;
};

const minutesSinceMidnight = (isoString: string): number => {
  const [hours, minutes] = isoString.split(":");
  return HOUR_MINUTES * parseInt(hours) + parseInt(minutes);
};

const dateFromIsoTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);
  return format(date, "yyyy-MM-dd");
};
const timeFromIsoTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);
  return format(date, "HH:mm:00");
};

export const plannedTasksForPeriodDates = (
  firstDate: string,
  lastDate: string,
  taskArray: readonly Task[],
): Map<string, Task[]> => {
  const beforeFirstDate = previousDateString(firstDate);
  // FIXME: Consider DST-switch?
  const maybeRelevantTaskArray = taskArray.filter((task) => {
    const taskDate = task.workFromTimestamp
      ? dateFromIsoTimestamp(task.workFromTimestamp)
      : task.date;
    if (!taskDate) {
      // NOTE: It may not be obvious from the function name that tasks without
      // a planned date should be included --- but we avoid a separate linear
      // pass over the task list to find those without planned dates where this
      // is used...
      return true;
    }
    if (taskDate > lastDate) {
      // starting *after* selected period
      return false;
    }
    const duration = task.minutesExpectedTotalTaskDuration;
    if (taskDate < beforeFirstDate && (!duration || duration < DAY_MINUTES)) {
      // starting earlier than the calendar day before selected period, and
      // lasting less than a day
      return false;
    }
    return true;
  });
  const sortedMaybeRelevantTaskArray = maybeRelevantTaskArray.sort(taskCompletedTimeComparator);
  const mutable: {[dateStr: string]: Task[]} = {};
  sortedMaybeRelevantTaskArray.forEach((task) => {
    const dateStr = task.workFromTimestamp
      ? dateFromIsoTimestamp(task.workFromTimestamp)
      : task.date || "";
    const timeStr = task.workFromTimestamp
      ? timeFromIsoTimestamp(task.workFromTimestamp)
      : (task.time as string);

    const duration = task.minutesExpectedTotalTaskDuration as number | null | undefined;
    if (!mutable[dateStr]) {
      mutable[dateStr] = [];
    }
    mutable[dateStr].push(task);
    if (task.completed && task.workFromTimestamp && task.workToTimestamp) {
      const endDate = dateFromIsoTimestamp(task.workToTimestamp);
      if (endDate > dateStr) {
        const currentDate = new Date(task.workFromTimestamp);
        currentDate.setDate(currentDate.getDate() + 1);
        // currentDate is modified

        while (dateToString(currentDate) <= endDate) {
          if (!mutable[dateToString(currentDate)]) {
            mutable[dateToString(currentDate)] = [];
          }
          mutable[dateToString(currentDate)].push(task);
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
    } else if (dateStr && timeStr && duration) {
      const date = new Date(dateStr);
      let minutesBeyond = minutesSinceMidnight(timeStr) + duration - DAY_MINUTES;
      while (minutesBeyond > 0) {
        // Add extra dates.
        date.setDate(date.getDate() + 1);
        const nextDateStr = dateToString(date);
        if (!mutable[nextDateStr]) {
          mutable[nextDateStr] = [];
        }
        mutable[nextDateStr].push(task);
        minutesBeyond = minutesBeyond - DAY_MINUTES;
      }
    }
  });
  return new Map(Object.entries(mutable));
};

const dateStringAddDays = (dateString: string, n: number): string => {
  const d = dateFromString(dateString) as Date;
  d.setDate(d.getDate() + n);
  return dateToString(d);
};

const getActiveCalendarWorkHours = (
  calendarWorkHoursArray: readonly CalendarWorkHours[],
  userURL: string,
  date: string,
): CalendarWorkHours | undefined =>
  _.maxBy(
    calendarWorkHoursArray.filter((c) => c.user === userURL && (c.fromDate as string) <= date),
    (c) => c.fromDate,
  );

export const tasksOverflowingToDate = (
  selectedDate: string,
  taskArray: readonly Task[],
  roleArray: readonly Role[],
  calendarWorkHoursArray: readonly CalendarWorkHours[],
  onlyManagerCreatedTasksCalendarPlanned: boolean,
): {overflowMinutes: number; task: Task}[] => {
  console.assert(Array.isArray(taskArray));
  console.assert(Array.isArray(roleArray));
  console.assert(Array.isArray(calendarWorkHoursArray));
  const WEEK_DAYS = 7;
  const firstCandidateDate = dateStringAddDays(selectedDate, -WEEK_DAYS);
  const managers = getManagerSet(roleArray);
  // * date, time, duration set
  // * date in past week before selectedDate
  // * created by manager user, hence "planned"
  const candidateTaskArray = taskArray.filter((task) => {
    const taskDate = task.date;
    const taskTime = task.time;
    const taskDuration = task.minutesExpectedTotalTaskDuration;
    const {machineOperator} = task;
    if (!taskDate || !taskTime || !taskDuration || !machineOperator) {
      return false;
    }
    if (onlyManagerCreatedTasksCalendarPlanned && !managers.has(task.createdBy || "")) {
      // only tasks created by managers are "planned"
      return false;
    }
    return firstCandidateDate <= taskDate && taskDate < selectedDate;
  });

  return candidateTaskArray
    .map((task) => {
      const machineOperator = task.machineOperator || "";
      const taskDate = task.date || "";
      const activeCalendarWorkHours = getActiveCalendarWorkHours(
        calendarWorkHoursArray,
        machineOperator,
        taskDate,
      );
      if (!activeCalendarWorkHours) {
        return null;
      }
      const {fromHour} = activeCalendarWorkHours;
      const {toHour} = activeCalendarWorkHours;
      if (!fromHour || !toHour) {
        return null;
      }
      const taskTime = task.time || "";
      const plannedStart = dateFromDateAndTime(taskDate, taskTime);
      const normalPeriodStart = dateFromDateAndTime(taskDate, fromHour);
      const normalPeriodEnd = dateFromDateAndTime(taskDate, toHour);
      if (normalPeriodStart <= plannedStart && plannedStart <= normalPeriodEnd) {
        let minutesRemaining = task.minutesExpectedTotalTaskDuration || 0;
        const firstDayMinutes =
          (normalPeriodEnd.valueOf() - plannedStart.valueOf()) / MINUTE_MILLISECONDS;
        minutesRemaining -= firstDayMinutes;
        if (minutesRemaining <= 0) {
          return null;
        }
        let day = dateStringAddDays(taskDate, 1);
        while (day < selectedDate) {
          // subtract for day
          const dayCalendarWorkHours = getActiveCalendarWorkHours(
            calendarWorkHoursArray,
            machineOperator,
            day,
          );
          if (!dayCalendarWorkHours) {
            return null;
          }
          const dayFromHour = dayCalendarWorkHours.fromHour;
          const dayToHour = dayCalendarWorkHours.toHour;
          if (!dayFromHour || !dayToHour) {
            return null;
          }
          const dayNormalPeriodStart = dateFromDateAndTime(day, dayFromHour);
          const dayNormalPeriodEnd = dateFromDateAndTime(day, dayToHour);
          const dayMinutes =
            (dayNormalPeriodEnd.valueOf() - dayNormalPeriodStart.valueOf()) / MINUTE_MILLISECONDS;
          minutesRemaining -= dayMinutes;
          if (minutesRemaining <= 0) {
            return null;
          }
          day = dateStringAddDays(day, 1);
        }
        return {overflowMinutes: minutesRemaining, task};
      }
      return null;
    })
    .filter(notNull);
};

const CONTINUATION_TASK_TARGET_DATE_ALLOWED_VALUES = [
  "NEXT_DAY",
  "NEXT_WEEKDAY",
  "NEXT_NON_SUNDAY",
] as const;
type ContinuationNextDateSetting = (typeof CONTINUATION_TASK_TARGET_DATE_ALLOWED_VALUES)[number];

const COMPLETED_AS_INTERNAL_TARGET_DATE_ALLOWED_VALUES = [
  "TODAY",
  "NEXT_DAY",
  "NEXT_WEEKDAY",
  "NEXT_NON_SUNDAY",
] as const;
type CompletedAsInternalNextDateSetting =
  (typeof COMPLETED_AS_INTERNAL_TARGET_DATE_ALLOWED_VALUES)[number];

const getContinuationDate = (
  selectedCalendars: readonly HolidayCalendarLabel[],
  date: Date,
  nextDateSetting: CompletedAsInternalNextDateSetting | ContinuationNextDateSetting,
): Date => {
  if (nextDateSetting === "TODAY") {
    return new Date();
  }
  const dateCopy = new Date(date);
  dateCopy.setDate(date.getDate() + 1);
  if (nextDateSetting === "NEXT_DAY") {
    return dateCopy;
  }

  const holidays = new Set([
    ...holidaysForYear(selectedCalendars, date.getFullYear()).keys(),
    ...holidaysForYear(selectedCalendars, date.getFullYear() + 1).keys(),
  ]);
  if (nextDateSetting === "NEXT_WEEKDAY") {
    while (
      isSunday(dateCopy) ||
      isSaturday(dateCopy) ||
      holidays.has(formatISO(dateCopy, {representation: "date"}))
    ) {
      dateCopy.setDate(dateCopy.getDate() + 1);
    }
    return dateCopy;
  }

  if (nextDateSetting === "NEXT_NON_SUNDAY") {
    while (isSunday(dateCopy) || holidays.has(formatISO(dateCopy, {representation: "date"}))) {
      dateCopy.setDate(dateCopy.getDate() + 1);
    }
    return dateCopy;
  }
  return date;
};

export const getContinuationTaskTargetDate = (
  selectedCalendars: readonly HolidayCalendarLabel[],
  date: Date,
  nextDateSetting: ContinuationNextDateSetting,
): Date => {
  if (!CONTINUATION_TASK_TARGET_DATE_ALLOWED_VALUES.includes(nextDateSetting)) {
    getFrontendSentry().captureMessage(
      `${nextDateSetting} is not valid for setting "continuationTaskTargetDate"`,
    );
    return date;
  }
  const newDate = getContinuationDate(selectedCalendars, date, nextDateSetting);
  if (newDate) {
    return newDate;
  }
  return date;
};

export const getCompletedAsInternalNewDate = (
  selectedCalendars: readonly HolidayCalendarLabel[],
  date: Date,
  nextDateSetting: CompletedAsInternalNextDateSetting,
): Date => {
  if (!COMPLETED_AS_INTERNAL_TARGET_DATE_ALLOWED_VALUES.includes(nextDateSetting)) {
    getFrontendSentry().captureMessage(
      `${nextDateSetting} is not valid for setting "completeTaskAsInternalTargetDate"`,
    );
    return date;
  }
  const newDate = getContinuationDate(selectedCalendars, date, nextDateSetting);
  if (newDate) {
    return newDate;
  }
  return date;
};
