import {
  PatchUnion,
  ResourceTypeUnion,
  Role,
  TaskPhoto,
  UserFile,
  UserPhoto,
} from "@co-common-libs/resources";
import {DeleteDialog} from "@co-frontend-libs/components";
import {
  actions,
  AppState,
  getCurrentRole,
  getCurrentUserURL,
  getShareToken,
  getUserFileArray,
  getUserPhotoArray,
} from "@co-frontend-libs/redux";
import {Button} from "@material-ui/core";
import {
  appPhotoHelper,
  PDFInstance,
  PhotoDisplayDialog,
  PhotoInstance,
  SourceType,
  useFileInputChangeHandler,
} from "app-components";
import {uploadFile} from "app-utils";
import React, {useCallback, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  addFiles: {
    defaultMessage: "Tilføj billeder (JPEG/PNG)",
    id: "user-instance.label.add-photos",
  },
  addPDF: {
    defaultMessage: "Tilføj PDF",
    id: "user-instance.label.add-PDF",
  },
  choosePDF: {
    defaultMessage: "Vælg PDF",
    id: "user-instance.label.choose-pdf",
  },
  choosePhoto: {
    defaultMessage: "Vælg billede",
    id: "user-instance.label.choose-photo",
  },
  invalidFile: {
    defaultMessage: "Ikke en gyldig billedfil: {name}",
    id: "user-instance.toast.invalid-image-file",
  },
  note: {
    defaultMessage: "Note",
    id: "user-instance.label.note",
  },
  takePhoto: {
    defaultMessage: "Tag billede",
    id: "user-instance.label.take-photo",
  },
});

interface FilesTabStateProps {
  currentRole: Role | null;
  currentUserURL: string | null;
  shareToken: string | null;
  userFileArray: readonly UserFile[];
  userPhotoArray: readonly UserPhoto[];
}

interface FilesTabDispatchProps {
  addToOffline: (instance: ResourceTypeUnion) => void;
  create: (instance: ResourceTypeUnion) => void;
  remove: (url: string) => void;
  setMessage: (message: string, timestamp: Date) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface FilesTabOwnProps {
  userURL: string;
}

type FilesTabProps = FilesTabDispatchProps & FilesTabOwnProps & FilesTabStateProps;

const FilesTab = ({
  addToOffline,
  create,
  currentRole,
  currentUserURL,
  remove,
  setMessage,
  shareToken,
  userFileArray,
  userPhotoArray,
  userURL,
}: FilesTabProps): React.JSX.Element => {
  const {formatMessage} = useIntl();
  const isManager = currentRole && currentRole.manager;

  const fileDropAccepted = useCallback(
    (files: File[]): void => {
      const now = new Date();
      const commonExtraData = {
        createdBy: currentUserURL || undefined,
        taken: now.toISOString(),
        user: userURL,
      };
      files.forEach((file) => {
        const name = file.name.replace(/\.[^.]*$/, "");
        const extraData = {note: name, ...commonExtraData};
        uploadFile(file, "userPhoto", extraData, "original", create, addToOffline);
      });
    },
    [addToOffline, create, currentUserURL, userURL],
  );

  const fileDropRejected = useCallback(
    (file: File): void => {
      const message = formatMessage(messages.invalidFile, {name: file.name});
      window.setTimeout(() => {
        setMessage(message, new Date());
      }, 0);
    },
    [formatMessage, setMessage],
  );

  const handleAppCameraButton = useCallback((): void => {
    appPhotoHelper(SourceType.CAMERA, fileDropAccepted);
  }, [fileDropAccepted]);

  const pdfFileDropAccepted = useCallback(
    (files: File[]): void => {
      const now = new Date();
      const commonExtraData = {
        created: now.toISOString(),
        createdBy: currentUserURL || undefined,
        user: userURL,
      };
      files.forEach((file) => {
        const name = file.name.replace(/\.[^.]*$/, "");
        const extraData = {note: name, ...commonExtraData};
        uploadFile(file, "userFile", extraData, "data", create, addToOffline);
      });
    },
    [addToOffline, create, currentUserURL, userURL],
  );

  const handleFileInputChange = useFileInputChangeHandler(
    fileDropAccepted,
    fileDropRejected,
    "image/jpeg,image/png",
  );
  const handlePDFFileInputChange = useFileInputChangeHandler(
    pdfFileDropAccepted,
    fileDropRejected,
    "application/pdf",
  );

  const [displayedImage, setDisplayedImage] = useState<TaskPhoto | UserPhoto | undefined>();
  const handlePhotoDisplay = useCallback((userPhoto: TaskPhoto | UserPhoto): void => {
    setDisplayedImage(userPhoto);
  }, []);

  const handleDisplayImageRequestClose = useCallback((): void => {
    setDisplayedImage(undefined);
  }, []);

  const [deleteDialogOpenedFor, setDeleteDialogOpenedFor] = useState<string | undefined>();

  const handleDeleteClick = useCallback((url: string): void => {
    setDeleteDialogOpenedFor(url);
  }, []);

  const handleDeleteCancel = useCallback((): void => {
    setDeleteDialogOpenedFor(undefined);
  }, []);

  const handleDelete = useCallback((): void => {
    if (!deleteDialogOpenedFor) {
      return;
    }
    remove(deleteDialogOpenedFor);

    setDeleteDialogOpenedFor(undefined);
  }, [deleteDialogOpenedFor, remove]);

  const photoElementList = userPhotoArray
    .filter((userPhoto) => userPhoto.user === userURL)
    .map((userPhoto) => {
      return (
        <PhotoInstance
          editDisabled={!isManager}
          key={userPhoto.url}
          onDeleteButton={isManager ? handleDeleteClick : undefined}
          onDisplay={handlePhotoDisplay}
          photo={userPhoto}
          shareToken={shareToken}
          showAddedDate
        />
      );
    });
  const fileElementList = userFileArray
    .filter((userFile) => userFile.user === userURL)
    .map((userFile) => {
      return (
        <PDFInstance
          editDisabled={!isManager}
          file={userFile}
          key={userFile.url}
          onDeleteButton={isManager ? handleDeleteClick : undefined}
          shareToken={shareToken}
          showAddedDate
        />
      );
    });

  let appFilesButton: React.JSX.Element | undefined;
  let appCameraButton: React.JSX.Element | undefined;
  let appPDFButton: React.JSX.Element | undefined;
  let browserFilesButton: React.JSX.Element | undefined;
  let browserPDFButton: React.JSX.Element | undefined;
  if (isManager) {
    if (window.cordova) {
      appFilesButton = (
        <>
          <input
            accept="image/jpeg,image/png"
            id="app-photos-input"
            multiple
            onChange={handleFileInputChange}
            style={{display: "none"}}
            type="file"
          />
          <label htmlFor="app-photos-input">
            <Button
              color="secondary"
              component="span"
              style={{margin: "0.5em"}}
              variant="contained"
            >
              {formatMessage(messages.choosePhoto)}
            </Button>
          </label>
        </>
      );
      appCameraButton = (
        <Button
          color="secondary"
          onClick={handleAppCameraButton}
          style={{margin: "0.5em"}}
          variant="contained"
        >
          {formatMessage(messages.takePhoto)}
        </Button>
      );
      appPDFButton = (
        <>
          <input
            accept="application/pdf"
            id="app-pdf-input"
            multiple
            onChange={handlePDFFileInputChange}
            style={{display: "none"}}
            type="file"
          />
          <label htmlFor="app-pdf-input">
            <Button
              color="secondary"
              component="span"
              style={{margin: "0.5em"}}
              variant="contained"
            >
              {formatMessage(messages.choosePDF)}
            </Button>
          </label>
        </>
      );
    } else {
      // https://material-ui.com/components/buttons/#upload-button
      browserFilesButton = (
        <>
          <input
            accept="image/jpeg,image/png"
            id="browser-files-button"
            multiple
            onChange={handleFileInputChange}
            style={{display: "none"}}
            type="file"
          />
          <label htmlFor="browser-files-button">
            <Button
              color="secondary"
              component="span"
              style={{margin: "0.5em"}}
              variant="contained"
            >
              {formatMessage(messages.addFiles)}
            </Button>
          </label>
        </>
      );
      browserPDFButton = (
        <>
          <input
            accept="application/pdf"
            id="browser-pdf-button"
            multiple
            onChange={handlePDFFileInputChange}
            style={{display: "none"}}
            type="file"
          />
          <label htmlFor="browser-pdf-button">
            <Button
              color="secondary"
              component="span"
              style={{margin: "0.5em"}}
              variant="contained"
            >
              {formatMessage(messages.addPDF)}
            </Button>
          </label>
        </>
      );
    }
  }
  return (
    <div>
      <div style={{padding: "0.5em"}}>
        {appCameraButton}
        {appFilesButton}
        {appPDFButton}
      </div>
      <div style={{overflow: "auto", padding: "0.5em", paddingTop: 0}}>
        {photoElementList}
        {fileElementList}
      </div>
      <div style={{padding: "0.5em", paddingTop: 0}}>
        {browserFilesButton}
        {browserPDFButton}
      </div>
      <PhotoDisplayDialog
        instance={displayedImage}
        key="photo-display-dialog"
        onRequestClose={handleDisplayImageRequestClose}
      />
      <DeleteDialog
        onCancel={handleDeleteCancel}
        onOk={handleDelete}
        open={!!deleteDialogOpenedFor}
      />
    </div>
  );
};

const ConnectedFilesTab: React.ComponentType<FilesTabOwnProps> = connect<
  FilesTabStateProps,
  FilesTabDispatchProps,
  FilesTabOwnProps,
  AppState
>(
  createStructuredSelector<AppState, FilesTabStateProps>({
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    shareToken: getShareToken,
    userFileArray: getUserFileArray,
    userPhotoArray: getUserPhotoArray,
  }),
  {
    addToOffline: actions.addToOffline,
    create: actions.create,
    remove: actions.remove,
    setMessage: actions.setMessage,
    update: actions.update,
  },
)(FilesTab);

export {ConnectedFilesTab as FilesTab};
