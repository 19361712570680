import {Journal, LocationUrl} from "@co-common-libs/resources";
import {useWindowSize} from "@co-frontend-libs/utils";
import {Theme, useTheme} from "@material-ui/core";
import {FieldEntry} from "app-utils";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {
  ListChildComponentProps,
  ListOnScrollProps,
  VariableSizeList,
  VariableSizeListProps,
} from "react-window";
import {DisplayAllFields} from "../display-all-fields-item";
import {FieldSingleSelectionListItem} from "./field-single-selection-list-item";

type SpecialEntry = "-DISPLAY-ALL-FIELDS-";

const listItemTopPadding = 8;
const listItemBottomPadding = 8;
const listItemTextTopMargin = 6;
const listItemTextBottomMargin = 6;
const checkboxHeight = 42;

const checkBoxItemMinHeight = listItemTopPadding + listItemBottomPadding + checkboxHeight;

const itemTotalMarginPadding =
  listItemTopPadding + listItemBottomPadding + listItemTextTopMargin + listItemTextBottomMargin;

const primaryLineHeight = 24; // uses theme.typography.body1
const secondaryLineHeight = 20; // uses theme.typography.body2

interface RenderRowData extends ListChildComponentProps {
  data: {
    readonly combinedEntries: readonly (FieldEntry | SpecialEntry)[];
    readonly displayAllFields: boolean;
    readonly lastUsedLocations?: ReadonlySet<string> | undefined;
    readonly locationJournalMap: Map<string, Journal[]>;
    readonly onDisplayAllFieldsChange: ((displayAll: boolean) => void) | undefined;
    readonly onSelect: (url: LocationUrl) => void;
    readonly theme: Theme;
    readonly withIcons: boolean;
  };
}

function renderRow(rowProps: RenderRowData): React.JSX.Element {
  const {data, index, style} = rowProps;
  const {
    combinedEntries,
    displayAllFields,
    lastUsedLocations,
    locationJournalMap,
    onDisplayAllFieldsChange,
    onSelect,
    theme,
    withIcons,
  } = data;
  const entry = combinedEntries[index];
  if (entry === "-DISPLAY-ALL-FIELDS-") {
    return (
      <DisplayAllFields
        displayAllFields={displayAllFields}
        onDisplayAllFieldsChange={
          // -DISPLAY-ALL-FIELDS- only present with onDisplayAllFieldsChange
          onDisplayAllFieldsChange as (displayAll: boolean) => void
        }
        style={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          ...style,
        }}
      />
    );
  } else {
    const {field, matches} = entry;
    const {url} = field;
    return (
      <FieldSingleSelectionListItem
        field={field}
        journalEntries={locationJournalMap.get(url)}
        key={url}
        matches={matches}
        onSelect={onSelect}
        recentlyUsed={lastUsedLocations?.has(url)}
        style={style}
        value={url}
        withIcons={withIcons}
      />
    );
  }
}

interface FieldSingleSelectionListProps {
  readonly displayAllFields: boolean;
  readonly entries: readonly FieldEntry[];
  readonly fullscreenLayout?: boolean;
  readonly isSearchResult: boolean;
  readonly lastUsedLocations?: ReadonlySet<string> | undefined;
  readonly locationJournalMap: Map<string, Journal[]>;
  readonly onDisplayAllFieldsChange: ((displayAll: boolean) => void) | undefined;
  readonly onScroll?: (props: ListOnScrollProps) => void;
  readonly onSelect: (url: LocationUrl) => void;
  readonly withIcons: boolean;
}

export const FieldSingleSelectionList = React.memo(function FieldSingleSelectionList(
  props: FieldSingleSelectionListProps,
): React.JSX.Element {
  const {
    displayAllFields,
    entries,
    fullscreenLayout,
    isSearchResult,
    lastUsedLocations,
    locationJournalMap,
    onDisplayAllFieldsChange,
    onScroll,
    onSelect,
    withIcons,
  } = props;

  const theme = useTheme();

  const variableSizeListRef = useRef<VariableSizeList | null>(null);

  useEffect(() => {
    if (variableSizeListRef.current) {
      variableSizeListRef.current.resetAfterIndex(0);
    }
  }, [isSearchResult]);

  const combinedEntries: readonly (FieldEntry | SpecialEntry)[] = useMemo(() => {
    const result: (FieldEntry | SpecialEntry)[] = entries.slice();
    if (onDisplayAllFieldsChange) {
      result.unshift("-DISPLAY-ALL-FIELDS-");
    }
    return result;
  }, [entries, onDisplayAllFieldsChange]);

  const getItemSize = useCallback(
    (index: number): number => {
      const entry = combinedEntries[index];
      if (entry === "-DISPLAY-ALL-FIELDS-") {
        const dividerBorder = 1;
        return Math.max(
          itemTotalMarginPadding + primaryLineHeight + dividerBorder,
          checkBoxItemMinHeight,
        );
      } else if (entry.matches) {
        return itemTotalMarginPadding + primaryLineHeight + secondaryLineHeight;
      } else {
        return itemTotalMarginPadding + primaryLineHeight;
      }
    },
    [combinedEntries],
  );

  const getItemKey = useCallback(
    (index: number): string => {
      const entry = combinedEntries[index];
      if (entry === "-DISPLAY-ALL-FIELDS-") {
        return entry;
      } else {
        return entry.field.url;
      }
    },
    [combinedEntries],
  );

  let listWidth = 0;
  let listHeight = 0;

  const {windowInnerHeight, windowInnerWidth} = useWindowSize();

  if (fullscreenLayout) {
    listWidth = windowInnerWidth;
    const headerHeight = 56;
    listHeight = windowInnerHeight - headerHeight;
  } else {
    const dialogMargin = 32;
    const dialogMaxWidth = 960;
    listWidth = Math.min(dialogMaxWidth, windowInnerWidth - (dialogMargin + dialogMargin));
    const dialogMaxHeight = windowInnerHeight - (dialogMargin + dialogMargin);
    const headerHeight = 116;
    const footerHeight = 52;
    const dividerLineHeight = 1;
    const listMaxHeight =
      dialogMaxHeight - (headerHeight + footerHeight + dividerLineHeight + dividerLineHeight);
    for (let i = 0; i < combinedEntries.length && listHeight < listMaxHeight; i += 1) {
      listHeight += getItemSize(i);
    }
    listHeight = Math.min(listHeight, listMaxHeight);
  }

  const itemData: RenderRowData["data"] = {
    combinedEntries,
    displayAllFields,
    lastUsedLocations,
    locationJournalMap,
    onDisplayAllFieldsChange,
    onSelect,
    theme,
    withIcons,
  };

  const optionalProps: Partial<VariableSizeListProps> = {};
  if (onScroll) {
    optionalProps.onScroll = onScroll;
  }

  return (
    <div>
      <VariableSizeList
        estimatedItemSize={itemTotalMarginPadding + primaryLineHeight}
        height={listHeight}
        itemCount={combinedEntries.length}
        itemData={itemData}
        itemKey={getItemKey}
        itemSize={getItemSize}
        ref={variableSizeListRef}
        width={listWidth}
        {...optionalProps}
      >
        {renderRow}
      </VariableSizeList>
    </div>
  );
});
