import {Checkbox, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";

interface ColumnListItemProps<ColumnChoice extends string> {
  checked: boolean;
  column: ColumnChoice;
  onClick: (column: ColumnChoice) => void;
}

export function ColumnListItem<ColumnChoice extends string>(
  props: ColumnListItemProps<ColumnChoice>,
): React.JSX.Element {
  const {checked, column, onClick} = props;
  const handleClick = useCallback(() => {
    onClick(column);
  }, [column, onClick]);
  return (
    <ListItem button dense key={column} onClick={handleClick}>
      <ListItemIcon>
        <Checkbox checked={checked} edge="start" />
      </ListItemIcon>
      <ListItemText primary={column} />
    </ListItem>
  );
}
