import {getReportingSpecificationLookup, makePathParameterGetter} from "@co-frontend-libs/redux";
import {Grid} from "@material-ui/core";
import {BackToolbar, PageLayout} from "app-components";
import {instanceURL} from "frontend-global-config";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {ReportingSpecificationEditForm} from "./edit-form";
import {ReportingSpecificationNewVersionForm} from "./new-version-form";

export const ReportingSpecificationEntry = React.memo(
  function ReportingSpecificationEntry(): React.JSX.Element {
    const idParameterGetter = useMemo(() => makePathParameterGetter("id"), []);
    const id = useSelector(idParameterGetter);
    const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
    const reportingSpecification = reportingSpecificationLookup(
      instanceURL("reportingSpecification", id),
    );

    return (
      <PageLayout
        toolbar={
          <BackToolbar
            title={
              <FormattedMessage defaultMessage="Rediger logopsætning" id="reporting.entry.title" />
            }
          />
        }
        withPadding
      >
        {reportingSpecification ? (
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <ReportingSpecificationEditForm reportingSpecification={reportingSpecification} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <ReportingSpecificationNewVersionForm
                reportingSpecification={reportingSpecification}
              />
            </Grid>
          </Grid>
        ) : null}
      </PageLayout>
    );
  },
);
