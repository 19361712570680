import {getCustomerSettings, getSettingsEntryLookupByIdentifier} from "@co-frontend-libs/redux";
import _ from "lodash";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";
import {defaultAbsenceTypeLabels} from "./absence-type-labels";

export function DisplayAbsenceTypes(props: SettingValueDisplayProps): React.JSX.Element {
  const {settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const currentlySelected: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);

  const customerSettings = useSelector(getCustomerSettings);
  const {absenceTypeLabels} = customerSettings;

  const knownAbsenceTypeLabels: {[key: string]: string} = useMemo(
    () => ({
      ...defaultAbsenceTypeLabels,
      ...absenceTypeLabels,
    }),
    [absenceTypeLabels],
  );
  const intl = useIntl();
  const currentlySelectedLabels = currentlySelected.map(
    (absenceType) =>
      knownAbsenceTypeLabels[absenceType] ||
      intl.formatMessage(
        {defaultMessage: 'Faværstypen "{absenceType}" eksisterer ikke'},
        {absenceType},
      ),
  );

  return <div>{_.sortBy(currentlySelectedLabels).join(", ")}</div>;
}
