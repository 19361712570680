import {
  CountBonusFormat,
  WeekAbsenceCompensatoryNormalHoursMinutesSpecification,
} from "@co-common-libs/config";
import {
  MachineUrl,
  PriceGroupUrl,
  ProjectUrl,
  TaskUrl,
  TimerUrl,
  UserUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {DayTypeHoliday, HolidayCalendarLabel, WeekdayNumberType} from "@co-common-libs/utils";

export type {RemunerationGroup as RemunerationGroupInput} from "@co-common-libs/config";

export interface TimerStart {
  readonly deviceTimestamp: string;
  readonly deviceUuid: string | undefined;
  readonly employee: UserUrl;
  readonly serverTimestamp: string | undefined;
  readonly task: TaskUrl;
  readonly timer: TimerUrl | null;
}
export interface SimpleTimerStart {
  readonly deviceTimestamp: string;
  readonly timer: TimerUrl | null;
}

export interface SimpleInterval {
  readonly fromTimestamp: string;
  readonly toTimestamp: string;
}

export interface TimerInterval extends SimpleInterval {
  readonly timer: TimerUrl | null;
}

export interface IntervalTaskData {
  readonly customerTask: boolean;
  readonly department: string | null;
  readonly effectiveTime: boolean;
  readonly machineURLs: readonly MachineUrl[];
  readonly orderReferenceNumber: string;
  readonly priceGroupURLs: readonly PriceGroupUrl[];
  readonly projectURL: ProjectUrl | null;
  readonly taskPriceGroupURL: PriceGroupUrl | null;
  readonly taskReferenceNumber: string;
  readonly taskURL: TaskUrl;
  readonly taskWorkTypeURL: WorkTypeUrl | null;
  readonly timerURL: TimerUrl | null;
  readonly workTypeURL: WorkTypeUrl | null;
}

export interface TaskInterval extends SimpleInterval {
  readonly isBreak: boolean;
  readonly taskData: readonly IntervalTaskData[];
}

export interface TaskIntervalWithUnregistered extends TaskInterval {
  readonly unregistered: boolean;
}

export interface TaskIntervalWithUnregisteredAndPaid extends TaskIntervalWithUnregistered {
  readonly isPaid: boolean;
}

export enum Rate {
  NORMAL = 0,
  OVERTIME_1 = 1,
  OVERTIME_10 = 10,
  OVERTIME_11 = 11,
  OVERTIME_12 = 12,
  OVERTIME_13 = 13,
  OVERTIME_14 = 14,
  OVERTIME_15 = 15,
  OVERTIME_2 = 2,
  OVERTIME_3 = 3,
  OVERTIME_4 = 4,
  OVERTIME_5 = 5,
  OVERTIME_6 = 6,
  OVERTIME_7 = 7,
  OVERTIME_8 = 8,
  OVERTIME_9 = 9,
  SPECIAL_START_RATE = -1,
  UNPAID = -2,
}

export const MAX_OVERTIME_RATE = Rate.OVERTIME_15;

export interface WithRate {
  readonly rate: Rate;
}

export interface SimpleIntervalWithRate extends SimpleInterval, WithRate {}

export interface TaskIntervalWithRate extends TaskIntervalWithUnregisteredAndPaid, WithRate {}

export interface WorkPeriod {
  readonly breaks: readonly SimpleInterval[];
  readonly date: string;
  readonly firstFromTimestamp: string;
  readonly lastToTimestamp: string;
  readonly work: readonly TaskIntervalWithRate[];
}

export interface BaseWorkDay {
  readonly date: string;
  readonly workPeriods: readonly WorkPeriod[];
}

export interface WorkDay extends BaseWorkDay {
  readonly extraRateMinutes: ReadonlyMap<Rate, number>;
}

export interface TaskIntervalWithBonus extends TaskIntervalWithRate {
  readonly bonus: readonly string[];
}

export interface WorkPeriodWithBonus {
  readonly breaks: readonly SimpleInterval[];
  readonly date: string;
  readonly firstFromTimestamp: string;
  readonly lastToTimestamp: string;
  readonly work: readonly TaskIntervalWithBonus[];
}

export interface WorkDayWithBonus {
  readonly bonus: readonly string[];
  /** bonus -> dates */
  readonly calendarDayBonus: ReadonlyMap<string, ReadonlySet<string>>;
  readonly calledIn: number;
  readonly countBonus: ReadonlyMap<string, number>;
  readonly date: string;
  readonly extraRateMinutes: ReadonlyMap<Rate, number>;
  readonly projectDistance: number;
  readonly projectTravelTime: number;
  /** bonus -> taskIds */
  readonly taskBonus: ReadonlyMap<string, ReadonlySet<string>>;
  readonly workPeriods: readonly WorkPeriodWithBonus[];
}

export interface SimplifiedWorkDay {
  readonly actualWorkMinutes: number;
  readonly bonusMinutes: ReadonlyMap<string, number>;
  readonly breakMinutes: number;
  readonly breakPeriods: readonly SimpleInterval[];
  readonly calendarDayBonus: ReadonlyMap<string, number>;
  readonly calledIn: number;
  readonly countBonus: ReadonlyMap<string, number>;
  readonly date: string;
  readonly orderReferenceNumbers: readonly string[];
  readonly projectDistance: number;
  readonly projects: readonly {
    readonly alias: string;
    readonly name: string;
    readonly projectNumber: string;
  }[];
  readonly projectTravelTime: number;
  readonly rateMinutes: ReadonlyMap<Rate, number>;
  readonly taskBonus: ReadonlyMap<string, number>;
  readonly taskReferenceNumbers: readonly string[];
  readonly workDayBonus: readonly string[];
  readonly workPeriods: readonly SimpleInterval[];
}

export interface SimplifiedWorkDayWithAccomodation extends SimplifiedWorkDay {
  readonly accomodationDay: boolean;
  readonly accomodationMinutes: number;
}

export interface SimplifiedWorkDayWithMeta extends SimplifiedWorkDayWithAccomodation {
  readonly daysAbsence: ReadonlyMap<
    string,
    readonly {readonly employeeReason: string; readonly note: string}[]
  >;
  readonly dinnerBookings: number;
  readonly holiday: string | null;
  readonly lunchBookings: number;
  readonly minutesAbsence: ReadonlyMap<
    string,
    readonly {
      readonly employeeReason: string;
      readonly fromTimestamp: string;
      readonly note: string;
      readonly toTimestamp: string;
    }[]
  >;
  readonly paidWeekdayHoliday: boolean | null;
}

export interface SimplifiedPoolPeriodWithMeta {
  readonly bonusMinutes: ReadonlyMap<string, number>;
  readonly breakMinutes: number;
  readonly daysAbsence: ReadonlyMap<string, number>;
  readonly daysMinutesAbsence: ReadonlyMap<string, number>;
  readonly dinnerBookings: number;
  readonly fromDate: string;
  readonly lunchBookings: number;
  readonly minutesAbsence: ReadonlyMap<string, number>;
  readonly rateMinutes: ReadonlyMap<Rate, number>;
  readonly toDate: string;
  readonly workDayBonus: ReadonlyMap<string, number>;
}

export interface SimplifiedPoolPeriodWithMetaAndCompensatory extends SimplifiedPoolPeriodWithMeta {
  readonly depositedCompensatoryMinutes: number;
  readonly potentialCompensatoryChange: number;
  readonly potentialNormalMinutes: number;
  readonly withdrawnCompensatoryMinutes: number;
}

export type DayHoursRatesSpecification = readonly Readonly<[string, string, number]>[];

export type WeekDayHoursRatesSpecification = Readonly<
  [
    DayHoursRatesSpecification,
    DayHoursRatesSpecification,
    DayHoursRatesSpecification,
    DayHoursRatesSpecification,
    DayHoursRatesSpecification,
    DayHoursRatesSpecification,
    DayHoursRatesSpecification,
  ]
>;

export type DayThresholdsSpecification = readonly (number | readonly [number, Rate])[];

export type WeekThresholdsSpecification = Readonly<
  [
    DayThresholdsSpecification,
    DayThresholdsSpecification,
    DayThresholdsSpecification,
    DayThresholdsSpecification,
    DayThresholdsSpecification,
    DayThresholdsSpecification,
    DayThresholdsSpecification,
  ]
>;

export interface WeekPoolSpecification {
  overtimeThreshold?: number;
  overtimeThresholds?: readonly number[];
  type: "week";
  weekAbsenceCompensatoryNormalHoursMinutes?: Readonly<WeekAbsenceCompensatoryNormalHoursMinutesSpecification> | null;
  weekStart: number;
}
export interface MonthPoolSpecification {
  overtimeThreshold?: number;
  overtimeThresholds?: readonly number[];
  periodStart: number;
  type: "month";
  weekAbsenceCompensatoryNormalHoursMinutes?: Readonly<WeekAbsenceCompensatoryNormalHoursMinutesSpecification> | null;
}
export interface NormalisedWeekPoolSpecification {
  dayThresholds?: WeekThresholdsSpecification;
  type: "normalisedWeek";
  // overrides normal/other overtime thresholds when specified
  weekStart: number;
}
export interface TwoWeekPoolSpecification {
  overtimeThreshold?: number;
  overtimeThresholds?: readonly number[];
  startDate: string;
  type: "twoWeeks";
  weekAbsenceCompensatoryNormalHoursMinutes?: Readonly<WeekAbsenceCompensatoryNormalHoursMinutesSpecification> | null;
}
export interface FourWeekPoolSpecification {
  overtimeThreshold?: number;
  overtimeThresholds?: readonly number[];
  startDate: string;
  type: "fourWeeks";
  weekAbsenceCompensatoryNormalHoursMinutes?: Readonly<WeekAbsenceCompensatoryNormalHoursMinutesSpecification> | null;
}

export type PoolSpecification =
  | FourWeekPoolSpecification
  | MonthPoolSpecification
  | NormalisedWeekPoolSpecification
  | TwoWeekPoolSpecification
  | WeekPoolSpecification;

// Setting intervalBonus:
// Each interval that fulfills the criteria for a bonus specification
// has the bonus with that label.
//
// Setting workdayBonus:
// Each workday that has at least one interval that furfills the criteria
// for a bonus specification has the bonus with that label.
//
// All criteria actually specified need to be fulfilled simultaneously;
// if several combinations should give the same bonus, this can be expressed
// as multiple bonus specifications with the same resulting label.
export interface IntervalMatchCriteria {
  // no bonus for the first N minutes within the same calendar day or work day
  // that fulfills the (other) criteria
  // currently no effect for workday/calendar day bonus...
  readonly afterThreshold?: {
    readonly minutes: number;
    readonly sum: "calendarDay" | "workDay";
  };
  // check for overlap with dates MM-DD dates --- fromDate and toDate included
  readonly checkDates?: {
    fromDay: number;
    fromMonth: number;
    toDay: number;
    toMonth: number;
  };
  // check for overlap with time interval
  readonly checkInterval?: {
    readonly fromDaySeconds: number;
    readonly toDaySeconds: number;
  };
  // external task/not external task/ignored when undefined
  readonly customerTask?: boolean;
  // check day of the week; 0--6 for sunday--saturday, holidays as sundays
  readonly dayOfTheWeek?: WeekdayNumberType;
  // check task department
  readonly departmentID?: string;
  // task effecive time/not effective time/ignored when undefined
  readonly effectiveTime?: boolean;
  // check interval.machineURLs.includes(machineURL)
  readonly machineURL?: MachineUrl;
  // following day is sunday/holiday
  readonly nextDayIsHoliday?: boolean;
  // check priceGroupURL against task price group, timer price group and machine price groups
  readonly priceGroupURL?: PriceGroupUrl;
  // check interval.rate === rate
  readonly rate?: Rate;
  // check taskPriceGroupURL against only the task price group
  readonly taskPriceGroupURL?: PriceGroupUrl;
  // check taskWorkTypeURL against only the task work type
  readonly taskWorkTypeURL?: WorkTypeUrl;
  // check workTypeURL against the timer work type and the task work type
  readonly workTypeURL?: WorkTypeUrl;
}

export interface BonusSpecification extends IntervalMatchCriteria {
  // output label
  readonly label: string;
}

export interface RateOverrideSpecification extends IntervalMatchCriteria {
  // output rate
  readonly resultingRate: Rate;
}

export interface InputIntervalMatchCriteria {
  readonly afterThreshold?: {
    readonly minutes: number;
    readonly sum: "calendarDay" | "workDay";
  };
  readonly checkDates?: {readonly fromDate: string; readonly toDate: string};
  readonly checkInterval?: {readonly fromTime: string; readonly toTime: string};
  readonly customerTask?: boolean;
  readonly dayOfTheWeek?: WeekdayNumberType;
  readonly departmentID?: string;
  readonly departmentId?: string;
  readonly effectiveTime?: boolean;
  readonly machineID?: string;
  readonly machineId?: string;
  readonly nextDayIsHoliday?: boolean;
  readonly priceGroupId?: string;
  readonly priceGroupID?: string;
  readonly rate?: Rate;
  readonly taskPriceGroupID?: string;
  readonly taskPriceGroupId?: string;
  readonly taskWorkTypeId?: string;
  readonly taskWorkTypeID?: string;
  readonly workTypeId?: string;
  readonly workTypeID?: string;
}

export interface InputBonusSpecification extends InputIntervalMatchCriteria {
  readonly label: string;
}

export interface InputRateOverrideSpecification extends InputIntervalMatchCriteria {
  readonly resultingRate: Rate;
}

export type PoolPeriodStartFunction = (date: Date) => Date;
export type PoolPeriodEndFunction = (poolPeriodStart: Date) => Date;
export type PoolPeriodThresholdsFunction = (fromDate: Date, toDate: Date) => readonly number[];

export type HoursRatesFunction = (date: Date) => DayHoursRatesSpecification;

export type OvertimeThresholdFunction = (date: Date) => number | undefined;
export type OvertimeThresholdsFunction = (
  date: Date,
) => readonly (number | readonly [number, number])[];

export type HolidayCheckFunction = (dateString: string) => DayTypeHoliday;

export interface InputSwitchGroupSpecification {
  readonly departmentID?: string;
  readonly departmentId?: string;
  readonly identifier: string;
  readonly workTypeID?: string;
  readonly workTypeId?: string;
}

export interface SwitchGroupSpecification {
  readonly departmentID?: string;
  readonly identifier: string;
  readonly workTypeURL?: string;
}

export interface InputCountBonusSpecification {
  readonly format?: CountBonusFormat;
  readonly label: string;
  readonly multiplier?: number;
  readonly priceItemUuid?: string;
  readonly priceItemUUID?: string;
}

export interface CountBonusSpecification {
  readonly format: CountBonusFormat;
  readonly label: string;
  readonly multiplier: number;
  readonly priceItemUUID?: string;
}

export interface RemunerationGroup {
  accomodationAllowance: boolean;
  accumulateCompensatoryLimit: number | null;
  calendarDayBonus: readonly BonusSpecification[];
  compensatoryMultiplier: number | null;
  compensatorySubtractOnly: readonly string[] | null;
  countBonus: readonly CountBonusSpecification[];
  countWeekdayHolidays: boolean;
  dayEndRounding: readonly [string, string] | null;
  dayStartRounding: readonly [string, string] | null;
  extraHalfHolidays: ReadonlyMap<string, string>;
  extraHolidays: ReadonlyMap<string, string>;
  forcedUnpaidBreakMinutes: number | null;
  halfHolidayHalfThresholds: boolean;
  halfHolidayHoursRates: DayHoursRatesSpecification | null;
  halfHolidaySundayAfterNoon: boolean;
  holidayCalendars: readonly HolidayCalendarLabel[];
  hoursRates: WeekDayHoursRatesSpecification;
  ignoreTimerIDs: readonly string[];
  intervalBonus: readonly BonusSpecification[];
  overtimeThresholds: WeekThresholdsSpecification;
  paidBreaks: boolean;
  pools: PoolSpecification | null;
  projectDistanceBonusLabel: string | null;
  projectTravelTimeBonusLabel: string | null;
  rateOverride: readonly RateOverrideSpecification[];
  rateSwitch: readonly RateOverrideSpecification[];
  reportIgnoreAbsenceOnHolidays: boolean;
  specialStartRateMinutes: number | null;
  // Injected into extraHolidays and extraHalfHolidays ...
  // extraHolidayAbsenceTypes: ReadonlyArray<string>;
  // extraHalfHolidayAbsenceTypes: ReadonlyArray<string>;
  switchGroups: readonly SwitchGroupSpecification[];
  taskBonus: readonly BonusSpecification[];
  workDayBonus: readonly BonusSpecification[];
}

export interface CommonRemunerationSettings {
  paidDayAbsenceTypes: readonly string[];
  paidTransportWorkType: string | undefined;
  periodSplitThresholdMinutes: number;
  unregisteredBreakAfterMinutes: number;
  validAbsenceTypes: readonly string[];
  workDaySplitThresholdMinutes: number | null;
}

export interface WorkDayData {
  // Not present on old data
  readonly accomodationDay?: boolean;
  readonly accomodationMinutes: number;
  readonly actualWorkMinutes: number;
  readonly bonusMinutes: {
    readonly [key: string]: number;
  };
  readonly breakMinutes: number;
  readonly breakPeriods: readonly {
    readonly fromTimestamp: string;
    readonly toTimestamp: string;
  }[];
  readonly calendarDayBonus: {
    readonly [key: string]: number;
  };
  readonly calledIn: number;
  readonly countBonus: {
    readonly [key: string]: number;
  };
  readonly date: string;
  readonly daysAbsence: {
    readonly [key: string]: readonly {
      readonly employeeReason: string;
      readonly note: string;
    }[];
  };
  readonly dinnerBookings: number;
  readonly holiday: string | null;
  readonly lunchBookings: number;
  readonly minutesAbsence: {
    readonly [key: string]: readonly {
      readonly employeeReason: string;
      readonly fromTimestamp: string;
      readonly note: string;
      readonly toTimestamp: string;
    }[];
  };
  readonly orderReferenceNumbers: readonly string[];
  readonly projectDistance: number;
  readonly projects: readonly {
    readonly alias: string;
    readonly name: string;
    readonly projectNumber: string;
  }[];
  readonly projectTravelTime: number;
  readonly rateMinutes: {
    readonly [key: string]: number;
  };
  readonly taskBonus: {
    readonly [key: string]: number;
  };
  readonly taskReferenceNumbers: readonly string[];
  readonly workDayBonus: readonly string[];
  readonly workPeriods: readonly {
    readonly fromTimestamp: string;
    readonly toTimestamp: string;
  }[];
}

export interface PoolData {
  readonly depositedCompensatoryMinutes: number;
  readonly fromDate: string;
  readonly potentialCompensatoryChange: number;
  readonly toDate: string;
  readonly withdrawnCompensatoryMinutes: number;
}

export interface UserData {
  readonly aboveAccumulatedCompensatoryLimit?: number | undefined;
  readonly accomodationAllowance: boolean;
  readonly accomodationAllowanceIncludeHours: boolean;
  readonly accomodationAllowanceLabel?: string | undefined;
  readonly accumulatedCompensatoryAfter?: number | undefined;
  readonly accumulatedCompensatoryBefore?: number | undefined;
  readonly accumulatedCompensatoryLimit?: number | undefined;
  readonly accumulatedCompensatoryLimitType?: "accumulatedAdditions" | "balance";
  readonly allowNegativeAccumulateCompensatory?: boolean | undefined;
  readonly compensatoryMultiplier?: number | undefined;
  readonly countWeekdayHolidays?: boolean | undefined;
  readonly dates: readonly WorkDayData[];
  readonly employeeDefaultRemunerationGroup: string;
  readonly employeeNumber: string;
  readonly forcedUnpaidBreakMinutes?: number | undefined;
  readonly hasPoolPeriodMismatchIssue: boolean;
  readonly includeCompensatoryOverLimitInPaid?: boolean | undefined;
  readonly increaseOnlyAccumulatedCompensatoryAfter?: number | undefined;
  readonly increaseOnlyAccumulatedCompensatoryBefore?: number | undefined;
  readonly initials: string;
  readonly name: string;
  readonly paidBreaks?: boolean | undefined;
  readonly periodCompensatoryLimit?: number | undefined;
  readonly periodContainsUncompletedTasks?: boolean;
  readonly pools?: readonly PoolData[] | undefined;
  readonly projectDistanceBonusLabel?: string | undefined;
  readonly projectTravelTimeBonusLabel?: string | undefined;
  readonly remunerationGroup: string;
  readonly reportTitle?: string | undefined;
  readonly userId: string;
  readonly visibleBonusLabels?: readonly string[] | undefined;
  readonly visibleLabels?: readonly string[] | undefined;
  readonly visibleRateLabels?: readonly string[] | undefined;
}

export interface SimplifiedData {
  billedBreaks: boolean;
  calendarDayBonusLabels: readonly string[];
  countBonusLabels: readonly string[];
  intervalBonusLabels: readonly string[];
  rateLabels: readonly string[] | null;
  reportContainsUncompletedTasksInPeriod: boolean;
  startRateLabel: string | null;
  taskBonusLabels: readonly string[];
  users: readonly UserData[];
  visibleBonusLabels: readonly string[] | null;
  visibleRateLabels: readonly string[] | null;
  workDayBonusLabels: readonly string[];
}
