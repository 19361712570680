import {
  KrPerLiterFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecification,
} from "@co-common-libs/resources";
import {TrimTextField} from "@co-frontend-libs/components";
import {Card, CardContent, FormControlLabel, Switch} from "@material-ui/core";
import {useEventTargetCheckedUpdater, useFieldUpdater} from "app-utils";
import React from "react";
import {useIntl} from "react-intl";

interface FuelSurchargeSpecificationEditProps {
  specification: KrPerLiterFuelSurchargeSpecification | PricePercentFuelSurchargeSpecification;
}

export const FuelSurchargeSpecificationEdit = React.memo(function FuelSurchargeSpecificationEdit(
  props: FuelSurchargeSpecificationEditProps,
): React.JSX.Element {
  const {specification} = props;

  const intl = useIntl();

  const {description, name} = specification;

  const setName = useFieldUpdater<
    KrPerLiterFuelSurchargeSpecification | PricePercentFuelSurchargeSpecification,
    string
  >(specification.url, "name");

  const setDescription = useFieldUpdater<
    KrPerLiterFuelSurchargeSpecification | PricePercentFuelSurchargeSpecification,
    string
  >(specification.url, "description");

  const setActive = useEventTargetCheckedUpdater<
    KrPerLiterFuelSurchargeSpecification | PricePercentFuelSurchargeSpecification
  >(specification.url, "active");

  return (
    <Card>
      <CardContent>
        <TrimTextField
          autoFocus
          fullWidth
          label={intl.formatMessage({defaultMessage: "Navn"})}
          margin="dense"
          onChange={setName}
          value={name}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          label={intl.formatMessage({
            defaultMessage: "Beskrivelse",
          })}
          margin="dense"
          multiline
          onChange={setDescription}
          value={description}
          variant="outlined"
        />
        <div>
          <FormControlLabel
            control={<Switch checked={specification.active} onChange={setActive} />}
            label={intl.formatMessage({defaultMessage: "Aktiv"})}
          />
        </div>
      </CardContent>
    </Card>
  );
});
