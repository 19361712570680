import {Task} from "@co-common-libs/resources";
import React from "react";
import {useEconomicTaskIssues} from "../hooks/use-economic-task-issues";
import {EconomicIssuesList} from "./economic-issues-list";
import {IssueContext} from "./types";

interface EconomicTaskIssuesListProps {
  context: IssueContext;
  task: Task;
}
export const EconomicTaskIssuesList = React.memo(function EconomicTaskIssuesList(
  props: EconomicTaskIssuesListProps,
): React.JSX.Element {
  const {context, task} = props;

  const economicIssues = useEconomicTaskIssues(task, context);

  return <EconomicIssuesList context={context} issues={economicIssues} taskUrl={task.url} />;
});
