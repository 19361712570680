import {instanceURL, ResourceInstance, ResourceName, urlToId} from "@co-common-libs/resources";
import {current, Draft} from "@reduxjs/toolkit";
import {performIdFetch} from "../actions";
import {ResourceInstanceRecords, ResourcesState, ResourceURLArrays} from "../types";
import {changeIsNewer} from "./change-is-newer";
import {getOfflineData} from "./selectors";
import {updateFromReceivedData} from "./update-from-received-data";

export function handlePerformIdFetchPending(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performIdFetch.pending>,
): void {
  const {requestId} = action.meta;
  const {resourceName} = action.meta.arg;
  console.assert(!state.fetchByIdActive[resourceName]);
  state.fetchByIdActive[resourceName] = requestId;
}

function computeBaseChanges(
  baseURL: string,
  currentOfflineResourcesData: ResourceInstanceRecords,
  resourceName: ResourceName,
  requestedIds: readonly string[],
  data: readonly ResourceInstance[],
): {
  deletedOnServer: Partial<ResourceURLArrays>;
  unchangedOnServer: Partial<ResourceURLArrays>;
  updatedOnServer: Partial<ResourceInstanceRecords>;
} {
  const updatedForResource: {[url: string]: ResourceInstance} = {};
  const potentiallyDeleted = new Set(requestedIds);
  const unchangedForResource: string[] = [];
  const currentData = currentOfflineResourcesData[resourceName];
  for (let i = 0; i < data.length; i += 1) {
    const instance = data[i];
    const {url} = instance;
    const id = instance.id || urlToId(url);
    potentiallyDeleted.delete(id);
    if (changeIsNewer(instance, currentData[url])) {
      updatedForResource[url] = instance;
    } else {
      unchangedForResource.push(url);
    }
  }

  const updatedOnServer = Object.keys(updatedForResource).length
    ? {[resourceName]: updatedForResource}
    : {};
  const deletedOnServer = potentiallyDeleted.size
    ? {
        [resourceName]: Array.from(potentiallyDeleted).map((id) =>
          instanceURL(baseURL, resourceName, id),
        ),
      }
    : {};
  const unchangedOnServer = unchangedForResource.length
    ? {[resourceName]: unchangedForResource}
    : {};

  return {deletedOnServer, unchangedOnServer, updatedOnServer};
}

export function handlePerformIdFetchFulfilled(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performIdFetch.fulfilled>,
): void {
  const {requestId} = action.meta;
  const {baseURL, ids, resourceName} = action.meta.arg;

  const {results} = action.payload;

  const updatedState = current(state) as ResourcesState;
  const currentOfflineData = getOfflineData(updatedState);
  const {deletedOnServer, unchangedOnServer, updatedOnServer} = computeBaseChanges(
    baseURL,
    currentOfflineData,
    resourceName,
    ids,
    results,
  );

  updateFromReceivedData(
    state,
    currentOfflineData,
    unchangedOnServer,
    updatedOnServer,
    deletedOnServer,
  );

  console.assert(state.fetchByIdActive[resourceName] === requestId);
  delete state.fetchByIdActive[resourceName];
}

export function handlePerformIdFetchRejected(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performIdFetch.rejected>,
): void {
  const {requestId} = action.meta;
  const {resourceName} = action.meta.arg;
  console.assert(state.fetchByIdActive[resourceName] === requestId);
  delete state.fetchByIdActive[resourceName];
}
