import {HOUR_MILLISECONDS, SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {red} from "@material-ui/core/colors";
import {bind} from "bind-decorator";
import React from "react";
import {HOUR_ROW_HEIGHT, NOW_RULER_Z_INDEX, UPDATE_NOW_RULER_INTERVAL_SECONDS} from "./constants";

interface NowRulerProps {
  width: number | string;
}

interface NowRulerState {
  top: number;
}

export class NowRuler extends React.Component<NowRulerProps, NowRulerState> {
  state: NowRulerState = {
    top: NowRuler.calculateTop(),
  };
  private intervalID: number | null = null;
  static calculateTop(): number {
    const now = new Date();
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    const differenceMilliseconds = now.valueOf() - startOfToday.valueOf();
    const differenceHours = differenceMilliseconds / HOUR_MILLISECONDS;
    return Math.round(HOUR_ROW_HEIGHT * differenceHours);
  }
  componentDidMount(): void {
    this.intervalID = window.setInterval(
      this.updateNowRuler,
      UPDATE_NOW_RULER_INTERVAL_SECONDS * SECOND_MILLISECONDS,
    );
  }
  componentWillUnmount(): void {
    if (this.intervalID) {
      window.clearInterval(this.intervalID);
      this.intervalID = null;
    }
  }
  render(): React.JSX.Element {
    const style: React.CSSProperties = {
      backgroundColor: red[500],
      height: 2,
      left: 0,
      opacity: 0.5,
      position: "absolute",
      top: this.state.top,
      width: this.props.width,
      zIndex: NOW_RULER_Z_INDEX,
    };
    return <div style={style} />;
  }
  @bind
  updateNowRuler(): void {
    this.setState({top: NowRuler.calculateTop()});
  }
}
