import {CustomerUrl, LocationUrl, RoutePlanUrl, urlToId} from "@co-common-libs/resources";
import {CircularProgress, Grid} from "@material-ui/core";
import {PeriodSelection} from "app-components";
import {useFetchGet} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useState} from "react";
import {Configuration, ConfigurationCard} from "./configuration";
import {DisplayResults, Results} from "./results";

interface FilterOptions {
  readonly invoiceCustomers: CustomerUrl[];
  readonly locations: LocationUrl[];
  readonly routePlans: RoutePlanUrl[];
}

export function RouteJournal(): React.JSX.Element {
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const [configuration, setConfiguration] = useState<Configuration | null>(null);

  const [
    fetchFilterOptions,
    fetchFilterOptionsCancel,
    filterOptions,
    fetchingFilterOptions,
    // filterOptionsError,
  ] = useFetchGet<FilterOptions>();

  const [
    fetchResults,
    fetchResultsCancel,
    results,
    fetchingResults,
    // resultsError
  ] = useFetchGet<Results>();

  useEffect(() => {
    fetchFilterOptionsCancel();
    setConfiguration(null);
  }, [fetchFilterOptionsCancel, fromDate, toDate]);

  const handlePeriodOk = useCallback(() => {
    const filterOptionsBaseURL = `${globalConfig.baseURL}/api/report/route_journal/filter_options/`;
    const filterOptionsURL = `${filterOptionsBaseURL}?fromDate=${fromDate}&toDate=${toDate}`;
    fetchResultsCancel();
    setConfiguration(null);
    fetchFilterOptions(filterOptionsURL);
  }, [fetchFilterOptions, fetchResultsCancel, fromDate, toDate]);

  const handleConfigurationChange = useCallback(() => {
    setConfiguration(null);
  }, []);

  const handleConfigurationOk = useCallback(
    (newConfiguration: Configuration) => {
      const resultsBaseURL = `${globalConfig.baseURL}/api/report/route_journal/results/`;
      const routePlanFilterString = newConfiguration.routePlanURLs
        .map((url) => `&routePlan=${urlToId(url)}`)
        .join("");
      const locationFilterString = newConfiguration.locationURLs
        .map((url) => `&location=${urlToId(url)}`)
        .join("");
      const invoiceCustomerFilterString = newConfiguration.invoiceCustomerURLs
        .map((url) => `&invoiceCustomer=${urlToId(url)}`)
        .join("");
      const resultURL = [
        `${resultsBaseURL}?fromDate=${fromDate}&toDate=${toDate}`,
        routePlanFilterString,
        locationFilterString,
        invoiceCustomerFilterString,
      ].join("");
      setConfiguration(newConfiguration);
      fetchResults(resultURL);
    },
    [fetchResults, fromDate, toDate],
  );

  return (
    <div style={{padding: "1em"}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PeriodSelection
            fromDate={fromDate}
            onFromDateChange={setFromDate}
            onOk={handlePeriodOk}
            onToDateChange={setToDate}
            toDate={toDate}
          />
        </Grid>
        <Grid item xs={12}>
          {filterOptions ? (
            <ConfigurationCard
              invoiceCustomerURLs={filterOptions.invoiceCustomers}
              locationURLs={filterOptions.locations}
              onConfigurationChange={handleConfigurationChange}
              onOk={handleConfigurationOk}
              routePlanURLs={filterOptions.routePlans}
            />
          ) : fetchingFilterOptions ? (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          {fromDate && toDate && configuration && results ? (
            <DisplayResults
              configuration={configuration}
              fromDate={fromDate}
              results={results}
              toDate={toDate}
            />
          ) : fetchingResults ? (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
