import {Config, ProductVariant, SettingID, SettingsGroupID} from "@co-common-libs/config";
import {getCustomerSettings, getSettingsEntryLookupByIdentifier} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader, IconButton, Typography, useTheme} from "@material-ui/core";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {SettingData} from "../types";
import {settingsOverrides} from "../ui-overrides";
import {ProductVariantDisplay} from "./product-variants-display";
import {SettingDataMissingView} from "./setting-data-missing-view";
import {SettingView} from "./setting-view";

interface SettingsGroupViewProps {
  customOfficeVariants: readonly ProductVariant[];
  groupDescription: string;
  groupDocumentationURL: string | undefined;
  groupID: SettingsGroupID;
  groupSettings: readonly SettingData[];
  groupTitle: string;
  nonBlankSettings: Partial<Config>;
  onOpenDialog: (settingsID: SettingID) => void;
  selectedGroup?: SettingsGroupID | undefined;
}

export function SettingsGroupView(props: SettingsGroupViewProps): React.JSX.Element | null {
  const {
    customOfficeVariants,
    groupDescription,
    groupDocumentationURL,
    groupID,
    groupSettings,
    groupTitle,
    nonBlankSettings,
    onOpenDialog,
    selectedGroup,
  } = props;
  const customerSettings = useSelector(getCustomerSettings);
  const {customOfficeProductVariant} = customerSettings;
  const theme = useTheme();

  const scrollRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (selectedGroup === groupID) {
      if (scrollRef.current) {
        // HACK; this actually scrolls the element to behind the appbar;
        // worked around by scrolling to an element 64 px away from what
        // we actually wanted to see...
        scrollRef.current.scrollIntoView();
      }
    }
  }, [selectedGroup, groupID]);

  const settingEntryLookup = useSelector(getSettingsEntryLookupByIdentifier);

  const groupDescriptionDiv = (
    <Typography component="div" variant="body2">
      {groupDescription}
    </Typography>
  );

  return (
    <Card style={{margin: "1em"}}>
      <a ref={scrollRef} style={{position: "relative", top: -64}} />
      <div style={{position: "relative"}}>
        <div
          style={{
            position: "absolute",
            right: 16,
            textAlign: "right",
            top: 16,
          }}
        >
          <ProductVariantDisplay variants={customOfficeVariants} />
          {groupDocumentationURL ? (
            <IconButton
              color="primary"
              href={groupDocumentationURL}
              style={{
                position: "relative",
                top: -2,
              }}
              target="blank"
            >
              <InformationOutlineIcon />
            </IconButton>
          ) : null}
        </div>
      </div>
      <CardHeader
        subheader={
          customOfficeVariants.length &&
          !customOfficeVariants.includes(customOfficeProductVariant) ? (
            <>
              {groupDescriptionDiv}
              <span style={{color: theme.palette.warning.main, paddingRight: 10}}>
                Denne feature er ikke indkluderet i den købte produktvariant
              </span>
            </>
          ) : (
            groupDescriptionDiv
          )
        }
        title={groupTitle}
      />
      <CardContent>
        {groupSettings.map(({settingID, settingMetaData}) => {
          const settingEntry = settingEntryLookup(settingID);
          let settingContent: React.JSX.Element;
          if (settingEntry) {
            const OverrideComponent = settingsOverrides[settingID];
            if (OverrideComponent) {
              settingContent = (
                <OverrideComponent settingID={settingID} settingMetaData={settingMetaData} />
              );
            } else {
              settingContent = (
                <SettingView
                  nonBlankSettings={nonBlankSettings}
                  onOpenDialog={onOpenDialog}
                  settingEntry={settingEntry}
                  settingID={settingID}
                  settingMetaData={settingMetaData}
                />
              );
            }
          } else {
            settingContent = (
              <SettingDataMissingView settingId={settingID} settingMetaData={settingMetaData} />
            );
          }
          return (
            <div key={settingID} style={{marginBottom: 10}}>
              {settingContent}
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
}
