import {getOfflineDB, SerializableError} from "@co-frontend-libs/db-resources";
import {clearAuthToken, jsonFetch, setAuthToken} from "@co-frontend-libs/utils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {BroadcastChannel} from "broadcast-channel";
import {buildErrorObject} from "../types";
import {clearSession, loadSession as loadSessionFromDB, putSession} from "./local-db";
import {SessionData} from "./types";

export const login = createAsyncThunk<
  SessionData,
  {loginURL: string; password: string; username: string},
  {
    rejectValue: SerializableError;
  }
>("authentication/login", async ({loginURL, password, username}, thunkAPI) => {
  try {
    const response = await jsonFetch(loginURL, "post", {password, username});
    const sessionData = response.data as SessionData;
    await putSession(sessionData);
    setAuthToken(sessionData.token);
    return sessionData;
  } catch (error) {
    return thunkAPI.rejectWithValue(buildErrorObject(error));
  }
});

const channel = window.cordova ? null : new BroadcastChannel("log-out");

const LOG_OUT_START = "LOG_OUT_START";
const LOG_OUT_DONE = "LOG_OUT_DONE";

if (channel) {
  channel.onmessage = async (msg) => {
    if (msg === LOG_OUT_START) {
      // eslint-disable-next-line no-console
      console.log("Log out from other tab...");
      await getOfflineDB().then((offlineDB) => offlineDB._closeConnection());
    } else if (msg === LOG_OUT_DONE) {
      // eslint-disable-next-line no-console
      console.log("Log out from other tab...done");
      window.location.reload();
    }
  };
}

export const logout = createAsyncThunk<void, void, {rejectValue: SerializableError}>(
  "authentication/logout",
  async () => {
    if (channel) {
      channel.postMessage(LOG_OUT_START);
    }
    await clearSession();
    const offlineDB = await getOfflineDB();
    // eslint-disable-next-line no-console
    console.log("closing offline database");
    await offlineDB._closeConnection();
    // eslint-disable-next-line no-console
    console.log("closing offline database: done");
    // eslint-disable-next-line no-console
    console.log("deleting offline database");
    await offlineDB._deleteDatabase();
    // eslint-disable-next-line no-console
    console.log("deleting offline database: done");
    clearAuthToken();
    if (channel) {
      channel.postMessage(LOG_OUT_DONE);
    }
    window.location.href = window.location.href.split("#")[0];
    window.location.reload();
  },
);

export const loadSession = createAsyncThunk<SessionData, void, {rejectValue: SerializableError}>(
  "authentication/loadSession",
  (_ignored, thunkAPI) =>
    loadSessionFromDB()
      .then((sessionData) => {
        setAuthToken(sessionData.token);
        return sessionData;
      })
      .catch((reason) => thunkAPI.rejectWithValue(buildErrorObject(reason))),
);

export const refreshToken = createAsyncThunk<
  SessionData,
  {refreshURL: string},
  {
    rejectValue: SerializableError;
  }
>("authentication/refreshToken", async ({refreshURL}, thunkAPI) => {
  try {
    const response = await jsonFetch(refreshURL, "post", {});
    const sessionData = response.data as SessionData;
    await putSession(sessionData);
    setAuthToken(sessionData.token);
    return sessionData;
  } catch (error) {
    return thunkAPI.rejectWithValue(buildErrorObject(error));
  }
});
