import {PriceItem, PriceItemUseWithOrder, Unit} from "@co-common-libs/resources";
import {formatUnitString} from "@co-common-libs/resources-utils";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {getPriceItemNameAndIdentifier} from "app-utils";
import _ from "lodash";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";

export interface EntryBaseProps {
  conversionRelatedValues: {
    readonly [unit: string]: number | null;
  } | null;
  conversionUnit: Unit | null;
  identifier: string;
  notesReadonly: boolean;
  onCountChange: (identifier: string, value: number | null) => void;
  onNotesButtonClick: ((identifier: string, disabled: boolean) => void) | null;
  onNotesChange: ((identifier: string, value: string) => void) | undefined;
  priceItem: PriceItem;
  priceItemUse: PriceItemUseWithOrder;
  readonly: boolean;
  showNotes: boolean;
  showRelationConversionColumns: boolean;
  unit: Unit | null;
}

export interface EntryProps {
  booleanSelection: boolean;
  countValue: number | null;
  dangling: boolean;
  inputUnitSymbol: string;
  notes: string;
  notesReadonly: boolean;
  onCountChange: (value: number | null) => void;
  onNotesButtonClick: (() => void) | null;
  onNotesChange?: ((value: string) => void) | undefined;
  onPlusClick: () => void;
  onRelationCountChange: (value: number | null) => void;
  priceItemActive: boolean;
  priceItemNameAndIdentifier: string;
  readonly: boolean;
  relationConversionBaseUnit: string | undefined;
  relationConversionUnitSymbol: string;
  relationConversionValue: number | null | undefined;
  relationCountValue: number | null;
  showNotes: boolean;
  showRelationConversionColumns: boolean;
}

export function withEntryCallbacks(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WrappedComponent: React.ComponentType<EntryProps>,
): React.ComponentType<EntryBaseProps> {
  function WithEntryCallbacks(props: EntryBaseProps): React.JSX.Element {
    const {
      conversionRelatedValues,
      conversionUnit,
      identifier,
      notesReadonly,
      onCountChange,
      onNotesButtonClick,
      onNotesChange,
      priceItem,
      priceItemUse,
      readonly,
      showNotes,
      showRelationConversionColumns,
      unit,
    } = props;

    const customerSettings = useSelector(getCustomerSettings);

    const {count, dangling, notes} = priceItemUse;
    const {active: priceItemActive, booleanSelection, conversionFactor} = priceItem;

    const inputUnit = conversionUnit && conversionFactor ? conversionUnit : unit;
    const inputUnitName = inputUnit?.name || "";
    const inputUnitSymbol = formatUnitString(inputUnit || undefined);

    const countValue =
      count == null
        ? count
        : _.round(
            conversionUnit && conversionFactor && count ? count / conversionFactor : count,
            customerSettings.materialDecimals,
          );

    const handleCountChange = useCallback(
      (value: number | null): void => {
        if (conversionUnit && conversionFactor && value) {
          const convertedValue = value * conversionFactor;
          const maxStoredDecimals = 5;
          const roundedValue = _.round(convertedValue, maxStoredDecimals);
          onCountChange(identifier, roundedValue);
        } else {
          onCountChange(identifier, value);
        }
      },
      [conversionFactor, conversionUnit, identifier, onCountChange],
    );

    const handlePlusClick = useCallback((): void => {
      handleCountChange(countValue == null ? 0 : countValue + 1);
    }, [handleCountChange, countValue]);

    let relationConversionBaseUnit: string | undefined;
    let relationConversionValue: number | null | undefined;

    if (showRelationConversionColumns && conversionRelatedValues) {
      for (const [baseUnit, targetUnits] of Object.entries(
        customerSettings.priceItemRelationUnitConversionHelpers,
      )) {
        if (
          conversionRelatedValues[baseUnit] !== undefined &&
          targetUnits.includes(inputUnitName)
        ) {
          relationConversionBaseUnit = baseUnit;
          relationConversionValue = conversionRelatedValues[baseUnit];
          break;
        }
      }
    }

    const relationCountValue =
      relationConversionValue && countValue != null
        ? _.round(countValue / relationConversionValue, customerSettings.materialDecimals)
        : null;

    const relationConversionUnitSymbol =
      relationConversionBaseUnit !== undefined
        ? `${inputUnitSymbol}/${relationConversionBaseUnit}`
        : "";

    const handleRelationCountChange = useCallback(
      (value: number | null): void => {
        if (value === null || relationConversionValue == null) {
          return;
        }
        handleCountChange(value * relationConversionValue);
      },
      [handleCountChange, relationConversionValue],
    );

    const handleNotesChange = useCallback(
      (value: string): void => {
        if (onNotesChange) {
          onNotesChange(identifier, value);
        }
      },
      [identifier, onNotesChange],
    );

    const handleNotesButtonClick = useCallback((): void => {
      if (onNotesButtonClick) {
        onNotesButtonClick(identifier, readonly);
      }
    }, [identifier, onNotesButtonClick, readonly]);

    return (
      <WrappedComponent
        booleanSelection={booleanSelection || false}
        countValue={countValue}
        dangling={dangling}
        inputUnitSymbol={inputUnitSymbol}
        notes={notes}
        notesReadonly={notesReadonly}
        onCountChange={handleCountChange}
        onNotesButtonClick={onNotesButtonClick ? handleNotesButtonClick : null}
        onNotesChange={onNotesChange ? handleNotesChange : undefined}
        onPlusClick={handlePlusClick}
        onRelationCountChange={handleRelationCountChange}
        priceItemActive={priceItemActive}
        priceItemNameAndIdentifier={getPriceItemNameAndIdentifier(priceItem, customerSettings)}
        readonly={readonly}
        relationConversionBaseUnit={relationConversionBaseUnit}
        relationConversionUnitSymbol={relationConversionUnitSymbol}
        relationConversionValue={relationConversionValue}
        relationCountValue={relationCountValue}
        showNotes={showNotes}
        showRelationConversionColumns={showRelationConversionColumns}
      />
    );
  }
  if (process.env.NODE_ENV !== "production") {
    const wrappedDisplayName = WrappedComponent.displayName || WrappedComponent.name || "Component";
    WithEntryCallbacks.displayName = `WithEntryCallbacks(${wrappedDisplayName})`;
  }
  return WithEntryCallbacks;
}
