import {Config} from "@co-common-libs/config";
import {
  Location,
  LocationUrl,
  ReportingInputSpecification,
  ReportingLocation,
  ReportingLocations,
  ReportingLogEntry,
} from "@co-common-libs/resources";
import {getValue} from "@co-common-libs/resources-utils";
import {formatTime, sortByOrderMember} from "@co-common-libs/utils";
import {getCustomerLookup} from "@co-frontend-libs/redux";
import {IconButton, TableCell, TableRow, useTheme} from "@material-ui/core";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ReportingValue} from "../../reporting-value";

interface TableEntryProps {
  customerSettings: Config;
  data: ReportingLogEntry;
  identifier: string;
  includePriceItems: boolean;
  includeProducts: boolean;
  inputSpecificationsMap: ReadonlyMap<string, ReportingInputSpecification>;
  locationLookup: (url: LocationUrl) => Location | undefined;
  logColumnPositions: ReadonlyMap<string, number>;
  materialColumnPositions: ReadonlyMap<string, number>;
  onRequestEditEntry: (identifier: string) => void;
  readonly: boolean;
  reportingLocations: ReportingLocations;
  showCustomer: boolean;
}

export const TableEntry = React.memo(function TableEntry(
  props: TableEntryProps,
): React.JSX.Element {
  const intl = useIntl();
  const theme = useTheme();

  const {
    customerSettings,
    data,
    identifier,
    includePriceItems,
    includeProducts,
    inputSpecificationsMap,
    locationLookup,
    logColumnPositions,
    materialColumnPositions,
    onRequestEditEntry,
    readonly,
    reportingLocations,
    showCustomer,
  } = props;

  const customerLookup = useSelector(getCustomerLookup);

  const handleEditClick = useCallback(() => {
    if (!readonly) {
      onRequestEditEntry(identifier);
    }
  }, [identifier, onRequestEditEntry, readonly]);

  const {deviceTimestamp} = data;
  const locationID = data.location;
  const {type} = data;
  const {values} = data;
  const locationData = reportingLocations[locationID] as ReportingLocation | undefined;
  console.assert(locationData, `log entry missing log location; location id: ${locationID}`);
  const dataColumns = [];
  const materialColumns: React.JSX.Element[] = [];
  let typeMarker = "";
  if (type === "pickup") {
    typeMarker = "A";
  } else if (type === "delivery") {
    typeMarker = "L";
  }
  let locationDataIndex = 0;
  if (locationData) {
    Object.values(reportingLocations).forEach((otherLocationData) => {
      if (
        otherLocationData.type === locationData.type &&
        otherLocationData.order < locationData.order
      ) {
        locationDataIndex += 1;
      }
    });
  }
  const locationURL = locationData?.location;
  const location = locationURL ? locationLookup(locationURL) : null;

  const pos = locationDataIndex + 1;

  const locationLabel = locationData
    ? `${typeMarker}${pos}, ${location?.name || location?.address || ""}`
    : `${typeMarker}?, ?`;

  const customer = showCustomer && location?.customer ? customerLookup(location.customer) : null;

  logColumnPositions.forEach((position, inputSpecificationIdentifier) => {
    const locationValues = locationData?.values;
    const valueMaps = locationValues ? [values, locationValues] : [values];
    const value = getValue(
      customerSettings,
      valueMaps,
      inputSpecificationsMap,
      inputSpecificationIdentifier,
    );
    const inputSpecification = inputSpecificationsMap.get(inputSpecificationIdentifier);
    const formatSpecification = inputSpecification && inputSpecification.format;
    if (formatSpecification && value != null) {
      dataColumns[position] = (
        <TableCell key={`${position}`}>
          <ReportingValue
            formatSpecification={formatSpecification}
            value={value as boolean | number | string | string[]}
          />
        </TableCell>
      );
    }
  });
  for (let i = 0; i < logColumnPositions.size; i += 1) {
    if (!dataColumns[i]) {
      dataColumns[i] = <TableCell key={`${i}`} />;
    }
  }
  if (includePriceItems) {
    const {priceItemUses} = data;
    if (priceItemUses) {
      sortByOrderMember(Object.values(priceItemUses)).forEach((entry: any) => {
        const priceItemURL = entry.priceItem;
        const {count} = entry;
        if (count != null) {
          const position = materialColumnPositions.get(priceItemURL);
          if (position != null) {
            materialColumns[position] = (
              <TableCell key={`${position}`}>{intl.formatNumber(count)}</TableCell>
            );
          }
        }
      });
    }
  }

  if (includeProducts) {
    const {productUses} = data;
    if (productUses) {
      sortByOrderMember(Object.values(productUses)).forEach((entry: any) => {
        const productURL = entry.product;
        const {count} = entry;
        if (count != null) {
          const position = materialColumnPositions.get(productURL);
          if (position != null) {
            materialColumns[position] = (
              <TableCell key={`${position}`}>{intl.formatNumber(count)}</TableCell>
            );
          }
        }
      });
    }
  }
  for (let i = 0; i < materialColumnPositions.size; i += 1) {
    if (!materialColumns[i]) {
      materialColumns[i] = <TableCell key={`${i}`} />;
    }
  }

  return (
    <TableRow key={deviceTimestamp} onClick={handleEditClick}>
      <TableCell
        style={{
          textAlign: "left",
        }}
      >
        {formatTime(deviceTimestamp)}
      </TableCell>
      {dataColumns}
      {materialColumns}
      <TableCell style={locationData ? {} : {color: theme.palette.error.main}}>
        {locationLabel}
      </TableCell>
      {showCustomer ? <TableCell>{customer?.name || ""}</TableCell> : null}
      <TableCell style={{padding: 0, width: 48}}>
        <IconButton color="primary" disabled={readonly} onClick={handleEditClick}>
          <PencilIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});
