import {ResourceInstance, resourceNames} from "@co-common-libs/resources";
import {Query} from "@co-frontend-libs/db-resources";
import {current, Draft} from "@reduxjs/toolkit";
import {computeNextFetchChangesTimestamp, fullFetchesPending} from "../../middleware/utils";
import {persistedQueriesRequested} from "../actions";
import {emptyQueryState, GenericInstanceRecord, ResourcesState} from "../types";
import {
  buildCheckFunction,
  buildResourceCheckMapping,
  entriesForEach,
  partialEntriesForEach,
  partialValuesSome,
} from "../utils";
import {computeInstancesToFetchFromAddedQueries} from "./compute-instances-to-fetch-from-added-queries";
import {purgeOldPersistedQueries} from "./purge-old-persisted-queries";
import {getAllResourcesData} from "./selectors";
import {
  combineResourceFetchByID,
  combineResourceFetchByRelation,
} from "./update-from-received-data";

export function handlePersistedQueriesRequested(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof persistedQueriesRequested>,
): void {
  const {persistedQueries} = state;
  const {forceReload, queries: newQueries} = action.payload;
  const survivingQueriesKeyStrings = new Set(newQueries.map((query) => query.keyString));
  partialEntriesForEach(persistedQueries, (key, entry) => {
    if (!survivingQueriesKeyStrings.has(key)) {
      entry.queryState.pendingRemoval = true;
    }
  });
  const addedQueries: Query[] = [];
  newQueries.forEach((query) => {
    const {keyString} = query;
    const currentEntry = persistedQueries[keyString];
    if (currentEntry) {
      currentEntry.query = query as Draft<Query>;
      currentEntry.queryState.pendingRemoval = false;
      if (forceReload) {
        currentEntry.queryState.fullFetchDataComputedAtTimestamp = null;
        currentEntry.queryState.takenIntoAccountForChangesComputedAtTimestamp = null;
      }
    } else {
      addedQueries.push(query);
      persistedQueries[keyString] = {
        query: query as Draft<Query>,
        queryState: emptyQueryState,
      };
    }
  });

  const updatedState = current(state) as ResourcesState;

  const allData = getAllResourcesData(updatedState);

  if (addedQueries.length) {
    const {toFetchByID, toFetchByRelation} = computeInstancesToFetchFromAddedQueries(
      allData,
      addedQueries,
      forceReload,
    );
    resourceNames.forEach((resourceName) => {
      const resourcePersistedFetchByID = toFetchByID[resourceName];
      if (resourcePersistedFetchByID?.size) {
        state.persistedFetchByID[resourceName] = combineResourceFetchByID(
          state.persistedFetchByID[resourceName],
          resourcePersistedFetchByID,
        );
        const fetchByIdForResource = state.temporaryFetchByID[resourceName];
        if (fetchByIdForResource) {
          const uuidLength = 36;
          fetchByIdForResource.forEach((id) => {
            console.assert(id.length === uuidLength, `not an UUID: ${id}`);
          });
        }
      }
      const resourcePersistedFetchByRelation = toFetchByRelation[resourceName];
      if (resourcePersistedFetchByRelation) {
        state.persistedFetchByRelation[resourceName] = combineResourceFetchByRelation(
          state.persistedFetchByRelation[resourceName],
          resourcePersistedFetchByRelation,
        );
      }
    });
  }

  const persistedResourceCheckMapping = buildResourceCheckMapping(newQueries);

  // potentially promote
  entriesForEach(updatedState.temporaryData, (resourceName, resourceInstanceRecord) => {
    if (!Object.keys(resourceInstanceRecord).length) {
      return;
    }
    const resourcePersistedCheck = persistedResourceCheckMapping[resourceName];
    const persistedEverything = resourcePersistedCheck.type === "alwaysOk";
    const persistedCheckFunction = buildCheckFunction(resourcePersistedCheck, allData);
    partialEntriesForEach(resourceInstanceRecord as GenericInstanceRecord, (url, instance) => {
      if (persistedEverything || persistedCheckFunction(instance)) {
        delete state.temporaryData[resourceName][url];
        state.persistedData[resourceName][url] = instance as ResourceInstance as Draft<any>;
      }
    });
  });

  const somePersistedQueryPendingRemoval = partialValuesSome(
    state.persistedQueries,
    ({queryState}) => queryState.pendingRemoval,
  );
  if (somePersistedQueryPendingRemoval) {
    purgeOldPersistedQueries(state);
  }
  if (!fullFetchesPending(state)) {
    state.lastFetchChangesTimestamp = computeNextFetchChangesTimestamp(state);
  }
}
