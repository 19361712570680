import {CustomerUrl, OrderUrl, ProjectUrl, TaskUrl} from "@co-common-libs/resources";
import {ConnectedProjectDialog} from "@co-frontend-libs/connected-components";
import {actions, getCustomerSettings, getProjectLookup} from "@co-frontend-libs/redux";
import {Button} from "@material-ui/core";
import {useFalseCallback, useTrueCallback} from "app-utils";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface ChangeProjectButtonProps {
  customerURL: CustomerUrl | null;
  disabled: boolean;
  orderURL: OrderUrl | null;
  taskURL: TaskUrl;
}

export const ChangeProjectButton = React.memo(function ChangeProjectButton(
  props: ChangeProjectButtonProps,
): React.JSX.Element {
  const {customerURL, disabled, orderURL, taskURL} = props;
  const {projectLabelVariant} = useSelector(getCustomerSettings);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const projectLookup = useSelector(getProjectLookup);
  const handleDialogOk = useCallback(
    (url: ProjectUrl) => {
      setDialogOpen(false);
      if (!customerURL && orderURL) {
        const project = projectLookup(url);
        if (project) {
          dispatch(actions.update(orderURL, [{member: "customer", value: project.customer}]));
        }
      }
      dispatch(actions.update(taskURL, [{member: "project", value: url}]));
    },
    [customerURL, dispatch, orderURL, projectLookup, taskURL],
  );
  return (
    <>
      <Button
        color="secondary"
        disabled={disabled}
        onClick={useTrueCallback(setDialogOpen, [setDialogOpen])}
        variant="contained"
      >
        {projectLabelVariant === "PROJECT" ? (
          <FormattedMessage defaultMessage="Skift projekt" />
        ) : (
          <FormattedMessage defaultMessage="Skift sag" />
        )}
      </Button>
      <ConnectedProjectDialog
        customerURL={customerURL}
        onCancel={useFalseCallback(setDialogOpen, [setDialogOpen])}
        onOk={handleDialogOk}
        open={dialogOpen}
        suggestRecentlyUsed
      />
    </>
  );
});
