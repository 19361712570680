import {Config} from "@co-common-libs/config";
import {
  Contact,
  ContactUrl,
  Customer,
  CustomerUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUsesDict,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUsesDict,
  Project,
  ProjectUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Task,
  Timer,
  TimerUrl,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {priceItemIsVisible} from "@co-common-libs/resources-utils";
import {notNull} from "@co-common-libs/utils";
import {ErrorEntry, getLogIssues, inlineTaskData} from "app-utils";
import _ from "lodash";
import {IntlShape} from "react-intl";

export type PriceItemUsesAction =
  | {count: number | null; identifier: string; type: "set-count"}
  | {identifier: string; notes: string; type: "set-notes"}
  | {type: "replace"; value: PriceItemUsesDict};

export function priceItemUsesReducer(
  state: PriceItemUsesDict,
  action: PriceItemUsesAction,
): PriceItemUsesDict {
  if (action.type === "replace") {
    return action.value;
  }
  const result = {...state};
  const {identifier} = action;
  switch (action.type) {
    case "set-count":
      result[identifier] = {...result[identifier], count: action.count};
      break;
    case "set-notes":
      result[identifier] = {...result[identifier], notes: action.notes};
      break;
  }
  return result;
}

export type ProductUsesAction =
  | {count: number | null; identifier: string; type: "set-count"}
  | {identifier: string; notes: string; type: "set-notes"}
  | {identifier: string; ours: boolean; type: "set-ours"}
  | {identifier: string; type: "delete"}
  | {type: "replace"; value: ProductUsesDict};

export function productUsesReducer(
  state: ProductUsesDict,
  action: ProductUsesAction,
): ProductUsesDict {
  if (action.type === "replace") {
    return action.value;
  }
  const result = {...state};
  const {identifier} = action;
  switch (action.type) {
    case "delete":
      delete result[identifier];
      break;
    case "set-count":
      result[identifier] = {...result[identifier], count: action.count};
      break;
    case "set-notes":
      result[identifier] = {...result[identifier], notes: action.notes};
      break;
    case "set-ours":
      result[identifier] = {...result[identifier], ours: action.ours};
      break;
  }
  return result;
}

export function wrappedGetLogIssues(
  priceItemUses: PriceItemUsesDict,
  productUses: ProductUsesDict,
  includePriceItems: boolean,
  data: {
    contactLookup: (url: ContactUrl) => Contact | undefined;
    customerLookup: (url: CustomerUrl) => Customer | undefined;
    customerSettings: Config;
    intl: IntlShape;
    machineArray: readonly Readonly<Machine>[];
    machineLookup: (url: MachineUrl) => Machine | undefined;
    orderLookup: (url: OrderUrl) => Order | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    priceItemArray: readonly Readonly<PriceItem>[];
    priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
    productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
    productLookup: (url: ProductUrl) => Product | undefined;
    productsWithLogData: Set<ProductUrl> | undefined;
    projectLookup: (url: ProjectUrl) => Project | undefined;
    readonlyProducts: Set<ProductUrl>;
    reportingSpecificationLookup: (
      url: ReportingSpecificationUrl,
    ) => ReportingSpecification | undefined;
    task: Task;
    timerLookup: (url: TimerUrl) => Timer | undefined;
    unitLookup: (url: UnitUrl) => Unit | undefined;
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
): ErrorEntry | null {
  const {
    contactLookup,
    customerLookup,
    customerSettings,
    intl,
    machineArray,
    machineLookup,
    orderLookup,
    priceGroupLookup,
    priceItemArray,
    priceItemLookup,
    productGroupLookup,
    productLookup,
    productsWithLogData,
    projectLookup,
    readonlyProducts,
    reportingSpecificationLookup,
    task,
    timerLookup,
    unitLookup,
    workTypeLookup,
  } = data;

  const sortedPriceItemUses = _.sortBy(
    Object.entries(priceItemUses),
    ([_identifier, priceItemUse]) => priceItemUse.order,
  );

  const priceItemUseList = sortedPriceItemUses.map(([_identifier, priceItemUse]) => priceItemUse);

  const relevantPriceItemUses = includePriceItems
    ? Object.fromEntries(
        sortedPriceItemUses.filter(([_identifier, priceItemUse]) => {
          const priceItem = priceItemLookup(priceItemUse.priceItem);
          if (!priceItem) {
            return false;
          }
          return priceItemIsVisible(
            priceItem,
            false,
            priceItemUseList,
            unitLookup,
            priceItemLookup,
          );
        }),
      )
    : {};

  const changedTask = {
    ...task,
    priceItemUses: relevantPriceItemUses,
    productUses,
  };

  const taskWithData = inlineTaskData(changedTask, {
    contactLookup,
    customerLookup,
    machineLookup,
    orderLookup,
    priceGroupLookup,
    priceItemLookup,
    productLookup,
    projectLookup,
    reportingSpecificationLookup,
    timerLookup,
    workTypeLookup,
  });

  const {workType} = taskWithData;
  const requireAtLeastOneOptionalPriceItemUseGreaterThanZero =
    workType?.requireAtLeastOneOptionalPriceItemUseGreaterThanZero;
  const requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup = new Map<
    PriceGroupUrl,
    readonly PriceItemUrl[]
  >();

  taskWithData.machineuseSet
    .map((m) => m.priceGroup)
    .concat([taskWithData.priceGroup])
    .filter(notNull)
    .filter((pg) => pg.requireAtLeastOneOptionalPriceItemUseGreaterThanZero)
    .forEach((priceGroup) => {
      const {url} = priceGroup;
      requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup.set(
        url,
        priceItemArray
          .filter(
            (priceItem) =>
              priceItem.required === false &&
              !priceItem.requiredGreaterThanZero &&
              !priceItem.genericEffectiveTimerTarget &&
              priceItem.priceGroups &&
              priceItem.priceGroups.includes(url),
          )
          .map((p) => p.url),
      );
    });

  const issues = getLogIssues(
    {
      machineArray,
      priceGroupLookup,
      priceItemLookup,
      productGroupLookup,
      productLookup,
      productsWithLogData,
      readonlyProducts,
      task: taskWithData,
      unitLookup,
    },
    customerSettings,
    intl,
    requireAtLeastOneOptionalPriceItemUseGreaterThanZero,
    requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup,
    true,
  );
  return issues;
}
