import {Typography} from "@material-ui/core";
import React from "react";
import {MapsButton} from "../maps-button";

export function AddressDisplay({
  address,
  label,
}: {
  address: string;
  label: string;
}): React.JSX.Element {
  return (
    <div>
      <MapsButton address={address} />
      <Typography component="span" variant="body2">
        {label}
      </Typography>
    </div>
  );
}
