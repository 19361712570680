import {Task} from "@co-common-libs/resources";
import {
  getRouteTaskActivityOptionLookup,
  getRouteTaskArray,
  getRouteTaskLookup,
  getRouteTaskResultArray,
} from "@co-frontend-libs/redux";
import _ from "lodash";
import React from "react";
import {useSelector} from "react-redux";
import {RouteInvoiceLineTableContent} from "./route-invoice-line-table-content";

interface RouteInvoiceLineTableContainerProps {
  readonly: boolean;
  task: Task;
}

const RouteInvoiceLineTableContainer = React.memo(function RouteInvoiceLineTableContainer(
  props: RouteInvoiceLineTableContainerProps,
): React.JSX.Element {
  const {readonly, task} = props;
  const taskURL = task.url;

  const routeTaskArray = useSelector(getRouteTaskArray);
  const routeTaskResultArray = useSelector(getRouteTaskResultArray);
  const routeTaskActivityOptionLookup = useSelector(getRouteTaskActivityOptionLookup);
  const routeTaskLookup = useSelector(getRouteTaskLookup);

  const routeTaskList = routeTaskArray.filter((routeTask) => routeTask.route === taskURL);
  const completedRouteTaskList = _.sortBy(
    routeTaskList.filter((routeTask) => routeTask.completed),
    (routeTask) => routeTask.completed,
  );
  const completedRouteTaskURLSet = new Set(
    completedRouteTaskList.map((routeTask) => routeTask.url),
  );
  const routeTaskResultSet = new Set(
    routeTaskResultArray.filter(
      (routeTaskResult) =>
        completedRouteTaskURLSet.has(routeTaskResult.routeTask) &&
        routeTaskActivityOptionLookup(routeTaskResult.activityOption)?.activity ===
          routeTaskLookup(routeTaskResult.routeTask)?.activity,
    ),
  );
  return (
    <RouteInvoiceLineTableContent
      completedRouteTaskList={completedRouteTaskList}
      readonly={readonly}
      routeTaskList={routeTaskList}
      routeTaskResultSet={routeTaskResultSet}
      task={task}
    />
  );
});

export {RouteInvoiceLineTableContainer as RouteInvoiceLineTable};
