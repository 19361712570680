import {SettingID} from "@co-common-libs/config";
import {CustomerUrl, ReportingSpecification} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {ConnectedCustomerDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getCustomerArray,
  getCustomerLookup,
  getReportingSpecificationArray,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {
  Button,
  Chip,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const CustomerChip = ({
  account,
  onDeleteClick,
}: {
  account: string | null;
  onDeleteClick: (c5acocunt: string) => void;
}): React.JSX.Element => {
  const customerArray = useSelector(getCustomerArray);
  const customer = customerArray.find((c) => c.c5_account === account);
  const handleDelete = useCallback(() => {
    if (!account) {
      return;
    }
    onDeleteClick(account);
  }, [account, onDeleteClick]);
  return (
    <Chip
      label={
        customer ? (
          `${customer.c5_account}: ${customer.name}`
        ) : (
          <FormattedMessage
            defaultMessage="{account}: Ukendt kunde"
            id="system-setup.dialog-title.unknown-customer"
            values={{account}}
          />
        )
      }
      onDelete={handleDelete}
    />
  );
};

const CustomersRow = ({
  customerIdentifiers,
  logIdentifier,
  onAddClick,
  onDeleteClick,
  reportingSpecification,
}: {
  customerIdentifiers: readonly string[];
  logIdentifier: string;
  onAddClick: (logIdentifier: string) => void;
  onDeleteClick: (c5acocunt: string, logIdentifier: string) => void;
  reportingSpecification: ReportingSpecification;
}): React.JSX.Element => {
  const handleDelete = useCallback(
    (c5account: string) => {
      onDeleteClick(c5account, logIdentifier);
    },
    [logIdentifier, onDeleteClick],
  );

  const handleAddClick = useCallback(() => {
    onAddClick(logIdentifier);
  }, [logIdentifier, onAddClick]);

  return (
    <TableRow key={logIdentifier}>
      <TableCell>{reportingSpecification.name}</TableCell>
      <TableCell>
        <div>
          <Button color="primary" onClick={handleAddClick} variant="contained">
            <FormattedMessage
              defaultMessage="Tilføj kunde"
              id="setting-dialog.label.add-customer"
            />
          </Button>
        </div>
        {customerIdentifiers.map((c5account) => (
          <CustomerChip account={c5account} key={c5account} onDeleteClick={handleDelete} />
        ))}
      </TableCell>
    </TableRow>
  );
};

interface SendValidatedLogToCustomersDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function SendValidatedLogToCustomersDialog(
  props: SendValidatedLogToCustomersDialogProps,
): React.JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const reportingSpecificationArray = useSelector(getReportingSpecificationArray);

  const sendValidatedGenericLogToCustomers: {
    [label: string]: readonly string[];
  } = useMemo(() => settingEntry?.data || {}, [settingEntry?.data]);

  const sendValidatedGenericLogToCustomersWithAllSpecs: {
    [key: string]: readonly string[];
  } = useMemo(
    () => ({
      ...reportingSpecificationArray
        .filter((spec) => spec.active)
        .reduce((dict: {[identifier: string]: string[]}, spec) => {
          dict[spec.identifier] = [];
          return dict;
        }, {}),
      ...sendValidatedGenericLogToCustomers,
    }),
    [reportingSpecificationArray, sendValidatedGenericLogToCustomers],
  );

  const [values, setValues] = useState(sendValidatedGenericLogToCustomersWithAllSpecs);

  useEffect(() => {
    if (open) {
      setValues(sendValidatedGenericLogToCustomersWithAllSpecs);
    }
  }, [open, sendValidatedGenericLogToCustomersWithAllSpecs]);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);

  const handleSave = useCallback(() => {
    const newValues = _.pickBy(values, function (value) {
      return value.length;
    });

    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValues},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, settingEntry, values]);

  const handleRemoveCustomerClick = useCallback(
    (removedC5Account: string, logIdentifier: string) => {
      const newValues = {
        ...values,
        [logIdentifier]: values[logIdentifier].filter((account) => account !== removedC5Account),
      };
      setValues(newValues);
    },
    [values],
  );

  const [customerDialogOpenedFor, setCustomerDialogOpenedFor] = useState<string | null>(null);

  const handleAddClick = useCallback((logIdentifier: string) => {
    setCustomerDialogOpenedFor(logIdentifier);
  }, []);
  const customerLookup = useSelector(getCustomerLookup);
  const handleCustomerDialogOk = useCallback(
    (customerURL: CustomerUrl) => {
      setCustomerDialogOpenedFor(null);
      const customer = customerLookup(customerURL);
      if (
        !customer ||
        !customer.c5_account ||
        !customerDialogOpenedFor ||
        values[customerDialogOpenedFor].includes(customer.c5_account)
      ) {
        return;
      }

      const newValues = {
        ...values,
        [customerDialogOpenedFor]: [...values[customerDialogOpenedFor], customer.c5_account],
      };
      setValues(newValues);
    },
    [customerDialogOpenedFor, customerLookup, values],
  );
  const handleCustomerDialogCancel = useCallback(() => {
    setCustomerDialogOpenedFor(null);
  }, []);

  return (
    <>
      <ResponsiveDialog
        fullWidth
        okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
        onCancel={onClose}
        onOk={handleSave}
        open={open && !customerDialogOpenedFor}
        title={
          <FormattedMessage
            defaultMessage="Vælg kunder"
            id="system-setup.dialog-title.select-color"
          />
        }
      >
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{width: 200}}>
                  <FormattedMessage
                    defaultMessage="Generisk log"
                    id="system-setup.dialog-title.generic-log"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Kunder"
                    id="system-setup.dialog-title.customers"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(values).map(([logIdentifier, customerIdentifiers]) => {
                const spec = reportingSpecificationArray.find(
                  (instance) => instance.active && instance.identifier === logIdentifier,
                );
                if (!spec) {
                  return null;
                }
                return (
                  <CustomersRow
                    customerIdentifiers={customerIdentifiers}
                    key={logIdentifier}
                    logIdentifier={logIdentifier}
                    onAddClick={handleAddClick}
                    onDeleteClick={handleRemoveCustomerClick}
                    reportingSpecification={spec}
                  />
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
      </ResponsiveDialog>
      <ConnectedCustomerDialog
        onCancel={handleCustomerDialogCancel}
        onOk={handleCustomerDialogOk}
        open={!!customerDialogOpenedFor}
      />
    </>
  );
}
