import {schema, SettingID} from "@co-common-libs/config";
import React from "react";
import {useIntl} from "react-intl";
import {DeviceListColumnsDialog} from "./device-list-columns-dialog";

interface BookkeepingListColumnsDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function BookkeepingListColumnsDialog(
  props: BookkeepingListColumnsDialogProps,
): React.JSX.Element {
  const intl = useIntl();
  const columnChoices = schema.definitions.bookkeepingListColumnsColumnChoices.items.enum;
  type ColumnChoice = (typeof columnChoices)[number];
  return (
    <DeviceListColumnsDialog<ColumnChoice>
      {...props}
      columnChoices={columnChoices}
      title={intl.formatMessage({
        defaultMessage: "Kolonner på dagseddelslisterne",
      })}
    />
  );
}
