import {MachineAnalysisAccountsTable, MenuToolbar, PageLayout} from "app-components";
import React from "react";
import {useIntl} from "react-intl";

interface MachineAnalysisSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const MachineAnalysisSettingsPage = React.memo(function MachineAnalysisSettingsPage(
  props: MachineAnalysisSettingsPageProps,
): React.JSX.Element {
  const intl = useIntl();

  return (
    <PageLayout
      toolbar={
        <MenuToolbar
          onMenuButton={props.onMenuButton}
          title={intl.formatMessage({defaultMessage: "Maskinanalyse"})}
        />
      }
      withBottomScrollPadding
    >
      <MachineAnalysisAccountsTable fabPositioning="fixed" />
    </PageLayout>
  );
});
