import {Config} from "@co-common-libs/config";
import {ComputedTime, Task, Timer, TransportLog, WorkType} from "@co-common-libs/resources";
import {TaskCheckDialog} from "app-components";
import {ErrorAction} from "app-utils";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";

interface ValidatedDialogProps {
  cancelled?: boolean;
  computedEndTime?: string | undefined;
  computedIntervals: readonly ComputedTime[];
  computedStartTime?: string | undefined;
  continuation: boolean;
  customerSettings: Config;
  finalEndTime?: string | undefined;
  finalIntervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  finalStartTime?: string | undefined;
  genericPrimaryTimer: Timer;
  onAction?: (action: ErrorAction) => void;
  onCancel: (event: unknown) => void;
  onOk?: (event: unknown, continuation: boolean) => void;
  open: boolean;
  primaryWorkType?: WorkType | undefined;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  transportLog?: TransportLog | undefined;
}

export function ValidatedDialog(props: ValidatedDialogProps): React.JSX.Element {
  const {cancelled, continuation, onCancel, onOk, open, task, ...other} = props;

  const intl = useIntl();

  const handleOk = useCallback(() => {
    if (onOk) {
      onOk(null, continuation);
    }
  }, [continuation, onOk]);

  const handleCancel = useCallback(() => {
    onCancel(null);
  }, [onCancel]);

  return (
    <TaskCheckDialog
      completedAsInternal={task.completedAsInternal}
      okHeaderText={
        cancelled
          ? intl.formatMessage({defaultMessage: "Godkend opgaven som aflyst med følgende data:"})
          : ""
      }
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      task={task}
      title={intl.formatMessage({defaultMessage: "Godkend?"})}
      {...other}
    />
  );
}
