import {PriceItemUsesDict} from "@co-common-libs/resources";
import {priceItemIsVisible} from "@co-common-libs/resources-utils";
import {notUndefined, valuesSortedByOrderMember} from "@co-common-libs/utils";
import {getCustomerSettings, getPriceItemLookup, getUnitLookup} from "@co-frontend-libs/redux";
import {buildConversionRelatedValues} from "app-utils";
import bowser from "bowser";
import _ from "lodash";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {DisplayGrid} from "./display-grid";
import {DisplayTable} from "./display-table";

interface PriceItemTableProps {
  extraConversionRelatedValues?:
    | {
        readonly [unit: string]: number | null;
      }
    | undefined;
  hiddenEntryIdentifiers?: ReadonlySet<string> | undefined;
  onCountChange: (identifier: string, value: number | null) => void;
  onNotesChange?: (identifier: string, value: string) => void;
  priceItemUses: PriceItemUsesDict;
  readonly?: boolean | undefined;
  readonlyPriceItems?: ReadonlySet<string> | undefined;
  showNotes?: boolean;
}

export const PriceItemTable = React.memo(function PriceItemTable(
  props: PriceItemTableProps,
): React.JSX.Element | null {
  const {
    extraConversionRelatedValues,
    hiddenEntryIdentifiers,
    onCountChange,
    onNotesChange,
    priceItemUses,
    readonly,
    readonlyPriceItems,
    showNotes,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);

  const sortedPriceItemUsesWithData = useMemo(
    () =>
      _.sortBy(
        Object.entries(priceItemUses)
          .map(([identifier, priceItemUse]) => {
            const priceItem = priceItemLookup(priceItemUse.priceItem);
            if (!priceItem) {
              return undefined;
            }
            const unit = priceItem.relatedUnit ? unitLookup(priceItem.relatedUnit) || null : null;
            const conversionUnit =
              priceItem.conversionUnit && priceItem.conversionFactor
                ? unitLookup(priceItem.conversionUnit) || null
                : null;
            return {conversionUnit, identifier, priceItem, priceItemUse, unit};
          })
          .filter(notUndefined),
        (entry) => entry.priceItemUse.order,
      ),
    [priceItemLookup, priceItemUses, unitLookup],
  );

  const filteredSortedPriceItemUses = useMemo(() => {
    const priceItemUseList = valuesSortedByOrderMember(priceItemUses);
    return sortedPriceItemUsesWithData.filter(
      ({identifier, priceItem}) =>
        !hiddenEntryIdentifiers?.has(identifier) &&
        priceItemIsVisible(priceItem, false, priceItemUseList, unitLookup, priceItemLookup),
    );
  }, [
    hiddenEntryIdentifiers,
    priceItemLookup,
    priceItemUses,
    sortedPriceItemUsesWithData,
    unitLookup,
  ]);

  const conversionRelatedKeys = useMemo(
    () => Object.keys(customerSettings.priceItemRelationUnitConversionHelpers),
    [customerSettings.priceItemRelationUnitConversionHelpers],
  );

  const conversionRelatedValues = useMemo(
    () =>
      buildConversionRelatedValues(
        conversionRelatedKeys,
        sortedPriceItemUsesWithData,
        extraConversionRelatedValues,
      ),
    [conversionRelatedKeys, extraConversionRelatedValues, sortedPriceItemUsesWithData],
  );

  const conversionRelatedInputUnits = useMemo(
    () =>
      conversionRelatedValues
        ? new Set(
            Object.entries(customerSettings.priceItemRelationUnitConversionHelpers)
              .filter(([baseUnit, _inputUnits]) => conversionRelatedValues[baseUnit] !== undefined)
              .flatMap(([_baseUnit, inputUnits]) => inputUnits),
          )
        : null,
    [conversionRelatedValues, customerSettings.priceItemRelationUnitConversionHelpers],
  );

  const showRelationConversionColumns = useMemo(
    () =>
      conversionRelatedInputUnits
        ? filteredSortedPriceItemUses.some(({conversionUnit, unit}) => {
            if (conversionUnit) {
              return conversionRelatedInputUnits.has(conversionUnit.name);
            } else if (unit) {
              return conversionRelatedInputUnits.has(unit.name);
            } else {
              return false;
            }
          })
        : false,
    [conversionRelatedInputUnits, filteredSortedPriceItemUses],
  );

  if (!filteredSortedPriceItemUses.length) {
    return null;
  }

  if (bowser.mobile || bowser.tablet) {
    return (
      <DisplayGrid
        conversionRelatedValues={conversionRelatedValues}
        onCountChange={onCountChange}
        onNotesChange={onNotesChange}
        readonly={readonly || false}
        readonlyPriceItems={readonlyPriceItems || null}
        showNotes={showNotes || false}
        showRelationConversionColumns={showRelationConversionColumns}
        sortedPriceItemUses={filteredSortedPriceItemUses}
      />
    );
  } else {
    return (
      <DisplayTable
        conversionRelatedValues={conversionRelatedValues}
        onCountChange={onCountChange}
        onNotesChange={onNotesChange}
        readonly={readonly || false}
        readonlyPriceItems={readonlyPriceItems || null}
        showNotes={showNotes || false}
        showRelationConversionColumns={showRelationConversionColumns}
        sortedPriceItemUses={filteredSortedPriceItemUses}
      />
    );
  }
});
