import {Config} from "@co-common-libs/config";
import {Machine, MachineUrl, urlToId} from "@co-common-libs/resources";
import {ConnectedMachineDialog} from "@co-frontend-libs/connected-components";
import {AppState, getCustomerSettings, getMachineLookup, getToken} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {CircularProgress} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {CalculateCard} from "./calculate-card";
import {DisplayCards} from "./display-cards";

interface MachineCostStateProps {
  customerSettings: Config;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  token: string | null;
}

type MachineCostProps = MachineCostStateProps;

interface MachineCostState {
  data: {
    [workTypeID: string]: {
      [initials: string]: [string, number, string, number][];
    };
  } | null;
  fetching: boolean;
  fromDate: string | null;
  machineDialogOpen: boolean;
  selectedMachine: MachineUrl | undefined;
  toDate: string | null;
}

class MachineCost extends PureComponent<MachineCostProps, MachineCostState> {
  state: MachineCostState = {
    data: null,
    fetching: false,
    fromDate: null,
    machineDialogOpen: false,
    selectedMachine: undefined,
    toDate: null,
  };

  componentWillUnmount(): void {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  private abortController: AbortController | undefined;
  fetch(): void {
    const {fromDate, selectedMachine, toDate} = this.state;
    if (!fromDate || !toDate || !selectedMachine) {
      return;
    }
    const url = new URL(globalConfig.baseURL);
    url.pathname = "api/report/machineSalaryCost/results/";
    url.searchParams.append("fromDate", fromDate);
    url.searchParams.append("toDate", toDate);
    url.searchParams.append("machineId", urlToId(selectedMachine));
    this.setState({data: null, fetching: true});
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
    if (window.AbortController) {
      this.abortController = new AbortController();
    }
    jsonFetch(url.toString(), "GET", null, this.abortController?.signal)
      .then((response) => {
        if (
          fromDate === this.state.fromDate &&
          toDate === this.state.toDate &&
          selectedMachine === this.state.selectedMachine
        ) {
          this.setState({
            data: response.data,
            fetching: false,
          });
        }
        return;
      })
      .catch((error) => {
        if (error.cause?.name === "AbortError") {
          return;
        }
        if (
          fromDate === this.state.fromDate &&
          toDate === this.state.toDate &&
          selectedMachine === this.state.selectedMachine
        ) {
          this.setState({
            fetching: false,
          });
        }
      });
  }

  @bind
  handleFromDateChanged(fromDate: string | null): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
    this.setState({
      data: null,
      fetching: false,
      fromDate,
    });
  }
  @bind
  handleGenerateClick(): void {
    this.fetch();
  }
  @bind
  handleToDateChanged(toDate: string | null): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
    this.setState({
      data: null,
      fetching: false,
      toDate,
    });
  }

  @bind
  handleMachineDialogCancel(): void {
    this.setState({machineDialogOpen: false});
  }

  @bind
  handleMachineDialogOk(machineURL: MachineUrl): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
    this.setState({
      data: null,
      fetching: false,
      machineDialogOpen: false,
      selectedMachine: machineURL,
    });
  }

  @bind
  handleRequestMachineDialogOpen(): void {
    this.setState({machineDialogOpen: true});
  }

  render(): JSX.Element {
    const {data, fetching, fromDate, selectedMachine, toDate} = this.state;
    const {customerSettings, machineLookup} = this.props;
    let content: JSX.Element | undefined;
    if (fetching) {
      content = (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "1em",
            width: 50,
          }}
        >
          <CircularProgress />
        </div>
      );
    } else if (fromDate && toDate && data) {
      content = (
        <DisplayCards
          data={data}
          fromDate={fromDate}
          machine={selectedMachine ? machineLookup(selectedMachine) : undefined}
          toDate={toDate}
        />
      );
    }
    return (
      <div style={{padding: "1em"}}>
        <CalculateCard
          customerSettings={customerSettings}
          data={data || undefined}
          fromDate={fromDate}
          onFromDateChanged={this.handleFromDateChanged}
          onGenerateClick={this.handleGenerateClick}
          onRequestMachineDialogOpen={this.handleRequestMachineDialogOpen}
          onToDateChanged={this.handleToDateChanged}
          selectedMachine={selectedMachine ? machineLookup(selectedMachine) : undefined}
          toDate={toDate}
          token={this.props.token}
        />
        {content}
        <ConnectedMachineDialog
          onCancel={this.handleMachineDialogCancel}
          onOk={this.handleMachineDialogOk}
          open={this.state.machineDialogOpen}
        />
      </div>
    );
  }
}

const ConnectedMachineCost = connect<MachineCostStateProps, object, object, AppState>(
  createStructuredSelector<AppState, MachineCostStateProps>({
    customerSettings: getCustomerSettings,
    machineLookup: getMachineLookup,
    token: getToken,
  }),
  {},
)(MachineCost);

export {ConnectedMachineCost as MachineCost};
