import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  LocationUseLog,
  Order,
  PatchOperation,
  PatchUnion,
  ReportingSpecification,
  ResourceTypeUnion,
  Role,
  Task,
} from "@co-common-libs/resources";
import {getInputSpecificationsMap} from "@co-common-libs/resources-utils";
import {
  actions,
  AppState,
  getContactArray,
  getCurrentRole,
  getCustomerLookup,
  getCustomerSettings,
  getLocationArray,
  getLocationLookup,
  getLocationUseLogArray,
} from "@co-frontend-libs/redux";
import {
  LocationDialog,
  PickupDeliveryLocationsBlock,
  WorkplaceLocationsBlock,
} from "app-components";
import {getFieldNotesPerLocation, PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import memoizeOne from "memoize-one";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  deliveryLocation: {
    defaultMessage: "Leveringssted",
    id: "log-places.label.delivery-location",
  },
  pickupLocation: {
    defaultMessage: "Afhentningssted",
    id: "log-places.label.pickup-location",
  },
  workplaceLocation: {
    defaultMessage: "Arbejdssted",
    id: "log-places.label.workplace-location",
  },
});

interface LogPlacesStateProps {
  contactArray: readonly Contact[];
  currentRole: Role | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  locationUseLogArray: readonly LocationUseLog[];
}

interface LogPlacesDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface LogPlacesOwnProps {
  logSpecification: ReportingSpecification;
  order: Order;
  readonly: boolean;
  task: Task;
  userIsOtherMachineOperator: boolean;
}

type LogPlacesProps = LogPlacesDispatchProps & LogPlacesOwnProps & LogPlacesStateProps;

interface LogPlacesState {
  addDeliveryLocationDialogOpen: boolean;
  addPickupLocationDialogOpen: boolean;
  addWorkplaceLocationDialogOpen: boolean;
  editLocationDialogIdentifier: string | null;
  editLocationDialogType: "delivery" | "pickup" | "workplace" | null;
  logEntryIdentifier: string | null;
  logEntryType: "delivery" | "pickup" | "workplace" | null;
  swappingLocations: boolean;
}

class LogPlaces extends PureComponent<LogPlacesProps, LogPlacesState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  getFieldNotesPerLocation = memoizeOne(getFieldNotesPerLocation);
  getInputSpecificationsMap = memoizeOne(getInputSpecificationsMap);
  state: LogPlacesState = {
    addDeliveryLocationDialogOpen: false,
    addPickupLocationDialogOpen: false,
    addWorkplaceLocationDialogOpen: false,
    editLocationDialogIdentifier: null,
    editLocationDialogType: null,
    logEntryIdentifier: null,
    logEntryType: null,
    swappingLocations: false,
  };

  @bind
  handleAddDeliveryLocation(): void {
    this.setState({addDeliveryLocationDialogOpen: true});
  }
  @bind
  handleAddDeliveryLocationCancel(): void {
    this.setState({
      addDeliveryLocationDialogOpen: false,
      editLocationDialogIdentifier: null,
      editLocationDialogType: null,
    });
  }
  @bind
  handleAddPickupLocation(): void {
    this.setState({addPickupLocationDialogOpen: true});
  }
  @bind
  handleAddPickupLocationCancel(): void {
    this.setState({
      addPickupLocationDialogOpen: false,
      editLocationDialogIdentifier: null,
      editLocationDialogType: null,
    });
  }
  @bind
  handleAddWorkplaceLocation(): void {
    this.setState({addWorkplaceLocationDialogOpen: true});
  }
  @bind
  handleAddWorkplaceLocationCancel(): void {
    this.setState({
      addWorkplaceLocationDialogOpen: false,
      editLocationDialogIdentifier: null,
      editLocationDialogType: null,
    });
  }
  @bind
  handleReorderLocations(fromIdentifier: string, toIdentifier: string): void {
    const {task, update} = this.props;
    const orderedReportingLocations = _.sortBy(
      Object.entries(task.reportingLocations || {}),
      ([_identifier, reportingLocation]) => reportingLocation.order,
    );
    const fromIndex = orderedReportingLocations.findIndex(
      ([identifier, _reportingLocation]) => identifier === fromIdentifier,
    );
    const toIndex = orderedReportingLocations.findIndex(
      ([identifier, _reportingLocation]) => identifier === toIdentifier,
    );
    if (fromIndex === -1 || toIndex === -1) {
      return;
    }
    const patch: PatchOperation<Task>[] = [];
    const entry = orderedReportingLocations[fromIndex];
    orderedReportingLocations.splice(fromIndex, 1);
    orderedReportingLocations.splice(toIndex, 0, entry);
    let nextOrderValueMin = 0;
    orderedReportingLocations.forEach(([identifier, reportingLocation]) => {
      if (reportingLocation.order < nextOrderValueMin) {
        patch.push({
          path: ["reportingLocations", identifier, "order"],
          value: nextOrderValueMin,
        });
        nextOrderValueMin += 1;
      } else {
        nextOrderValueMin = reportingLocation.order + 1;
      }
    });
    if (patch.length) {
      update(task.url, patch);
    }
  }
  @bind
  handleRequestEditEntryLocation(): void {
    const {logEntryIdentifier, logEntryType} = this.state;
    this.setState({
      editLocationDialogIdentifier: logEntryIdentifier,
      editLocationDialogType: logEntryType,
    });
  }
  @bind
  handleRequestEditEntryLocationButton(
    logEntryType: "delivery" | "pickup" | "workplace",
    logEntryIdentifier: string,
  ): void {
    this.setState({
      editLocationDialogIdentifier: logEntryIdentifier,
      editLocationDialogType: logEntryType,
    });
  }

  @bind
  handleSwapButtonClick(): void {
    this.setState({swappingLocations: !this.state.swappingLocations});
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings, locationLookup, logSpecification, order, readonly, task} = this.props;
    const {workplaceRegistration} = logSpecification;
    const fieldNotesPerLocation = this.getFieldNotesPerLocation(
      logSpecification,
      task,
      order,
      locationLookup,
    );
    let locationsBlock: React.JSX.Element | undefined;
    const addLocationDialogs: React.JSX.Element[] = [];

    const {editLocationDialogIdentifier, editLocationDialogType} = this.state;

    const observedLocationCounts = new Map<string, number>();

    if (task.reportingLog) {
      for (const data of Object.values(task.reportingLog)) {
        const locationID = data.location;
        observedLocationCounts.set(locationID, (observedLocationCounts.get(locationID) || 0) + 1);
      }
    }
    const observedLocations = new Set(observedLocationCounts.keys());

    const WORKPLACE_REGISTRATION_WORKPLACE = 1;
    const WORKPLACE_REGISTRATION_TRANSPORT = 2;

    if (workplaceRegistration === WORKPLACE_REGISTRATION_WORKPLACE) {
      const logEntryAllowedCount = logSpecification?.workplaceData?.workplace?.logEntries;
      const completedWorkplaceLocations =
        logEntryAllowedCount === 1 ? observedLocations : new Set<string>();
      if (task.reportingLocations || task.productUses) {
        locationsBlock = (
          <WorkplaceLocationsBlock
            completedWorkplaceLocations={completedWorkplaceLocations}
            fieldNotesPerLocation={fieldNotesPerLocation || undefined}
            locationCounts={observedLocationCounts}
            logSpecification={logSpecification}
            onEditLocationButtonClick={this.handleRequestEditEntryLocationButton}
            onReorderWorkplaceLocations={this.handleReorderLocations}
            onRequestAddWorkplaceLocation={this.handleAddWorkplaceLocation}
            onSwapButtonClick={this.handleSwapButtonClick}
            readonly={readonly}
            reportingLocations={task.reportingLocations || {}}
            reportingLog={task.reportingLog || {}}
            swappingLocations={this.state.swappingLocations}
            task={task}
          />
        );
      }

      if (!customerSettings.addEditLogLocationSkipsCustomerSelection) {
        let identifier: string | null = null;
        if (editLocationDialogType === "workplace") {
          identifier = editLocationDialogIdentifier;
        }

        const workplaceDeleteDisabled =
          !identifier ||
          (task.reportingLog ? Object.values(task.reportingLog) : []).some(
            (entry) => entry.location === identifier && entry.type === "workplace",
          );

        addLocationDialogs.push(
          <LocationDialog
            deleteDisabled={workplaceDeleteDisabled}
            editingIdentifier={identifier || null}
            key="workplace-location-dialog"
            logSpecification={logSpecification}
            onClose={this.handleAddWorkplaceLocationCancel}
            open={
              this.state.addWorkplaceLocationDialogOpen || editLocationDialogType === "workplace"
            }
            orderCustomerUrl={order?.customer || null}
            task={task}
            title={formatMessage(messages.workplaceLocation)}
            type="workplace"
          />,
        );
      }
    } else if (workplaceRegistration === WORKPLACE_REGISTRATION_TRANSPORT) {
      const pickupLogEntryAllowedCount = _.get(logSpecification, [
        "workplaceData",
        "pickup",
        "logEntries",
      ]);
      const deliveryLogEntryAllowedCount = _.get(logSpecification, [
        "workplaceData",
        "delivery",
        "logEntries",
      ]);
      let completedPickupLocations = new Set<string>();
      let completedDeliveryLocations = new Set<string>();
      if (pickupLogEntryAllowedCount === 1) {
        completedPickupLocations = observedLocations;
      }
      if (deliveryLogEntryAllowedCount === 1) {
        completedDeliveryLocations = observedLocations;
      }

      locationsBlock = (
        <PickupDeliveryLocationsBlock
          completedDeliveryLocations={completedDeliveryLocations}
          completedPickupLocations={completedPickupLocations}
          fieldNotesPerLocation={fieldNotesPerLocation || undefined}
          locationCounts={observedLocationCounts}
          logSpecification={logSpecification}
          onEditLocationButtonClick={this.handleRequestEditEntryLocationButton}
          onReorderDeliveryLocations={this.handleReorderLocations}
          onReorderPickupLocations={this.handleReorderLocations}
          onRequestAddDeliveryLocation={this.handleAddDeliveryLocation}
          onRequestAddPickupLocation={this.handleAddPickupLocation}
          onSwapButtonClick={this.handleSwapButtonClick}
          readonly={readonly}
          reportingLocations={task.reportingLocations || {}}
          reportingLog={task.reportingLog || {}}
          swappingLocations={this.state.swappingLocations}
          task={task}
        />
      );

      if (!customerSettings.addEditLogLocationSkipsCustomerSelection) {
        let pickupIdentifier: string | null | undefined;
        if (editLocationDialogType === "pickup") {
          pickupIdentifier = editLocationDialogIdentifier;
        }

        const deleteDisabled =
          !pickupIdentifier ||
          (task.reportingLog ? Object.values(task.reportingLog) : []).some(
            (entry) => entry.location === pickupIdentifier && entry.type === "pickup",
          );

        addLocationDialogs.push(
          <LocationDialog
            deleteDisabled={deleteDisabled}
            editingIdentifier={pickupIdentifier || null}
            key="pickup-location-dialog"
            logSpecification={logSpecification}
            onClose={this.handleAddPickupLocationCancel}
            open={this.state.addPickupLocationDialogOpen || editLocationDialogType === "pickup"}
            orderCustomerUrl={order?.customer || null}
            task={task}
            title={formatMessage(messages.pickupLocation)}
            type="pickup"
          />,
        );

        let deliveryIdentifier: string | null | undefined;
        if (editLocationDialogType === "delivery") {
          deliveryIdentifier = editLocationDialogIdentifier;
        }

        const deliveryDeleteDisabled =
          !deliveryIdentifier ||
          (task.reportingLog ? Object.values(task.reportingLog) : []).some(
            (entry) => entry.location === deliveryIdentifier && entry.type === "delivery",
          );

        addLocationDialogs.push(
          <LocationDialog
            deleteDisabled={deliveryDeleteDisabled}
            editingIdentifier={deliveryIdentifier || null}
            key="delivery-location-dialog"
            logSpecification={logSpecification}
            onClose={this.handleAddDeliveryLocationCancel}
            open={this.state.addDeliveryLocationDialogOpen || editLocationDialogType === "delivery"}
            orderCustomerUrl={order?.customer || null}
            task={task}
            title={formatMessage(messages.deliveryLocation)}
            type="delivery"
          />,
        );
      }
    }

    return (
      <div>
        <h2 style={{position: "relative"}}>{logSpecification.name}</h2>
        {locationsBlock}
        {addLocationDialogs}
      </div>
    );
  }
}

const ConnectedLogPlaces: React.ComponentType<LogPlacesOwnProps> = connect<
  LogPlacesStateProps,
  LogPlacesDispatchProps,
  LogPlacesOwnProps,
  AppState
>(
  createStructuredSelector<AppState, LogPlacesStateProps>({
    contactArray: getContactArray,
    currentRole: getCurrentRole,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationArray: getLocationArray,
    locationLookup: getLocationLookup,
    locationUseLogArray: getLocationUseLogArray,
  }),
  {
    create: actions.create,
    update: actions.update,
  },
)(LogPlaces);

export {ConnectedLogPlaces as LogPlaces};
