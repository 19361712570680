import {makeStyles, TextField, TextFieldProps, Theme} from "@material-ui/core";
import React, {useCallback, useEffect, useRef, useState} from "react";

const useStyles = makeStyles((theme: Theme) => ({
  readonly: {
    backgroundColor: theme.palette.grey[200],
  },
  warningHelperText: {
    color: theme.palette.warning.main,
  },
}));

export type TextFieldPropsWithoutOnChange = Omit<TextFieldProps, "onChange">;

export interface CustomTextFieldProps<T> extends TextFieldPropsWithoutOnChange {
  errorText?: string | undefined;
  hideError?: boolean;
  onChange?: ((value: T | null) => void) | undefined;
  readonly?: boolean | undefined;
  textFrom: (value: T | null) => string;
  validationError?: (text: string, browserValid?: boolean) => string | undefined;
  validationWarning?: (text: string, browserValid?: boolean) => string | undefined;
  value?: T | undefined;
  valueFrom: (text: string) => T | null;
  warningText?: string | undefined;
}

export function CustomTextField<T>({
  error,
  errorText,
  helperText,
  hideError,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  InputProps,
  onBlur,
  onChange,
  readonly,
  textFrom,
  validationError,
  validationWarning,
  value: valueFromProps,
  valueFrom,
  warningText,
  ...other
}: CustomTextFieldProps<T>): React.JSX.Element {
  const classes = useStyles();

  const value = valueFromProps ?? null;

  const [text, setText] = useState(textFrom(value));

  const oldTextRef = useRef<string>(text);
  useEffect(() => {
    oldTextRef.current = text;
  }, [text]);

  useEffect(() => {
    if (valueFrom(oldTextRef.current) !== value) {
      setText(textFrom(value));
    }
  }, [textFrom, value, valueFrom]);

  const [browserValid, setBrowserValid] = useState<boolean | undefined>(undefined);

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newText = event.target.value;
      // We need to discern between "value is blank" and "value appears
      // blank because input didn't pass validation, so the browser gave us
      // the empty string instead" -- but ignore the browsers opinion on
      // validity in the (unexpected) cases where it *does* hand  us a value
      // while indicating that it's invalid.
      // At least Chrome 81 under Android with Danish locale has a tendency
      // to hand us values that are "valid" according to our logic,
      // while indicating that they are invalid...
      setBrowserValid(newText === "" ? event.target.validity?.valid : undefined);
      const oldValue = valueFrom(text);
      const newValue = valueFrom(newText);
      setText(newText);
      if (onChange && newValue !== oldValue) {
        onChange(newValue);
      }
    },
    [setText, valueFrom, text, onChange],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      // transform to enforce rules
      // e.g. TrimTextField will remove whitespace before and after
      const newValue = valueFrom(text);
      setText(textFrom(newValue));

      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur, setText, text, textFrom, valueFrom],
  );

  const finalErrorText = hideError
    ? undefined
    : (errorText ??
      (validationError && validationError(text, browserValid)) ??
      (validationWarning && validationWarning(text, browserValid)));

  // error message takes precedence over warning
  // we currently cannot display both as we change the color of FormHelperText
  // if we display a warning - which would cause errors to be yellow as well.
  const finalWarningText = !finalErrorText ? warningText : undefined;

  return (
    <TextField
      classes={readonly ? {root: classes.readonly} : {}}
      error={!!finalErrorText || error || false}
      FormHelperTextProps={{
        classes: finalWarningText
          ? {
              root: classes.warningHelperText,
            }
          : {},
      }}
      helperText={finalErrorText || finalWarningText || helperText}
      InputProps={{
        ...InputProps,
        ...(readonly !== undefined ? {readOnly: readonly} : {}),
      }}
      onBlur={handleBlur}
      onChange={handleTextChange}
      value={text}
      variant="outlined"
      {...other}
    />
  );
}
