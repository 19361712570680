import {OrderUrl, urlToId} from "@co-common-libs/resources";
import {AwaitingBackendRequestSpinner} from "@co-frontend-libs/components";
import {ResponseWithData} from "@co-frontend-libs/utils";
import {getApiEndpoint} from "api-endpoint-urls";
import {LogsCard, LogTaskData} from "app-components";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useState} from "react";

interface OrderPhotoAppendicesProps {
  orderUrl: OrderUrl;
}
export const OrderLogAppendices = React.memo(function OrderLogAppendices(
  props: OrderPhotoAppendicesProps,
): React.JSX.Element {
  const {orderUrl} = props;
  const [logTasks, setLogTasks] = useState<LogTaskData[] | null>(null);

  const handleSuccess = useCallback((response?: ResponseWithData) => {
    if (response) {
      setLogTasks(response.data);
    }
  }, []);

  const data = {
    orderId: urlToId(orderUrl),
  };

  if (!logTasks) {
    return (
      <AwaitingBackendRequestSpinner
        apiUrl={getApiEndpoint(globalConfig.baseURL).orderLogs()}
        data={data}
        execute={!logTasks}
        method="POST"
        onSuccess={handleSuccess}
      />
    );
  }

  return <LogsCard data={logTasks} />;
});
