import {Config} from "@co-common-libs/config";
import {
  Contact,
  ContactUrl,
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  Project,
  ProjectUrl,
  RoutePlan,
  RoutePlanUrl,
  Task,
  Timer,
  TimerStart,
  TimerUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  HOUR_MILLISECONDS,
  MINUTE_MILLISECONDS,
  normalisedTimestamp,
  QUARTER_HOUR_MINUTES,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {computeWorkFromTo, dateFromDateAndTime, mergeIntervals} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import _ from "lodash";
import {HALF_HOUR_ROW_HEIGHT, NO_ESTIMATE_TASK_MINUTES} from "./constants";

export const addHour = (immutableDate: ImmutableDate): ImmutableDate => {
  // add to the absolute time to get correct results on DST-switch
  return immutableDate.setTime(immutableDate.getTime() + HOUR_MILLISECONDS);
};

export const addQuarterHour = (immutableDate: ImmutableDate, quarters: number): ImmutableDate => {
  // add to the absolute time to get correct results on DST-switch
  return immutableDate.setUTCMinutes(
    immutableDate.getUTCMinutes() + QUARTER_HOUR_MINUTES * quarters,
  );
};

export const calculateYPosition = (
  calendarFromTimestamp: Date | ImmutableDate,
  calendarToTimestamp: Date | ImmutableDate,
  timestamp: Date | ImmutableDate,
): number => {
  let clampedTimestamp = timestamp;
  if (clampedTimestamp < calendarFromTimestamp) {
    clampedTimestamp = calendarFromTimestamp;
  }
  if (clampedTimestamp > calendarToTimestamp) {
    clampedTimestamp = calendarToTimestamp;
  }
  const offsetMilliseconds = clampedTimestamp.valueOf() - calendarFromTimestamp.valueOf();
  const offsetMinutes = offsetMilliseconds / MINUTE_MILLISECONDS;
  const HALF_HOUR_MINUTES = 30;
  return Math.round(offsetMinutes * (HALF_HOUR_ROW_HEIGHT / HALF_HOUR_MINUTES));
};

export const plannedPeriod = (
  task: Task,
  calendarFromTimestamp: ImmutableDate,
  calendarToTimestamp: ImmutableDate,
  noEstimateTaskMinutes: number,
): {fromTimestamp: ImmutableDate; toTimestamp: ImmutableDate} | null => {
  const taskDate = task.date;
  const taskTime = task.time;
  const taskDuration = task.minutesExpectedTotalTaskDuration || noEstimateTaskMinutes;
  if (!taskDate || !taskTime) {
    return null;
  }
  const fromTimestamp = new ImmutableDate(dateFromDateAndTime(taskDate, taskTime));
  const toTimestamp = fromTimestamp.setUTCMinutes(fromTimestamp.getUTCMinutes() + taskDuration);
  if (
    fromTimestamp.valueOf() > calendarToTimestamp.valueOf() ||
    toTimestamp.valueOf() < calendarFromTimestamp.valueOf()
  ) {
    return null;
  }
  return {fromTimestamp, toTimestamp};
};

export interface TaskWithRelations {
  readonly computedTimeSet: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | undefined;
    readonly toTimestamp: string;
  }[];
  readonly contact?: Contact | undefined;
  readonly culture?: Culture | undefined;
  readonly customer?: Customer | undefined;
  readonly fielduseSet?:
    | readonly {
        readonly notes: string;
        readonly relatedField: Location | undefined;
      }[]
    | undefined;
  readonly hasPhoto: boolean;
  readonly intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | undefined;
    readonly toTimestamp: string;
  }[];
  readonly intervalsInPeriod: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | undefined;
    readonly toTimestamp: string;
  }[];
  readonly machineOperatorTimeCorrectionSet: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null | undefined;
    readonly toTimestamp: string;
  }[];
  readonly machineuseSet: readonly {
    readonly machine: Machine | undefined;
    readonly priceGroup: string | null;
    readonly transporter: boolean;
  }[];
  readonly managerTimeCorrectionSet: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null | undefined;
    readonly toTimestamp: string;
  }[];
  readonly order?: Order | undefined;
  readonly priceitemuseSet?:
    | readonly {
        readonly correctedCount: number | null;
        readonly count: number | null;
        readonly dangling: boolean;
        readonly notes: string;
        readonly priceItem: PriceItem | undefined;
      }[]
    | undefined;
  readonly productuseSet?:
    | readonly {
        readonly correctedCount: number | null;
        readonly count: number | null;
        readonly notes: string;
        readonly ours: boolean;
        readonly product: Product | undefined;
      }[]
    | undefined;
  readonly project?: Project | undefined;
  readonly routePlan?: RoutePlan | undefined;
  readonly task: Task;
  readonly workFrom: string | null;
  readonly workStartedEarlier: boolean;
  readonly workTo: string | null;
  readonly workType?: WorkType | undefined;
}

export const resolveTaskRelations = (
  task: Task,
  fromTimestamp: ImmutableDate | null,
  toTimestamp: ImmutableDate | null,
  now: string,
  hasPhoto: boolean,
  customerSettings: Config,
  data: {
    contactLookup: (url: ContactUrl) => Contact | undefined;
    cultureLookup?: (url: CultureUrl) => Culture | undefined;
    customerLookup: (url: CustomerUrl) => Customer | undefined;
    locationLookup: (url: LocationUrl) => Location | undefined;
    machineLookup: (url: MachineUrl) => Machine | undefined;
    orderLookup: (url: OrderUrl) => Order | undefined;
    priceItemLookup?: (url: PriceItemUrl) => PriceItem | undefined;
    productLookup?: (url: ProductUrl) => Product | undefined;
    projectLookup: (url: ProjectUrl) => Project | undefined;
    routePlanLookup?: (url: RoutePlanUrl) => RoutePlan | undefined;
    timerLookup: (url: TimerUrl) => Timer | undefined;
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
): TaskWithRelations => {
  const {
    contactLookup,
    cultureLookup,
    customerLookup,
    locationLookup,
    machineLookup,
    orderLookup,
    priceItemLookup,
    productLookup,
    projectLookup,
    routePlanLookup,
    timerLookup,
    workTypeLookup,
  } = data;

  const machineuseSet = (task.machineuseSet || []).map((entry) => {
    const machineURL = entry.machine;
    const machine = machineLookup(machineURL);
    return {...entry, machine};
  });

  let priceitemuseSet:
    | {
        correctedCount: number | null;
        count: number | null;
        dangling: boolean;
        notes: string;
        priceItem: PriceItem | undefined;
      }[]
    | undefined;

  if (customerSettings.calendarShowMaterialsWithUnits.length && priceItemLookup) {
    priceitemuseSet = sortByOrderMember(Object.values(task.priceItemUses || {})).map((entry) => {
      const priceitemURL = entry.priceItem;
      const priceItem = priceItemLookup(priceitemURL);
      return {...entry, priceItem};
    });
  }
  let productuseSet:
    | {
        correctedCount: number | null;
        count: number | null;
        notes: string;
        ours: boolean;
        product: Product | undefined;
      }[]
    | undefined;

  if (customerSettings.calendarShowMaterialsWithUnits.length && productLookup) {
    productuseSet = sortByOrderMember(Object.values(task.productUses || {})).map((entry) => {
      const productURL = entry.product;
      const product = productLookup(productURL);
      return {...entry, product};
    });
  }

  const computedTimeSet = (task.computedTimeSet || []).map((computedTime) => {
    const timerURL = computedTime.timer;
    const timer = typeof timerURL === "string" ? timerLookup(timerURL) : timerURL;
    return {...computedTime, timer};
  });

  const machineOperatorTimeCorrectionSet = (task.machineOperatorTimeCorrectionSet || []).map(
    (correction) => {
      const timerURL = correction.timer;
      const timer = typeof timerURL === "string" ? timerLookup(timerURL) : timerURL;
      return {...correction, timer};
    },
  );

  const managerTimeCorrectionSet = (task.managerTimeCorrectionSet || []).map((correction) => {
    const timerURL = correction.timer;
    const timer = typeof timerURL === "string" ? timerLookup(timerURL) : timerURL;
    return {...correction, timer};
  });
  let fielduseSet:
    | {
        notes: string;
        relatedField: Location | undefined;
      }[]
    | undefined;

  if (customerSettings.showFieldNumbersOnCalendar) {
    fielduseSet = (task.fielduseSet || []).map((fieldUse) => {
      const fieldURL = fieldUse.relatedField;
      return {...fieldUse, relatedField: locationLookup(fieldURL)};
    });
  }

  const workTypeURL = task.workType;
  const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;

  const projectURL = task.project;
  const project = projectURL ? projectLookup(projectURL) : undefined;

  const orderURL = task.order;
  const order = orderURL ? orderLookup(orderURL) : undefined;

  const customerURL = order && order.customer;
  const customer = customerURL ? customerLookup(customerURL) : undefined;

  const contactURL = order && order.contact;
  const contact = contactURL ? contactLookup(contactURL) : undefined;

  const cultureURL = order && order.culture;
  const culture = cultureLookup && cultureURL ? cultureLookup(cultureURL) : undefined;

  const routePlanURL = order && order.routePlan;
  const routePlan = routePlanLookup && routePlanURL ? routePlanLookup(routePlanURL) : undefined;

  const intervals = mergeIntervals(
    task.computedTimeSet || [],
    task.machineOperatorTimeCorrectionSet || [],
    task.managerTimeCorrectionSet || [],
  ).map((interval) => {
    const intervalTimerURL = interval.timer;
    const intervalTimer = intervalTimerURL ? timerLookup(intervalTimerURL) : undefined;
    const intervalFromTimestamp = normalisedTimestamp(interval.fromTimestamp);
    const intervalToTimestamp = normalisedTimestamp(interval.toTimestamp);
    return {
      fromTimestamp: intervalFromTimestamp,
      timer: intervalTimer,
      toTimestamp: intervalToTimestamp,
    };
  });

  let workStartedEarlier = false;

  let intervalsInPeriod: {
    fromTimestamp: string;
    timer: Timer | undefined;
    toTimestamp: string;
  }[] = [];
  let workFrom: string | null = null;
  let workTo: string | null = null;
  if (fromTimestamp && toTimestamp) {
    const fromTimestampString = fromTimestamp.toISOString();
    const toTimestampString = toTimestamp.toISOString();

    intervalsInPeriod = intervals
      .filter((interval) => {
        if ((interval.toTimestamp || now) <= fromTimestampString) {
          workStartedEarlier = true;
          return false;
        } else if (interval.fromTimestamp >= toTimestampString) {
          return false;
        } else {
          return true;
        }
      })
      .map((interval) => {
        let newInterval = interval;
        if (interval.fromTimestamp < fromTimestampString) {
          newInterval = {...interval, fromTimestamp: fromTimestampString};
          workStartedEarlier = true;
        }
        if ((interval.toTimestamp || now) > toTimestampString) {
          newInterval = {...interval, toTimestamp: toTimestampString};
        }
        return newInterval;
      });

    const fromTo = computeWorkFromTo(intervalsInPeriod);
    workFrom = fromTo.workFromTimestamp;
    workTo = fromTo.workToTimestamp;
  }

  return {
    computedTimeSet,
    contact,
    culture,
    customer,
    fielduseSet,
    hasPhoto,
    intervals,
    intervalsInPeriod,
    machineOperatorTimeCorrectionSet,
    machineuseSet,
    managerTimeCorrectionSet,
    order,
    priceitemuseSet,
    productuseSet,
    project,
    routePlan,
    task,
    workFrom,
    workStartedEarlier,
    workTo,
    workType,
  };
};

export const getAnniversaryBackground = (iconName: "cake" | "flag" | "medal"): string => {
  switch (iconName) {
    case "cake":
      return (
        'url("data:image/svg+xml;utf8,' +
        "<svg xmlns='http://www.w3.org/2000/svg'>" +
        "<path d='" +
        "M12,6C13.11,6 14,5.1 14,4C14,3.62 13.9,3.27 13.71,2.97L12,0L10.29,2.97C10.1,3.27 10,3.62 10,4C10,5.1 10.9,6 12,6M16.6,16L15.53,14.92L14.45,16C13.15,17.29 10.87,17.3 9.56,16L8.5,14.92L7.4,16C6.75,16.64 5.88,17 4.96,17C4.23,17 3.56,16.77 3,16.39V21C3,21.55 3.45,22 4,22H20C20.55,22 21,21.55 21,21V16.39C20.44,16.77 19.77,17 19.04,17C18.12,17 17.25,16.64 16.6,16M18,9H13V7H11V9H6C4.34,9 3,10.34 3,12V13.54C3,14.62 3.88,15.5 4.96,15.5C5.5,15.5 6,15.3 6.34,14.93L8.5,12.8L10.61,14.93C11.35,15.67 12.64,15.67 13.38,14.93L15.5,12.8L17.65,14.93C18,15.3 18.5,15.5 19.03,15.5C20.11,15.5 21,14.62 21,13.54V12C21,10.34 19.66,9 18,9" +
        "' />" +
        '</svg>")'
      );
    case "flag":
      return (
        'url("data:image/svg+xml;utf8,' +
        "<svg xmlns='http://www.w3.org/2000/svg'>" +
        "<path d='" +
        "M6,3C6.55,3 7,3.45 7,4V4.88C8.06,4.44 9.5,4 11,4C14,4 14,6 16,6C19,6 20,4 20,4V12C20,12 19,14 16,14C13,14 13,12 11,12C8,12 7,14 7,14V21H5V4C5,3.45 5.45,3 6,3Z" +
        "' />" +
        '</svg>")'
      );
    case "medal":
      return (
        'url("data:image/svg+xml;utf8,' +
        "<svg xmlns='http://www.w3.org/2000/svg'>" +
        "<path d='" +
        "M20,2H4V4L9.81,8.36C6.14,9.57 4.14,13.53 5.35,17.2C6.56,20.87 10.5,22.87 14.19,21.66C17.86,20.45 19.86,16.5 18.65,12.82C17.95,10.71 16.3,9.05 14.19,8.36L20,4V2M14.94,19.5L12,17.78L9.06,19.5L9.84,16.17L7.25,13.93L10.66,13.64L12,10.5L13.34,13.64L16.75,13.93L14.16,16.17L14.94,19.5Z" +
        "' />" +
        '</svg>")'
      );
    default:
      throw new Error("Anniversary icon type not allowed");
  }
};

export const generateTimerStartMapping = (
  sortedTimerStarts: readonly TimerStart[],
): {[taskURL: string]: TimerStart[] | undefined} => {
  const taskTimerStartMapping: {
    [taskURL: string]: TimerStart[] | undefined;
  } = {};
  sortedTimerStarts.forEach((timerStart) => {
    const taskURL = timerStart.task;
    const arrayForTask = taskTimerStartMapping[taskURL];
    if (arrayForTask) {
      arrayForTask.push(timerStart);
    } else {
      taskTimerStartMapping[taskURL] = [timerStart];
    }
  });
  return taskTimerStartMapping;
};

function getTaskFromTo(task: TaskWithRelations): [string | null, string | null] {
  if (task.intervals.length) {
    return [task.workFrom, task.workTo];
  } else if (task.task.date && task.task.time) {
    const taskDuration = task.task.minutesExpectedTotalTaskDuration || NO_ESTIMATE_TASK_MINUTES;
    const fromTimestamp = dateFromDateAndTime(task.task.date, task.task.time);
    const toTimestamp = new Date(fromTimestamp);
    toTimestamp.setUTCMinutes(toTimestamp.getUTCMinutes() + taskDuration);
    return [fromTimestamp.toISOString(), toTimestamp.toISOString()];
  } else {
    return [null, null];
  }
}

function tasksHaveOverlappingIntervals(
  taskA: TaskWithRelations,
  taskB: TaskWithRelations,
  taskOverlapWarningAfterMinutes: number,
): boolean {
  const aIntervals = [...taskA.intervals];
  if (!aIntervals.length) {
    const [fromTimestamp, toTimestamp] = getTaskFromTo(taskA);
    if (fromTimestamp && toTimestamp) {
      aIntervals.push({fromTimestamp, timer: undefined, toTimestamp});
    }
  }
  const bIntervals = [...taskB.intervals];
  if (!bIntervals.length) {
    const [fromTimestamp, toTimestamp] = getTaskFromTo(taskB);
    if (fromTimestamp && toTimestamp) {
      bIntervals.push({fromTimestamp, timer: undefined, toTimestamp});
    }
  }
  let aIndex = 0;
  let bIndex = 0;
  while (aIndex < aIntervals.length && bIndex < bIntervals.length) {
    // Both aIntervals and bIntervals are sorted.
    // On each iteration of the loop, we will either
    // * conclude that the element in aIntervals at aIndex cannot overlap
    //   with *any* element from bIntervals and increment aIndex,
    // * conclude that the element in bIntervals at bIndex cannot overlap
    //   with *any* element from aIntervals and increment bIndex, or
    // * conclude that there is an overlap.
    const aInterval = aIntervals[aIndex];
    const bInterval = bIntervals[bIndex];

    const aFromDateTime = new Date(aInterval.fromTimestamp);
    aFromDateTime.setUTCMinutes(aFromDateTime.getUTCMinutes() + taskOverlapWarningAfterMinutes);
    const aFromTimestamp = aFromDateTime.toISOString();

    if (aFromTimestamp >= aInterval.toTimestamp) {
      // Nothing left when taskOverlapWarningAfterMinutes skipped;
      // so this aInterval cannot be considered overlapping with anything.
      aIndex += 1;
      continue;
    }

    const bFromDateTime = new Date(bInterval.fromTimestamp);
    bFromDateTime.setUTCMinutes(bFromDateTime.getUTCMinutes() + taskOverlapWarningAfterMinutes);
    const bFromTimestamp = bFromDateTime.toISOString();

    if (bFromTimestamp >= bInterval.toTimestamp) {
      // Nothing left when taskOverlapWarningAfterMinutes skipped;
      // so this bInterval cannot be considered overlapping with anything.
      bIndex += 1;
      continue;
    }

    if (aFromTimestamp < bInterval.toTimestamp && aInterval.toTimestamp > bFromTimestamp) {
      // Overlap between parts after taskOverlapWarningAfterMinutes
      // from aInterval and bInterval
      return true;
    }

    // Both aInterval and bInterval contains more than
    // taskOverlapWarningAfterMinutes, but part after
    // taskOverlapWarningAfterMinutes from both are not overlapping.
    //
    // This must mean that either aInterval ends before the part of
    // bInterval after taskOverlapWarningAfterMinutes, or bInterval ends
    // before the part of aInterval after taskOverlapWarningAfterMinutes.
    //
    // Both aIntervals and bIntervals are sorted on fromTimestamp,
    // so if the current aInterval is "before" the current bInterval,
    // it is also "before" all later entries in bIntervals, and if the
    // current bInterval is "before" the current aInterval, it is also
    // "before" all later entries in aIntervals.
    //
    // The check for "cannot overlap with anything at current or later index"
    // implies "cannot overlap with anything": This is obvious in the base
    // case where aIndex and bIndex are 0; and from then on, we anly increment
    // aIndex/bIndex when the entry we thus "skip" for further checks cannot
    // overlap with anything.
    if (aInterval.fromTimestamp < bInterval.fromTimestamp) {
      aIndex += 1;
    } else if (bInterval.fromTimestamp < aInterval.fromTimestamp) {
      bIndex += 1;
    } else {
      // impossible
      return true;
    }
  }
  return false;
}

const placeTask = (
  startColumn: number,
  task: TaskWithRelations,
  columns: TaskWithRelations[][],
  taskOverlapWarningAfterMinutes: number,
): void => {
  if (!columns[startColumn]) {
    columns[startColumn] = [task];
    return;
  }

  let hasOverlap = false;

  for (let i = 0; i < columns[startColumn].length; i += 1) {
    const placedTask = columns[startColumn][i];
    const [placedTaskFrom, placedTaskTo] = getTaskFromTo(placedTask);
    const [taskFrom, taskTo] = getTaskFromTo(task);
    if (
      placedTaskFrom &&
      placedTaskTo &&
      taskFrom &&
      taskTo &&
      placedTaskFrom < taskTo &&
      placedTaskTo > taskFrom
    ) {
      hasOverlap = tasksHaveOverlappingIntervals(task, placedTask, taskOverlapWarningAfterMinutes);
      if (hasOverlap) {
        break;
      }
    }
  }
  if (hasOverlap) {
    placeTask(startColumn + 1, task, columns, taskOverlapWarningAfterMinutes);
  } else {
    columns[startColumn].push(task);
  }
};

interface TaskWithRelationsAndPhase extends TaskWithRelations {
  readonly phase: "complete" | "incomplete" | "planned";
}

export const sortTasksInColumns = (
  userURL: string,
  intersectingPlannedTasks: readonly Task[],
  intersectingCompletedTasks: readonly Task[],
  incompleteTasks: readonly Task[],
  inlineTaskRelations: (task: Task) => TaskWithRelations,
  taskOverlapWarningAfterMinutes: number,
): TaskWithRelationsAndPhase[][] => {
  const planned = intersectingPlannedTasks
    .filter((task) => task.machineOperator === userURL)
    .map((task) => ({...inlineTaskRelations(task), phase: "planned"}) as const);

  const completed = _.sortBy(
    intersectingCompletedTasks
      .filter((task) => task.machineOperator === userURL)
      .map((task) => ({...inlineTaskRelations(task), phase: "complete"}) as const)
      .filter((taskWithRelations) => taskWithRelations.workFrom),
    (taskWithRelations) => taskWithRelations.workFrom,
  );

  const incomplete = _.sortBy(
    incompleteTasks
      .filter((task) => task.machineOperator === userURL)
      .map((task) => ({...inlineTaskRelations(task), phase: "incomplete"}) as const)
      .filter((taskWithRelations) => taskWithRelations.workFrom),
    (taskWithRelations) => taskWithRelations.workFrom,
  );

  const columns: TaskWithRelationsAndPhase[][] = [];
  completed.forEach((task) => {
    placeTask(0, task, columns, taskOverlapWarningAfterMinutes);
  });

  incomplete.forEach((task) => {
    placeTask(0, task, columns, taskOverlapWarningAfterMinutes);
  });

  planned.forEach((taskWithRelations) => {
    const taskURL = taskWithRelations.task.url;
    let placed = false;
    columns.forEach((columnTasksWithRelations) => {
      if (columnTasksWithRelations.find((t) => t.task.url === taskURL)) {
        columnTasksWithRelations.push(taskWithRelations);
        placed = true;
      }
    });
    if (!placed) {
      placeTask(0, taskWithRelations, columns, taskOverlapWarningAfterMinutes);
    }
  });

  // Should contain atleast one column
  if (columns.length === 0) {
    columns.push([]);
  }
  return columns;
};
