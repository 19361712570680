import {Config} from "@co-common-libs/config";
import {PriceItem, PriceItemUrl, Unit, UnitUrl} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {DecimalField} from "@co-frontend-libs/components";
import {TableCell, TableRow} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";

const UNIT_COLUMN_WIDTH = 96;
const VALUE_COLUMN_WIDTH = 128;

interface PriceItemEntryProps {
  customerSettings: Config;
  onUpdateValue: (priceItemURL: PriceItemUrl, count: number | null) => void;
  priceItem: PriceItem;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  value?: number | undefined;
}

export const PriceItemEntry = React.memo(function PriceItemEntry(
  props: PriceItemEntryProps,
): React.JSX.Element {
  const {customerSettings, onUpdateValue, priceItem, unitLookup, value} = props;
  const backendMaxDigits = 10;
  const backendDecimalPlaces = 3;
  const integerPartDigits = backendMaxDigits - backendDecimalPlaces;
  const decimalPlaces = customerSettings.transportLogDecimals;
  console.assert(decimalPlaces <= backendDecimalPlaces);
  const maxDigits = integerPartDigits + decimalPlaces;

  const handleCountChange = useCallback(
    (newValue: number | null): void => {
      onUpdateValue(priceItem.url, newValue);
    },
    [onUpdateValue, priceItem.url],
  );

  const intl = useIntl();

  return (
    <TableRow>
      <TableCell>{priceItem.name}</TableCell>
      <TableCell style={{width: VALUE_COLUMN_WIDTH}}>
        <DecimalField
          decimalPlaces={decimalPlaces}
          label={intl.formatMessage({defaultMessage: "Antal"})}
          margin="dense"
          maxDigits={maxDigits}
          onChange={handleCountChange}
          value={value != null ? value : null}
        />
      </TableCell>
      <TableCell style={{width: UNIT_COLUMN_WIDTH}}>
        {getUnitString(priceItem, unitLookup)}
      </TableCell>
    </TableRow>
  );
});
