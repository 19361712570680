import {ReadonlyConfig} from "@co-common-libs/config";
import {Project, ProjectAccess, Role} from "@co-common-libs/resources";
import {
  economicProjectImportEnabled,
  EconomicProjectSyncEnabledCustomerSettings,
  roleIsManager,
  roleIsProjectManager,
} from "@co-common-libs/utils";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";
import {
  extendedResourcesSettings,
  ExtendedResourcesSyncSettingsPart,
} from "./extended-resources-settings";

type ExtendedProjectSettingsPart = EconomicProjectSyncEnabledCustomerSettings &
  EconomySystemIntegrationKindPart &
  ExtendedResourcesSyncSettingsPart &
  Pick<
    ReadonlyConfig,
    | "canAddProject"
    | "canEditProjectDistance"
    | "canEditProjects"
    | "createCostLines"
    | "enableProjectDistance"
    | "enableProjects"
    | "enableProjectTravelTime"
    | "projectLabelVariant"
    | "showProjectAlias"
  >;

export interface ExtendedProjectSettings
  extends Pick<
    ReadonlyConfig,
    "enableProjectDistance" | "enableProjectTravelTime" | "projectLabelVariant" | "showProjectAlias"
  > {
  canCreate: boolean;
  canEditField: (project: Pick<Project, "access" | "remoteUrl">, field: keyof Project) => boolean;
  canOpenEditDialog: boolean;
  requireIntegerProjectNumber: boolean;
  useInternalProjects: boolean;
}

const externalDataMembers: ReadonlySet<keyof Project> = new Set([
  "access",
  "customer",
  "name",
  "projectNumber",
  "remoteUrl",
]);

export function extendedProjectSettings(
  config: ExtendedProjectSettingsPart,
  role: Role | null,
): ExtendedProjectSettings {
  const {
    c5Sync,
    canAddProject,
    canEditProjectDistance,
    canEditProjects,
    economicSync,
    enableProjectDistance,
    enableProjects,
    enableProjectTravelTime,
    projectLabelVariant,
    showProjectAlias,
  } = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);
  const extendedResourceSettings = extendedResourcesSettings(config, role);

  const useInternalProjects =
    (enableProjects &&
      !c5Sync &&
      !economicSync &&
      !extendedResourceSettings.syncEnabled("project")) ||
    (economicSync && !economicProjectImportEnabled(config));

  const integrationAllowsManage =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE;

  const canCreate =
    enableProjects &&
    (canAddProject === "all" ||
      (canAddProject === "manager" && (roleIsManager(role) || roleIsProjectManager(role)))) &&
    (useInternalProjects || integrationAllowsManage);

  const canEditExternalData =
    canEditProjects &&
    (roleIsManager(role) || roleIsProjectManager(role)) &&
    integrationAllowsManage;

  return {
    canCreate,
    canEditField: (project: Pick<Project, "access" | "remoteUrl">, field: keyof Project) => {
      let passAdditionalFieldRequirements = true;

      if (field === "distanceInKm") {
        passAdditionalFieldRequirements = canEditProjectDistance;
      }
      if (!project.remoteUrl) {
        return roleIsManager(role) && passAdditionalFieldRequirements;
      } else {
        const isInternalField = !externalDataMembers.has(field);
        return (
          project.access !== ProjectAccess.Barred &&
          (canEditExternalData || isInternalField) &&
          roleIsManager(role) &&
          passAdditionalFieldRequirements
        );
      }
    },
    canOpenEditDialog: enableProjects && canEditProjects,
    enableProjectDistance,
    enableProjectTravelTime,
    projectLabelVariant,
    requireIntegerProjectNumber: economicSync,
    showProjectAlias,
    useInternalProjects,
  };
}
