import {DeleteDialog, IntegerField, ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  DialogContent,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import _ from "lodash";
import DeleteIcon from "mdi-react/DeleteIcon";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {StatusDot} from "../status-dot";
import {SettingViewProps} from "../types";

function findDuplicates(list: readonly (number | null)[]): Set<number> {
  return new Set(
    list.filter((item, index) => item !== null && list.indexOf(item) !== index) as number[],
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

function EnterIntegersRow({
  duplicateValues,
  index,
  onRequestDelete,
  onValueChange,
  value,
}: {
  duplicateValues: Set<number | null>;
  index: number;
  onRequestDelete: (index: number) => void;
  onValueChange: (index: number, value: number | null) => void;
  value: number | null;
}): React.JSX.Element {
  const intl = useIntl();
  const handleValueChange = useCallback(
    (newValue: number | null) => {
      onValueChange(index, newValue);
    },
    [index, onValueChange],
  );

  const handleDeleteClick = useCallback(() => {
    onRequestDelete(index);
  }, [index, onRequestDelete]);

  const duplicateError = duplicateValues.has(value);
  return (
    <TableRow>
      <TableCell>
        <IntegerField
          error={duplicateError}
          fullWidth
          helperText={
            duplicateError
              ? intl.formatMessage({
                  defaultMessage: "Værdien eksisterer flere gange i listen",
                })
              : undefined
          }
          margin="dense"
          onChange={handleValueChange}
          value={value}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function EnterIntegersDialog({
  description,
  onCancel,
  onOk,
  open,
  values: initialValues,
}: {
  description?: string;
  onCancel: () => void;
  onOk: (newValue: readonly (number | null)[]) => void;
  open: boolean;
  values: (number | null)[];
}): React.JSX.Element {
  const [values, setValues] = useState<readonly (number | null)[]>(initialValues);
  useEffect(() => {
    if (open) {
      const sorted = _.sortBy(initialValues);
      setValues(sorted);
    }
  }, [initialValues, open]);

  const handleValueChange = useCallback(
    (index: number, value: number | null) => {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
    },
    [values],
  );

  const [newEnterIntegersDialogOpen, setNewEnterIntegersDialogOpen] = useState(false);
  const setNewEnterIntegersDialogOpenTrue = useCallWithTrue(setNewEnterIntegersDialogOpen);
  const setNewEnterIntegersDialogOpenFalse = useCallWithFalse(setNewEnterIntegersDialogOpen);

  const handleOk = useCallback(() => {
    onOk(values);
  }, [onOk, values]);
  const handleNewEnterIntegersDialogOk = useCallback(
    (value: number | null) => {
      setNewEnterIntegersDialogOpen(false);
      const newValues = [...values, value];
      setValues(newValues);
    },
    [values],
  );

  const [deleteDialogOpenFor, setDeleteDialogOpenFor] = useState<number | null>(null);

  const handleDeleteClick = useCallback((index: number) => {
    setDeleteDialogOpenFor(index);
  }, []);

  const handleDeleteDialogOk = useCallback(() => {
    setDeleteDialogOpenFor(null);
    if (deleteDialogOpenFor === null) {
      return;
    }
    const newValues = [...values];
    newValues.splice(deleteDialogOpenFor, 1);
    setValues(newValues);
  }, [deleteDialogOpenFor, values]);

  const handleDeleteDialogCancel = useCallback(() => {
    setDeleteDialogOpenFor(null);
  }, []);

  const deleteDialogOpen = deleteDialogOpenFor !== null;
  const duplicateValues = useMemo(() => findDuplicates(values), [values]);

  return (
    <>
      <ResponsiveDialog
        okDisabled={!!duplicateValues.size}
        onCancel={onCancel}
        onOk={handleOk}
        open={open && !newEnterIntegersDialogOpen && !deleteDialogOpen}
        title={
          <FormattedMessage defaultMessage="Rediger værdier" id="enter-integers.edit-integers" />
        }
      >
        <DialogContent>
          {description}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Værdi" id="enter-integers.label" />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((value, index) => {
                return (
                  <EnterIntegersRow
                    duplicateValues={duplicateValues}
                    index={index}
                    key={index}
                    onRequestDelete={handleDeleteClick}
                    onValueChange={handleValueChange}
                    value={value}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Button onClick={setNewEnterIntegersDialogOpenTrue}>Tilføj</Button>
        </DialogContent>
      </ResponsiveDialog>
      <AddIntegersDialog
        onCancel={setNewEnterIntegersDialogOpenFalse}
        onOk={handleNewEnterIntegersDialogOk}
        open={newEnterIntegersDialogOpen}
        otherValues={values}
      />
      <DeleteDialog
        onCancel={handleDeleteDialogCancel}
        onOk={handleDeleteDialogOk}
        open={deleteDialogOpen}
      >
        <FormattedMessage
          defaultMessage="Hvis du sletter denne mens den er i brug, kan du ødelægge opsætningen!"
          id="key-value-setting.delete-message"
        />
      </DeleteDialog>
    </>
  );
}

function AddIntegersDialog({
  onCancel,
  onOk,
  open,
  otherValues,
}: {
  onCancel: () => void;
  onOk: (value: number | null) => void;
  open: boolean;
  otherValues: readonly (number | null)[];
}): React.JSX.Element {
  const intl = useIntl();
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    if (open) {
      setValue(null);
    }
  }, [open]);

  const handleOk = useCallback(() => {
    onOk(value);
  }, [onOk, value]);

  const duplicateError = otherValues.includes(value);
  return (
    <ResponsiveDialog
      okDisabled={!value || duplicateError}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={<FormattedMessage defaultMessage="Tilføj ny" id="absence-type-labels-dialog.title" />}
    >
      <DialogContent>
        <IntegerField
          autoFocus
          error={duplicateError}
          fullWidth
          helperText={
            duplicateError
              ? intl.formatMessage({
                  defaultMessage: "Værdien eksisterer allerede i listen",
                })
              : undefined
          }
          label={intl.formatMessage({
            defaultMessage: "Værdi",
          })}
          margin="dense"
          onChange={setValue}
          value={value}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}

export function EnterIntegersSetting(props: SettingViewProps): React.JSX.Element {
  const {settingID, settingMetaData} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const settingsData: number[] = settingEntry?.data || [];
  const classes = useStyles();

  const [enterIntegersDialogOpen, setEnterIntegersDialogOpen] = useState(false);
  const setEnterIntegersDialogOpenTrue = useCallWithTrue(setEnterIntegersDialogOpen);
  const setEnterIntegersDialogOpenFalse = useCallWithFalse(setEnterIntegersDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleEnterIntegersDialogOk = useCallback(
    (newValue: readonly (number | null)[]) => {
      setEnterIntegersDialogOpen(false);

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry],
  );

  return (
    <>
      <div className={classes.root} style={{display: "flex"}}>
        <div onClick={setEnterIntegersDialogOpenTrue} style={{cursor: "pointer", flex: "0 0 50px"}}>
          <StatusDot settingEntry={settingEntry} />
        </div>
        <div onClick={setEnterIntegersDialogOpenTrue} style={{cursor: "pointer", flex: 1}}>
          {settingMetaData.description}
          <div>
            <i>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{width: 200}}>
                      <FormattedMessage defaultMessage="Værdi" id="enter-integers.label" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settingsData.map((value, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </i>
          </div>
        </div>
        <div style={{flex: "0 0 100px", paddingRight: 50}}>
          <small>{settingID}</small>
        </div>
      </div>
      <EnterIntegersDialog
        description={settingMetaData.description}
        onCancel={setEnterIntegersDialogOpenFalse}
        onOk={handleEnterIntegersDialogOk}
        open={enterIntegersDialogOpen}
        values={settingsData}
      />
    </>
  );
}
