import {TaskFile, TaskPhoto, UserFile, UserPhoto} from "@co-common-libs/resources";
import {formatDateNumeric} from "@co-common-libs/utils";
import {ErrorColorFab, FilePdfIcon, TrimTextField} from "@co-frontend-libs/components";
import {IMAGE_DEFAULT_MAX_SIZE} from "@co-frontend-libs/utils";
import {Card, CardContent, Grid, IconButton} from "@material-ui/core";
import {useFieldUpdater} from "app-utils";
import {mobile} from "bowser";
import DeleteIcon from "mdi-react/DeleteIcon";
import React, {useCallback} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {PhotoImage} from "./photo-image";

const messages = defineMessages({
  note: {
    defaultMessage: "Note",
    id: "files.label.note",
  },
});

const ERROR_STYLE = {
  display: "inline-block",
  paddingRight: 48,
} as const;

const cardContentStyle: React.CSSProperties = {
  display: "inline-block",
  height: 390,
  minWidth: 292,
  position: "relative",
  width: "100%",
};

interface PhotoInstanceProps {
  editDisabled?: boolean;
  onDeleteButton?: ((photo: string) => void) | undefined;
  onDisplay: (photo: TaskPhoto | UserPhoto) => void;
  photo: TaskPhoto | UserPhoto;
  shareToken: string | null;
  showAddedDate?: boolean;
}
const container: React.CSSProperties = {
  height: "100%",
  width: "100%",
};

const cardStylePC: React.CSSProperties = {
  maxWidth: 290,
};

const cardStyleMobile: React.CSSProperties = {
  width: "100%",
};

export const PhotoInstance = ({
  editDisabled,
  onDeleteButton,
  onDisplay,
  photo,
  shareToken,
  showAddedDate,
}: PhotoInstanceProps): React.JSX.Element => {
  const handleImageClick = useCallback((): void => {
    onDisplay(photo);
  }, [onDisplay, photo]);
  const {formatMessage} = useIntl();
  const deleteButtonStyle: React.CSSProperties = {
    position: "absolute",
    right: 24,
    top: 24,
  };
  const handleDeleteButton = useCallback(() => {
    if (onDeleteButton) {
      onDeleteButton(photo.url);
    }
  }, [onDeleteButton, photo.url]);

  const handleNoteFieldChange = useFieldUpdater<UserPhoto, string>(photo.url, "note");
  return (
    <Card style={mobile ? cardStyleMobile : cardStylePC}>
      <CardContent style={cardContentStyle}>
        <Grid container direction="column" justifyContent="flex-start" style={container}>
          <Grid item style={pdfImageGridStyle}>
            <PhotoImage
              errorStyle={ERROR_STYLE}
              key={photo.url}
              onClick={handleImageClick}
              photo={photo}
              shareToken={shareToken}
              size="thumbnail"
            />
            {showAddedDate ? (
              <div>
                <FormattedMessage defaultMessage="Tilføjet" id="photo-instance.added" />:{" "}
                {formatDateNumeric(photo.taken)}
              </div>
            ) : null}
          </Grid>

          <Grid item>
            <TrimTextField
              disabled={!!editDisabled}
              fullWidth
              label={formatMessage(messages.note)}
              maxRows={30}
              minRows={2}
              multiline
              onChange={handleNoteFieldChange}
              value={photo.note}
              variant="outlined"
            />
          </Grid>
          {onDeleteButton ? (
            <ErrorColorFab
              disabled={!!editDisabled}
              onClick={handleDeleteButton}
              size="small"
              style={deleteButtonStyle}
            >
              <DeleteIcon />
            </ErrorColorFab>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

interface PDFInstanceProps {
  editDisabled?: boolean;
  file: TaskFile | UserFile;
  onDeleteButton?: ((file: string) => void) | undefined;
  shareToken: string | null;
  showAddedDate?: boolean;
}

function instanceOfUserFile(object: TaskFile | UserFile): object is UserFile {
  return "created" in object;
}

const pdfImageGridStyle: React.CSSProperties = {
  alignItems: "center",
  alignSelf: "center",
  display: "flex",
  flexGrow: 1,
};
export const PDFInstance = function PDFInstance({
  editDisabled,
  file,
  onDeleteButton,
  shareToken,
  showAddedDate,
}: PDFInstanceProps): React.JSX.Element {
  const {formatMessage} = useIntl();
  const deleteButtonStyle: React.CSSProperties = {
    position: "absolute",
    right: 24,
    top: 24,
  };
  const handleDeleteButton = useCallback(() => {
    if (onDeleteButton) {
      onDeleteButton(file.url);
    }
  }, [onDeleteButton, file.url]);
  const handleNoteFieldChange = useFieldUpdater<UserFile, string>(file.url, "note");

  return (
    <Card style={mobile ? cardStyleMobile : cardStylePC}>
      <CardContent style={cardContentStyle}>
        <Grid container direction="column" justifyContent="flex-start" style={container}>
          <Grid item style={pdfImageGridStyle}>
            <IconButton
              color="primary"
              href={`${file.download}?token=${shareToken}`}
              target="_blank"
            >
              <FilePdfIcon size={235} />
            </IconButton>
            {showAddedDate && instanceOfUserFile(file) ? (
              <div>
                <FormattedMessage defaultMessage="Tilføjet" id="pdf-instance.added" />:{" "}
                {formatDateNumeric(file.created)}
              </div>
            ) : null}
          </Grid>
          <Grid item>
            <TrimTextField
              disabled={!!editDisabled}
              fullWidth
              label={formatMessage(messages.note)}
              maxRows={30}
              minRows={2}
              multiline
              onChange={handleNoteFieldChange}
              value={file.note}
              variant="outlined"
            />
          </Grid>
          {onDeleteButton ? (
            <ErrorColorFab
              disabled={!!editDisabled}
              onClick={handleDeleteButton}
              size="small"
              style={deleteButtonStyle}
            >
              <DeleteIcon />
            </ErrorColorFab>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

// https://cordova.apache.org/docs/en/11.x/reference/cordova-plugin-camera/#camerapicturesourcetype--enum
export enum SourceType {
  CAMERA = 1,
  PHOTOLIBRARY = 0,

  SAVEDPHOTOALBUM = 2,
}
export const appPhotoHelper = (
  sourceType: SourceType,
  filesAcceptedFunction: (files: File[]) => void,
): void => {
  const options = {
    correctOrientation: true,
    destinationType: (window as any).Camera.DestinationType.FILE_URI,
    encodingType: (window as any).Camera.EncodingType.JPEG,
    mediaType: (window as any).Camera.MediaType.PICTURE,
    quality: 85,
    saveToPhotoAlbum: sourceType === SourceType.CAMERA,
    sourceType,
    targetHeight: IMAGE_DEFAULT_MAX_SIZE,
    targetWidth: IMAGE_DEFAULT_MAX_SIZE,
  };
  const getPicturePromise = (): Promise<string> =>
    new Promise((resolve, reject) => {
      (navigator as any).camera.getPicture(resolve, reject, options);
    });
  const getFileEntryPromise = (fileURL: string): Promise<any> =>
    new Promise((resolve, reject) => {
      (window as any).resolveLocalFileSystemURL(fileURL, resolve, reject);
    });
  const getFilePromise = (fileEntry: any): Promise<File> =>
    new Promise((resolve, reject) => {
      fileEntry.file(resolve, reject);
    });
  getPicturePromise()
    .then(getFileEntryPromise)
    .then(getFilePromise)
    .then((file) => filesAcceptedFunction([file]))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      // ignore for now...
    });
};

export function useFileInputChangeHandler(
  onFilesAccepted: (files: File[]) => void,
  onFilesRejected: (file: File) => void,
  acceptedFileType: string,
): (e: React.ChangeEvent<HTMLInputElement>) => void {
  return useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      const droppedFiles = (e as any).dataTransfer
        ? ((e as any).dataTransfer as DataTransfer).files
        : e.target.files;

      if (!droppedFiles) {
        return;
      }
      const max = droppedFiles.length;
      const files = [];
      for (let i = 0; i < max; i += 1) {
        const file = droppedFiles[i];
        files.push(file);
      }
      const types = acceptedFileType.split(",");
      const invalidFile = files.find((file) => !types.includes(file.type));
      if (invalidFile) {
        onFilesRejected(invalidFile);
      } else {
        onFilesAccepted(files);
      }
      // See: https://github.com/ngokevin/react-file-reader-input/issues/11#issuecomment-612959113
      // Resolves: https://github.com/customoffice-dk/customoffice/issues/1684
      const {target = {}} = e || {};
      (target as any).value = "";
    },
    [acceptedFileType, onFilesAccepted, onFilesRejected],
  );
}
