import {Chip, ChipProps} from "@material-ui/core";
import React, {useCallback} from "react";

export interface MachineChipProps {
  deletable: boolean;
  index: number;
  onDelete: (index: number) => void;
  text: string;
}

export const MachineChip = React.memo(function MachineChip(
  props: MachineChipProps,
): React.JSX.Element {
  const {deletable, index, onDelete, text} = props;
  const handleDelete = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);
  const optionalDeleteProps: ChipProps = {};
  if (deletable) {
    optionalDeleteProps.onDelete = handleDelete;
  }
  return (
    <Chip
      label={
        <span
          style={{
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </span>
      }
      style={{maxWidth: "100%"}}
      {...optionalDeleteProps}
    />
  );
});
