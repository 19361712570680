import {dateToString, MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {
  getCurrentRole,
  getCustomerSettings,
  getPathName,
  getUpdateReady,
} from "@co-frontend-libs/redux";
import {common as commonColors} from "@material-ui/core/colors";
import {OrderTabContent} from "app-components";
import {machineOperatorCanSeeFutureTasksUntil, updateAutoReloadCallback} from "app-utils";
import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";

export const MinimalFullscreenOrderCalendar = React.memo(
  function MinimalFullscreenOrderCalendar(): React.JSX.Element {
    const updateReady = useSelector(getUpdateReady);

    useEffect(() => {
      if (updateReady) {
        updateAutoReloadCallback();
      }
    }, [updateReady]);

    const customerSettings = useSelector(getCustomerSettings);
    const currentRole = useSelector(getCurrentRole);
    const pathName = useSelector(getPathName);

    const userIsManager = currentRole && currentRole.manager;
    const userIsSeniorMachineOperator = currentRole && currentRole.seniorMachineOperator;

    const lastVisibleDate = useMemo(() => {
      if (!userIsManager && !userIsSeniorMachineOperator) {
        return machineOperatorCanSeeFutureTasksUntil(customerSettings);
      }
      return undefined;
    }, [customerSettings, userIsManager, userIsSeniorMachineOperator]);

    const [today, setToday] = useState(dateToString(new Date()));

    useEffect(() => {
      const intervalID = window.setInterval(
        () => setToday(dateToString(new Date())),
        MINUTE_MILLISECONDS,
      );
      return () => {
        window.clearInterval(intervalID);
      };
    }, []);
    return (
      <div style={{backgroundColor: commonColors.white, height: "100%"}}>
        <div className="scrollable" style={{height: "100%", width: "100%"}}>
          <OrderTabContent
            allFolded
            lastVisibleDate={lastVisibleDate}
            pathName={pathName}
            selectedDate={today}
            userIsOnlyMachineOperator={!userIsManager}
          />
        </div>
      </div>
    );
  },
);
