import {Customer, Order, Task, urlToId, WorkType} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {getCustomerSettings, getWorkTypeLookup} from "@co-frontend-libs/redux";
import {colorMap, matchingTextColor} from "@co-frontend-libs/utils";
import _ from "lodash";
import React, {useCallback} from "react";
import {ConnectDragSource, DragSource} from "react-dnd";
import {useSelector} from "react-redux";
import {ORDER_CALENDAR_COLUMN_WIDTH, PARTIALLY_PLANNED_ORDER_HEIGHT} from "./constants";

const MARGIN = 4;

interface PartiallyPlannedOrderBlockOwnProps {
  customer?: Customer | undefined;
  dndMode: boolean;
  firstScrollVisibleDay: number;
  onGoToOrder: (orderId: string) => void;
  order: Order;
  overflowsEnd: boolean;
  overflowsStart: boolean;
  taskList: readonly Task[] | undefined;
  visibleDays: number;
  visibleDaysOffset: number;
}

interface PartiallyPlannedOrderBlockDragSourceProps {
  connectDragSource: ConnectDragSource;
  isDragging: boolean;
}

type PartiallyPlannedOrderBlockProps = PartiallyPlannedOrderBlockDragSourceProps &
  PartiallyPlannedOrderBlockOwnProps;

const PartiallyPlannedOrderBlock = React.memo(function PartiallyPlannedOrderBlock(
  props: PartiallyPlannedOrderBlockProps,
): React.JSX.Element {
  const {
    connectDragSource,
    customer,
    firstScrollVisibleDay,
    isDragging,
    onGoToOrder,
    order,
    overflowsEnd,
    overflowsStart,
    taskList,
    visibleDays,
    visibleDaysOffset,
  } = props;

  const handleGoToOrder = useCallback(() => {
    onGoToOrder(urlToId(order.url));
  }, [onGoToOrder, order.url]);

  const customerSettings = useSelector(getCustomerSettings);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  let workType: Readonly<WorkType> | undefined;
  if (!customerSettings.noExternalTaskWorkType && taskList) {
    const oldestTaskWithWorkType = _.minBy(
      taskList.filter((t) => t.workType),
      (t) => t.created,
    );
    if (oldestTaskWithWorkType?.workType) {
      workType = workTypeLookup(oldestTaskWithWorkType.workType);
    }
  }

  const backgroundColor = (workType && workType.color) || colorMap.ORDER;
  const color = matchingTextColor(backgroundColor);

  let workTypeStr = null;
  if (workType) {
    workTypeStr = getWorkTypeString(workType);
  }
  let customerStr = null;
  if (customer) {
    customerStr = `, ${customer.name}`;
  }

  const left = visibleDaysOffset * ORDER_CALENDAR_COLUMN_WIDTH + 4;

  let width = visibleDays * ORDER_CALENDAR_COLUMN_WIDTH - (MARGIN + MARGIN);
  let startArrow;
  let endArrow;
  if (overflowsStart) {
    const arrowStyle: React.CSSProperties = {
      borderBottom: "9px solid transparent",
      borderLeft: "9px solid white",
      borderTop: "9px solid transparent",
      height: 0,
      marginLeft: -9,
      width: 0,
    };
    startArrow = <span style={arrowStyle} />;
  }
  if (overflowsEnd) {
    const arrowStyle: React.CSSProperties = {
      borderBottom: "16px solid transparent",
      borderLeft: `16px solid ${backgroundColor}`,
      borderTop: "16px solid transparent",
      float: "right",
      height: 0,
      marginRight: -20,
      marginTop: -4,
      width: 0,
    };
    endArrow = <span style={arrowStyle} />;
    // somehow,

    // did not actually work...?
    const x = 8;
    width = width - x;
  }
  const style: React.CSSProperties = {
    backgroundColor,
    color,
    cursor: "pointer",
    height: PARTIALLY_PLANNED_ORDER_HEIGHT,
    left,

    opacity: isDragging ? 0.2 : 1,
    padding: "0px 4px 0px 9px",
    position: "absolute",
    width,
  };
  const workTypeStyle: React.CSSProperties = {
    color: workTypeStr ? color : colorMap.ERROR,
  };
  const customerStyle: React.CSSProperties = {
    color: customerStr ? color : colorMap.ERROR,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  const innerStyle: React.CSSProperties = {
    cursor: "pointer",
    fontSize: "10pt",
    fontWeight: "bold",
    marginTop: 5,
    position: "relative",
    verticalAlign: "top",
  };
  const labelStyle: React.CSSProperties = {
    marginLeft:
      firstScrollVisibleDay > visibleDaysOffset
        ? Math.min(firstScrollVisibleDay - visibleDaysOffset, visibleDays - 1) *
          ORDER_CALENDAR_COLUMN_WIDTH
        : 0,
    whiteSpace: "nowrap",
  };
  return connectDragSource(
    <div style={style}>
      <div onClick={handleGoToOrder} style={innerStyle}>
        {startArrow}
        <span style={labelStyle}>
          <span style={workTypeStyle}>{workTypeStr || "???"}</span>
          <span style={customerStyle}>{customerStr || "???"}</span>
        </span>
        {endArrow}
      </div>
    </div>,
  ) as React.JSX.Element;
});

const orderSource = {
  beginDrag(props: PartiallyPlannedOrderBlockOwnProps) {
    return {
      fromDate: null,
      orderUrl: props.order.url,
    };
  },
  canDrag(props: PartiallyPlannedOrderBlockOwnProps) {
    return props.dndMode;
  },
};

const DragSourcePartiallyPlannedOrderBlock = DragSource(
  "order",
  orderSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(PartiallyPlannedOrderBlock);

export {DragSourcePartiallyPlannedOrderBlock as PartiallyPlannedOrderBlock};
