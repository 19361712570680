import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Order,
  OrderUrl,
} from "@co-common-libs/resources";
import {formatDateNumeric} from "@co-common-libs/utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {EntryData, GenericSingleSelectionSearchDialog} from "../search-dialog";

const messages = defineMessages({
  newOrder: {
    defaultMessage: "Opret ny ordre",
    id: "order.dialog.new-order",
  },
  search: {
    defaultMessage: "Søg ordre",
    id: "order.dialog.search-orders",
  },
});

function computeBaseChoices(
  orderArray: readonly Order[],
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  locationLookup: (url: LocationUrl) => Location | undefined,
  preferredCustomer?: CustomerUrl | null,
): readonly EntryData<OrderUrl>[] {
  const data = orderArray.map((instance) => {
    const customer = instance.customer ? customerLookup(instance.customer) : null;
    const location = instance.relatedWorkplace ? locationLookup(instance.relatedWorkplace) : null;

    const customerName = customer?.name || "";
    const plannedDate = formatDateNumeric(instance.date);
    const reference = instance.referenceNumber;
    const {notes} = instance;
    const workplaceString = location ? location.name || location.address : "";

    const entry: EntryData<OrderUrl> = {
      category:
        preferredCustomer && instance.customer === preferredCustomer ? "favorite" : "standard",
      identifier: instance.url,
      primaryText: [plannedDate, customerName, reference].filter(Boolean).join(" - "),
      searchFields: [
        {label: "Planlagt dato", priority: 20, text: plannedDate || ""},
        {label: "Kunde", priority: 15, text: customerName},
        {label: "Arbejdsområde", priority: 10, text: workplaceString},
        {label: "Ref. nr.", priority: 5, text: reference},
      ],
      secondaryText: [workplaceString, notes].filter(Boolean).join(" - "),
    };
    return entry;
  });
  return data;
}

interface OrderDialogProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  locationLookup: (url: LocationUrl) => Location | undefined;
  onCancel(): void;
  onNewOrder: () => void;
  onOk(url: OrderUrl | null): void;
  open: boolean;
  orderArray: readonly Order[];
  preferredCustomer?: CustomerUrl | null;
  skipOrder?: OrderUrl;
}

export function OrderDialog({
  customerLookup,
  locationLookup,
  onCancel,
  onNewOrder,
  onOk,
  open,
  orderArray,
  preferredCustomer,
  skipOrder,
}: OrderDialogProps): React.JSX.Element {
  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(
    orderArray.filter((order) => order.url !== skipOrder),
    customerLookup,
    locationLookup,
    preferredCustomer,
  );
  const {formatMessage} = useIntl();

  return (
    <GenericSingleSelectionSearchDialog<OrderUrl>
      addLabel={formatMessage(messages.newOrder)}
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={2}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={2}
      onAdd={onNewOrder}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={formatMessage(messages.search)}
      title={<FormattedMessage defaultMessage="Vælg ordre" id="order.dialog.select-order" />}
    />
  );
}
