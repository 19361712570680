import {CustomerUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {ConnectedDepartmentDialog} from "@co-frontend-libs/connected-components";
import {
  getExtendedCustomerSettings,
  getLocationArray,
  getMachineLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useMachine} from "@xstate/react/lib/fsm";
import React, {useCallback, useEffect} from "react";
import {useSelector} from "react-redux";
import {LocationFieldSelectionDisplay} from "../location-field-selection/location-field-selection-display";
import {locationFieldSelectionStateMachine} from "../location-field-selection/location-field-selection-state-machine";
import {MultiMachineSelectionDisplay} from "../multi-machine-selection/multi-machine-selection-display";
import {multiMachineSelectionStateMachine} from "../multi-machine-selection/multi-machine-selection-state-machine";
import {ProjectCustomerSelectionDisplay} from "../project-customer-selection/project-customer-selection-display";
import {projectCustomerSelectionStateMachine} from "../project-customer-selection/project-customer-selection-state-machine";
import {WorkTypeSelectionDisplay} from "../work-type-selection/work-type-selection-display";
import {workTypeSelectionStateMachine} from "../work-type-selection/work-type-selection-state-machine";
import {
  CustomerTaskCreationEvent,
  CustomerTaskCreationState,
} from "./customer-task-creation-state-machine";

interface CustomerTaskCreationDisplayProps {
  send: (event: CustomerTaskCreationEvent) => void;
  state: CustomerTaskCreationState;
}

export function CustomerTaskCreationDisplay(
  props: CustomerTaskCreationDisplayProps,
): React.JSX.Element {
  const {send, state} = props;

  const {
    locations: {canCreateWorkplace},
    taskWizardAskForWorkPlace,
    taskWizardFieldSelection,
  } = useSelector(getExtendedCustomerSettings);

  const workTypeLookup = useSelector(getWorkTypeLookup);
  const machineLookup = useSelector(getMachineLookup);

  const [projectCustomerSelectionState, projectCustomerSelectionSend] = useMachine(
    projectCustomerSelectionStateMachine,
    {
      actions: {
        signalCancelled: (_context, _event) => {
          send({type: "CANCEL"});
        },
        signalDone: (context, _event) => {
          const {contact, customer, location: relatedWorkplace, project} = context;
          console.assert(customer);
          const customerLocations = locationArray.filter(
            (location) => location.active && location.customer === customer,
          );
          const customerHasFields = customerLocations.some((location) => location.geojson);

          const customerHasWorkPlaces = customerLocations.some(
            (location) => !location.geojson && !location.logOnlyLocation,
          );

          const basePayload = {
            contact,
            customer: customer as CustomerUrl,
            customerHasFields,
            customerHasWorkPlaces,
            project,
          };

          const payload: CustomerTaskCreationEvent = relatedWorkplace
            ? {
                ...basePayload,
                location: relatedWorkplace,
                type: "CUSTOMER_PROJECT_LOCATION_SELECTED",
              }
            : {
                ...basePayload,
                type: "CUSTOMER_PROJECT_SELECTED",
              };

          send(payload);
        },
      },
    },
  );
  const [locationFieldSelectionState, locationFieldSelectionSend] = useMachine(
    locationFieldSelectionStateMachine,
    {
      actions: {
        signalCancelled: (_context, _event) => {
          send({type: "CANCEL"});
        },
        signalDone: (context, _event) => {
          const {fields, location} = context;
          send({fields, location, type: "LOCATION_FIELDS_SELECTED"});
        },
      },
    },
  );
  const [workTypeSelectionState, workTypeSelectionSend] = useMachine(
    workTypeSelectionStateMachine,
    {
      actions: {
        signalCancelled: (_context, _event) => {
          send({type: "CANCEL"});
        },
        signalDone: (context, _event) => {
          const {priceGroup, workType} = context;
          const department = workTypeLookup(workType as WorkTypeUrl)?.department || "";
          send({
            department,
            priceGroup,
            type: "WORK_TYPE_SELECTED",
            workType: workType as WorkTypeUrl,
          });
        },
      },
    },
  );
  const [multiMachineSelectionState, multiMachineSelectionSend] = useMachine(
    multiMachineSelectionStateMachine,
    {
      actions: {
        signalCancelled: (_context, _event) => {
          send({type: "CANCEL"});
        },
        signalDone: (context, _event) => {
          const {machines} = context;
          const observedDepartments = new Set(
            machines.map((m) => machineLookup(m.machine)?.department || "").filter((d) => d !== ""),
          );
          const department =
            observedDepartments.size === 1 ? Array.from(observedDepartments)[0] : "";
          send({
            department,
            machines,
            type: "MACHINES_SELECTED",
          });
        },
      },
    },
  );

  const locationArray = useSelector(getLocationArray);

  useEffect(() => {
    const {context, value} = state;
    if (value === "selectProjectOrCustomer") {
      const projectsEnabled = !!context.projectsEnabled;
      projectCustomerSelectionSend({
        customer: context.customer,
        projectsEnabled,
        type: "START",
      });
    } else if (value === "selectLocation") {
      const customerURL = context.customer as CustomerUrl;
      console.assert(customerURL);
      const customerHasFields = context.customerHasFields as boolean;
      console.assert(customerHasFields === true || customerHasFields === false);
      const customerHasWorkPlaces = context.customerHasWorkPlaces as boolean;
      console.assert(customerHasWorkPlaces === true || customerHasWorkPlaces === false);
      locationFieldSelectionSend({
        customer: customerURL,
        customerHasFields,
        customerHasWorkPlaces,
        fields: context.fields,
        locationCreationAllowed: canCreateWorkplace,
        taskWizardAskForWorkPlace,
        taskWizardFieldSelection,
        type: "START",
      });
    } else if (value === "selectWorkType") {
      const customerURL = context.customer as CustomerUrl;
      console.assert(customerURL);
      workTypeSelectionSend({customer: customerURL, type: "START"});
    } else if (value === "selectMachines") {
      const customerURL = context.customer as CustomerUrl;
      console.assert(customerURL);
      multiMachineSelectionSend({customer: customerURL, type: "START"});
    }
    // Intentionally only running on changes to state.value...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value]);

  const handleCancel = useCallback((): void => {
    send({type: "CANCEL"});
  }, [send]);

  const handleDepartmentSelected = useCallback(
    (department: string): void => {
      send({department, type: "DEPARTMENT_SELECTED"});
    },
    [send],
  );

  return (
    <>
      <ProjectCustomerSelectionDisplay
        send={projectCustomerSelectionSend}
        state={projectCustomerSelectionState}
      />
      <LocationFieldSelectionDisplay
        send={locationFieldSelectionSend}
        state={locationFieldSelectionState}
      />
      <WorkTypeSelectionDisplay send={workTypeSelectionSend} state={workTypeSelectionState} />
      <MultiMachineSelectionDisplay
        send={multiMachineSelectionSend}
        state={multiMachineSelectionState}
      />
      <ConnectedDepartmentDialog
        onCancel={handleCancel}
        onOk={handleDepartmentSelected}
        open={state.value === "selectDepartment"}
      />
    </>
  );
}
