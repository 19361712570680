import {OnlineTaskPhoto, TaskPhoto} from "@co-common-libs/resources";
import {formatDateShort} from "@co-common-libs/utils";
import {Checkbox, ImageListItem, ImageListItemBar} from "@material-ui/core";
import {bind} from "bind-decorator";
import React from "react";

interface PhotoTileProps {
  readonly checked: boolean;
  readonly customerName: string;
  readonly employeeAlias: string;
  readonly onCheck: (url: string, checked: boolean) => void;
  onImageClick: (photoInstance: TaskPhoto) => void;
  onTitleClick: (url: string) => void;
  photoInstance: TaskPhoto;
  shareToken: string;
  readonly taskDate: string;
}

export class PhotoTile extends React.PureComponent<PhotoTileProps> {
  @bind
  handleClick(): void {
    this.props.onCheck(this.props.photoInstance.url, !this.props.checked);
  }
  @bind
  handleImageClick(): void {
    this.props.onImageClick(this.props.photoInstance);
  }

  @bind
  handleTitleClick(): void {
    this.props.onTitleClick(this.props.photoInstance.task);
  }
  render(): React.JSX.Element {
    const {
      checked,
      customerName,
      employeeAlias,
      onCheck,
      onImageClick,
      onTitleClick,
      photoInstance,
      shareToken,
      taskDate,
      ...other
    } = this.props;
    // local-only photos will not appear as appendices
    const {note, taken, thumbnailDownload} = photoInstance as OnlineTaskPhoto;
    const takenDate = formatDateShort(taken);
    return (
      // pass on whatever props <GridList> provides to child components
      <ImageListItem cols={1} rows={1} {...other}>
        <img
          alt=""
          loading="lazy"
          onClick={this.handleImageClick}
          src={`${thumbnailDownload}?token=${shareToken}`}
          style={{cursor: "pointer"}}
        />
        <ImageListItemBar
          actionIcon={
            <Checkbox checked={checked} onClick={this.handleClick} style={{color: "#fff"}} />
          }
          actionPosition="right"
          position="bottom"
          subtitle={`${note}`}
          title={
            <span
              onClick={this.handleTitleClick}
              style={{cursor: "pointer", display: "inline-block"}}
            >
              {takenDate} {employeeAlias} {customerName}
            </span>
          }
        />
      </ImageListItem>
    );
  }
}
