import {Config} from "@co-common-libs/config";
import {Availability, Role, User, UserProfile, UserUrl} from "@co-common-libs/resources";
import {dateFromString, weekNumber} from "@co-common-libs/utils";
import {createSelector} from "reselect";
import {
  getAvailabilityArray,
  getCustomerSettings,
  getRoleArray,
  getUserArray,
  getUserProfileArray,
} from "../resources/selectors";
import {mapToLookupSelector} from "../resources/selectors/resources-helpers";
import {StateWithResources} from "../resources/types";
import {AppState} from "../root-reducer";
import {filterOnActive} from "./utils";

function buildPerUserMap<T extends {user: UserUrl}>(
  entries: readonly T[],
): ReadonlyMap<UserUrl, T> {
  const result = new Map<UserUrl, T>();
  for (let i = 0; i < entries.length; i += 1) {
    const entry = entries[i];
    const key = entry.user;
    result.set(key, entry);
  }
  return result;
}

const getUserUserProfileMap: (state: AppState) => ReadonlyMap<UserUrl, UserProfile> =
  createSelector(getUserProfileArray, buildPerUserMap);

export const getUserUserProfileLookup = mapToLookupSelector(getUserUserProfileMap);

const getUserRoleMap: (state: AppState) => ReadonlyMap<UserUrl, Role> = createSelector(
  getRoleArray,
  buildPerUserMap,
);

export const getUserRoleLookup = mapToLookupSelector(getUserRoleMap);

function findDefaultTaskEmployee(
  userArray: readonly Readonly<User>[],
  customerSettings: Config,
): User | null {
  const {defaultTaskEmployee: defaultTaskEmployeeId} = customerSettings;
  if (!defaultTaskEmployeeId) {
    return null;
  } else {
    return userArray.find((user) => user.id === defaultTaskEmployeeId) || null;
  }
}

export const getActiveUserArray: (state: StateWithResources) => readonly User[] = createSelector(
  getUserArray,
  filterOnActive,
);

export const getDefaultTaskEmployee: (state: AppState) => Readonly<User> | null = createSelector(
  getUserArray,
  getCustomerSettings,
  findDefaultTaskEmployee,
);
export const getDefaultTaskEmployeeUrl: (state: AppState) => UserUrl | null = createSelector(
  getDefaultTaskEmployee,
  (user) => (user ? user.url : null),
);

export function getDateEmployeeAvailabilityMap(
  dateString: string,
): (state: AppState) => ReadonlyMap<UserUrl, Availability> {
  return createSelector(getAvailabilityArray, (availabilityArray) => {
    const date = dateFromString(dateString);
    if (!date) {
      return new Map();
    }
    const weekData = weekNumber(date);
    const {week, year} = weekData;
    return new Map(
      availabilityArray
        .filter((availability) => availability.weekNumber === week && availability.year === year)
        .map((availability) => [availability.user, availability]),
    );
  });
}

export const getSelectableUsers: (state: AppState) => readonly User[] = createSelector(
  [getActiveUserArray, getUserRoleLookup],
  (users, getUserRole) => {
    return users.filter((user) => {
      const role = getUserRole(user.url);
      return !role || (!role.consultant && !role.breakRoom);
    });
  },
);
