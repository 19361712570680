import {
  CustomerUrl,
  PriceGroup,
  PriceGroupUrl,
  ReportingSpecificationUrl,
  Task,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  customerFilteredPriceGroups,
  getExpectedLogSpecification,
  logChangeLegal,
} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {
  computeBaseChoices,
  EntryData,
  GenericSingleSelectionSearchDialog,
} from "@co-frontend-libs/components";
import {
  getCurrentUserURL,
  getMachineLookup,
  getPriceGroupLookup,
  getReportingSpecificationLookup,
  getTaskArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useConditionalMemo} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {IntlShape, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {
  getDisabledWorkTypes,
  getFilteredActiveExternalTaskPrimaryWorkTypeArray,
  getSuggestRecentlyUsedWorkTypes,
} from "./selectors";
import {WorkTypeDialogProps} from "./types";

function computeLogLegalWorkTypes(
  workTypeArray: readonly WorkType[],
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  reportingSpecification: ReportingSpecificationUrl,
  reportingSpecificationRequired: boolean,
  customerUrl: CustomerUrl | null,
): Set<WorkTypeUrl> {
  function priceGroupHasSpecifiedLog(priceGroup: PriceGroup): boolean {
    return priceGroup.reportingSpecification === reportingSpecification;
  }

  function priceGroupHasSpecifiedOrNoLog(priceGroup: PriceGroup): boolean {
    return (
      priceGroup.reportingSpecification === null ||
      priceGroup.reportingSpecification === reportingSpecification
    );
  }

  const legalWorkTypes = new Set(
    workTypeArray
      .filter((workType): boolean => {
        const workTypePriceGroups = workType.pricegroups.map(priceGroupLookup).filter(notUndefined);
        const filteredWorkTypePriceGroups = customerFilteredPriceGroups(
          workTypePriceGroups,
          customerUrl,
        );
        if (workType.reportingSpecification === reportingSpecification) {
          // worktype specifies desired log; pricegroup must not override
          return (
            filteredWorkTypePriceGroups.length === 0 ||
            filteredWorkTypePriceGroups.some(priceGroupHasSpecifiedOrNoLog)
          );
        } else if (workType.reportingSpecification === null) {
          if (reportingSpecificationRequired) {
            // either worktype or pricegroup must specify desired log;
            // and worktype didn't
            return filteredWorkTypePriceGroups.some(priceGroupHasSpecifiedLog);
          } else {
            // machines specify desired log; just don't override
            return (
              filteredWorkTypePriceGroups.length === 0 ||
              filteredWorkTypePriceGroups.some(priceGroupHasSpecifiedOrNoLog)
            );
          }
        } else {
          // worktype specifies a *different* log; pricegroup must override
          return filteredWorkTypePriceGroups.some(priceGroupHasSpecifiedLog);
        }
      })
      .map(({url}) => url),
  );

  return legalWorkTypes;
}

function computeBaseChoicesWithSameLog(
  workTypeArray: readonly WorkType[],
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  taskArray: readonly Task[] | undefined,
  currentUserURL: UserUrl | null,
  disabledWorkTypes: ReadonlySet<WorkTypeUrl>,
  suggestRecentlyUsedWorkTypes: boolean,
  reportingSpecification: ReportingSpecificationUrl | undefined,
  reportingSpecificationRequired: boolean,
  customerUrl: CustomerUrl | null,
  intl: IntlShape,
): readonly EntryData<WorkTypeUrl>[] {
  const baseResult = computeBaseChoices(
    workTypeArray,
    taskArray,
    currentUserURL,
    disabledWorkTypes,
    suggestRecentlyUsedWorkTypes,
  );
  if (!reportingSpecification) {
    return baseResult;
  }

  const legalWorkTypes = computeLogLegalWorkTypes(
    workTypeArray,
    priceGroupLookup,
    reportingSpecification,
    reportingSpecificationRequired,
    customerUrl,
  );

  const result = baseResult.map((entry) => {
    if (legalWorkTypes.has(entry.identifier)) {
      return entry;
    } else {
      return {
        ...entry,
        disabled: true,
        secondaryText: intl.formatMessage(
          {
            defaultMessage: "{identifier}; kan ikke anvendes med aktuel log på opgaven",
          },
          {identifier: entry.secondaryText},
        ),
      };
    }
  });
  return result;
}

interface ConnectedLogLegalExternalWorkTypeDialogProps extends WorkTypeDialogProps {
  customerUrl: CustomerUrl | null;
  task: Task;
}

export const ConnectedLogLegalExternalWorkTypeDialog = React.memo(
  function ConnectedLogLegalExternalWorkTypeDialog(
    props: ConnectedLogLegalExternalWorkTypeDialogProps,
  ): React.JSX.Element {
    const {customerUrl, onCancel, onOk, open, task} = props;

    const priceGroupLookup = useSelector(getPriceGroupLookup);
    const workTypeLookup = useSelector(getWorkTypeLookup);
    const machineLookup = useSelector(getMachineLookup);
    const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);

    const {reportingSpecification, reportingSpecificationRequired} = useMemo(() => {
      if (task.reportingSpecification && !logChangeLegal(task)) {
        const logSpecificationFromMachines = getExpectedLogSpecification(
          {
            machineuseSet: task.machineuseSet,
            priceGroup: null,
            workType: null,
          },
          {
            machineLookup,
            priceGroupLookup,
            reportingSpecificationLookup,
            workTypeLookup,
          },
        );
        return {
          reportingSpecification: task.reportingSpecification,
          reportingSpecificationRequired:
            logSpecificationFromMachines?.url !== task.reportingSpecification,
        };
      } else {
        return {
          reportingSpecification: undefined,
          reportingSpecificationRequired: false,
        };
      }
    }, [machineLookup, priceGroupLookup, reportingSpecificationLookup, task, workTypeLookup]);

    const currentUserURL = useSelector(getCurrentUserURL);
    const disabledWorkTypes = useSelector(getDisabledWorkTypes);
    const suggestRecentlyUsedWorkTypes = useSelector(getSuggestRecentlyUsedWorkTypes);
    const taskArray = useSelector(getTaskArray);
    const activeWorkTypeArray = useSelector(getFilteredActiveExternalTaskPrimaryWorkTypeArray);

    const disabledWorkTypesSet = useMemo(
      () => new Set(disabledWorkTypes as readonly WorkTypeUrl[]),
      [disabledWorkTypes],
    );
    const intl = useIntl();
    const title = intl.formatMessage({defaultMessage: "Vælg arbejdsområde"});
    const searchTitle = intl.formatMessage({
      defaultMessage: "Søg arbejdsområde",
    });

    const data = useConditionalMemo(
      () =>
        computeBaseChoicesWithSameLog(
          activeWorkTypeArray,
          priceGroupLookup,
          taskArray,
          currentUserURL,
          disabledWorkTypesSet,
          suggestRecentlyUsedWorkTypes,
          reportingSpecification,
          reportingSpecificationRequired,
          customerUrl,
          intl,
        ),
      [
        activeWorkTypeArray,
        currentUserURL,
        customerUrl,
        disabledWorkTypesSet,
        intl,
        priceGroupLookup,
        reportingSpecification,
        reportingSpecificationRequired,
        suggestRecentlyUsedWorkTypes,
        taskArray,
      ],
      open,
      [],
    );

    return (
      <GenericSingleSelectionSearchDialog
        data={data}
        mobilePrimaryLines={1}
        mobileSearchPrimaryLines={1}
        mobileSearchSecondaryLines={1}
        mobileSecondaryLines={1}
        onCancel={onCancel}
        onOk={onOk}
        open={open}
        searchTitle={searchTitle}
        sorting="SECONDARY_IDENTIFIER"
        title={title}
      />
    );
  },
);
