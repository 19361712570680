import {Role, User, UserProfile} from "@co-common-libs/resources";
import {DateField} from "@co-frontend-libs/components";
import {getCustomerSettings, getSettingsArray, getTimerArray} from "@co-frontend-libs/redux";
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@material-ui/core";
import {useEventTargetCheckedUpdater, useFieldUpdater} from "app-utils";
import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

export const SetupCard = React.memo(function SetupCard({
  user,
  userProfile,
  userRoles,
}: {
  user: User;
  userProfile: UserProfile;
  userRoles: Role;
}): React.JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  const timerArray = useSelector(getTimerArray);
  const intl = useIntl();
  const userUrl = user.url;
  const userProfileUrl = userProfile.url;
  const userRolesUrl = userRoles.url;

  const handleAskAboutAvailabilityChange = useEventTargetCheckedUpdater<UserProfile>(
    userProfileUrl,
    "askAboutAvailability",
  );
  const handleShowDinnerBookingPopupChange = useEventTargetCheckedUpdater<UserProfile>(
    userProfileUrl,
    "showDinnerBookingPopup",
  );
  const handleShowLunchBookingPopupChange = useEventTargetCheckedUpdater<UserProfile>(
    userProfileUrl,
    "showLunchBookingPopup",
  );
  const handleRequireGPSToggleChange = useEventTargetCheckedUpdater<UserProfile>(
    userProfileUrl,
    "requireGps",
  );
  const handleCanReadFoodbookingTogleChange = useEventTargetCheckedUpdater<Role>(
    userRolesUrl,
    "canReadFoodBookings",
  );
  const settingsArray = useSelector(getSettingsArray);
  const settings = settingsArray[0];
  const dinnerBookingsActive = !!(settings && settings.dinnerBookingsActive);
  const lunchBookingsActive = !!(settings && settings.lunchBookingsActive);

  const timersWithnotifyUsersOnStart = [
    ...new Set(
      _.sortBy(timerArray.filter((t) => t.active && t.notifyUsersOnStart).map((t) => t.label)),
    ),
  ].join(", ");

  const handleBirthDateChange = useFieldUpdater<UserProfile, string | null>(
    userProfileUrl,
    "birthDate",
  );
  const handleEmploymentDateChange = useFieldUpdater<UserProfile, string | null>(
    userProfileUrl,
    "employmentDate",
  );

  return (
    <Card>
      <CardHeader title={intl.formatMessage({defaultMessage: "Opsætning"})} />
      <CardContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={user.active}
                onChange={useEventTargetCheckedUpdater<User>(userUrl, "active")}
              />
            }
            label={intl.formatMessage({defaultMessage: "Aktiv"})}
          />
          <FormControlLabel
            control={
              <Switch
                checked={userProfile.alwaysVisible}
                onChange={useEventTargetCheckedUpdater<UserProfile>(
                  userProfileUrl,
                  "alwaysVisible",
                )}
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Altid synlig på kalender",
            })}
          />
          <FormControlLabel
            control={
              <Switch
                checked={
                  userProfile.visibleOnAbsenceCalendar === undefined ||
                  userProfile.visibleOnAbsenceCalendar === true
                }
                onChange={useEventTargetCheckedUpdater<UserProfile>(
                  userProfileUrl,
                  "visibleOnAbsenceCalendar",
                )}
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Vises på fraværskalender",
            })}
          />
          {customerSettings.availabilityWeekdays || customerSettings.availabilityWeekends ? (
            <FormControlLabel
              control={
                <Switch
                  checked={
                    userProfile.askAboutAvailability == null || userProfile.askAboutAvailability
                  }
                  onChange={handleAskAboutAvailabilityChange}
                />
              }
              label={intl.formatMessage({defaultMessage: "Spørg om rådighed"})}
            />
          ) : null}

          {customerSettings.dinnerBookings && dinnerBookingsActive ? (
            <FormControlLabel
              control={
                <Switch
                  checked={userProfile.showDinnerBookingPopup}
                  onChange={handleShowDinnerBookingPopupChange}
                />
              }
              label={intl.formatMessage({
                defaultMessage: "Vis aftensmadsbestillingspåmindelse",
              })}
            />
          ) : null}
          {customerSettings.lunchBookings && lunchBookingsActive ? (
            <FormControlLabel
              control={
                <Switch
                  checked={userProfile.showLunchBookingPopup}
                  onChange={handleShowLunchBookingPopupChange}
                />
              }
              label={intl.formatMessage({
                defaultMessage: "Vis frokostbestillingspåmindelse",
              })}
            />
          ) : null}

          {customerSettings.dinnerBookings || customerSettings.lunchBookings ? (
            <FormControlLabel
              control={
                <Switch
                  checked={userRoles.manager || userRoles.canReadFoodBookings}
                  onChange={handleCanReadFoodbookingTogleChange}
                />
              }
              disabled={userRoles.manager}
              label={intl.formatMessage({
                defaultMessage: "Kan se madbestillingslisten",
              })}
            />
          ) : null}
          {customerSettings.geolocation.enabled ? (
            <FormControlLabel
              control={
                <Switch checked={userProfile.requireGps} onChange={handleRequireGPSToggleChange} />
              }
              label={intl.formatMessage({
                defaultMessage: "Kræv GPS",
              })}
            />
          ) : null}
          <FormControlLabel
            control={
              <Switch
                checked={userProfile.showNotePopupOnTask !== false}
                onChange={useEventTargetCheckedUpdater<UserProfile>(
                  userProfileUrl,
                  "showNotePopupOnTask",
                )}
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Vis notepopup på opgaver",
            })}
          />
          <FormControlLabel
            control={
              <Switch
                checked={!!userProfile.receiveTimerStartNotifications}
                onChange={useEventTargetCheckedUpdater<UserProfile>(
                  userProfileUrl,
                  "receiveTimerStartNotifications",
                )}
              />
            }
            label={
              timersWithnotifyUsersOnStart
                ? intl.formatMessage(
                    {
                      defaultMessage:
                        "Modtager notifikation ved start af tidsregistrering for: {timers}",
                    },
                    {
                      timers: timersWithnotifyUsersOnStart,
                    },
                  )
                : intl.formatMessage({
                    defaultMessage:
                      "Modtager notifikation ved start af tidsregistrering for: Ingen tidsknapper sender notifikationer",
                  })
            }
          />
        </FormGroup>
        {customerSettings.anniversariesEnabled ? (
          <>
            <DateField
              autoOk
              fullWidth
              label={intl.formatMessage({defaultMessage: "Fødselsdato"})}
              margin="dense"
              onChange={handleBirthDateChange}
              value={userProfile.birthDate}
            />
            <DateField
              autoOk
              fullWidth
              label={intl.formatMessage({defaultMessage: "Ansættelsesdato"})}
              margin="dense"
              onChange={handleEmploymentDateChange}
              value={userProfile.employmentDate}
            />
          </>
        ) : null}
      </CardContent>
    </Card>
  );
});
