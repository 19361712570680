import {
  dateFromString,
  dateToString,
  DAY_MILLISECONDS,
  DAY_MINUTES,
  DayTypeHoliday,
  FOUR_WEEK_DAYS,
  midnightFromDateString,
  MINUTE_MILLISECONDS,
  objectMerge,
  objectSet,
  TWO_WEEK_DAYS,
  WEEK_DAYS,
  WEEKDAY_SUNDAY,
  WeekdayNumberType,
} from "@co-common-libs/utils";
import {computeIntervalDurationMilliseconds} from "./duration";
import {normalMinutesFromHoursRates} from "./hours-rates";
import {
  HolidayCheckFunction,
  HoursRatesFunction,
  MAX_OVERTIME_RATE,
  OvertimeThresholdsFunction,
  PoolPeriodStartFunction,
  PoolPeriodThresholdsFunction,
  Rate,
  RemunerationGroup,
  TaskIntervalWithRate,
  WorkDay,
} from "./types";

function poolThresholds(
  fromRate: Rate,
  poolWorkDays: readonly WorkDay[],
  overtimeThreshold: number,
): WorkDay[] {
  const nextRate = (fromRate + 1) as Rate;
  const result: WorkDay[] = [];
  let remainingMilliseconds = overtimeThreshold * MINUTE_MILLISECONDS;
  poolWorkDays.forEach((workDay) => {
    let {extraRateMinutes} = workDay;
    const extraFromRateMinutes = extraRateMinutes.get(fromRate);
    if (extraFromRateMinutes) {
      const extraFromRateMilliseconds = extraFromRateMinutes * MINUTE_MILLISECONDS;
      if (extraFromRateMilliseconds > remainingMilliseconds) {
        const updatedExtraRateMinutes = new Map(extraRateMinutes);
        updatedExtraRateMinutes.set(fromRate, remainingMilliseconds / MINUTE_MILLISECONDS);
        updatedExtraRateMinutes.set(
          nextRate,
          (updatedExtraRateMinutes.get(nextRate) || 0) +
            (extraFromRateMilliseconds - remainingMilliseconds) / MINUTE_MILLISECONDS,
        );
        extraRateMinutes = updatedExtraRateMinutes;
        remainingMilliseconds = 0;
      } else {
        remainingMilliseconds -= extraFromRateMilliseconds;
      }
    }
    console.assert(remainingMilliseconds >= 0);
    const workPeriods = workDay.workPeriods.map((workPeriod) => {
      const work: TaskIntervalWithRate[] = [];
      const inputIntervals = workPeriod.work;
      for (let i = 0; i < inputIntervals.length; i += 1) {
        const interval = inputIntervals[i];
        if (interval.rate !== fromRate) {
          work.push(interval);
        } else if (!remainingMilliseconds) {
          work.push(objectSet(interval, "rate", nextRate));
        } else {
          const intervalDuration = computeIntervalDurationMilliseconds(interval);
          if (intervalDuration <= remainingMilliseconds) {
            console.assert(interval.rate === fromRate);
            work.push(interval);
            remainingMilliseconds -= intervalDuration;
          } else {
            const splitDate = new Date(interval.fromTimestamp);
            splitDate.setUTCMilliseconds(splitDate.getUTCMilliseconds() + remainingMilliseconds);
            const splitTimestamp = splitDate.toISOString();
            console.assert(interval.rate === fromRate);
            work.push(objectSet(interval, "toTimestamp", splitTimestamp));
            work.push(
              objectMerge(interval, {
                fromTimestamp: splitTimestamp,
                rate: nextRate,
              }),
            );
            remainingMilliseconds = 0;
          }
        }
      }
      return objectSet(workPeriod, "work", work);
    });
    result.push({
      date: workDay.date,
      extraRateMinutes,
      workPeriods,
    });
  });
  return result;
}

type PoolGroupedWorkDays = {fromDate: Date; toDate: Date; workDays: WorkDay[]};

function groupForPools(
  workDays: readonly WorkDay[],
  getPoolPeriodStart: (date: Date) => Date,
  getPoolPeriodEnd: (poolPeriodStart: Date) => Date,
): PoolGroupedWorkDays[] {
  const groupMap = new Map<number, PoolGroupedWorkDays>();
  workDays.forEach((workDay) => {
    const fromDate = getPoolPeriodStart(dateFromString(workDay.date) as Date);
    const timestamp = fromDate.valueOf();
    const group = groupMap.get(timestamp);
    if (group) {
      group.workDays.push(workDay);
    } else {
      const toDate = getPoolPeriodEnd(fromDate);
      groupMap.set(timestamp, {
        fromDate,
        toDate,
        workDays: [workDay],
      });
    }
  });

  // ES6 Map (unlike normal JS objects)
  // is specified to provide elements in insertion order.
  const result = Array.from(groupMap.values());
  return result;
}

export function setPoolsOvertime(
  fromRate: Rate,
  workDays: readonly WorkDay[],
  getPoolPeriodStart: (date: Date) => Date,
  getPoolPeriodEnd: (poolPeriodStart: Date) => Date,
  getPoolPeriodThresholds: (fromDate: Date, toDate: Date) => readonly number[],
): WorkDay[] {
  const grouped = groupForPools(workDays, getPoolPeriodStart, getPoolPeriodEnd);
  const groupedWithOvertime = grouped.map((groupedWorkDays) => {
    const thresholds = getPoolPeriodThresholds(groupedWorkDays.fromDate, groupedWorkDays.toDate);
    const threshold = thresholds[fromRate];
    if (threshold != null) {
      return poolThresholds(fromRate, groupedWorkDays.workDays, threshold);
    } else {
      return groupedWorkDays.workDays;
    }
  });
  const empty: WorkDay[] = [];
  const result = empty.concat(...groupedWithOvertime);
  return result;
}

export function getWeekPoolPeriodStartFunction(weekStart: number): PoolPeriodStartFunction {
  const weekPoolPeriodStartFunction = (inputDate: Date): Date => {
    console.assert(inputDate instanceof Date);
    const date = new Date(inputDate);
    date.setHours(0, 0, 0, 0);
    const fromDateSubtract = (date.getDay() - weekStart + WEEK_DAYS) % WEEK_DAYS;
    date.setDate(date.getDate() - fromDateSubtract);
    return date;
  };
  return weekPoolPeriodStartFunction;
}

export function weekPoolPeriodEndFunction(fromDate: Date): Date {
  const toDate = new Date(fromDate);
  toDate.setDate(toDate.getDate() + WEEK_DAYS);
  return toDate;
}

export function getMonthPoolPeriodStartFunction(periodStart: number): PoolPeriodStartFunction {
  const monthPoolPeriodStartFunction = (inputDate: Date): Date => {
    const date = new Date(inputDate);
    date.setHours(0, 0, 0, 0);
    if (date.getDate() >= periodStart) {
      date.setDate(periodStart);
    } else {
      date.setMonth(date.getMonth() - 1, periodStart);
    }
    return date;
  };
  return monthPoolPeriodStartFunction;
}

export function monthPoolPeriodEndFunction(fromDate: Date): Date {
  const toDate = new Date(fromDate);
  toDate.setMonth(toDate.getMonth() + 1);
  return toDate;
}

export function getTwoWeekPoolPeriodStartFunction(startDate: string): PoolPeriodStartFunction {
  const startDateDate = midnightFromDateString(startDate);
  const twoWeekPoolPeriodStartFunction = (inputDate: Date): Date => {
    const date = new Date(inputDate);
    date.setHours(0, 0, 0, 0);
    const millisecondsPastStart = date.valueOf() - startDateDate.valueOf();
    const daysPastStart = Math.round(millisecondsPastStart / DAY_MILLISECONDS);
    let daysPastPeriodStart = daysPastStart % TWO_WEEK_DAYS;
    if (daysPastPeriodStart < 0) {
      daysPastPeriodStart += TWO_WEEK_DAYS;
    }
    date.setDate(date.getDate() - daysPastPeriodStart);
    return date;
  };
  return twoWeekPoolPeriodStartFunction;
}

export function twoWeekPoolPeriodEndFunction(fromDate: Date): Date {
  const toDate = new Date(fromDate);
  toDate.setDate(toDate.getDate() + TWO_WEEK_DAYS);
  return toDate;
}

export function getFourWeekPoolPeriodStartFunction(startDate: string): PoolPeriodStartFunction {
  const startDateDate = midnightFromDateString(startDate);
  const fourWeekPoolPeriodStartFunction = (inputDate: Date): Date => {
    const date = new Date(inputDate);
    date.setHours(0, 0, 0, 0);
    const millisecondsPastStart = date.valueOf() - startDateDate.valueOf();
    const daysPastStart = Math.round(millisecondsPastStart / DAY_MILLISECONDS);
    let daysPastPeriodStart = daysPastStart % FOUR_WEEK_DAYS;
    if (daysPastPeriodStart < 0) {
      daysPastPeriodStart += FOUR_WEEK_DAYS;
    }
    date.setDate(date.getDate() - daysPastPeriodStart);
    return date;
  };
  return fourWeekPoolPeriodStartFunction;
}

export function fourWeekPoolPeriodEndFunction(fromDate: Date): Date {
  const toDate = new Date(fromDate);
  toDate.setDate(toDate.getDate() + FOUR_WEEK_DAYS);
  return toDate;
}

export function getPoolPeriodConstantsThresholdsFunction(
  values: readonly number[],
): PoolPeriodThresholdsFunction {
  const poolPeriodConstantThresholdsFunction = (
    _fromDate: Date,
    _toDate: Date,
  ): readonly number[] => values;
  return poolPeriodConstantThresholdsFunction;
}

export function getPoolPeriodOvertimeThresholdsFunction(
  getDateOvertimeThreshold: OvertimeThresholdsFunction,
): PoolPeriodThresholdsFunction {
  const poolPeriodOvertimeThresholdsFunction = (
    fromDate: Date,
    toDate: Date,
  ): readonly number[] => {
    const result: number[] = [];
    const date = new Date(fromDate);
    while (date.valueOf() < toDate.valueOf()) {
      const x = getDateOvertimeThreshold(date);
      for (let r = Rate.NORMAL; r <= MAX_OVERTIME_RATE; r += 1) {
        const rateValue = x[r];
        if (rateValue != null) {
          if (typeof rateValue === "number") {
            result[r] = (result[r] || 0) + rateValue;
          } else {
            const [value /* target */] = rateValue;
            result[r] = (result[r] || 0) + value;
          }
        }
      }
      date.setDate(date.getDate() + 1);
    }
    return result;
  };
  return poolPeriodOvertimeThresholdsFunction;
}

export function dayPotentialNormalHoursMinutes(
  getDateHoursRates: HoursRatesFunction,
  getDateOvertimeThresholds: OvertimeThresholdsFunction,
  date: Date,
): number {
  const overtimeThreshold = getDateOvertimeThresholds(date)[Rate.NORMAL];
  const overtimeThresholdMinutes =
    overtimeThreshold != null
      ? typeof overtimeThreshold === "number"
        ? overtimeThreshold
        : overtimeThreshold[0]
      : DAY_MINUTES;
  const hoursRates = getDateHoursRates(date);
  const hoursNormalMinutes = normalMinutesFromHoursRates(hoursRates);
  const dayMinutes = Math.min(overtimeThresholdMinutes, hoursNormalMinutes);
  return dayMinutes;
}

export function getPoolDateAbsenceCompensatoryNormalHoursMinutesFunction(
  checkHoliday: HolidayCheckFunction,
  getDateHoursRates: HoursRatesFunction,
  getDateOvertimeThresholds: OvertimeThresholdsFunction,
  remunerationGroup: RemunerationGroup,
): (date: Date) => number {
  if (
    remunerationGroup.pools?.type !== "normalisedWeek" &&
    remunerationGroup.pools?.weekAbsenceCompensatoryNormalHoursMinutes != null
  ) {
    const {weekAbsenceCompensatoryNormalHoursMinutes} = remunerationGroup.pools;
    return (date: Date): number => {
      const dateString = dateToString(date);
      const dayType = checkHoliday(dateString);
      const weekDay = date.getDay() as WeekdayNumberType;

      if (dayType === DayTypeHoliday.HOLIDAY || dayType === DayTypeHoliday.VALID_ABSENCE) {
        return weekAbsenceCompensatoryNormalHoursMinutes[WEEKDAY_SUNDAY];
      } else if (dayType === DayTypeHoliday.HALF_HOLIDAY) {
        const {halfHolidayHalfThresholds} = remunerationGroup;
        const dayNormalHoursMinutes = weekAbsenceCompensatoryNormalHoursMinutes[weekDay];
        if (dayNormalHoursMinutes && halfHolidayHalfThresholds) {
          return dayNormalHoursMinutes * 0.5;
        } else {
          const dayHoursRates = getDateHoursRates(date);
          return normalMinutesFromHoursRates(dayHoursRates);
        }
      } else {
        console.assert(dayType === DayTypeHoliday.NORMAL);
        return weekAbsenceCompensatoryNormalHoursMinutes[weekDay];
      }
    };
  } else {
    return dayPotentialNormalHoursMinutes.bind(null, getDateHoursRates, getDateOvertimeThresholds);
  }
}
