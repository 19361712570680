import {Checkbox} from "@material-ui/core";
import React, {useCallback} from "react";

export function UpdateExistingRowCheckbox(props: {
  columnName: string;
  disabled: boolean;
  isChecked: boolean;
  onImportColumnConfigurationChange: (
    columnName: string,
    configurationChange: {updateExisting: boolean},
  ) => void;
}): React.JSX.Element {
  const {columnName, disabled, isChecked, onImportColumnConfigurationChange} = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      onImportColumnConfigurationChange(columnName, {
        updateExisting: checked,
      });
    },
    [columnName, onImportColumnConfigurationChange],
  );

  return <Checkbox checked={isChecked} disabled={disabled} onChange={handleChange} />;
}
