import {WorkType} from "@co-common-libs/resources";
import {HOUR_MINUTES} from "@co-common-libs/utils";
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import {defineMessages, FormattedMessage, FormattedNumber, IntlContext} from "react-intl";

const messages = defineMessages({
  worTypeMissing: {
    defaultMessage: "(Område mangler)",
    id: "reports.label.work-type-missing",
  },
});

/*
{
  "AJ": [
    [
      "group_1",
      0,
      "Værksted",
      187
    ],
    [
      "group_1",
      1,
      "Overtid",
      19
    ]
  ]
}
*/

interface WorkTypeDisplayTableRowProps {
  bonusLabels: readonly string[];
  bonusValues: ReadonlyMap<string, number>;
  groupLabel: string;
  initials: string;
  rates: number;
  rateValues: ReadonlyMap<number, number>;
}

function WorkTypeDisplayTableRow(props: WorkTypeDisplayTableRowProps): JSX.Element {
  const {bonusLabels, bonusValues, groupLabel, initials, rates, rateValues} = props;
  const rateColumns: JSX.Element[] = [];
  for (let i = 0; i < rates; i += 1) {
    const value = rateValues.get(i) || 0;
    rateColumns.push(
      <TableCell key={`${i}`} style={{textAlign: "right"}}>
        <FormattedNumber
          maximumFractionDigits={2}
          minimumFractionDigits={2}
          value={value / HOUR_MINUTES}
        />
      </TableCell>,
    );
  }
  const bonusColumns = bonusLabels.map((label) => {
    const value = bonusValues.get(label) || 0;
    return (
      <TableCell key={label} style={{textAlign: "right"}}>
        <FormattedNumber
          maximumFractionDigits={2}
          minimumFractionDigits={2}
          value={value / HOUR_MINUTES}
        />
      </TableCell>
    );
  });
  return (
    <TableRow>
      <TableCell>{initials}</TableCell>
      <TableCell>{groupLabel}</TableCell>
      {rateColumns}
      {bonusColumns}
    </TableRow>
  );
}

interface WorkTypeDisplayTableProps {
  bonusLabels: readonly string[];
  data: {
    readonly [initials: string]: readonly (readonly [string, number, string, number])[];
  };
  groupLabels: ReadonlyMap<string, string>;
  rateLabels: readonly string[];
}

function WorkTypeDisplayTable(props: WorkTypeDisplayTableProps): JSX.Element {
  const {bonusLabels, data, groupLabels, rateLabels} = props;
  const grouped = new Map<
    string,
    {
      bonusValues: Map<string, number>;
      group: string;
      initials: string;
      rateValues: Map<number, number>;
    }
  >();
  const bonusSumValues = new Map<string, number>();
  const rateSumValues = new Map<number, number>();
  Object.entries(data).forEach(([initials, employeeData]) => {
    employeeData.forEach(([group, rate, bonus, minutes]) => {
      const id = JSON.stringify([initials, group]);
      const existingGroupEntry = grouped.get(id);
      const groupEntry = existingGroupEntry || {
        bonusValues: new Map<string, number>(),
        group,
        initials,
        rateValues: new Map<number, number>(),
      };
      if (!existingGroupEntry) {
        grouped.set(id, groupEntry);
      }
      const {bonusValues, rateValues} = groupEntry;
      rateValues.set(rate, (rateValues.get(rate) || 0) + minutes);
      rateSumValues.set(rate, (rateSumValues.get(rate) || 0) + minutes);
      if (bonus) {
        bonus.split(":::").forEach((bonusLabel) => {
          console.assert(bonusLabels.includes(bonusLabel));
          bonusValues.set(bonusLabel, (bonusValues.get(bonusLabel) || 0) + minutes);
          bonusSumValues.set(bonusLabel, (bonusSumValues.get(bonusLabel) || 0) + minutes);
        });
      }
    });
  });
  const withGroupLabels = Array.from(grouped.values()).map((entry) => ({
    ...entry,
    groupLabel: groupLabels.get(entry.group) || entry.group,
  }));
  const sorted = _.sortBy(withGroupLabels, [
    (entry) => entry.initials,
    (entry) => entry.groupLabel,
    (entry) => entry.group,
  ]);
  const rows = sorted.map(({bonusValues, groupLabel, initials, rateValues}, index) => {
    return (
      <WorkTypeDisplayTableRow
        bonusLabels={bonusLabels}
        bonusValues={bonusValues}
        groupLabel={groupLabel}
        initials={initials}
        key={`${index}`}
        rates={rateLabels.length}
        rateValues={rateValues}
      />
    );
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage defaultMessage="Medarbejder" id="reports.table-header.employee" />
          </TableCell>
          <TableCell>
            <FormattedMessage
              defaultMessage="Løngruppe"
              id="reports.table-header.remuneration-group"
            />
          </TableCell>
          {rateLabels.map((label, index) => (
            <TableCell key={`${index}`} style={{textAlign: "right"}}>
              {label}
            </TableCell>
          ))}
          {bonusLabels.map((label) => (
            <TableCell key={label} style={{textAlign: "right"}}>
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
      <TableFooter>
        <TableRow style={{fontWeight: "bold"}}>
          <TableCell colSpan={2}>
            <FormattedMessage defaultMessage="Tilsammen" id="reports.table-header.total" />
          </TableCell>
          {rateLabels.map((_label, i) => {
            const value = rateSumValues.get(i) || 0;
            return (
              <TableCell key={`${i}`} style={{fontWeight: "bold", textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={value / HOUR_MINUTES}
                />
              </TableCell>
            );
          })}
          {bonusLabels.map((label) => {
            const value = bonusSumValues.get(label) || 0;
            return (
              <TableCell key={label} style={{fontWeight: "bold", textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={value / HOUR_MINUTES}
                />
              </TableCell>
            );
          })}
        </TableRow>
      </TableFooter>
    </Table>
  );
}

interface WorkTypeDisplayCardProps {
  bonusLabels: readonly string[];
  data: {
    readonly [initials: string]: readonly (readonly [string, number, string, number])[];
  };
  groupLabels: ReadonlyMap<string, string>;
  rateLabels: readonly string[];
  workTypes: WorkType[];
}

export class WorkTypeDisplayCard extends React.PureComponent<WorkTypeDisplayCardProps, object> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {bonusLabels, data, groupLabels, rateLabels, workTypes} = this.props;
    return (
      <Card style={{marginTop: "1em"}}>
        <CardHeader
          title={
            workTypes.length
              ? workTypes.map((workType) => workType.name).join("/")
              : formatMessage(messages.worTypeMissing)
          }
        />
        <WorkTypeDisplayTable
          bonusLabels={bonusLabels}
          data={data}
          groupLabels={groupLabels}
          rateLabels={rateLabels}
        />
      </Card>
    );
  }
}
