import {type BookkeepingArchiveData, dateToString, WEEK_DAYS} from "@co-common-libs/utils";
import {DateField, TrimTextField} from "@co-frontend-libs/components";
import {NetworkError, StatusError} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import {useFetchGet, useNullableQueryParameterState, useQueryParameterState} from "app-utils";
import bowser from "bowser";
import clsx from "clsx";
import {subDays} from "date-fns";
import {resourceURL} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {BookkeepingArchiveTable} from "./bookkeeping-archive-table";

const useStyles = makeStyles((_theme: Theme) => {
  return createStyles({
    mobilePadding: {padding: "1em 11px 1em 11px"},
    pcPadding: {padding: "1em"},
  });
});

const mobile = bowser.tablet || bowser.mobile;

export function BookkeepingArchive({onDataLoaded}: {onDataLoaded: () => void}): React.JSX.Element {
  const classes = useStyles();

  const intl = useIntl();

  const now = new Date();
  const oneWeekAgo = subDays(now, WEEK_DAYS);

  const [fromDate, setFromDate] = useNullableQueryParameterState(
    "archiveFromDate",
    dateToString(oneWeekAgo),
  );
  const [toDate, setToDate] = useNullableQueryParameterState("archiveToDate", dateToString(now));
  const [textSearch, setTextSearch] = useQueryParameterState<string>("archiveSearch", "");

  const [fetchData, fetchDataCancel, data, fetching, fetchError] =
    useFetchGet<BookkeepingArchiveData[]>();

  useEffect(() => {
    if (data) {
      onDataLoaded();
    }
  }, [data, onDataLoaded]);

  useEffect(() => {
    fetchDataCancel();
  }, [fromDate, toDate, textSearch, fetchDataCancel]);

  const handleFetchClick = useCallback(() => {
    if (fromDate && toDate && fromDate <= toDate) {
      const taskListURL = resourceURL("task");
      let taskArchiveSearchURL = `${taskListURL}bookkeeping_archive_search/?archiveFromDate=${fromDate}&archiveToDate=${toDate}`;
      if (textSearch) {
        taskArchiveSearchURL += `&bookkeepingSearch=${encodeURIComponent(textSearch)}`;
      }
      fetchData(taskArchiveSearchURL);
    }
  }, [fetchData, fromDate, textSearch, toDate]);

  const handleSubmitOnEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleFetchClick();
      }
    },
    [handleFetchClick],
  );

  useEffect(() => {
    handleFetchClick();
    // only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFromDateBlur = useCallback(() => {
    if (fromDate && (!toDate || fromDate > toDate)) {
      setToDate(fromDate);
    }
  }, [fromDate, setToDate, toDate]);

  const handlePeriodStartSelectedInDialog = useCallback(
    (date: string | null) => {
      if (date) {
        if (!toDate || date > toDate) {
          setFromDate(date);
          setToDate(date);
        } else {
          setFromDate(date);
        }
      }
    },
    [setFromDate, setToDate, toDate],
  );

  const theme = useTheme();

  let errorText: string | undefined;

  if (fetchError) {
    if (fetchError instanceof NetworkError) {
      errorText = intl.formatMessage({
        defaultMessage: "Kunne ikke kontakte server",
      });
    } else if (fetchError instanceof StatusError) {
      errorText = intl.formatMessage({defaultMessage: "Serverfejl"});
    } else {
      errorText = intl.formatMessage({defaultMessage: "Ukendt fejl"});
    }
  }

  const filteringData = useMemo(
    () => ({fromDate, textSearch, toDate}),
    [fromDate, textSearch, toDate],
  );

  return (
    <div
      className={clsx({
        [classes.mobilePadding]: mobile,
        [classes.pcPadding]: !mobile,
      })}
    >
      <Card>
        <CardContent>
          <FormattedMessage defaultMessage="Periode" />
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <DateField
                autoFocus={!bowser.ios}
                autoOk
                fullWidth
                label={intl.formatMessage({defaultMessage: "Fra"})}
                margin="dense"
                onBlur={handleFromDateBlur}
                onChange={setFromDate}
                onKeyDown={handleSubmitOnEnter}
                onSelectedInDialog={handlePeriodStartSelectedInDialog}
                referenceDate={toDate || undefined}
                value={fromDate}
                yearSuggestions="DATE_BEFORE"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                label={intl.formatMessage({defaultMessage: "Til"})}
                margin="dense"
                minDate={fromDate || undefined}
                onChange={setToDate}
                onKeyDown={handleSubmitOnEnter}
                referenceDate={fromDate || undefined}
                value={toDate}
                yearSuggestions={fromDate ? "DATE_AFTER" : "DATE_BEFORE"}
              />
            </Grid>
            <Grid item xs={12}>
              <TrimTextField
                fullWidth
                label={intl.formatMessage({defaultMessage: "Fritekst"})}
                margin="dense"
                onChange={setTextSearch}
                onKeyDown={handleSubmitOnEnter}
                value={textSearch}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disabled={!fromDate || !toDate || fromDate > toDate || fetching}
            onClick={handleFetchClick}
            variant="contained"
          >
            <FormattedMessage defaultMessage="Søg" />
          </Button>
        </CardActions>
      </Card>
      {fetching ? (
        <div style={{marginTop: "1em", textAlign: "center"}}>
          <CircularProgress />
        </div>
      ) : null}
      {data ? (
        <div style={{marginTop: "1em"}}>
          <BookkeepingArchiveTable data={data} filteringData={filteringData} />
        </div>
      ) : null}
      {errorText ? (
        <Card style={{marginTop: "1em"}}>
          <CardContent>
            <div style={{color: theme.palette.error.main}}>{errorText}</div>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
}
