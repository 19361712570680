import {SettingID} from "@co-common-libs/config";

/* 
  These settings are configured elsewhere in the UI, and will eventually be moved away 
  from the settings completely. Until then we hide them on the settings page by listing them here
*/
export const hiddenSettings = new Set<SettingID>([
  "departmentExtraTimers",
  "machineAnalysisAccounts",
  "machineExtraTimers",
  "machinePriceGroupExtraTimers",
  "workTypeExtraTimers",
  "workTypeMachineExtraTimers",
  "workTypePriceGroupExtraTimers",
]);
