import {minuteTruncatedTimestamp} from "@co-common-libs/payroll";
import {
  ComputedTime,
  ResourceTypeUnion,
  Task,
  TaskUrl,
  TimeCorrection,
  Timer,
  TimerStart,
  TimerUrl,
  UserUrl,
} from "@co-common-libs/resources";
import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import {v4 as uuid} from "uuid";

const NORMAL_TIMESTAMP_LENGTH = 24;
console.assert(NORMAL_TIMESTAMP_LENGTH === new Date().toISOString().length);

const THIS_DEVICE_UUID = (window as any).device ? ((window as any).device.uuid as string) : "";

export const saveTimerStart = (
  create: (instance: ResourceTypeUnion) => void,
  timerURL: TimerUrl | null,
  taskURL: TaskUrl,
  userURL: UserUrl,
  timestamp: string | null,
  registerTimerStartPosition?: (timerStart: TimerStart) => void,
): void => {
  console.assert(timerURL || timerURL === null);
  console.assert(taskURL);
  console.assert(userURL);
  const id = uuid();
  const url = instanceURL("timerStart", id);
  const task = taskURL;
  const employee = userURL;
  const deviceTimestamp = timestamp || new Date().toISOString();
  const newTimerStart: TimerStart = {
    deviceTimestamp,
    deviceUuid: THIS_DEVICE_UUID,
    employee,
    id,
    task,
    timer: timerURL,
    url,
  };
  window.setTimeout(() => {
    create(newTimerStart);
  });

  if (registerTimerStartPosition) {
    window.setTimeout(() => {
      registerTimerStartPosition(newTimerStart);
    });
  }
};

function combineSameTimerSequence<
  T extends {
    readonly fromTimestamp: string;
    readonly task?: TaskUrl;
    readonly timer: TimerUrl;
    readonly toTimestamp: string;
  },
>(intervals: T[]): T[] {
  if (!intervals.length) {
    return [];
  }
  const sequenceArray: T[][] = [];
  let sequence = [intervals[0]];
  // intentionally skipping index 0
  for (let i = 1; i < intervals.length; i += 1) {
    const entry = intervals[i];
    const sequenceLast = sequence[sequence.length - 1];
    if (
      entry.timer === sequenceLast.timer &&
      entry.task === sequenceLast.task &&
      entry.fromTimestamp === sequenceLast.toTimestamp
    ) {
      sequence.push(entry);
    } else {
      sequenceArray.push(sequence);
      sequence = [entry];
    }
  }
  sequenceArray.push(sequence);
  return sequenceArray.map((s) => ({
    ...s[0],
    toTimestamp: s[s.length - 1].toTimestamp,
  }));
}

// TODO: remove/replace with @co-backend-libs/utils/timer-intervals
const computeIntervalsNormalisedInput = <
  T extends {
    readonly deviceTimestamp: string;
    readonly task?: TaskUrl;
    readonly timer: TimerUrl | null;
  },
>(
  inputTimerStarts: readonly T[],
  nowEnd: string,
): {
  readonly fromTimestamp: string;
  readonly task?: TaskUrl;
  readonly timer: TimerUrl;
  readonly toTimestamp: string;
}[] => {
  // Construct (subset of) ComputedTime model format:
  // fromTimestamp, toTimestamp, task, timer
  const indexWithTimer = inputTimerStarts.findIndex((t) => t.timer);
  if (indexWithTimer === -1) {
    return [];
  }
  const timerStarts = inputTimerStarts.slice(indexWithTimer);
  console.assert(timerStarts.length);
  const result: {
    readonly fromTimestamp: string;
    readonly task?: TaskUrl;
    readonly timer: TimerUrl;
    readonly toTimestamp: string;
  }[] = [];
  const firstTimerStart = timerStarts[0];
  let lastTimer = firstTimerStart.timer;
  let lastTask = firstTimerStart.task;
  let lastDeviceTimestamp = firstTimerStart.deviceTimestamp;
  timerStarts.slice(1).forEach((timerStart) => {
    const {timer} = timerStart;
    const {task} = timerStart;
    const {deviceTimestamp} = timerStart;
    if (timer !== lastTimer || task !== lastTask) {
      if (lastTimer) {
        const difference =
          new Date(deviceTimestamp).valueOf() - new Date(lastDeviceTimestamp).valueOf();
        const positiveDifference = Math.round(difference / MINUTE_MILLISECONDS) > 0;
        if (positiveDifference) {
          if (lastTask) {
            result.push({
              fromTimestamp: lastDeviceTimestamp,
              task: lastTask,
              timer: lastTimer,
              toTimestamp: deviceTimestamp,
            });
          } else {
            result.push({
              fromTimestamp: lastDeviceTimestamp,
              timer: lastTimer,
              toTimestamp: deviceTimestamp,
            });
          }
        }
      }
      lastTimer = timer;
      lastTask = task;
      lastDeviceTimestamp = deviceTimestamp;
    }
  });
  if (lastTimer) {
    const difference = new Date(nowEnd).valueOf() - new Date(lastDeviceTimestamp).valueOf();
    const positiveDifference = Math.round(difference / MINUTE_MILLISECONDS) > 0;
    if (positiveDifference) {
      if (lastTask) {
        result.push({
          fromTimestamp: lastDeviceTimestamp,
          task: lastTask,
          timer: lastTimer,
          toTimestamp: nowEnd,
        });
      } else {
        result.push({
          fromTimestamp: lastDeviceTimestamp,
          timer: lastTimer,
          toTimestamp: nowEnd,
        });
      }
    }
  }
  return combineSameTimerSequence(result);
};

// TODO: remove/replace with @co-backend-libs/utils/timer-intervals
export const computeIntervalsNormalisedInputTruncated = (
  inputTimerStarts: readonly Pick<TimerStart, "deviceTimestamp" | "task" | "timer">[],
  nowEnd: string,
): {
  readonly fromTimestamp: string;
  readonly task: TaskUrl;
  readonly timer: TimerUrl;
  readonly toTimestamp: string;
}[] => {
  console.assert(new Date(nowEnd).getSeconds() === 0);
  console.assert(new Date(nowEnd).getMilliseconds() === 0);
  console.assert(Array.isArray(inputTimerStarts));
  // Construct (subset of) ComputedTime model format:
  // fromTimestamp, toTimestamp, task, timer
  if (!inputTimerStarts.length) {
    return [];
  }
  const indexWithTimer = inputTimerStarts.findIndex((t) => t.timer);
  if (indexWithTimer === -1) {
    return [];
  }
  const timerStarts = inputTimerStarts.slice(indexWithTimer);
  const result = [];
  const firstTimerStart = timerStarts[0];
  let lastTimer = firstTimerStart.timer;
  let lastTask = firstTimerStart.task;
  let lastDeviceTimestamp = minuteTruncatedTimestamp(firstTimerStart.deviceTimestamp);
  timerStarts.slice(1).forEach((timerStart) => {
    const {task, timer} = timerStart;
    const deviceTimestamp = minuteTruncatedTimestamp(timerStart.deviceTimestamp);
    if (timer !== lastTimer || task !== lastTask) {
      if (lastTimer && deviceTimestamp !== lastDeviceTimestamp) {
        console.assert(
          deviceTimestamp > lastDeviceTimestamp,
          `timerstarts in wrong order; ${deviceTimestamp} vs ${lastDeviceTimestamp} when processing ${JSON.stringify(
            inputTimerStarts,
          )}`,
        );
        result.push({
          fromTimestamp: lastDeviceTimestamp,
          task: lastTask,
          timer: lastTimer,
          toTimestamp: deviceTimestamp,
        });
      }
      lastTimer = timer;
      lastTask = task;
      lastDeviceTimestamp = deviceTimestamp;
    }
  });
  if (lastTimer) {
    if (lastDeviceTimestamp < nowEnd) {
      result.push({
        fromTimestamp: lastDeviceTimestamp,
        task: lastTask,
        timer: lastTimer,
        toTimestamp: nowEnd,
      });
    }
  }
  return combineSameTimerSequence(result);
};

// TODO: remove?
export const normaliseTimerStarts = <
  T extends {
    readonly deviceTimestamp: string;
    readonly timer: string | null;
  },
>(
  timerStarts: readonly T[],
): T[] => {
  return _.sortBy(
    timerStarts.map((t) => {
      const timestamp = t.deviceTimestamp;
      if (timestamp.length === NORMAL_TIMESTAMP_LENGTH) {
        return t;
      } else {
        return {...t, deviceTimestamp: new Date(timestamp).toISOString()};
      }
    }),
    [(t) => t.deviceTimestamp, (t) => !!t.timer],
  );
};

const JS_TIMESTAMP_LENGTH = "2018-10-26T11:35:43.278Z".length;
console.assert(JS_TIMESTAMP_LENGTH === new Date().toISOString().length);

function normaliseTimestamps<
  T extends {readonly fromTimestamp: string; readonly toTimestamp: string},
>(entry: T): T {
  const {fromTimestamp} = entry;
  const {toTimestamp} = entry;
  if (
    fromTimestamp.length === JS_TIMESTAMP_LENGTH &&
    (!toTimestamp || toTimestamp.length === JS_TIMESTAMP_LENGTH)
  ) {
    return entry;
  } else {
    return {
      ...entry,
      fromTimestamp: new Date(fromTimestamp).toISOString(),
      toTimestamp: toTimestamp && new Date(toTimestamp).toISOString(),
    };
  }
}

// TODO: remove/replace with @co-backend-libs/utils/timer-intervals
export const computeIntervalsTruncated = (
  inputTimerStarts: readonly Pick<TimerStart, "deviceTimestamp" | "task" | "timer">[],
  now: string | null = null,
): {
  readonly fromTimestamp: string;
  readonly task: TaskUrl;
  readonly timer: TimerUrl;
  readonly toTimestamp: string;
}[] => {
  console.assert(typeof now === "string" || now === null);
  console.assert(inputTimerStarts.every((t) => t.deviceTimestamp));
  console.assert(Array.isArray(inputTimerStarts));
  const nowEnd = now ? new Date(now) : new Date();
  nowEnd.setUTCSeconds(0, 0);
  const timerStarts = normaliseTimerStarts(inputTimerStarts);
  return computeIntervalsNormalisedInputTruncated(timerStarts, nowEnd.toISOString());
};

const computeIntervals = <
  T extends {
    readonly deviceTimestamp: string;
    readonly task?: TaskUrl;
    readonly timer: TimerUrl | null;
  },
>(
  inputTimerStarts: readonly T[],
  now: string | null = null,
): {
  readonly fromTimestamp: string;
  readonly task?: TaskUrl;
  readonly timer: TimerUrl;
  readonly toTimestamp: string;
}[] => {
  console.assert(typeof now === "string" || now === null);
  console.assert(inputTimerStarts.every((t) => t.deviceTimestamp));
  const nowEnd = now || new Date().toISOString();
  const timerStarts = normaliseTimerStarts(inputTimerStarts);
  return computeIntervalsNormalisedInput(timerStarts, nowEnd);
};

// TODO: remove/replace with @co-backend-libs/utils/timer-intervals
export const mergeIntervals = (
  computedIntervals: readonly ComputedTime[],
  correctionIntervals: readonly TimeCorrection[],
  managerCorrectionIntervals: readonly TimeCorrection[],
  nowParam: string | null = null,
): readonly {
  readonly fromTimestamp: string;
  readonly timer: TimerUrl | null;
  readonly toTimestamp: string;
}[] => {
  // input intervals should be sorted; and should be associated with same task for sensible output
  console.assert(Array.isArray(computedIntervals), `Not an array: ${computedIntervals}`);
  console.assert(Array.isArray(correctionIntervals), `Not an array: ${correctionIntervals}`);
  console.assert(
    Array.isArray(managerCorrectionIntervals),
    `Not an array: ${managerCorrectionIntervals}`,
  );
  console.assert(!nowParam || typeof nowParam === "string");
  const now = nowParam || new Date().toISOString();
  // fast path if there are no corrections
  if (!correctionIntervals.length && !managerCorrectionIntervals.length) {
    return computedIntervals;
  }
  // Strategy:
  // * Find all timestamps where "something happens" --- any from/to timestamps
  // * For each of those, check what timer would start or be active here
  //   - In particular, we consider the input intervals half-closed; [from, to)
  //   - Have the corrections override the computed
  // * Format this like a sequence of "timerStart"-entries
  // * Use computeIntervals() to turn this back into a sequence of intervals ---
  //   it handles repetitions and "null"-entries...
  const timestampSet = new Set<string>();
  const addToTimestampSet = (entry: {
    readonly fromTimestamp: string;
    readonly toTimestamp: string;
  }): void => {
    const {fromTimestamp} = entry;
    const {toTimestamp} = entry;
    timestampSet.add(fromTimestamp);
    timestampSet.add(toTimestamp);
  };
  let normalisedComputedIntervals = computedIntervals.map(normaliseTimestamps);
  let normalisedCorrectionIntervals = correctionIntervals.map(normaliseTimestamps);
  let normalisedManagerCorrectionIntervals = managerCorrectionIntervals.map(normaliseTimestamps);

  normalisedComputedIntervals.forEach(addToTimestampSet);
  normalisedCorrectionIntervals.forEach(addToTimestampSet);
  normalisedManagerCorrectionIntervals.forEach(addToTimestampSet);
  const timestampArray = Array.from(timestampSet);
  timestampArray.sort();

  // intervals with no end specified is considered to end "now"
  const endsAfterTimestamp = (
    comparisonTimestamp: string,
    entry: {readonly fromTimestamp: string; readonly toTimestamp: string},
  ): boolean => {
    console.assert(comparisonTimestamp);
    const toTimestamp = entry.toTimestamp || now;
    return toTimestamp > comparisonTimestamp;
  };

  const arrayPartEndsAfterTimestamp = <
    T extends {readonly fromTimestamp: string; readonly toTimestamp: string},
  >(
    comparisonTimestamp: string,
    arr: readonly T[],
  ): T[] => {
    const endsAfterComparisonTimestamp = endsAfterTimestamp.bind(null, comparisonTimestamp);
    const index = arr.findIndex(endsAfterComparisonTimestamp);
    if (index === -1) {
      return [];
    } else {
      return arr.slice(index);
    }
  };

  // determine timer that would start/continue at each of the "interesting" timestamps
  const timerStarts = timestampArray.map((timestamp) => {
    // any overlapping manager corrections?
    const comparisonTimestamp = timestamp || now;
    normalisedManagerCorrectionIntervals = arrayPartEndsAfterTimestamp(
      comparisonTimestamp,
      normalisedManagerCorrectionIntervals,
    );
    const managerCorrectionEntry = normalisedManagerCorrectionIntervals[0];
    if (managerCorrectionEntry && managerCorrectionEntry.fromTimestamp <= comparisonTimestamp) {
      return {
        deviceTimestamp: timestamp,
        timer: managerCorrectionEntry.timer,
      };
    }
    // any overlapping machine operator corrections?
    normalisedCorrectionIntervals = arrayPartEndsAfterTimestamp(
      comparisonTimestamp,
      normalisedCorrectionIntervals,
    );
    const connectionEntry = normalisedCorrectionIntervals[0];
    if (connectionEntry && connectionEntry.fromTimestamp <= comparisonTimestamp) {
      return {
        deviceTimestamp: timestamp,
        timer: connectionEntry.timer,
      };
    }
    // any everlapping computed intervals?
    normalisedComputedIntervals = arrayPartEndsAfterTimestamp(
      comparisonTimestamp,
      normalisedComputedIntervals,
    );
    const computedEntry = normalisedComputedIntervals[0];
    if (computedEntry && computedEntry.fromTimestamp <= comparisonTimestamp) {
      return {
        deviceTimestamp: timestamp,
        timer: computedEntry.timer,
      };
    }
    // nothing here; this must match a "stop"
    return {
      deviceTimestamp: timestamp,
      timer: null,
    };
  });
  return computeIntervals(timerStarts, now);
};

export const intervalMinutes = (
  interval: {readonly fromTimestamp: string; readonly toTimestamp: string},
  now?: Date,
): number => {
  if (process.env.NODE_ENV !== "production") {
    if (console && console.assert) {
      console.assert(!now || now instanceof Date);
    }
  }
  const {fromTimestamp, toTimestamp} = interval;
  if (toTimestamp) {
    const difference = new Date(toTimestamp).valueOf() - new Date(fromTimestamp).valueOf();
    return Math.round(difference / MINUTE_MILLISECONDS);
  } else {
    const difference = Math.max(
      (now || new Date()).valueOf() - new Date(fromTimestamp).valueOf(),
      0,
    );
    // NOTE: No rounding here; we want to show seconds for the active timer.
    return difference / MINUTE_MILLISECONDS;
  }
};

export const computeIntervalSums = (
  intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | TimerUrl | null;
    readonly toTimestamp: string;
  }[],
  now?: Date,
): Map<TimerUrl, number> => {
  console.assert(Array.isArray(intervals));
  console.assert(!now || now instanceof Date);
  const result = new Map<TimerUrl, number>();
  intervals.forEach((entry) => {
    const minutes = intervalMinutes(entry, now);
    const {timer} = entry;
    if (!timer) {
      return;
    }
    const timerURL = typeof timer === "string" ? timer : timer.url;
    result.set(timerURL, (result.get(timerURL) || 0) + minutes);
  });
  return result;
};

export const computeWorkFromTo = (
  intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: string | Timer | null | undefined;
    readonly toTimestamp: string;
  }[],
  nowString: string | null = null,
): {workFromTimestamp: string | null; workToTimestamp: string | null} => {
  console.assert(typeof nowString === "string" || nowString === null);
  // FIXME: workaround, entries observed to be undefined --- but entire
  // stack trace inside React so hard to debug...
  const filteredIntervals = intervals.filter((x) => x);
  if (filteredIntervals.length) {
    return {
      workFromTimestamp: filteredIntervals[0].fromTimestamp,
      workToTimestamp: filteredIntervals[filteredIntervals.length - 1].toTimestamp || nowString,
    };
  } else {
    return {
      workFromTimestamp: null,
      workToTimestamp: null,
    };
  }
};

export const completedTaskIntervals = (
  task: Task,
): readonly {
  readonly fromTimestamp: string;
  readonly timer: TimerUrl | null;
  readonly toTimestamp: string;
}[] => {
  const computedIntervals = task.computedTimeSet || [];
  const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
  const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
  return mergeIntervals(computedIntervals, correctionIntervals, managerCorrectionIntervals);
};
