import {actions, getCommitQueue} from "@co-frontend-libs/redux";
import {
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {PageLayout} from "app-components";
import {sendCommitQueueToSentry} from "app-utils";
import React, {useCallback} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const SYNC_COUNT_BEFORE_ERROR = 6;
const SYNC_COUNT_BEFORE_WARNING = 3;

const messages = defineMessages({
  title: {
    defaultMessage: "Synkronisering",
    id: "sync.title",
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    circle: {
      "&:before": {
        content: '""',
        display: "inline-block",
        height: 0,
        paddingTop: "100%",
        verticalAlign: "middle",
      },
      border: "10px solid",
      borderRadius: "50%",
      boxSizing: "content-box",
      display: "inline-block",
      lineHeight: 1,
      minHeight: 200,
      minWidth: 200,
      padding: 5,
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    number: {
      display: "inline-block",
      fontSize: 100,
      verticalAlign: "middle",
    },
  }),
);

export function Sync(): React.JSX.Element {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const theme = useTheme();

  const commitQueue = useSelector(getCommitQueue);

  const dispatch = useDispatch();

  const handleButtonClick = useCallback(() => {
    sendCommitQueueToSentry("User requesting sync", "info");
    dispatch(actions.clearCommitErrorTimestamps());
    dispatch(actions.startOnlineSaves());
  }, [dispatch]);

  const count = commitQueue.filter(
    ({status}) =>
      status === "PROMISE" ||
      status === "UNSAVED" ||
      status === "SAVING_LOCALLY" ||
      status === "SAVED_LOCALLY",
  ).length;

  let color = theme.palette.success.main;
  if (count > SYNC_COUNT_BEFORE_ERROR) {
    color = theme.palette.error.main;
  } else if (count > SYNC_COUNT_BEFORE_WARNING) {
    color = theme.palette.warning.main;
  }
  return (
    <PageLayout toolbar={formatMessage(messages.title)} withPadding>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item style={{textAlign: "center"}} xs={12}>
              <div className={classes.circle} style={{borderColor: color}}>
                <span className={classes.number}>{count}</span>
              </div>
            </Grid>
            <Grid item style={{textAlign: "center"}} xs={12}>
              <FormattedMessage
                defaultMessage="Emner venter på synkronisering."
                id="sync.waiting"
              />
            </Grid>
            <Grid item style={{textAlign: "center"}} xs={12}>
              <Button
                color="primary"
                disabled={!count}
                onClick={handleButtonClick}
                variant="contained"
              >
                <FormattedMessage defaultMessage="Synkroniser nu" id="sync.synchronize-now" />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </PageLayout>
  );
}
