import {schema} from "@co-common-libs/config";
import _ from "lodash";
import React from "react";
import {SettingValueDisplayProps} from "../types";
import {IndustryLabel} from "./industry-label";

type Industry = (typeof schema.properties.industries.items.enum)[number];

export function DisplayIndustries(props: SettingValueDisplayProps): React.JSX.Element {
  const industries: Industry[] = props.settingEntry?.data || [];

  return (
    <div>
      {_.sortBy(industries).map((industry) => (
        <div key={industry}>
          <IndustryLabel industry={industry} />
        </div>
      ))}
    </div>
  );
}
