import {ReadonlyConfig} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {
  ActiveEconomySystemIntegrationKind,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";
import {ExtendedCustomerSettingsPart} from "./extended-customer-settings";

type ExtendedPriceItemSettingsPart = ExtendedCustomerSettingsPart &
  Pick<ReadonlyConfig, "economicEnableProjectActivitiesImport" | "enablePriceItemVolumeDiscount">;

export interface ExtendedPriceItemSettings {
  addTimeAfterMinutesSetup: boolean;
  allowManualPriceGroupPriceItemOrdering: boolean;
  canImport: boolean;
  enableVolumeDiscount: boolean;
}

export function extendedPriceItemSettings(
  config: ExtendedPriceItemSettingsPart,
  role: Pick<Role, "consultant"> | null,
): ExtendedPriceItemSettings {
  const {
    economicEnableProjectActivitiesImport,
    economicSync,
    enablePriceItemVolumeDiscount,
    navSync,
    navSyncProfile,
    unicontaSync,
  } = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  return {
    addTimeAfterMinutesSetup: Boolean(
      role?.consultant &&
        (economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE || economicSync),
    ),
    allowManualPriceGroupPriceItemOrdering:
      economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
      economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC ||
      (navSync &&
        (navSyncProfile === "vredodanmark" ||
          navSyncProfile === "hvt" ||
          navSyncProfile === "rosgaard")),
    canImport:
      economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC &&
      !economicEnableProjectActivitiesImport,
    enableVolumeDiscount: !unicontaSync && enablePriceItemVolumeDiscount,
  };
}
