import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Order,
  OrderUrl,
  Role,
  Task,
  TaskUrl,
  urlToId,
  User,
  UserProfile,
  UserUrl,
  YieldDelivery,
  YieldDeliveryLocation,
  YieldLog,
  YieldLogReport,
  YieldPickup,
  YieldPickupLocation,
} from "@co-common-libs/resources";
import {formatDate, formatTime} from "@co-common-libs/utils";
import {FilePdfIcon, ResponsiveDialog, SpinnerDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getOrderLookup,
  getShareToken,
  getSortedActiveContactArrayPerCustomer,
  getTaskArray,
  getTaskLookup,
  getUserUserProfileLookup,
  getYieldDeliveryArray,
  getYieldDeliveryLocationArray,
  getYieldLogArray,
  getYieldLogReportArray,
  getYieldPickupArray,
  getYieldPickupLocationArray,
} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {getLocationButtonString, MailReportDialog, unitIdToFormattedString} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import DeleteIcon from "mdi-react/DeleteIcon";
import MailIcon from "mdi-react/MailIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback} from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {TransportLogTotalsCard} from "./transport-log-totals-card";

const messages = defineMessages({
  addDeliveryLocation: {
    defaultMessage: "Tilføj sted",
    id: "yield-log-card.label.add-delivery-location",
  },
  addPickupLocation: {
    defaultMessage: "Tilføj sted",
    id: "yield-log-card.label.add-pickup-location",
  },
  corn: {
    defaultMessage: "Majs",
    id: "yield-log-card.label.corn",
  },
  cutNumber: {
    defaultMessage: "slæt nr.",
    id: "yield-log-card.label.cutNumber",
  },
  density: {
    defaultMessage: "vægtfylde",
    id: "yield-log-card.label.density",
  },
  densityUnit: {
    defaultMessage: "ton/m³",
    id: "yield-log-card.label.densityUnit",
  },
  edit: {
    defaultMessage: "Ret",
    id: "yield-log-card.label.edit",
  },
  editYieldLog: {
    defaultMessage: "Ret",
    id: "yield-log-card.label.edit-yield-log",
  },
  grass: {
    defaultMessage: "Græs",
    id: "yield-log-card.label.grass",
  },
  mailError: {
    defaultMessage: "Der skete en fejl under afsendelsen af emailen",
    id: "yield-log-card.label.mailerror",
  },
  mailMessage: {
    defaultMessage: `Denne mail er sendt til dig fra {companyName} via software leveret af CustomOffice ApS og kan derfor ikke besvares.
Ønsker du at svare på denne mail eller ønsker du ikke at modtage denne type mails fremover, skal du henvende dig til {companyName}.`,
  },
  mesurementMethod: {
    defaultMessage: "Målemetode",
    id: "yield-log-card.label.mesurementMethod",
  },
  reports: {
    defaultMessage: "Udskrifter, udbyttelog",
    id: "yield-log-card.label.reports",
  },
  sendingMail: {
    defaultMessage: "Sender mail",
    id: "yield-log-card.label.sending-mail",
  },
  tonne: {
    defaultMessage: "ton",
    id: "yield-log-card.label.tonne",
  },
  volume: {
    defaultMessage: "Rumfang (m³)",
    id: "yield-log-card.label.volume",
  },
  wagonWeight: {
    defaultMessage: "vognvægt",
    id: "yield-log-card.label.wagon-weight",
  },
  weight: {
    defaultMessage: "Vægt (ton)",
    id: "yield-log-card.label.weight",
  },
  wholecrop: {
    defaultMessage: "Helsæd",
    id: "yield-log-card.label.wholecrop",
  },
  yieldLog: {
    defaultMessage: "Udbyttelog",
  },
});

const TIME_COLUMN_STYLE = {width: 86};
const AMOUNT_COLUMN_STYLE = {width: 100};

const BUTTON_COLUMN_WIDTH = 2 * 24 + 2 * 48;

export const ReportRow = ({
  createdBy,
  deviceTimestamp,
  onMailIconClick,
  pdfDownloadURL,
  tokenPart,
  url,
}: {
  createdBy?: string | undefined;
  deviceTimestamp: string;
  onMailIconClick: (url: string) => void;
  pdfDownloadURL?: string | undefined;
  tokenPart: string;
  url: string;
}): React.JSX.Element => {
  const handleMailIconClick = useCallback(() => {
    onMailIconClick(url);
  }, [onMailIconClick, url]);

  let pendingSpinner;
  let pdfActionButtons;
  if (!pdfDownloadURL) {
    pendingSpinner = <CircularProgress />;
  } else {
    pdfActionButtons = (
      <>
        <IconButton color="primary" href={pdfDownloadURL + tokenPart} target="_blank">
          <FilePdfIcon />
        </IconButton>
        <IconButton color="primary" onClick={handleMailIconClick}>
          <MailIcon />
        </IconButton>
      </>
    );
  }

  return (
    <TableRow key={url}>
      <TableCell>{createdBy}</TableCell>
      <TableCell>
        {formatDate(deviceTimestamp)}
        &nbsp;
        {formatTime(deviceTimestamp)}
      </TableCell>
      <TableCell style={{width: BUTTON_COLUMN_WIDTH}}>
        {pendingSpinner}
        {pdfActionButtons}
      </TableCell>
    </TableRow>
  );
};

export const getCustomerMailAddress = (
  taskURL: TaskUrl,
  {
    contactArrayPerCustomer,
    customerLookup,
    orderLookup,
    taskLookup,
  }: {
    contactArrayPerCustomer: ReadonlyMap<string, readonly Contact[]>;
    customerLookup: (url: CustomerUrl) => Customer | undefined;
    orderLookup: (url: OrderUrl) => Order | undefined;
    taskLookup: (url: TaskUrl) => Task | undefined;
  },
): string | undefined => {
  const task = taskLookup(taskURL);
  const order = task?.order ? orderLookup(task.order) : null;
  const customer = order?.customer ? customerLookup(order.customer) : null;
  let recipient = customer?.logEmail || customer?.billingEmail;
  if (!recipient && customer) {
    const contacts = contactArrayPerCustomer.get(customer.url);
    const defaultContact = contacts
      ? contacts.find((contact: Contact): boolean => contact.defaultContact)
      : null;
    recipient = defaultContact?.email;
  }

  return recipient;
};

interface ReportsCardStateProps {
  contactArrayPerCustomer: ReadonlyMap<string, readonly Contact[]>;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  orderLookup: (url: OrderUrl) => Order | undefined;
  taskLookup: (url: TaskUrl) => Task | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface ReportsCardOwnProps {
  amountUnit: string;
  currentUserURL: string | null;
  entryData: unknown[];
  machineOperator?: User | undefined;
  onRequestBuildReports: () => void;
  shareToken: string | null;
  workTypeString: string;
  yieldLog: YieldLog;
  yieldLogReportArray: readonly YieldLogReport[];
}

type ReportsCardProps = ReportsCardOwnProps & ReportsCardStateProps;

interface ReportsCardState {
  mailError: boolean;
  mailReportDialogOpenFor: string | null;
  sendingMail: boolean;
}

class ReportsCard extends PureComponent<ReportsCardProps, ReportsCardState> {
  static contextType = IntlContext;
  _sending: boolean = false;
  context!: React.ContextType<typeof IntlContext>;
  state = {
    mailError: false,
    mailReportDialogOpenFor: null,
    sendingMail: false,
  };
  @bind
  handleFabButton(): void {
    this.props.onRequestBuildReports();
  }
  @bind
  handleMailClick(url: string): void {
    this.setState({mailReportDialogOpenFor: url});
  }
  @bind
  handleMailErrorDialogOk(): void {
    this.setState({mailError: false});
  }

  @bind
  handleMailReportDialogCancel(): void {
    this.setState({mailReportDialogOpenFor: null});
  }

  @bind
  handleMailReportDialogOk(subject: string, message: string, recipient: string): void {
    const {mailReportDialogOpenFor} = this.state;
    if (this._sending || !mailReportDialogOpenFor) {
      return;
    }
    this._sending = true;

    const {baseURL} = globalConfig;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const reportId = urlToId(mailReportDialogOpenFor!);
    const url = `${baseURL}/api/mail/yieldlog_report/${reportId}`;
    this.setState({mailReportDialogOpenFor: null, sendingMail: true});
    jsonFetch(url, "POST", {
      message,
      recipient,
      subject,
    })
      .then(() => {
        this._sending = false;
        this.setState({
          sendingMail: false,
        });
        return;
      })
      .catch(() => {
        this._sending = false;
        this.setState({
          mailError: true,
          sendingMail: false,
        });
      });
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      contactArrayPerCustomer,
      customerLookup,
      entryData,
      orderLookup,
      taskLookup,
      userUserProfileLookup,
      yieldLog,
      yieldLogReportArray,
    } = this.props;
    const yieldLogURL = yieldLog.url;
    const recipient = getCustomerMailAddress(yieldLog.task, {
      contactArrayPerCustomer,
      customerLookup,
      orderLookup,
      taskLookup,
    });

    const tokenPart = `?token=${this.props.shareToken}`;
    const addButtonStyle: React.CSSProperties = {
      position: "absolute",
      right: 23,
      top: 23,
    };
    const tableRows = _.sortBy(
      yieldLogReportArray.filter((r) => r.yieldlog === yieldLogURL),
      (r) => r.serverTimestamp,
    )
      .reverse()
      .map((report) => {
        const pdfDownloadURL = report.pdfDownload;
        const createdByURL = report.createdBy;
        const createdByProfile = userUserProfileLookup(createdByURL);
        return (
          <ReportRow
            createdBy={createdByProfile?.alias}
            deviceTimestamp={report.deviceTimestamp}
            key={report.url}
            onMailIconClick={this.handleMailClick}
            pdfDownloadURL={pdfDownloadURL}
            tokenPart={tokenPart}
            url={report.url}
          />
        );
      });
    return (
      <>
        <Card style={{margin: "1em"}}>
          <div style={{position: "relative"}}>
            <Fab
              disabled={!entryData.length}
              onClick={this.handleFabButton}
              size="small"
              style={addButtonStyle}
            >
              <PlusIcon />
            </Fab>
          </div>
          <CardHeader title={formatMessage(messages.reports)} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Udskrevet af"
                    id="transportlog-report.table-header.printed-by"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Tidspunkt"
                    id="transportlog-report.table-header.printed-on"
                  />
                </TableCell>
                <TableCell style={{width: BUTTON_COLUMN_WIDTH}}>
                  <FormattedMessage
                    defaultMessage="Download"
                    id="transportlog-report.table-header.download"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </Card>
        <MailReportDialog
          defaultMessage={formatMessage(messages.mailMessage, {
            companyName: this.props.customerSettings.companyName,
          })}
          logName={formatMessage(messages.yieldLog)}
          onCancel={this.handleMailReportDialogCancel}
          onOk={this.handleMailReportDialogOk}
          open={!!this.state.mailReportDialogOpenFor}
          recipient={recipient}
        />
        <SpinnerDialog open={this.state.sendingMail} title={formatMessage(messages.sendingMail)} />
        <ResponsiveDialog
          onOk={this.handleMailErrorDialogOk}
          open={this.state.mailError === true}
          title={formatMessage(messages.mailError)}
        />
      </>
    );
  }
}

const ConnectedReportsCard: React.ComponentType<ReportsCardOwnProps> = connect<
  ReportsCardStateProps,
  object,
  ReportsCardOwnProps,
  AppState
>(
  createStructuredSelector<AppState, ReportsCardStateProps>({
    contactArrayPerCustomer: getSortedActiveContactArrayPerCustomer,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    orderLookup: getOrderLookup,
    taskLookup: getTaskLookup,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {},
)(ReportsCard);

interface YieldLogInfoProps {
  crop: string;
  cuts?: number | undefined;
  density?: number | undefined;
  unit: string;
  wagonWeight?: number | undefined;
}

class YieldLogInfo extends PureComponent<YieldLogInfoProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  cropToFormattedString(): string {
    const {formatMessage} = this.context;
    let returnString = "";
    switch (this.props.crop) {
      case "corn":
        returnString = formatMessage(messages.corn);
        break;
      case "grass":
        returnString = `${formatMessage(messages.grass)}, ${formatMessage(messages.cutNumber)}: ${
          this.props.cuts ? this.props.cuts : ""
        }`;
        break;
      case "wholecrop":
        returnString = formatMessage(messages.wholecrop);
        break;
      default:
        returnString = "";
    }
    return returnString;
  }

  render(): React.JSX.Element {
    return (
      <div>
        <div>{this.cropToFormattedString()}</div>
        <div>{this.unitToFormattedString()}</div>
      </div>
    );
  }

  unitToFormattedString(): string {
    const {formatMessage} = this.context;
    let returnString = "";
    switch (this.props.unit) {
      case "m3":
        returnString = `${formatMessage(messages.volume)}, ${formatMessage(messages.density)}: ${
          this.props.density ? this.props.density : ""
        } ${formatMessage(messages.densityUnit)}`;
        break;
      case "tonne":
        returnString = `${formatMessage(messages.weight)}, ${formatMessage(
          messages.wagonWeight,
        )}: ${this.props.wagonWeight ? this.props.wagonWeight : ""} ${formatMessage(
          messages.tonne,
        )}`;
        break;
      default:
        returnString = "";
    }
    return `${formatMessage(messages.mesurementMethod)}: ${returnString}`;
  }
}

interface EntryRowProps {
  disabled: boolean;
  entry: {
    action: "delivery" | "pickup";
    amount: number;
    customerName?: string | undefined;
    entry: YieldDelivery | YieldPickup;
    location: string;
    locationUuid: string;
    note: string;
    place: string;
    timestamp?: string | undefined;
    unit?: string | undefined;
  };
  onDeleteClick?: (entry: YieldDelivery | YieldPickup) => void;
  onEditClick?: (entry: YieldDelivery | YieldPickup) => void;
}

class EntryRow extends PureComponent<EntryRowProps> {
  @bind
  handleDeleteClick(): void {
    const {entry, onDeleteClick} = this.props;
    if (onDeleteClick) {
      onDeleteClick(entry.entry);
    }
  }
  @bind
  handleEditClick(): void {
    const {entry, onEditClick} = this.props;
    if (onEditClick) {
      onEditClick(entry.entry);
    }
  }
  render(): React.JSX.Element {
    const {disabled, entry} = this.props;
    const limitedSpace = bowser.mobile;
    const location = limitedSpace ? entry.place : `${entry.place}, ${entry.location}`;
    return (
      <TableRow
        style={{
          borderBottomWidth: entry.note ? 0 : 1,
          cursor: disabled ? "default" : "pointer",
        }}
      >
        <TableCell onClick={this.handleEditClick} style={TIME_COLUMN_STYLE}>
          {formatTime(entry.timestamp)}
        </TableCell>
        <TableCell onClick={this.handleEditClick} style={AMOUNT_COLUMN_STYLE}>
          {entry.amount} {entry.unit}
        </TableCell>
        <TableCell onClick={this.handleEditClick}>{location}</TableCell>
        <TableCell style={{padding: 0, width: 48}}>
          <IconButton disabled={disabled} onClick={this.handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

interface YieldEntryProps {
  amount: number;
  disabled: boolean;
  index: number;
  label: string;
  onClick: (index: number) => void;
  unit?: string | undefined;
}

class YieldEntry extends PureComponent<YieldEntryProps> {
  @bind
  handleClick(): void {
    this.props.onClick(this.props.index);
  }

  render(): React.JSX.Element {
    const {amount, disabled, label, unit} = this.props;
    return (
      <div>
        <Button
          color="secondary"
          disabled={disabled}
          onClick={this.handleClick}
          style={{width: "100%"}}
          variant="contained"
        >
          {label}
        </Button>
        {amount} {unit}
      </div>
    );
  }
}

interface YieldLogCardStateProps {
  currentRole: Role | null;
  currentUserURL: string | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  shareToken: string | null;
  taskArray: readonly Task[];
  taskLookup: (url: TaskUrl) => Task | undefined;
  yieldDeliveryArray: readonly YieldDelivery[];
  yieldDeliveryLocationArray: readonly YieldDeliveryLocation[];
  yieldLogArray: readonly YieldLog[];
  yieldLogReportArray: readonly YieldLogReport[];
  yieldPickupArray: readonly YieldPickup[];
  yieldPickupLocationArray: readonly YieldPickupLocation[];
}

interface YieldLogCardOwnProps {
  completed: boolean;
  disabled: boolean;
  machineOperator?: User | undefined;
  onRequestBuildReports: () => void;
  onRequestDeleteEntry: (entry: YieldDelivery | YieldPickup) => void;
  onRequestDeliveryDialog: (deliveryLocation: YieldDeliveryLocation) => void;
  onRequestDeliveryLocationDialog: (deliveryLocation: YieldDeliveryLocation | null) => void;
  onRequestEditEntry: (entry: YieldDelivery | YieldPickup) => void;
  onRequestPickupDialog: (pickupLocation: YieldPickupLocation) => void;
  onRequestPickupLocationDialog: (pickupLocation: YieldPickupLocation | null) => void;
  onRequestYieldLogDialog: () => void;
  validated: boolean;
  workTypeString: string;
  yieldLog: YieldLog;
}

type YieldLogCardProps = YieldLogCardOwnProps & YieldLogCardStateProps;

class YieldLogCard extends PureComponent<YieldLogCardProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  generateEntryList(
    yieldArray: readonly YieldDelivery[] | readonly YieldPickup[],
    yieldLocations: readonly YieldDeliveryLocation[] | readonly YieldPickupLocation[],
    action: "delivery" | "pickup",
    unit: "" | "m3" | "tonne",
    handle: ((index: number) => void) | void,
  ): {
    blocks: React.JSX.Element[];
    entryData: {
      action: "delivery" | "pickup";
      amount: number;
      customerName: string | undefined;
      entry: YieldDelivery | YieldPickup;
      location: string;
      locationUuid: string;
      note: string;
      place: string;
      timestamp: string | undefined;
      unit: string | undefined;
    }[];
  } {
    const entryData: {
      action: "delivery" | "pickup";
      amount: number;
      customerName: string | undefined;
      entry: YieldDelivery | YieldPickup;
      location: string;
      locationUuid: string;
      note: string;
      place: string;
      timestamp: string | undefined;
      unit: string | undefined;
    }[] = [];
    const blocks: React.JSX.Element[] = [];
    yieldLocations.forEach((yieldLocation: YieldDeliveryLocation | YieldPickupLocation, index) => {
      const identifier = (action === "pickup" ? "A" : "L") + (yieldLocation.order + 1);
      const location =
        yieldLocation.relatedLocation && this.props.locationLookup(yieldLocation.relatedLocation);
      const address = getLocationButtonString(
        this.props.customerSettings.logButtonText,
        location || undefined,
      );
      const yieldLocationURL = yieldLocation.url;
      const customerURL = yieldLocation.customer;
      const customer = (customerURL && this.props.customerLookup(customerURL)) || undefined;
      const customerName = customer && customer.name;
      const unitString = unitIdToFormattedString(unit, this.context);
      let yielded = 0;
      let count = 0;
      yieldArray.forEach((pickup) => {
        if (pickup.location !== yieldLocationURL) {
          return;
        }
        count += 1;
        entryData.push({
          action,
          amount: pickup.amount || 0,
          customerName,
          entry: pickup,
          location: address,
          locationUuid: urlToId(yieldLocation.url),
          note: pickup.note || "",
          place: identifier,
          timestamp: pickup.deviceTimestamp || undefined,
          unit: unitString,
        });
        yielded += pickup.amount || 0;
      });

      if (handle) {
        let includeCount = false;
        if (action === "pickup") {
          includeCount = this.props.customerSettings.logPickupButtonDisplayCount;
        } else {
          console.assert(action === "delivery");
          includeCount = this.props.customerSettings.logDeliveryButtonDisplayCount;
        }
        const countPart = includeCount && count ? ` (${count})` : "";
        const label = `${identifier}${countPart}, ${address}`;
        const decimals = 2;
        const rounded = _.round(yielded, decimals);
        blocks.push(
          <YieldEntry
            amount={rounded}
            disabled={this.props.disabled}
            index={index}
            key={index}
            label={label}
            onClick={handle}
            unit={unitString}
          />,
        );
      }
    });
    return {
      blocks,
      entryData,
    };
  }
  @bind
  handleAddDeliveryLocation(): void {
    this.props.onRequestDeliveryLocationDialog(null);
  }
  @bind
  handleAddPickupLocation(): void {
    this.props.onRequestPickupLocationDialog(null);
  }
  @bind
  handleDoDelivery(index: number): void {
    const {yieldDeliveryLocationArray, yieldLog} = this.props;
    const yieldLogURL = yieldLog.url;
    const deliveryLocations = _.sortBy(
      yieldDeliveryLocationArray.filter((p) => p.yieldlog === yieldLogURL),
      (p) => p.order,
    );
    const deliveryLocation = deliveryLocations[index];
    this.props.onRequestDeliveryDialog(deliveryLocation);
  }
  @bind
  handleDoPickup(index: number): void {
    const {yieldLog, yieldPickupLocationArray} = this.props;
    const yieldLogURL = yieldLog.url;
    const pickupLocations = _.sortBy(
      yieldPickupLocationArray.filter((p) => p.yieldlog === yieldLogURL),
      (p) => p.order,
    );
    const pickupLocation = pickupLocations[index];
    this.props.onRequestPickupDialog(pickupLocation);
  }
  @bind
  handleEditDeliveryLocation(deliveryLocation: YieldDeliveryLocation): void {
    this.props.onRequestDeliveryLocationDialog(deliveryLocation);
  }
  @bind
  handleEditPickupLocation(pickupLocation: YieldPickupLocation): void {
    this.props.onRequestPickupLocationDialog(pickupLocation);
  }
  @bind
  handleRequestDeleteEntry(entry: YieldDelivery | YieldPickup): void {
    this.props.onRequestDeleteEntry(entry);
  }

  @bind
  handleRequestEditEntry(entry: YieldDelivery | YieldPickup): void {
    if (!this.props.disabled) {
      this.props.onRequestEditEntry(entry);
    }
  }

  render(): React.JSX.Element | null {
    const {formatMessage} = this.context;
    const {
      completed,
      currentUserURL,
      disabled,
      taskArray,
      taskLookup,
      workTypeString,
      yieldDeliveryArray,
      yieldDeliveryLocationArray,
      yieldLog,
      yieldLogArray,
      yieldLogReportArray,
      yieldPickupArray,
      yieldPickupLocationArray,
    } = this.props;
    const role = this.props.currentRole;
    const isManager = role && role.manager;
    const task = taskLookup(yieldLog.task);
    if (!task) {
      return null;
    }
    const {unit} = yieldLog;
    const yieldLogURL = yieldLog.url;
    const pickupLocations = _.sortBy(
      yieldPickupLocationArray.filter((p) => p.yieldlog === yieldLogURL),
      (p) => p.order,
    );
    const deliveryLocations = _.sortBy(
      yieldDeliveryLocationArray.filter((p) => p.yieldlog === yieldLogURL),
      (p) => p.order,
    );

    const pickupEntryData = this.generateEntryList(
      yieldPickupArray,
      pickupLocations,
      "pickup",
      unit,
      this.handleDoPickup,
    );

    const pickupLocationBlocks = pickupEntryData.blocks;

    const deliveryEntryData = this.generateEntryList(
      yieldDeliveryArray,
      deliveryLocations,
      "delivery",
      unit,
      this.handleDoDelivery,
    );

    const entryData = pickupEntryData.entryData.concat(deliveryEntryData.entryData);
    const deliveryLocationBlocks = deliveryEntryData.blocks;

    const orderTotals = new Map<
      string,
      {
        delivery: number;
        difference: number;
        pickup: number;
        unit: string;
      }
    >();

    const orderUrl = task.order;
    const taskUrls = new Set(taskArray.filter((t) => t.order === orderUrl).map((t) => t.url));
    const orderYieldLogs = yieldLogArray.filter((t) => taskUrls.has(t.task));

    const orderEntryData: {
      action: "delivery" | "pickup";
      amount: number;
      customerName?: string | undefined;
      entry: YieldDelivery | YieldPickup;
      location: string;
      locationUuid: string;
      note: string;
      place: string;
      timestamp?: string | undefined;
      unit?: string | undefined;
    }[] = [];
    orderYieldLogs.forEach((orderYieldLog) => {
      const orderUnit = orderYieldLog.unit;
      const orderYieldLogURL = orderYieldLog.url;
      const orderPickupLocations = _.sortBy(
        yieldPickupLocationArray.filter((p) => p.yieldlog === orderYieldLogURL),
        (p) => p.order,
      );
      const orderDeliveryLocations = _.sortBy(
        yieldDeliveryLocationArray.filter((p) => p.yieldlog === orderYieldLogURL),
        (p) => p.order,
      );

      const orderPickupEntryData = this.generateEntryList(
        yieldPickupArray,
        orderPickupLocations,
        "pickup",
        orderUnit,
      );
      Array.prototype.push.apply(orderEntryData, orderPickupEntryData.entryData);

      const orderDeliveryEntryData = this.generateEntryList(
        yieldDeliveryArray,
        orderDeliveryLocations,
        "delivery",
        orderUnit,
      );
      Array.prototype.push.apply(orderEntryData, orderDeliveryEntryData.entryData);
    });

    orderEntryData.forEach((entry) => {
      const unitString = entry.unit || "";

      const existing = orderTotals.get(unitString);
      const totals = existing || {
        delivery: 0,
        difference: 0,
        pickup: 0,
        unit: unitString,
      };
      if (!existing) {
        orderTotals.set(unitString, totals);
      }
      totals[entry.action] += entry.amount;
      if (entry.action === "pickup") {
        totals.difference += entry.amount;
      } else {
        totals.difference -= entry.amount;
      }
    });

    entryData.sort(
      (
        entryA: {readonly timestamp: string | undefined},
        entryB: {readonly timestamp: string | undefined},
      ): number => {
        // newest first
        return (
          new Date(entryB.timestamp || 0).valueOf() - new Date(entryA.timestamp || 0).valueOf()
        );
      },
    );
    const entries: React.JSX.Element[] = [];
    const columns = 4;

    const taskTotals = new Map<
      string,
      {
        delivery: number;
        difference: number;
        pickup: number;
        unit: string;
      }
    >();
    entryData.forEach((entry, index) => {
      entries.push(
        <EntryRow
          disabled={disabled}
          entry={entry}
          key={index}
          onDeleteClick={this.handleRequestDeleteEntry}
          onEditClick={this.handleRequestEditEntry}
        />,
      );

      const unitString = entry.unit || "";

      const existing = taskTotals.get(unitString);
      const totals = existing || {
        delivery: 0,
        difference: 0,
        pickup: 0,
        unit: unitString,
      };
      if (!existing) {
        taskTotals.set(unitString, totals);
      }
      totals[entry.action] += entry.amount;
      if (entry.action === "pickup") {
        totals.difference += entry.amount;
      } else {
        totals.difference -= entry.amount;
      }

      if (entry.note) {
        const editEntry = (): void => this.handleRequestEditEntry(entry.entry);
        entries.push(
          <TableRow
            key={`${index}-note`}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={editEntry}
            style={{height: 36}}
          >
            <TableCell colSpan={columns - 1} style={{height: 24, verticalAlign: "top"}}>
              <em>{entry.note}</em>
            </TableCell>
            <TableCell style={{height: 24}} />
          </TableRow>,
        );
      }
    });

    return (
      <Grid>
        <Cell>
          <Card style={{margin: "1em"}}>
            <CardContent>
              <Grid>
                <Cell palm="12/12">
                  <FormattedMessage
                    defaultMessage="Afhentning"
                    id="task-instance.header.pickup"
                    tagName="h3"
                  />
                  {pickupLocationBlocks}
                  <Button
                    color="primary"
                    disabled={disabled}
                    onClick={this.handleAddPickupLocation}
                    variant="contained"
                  >
                    {formatMessage(messages.addPickupLocation)}
                  </Button>
                </Cell>
                <Cell palm="12/12">
                  <FormattedMessage
                    defaultMessage="Levering"
                    id="task-instance.header.delivery"
                    tagName="h3"
                  />
                  {deliveryLocationBlocks}
                  <Button
                    color="primary"
                    disabled={disabled}
                    onClick={this.handleAddDeliveryLocation}
                    variant="contained"
                  >
                    {formatMessage(messages.addDeliveryLocation)}
                  </Button>
                </Cell>
              </Grid>
              <YieldLogInfo
                crop={yieldLog.crop || ""}
                cuts={yieldLog.cuts != null ? yieldLog.cuts : undefined}
                density={yieldLog.density != null ? yieldLog.density : undefined}
                unit={yieldLog.unit}
                wagonWeight={yieldLog.wagonWeight != null ? yieldLog.wagonWeight : undefined}
              />
              <Button
                color="primary"
                disabled={disabled}
                onClick={this.props.onRequestYieldLogDialog}
                variant="contained"
              >
                {formatMessage(messages.edit)}
              </Button>
            </CardContent>
          </Card>
          <TransportLogTotalsCard
            customerSettings={this.props.customerSettings}
            orderTotals={orderTotals}
            taskTotals={taskTotals}
          />
          <Card style={{margin: "1em"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={TIME_COLUMN_STYLE}>
                    <FormattedMessage defaultMessage="Kl." id="task-instance.table-header.time" />
                  </TableCell>
                  <TableCell style={AMOUNT_COLUMN_STYLE}>
                    <FormattedMessage
                      defaultMessage="Mængde"
                      id="task-instance.table-header.amount"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage defaultMessage="Sted" id="task-instance.table-header.place" />
                  </TableCell>
                  <TableCell style={{width: 48}} />
                </TableRow>
              </TableHead>
              <TableBody>{entries}</TableBody>
            </Table>
          </Card>
          {completed && isManager ? (
            <ConnectedReportsCard
              amountUnit={unit}
              currentUserURL={currentUserURL}
              entryData={entryData}
              machineOperator={this.props.machineOperator}
              onRequestBuildReports={this.props.onRequestBuildReports}
              shareToken={this.props.shareToken}
              workTypeString={workTypeString}
              yieldLog={yieldLog}
              yieldLogReportArray={yieldLogReportArray}
            />
          ) : null}
        </Cell>
      </Grid>
    );
  }
}

const ConnectedYieldLogCard: React.ComponentType<YieldLogCardOwnProps> = connect<
  YieldLogCardStateProps,
  object,
  YieldLogCardOwnProps,
  AppState
>(
  createStructuredSelector<AppState, YieldLogCardStateProps>({
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    shareToken: getShareToken,
    taskArray: getTaskArray,
    taskLookup: getTaskLookup,
    yieldDeliveryArray: getYieldDeliveryArray,
    yieldDeliveryLocationArray: getYieldDeliveryLocationArray,
    yieldLogArray: getYieldLogArray,
    yieldLogReportArray: getYieldLogReportArray,
    yieldPickupArray: getYieldPickupArray,
    yieldPickupLocationArray: getYieldPickupLocationArray,
  }),
  {},
)(YieldLogCard);

export {ConnectedYieldLogCard as YieldLogCard};
