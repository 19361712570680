import {CsvImportSpecification} from "@co-common-libs/csv-import-specifications";
import {Card, CardContent, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import React, {ChangeEvent, useCallback} from "react";
import {useIntl} from "react-intl";

interface ImportSpecificationSelectProps {
  availableImportSpecifications: readonly CsvImportSpecification[];
  onSelectorChange: (value: string) => void;
  selected: string;
}

function ImportSpecificationSelect(props: ImportSpecificationSelectProps): React.JSX.Element {
  const {availableImportSpecifications, onSelectorChange, selected} = props;

  const intl = useIntl();

  const handleChange = useCallback(
    ({target: {value}}: ChangeEvent<{value: unknown}>): void => {
      if (typeof value === "string") {
        onSelectorChange(value);
      }
    },
    [onSelectorChange],
  );

  const menuItems = availableImportSpecifications.map((exportSpecification) => (
    <MenuItem key={exportSpecification.name} value={exportSpecification.name}>
      {exportSpecification.label}
    </MenuItem>
  ));

  const label = intl.formatMessage({defaultMessage: "Vælg kartotek"});

  return (
    <FormControl fullWidth margin="dense" variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select label={label} onChange={handleChange} value={selected}>
        {menuItems}
      </Select>
    </FormControl>
  );
}

export function SelectImportSpecificationCard(props: {
  availableImportSpecification: readonly CsvImportSpecification[];
  onSelectorChange: (value: string) => void;
  selected: string;
}): React.JSX.Element {
  const {
    availableImportSpecification: availableImportSpecifications,
    onSelectorChange,
    selected,
  } = props;

  return (
    <Card style={{height: "100%"}}>
      <CardContent>
        <ImportSpecificationSelect
          availableImportSpecifications={availableImportSpecifications}
          onSelectorChange={onSelectorChange}
          selected={selected}
        />
      </CardContent>
    </Card>
  );
}
