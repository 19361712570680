import {HALF_MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {actions, getLastKnownPosition, Position} from "@co-frontend-libs/redux";
import {getCurrentPosition} from "@co-frontend-libs/utils";
import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

let useLastKnownPositionCurrentlyUsedBy = 0;
let periodicFetchingStarted = false;

/**
 * Helper to get the last known position of the device.
 *
 * @returns Position or null
 */
export function useLastKnownPosition(): Position | null {
  const dispatch = useDispatch();
  const handlePeriodicCurrentLocationReceived = useCallback(
    (position: GeolocationPosition) => {
      dispatch(
        actions.lastKnownPosition(
          position.coords.latitude,
          position.coords.longitude,
          new Date(position.timestamp).toISOString(),
        ),
      );
      if (useLastKnownPositionCurrentlyUsedBy > 0) {
        setTimeout(() => {
          getCurrentPosition()
            .then(handlePeriodicCurrentLocationReceived)
            .catch(() => {
              //ignored
            });
        }, HALF_MINUTE_MILLISECONDS);
      } else {
        periodicFetchingStarted = false;
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const oldUseLastKnownPositionCurrentlyUsedBy = useLastKnownPositionCurrentlyUsedBy;
    useLastKnownPositionCurrentlyUsedBy += 1;
    if (
      navigator.geolocation &&
      oldUseLastKnownPositionCurrentlyUsedBy === 0 &&
      !periodicFetchingStarted
    ) {
      periodicFetchingStarted = true;
      getCurrentPosition()
        .then(handlePeriodicCurrentLocationReceived)
        .catch(() => {
          //ignored
        });
    }

    return () => {
      useLastKnownPositionCurrentlyUsedBy -= 1;
    };
  }, [handlePeriodicCurrentLocationReceived]);

  const lastKnownPosition = useSelector(getLastKnownPosition);

  if (!navigator.geolocation) {
    return null;
  }
  return lastKnownPosition;
}
