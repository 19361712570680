import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  RoutePlanTask,
  RoutePlanTaskActivityOption,
  RoutePlanTaskResult,
  Unit,
  UnitUrl,
  WorkType,
} from "@co-common-libs/resources";
import {sortByOrderMember} from "@co-common-libs/utils";
import {PureComponent} from "app-utils";
import _ from "lodash";
import React from "react";
import {
  ActivityOptionWithErrorChecks,
  ResultWithErrorChecks,
  RoutePlanTaskRow,
} from "./route-plan-task-row";

interface RowData {
  activityOption?: ActivityOptionWithErrorChecks;
  customer?: Customer;
  result?: ResultWithErrorChecks;
  task?: RoutePlanTask;
}

function buildActivityOptionRowData(
  activityOption: RoutePlanTaskActivityOption,
  resultsPerActivityOption: ReadonlyMap<string, readonly RoutePlanTaskResult[]>,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  routePlanTask: RoutePlanTask,
  customerHasSpecificPriceGroup: boolean,
  workType?: WorkType,
): RowData[] {
  const rows: RowData[] = [];
  const results = resultsPerActivityOption.get(activityOption.url);
  const priceGroup = priceGroupLookup(activityOption.activity);

  const incorrectWorkType = !!(workType && !workType.pricegroups.includes(activityOption.activity));
  let allPriceGroupItemsExistsOnActivity = true;
  let routeCustomerIsAllowedToUseSpecificPriceGroup = true;
  let routeCustomerIsAllowedToUseUnspecificPriceGroup = true;
  if (priceGroup) {
    if (priceGroup.customers.length) {
      routeCustomerIsAllowedToUseSpecificPriceGroup = priceGroup.customers.includes(
        routePlanTask.customer,
      );
    } else if (customerHasSpecificPriceGroup) {
      routeCustomerIsAllowedToUseUnspecificPriceGroup = false;
    }
    priceGroup.priceGroupItemSet.forEach((p) => {
      const priceItem = priceItemLookup(p.priceItem);
      if (allPriceGroupItemsExistsOnActivity && priceItem && priceItem.active) {
        allPriceGroupItemsExistsOnActivity =
          !!results && results.some((result) => result.specification === p.priceItem);
      }
    });
  }
  if (results) {
    sortByOrderMember(results).forEach((result) => {
      const priceItemExistsOnGroup =
        priceGroup &&
        priceGroup.priceGroupItemSet.some((p) => p.priceItem === result.specification);
      rows.push({
        result: {
          priceItemMissingOnGroup: !priceItemExistsOnGroup,
          routePlanTaskResult: result,
        },
      });
    });
  }
  const activityWithErrorChecks = {
    activityOption,
    incorrectWorkType,
    priceItemsMissing: !allPriceGroupItemsExistsOnActivity,
    routeCustomerIsNotAllowedToUseSpecificPriceGroup:
      !routeCustomerIsAllowedToUseSpecificPriceGroup,
    routeCustomerIsNotAllowedToUseUnspecificPriceGroup:
      !routeCustomerIsAllowedToUseUnspecificPriceGroup,
  };
  if (rows.length) {
    rows[0].activityOption = activityWithErrorChecks;
  } else {
    rows.push({activityOption: activityWithErrorChecks});
  }
  return rows;
}

function buildTaskRowData(
  task: RoutePlanTask,
  activityOptionsPerTask: ReadonlyMap<string, readonly RoutePlanTaskActivityOption[]>,
  resultsPerActivityOption: ReadonlyMap<string, readonly RoutePlanTaskResult[]>,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  customersWithSpecificPriceGroups: Set<string>,
  workType?: WorkType,
): RowData[] {
  const rows: RowData[] = [];
  const activityOptions = activityOptionsPerTask.get(task.url);
  const customerHasSpecificPriceGroup = customersWithSpecificPriceGroups.has(task.customer);
  if (activityOptions) {
    _.sortBy(activityOptions, (activityOption) => {
      const priceGroup = priceGroupLookup(activityOption.activity);
      return (priceGroup && priceGroup.identifier) || "";
    }).forEach((activityOption) => {
      const activityOptionRows = buildActivityOptionRowData(
        activityOption,
        resultsPerActivityOption,
        priceItemLookup,
        priceGroupLookup,
        task,
        customerHasSpecificPriceGroup,
        workType,
      );
      rows.push(...activityOptionRows);
    });
  }
  if (rows.length > 0) {
    rows[0].task = task;
  } else {
    rows.push({task});
  }
  const customerURL = task.customer;
  const customer = customerLookup(customerURL);
  if (customer) {
    if (rows.length > 1) {
      rows[1].customer = customer;
    } else {
      rows.push({customer});
    }
  }
  return rows;
}

interface RoutePlanTaskRowsProps {
  activityOptionsPerTask: ReadonlyMap<string, readonly RoutePlanTaskActivityOption[]>;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  customersWithSpecificPriceGroups: Set<string>;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  resultsPerActivityOption: ReadonlyMap<string, readonly RoutePlanTaskResult[]>;
  routePlanTask: RoutePlanTask;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workType?: WorkType | undefined;
}

export class RoutePlanTaskRows extends PureComponent<RoutePlanTaskRowsProps> {
  render(): React.JSX.Element {
    const {
      activityOptionsPerTask,
      customerLookup,
      customerSettings,
      customersWithSpecificPriceGroups,
      priceGroupLookup,
      priceItemLookup,
      resultsPerActivityOption,
      routePlanTask,
      unitLookup,
      workType,
    } = this.props;

    const rowsData = buildTaskRowData(
      routePlanTask,
      activityOptionsPerTask,
      resultsPerActivityOption,
      priceGroupLookup,
      priceItemLookup,
      customerLookup,
      customersWithSpecificPriceGroups,
      workType,
    );

    const rows = rowsData.map((rowData) => {
      const {activityOption, customer, result, task} = rowData;
      console.assert(result || activityOption || task || customer);
      const key =
        (result && result.routePlanTaskResult.url) ||
        (activityOption && activityOption.activityOption.url) ||
        (task && task.url) ||
        "";
      return (
        <RoutePlanTaskRow
          customer={customer}
          customerSettings={customerSettings}
          key={key}
          priceGroupLookup={priceGroupLookup}
          priceItemLookup={priceItemLookup}
          routePlanTask={task}
          routePlanTaskActivityOptionWithErrorChecks={activityOption}
          routePlanTaskResultWithErrorChecks={result}
          unitLookup={unitLookup}
        />
      );
    });

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{rows}</>;
  }
}
