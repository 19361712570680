import type {ClassNameMap} from "@material-ui/styles/withStyles/withStyles";
import {AppBar, AppBarClassKey, AppBarProps, createStyles, makeStyles} from "@material-ui/core";
import React, {useMemo} from "react";
import {useIsOnline} from "react-use-is-online";

const useOfflineStyles = makeStyles((theme) =>
  createStyles({
    colorOffline: {
      backgroundColor: theme.palette.grey[600],
    },
  }),
);

export const OfflineAwareAppBar = React.forwardRef(function OfflineAwareAppBar(
  props: Omit<AppBarProps, "style">,
  ref,
): React.JSX.Element {
  const {isOffline} = useIsOnline();
  const offlineClasses = useOfflineStyles();

  const classes: Partial<ClassNameMap<AppBarClassKey>> = useMemo(() => {
    return isOffline
      ? {
          colorDefault: offlineClasses.colorOffline,
          colorPrimary: offlineClasses.colorOffline,
          colorSecondary: offlineClasses.colorOffline,
        }
      : {};
  }, [isOffline, offlineClasses.colorOffline]);

  const {children, ...rest} = props;
  return (
    <AppBar ref={ref} {...rest} classes={classes}>
      {children}
    </AppBar>
  );
});
