import {
  KrPerLiterFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecification,
  ProductUrl,
} from "@co-common-libs/resources";
import {ConnectedSingleProductDialog} from "@co-frontend-libs/connected-components";
import {actions, getCustomerSettings, getProductLookup} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Card, CardContent, CardHeader, IconButton} from "@material-ui/core";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface FuelSurchargeInvoiceProductProps {
  specification: KrPerLiterFuelSurchargeSpecification | PricePercentFuelSurchargeSpecification;
}

export const FuelSurchargeInvoiceProduct = React.memo(function FuelSurchargeInvoiceProduct(
  props: FuelSurchargeInvoiceProductProps,
): React.JSX.Element {
  const {specification} = props;

  const {invoiceLineProduct} = specification;

  const intl = useIntl();

  const dispatch = useDispatch();

  const productLookup = useSelector(getProductLookup);

  const customerSettings = useSelector(getCustomerSettings);

  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const setProductDialogOpenTrue = useCallWithTrue(setProductDialogOpen);
  const setProductDialogOpenFalse = useCallWithFalse(setProductDialogOpen);

  const handleProductDialogOk = useCallback(
    (productUrl: ProductUrl) => {
      dispatch(
        actions.update(specification.url, [{member: "invoiceLineProduct", value: productUrl}]),
      );
      setProductDialogOpen(false);
    },
    [dispatch, specification.url],
  );

  const handleClearProductButton = useCallback((): void => {
    dispatch(actions.update(specification.url, [{member: "invoiceLineProduct", value: null}]));
  }, [dispatch, specification.url]);

  const product = invoiceLineProduct ? productLookup(invoiceLineProduct) : null;

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            defaultMessage: "Fakturavare",
          })}
        />
        <CardContent>
          <Button color="primary" onClick={setProductDialogOpenTrue} variant="contained">
            <FormattedMessage defaultMessage="Vælg vare" />
          </Button>
          {product ? (
            <div>
              {product.name}: ({product.catalogNumber})
              {!customerSettings.economicSync ? (
                <IconButton onClick={handleClearProductButton}>
                  <CloseIcon />
                </IconButton>
              ) : null}
            </div>
          ) : null}
        </CardContent>
      </Card>
      <ConnectedSingleProductDialog
        onCancel={setProductDialogOpenFalse}
        onOk={handleProductDialogOk}
        open={productDialogOpen}
      />
    </>
  );
});
