import {Location} from "@co-common-libs/resources";
import {Typography} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {LocationLine} from "./info-lines/location-line";
import {PhoneLine} from "./info-lines/phone-line";

export interface LocationInfoProps {
  canSeeLocationCustomerName?: boolean;
  location: Location;
}

export function LocationInfo(props: LocationInfoProps): React.JSX.Element {
  const {location} = props;
  const {address, attention, cellphone, name, phone} = location;

  return (
    <>
      {attention && (
        <Typography variant="body2">
          <FormattedMessage defaultMessage="Att: {attention}" values={{attention}} />
        </Typography>
      )}
      {name && <Typography variant="body2">{name}</Typography>}
      {address && <LocationLine location={location} />}
      {cellphone && <PhoneLine phone={cellphone} type="cellphone" />}
      {phone && <PhoneLine phone={phone} type="landline" />}
    </>
  );
}
