import {Journal, Location} from "@co-common-libs/resources";
import {emphasizeSubstrings} from "@co-frontend-libs/utils";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {Match} from "app-utils";
import HistoryIcon from "mdi-react/HistoryIcon";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {areEqual} from "react-window";
import {useFieldItemStyles} from "../utils";

function buildMatchesElement(matches: readonly Match[]): React.JSX.Element {
  const matchElements = matches.map((entry, index) => {
    const {label, matching, text} = entry;
    const textWithMatches = emphasizeSubstrings(text, matching);
    return (
      <span key={index}>
        <em>{label}:</em> {textWithMatches}{" "}
      </span>
    );
  });
  const matchElement = <span>{matchElements}</span>;
  return matchElement;
}

interface FieldSingleSelectionListItemProps {
  field: Location;
  journalEntries: readonly Journal[] | undefined;
  matches?: readonly Match[] | undefined;
  onSelect(value: string): void;
  recentlyUsed?: boolean | undefined;
  style?: React.CSSProperties;
  value: string;
  withIcons: boolean;
}

export const FieldSingleSelectionListItem = React.memo(function FieldSingleSelectionListItem(
  props: FieldSingleSelectionListItemProps,
): React.JSX.Element {
  const {field, journalEntries, matches, onSelect, recentlyUsed, style, value, withIcons} = props;

  const intl = useIntl();

  const Icon = recentlyUsed ? HistoryIcon : undefined;

  const handleClick = useCallback(() => onSelect(value), [onSelect, value]);
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        onSelect(value);
      }
    },
    [onSelect, value],
  );

  const code = field.fieldNumber || intl.formatMessage({defaultMessage: "Intet marknr."});
  const crop = field.fieldCrop || "";
  const areaString = intl.formatNumber(field.fieldAreaHa || 0, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const primary = crop ? `${code}, ${areaString} ha: ${crop}` : `${code}, ${areaString} ha`;

  const matchElements = matches ? buildMatchesElement(matches) : undefined;
  const secondary = (
    <>
      {matchElements}
      {journalEntries?.length ? (
        <strong>
          <FormattedMessage defaultMessage="Note:" />{" "}
          {journalEntries.map((journal) => journal.entry).join(". ")}
        </strong>
      ) : undefined}
    </>
  );

  const classes = useFieldItemStyles();

  return (
    <ListItem button onClick={handleClick} onKeyDown={handleKeyDown} style={style || {}}>
      {withIcons && Icon ? (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      ) : null}
      <ListItemText
        classes={{
          primary: classes.overflowOne,
          secondary: classes.overflowOne,
        }}
        inset={withIcons && !Icon}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
}, areEqual);
