import {Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";

const messages = defineMessages({
  allCheckbox: {
    defaultMessage: "Alle",
    id: "filter-dialog.label.all",
  },
});

interface MultiSelectableListItemProps<T extends string> {
  isSelected: boolean;
  onCheck: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: T,
    checked: boolean,
  ) => void;
  primaryText: string;
  secondaryText?: string | undefined;
  value: T;
}

export class MultiSelectableListItem<T extends string> extends PureComponent<
  MultiSelectableListItemProps<T>
> {
  @bind
  handleCheck(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    this.props.onCheck(event, this.props.value, !this.props.isSelected);
  }
  render(): React.JSX.Element {
    const {isSelected, primaryText, secondaryText} = this.props;
    return (
      <ListItem button onClick={this.handleCheck}>
        <ListItemIcon>
          <Checkbox checked={isSelected} disableRipple edge="start" tabIndex={-1} />
        </ListItemIcon>
        <ListItemText primary={primaryText} secondary={secondaryText} />
      </ListItem>
    );
  }
}

interface MultiSelectableListProps<T extends string> {
  data: readonly {
    readonly primaryText: string;
    readonly secondaryText?: string | undefined;
    readonly value: T;
  }[];
  emptyPrimaryText?: string | undefined;
  emptySecondaryText?: string | undefined;
  onChange: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: ReadonlySet<T>) => void;
  selectAllValues?: ReadonlySet<T>;
  style?: React.CSSProperties;
  value: ReadonlySet<T>;
}

export class MultiSelectableList<T extends string> extends PureComponent<
  MultiSelectableListProps<T>
> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleItemCheck(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    itemValue: T,
    include: boolean,
  ): void {
    const oldSet = this.props.value;
    const newSet = new Set(Array.from(oldSet));
    if (include) {
      newSet.add(itemValue);
    } else {
      newSet.delete(itemValue);
    }
    this.props.onChange(event, new Set(Array.from(newSet).sort()));
  }
  @bind
  handleSetAllChecked(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    const allChecked = _.isEqual(this.props.value, this.props.selectAllValues);
    const checked = !allChecked;
    const {onChange, selectAllValues} = this.props;
    if (!selectAllValues) {
      return;
    }
    if (checked) {
      onChange(event, selectAllValues);
    } else {
      onChange(event, new Set());
    }
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {data, emptyPrimaryText, emptySecondaryText, style, value} = this.props;
    let allCheckboxListItem;
    if (this.props.selectAllValues) {
      const allChecked = _.isEqual(value, this.props.selectAllValues);
      allCheckboxListItem = (
        <ListItem button onClick={this.handleSetAllChecked}>
          <ListItemIcon>
            <Checkbox checked={allChecked} disableRipple edge="start" tabIndex={-1} />
          </ListItemIcon>
          <ListItemText primary={formatMessage(messages.allCheckbox)} />
        </ListItem>
      );
    }
    const entryList = data.map((entryData) => {
      const entryValue = entryData.value;
      return (
        <MultiSelectableListItem<T>
          isSelected={value.has(entryValue)}
          key={entryValue}
          onCheck={this.handleItemCheck}
          primaryText={entryData.primaryText}
          secondaryText={entryData.secondaryText}
          value={entryValue}
        />
      );
    });
    let emptyEntry;
    if (!entryList.length && emptyPrimaryText) {
      emptyEntry = (
        <ListItem disabled>
          <ListItemText primary={emptyPrimaryText} secondary={emptySecondaryText} />
        </ListItem>
      );
    }
    return (
      <List style={style || {}}>
        {allCheckboxListItem}
        {allCheckboxListItem ? <Divider /> : null}
        {entryList}
        {emptyEntry}
      </List>
    );
  }
}
