import {Config} from "@co-common-libs/config";
import {Customer, Machine, WorkType} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {ResponsiveInfoDialog} from "@co-frontend-libs/components";
import {AppState, getCustomerSettings} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  ok: {defaultMessage: "OK", id: "dialog.label.ok"},
  title: {
    defaultMessage: "Kopi af opgave",
    id: "task-instance.dialog-title.copy-of-task",
  },
});

interface CopiedDialogStateProps {
  customerSettings: Config;
}

interface CopiedDialogOwnProps {
  customer?: Customer | undefined;
  machineList: readonly Machine[];
  onRequestClose: () => void;
  open: boolean;
  workType?: WorkType | undefined;
}

type CopiedDialogProps = CopiedDialogOwnProps & CopiedDialogStateProps;

class CopiedDialog extends PureComponent<CopiedDialogProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): React.JSX.Element {
    const {customer, machineList, open, workType} = this.props;
    const {formatMessage} = this.context;
    const customerName = customer ? customer.name : null;
    const workTypeString = getWorkTypeString(workType);
    let customerBlock: React.JSX.Element | undefined;
    if (customer) {
      customerBlock = (
        <tr>
          <FormattedMessage defaultMessage="Kunde" id="task-instance.label.customer" tagName="td" />
          <td>{customerName}</td>
        </tr>
      );
    }
    let machinesRow: React.JSX.Element | undefined;
    if (this.props.customerSettings.taskCopyFields.includes("machineuseSet")) {
      const machines = machineList
        ? machineList
            .map((machine) => {
              return `${machine.c5_machine}: ${machine.name}`;
            })
            .join(", ")
        : "";
      machinesRow = (
        <tr>
          {this.props.customerSettings.machineLabelVariant === "MACHINE" ? (
            <FormattedMessage
              defaultMessage="Maskiner"
              id="task-instance.label.machines"
              tagName="td"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Køretøjer"
              id="task-instance.label.vehicles"
              tagName="td"
            />
          )}
          <td>{machines}</td>
        </tr>
      );
    }
    return (
      <ResponsiveInfoDialog
        autoFocusClose
        onClose={this.props.onRequestClose}
        open={open}
        title={formatMessage(messages.title)}
      >
        <DialogContent>
          <table>
            <tbody>
              {customerBlock}
              <tr>
                <FormattedMessage
                  defaultMessage="Arbejdsområde"
                  id="task-instance.label.task-type"
                  tagName="td"
                />
                <td>{workTypeString}</td>
              </tr>
              {machinesRow}
            </tbody>
          </table>
        </DialogContent>
      </ResponsiveInfoDialog>
    );
  }
}

const ConnectedCopiedDialog: React.ComponentType<CopiedDialogOwnProps> = connect<
  CopiedDialogStateProps,
  object,
  CopiedDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, CopiedDialogStateProps>({
    customerSettings: getCustomerSettings,
  }),
  {},
)(CopiedDialog);

export default ConnectedCopiedDialog;
