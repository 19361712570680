import {CustomerUrl, MachineUrl, PriceGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {IconButton, TableCell, TableRow, useTheme} from "@material-ui/core";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import React, {useState} from "react";
import {FormattedNumber} from "react-intl";
import {useSelector} from "react-redux";
import {FuelSurchargePeriodMissingDialog} from "./fuel-surcharge-period-missing-dialog";
import {SurchargeReasonDialog} from "./surcharge-reason-dialog";

interface PricePercentSurchargeLineProps {
  invoiceCorrectionEnabled: boolean;
  missingSpecificationEntry: boolean;
  text: string;
  uses: ReadonlySet<{
    readonly customer: CustomerUrl | null;
    readonly machine?: MachineUrl | null;
    readonly variant?: PriceGroupUrl | null;
    readonly workType?: WorkTypeUrl | null;
  }>;
  value: number | null;
}

export const PricePercentSurchargeLine = React.memo(function PricePercentSurchargeLine(
  props: PricePercentSurchargeLineProps,
): React.JSX.Element {
  const {invoiceCorrectionEnabled, missingSpecificationEntry, text, uses, value} = props;

  const customerSettings = useSelector(getCustomerSettings);

  const theme = useTheme();

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const setInfoDialogOpenTrue = useCallWithTrue(setInfoDialogOpen);
  const setInfoDialogOpenFalse = useCallWithFalse(setInfoDialogOpen);

  const [missingSpecificationDialogOpen, setMissingSpecificationDialogOpen] = useState(false);
  const setMissingSpecificationDialogOpenTrue = useCallWithTrue(setMissingSpecificationDialogOpen);
  const setMissingSpecificationDialogOpenFalse = useCallWithFalse(
    setMissingSpecificationDialogOpen,
  );

  return (
    <TableRow>
      <TableCell>
        {text}
        {missingSpecificationEntry ? (
          <IconButton onClick={setMissingSpecificationDialogOpenTrue}>
            <AlertCircleIcon
              color={
                customerSettings.economicSync
                  ? theme.palette.warning.main
                  : theme.palette.error.main
              }
            />
          </IconButton>
        ) : null}
        <IconButton onClick={setInfoDialogOpenTrue}>
          <InformationOutlineIcon />
        </IconButton>
      </TableCell>
      <TableCell style={{textAlign: "right"}}>
        {value !== null ? (
          <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={value} />
        ) : null}
      </TableCell>
      {invoiceCorrectionEnabled ? <TableCell /> : null}
      <TableCell>%</TableCell>
      {customerSettings.showNoteOnInvoiceLineTable ? <TableCell /> : null}
      <SurchargeReasonDialog onClose={setInfoDialogOpenFalse} open={infoDialogOpen} uses={uses} />
      <FuelSurchargePeriodMissingDialog
        onClose={setMissingSpecificationDialogOpenFalse}
        open={missingSpecificationDialogOpen}
      />
    </TableRow>
  );
});
