import React from "react";
import {
  ConnectedMultipleWorkTypesDialog,
  ConnectedWorkTypeDialog,
} from "./connected-work-type-dialog";
import {
  alwaysEmptyArray,
  alwaysFalse,
  getActiveFilteredBothPrimaryWorkTypeArray,
} from "./selectors";
import {MultipleWorkTypesDialogProps, WorkTypeDialogProps} from "./types";

export const ConnectedCombinedWorkTypesDialog = React.memo(
  function ConnectedCombinedWorkTypesDialog(props: WorkTypeDialogProps): React.JSX.Element {
    return (
      <ConnectedWorkTypeDialog
        suggestRecentlyUsedWorkTypesSelector={alwaysFalse}
        workTypeArraySelector={getActiveFilteredBothPrimaryWorkTypeArray}
        {...props}
      />
    );
  },
);

export const ConnectedMultipleCombinedWorkTypesDialog = React.memo(
  function ConnectedMultipleCombinedWorkTypesDialog(
    props: MultipleWorkTypesDialogProps,
  ): React.JSX.Element {
    return (
      <ConnectedMultipleWorkTypesDialog
        suggestRecentlyUsedWorkTypesSelector={alwaysFalse}
        taskArraySelector={alwaysEmptyArray}
        workTypeArraySelector={getActiveFilteredBothPrimaryWorkTypeArray}
        {...props}
      />
    );
  },
);
