import {Config} from "@co-common-libs/config";
import {PatchUnion, Settings, UserProfile, UserUrl} from "@co-common-libs/resources";
import {formatDateTime} from "@co-common-libs/utils";
import {
  actions,
  AppState,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsArray,
  getUserUserProfileLookup,
  makeQueryParameterGetter,
} from "@co-frontend-libs/redux";
import {PartialNavigationKind} from "@co-frontend-libs/routing-sync-history";
import {Button, Tab, Tabs} from "@material-ui/core";
import {
  MenuToolbar,
  PageLayout,
  PrivacyPolicy,
  ProcessorAgreement,
  ProcessorAgreementDialog,
  TermsOfService,
  TermsOfServiceDialog,
} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {LogoTab} from "./logo-tab";

const messages = defineMessages({
  approve: {
    defaultMessage: "Godkend",
    id: "account.label.approve",
  },
  privacyPolicy: {
    defaultMessage: "Privatlivspolitik",
    id: "account.label.privacyPolicy",
  },
  processorAgreement: {
    defaultMessage: "Databehandleraftale",
    id: "account.label.processorAgreement",
  },
  termsOfService: {
    defaultMessage: "Abonnementsvilkår",
    id: "account.label.termsOfService",
  },
  title: {
    defaultMessage: "Konto",
    id: "account.label.title",
  },
});

interface AccountPageStateProps {
  currentUserURL: UserUrl | null;
  customerSettings: Config;
  settingsArray: readonly Settings[];
  tab: string;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface AccountPageDispatchProps {
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface AccountPageOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type AccountPageProps = AccountPageDispatchProps & AccountPageOwnProps & AccountPageStateProps;

interface AccountPageState {
  processorAgreementDialogOpen: boolean;
  termsOfServiceDialogOpen: boolean;
}

class AccountPage extends PureComponent<AccountPageProps, AccountPageState> {
  static contextType = IntlContext;

  context!: React.ContextType<typeof IntlContext>;
  state: AccountPageState = {
    processorAgreementDialogOpen: false,
    termsOfServiceDialogOpen: false,
  };

  @bind
  handleApprovalButtonClick(): void {
    this.setState({processorAgreementDialogOpen: true});
  }

  @bind
  handleProcessorAgreementConfirmed(): void {
    this.setState({processorAgreementDialogOpen: false});
    const settings = this.props.settingsArray[0];
    const approvedTimestamp = new Date();
    this.props.update(settings.url, [
      {member: "policyApproved", value: approvedTimestamp.toISOString()},
      {member: "policyApprovedBy", value: this.props.currentUserURL},
    ]);
  }

  @bind
  handleProcessorAgreementDialogCancel(): void {
    this.setState({processorAgreementDialogOpen: false});
  }

  @bind
  handleTabChange(_event: React.ChangeEvent<unknown>, value: any): void {
    this.props.putQueryKey("tab", value || "");
  }

  @bind
  handleTermsOfServiceApprovalButtonClick(): void {
    this.setState({termsOfServiceDialogOpen: true});
  }

  @bind
  handleTermsOfServiceConfirmed(): void {
    this.setState({termsOfServiceDialogOpen: false});
    const settings = this.props.settingsArray[0];
    const approvedTimestamp = new Date();
    this.props.update(settings.url, [
      {
        member: "termsOfServiceApproved",
        value: approvedTimestamp.toISOString(),
      },
      {member: "termsOfServiceApprovedBy", value: this.props.currentUserURL},
    ]);
  }

  @bind
  handleTermsOfServiceDialogCancel(): void {
    this.setState({termsOfServiceDialogOpen: false});
  }

  render(): React.JSX.Element {
    const intl = this.context;
    const {settingsArray, tab, userUserProfileLookup} = this.props;
    const settings = settingsArray[0];

    let content;
    if (tab === "termsOfService") {
      const approvalTimestamp = settings.termsOfServiceApproved;
      const approvedByURL = settings.termsOfServiceApprovedBy;
      const approvedByUserProfile = approvedByURL ? userUserProfileLookup(approvedByURL) : null;
      const approvalStatus = approvalTimestamp ? (
        <FormattedMessage
          defaultMessage="Godkendt {approvalTimestamp} af {initials}"
          id="account.label.approved"
          values={{
            approvalTimestamp: formatDateTime(approvalTimestamp),
            initials: approvedByUserProfile?.alias,
          }}
        />
      ) : (
        <div>
          <span style={{color: "red", marginRight: "1em"}}>
            <FormattedMessage defaultMessage="Ikke godkendt" id="account.label.notApproved" />
          </span>
          <Button
            color="secondary"
            onClick={this.handleTermsOfServiceApprovalButtonClick}
            variant="contained"
          >
            {intl.formatMessage(messages.approve)}
          </Button>
        </div>
      );
      content = (
        <div style={{backgroundColor: "#FFF", padding: "1em"}}>
          <div style={{textAlign: "right"}}>{approvalStatus}</div>
          <TermsOfService />
        </div>
      );
    } else if (tab === "processorAgreement") {
      const approvalTimestamp = settings.policyApproved;
      const approvedByURL = settings.policyApprovedBy;
      const approvedByUserProfile = approvedByURL ? userUserProfileLookup(approvedByURL) : null;
      const approvalStatus = approvalTimestamp ? (
        <FormattedMessage
          defaultMessage="Godkendt {approvalTimestamp} af {initials}"
          id="account.label.approved"
          values={{
            approvalTimestamp: formatDateTime(approvalTimestamp),
            initials: approvedByUserProfile?.alias,
          }}
        />
      ) : (
        <div>
          <span style={{color: "red", marginRight: "1em"}}>
            <FormattedMessage defaultMessage="Ikke godkendt" id="account.label.notApproved" />
          </span>
          <Button color="secondary" onClick={this.handleApprovalButtonClick} variant="contained">
            {intl.formatMessage(messages.approve)}
          </Button>
        </div>
      );
      content = (
        <div style={{backgroundColor: "#FFF", padding: "1em"}}>
          <div style={{textAlign: "right"}}>{approvalStatus}</div>
          <ProcessorAgreement customerSettings={this.props.customerSettings} />
        </div>
      );
    } else if (tab === "privacyPolicy") {
      content = (
        <div style={{backgroundColor: "#FFF", padding: "1em"}}>
          <PrivacyPolicy />
        </div>
      );
    } else if (tab === "logo") {
      content = (
        <div style={{padding: "1em"}}>
          <LogoTab />
        </div>
      );
    }

    return (
      <PageLayout
        tabs={
          <Tabs
            onChange={this.handleTabChange}
            value={this.props.tab}
            variant={bowser.mobile ? "fullWidth" : "standard"}
          >
            <Tab label={intl.formatMessage(messages.termsOfService)} value="termsOfService" />
            <Tab
              label={intl.formatMessage(messages.processorAgreement)}
              value="processorAgreement"
            />
            <Tab label={intl.formatMessage(messages.privacyPolicy)} value="privacyPolicy" />
            <Tab label={intl.formatMessage({defaultMessage: "Logo"})} value="logo" />
          </Tabs>
        }
        toolbar={
          <MenuToolbar
            onMenuButton={this.props.onMenuButton}
            title={intl.formatMessage(messages.title)}
          />
        }
      >
        {content}
        <ProcessorAgreementDialog
          onCancel={this.handleProcessorAgreementDialogCancel}
          onOk={this.handleProcessorAgreementConfirmed}
          open={this.state.processorAgreementDialogOpen}
        />
        <TermsOfServiceDialog
          onCancel={this.handleTermsOfServiceDialogCancel}
          onOk={this.handleTermsOfServiceConfirmed}
          open={this.state.termsOfServiceDialogOpen}
        />
      </PageLayout>
    );
  }
}

const ConnectedAccountPage: React.ComponentType<AccountPageOwnProps> = connect<
  AccountPageStateProps,
  AccountPageDispatchProps,
  AccountPageOwnProps,
  AppState
>(
  createStructuredSelector<AppState, AccountPageStateProps>({
    currentUserURL: getCurrentUserURL,
    customerSettings: getCustomerSettings,
    settingsArray: getSettingsArray,
    tab: makeQueryParameterGetter("tab", "termsOfService"),
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {
    putQueryKey: actions.putQueryKey,
    update: actions.update,
  },
)(AccountPage);

export {ConnectedAccountPage as AccountPage};
