import {EmployeeGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {MultipleEmployeeGroupDialog, useModal} from "@co-frontend-libs/components";
import {
  ConnectedMultipleCombinedWorkTypesDialog,
  ConnectedMultipleDepartmentsDialog,
} from "@co-frontend-libs/connected-components";
import {
  getActiveEmployeeGroupArray,
  getCustomerSettings,
  isEmployeeGroupsActivated,
} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {IconButton} from "@material-ui/core";
import FilterIcon from "mdi-react/FilterIcon";
import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {FilterTypeChoices, FilterTypeSelectionDialog} from "./filter-type-selection-dialog";

interface FilterButtonProps {
  onSelectedDepartmentIdentifierSetChange: (value: ReadonlySet<string>) => void;
  onSelectedEmployeeGroupUrlSetChange?: (value: ReadonlySet<EmployeeGroupUrl>) => void;
  onSelectedWorkTypeURLSetChange: (value: ReadonlySet<WorkTypeUrl>) => void;
  selectedDepartmentIdentifierSet: ReadonlySet<string>;
  selectedEmployeeGroupUrlSet?: ReadonlySet<EmployeeGroupUrl>;
  selectedWorkTypeURLSet: ReadonlySet<WorkTypeUrl>;
}
export const FilterButton = React.memo(function FilterButton(
  props: FilterButtonProps,
): React.JSX.Element {
  const {
    onSelectedDepartmentIdentifierSetChange,
    onSelectedEmployeeGroupUrlSetChange,
    onSelectedWorkTypeURLSetChange,
    selectedDepartmentIdentifierSet,
    selectedEmployeeGroupUrlSet,
    selectedWorkTypeURLSet,
  } = props;
  const [selectFilterTypeDialogOpen, setSelectFilterTypeDialogOpen] = useState(false);
  const setSelectFilterTypeDialogOpenFalse = useCallWithFalse(setSelectFilterTypeDialogOpen);

  const [workTypeFilterDialogOpen, setWorkTypeFilterDialogOpen] = useState(false);
  const setWorkTypeFilterDialogOpenFalse = useCallWithFalse(setWorkTypeFilterDialogOpen);

  const [departmentFilterDialogOpen, setDepartmentFilterDialogOpen] = useState(false);
  const setDepartmentFilterDialogOpenFalse = useCallWithFalse(setDepartmentFilterDialogOpen);
  const customerSettings = useSelector(getCustomerSettings);
  const activeEmployeeGroupArray = useSelector(getActiveEmployeeGroupArray);
  const activateEmployeeGroups = useSelector(isEmployeeGroupsActivated);

  const [employeeGroupDialog, promptEmployeeDialog] = useModal(MultipleEmployeeGroupDialog);

  const handleEmployeeGroupFilterDialog = useCallback(
    async (selected: ReadonlySet<EmployeeGroupUrl>) => {
      const selectedFromDialog = await promptEmployeeDialog({
        employeeGroupArray: activeEmployeeGroupArray,
        selected,
      });
      if (selectedFromDialog && onSelectedEmployeeGroupUrlSetChange) {
        onSelectedEmployeeGroupUrlSetChange(selectedFromDialog);
      }
    },
    [activeEmployeeGroupArray, onSelectedEmployeeGroupUrlSetChange, promptEmployeeDialog],
  );

  const handleFilterTypeDialogOk = useCallback(
    (filterType: FilterTypeChoices) => {
      setSelectFilterTypeDialogOpen(false);
      if (filterType === "workType") {
        setWorkTypeFilterDialogOpen(true);
      } else if (filterType === "deparment") {
        setDepartmentFilterDialogOpen(true);
      } else if (filterType === "employeeGroup") {
        if (selectedEmployeeGroupUrlSet)
          handleEmployeeGroupFilterDialog(selectedEmployeeGroupUrlSet);
      }
    },
    [handleEmployeeGroupFilterDialog, selectedEmployeeGroupUrlSet],
  );

  const {enableOrderTaskDepartmentFilter, enableOrderTaskWorkTypeFilter} = customerSettings;

  const handleFilterButtonClick = useCallback(() => {
    if (
      (enableOrderTaskWorkTypeFilter && enableOrderTaskDepartmentFilter) ||
      (activateEmployeeGroups && enableOrderTaskDepartmentFilter) ||
      (activateEmployeeGroups && enableOrderTaskWorkTypeFilter)
    ) {
      setSelectFilterTypeDialogOpen(true);
    } else if (enableOrderTaskWorkTypeFilter) {
      setWorkTypeFilterDialogOpen(true);
    } else if (activateEmployeeGroups && selectedEmployeeGroupUrlSet) {
      handleEmployeeGroupFilterDialog(selectedEmployeeGroupUrlSet);
    } else {
      setDepartmentFilterDialogOpen(true);
    }
  }, [
    enableOrderTaskWorkTypeFilter,
    enableOrderTaskDepartmentFilter,
    activateEmployeeGroups,
    selectedEmployeeGroupUrlSet,
    handleEmployeeGroupFilterDialog,
  ]);

  const handleWorkTypeFilterDialogOk = useCallback(
    (selected: ReadonlySet<WorkTypeUrl>) => {
      setWorkTypeFilterDialogOpen(false);
      onSelectedWorkTypeURLSetChange(selected);
    },
    [onSelectedWorkTypeURLSetChange],
  );

  const handleDepartmentFilterDialogOk = useCallback(
    (selected: ReadonlySet<string>) => {
      onSelectedDepartmentIdentifierSetChange(selected);
      setDepartmentFilterDialogOpen(false);
    },
    [onSelectedDepartmentIdentifierSetChange],
  );

  return (
    <>
      <IconButton onClick={handleFilterButtonClick}>
        <FilterIcon color="#fff" />
      </IconButton>
      <ConnectedMultipleCombinedWorkTypesDialog
        onCancel={setWorkTypeFilterDialogOpenFalse}
        onOk={handleWorkTypeFilterDialogOk}
        open={workTypeFilterDialogOpen}
        selected={selectedWorkTypeURLSet}
      />
      <ConnectedMultipleDepartmentsDialog
        onCancel={setDepartmentFilterDialogOpenFalse}
        onOk={handleDepartmentFilterDialogOk}
        open={departmentFilterDialogOpen}
        selected={selectedDepartmentIdentifierSet}
      />

      {employeeGroupDialog}

      <FilterTypeSelectionDialog
        onCancel={setSelectFilterTypeDialogOpenFalse}
        onOk={handleFilterTypeDialogOk}
        open={selectFilterTypeDialogOpen}
      />
    </>
  );
});
