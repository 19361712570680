import {Customer} from "@co-common-libs/resources";
import {getExtendedCustomerSettings} from "@co-frontend-libs/redux";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {computeDisabledInputFields} from "./compute-disabled-input-fields";
import {computeRequiredInputFields} from "./compute-required-input-fields";
import {computeVisibleInputFields} from "./compute-visible-input-fields";
import {CustomerFormInputDefinitions} from "./customer-form";

export function useCustomerFormInputDefinitions(
  editCustomer: Customer | null,
  offlineOnlyInputs: boolean = false,
): CustomerFormInputDefinitions {
  const customerSettings = useSelector(getExtendedCustomerSettings);

  return useMemo(() => {
    const visibleInputFields = computeVisibleInputFields(offlineOnlyInputs, customerSettings);
    const requiredInputFields = computeRequiredInputFields(
      visibleInputFields,
      offlineOnlyInputs,
      customerSettings,
    );
    const disabledInputFields = computeDisabledInputFields(
      visibleInputFields,
      editCustomer,
      customerSettings,
    );

    return {
      disabledInputFields,
      requiredInputFields,
      visibleInputFields,
    };
  }, [customerSettings, editCustomer, offlineOnlyInputs]);
}
