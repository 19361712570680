import {getDBConnection, StoreWrapper} from "@co-frontend-libs/db-generic";
import {sharePromiseFactory} from "@co-frontend-libs/utils";
import _ from "lodash";
import {SessionData} from "./types";

const dbName = "authentication";
const storeName = "session";

const getAuthStoreWrapper: () => Promise<StoreWrapper> = sharePromiseFactory(() =>
  getDBConnection(dbName, [storeName]).then(
    (connection) => new StoreWrapper(connection, storeName),
  ),
);

export async function deleteAuthenticationDatabase(): Promise<void> {
  const authStoreWrapper = await getAuthStoreWrapper();
  // eslint-disable-next-line no-console
  console.log("closing authentication database");
  await authStoreWrapper._closeConnection();
  // eslint-disable-next-line no-console
  console.log("closing authentication database: done");
  // eslint-disable-next-line no-console
  console.log("deleting authentication database");
  await authStoreWrapper._deleteDatabase();
  // eslint-disable-next-line no-console
  console.log("deleting authentication database: done");
}

export async function closeAuthenticationDatabase(): Promise<void> {
  const authStoreWrapper = await getAuthStoreWrapper();
  // eslint-disable-next-line no-console
  console.log("closing authentication database");
  await authStoreWrapper._closeConnection();
  // eslint-disable-next-line no-console
  console.log("closing authentication database: done");
}

const sessionURL = "session://";

export const loadSession = (): Promise<SessionData> =>
  getAuthStoreWrapper()
    .then((store) => store.fetchInstance(sessionURL))
    .then((instance) => {
      const sessionData = _.omit(instance, "url");
      return sessionData as SessionData;
    });

export const putSession = (data: SessionData): Promise<void> => {
  const withUrl = {url: sessionURL, ...data};
  return getAuthStoreWrapper().then((store) => store.replaceInstance(withUrl));
};

export const clearSession = (): Promise<void> =>
  getAuthStoreWrapper().then((store) => store.removeInstance(sessionURL));
