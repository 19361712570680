import {emptyProductGroup, ProductGroup} from "@co-common-libs/resources";
import {instanceURL} from "frontend-global-config";
import {v4 as uuid} from "uuid";

export function createProductGroup(
  instance: Partial<Omit<ProductGroup, "id" | "url">>,
): ProductGroup {
  const id = uuid();
  const url = instanceURL("productGroup", id);

  return {
    id,
    url,
    ...emptyProductGroup,
    ...instance,
  };
}
