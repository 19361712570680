import {getProjectString} from "@co-common-libs/resources-utils";
import {IntlShape} from "react-intl";
import {EconomicProjectIssue} from "../types";

// eslint-disable-next-line consistent-return
export function economicProjectIssueTranslation(
  issue: EconomicProjectIssue,
  intl: IntlShape,
): string {
  const {instance, issueType} = issue;
  const name = getProjectString(instance);

  switch (issueType) {
    case "barred":
      return intl.formatMessage(
        {
          defaultMessage: "Projektet {name} er spærret i e-conomic",
        },
        {name},
      );
    case "closed":
      return intl.formatMessage(
        {
          defaultMessage: "Projektet {name} er lukket i e-conomic",
        },
        {name},
      );
    case "deleted":
      return intl.formatMessage(
        {
          defaultMessage: "Projektet {name} er slettet i e-conomic",
        },
        {name},
      );
    case "missingRemoteIdentifier":
      return intl.formatMessage(
        {
          defaultMessage: "Projektet {name} mangler projektnummer",
        },
        {name},
      );
    case "missingRemoteUrl":
      return intl.formatMessage(
        {
          defaultMessage: "Projektet {name} er ikke oprettet i e-conomic",
        },
        {name},
      );
  }
}
