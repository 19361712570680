import {Config} from "@co-common-libs/config";
import {
  emptyTask,
  Machine,
  MachineUrl,
  PatchUnion,
  ResourceTypeUnion,
  Role,
  Task,
  TaskPhoto,
  TimerStart,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {dateToString, identifierComparator, makeContainsPredicate} from "@co-common-libs/utils";
import {
  AppbarSearchField,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {ConnectedInternalWorkshopWorkTypeDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  AppState,
  getCurrentRole,
  getCurrentUser,
  getCurrentUserProfile,
  getCurrentUserURL,
  getCustomerSettings,
  getFilteredMachineArray,
  getFilteredMachineLookup,
  getMachineArray,
  getTaskArray,
  getTaskPhotoArray,
  getTimerStartArray,
  getUserId,
  getUserUserProfileLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
  makeQueryParameterGetter,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Tab, Tabs} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {MachineTable} from "./machine-table";
import {ServiceTaskTable} from "./service-task-table";
import {TaskDragPreview} from "./task-drag-preview";
import {TodoTaskTable} from "./todo-task-table";

const messages = defineMessages({
  machines: {defaultMessage: "Maskiner", id: "workshop.tab-label.machines"},
  service: {defaultMessage: "Service", id: "workshop.tab-label.service"},
  tasks: {defaultMessage: "Opgaver", id: "workshop.tab-label.tasks"},
  title: {defaultMessage: "Værksted", id: "workshop.title.workshop"},
  vehicles: {defaultMessage: "Køretøjer", id: "workshop.tab-label.vehicles"},
});

interface WorkshopStateProps {
  currentRole: Role | null;
  currentUser: User | null;
  currentUserID: string | null;
  currentUserProfile: UserProfile | null;
  currentUserURL: UserUrl | null;
  customerSettings: Config;
  filteredMachineArray: readonly Machine[];
  filteredMachineLookup: (url: MachineUrl) => Machine | undefined;
  machineArray: readonly Machine[];
  q: string;
  tab: string;
  taskArray: readonly Task[];
  taskPhotoArray: readonly TaskPhoto[];
  timerStartArray: readonly TimerStart[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface WorkshopDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface WorkshopOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type WorkshopProps = WorkshopDispatchProps & WorkshopOwnProps & WorkshopStateProps;

class Workshop extends PureComponent<WorkshopProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state = {
    workTypeDialogOpen: false,
  };
  @bind
  handleFabButton(): void {
    this.setState({workTypeDialogOpen: true});
  }
  @bind
  handleFilterChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    this.props.putQueryKey("q", value || "");
  }
  @bind
  handleTabChange(_event: React.ChangeEvent<unknown>, value: string): void {
    this.props.putQueryKey("tab", value);
  }
  @bind
  handleWorkTypeDialogCancel(): void {
    this.setState({workTypeDialogOpen: false});
  }
  @bind
  handleWorkTypeDialogOk(workTypeURL: WorkTypeUrl): void {
    const id = uuid();
    const url = instanceURL("task", id);
    const {currentUserURL, customerSettings} = this.props;
    const role = this.props.currentRole;
    const isManager = role && role.manager;
    const params: Task = {
      ...emptyTask,
      createdBy: currentUserURL,
      date: dateToString(new Date()),
      id,
      machineOperator: isManager
        ? customerSettings.defaultTaskEmployee
          ? instanceURL("user", customerSettings.defaultTaskEmployee)
          : null
        : currentUserURL,
      url,
      workType: workTypeURL,
    };
    this.props.create(params);
    this.setState({workTypeDialogOpen: false});
    if (isManager) {
      this.props.go("/internalTask/:id", {id});
    } else {
      this.props.go("/task/:id", {id});
    }
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      filteredMachineArray,
      filteredMachineLookup,
      machineArray,
      tab,
      taskArray,
      userUserProfileLookup,
      workTypeArray,
      workTypeLookup,
    } = this.props;
    let taskSeq = _.sortBy(
      _.sortBy(
        taskArray.filter((t) => !t.order).filter((t) => !t.completed),
        (t) => t.created || null,
      ),
      (t) => t.priority || null,
    );
    if (this.props.customerSettings.workshopWorkTypes.length) {
      const workshopWorkTypeIdentifierSet = new Set(this.props.customerSettings.workshopWorkTypes);
      const workshopWorkTypeURLSet = new Set(
        workTypeArray
          .filter((w) => workshopWorkTypeIdentifierSet.has(w.identifier))
          .map((w) => w.url),
      );
      taskSeq = taskSeq.filter((t) => t.workType && workshopWorkTypeURLSet.has(t.workType));
    }
    let content;
    if (tab === "tasks") {
      const tasksWithPhotos = new Set(this.props.taskPhotoArray.map((instance) => instance.task));
      const withPriority: Task[] = [];
      const withoutPriority: Task[] = [];
      taskSeq.forEach((task) => {
        if (task.priority == null) {
          withoutPriority.push(task);
        } else {
          withPriority.push(task);
        }
      });
      content = (
        <TodoTaskTable
          customerSettings={this.props.customerSettings}
          go={this.props.go}
          machineLookup={filteredMachineLookup}
          tasksWithPhotos={tasksWithPhotos}
          timerStartArray={this.props.timerStartArray}
          update={this.props.update}
          userUserProfileLookup={userUserProfileLookup}
          withoutPriority={withoutPriority}
          withPriority={withPriority}
          workTypeLookup={workTypeLookup}
        />
      );
    } else if (tab === "service") {
      content = (
        <ServiceTaskTable
          customerSettings={this.props.customerSettings}
          go={this.props.go}
          machineArray={filteredMachineArray}
          taskList={taskSeq}
          timerStartArray={this.props.timerStartArray}
          userUserProfileLookup={userUserProfileLookup}
          workTypeLookup={workTypeLookup}
        />
      );
    } else if (tab === "machines") {
      let machineSeq = machineArray.filter((m) => m.active);
      const filterString = this.props.q;
      if (filterString.trim()) {
        const check = makeContainsPredicate(filterString);
        const filterFn = (machine: Machine): boolean =>
          check(`${machine.c5_machine} ${machine.name}`);
        machineSeq = machineSeq.filter(filterFn);
      }
      const machineList = machineSeq
        .slice()
        .sort((a, b) => identifierComparator(a.c5_machine, b.c5_machine));
      content = (
        <MachineTable
          customerSettings={this.props.customerSettings}
          go={this.props.go}
          machineList={machineList}
        />
      );
    }

    let right;
    if (tab === "machines") {
      right = <AppbarSearchField onChange={this.handleFilterChange} value={this.props.q} />;
    }

    const floatingActionButton = (
      <VerticalStackingFloatingActionButton onClick={this.handleFabButton} stackIndex={0}>
        <PlusIcon />
      </VerticalStackingFloatingActionButton>
    );
    const workTypeDialog = (
      <ConnectedInternalWorkshopWorkTypeDialog
        key="work-type-dialog"
        onCancel={this.handleWorkTypeDialogCancel}
        onOk={this.handleWorkTypeDialogOk}
        open={this.state.workTypeDialogOpen}
      />
    );
    const dialogs = [workTypeDialog];
    if (bowser.mobile || bowser.tablet) {
      const dragPreview = (
        <TaskDragPreview customerSettings={this.props.customerSettings} key="drag-preview" />
      );
      dialogs.push(dragPreview);
    }
    return (
      <PageLayout
        dialogs={dialogs}
        disableTouchPullRefresh={tab === "tasks"}
        floatingActionButton={floatingActionButton}
        tabs={
          <Tabs
            onChange={this.handleTabChange}
            value={tab}
            variant={bowser.mobile ? "fullWidth" : "standard"}
          >
            <Tab label={formatMessage(messages.tasks)} value="tasks" />
            <Tab label={formatMessage(messages.service)} value="service" />
            <Tab
              label={
                this.props.customerSettings.machineLabelVariant === "MACHINE"
                  ? formatMessage(messages.machines)
                  : formatMessage(messages.vehicles)
              }
              value="machines"
            />
          </Tabs>
        }
        toolbar={
          <MenuToolbar
            onMenuButton={this.props.onMenuButton}
            rightElement={right}
            title={formatMessage(messages.title)}
          />
        }
        withBottomScrollPadding
        withDragScroll={tab === "tasks"}
      >
        {content}
      </PageLayout>
    );
  }
}

const ConnectedWorkshop = connect<
  WorkshopStateProps,
  WorkshopDispatchProps,
  WorkshopOwnProps,
  AppState
>(
  createStructuredSelector<AppState, WorkshopStateProps>({
    currentRole: getCurrentRole,
    currentUser: getCurrentUser,
    currentUserID: getUserId,
    currentUserProfile: getCurrentUserProfile,
    currentUserURL: getCurrentUserURL,
    customerSettings: getCustomerSettings,
    filteredMachineArray: getFilteredMachineArray,
    filteredMachineLookup: getFilteredMachineLookup,
    machineArray: getMachineArray,
    q: makeQueryParameterGetter("q"),
    tab: makeQueryParameterGetter("tab", "tasks"),
    taskArray: getTaskArray,
    taskPhotoArray: getTaskPhotoArray,
    timerStartArray: getTimerStartArray,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    create: actions.create,
    go: actions.go,
    putQueryKey: actions.putQueryKey,
    update: actions.update,
  },
)(Workshop);

export default ConnectedWorkshop;
