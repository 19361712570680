import {CustomerUrl, MachineUrl, PriceGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {ResponsiveInfoDialog} from "@co-frontend-libs/components";
import {
  getCustomerLookup,
  getMachineLookup,
  getPriceGroupLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface FuelSurchargeUsePart {
  readonly customer: CustomerUrl | null;
  readonly machine?: MachineUrl | null;
  readonly variant?: PriceGroupUrl | null;
  readonly workType?: WorkTypeUrl | null;
}

interface SurchargeReasonDialogProps {
  onClose: () => void;
  open: boolean;
  uses: ReadonlySet<FuelSurchargeUsePart>;
}

interface UseLineProps {
  surchargeUse: FuelSurchargeUsePart;
}

const UseLine = React.memo(function UseLine(props: UseLineProps): React.JSX.Element {
  const {surchargeUse} = props;
  const customerLookup = useSelector(getCustomerLookup);
  const machineLookup = useSelector(getMachineLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);

  const {
    customer: customerUrl,
    machine: machineUrl,
    variant: priceGroupUrl,
    workType: workTypeUrl,
  } = surchargeUse;

  const customer = customerUrl ? customerLookup(customerUrl) : null;
  const machine = machineUrl ? machineLookup(machineUrl) : null;
  const workType = workTypeUrl ? workTypeLookup(workTypeUrl) : null;
  const priceGroup = priceGroupUrl ? priceGroupLookup(priceGroupUrl) : null;

  if (customer && machine && priceGroup) {
    return (
      <FormattedMessage
        defaultMessage="Kunde: {customerName}, maskine: {machineName}, variant: {priceGroupName}"
        values={{
          customerName: customer.name,
          machineName: machine.name,
          priceGroupName: priceGroup.name,
        }}
      />
    );
  } else if (customer && machine) {
    return (
      <FormattedMessage
        defaultMessage="Kunde: {customerName}, maskine: {machineName}"
        values={{customerName: customer.name, machineName: machine.name}}
      />
    );
  } else if (customer && workType && priceGroup) {
    return (
      <FormattedMessage
        defaultMessage="Kunde: {customerName}, område: {workTypeName}, variant: {priceGroupName}"
        values={{
          customerName: customer.name,
          priceGroupName: priceGroup.name,
          workTypeName: workType.name,
        }}
      />
    );
  } else if (customer && workType) {
    return (
      <FormattedMessage
        defaultMessage="Kunde: {customerName}, område: {workTypeName}"
        values={{
          customerName: customer.name,
          workTypeName: workType.name,
        }}
      />
    );
  } else if (customer) {
    return (
      <FormattedMessage
        defaultMessage="Kunde: {customerName}"
        values={{
          customerName: customer.name,
        }}
      />
    );
  } else if (machine && priceGroup) {
    return (
      <FormattedMessage
        defaultMessage="Maskine: {machineName}, variant: {priceGroupName}"
        values={{
          machineName: machine.name,
          priceGroupName: priceGroup.name,
        }}
      />
    );
  } else if (machine) {
    return (
      <FormattedMessage
        defaultMessage="Maskine: {machineName}"
        values={{machineName: machine.name}}
      />
    );
  } else if (workType && priceGroup) {
    return (
      <FormattedMessage
        defaultMessage="Område: {workTypeName}, variant: {priceGroupName}"
        values={{
          priceGroupName: priceGroup.name,
          workTypeName: workType.name,
        }}
      />
    );
  } else if (workType) {
    return (
      <FormattedMessage
        defaultMessage="Område: {workTypeName}"
        values={{
          workTypeName: workType.name,
        }}
      />
    );
  } else {
    return <FormattedMessage defaultMessage="Standard" />;
  }
});

export const SurchargeReasonDialog = React.memo(function SurchargeReasonDialog(
  props: SurchargeReasonDialogProps,
): React.JSX.Element {
  const {onClose, open, uses} = props;

  const intl = useIntl();

  const useEntries = Array.from(uses).map((surchargeUse, index) => (
    <li key={index}>
      <UseLine surchargeUse={surchargeUse} />
    </li>
  ));

  return (
    <ResponsiveInfoDialog
      onClose={onClose}
      open={open}
      title={intl.formatMessage({defaultMessage: "Brændstoftillæg kriterier"})}
    >
      <DialogContent>
        <ul>{useEntries}</ul>
      </DialogContent>
    </ResponsiveInfoDialog>
  );
});
