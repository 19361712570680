import {LocationUrl} from "@co-common-libs/resources";
import {
  Cluster,
  ClusterStats,
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import React, {useEffect, useRef, useState} from "react";
import {LocationWithGeoJson} from "./types";

function bboxCenter(bbox: [number, number, number, number]): [number, number] {
  const longitude = (bbox[0] + bbox[2]) / 2;

  const latitude = (bbox[1] + bbox[3]) / 2;
  return [longitude, latitude];
}

function getSvgDataUrl(color: string, count: number): string {
  // svg from @googlemaps/markerclusterer DefaultRenderer
  const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
<circle cx="120" cy="120" opacity=".6" r="70" />
<circle cx="120" cy="120" opacity=".3" r="90" />
<circle cx="120" cy="120" opacity=".2" r="110" />
<text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
</svg>`;
  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

function getMarker(
  position: google.maps.LatLng | google.maps.LatLngLiteral,
  color: string,
  count: number,
  zIndex: number | null,
): google.maps.Marker {
  return new google.maps.Marker({
    icon: {
      // constants from @googlemaps/markerclusterer DefaultRenderer

      anchor: new google.maps.Point(25, 25),
      url: getSvgDataUrl(color, count),
    },
    position,
    zIndex,
  });
}

// based on @googlemaps/markerclusterer DefaultRenderer
function renderCluster(
  color: string,
  zIndex: number | null,
  cluster: Cluster,
  _stats: ClusterStats,
  _map: google.maps.Map,
): google.maps.Marker {
  const {count, position} = cluster;
  return getMarker(position, color, count, zIndex);
}

function updateMap(
  map: google.maps.Map,
  color: string,
  zIndex: number | null,
  markers: Map<LocationUrl, {lastChanged: string | undefined; marker: google.maps.Marker}>,
  markerClusterer: MarkerClusterer,
  instances: readonly LocationWithGeoJson[],
  displayPolygonsZoomThreshold: number,
): void {
  const zoom = map.getZoom() || 0;

  if (zoom > displayPolygonsZoomThreshold) {
    // hide markers; other component will display polygons
    markerClusterer.clearMarkers();
    markers.clear();
  } else {
    // update markers
    const currentInstanceUrls = new Set<LocationUrl>(instances.map(({url}) => url));

    const toRemove: google.maps.Marker[] = [];
    const toAdd: google.maps.Marker[] = [];

    markers.forEach(({marker}, url) => {
      if (!currentInstanceUrls.has(url)) {
        toRemove.push(marker);
        markers.delete(url);
      }
    });

    for (const instance of instances) {
      const currentData = markers.get(instance.url);
      if (!currentData || instance.lastChanged !== currentData.lastChanged) {
        if (currentData) {
          toRemove.push(currentData.marker);
          // replaced in `markers` below
        }
        const {geojson, lastChanged, url} = instance;
        const [lng, lat] = bboxCenter(geojson.geometry.bbox);
        const marker = getMarker({lat, lng}, color, 1, zIndex);
        toAdd.push(marker);
        markers.set(url, {lastChanged, marker});
      }
    }

    if (toRemove.length) {
      markerClusterer.removeMarkers(toRemove);
    }
    if (toAdd.length) {
      markerClusterer.addMarkers(toAdd);
    }
  }
}

interface FieldsClusteredMarkersProps {
  color?: string | undefined;
  displayPolygonsZoomThreshold: number;
  instances: readonly LocationWithGeoJson[];
  map: google.maps.Map;
  zIndex?: number | null | undefined;
}

/**
 * NOTE: Will update map in case of changes to `lastChanged` on existing
 * location instances -- local GeoJSON changes without changes to
 * `lastChanged` will not take effect.
 * NOTE: Will *not* update clusterer state on change to color.
 */
export function FieldsClusteredMarkers(props: FieldsClusteredMarkersProps): React.JSX.Element {
  const {color = "#0000ff", displayPolygonsZoomThreshold, instances, map, zIndex = null} = props;

  // state rather than ref to not repeatedly call constructor
  const [markerClusterer] = useState(
    () =>
      new MarkerClusterer({
        algorithm: new SuperClusterAlgorithm({radius: 200}),
        map,
        renderer: {render: renderCluster.bind(null, color, zIndex)},
      }),
  );
  const [markers] = useState(
    () => new Map<LocationUrl, {lastChanged: string | undefined; marker: google.maps.Marker}>(),
  );

  useEffect(() => {
    markerClusterer.setMap(map);
    return () => {
      markerClusterer.setMap(null);
    };
  }, [map, markerClusterer]);

  // update after changes to `instances` (search/filtering)
  useEffect(() => {
    const timeout = setTimeout(() => {
      updateMap(
        map,
        color,
        zIndex,
        markers,
        markerClusterer,
        instances,
        displayPolygonsZoomThreshold,
      );
    });
    return () => {
      clearTimeout(timeout);
    };
  }, [color, displayPolygonsZoomThreshold, instances, map, markerClusterer, markers, zIndex]);

  // HACK: make current state available to map event handler without
  // rebinding event handler on every change
  const instancesRef = useRef(instances);
  instancesRef.current = instances;

  // update after zoom/pan (i.e. on "idle" event)
  useEffect(() => {
    const listener = map.addListener("idle", () => {
      updateMap(
        map,
        color,
        zIndex,
        markers,
        markerClusterer,
        instancesRef.current,
        displayPolygonsZoomThreshold,
      );
    });
    return () => {
      listener.remove();
    };
  }, [color, displayPolygonsZoomThreshold, map, markerClusterer, markers, zIndex]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
