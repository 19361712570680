import {SettingID} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {DialogContent, Grid} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {ColumnSelectionDialog} from "../column-selection-dialog";
import {DeviceType, UserType} from "../list-columns-dialog/types";
import {ColumnListEdit} from "./column-list-edit";

interface UserTypeDeviceListColumnsDialogProps<ColumnChoice extends string> {
  columnChoices: readonly ColumnChoice[];
  managerColumnChoices: readonly ColumnChoice[];
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function UserTypeDeviceListColumnsDialog<ColumnChoice extends string>(
  props: UserTypeDeviceListColumnsDialogProps<ColumnChoice>,
): React.JSX.Element {
  const {columnChoices, managerColumnChoices, onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const customerSettings = useSelector(getCustomerSettings);
  const settingsEntry = settingsEntryLookupByIdentifier(settingID);
  const currentUserURL = useSelector(getCurrentUserURL);
  const dispatch = useDispatch();

  type CurrentSettingValueType = {
    readonly [U in UserType]: {
      readonly [D in DeviceType]: readonly ColumnChoice[];
    };
  };

  type WritableSettingValueType = {
    [U in UserType]: {
      [D in DeviceType]: ColumnChoice[];
    };
  };

  const [data, setData] = useState<CurrentSettingValueType>(
    customerSettings[settingID] as CurrentSettingValueType,
  );
  useEffect(() => {
    setData(customerSettings[settingID] as CurrentSettingValueType);
  }, [customerSettings, settingID]);

  const handleSave = useCallback(() => {
    if (settingsEntry) {
      dispatch(
        actions.update(settingsEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: data},
        ]),
      );
    } else {
      const id = uuid();
      const url = instanceURL("settingEntry", id);
      dispatch(
        actions.create({
          changedBy: currentUserURL,
          data,
          key: settingID,
          url,
        }),
      );
    }
    onClose();
  }, [settingsEntry, onClose, dispatch, currentUserURL, data, settingID]);

  const handleChange = useCallback(
    (
      reorderedIdentifiers: readonly ColumnChoice[],
      userType: UserType,
      deviceType: DeviceType,
    ): void => {
      const newData = _.cloneDeep(data) as WritableSettingValueType;
      newData[userType][deviceType] = reorderedIdentifiers.slice();
      setData(newData);
    },
    [data],
  );

  const [columnSelectionDialogOpenWithData, setColumnSelectionDialogOpenWithData] = useState<{
    deviceType: DeviceType;
    selected: readonly ColumnChoice[];
    userType: UserType;
  } | null>(null);

  const handleColumnListEditButtonClick = useCallback(
    (userType: UserType, deviceType: DeviceType) => {
      const selected = data[userType][deviceType];
      setColumnSelectionDialogOpenWithData({
        deviceType,
        selected,
        userType,
      });
    },
    [data],
  );

  const handleColumnSelectionDialogClose = useCallback(() => {
    setColumnSelectionDialogOpenWithData(null);
  }, []);

  const handleColumnSelectionDialogSave = useCallback(
    (selected: readonly ColumnChoice[]) => {
      if (columnSelectionDialogOpenWithData) {
        const newData = _.cloneDeep(data) as WritableSettingValueType;
        const {deviceType, userType} = columnSelectionDialogOpenWithData;
        newData[userType][deviceType] = selected.slice();
        setData(newData);
        handleColumnSelectionDialogClose();
      }
    },
    [columnSelectionDialogOpenWithData, data, handleColumnSelectionDialogClose],
  );

  const deviceTypes: DeviceType[] = ["mobile", "tablet", "desktop"];

  return (
    <>
      <ResponsiveDialog
        fullscreen
        okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
        onCancel={onClose}
        onOk={handleSave}
        open={open && !columnSelectionDialogOpenWithData}
        title={<FormattedMessage defaultMessage="Kolonner på medarbejderlisten" />}
      >
        <DialogContent style={{padding: 32}}>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              {deviceTypes.map((deviceType) => (
                <div key={`manager-${deviceType}`}>
                  <FormattedMessage
                    defaultMessage="admin - {deviceType}"
                    id="system-setup.header.list-identifier-manager-device-type-columns"
                    tagName="b"
                    values={{deviceType}}
                  />
                  <ColumnListEdit<ColumnChoice>
                    deviceType={deviceType}
                    identifiers={data.manager[deviceType]}
                    onChange={handleChange}
                    onEditClick={handleColumnListEditButtonClick}
                    userType="manager"
                  />
                </div>
              ))}
            </Grid>
            <Grid item xs={6}>
              {deviceTypes.map((deviceType) => (
                <div key={`employee-${deviceType}`}>
                  <FormattedMessage
                    defaultMessage="medarbejder - {deviceType}"
                    id="system-setup.header.list-identifier-employee-device-type-columns"
                    tagName="b"
                    values={{deviceType}}
                  />
                  <ColumnListEdit<ColumnChoice>
                    deviceType={deviceType}
                    identifiers={data.employee[deviceType]}
                    onChange={handleChange}
                    onEditClick={handleColumnListEditButtonClick}
                    userType="employee"
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </ResponsiveDialog>
      <ColumnSelectionDialog<ColumnChoice>
        columns={
          columnSelectionDialogOpenWithData?.userType === "employee"
            ? columnChoices
            : managerColumnChoices
        }
        onClose={handleColumnSelectionDialogClose}
        onSave={handleColumnSelectionDialogSave}
        open={!!columnSelectionDialogOpenWithData}
        selected={columnSelectionDialogOpenWithData?.selected}
      />
    </>
  );
}
