import {createReducer, current, Draft} from "@reduxjs/toolkit";
import {History} from "history";
import _ from "lodash";
import {AnyAction} from "redux";
import * as actions from "./actions";
import {buildPathSelfMatcher, MatchResult} from "./path-matcher";
import {PartialNavigationKind, RoutingHistoryEntry, RoutingState} from "./types";
import {getSearchObject} from "./utils";

const MAX_HISTORY_LENGTH = 50;

function getInitialState<T extends string>(
  history: History,
  pathMatcher: (path: string) => MatchResult<T> | null,
): RoutingState<T> {
  const {pathname, search} = history.location;
  const matchResult = pathMatcher(pathname);
  const matchingPathTemplate = matchResult ? matchResult.value : null;
  const pathParameters = matchResult ? matchResult.parameters : {};
  const queryParameters = getSearchObject(search);
  const position = 0;
  const entry: RoutingHistoryEntry<T> = {
    matchingPathTemplate,
    pathname,
    pathParameters,
    position,
    queryParameters,
  };
  return {
    current: entry,
    history: [entry],
  };
}

function updateHistory<T extends string>(
  state: Draft<RoutingState<T>>,
  navigationKind: PartialNavigationKind,
): void {
  const currentPosition = state.current.position;
  if (navigationKind === "REPLACE") {
    const historyEntryIndex = state.history.findIndex(
      (entry) => entry.position === currentPosition,
    );
    if (historyEntryIndex !== -1) {
      state.history[historyEntryIndex] = state.current;
    }
  } else {
    console.assert(navigationKind === "PUSH", `navigationKind not PUSH; was ${navigationKind}`);
    state.history = state.history.filter((entry) => entry.position < currentPosition);
    state.history.push(state.current);
    if (state.history.length > MAX_HISTORY_LENGTH) {
      state.history = state.history.slice(-MAX_HISTORY_LENGTH);
    }
  }
}

function handlePutQueryKey<T extends string>(
  state: Draft<RoutingState<T>>,
  action: ReturnType<typeof actions.putQueryKey>,
): void {
  const {key, navigationKind, value} = action.payload;
  if (state.current.queryParameters[key] === value) {
    // no changes; so no effect on REPLACE and would add confusion on PUSH
    return;
  }
  state.current.queryParameters[key] = value;
  if (navigationKind === "PUSH") {
    state.current.position += 1;
  }
  updateHistory(state, navigationKind);
}

function handlePutQueryKeys<T extends string>(
  state: Draft<RoutingState<T>>,
  action: ReturnType<typeof actions.putQueryKeys>,
): void {
  const {navigationKind, update} = action.payload;
  let changed = false;
  Object.entries(update).forEach(([key, value]) => {
    if (state.current.queryParameters[key] !== value) {
      changed = true;
      state.current.queryParameters[key] = value;
    }
  });
  if (!changed) {
    // no changes; so no effect on REPLACE and would add confusion on PUSH
    return;
  }
  if (navigationKind === "PUSH") {
    state.current.position += 1;
  }
  updateHistory(state, navigationKind);
}

function handleDeleteQueryKey<T extends string>(
  state: Draft<RoutingState<T>>,
  action: ReturnType<typeof actions.deleteQueryKey>,
): void {
  const {key, navigationKind} = action.payload;
  if (state.current.queryParameters[key] === undefined) {
    // no changes; so no effect on REPLACE and would add confusion on PUSH
    return;
  }
  delete state.current.queryParameters[key];
  if (navigationKind === "PUSH") {
    state.current.position += 1;
  }
  updateHistory(state, navigationKind);
}

function handleReplaceQuery<T extends string>(
  state: Draft<RoutingState<T>>,
  action: ReturnType<typeof actions.replaceQuery>,
): void {
  const {navigationKind, queryParameters} = action.payload;
  if (_.isEqual(queryParameters, current(state.current.queryParameters))) {
    // no changes; so no effect on REPLACE and would add confusion on PUSH
    return;
  }
  state.current.queryParameters = queryParameters;
  if (navigationKind === "PUSH") {
    state.current.position += 1;
  }
  updateHistory(state, navigationKind);
}

function handleNavigate<T extends string>(
  pathMatcher: (path: string) => MatchResult<T> | null,
  state: Draft<RoutingState<T>>,
  action: ReturnType<typeof actions.navigate>,
): void {
  const {navigationKind, pathname, queryParameters} = action.payload;
  if (
    pathname === state.current.pathname &&
    _.isEqual(queryParameters, state.current.queryParameters)
  ) {
    // no changes; so no effect on REPLACE and would add confusion on PUSH
    return;
  }
  const matchResult = pathMatcher(pathname);
  const matchingPathTemplate = matchResult ? matchResult.value : null;
  const pathParameters = matchResult ? matchResult.parameters : {};
  state.current = {
    matchingPathTemplate: matchingPathTemplate as Draft<T> | null,
    pathname,
    pathParameters,
    position: navigationKind === "PUSH" ? state.current.position + 1 : state.current.position,
    queryParameters,
  };
  updateHistory(state, navigationKind);
}

function handleBack<T extends string>(
  pathMatcher: (path: string) => MatchResult<T> | null,
  state: Draft<RoutingState<T>>,
  action: ReturnType<typeof actions.back>,
): void {
  const {count, fallback} = action.payload;
  if (count === 0) {
    // no effect...
    return;
  }
  const resultingPosition = state.current.position - count;
  const historyEntry =
    resultingPosition >= 0
      ? state.history.find((entry) => entry.position === resultingPosition)
      : undefined;
  if (historyEntry) {
    state.current = historyEntry;
  } else {
    const matchResult = pathMatcher(fallback);
    const matchingPathTemplate = matchResult ? matchResult.value : null;
    const pathParameters = matchResult ? matchResult.parameters : {};
    state.current = {
      matchingPathTemplate: matchingPathTemplate as Draft<T> | null,
      pathname: fallback,
      pathParameters,
      position: state.current.position,
      queryParameters: {},
    };
    updateHistory(state, "REPLACE");
  }
}

function handleNavigateFromBrowser<T extends string>(
  pathMatcher: (path: string) => MatchResult<T> | null,
  state: Draft<RoutingState<T>>,
  action: ReturnType<typeof actions.navigateFromBrowser>,
): void {
  const {pathname, position, queryParameters} = action.payload;
  const matchResult = pathMatcher(pathname);
  const matchingPathTemplate = matchResult ? matchResult.value : null;
  const pathParameters = matchResult ? matchResult.parameters : {};
  const resultingPosition = position ?? state.current.position + 1;
  state.current = {
    matchingPathTemplate: matchingPathTemplate as Draft<T> | null,
    pathname,
    pathParameters,
    position: resultingPosition,
    queryParameters,
  };
  if (process.env.NODE_ENV !== "production" && position !== null) {
    const historyEntry = state.history.find((entry) => entry.position === position);
    if (historyEntry) {
      console.assert(
        _.isEqual(historyEntry, state.current),
        `history entry differs; position: ${position}; historyEntry: ${JSON.stringify(
          historyEntry,
        )}; current: ${JSON.stringify(state.current)}`,
      );
    }
  }
  updateHistory(state, position === null ? "PUSH" : "REPLACE");
}

function handleBackSkip<T extends string>(
  pathMatcher: (path: string) => MatchResult<T> | null,
  state: Draft<RoutingState<T>>,
  action: ReturnType<typeof actions.backSkip>,
): void {
  const {fallback, skip} = action.payload;
  const currentPosition = state.current.position;
  let historyEntry: Draft<RoutingHistoryEntry<T>> | undefined;
  for (let i = state.history.length - 1; i >= 0; i -= 1) {
    const entry = state.history[i];
    if (
      entry.position < currentPosition &&
      entry.matchingPathTemplate &&
      !skip.includes(entry.matchingPathTemplate)
    ) {
      historyEntry = entry;
      break;
    }
  }
  if (historyEntry) {
    state.current = historyEntry;
  } else {
    const matchResult = pathMatcher(fallback);
    const matchingPathTemplate = matchResult ? matchResult.value : null;
    const pathParameters = matchResult ? matchResult.parameters : {};
    state.current = {
      matchingPathTemplate: matchingPathTemplate as Draft<T> | null,
      pathname: fallback,
      pathParameters,
      position: state.current.position,
      queryParameters: {},
    };
    updateHistory(state, "REPLACE");
  }
}

function handleForwardBackSkip<T extends string>(
  pathMatcher: (path: string) => MatchResult<T> | null,
  state: Draft<RoutingState<T>>,
  action: ReturnType<typeof actions.forwardBackSkip>,
): void {
  const {fallback, skip} = action.payload;
  const currentPosition = state.current.position;
  let historyEntry: Draft<RoutingHistoryEntry<T>> | undefined;
  for (let i = state.history.length - 1; i >= 0; i -= 1) {
    const entry = state.history[i];
    if (
      entry.position < currentPosition &&
      entry.matchingPathTemplate &&
      !skip.includes(entry.matchingPathTemplate)
    ) {
      historyEntry = entry;
      break;
    }
  }
  if (historyEntry) {
    state.current = {...historyEntry, position: state.current.position + 1};
  } else {
    const matchResult = pathMatcher(fallback);
    const matchingPathTemplate = matchResult ? matchResult.value : null;
    const pathParameters = matchResult ? matchResult.parameters : {};
    state.current = {
      matchingPathTemplate: matchingPathTemplate as Draft<T> | null,
      pathname: fallback,
      pathParameters,
      position: state.current.position + 1,
      queryParameters: {},
    };
  }
  updateHistory(state, "PUSH");
}

export function getReducer<T extends string>(
  history: History,
  pathTemplates: readonly T[],
): (state: RoutingState<T> | undefined, action: AnyAction) => RoutingState<T> {
  const pathMatcher = buildPathSelfMatcher(pathTemplates);
  const initialState = getInitialState(history, pathMatcher);
  return createReducer(initialState, (builder) => {
    builder.addCase(actions.putQueryKey, handlePutQueryKey);
    builder.addCase(actions.putQueryKeys, handlePutQueryKeys);
    builder.addCase(actions.deleteQueryKey, handleDeleteQueryKey);
    builder.addCase(actions.replaceQuery, handleReplaceQuery);
    builder.addCase(actions.navigate, handleNavigate.bind(null, pathMatcher));
    builder.addCase(actions.back, handleBack.bind(null, pathMatcher));
    builder.addCase(actions.navigateFromBrowser, handleNavigateFromBrowser.bind(null, pathMatcher));
    builder.addCase(actions.backSkip, handleBackSkip.bind(null, pathMatcher));
    builder.addCase(actions.forwardBackSkip, handleForwardBackSkip.bind(null, pathMatcher));
  });
}
