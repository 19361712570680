import {ResourceInstance, ResourceName, resourceNames} from "@co-common-libs/resources";
import {Check, Query} from "@co-frontend-libs/db-resources";
import {ResourceInstanceRecords} from "../types";
import {entriesForEach} from "./iteration";

const alwaysOkCheck: Check = {type: "alwaysOk"};
const neverOkCheck: Check = {type: "neverOk"};

function combineCheckArray(checks: readonly Check[]): Check {
  console.assert(checks.length >= 1);
  if (checks.length === 0) {
    // Should be impossible...
    return alwaysOkCheck;
  } else if (checks.length === 1) {
    return checks[0];
  } else {
    console.assert(checks.length > 1);
    return {checks, type: "or"};
  }
}

export function buildResourceCheckMapping(queriesCheckFunctions: readonly Query[]): {
  readonly [N in ResourceName]: Check;
} {
  const checksPerResource = Object.fromEntries(
    resourceNames.map((resourceName) => [resourceName, [neverOkCheck]]),
  ) as {[N in ResourceName]: Check[]};

  queriesCheckFunctions.forEach((query: Query): void => {
    const {check, resourceName} = query;
    const entry = checksPerResource[resourceName];
    if (entry) {
      entry.push(check);
    } else {
      // resource type unknown/removed...
      // eslint-disable-next-line no-console
      console.warn(`unknown resource: ${resourceName}`);
    }
  });
  const result = Object.fromEntries(
    Object.entries(checksPerResource).map(([resourceName, checks]) => [
      resourceName,
      combineCheckArray(checks),
    ]),
  ) as {[N in ResourceName]: Check};
  return result;
}

export type FkRulesForTarget = readonly {
  readonly checkedResourceName: ResourceName;
  readonly memberName: string;
  readonly targetCheck: Check;
}[];

export type FkRules = Partial<{
  readonly [targetType in ResourceName]: FkRulesForTarget;
}>;

export type ReverseFkRulesForSource = readonly {
  readonly checkedResourceName: ResourceName;
  readonly memberName: string;
  readonly sourceCheck: Check;
}[];

export type ReverseFkRules = Partial<{
  readonly [fromResource in ResourceName]: ReverseFkRulesForSource;
}>;

/**
 * When receiving new data; we need to check whether to keep/persist the new
 * data; but we may *also* need to check whether to promote existing data to
 * persisted, and determine what extra data to fetch...
 *
 * The `alwaysOkResources` set is just to simplify the special
 * case/optimisation for resources where no individual instance checks are
 * necessary.
 *
 * `checksPerResource` is the result from `buildResourceCheckMapping`;
 * combined checks per resource passed through.
 *
 * The interesting parts are `fkRules` and `reverseFkRules`; new data should
 * be checked against those to determine whether the relations mean that extra
 * data should be fetched or existing data promoted to persisted. The resource
 * names and FK-member-field involved can be used for a cheap initial check
 * and as criteria when fetching data; though the actual FK-criteria *may* have
 * been part of an "and"-combination, and rather than track this (through
 * arbitrary levels of "and" and "or"), we'll take the brute force approach
 * and run the actual check to be safe...
 *
 * We don't follow foreign keys from within foreign keys; in a sensible setup,
 * any intermediate objects in such a sequence should be matched by by some
 * other query, and we will reevaluate what to fetch when *those* objects
 * are fetched -- we don't want the complexity of multi-level lookups in the
 * API. When checking/promoting local data, we need to also consider the
 * results of promoting objects to persisted as "new" for another round of
 * checks wrt. relations (and so on until nothing gets added), but that's also
 * rather straightforward, and the sets of data from this should be small and
 * smaller for each round...
 */
export function buildRelationCheckMappings(checkPerResource: {
  readonly [N in ResourceName]: Check;
}): {
  fkRules: FkRules;
  reverseFkRules: ReverseFkRules;
} {
  const fkRules: Partial<{
    [targetResourceName in ResourceName]: {
      checkedResourceName: ResourceName;
      memberName: string;
      targetCheck: Check;
    }[];
  }> = {};
  const reverseFkRules: Partial<{
    [sourceResourceName in ResourceName]: {
      checkedResourceName: ResourceName;
      memberName: string;
      sourceCheck: Check;
    }[];
  }> = {};
  const processFKStuff = (resourceName: ResourceName, check: Check): void => {
    if (check.type === "hasForeignKey") {
      const {check: relatedCheck, memberName, targetType} = check;
      const fkRulesForTarget = (fkRules[targetType] = fkRules[targetType] || []);
      fkRulesForTarget.push({
        checkedResourceName: resourceName,
        memberName,
        targetCheck: relatedCheck,
      });
    } else if (check.type === "targetOfForeignKey") {
      const {check: relatedCheck, fromResource, memberName} = check;
      const reverseRulesFromResource = (reverseFkRules[fromResource] =
        reverseFkRules[fromResource] || []);
      reverseRulesFromResource.push({
        checkedResourceName: resourceName,
        memberName,
        sourceCheck: relatedCheck,
      });
    } else if (check.type === "or" || check.type === "and") {
      check.checks.forEach(processFKStuff.bind(null, resourceName));
    }
  };
  entriesForEach(checkPerResource, (resourceName, check) => {
    processFKStuff(resourceName, check);
  });
  return {
    fkRules,
    reverseFkRules,
  };
}

function alwaysTrue(_instance: ResourceInstance): boolean {
  return true;
}

function alwaysFalse(_instance: ResourceInstance): boolean {
  return false;
}

// HACK: TypeScript validates that all path returns the correct type, meaning
// that any type-valid check.type for the switch must have been handled...
// eslint-disable-next-line consistent-return
export function buildCheckFunction(
  check: Check,
  allData: ResourceInstanceRecords,
): (instance: ResourceInstance) => boolean {
  switch (check.type) {
    case "alwaysOk":
      return alwaysTrue;
    case "and": {
      const checkFunctions = check.checks.map((innerCheck) =>
        buildCheckFunction(innerCheck, allData),
      );
      return (instance) => {
        for (let i = 0; i < checkFunctions.length; i += 1) {
          if (!checkFunctions[i](instance)) {
            return false;
          }
        }
        return true;
      };
    }
    case "hasForeignKey": {
      const {check: innerCheck, memberName, targetType} = check;
      const innerCheckFunction = buildCheckFunction(innerCheck, allData);
      return (instance) => {
        const foreignKey = (instance as Record<string, any>)[memberName];
        if (!foreignKey) {
          return false;
        }
        const otherResourceRecord = allData[targetType];
        const otherInstance = otherResourceRecord && otherResourceRecord[foreignKey];
        if (!otherInstance) {
          return false;
        }
        return innerCheckFunction(otherInstance);
      };
    }
    case "memberEq": {
      const {memberName, value} = check;
      return (instance) => (instance as Record<string, any>)[memberName] === value;
    }
    case "memberFalsy": {
      const {memberName} = check;
      return (instance) => !(instance as Record<string, any>)[memberName];
    }
    case "memberGt": {
      const {memberName, value} = check;
      return (instance) => (instance as Record<string, any>)[memberName] > value;
    }
    case "memberGte": {
      const {memberName, value} = check;
      return (instance) => (instance as Record<string, any>)[memberName] >= value;
    }
    case "memberIn": {
      const {memberName, values} = check;
      const valuesSet = new Set(values);
      return (instance) => valuesSet.has((instance as Record<string, any>)[memberName]);
    }
    case "memberLt": {
      const {memberName, value} = check;
      return (instance) => (instance as Record<string, any>)[memberName] < value;
    }
    case "memberLte": {
      const {memberName, value} = check;
      return (instance) => (instance as Record<string, any>)[memberName] <= value;
    }
    case "memberTruthy": {
      const {memberName} = check;
      return (instance) => !!(instance as Record<string, any>)[memberName];
    }
    case "neverOk":
      return alwaysFalse;
    case "or": {
      const checkFunctions = check.checks.map((innerCheck) =>
        buildCheckFunction(innerCheck, allData),
      );
      return (instance) => {
        for (let i = 0; i < checkFunctions.length; i += 1) {
          if (checkFunctions[i](instance)) {
            return true;
          }
        }
        return false;
      };
    }
    case "targetOfForeignKey": {
      const {check: innerCheck, fromResource, memberName} = check;
      const innerCheckFunction = buildCheckFunction(innerCheck, allData);
      const otherResourceRecord = allData[fromResource];
      const otherResourceInstances = otherResourceRecord
        ? (Object.values(otherResourceRecord) as Readonly<ResourceInstance>[])
        : [];
      const matchingOtherResourceInstances = otherResourceInstances.filter(innerCheckFunction);
      const matchingOtherResourceInstancesForeignKeys = new Set(
        matchingOtherResourceInstances.map(
          (otherInstance) => (otherInstance as Record<string, any>)[memberName],
        ),
      );
      return (instance) => matchingOtherResourceInstancesForeignKeys.has(instance.url);
    }
  }
}
