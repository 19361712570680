import {Config} from "@co-common-libs/config";
import {PriceItem, PriceItemUrl, Unit, UnitUrl} from "@co-common-libs/resources";
import {Table, TableBody} from "@material-ui/core";
import React from "react";
import {PriceItemEntry} from "./price-item-entry";

interface RouteTaskResultBlockProps {
  customerSettings: Config;
  editing: boolean;
  onUpdateValue: (priceItemURL: PriceItemUrl, count: number | null) => void;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  priceItemValues: ReadonlyMap<PriceItemUrl, number | null>;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export const RouteTaskResultBlock = React.memo(function RouteTaskResultBlock(
  props: RouteTaskResultBlockProps,
): React.JSX.Element | null {
  const {customerSettings, editing, onUpdateValue, priceItemLookup, priceItemValues, unitLookup} =
    props;
  const entries: React.JSX.Element[] = [];

  priceItemValues.forEach((value, priceItemUrl) => {
    const priceItem = priceItemLookup(priceItemUrl);
    if (!priceItem) {
      return;
    }
    const relevantForExecution =
      priceItem.relevantForExecution != null ? priceItem.relevantForExecution : true;
    if (!relevantForExecution && !editing) {
      return;
    }
    entries.push(
      <PriceItemEntry
        customerSettings={customerSettings}
        key={priceItemUrl}
        onUpdateValue={onUpdateValue}
        priceItem={priceItem}
        unitLookup={unitLookup}
        value={value != null ? value : undefined}
      />,
    );
  });
  return (
    <Table>
      <TableBody>{entries}</TableBody>
    </Table>
  );
});
