import {ImportPreviewMachine} from "@co-common-libs/resources-utils";
import {ErrorDialog, SpinnerDialog} from "@co-frontend-libs/components";
import {actions, getCustomerSettings, getMachineArray} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  translateNetworkError,
  useCallWithFalse,
  useCallWithTrue,
} from "@co-frontend-libs/utils";
import {Fab} from "@material-ui/core";
import {getApiEndpoint} from "api-endpoint-urls";
import {globalConfig} from "frontend-global-config";
import CloudDownloadIcon from "mdi-react/CloudDownloadIcon";
import React, {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ImportFabProps} from "../import-fab-types";
import {ImportMachinesDialog} from "./import-machines-dialog";

export const ImportMachinesFab = React.memo(function ImportMachinesFab(
  props: ImportFabProps,
): React.JSX.Element {
  const {buttonStyle, size = "large"} = props;

  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const setImportDialogOpenTrue = useCallWithTrue(setImportDialogOpen);
  const setImportDialogOpenFalse = useCallWithFalse(setImportDialogOpen);

  const customerSettings = useSelector(getCustomerSettings);

  const intl = useIntl();

  const [spinnerDialogOpen, setSpinnerDialogOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const handleOk = useCallback(
    async (machines: ImportPreviewMachine[]) => {
      setImportDialogOpen(false);
      setSpinnerDialogOpen(true);

      try {
        // TODO(mr): refactor to `useAsync` (removes need for spinnerDialogOpen and errorMessage states)
        const response = await jsonFetch(
          getApiEndpoint(globalConfig.baseURL).importResourceUrl("machine"),
          "POST",
          {
            machines: machines.map((m) => ({
              identifier: m.number.toString(),
              name: m.name,
              remoteUrl: m.remoteUrl,
            })),
          },
        );

        if (response.data) {
          dispatch(actions.addToOffline(response.data));
        }
      } catch (error) {
        setErrorMessage(translateNetworkError(error, intl));
      }

      setSpinnerDialogOpen(false);
    },
    [dispatch, intl, setSpinnerDialogOpen],
  );
  const machineArray = useSelector(getMachineArray);

  const existingMachineRemoteUrls = useMemo(
    () => new Set(machineArray.map(({remoteUrl}) => remoteUrl)),
    [machineArray],
  );
  const handleErrorMessageOk = useCallback(() => {
    setErrorMessage("");
    setSpinnerDialogOpen(false);
  }, [setSpinnerDialogOpen]);

  return (
    <>
      <Fab onClick={setImportDialogOpenTrue} size={size} style={buttonStyle}>
        <CloudDownloadIcon />
      </Fab>
      <ImportMachinesDialog
        omitted={existingMachineRemoteUrls}
        onCancel={setImportDialogOpenFalse}
        onOk={handleOk}
        open={importDialogOpen}
      />
      <SpinnerDialog
        open={spinnerDialogOpen}
        title={
          customerSettings.machineLabelVariant === "MACHINE"
            ? intl.formatMessage({defaultMessage: "Importerer maskiner"})
            : intl.formatMessage({defaultMessage: "Importerer køretøjer"})
        }
      />
      <ErrorDialog
        message={errorMessage}
        onOk={handleErrorMessageOk}
        open={!!errorMessage}
        title={intl.formatMessage({defaultMessage: "Import fejlede"})}
      />
    </>
  );
});
