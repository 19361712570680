import {
  Customer,
  Location,
  LocationUrl,
  YieldDeliveryLocation,
  YieldPickupLocation,
} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {DecimalField, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {Button, DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
import {Cell, Grid} from "react-flexr";
import {defineMessages, IntlContext, IntlShape} from "react-intl";
import {Linkify} from "./linkify";

const messages = defineMessages({
  amount: {
    defaultMessage: "Mængde ({unit})",
    id: "yield-dialog.label.amount",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  delivery: {
    defaultMessage: "Levering",
    id: "yield-dialog.title.delivery",
  },
  editDelivery: {
    defaultMessage: "Ret levering",
    id: "yield-dialog.title.edit-delivery",
  },
  editDeliveryLocation: {
    defaultMessage: "Ret leveringssted",
    id: "yield-dialog.title.edit-delivery-location",
  },
  editPickup: {
    defaultMessage: "Ret afhentning",
    id: "yield-dialog.title.edit-pickup",
  },
  editPickupLocation: {
    defaultMessage: "Ret afhentningssted",
    id: "yield-dialog.title.edit-pickup-location",
  },
  note: {
    defaultMessage: "Note",
    id: "yield-dialog.label.note",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  pickup: {
    defaultMessage: "Afhentning",
    id: "yield-dialog.title.pickup",
  },
  volume: {
    defaultMessage: "m³",
    id: "yield-log-card.label.corn",
  },
  weight: {
    defaultMessage: "ton",
    id: "yield-log-card.label.wholecrop",
  },
});

export const unitIdToFormattedString = (
  id: "" | "m3" | "tonne" | undefined,
  intl: IntlShape,
): string | undefined => {
  const {formatMessage} = intl;
  let returnString;
  switch (id) {
    case "m3":
      returnString = formatMessage(messages.volume);
      break;
    case "tonne":
      returnString = formatMessage(messages.weight);
      break;
    default:
      break;
  }

  return returnString;
};

interface YieldDialogProps<T extends YieldDeliveryLocation | YieldPickupLocation> {
  amount?: number | undefined;
  customer?: Customer | undefined;
  isNew: boolean;
  isPickup: boolean;
  locationLookup: (url: LocationUrl) => Location | undefined;
  note?: string | undefined;
  onCancel: () => void;
  onOk: (data: {amount: number | null; note: string}) => void;
  onRequestLocationDialog: (location: T) => void;
  open: boolean;
  unit?: "" | "m3" | "tonne" | undefined;
  yieldLocation?: T | undefined;
}

interface YieldDialogState {
  amount: number | null;
  note: string;
}

export class YieldDialog<
  T extends YieldDeliveryLocation | YieldPickupLocation,
> extends PureComponent<YieldDialogProps<T>, YieldDialogState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: YieldDialogState = {
    amount: this.props.amount != null ? this.props.amount : null,
    note: this.props.note || "",
  };
  getTitle(): string {
    const {formatMessage} = this.context;
    const {isNew, isPickup} = this.props;
    if (isPickup) {
      return isNew ? formatMessage(messages.pickup) : formatMessage(messages.editPickup);
    } else {
      return isNew ? formatMessage(messages.delivery) : formatMessage(messages.editDelivery);
    }
  }
  @bind
  handleAmountChange(newValue: number | null): void {
    this.setState({amount: newValue});
  }
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleEditLocation(): void {
    const {onRequestLocationDialog, yieldLocation} = this.props;
    if (yieldLocation) {
      onRequestLocationDialog(yieldLocation);
    }
  }
  @bind
  handleNoteChange(value: string): void {
    this.setState({note: value});
  }
  @bind
  handleOk(): void {
    const {amount, note} = this.state;
    this.props.onOk({
      amount,
      note,
    });
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customer, open, unit, yieldLocation} = this.props;
    const customerName = customer ? customer.name : null;
    const location =
      yieldLocation && yieldLocation.relatedLocation
        ? this.props.locationLookup(yieldLocation.relatedLocation)
        : null;
    const yieldLocationAddress = location ? location.name || location.address : null;
    const yieldLocationNote = yieldLocation ? yieldLocation.note : null;
    const unitString = unitIdToFormattedString(unit, this.context);
    const dialogContent = (
      <div>
        <Grid>
          <Cell palm="12/12">
            <div>Kunde: {customerName}</div>
            <div>Kundes adresse: {customer ? formatAddress(customer) : null}</div>
            <div>Arbejdssted: {yieldLocationAddress}</div>
            <div>
              Note: <Linkify>{yieldLocationNote}</Linkify>
            </div>
            <Button color="secondary" onClick={this.handleEditLocation} variant="contained">
              {this.props.isPickup
                ? formatMessage(messages.editPickupLocation)
                : formatMessage(messages.editDeliveryLocation)}
            </Button>
          </Cell>
          <Cell palm="12/12">
            <DecimalField
              autoFocus
              decimalPlaces={2}
              fullWidth
              label={formatMessage(messages.amount, {
                unit: unitString,
              })}
              margin="dense"
              maxDigits={9}
              onChange={this.handleAmountChange}
              value={this.state.amount}
            />
            <TrimTextField
              fullWidth
              label={formatMessage(messages.note)}
              margin="dense"
              onChange={this.handleNoteChange}
              value={this.state.note}
              variant="outlined"
            />
          </Cell>
        </Grid>
      </div>
    );
    return (
      <ResponsiveDialog
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        open={open}
        title={this.getTitle()}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps: YieldDialogProps<T>): void {
    if (nextProps.open && !this.props.open) {
      this.setState({
        amount: nextProps.amount != null ? nextProps.amount : null,
        note: nextProps.note || "",
      });
    }
  }
}
