import {AvailabilityUrl, UserUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface Availability extends ResourceInstance<AvailabilityUrl> {
  readonly friday: boolean | null;
  readonly monday: boolean | null;
  readonly saturday: boolean | null;
  readonly sunday: boolean | null;
  readonly thursday: boolean | null;
  readonly tuesday: boolean | null;
  readonly user: UserUrl;
  readonly wednesday: boolean | null;
  readonly weekNumber: number;
  readonly year: number;
}

const AVAILABILITY_WEEKDAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

type AvailabilityWeekday = (typeof AVAILABILITY_WEEKDAYS)[number];

export function availabilityWeekdayFromDate(date: Date): AvailabilityWeekday {
  return AVAILABILITY_WEEKDAYS[date.getDay()];
}
