import React, {useEffect, useState} from "react";
import {useDragDropManager} from "react-dnd";

const SCROLL_AREA_MIN_HEIGHT_PX = 200;
const SCROLL_AREA_MIN_HEIGHT_PROPORTIONAL = 0.33;
const SCROLL_AREA_MAX_HEIGHT_PROPORTIONAL = 0.48;

const FAST_SCROLL_AREA_PROPORTIONAL = 0.5;

const SCROLL_PIXELS_FAST = 15;
const SCROLL_PIXELS_SLOW = 5;

enum ScrollDirection {
  NONE,
  UP_FAST,
  UP_SLOW,
  DOWN_SLOW,
  DOWN_FAST,
}

interface DragDropScrollHandlerProps {
  scrollElement: "GLOBAL" | HTMLElement | null;
}

export const DragDropScrollHandler = function (
  props: DragDropScrollHandlerProps,
): React.JSX.Element {
  const {scrollElement} = props;

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(ScrollDirection.NONE);
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  useEffect(() => {
    const unsubscribe = monitor.subscribeToStateChange(() => {
      setIsDragging(monitor.isDragging());
    });
    return unsubscribe;
  }, [monitor]);

  useEffect(() => {
    if (isDragging) {
      const unsubscribe = monitor.subscribeToOffsetChange(() => {
        const offset = monitor.getClientOffset();

        if (!offset) {
          return;
        }

        const scrollAreaMinHeigh = Math.max(
          SCROLL_AREA_MIN_HEIGHT_PX,
          SCROLL_AREA_MIN_HEIGHT_PROPORTIONAL * window.innerHeight,
        );
        const scrollAreaMaxHeight = SCROLL_AREA_MAX_HEIGHT_PROPORTIONAL * window.innerHeight;
        const scrollAreaHeight = Math.min(scrollAreaMinHeigh, scrollAreaMaxHeight);

        if (offset.y < scrollAreaHeight * FAST_SCROLL_AREA_PROPORTIONAL) {
          setScrollDirection(ScrollDirection.UP_FAST);
        } else if (offset.y < scrollAreaHeight) {
          setScrollDirection(ScrollDirection.UP_SLOW);
        } else if (
          offset.y >
          window.innerHeight - scrollAreaHeight * FAST_SCROLL_AREA_PROPORTIONAL
        ) {
          setScrollDirection(ScrollDirection.DOWN_FAST);
        } else if (offset.y > window.innerHeight - scrollAreaHeight) {
          setScrollDirection(ScrollDirection.DOWN_SLOW);
        } else {
          setScrollDirection(ScrollDirection.NONE);
        }
      });
      return () => {
        setScrollDirection(ScrollDirection.NONE);
        unsubscribe();
      };
    } else {
      return undefined;
    }
  }, [isDragging, monitor, scrollElement]);

  useEffect(() => {
    if (!scrollElement || scrollDirection === ScrollDirection.NONE) {
      return undefined;
    }
    const step =
      scrollDirection === ScrollDirection.UP_FAST
        ? -SCROLL_PIXELS_FAST
        : scrollDirection === ScrollDirection.UP_SLOW
          ? -SCROLL_PIXELS_SLOW
          : scrollDirection === ScrollDirection.DOWN_SLOW
            ? SCROLL_PIXELS_SLOW
            : SCROLL_PIXELS_FAST;
    const realScrollElement = scrollElement === "GLOBAL" ? window : scrollElement;
    const interval = window.setInterval(() => {
      realScrollElement.scrollBy(0, step);
    }, 1);
    return () => {
      window.clearInterval(interval);
    };
  }, [scrollDirection, scrollElement]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
