import {HOUR_MINUTES} from "@co-common-libs/utils";
import React, {useCallback} from "react";
import {defineMessages, useIntl} from "react-intl";
import {CustomTextField, TextFieldPropsWithoutOnChange} from "./custom-text-field";

// import bowser from "bowser";

const messages = defineMessages({
  format: {
    defaultMessage: "Indtast minutter, timer:minutter eller timer.minutter",
    id: "minutes-field.error.provide-minutes",
  },
  formatHours: {
    defaultMessage: "Indtast timer, timer:minutter eller timer.minutter",
    id: "minutes-field.error.provide-hours",
  },
  hourMinutes: {
    defaultMessage: "Minuttal over 59 må ikke kombineres med timer",
    id: "minutes-field.error.max-minutes-with-hour",
  },
  minValue: {
    defaultMessage: "Husk at tid indtastes mm, tt:mm (eller tt.mm), mente du {suggestHours}:00?",
    id: "minutes-field.warning.min-value",
  },
  minValueHours: {
    defaultMessage: "Der skal angives min. 15 minutter",
    id: "minutes-field.warning.min-value-hours",
  },
  mobileFormat: {
    defaultMessage: "Indtast minutter, timer,minutter eller timer.minutter",
    id: "minutes-field.error.provide-minutes-mobile",
  },
  mobileFormatHours: {
    defaultMessage: "Indtast timer, timer,minutter eller timer.minutter",
    id: "minutes-field.error.provide-hours-mobile",
  },
  valueToHigh: {
    defaultMessage: "Tallet er for stort",
  },
});

function baseValueFrom(text: string, integerAsHours: boolean): number | null {
  const parts = text.trim().split(/[:.,]/);
  const HOURS_AND_MINUTES_ARRAY_LENGTH = 2;
  if (parts.length === HOURS_AND_MINUTES_ARRAY_LENGTH) {
    const [hours, minutes] = parts;
    const hoursValue = hours ? parseInt(hours) : 0;
    const minutesValue = minutes ? parseInt(minutes) : 0;
    return hoursValue * HOUR_MINUTES + minutesValue;
  } else if (integerAsHours) {
    const [hours] = parts;
    return HOUR_MINUTES * parseInt(hours);
  } else {
    const [minutes] = parts;
    return parseInt(minutes);
  }
}

interface MinutesFieldProps extends TextFieldPropsWithoutOnChange {
  integerAsMinutes?: boolean;
  maxMinutes?: number;
  minQuarter?: boolean;
  onChange: (value: number | null) => void;
  step?: string;
  type?: string;
}

export function MinutesField(props: MinutesFieldProps): React.JSX.Element {
  // const mobile = !!bowser.mobile || !!bowser.tablet;
  const mobile = false;
  const {
    integerAsMinutes = false,
    maxMinutes,
    minQuarter = false,
    step = mobile ? "any" : undefined,
    type = mobile ? "number" : "text",
    ...other
  } = props;

  const {formatMessage} = useIntl();

  const integerAsHours = !integerAsMinutes;

  const validationError = useCallback(
    (text: string, browserValid?: boolean): string | undefined => {
      if (mobile && browserValid === false) {
        if (integerAsHours) {
          return formatMessage(messages.mobileFormatHours);
        } else {
          return formatMessage(messages.mobileFormat);
        }
      }
      if (text) {
        const re = /^\d+(?:[:.,]\d*)?$/;
        if (!re.test(text.trim())) {
          if (integerAsHours) {
            return formatMessage(messages.formatHours);
          } else {
            return formatMessage(messages.format);
          }
        } else {
          const parts = text.trim().split(/[:.,]/);
          const minutes = parts[1];
          const minutesValue = minutes ? parseInt(minutes) : 0;
          if (minutesValue >= HOUR_MINUTES) {
            return formatMessage(messages.hourMinutes);
          }
        }
        const baseValue = baseValueFrom(text, integerAsHours);
        if (baseValue && maxMinutes && baseValue > maxMinutes) {
          return formatMessage(messages.valueToHigh);
        }
      }
      return undefined;
    },
    [formatMessage, integerAsHours, maxMinutes, mobile],
  );

  const valueFrom = useCallback(
    (text: string): number | null => {
      if (text && !validationError(text)) {
        return baseValueFrom(text, integerAsHours);
      } else {
        return null;
      }
    },
    [integerAsHours, validationError],
  );

  const minQuarterValidationWarning = useCallback(
    (text: string, _browserValid?: boolean): string | undefined => {
      if (text) {
        const value = baseValueFrom(text, integerAsHours);
        const QUARTER_HOUR_MINUTES = 15;
        if (value && value < QUARTER_HOUR_MINUTES) {
          if (integerAsHours) {
            return formatMessage(messages.minValueHours);
          } else {
            return formatMessage(messages.minValue, {suggestHours: value});
          }
        }
      }
      return undefined;
    },
    [formatMessage, integerAsHours],
  );

  const textFrom = useCallback(
    (value: number | null): string => {
      if (value == null) {
        return "";
      }
      const hours = Math.floor(value / HOUR_MINUTES);
      const minutes = value % HOUR_MINUTES;
      const minutesString = `${minutes}`;
      if (hours || integerAsHours) {
        const MINUTES_DIGITS = 2;
        const paddedMinutesString = minutesString.padStart(MINUTES_DIGITS, "0");
        const separator = mobile ? "." : ":";
        return `${hours}${separator}${paddedMinutesString}`;
      } else {
        return minutesString;
      }
    },
    [integerAsHours, mobile],
  );

  const extra = {step, type} as any;

  return (
    <CustomTextField<number>
      {...other}
      inputProps={{inputMode: "decimal"}}
      textFrom={textFrom}
      validationError={validationError}
      validationWarning={minQuarter ? minQuarterValidationWarning : undefined}
      valueFrom={valueFrom}
      {...extra}
    />
  );
}
