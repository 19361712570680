import {ReadonlyConfig} from "@co-common-libs/config";
import {ExtendedCustomerSettingsPart} from "./extended-customer-settings";

type ExtendedUnitSettingsPart = ExtendedCustomerSettingsPart & Pick<ReadonlyConfig, "canEditUnits">;

export interface ExtendedUnitSettings {
  canCreate: boolean;
  canEdit: boolean;
}

export function extendedUnitSettings(config: ExtendedUnitSettingsPart): ExtendedUnitSettings {
  const {canEditUnits, unicontaSync} = config;

  return {
    canCreate: canEditUnits && !unicontaSync,
    canEdit: canEditUnits && !unicontaSync,
  };
}
