import {Command, instanceURL, ProductUseLog, Task, urlToId} from "@co-common-libs/resources";
import {sortByOrderMember} from "@co-common-libs/utils";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {ProvisionaryCommand} from "../../resources/actions";
import {getCustomerSettings, getProductUseLogArray} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";
import {getBaseURL} from "./get-base-url";

const MAX_SAVED_PRODUCT_COUNT = 10;

// only triggered an *adding* products;
// *after* setting machine operator and work type
export function writeProductUseLog(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  if (!newTask) {
    return null;
  }

  const {machineOperator, priceGroup, workType} = newTask;
  if (
    !workType ||
    !machineOperator ||
    (newTask.productUses &&
      (!Object.keys(newTask.productUses).length ||
        _.isEqual(newTask.productUses, oldTask?.productUses)))
  ) {
    return null;
  }

  const state = middlewareApi.getState();
  const customerSettings = getCustomerSettings(state);
  if (
    customerSettings.defaultTaskEmployee &&
    urlToId(machineOperator) === customerSettings.defaultTaskEmployee
  ) {
    // nothing to save if machineOperator is defaultTaskEmployee
    return null;
  }

  const productUseArray = sortByOrderMember(Object.values(newTask.productUses || {}));
  const oldProductUseArray = oldTask
    ? sortByOrderMember(Object.values(oldTask.productUses || {}))
    : [];

  const productURLs = productUseArray.map((p) => p.product);
  const oldProductURLs = oldProductUseArray.map((p) => p.product);

  const addedProductURLs = _.difference(productURLs, oldProductURLs);

  if (!addedProductURLs.length) {
    return null;
  }

  const productUseLogArray = getProductUseLogArray(state);
  const filteredProductUseLogArray = productUseLogArray.filter(
    (p) => p.user === machineOperator && p.worktype === workType && p.priceGroup === priceGroup,
  );
  const existingProductUseLog = _.maxBy(
    filteredProductUseLogArray,
    // if no lastChanged (local/not synced with server), use fallback
    // value which puts that entry "last" compared to timestamps
    (p) => p.lastChanged || "x",
  );
  if (existingProductUseLog) {
    const newProductList = [
      ...new Set(addedProductURLs.concat(existingProductUseLog.products)),
    ].slice(0, MAX_SAVED_PRODUCT_COUNT);
    if (_.isEqual(newProductList, existingProductUseLog.products)) {
      return null;
    }
    const patchProductUseLog: Command = {
      action: "UPDATE",
      patch: [{member: "products", value: newProductList}],
      url: existingProductUseLog.url,
    };
    return {after: [patchProductUseLog]};
  } else {
    const newProductList = [...new Set(addedProductURLs)].slice(0, MAX_SAVED_PRODUCT_COUNT);
    const id = uuid();
    const baseURL = getBaseURL(command.url);
    const url = instanceURL(baseURL, "productUseLog", id);
    const newProductUseLog: ProductUseLog = {
      id,
      priceGroup,
      products: newProductList,
      url,
      user: machineOperator,
      worktype: workType,
    };
    return {after: [{action: "CREATE", instance: newProductUseLog, url}]};
  }
}
