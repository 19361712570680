import {EmployeeGroup} from "@co-common-libs/resources";
import {TrimTextField} from "@co-frontend-libs/components";
import {FormControlLabel, Switch} from "@material-ui/core";
import {SetObjectPropertyAction, useObjectPropertyOnChangeDispatcher} from "app-utils";
import React, {Dispatch, useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {z} from "zod";

type EmployeeGroupForm = Pick<EmployeeGroup, "active" | "name">;

export function EmployeeGroupForm<FormErrorType extends z.ZodError>(props: {
  dispatch: Dispatch<SetObjectPropertyAction<EmployeeGroupForm>>;
  employeeGroup: EmployeeGroupForm;
  validationError: FormErrorType | null;
}): React.JSX.Element {
  const {dispatch, employeeGroup, validationError} = props;
  const fieldErrors = validationError?.formErrors.fieldErrors;
  const intl = useIntl();
  const changePropagator = useObjectPropertyOnChangeDispatcher<EmployeeGroupForm>(dispatch);

  const handleActiveChange = useCallback(
    (_event: React.ChangeEvent<object>, checked: boolean) =>
      changePropagator.handleChange("active")(checked),
    [changePropagator],
  );
  return (
    <>
      <TrimTextField
        autoFocus
        error={!!fieldErrors?.name}
        fullWidth
        label={intl.formatMessage({defaultMessage: "Navn"})}
        margin="dense"
        onChange={changePropagator.handleChange("name")}
        required
        value={employeeGroup.name}
        variant="outlined"
        warningText={fieldErrors?.name?.join(" ")}
      />
      <FormControlLabel
        control={<Switch checked={employeeGroup.active} />}
        label={intl.formatMessage({defaultMessage: "Aktiv"})}
        labelPlacement="end"
        onChange={handleActiveChange}
      />
      <div>
        <FormattedMessage defaultMessage="* Skal udfyldes" />
      </div>
    </>
  );
}
