import {Fab, FabProps, PropTypes} from "@material-ui/core";
import {getVerticalStackingFabstyle} from "app-utils";
import React from "react";

interface StackingFloatingActionButtonProps {
  children: NonNullable<React.ReactNode>;
  color?: PropTypes.Color;
  component?: React.ElementType;
  disabled?: boolean;
  fabPosition?: "absolute" | "fixed";
  onClick?: (event: React.MouseEvent) => void;
  stackIndex: number;
}

export const VerticalStackingFloatingActionButton = React.memo(
  function VerticalStackingFloatingActionButton(
    props: StackingFloatingActionButtonProps,
  ): React.JSX.Element {
    const {
      children,
      color,
      component = "button",
      disabled = false,
      fabPosition = "fixed",
      onClick,
      stackIndex = 0,
    } = props;

    const fabProps: Partial<FabProps> = {
      disabled,
      onClick,
      style: getVerticalStackingFabstyle(stackIndex, fabPosition),
    };
    if (color) {
      fabProps.color = color;
    }

    return (
      <Fab component={component} {...fabProps}>
        {children}
      </Fab>
    );
  },
);
