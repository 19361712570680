import {PriceItem, PriceItemUseWithOrder, Unit} from "@co-common-libs/resources";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {CONVERSION_UNIT_COLUMN_WIDTH, COUNT_COLUMN_WIDTH, UNIT_COLUMN_WIDTH} from "./constants";
import {DisplayTableRowWithEntryCallbacks} from "./display-table-row";

interface DisplayTableProps {
  conversionRelatedValues: {
    readonly [unit: string]: number | null;
  } | null;
  onCountChange: (identifier: string, value: number | null) => void;
  onNotesChange?: ((identifier: string, value: string) => void) | undefined;
  readonly: boolean;
  readonlyPriceItems: ReadonlySet<string> | null;
  showNotes: boolean;
  showRelationConversionColumns: boolean;
  sortedPriceItemUses: readonly {
    readonly conversionUnit: Unit | null;
    readonly identifier: string;
    readonly priceItem: PriceItem;
    readonly priceItemUse: PriceItemUseWithOrder;
    readonly unit: Unit | null;
  }[];
}

export const DisplayTable = React.memo(function DisplayTable(
  props: DisplayTableProps,
): React.JSX.Element {
  const {
    conversionRelatedValues,
    onCountChange,
    onNotesChange,
    readonly,
    readonlyPriceItems,
    showNotes,
    showRelationConversionColumns,
    sortedPriceItemUses,
  } = props;

  const rows = sortedPriceItemUses.map(
    ({conversionUnit, identifier, priceItem, priceItemUse, unit}) => (
      <DisplayTableRowWithEntryCallbacks
        conversionRelatedValues={conversionRelatedValues}
        conversionUnit={conversionUnit}
        identifier={identifier}
        key={identifier}
        notesReadonly={readonly}
        onCountChange={onCountChange}
        onNotesButtonClick={null}
        onNotesChange={onNotesChange}
        priceItem={priceItem}
        priceItemUse={priceItemUse}
        readonly={readonly || readonlyPriceItems?.has(priceItemUse.priceItem) || false}
        showNotes={showNotes}
        showRelationConversionColumns={showRelationConversionColumns}
        unit={unit}
      />
    ),
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage defaultMessage="Tekst" />
          </TableCell>
          <TableCell style={{width: COUNT_COLUMN_WIDTH}}>Antal</TableCell>
          <TableCell style={{paddingLeft: 0, paddingRight: 0, width: 48}} />
          <TableCell style={{width: UNIT_COLUMN_WIDTH}}>Enhed</TableCell>
          {showRelationConversionColumns ? (
            <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Antal" />
            </TableCell>
          ) : null}
          {showRelationConversionColumns ? (
            <TableCell style={{width: CONVERSION_UNIT_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Enhed" />
            </TableCell>
          ) : null}
          {showNotes ? (
            <TableCell>
              <FormattedMessage defaultMessage="Noter" />
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
});
