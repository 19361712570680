import {Customer} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import React from "react";
import {useSelector} from "react-redux";
import {CustomerDefaultKrPerLiterFuelSurchargeCard} from "./customer-default-kr-per-liter-fuel-surcharge-card";
import {CustomerDefaultPricePercentFuelSurchargeCard} from "./customer-default-price-percent-fuel-surcharge-card";
import {CustomerMachineKrPerLiterFuelSurchargeCard} from "./customer-machine-kr-per-liter-fuel-surcharge-card";
import {CustomerMachinePricePercentFuelSurchargeCard} from "./customer-machine-price-percent-fuel-surcharge-card";
import {CustomerWorkTypeKrPerLiterFuelSurchargeCard} from "./customer-work-type-kr-per-liter-fuel-surcharge-card";
import {CustomerWorkTypePricePercentFuelSurchargeCard} from "./customer-work-type-price-percent-fuel-surcharge-card";

interface FuelSurchargeTabProps {
  customer: Customer;
}

export const FuelSurchargeTab = React.memo(function FuelSurchargeTab(
  props: FuelSurchargeTabProps,
): React.JSX.Element | null {
  const {customer} = props;

  const customerSettings = useSelector(getCustomerSettings);

  if (customerSettings.fuelSurcharge === "PRICE_PERCENT") {
    return (
      <>
        <div style={{padding: "1em"}}>
          <CustomerDefaultPricePercentFuelSurchargeCard customer={customer} />
        </div>
        {customerSettings.enableMachinePriceStructureOverviewSettingsPage ? (
          <div style={{padding: "1em"}}>
            <CustomerMachinePricePercentFuelSurchargeCard customer={customer} />
          </div>
        ) : null}
        {customerSettings.enableWorkTypePriceStructureOverviewSettingsPage ? (
          <div style={{padding: "1em"}}>
            <CustomerWorkTypePricePercentFuelSurchargeCard customer={customer} />
          </div>
        ) : null}
      </>
    );
  } else if (customerSettings.fuelSurcharge === "KR_PER_LITER") {
    return (
      <>
        <div style={{padding: "1em"}}>
          <CustomerDefaultKrPerLiterFuelSurchargeCard customer={customer} />
        </div>
        {customerSettings.enableMachinePriceStructureOverviewSettingsPage ? (
          <div style={{padding: "1em"}}>
            <CustomerMachineKrPerLiterFuelSurchargeCard customer={customer} />
          </div>
        ) : null}
        {customerSettings.enableWorkTypePriceStructureOverviewSettingsPage ? (
          <div style={{padding: "1em"}}>
            <CustomerWorkTypeKrPerLiterFuelSurchargeCard customer={customer} />
          </div>
        ) : null}
      </>
    );
  } else {
    return null;
  }
});
