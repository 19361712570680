import {ResponsiveInfoDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";

interface FuelSurchargeConsumptionMissingDialogProps {
  onClose: () => void;
  open: boolean;
}

export const FuelSurchargeConsumptionMissingDialog = React.memo(
  function FuelSurchargeConsumptionMissingDialog(
    props: FuelSurchargeConsumptionMissingDialogProps,
  ): React.JSX.Element {
    const {onClose, open} = props;

    const intl = useIntl();

    return (
      <ResponsiveInfoDialog
        onClose={onClose}
        open={open}
        title={intl.formatMessage({
          defaultMessage: "Maskine mangler brændstofforbrug",
        })}
      >
        <DialogContent>
          <FormattedMessage defaultMessage="Tillæg kan ikke beregnes da maskinens brændstofforbrug ikke er angivet" />
        </DialogContent>
      </ResponsiveInfoDialog>
    );
  },
);
