import {Config} from "@co-common-libs/config";
import {
  Machine,
  PriceGroup,
  PriceGroupItem,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Unit,
  UnitUrl,
  WorkType,
} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {identifierComparator, notUndefined, simpleComparator} from "@co-common-libs/utils";
import {ExtendedConfig} from "extended-config";
import _ from "lodash";

//TODO(mr): split this into files with more helpful names

export const getSortedMachines = (
  machineArray: readonly Machine[],
  disabledMachines: readonly string[],
): Machine[] => {
  return machineArray
    .filter(
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ({active, c5_machine}) => active && (!c5_machine || !disabledMachines.includes(c5_machine)),
    )
    .sort((a, b) => identifierComparator(a.c5_machine, b.c5_machine));
};

export const getSortedWorkTypes = (
  workTypeArray: readonly WorkType[],
  disabledWorkTypes: readonly string[],
): WorkType[] => {
  return workTypeArray
    .filter(
      (instance) =>
        instance.externalTaskPrimary &&
        !disabledWorkTypes.includes(instance.identifier) &&
        instance.active,
    )
    .sort((a, b) => identifierComparator(a.identifier, b.identifier));
};

export const priceGroupCompare = (a: PriceGroup, b: PriceGroup): number => {
  const aActive = a.active;
  const bActive = b.active;
  if (aActive && !bActive) {
    return -1;
  } else if (bActive && !aActive) {
    return 1;
  } else {
    return identifierComparator(a.identifier, b.identifier);
  }
};

export const getPriceGroupsFromWorkTypeOrMachine = (
  instance: {pricegroups?: readonly PriceGroupUrl[]} | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
): PriceGroup[] => {
  const priceGroupURLs = instance?.pricegroups;
  if (!priceGroupURLs || !priceGroupURLs.length) {
    return [];
  }
  const priceGroups: PriceGroup[] = priceGroupURLs
    .map(priceGroupLookup)
    .filter(notUndefined)
    .filter(({active}) => active);
  priceGroups.sort((a, b) => identifierComparator(a.identifier, b.identifier));
  return priceGroups;
};

export function getPriceGroupsFromMap<Url extends string>(
  url: Url,
  sourceLookup: (url: Url) => {pricegroups?: readonly PriceGroupUrl[]} | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
): PriceGroup[] {
  const workTypeOrMachine = sourceLookup(url);
  return getPriceGroupsFromWorkTypeOrMachine(workTypeOrMachine, priceGroupLookup);
}

export const getPriceItemsForSelectedPriceGroup = (
  selectedPriceGroup: PriceGroup,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  extendedCustomerSettings: ExtendedConfig,
): PriceItem[] => {
  const {navSync, priceItems} = extendedCustomerSettings;
  const {priceGroupItemSet} = selectedPriceGroup;
  if (!priceGroupItemSet || !priceGroupItemSet.length) {
    return [];
  }
  const priceGroupItemPriceItemPairs: [PriceGroupItem, PriceItem][] = [];
  for (let i = 0; i < priceGroupItemSet.length; i += 1) {
    const priceGroupItem = priceGroupItemSet[i];
    const priceItem = priceItemLookup(priceGroupItem.priceItem);
    if (priceItem && priceItem.active) {
      priceGroupItemPriceItemPairs.push([priceGroupItem, priceItem]);
    }
  }
  priceGroupItemPriceItemPairs.sort(
    ([_priceGroupItemA, priceItemA], [_priceGroupItemB, priceItemB]) =>
      simpleComparator(
        getUnitString(priceItemA, unitLookup).toLowerCase(),
        getUnitString(priceItemB, unitLookup).toLowerCase(),
      ) || simpleComparator(priceItemA.name.toLowerCase(), priceItemB.name.toLowerCase()),
  );

  if (priceItems.allowManualPriceGroupPriceItemOrdering) {
    return _.sortBy(priceGroupItemPriceItemPairs, ([priceGroupItem, _priceItem]) => {
      return priceGroupItem.order;
    }).map(([_priceGroupItem, priceItem]) => priceItem);
  } else {
    const priceItemArray = priceGroupItemPriceItemPairs.map(
      ([_priceGroupItem, priceItem]) => priceItem,
    );
    if (navSync) {
      return _.sortBy(priceItemArray, (priceItem) => priceItem.lineNumber);
    } else {
      priceItemArray.sort((a, b) => identifierComparator(a.remoteUrl, b.remoteUrl));
      return priceItemArray;
    }
  }
};

const economicProductsURLPrefix = "https://restapi.e-conomic.com/products/";

export const getPriceItemIdentifier = (priceItem: PriceItem): string | null => {
  const {remoteUrl} = priceItem;
  if (remoteUrl) {
    if (remoteUrl.startsWith(economicProductsURLPrefix)) {
      return remoteUrl.substring(economicProductsURLPrefix.length);
    } else {
      return remoteUrl;
    }
  }
  return null;
};

export const getPriceItemNameAndIdentifier = (
  priceItem: PriceItem,
  customerSettings: Config,
): string => {
  const priceitemIdentifier = getPriceItemIdentifier(priceItem);

  return customerSettings.economicSync && priceitemIdentifier
    ? `${priceItem.name} (${priceitemIdentifier})`
    : priceItem.name;
};

export const remoteURLContainsData = (priceItemArray: readonly PriceItem[]): boolean =>
  priceItemArray.some((p) => p.active && !!p.remoteUrl);

export type ValuesState = {readonly [identifier: string]: unknown};

export type ValuesAction =
  | {identifier: string; type: "delete"}
  | {identifier: string; type: "put"; value: unknown}
  | {type: "replace"; value: ValuesState};

export function valuesReducer(state: ValuesState, action: ValuesAction): ValuesState {
  if (action.type === "replace") {
    return action.value;
  }
  const result = {...state};
  const {identifier} = action;
  switch (action.type) {
    case "delete":
      delete result[identifier];
      break;
    case "put":
      result[identifier] = action.value;
      break;
  }
  return result;
}
