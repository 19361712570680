import {DataIssue, dataIssues} from "@co-common-libs/csv-import-specifications";
import {IntlShape} from "react-intl";

// TypeScript validates via return type
// eslint-disable-next-line consistent-return
function formatIssue(
  intl: IntlShape,
  issue: DataIssue,
  columnLabels: ReadonlyMap<string, string>,
): string {
  switch (issue.type) {
    case "economic_sync":
      return issue.text;
    case "hidden_identifiers":
      return intl.formatMessage(
        {
          defaultMessage: "Værdier er skjulte via anden opsætning: {values}.",
        },
        {values: issue.values.join(", ")},
      );
    case "inactive_exists":
      return intl.formatMessage(
        {
          defaultMessage: "Værdier findes i forvejen som inaktive: {values}.",
        },
        {values: issue.values.join(", ")},
      );
    case "unknown_reference":
      return intl.formatMessage(
        {
          defaultMessage: "Ukendte referencer i kolonne {label}: {values}.",
        },
        {
          label: columnLabels.get(issue.column) ?? issue.column,
          values: issue.values.join(", "),
        },
      );
  }
}

export function formatImportError(
  intl: IntlShape,
  errorData: unknown,
  columnLabels: ReadonlyMap<string, string>,
): string {
  const csvDataIssues = dataIssues.parse(errorData);
  return csvDataIssues.map((issue) => formatIssue(intl, issue, columnLabels)).join("\n");
}
