import {Task} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React, {useCallback} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {Linkify} from "../linkify";

const messages = defineMessages({
  goTo: {
    defaultMessage: "Gå til",
  },
  newNoteTitle: {
    defaultMessage: "Ny note på aktiv opgave",
  },
  ok: {
    defaultMessage: "OK",
  },
});

interface NotesChangedDialogProps {
  onRequestClose: () => void;
  task?: Task | undefined;
}

export const NotesChangedDialog = React.memo(function NotesChangedDialog(
  props: NotesChangedDialogProps,
): React.JSX.Element {
  const {onRequestClose, task} = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleGoToActive = useCallback(() => {
    if (task) {
      dispatch(actions.go("/task/:id", {id: task.id as string}, {notesDisplayed: "1"}));
    }
    onRequestClose();
  }, [dispatch, onRequestClose, task]);

  const open = !!task;
  return (
    <ResponsiveDialog
      autoFocusCancel
      cancelLabel={intl.formatMessage(messages.ok)}
      okLabel={intl.formatMessage(messages.goTo)}
      onCancel={props.onRequestClose}
      onOk={handleGoToActive}
      open={open}
      title={intl.formatMessage(messages.newNoteTitle)}
    >
      <DialogContent>
        <Linkify>{task && task.notesFromManager}</Linkify>
      </DialogContent>
    </ResponsiveDialog>
  );
});
