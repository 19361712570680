import {
  ContentSelection,
  JournalDataEntry,
  JournalSummarizedDataByProductEntry,
  JournalSummarizedDataEntry,
  StorageJournalContent,
} from "@co-common-libs/report-rendering";
import {notUndefined} from "@co-common-libs/utils";
import {
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getPriceGroupLookup,
  getProductLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import {instanceURL, SPACING} from "frontend-global-config";
import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {StorageJournalContentSelection} from "./storage-journal-content-selection";

interface StorageJournalCardProps {
  csvURL: string;
  data: readonly JournalDataEntry[];
  fromDate: string;
  invoiceCustomers: readonly string[];
  location: string;
  locationCustomers: readonly string[];
  machines: readonly {
    readonly machine: string;
    readonly priceGroups: readonly string[];
  }[];
  onShowCustomerColumnCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onShowFilteringCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pdfURL: string;
  products: readonly string[];
  restrictToAndFromToLocationCustomers: boolean;
  showCustomerColumn: boolean;
  showFiltering: boolean;
  summarizedByProductData: readonly JournalSummarizedDataByProductEntry[];
  summarizedData: readonly JournalSummarizedDataEntry[];
  toDate: string;
  workTypes: readonly {
    readonly priceGroups: readonly string[];
    readonly workType: string;
  }[];
}

export function StorageJournalCard(props: StorageJournalCardProps): React.JSX.Element {
  const {
    csvURL,
    data,
    fromDate,
    invoiceCustomers,
    location,
    locationCustomers,
    machines,
    onShowCustomerColumnCheck,
    onShowFilteringCheck,
    pdfURL,
    products,
    restrictToAndFromToLocationCustomers,
    showCustomerColumn,
    showFiltering,
    summarizedByProductData,
    summarizedData,
    toDate,
    workTypes,
  } = props;

  const theme = useTheme();

  const [selectedContent, setSelectedContent] = useState(ContentSelection.EVERYTHING);
  const customerLookup = useSelector(getCustomerLookup);
  const machineLookup = useSelector(getMachineLookup);
  const pricegroupLookup = useSelector(getPriceGroupLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const locationLookup = useSelector(getLocationLookup);
  const productLookup = useSelector(getProductLookup);
  const customerSettings = useSelector(getCustomerSettings);

  const invoiceCustomerNames = invoiceCustomers
    .map((id) => customerLookup(instanceURL("customer", id)))
    .filter(notUndefined)
    .map((customer) => customer.name)
    .sort();
  const productLabels = products
    .map((id) => productLookup(instanceURL("product", id)))
    .filter(notUndefined)
    .map((product) => `${product.catalogNumber}: ${product.name}`)
    .sort();
  const locationCustomerNames = locationCustomers
    .map((id) => customerLookup(instanceURL("customer", id)))
    .filter(notUndefined)
    .map((customer) => customer.name)
    .sort();
  const machineWithPriceGroupLabels = machines
    .map(({machine: machineId, priceGroups}) => {
      const machine = machineLookup(instanceURL("machine", machineId));
      const machineString = machine ? `${machine.c5_machine}: ${machine.name}` : "";
      if (!priceGroups.length) {
        return machineString;
      } else {
        const priceGroupsString = priceGroups
          .map((id) => pricegroupLookup(instanceURL("priceGroup", id)))
          .filter(notUndefined)
          .map((priceGroup) => priceGroup.name)
          .sort()
          .join(", ");
        return `${machineString} (${priceGroupsString})`;
      }
    })
    .sort();

  const workTypeWithPriceGroupLabels = workTypes
    .map(({priceGroups, workType: workTypeId}) => {
      const workType = workTypeLookup(instanceURL("workType", workTypeId));
      const workTypeString = workType ? `${workType.identifier}: ${workType.name}` : "";
      if (!priceGroups.length) {
        return workTypeString;
      } else {
        const priceGroupsString = priceGroups
          .map((id) => pricegroupLookup(instanceURL("priceGroup", id)))
          .filter(notUndefined)
          .map((priceGroup) => priceGroup.name)
          .sort()
          .join(", ");
        return `${workTypeString} (${priceGroupsString})`;
      }
    })
    .sort();

  const locationInstance = locationLookup(instanceURL("location", location));
  const locationName =
    locationInstance?.name && locationInstance?.address
      ? `${locationInstance.name}, ${locationInstance.address}`
      : locationInstance?.name || locationInstance?.address || "";

  const showCustomerColumnString = showCustomerColumn ? "&showCustomerColumn" : "";
  const showFilteringString = showFiltering ? "&showFiltering" : "";

  const contentSelectionString = `&contentSelection=${selectedContent}`;

  const restrictToAndFromToLocationCustomersString = restrictToAndFromToLocationCustomers
    ? "&restrictToAndFromToLocationCustomers"
    : "";

  const pdfURLWithOptions = `${pdfURL}${showCustomerColumnString}${showFilteringString}${contentSelectionString}${restrictToAndFromToLocationCustomersString}`;

  const csvURLWithContentSelection = `${csvURL}${showCustomerColumnString}${contentSelectionString}${restrictToAndFromToLocationCustomersString}`;

  const enableCustomerColumnOption =
    selectedContent === ContentSelection.EVERYTHING || selectedContent === ContentSelection.EVENTS;
  const enableCsvDownload = selectedContent !== ContentSelection.EVERYTHING;

  const anyFilteringApplied = [
    invoiceCustomers,
    locationCustomers,
    workTypes,
    machines,
    products,
  ].some((idArray) => idArray.length);
  const disableShowFiltering = !anyFilteringApplied;

  return (
    <Card style={{maxWidth: 1150}}>
      <CardHeader title={<FormattedMessage defaultMessage="Lagerjournal" />} />
      <CardContent>
        <Grid container direction="column">
          <Grid item>
            <Tooltip
              arrow
              disableFocusListener={enableCustomerColumnOption}
              disableHoverListener={enableCustomerColumnOption}
              disableTouchListener={enableCustomerColumnOption}
              placement="top"
              title="Fakturakunde-kolonne vises kun ved valg af ALLE og LAGER BEVÆGELSER"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showCustomerColumn && enableCustomerColumnOption}
                    onChange={onShowCustomerColumnCheck}
                  />
                }
                disabled={!enableCustomerColumnOption}
                label={<FormattedMessage defaultMessage="Vis fakturakunde-kolonne" />}
              />
            </Tooltip>
            <FormControlLabel
              control={<Checkbox checked={showFiltering} onChange={onShowFilteringCheck} />}
              disabled={disableShowFiltering}
              label={<FormattedMessage defaultMessage="Vis filtreringskriterier" />}
            />
          </Grid>
          <Grid alignItems="flex-start" container direction="row" item spacing={1}>
            <Grid item>
              <StorageJournalContentSelection
                onChange={setSelectedContent}
                selected={selectedContent}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                href={pdfURLWithOptions}
                style={{
                  marginRight: theme.spacing(SPACING.XSMALL),
                }}
                target="_blank"
                variant="contained"
              >
                <FormattedMessage defaultMessage="Hent PDF" />
              </Button>
              <Tooltip
                disableFocusListener={enableCsvDownload}
                disableHoverListener={enableCsvDownload}
                disableTouchListener={enableCsvDownload}
                placement="top"
                title="Der kan ikke hentes CSV for valget ALLE"
              >
                <span>
                  <Button
                    color="primary"
                    disabled={!enableCsvDownload}
                    href={csvURLWithContentSelection}
                    target="_blank"
                    variant="contained"
                  >
                    <FormattedMessage defaultMessage="Hent CSV" />
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{paddingTop: theme.spacing(SPACING.SMALL)}}>
          <div style={{border: "1px solid black", padding: "7mm", width: "297mm"}}>
            <StorageJournalContent
              contentSelection={selectedContent}
              data={data}
              fromDate={fromDate}
              invoiceCustomerNames={invoiceCustomerNames}
              locationCustomerNames={locationCustomerNames}
              locationName={locationName}
              machineWithPriceGroupLabels={machineWithPriceGroupLabels}
              productLabels={productLabels}
              showCustomerColumn={showCustomerColumn}
              showFiltering={showFiltering}
              showStatusColumn={customerSettings.locationStorageJournalStatusColumn}
              summarizedByProductData={summarizedByProductData}
              summarizedData={summarizedData}
              toDate={toDate}
              workTypeWithPriceGroupLabels={workTypeWithPriceGroupLabels}
            />
          </div>
        </Grid>
      </CardContent>
    </Card>
  );
}
