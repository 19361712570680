import {UserUrl} from "@co-common-libs/resources";
import {
  getAccomodationAllowanceArray,
  getCurrentRoleIsManager,
  getCurrentUser,
  getCustomerSettings,
} from "@co-frontend-libs/redux";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {upperFirst} from "lodash";
import React, {useCallback, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  registerAccommodation: {
    defaultMessage: "Registrer diæt/udetillæg",
    id: "accommodation-allowance-checkbox.register-accommodation",
  },
  registerAccommodationOverride: {
    defaultMessage: "Registrer {label}",
    id: "accommodation-allowance-checkbox.register-accommodation-override",
  },
});

interface AccommodationAllowanceCheckboxProps {
  date: string;
  employeeUrl: UserUrl;
  labelPlacement?: "bottom" | "end" | "start" | "top";
  onChange: (registerAccommodation: boolean, remunerationGroup: string) => void;
  remunerationGroup: string;
}

export const AccommodationAllowanceCheckbox = React.memo(function AccommodationAllowanceCheckbox(
  props: AccommodationAllowanceCheckboxProps,
): React.JSX.Element {
  const {date, employeeUrl, labelPlacement = "end", onChange, remunerationGroup} = props;

  const currentUser = useSelector(getCurrentUser);
  const currentUserIsManager = useSelector(getCurrentRoleIsManager);
  const currentUserIsProjectManager = useSelector(getCurrentRoleIsManager);
  const customerSettings = useSelector(getCustomerSettings);

  const currentUserCanRegisterAccommodation =
    currentUser?.url === employeeUrl || currentUserIsManager || currentUserIsProjectManager;

  const accommodationAllowanceArray = useSelector(getAccomodationAllowanceArray);

  const employeeAccomodationAllowanceArray = accommodationAllowanceArray.filter(
    (accommodationAllowance) =>
      accommodationAllowance.employee === employeeUrl &&
      accommodationAllowance.date === date &&
      accommodationAllowance.remunerationGroup === remunerationGroup,
  );

  const employeeAccommodationAllowance = employeeAccomodationAllowanceArray.length
    ? employeeAccomodationAllowanceArray[0]
    : null;

  const [checked, setChecked] = useState(employeeAccommodationAllowance !== null);

  const {formatMessage} = useIntl();

  const handleChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      setChecked(value);
      onChange(value, remunerationGroup);
    },
    [onChange, remunerationGroup],
  );

  const remunerationGroupAccomodationAllowanceLabel =
    customerSettings.remunerationGroups[remunerationGroup]?.accomodationAllowanceLabel;

  const label = remunerationGroupAccomodationAllowanceLabel
    ? formatMessage(messages.registerAccommodationOverride, {
        label: upperFirst(remunerationGroupAccomodationAllowanceLabel),
      })
    : formatMessage(messages.registerAccommodation);

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      disabled={!currentUserCanRegisterAccommodation}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
});
