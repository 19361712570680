import {Config} from "@co-common-libs/config";
import {
  PriceItemUse,
  ProductUse,
  ReportingInputSpecification,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getValue} from "@co-common-libs/resources-utils";
import _ from "lodash";
import React from "react";
import {
  ReportingConversionInputField,
  ReportingInputField,
  ReportingMultiplicationInputField,
  ReportingSubtractionInputField,
} from "./input-fields";

export function buildDialogFields(
  unitLookup: (url: UnitUrl) => Unit | undefined,
  inputSpecificationList: readonly ReportingInputSpecification[],
  customerSettings: Config,
  valueMaps: {[identifier: string]: unknown}[],
  inputSpecificationsMap: ReadonlyMap<string, ReportingInputSpecification>,
  handleFieldChange: (
    identifier: string,
    value: boolean | number | string | readonly string[] | null,
  ) => void,
  currentValues: {[identifier: string]: unknown},
  priceItemUseList?: readonly PriceItemUse[],
  productUseList?: readonly ProductUse[],
  onTransferOk?: (value: number | null, url: string, unit: string) => void,
): {anyRequired: boolean; inputFields: React.JSX.Element[]; okDisabled: boolean} {
  const localGetValue = (identifier: string): any =>
    getValue(customerSettings, valueMaps, inputSpecificationsMap, identifier);
  let anyRequired = false;
  let okDisabled = false;

  const inputFields = inputSpecificationList.map((inputSpecification) => {
    const {identifier} = inputSpecification;
    const conversionSpecification = inputSpecification.conversion;
    if (conversionSpecification) {
      const numeratorIdentifier = conversionSpecification.numerator;
      const denominatorIdentifier = conversionSpecification.denominator;
      const numeratorValue = localGetValue(numeratorIdentifier);
      const denominatorValue = localGetValue(denominatorIdentifier);
      const numeratorInputSpecification = inputSpecificationsMap.get(
        numeratorIdentifier,
      ) as ReportingInputSpecification;
      return (
        <ReportingConversionInputField
          customerSettings={customerSettings}
          denominatorValue={denominatorValue}
          inputSpecification={inputSpecification}
          key={identifier}
          numeratorIdentifier={numeratorIdentifier}
          numeratorInputSpecification={numeratorInputSpecification}
          numeratorValue={numeratorValue}
          onNumeratorChange={handleFieldChange}
          onTransferOk={onTransferOk}
          priceItemUseList={priceItemUseList}
          productUseList={productUseList}
          unitLookup={unitLookup}
        />
      );
    }
    const subtractionSpecification = inputSpecification.subtraction;
    if (subtractionSpecification) {
      const minuendIdentifier = subtractionSpecification.minuend;
      const subtrahendIdentifier = subtractionSpecification.subtrahend;
      const minuendValue = localGetValue(minuendIdentifier);
      const subtrahendValue = localGetValue(subtrahendIdentifier);
      const minuendInputSpecification = inputSpecificationsMap.get(
        minuendIdentifier,
      ) as ReportingInputSpecification;
      return (
        <ReportingSubtractionInputField
          customerSettings={customerSettings}
          inputSpecification={inputSpecification}
          key={identifier}
          minuendIdentifier={minuendIdentifier}
          minuendInputSpecification={minuendInputSpecification}
          minuendValue={minuendValue}
          onChange={handleFieldChange}
          onTransferOk={onTransferOk}
          priceItemUseList={priceItemUseList}
          productUseList={productUseList}
          subtrahendValue={subtrahendValue}
          unitLookup={unitLookup}
        />
      );
    }

    const multiplicationSpecification = inputSpecification.multiplication;
    if (multiplicationSpecification) {
      const multiplierIdentifier = multiplicationSpecification.multiplier;
      const multiplicandIdentifier = multiplicationSpecification.multiplicand;
      const multiplierValue = localGetValue(multiplierIdentifier);
      const multiplicandValue = localGetValue(multiplicandIdentifier);
      const multiplierInputSpecification = inputSpecificationsMap.get(
        multiplierIdentifier,
      ) as ReportingInputSpecification;
      return (
        <ReportingMultiplicationInputField
          customerSettings={customerSettings}
          inputSpecification={inputSpecification}
          key={identifier}
          multiplicandValue={multiplicandValue}
          multiplierIdentifier={multiplierIdentifier}
          multiplierInputSpecification={multiplierInputSpecification}
          multiplierValue={multiplierValue}
          onChange={handleFieldChange}
          onTransferOk={onTransferOk}
          priceItemUseList={priceItemUseList}
          productUseList={productUseList}
          unitLookup={unitLookup}
        />
      );
    }

    const currentValue = currentValues[identifier];
    let value = currentValue === undefined ? localGetValue(identifier) : currentValue;
    if (value == null && _.get(inputSpecification, ["format", "type"]) === "boolean") {
      value = _.get(inputSpecification, ["format", "booleanDefault"]);
      handleFieldChange(identifier, value);
    }
    if (inputSpecification.required) {
      anyRequired = true;
      if (!okDisabled && !value && value !== 0) {
        if (_.get(inputSpecification, ["format", "type"]) !== "boolean") {
          okDisabled = true;
        }
      }
    }
    return (
      <ReportingInputField
        identifier={identifier}
        inputSpecification={inputSpecification}
        key={identifier}
        onChange={handleFieldChange}
        onTransferOk={onTransferOk}
        priceItemUseList={priceItemUseList}
        productUseList={productUseList}
        unitLookup={unitLookup}
        value={value}
      />
    );
  });
  return {anyRequired, inputFields, okDisabled};
}
