import {urlToId, User} from "@co-common-libs/resources";
import {getFrontendSentry} from "@co-frontend-libs/utils";

export function setSentryUser(user: User): void {
  if (user) {
    const sentry = getFrontendSentry();
    if (window.cordova) {
      // Our app uses an older native Cordova Sentry plugin.
      (sentry as any).configureScope((scope: any) => {
        scope.setUser({
          id: urlToId(user.url),
          username: user.loginIdentifier,
        });
      });
    } else {
      sentry.getCurrentScope().setUser({
        id: urlToId(user.url),
        username: user.loginIdentifier,
      });
    }
  }
}
