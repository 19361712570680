import {Config} from "@co-common-libs/config";
import {
  Machine,
  PriceGroup,
  Task,
  TaskPhoto,
  Timer,
  User,
  WorkshopChecklist,
  WorkshopChecklistAnswer,
  WorkshopChecklistItem,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {setDifference} from "@co-common-libs/utils";
import {defineMessages, IntlShape} from "react-intl";
import {mapSum} from "../iterable";
import {computeIntervalSums} from "../task-timers";
import {ADD_MACHINE_ACTION, CHANGE_DEPARTMENT_ACTION, EDIT_NOTE_ACTION, ErrorEntry} from "./types";

const messages = defineMessages({
  chauffeurError: {
    defaultMessage: "Chauffør mangler.",
  },
  checklistError: {
    defaultMessage: 'Du skal svare på alle spørgsmål i tjeklisten "{checklist}"',
  },
  departmentError: {
    defaultMessage: "Afdeling er ikke valgt.",
  },
  employeeError: {
    defaultMessage: "Medarbejder mangler.",
  },
  machineOperatorError: {
    defaultMessage: "Maskinfører mangler.",
  },
  noMachineError: {
    defaultMessage: "Der er ikke angivet maskine.",
  },
  noTimeError: {
    defaultMessage: "Der er ingen tid registreret.",
  },
  noVehicleError: {
    defaultMessage: "Der er ikke angivet køretøj.",
  },
  noWorkTypeError: {
    defaultMessage: "Der er ikke angivet arbejdsområde.",
  },
  noWorkTypeTimeError: {
    defaultMessage: "Der er ikke registreret tid på: {workTypeName}.",
  },
  taskMissingNoteError: {
    defaultMessage: "Notefeltet skal udfyldes på opgaven.",
  },
  taskMissingPhotoError: {
    defaultMessage: "Der mangler foto på opgaven.",
  },
});

export const getInternalTaskErrors = (
  data: {
    customerSettings: Config;
    department?: string | undefined;
    finalIntervals?:
      | readonly {
          readonly fromTimestamp: string;
          readonly timer: Timer | null;
          readonly toTimestamp: string;
        }[]
      | undefined;
    machineOperator?: User | undefined;
    machineUseList: {
      machine: Machine | null | undefined;
      priceGroup: string | PriceGroup | null;
      transporter: boolean;
    }[];
    onlyCompletedChecks?: boolean | undefined;
    primaryWorkType?: WorkType | undefined;
    task: Task;
    taskPhotoArray: readonly TaskPhoto[];
    workshopChecklistAnswers: readonly WorkshopChecklistAnswer[];
    workshopChecklistItemArray?: readonly WorkshopChecklistItem[] | undefined;
    workshopChecklists?: readonly WorkshopChecklist[] | undefined;
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
  now: Date,
  intl: IntlShape,
): readonly ErrorEntry[] => {
  const {
    customerSettings,
    department,
    finalIntervals,
    machineOperator,
    machineUseList,
    onlyCompletedChecks,
    primaryWorkType,
    task,
    taskPhotoArray,
    workshopChecklistAnswers,
    workshopChecklistItemArray,
    workshopChecklists,
    workTypeLookup,
  } = data;
  const errors: ErrorEntry[] = [];
  const finalSums = computeIntervalSums(finalIntervals || [], now);
  const minutesTotal = mapSum(finalSums);
  if (customerSettings.enableInternalTaskDepartmentField) {
    if (!department && !(customerSettings.onlyAdminCanChangeDepartment && onlyCompletedChecks)) {
      errors.push({
        action: CHANGE_DEPARTMENT_ACTION,
        text: intl.formatMessage(messages.departmentError),
      });
    }
  }
  if (!minutesTotal) {
    errors.push(intl.formatMessage(messages.noTimeError));
  }
  if (!machineOperator) {
    errors.push(
      customerSettings.employeeLabelVariant === "MACHINEOPERATOR"
        ? intl.formatMessage(messages.machineOperatorError)
        : customerSettings.employeeLabelVariant === "EMPLOYEE"
          ? intl.formatMessage(messages.employeeError)
          : intl.formatMessage(messages.chauffeurError),
    );
  }
  if (!primaryWorkType) {
    errors.push(intl.formatMessage(messages.noWorkTypeError));
  }
  if (
    !machineUseList.length &&
    primaryWorkType &&
    !primaryWorkType.disallowMachineUse &&
    primaryWorkType.requireMachineUse !== false
  ) {
    errors.push({
      action: ADD_MACHINE_ACTION,
      text:
        customerSettings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage(messages.noMachineError)
          : intl.formatMessage(messages.noVehicleError),
    });
  }

  if (workshopChecklists && workshopChecklistItemArray) {
    const answeredURLs = new Set(
      workshopChecklistAnswers.filter((a) => a.value != null).map((a) => a.checklistItem),
    );
    workshopChecklists.forEach((checklist) => {
      if (!checklist.required) {
        return;
      }
      const checklistUrl = checklist.url;
      const requiredItemURLs = new Set(
        workshopChecklistItemArray.filter((i) => i.checklist === checklistUrl).map((i) => i.url),
      );

      if (setDifference(requiredItemURLs, answeredURLs).size) {
        errors.push(
          intl.formatMessage(messages.checklistError, {
            checklist: checklist.name,
          }),
        );
      }
    });
  }

  const workType = task.workType && workTypeLookup(task.workType);
  if (workType && workType.requirePhotoOnTaskCompletion) {
    const photoOnTask = taskPhotoArray.some((taskPhoto) => taskPhoto.task === task.url);
    if (!photoOnTask) {
      errors.push(intl.formatMessage(messages.taskMissingPhotoError));
    }
  }
  if (!task.notesFromMachineOperator && workType && workType.requireNotesFromMachineOperator) {
    errors.push({
      action: EDIT_NOTE_ACTION,
      text: intl.formatMessage(messages.taskMissingNoteError),
    });
  }

  return errors;
};
