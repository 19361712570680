import {jsonFetch} from "@co-frontend-libs/utils";
import {Grid, TextField, Typography} from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import {useEventTargetValueCallback} from "app-utils";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {CvrResult} from "./cvr-types";
import {transformResponseToCVRResult} from "./cvr-utils";

const LOOKUP_DEBOUNCE_MS = 500;

interface CVRAutocompleteFieldProps {
  autoFocus?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  onCVRResultSelected: (cvrResult: CvrResult) => void;
  open: boolean;
  value: string;
}

const doFetch = async (
  baseURL: string,
  searchString: string,
  abortController: AbortController | undefined,
): Promise<CvrResult[]> => {
  const result = await jsonFetch(
    `${baseURL}cvr/search/?q=${searchString}`,
    "GET",
    null,
    abortController?.signal,
  );

  return transformResponseToCVRResult(result.data);
};

export function CVRAutocompleteField(props: CVRAutocompleteFieldProps): React.JSX.Element {
  const {autoFocus, disabled = false, onChange, onCVRResultSelected, open, value} = props;

  const [options, setOptions] = useState<CvrResult[]>([]);

  const intl = useIntl();
  const {baseURL} = globalConfig.resources;

  useEffect(() => {
    if (value === "") {
      setOptions([]);
      return undefined;
    }

    let abortController: AbortController | undefined;
    const timeout = window.setTimeout(() => {
      abortController = window.AbortController ? new AbortController() : undefined;
      doFetch(baseURL, value, abortController)
        .then((results: CvrResult[]) => {
          return setOptions(results);
        })
        .catch((error) => {
          if (abortController?.signal.aborted) {
            return;
          }
          // eslint-disable-next-line no-console
          console.error("CVR lookup failed:", error);
          throw error;
        });
    }, LOOKUP_DEBOUNCE_MS);

    return () => {
      window.clearTimeout(timeout);
      if (abortController) {
        abortController.abort();
      }
    };
  }, [value, baseURL]);

  const handleTextFieldChange = useEventTargetValueCallback(onChange, [onChange]);

  const handleTextFieldKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        if (options.length) {
          event.preventDefault();
          onCVRResultSelected(options[0]);
        }
      }
    },
    [onCVRResultSelected, options],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): React.JSX.Element => (
      <TextField
        {...(params.size ? params : _.omit(params, ["size"]))}
        autoFocus={autoFocus ?? true}
        fullWidth
        label={intl.formatMessage({
          defaultMessage: "Søg efter CVR nr., firmanavn, el.lign.",
        })}
        margin="dense"
        onChange={handleTextFieldChange}
        onKeyDown={handleTextFieldKeyDown}
        variant="outlined"
      />
    ),
    [autoFocus, handleTextFieldChange, handleTextFieldKeyDown, intl],
  );

  const renderOption = useCallback((option: CvrResult): React.JSX.Element => {
    const {address, city, name, postalCode, postalDistrict, vatNumber} = option;

    const postalDistrictStr = postalDistrict !== city ? `(${postalDistrict})` : "";

    return (
      <Grid alignItems="center" container>
        <Grid item style={{width: "100%", wordWrap: "break-word"}}>
          <Typography color="textPrimary" variant="body1">
            {name}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {address}, {postalCode} {city} {postalDistrictStr}, {vatNumber}
          </Typography>
        </Grid>
      </Grid>
    );
  }, []);

  const handleChange = useCallback(
    (
      _event: React.ChangeEvent<unknown>,
      selectedValue: string | CvrResult | null,
      reason: AutocompleteChangeReason,
    ): void => {
      if (reason === "clear") {
        onChange("");
      } else if (reason === "select-option") {
        if (selectedValue && typeof selectedValue !== "string") {
          onCVRResultSelected(selectedValue);
        }
      }
    },
    [onChange, onCVRResultSelected],
  );

  const getOptionLabel = useCallback((option: CvrResult): string => option.name, []);

  return (
    <Autocomplete<CvrResult, false, undefined, true>
      disabled={disabled}
      filterOptions={_.identity}
      freeSolo
      getOptionLabel={getOptionLabel}
      includeInputInList
      inputValue={value}
      multiple={false}
      onChange={handleChange}
      openOnFocus={open}
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
      style={{width: "100%"}}
    />
  );
}
