import {ImportPreviewMachine} from "@co-common-libs/resources-utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {EntryData, GenericMultiSelectionSearchDialog} from "../../search-dialog";

function computeBaseChoices(
  previewMachines: readonly ImportPreviewMachine[],
): readonly EntryData<string>[] {
  return previewMachines.map((instance) => {
    const {name, nameConflict, number} = instance;
    const identifier = number.toString();
    return {
      category: "standard",
      conflict: !!nameConflict,
      exactMatchValue: identifier,
      identifier,
      primaryText: name,
      searchFields: [
        {label: "Nr", priority: 10, text: identifier},
        {label: "Navn", priority: 5, text: name},
      ],
      secondaryText: identifier,
    };
  });
}

interface ImportMachinesPreviewDialogProps {
  error: string | undefined;
  onCancel(): void;
  onOk(identifiers: ReadonlySet<string>): void;
  open: boolean;
  previewMachines: readonly ImportPreviewMachine[] | null;
}

export const ImportMachinesPreviewDialog = React.memo(function ImportMachinesPreviewDialog(
  props: ImportMachinesPreviewDialogProps,
) {
  const {error, onCancel, onOk, open, previewMachines} = props;
  const intl = useIntl();

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );

  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = previewMachines ? getBaseChoices(previewMachines) : null;

  return (
    <GenericMultiSelectionSearchDialog<string>
      data={data}
      error={error}
      listEmptyMessage={intl.formatMessage({
        defaultMessage: "Alle maskiner er allerede importeret til CustomOffice",
      })}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      searchTitle={intl.formatMessage({
        defaultMessage: "Søg maskiner",
      })}
      sorting="SECONDARY_IDENTIFIER"
      title={intl.formatMessage({
        defaultMessage: "Vælg maskiner",
      })}
    />
  );
});
