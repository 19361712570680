import type {SettingID} from "./setting-group-relations";
import type {Config} from "./types";

export type SettingDependency = SettingID | {key: SettingID; value: Config[SettingID]};

export const requiresAllOf: Partial<{
  readonly [Key in SettingID]: readonly SettingDependency[];
}> = {
  allowMachineOperatorChangeOnTaskInfoPage: ["allowCustomerTaskEmployeeChange"],
  autoCopyMaterialNoteToSupplementingProductNote: ["autoSupplementingProducts"],
  autoSupplementingProducts: ["alternativeMaterialsMobileLayout"],
  brugerdataCreateOrderButton: ["brugerdataSync", "showBrugerdataOrdernumber"],
  brugerdataSyncOnCompleted: ["brugerdataSync"],
  brugerdataSyncOnValidated: ["brugerdataSync"],
  calendarPlanningFunctions: ["enableTaskEstimation"],
  canAddProject: ["enableProjects"],
  canEditProducts: ["showProductList"],
  canEditProjectDistance: ["canEditProjects", "enableProjectDistance"],
  canEditProjects: ["enableProjects"],
  customerOrderOverview: ["enableOrders"],

  economicCustomerDetailsOnTransportlogOnInvoice: ["economicShowTransportlogOnInvoice"],
  economicEnableMachinesProjectEmployeesSync: ["economicUploadMachinesToProjectEmployeeGroup"],
  economicInvoiceIncludeTotalTaskTime: ["economicSync"],
  economicInvoicePerWorkplacePickupLocationFallback: ["economicSync", "taskPickupDelivery"],
  economicInvoicePullerAsDeparment: ["economicEnableMachinesDepartmentSync"],
  economicInvoicesForProjectTasks: ["economicSync"],
  economicInvoiceWorkplaceAddressReplaceName: ["economicShowWorkPlaceOrDeliveryLocation"],
  economicRouteTaskStartStopOnInvoice: ["routeTaskStartStop"],
  economicShowPickupLocation: ["taskPickupDelivery"],
  economicShowTaskReferenceNumber: ["enableTaskReferenceNumber"],
  economicSync: ["useApproveReport"],
  employeeCanAccessAppendicesPage: ["enableAppendicesPage"],
  enablePriceItemTransferAsMinutes: ["economicSync"],
  enableProjectDistance: ["enableProjects"],
  enableProjectTravelTime: ["enableProjects"],
  hideOrderCalendarTab: ["orderCalendar"],
  invoiceAllowRecordedWithoutInvoiceLines: ["economicSync"],
  locationStoragePageVisibleToEveryone: ["enableLocationStorage"],
  machineOperaterCanEditInvoiceNote: ["showInvoiceNote"],
  navImportProjects: ["enableProjects"],
  onlyShowCalledInDialogForWorktypes: ["showCalledInDialogAfterMinutes"],
  orderCalendarAsTaskCalendar: ["orderCalendar"],
  orderListPageEnabled: ["enableOrders"],
  priceItemConversionSetup: ["canEditPriceItems"],
  showBrugerdataOrdernumber: ["brugerdataSync"],
  showFieldsOnBookkeepingTables: ["customerFields"],
  showOrderReferenceNumberOnTaskInfoTab: ["enableOrderReferenceNumber"],
  showProjectAlias: ["enableProjects"],
  showProjectNameOnTaskInfoTab: ["enableProjects"],
  showProjectNameOnTimeOverviewPage: ["enableProjects"],
  showProjectNumberOnTaskInfoTab: ["enableProjects"],
  showProjectNumberOnTimeOverviewPage: ["enableProjects"],
  taskCompletePrepareQuery: ["preparationWorkType"],
  taskCompleteRepairQuery: ["repairWorkType"],
  taskNoteLinesOnTaskOrderCalender: ["orderCalendarAsTaskCalendar"],
  taskWizardAskForWorkPlace: ["taskOrderTaskShowWorkPlace"],
  transportResponsibleEffectiveTimers: ["treatTransportTimeAsEffectiveForDepartments"],
  unicontaDMEUseTaskProjectForInternalCost: ["unicontaVariantDME", "createCostLines"],
  unicontaMachineDimension: ["unicontaSync"],
  unicontaManHoursMachines: ["unicontaSync", "unicontaMachineDimension"],
  unicontaPullerDimension: ["unicontaSync"],
  unicontaVariantDME: ["unicontaSync"],
  useCorrectedCountOnCostLines: ["createCostLines"],
  userProfileNormalTransportKilometersDefault: ["userProfileNormalTransportKilometers"],
  userProfileNormalTransportMinutesDefault: ["userProfileNormalTransportMinutes"],
};

export const noopWithoutAllOf: Partial<{
  readonly [key in SettingID]: readonly SettingDependency[];
}> = {
  allowOrderReferenceNumberEdit: ["enableOrderReferenceNumber"],
  contactsMayHaveCellphone: ["enableCustomerContacts"],
  createCostLines: [
    "enableProjects",
    "unicontaVariantDME",
    {key: "syncResources", value: "project"},
  ],
  displayCustomerContacts: ["enableCustomerContacts"],
  displayProjectsPageMenu: ["enableProjects"],
  economicEnableMachinesDepartmentSync: ["canCreateMachines"],
  economicEnableMachinesProjectEmployeesSync: ["canCreateMachines", "enableProjects"],
  economicMachineDimension: ["economicEnableMachinesDepartmentSync"],
  economicProjectsIncludeNotes: ["enableProjects"],
  economicProjectsMachineEmployeeGroups: ["enableProjects"],
  economicProjectsMachineEmployeeNumberOffset: ["enableProjects"],
  economicProjectsMachineHoursActivity: ["enableProjects"],
  economicProjectsManHoursActivity: ["enableProjects"],
  economicProjectsManHoursMachines: ["enableProjects"],
  economicProjectsProductUseCostActivity: ["enableProjects"],
  economicProjectsProductUseCostEmployee: ["enableProjects"],
  employeeCalendarManualOrderPerUser: ["enableEmployeeCalendarManualOrder"],
  fieldDefaultLocationType: ["customerFields"],
  orderEntryShowContact: ["enableCustomerContacts"],
  productImageSelectShowOnlyProductsWithPhoto: ["productImageSelection"],
  projectLabelVariant: ["enableProjects"],
  showStorageProductNotOnLocationWarning: ["enableLocationStorage"],
  taskOrderShowContact: ["enableCustomerContacts"],
  unicontaDMEWorkingHours: ["createCostLines"],
  useEmployeeGroups: ["enableEmployeeGroups"],
};

export const requiresOneOf: Partial<{
  readonly [key in SettingID]: readonly SettingDependency[];
}> = {
  colorInternalTaskWorkTypesOnEmployeeCalendar: [
    "colorWorkTypesOnEmployeeCalendar",
    "colorUnscheduledCalendar",
  ],
  createSalesLines: ["unicontaSync"],
  economicInvoicePerWorkplace: ["economicEnableWorkplaceImport", "economicUploadWorkPlaces"],
  economicShowMachineOperatorAliasOrNameWithoutLineBreak: [
    "economicShowMachineOperatorAlias",
    "economicShowMachineOperatorName",
  ],
  fuelSurcharge: ["economicSync", "c5Sync"],
  importExternalWorkTypesFromProductGroups: ["unicontaSync"],
  importInternalWorkTypesFromProducts: ["unicontaSync"],
};

export const noopWithoutOneOf: Partial<{
  readonly [key in SettingID]: readonly SettingDependency[];
}> = {};

export const conflictsWith: Partial<{
  readonly [key in SettingID]: readonly SettingDependency[];
}> = {
  addDefaultLocationToCustomers: ["brugerdataSync", "c5Sync", "navSync"],
  allowOrderReferenceNumberEdit: ["alwaysAllowEditingOrderReferenceNumber"],
  alternativeMaterialsMobileLayout: ["showOursToggle"],
  billedBreaks: ["c5Sync"],
  brugerdataSync: ["unicontaSync", "c5Sync", "economicSync", "navSync"],
  brugerdataSyncOnCompleted: ["brugerdataSyncOnValidated"],
  brugerdataSyncOnValidated: ["brugerdataSyncOnCompleted"],
  c5Sync: ["unicontaSync", "brugerdataSync", "economicSync", "navSync", "billedBreaks"],
  calendarPlanningFunctions: ["onlyManagerCreatedTasksCalendarPlanned"],
  canEditProducts: ["c5Sync", "brugerdataSync"],
  contactsMayHaveCellphone: ["economicSync"],
  economicEnableProjectActivitiesImport: [
    "economicEnableWorkplaceImport",
    "economicEnableMachinesDepartmentSync",
    "economicProjectsMachineHoursActivity",
    "economicProjectsMachineHoursActivity",
    "economicProjectsProductUseCostActivity",
    "economicProjectsProductUseCostEmployee",
  ],
  economicInvoicePerOrderReferenceNumber: [
    "economicInvoiceShowOrderWorkplaceNameAsOtherRef",
    "economicInvoiceShowTaskWorkplaceNameAsOtherRef",
    "economicInvoiceProjectNumberAndNameAsOtherReference",
  ],
  economicInvoicePerWorkplace: ["locationCrossCustomerSelectionEnabled"],
  economicInvoiceProjectNumberAndNameAsOtherReference: [
    "economicInvoicePerOrderReferenceNumber",
    "economicInvoiceShowOrderWorkplaceNameAsOtherRef",
    "economicInvoiceShowTaskWorkplaceNameAsOtherRef",
  ],
  economicInvoiceShowOrderWorkplaceNameAsOtherRef: [
    "economicInvoicePerOrderReferenceNumber",
    "economicInvoiceShowTaskWorkplaceNameAsOtherRef",
    "economicInvoiceProjectNumberAndNameAsOtherReference",
  ],
  economicInvoiceShowTaskWorkplaceNameAsOtherRef: [
    "economicInvoicePerOrderReferenceNumber",
    "economicInvoiceShowOrderWorkplaceNameAsOtherRef",
    "economicInvoiceProjectNumberAndNameAsOtherReference",
  ],
  economicMergLinesPerOrder: ["economicShowMachineOperatorAliasOrNameWithoutLineBreak"],
  economicProjectsMachineHoursActivity: ["routesEnabled"],
  economicProjectsManHoursActivity: ["routesEnabled"],
  economicProjectsProductUseCostActivity: ["routesEnabled"],
  economicProjectsProductUseCostEmployee: ["routesEnabled"],
  economicShowMachineOperatorAliasOrNameWithoutLineBreak: ["economicMergLinesPerOrder"],
  economicSync: ["unicontaSync", "brugerdataSync", "c5Sync", "navSync"],
  enablePriceItemVolumeDiscount: ["unicontaSync"],
  enableProjectDistance: ["enableProjectTravelTime"],
  enableProjectTravelTime: ["enableProjectDistance"],
  fuelSurcharge: ["navSyncProfile"],
  importExternalWorkTypesFromProductGroups: ["navSync", "brugerdataSync", "c5Sync", "economicSync"],
  locationCrossCustomerSelectionEnabled: ["economicInvoicePerWorkplace"],
  machineOperatorsCanSeeTheirOwnTasksAndActiveTasks: ["machineOperatorsCanOnlySeeTheirOwnTasks"],
  navSync: ["unicontaSync", "brugerdataSync", "c5Sync", "economicSync"],
  routesEnabled: [
    "economicProjectsProductUseCostActivity",
    "economicProjectsProductUseCostEmployee",
    "economicProjectsMachineHoursActivity",
    "economicProjectsManHoursActivity",
  ],
  showOursToggle: ["alternativeMaterialsMobileLayout"],
  taskListsMachineOperatorHideCompleted: ["taskListsMachineOperatorHideCompletedExceptToday"],
  taskListsMachineOperatorHideCompletedExceptToday: ["taskListsMachineOperatorHideCompleted"],
  unicontaMachineDimension: ["navSync", "brugerdataSync", "c5Sync", "economicSync"],
  unicontaPullerDimension: ["navSync", "brugerdataSync", "c5Sync", "economicSync"],
  unicontaSync: ["navSync", "brugerdataSync", "c5Sync", "economicSync"],
  useCalendarWorkHours: ["usePunchInOut"],
  usePunchInOut: ["useCalendarWorkHours"],
};
