import {IconButton, Typography} from "@material-ui/core";
import EmailIcon from "mdi-react/EmailIcon";
import React from "react";

export function EmailLine({email}: {email: string}): React.JSX.Element {
  return (
    <div>
      <IconButton color="primary" href={`mailto:${email}`}>
        <EmailIcon />
      </IconButton>
      <Typography component="span" variant="body2">
        {email}
      </Typography>
    </div>
  );
}
