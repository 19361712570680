import {emptyOrder} from "@co-common-libs/resources";
import {getSettingsEntryLookupByIdentifier} from "@co-frontend-libs/redux";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";
import {orderCopyExcludes} from "./order-copy-fields-dialog";

type OrderCopyFieldsMember = keyof typeof emptyOrder;
type OrderCopyFields = {[member in OrderCopyFieldsMember]: boolean};

export function DisplayOrderCopyFields(props: SettingValueDisplayProps): React.JSX.Element {
  const {settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const sentry = getFrontendSentry();
  const orderCopyFields: OrderCopyFields = useMemo(() => {
    const data: OrderCopyFieldsMember[] =
      settingEntry?.data || props.settingMetaData.schema.default;
    const newOrderCopyFields: Partial<OrderCopyFields> = {};
    const orderKeys = Object.keys(emptyOrder) as OrderCopyFieldsMember[];
    data.forEach((key) => {
      if (!orderKeys.includes(key) || orderCopyExcludes.includes(key)) {
        if (process.env.NODE_ENV !== "production") {
          // eslint-disable-next-line no-console
          console.error(`'${key}' not in allowed emptyOrder members`);
        }
        sentry.captureMessage(`'${key}' not in allowed emptyOrder members`);
      }
    });
    orderKeys.sort();
    orderKeys.forEach((key) => {
      if (!orderCopyExcludes.includes(key)) {
        newOrderCopyFields[key] = data.includes(key);
      }
    });
    return newOrderCopyFields as OrderCopyFields;
  }, [settingEntry?.data, props.settingMetaData.schema.default, sentry]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{width: 400}}>
            <FormattedMessage defaultMessage="Navn" id="system-setup.dialog-title.name" />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Kopieres" id="system-setup.copy" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(orderCopyFields).map(([field, enabled]) => {
          return (
            <TableRow key={field}>
              <TableCell style={{width: 400}}>{field}</TableCell>
              <TableCell>
                <Checkbox checked={enabled} disabled />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
