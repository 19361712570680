import {ResponsiveInfoDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import {WorkTypeChangeBlockedReason} from "app-utils";
import React from "react";
import {FormattedMessage} from "react-intl";

// TS validates that we return for any valid `blockedReason`
// eslint-disable-next-line consistent-return
function blockedReasonText(blockedReason: WorkTypeChangeBlockedReason): React.JSX.Element {
  switch (blockedReason) {
    case "extraTimer":
      return (
        <FormattedMessage defaultMessage="Der er målt tid på på en timer der tilhører opgavens nuværende område." />
      );
    case "replacedDisabledLog":
      return <FormattedMessage defaultMessage="Opgavens aktuelle log er en gammel version." />;
  }
}

interface WorkTypeChangeBlockedDialogProp {
  blockedReason: WorkTypeChangeBlockedReason | null;
  onClose: () => void;
  open: boolean;
}

export const WorkTypeChangeBlockedDialog = React.memo(function WorkTypeChangeBlockedDialog(
  props: WorkTypeChangeBlockedDialogProp,
): React.JSX.Element {
  const {blockedReason, onClose, open} = props;
  return (
    <ResponsiveInfoDialog
      onClose={onClose}
      open={open}
      title={<FormattedMessage defaultMessage="Du kan ikke skifte arbejdsområde" />}
    >
      <DialogContent>{blockedReason ? blockedReasonText(blockedReason) : null}</DialogContent>
    </ResponsiveInfoDialog>
  );
});
