import {SettingID} from "@co-common-libs/config";
import {urlToId} from "@co-common-libs/resources";
import {ConnectedMachineOperatorDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

interface SelectEmployeeProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function SelectEmployee(props: SelectEmployeeProps): React.JSX.Element {
  const {onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleMachineOperatorDialogOk = useCallback(
    (userUrl: string | null) => {
      const id = userUrl ? urlToId(userUrl) : null;
      if (settingEntry && id !== settingEntry.data) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: id},
          ]),
        );
      }
      onClose();
    },
    [currentUserURL, dispatch, onClose, settingEntry],
  );
  const handleMachineOperatorNone = useCallback(() => {
    handleMachineOperatorDialogOk(null);
  }, [handleMachineOperatorDialogOk]);

  return (
    <ConnectedMachineOperatorDialog
      onCancel={onClose}
      onNone={handleMachineOperatorNone}
      onOk={handleMachineOperatorDialogOk}
      open={open}
    />
  );
}
