import React from "react";
import {defineMessages, useIntl} from "react-intl";
import {FieldUseWithField} from "../fields-notes-reordering";
import {Linkify} from "../linkify";

const messages = defineMessages({
  noCode: {
    defaultMessage: "Intet marknr.",
    id: "fields-notes.label.no-code",
  },
});

export const FieldsNotes = ({
  extraNotes,
  fieldWithNotesList,
  ordered,
  showAll,
}: {
  extraNotes?: ReadonlyMap<string, string> | undefined;
  fieldWithNotesList: readonly FieldUseWithField[];
  ordered?: boolean;
  showAll?: boolean;
}): React.JSX.Element | null => {
  const intl = useIntl();
  if (!fieldWithNotesList.length) {
    return null;
  }
  const fieldList = fieldWithNotesList.map((field, index) => {
    const code = field.field.fieldNumber || intl.formatMessage(messages.noCode);
    const area = field.field.fieldAreaHa
      ? intl.formatNumber(field.field.fieldAreaHa, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : null;
    const crop = field.field.fieldCrop;
    const {notes} = field;
    const fieldField = field.field;
    const url = fieldField && fieldField.url;
    const fieldExtraNotes = extraNotes && extraNotes.get(url);
    if (!notes && !fieldExtraNotes && !showAll) {
      return null;
    }
    let notesLine;
    if (notes) {
      notesLine = (
        <span>
          <br />
          <Linkify>{notes}</Linkify>
        </span>
      );
    }
    let extraNotesLine;

    const fieldRecordYear = field.field.fieldRecordYear
      ? ` (${field.field.fieldRecordYear})`
      : null;

    if (fieldExtraNotes) {
      extraNotesLine = (
        <span>
          <br />
          <Linkify>{fieldExtraNotes}</Linkify>
        </span>
      );
    }
    return (
      <li key={`${url}--${index}`}>
        <b>{code}</b> {area ? `${area} ha` : null}
        {fieldRecordYear}: {crop}
        {extraNotesLine}
        {notesLine}
      </li>
    );
  });
  if (ordered) {
    return <ol>{fieldList}</ol>;
  }
  return <ul>{fieldList}</ul>;
};
