import {WorkStatus} from "@co-common-libs/utils";
import {colors, getContrastRatio} from "@material-ui/core";

// eslint-disable-next-line no-console
console.log(
  "colors.ts -- can/should to a larger extent be replaced with " +
    "MUI theme features;" +
    "theme.palette.error/theme.palette.warning, " +
    "theme.palette.getContrastText, ...",
);

let warnedMatchingTextColor = false;

export const matchingTextColor = (backgroundColor: string): string => {
  if (process.env.NODE_ENV !== "production" && !warnedMatchingTextColor) {
    // eslint-disable-next-line no-console
    console.warn("matchingTextColor() deprecated; use MUI theme.palette.getContrastText()");
    warnedMatchingTextColor = true;
  }
  const contrastRatio = getContrastRatio(backgroundColor, colors.common.white);

  if (contrastRatio < 4) {
    return colors.common.black;
  } else {
    return colors.common.white;
  }
};

export const colorMap = {
  ACTION_DELETE: colors.red[500],
  ACTIVITY_BREAK: colors.grey[500],
  ACTIVITY_DOWNTIME: colors.red[500],
  ACTIVITY_EFFECTIVE: colors.green[500],
  ACTIVITY_PREPARATION: colors.blue[500],
  ACTIVITY_TRANSPORTATION: colors.yellow[500],
  ACTIVITY_UNSPECIFIED: colors.common.white,
  CALENDAR_INTERNAL_TASK_DRAG: colors.grey[800],
  CALENDAR_NORMAL_TASK_DRAG: colors.green[800],
  CALENDAR_TASK_ESTIMATE: colors.blue[200],
  CALENDAR_TASK_OK: colors.green[500],
  CALENDAR_TASK_OVERLAP: colors.red["A700"],
  CALENDAR_TASK_PARTIAL: colors.red[500],
  CALENDAR_TASK_SELF: colors.grey[500],
  CALENDAR_TODO_OK: colors.grey[500],
  /** @deprecated use theme.palette.error */
  ERROR: colors.red[500],
  MACHINE_OPERATOR_AVATAR_BACKGROUND: colors.blueGrey[500],
  ORDER: colors.yellow[200],
  ORDER_TASK: colors.lightGreen[300],
  /** @deprecated use theme.palette.warning */
  WARNING: colors.amber[700],
};

export const statusColorMap: {[key in WorkStatus]: string | null} = {
  active: "#DFEEFC",
  completed: "#AEE8AB",
  error: null,
  paused: "#FFFDA4",
  pending: "#ffffff",
  recorded: "#E0E0E0",
  validated: "#AECAA8",
};

export const hexToRGBA = (hex: string, opacity = 1): string => {
  // Source: http://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const fullHex = hex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);
  if (result) {
    const [r, g, b] = result.slice(1).map((v) => parseInt(v, 16));
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  throw new Error(`"${hex}" is not a valid hex color code.`);
};
