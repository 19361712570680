import {MultiSelectionListItem, ResponsiveDialog} from "@co-frontend-libs/components";
import {
  Checkbox,
  createStyles,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    overflowOne: {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 1,
    },
  }),
);

export const MapFilterDialog = function MapFilterDialog({
  markerTypes,
  onCancel,
  onOk,
  open,
  selected,
}: {
  markerTypes: readonly {
    readonly label: string;
    readonly value: string;
  }[];
  onCancel?: () => void;
  onOk: (selected: readonly string[]) => void;
  open: boolean;
  selected: readonly string[];
}): React.JSX.Element {
  const [selectedMarkerTypes, setSelectedMarkerTypes] = useState<readonly string[]>(selected);

  useEffect(() => {
    setSelectedMarkerTypes(selected);
  }, [selected]);

  const handleMarkerTypeSelectChange = useCallback(
    (identifier: string, isSelected: boolean): void => {
      let newSelectedMarkerTypes;
      if (isSelected) {
        newSelectedMarkerTypes = [identifier, ...selectedMarkerTypes];
      } else {
        newSelectedMarkerTypes = selectedMarkerTypes.filter((type) => type !== identifier);
      }
      setSelectedMarkerTypes(newSelectedMarkerTypes);
    },
    [selectedMarkerTypes],
  );

  const allSelected = selectedMarkerTypes.length === markerTypes.length;

  const handleAllClick = useCallback((): void => {
    if (allSelected) {
      setSelectedMarkerTypes([]);
    } else {
      setSelectedMarkerTypes(markerTypes.map((type) => type.value));
    }
  }, [allSelected, markerTypes]);

  const handleOk = useCallback((): void => {
    onOk(selectedMarkerTypes);
  }, [onOk, selectedMarkerTypes]);

  const classes = useStyles();

  return (
    <ResponsiveDialog
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={<FormattedMessage defaultMessage="Lag" id="map-filter-dialog.title" />}
    >
      <DialogContent>
        <List>
          <ListItem button onClick={handleAllClick}>
            <ListItemIcon>
              <Checkbox checked={allSelected} disableRipple edge="start" tabIndex={-1} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage defaultMessage="Alle" id="map-filter-dialog.label.all" />}
            />
          </ListItem>
          <Divider />
          {markerTypes.map((marker) => (
            <MultiSelectionListItem
              category="standard"
              currentlySelected={selectedMarkerTypes.includes(marker.value)}
              key={marker.value}
              onSelect={handleMarkerTypeSelectChange}
              primaryText={marker.label}
              primaryTextClass={classes.overflowOne}
              secondaryTextClass={classes.overflowOne}
              value={marker.value}
              withIcons={false}
            />
          ))}
        </List>
      </DialogContent>
    </ResponsiveDialog>
  );
};
