import {Customer, CustomerUrl, Location, LocationTypeUrl} from "@co-common-libs/resources";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {ConnectedLocationTypeDialog} from "@co-frontend-libs/connected-components";
import {
  getExtendedCustomerSettings,
  getLocationTypeArray,
  getLocationTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue, useResettingState} from "@co-frontend-libs/utils";
import {
  Button,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  useTheme,
} from "@material-ui/core";
import {
  postalCodes,
  useEventTargetCheckedCallback,
  useFalseCallback,
  useLoadGoogleMaps,
  useTrueCallback,
} from "app-utils";
import {SPACING} from "frontend-global-config";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import React, {ChangeEvent, useCallback, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ChangeLocationCustomerDialog} from "../change-location-customer-dialog";
import {CustomerSelectCreateDialog} from "../customer-select-create-dialog";
import {AddressAutocompleteField} from "./address-autocomplete-field";
import {FullWindowLocationMap} from "./full-window-location-map";

// The following three props interfaces are combined below to the actual
// LocationCreateEditDialogProps interface, as a quickfix to make TypeScript
// catch misuse of this component.

export interface LocationCreateData {
  active: boolean;
  address: string;
  attention: string;
  city: string;
  coordinatesFromAddress: boolean;
  customer: CustomerUrl | null;
  customerChanged?: boolean;
  favorite: boolean;
  latitude: number | null;
  locationType: LocationTypeUrl | null;
  logOnlyLocation: boolean;
  longitude: number | null;
  name: string;
  phone: string;
  postalCode: string;
  workplaceOnlyLocation: boolean;
}

interface LocationCreateEditDialogCommonProps {
  customerLookup?: (url: CustomerUrl) => Customer | undefined;
  initialCustomer?: CustomerUrl | null;
  initialSearch?: string;
  locationFavoritesEnabled?: boolean;
  onCancel: () => void;
  onOk: (data: LocationCreateData) => void;
  open: boolean;
}

export interface LocationCreateEditDialogWorkplaceCreateProps
  extends LocationCreateEditDialogCommonProps {
  workplaceOnlyLocation: boolean;
}

export interface LocationCreateEditDialogLogCreateProps
  extends LocationCreateEditDialogCommonProps {
  logOnlyLocation: boolean;
}

export interface LocationCreateEditDialogEditProps extends LocationCreateEditDialogCommonProps {
  location: Readonly<Location> | undefined;
}

export type LocationCreateEditDialogProps =
  | LocationCreateEditDialogEditProps
  | LocationCreateEditDialogLogCreateProps
  | LocationCreateEditDialogWorkplaceCreateProps;

export const LocationCreateEditDialog = React.memo(function LocationCreateEditDialog(
  props: LocationCreateEditDialogProps,
): React.JSX.Element {
  const {
    customerLookup,
    initialCustomer,
    initialSearch,
    locationFavoritesEnabled,
    onCancel,
    onOk,
    open,
  } = props;
  const customerSettings = useSelector(getExtendedCustomerSettings);

  const location = (props as Partial<LocationCreateEditDialogEditProps>)?.location;
  const logOnly = (props as Partial<LocationCreateEditDialogLogCreateProps>)?.logOnlyLocation;
  const workplaceOnly = (props as Partial<LocationCreateEditDialogWorkplaceCreateProps>)
    ?.workplaceOnlyLocation;

  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const locationTypeArray = useSelector(getLocationTypeArray);

  const [placesSearch, setPlacesSearch] = useResettingState(initialSearch || "", open);
  const [active, setActive] = useResettingState(location?.active ?? true, open);
  const [address, setAddress] = useResettingState(location?.address || "", open);
  const [city, setCity] = useResettingState(location?.city || "", open);
  const [attention, setAttention] = useResettingState(location?.attention || "", open);
  const [phone, setPhone] = useResettingState(location?.phone || "", open);
  const [customer, setCustomer] = useResettingState(
    location?.customer || initialCustomer || null,
    open,
  );
  const [customerDialogOpen, setCustomerDialogOpen] = useResettingState(false, open);
  const [favorite, setFavorite] = useResettingState(location?.favorite || false, open);

  const [locationTypeUrl, setLocationTypeUrl] = useResettingState(
    location?.locationType ||
      locationTypeArray.find((instance) => instance.identifier === "standard")?.url ||
      null,
    open,
  );

  const locationType = locationTypeUrl ? locationTypeLookup(locationTypeUrl) : null;

  const [locationTypeDialogOpen, setLocationTypeDialogOpen] = useResettingState(false, open);
  const [name, setName] = useResettingState(location?.name || "", open);
  const [postalCode, setPostalCode] = useResettingState(location?.postalCode || "", open);
  const [latitude, setLatitude] = useResettingState(location?.latitude || null, open);
  const [longitude, setLongitude] = useResettingState(location?.longitude || null, open);
  const [centerLatitude, setCenterLatitude] = useResettingState(
    latitude || customerSettings.geolocation.initialPositionLatitude,
    open,
  );
  const [centerLongitude, setCenterLongitude] = useResettingState(
    longitude || customerSettings.geolocation.initialPositionLongitude,
    open,
  );
  const [logOnlyLocation, setLogOnlyLocation] = useResettingState(
    location?.logOnlyLocation ?? logOnly ?? false,
    open,
  );

  const [workplaceOnlyLocation, setWorkplaceOnlyLocation] = useResettingState(
    location?.workplaceOnlyLocation ?? workplaceOnly ?? false,
    open,
  );
  const [coordinatesFromAddress, setCoordinatesFromAddress] = useResettingState(
    location?.coordinatesFromAddress ?? false,
    open,
  );

  const handleOk = useCallback(() => {
    onOk({
      active,
      address: address.trim(),
      attention,
      city,
      coordinatesFromAddress,
      customer,
      customerChanged: !!location?.customer && customer !== location?.customer,
      favorite,
      latitude,
      locationType: locationTypeUrl,
      logOnlyLocation,
      longitude,
      name,
      phone,
      postalCode,
      workplaceOnlyLocation,
    });
  }, [
    onOk,
    active,
    address,
    attention,
    city,
    coordinatesFromAddress,
    customer,
    location?.customer,
    favorite,
    latitude,
    locationTypeUrl,
    logOnlyLocation,
    longitude,
    name,
    phone,
    postalCode,
    workplaceOnlyLocation,
  ]);

  const handlePostalCodeChange = useCallback(
    (value: string) => {
      setPostalCode(value);
      const cityForPostalCode = postalCodes[value];
      if (cityForPostalCode) {
        setCity(cityForPostalCode);
      }
    },
    [setCity, setPostalCode],
  );

  const handleActiveChange = useEventTargetCheckedCallback(setActive, [setActive]);

  const handleLocationOnlyChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, value: string) => {
      if (value === "both") {
        setLogOnlyLocation(false);
        setWorkplaceOnlyLocation(false);
      } else if (value === "logOnly") {
        setLogOnlyLocation(true);
        setWorkplaceOnlyLocation(false);
      } else {
        setWorkplaceOnlyLocation(true);
        setLogOnlyLocation(false);
      }
    },
    [setLogOnlyLocation, setWorkplaceOnlyLocation],
  );

  const handleCreateCustomerButtonClick = useTrueCallback(setCustomerDialogOpen, [
    setCustomerDialogOpen,
  ]);

  const handleLocationTypeButtonClick = useTrueCallback(setLocationTypeDialogOpen, [
    setLocationTypeDialogOpen,
  ]);

  const okDisabled = !(
    locationTypeUrl &&
    (name || address || postalCode || city) &&
    (customer || !customerLookup)
  );

  const intl = useIntl();

  const handleFavoriteChange = useEventTargetCheckedCallback(setFavorite, [setFavorite]);

  const handleLocationTypeDialogOk = useCallback(
    (locationTypeURL: LocationTypeUrl) => {
      setLocationTypeUrl(locationTypeURL);
      setLocationTypeDialogOpen(false);
    },
    [setLocationTypeUrl, setLocationTypeDialogOpen],
  );
  const handleCustomerDialogOk = useCallback(
    (customerURL: CustomerUrl | null) => {
      setCustomer(customerURL);
      setCustomerDialogOpen(false);
    },
    [setCustomer, setCustomerDialogOpen],
  );

  const handleLocationChange = useCallback(
    (newLatitude: number | null, newLongitude: number | null) => {
      setLatitude(newLatitude);
      setLongitude(newLongitude);
      setCoordinatesFromAddress(false);
    },
    [setCoordinatesFromAddress, setLatitude, setLongitude],
  );

  const autocompleteServiceRef = useRef<google.maps.places.AutocompleteService | undefined>();

  const geocoderRef = useRef<google.maps.Geocoder | undefined>();

  const {isLoaded: mapLoaded} = useLoadGoogleMaps();

  if (mapLoaded && !autocompleteServiceRef.current) {
    autocompleteServiceRef.current = new google.maps.places.AutocompleteService();
  }
  if (mapLoaded && !geocoderRef.current) {
    geocoderRef.current = new google.maps.Geocoder();
  }

  const [fullWindowMapOpen, setFullWindowMapOpen] = useState(false);
  const setFullWindowMapOpenTrue = useCallWithTrue(setFullWindowMapOpen);
  const setFullWindowMapOpenFalse = useCallWithFalse(setFullWindowMapOpen);

  const handleGeocodeResult = useCallback(
    (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
      if (status === google.maps.GeocoderStatus.OK && results) {
        let foundStreetAddress = "";
        let foundPostalCode = "";
        let foundPostalTown = "";
        let foundStreetNumber = "";
        let foundSublocality = "";
        let foundLocality = "";
        const result = results[0];
        const geometryLocation = result.geometry.location;
        const lat = geometryLocation.lat();
        const lng = geometryLocation.lng();
        setLatitude(lat);
        setLongitude(lng);
        setCenterLatitude(lat);
        setCenterLongitude(lng);
        setCoordinatesFromAddress(true);
        result.address_components.forEach((addressComponent) => {
          if (process.env.NODE_ENV !== "production") {
            // eslint-disable-next-line no-console
            console.log("found address:");
            // eslint-disable-next-line no-console
            console.log(addressComponent);
          }
          const {
            long_name: longName,
            types,
            /* short_name */
          } = addressComponent;
          types.forEach((type) => {
            switch (type) {
              case "locality":
                foundLocality = longName;
                break;
              case "postal_code":
                foundPostalCode = longName;
                break;
              case "postal_town":
                foundPostalTown = longName;
                break;
              case "route":
                foundStreetAddress = longName;
                break;
              case "street_address":
                foundStreetAddress = longName;
                break;
              case "street_number":
                foundStreetNumber = longName;
                break;
              case "sublocality":
                foundSublocality = longName;
                break;
              default:
                break;
            }
          });
        });
        if (foundPostalCode) {
          setPostalCode(foundPostalCode);
        }
        if (foundPostalTown) {
          setCity(foundPostalTown);
        } else if (foundLocality) {
          setCity(foundLocality);
        }
        const resultAddress =
          `${foundStreetAddress} ${foundStreetNumber}`.trim() || foundSublocality || foundLocality;
        if (resultAddress) {
          setAddress(resultAddress);
        }
      }
    },
    [
      setAddress,
      setCenterLatitude,
      setCenterLongitude,
      setCity,
      setCoordinatesFromAddress,
      setLatitude,
      setLongitude,
      setPostalCode,
    ],
  );

  const handlePlaceSelected = useCallback(
    async (place: google.maps.places.AutocompletePrediction): Promise<void> => {
      if (place.types.includes("point_of_interest") || place.types.includes("establishment")) {
        const resultMainText = place.structured_formatting.main_text;
        setName(resultMainText);
        setPlacesSearch(resultMainText);
      } else {
        setName("");
      }
      if (geocoderRef.current) {
        await geocoderRef.current.geocode({placeId: place.place_id}, handleGeocodeResult);
      }
    },
    [handleGeocodeResult, setName, setPlacesSearch],
  );

  const [changeCustomerDialogOpen, setChangeCustomerDialogOpen] = useState(false);
  const handleChangeCustomerClick = useTrueCallback(setChangeCustomerDialogOpen, [
    setChangeCustomerDialogOpen,
  ]);

  const handleChangeCustomerDialogOk = useCallback(() => {
    setChangeCustomerDialogOpen(false);
    setCustomerDialogOpen(true);
  }, [setCustomerDialogOpen]);

  const theme = useTheme();
  const {
    locations: {
      canChangeWorkplace,
      canCreateLocation,
      canCreateWorkplace,
      canEditField: unboundCanEditField,
    },
  } = customerSettings;

  const canEditField = location
    ? unboundCanEditField.bind(null, location)
    : () => canCreateLocation;

  const showUseAsSelection = location
    ? canChangeWorkplace
    : canCreateWorkplace && workplaceOnlyLocation === undefined && logOnlyLocation === undefined;

  const customerInstance = customerLookup && customer ? customerLookup(customer) : null;
  const customerActiveOrAbsent = customerInstance?.active ?? true;

  const handleChangeCustomerDialogCancel = useFalseCallback(setChangeCustomerDialogOpen, [
    setChangeCustomerDialogOpen,
  ]);

  const locationTypesExist = !!locationTypeArray.length;

  const title = location
    ? intl.formatMessage({defaultMessage: "Redigér sted"})
    : intl.formatMessage({defaultMessage: "Opret arbejdssted"});

  return (
    <>
      <ResponsiveDialog
        okDisabled={okDisabled}
        onCancel={onCancel}
        onOk={handleOk}
        open={open && !locationTypeDialogOpen && !customerDialogOpen && !changeCustomerDialogOpen}
        title={title}
      >
        <DialogContent>
          <Grid alignItems="flex-end" container spacing={1}>
            <Grid item xs>
              <AddressAutocompleteField
                autocompleteService={autocompleteServiceRef.current}
                autoFocus={!location}
                countryRestrictions={customerSettings.googleMapsCountryRestrictions}
                disabled={!canEditField("name")}
                onChange={setPlacesSearch}
                onPlaceSelected={handlePlaceSelected}
                openOnFocus={!!initialSearch}
                value={placesSearch}
              />
            </Grid>
            <Grid item>
              <IconButton
                color={latitude != null && longitude != null ? "primary" : "default"}
                onClick={setFullWindowMapOpenTrue}
              >
                <MapMarkerIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider
            style={{
              marginBottom: theme.spacing(SPACING.SMALL),
              marginTop: theme.spacing(SPACING.SMALL),
            }}
          />

          <TrimTextField
            disabled={!canEditField("name")}
            fullWidth
            label={intl.formatMessage({defaultMessage: "Navn"})}
            margin="dense"
            onChange={setName}
            value={name}
            variant="outlined"
          />
          <TrimTextField
            disabled={!canEditField("address")}
            fullWidth
            label={intl.formatMessage({defaultMessage: "Adresse"})}
            margin="dense"
            onChange={setAddress}
            value={address}
            variant="outlined"
          />
          <TrimTextField
            disabled={!canEditField("postalCode")}
            fullWidth
            inputProps={{maxLength: 15}}
            label={intl.formatMessage({defaultMessage: "Postnr."})}
            margin="dense"
            onChange={handlePostalCodeChange}
            value={postalCode}
            variant="outlined"
          />
          <TrimTextField
            disabled={!canEditField("city")}
            fullWidth
            label={intl.formatMessage({defaultMessage: "By"})}
            margin="dense"
            onChange={setCity}
            value={city}
            variant="outlined"
          />
          <TrimTextField
            disabled={!canEditField("attention")}
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Kontaktperson",
            })}
            margin="dense"
            onChange={setAttention}
            value={attention}
            variant="outlined"
          />
          <TrimTextField
            disabled={!canEditField("cellphone")}
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Telefonnr.",
            })}
            margin="dense"
            onChange={setPhone}
            value={phone}
            variant="outlined"
          />
          {customerLookup && canEditField("customer") && (
            <div>
              <Button
                color="secondary"
                disabled={!canEditField("customer")}
                onClick={location ? handleChangeCustomerClick : handleCreateCustomerButtonClick}
                variant="contained"
              >
                {customerInstance ? (
                  <FormattedMessage defaultMessage="Skift kunde" />
                ) : (
                  <FormattedMessage defaultMessage="Vælg kunde" />
                )}
              </Button>
              <div style={{marginTop: 5}}>
                {customerInstance ? (
                  customerInstance.name
                ) : (
                  <FormattedMessage defaultMessage="Ingen valgt" />
                )}
              </div>
            </div>
          )}
          {locationTypesExist ? (
            <div style={{marginTop: 10}}>
              <Button
                color="secondary"
                disabled={!canEditField("locationType")}
                onClick={handleLocationTypeButtonClick}
                variant="contained"
              >
                <FormattedMessage defaultMessage="Vælg stedtype" />
              </Button>
              <div style={{marginTop: 5}}>
                {locationType
                  ? locationType.name || locationType.identifier
                  : intl.formatMessage({defaultMessage: "Ingen valgt"})}
              </div>
            </div>
          ) : null}
          {locationFavoritesEnabled ? (
            <div>
              <FormControlLabel
                control={<Switch checked={favorite} onChange={handleFavoriteChange} />}
                label={intl.formatMessage({defaultMessage: "Favorit"})}
                labelPlacement="end"
              />
            </div>
          ) : null}
          {showUseAsSelection ? (
            <FormControl component="fieldset">
              <RadioGroup
                onChange={handleLocationOnlyChange}
                value={
                  logOnlyLocation ? "logOnly" : workplaceOnlyLocation ? "workplaceOnly" : "both"
                }
              >
                <FormControlLabel
                  control={<Radio />}
                  label={intl.formatMessage({
                    defaultMessage: "Kan bruges som både arbejdssted og logsted",
                  })}
                  value="both"
                />
                <FormControlLabel
                  control={<Radio />}
                  label={intl.formatMessage({
                    defaultMessage: "Kan kun bruges som arbejdssted",
                  })}
                  value="workplaceOnly"
                />
                <FormControlLabel
                  control={<Radio />}
                  label={intl.formatMessage({
                    defaultMessage: "Kan kun bruges som logsted",
                  })}
                  value="logOnly"
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {location ? (
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={active && customerActiveOrAbsent}
                    onChange={handleActiveChange}
                  />
                }
                disabled={!customerActiveOrAbsent || !canEditField("active")}
                label={intl.formatMessage({defaultMessage: "Aktiv"})}
                labelPlacement="end"
              />
              <div style={{color: theme.palette.warning.main}}>
                {!customerActiveOrAbsent && (
                  <FormattedMessage
                    defaultMessage="Stedet tilhører en inaktiv kunde og kan derfor ikke gøres aktiv"
                    tagName="div"
                  />
                )}
                {location.barred && (
                  <FormattedMessage
                    defaultMessage="Stedet er spærret økonomisystemet og kan derfor ikke gøres aktiv"
                    tagName="div"
                  />
                )}
              </div>
            </div>
          ) : null}
        </DialogContent>
      </ResponsiveDialog>
      <ConnectedLocationTypeDialog
        onCancel={useFalseCallback(setLocationTypeDialogOpen, [setLocationTypeDialogOpen])}
        onOk={handleLocationTypeDialogOk}
        open={locationTypeDialogOpen}
      />
      {location?.url ? (
        <ChangeLocationCustomerDialog
          locationUrl={location.url}
          onCancel={handleChangeCustomerDialogCancel}
          onOk={handleChangeCustomerDialogOk}
          open={changeCustomerDialogOpen}
        />
      ) : null}
      <CustomerSelectCreateDialog
        onCancel={useFalseCallback(setCustomerDialogOpen, [setCustomerDialogOpen])}
        onOk={handleCustomerDialogOk}
        open={customerDialogOpen}
      />
      <FullWindowLocationMap
        centerLatitude={centerLatitude}
        centerLongitude={centerLongitude}
        latitude={latitude}
        longitude={longitude}
        mapLoaded={mapLoaded}
        onClose={setFullWindowMapOpenFalse}
        onLocationChange={handleLocationChange}
        open={fullWindowMapOpen}
      />
    </>
  );
});
