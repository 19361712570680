import {
  PriceItemUsesDict,
  ReportingInputSpecification,
  ReportingWorkplaceTypeDataSpecification,
  Task,
} from "@co-common-libs/resources";
import {getValue} from "@co-common-libs/resources-utils";
import {
  getCurrentRole,
  getCustomerSettings,
  getPriceItemLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {PriceItemTable} from "app-components";
import {getPotentialTargetTransferPriceItemUrls} from "app-utils";
import React, {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {PriceItemUsesAction} from "./utils";

interface PriceItemBlockProps {
  dispatchPriceItemUses: React.Dispatch<PriceItemUsesAction>;
  inputSpecificationsMap: Map<string, ReportingInputSpecification>;
  priceItemUses: PriceItemUsesDict;
  task: Task;
  valueMaps: readonly {
    readonly [identifier: string]: unknown;
  }[];
  workplaceTypeDataSpecification: ReportingWorkplaceTypeDataSpecification;
}

export function PriceItemBlock(props: PriceItemBlockProps): React.JSX.Element {
  const {
    dispatchPriceItemUses,
    inputSpecificationsMap,
    priceItemUses,
    task,
    valueMaps,
    workplaceTypeDataSpecification,
  } = props;

  console.assert(
    workplaceTypeDataSpecification.logPriceItems,
    "Using ProductBlock without logPriceItems",
  );

  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);

  const customerSettings = useSelector(getCustomerSettings);
  const currentRole = useSelector(getCurrentRole);

  const userIsManager = currentRole && currentRole.manager;
  const userIsOtherMachineOperator = !userIsManager && currentRole?.user !== task.machineOperator;

  const {logInputs: inputSpecifications, priceItemConversion} = workplaceTypeDataSpecification;

  const priceItemConversionDenominatorUnit = priceItemConversion?.unit;
  const priceItemDenominatorValue = priceItemConversion
    ? getValue(customerSettings, valueMaps, inputSpecificationsMap, priceItemConversion.field)
    : undefined;

  const extraConversionRelatedValues =
    priceItemConversionDenominatorUnit && typeof priceItemDenominatorValue === "number"
      ? {[priceItemConversionDenominatorUnit]: priceItemDenominatorValue}
      : undefined;

  const handlePriceItemUseCountChange = useCallback(
    (identifier: string, count: number | null): void => {
      dispatchPriceItemUses({count, identifier, type: "set-count"});
    },
    [dispatchPriceItemUses],
  );

  const handlePriceItemUseNotesChange = useCallback(
    (identifier: string, notes: string): void => {
      dispatchPriceItemUses({identifier, notes, type: "set-notes"});
    },
    [dispatchPriceItemUses],
  );

  const allPriceItemTransferTargets = useMemo(() => {
    const combinedPriceItemTargets = new Set<string>();
    const priceItemUrls = Object.values(priceItemUses).map(
      (priceItemUse) => priceItemUse.priceItem,
    );
    if (inputSpecifications) {
      inputSpecifications.forEach((inputSpecification) => {
        if (inputSpecification.transferToEntry && inputSpecification.unit) {
          const priceItemTargets = getPotentialTargetTransferPriceItemUrls(
            priceItemUrls,
            inputSpecification.unit,
            priceItemLookup,
            unitLookup,
          );
          if (priceItemTargets) {
            priceItemTargets.forEach((url) => combinedPriceItemTargets.add(url));
          }
        }
      });
    }
    return combinedPriceItemTargets;
  }, [inputSpecifications, priceItemLookup, priceItemUses, unitLookup]);

  return (
    <PriceItemTable
      extraConversionRelatedValues={extraConversionRelatedValues}
      onCountChange={handlePriceItemUseCountChange}
      onNotesChange={handlePriceItemUseNotesChange}
      priceItemUses={priceItemUses}
      readonly={
        task.validatedAndRecorded ||
        (!userIsManager && (task.completed || userIsOtherMachineOperator))
      }
      readonlyPriceItems={allPriceItemTransferTargets}
      showNotes={false}
    />
  );
}
