import {ReadonlyConfig} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {roleIsManager} from "@co-common-libs/utils";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";
import {liveSyncResource} from "../live-sync-resource";

export type ExtendedContactSettingsPart = EconomySystemIntegrationKindPart &
  Pick<ReadonlyConfig, "contactsMayHaveCellphone" | "createCustomer">;

export interface ExtendedContactSettings {
  canManage: boolean;
  contactsMayHaveCellphone: boolean;
  contactsMayHaveFax: boolean;
}

export function extendedContactSettings(
  config: ExtendedContactSettingsPart,
  role: Pick<Role, "manager" | "projectManager"> | null,
): ExtendedContactSettings {
  const {createCustomer} = config;

  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const roleCanManage =
    createCustomer === "all" || (createCustomer === "manager" && roleIsManager(role));

  const integrationAllowsManage =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
    (economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC &&
      liveSyncResource(config, "contact"));

  return {
    canManage: integrationAllowsManage && roleCanManage,
    contactsMayHaveCellphone: config.contactsMayHaveCellphone,
    contactsMayHaveFax: !config.economicSync,
  };
}
