import {Config} from "@co-common-libs/config";
import {
  Machine,
  PriceGroup,
  PriceGroupUrl,
  ReportingSpecification,
  Role,
  Task,
  TimerUrl,
} from "@co-common-libs/resources";
import {Button, Grid} from "@material-ui/core";
import {TaskPriceItemTable, TaskProductTable} from "app-components";
import React from "react";
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
  addProduct: {
    defaultMessage: "Tilføj",
  },
});

interface ProductsTabContentProps {
  completed?: boolean;
  currentRole: Role | null;
  currentUserURL: string | null;
  customerSettings: Config;
  hasActivity: boolean;
  machineArray: readonly Machine[];
  onProductSelectButton: () => void;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemsReadonly?: boolean;
  productDialogTimerMinutesMap: ReadonlyMap<TimerUrl, number>;
  readonlyPriceItems?: ReadonlySet<string> | undefined;
  reportingSpecification?: ReportingSpecification | undefined;
  task: Task;
  userIsManager: boolean;
  userIsOtherMachineOperator: boolean;
  validated?: boolean;
}

const ProductsTabContent = (props: ProductsTabContentProps): React.JSX.Element => {
  const {formatMessage} = useIntl();
  const {
    completed,
    customerSettings,
    hasActivity,
    onProductSelectButton,
    productDialogTimerMinutesMap,
    readonlyPriceItems,
    reportingSpecification,
    task,
    userIsManager,
    userIsOtherMachineOperator,
    validated,
  } = props;
  let addProductButtonGrid = null;
  if (!customerSettings.noProducts && customerSettings.enableAddProducts) {
    addProductButtonGrid = (
      <Grid container style={{paddingLeft: "1em", paddingRight: "1em"}}>
        <Grid item xs={12}>
          <Button
            color="secondary"
            disabled={validated || (!userIsManager && (completed || userIsOtherMachineOperator))}
            onClick={onProductSelectButton}
            style={{width: "100%"}}
            variant="contained"
          >
            {formatMessage(messages.addProduct)}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      {addProductButtonGrid}
      <Grid container style={{marginTop: "1em", paddingLeft: "1em", paddingRight: "1em"}}>
        <Grid item xs={12}>
          <TaskPriceItemTable
            readonly={
              task.validatedAndRecorded ||
              (!userIsManager && (completed || userIsOtherMachineOperator))
            }
            readonlyPriceItems={readonlyPriceItems}
            task={task}
          />
          <TaskProductTable
            hasActivity={hasActivity}
            productDialogTimerMinutesMap={productDialogTimerMinutesMap}
            reportingSpecification={reportingSpecification}
            task={task}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductsTabContent;
