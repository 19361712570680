import type {
  FuelSurchargeKrPerLiterInvoiceData,
  KrPerLiterDefaultFuelSurchargeUse,
  KrPerLiterFuelSurchargeBasis,
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationEntry,
  KrPerLiterMachineFuelSurchargeUse,
  KrPerLiterWorkTypeFuelSurchargeUse,
  MachineUrl,
  Product,
  ProductUrl,
} from "@co-common-libs/resources";
import {dateFromString} from "@co-common-libs/utils";
import {IntlShape} from "@formatjs/intl";
import _ from "lodash";
import {findFuelSurchargeBasis} from "./find-fuel-surcharge-basis";
import {formatKrPerLiterString} from "./format-kr-per-liter-string";

type KrPerLiterFuelSurchargeSpecificationPart = Pick<
  KrPerLiterFuelSurchargeSpecification,
  | "conversionFactor"
  | "invoiceLineProduct"
  | "invoiceLineText"
  | "name"
  | "url"
  | "zeroSurchargeInvoiceLines"
>;

type KrPerLiterFuelSurchargeSpecificationEntryPart = Pick<
  KrPerLiterFuelSurchargeSpecificationEntry,
  "fromDate" | "priceKrPerLiter" | "toDate"
>;

type KrPerLiterFuelSurchargeBasisPart = Pick<
  KrPerLiterFuelSurchargeBasis,
  | "basePriceKrPerLiter"
  | "fromDate"
  | "priceKrPerLiterIncreaseThreshold"
  | "specification"
  | "truncateNegativeToZero"
>;

type KrPerLiterFuelSurchargeUsePart = Partial<
  Pick<KrPerLiterMachineFuelSurchargeUse, "customer" | "machine" | "variant">
> &
  Partial<Pick<KrPerLiterWorkTypeFuelSurchargeUse, "customer" | "variant" | "workType">> &
  Pick<KrPerLiterDefaultFuelSurchargeUse, "customer">;

type ProductPart = Pick<Product, "name">;

function getWithFuelSurchargeEntryData(
  intl: Pick<IntlShape, "formatMessage">,
  productLookup: (url: ProductUrl) => ProductPart | undefined,
  fuelSurchargeBasisArray: readonly KrPerLiterFuelSurchargeBasisPart[],
  taskDate: string,
  specification: KrPerLiterFuelSurchargeSpecificationPart,
  specificationEntry: KrPerLiterFuelSurchargeSpecificationEntryPart,
  use: KrPerLiterFuelSurchargeUsePart,
  literPerHour: number,
): FuelSurchargeKrPerLiterInvoiceData[MachineUrl] {
  const basis = findFuelSurchargeBasis(fuelSurchargeBasisArray, specification.url, taskDate);
  if (!basis) {
    throw new Error(`Basis not found for specification ${specification.url} at ${taskDate}`);
  }
  const priceIncrease = specificationEntry.priceKrPerLiter - basis.basePriceKrPerLiter;
  const priceIncreaseOverThreshold =
    priceIncrease > 0
      ? Math.max(priceIncrease - basis.priceKrPerLiterIncreaseThreshold, 0)
      : priceIncrease;
  const truncatedPriceIncreaseOverThreshold =
    basis.truncateNegativeToZero && priceIncreaseOverThreshold < 0 ? 0 : priceIncreaseOverThreshold;

  const productUrl = specification.invoiceLineProduct;
  const product = productUrl ? productLookup(productUrl) : null;
  const name = product ? product.name : specification.name;
  const text = formatKrPerLiterString(
    intl,
    specification.invoiceLineText,
    name,
    dateFromString(specificationEntry.fromDate) as Date,
    dateFromString(specificationEntry.toDate) as Date,
    specificationEntry.priceKrPerLiter,
  );
  return {
    costKrPerLiter: priceIncrease,
    krPerLiter: truncatedPriceIncreaseOverThreshold,
    literPerHour,
    product: productUrl,
    specification: {
      ..._.pick(specification, ["conversionFactor", "zeroSurchargeInvoiceLines"]),
      basis: _.pick(basis, [
        "fromDate",
        "basePriceKrPerLiter",
        "priceKrPerLiterIncreaseThreshold",
        "truncateNegativeToZero",
      ]),
      entry: _.pick(specificationEntry, ["fromDate", "toDate", "priceKrPerLiter"]),
    },
    text,
    useRule: {
      customer: use.customer,
      machine: use.machine || null,
      variant: use.variant || null,
      workType: use.workType || null,
    },
  };
}

function getWithoutFuelSurchargeEntryData(
  intl: Pick<IntlShape, "formatMessage">,
  use: KrPerLiterFuelSurchargeUsePart,
): FuelSurchargeKrPerLiterInvoiceData[MachineUrl] {
  const text = intl.formatMessage({
    defaultMessage: "Ingen brændstoftillæg",
  });
  return {
    costKrPerLiter: 0,
    krPerLiter: 0,
    literPerHour: 0,
    product: null,
    specification: null,
    text,
    useRule: {
      customer: use.customer,
      machine: use.machine || null,
      variant: use.variant || null,
      workType: use.workType || null,
    },
  };
}

export function formatFuelSurchargeKrPerLiterInvoiceData(
  intl: Pick<IntlShape, "formatMessage">,
  productLookup: (url: ProductUrl) => ProductPart | undefined,
  fuelSurchargeBasisArray: readonly KrPerLiterFuelSurchargeBasisPart[],
  taskDate: string,
  withFuelSurcharge: ReadonlyMap<
    MachineUrl,
    {
      fuelConsumptionLiterPerHour: number;
      specification: KrPerLiterFuelSurchargeSpecificationPart;
      specificationEntry: KrPerLiterFuelSurchargeSpecificationEntryPart;
      use: KrPerLiterFuelSurchargeUsePart;
    }
  >,
  withoutFuelSurcharge: ReadonlyMap<
    MachineUrl,
    {
      use: KrPerLiterFuelSurchargeUsePart;
    }
  >,
): FuelSurchargeKrPerLiterInvoiceData {
  const entries: [string, FuelSurchargeKrPerLiterInvoiceData[MachineUrl]][] = [];
  withFuelSurcharge.forEach(
    ({fuelConsumptionLiterPerHour, specification, specificationEntry, use}, key) => {
      const data = getWithFuelSurchargeEntryData(
        intl,
        productLookup,
        fuelSurchargeBasisArray,
        taskDate,
        specification,
        specificationEntry,
        use,
        fuelConsumptionLiterPerHour,
      );
      entries.push([key, data]);
    },
  );
  withoutFuelSurcharge.forEach(({use}, key) => {
    const data = getWithoutFuelSurchargeEntryData(intl, use);
    entries.push([key, data]);
  });
  return Object.fromEntries(entries);
}
