import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

interface ReportingIdentifierNameDialogProps {
  identifier?: string | undefined;
  name?: string | undefined;
  onCancel: () => void;
  onOk: (identifier: string, name: string) => void;
  open: boolean;
  otherReportingIdentifiers: ReadonlySet<string>;
}

export function ReportingIdentifierNameDialog(
  props: ReportingIdentifierNameDialogProps,
): React.JSX.Element {
  const {
    identifier: propsIdentifier,
    name: propsName,
    onCancel,
    onOk,
    open,
    otherReportingIdentifiers,
  } = props;
  const [identifier, setIdentifier] = useState(propsIdentifier || "");
  const [name, setName] = useState(propsName || "");
  const trimmedIdentifier = identifier.trim();
  const trimmedName = name.trim();
  useEffect(() => {
    if (open) {
      setIdentifier(propsIdentifier || "");
      setName(propsName || "");
    }
  }, [propsIdentifier, open, propsName]);

  const handleOk = useCallback(() => {
    onOk(trimmedIdentifier, trimmedName);
  }, [onOk, trimmedIdentifier, trimmedName]);
  const title =
    propsIdentifier === undefined ? (
      <FormattedMessage
        defaultMessage="Opret log"
        id="reporting-identifier-name-dialog.dialog-title.create-log"
      />
    ) : (
      <FormattedMessage
        defaultMessage="Redigér log"
        id="reporting-identifier-name-dialog.dialog-title.edit-log"
      />
    );
  const identifierConflict =
    !!trimmedIdentifier && otherReportingIdentifiers.has(trimmedIdentifier);
  const okDisabled = !trimmedIdentifier || identifierConflict || !name;
  return (
    <ResponsiveDialog
      okDisabled={okDisabled}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={title}
    >
      <DialogContent>
        <TrimTextField
          autoFocus
          error={identifierConflict}
          fullWidth
          helperText={
            identifierConflict ? (
              <FormattedMessage
                defaultMessage="ID allerede i brug"
                id="reporting-identifier-name-dialog.error-text.identifier-conflict"
              />
            ) : undefined
          }
          label={
            <FormattedMessage
              defaultMessage="ID"
              id="reporting-identifier-name-dialog.label.identifier"
            />
          }
          margin="dense"
          onChange={setIdentifier}
          value={identifier}
          variant="outlined"
        />
        <TrimTextField
          autoFocus
          fullWidth
          label={
            <FormattedMessage
              defaultMessage="Navn"
              id="reporting-identifier-name-dialog.label.name"
            />
          }
          margin="dense"
          onChange={setName}
          value={name}
          variant="outlined"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
