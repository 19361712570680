import {EmployeeGroupUrl, UserProfile} from "@co-common-libs/resources";
import {
  actions,
  getActiveEmployeeGroupArray,
  getEmployeeGroupLookup,
} from "@co-frontend-libs/redux";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@material-ui/core";
import {sortBy} from "lodash";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface EmployeeGroupCardProps {
  userProfile: UserProfile;
}

export const EmployeeGroupCard = React.memo(function EmployeeGroupCard(
  props: EmployeeGroupCardProps,
) {
  const {userProfile} = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();

  const activeEmployeeGroupArray = useSelector(getActiveEmployeeGroupArray);

  const employeeGroupLookup = useSelector(getEmployeeGroupLookup);

  const handleEmployeeGroupChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      if (value !== userProfile.employeeGroup) {
        dispatch(
          actions.updateDiff(
            {employeeGroup: value ? (value as EmployeeGroupUrl) : null},
            userProfile,
          ),
        );
      }
    },
    [dispatch, userProfile],
  );

  const currentEmployeeGroup =
    userProfile.employeeGroup && employeeGroupLookup(userProfile.employeeGroup);

  return (
    <Card>
      <CardHeader title={intl.formatMessage({defaultMessage: "Medarbejdergrupper"})} />
      <CardContent>
        <RadioGroup
          name="employee-group-group"
          onChange={handleEmployeeGroupChange}
          value={userProfile.employeeGroup}
        >
          {currentEmployeeGroup && !currentEmployeeGroup.active && (
            <>
              <div style={{color: theme.palette.error.main}}>
                <FormControlLabel
                  control={<Radio style={{color: theme.palette.error.main}} />}
                  key={currentEmployeeGroup.url}
                  label={currentEmployeeGroup.name}
                  value={currentEmployeeGroup.url}
                />
                <div>
                  <FormattedMessage defaultMessage="Denne medarbejdergruppe er inaktiv, vælg en anden" />
                </div>
              </div>
              <Divider />
            </>
          )}
          <FormControlLabel
            control={<Radio />}
            key={null}
            label={intl.formatMessage({defaultMessage: "Ingen gruppe"})}
            value={null}
          />
          {sortBy(activeEmployeeGroupArray, "name").map((employeeGroup) => (
            <FormControlLabel
              control={<Radio />}
              key={employeeGroup.url}
              label={employeeGroup.name}
              value={employeeGroup.url}
            />
          ))}
        </RadioGroup>
      </CardContent>
    </Card>
  );
});
